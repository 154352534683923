export function normalizeGetOptionOptions(opts, view) {
	if (opts === false) {
		return { invoke: false }
	} else if (opts === true) {
		return {
			invoke: true,
			invokeContext: view,
			invokeArgs: view
		}
	} else if (opts == null) {
		return {};
	} else {
		return opts;
	}
}

export function getOptions(view, keys, options) {
	options = normalizeGetOptionOptions(options, view);
	const { ignoreUndefined, ignoreNull } = options;
	return keys.reduce((memo, key) => {
		const value = view.getOption(key, options);
		if (
			(value === undefined && ignoreUndefined !== false)
			||
			(value === null && ignoreNull === true)
		) {
			return memo;
		}
		memo[key] = value;
		return memo;
	}, {});
}