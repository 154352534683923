import { displayNum } from "utils";
import { View } from 'core/view';
import consts from 'vXX/app/constants';
const { NDS_KOEF } = consts;

function decreaseToBaseValue(value, options) {
	const { withNds, withBoma, nds, boma } = options;

	if (withNds && nds) {
		value = value / NDS_KOEF;
	}

	if (withBoma && boma > 1) {
		value = value / boma;
	}

	return value;

}

function getSquare(baseMeter, options) {
	const { square, isRent } = options;
	return square * baseMeter / (isRent ? 12 : 1);
}

function getMeterByMeter(meterValue, options) {
	const { value: defaultMeter } = options;

	if (meterValue == null) {
		return defaultMeter;
	}

	let value = decreaseToBaseValue(meterValue, options);

	return value;
}


function getMeterBySquare(squareValue, options) {
	const { priceMeter: defaultMeter } = options;

	if (squareValue == null) {
		// const defaultSquare = getSquare(defaultMeter, options);
		return defaultMeter;
	}

	let baseSquare = decreaseToBaseValue(squareValue, options);
	const { square, isRent } = options;
	let meter = baseSquare / square * (isRent ? 12 : 1);
	return meter;
}

function getSquareByMeter(meterValue, options) {
	return getSquareByMeterGetter(meterValue, options, getMeterByMeter);
}

function getSquareBySquare(squareValue, options) {
	return getSquareByMeterGetter(squareValue, options, getMeterBySquare);
}

function getSquareByMeterGetter(inputedValue, options, meterGetter) {

	const baseMeter = meterGetter(inputedValue, options);

	const value = getSquare(baseMeter, options);

	return value;

}



function calculateBase (getType, valueType, value, options) {
	const key = getType + ':' + valueType;
	
	switch (key) {
		case 'meter:meter':
			return getMeterByMeter(value, options);
		case 'meter:square':
			return getMeterBySquare(value, options);
		case 'square:meter':
			return getSquareByMeter(value, options);
		case 'square:square':
			return getSquareBySquare(value, options);
	}
}

function valueToOptions(valueObject, withNds, withBoma) {
	const { operation, nds, boma } = valueObject;
	
	const isRent = operation === 'rent';
	const bomaKoef = isRent && boma > 1 ? boma : 1;
	const ndsKoef = nds ? NDS_KOEF : 1;


	return {
		withNds, withBoma, isRent,
		bomaKoef, ndsKoef,
		...valueObject
	}
}

export const EditOfferPriceBase = View.extend({
	
	template: `
		<label><%= operation %> <%= square %> м<sup>2</sup></label>
		<p>налогообложение: <big><%= taxType %></big></p>
		<% if (rent) {%><p>коридорный коэффициент: <big><%= boma %></big></p><% } %>
		<p>текущая цена метра: <big><%= fullmeter %></big> р. <%= ndsMeterPostfix %></p>
		<p>текущая цена площади: <big><%= fullsquare %></big> р. <%= ndsSquarePostfix %></p>
		<p>
			<label>установите новую стоимость <%= subjectLabela %>:</label>
			<input name="value" class="form-control" type="number" />
			<label><input name="nds" type="checkbox"  checked="checked"/> установленная цена включает ндс</label>
			<% if (hasBoma) {%><label><input name="boma" type="checkbox" checked="checked"/> установленная цена включает коридорный коэффициент</label><% } %>
		</p>
		<hr>
		<p>новая цена метра: <big><span class="new-meter-price">&ndash;</span></big> р. <%= ndsMeterPostfix %></p>
		<p>новая цена площади: <big><span class="new-square-price">&ndash;</span></big> р. <%= ndsSquarePostfix %></p>
	`,
	ui: {
		value: 'input[name=value]',
		nds: 'input[name=nds]',
		boma: 'input[name=boma]',
		newmeter: '.new-meter-price',
		newsquare: '.new-square-price'
	},
	events: {
		'input input'() {
			let value = parseFloat(this.ui.value.get(0).value, 10);
			const nds = this.ui.nds.is(':checked');
			const boma = this.ui.boma.is(':checked');
			this.update(value, nds, boma);
		}
	},
	// getBaseValue(getType, value, withNds, withBoma) {
	// 	const options = valueToOptions(this.value, withNds, withBoma);
		
	// 	calculateBase()
	// },
	update(value, nds, boma) {

		let meter = '&ndash;';
		let square = '&ndash;';
		
		let basePriceMeter;

		if (!isNaN(value)) {
			// const basevalue = this.getBaseValue(value, nds, boma);

			const numMeter = this.getFullMeterValue(value, nds, boma);
			
			meter = displayNum(numMeter, 2);
			const numSquare = this.getFullSquareValue(value, nds, boma);
			square = displayNum(numSquare, 2);

			basePriceMeter = this.getBaseMeterFromFullMeter(numMeter);
			
		} else {
			value = undefined;
		}
		
		this.newvalue = basePriceMeter;
		this.triggerMethod('value:changed', { priceMeter: basePriceMeter });
		console.warn('#', value, meter, square);

		this.ui.newmeter.html(meter);
		this.ui.newsquare.html(square);


	},
	templateContext() {
		let ndsSquarePostfixes = [];
		
		const rent = this.value.operation === 'rent';
		const nds = !!this.value.nds;
		const includes = [];
		if (rent) {
			ndsSquarePostfixes.push('в месяц');
		}

		if (nds) {
			includes.push('ндс');
		}

		if (rent && this.value.boma > 1) {
			includes.push('коридорный коэф.');
		}

		if (includes.length) {
			let word = includes.pop();
			if (includes.length) {
				word = includes.join(', ') + ' и ' + word;
			}
			word = 'включая ' + word;
			ndsSquarePostfixes.push(word);
		}
		
		const fullmeter = this.getFullMeterValue();
		const fullsquare = this.getFullSquareValue();

		return {
			rent,
			hasBoma: this.valueType !== 'meter' && rent && this.value.boma > 1,
			boma: this.value.boma || 'отсутствует',
			subjectLabela: this.valueType !== 'meter' ? 'площади' : 'метра',
			operation: rent ? 'аренда' : 'продажа',
			taxType: nds ? 'НДС' : 'УСН',
			square: this.value.square,
			ndsMeterPostfix: nds ? 'включая ндс' : '',
			ndsSquarePostfix: ndsSquarePostfixes.join(', '),
			fullmeter: displayNum(fullmeter,2),
			fullsquare: displayNum(fullsquare,2)
		}
		
	},

	_getBaseValue(getType, inputValue, options) {
		return calculateBase(getType, this.valueType, inputValue, options);
	},

	_getBaseMeterValue(inputValue, options) {
		return this._getBaseValue('meter', inputValue, options);
	},

	_getBaseSquareValue(inputValue, options) {
		return this._getBaseValue('square', inputValue, options);
	},

	getFullMeterValue(value, withNds, withBoma) {
		
		const options = valueToOptions(this.value, withNds, withBoma);
		const baseMeterPrice = this._getBaseMeterValue(value, options);
		const { bomaKoef, ndsKoef } = options;
		// не ясно, нужно ли добавлять бома к стоимости метра
		return baseMeterPrice * ndsKoef;
	},

	getFullSquareValue(value, withNds, withBoma) {
		const options = valueToOptions(this.value, withNds, withBoma);
		const baseSquarePrice = this._getBaseSquareValue(value, options);
		const { bomaKoef, ndsKoef } = options;
		return baseSquarePrice * bomaKoef * ndsKoef;
	},
	getBaseMeterFromFullMeter(fullMeter) {
		const options = valueToOptions(this.value);
		let { ndsKoef } = options;
		return fullMeter / ndsKoef;
	},
	initialize() {
		this.initializePriceControl();
	},
	initializePriceControl() {
		this.valueType = this.controlValueType;
		this.value = this.getOption('value') || {};
	},
	getNewValue() {
		return this.newvalue;
	}
});
