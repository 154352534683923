﻿//define('modals-engine',['modals-config'],function(cfg){});

import { $ } from 'vendors';
import cfg from './modals-config';

var modals = [];
import { MnObject } from 'vendors';	

var Modals = MnObject.extend({
	channelName: cfg.radio.channelName,
	radioEvents: {
		'opened': 'add',
		'closed': 'remove',
	},
	onArrayChanged: function () {
		if (modals.length == 0)
			$('body').removeClass('modals-opened');
		else
			$('body').addClass('modals-opened');
	},
	add: function (modal) {
		modals.push(modal);
		this.triggerMethod('array:changed');
	},
	remove: function (modal) {

		if (modals.length == 0 || !modal) return;
		modals.remove(modal);
		this.triggerMethod('array:changed');

	},
	onRemoveTop: function () {
		if (modals.length == 0) return;
		var modal = modals[modals.length - 1];
		modal.destroy();
	},
	clearModals: function () {
		var ms = [].slice.call(modals);
		_(ms).each(function (m) {
			m.destroy();				
		});
	}
});

var engine = new Modals();


//keybord ESC handler
$(function () {

	$(window).on('keyup', function (e) {


		if (e.keyCode != 27 || modals.length == 0)
			return;


		engine.triggerMethod('remove:top');

	});

})

export default engine;
