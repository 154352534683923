import { define, find } from './schemaStore';

export const modelSchemaApi = {
    getPropertySchema(key, modelSchema) {
        if (!modelSchema) { return; }
        let property = modelSchema[key];
        if (property && !property.property) {
            property.property = key;
        }
        return property;
    },
		getModelSchema(arg) {
			return find(arg);
		},
		define(arg, schema) {
			schema = Object.keys(schema).reduce((newschema, property) => {
				newschema[property] = Object.assign({}, schema[property], { property });
				return newschema;
			}, {});
	
			schema = define(arg, schema);
			
			return schema;
		}
}