module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div '+
((__t=( wrapperClass ))==null?'':__t)+
'>\n	<div class="ui-phone-country" data-placeholder="страна"><input data-name="country" type="number" value="'+
((__t=( country))==null?'':__t)+
'" class="form-control" min="1" max="2" /></div>\n	<div class="ui-phone-region" data-placeholder="регион"><input data-name="region" type="number" value="'+
((__t=( region))==null?'':__t)+
'" class="form-control" min="2" max="4" /></div>\n	<div class="ui-phone-number" data-placeholder="номер"><input data-name="number" type="text"  value="'+
((__t=( number))==null?'':__t)+
'" class="form-control" min="3" max="9" /></div>\n	';
if(withAdd){
__p+='<div class="ui-phone-add" data-placeholder="добавочный"><input data-name="add" type="text" value="'+
((__t=( add))==null?'':__t)+
'" class="form-control"  /></div>';
}
__p+='\n</div>\n\n\n';
}
return __p;
};
