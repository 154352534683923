import { ABCompare } from 'utils';

export function imageViewComparator(a,b) {
    return ABCompare(a, b, [ 
        v => v.model.get('isPrimary') === true ? -1 : 1,
        { reverse: true, get: v => v.model.get('order') }
    ]);
}
export function imageModelComparator(a,b) {
    return ABCompare(a, b, [ 
        m => m.get('isPrimary') === true ? -1 : 1,
        { reverse: true, get: m => m.get('order') }
    ]);
}