import { UiMoleculeView } from "../../../../../coms/ui/UiMoleculeView";
import { UiAtomView } from "../../../../../coms/ui/UiAtomView";
// import beh from 'behaviors';
import { offerPopupMixin } from "./offer-popup-mixin";
// import { schema } from "../../../../../mods/realties/realties/schema";
import { offerSchema } from "mods/realties/offers";
import { propertySchemaApi } from "../../../../../apis/schema";
import { HamburgerView } from "../../../../../coms/ui/HamburgerView";
import { ButtonView } from "../../../../../coms/ui/Button";

import { View } from 'core';
import { displaySchemaViewMixin, UiSchemaAtomView, UiSchemaMoleculeView } from "../../../../../coms/schema";
import OfferCardModalView from "./cardInModal";
import { showFullscreenModal } from '_libs/modals';
import { showOfferInModal } from "../utils";


// const displaySchemaMixin = {
// 	getModelSchema() {
// 		return this.getOption('modelSchema', true);
// 	},
// 	display(property) {
// 		const modelSchema = this.getModelSchema();
// 		// console.log(property, this.model.attributes);
// 		return propertySchemaApi.displayValue(property, this.model, modelSchema);
// 	},
// 	value(property) {
// 		const modelSchema = this.getModelSchema();
// 		// console.log(property, this.model.attributes);
// 		return propertySchemaApi.getValue(property, this.model, modelSchema);
// 	}
// }

const TextView = View.extend({
	template: '<%= text %>',
	templateContext() {
		const text = this.getOption('text', true);
		return { text };
	},
	... displaySchemaViewMixin
});

// const UiSchemaAtomView = UiAtomView.extend({
// 	getModelSchema() {
// 		return this.getOption('modelSchema');
// 	},
// 	display(property) {
// 		const modelSchema = this.getModelSchema();
// 		// console.log(property, this.model.attributes);
// 		return propertySchemaApi.displayValue(property, this.model, modelSchema);
// 	}
// });

const AreaView = UiSchemaAtomView.extend({
	thisClassName: 'area',
	showTop: false,
	showBottom: false,
	// topText: v => v.display('obj.info.purposes'),
	// bottomText: v => v.display('operationMarket'),
	text: v => v.display('square'),
	showSmall: true,
	smallText: v => v.display('floor'),
});

const PriceView = UiSchemaAtomView.extend({
	thisClassName: 'price',

	showTop: false,
	showBottom: false,
	showSmall: true,
	ifRent(ifYes, ifNo = '') {
		return this.model.get('operation') === 'rent' ? ifYes : ifNo;
	},

	text: v => v.display('priceMeterPostfix'),
	smallText: v => v.display('priceSquarePostfix'),
	// bottomText: v => v.display('priceDetailsWords'),
	zzz: v => {
		const atrs = v.model.attributes;
		const fc = atrs.forCustomer || {};
		const words = [];
		if ((fc.taxType || '').indexOf('nds') > -1) {
			words.push('НДС')
		}
		if (atrs.operation === 'rent' && fc.boma > 1) {
			words.push('КОР. КОЭФ.')
		}
		return words.join(', ');
	}
});

const BuildingView = UiSchemaAtomView.extend({
	thisClassName: 'building',
	showBottom: false,
	showTop: false,
	showSmall: true,
	// topText: v => v.display('realtyPrefix'),
	text: v => v.display('realtyName'),
	smallText: v => v.display('metroOrAddress'),
	// bottomText: v => v.display('realtyClass')
});



const AgentView = UiSchemaAtomView.extend({
	thisClassName: 'agent',
	showTop: false,
	showBottom: false,
	showSmall: true,
	text: v => v.display('agentPercent') || '',
	// text: v => v.display('forAgent.income') + '%',
	smallText: v => v.display('agentIncomPostfix')
});


const mapHoverMixin = {
	events: {
		'mouseenter': function (e) {
			e.stopPropagation();
			if (this._hover) return;
			this._hover = true;
			this.triggerMethod('hover', this);
			var _this = this;
			this.once('unhover', function () {
				_this._hover = false;
			});
		},
		'mouseleave': function (e) {
			e.stopPropagation();
			if (!this._hover) return;
			this.triggerMethod('unhover', this);
		},
	},
	onHover: function () {
		
		// let address = this.model.getAddress();
		// if (!address) return;

		this._hoverTimeout = setTimeout(() => {
			this.triggerMethod('offer:hover', this.model);
			// this.trigger('address:hover', address);
		}, 1000);

	},
	onUnhover: function () {
		// var address = this.model.getAddress();
		// if (!address) return;
		clearTimeout(this._hoverTimeout);
		this.triggerMethod('offer:unhover', this.model);
		//this.trigger('address:unhover', address);
	},
}

const ButtonsView = HamburgerView.extend({
	thisClassName: 'actions',
	childrenViews:[
		{ class: ButtonView, buttonName: 'card', thisClassName: 'card', text: '<i></i>' },
		{ class: ButtonView, buttonName: 'select', thisClassName: 'select', text: '<i></i>' },
	],
	childViewTriggers:{
		'card:click':'card:click',
		'select:click':'select:click'
	}
})

const smallBadge = (i, add) => `<span class="small-badge${add ? ' ' + add : ''}">${i}</span>`;

function addBadgeClass(i) {
	if (i === 'НДС') {
		return 'nds';
	} else if (i === 'КОР. КОЭФ.') {
		return 'boma';
	}
}

export const OfferInListView = UiSchemaMoleculeView.extend({
	// behaviors: [
	// 	beh.SelectedForActions.ChildView.extend({ triggerSelector: '> button.select' }), 
	// 	beh.ModelActionsInPopup
	// ],
	stateClassNames:['selected','joined'],
	thisClassName: 'offer-list-item2',
	classNames: [
		v => v.modelValue('operation'),
		v => v.modelValue('forAgent.contractType'),
		v => 'class-' + v.modelValue('realtyClassEng')
	],
	// rightButtons: ['button:select'],
	// cardButton: true,
	// optionsButton: true,
	content:AreaView,
	leftItems:[
		ButtonsView,
		{
			class: TextView,
			thisClassName: 'system offer-additional-info',
			text: v => [v.display('operation'), v.display('obj.info.purposes'), ...v.modelValue('priceDetailsWords')]
				.filter(f => !!f)
				.map(i => smallBadge(i, addBadgeClass(i)))
				.join(''),
		}
	],
	rightItems: [
		PriceView, 
		AgentView,
		v => v.getOption('hideBuildingLine', true) ? undefined : BuildingView, 
		// AddressView, 
	],
	modelSchema: () => offerSchema.getOption('modelSchema'),
	childViewOptions() {
		return {
			modelSchema: this.getOption('modelSchema', true)
		}
	},

	// ...displaySchemaMixin,
	...mapHoverMixin,
	...offerPopupMixin,

	initialize() {
		this.listenTo(this.model, 'selected:state:changed', value => this.state('selected', value));
		this.initializePopupMixin();
		this.once('before:render', () => {
			const holder = this.getOption('selectionHolder');
			let selected;
			if (holder) {
				selected = holder.isSelected(this.model);
			} else {
				selected = this.getOption('isSelected', true);
			}
			// const selected = holder?.isSelected(this.model);
			this.state('selected', selected);

			const isJoined = this.getOption('isJoined', true);
			if (isJoined) {
				this.state('joined', isJoined);
			}
			// this.updateElClassName();
		});
		// console.warn('-sel-', selected, holder.collection.models);
	},

	onChildviewSelectClick(v,e) {
		
		this.triggerMethod('selectable:toggle', this, true, e.shiftKey);
		// this.triggerMethod('after:selectable:toggle',this.model.id, this.model.get('realtyId'));
	},

	onChildviewCardClick(v,e) {
		this.showInModal()
		//this.model.actionOpenModal();
	},
	showInModal() {
		showOfferInModal(this.model);
		// const modalOptions = {
		// 	View: OfferCardModalView,
		// 	cssClasses: { dialog: 'offer-modal-dialog' },
		// 	noFetch: true,
		// 	model: this.model,
		// };
		// showFullscreenModal(modalOptions);
	}

	// childViewEvents: {
	// 	'select:click'() {
	// 		console.log('-bam-');
	// 	},
	// 	'card:click'() {
	// 		console.log('-bam-pam-');
	// 	}
	// }


});