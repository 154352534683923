// define('svc/actualization/ownerInRealty/filters-view', [
// 	'svc/actualization/ownerInRealty/filters',
// 	'behaviors',
// 	'references'
// ], 
// function(
// 	filters,
// 	beh,
// 	refs
// ){});

import filters from './filters';
import beh from 'behaviors';
import refs from 'references';

import { BbeModel, BbeCollection } from 'core/bbe';

import { MneView, MneNextCollectionView } from 'core/mne';


const EnumModel = BbeModel.extend({
	getEnum() {
		if (this._enum) {
			return this._enum;
		}
		let arg = this.get('enum');
		if (arg && _.isObject(arg)) return arg;
		let enumObject = Object.assign({}, refs.enum(arg));
		let none = this.get('none');
		if (none) {
			enumObject.none = none.label;
		}
		this._enum = enumObject;
		return this._enum;
	},
	getDisplay(value) {
		let en = this.getEnum();
		if (en.none && value == null) {
			value = 'none';
		}
		return refs.enum(en, value)
	}
});

// const EnumItemView = MneView.extend({
// 	tagName: 'button',
// 	className: 'enum-item',
// 	template: _.template('<%= label %>'),
// 	behaviors:[ beh.DynamicClass ],
// 	initialize() {
// 		this.listenTo(filters, 'change', this.render);
// 	},
// 	dynamicClass: function() {
// 		let active = this.isActive() ? 'active' : '';
//         return active;
// 	},
// 	isActive() {
// 		let key = this.model.collection.parent.id;
// 		let tValue = this.model.get('value');
// 		let fValue = filters.get(key);
// 		if (tValue == null && fValue == null) {
// 			return true;
// 		}
// 		return tValue == fValue;
// 	},
// 	events: {
// 		'click'() {
// 			let key = this.model.collection.parent.id;
// 			let val = this.model.get('value');
// 			filters.set(key, val);
// 		}
// 	}
// });

// const EnumView = MneNextCollectionView.extend({

// 	className: 'enum-collection',
// 	initialize() {
// 		// let src = this.model.get('sourceValues');
// 		// let items = _.map(src, (label, id) => ({ id, label, value: id }));
// 		// let fixNone = !!this.model.get('none');
// 		// if (this.model.get('addNone')) {
// 		// 	items.push(this.model.get('none'))
// 		// 	fixNone = false;
// 		// }
// 		// this.collection = new BbeCollection(items);
// 		// this.collection.parent = this.model;
// 		// if (fixNone) {
// 		// 	let none = this.collection.get('none');
// 		// 	none.set(this.model.get('none'));
// 		// }
// 	},
// 	childView: EnumItemView,
// 	viewComparator(v1,v2) {
// 		if (v1.model.id == 'none') {
// 			return 1;
// 		} else if (v2.model.id == 'none') {
// 			return -1;
// 		} else {
// 			return 0;
// 		}
// 	}
// });

const DropdownEnumItemView = MneView.extend({
	tagName: 'li',
	behaviors:[ beh.DynamicClass ],
	template: _.template('<a href="#"><i class="is-active"></i><%= label %></a>'),
	initialize(opts) {
		this.mergeOptions(opts, ['filters', 'parent']);
		this.listenTo(this.filters, 'change:' + this.parent.id, this.onFilterChange);
	},
	onFilterChange() {
		this.triggerMethod('refresh:css:class');
	},
	dynamicClass: function() {
		let active = this.isActive() ? 'active' : '';
		return active;
	},		
	isActive() {
		let key = this.parent.id;
		let tValue = this.model.get('value');
		let fValue = this.filters.get(key);
		if (tValue == null && fValue == null) {
			return true;
		}
		return tValue == fValue;
	},
	events: {
		'click'() {
			let key = this.parent.id;
			let val = this.model.get('value');
			this.filters.set(key, val);
		}
	}		
})

const DropdownEnumView = MneNextCollectionView.extend({
	className: 'dropdown-menu',
	tagName: 'ul',
	initialize(opts) {
		this.mergeOptions(opts, ["filters"]);
		let items = _.map(this.model.getEnum(), (label, id) => ({ id, label, value: id}));
		this.collection = new BbeCollection(items);
		let none = this.model.get('none');
		if (none) {
			let enone = this.collection.get('none');
			if (!enone) {
				enone = this.collection.add({ id: 'none' });
			}
			enone.set(none);
		}
		
	},

	childViewOptions() {
		return {
			parent: this.model,
			filters: this.filters
		}
	},
	childView: DropdownEnumItemView
})

const EnumButtonView = MneView.extend({
	className: 'btn-group',
	template: _.template(`
	<button type="button" class="btn btn-default dropdown-toggle labeled" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
		<label><%= label %>: </label>
		<span><span class="display-value"><%= display %></span> <span class="caret"></span></span>
	</button>
	<ul class="dropdown-menu"></ul>
	`),
	initialize(opts) {
		this.mergeOptions(opts, ["filters"]);
		this.listenTo(this.filters, 'change:' + this.model.id, this.onFilterChange);
	},
	onFilterChange() {
		this.ui.display.html(this.getLabel());
	},
	ui: {
		display: '.display-value'
	},
	regions: {
		dropdown: '.dropdown-menu'
	},
	onRender() {
		let view = new DropdownEnumView({ model: this.model, filters: this.filters });
		this.showChildView('dropdown', view, { replaceElement: true });
	},
	getLabel() {
		return this.model.getDisplay(this.filters.get(this.model.id));
	},
	templateContext() {
		let display = this.getLabel();
		return {
			display
		}
	},		
});

const FiltersView = MneNextCollectionView.extend({
	className: 'filters',
	initialize() {
		this.model = filters;
		let models = _.map(this.model.config(), model => model);
		this.collection = new BbeCollection(models, { model: EnumModel })
	},
	childView: EnumButtonView,
	childViewOptions() {
		return {
			filters: this.model
		}
	}
});



export default FiltersView;
