import { SmartPropertiesLinesView } from 'coms/ui/PropertiesLineView';
import { claimsApi } from 'apis/claims';
import { modelSchema } from '../schema';
import { fastEditAction } from '../../fast-edit-action';

const commonProperties = [
    // 'name',
    ['info.floor', 'info.roomNumber', 'info.squareOffer'],
    ['status', 'info.state'],
    'info.currentPurpose',
    'info.purposes',
    ['info.squareOffer', 'info.squareMin'],
    ['info.floor', 'info.floorsCount'],
    ['info.floorsHeight', 'info.roomNumber'],
    ['info.floorsHeightWork'],
    'info.power',
		'info.features.wholeBuilding',
    
];



const officeProperties = [

    ...commonProperties,

    
    ['info.spaceLayout', 'info.roomsCount'],
    'info.features.windows',
    'info.features.legalAddress',
    'info.features.ownEntrance',
    'info.features.personalLift',
    'info.waterPoint',
    'note',
    'privateNote',
];

const warehouseProperties = [

    ...commonProperties,

    'warehouse.features',
    'warehouse.floorMaterial',
    'warehouse.freezeEquipment',
    'warehouse.gatesCount',
    'warehouse.heating',
    'warehouse.palletPlaces',
    'warehouse.unloadingEquipment',
    'warehouse.unloadingFeatures',
    'warehouse.note',
    'warehouse.privateNote',
    
]

const lesseeProperties = [
    'lessee.companyName',
    'lessee.companyDescription',
    'lessee.incomeInMonth',
    'lessee.longDuration',
    'lessee.occupied',
    'lessee.occupiedAt',
    'lessee.releaseAt',
    'lessee.note',
    'lessee.privateNote',
]

export function getProperties(arg) {
    //console.log('-get-props-', arg);
    switch(arg) {
        case 'lessee':
            return lesseeProperties;
        case 'warehouse':
            return warehouseProperties;
        default: 
            return officeProperties;
    }
}

export const RealtyObjectPropertiesView = SmartPropertiesLinesView.extend({
    thisClassName: 'with-borders  colored-values',
    modelSchema: modelSchema,
    editEnabled: () => claimsApi.canEditRealtiesInCard(),
    editAction: fastEditAction,
    properties() {
        let props = getProperties(this.getOption('propertiesSetName', true));
        //console.log('-', props);
        return props;
    },
    //showValueless: false
});