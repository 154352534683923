import MediaPlates from './media-plates';
import MediaInfo from './media-info';
import UploadMedias from './upload-medias';
import { MneView } from 'core/mne';
import './edit-medias.less';

const empty = {};

function typefilter(v, compare)
{
    let type = (((v.model || empty).attributes || empty).media || empty).type;
    return compare(type);
}
function ImageFilter(v) {
    return typefilter(v, t => t == 'image');
}
function FileFilter(v) {
    return typefilter(v, t => t != 'image');
}


export const MediaLayout = MneView.extend({
    className: 'edit-medias-container',
    template: _.template('<div class="info-container"></div><div class="filters-container"></div><div class="medias-container"></div>'),
    regions: {
        info: '.info-container',
        filters: '.filters-container',
        medias: '.medias-container',
    },
    onRender() {
        this.showInfo();
        // this.showFilters();
        // this.showMedias();
        console.log('WTF???');
        this.renderCounter = this.renderCounter || 0;
        this.renderCounter++;
    },
    childViewOptions(add) {
        add = add || {};
        return {
            model: this.model,
            ...add
        }
    },
    showInfo(){
        let view = new MediaInfo();
        this.tabsView = view;
        this.showChildView('info', view);
    },
    showFilters(){

    },
    showMedias(options){
        console.log('## showing medias!!', this.renderCounter);
        let view = new MediaPlates(this.childViewOptions(options));
        if (!this.renderCounter && !view.collection.length) {
            this.tabsView.activate('upload');
            //this.triggerMethod('show:tab:upload');
            return;
        }
        this.showChildView('medias', view, { replaceElement: true });
    },
    showUploadMedias(){
        let view = new UploadMedias(this.childViewOptions());
        this.showChildView('medias', view, { replaceElement: true });
    },
    childViewEvents: {
        'show:tab'(tabId, tab) {
            this.triggerMethod('show:tab:' + tabId, tab);
        }
    },
    onShowTabImages() {
        this.showFilters();
        this.showMedias({ viewFilter: ImageFilter });
    },
    onShowTabFiles() {
        this.showFilters();
        this.showMedias({ viewFilter: FileFilter });
    },
    onShowTabUpload() {
        this.showFilters();
        this.showUploadMedias();
    },
});