import refs from 'references';
import { required } from './common';
import { _ } from 'vendors';
import { createPriceOptions, getFullPriceMeterDisplay, getFullPriceSquareDisplay } from '../../../../utils/price';

export const offerProperties = () => ({
	operation: {
		caption: 'операция',
		emptyText: 'не выбрана',
		//display: v => v ? refs.enum('realtyOperations',v) : undefined,
		sourceValues: refs.enum('realtyOperations'),
		modelType: 'single',
		valueType: 'enum',
		//flattenValues: true,
		multiple: false,
		validate: required,			
	},
	market: {
		caption: 'рынок',
		emptyText: 'не выбран',
		sourceValues: refs.enum('realtyMarkets'),
		//display: v => v ? refs.enum('realtyMarkets',v) : undefined,
		modelType: 'single',
		valueType: 'enum',
		//flattenValues: true,
		multiple: false,
		validate: required,
	},
	contractType: {
		caption: 'тип договора',
		sourceValues: refs.enum('contractTypes'),
		//display: v => v ? refs.enum('contractTypes',v) : undefined,
		modelType: 'single',
		valueType: 'enum',
		//flattenValues: true,
		multiple: false,
		//validate: reuired,
	},
	taxType: {
		caption: 'налогообложение',
		emptyText: 'отсутствует',
		sourceValues: refs.enum('taxTypes'),
		//display: v => v ? refs.enum('taxTypes',v) : undefined,
		modelType: 'single',
		valueType: 'enum',
		flattenValues: true,
		multiple: false,
	},
	incom: {
		caption: 'процент коммиссии',
		modelType: 'single',
		valueType: 'number',
		display: v => v != null ? v + '%' : '',
		validate: required,
	},
	'boma': {
		caption: 'коридорный коэффициент (boma, число от 1 до 2)',
		modelType: 'single',
		valueType: 'number',
		display: v => {
			if (v > 1) {
				return v;
			} else {
				return 'нет';
			}
		},
		isEmpty: v => !(v > 1),
		emptyText: 'нет',
		_value: 1,
		// validate: required,
	},
	priceMeter: {
		caption: 'стоимость',
		// controlType: 'editPriceMeter',
		// controlType: 'editOfferPriceMeter',
		controlType: 'editOfferPriceBoth',
		modelType: 'single',
		clearOnChange: ['operation', 'square'],
		refreshOnChange: ['boma', 'taxType'],
		editAvailableError: all => {
			if (all.operation != null && all.square > 0) {
				return;
			}
			return 'Укажите операцию, площадь и налогообложение';
		},
		controlOptions(hash) {
			let { boma, operation, square, price, taxType } = hash;
			const opts = {
				multipleOffers: false,
				models: [
					{
						object: {
							info: {
								squareOffer: square,
							}
						},
						offer: {
							operation,
							forCustomer: {
								boma,
								priceMeter: price,
								taxType
							}
						}
					}
				]
			}
			console.warn('#', opts);
			return opts;
		},
		display(v, prop) {
			if (v == null) return '';
			if (v === 0) { return v; }
			
			
			const all = prop.getAllValues();
			const { square, boma, operation, taxType } = all;
			const priceOptions = createPriceOptions(v, square,operation, taxType, boma);
			
			const meterPrice = getFullPriceMeterDisplay(priceOptions, true);
			const squarePrice = getFullPriceSquareDisplay(priceOptions, true);
			return squarePrice + ' (' + meterPrice + ')';
		},
		validate: required,
		//valueType: 'number',
	},


})