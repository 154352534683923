﻿

import Module from 'appModule';
import cfg from 'app-config';
import link from 'link';
//import gmaps from '_libs//gmaps';
import { mapsApi } from 'apis/mapsApi';
// import geoModel from 'vXX/geo/reference/model';
// import mapPoints from 'vXX/geo/reference/mapPoints';
import Layout from './layout';
import factory from 'm/factory';

import './googlemaps-fix.less';
import './search-on-map.less';
import './side-bar.less';

// import { geoRefs } from '../../../geo/reference/model';
// import { allbuildings } from '../../../m/realties/buildings';
// import { ensureBuildingsPromise } from '../../../m/realties/buildings/buildings';
import { geoReference } from 'mods/geo';
// import { ensureRealtiesPromise } from 'mods/realties';

var module = Module.content({
	name: 'realties-searchOnMap',
	label: 'Подбор по карте',
	urlKey:'realtiesSearchMap',
	routeData: function() {
		//return [link.route('realtiesSearchMap'), link.route('realtiesSearchMapDeal')];
		//return link.route('realtiesSearchMap');
		return cfg.appSvcRoute('rlt/searchmap(/:dealWebId)')
	},
	runPromises: function() {
		const mapsApiPromise = mapsApi.initialize();
		console.warn('	map promise', mapsApiPromise);
		return [
			// gmaps.initialize, 
			// ensureRealtiesPromise(),
			// ensureBuildingsPromise(),
			geoReference.refetch(),
			mapsApiPromise,
			// () => geoModel.populate(), () => mapPoints.populate()
		];

	},
	onBeforeRun: function(svcId, dealWebId) {
		
		//if (dealWebId) {
		//	var deal = store.get(store.KEYS.currentDeal);
		//	this.addRunArgument(deal);
		//}

		//if (!geoModel.fetched)
		//	geoModel.refresh();

		//this.addRunPromise(geoModel.populate());
		
		//this.addRunPromise(ensureBuildingsPromise());

		if (dealWebId) {
			var deal = factory.model('JsonDeal', dealWebId);
			if (!deal.isFetched()) {
				deal.loudFetch();
				this.addRunPromise(deal.getApiPromise());
			}
			this.addRunArgument(deal);
		}

	},
	onRun: function (deal) {

		//this.showButtonsLayout();

		var label = deal ? 'Подбор к процессу ' + deal.get('name') : 'Подбор по карте';
		var backUrl = deal ? deal.cardUrl() : undefined;
		var navLinks = deal ? [{ label: 'подбор по списку', href: link.url('realtiesSearch:' + deal.get('wId')) }, { label: 'вернуться в процесс', href: link.url('dealCard:' + deal.get('wId')) }] : undefined;
		//var result = 
		this.createAndShow(Layout, { header: label, backUrl: backUrl }, { deal: deal, navLinks: navLinks });


	},
	destroyLayoutOnStop: true,
	onStop() {
		console.error('	+ MODULE STOP +', this);
		console.log(this.layout);
	}
});

export default module;
