import { _ } from 'vendors';
// import { SchemaView } from "../../SchemaView";
import { Collection, Model } from 'core';
import { EntriesView } from "./EntriesView";
//import { enumsModel } from "mods/app-data/enums";
import { isCollection, modelContainsText } from 'utils';
import { Selector } from "./Selector";
import { SearchView } from "./SearchView";
import { SegmentsCollection, SegmentsView } from './SegmentsView';
import './select-control.less';
import { HamburgerView } from 'coms/ui/HamburgerView';
import { controlValueMixin, getBooleanValue, SchemaView } from '../common';
import refs from 'references';
// const SelectModel = Model.extend({
// 	filter(view) {

// 	}
// });
// const SchemaView = HamburgerView.extend({
// 	getValueSchema() {
// 		return this.getOption('valueSchema') || {};
// 	},
// 	getSchemaProperty(key) {
// 		const schema = this.getValueSchema();
// 		return schema[key];
// 	}
// });

export const Select = SchemaView.extend({
	...controlValueMixin,
	baseClassName: 'select-control',
	stateClassNames: ['multiple', 'single', 'single-line'],
	passDownModels: true,
	childViewOptionsKeys: ['selector', 'schemaModel'],
	mergeOptionsKeys: ['valueMapper', 'valuesMapper'],
	initialize(options) {
		
		this.initializeControl();

		const multiple = this.getSchemaProperty('multiple');
		this.multiple = !!multiple;
		this.state('multiple', multiple ? 'multiple' : 'single');

		this.initializeValue();
		this.schemaModel = this.model;
		this.model = new Model();
		this.initializeCollection();
		this.initializeSelector();
		


	},

	initializeCollection() {
		if (this.collection) { return; }

		const sourceValues = this.getSourceValues();

		if (isCollection(sourceValues)) {
			this.collection = sourceValues;
			return;
		}

		let models;
		if (Array.isArray(sourceValues)) {
			models = sourceValues;
			// this.collection = new Collection(sourceValues);
			// return;
		} else {
			models = _.map(sourceValues, (label, value) => ({ id: value, value, label }));
		}


		this.collection = new Collection(models);

	},

	initializeSelector() {
		const schema = this.getValueSchema();
		const { multiple, required, nullAllowed } = schema;
		const singleToggleAllowed = !multiple && !required && !!nullAllowed;
		const { valueMapper, valuesMapper } = this;
		this.selector = new Selector({ multiple, singleToggleAllowed, valueMapper, valuesMapper });
		this.selector.on('all', c => console.log('selector -', c));
		const notfound = this.selector.initializeValues(this.initialValues, this.collection);

		this.listenTo(this.selector, 'change', () => {
			const values = this.selector.get();
			const done = !multiple;
			console.warn('---', values);
			this.setValue(values, done);
			// this.triggerMethod('user:input', values, done);
		});

	},

	initializeValue() {
		
		const schema = this.getValueSchema();
		const { valueType } = schema;
		this.value = this.getOption('value', true);
		this.initialValue = this.value;

		let transformInitialValue;
		if (valueType === 'enum') {
			if (!this.valuesMapper) {
				this.valuesMapper = models => models.map(m => m.get('value')).join(', ');
			}
			transformInitialValue = value => value == null || value === '' ? [] : value.split(/\s*,\s*/);
		} else if (valueType === 'boolean') {
			const multiple = this.multiple === true;
			this.state('single-line', true);
			this.valuesMapper = models => {	
				const vals = models.map(model => getBooleanValue(model.get('value')));
				if (!multiple) { return vals[0]; }
				return vals;
			}
		}
		let initialValues = this.getInitialValue();
		if (initialValues == null || initialValues === '') {
			initialValues = [];
		} else if (transformInitialValue) {
			initialValues = transformInitialValue(initialValues);
		} else if (!Array.isArray(initialValues)) {
			initialValues = [initialValues]
		}
		this.initialValues = initialValues;
		this.value = initialValues;
		this.updateValidateStatus(this.value);
	},



	getSourceValues() {
		const defined = this.getSchemaProperty('sourceValues');
		if (defined) {
			return defined;
		}

		const valueSchema = this.getValueSchema();
		const { valueType, valueSubType, required, multiple } = valueSchema;
		if (valueType === 'enum') {
			return this._getEnumSourceValue(valueSubType, multiple, required);			
		}

		if (valueType === 'boolean') {
			if (valueSubType) {
				return this._getEnumSourceValue(valueSubType, multiple, required);
			}
			const hash = {};
			if (required === false) {
				hash.none = 'Значение не установлено';
			}
			hash.true = 'Да';
			hash.false = 'Нет';
			return hash;
		}

		throw new Error('sourceValues was not provided');

	},

	_getEnumSourceValue(valueSubType, multiple, required) {
		const hash = Object.assign({}, refs.enum(valueSubType));
		if (multiple || hash.none === 'none' || required) {
			delete hash.none;
		}
		if (hash.none === '') {
			hash.none = 'Значение не установлено'
		}
		return hash;
	},

	getChildren() {

		const segments = this.getSegmentsView();
		const filters = this.getFiltersView();
		const entries = this.getEntriesView();
		return [
			segments,
			filters,
			entries
		]
	},

	getSegmentsView() {
		let segments = this.getOption('segments');
		if (!segments) {
			return;
		}
		if (segments === true) {
			segments = [];
		} else if (!Array.isArray(segments)) {
			return;
		} else {
			segments = [...segments];
		}

		if (this.multiple) {
			segments.push({ 
				name: 'Выбранное',
				badgeValue: v => this.selector.items.length,
				filter: (model, selector) => {
					return selector.has(model)
				},
				onSetup: v => {
					console.log('- on setup -', v);
					v.listenTo(this.selector, 'change', () => {
						console.log('- on setup on render -', this.selector.items.length);
						v.render()
					});
				}
			});
		}
		if (segments.length) {
			segments.unshift({ name: 'Все' })
		} else {
			return;
		}
		
		
		const collection = new SegmentsCollection(segments);
		
		this.model.set('segment', collection.active.toJSON());
		return { class: SegmentsView, segmentsCollection: collection }
	},

	getFiltersView() {
		if (!this.getOption('searchEnabled')) {
			return;
		}

		return SearchView;

	},

	getEntriesViewFilter() {
		const model = this.model;
		const selector = this.selector;

		const valueSchema = this.getOption('valueSchema');
		const shemaValuesFilter = valueSchema.valuesFilter;

		let textFilter;
		const searchEnabled = this.getOption('searchEnabled', true);
		if (searchEnabled) {
			textFilter = this.getOption('textFilter', false);
			if (!textFilter) {
				textFilter = (v, text) => modelContainsText(v.model, text);
			}
		}

		const segmentFilter = this.getOption('segmentFilter', false);

		if (shemaValuesFilter || textFilter || segmentFilter) {

			return function(v) {
				if (shemaValuesFilter && !shemaValuesFilter(v.model, schemaModel)) {
					return false;
				}
				if (textFilter && !textFilter(v, model.get('search'), selector)) {
					return false;
				}
				if (segmentFilter && !segmentFilter(v, model.get('segment'), selector)) {
					return false;
				}
				return true;
			}

		}


	},
	getEntriesView() {


		return this.getOption('entriesView') || { 
			class: EntriesView, 
			isBackendSelect: this.getOption('isBackendSelect', true),
			labelView: this.getOption('labelView'),
			viewFilter: this.getEntriesViewFilter(),
			viewComparator: this.getOption('entriesViewComparator', false),
			multiple: this.multiple,
			getLabel: this.getOption('getLabel', false),
		};

	},

	childViewEvents: {
		'search:change'(search) {
			if (search) {
				search = search.toLowerCase();
			}
			this.model.set({ search });
			console.log('search changed', search);
		},
		'segment:change'(segment) {
			this.model.set({ segment });
		},
	}

});