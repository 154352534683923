﻿//define('app-modules-behavior', ['bus'], function (Bus) {});

import Bus from 'bus';

var radio = Bus.app;
var moduleRequestKey = 'current:module';
var moduleStarting = "module:starting";
var contentEventName = 'content:update';

radio.reply(moduleRequestKey, undefined);

var $root = $('body');

function processCurrentModule() {
	var module = radio.request(moduleRequestKey);
	if (!module) return;
	let nameAsClass = module.name.replace(/[/\\]/gmi,'-');
	if (nameAsClass)
		$root.removeClass(nameAsClass);

	module.stop();
}

function processNewModule(module) {
	radio.reply(moduleRequestKey, module);
	if (!module) return;
	let nameAsClass = module.name.replace(/[/\\]/gmi,'-');
	if (nameAsClass)
		$root.addClass(nameAsClass);

}

function updateAppContent(layout, module)
{

	//processCurrentModule.call(this);
	processNewModule.call(this, module);

	//console.log('>>> ', layout.cid, layout.name);
	this.showView(layout);

}

function initFunc()
{
	var app = this;
	this.listenTo(radio, moduleStarting, function (module) {
		if (!module.isContent) return;
		processCurrentModule();
	});
	this.listenTo(radio, contentEventName, function () {
		updateAppContent.apply(app, arguments);
	});
}




export default initFunc;
