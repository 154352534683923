// define('svc/actualization/realties-list/realty/edit/properties/select-control', [
//     'svc/actualization/realties-list/realty/edit/properties/controls'
// ], function(
//     controls
// ) {});

import controls from './controls';
import { MnView, CollectionView } from 'vendors';
import { MneView } from 'core/mne';

const OptionView = MnView.extend({
    tagName: 'option',
    template: _.template('<%= label %>'),

    templateContext() {
        let label = this.model.get('label') || this.model.get('value');
        if (label == 'none' || label == 'None') label = '[ не установлено ]';
        return {
            label
        }
    }, 

    attributes() {
        this.initializeValue();
        return {
            value: this.model.get('value'),
            selected: this.isSelected(),
        }
    },

    isSelected() {
        if (this.schema.get('multiple')) {
            throw new Error('not implemented');
        } else {
            return this.model.get('value') == this.value
        }
    },

    ...controls.ControlValueMixin,

});

const SelectView = CollectionView.extend({
    tagName: 'select',
    className: 'form-control',
    childView: OptionView,
    childViewOptions() {
        return this.buildControlOptions();
    },
    ...controls.ControlValueMixin,

});


const Layout = MneView.extend({

    className: 'select-control',
    template: _.template(`<div></div>`),

    regions: {
        select: '> div'
    },

    onRender() {
        let options = this.buildControlOptions({ collection: this.collection });
        let view = new SelectView(options);
        this.showChildView('select', view, { replaceElement: true });
    },

    ...controls.ControlValueMixin,
    ...controls.ControlChangeValueMixin,
    ...controls.InputValueMixin,
    events: {
        ...controls.InputValueEventsMixin('select', 'change'),
    }

    // events: {
    //     'change select'() {
    //         console.log('chpok');
    //     }
    // }

});

export default Layout;
