export function editModalOptions(add) {
	return Object.assign({
		resolveButton: 'Применить',
		rejectButton: 'Отмена',
		rejectRejectHardSeparator: true,
		resolveAction() { return this.getSettleWith('resolve'); },
		rejectAction() { return this.getSettleWith('reject'); },
		rejectHardAction() { return this.getSettleWith('rejectHard'); },				

	}, add);
}