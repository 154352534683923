import { CollectionView, Collection } from 'core';
import { ManagerView } from './ManagerView';

export const ManagersListView = CollectionView.extend({
	childView: ManagerView,
	// initialize() {
	// 	const models = this.model.get('agencyAgents') || [];
	// 	this.collection = new Collection(models);
	// },
	childViewEvents: {
		'remove:click'(view) {
			this.trigger('remove:employee', view.model.id);
		}
	}
});