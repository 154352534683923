import { EditOfferPriceBoth } from '../../../ui/controls/editOfferPrice';

export const EditPriceView = EditOfferPriceBoth.extend({
	multipleOffers: true,
	applyButton: true,
});


// import { View } from 'core';
// import { getValueByPath, displayNum } from 'utils';
// import { _ } from 'vendors';
// import consts from 'vXX/app/constants';
// import refs from 'references';

// const { NDS_KOEF } = consts;

// const dropdownTemplate = _.template(`
// <div class="btn-group" style="display: inline-block">
// 	<button type="button" class="dropdown-toggle<%= buttonCss ? ' ' + buttonCss : '' %>" data-toggle="dropdown">
// 		<label><%= label %></label>
// 		<span class="caret"></span>
// 	</button>
// 	<ul class="dropdown-menu">
// 		<% for(let i = 0; i < items.length; i++) {%>
// 			<li><a><%= items[i] %></a></li>	
// 		<% } %>
// 	</ul>
// </div>
// `)

// function isArray(arg) {
// 	return Array.isArray(arg);
// }

// function comaSplit(txt) {
// 	if (!txt) { return txt; }
// 	return txt.split(/\s*,\s*/);
// }

// function getInputValue(inp) {
// 	let parsed;
// 	switch(inp.type) {
// 		case 'radio':
// 			return inp.checked ? inp.value : undefined;
// 		case 'checkbox':
// 			return inp.checked;
// 		case 'number':
// 			parsed = parseFloat(inp.value, 10);
// 			if (isNaN(parsed)) {
// 				return;
// 			}
// 			return parsed;
// 		default:
// 			return inp.value;
// 	}
// }

// export const _EditPriceView = View.extend({
// 	className: 'edit-price-dialogue',
// 	template: _.template(`		
// 	<input type="hidden" data-role="price" name="customer.priceMeter" class="form-control" value="<%= priceMeter %>" />
// 	<div>
// 		<label title="<%= _priceMeter.base %>" class="current-price-label">
// 			<span>текущая стоимость метра: </span><big><%= _f(_priceMeter.total) %></big>
// 			<% if(_priceMeter.nds) { %> <small>(ндс внутри, базовая: <%= _f(_priceMeter.base, 2)%>)</small> <% } %>
// 		</label>
// 	</div>
// 	<div>
// 		<label class="header-label">новая цена: </label>
// 		<input type="number" data-role="newprice-value" value="<%= priceMeter %>" class="form-control" /> 
// 		<label><input type="checkbox" data-role="newprice-nds" checked="checked"/> ввожу с ндс </label>
// 		<% if (_squareAllowed) { %>, 
// 			<label><input type="checkbox" data-role="newprice-square" /> за всю площадь </label>
// 			<% if (_hasBoma) { %>
// 				<label><input type="checkbox" data-role="newprice-boma" checked="checked"/> с корид. коэф. </label>
// 			<% } %>
// 		<% } else { %>
// 			, стоимость метра
// 		<% }%>
// 	</div>
// 	<div>
// 		<label class="new-price-label">установитcя: <big data-role="newprice-label">без изменений</big></label>
// 	</div>
// 	<div>
// 		<button class="btn btn-primary apply">Установить</button>
// 	</div>
// 	`),
// 	events: {
// 		'click button.apply'(e) {
// 			this.triggerMethod('done', this._getNewPriceValue().newValue, this);
// 		}
// 	},
// 	initialize(opts) {
// 		console.log('# options ', opts);
// 		this.mergeOptions(opts, ["models"]);
// 		this.manyObjects = this.models.length > 1;
// 		this.isRent = !this.manyObjects && getValueByPath(this.models[0], 'offer.operation') === 'rent' || false;

// 		this.delegate('input', '[data-role="newprice-value"]', this._onPriceChange.bind(this));
// 		this.delegate('change', '[data-role="newprice-nds"]', this._onPriceChange.bind(this));
// 		this.delegate('change', '[data-role="newprice-square"]', this._onPriceChange.bind(this));

// 	},
// 	_getNewPrice: function () {
// 		return {
// 			$label: this.$('[data-role="newprice-label"]'),
// 			$el: this.$('[data-role="price"]'),
// 			value: this.$('[data-role="newprice-value"]').val(),
// 			nds: this.$('[data-role="newprice-nds"]').get(0).checked,
// 			square: this.manyObjects ? undefined : this.$('[data-role="newprice-square"]').get(0).checked,
// 			rent: this.isRent,
// 			boma: this.hasBoma() ? !!this.$('[data-role="newprice-boma"]').get(0).checked : undefined,
// 		};
// 	},
// 	_getNewPriceValue: function () {
// 		var price = this._getNewPrice();
// 		var value = parseFloat(price.value);
// 		value = isNaN(value) ? 0 : value;

// 		if (value < 0) price.value = 0;

// 		if (price.nds) {
// 			value = value / NDS_KOEF;
// 		}

// 		if (price.square) {
// 			var sq = this.getSquare();
// 			if (sq > 0) {
// 				value = value / sq;
// 			}
// 			if (price.rent) {
// 				value = value * 12;
// 			}
// 			if (price.boma) {
// 				const boma = this.getBoma();
// 				value = value / boma;
// 			}
// 		}
// 		price.newValue = value;
// 		return price;
// 	},
// 	hasBoma() {
// 		const boma = this.getBoma();
// 		return boma > 1;
// 	},
// 	getBoma() {
// 		if (!this.isRent) { return 1; }
// 		const value = this.getValue('offer.forCustomer.boma', true);
// 		if (Array.isArray(value)) { return 1; }

// 		const boma = parseFloat(value[0], 10);

// 		if (isNaN(boma)) { return 1; }

// 		return boma > 1 ? boma : 1;
// 	},
// 	_onPriceChange: function () {

// 		var c = this._getNewPriceValue();
// 		var base = c.newValue;
// 		var total = base;
// 		var nds = this.hasNds() ? NDS_KOEF : 1;
// 		total = base * nds;
// 		base = this.formatPrice(base, 2);
// 		total = this.formatPrice(total, 2);
// 		if (nds > 1) {
// 			c.$label.html(total + ' за кв.м (ндс внутри, базовая: ' + base + ')');
// 		} else {
// 			c.$label.html(total + ' за кв.м');
// 		}
// 		c.$el.val(c.newValue);
// 	},
// 	hasNds: function () {
// 		if (this._hasNds != null) return this._hasNds;
// 		let tax = this.getValue('offer.forCustomer.taxType', true);
// 		if (!Array.isArray(tax)) {
// 			tax = [tax];
// 		}
// 		tax = _.reduce(tax, (memo, t) => {
// 			if (t == null) {
// 				return memo;
// 			}
// 			t = t.toLowerCase();
// 			t = t.replace(/\s/gmi,'');
// 			let ta = t.split(',');
// 			memo.push(...ta);
// 			return memo;
// 		}, []);
// 		this._hasNds = tax.indexOf('nds') > -1;
// 		return this._hasNds;
// 	},
// 	getValue(key, distinct) {
// 		let values = this.models.map(m => getValueByPath(m, key));
// 		if (this.manyObjects && !distinct) {
// 			return values;
// 		}
// 		let hash = _.reduce(values, (memo, val) => {
// 			memo[val] = val;
// 			return memo;
// 		}, {});
// 		values = _.map(hash, (val, key) => val);
// 		if (values.length == 1) {
// 			return values[0];
// 		}
// 		return values;
// 	},
// 	getSquare() {
// 		let value = this.getValue('object.info.squareOffer', true);
// 		if (Array.isArray(value)) return 0;
// 		return value;
// 	},
// 	_getPriceMeter() {
// 		if (this._priceMeter != null) return this._priceMeter;
// 		let val = this.getValue('offer.forCustomer.priceMeter');
// 		val = Array.isArray(val) ? 0 : val;
// 		this._priceMeter = val;
// 		return val;
// 	},
// 	getPriceMeter: function() {
// 		var price = this._getPriceMeter();
// 		var nds = this.hasNds();
// 		return {
// 			base: price,
// 			nds: nds,
// 			total: nds ? price * NDS_KOEF : price
// 		};
// 	},
// 	formatPrice: function (num, period) {
// 		return _.displayNum(num, period || 0);
// 	},
// 	templateContext: function () {
// 		return {
// 			_v: this,
// 			_f: this.formatPrice,
// 			_priceMeter: this.getPriceMeter(),
// 			_many: this.manyObjects,
// 			priceMeter: this._getPriceMeter(),
// 			_squareAllowed: !this.manyObjects,
// 			_hasBoma : this.hasBoma(),
// 			_boma: this.getBoma(),
// 		};
// 	}	
// });

// const postfixes = {
// 	priceMeter: ' р. за м<sup>2</sup>',
// 	priceSquare: {
// 		true: ' р. в месяц',
// 		false: ' р.',
// 		undefined: ' р.',
// 	},
// 	square: ' м<sup>2</sup>',
// }

// const postfix = txt => `<small class="postfix">${txt}</small>`;

// function inputToBaseValue(value, { withNds, ndsKoef, withBoma, bomaKoef, isRent } = {}) {
// 	if (withNds) {
// 		value = value / ndsKoef;
// 	}
// 	if (withBoma) {
// 		value = value / bomaKoef;
// 	}
// 	return value;
// }

// const api = {
// 	_getMany(mapper, models, distinct, display, flatten) {
// 		const hash = {};
// 		const all = [];

// 		const add = distinct 
// 			? v => hash[v] = 1
// 			: v => all.push(v);

// 		models.forEach(model => {
// 			const value = mapper(model, display);
// 			if (flatten && isArray(value)) {
// 				value.forEach(add);
// 			} else {
// 				add(value);
// 			}
// 		});

// 		let result = distinct ? Object.keys(hash) : all;

// 		if (result.length === 1) {
// 			result = result[0];
// 		} else if (result.length === 0) {
// 			return;
// 		}

// 		return result;
// 	},
// 	get(model, key) {
// 		return getValueByPath(model, key);
// 	},
// 	_getPriceIncludeWords(ndsKoef, bomaKoef, display) {
// 		const words = [];
// 		if (ndsKoef > 1) {
// 			words.push('ндс')
// 		}
// 		if (bomaKoef > 1) {
// 			words.push('кор. коэф.')
// 		}
// 		if (!display) {
// 			return words;
// 		}

// 		let result = '';
// 		if (words.length) {
// 			let last = words.length > 1 ? words.pop() : '';
// 			if (last) {
// 				last = ' и ' + last;
// 			}
// 			result = ' включая ' + words.join(', ') + last;
// 		}

// 		return result;
// 	},
// 	getOperation(model, display) {
// 		let value = this.get(model, 'offer.operation');
// 		if (display) {
// 			value = refs.enum('realtyOperations', value);
// 		}
// 		return value;
// 	},
// 	getOperations(models, display) {
// 		const distinct = true;
// 		return this._getMany(m => this.getOperation(m, display), models, distinct);
// 	},
// 	isRent(model) {
// 		let op = this.getOperation(model);
// 		return op === 'rent';
// 	},
// 	getNdsKoef(model) {
// 		const tax = this.get(model, 'offer.forCustomer.taxType') || '';
// 		if (tax.indexOf('nds') > -1) {
// 			return NDS_KOEF
// 		}
// 		return 1;
// 	},
// 	getBomaKoef(model, display) {
// 		let notApplied = 'не применяется';
// 		const rent = this.isRent(model);
// 		if (!rent) { return display ? notApplied : 1; }

// 		const boma = this.get(model, 'offer.forCustomer.boma');

// 		if (boma > 1) {
// 			return boma;
// 		}

// 		return display ? 'отсутствует' : 1;

// 	},
// 	hasBoma(models) {
// 		return models.some(model => this.getBomaKoef(model) > 1);
// 	},
// 	hasNds(models) {
// 		return models.some(model => this.getNdsKoef(model) > 1);
// 	},
// 	getBomas(models, distinct, display) {
// 		const mapper = (m,d) => this.getBomaKoef(m, d);
// 		return this._getMany(mapper, models, distinct, display);
// 	},
// 	getTaxTypes(models, display) {
// 		const distinct = true;
// 		const flatten = true;
// 		return this._getMany((m) => this.getTaxType(m, display), models, distinct, display, flatten);
// 	},
// 	getTaxType(model, display) {
// 		let result = this.get(model, 'offer.forCustomer.taxType') || 'none';
// 		if (display) {
// 			result = refs.enum('taxTypes', result) || 'не установлено';
// 		}
// 		return comaSplit(result);
// 	},
// 	getPriceMeters(models, distinct, display) {
// 		const mapper = (m,d) => this.getPriceMeter(m, d);
// 		return this._getMany(mapper, models, distinct, display);
// 	},
// 	getPriceMeter(model, display, cfg) {
// 		console.log('check meter');
// 		const basePriceMeter = cfg 
// 			? this.getConfigPriceMeter(model, cfg)
// 			: getValueByPath(model, 'offer.forCustomer.priceMeter');
			
// 		let result = basePriceMeter;
// 		if (display) {
// 			result = displayNum(basePriceMeter, 2) + postfix(postfixes.priceMeter);
// 		}
// 		return result;
// 	},
// 	getConfigPriceMeter(model, cfg) {
// 		console.error(cfg);
// 		let { withBoma, withNds } = cfg;
		
// 		let bomaKoef = this.getBomaKoef(model);
// 		let isRent = this.isRent(model);
// 		let ndsKoef = this.getNdsKoef(model);

// 		if (cfg.priceType === 'meter') {
// 			return inputToBaseValue(cfg.value, { withNds, ndsKoef });
// 		} else if (cfg.priceType === 'square') {
// 			const baseSquarePrice = inputToBaseValue(cfg.value, { withNds, withBoma, bomaKoef, ndsKoef });
// 			const square = this.getSquare(model);
// 			return baseSquarePrice / square * (isRent ? 12 : 1);
// 		}
// 	},
// 	getFullPriceMeter(model, display, cfg) {
// 		const basePriceMeter = this.getPriceMeter(model, false, cfg);
// 		//const square = this.getSquare(model);
// 		// const bomaKoef = this.getBomaKoef(model);
// 		const ndsKoef = this.getNdsKoef(model);
// 		//const isRent = this.isRent(model);

// 		const value = basePriceMeter * ndsKoef;

// 		if (!display) { return value; }



// 		let result = displayNum(value, 2)
// 		let add = postfixes.priceMeter;
// 		let words = this._getPriceIncludeWords(ndsKoef, undefined, true);
// 		if (words) {
// 			add += words;
// 		}
// 		return result + postfix(add);
// 	},
// 	getFullPriceMeters(models, distinct, display, cfg) {
// 		const mapper = (m,d) => this.getFullPriceMeter(m, d, cfg);
// 		return this._getMany(mapper, models, distinct, display);
// 	},
// 	getPriceSquare(model, display, cfg) {
// 		const basePriceMeter = this.getPriceMeter(model, false, cfg);
// 		const square = this.getSquare(model);
// 		const isRent = this.isRent(model);
// 		const value = basePriceMeter * square / (isRent ? 12 : 1);
// 		if (!display) { return value; }
// 		let result = displayNum(value, 2) + postfix(postfixes.priceSquare[isRent]);
// 		return result;
// 	},
// 	getFullPriceSquare(model, display, cfg) {
// 		const basePrice = this.getPriceSquare(model, false, cfg);
// 		const bomaKoef = this.getBomaKoef(model);
// 		const ndsKoef = this.getNdsKoef(model);
// 		const isRent = this.isRent(model);
// 		const value = basePrice * bomaKoef * ndsKoef;

// 		if (!display) { return value; }

// 		let result = displayNum(value, 2);
// 		let postfixText = postfixes.priceSquare[isRent];
// 		let words = this._getPriceIncludeWords(ndsKoef, bomaKoef, true);
// 		if (words) {
// 			postfixText += words;
// 		}

// 		return result + postfix(postfixText);
		

// 	},
// 	getFullPriceSquares(models, distinct, display, cfg) {
// 		const mapper = (m,d) => this.getFullPriceSquare(m, d, cfg);
// 		return this._getMany(mapper, models, distinct, display);
// 	},
// 	getSquare(model, display) {
// 		let value = this.get(model, 'object.info.squareOffer');
// 		if (display) {
// 			value += postfix(postfixes.square);
// 		}
// 		return value;
// 	},
// 	getSquares(models, distinct, display) {
// 		const mapper = (m,d) => this.getSquare(m, d);
// 		return this._getMany(mapper, models, distinct, display);
// 	}
// }

// const li = (label, cell) => `<li class="list-group-item">
// 	<div class="row">
// 		<span class="col-xs-5">${label}: </span>
// 		<span class="col-xs-7">${cell}</span>
// 	</div>
// </li>`


// const mainTemplate = `<h3>изменение цены</h3>
// <ul class="list-group">
// 	<% if (multiple) { %>
// 	${li('количество площадей', '<b><%= length %></b>')}
// 	<% } %>
// 	${li('площадь', '<%= squares %>')}
// 	${li('операция', '<%= operations %>')}
// 	${li('налогообложение', '<%= taxTypes %>')}
// 	<% if (hasBoma) { %>
// 	${li('коридорный коэффициэнт', '<%= bomas %>')}
// 	<% } %>
// 	${li('базовая стоимость метра', '<%= baseMeterPrices %>')}
// 	${li('полная стоимость метра', '<%= meterPrices %>')}
// 	${li('полная стоимость площади', '<%= squarePrices %>')}
// </ul>
// <hr/>
// <label><input type="radio" name="priceType" checked="checked" value="meter"> за метр</label>
// <label><input type="radio" name="priceType" value="square"> за площадь</label>
// <input class="form-control" name="value" type="number" />
// <% if (hasNds) { %><label><input type="checkbox" checked="checked" name="withNds"/> ввожу с ндс</label><% } %>
// <% if (hasBoma) { %><label><input type="checkbox" checked="checked" name="withBoma"/> ввожу с кор. коэффициентом</label><% } %>
// <hr/>
// <ul class="list-group">
// 	<li class="list-group-item list-group-item-success">установится цена:</li>
// 	${li('полная стоимость метра', '<b class="new-price-meter-full">&mdash;</b>')}
// 	${li('полная стоимость площади', '<b class="new-price-square-full">&mdash;</b>')}
// </ul>
// <hr/>
// <button class="btn-primary apply">применить</button>
// `;


// export const EditPriceView = View.extend({
// 	constructor: function(options) {
		
// 		View.apply(this, arguments);

// 		this.inputConfig = {};
// 		this.refreshNumbers = _.debounce(this._refreshNumbers.bind(this), 300);

// 		this.initializeModels(options);


// 		this.initializeInfo();

// 	},
// 	template: mainTemplate,
// 	templateContext() {
// 		return {
// 			...this.info,
// 		}
// 	},
// 	ui: {
// 		meter: '.new-price-meter-full',
// 		square: '.new-price-square-full'
// 	},
// 	_dropdown(value, opts = {}) {
// 		if (value == null || value === '') {
// 			value = '&mdash;';
// 		}
// 		let { label = 'разные', buttonCss = 'btn-flat-text' } = opts;

// 		if (isArray(value)) {
// 			const ctx = {
// 				...opts,
// 				label,
// 				buttonCss,
// 				items: value,
// 			}
// 			let template = dropdownTemplate(ctx);
// 			return template;
// 		} else {
// 			return `<b>${value}</b>`;
// 		}
// 	},

// 	initializeModels(opts) {
// 		this.mergeOptions(opts, ["models"]);
// 	},
// 	getModels() {
// 		return this.models;
// 	},
// 	initializeInfo() {
		
// 		const models = this.getModels();

// 		const operations = api.getOperations(models, true);
// 		let baseMeterPrices = api.getPriceMeters(models, true, true);
// 		let meterPrices = api.getFullPriceMeters(models, true, true);
// 		let taxTypes = api.getTaxTypes(models, true, true);
// 		let squarePrices = api.getFullPriceSquares(models, true, true);
// 		let squares = api.getSquares(models, true, true);
// 		let bomas = api.getBomas(models, true, true);
// 		this.info = {
// 			length: models.length,
// 			single: models.length === 1,
// 			multiple: models.length > 1,
// 			isRent: operations === 'rent',

// 			hasBoma: api.hasBoma(models),
// 			hasNds: api.hasNds(models),

// 			operations: this._dropdown(operations),			
// 			baseMeterPrices: this._dropdown(baseMeterPrices),
// 			meterPrices: this._dropdown(meterPrices),
// 			taxTypes: this._dropdown(taxTypes),
// 			squarePrices: this._dropdown(squarePrices),
// 			squares: this._dropdown(squares),
// 			bomas: this._dropdown(bomas),
// 		}
// 	},

// 	// getOperation() {
// 	// 	return this.getValue('offer.operation', true);
// 	// },
// 	// getSquare() {
// 	// 	return this.getValue('object.info.square');
// 	// },
// 	// getMeterPrice() {
// 	// 	return this.getValue('offer.forCustomer.priceMeter', true);
// 	// },
// 	// getValue(key, distinct) {
// 	// 	const models = this.getModels();
// 	// 	let values = models.map(m => getValueByPath(m, key));
// 	// 	if (this.manyObjects && !distinct) {
// 	// 		return values;
// 	// 	}
// 	// 	let hash = _.reduce(values, (memo, val) => {
// 	// 		memo[val] = val;
// 	// 		return memo;
// 	// 	}, {});
// 	// 	values = _.map(hash, (val, key) => val);
// 	// 	if (values.length == 1) {
// 	// 		return values[0];
// 	// 	}
// 	// 	return values;
// 	// },
// 	multipleOffers: true,
// 	events: {
// 		'input input'(event) {
// 			const hash = this._getInputHash(event.target);
// 			this.changeConfig(hash);
// 			this.refreshNumbers();
// 			this.updateValue();
// 		},
// 		'click button.apply'(event) {
// 			const value = this.getValue();
// 			this.triggerMethod('done', value);
// 		}
// 	},
// 	getValue() {
// 		const multiple = this.getOption('multipleOffers', true);

// 		const editValue = this.editValue || [];

// 		if (multiple) {
// 			return _.reduce(editValue, (hash, value, key) => {
// 				hash[value.id] = value.priceMeter;
// 				return hash;
// 			}, {});
// 		} else {
// 			if (editValue[0]) {
// 				return editValue[0].priceMeter;
// 			}
// 		}
// 	},
// 	updateValue() {
// 		const models = this.getModels();

// 		this.editValue = models.map(model => {
// 			const id = getValueByPath(model, 'offer.id');
// 			return {
// 				id,
// 				priceMeter: api.getPriceMeter(model, false, this.inputConfig),
// 				priceSquare: api.getPriceSquare(model, false, this.inputConfig),
// 			}
// 		});

// 	},
// 	_getInputHash(inp) {
// 		const key = inp.name;
// 		let value = getInputValue(inp);
// 		if (value == null) {
// 			return {};
// 		}
// 		const hash = { [key]: value };
// 		return hash;
// 	},
// 	onRender() {
// 		const inputConfig = this.$('input')
// 			.map((i, ar) => ar)
// 			.toArray()
// 			.reduce((hash, inp) => Object.assign(hash, this._getInputHash(inp)), {});
// 		this.changeConfig(inputConfig);
// 	},
// 	changeConfig(newhash) {
// 		Object.assign(this.inputConfig, newhash);
// 	},
// 	_refreshNumbers() {
		
// 		let models = this.inputConfig.value > 0 ? this.getModels() : [];
		
// 		const fullMeterPrices = api.getFullPriceMeters(models, true, true, this.inputConfig);
// 		const meterHtml = this._dropdown(fullMeterPrices);
// 		this.ui.meter.html(meterHtml);
// 		const fullSquarePrices = api.getFullPriceSquares(models, true, true, this.inputConfig);
// 		const squareHtml = this._dropdown(fullSquarePrices);
// 		this.ui.square.html(squareHtml);
// 	}
// });
