﻿//define('svc-staff-deps-departmentItem', ['bus-svc','behaviors'], function (appRadio, beh) {});

import appRadio from 'bus/svc';

import template from './departmentItem.html';

import { MneView } from 'core/mne';

var View = MneView.extend({		
	className: 'department-item',
	//template: '#tmpl-service-staff-deps-departmentItem',
	template,
	//behaviors: [beh.ModelActionsInPopup],
	modelEvents: {
		'change':'render'
	},
	templateContextExtend: function () {
		var emps = _.map(this.model.get('employees'), function (l) {
			return { eId: l.eId, joinedAs: l.joinedAs, name: appRadio.request('employeeName', l.eId) };
		});
		var rawleads = _(emps).filter(function (emp) { return emp.joinedAs == 'lead' });
		var leads = _(rawleads).map(function (l) { return l.name }).join(', ');
		var leadsids = _(rawleads).pluck('eId');
		var rawemps = _.filter(emps).filter(function (f) { return leadsids.indexOf(f.eId) == -1; })

		emps = _(rawemps).map(function (e) { return e.name; }).join(', ');

		return {
			leads: leads,
			emps: emps
		}
	},
	events: {
		'click': function () {
			this.model.actionOpenPage();
		},
	}
});

export default View;