import { ActionsView, AgentView, IdView, LastModifiedView, PriceView, PurposeView } from "./object-property-views";
import { _ } from 'vendors';

const displayNum = function(value) {
	return _.displayNum(value, 2);
}


export const objectProperties = {
	id: { 
		buildView({ parentView, prop }) {
			return new IdView({ className: prop, parentView, prop });
		}
	},
	modified: {
		buildView({ parentView, prop }) {
			return new LastModifiedView({ className: prop, parentView, prop });
		}
	},
	purpose: {
		buildView({ parentView, prop }) {
			return new PurposeView({ className: prop, parentView, prop });
		}
	},
	// operation: { 
	// 	entity: 'offer', path: 'operation', hideOnFilter: { 'offer.operation': true },
	// 	display: value => refs.enum('realtyOperations', value)
	// },
	// market: { 
	// 	entity: 'offer', path: 'market', hideOnFilter: { 'offer.market': true },
	// 	display: value => refs.enum('realtyMarkets', value)
	// },
	floor: { entity: 'object', path: 'info.floor', postfix: '<i class="postfix">Эт.</i>' },
	square: { entity: 'object', path: 'info.squareTotal', display: displayNum, postfix: '<i class="postfix">м<sup>2</sup></i>' },
	roomId: { entity: 'object', path: 'info.roomNumber', title: 'Номер кабинета (или наш внутренний номер, для идентификации)' },
	price: {
		buildView({ parentView, prop }) {
			return new PriceView({ className: prop, parentView, prop });
		}
	},
	agent: {
		buildView({parentView, prop}) {
			return new AgentView({ className: prop, parentView, prop });
		}
	},

	// publicity: {
	// 	buildView({ parentView, prop }) {
	// 		return new PublicityView({ className: prop, offer: parentView.model, object: parentView.object });
	// 	}
	// },
	actions: {
		buildView({ parentView, prop }) {
			return new ActionsView({ className: prop, parentView, prop });
		}
	},
}