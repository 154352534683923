import { _, moment } from 'vendors';
import SchemaEdit from 'ui/controls/schemaEdit';
import refs from 'references';
import { schemaEditApi, modelSchemaApi } from 'apis/schema';
import { unFlatObject } from 'utils';
import { OwnersCollection } from 'mods/realties/owners';

// import { View } from 'core';
// import { getValueByPath } from 'utils';
// import filters from '../filters';
// import htmllib from '_libs/html';
// import { objectProperties } from './objectProperties';
// import { HamburgerView } from '../../../../../coms/ui/HamburgerView';
// import { UiMoleculeView } from '../../../../../coms/ui/UiMoleculeView';
// import { UiAtomView } from '../../../../../coms/ui/UiAtomView';
// import { objectSchema } from './objectSchema';
// import { propertySchemaApi } from '../../../../../apis/schema';
// import { UiSchemaAtomView, UiSchemaMoleculeView } from '../../../../../coms/schema';
// import { ActionsView, LastModifiedView } from './object-property-views';
// import { innerBus } from '../innerBus';
// import { smartOpen } from 'utils';

// import { offerProperties } from '../offerProperties';
// import helpers from '../../helpers';
// import { patchRealtyObject } from 'mods/realties/objects/crud';
const get = res => {
	if (!res) { return }
	return res.value;
}

export function editMainProperties(defaultValues = {}, modelSchema) {
	modelSchema = modelSchema || modelSchemaApi.getModelSchema('realtyObject');
	const properties = [
		'status',
		'visibleFor',
		'info.floor',
		'info.roomNumber',
		'info.purposes',
		'info.currentPurpose',
		'info.state',
		'info.spaceLayout',
	];
	const header = 'Изменение основных характеристик';
	const value = {};
	_.each(properties, key => {
		value[key] = defaultValues[key];
	});
	return schemaEditApi.editProperties(properties, { header, value: value, modelSchema, inlineEdit: true }).then(res => get(res));
}


export async function addOffer(objectModel) {

	const modelSchema = modelSchemaApi.getModelSchema('realtyOffer');
	const header = 'Добавление нового предложения';

	const value = {
		allowedMarketOperations: objectModel.getAvailableMarketsOperations(),
		'object.info.squareOffer': objectModel.getByPath('info.squareOffer'),
	};

	const properties = [
		'visibleFor',
		'operation',
		'market',
		'forAgent.contractType',
		'forCustomer.taxType',
		'forAgent.income',
		'forCustomer.boma',
		'forCustomer.priceMeter',
	];

	const res = await schemaEditApi.editProperties(properties, { header, value: value, modelSchema, inlineEdit: true, validate: true });
	if (!res.ok) {
		return;
	}
	const offer = res.value;
	delete offer.allowedMarketOperations
	//const offer = unFlatObject(res.value);
	await objectModel.sendNewOffer(offer);
}


export function changeOwner(models) {

		schemaEditApi.editValue({
			label: 'Собственник',
			controlName: 'apiSelect',
			property: 'owner_id',
			controlOptions: {
				collection: new OwnersCollection(),
				getLabel() {
					return this.model.get('name')
				}
			}
		}, { header: 'Выберите нового собственника'})
}

// export function __editMainProperties (model) {

// 	let properties = {
// 		'info.floor': {
// 			caption: 'этаж',
// 			emptyText: 'не установлен',
// 			modelType: 'single',
// 			valueType: 'number',
// 			display: v => v != null ? v + ' эт.' : '',
// 			// validate: required,	
// 		},
// 		'info.roomNumber': {
// 			caption: 'номер кабинета',
// 			emptyText: 'не установлен',
// 			modelType: 'single',
// 			valueType: 'string',
// 			//display: v => v ? v + ' эт.' : '',
// 			//validate: required,	
// 		},
// 		// 'info.squareOffer': {
// 		// 	caption: 'площадь',
// 		// 	emptyText: 'не указана',
// 		// 	modelType: 'single',
// 		// 	valueType: 'number',
// 		// 	display: v => v != null ? v + ' кв. м' : '',
// 		// 	// validate: required,	
// 		// },
// 		'info.purposes': {
// 			caption: 'возможные назначения',
// 			emptyText: 'не выбраны',
// 			// display: v => {
// 			// 	console.log('v::', v);
// 			// 	let gg = (v || []).map(mv => refs.enum('realtyPurposes', mv));
// 			// 	return gg.join(', ');
// 			// },
// 			sourceValues: refs.enum('realtyPurposes'),
// 			modelType: 'single',
// 			valueType: 'enum',
// 			//flattenValues: true,
// 			multiple: true,
// 			// validate: required,	
// 		},		
// 		'info.currentPurpose': {
// 			caption: 'текущее назначение',
// 			emptyText: 'не выбрано',
// 			//display: v => v ? refs.enum('realtyPurposes',v) : undefined,
// 			sourceValues: refs.enum('realtyPurposes'),
// 			modelType: 'single',
// 			valueType: 'enum',
// 			//flattenValues: true,
// 			multiple: false,
// 		},

// 	}

// 	const valuesHash = model.toJSON();

// 	let options = {
// 		header: 'изменение характеристик',
// 		applyText: 'сохранить',
// 		beforeApply: (data) => {
// 			console.log('opa opa opapa', data);
// 			// return patchRealtyObject(valuesHash.id, data).then((data) => {
// 			// 	innerBus.trigger('main:re:render');
// 			// });
// 		},
// 		valuesHash,
// 		// beforeApply: (value) => {
// 		// 	return this.takeAction('create:object', 'добавление помещени' + (many ? 'й' : 'я'), [], value).then(() => this.model.fetch());
// 		// },
// 	}

// 	console.error(options.valuesHash);

// 	return SchemaEdit.inModal(properties, options);

// }