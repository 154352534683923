﻿//define('ui-models-filters', ['ui-models-filters-groups', 'ui-models-filters-collection'],function(Groups, FiltersCollection){});

//let Groups, FiltersCollection;
import Groups from './groups';
import FiltersCollection from './collection';

import { MneObject } from 'core/mne';

function normalizeNum(value, valueType) {
	if (!valueType)
		return value;
	else {
		if (valueType == 'number') {
			var num = parseFloat(value, 10);
			if (isNaN(num)) {
				return;
			} else {
				return num;
			}
		} else if (valueType == 'datetime') {
			var dat = new Date(value);
			if (isNaN(dat.valueOf())) {
				return;
			} else {
				return dat;
			}
		}
		else {
			return value;
		}
	}
}

function normalizeSetValue(value, type, valueType) {
	if (value == null) return;

	if (type == 'range' && _.isObject(value)) {
		if (!('from' in value)) {
			value.from = normalizeNum(value.f, valueType);
			delete value.f;
		}
		if (!('to' in value)) {
			value.to = normalizeNum(value.t, valueType);
			delete value.t;
		}
		return value;
	} else {
		if (_.isObject(value)) {
			if (!('value' in value)) {
				value.value = normalizeNum(value.v, valueType);
				delete value.v;
			} else {
				value.value = normalizeNum(value.value, valueType);
			}
			return value;
		} else {
			//return normalizeNum(value, isNumber);
			return normalizeNum(value, 'number');
		}
	}
}

// var DelegateEntityEventsMixin = {
// 	// Handle `modelEvents`, and `collectionEvents` configuration
// 	_delegateEntityEvents: function _delegateEntityEvents(model, collection) {
// 		this._undelegateEntityEvents(model, collection);

// 		var modelEvents = _.result(this, 'modelEvents');
// 		bindEvents.call(this, model, modelEvents);

// 		var collectionEvents = _.result(this, 'collectionEvents');
// 		bindEvents.call(this, collection, collectionEvents);
// 	},
// 	_undelegateEntityEvents: function _undelegateEntityEvents(model, collection) {
// 		var modelEvents = _.result(this, 'modelEvents');
// 		unbindEvents.call(this, model, modelEvents);

// 		var collectionEvents = _.result(this, 'collectionEvents');
// 		unbindEvents.call(this, collection, collectionEvents);
// 	}
// };


function normalizeUrlValue(val) {
	if (val && typeof(val.toJSON) === 'function') {
		return val.toJSON();
	}
	return val;
}

function normalizePropertyValue(prop) {
	if (Array.isArray(prop)) {
		return prop.map(normalizeUrlValue).join(', ')
	}
	else {
		return normalizeUrlValue(prop);
	}
}

function getUrlHashfromValue(value, index, key) {

	var hash = {};

	_(value).each(function (propValue, propName) {
		var propKey = key + (index ? '[' + index + ']' : '') + '.' + propName;
		var propVal = normalizePropertyValue(propValue);
		hash[propKey] = propVal;
	});


	return hash;
}


var Filters = MneObject.extend({

	initialize: function (opts) {
		if (opts.groups) {
			this.groups = new Groups(opts.groups);
		}
		if (opts.filters) {
			this.filters = new FiltersCollection(opts.filters, { groups: this.groups, filtersContext: this });
		}
		if (opts.changeHandlers) {
			this.addChangeHandlers(opts.changeHandlers);
		}
		this.radio = opts.radio;
		//console.log('filters options', opts);
		//var filtersEvents = _.result(this, 'filtersEvents');
		//this.bindEvents(this.filters, filtersEvents);
	},

	setValues: function (valuesHash) {
		if (!_.isObject(valuesHash)) { return; }

		//console.log(valuesHash);

		var filters = this.filters;

		_.each(valuesHash, function (value, key) {
			var filter = filters.get(key);
			if (!filter) return;
			var type = filter.get('modelType');
			var valueType = filter.get('valueType');

			//console.log('*', valueType, filter.id, filter);

			value = normalizeSetValue(value, type, valueType);
			filter.applyValues(value);
		});


	},

	addChangeHandlers: function (handlers) {
		if (handlers == null) return;
		if (!(_.isArray(handlers) || _.isFunction(handlers)))
			return;

		if (!_.isArray(handlers))
			handlers = [handlers];

		this.on('filters:apply', function () {
			var args = [].slice.call(arguments, 0);
			//var _this = this;
			args.unshift(this.options);
			_(handlers).each(function (handler) {
				handler.apply(null, args);
			});
		});
			
	},
	clearValues: function () {
		if (!this.filters) return;
		this.filters.each(function (f) { f.clearValues({ silent: true }); });
		this.trigger('view:refresh');
	},
	apply: function () {

		var hash = this.filters.getFiltersData();
		if (this.radio)
			this.radio.trigger('filters:apply', this.cid, hash, this.filters);

		this.trigger('filters:apply', hash, this.filters, this.filters.toDeepJSON());

		//var hash;
		//if(this.filters)
		//	hash = this.filters.getFiltersData();
		//else
		//	console.warn('Filters Apply: filters empty');

		//if (!hash)
		//	console.warn('Filters Apply: hash empty');

		//console.log('filters radio', this.radio);

		//if (hash && this.radio)
		//	this.radio.trigger('filters:apply', hash);

	},

	getUrlHash: function getUrlHash(filters) {
		filters || (filters = this.filters.getFiltersData());
		if (!filters || _.size(filters) == 0) return undefined;
		var hash = {};

		_(filters).each(function (keyValue, key) {
			var whoK = _.who(keyValue);

			if (whoK.primitive) {
				// if (_.isDate(keyValue)) {
				// 	keyValue = JSON.stringify(keyValue);
				// }
				hash[key] = normalizeUrlValue(keyValue);
			}
			else if (whoK.array) {
				_(keyValue).each(function (itemValue, index) {
					//console.log(itemValue, index, key);
					var hfv = getUrlHashfromValue(itemValue, index, key);
					//console.log('==', hfv);
					_.extend(hash, hfv);
				});
			}

		});
		//console.log('data hash: ', hash);
		return hash;

	},

	refreshById: function (id) {
		var fltr = this.getFilterById(id);
		if (!fltr) return;
		fltr.trigger('view:refresh');
	},
	getFilterById: function (id) {
		var filter = this.filters.get(id);
		//console.log(filter);
		return filter;
	},

});


Filters.create = function (opts) {
	var options = _.extend({},opts);
	var fo = new Filters(options);
	//if (opts.groups) {
	//	options.groups = new Groups(opts.groups);
	//}
	//if (opts.filters) {
	//	options.filters = new FiltersCollection(opts.filters, { groups: options.groups, filtersContext: this});
	//}
	return fo;
}

export default Filters;