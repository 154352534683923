﻿//define('m-singleFetch', ['store-model'], function (StoreModel) {});

import StoreModel from '_libs/store/model';

const sstore = new StoreModel();

function normalizePromiseArgument(arg, context)
{
	if (!arg) {
		return Promise.resolve();
	}

	if (typeof arg == 'function') {
		return normalizePromiseArgument(arg.call(context, context), context);
	}

	if (arg instanceof Promise) {
		return arg;
	}

	if (Array.isArray(arg)) {

		return Promise.all(arg.map(arrItem => normalizePromiseArgument(arrItem, this)));
	}

	if (typeof arg !== 'object') {
		return Promise.resolve(arg);
	}

	if (typeof arg.then === 'function') {
		return new Promise((res, rej) => {
			arg.then(res, rej);
		});
	}
	return Promise.resolve(arg);
}

var ns = {
	ppopulate: function (options) {
		var _this = this;
		options || (options = {});
		var opts = _.extend({}, options, _.result(_this, 'populateOptions'));
		//console.log(_this._typeLabel, 'POPULATING', _this.singleFetch, otherPromises);
		if (options.force || !_this.singleFetch) {
			var otherPromises = opts.promises;
			if (otherPromises == null) {
				otherPromises = {};
			}
			if (!_.isArray(otherPromises)) {
				otherPromises = [otherPromises];
			}
			var promise = _this.singleFetch = $.Deferred();
			//console.log(_this._typeLabel, 'BEFORE', otherPromises);
			$.when.apply($, otherPromises).then(function () {
				//console.log('!!!', otherPromises);
				//console.log('resolved!', _this._typeLabel, _this.url());
				_this.loudFetch().then(function () {
					promise.resolve(_this);
				}, function () {
					promise.reject('populate fails', _this);
				});

			}, function (arg1, arg2) {

				promise.reject(arg1, arg2);

			});
			return promise;
		}
		return _this.singleFetch;
	},
	restoreOrFetch() {
		let key = this.storeKey;
		let storeOpetions = this.storeOptions;
		let stored = key && sstore.get(key);

		if (stored) {
			//console.log('[ ## resto ]', key, stored);
			if (storeOpetions && storeOpetions.parseBeforeSet && this.parse) {
				stored = this.parse(stored);
			}
			this.set(stored);
			return Promise.resolve(this);
		}

		return this.loudFetch().then((arg) => {
			key && sstore.set(key, arg, storeOpetions);
			return Promise.resolve(arg);
		});
	},
	pppopulate(options) {
		options = Object.assign({}, options, _.result(this, 'populateOptions'));
		let { force } = options;

		if (this.singleFetch && !force) {
			return this.singleFetch;
		}

		let promiseContext = {};
		this.singleFetch = new Promise((resolve, reject) => {
			promiseContext.resolve = resolve;
			promiseContext.reject = reject;
		});

		let { promises } = options;
		promises = normalizePromiseArgument(promises, this);
		promises.then(() => {
			

			this.restoreOrFetch().then((arg) => {
				promiseContext.resolve(arg);
			}, err => {
				promiseContext.reject(err);
			});
		}, (err) => {
			promiseContext.reject(err);
		});

		return this.singleFetch;
	},
	populate(options) {
		let { force, promises } = (options || {});

		if (this._populated && !force) return Promise.resolve(this);

		promises = normalizePromiseArgument(promises, this);

		return promises
			.then(() => this.restoreOrFetch())
			.then(() => {
				this._populated = true;
				return this;
			});
	}
};

export default ns;
