import { BbView, Model, MnObject, MnAppRouter, Application } from 'vendors';
export const knownCtors = [
    String, Number, Boolean, Date,
    BbView, Model, MnObject, MnAppRouter, Application
];

export function isKnownCtor(arg) {
    if (typeof arg !== 'function') { return false; }
    for(let x = 0; x < knownCtors.length; x++) {
        let ctor = knownCtors[x];
        if (arg === ctor || arg.prototype instanceof ctor) {
            return true;
        }
    }
    return false;
}