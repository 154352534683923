import { DropdownButton } from "../../../../coms/ui/DropdownButton";
import { HamburgerView } from "../../../../coms/ui/HamburgerView";
import { UiSchemaMoleculeView } from '../../../../coms/schema';

import { PropertiesBoxView } from 'coms/ui/PropertiesBox';
import { editContactSchema } from './editContactSchema';
import { viewSchemaMixin } from "apis/schema";
import { CoreBbView } from "../../../../core/coreBbView";
import { CollectionView } from 'core/collection-view';
import { Collection } from 'core';

import { BoxView } from 'coms/ui/Box';
import { BaseContactValueListItemView } from "../BaseContactValueListItemView";
import { editContactValueInModal, removeContactValue } from "../../editContactValueInModal";
import { applyChangesByPath } from "../../../../utils";
import modals from '_libs/modals';

// import { SmartPropertiesLinesView } from "../PropertiesLineView";

// export const PropertiesBoxView = BoxView.extend({
// 	// thisClassName: 'employees-box with-borders fixed-height',
// 	// header: 'сотрудники',
// 	content: v => ({
// 		class: SmartPropertiesLinesView,
// 		model: v.model,
// 		modelValue: v.getOption('modelValue', true),
// 		modelHash: v.getOption('modelHash', true),
// 		modelSchema: v.getOption('modelSchema'),
// 		properties: v.getOption('properties', true),
// 		editEnabled: v.getOption('editEnabled'),
// 		editAction: v.getOption('editAction', false),
// 		setValueBySchemaApi: v.getOption('setValueBySchemaApi', true),
// 		onPropertyChange: (newvalue, propertySchema, modelValue, modelSchema) => v.triggerMethod('property:change', newvalue, propertySchema, modelValue, modelSchema)
// 	}),
// 	// action: v => ({
// 	// 	glyph: 'plus',
// 	// 	text: 'добавить сотрудников',
// 	// 	clickAction: () => v.addEmployeesAction()
// 	// }),
// });

const ContactListItemView = BaseContactValueListItemView.extend({
	removeButton: true,
	clickableMixinEnabled: true,
	clickableAction() {
		this.triggerMethod('edit:click', this.model);
	},
	rightItems: [
		// {
		// 	topText: 'доб.',
		// 	text: v => v.display('addValue')
		// },
		{
			topText: 'назначение',
			text: v => v.display('purposes')
		},
		{
			topText: 'описание',
			text: v => v.display('note')
		},
	],
	modelEvents: {
		change: 'render'
	},
	childViewEvents: {
		'remove:click'() {
			this.triggerMethod('remove:click', this.model);
		}
	},
});
// UiSchemaMoleculeView.extend({
// 	content: {
// 		topText: v => v.display('value.type'),
// 		text: v => v.display('value.value')
// 	}
// });

const ContactsList = CollectionView.extend({
	thisClassName: 'contact-contacts-list with-borders',
	childView: ContactListItemView,
	childViewOptions() {
		return {
			owner: this.getOption('owner'),
			contact: this.getOption('contact')
		}
	},
	childViewTriggers: {
		'edit:click':'edit:click',
		'remove:click':'remove:click'
	}
});

export const EditContactContacts = BoxView.extend({
	thisClassName: 'contact-contacts-container with-borders',
	header: 'контактные данные',
	initialize() {
		const isNew = !this.model;
		const modelHash = this.getOption('modelHash', true) || {};
		const models = modelHash.values || [];
		this.collection = new Collection(models);
		this.listenTo(this.collection, 'update remove change', () => {
			const values = this.collection.toJSON();
			if (isNew) {
				modelHash.values = values;
			} else {
				this.model.set('values', values);
			}
		});
		console.log('HASH INB CONTACTS', modelHash, this.model);
	},
	content: v => ({
		class: ContactsList,
		modelHash: v.getOption('modelHash', true),
		isNewContact: v.getOption('isNew', true),
		collection: v.collection,
		owner: v.getOption('owner'),
		contact: v.model,
	}),
	contentSectionOptions: {
		childViewTriggers: {
			'edit:click':'edit:click',
			'remove:click':'remove:click'
		}
	},
	async _editContactValue(model) {


		const options = {
			owner: this.getOption('owner'),
			contact: this.model
		}
		
		const isNew = !model;
		const result = await editContactValueInModal(model, options);

		if (!result.ok) { return; }
		
		// const isNewContact = this.getOption('isNew', true);

		if (isNew) {
			this.collection.add(result.value);
		} else {

			applyChangesByPath(model, result.value);
			console.error(model);
		}

		console.log('##_editContactValue>> end', result);
	},
	_removeContactValue(model) {
		const options = {
			collection: this.collection,
			owner: this.getOption('owner', true),
			contact: this.model
		}
		if (model.id) {
			modals.confirm('подтвердите удаление').then(() => removeContactValue(model, options), () => { console.log('NO') });
		} else {
			this.collection.remove(model);
		}
	},
	action: v => {
		const view = {
			glyph: 'plus',
			text: 'добавить',
			clickAction: () => v._editContactValue(),
		}
		return view;
	},
	childViewEvents: {
		'edit:click'(model) {
			this._editContactValue(model);
		},
		'remove:click'(model) {
			this._removeContactValue(model);
		}
	}
});