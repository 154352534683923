﻿//define('svc-clients-client-info', ['bus'], function (Bus) {});

import Bus from 'bus';

import template from './info.html';
import { MneView } from 'core/mne';

var View = MneView.extend({
	//template: '#tmpl-service-clients-client-info',
	template,
	modelEvents: {
		'change':'render'
	},
	events: {
		'click': function () {
			this.model.executeAction({ action: 'changeInfo'});
		}
	}
});


export const ClientInfo = View;

Bus.Views.reply('client:info', function () { return View; });

export default View;