﻿//define('realties-offers-views-collection', ['realties-offers-views-inList', 'realties-offers-views-inList2', 'behaviors-selectedForActions'], function (InList, InList2, SelectedForActions) {});

import InList from './inList';
import InList2 from './inList2';
import SelectedForActions from 'behaviors/selectedForActions';
import { LoadmoreOnScrollCollectionView } from 'core/mne';
import { OfferInListView } from './inList3';


	//var View = LoadmoreableCollectionView.extend({
	//	childView:InList
	//});
	/*
	var View = MixedView.extend({
		className:'offers-items-list scrollY-region',
		childView: InList,
		behaviors: [MneBehaviors.OnScrollLoadmorable, MneBehaviors.ScrollableY],
	});
	*/
	var View = LoadmoreOnScrollCollectionView.extend({
		className: 'offers-items-list',
		childView: InList,
		childViewOptions: function () {
			return {
				selectionHolder: this.getOption('selectionHolder'),
				denySelectionFor: this.getOption('denySelectionFor'),
				isJoined: this.getOption('isJoined')
			}
		},
		collectionViewExtend: {
			behaviors: [SelectedForActions],
			childViewTriggers: {
				'address:hover': 'address:hover',
				'address:unhover': 'address:unhover',
				'offer:hover': 'offer:hover',
				'offer:unhover': 'offer:unhover',
				'metro:station:click': 'metro:station:click',
				'address:click': 'address:click',
				'selectable:toggle':'selectable:toggle'
			}
		},
		childViewTriggers: {
			'address:hover': 'address:hover',
			'address:unhover': 'address:unhover',
			'offer:hover': 'offer:hover',
			'offer:unhover': 'offer:unhover',
			'metro:station:click': 'metro:station:click',
			'address:click': 'address:click',
			
		}
	});

	View.V2 = View.extend({
		// childView: InList2,
		childView: OfferInListView
	});


	export const OffersSearchListView = View;
	export const OffersSearchListView_V2 = View.V2;


	export default View;