import { _, Behavior, BbView, View } from 'vendors';

import { invokeValue } from 'utils/invoke';
import { computePosition } from 'vendors/floating-ui';


const PopupView = View.extend({
    className: 'floatui-popup',
    template: _.template('<section></section><div data-role="arrow"></div>'),
    regions: {
        content: 'section'
    },
    onRender() {
        let View = this.getOption('contentView');
        let options = this.getOption('contentOptions');
        this.showChildView('content', new View(options), { replaceElement: true });
    }
});

export const BasePopup = Behavior.extend({

    onInitialize() {
        this._popups = {};
        let popups = this._getPopups();
        if (!popups) { return; };
        _.each(popups, this._initializePopup.bind(this));
    },

    _getPopups() {
        let popups = this.getOption('popups');
        if (popups) { return popups; }
        return this.view.getOption('popups');
    },


    _initializePopup(config, index) {
        if (!config || !config.openEvent) return;
        let buildedConfig = Object.assign({}, this.getOption('defaultPopupConfig'), config);
        let { openEvent, openSelector } = buildedConfig;
        this.delegate(openEvent, openSelector, event => this.open(buildedConfig, index, event));
    },

    delegate() {
        return this.view.delegate.apply(this.view, arguments);
    },

    undelegate() {
        return this.view.undelegate.apply(this.view, arguments);
    },

    open(config, index, event) {
        console.log('#open', index, config);
        let api = this;
        
        let popup = this.getPopup(index, config);
        
        // убираем таймер разрушения если он установлен 
        this.clearRemoveTimeout(popup);
        
        // если попап уже показывается
        if (popup.view && popup.view.isAttached()) { 
            // разрушаем попап если выбрано поведение "TOGGLE" или просто выходим
            if (config.toggle) {
                api.removePopup(popup);
            } 
            console.log('   •popup exist');
            return; 
        }
        
        // если установлен таймер показа, значит всё уже сделано и нужно просто подождать
        if (popup.showTimer) {
            return;
        }

        let showDelay = config.showDelay || (config.openEvent === 'mouseenter' ? 500 : 50);

        popup.showTimer = setTimeout(() => {

            let view;
            let isNew;
            if (popup.view) {
                view = popup.view;
            } else {
                view = this._buildPopupView(config, event.target);
                popup.view = view;
                isNew = true;
            }
            
            if (!view) { return; }
            
            if (isNew) {
                if (config.openEvent === 'mouseenter') {
                    view.delegate('mouseenter', null, () => {
                        console.log('•popup mouseenter');
                        this.clearRemoveTimeout(popup, view)
                    });
                    view.delegate('mouseleave', null, () => {
                        console.log('•popup mouseleave');
                        popup.scheduleRemove();
                    });
                }
                
                view.on('destroy', () => {
                    console.log('#popup:destroy');
                    this.removePopup(popup, true);
                });

                this.setupPopup(popup);
            }
            

            this.attachPopupView(view, config, event.target);


        }, showDelay);


    },

    getPopup(index, config) {
        let popup = this._popups[index];
        if (popup) { return popup; }

        let isMouseEnter = config.openEvent === 'mouseenter';
        let removeDelay = config.removeDelay || 1000;
        popup = { index, removeBehavior: config.removeBehavior }

        popup.scheduleRemove = (...callArgs) => {
            console.log('#scheduleRemove', callArgs);
            this.clearShowTimer(popup);
            this.clearRemoveTimeout(popup);
            popup.removeTimeout = setTimeout(() => this.removePopup(popup), removeDelay);
        }


        if (isMouseEnter) {
            this.delegate('mouseleave', config.openSelector, () => {
                console.log('•mouseleave');
                popup.scheduleRemove();
            });
        }

        this._popups[index] = popup;
        return popup;
    },

    clearRemoveTimeout(popup, view) {
        console.log('   #clearRemoveTimeout', popup.removeTimeout);
        if (!popup.removeTimeout) { return; }
        clearTimeout(popup.removeTimeout);
        delete popup.removeTimeout;
    },

    clearShowTimer(popup) {
        console.log('   #clearShowTimer');
        if (!popup.showTimer) { return; }
        clearTimeout(popup.showTimer);
        delete popup.showTimer;
    },

    removePopup(popup) {

        if (popup._isDestroying === true) { return; }

        let forceDestroy = arguments.length >= 2 ? arguments[1] : undefined;

        let shouldDestroy = popup.removeBehavior !== 'detach' || forceDestroy;

        console.log('#removePopup');

        this.clearRemoveTimeout(popup);
        this.clearShowTimer(popup);

        if (popup.view) {
            if (shouldDestroy) {
                popup._isDestroying = true;
                popup.view.destroy();
                delete popup._isDestroying;
                delete popup.view;
                //delete this._popups[popup.index];
            } else {
                this.detachPopupView(popup.view);
                //throw new Error('detach popup view not implemented');
            }
        }
    },

    setupPopup(popup) {
        if (this.setupPopupView) {
            this.setupPopupView(popup.view);
        }
        if (this.view.setupPopupView) {
            this.view.setupPopupView(popup.view);
        }
    },

    _buildPopupView(config, triggerEl) {
        let opts = invokeValue(config.view, this, [this, triggerEl, config]);
        if (!opts) return;
        if (opts instanceof BbView) { return opts }

        let View = opts.class;
        let options = _.omit(opts, 'class');
        return this.buildPopupView(View, options, triggerEl, config)
    },

    buildPopupView(View, options, triggerEl, config) {
        if (config.addArrow) {
            return new PopupView({ contentView: View, contentOptions: options });
        }
        return new View(options);
    },


    attachPopupView(view, cfg, triggerEl) {

        !view.isRendered() && view.render();

        view.triggerMethod('before:attach');

        let popupContainer = _.result(cfg, 'containerEl') || document.body;

        popupContainer.appendChild(view.el);

        view._isAttached = true;

        view.triggerMethod('attach');

        this.triggerMethod('popup:attach', view, triggerEl, cfg);

    },

    detachPopupView(view) {
        view.triggerMethod('before:detach');

        view._isAttached = false;
        view.$el.detach();

        view.triggerMethod('detach');
    }

});

export const DynamicPositionPopup = BasePopup.extend({
    onPopupAttach(popupView, triggerEl, config) {
        // setTimeout(() => {
        // }, 0);
        this.updatePosition(popupView, triggerEl, config);
    },
    updatePosition(popupView, triggerEl, config) {
        let referenceEl = this.getReferenceElement(config, triggerEl);
        let { arrowSelector, popupOffset, popupShift, useShift } = config;
        let options = Object.assign({ arrowSelector, popupOffset, popupShift, useShift }, config.positionOptions);
        if (options.arrowSelector) {
            let arrowEl = popupView.$(options.arrowSelector).get(0);
            if (arrowEl) {
                options.arrowEl = arrowEl;
            }
        }
        computePosition(referenceEl, popupView.el, options);
    },
    getReferenceElement(config, eventEl) {
        let selector = config.referenceSelector || config.openSelector;
        if (selector) {
            let $el = this.$(selector);
            if ($el && $el.length) {
                return $el.get(0);
            }
        }
        return eventEl;
    },
    defaultPopupConfig: {
        addArrow: true,
        arrowSelector: '[data-role="arrow"]',
        popupOffset: 6,
        popupShift: { padding: 5 }
    }
});

export const SimplePopup = DynamicPositionPopup.extend({
    _getPopups() {
        let popups = [];
        let popup = this.view.getOption('hoverPopup');
        if (popup) {
            popups.push(popup);
        }
        return popups;
    }
});