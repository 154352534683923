import { Model } from 'vendors';
import { backendApi } from '../backend';
import appCfg from 'app-config';
const urlRoot = 'realties/presentation/';
export default {
    requestBlocks(offers, model) {
        const requestContext = { offers };
        model = model || new Model();
        model.requestContext = requestContext;
        let url = appCfg.urls.apiV(urlRoot + 'request-blocks');
        model.fetch = () => backendApi.post(offers, url, data => {
            model.set(data);
        }).then(data => model);

        return model.fetch();
    },
    createDocument(model) {
        if (!model) {
            throw new Error('pdf model should be defined');
        }
        let url = appCfg.urls.apiV(urlRoot + 'request-presentation');
        return backendApi.post(model.attributes, url);
    },
    downloadDocument(id) {
        let url = appCfg.urls.apiV(urlRoot + 'download/' + encodeURIComponent(id));
		window.open(url, '_blank');
    }
}