import { DropdownActions } from "coms/ui/DropdownActions";
import { HamburgerView } from "coms/ui/HamburgerView";
import { NextIconButtonView } from "coms/ui/Button";
import { CollectionView, Collection, View, TextView, Model } from 'core';
import { _ } from 'vendors';
import { createCompare } from "utils";
import refs from 'references';

function phoneToString(value)
{
	if (!value) return value;
	const chunks = value.split('.');
	if (chunks[0] !== '8') {
		chunks[0] = '+' + chunks[0];
	}
	if (chunks[2] && chunks[2].length > 5) {
		chunks[2] = chunks[2].substring(0, 3) + '-' + chunks[2].substring(3);
	}
	return chunks.join(' ');
}

function contactValueHtml(item)
{
	switch (item.type) {
		default: return item.value;
		case 'phone':
		case 'mobilePhone':
			return phoneToString(item.value);
	}
}


// import { getValueByPath } from "utils";
// import { EntityLabelView } from "./EntityLabelView";
const AddButton = NextIconButtonView.extend({
	thisClassName: 'two-icons',
	iconRight: 'fa:plus',
	icon: 'fa:user'
});


const ContactView = View.extend({
	className: 'contacts-list-item',
	// behaviors:[ PopoverBehavior ],
	template: `
		<div class="name-phone">
			<span class="name"><%- name %></span>
			<span class="value"><%= valueHtml %></span>
		</div>
		<div class="funcs"><%= roles %></div>
	`,
	getValueHtml() {
		let l1 = this.model.get('values') || [];
		let raw = l1.map(l => {
			const hash = Object.assign({}, l, l.value, {
				orderType: l.type == 'mobilePhone' 
				? 100
				: l.type == 'phone'
					? 50
					: l.type == 'email'
						? 25
						: 0
			});
			return hash;
			// return {
			// 	..._.omit(l, 'value'),
			// 	...(l.value || {}),
			// 	orderType: l.type == 'mobilePhone' 
			// 		? 100
			// 		: l.type == 'phone'
			// 			? 50
			// 			: l.type == 'email'
			// 				? 25
			// 				: 0
			// }
		});

		const comparator = createCompare([i => i.orderType, i => i.type])
		let first = _.sortBy(raw, comparator)[0];
		// console.log('RAW', raw, first);
		//_.sortBy(raw, (a,b) => bbeComparator(b, a, [i => i.orderType, i => i.type]))[0];
		
		if (!first) return '';

		return contactValueHtml(first);
	},
	templateContext() {
		//console.log(this.model.toJSON());
		const full = this.model.getByPath('person.name.full');
		const short = this.model.getByPath('person.name.short');
		return {
			name: full || short || '<имя не указано>',
			valueHtml: this.getValueHtml(),
			roles: refs.enum('realtyOwnerContactFunctions', this.model.get('ownerFuncs'))
		}
	}
});

const NoContactsView = TextView.extend({
	className: 'just-text',
	text: 'контакты не установлены'
});

const ContactsView = CollectionView.extend({
	className: 'contacts-list',
	emptyView: NoContactsView,
	childView: ContactView,
	initialize() {
		let models = this.model.get('contacts') || [];
		this.collection = new Collection(models, { model: Model });
	}
});

export const ContactsInfoLine = HamburgerView.extend({
	baseClassName: 'flex-row contacts box-row',
	childrenViews: [
		AddButton,
		ContactsView
	]
});