// define('ui-controls-tagsInput', 
// [
	
// ],
// function(

// ) {});

import { BbeCollection } from 'core/bbe';
import { MneView, MneCollectionView } from 'core/mne';

const TagView = MneView.extend({
	tagName: 'li',
	className: 'ui-list-item',
	template: _.template(`
		<button title="удалить">&times;</button>
		<span>
			<strong>#<%= id %></strong>
		</span>
	`),
	events: {
		'click button'() {
			this.model.isNew = () => true;
			this.model.destroy();
		}
	}
});

const TagsView = MneCollectionView.extend({
	tagName: 'ul',
	className: 'ui-list',
	childView: TagView,
});


const Control = MneView.extend({
	template: _.template(`
		<div class="input-box padding-v">
			<em>Для добавления тэга нажимайте кнопку <kb>Enter</kb> после ввода текста</em>
			<input type="text" class="form-control input-tag" />
		</div>
		<label class="margin-v">Выбранные теги: </label>
		<div class="added-tags padding-v"></div>
	`),
	regions: {
		'tags': '.added-tags'
	},
	initialize: function (opts) {
		this.mergeOptions(opts, ['modelType']);
		this.initializeValue(opts);
	},
	initializeValue() {

		let raw = [];			
		let val = this.getOption('initialValue');
		if (typeof(val) == 'string') {
			raw = val.split(';');
		} else if (Array.isArray(val)) {
			raw = val;
		}
		let models = raw.map(value => this.normalizeTagValue(value)).filter(f => !!f).map(id => ({ id }));
		// if (val) {
		// 	if (!Array.isArray(val)) {
		// 		val = this.normalizeTagValue(val);
		// 		if (val) {
		// 			this.value = [val]
		// 		}
		// 	} else {
		// 		let arr = _.map(val, value => this.normalizeTagValue(value));
		// 		arr = _.filter(arr, v => !!v);
		// 		raw = arr;
		// 	}
		// }
		// let models = _.map(raw, id => ({ id }));
		this.collection = new BbeCollection(models);
	},
	events: {
		'keyup .input-tag'(event) {
			if (event.which != 13) {
				return;
			}
			this.triggerNewTag(event.target.value);
			event.target.value = '';
		}
	},
	_updateValue() {
		let value = this.collection.map(m => m.id);
		//.join(';');
		this.triggerMethod('value:changed', value);
	},
	collectionEvents: {
		add() {
			this._updateValue();
		},
		remove() {
			this._updateValue();
		}
	},
	triggerNewTag(tag) {
		tag = this.normalizeTagValue(tag)			
		if (!tag) return;
		this.triggerMethod('new:tag', tag);
	},
	normalizeTagValue(value){
		if (value == null || value === '') {
			return;
		}
		value = value.trim().toLowerCase();
		value = value.replace(/[^a-zа-яё0-9]/gmi, '');
		return value || undefined;
	},
	onNewTag(id) {			
		this.collection.add({ id });
	},
	onRender() {
		let view = new TagsView({ collection: this.collection });
		this.showChildView('tags', view);
	}
});

export default Control;