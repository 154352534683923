﻿//define('svc-leads-stats-resultsView', ['behaviors', 'references', 'bus-svc', 'ui-controls-fastfilters', 'modals'], function (beh, refs, svcRadio, FastFilters, modals) {});

import beh from 'behaviors';
import refs from 'references';
import svcRadio from 'bus/svc';
import FastFilters from 'ui/controls/fastfilters';
import modals from '_libs/modals';

import {bbeViewComparator} from 'utils';

import { BbeModel, BbeCollection } from 'core/bbe';
	
import { MneObject, MneView, MneNextCollectionView } from 'core/mne';



var allSegments = [
	{ id: 'market', label: 'рынок', index:1 },
	{ id: 'operation', label: 'операция', index: 2 },
	{ id: 'source', label: 'источник', index: 3 },
	{ id: 'originType', label: 'тип лида', index: 4 },
	{ id: 'instrument', label: 'инструмент', index: 5 },
	{ id: 'realtyName', label: 'точка входа', index: 6 },
	{ id: 'geoName', label: 'география', index: 7 },
	{ id: 'stageId', label: 'стадия', index: 8 },
	{ id: 'ownerId', label: 'ответственный', index: 9 },
	{ id: 'square', label: 'метраж', index: 10 },
];

var SegmentButtonModel = BbeModel.extend({});

var SegmentCollection = BbeCollection.extend({
	model: SegmentButtonModel
});

var segmentsCollection = new SegmentCollection(allSegments);

var defaultSegments = ['source', 'market'];


var transforms = {
	'source': function (v) { return v; },
	'originType': function (v) { return refs.enum('leadOriginTypes', v) },
	'market': function (v) { return refs.enum('realtyMarkets', v) },
	'stageId': function (v) { return refs.enum('dealStages', v) },
	'ownerId': function (v) { return svcRadio.request('employeeName', v) },
	'square': function (v, m) { return m.squareName();  }
}




function getModelBySegment(segments, model, col) {
	var level = col.level != null ? col.level + 1 : 0;
	var field = segments[0];
	if (!field) return;
	var id = model.get(field) || 'без сегментации';

	if (field in transforms) {
		id = transforms[field](id, model);
	}


	var found = col.get(id);
	if (!found) {
		var childSegments = segments.slice(1);
		found = col.add({ id: id, segments: childSegments, level: level, segment: field });
	}
	return found;
}

var Segmentor = MneObject.extend({
	initialize: function (opts) {
		opts || (opts = {});
		this.mergeOptions(opts, ['collection', 'segments']);
		if (!this.segments || !this.segments.length)
			this.segments = ['none'];
	},
	add: function (model) {
		var segmentModel = getModelBySegment(this.segments, model, this.collection);
		segmentModel.add(model);
	}
});

var StatModel = BbeModel.extend({
	defaults: {
		level: 0
	},
	initialize: function () {
		this.children = new StatCollection();
		this.children.level = this.get('level');
		this.items = [];
	},
	add: function (model) {
		var segmentModel = getModelBySegment(this.get('segments'), model, this.children);
		if (segmentModel)
			segmentModel.add(model);

		this.count(model);
		this.items.push(model);
	},
	count: function (model) {
		var total = this.get('total') || 0;
		var completed = this.get('completed') || 0;
		var canceled = this.get('canceled') || 0;
		var processing = this.get('processing') || 0;

		total++;

		var status = model.get('status');

		switch (status) {
			case 'completed': completed++; break;
			case 'canceled': canceled++; break;
			case 'processing': processing++; break;
		}

		this.set({
			total: total,
			completed: completed,
			canceled: canceled,
			processing: processing
		}, {silent: true});

	},
	total: function () { },
	completed: function () { },
	canceled: function () { },
	processing: function () { },
});

var StatCollection = BbeCollection.extend({
	model: StatModel
});




var FunnelItemView = MneView.extend({
	className: 'funnel-item',
	behaviors: [beh.DynamicClass],
	dynamicClass: function () {
		var cls = [this.model.id];
		if (this.model.get('canceled')) {
			cls.push('has-canceled');
		}
		return cls.join(' ');
	},
	template: _.template('<div class="info"><div class="bar"><div style="width:<%= num(totalPercent, 0) %>%"></div></div><div class="name"><%= name %></div></div><div class="changes"><big><span><b><%= count %></b><i>достигло</i></span><span><b><%= perc(totalPercent,1) %></b><i>от общего</i></span></big><small><span class="canceled-indi"><b><%= num(canceled,0) %></b><i>потери</i></span><span class="canceled-indi"><b><%= perc(canceledPercent, 1) %></b><i>% потерь</i></span><span><b><%= num(inProcess,0) %></b><i>в работе</i></span><span><b><%= perc(inProcessPercent, 0) %></b><i>% в работе</i></span></small></div><i></i>'),
	templateContext: function () {
		return {
			perc: function () {
				var value = this.num.apply(this, arguments);
				if (_.isNumber(value)) {
					return value + '%';
				} else {
					return value;
				}
			},
			num: function (value, round, ifEmpty) {
				
				if (ifEmpty == null) {
					ifEmpty = '&ndash;';
				}
				if (round == null) {
					round = 0;
				}
				if (_.isNumber(value) && !isNaN(value)) {
					var koef = Math.pow(10, round);
					var ready = Math.round(value * koef) / koef;
					if (isNaN(ready)) {
						return ifEmpty;
					} else {
						return ready;
					}
				} else {
					return ifEmpty;
				}
			}
		}
	},
	events: {
		'click'() {
			console.log(this.model.attributes);
		}
	}
});

var SellFunnelLayout = MneView.extend({
	className: 'sell-funnel',
	template: _.template('<div></div>'),
	regions: {
		'items':'div'
	},
	onRender: function() {
		this.showFunnel();
	},
	showFunnel() {
		var items = this.model.items;			
		var col = this._getFunnelCol(items);
		var view = new MneNextCollectionView({
			className: 'funnel-items',
			collection: col,
			childView: FunnelItemView,
			emptyView: MneView.extend({ template: _.template('нет данных') }),
		});
		this.showChildView('items', view);

	},
	_getFunnelCol(items) {
		if (!items || !items.length) {
			return new BbeCollection();
		}

		var stages = [
			{
				id: "initial", name: "новый"
			},
			{
				id: "qualify", name: "квалификация"
			},
			{
				id: "search", name: "подбор"
			},
			{
				id: "offer", name: "предложение"
			},
			{
				id: "show", name: "показы"
			},
			{
				id: "negotiations", name: "переговоры"
			},
			{
				id: "deal", name: "сделка"
			},
			{
				id: "income", name: "комиссия"
			},
			{
				id: 'done', name: "успех"
			}
		];

		var fm = {
			info: {
				completed: 0,
				canceled: 0,
				inProcess: 0,
			},
			stages: [],
			_stagesById: {},
		};

		_.each(items, function (item) {

			_.some(stages, function (stage) {
				var fmstage = fm._stagesById[stage.id];

				if (!fmstage) {
					fmstage = _.clone(stage);
					fmstage.count = 0;
					fmstage.canceled = 0;
					fmstage.inProcess = 0;
					fm.stages.push(fmstage);
					fm._stagesById[stage.id] = fmstage;
				}

				fmstage.count++;

				if (stage.id === item.get('stageId')) {

					if (item.get('status') === 'canceled') {
						fmstage.canceled++;
						fm.info.canceled++;
					}

					if (item.get('status') != 'canceled' || item.get('status') != 'completed') {
						fmstage.inProcess++;
					}
					if (item.get('status') != 'completed') {
						return true;
					}
				}

				if (stage.id === 'done') {
					fm.info.completed++;
				}

			});

		});
		var total = items.length;
		var prev;
		_.each(fm.stages, function (stage, index) {
			if (index === 0) {
				stage.totalPercent = 100;
				stage.changeValue = '';
				stage.changePercent = '';					
			} else {
				stage.totalPercent = stage.count * 100 / total;
				stage.changeValue = stage.count - prev.count;
				stage.changePercent = stage.count * 100 / prev.count - 100;
			}
			stage.canceledPercent = stage.canceled * 100 / stage.count;
			stage.inProcessPercent = stage.inProcess * 100 / total;
			prev = stage;
		});		
		
		var col = new BbeCollection(fm.stages);
		col.info = fm.info;
		return col;
	}
});

var LineView = MneView.extend({
	tagName: 'span',
	behaviors:[beh.DynamicClass],
	className: function () {
		var level = this.model.get('level') || 0;
		var cls = ['stat-item', 'level' + level];
		// if (level < 2) {
		// 	cls.push('uncollapse');
		// }
		return cls.join(' ');
	},
	dynamicClass: function () {
		return this.model.uncollapse ? 'uncollapse' : '';
	},
	initialize() {
		var level = this.model.get('level') || 0;
		if (level < 2) {
			this.model.uncollapse = true;
		}
	},
	template: _.template('<b <%=offset%>></b><% if(hasChildren) {%><button><i></i></button><%}%><i><%= label %></i><i><%- total %></i><i><%- completed %></i><i><%- canceled %></i><i><%- processing %></i>'),
	events: {
		'click > button': function (e) {
			console.log('>.<');
			e.stopPropagation();
			this.model.uncollapse = !this.model.uncollapse;
			this.triggerMethod('refresh:css:class');
		},
		'click': function () {
			var view = new SellFunnelLayout({ model: this.model });
			modals.modal(view);
			//console.log(this.model);
		}
	},
	templateContext: function () {
		var style = '';
		var level = this.model.get('level') || 0;
		var styleNoBtn = '';
		if (level) {
			style = ' style="margin-left:' + (level * 10) + 'px"';
			styleNoBtn = ' style="margin-left:' + (15 + level * 10) + 'px"';
		}
		var label = this.model.id || '&mdash;';
		var hasChildren = !!this.model.children.length;
		var offsetVal = (level * 10) + (!hasChildren ? 21 : 0);

		if (hasChildren)
			styleNoBtn = '';
		return {
			style: style,
			styleNoBtn: styleNoBtn,
			hasChildren: hasChildren,
			label: label,
			offset: ' style="width:'+ offsetVal + 'px;"',
		}
	}
});

var StatLine = MneView.extend({
	tagName: 'li',
	template: _.template('<span>...</span><% if(children) {%><ul></ul><% } %>'),
	regions: {
		'line': { el: '> span', replaceElement: true },
		'children': { el: '> ul', replaceElement: true },
	},
	onRender: function () {
		this.showLine();
		this.showChildren();
	},
	showLine: function () {
		var view = new LineView({
			model: this.model
		});
		this.showChildView('line', view);
	},
	showChildren: function () {
		if (!this.model.children.length) return;

		var view = new StatsList({
			collection: this.model.children,
		});
		this.showChildView('children', view);
	},
	templateContextExtend: function () {
		return {
			children: !!this.model.children.length,
		}
	}
})

var StatsList = MneNextCollectionView.extend({
	childView: StatLine,
	tagName: 'ul',
	viewComparator: function (v1, v2) {
		return bbeViewComparator(v2, v1, function (m) { return !m ? Infinity : m.get('total'); })
	},
	onRender: function () {
		var add = this.getOption('addThis');
		if (!add) return;
		this.addChildView(add, 0);
		this.$el.addClass('stats-ul');
	}
});

var StatsContainer = MneView.extend({
	className:'block uncollapsed',
	template: _.template('<h3><span>3:</span> изучайте статистику</h3><div class="statistika"></div>'),
	regions: {
		'stats':'.statistika',
	},
	onRender() {
		var view = this.statsView = new StatsList({
			collection: this.collection,
			addThis: this.getOption('addThis'),
		});
		this.showChildView('stats', view);			
	}
});


var StatsHeader = MneView.extend({
	tagName: 'li',
	template: _.template('<span class="stat-item header"><i>сегмент</i><i>всего</i><i>завершено</i><i>отменено</i><i>в работе</i></span>')
});


var SegmentItem = MneView.extend({
	tagName: 'button',
	className: 'segment-button',
	template: _.template('<% if (swap) { %><i class="start"></i><% } %><span><%= label %></span><% if (swap) { %><i class="end"></i><% } %>'),
	triggers: {
		'click span': 'clicked',
		'click .start': 'to:start',
		'click .end': 'to:end',
	},
	templateContext() {
		return {
			swap: this.getOption('swap')
		}
	}
});

var SegmentsView = MneView.extend({
	className: 'block offset-b segments-buttons',
	template: _.template('<h3><span>2:</span> установите порядок сегмментации статистики</h3><section><b>добавление сегмента</b><div class="available"></div></section><section><b>установленная сегментация</b> <small>(применяется слева направо)</small><div class="active"></div></section><footer><button>применить</button></footer>'),
	initialize: function () {
		this.segments = this.getOption('segments');
	},
	regions: {
		'active': '.active',
		'available': '.available'
	},
	onRender: function () {
		this.showActive();
		this.showAvailable();
	},
	showActive: function () {
		

		var _this = this;
		var segments = this.segments;

		var getIndex = function(v) {
			var m = v.model;
			return segments.indexOf(m);
		}

		var view = new MneNextCollectionView({
			childView: SegmentItem,
			collection: segments,
			childViewOptions: {
				swap: true
			},
			childViewEvents: {
				'clicked': function (v) {
					this.collection.remove(v.model);
					_this.segments.trigger('refresh');
					//_this.reflectUrl(this.collection.pluck('id'));
					_this.availableSegmentsView.sort();
					//_this.remove(v.model.id);
				},
				'to:end': function (v) {
					var index = getIndex(v);
					index++;
					this.collection.remove(v.model);
					this.collection.add(v.model, { at: index });
					_this.segments.trigger('refresh');
					//_this.reflectUrl(this.collection.pluck('id'));
				},
				'to:start': function (v) {
					var index = getIndex(v);
					index--;
					if (index < 0) { index = 0; }
					this.collection.remove(v.model);
					this.collection.add(v.model, { at: index });
					_this.segments.trigger('refresh');
					//_this.reflectUrl(this.collection.pluck('id'));
				},
			},

		});
		this.showChildView('active', view);

	},
	showAvailable: function () {
		//var _this = this;
		var segments = this.segments;
		var view = this.availableSegmentsView = new MneNextCollectionView({
			childView: SegmentItem,
			collection: segmentsCollection,				
			viewFilter: function (v) {
				return segments.indexOf(v.model) < 0;
			},
			viewComparator: function (v1, v2) {
				return bbeViewComparator(v1, v2, function (v) { return v.get('index'); })
			},
			childViewEvents: {
				'clicked': function (v) {
					segments.add(v.model);
					segments.trigger('refresh');
					//_this.reflectUrl(segments.pluck('id'));
					this.sort();
				}
			},

		});
		this.showChildView('available', view);
	},
	//reflectUrl(segments) {
	//	var filters = this.getOption('filters');
	//	var hash = filters.getUrlHash() || {};
	//	hash.segments = segments;
	//	var search = $.param(hash);
	//	changeUrlSearch(search);
	//},
	add: function (id) {
		if (this.segments.indexOf(id) > -1) return;
		this.segments.push(id);
		this.render();
	},
	remove: function (id) {
		var index = this.segments.indexOf(id);
		if (index == -1) return;
		this.segments.splice(index, 1);			
		this.render();
	},
	triggers: {
		'click footer > button':'apply:segments'
	},
	events: {
		'click > h3': function () {
			this.$el.toggleClass('uncollapsed');
		}
	}

});

var FiltersView = MneView.extend({
	className:'block offset-b',
	template: _.template('<h3><span>1:</span> отфильтруйте лиды</h3><section></section>'),
	regions: {
		'items':'section'
	},
	onRender() {
		var view = FastFilters.create({
			className: 'filters-items',
			filters: this.getOption('filters'),
			onlyPined: true,
			noHeader: true,
		});
		this.showChildView('items', view);
	},
	events: {
		'click > h3': function () {
			this.$el.toggleClass('uncollapsed');
		}
	}
});


var StatsView = MneView.extend({
	className: 'leads-stats',
	behaviors: [beh.FilteredCollection],
	template: _.template('<div class="filters-region"></div><div class="segments-region"></div><div class="stats-region"><div class="block"><h3>Установите фильтры и порядок сегментации</h3></div></div>'),
	regions: {
		'filters': '.filters-region',
		'stats': '.stats-region',
		'segments': '.segments-region'
	},
	initialize: function () {
		this.stats = new StatCollection();

		//var filters = this.getOption('filters');
		//var urlHash = filters.getUrlHash() || {};

		var urlHash = this.getOption('urlHash');
//				urlObj(document.location.search.substring(1));
		var segmentsIds = urlHash.segments || defaultSegments;
		let models = segmentsCollection.filter(function (m) { return segmentsIds.indexOf(m.id) > -1; });

		models.sort(function (m1, m2) { return segmentsIds.indexOf(m1.id) - segmentsIds.indexOf(m2.id); });
		this.segments = new SegmentCollection(models);
	},
	onRender: function () {
		this.onFirstRender();
		this.showFilters();
		this.showSegments();
	},
	onFirstRender: function () {
		if (this.firstRenderWas) return;

		var filters = this.getOption('filterObject');
		var url = filters.getUrlHash();
		if (url && _.size(url) > 0) {
			this.triggerMethod('filters:apply');
		}

		this.firstRenderWas = true;
	},
	onFiltersDataChange: function (data) {
		//console.log('args', arguments);
		var opts;
		if (data)
			opts = { data: data }

		var _this = this;
		this.collection.loudFetch(opts).then(function () {
			_this.triggerMethod('stats:fetched');
		});
	},
	getSegmentor: function () {
		var segments = this.segments; // || defaultSegments;
		return new Segmentor({
			segments: segments.pluck('id'),
			collection: this.stats,
		});
	},
	onStatsFetched: function () {
		this.recalcStats();
	},
	recalcStats: function () {
		if (this.statsView)
			this.statsView.destroy();

		this.stats.reset();
		var segmentor = this.getSegmentor();
		this.collection.each(function (model) {
			segmentor.add(model);
		});

		this.triggerMethod('stats:ready');
	},
	onStatsReady: function () {
		//if (this.statsView) return;
		var header = new StatsHeader();
		var view = this.statsView = new StatsContainer({
			collection: this.stats,
			addThis: header,
		});
		this.showChildView('stats', view);
		//console.log('stats', this.stats);
	},
	showFilters() {
		var view = new FiltersView({ filters: this.getOption('filterObject') });
		this.showChildView('filters', view);
	},
	showSegments: function () {
		var view = new SegmentsView({ segments: this.segments, filters: this.getOption('filterObject') });
		this.showChildView('segments', view);
	},
	childViewEvents: {
		'apply:segments': function (v) {
			//this.segments = v.segments.slice(0);
			this.recalcStats();
		}
	}
});

//StatsView.changeUrlSearch = changeUrlSearch;
//StatsView.urlObj = urlObj;
export default StatsView;
