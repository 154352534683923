import { HamburgerView } from "coms/ui/HamburgerView";
import { View } from 'core';
import { VisibilityButton } from "coms/visibility-button";
// import { ObjectBrokerVisibilityButton, ObjectAdvertVisibilityButton } from "mods/realties/objects/views";
import { DropdownActions } from "coms/ui/DropdownActions";
import { createCompare } from 'utils';
import { mediaApi } from "apis/media";

import { GroupColumn, SelectButton, Separator, SingleValue, MultyValues, GroupRow, PlateActions, LastActualize, executeClickActionMixin, ActualizeBlock } from "./common";
import { OffersContainer } from "./OffersContainer";




const SquareFloor = View.extend({
	
	baseClassName: 'square-floor',
	template: '<div><%= floor %><%= square %></div><div><%= room %></div>',
	displaySchemaKeys: {
		'info.floor':'floor', 
		'info.squareOffer': 'square', 
		'info.roomNumber':'room', 
	},
	templateContext() {
		const h = this.getDisplayHash({ wrapEmptyValue: false })
		return h;
	}
});



const PurposeView = SingleValue.extend({
	// property: 'info.purposes',
	withLabel: true,
	classNames: [
		v => v._noPurposeCssClass(),
	],
	_noPurposeCssClass() {
		if (!this.property) { return; }
		const noValuePrefix = this.getOption('noValuePrefix') || '';
		const value = this.model.getByPath(this.property);
		if (!value || value === 'none') {
			return noValuePrefix + '-none';
		}
	},
});






const ObjectPhotos = View.extend({
	baseClassName: 'images',
	template: '<div class="main-image"><%= main %></div><div class="plan-image"><%= plan %></div>',
	templateContext() {
		return {
			main: this.getMain(),
			plan: this.getPlan(),
		}
	},
	getImages(filter) {		
		const medias = this.model.get('medias') || [];
		if (medias.length) {
			console.error('medias', medias);
		}
		medias.sort(createCompare([m => m.isPrimary ? -1 : 0, m => m.order * -1]));
		return medias.filter(filter);
	},
	getMain() {
		const allowedTypes = {
			commonImage: 1,
			mainImage: 1,
		}
		const filter = f => f.type in allowedTypes;
		const images = this.getImages(filter);
		if (images.length) {
			const main = images[0];
			if (main) {
				return this._buildImageHtml(main, 'основное фото');
			}
		}
		return '<span>нет фото</span>';
	},
	getPlan() {
		const filter = f => f === 'planImage';
		const images = this.getImages(filter);
		if (images.length) {
			const plan = images[0];
			if (plan) {
				return this._buildImageHtml(plan, 'планировка');
			}
		}
		return '<span>нет планировки</span>';
	},
	_buildImageHtml(img, title) {
		const url = mediaApi.url(img.hash, "w70-h70-ls", "jpg");
		return `<img src="${url}" title="${title || ''}"/>`;
	}
});







const ObjectBlock = HamburgerView.extend({
	...executeClickActionMixin,
	clickAction: 'openpage',	
	baseClassName: 'object-block',
	thisClassName: 'clickable-block',
	tagTitle: 'Этаж, Метраж, Номер кабинета\r\nКликните чтобы перейти на страницу объекта',
	childrenViews: [
		{ class: PurposeView, property: 'info.purposes', withLabel: false, },
		SquareFloor,
	]
});

const ObjectsActions = PlateActions.extend({
	type: 'objects'
});

const MainInfoView = HamburgerView.extend({
	baseClassName: 'flex-row centered main-info',
	childrenViews: [
		ObjectsActions,
		{ class: SelectButton, thisClassName: 'object' },
		ObjectBlock
	],
	childViewTriggers: {
		'toggle:select':'toggle:object:select',
		'multy:action:click':'multy:action:click'
	}
})

const PropertiesView = HamburgerView.extend({
	...executeClickActionMixin,
	clickAction: 'edit',
	baseClassName: 'flex-row wrap-on centered',
	thisClassName: 'clickable-block inner-margin-3',
	tagTitle: 'Кликните для редактирования основных характеристик',
	childrenViews: [
		{ 
			class: MultyValues,
			baseClassName: 'object-details table-properties-column', 
			properties: ['status', 'info.state', 'info.spaceLayout'],
			childViewOptions: {
				withLabel: true
			}
		},
		// { 
		// 	class: MultyValues,
		// 	baseClassName: 'object-details table-properties-column', 
		// 	properties: ['status', 'info.state', 'info.spaceLayout'],
		// 	childViewOptions: {
		// 		withLabel: true
		// 	}
		// },
	]
});

const ObjectView = HamburgerView.extend({
	baseClassName: 'flex-row wrap-on gap-on',
	initialize() {
		const offers = this.model.getOffers();
		this.listenTo(offers, 'add', this.render);
	},
	childrenViews: [
		MainInfoView,
		ObjectPhotos,
		PropertiesView,
		Separator,
		v => v.model.hasOffers() ? undefined : ActualizeBlock,
	],
	childViewTriggers: {
		'toggle:object:select':'toggle:object:select',
	},
	childViewEvents: {
		'multy:action:click'(type = 'objects', action, models) {
			this.trigger('multy:action:click', type, action, models);
		}
	}
});



export const ObjectListItem = HamburgerView.extend({
	baseClassName: 'object-list-item',
	updateElClassBeforeRender: true,
	classNames: [
		v => v.model.hasOffers() ? '' : 'no-offers',
		v => 'object-' + v.model.get('status'),
		v => v.model.hasAvailableOffers() ? '' : 'no-available-offers',
		v => v.getSelectedClassName()
	],
	modelEvents:{
		'change': 'render',
		'toggle:select':'updateClassName'
	},
	getSelectedClassName() {
		const selector = this.getOption('objectsSelector');
		return selector.isSelected(this.model) ? 'selected' : '';
	},
	childrenViews: [
		ObjectView,
		OffersContainer,
	],
	childViewOptions() {
		return {
			filterModel: this.getOption('filterModel'), 
			objectsSelector: this.getOption('objectsSelector'), 
			offersSelector: this.getOption('offersSelector'), 
		}
	},
	childViewEvents: {
		'toggle:object:select'(event) {
			this.trigger('toggle:object:select', event, this);
		},
		'toggle:offer:select'(event, offerView) { 
			this.trigger('toggle:offer:select', event, offerView, this);
		},
		'multy:action:click'(type = 'objects', action, models) {
			this.trigger('multy:action:click', type, action, models);
		}
	},
	events: {
		click() {
			this.model.getAvailableMarketsOperations();
		}
	}
});


/*




const StateInfo = View.extend({
	baseClassName: 'state-purposes',
	template: `<div><%= state %></div>`,
	displaySchemaKeys: {
		'info.state':'state', 
	},
	templateContext() {
		const h = this.getDisplayHash()
		return h;
	}
});


const ObjectInfoView = HamburgerView.extend({
	baseClassName: 'group-row',
	childrenViews: [
		SquareFloor,
		{ 
			class: MultyValues,
			baseClassName: 'object-details table-properties-column', 
			properties: ['status', 'info.state', 'info.spaceLayout'],
			childViewOptions: {
				withLabel: true
			}
		},
		{ 
			class: MultyValues,
			baseClassName: 'object-details table-properties-column', 
			properties: ['status', 'info.state', 'info.spaceLayout'],
			childViewOptions: {
				withLabel: true
			}
		},
	]
})

const StatusView = SingleValue.extend({
	property: 'status',
	withLabel: true,
});



const PossiblePurposes = SingleValue.extend({
	property: 'info.purposes',
	withLabel: true,
	classNames: [
		v => v._noPurposeCssClass('possible'),
	],
	_noPurposeCssClass(prefix) {
		const value = this.model.getByPath(this.property);
		if (!value || value === 'none') {
			return prefix + '-none';
		}
	},
});
const PurposesView = View.extend({
	baseClassName: 'purposes',
	classNames: [
		v => v._noPurposeCssClass('info.currentPurpose', 'current'),
		v => v._noPurposeCssClass('info.purposes', 'possible'),
	],
	_noPurposeCssClass(path, prefix) {
		const value = this.model.getByPath(path);
		if (!value || value === 'none') {
			return prefix + '-none';
		}
	},
	template: `<span class="current"><%= current %></span><span class="possible"><%= possible %></span>`,
	displaySchemaKeys: {
		'info.purposes': 'possible',
		'info.currentPurpose': 'current',
	},
	templateContext() { return this.getDisplayHash(); }
});

const SystemLine = HamburgerView.extend({
	baseClassName: 'group-row',
	thisClassName: 'system-group',
	childrenViews: [
		StatusView,
		{
			class: PurposeView,
			property: 'info.purposes',
			noValuePrefix: 'possible',
		},
		{
			class: PurposeView,
			property: 'info.currentPurpose',
			noValuePrefix: 'current',
		},
	]
})



const ObjectActions = Actions.extend({
	actionsName: 'object-actions'
});

const MainColumn = GroupRow.extend({
	childrenViews: [
		//SystemLine,
		// ObjectInfoView,
		{
			class: GroupRow,
			childrenViews: [
				ObjectActions,
				SelectButton,	
				SquareFloor,
			]
		},
		{ 
			class: MultyValues,
			baseClassName: 'object-details table-properties-column', 
			properties: ['status', 'info.state', 'info.spaceLayout'],
			childViewOptions: {
				withLabel: true
			}
		},
		{ 
			class: MultyValues,
			baseClassName: 'object-details table-properties-column', 
			properties: ['status', 'info.state', 'info.spaceLayout'],
			childViewOptions: {
				withLabel: true
			}
		},
	]
});

const MainLineView = HamburgerView.extend({
	className: 'flex-row main-line',
	childrenViews: [

		//ObjectPhotos,
		MainColumn,
		Separator,
		ObjectPhotos,
		{ class: VisibilityButton, label: 'брок.' },
		{ class: VisibilityButton, label: 'рек.' },
		
	]
});



*/