import { isKnownCtor } from './knownCtors';

export function invokeValue(value, invokeContext, invokeArgs) {

    if (value == null || typeof value !== 'function') {        
        return value;
    }

    if (isKnownCtor(value)) {
        return value;
    }

    if (invokeArgs == null) {
        return value.call(invokeContext);
    } else if (Array.isArray(invokeArgs)) {
        return value.apply(invokeContext, invokeArgs);
    } else {
        return value.call(invokeContext, invokeArgs);
    }


}

