import { isNotValue, isValue, isEmpty } from 'utils/is';
import { _, BbModel, BbCollection, MnObject, Application, MnView, CollectionView, NextCollectionView } from 'vendors';
import { MneView } from './view';
import { MneEmptyView } from './emptyView';
import { MneButtonView } from './buttonView';
import { MneMixedView } from './mixedView';
import { LoadmoreOnScrollCollectionView } from './loadmoreOnScrollCollectionView';
import { MneCollectionView } from './collectionView';




_.mixin({
	resultArg: function () {
		var args = [].slice.call(arguments);
		var context = args.shift();
		var field = args.shift();

		if (typeof context != 'object') return;
		if (!_.isFunction(context[field])) return _.result(context, field);
		if (args.length == 0) return _.result(context, field);

		return context[field].apply(context, args);
	}
});


_.mixin({
	lbl: function (obj, defVal) {
		defVal = defVal || '';
		var who = _.who(obj);

		if (!who.value) return defVal;
		if (who.primitive) return obj;
		if (obj instanceof Date) return obj.toString();

		if ('getLabel' in obj)
			return _.ifEmpty(_.v(obj.getLabel, obj), defVal);
		else if ('getName' in obj)
			return _.ifEmpty(_.v(obj.getName, obj), defVal);
		else if (who.bbc)
			return _.ifEmpty(obj.map(function (m) { return _.lbl(m); }).onlyValues().join(', '), defVal);
		else if (who.bbm && obj.has('label'))
			return _.ifEmpty(_.val(obj, 'label'), defVal);
		else if (who.bbm && obj.has('name'))
			return _.ifEmpty(_.val(obj, 'name'), defVal);
		else
			return _.ifEmpty(_.val(obj, 'label'), defVal);
		//defVal = defVal || '';
		//var who = _.who(obj);

		//if (!who.value) return defVal;
		//if (who.primitive) return obj;
		//if (obj instanceof Date) return obj.toString();

		//if ('getLabel' in obj)
		//	return _.ifEmpty(_.v(obj.getLabel, obj), defVal);
		//else if (who.bbc)
		//	return _.ifEmpty(obj.map(function (m) { return _.lbl(m); }).onlyValues().join(', '), defVal);
		//else
		//	return _.ifEmpty(_.val(obj, 'label'), defVal);
	},
	val: function (obj, property, defVal) {

		var who = _.who(obj);

		if (!who.value) return defVal;
		
		if (who.primitive && !property) return obj;
		if (who.primitive && !!property) return defVal;
		
		
		if ('getValue' in obj && !property) {
			const result = _.v(obj.getValue, obj);
			// console.log('-- --', result);
			return result
		}



		if (who.mn && !!property)
			return _.v(_.result(obj, property, obj.getOption(property)));
		else if (who.bbm && !!property && obj.has(property))
			return _.v(obj.get(property));
		else if (who.bbm && !!property && !obj.has(property))
			return _.v(MnView.prototype.getOption.call(obj, property));
		else if (who.bbm && !property && obj.has('value'))
			return _.v(obj.get('value'));
		else if (who.bbm && !property && obj.has('id'))
			return _.v(obj.get('id'));
		else if ((who.hash || who.array) && !!property && property in obj)
			return _.v(obj[property]);
		else if ('value' in obj)
			return _.v(obj.value);
		else if ('id' in obj)
			return _.v(obj.id);
		else
			return _.v(obj);
	},
	ifEmpty: function (obj, ifempty) {
		return isNotValue(obj) || obj === '' ? ifempty : obj;
	},
});

_.mixin({
	isBbc: function (obj) {
		return obj instanceof BbCollection;
	},
	isBbm: function (obj) {
		return obj instanceof BbModel;
	},
	isBb: function (obj) {
		return _(obj).isBbm() || _(obj).isBbc();
	},
	isMnv: function (obj) {
		return obj instanceof MnView;
	},
	isMncv: function (obj) {
		return obj instanceof CollectionView || obj instanceof NextCollectionView;
	},
	isMn: function (obj) {
		return _(obj).isMnv() || _(obj).isMncv();
	},
	isHash: function (obj) {
		if (!_.isObject(obj)) return false;

		return !_.isBb(obj) && !_.isMn(obj) && !_.isArray(obj);

	},
	who: function (obj) {

		var notEmpty = obj != null;
		var isObj = notEmpty && _.isObject(obj);

		var val = isValue(obj);
		var prim = Object(obj) !== obj;
		var bbm = _(obj).isBbm();
		var bbc = _(obj).isBbc();
		var bb = _(obj).isBb();
		var mn = _(obj).isMn();
		var mnv = _(obj).isMnv();
		var mncv = _(obj).isMncv();
		var arr = _(obj).isArray();
		var hash = _(obj).isHash();
		return {
			null: obj === null,
			undefined: obj === undefined,
			empty: obj == null,
			notValue: obj == null || obj === '',
			value: val,
			primitive: prim,
			bb: bb,
			bbm: bbm,
			bbc: bbc,
			mn: mn,
			mnv: mnv,
			mncv: mncv,
			array: arr,
			hash: hash,
			date: _.isDate(obj),
			range: isObj && ('from' in obj || 'to' in obj),
			single: isObj && ('value' in obj),
		}
	},
	compareHashs: function (a, b) {

		//console.log('start compare', a,b);


		if (isEmpty(a) && isEmpty(b)) return true;
		else if (isEmpty(a) !== isEmpty(b)) return false;

		var whoA = _.who(a);
		var whoB = _.who(b);

		//console.log('primitive compare...')
		if (whoA.primitive && whoB.primitive) return a.toString().toLowerCase() == b.toString().toLowerCase();
		else if (whoA.primitive !== whoB.primitive) return false;
		//console.log('not a primitive, then')


		//console.log('type compare', (typeof a === typeof b))
		if (typeof a !== typeof b) return false;

		//console.log('maybe array?');

		if (a instanceof Array && b instanceof Array) {
			var a1 = a.onlyValues();
			var b1 = b.onlyValues();
			var res = a1.length === b1.length && a1.length == _.intersection(a1, b1).length;
			//console.log('array compare:', res)
			return res;

		} else {
			//console.log('no, it isnt')
		}


		var keys = _([_.keys(a), _.keys(b)]).chain().flatten().uniq().value();
		//console.log('cheking keys', keys);
		var result = _(keys).every(function (key) {
			if (((key in a) != (key in b)) && isEmpty(a[key]) != isEmpty(b[key])) return false;
			var result = _.compareHashs(a[key], b[key]);
			return result;
		});
		//console.log('end compare')
		return result;

	},
});



//define('assets-marionette-namespace', [], function () {});

const Mne = {

    View: MneView,
    CollectionView: MneCollectionView,
    EmptyView: MneEmptyView,
    ButtonView: MneButtonView,
    MixedView: MneMixedView,
    LoadmoreOnScrollCollectionView,
    NextCollectionView,

    Application,
    Object: MnObject,
    behaviors: {},

};

window.Mne = Mne;

export { Mne }
