// define('svc/actualization/realties-list/realty/edit/properties/property-view', [
//     'references', 'behaviors',
//     'svc/actualization/realties-list/realty/edit/properties/utils',
//     'svc/actualization/realties-list/realty/edit/properties/boolean-control',
//     'svc/actualization/realties-list/realty/edit/properties/select-control',
//     'svc/actualization/realties-list/realty/edit/properties/multiselect-control',
//     'svc/actualization/realties-list/realty/edit/properties/number-control',
//     'svc/actualization/realties-list/realty/edit/properties/string-control',
//     'svc/actualization/realties-list/realty/edit/properties/text-control',
// ], function(
//     refs, beh, utils, 
//     BooleanControl, SelectControl, MultiselectControl, NumberControl, StringControl, TextControl
// ) {});
import bus from './bus';
import refs from 'references';
//import beh from 'behaviors';
import utils  from './utils';
import BooleanControl from './boolean-control';
import SelectControl from './select-control';
import MultiselectControl from './multiselect-control';
import NumberControl from './number-control';
import StringControl from './string-control';
import TextControl from './text-control';
import DatetimeControl from './datetime-control';

import { BbCollection } from 'vendors';

import { MnView } from 'vendors';
import { View } from 'core';

const Layout = View.extend({
	baseClassName: 'property-edit-view',
	template: `<div class="label-place"><%= label %></div><div class="control-place"></div>`,
	classNames: [
		v => v.model.get('isGroup') ? 'group' : '',
		v => v.isChanged() ? 'has-changes' : ''
	],
    // behaviors: [beh.DynamicClass],
    // dynamicClass() {
    //     let res = [];

    //     if (this.model.get('isGroup') == true) {
    //         res.push('group');
    //     }

    //     if (this.isChanged()) {
    //         res.push('has-changes');
    //     }

    //     return res.join(' ');
    // },
	regions: {
		control: '.control-place'
	},
	initialize() {
		this.entity = this.getOption('entity');
		this.listenTo(bus, 'model:changes', this._updateElClassName);
	},
	isChanged(changes) {
			
		if (arguments.length === 0) {
				changes = bus.getChanges();
		}
		if (!changes) return false;
		return this.model.id in changes;
	},
    // _onEntityChanges() {
    //     this.triggerMethod('refresh:css:class');
    // },
    onRender() {
			// console.warn('render ', this.model.id);
			let valueType = this.model.get('valueType')
			if (this.model.get('readonly')) {
				valueType = "readonly";
			}
			let controlOptions = this.getControlOptions();
			//console.log('#: ', valueType, this.model.id);
			switch(valueType) {
				case "boolean":
					this.showBoolean(controlOptions);
					break;
				case "enum":
					this.showEnum(controlOptions);
					break;
				case "number":
					this.showNumber(controlOptions);
					break;                    
				case "string":
					this.showString(controlOptions);
					break;                    
				case "text":
					this.showText(controlOptions);
					break;
				case "datetime":
					this.showDatetime(controlOptions);
					break;
				case "readonly":
					this.showReadonly(controlOptions);
					break;
			}
    },
    getEntityValue(defVal) {
			return utils.getValue(this.entity, this.model.id) || defVal;
    },
    showReadonly(controlOptions) {
			//let readonlyValue = this.getEntityValue('');
			let view = new MnView({
				template: () => controlOptions.value || ''
			});
			this.showControl(view);
    },
    showBoolean(controlOptions) {
			let view = new BooleanControl(controlOptions);
			this.showControl(view);
    },
    showNumber(controlOptions) {
        let view = new NumberControl(controlOptions);
        this.showControl(view);
    },        
    showString(controlOptions) {
			let view = new StringControl(controlOptions);
			this.showControl(view);
    },
    showDatetime(controlOptions) {
			let view = new DatetimeControl(controlOptions);
			// console.warn('=== date =?>', controlOptions);
			this.showControl(view);
		},
    showText(controlOptions) {
			let view = new TextControl(controlOptions);
			this.showControl(view);
    },        
    showEnum(controlOptions) {
			let enumType = this.model.get('valueSubtype') || this.model.get('valueSubType');
			if (!enumType) {
				console.warn('# enum type was not provided', this.model.attributes);
				return;
			}
			let enumKey = enumType[0].toLowerCase() + enumType.substring(1);
			let enumHash = refs.enum(enumKey);
			if (!enumHash) {
				console.warn('# enum was not found', enumKey, this.model.attributes);
				return;
			}

			let raw = Object.keys(enumHash).map(value => ({ id:value, value, label: enumHash[value] }));
			let collection = new BbCollection(raw);
			let options = Object.assign({}, controlOptions, { collection });
			let ViewClass = this.model.get('multiple') ? MultiselectControl : SelectControl;
			let view = new ViewClass(options);

			this.showControl(view);
    },
    showControl(view) {
			this.showChildView('control', view);
    },
    getControlOptions() {
			return {
				value: this.getEntityValue(),
				schema: this.model,
				entity: this.entity
			}
    },
    events: {
			'click .label-place'() {
				console.log(this.model.attributes);
			}
    }
});

export default Layout;
