﻿//define('realties-building-layout', ['bus', 'link', 'ui-controls-commentBoardList', 'realties-building-images', 'realties-building-objects'], function (Bus, link, CommentBoardList, ImagesLayout, ObjectsLayout){});

import CommentBoardList from 'ui/controls/commentBoardList';
import ImagesLayout from 'svc/realties/building/images';
import ObjectsLayout from 'svc/realties/building/objects';

import template from './layout.html';

import { MneView } from 'core/mne';

var Layout = MneView.extend({
	//template: '#tmpl-service-realties-building-layout',
	template,
	regions: {
		'comments': '.board-list-region.comments',
		'images': '.board-list-region.images',
		'objects': '.board-list-region.objects',
	},
	onRender: function () {
		var view = new CommentBoardList({ entity: 'realty', entityId: this.model.get('id') });
		this.showChildView('comments', view);

		var images = new ImagesLayout({ medias: this.model.get('medias') });
		this.showChildView('images', images);

		var objects = new ObjectsLayout({ objects: this.model.get('objects') });
		this.showChildView('objects', objects);

	}
});

export default Layout;