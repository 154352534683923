﻿//define('realties-offers-views-inList', ['references', 'realties-models', 'link', 'behaviors'], function (refs, rltNs, link, beh) {});
import { modelSchema } from 'mods/realties/offers/schema';
import refs from 'references';
import beh from 'behaviors';

import template from './inList.html';

import './deal-offer.less';
import './offer-list-item.less';
import './offer-view-list.less';

import offices from './popover/offices.html';
import retail from './popover/retail.html';
import warehouses from './popover/warehouses.html';

import { MneView } from 'core/mne';

import './popover/popover-offer-details.less';
import { propertySchemaApi } from 'apis/schema';
import { showOfferInModal } from '../utils';






	//(function () {

		// var listValueBuilder = function (v, post, pre) {
		// 	var res = '';
		// 	if (v == null) return res;
		// 	if (typeof v == 'number') {
		// 		res = v.toLocaleString('ru', { maximumFractionDigits: 1 });
		// 	} else {
		// 		res = v;
		// 	}
		// 	var arr = [];
		// 	arr.addValue(pre);
		// 	arr.addValue(res);
		// 	arr.addValue(post);
		// 	return arr.join(' ');
		// };
		// var listBuilder = function (v, l, post, pre) {
		// 	var res = listValueBuilder(v, post, pre);
		// 	if (!l)
		// 		return "<li>" + res + "</li>";
		// 	else
		// 		return "<li><small>" + l + "</small><span>" + res + "</span></li>";
		// }

		// return listBuilder;
	//})();

	const inListTemplates = {
		offices,
		retail,
		warehouses
	}


	var PopoverView = MneView.extend({
		getTemplate: function () {
			let type = this.model.get('market');
			return inListTemplates[type];
			//return '#tmpl-service-realties-offers-views-popover-' + this.model.get('market');
			//return _.template('<div></div>');
		},
		//initialize: function (opts) {
		//	this.mergeOptions(opts, ['clusterModel']);
		//},
		//regions: {
		//	'realties': '> div'
		//},
		onRender: function () {
			//console.log(this.clusterModel);
			//console.log('>>', this.model);
			//this.showRealties();
		},
	});


	var OfferPopoverBehavior = beh.BootstrapPopover.extend({
		content: PopoverView,
		//DEBUG:true,
		contentOptions: function () {
			return {
				model: this.view.model
			}
		},
		popoverTemplateContext: function(){
			return { popoverClass: 'popover-flat popover-offer-details bg-' + this.view.model.get('operation') };
		},
		placement: 'right auto',
		container: 'body',
	});







	var View = MneView.extend({
		/*
		_getPopoverTemplate: function (op) {
			return '<div class="popover popover-flat bg-' + (op || '') +'" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>';
		},
		_buildPopoverContent: function () {
			var m = this.model.toDeepJSON();
			var v = function (path) {
				return m.getValueByPath(path);
			};
			var e = function (path, en) {
				var val = v(path);
				return refs.enum(en, val) || "&mdash;";
			};
			var mdash = '&mdash;';
			var li = listBuilder;
			var l = '';

			l += li(e('object.info.state', 'realtyObjectStates'), 'состояние помещениея');
			if (m.market == 'offices') {
				l += li(e('object.info.spaceLayout', 'realtyLayouts'), 'планировка');
			} else if (m.market == 'warehouses') {
				l += li(v('object.warehouse.gatesCount') || mdash, 'количество ворот');
				l += li(v('object.warehouse.palletPlaces') || mdash, 'количество паллетомест');
				l += li(e('object.warehouse.heating', 'realtyObjectHeatings'), 'отапливаемсть');
				l += li(e('object.warehouse.features', 'warehouseFeatures'), 'особенности');
				l += li(e('object.warehouse.floorMaterial', 'warehouseFloors'), 'тип пола');
				l += li(e('object.warehouse.freezeEquipment', 'warehouseFreezeEquipments'), 'морозильное оборудование');
				l += li(e('object.warehouse.unloadingFeatures', 'warehouseUnloadingFeatures'), 'особенности разгрузки');
				l += li(e('object.warehouse.unloadingEquipment', 'warehouseUnloadingEquipments'), 'разгрузочное оборудование');
			}
			l += li(v('object.building.fullAddress'), 'адрес');

			var cls = [];
			cls.push('list-group');
			cls.push('bg-'+m.operation);

			return '<ul class="'+cls.join(' ')+'">' + l + '</ul>';
		},
		getPopoverContent: function () {
			if (this._popoverContent)
				return this._popoverContent;
			else
				return this._buildPopoverContent();
		},

		
		onBeforeRender: function () {
			if (!this.dynamicClassHash)
				this.dynamicClassHash = {};
		},
		dynamicClass: function () {
			var res = [];


			return res.join(' ');
		},
		onBeforeDestroy: function () {
			this.$el.popover('destroy');
		},
		*/
		behaviors: [beh.DynamicClass, beh.SelectedForActions.ChildView, OfferPopoverBehavior],
		className: function () {
			var cls = [
				'offer-list-item'
				, 'offer-view-list'
				, 'mo-' + this.model.get('operation')
				, 'mt-' + this.model.get('market')
				, 'status-' + this.model.get('status')
			];
			return cls.join(' ');
		},
		events: {
			//'mouseenter': function () {
			//	this.$el.popover({
			//		placement: this.getOption('popoverPlacement') || 'left',
			//		content: this.getPopoverContent(),
			//		container: 'body',
			//		html: true,
			//		//trigger: 'click',
			//		template: this._getPopoverTemplate(this.model.get('operation')),
			//	});
			//	this.$el.popover('show');
			//},
			//'mouseleave': function () {
			//	this.$el.popover('hide');
			//},
			'click': function () {
				//console.log('click');
				showOfferInModal(this.model);
				// this.model.actionOpenModal();
			},
		},
		//template: '#tmpl-service-realties-offers-views-inList',
		template,
		attributes: function () {
			//console.log(this.model);
			let title = refs.enum('realtyOperations', this.model.get('operation')) + ' ' + refs.enum('realtyMarkets', this.model.get('market'));
			return {
				'data-id': this.model.get('id'),
				title
			}
		},
		templateContext() {
			return {
				purposes: this.displayModelValue('obj.info.purposes'),
				realtyName: this.displayModelValue('realtyName'),
				engClassName: this.modelValue('realtyClassEng'),
				square: this.displayModelValue('squarePostfix'),
				priceMeter: this.displayModelValue('priceMeterPostfix'),
				contractType: this.modelValue('forAgent.contractType'),
				incomPercent: this.displayModelValue('forAgent.income'),
				_cid: this.cid,
			}
		},
		modelValue(key) {
			return propertySchemaApi.getValue(key, this.model, this.schema);
		},
		displayModelValue(key, options) {
			return propertySchemaApi.displayValue(key, this.model, this.schema, options);
		},
		initialize() {
			this.schema = modelSchema;
		}
	});

	export default View;