module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="bg-holder"></div>\n<div class="system">	\n	';
if(_notme(_m.responsibleId)){
__p+='\n	<span class="task-responsible">'+
((__t=(_empn(_m.responsibleId)))==null?'':__t)+
'</span>\n	';
}
__p+='\n	';
if(_m.checkerId && _notme(_m.checkerId)){
__p+='\n	<span class="task-checker">'+
((__t=(_empn(_m.checkerId)))==null?'':__t)+
'</span>\n	';
}
__p+='\n</div>\n<div class="task-info">\n	<span class="task-date" title="'+
((__t=(_fullDate(dates.deadline)))==null?'':__t)+
'">\n		<span class="task-date-value">'+
((__t=(_tfn(dates.deadline)))==null?'':__t)+
'</span>\n		<span class="task-date-help">'+
((__t=(_fromNow(dates.deadline)))==null?'':__t)+
'</span>\n	</span>\n	<i></i>\n	<span class="status">'+
((__t=( _enum(state,'employeeTaskStates')))==null?'':__t)+
'</span>\n</div>\n<div class="todo">'+
((__t=( _m.toDo ))==null?'':_.escape(__t))+
'</div>\n';
if(_m.lastComment){
__p+='<div class="comment"><i></i> '+
((__t=( _m.lastComment))==null?'':__t)+
'</div>';
}
__p+='\n';
if(_m.deal){
__p+='<div class="deal"><i></i> '+
((__t=( _m.deal.name))==null?'':__t)+
'</div>';
}
__p+='\n';
if(_m.client){
__p+='<div class="client"><i></i> '+
((__t=( _m.client.name))==null?'':__t)+
'</div>';
}
__p+='\n\n';
}
return __p;
};
