//define('acc/forgotPassword', ['appModule', 'link', '_libs/identity', 'acc/forgotPassword/layout', 'acc/forgotPassword/restore'], function(Module, link, identity, Layout, moduleRestore) {});

//let Module, link, identity, Layout, moduleRestore;

import Module from 'appModule';
import link from 'link';
//import identity from '_libs/identity';

import Layout from './layout';
import moduleRestore from './restore';

var module = Module.content({
    name: 'accForgotPassword',
    noHeaderMenu: true,
    anonymousAllowed: true,
    routeData: function() {
        return link.route('accForgotPassword',true);
    },
    onRun: function() {
        console.log('run', this.name);
        let footer = {
            options: {
                tagName: 'footer',
                template: _.template('<a href="/acc/login" class="system offset-r">перейти на страницу входа</a>')
            }
        }
        this.createAndShow(Layout, 'Восстановление пароля', {  }, { addClass:'short-module', footer });
    },
});

module.addSubModule(moduleRestore);
export default module;