import { Collection } from 'core';

export const Selector = Collection.extend({
	toggle(models) {
		if (models == null || typeof models !== 'object') {
			models = [];
		}
		if (!Array.isArray(models)) {
			models = [models];
		}
		return this._toggle(models);
	},
	_toggle(models) {
		const selected = [];
		const unselected = [];
		for (let model of models) {
			const exist = this.get(model);
			
			if (exist) {
				const processed = this.unselect(exist, unselected);
				if (processed) {
					unselected.push(processed);
				}
			} else {
				const processed = this.select(model, selected);
				if (processed) {
					selected.push(processed);
				}
			}
		}
		const result = this._triggerSelect(selected, unselected);
		return result;
	},
	_triggerSelect(selected, unselected) {
		const changes = (selected?.length || 0) + (unselected?.length || 0);
		const result = {
			selected,
			unselected,
			changes
		}
		this.trigger('select', result);
		return result;
	},
	unselect(model, silentArray) {
		const removed = this.remove(model);
		if (removed) {
			model.trigger('toggle:select', false, removed);
			if (!silentArray) {
				this._triggerSelect(undefined, [removed]);
			} else {
				silentArray.push(removed);
			}
			return removed;
		}
	},
	unselectAll() {
		const models = [...this.models];
		return this._toggle(models);
	},
	select(model, silentArray) {
		const added = this.add(model);
		if (added) {
			model.trigger('toggle:select', true, added);
			if (!silentArray) {
				this._triggerSelect(undefined, [added]);
			} else {
				silentArray.push(added);
			}
			return added;
		}
	},
	isSelected(model) {
		return this.has(model);
	}
});