import './object-card-layout.less';
import helpers from '../../../helpers';
import { MneView } from 'core/mne';

export default MneView.extend({
    className: 'object-card-layout',
    template: _.template(`
<div class="core-stats">
    <div>
        <label>Этаж</label>
        <div><%=  info.floor || '&mdash;' %></div>
    </div>
    <div>
        <label>Номер кабинета</label>
        <div><%=  info.roomNumber || '&mdash;' %></div>
    </div>
    <div>
        <label>Площадь, м<sup>2</sup></label>
        <div><%= _.displayNum(info.squareOffer, 1) %></div>
    </div>
</div>
<div class="links">
    <div class="row">
        <div>Собственник</div>
        <div>
            <label><%= owner.name %></label>
            <a href="<%= ownerUrl %>" class="as-button">редактировать</a>
        </div>
    </div>
    <div class="row">
        <div>Здание</div>
        <div>
            <label><%= realty.name %></label>
            <div><small><%= realty.address.fullAddress %></small></div>
            <a href="<%= realtyUrl %>" class="as-button">редактировать</a>
        </div>
    </div>
</div>
`),
    templateContextExtend() {
        let { realty, owner } = this.model.attributes;
        return {
            realtyUrl: helpers.getEditUrl('realty', realty.id),
            ownerUrl: helpers.getEditUrl('owner', owner.id),
        }
    }
});
