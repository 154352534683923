import { MnObject } from 'vendors';

import { getTypesHash, buildModel } from './privateApi';

const Factory = MnObject.extend({
	initialize: function () {
		this.store = {};
		this.types = getTypesHash();
	},

	setType: function (type, Model) {
		
		if (typeof type === 'function') {
			Model = type
			type = Model.prototype.factoryName;
		}

		if (type == null || typeof type !== 'string' || !type) {
			throw Error('Can`t set Type store, type is undefined');
		}

		if (!this.types[type]) {
			this.types[type] = {};
		}

		// this.types[type] || (this.types[type] = {});
		this.types[type].model = Model;
		
	},

	model: function (type, hash, options) {
        
		return buildModel.call(this, {
			type: type,
			hash: hash,
			options: options,
		});
	},
});

export const factory = new Factory();
