import { LimitedStore } from "./store";

const stores = new Map();

export const limitedStoreApi = {

    getStore(name, shouldThrow) {
        let store = stores.get(name);
        if (!store && shouldThrow) {
            throw new Error(name + ' not yet exists');
        }
        return store;
    },

    createStore(name, options) {
        let exist = this.getStore(name);
        if (exist) {
            throw new Error(name + ' already exists');            
        }
        let store = new LimitedStore(options);
        stores.set(name, store);
        return store;
    },


    getModel(storeName, id, shouldFetch, urlParams) {
        let store = this.getStore(storeName, true);
        return store.get(id, shouldFetch, urlParams);
    },

    getModelAsync(storeName, id, urlParams) {
        let store = this.getStore(storeName, true);
        return store.getAsync(id, urlParams);
    }

}