//define('svc/actualization/realties-list/filters', ['ui/models/filters/config', 'references'], function(FiltersConfig, refs){});


import FiltersConfig from 'ui/models/filters/config';
import refs from 'references';

const config = new FiltersConfig({
	allFiltersDisabled: true,
	filters: () => [
		{
			id: 'txt',
			group: 'base',
			order: -2,
			caption: 'текст',
			//values: ['offices'],
			emptyText: 'не задан',
			modelType: 'single',
			valueType: 'string',
			//sourceValues: refs.enum('realtyMarkets'),
			//flattenValues: true,
			//multiple: false,
			pinned: true,
		},
		{
			id: 'offid',
			group: 'base',
			order: -2,
			caption: 'ID предложения',
			//values: ['offices'],
			emptyText: 'не задан',
			modelType: 'single',
			valueType: 'string',
			//sourceValues: refs.enum('realtyMarkets'),
			//flattenValues: true,
			//multiple: false,
			pinned: true,
		},
		{
			id: 'act_status',
			group: 'base',
			order: 0,
			caption: 'статус актуализации',
			//values: ['offices'],
			emptyText: 'не выбран',
			modelType: 'single',
			valueType: 'string',
			sourceValues: refs.enum('actualizeDataStatuses'),
			flattenValues: true,
			multiple: false,
			pinned: true
		},
		{
			id: 'act_comment',
			group: 'base',
			order: 0,
			caption: 'комментарий актуализации',
			//values: ['offices'],
			emptyText: 'не указан',
			modelType: 'single',
			valueType: 'string',
			//sourceValues: refs.enum('actualizeDataStatuses'),
			//flattenValues: true,
			multiple: false,
			pinned: true
		},
		{
			id: 'cls',
			group: 'base',
			order: 1,
			caption: 'класс',
			multiple:true,
			emptyText: 'не установлен',					
			modelType: 'single',
			valueType: 'string',
			sourceValues: refs.enum('realtyClasses'),
			pinned: true,
		},
		{
			id: 'mt',
			group: 'base',
			order: 0,
			caption: 'относится к рынку',
			//values: ['offices'],
			emptyText: 'не выбран',
			modelType: 'single',
			valueType: 'string',
			sourceValues: refs.enum('realtyMarkets'),
			flattenValues: true,
			multiple: true,
			pinned: true
		},
		{
			id: 'rltt',
			group: 'base',
			order: 0,
			caption: 'тип недвижимости',
			//values: ['offices'],
			emptyText: 'не выбран',
			modelType: 'single',
			valueType: 'string',
			sourceValues: refs.enum('realtyTypes'),
			flattenValues: true,
			multiple: false,
			pinned: true
		},
		{
			id: 'bldt',
			group: 'base',
			order: 0,
			caption: 'тип строения',
			//values: ['offices'],
			emptyText: 'не выбран',
			modelType: 'single',
			valueType: 'string',
			sourceValues: refs.enum('buildingTypes'),
			flattenValues: true,
			multiple: false,
			pinned: true
		},
		// {
		// 	id: 'notags',
		// 	group: 'base',
		// 	//order: -2,
		// 	caption: 'не содержит тэги #',
		// 	//values: ['неработаем'],
		// 	emptyText: 'не заданы',
		// 	modelType: 'single',
		// 	valueType: 'string',
		// 	controlType: 'tagsInput',
		// 	flattenValues: true,
		// 	multiple: true,
		// 	pinned: true,
		// },
		// {
		// 	id: 'hastags',
		// 	group: 'base',
		// 	caption: 'содержит тэги #',
		// 	emptyText: 'не заданы',
		// 	modelType: 'single',
		// 	valueType: 'string',
		// 	controlType: 'tagsInput',
		// 	flattenValues: true,
		// 	multiple: true,
		// 	pinned: true,
		// },
		// {
		//     id: 'lastactualizedmax',
		//     group: 'base',
		//     //order: -2,
		//     caption: 'дата последней актуализации не позднее чем',
		//     emptyText: 'не указана',
		//     modelType: 'single',
		// 	valueType: 'datetime',
		// 	controlType: 'datetime2',
		//     multiple: false,
		// 	pinned: true,
		// },			
		// {
		//     id: 'lastactualizedmin',
		//     group: 'base',
		//     //order: -2,
		//     caption: 'дата последней актуализации не ранее чем',
		//     emptyText: 'не указана',
		//     modelType: 'single',
		// 	valueType: 'datetime',
		// 	controlType: 'datetime2',
		//     multiple: false,
		//     pinned: true,
		// },			
		// {
		// 	id: 'mt',
		// 	group: 'base',
		// 	order: 0,
		// 	caption: 'рынок',
		// 	//values: ['offices'],
		// 	emptyText: 'не выбран',
		// 	modelType: 'single',
		// 	valueType: 'string',
		// 	sourceValues: refs.enum('realtyMarkets'),
		// 	flattenValues: true,
		// 	multiple: false,
		// 	pinned: true
		// },
		// {
		// 	id: 'mo',
		// 	group: 'base',
		// 	order: 1,
		// 	caption: 'операция',
		// 	//values: ['rent'],
		// 	emptyText: 'не выбрана',
		// 	modelType: 'single',
		// 	valueType: 'string',
		// 	sourceValues: refs.enum('realtyOperations'),
		// 	multiple: false,
		// 	pinned: true
		// },			
	],
	groups: () => [
		{
			id: 'base',
			order: 100,
			name: 'основные'
		},			
	],
});


export default config;
