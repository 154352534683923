import { PopupOfferPropertiesView } from 'mods/realties/offers/popup-properties';
import { simplePopupMixin, popupView } from 'apis/popup';

export const offerPopupMixin = {
	
	...simplePopupMixin,

	hoverPopup: {
		autoUpdateEnabled: true,
		openEvent: 'mouseenter',
		removeDelay: 500,
		wrapContent: true,
		popupViewOptions: {
			thisClassName: 'offer-properties-popup'
		},
		view: {
			class: PopupOfferPropertiesView,
		},
		useFlip: true,
		positionOptions: {
			placement: 'left-start',
		}
	},
}