import { basePopupMixin } from "./basePopupMixin";

export const simplePopupMixin = {
    ...basePopupMixin,
    getPopupConfigs() {
        let popups = [];
        let popup = this.getOption('hoverPopup');
        if (popup) {
            popups.push(popup);
        }
        return popups;
    },
}