import { _, Model, Collection, Radio } from 'vendors';
import { hashToPatch } from 'utils/hashToPatch';

import { validateMixin } from './validate';
import { loudModelMixin } from './loud';
import { toDeepJSON3 } from './toJSON';

const coreRadio = Radio.ModelsBus;

let originalFetch = Model.prototype.fetch;


const BaseModelMixin = {
	patch: function (key, val, options) {
		var attrs;
		var notSet = false;
		if (key == null || typeof key === 'object') {
			attrs = key;
			options = val;
		} else {
			(attrs = {})[key] = val;
		}

		if (attrs == null) {
			var changed = this.changedAttributes();
			if (changed) {
				options.notSet = true;
				attrs = changed;
			}
		}

		options = _.extend({}, {
			deepKeys: true,
			wait: true,
			patch: true,
		}, options);

		if (!('notSet' in options))
			options.notSet = notSet;

		options.attrs = hashToPatch(attrs, options);
		//var model = this;
		return this.save(null, options).then(function () {});
	},
	getFetchId: function () {
		if (this._hasWebId && _.isFunction(this.getWebId))
			return this.getWebId();
		else
			return this.getId();
	},
	getId: function () {
		if (!this.idAttribute) return;
		var key = _.result(this, 'idAttribute');
		return this.get(key);
	},
	setId: function (value) {
		var idKey = this.idAttribute || 'id';
		this.set(idKey, value);
	},
	toDeepJSON: function () { return toDeepJSON3(this); }, 
	save: function (key, val, options) {
		var attrs;
		if (key == null || typeof key === 'object') {
			attrs = key;
			options = val;
		} else {
			(attrs = {})[key] = val;
		}
		options || (options = {});
		options.deepJson = true;
		return Model.prototype.save.call(this, attrs, options);
	},
	toJSON: function ({ deepJson, improvedJson, cidHash } = {}) {
		if (deepJson)
			return this.toDeepJSON();
		else if (improvedJson) 
			return this.toJSON2({ cidHash });
		else
			return Model.prototype.toJSON.call(this);
	},
	toJSON2({ cidHash = {} } = {}) {
		cidHash[this.cid] = true;
		return _.reduce(this.attributes, (memo, value, property) => {

			if (value instanceof Model || value instanceof Collection) {
				if (cidHash[value.cid]) return memo;
				memo[property] = value.toJSON({ improvedJson: true, cidHash }); 
			} else if (value && typeof value.toJSON === 'function') {
				memo[property] = value.toJSON({ improvedJson: true, cidHash }); 
			} else if (typeof value === 'function') {
				// skip
			} else {
				memo[property] = value;
			}
			return memo;
		}, {});
	},
	getRadio: function () {
		var prefix = _.result(this, 'channel');
		if (!prefix) return;
		return Radio.channel(coreRadio + ":" + prefix);
	},

	//_fetch: originalFetch,

	isFetched: function () {
		return !!this.lastFetch;
	},

	fetch: function () {
		let m = this;
		m._isFetchingNow = true;
		// this.once('sync', () => {
		// 	this._isFetchingNow = false;
		// });

		return originalFetch.apply(this, arguments).then(function(res){
			m._isFetchingNow = false;
			m.lastFetch = new Date();
			m.trigger('fetched', m);
			return res;
		}, function (xhr) {
			m._isFetchingNow = false;
			return xhr;
		});
	},
	isFetchingNow() {
		return this._isFetchingNow == true;
	}
}

export const modelMixin = _.extend({}, BaseModelMixin, loudModelMixin, validateMixin);


