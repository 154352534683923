module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header>'+
((__t=(label))==null?'':__t)+
'</header>\n<footer>\n	<a href="'+
((__t=(href))==null?'':__t)+
'" '+
((__t=(skipRoute))==null?'':__t)+
'>перейти в раздел <span></span></a>\n</footer>\n';
}
return __p;
};
