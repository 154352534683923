﻿//define('ui-controls-input',['bus'],function(Bus){});

import Bus from 'bus';
import './input.less';

import { MneView } from 'core/mne';

var View = MneView.extend({
	className: 'input-control',
	template: _.template(`
		<% if (description) { %><div class="input-description"><%= description %></div><% } %>
		<% if (!big) {%><input class="form-control" type="<%=type%>" value="<%=value%>" /><% } %>
		<% if (big) { %><textarea class="form-control"><%=value%></textarea><% } %>
	`),
	events: {
		'input input': 'updateValue',
		'input textarea': 'updateValue',
		'focus input': function (e) {
			$(e.target).select();
		},
		'keypress input'(e) {
			// console.warn(this.preventPattern);
			if (this.preventPattern) {
				if (this.preventPattern.test(e.key)) {
					e.preventDefault();
				}
			}
		}
	},
	updateValue: function (e, silent) {
		e.stopPropagation();
		var val = e.target.value;

		if (this.valueType == 'number') {
			if (val !== '')
				val = parseFloat(val, 10);
			else
				val = undefined;
		}

		this.value = val;

		if (silent === true) return;
		this.triggerMethod('value:inputed', this.value, this.viewName);
		this.triggerMethod('value:changed', this.value, this.viewName);
	},
	onRender: function () {
		var $inp = this.$(this.inputTagName);

		if (this.doNotFocus !== true) {
			setTimeout(function () {
				$inp.focus();
				$inp.select();
			}, 0);
		}

		//debugger;
		var attrs = this.getOption('inputAttributes');
		if (attrs) {
			_.each(attrs, function (val, key) {
				$inp.attr(key, val);
			});
			//this.$('input').attrs(attrs);
		}
	},
	initialize: function () {
		const { preventPattern } = (this.getOption('inputAttributes') || {});
		this.preventPattern = preventPattern ? new RegExp(preventPattern) : undefined;
		// console.error('INPUT', this.options);
		this.valueType = this.getOption('valueType') || 'string';
		this.value = this.getOption('initialValue');
		this.isBig = this.valueType === 'bigtext';
		this.inputTagName = this.isBig ? 'textarea' : 'input';
		this.doNotFocus = this.getOption('doNotFocus');
		//this.delegate('input', this.inputTagName, _.debounce(this.updateValue.bind(this), 300));
	},
	refreshValue(arg) {
		var value = arg instanceof Array ? arg[0] : arg;
		value = _.isObject(value) ? null : value === undefined ? '' : value.toString();
		if (value == null) return;

		this.$('input').val(value);
		this.$('input').trigger('change', true);
	},


	templateContextExtend: function () {

		let valueType = this.getOption('valueType');
		let value = this.value;
		let label = this.getOption('valueType');

		var type = this.getOption('type')
			|| valueType;

		if (type == 'string')
			type = 'text';

		return {
			type: type,
			valueType: valueType,
			value: value,
			label: label,
			big: this.isBig,
			description: this.getOption('editDescription', true)
		}
	}
});

View.create = function (opts) {
	return new View(opts);
}

View.createFromEditContext = function (ec, opts) {
	return new View(_.extend({}, ec.options, opts));
}


Bus.Views.reply('ui:input', function () {
	return View;
});

export default View;