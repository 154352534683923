import { TabTriggerView } from "./tab-trigger-view";
import { TabsCollection } from "./tabs-collection";

import { collectionActiveTabKey } from './config';
import { MneNextCollectionView } from 'core/mne';
export const TabTriggersView = MneNextCollectionView.extend({
    className: 'ui-tab-triggers',
    childView: TabTriggerView,
    initialize(options) {
        if (options && options.initCollection) {
            this.initCollection = options.initCollection.bind(this);
        }
        this.initCollection();
        this.on('before:render', this._prepareTabTriggers);
        this.on('render', this._invokeActiveTab);
    },
    initCollection() {
        if (this.collection) return;
        this.collection = new TabsCollection();
    },
    _prepareTabTriggers() {
        let tabs = this.getTabs();
        this.collection.set(tabs, { remove: true, merge: true });
        let active = this.collection[collectionActiveTabKey] || this.collection.find({ active: true }) || this.collection.first();
        this._makeActive(active);
    },
    getTabs() {
        return this.getOption('tabs') || [];
    },
    _makeActive(tab) {

        let active = this.collection[collectionActiveTabKey];

        if (active == tab) return;

        if (active) {
            delete this.collection[collectionActiveTabKey];
            active[collectionActiveTabKey] = false;
            active.trigger('disactivate', active);
            console.log('disavtivating ', active);
        }
        if (tab) {
            tab[collectionActiveTabKey] = true;
            this.collection[collectionActiveTabKey] = tab;
            tab.trigger('activate', tab);
            console.log('avtivating ', tab);
        }
    },
    activate(id) {
        const tab = this.collection.get(id);
        if (!tab) {
            console.warn('tab not found');
            return;
        }
        this._makeActive(tab);
        this._invokeActiveTab();
    },
    _invokeActiveTab() {
        let active = this.collection[collectionActiveTabKey];
        console.log('INVOKING ...', active);
        if (!active) return;
        active.trigger('tab:trigger', active);
        this.triggerMethod('show:tab', active.id, active);
        this.triggerMethod('show:tab:' + active.id, active);
        console.log('INVOKED TAB', active.id);
    },
    childViewEvents: {
        'tab:click'(view, event) {
            this._makeActive(view.model);
            this._invokeActiveTab();
        }
    }
});