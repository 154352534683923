import { Collection } from 'core/collection';
import { urls } from 'coms/urls';

export const RealtyAgencyDataCollection = Collection.extend({
	constructor: function(models, options = {}) {
		this.parent = options.parent;
		Collection.apply(this, arguments);
	},
	url(){
		const id = this.parent.id;
		const url = urls.api('v4/realties/realties/' + id + '/agency-data');
		return url;
	}
});