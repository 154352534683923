﻿//define('ui-models-regionsCollections', [], function () {});
import { Collection as BbeCollection } from 'core/bbe';
import { MneObject } from 'core/mne';

var GrouppedCollection = MneObject.extend({
	constructor: function (options) {
		MneObject.apply(this, arguments);
		this.init(options);
	},
	init: function (options) {
		if (this._initialized == true) return;

		options || {};
		this.mergeOptions(options, ['collection', 'iteratee']);
		this._ensureOptions();
		this._initializeGroups();
		this._initializeEventHandlers();

		this._initialized == true
	},
	_groupBy: function (m) {
		if (!this.fltr(m))
			return '.filteredOut';
		else
			return this.iteratee(m);
	},
	group: function () {
		var result = {};
		var colGroups = this.collection.groupBy(_.bind(this._groupBy, this));
		var optionGroups = this.getOption('groups');
		_(optionGroups).each(function (name) {

			if (name in colGroups)
				result[name] = colGroups[name], delete colGroups[name];
			else
				result[name] = [];
		});
		_(colGroups).each(function (models, name) {
			if (name == '.filteredOut') return;
			result[name] = models;
		});
		return result;
	},
	_ensureOptions: function () {
		if (!this.collection)
			throw new Error('collection must be set');

		if (!this.iteratee)
			throw new Error('iteratee must be set');

		if (typeof this.iteratee === 'string') {
			var propertyName = this.getOption('iteratee');
			this.iteratee = function (model) { return model.get(propertyName) };
		}

	},
	_initializeGroups: function () {
		var groups = this.group();
		var _this = this;
		_(groups).each(function (models, name) {
			groups[name] = _this._createGroup(name, models);
		});

		this.groups = groups;
	},
	_createGroup: function (name, models) {
		var iteratee = _.bind(this._groupBy, this);
		var groupCol = new BbeCollection(models);

		groupCol.on('change', function (model) {
			if (iteratee(model) !== name)
				groupCol.remove(model);
		});
		groupCol.name = name;
		return groupCol;
	},
	fltr: function (model) {
		var fltr = this.getOption('itemFilter');
		if (!fltr) return true
		return fltr(model);
	},
	_initializeEventHandlers: function () {
		this.listenTo(this.collection, 'update', this._onCollectionUpdate);
		this.listenTo(this.collection, 'change', this._onModelChange);
	},
	_onCollectionUpdate: function (col, opts) {
		var toAdd = _(opts.changes.added).groupBy(_.bind(this._groupBy, this));
		var toRemove = _(opts.changes.removed).groupBy(this.iteratee);

		var groups = this.groups;
		//var fltr = this.fltr;
		_(toAdd).each(function (models, groupName) {
			if (groupName in groups)
				groups[groupName].add(models);
		});
		_(toRemove).each(function (models, groupName) {
			if (groupName in groups)
				groups[groupName].remove(models);
		});
	},
	_onModelChange: function (model) {
		var groupName = this.iteratee(model);
		var group = this.groups[groupName];
		if (group)
			if (this.fltr(model))
				group.add(model);
			else
				group.remove(model);
		else {
			console.warn('model is out of groupping', model, groupName);
		}
	},
	destroy: function () {

		_(this.groups).each(function (group) {
			group.stopListening();
			if (_.isFunction(group.destroy))
				group.destroy();
		});
		delete this.groups;
		MneObject.prototype.destroy.apply(this, arguments);


		if (_.isFunction(this.collection.destroy))
			this.collection.destroy();
		delete this.collection;

	}
});


var RegionsCollections = GrouppedCollection.extend({
	_result: function () {
		var args = [].slice.call(arguments);
		var name = args.shift();
		if (_.isFunction(this[name]))
			return this[name].apply(this, args);
		else
			return this[name];
	},
	regionTagName: 'div',
	initialize: function (options) {
		this.init(options);
		this._normalizeInitOptions(options);
		this.initRegions();
	},
	_normalizeInitOptions: function (options) {
		this.mergeOptions(options, ['View', 'viewOptions', 'regionTagName', 'regionTagClass', 'regionAttributes']);
		this.viewOptions || (this.viewOptions = {});
	},
	_buildView: function (name) {
		var View = this.getViewClass(name);
		var options = this.getViewOptions(name);
		return this.buildView(name, View, options);
	},
	buildView: function (name, View, viewOptions) {
		return new View(viewOptions);
	},
	getViewClass: function (name) {
		return this.View;
	},
	getViewOptions: function (name) {
		var options = _.isFunction(this.viewOptions) ? this.viewOptions(name) : _.result(this, 'viewOptions', {});
		var collection = this.groups[name];
		return _.extend({ name: name, collection: collection }, options);
	},
	createRegionEl: function (name) {
		var tag = this._result('regionTagName', name);
		var cssClass = this._result('regionTagClass', name);
		var attrs = _.extend({ 'data-region': name }, this._result('regionAttributes', name));
		var $el = $('<' + tag + '/>');
		$el.attr(attrs);
		if (cssClass)
			$el.addClass(cssClass);

		this._regionsEls.push($el);

		return $el;
	},
	getContexts: function () {
		var regions = this.viewRegions;
		return _(this.groups).map(function (collection) {
			var region = regions[collection.name];
			return {
				collection: collection,
				region: region,
			}
		});
	},
	createRegion: function (name) {
		var region = {
			el: '[data-region="' + name + '"]',
			replaceElement: false,
		}
		if (this.RegionClass)
			region.regionClass = this.RegionClass;
		return this._regions[name] = region;
	},
	initRegion: function (group) {
		this.createRegionEl(group.name);
		this.createRegion(group.name);
	},
	initRegions: function () {
		this._regionsEls = [];
		this._regions = {};

		var _this = this;
		_(this.groups).each(function (group) {
			_this.initRegion(group);
		});
	},
	getView: function (name) {
		return this._buildView(name);
	},

	apply: function (context, selector) {
		var $el = selector ? context.$(selector) : context.$el;
		$el.append(this._regionsEls);
		this.viewRegions = context.addRegions(this._regions);
	},

});

export default RegionsCollections;
