﻿//define('ui-layouts-selectedPane', ['behaviors', 'modals', 'realties-offers-views-inList'], function (beh, modals, OfferInList) {});

import beh from 'behaviors';
import modals from '_libs/modals';
import OfferInList from 'svc/realties/offers/views/inList';
import { OfferInListView } from 'svc/realties/offers/views/inList3';
import blocktemplate from './blockLayout.html';

import { plural } from 'utils';

import { _, Model as BbModel } from 'vendors';

import { BbeCollection } from 'core/bbe';

import { MneView, MneNextCollectionView } from 'core/mne';

import { View, TextView, Collection, CollectionView } from 'core';
import { HamburgerView } from 'coms/ui/HamburgerView';

var SelectedList = MneNextCollectionView.extend({
	tagName: 'div',
	className:'offset-t',
	childView: OfferInList,
	behaviors: [beh.SelectedForActions],
	getComparatorsHash: function () {
		if (this.comparatorsHash)
			return this.comparatorsHash;

		var ch = this.getOption('comparatorsHash');
		if (_.isFunction(ch))
			ch = ch();

		this.comparatorsHash = ch;

		return ch;
	},
	onChangeSort: function (type) {
		var ch = this.getComparatorsHash();
		var cmp = ch[type];
		this.setComparator(cmp);
	},
	childViewTriggers: {
		'selectable:toggle':'selectable:toggle',
	},
	childViewOptions: function () {
		return {
			selectionHolder: this.getOption('selectionHolder')
		}
	},
});

var btntrigger = function () {
	var view = this;
	var action = this.model.get('action');
	if (!action) return;
	var args = [].slice.call(this.model.get('data') || []);
	args.unshift(action);
	args.unshift('action');
	this.triggerMethod.apply(view, args);
}

var ButtonView = MneView.extend({
	tagName: 'button',
	dynamicClass: function () {
		var res = ['btn', 'btn-xs', 'offset-r'];
		res.push('btn-' + (this.model.get('type') || 'primary'));
		return res.join(' ');
	},
	behaviors:[beh.DynamicClass],
	template: _.template('<%=text%>'),
	events: {
		'click': function () {
			btntrigger.call(this);
		}
	},
});
var DropdownElement = MneView.extend({
	tagName: 'li',
	template: _.template('<a href="javascript:"><%=text%></a>'),
	events: function () {
		var action = this.model.get('action');
		if (!action) return {};
		return {
			'click':'broadcast'
		}
	},
	broadcast: function () {
		btntrigger.call(this);
	},
});
var DropdownElements = MneNextCollectionView.extend({
	tagName: 'ul',
	className: 'dropdown-menu',
	childView: DropdownElement,
	childViewEvents: {
		'action': function () {
			var args = [].slice.call(arguments);
			args.unshift('action');
			this.triggerMethod.apply(this, args);				
		}
	}
});
DropdownElements.create = function (data) {
	var col = new BbeCollection(data);
	return new this({ collection: col });
}

var DropdownButtonView = MneView.extend({
	tagName: 'div',
	className: 'btn-group',
	template:_.template('<button></button><ul></ul>'),
	regions: {
		'btn': { el: 'button', replaceElement: true },
		'dropdown': { el: 'ul', replaceElement: true },
	},
	onRender: function () {
		this.showButton();
		this.showDropdown();
	},
	showButton: function () {
		var view = new ButtonView({ model: this.model, addElClass: 'dropdown-toggle', attributes: {'data-toggle':'dropdown'}, });
		this.showChildView('btn',view);
	},
	showDropdown: function () {
		var view = DropdownElements.create(this.model.get('dropdown'));
		this.showChildView('dropdown', view);
	},
	childViewEvents: {
		'action': function () {
			var args = [].slice.call(arguments);
			args.unshift('action');
			this.triggerMethod.apply(this, args);
		}
	}
});


var ControlsLayout = MneNextCollectionView.extend({
	className:'offset-t',
	childView: function (model) {
		if (model.get('dropdown'))
			return DropdownButtonView;
		else
			return ButtonView;
	},
	onChildviewAction: function () {
		var args = [].slice.call(arguments);
		args[0] = 'action:' + args[0];
		this.triggerMethod.apply(this, args);

		var args2 = [].slice.call(arguments);
		args2.unshift('action');
		this.triggerMethod.apply(this, args2);
	},
});
ControlsLayout.create = function (data) {
	var col = new BbeCollection(data);
	return new this({ collection: col });
}

var SelectedLayout = MneView.extend({
	template: _.template('<h3><%=header%></h3><div class="controls-region"></div><div class="data-region"></div>'),
	regions: {
		'controls': '.controls-region',
		'data':'.data-region',
	},
	ui: {
		'header':'h3'
	},
	onRender: function () {
		this.showControls();
		this.showData();
	},
	getHeader: function () {
		var len = this.collection.length || 0;
		var res = 'отобрано ' + plural(len, 'предложение', 'предложения', 'предложений', true);
		return res;
	},
	showControls: function () {
		var data = [
			{ text: 'обновить', action: 'renew' },
			{ text: 'очистить', action: 'clearall' },
			//{ text: 'сортировать по площади', action: 'sort', data: ['square'] },
			//{ text: 'сортировать по порядку добавления', action: 'sort', data: ['addorder'] },
			{ text: 'сортировка', dropdown: [{ text: 'по метражу', action: 'sort', data: ['square'] }, { text: 'по порядку добавления', action: 'sort', data:['addorder'] }] },
		];
		var view = ControlsLayout.create(data);
		this.showChildView('controls', view);
	},
	showData: function () {
		var col = new BbeCollection(this.collection.models);
		var view = this.dataView = new SelectedList({ collection: col, selectionHolder: this.getOption('selectionHolder'), comparatorsHash: this.getOption('comparatorsHash') });
		this.showChildView('data', view);
	},
	collectionEvents: {
		'add remove reset': 'refreshHeader',
	},
	childViewEvents: {
		'action:renew': function () {
			this.showData();
		},
		'action:clearall': function () {
			var sh = this.getOption('selectionHolder');
			sh.clearAll();
		},
		'action:sort': function (type) {
			this.dataView.triggerMethod('change:sort', type);
		}
	},
	refreshHeader: function () {
		this.ui.header.html(this.getHeader());
	},
	templateContextExtend: function () {
		return {
			header: this.getHeader(),
		}
	},
});

var SelectedAction = MneView.extend({
	tagName: 'li',
	className: 'list-btn',
	template: _.template('<span><%=text%></span>'),
	triggers: {
		'click':'selectedaction:clicked'
	}
});

var SelectedActions = MneNextCollectionView.extend({
	tagName:'ul',
	childView: SelectedAction,
	childViewEvents: {
		'selectedaction:clicked': function (view) {
			console.log('clicked action');
			this.triggerMethod('selectedaction', view.model);
		}
	}
});


var DataView = MneView.extend({
	className: function () { return this.collection.length ? 'list-btn' : 'list-lbl'; },
	tagName: 'li',
	template: _.template('<%=clear%><%=label%>'),
	behaviors: [beh.DynamicClass],
	events: {
		'click .close': 'clearAll',
	},
	triggers: {
		'click [data-role="showSelected"]': 'show:selected'
	},
	clearAll: function () {
		var col = this.collection;
		modals.confirm('Очистить список?').then(function () {
			col.trigger('clear:all');
		});
	},
	collectionEvents: {
		'add remove reset':'render'
	},
	templateContextExtend: function () {
		var len = this.collection.length;
		var clear = len ? '<button class="close">&times;</button>' : '';
		var label = len ? '<span data-role="showSelected">отобрано ' + plural(len, 'предложение','предложения','предложений',true) + ' <small>кликните для просмотра списка</small></span>':'<span>нет выбранных предложений</span>';
		return {
			label: label,
			clear:clear
		};
	}
});

var _View = MneView.extend({
	className:'block selected-pane',
	//template: '#tmpl-ui-layouts-selectedPane-blockLayout',
	template: blocktemplate,
	regions: {
		'data': '.data-region',
		'actions': { el: '.actions-region', replaceElement:true },
	},
	onRender: function () {
		//debugger;
		this.showDataView();
		this.showActionsView();
	},
	onChildviewShowSelected: function () {
		var sh = this.getOption('selectionHolder');
		if (!sh) return;
		var view = new SelectedLayout({ collection: sh.collection, selectionHolder: sh, comparatorsHash: this.getOption('selectedComparators')  });
		modals.modal(view);
	},
	showDataView: function () {
		var sh = this.getOption('selectionHolder');
		if (!sh) return;
		if (!this.dataView)
			this.dataView = new DataView({ collection: sh.collection });
		this.showChildView('data', this.dataView);
	},
	showActionsView: function () {
		var sa = this.getOption('selectedActions');

		var actions = new BbeCollection(sa);
		var view = new SelectedActions({ collection: actions });
		this.showChildView('actions', view);
	},
	childViewEvents: {
		'selectedaction': function (model) {
			var sh = this.getOption('selectionHolder');
			if (!sh) return;
			var action = model.get('action');
			var entity = model.get('actionOn');
			if (entity) {
				console.warn('-action-', action);
				var data = [].concat(sh.collection.models);
				if (entity instanceof BbModel) {
					entity.executeAction({
						action: action,
						data: data
					});
				} else {
					var method = _.camelCase("action:" + action);
					if (_.isFunction(entity[method])) {
						entity[method](data);
					} else {
						console.warn(method + ' missing on', entity);
					}
				}
			} else {
				// WTF?
			}
			//console.log('action on', model.get('actionOn'));
		}
	}
});


_View.create = function (opts) {
	var options = _.extend({}, opts);
	if (!options.selectionHolder) return;
	//if (!options.layout)
	//	options.template = '#tmpl-ui-layouts-selectedPane-blockLayout'
	return new View(options);
}


// export default _View;

const newtemplate = 
`<header class="small bg-default">отобранные предложения</header>
<ul class="data-region"></ul>
<ul class="actions-region"></ul>`;


const HeaderView = TextView.extend({
	tagName: 'header',
	baseClassName: 'small bg-default',
	text: v => v.getOption('header')
});

const InfoView = View.extend({
	classNames: [v => v.collection.length ? 'list-btn' : 'list-lbl' ],
	tagName: 'li',
	template: '<%= clear %><span<%= datarole %>><%= label %></span>',
	events: {
		'click .close': 'clearAll',
	},
	triggers: {
		'click [data-role="showSelected"]': 'show:selected'
	},
	clearAll() {
		var col = this.collection;
		modals.confirm('Очистить список?').then(function () {
			col.trigger('clear:all');
		});
	},
	updateClassNameOnRender: true,
	// onRender() { this.updateClassName(); },
	collectionEvents: {
		'add remove reset':'render'
	},
	templateContext() {
		let clear = '';
		let label = 'нет выбранных предложений' ;
		let datarole = '';
		if (this.collection.length) {
			const len = this.collection.length;
			clear = '<button class="close">&times;</button>';
			datarole = ' data-role="showSelected"';
			label = 'отобрано ' + plural(len, 'предложение','предложения','предложений', true) + ' <small>кликните для просмотра списка</small>';
		}
		// var len = this.collection.length;
		// var clear = len ? '<button class="close">&times;</button>' : '';
		// var label = len 
		// 	? '<span data-role="showSelected">отобрано ' + plural(len, 'предложение','предложения','предложений',true) + ' <small>кликните для просмотра списка</small></span>'
		// 	: '<span>нет выбранных предложений</span>';

		return {
			label: label,
			clear: clear,
			datarole
		};
	}
});

const SelectedPane = HamburgerView.extend({
	baseClassName:'block selected-pane',
	initialize() {
		var sa = this.getOption('selectedActions') || [];
		this.collection = new Collection(sa);
	},
	childViewOptions() {
		const options = this.getOptions(['header', 'selectionHolder']);
		return options;
	},
	getChildren() {
		var sh = this.getOption('selectionHolder');
		if (!sh) return;
		return [
			HeaderView,
			{ 
				class: HamburgerView, 
				childrenViews: [InfoView], 
				tagName: 'ul',
				collection: sh.collection,
				childViewTriggers: {
					'show:selected': 'show:selected'
				}
			},
			this.getActions(),
		];
	},
	getActions() {
		if (!this.collection.length) { return; }
		return SelectedActions;
		//var view = new SelectedActions({ collection: actions });
	},
	childViewEvents: {
		'show:selected'() {
			var sh = this.getOption('selectionHolder');
			if (!sh) return;
			var view = new SelectedLayout({ 
				collection: sh.collection, 
				selectionHolder: sh, 
				comparatorsHash: this.getOption('selectedComparators')  
			});
			modals.modal(view);				
		},
		'selectedaction': function (model) {
			var sh = this.getOption('selectionHolder');
			if (!sh) return;
			var action = model.get('action');
			var entity = model.get('actionOn');
			if (entity) {
				console.warn('-action-', action);
				var data = [].concat(sh.collection.models);
				if (entity instanceof BbModel) {
					entity.executeAction({
						action: action,
						data: data
					});
				} else {
					var method = _.camelCase("action:" + action);
					if (_.isFunction(entity[method])) {
						entity[method](data);
					} else {
						console.warn(method + ' missing on', entity);
					}
				}
			} else {
				// WTF?
			}
			//console.log('action on', model.get('actionOn'));
		}
	}
});

export default SelectedPane;