import { View } from 'core';
import { HamburgerView } from "coms/ui/HamburgerView";
import { BlockStateView } from './BlockStateView';
import { BlockErrorsView } from './BlockErrorsView';

export const BlockView = HamburgerView.extend({
    baseClassName: 'pdf-block',
    modelStateKeys: ['excluded'],
    stateClassNames: ['excluded'],
    stateful: true,
    //template: '<header><%= name %></header>'
    classNames: [
        v => v.hasErrors() ? 'invalid' : 'valid',
        v => v.model.get('isOptional') ? 'optional' : 'required'
    ],
    childrenViews() {
        let errs = this.model.get('errors') || [];
        if (errs.length) {
            console.log('block view', this);
        }
        return [
            {
                class: BlockStateView,
                name: this.model.get('name') + (this.model.get('isOptional') ? ': <small>опциональный слайд</small>' : ': <small>обязательный слайд</small>'),
                model: this.model
            },
            errs.length && {
                class: BlockErrorsView,
                errors: errs,
                data: this.getOption('data'),
                requestModel: this.getOption('requestModel')

                // blockModel: this.model
            }
        ];
    },
    hasErrors() {
        return this.model.hasErrors(true);
    },
    initialize() {
        let withErrors = this.hasErrors();
        let optional = this.model.get('isOptional');
        if (withErrors && optional) {
            this.model.set('excluded', true);
        }
    }
});