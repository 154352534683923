import { BbCollection } from 'vendors';

import { SchemaModel } from "./schema-model";
export const SchemaCollection = BbCollection.extend({
	model: SchemaModel,
	initialize() {
		this._fetched = false;
	},
	// url: function () {
	// 	return cfg.urls.apiV('realties/owners/schema');
	// },
	fetch() {
		if (this._fetched) {
			return Promise.resolve(this);
		} else {
			return BbCollection.prototype.fetch.call(this).then(arg => {
                this.prepareGroups();
                this._fetched = true;
                return this.onAfterFetch(arg)
            });
		}
	},
	prepareGroups() {
			this._fixRootGroup();
			this._prepareGroups();
	},
	onAfterFetch(arg) {
		return arg;
	},
	_fixRootGroup() {
		let root = this.get('root');
		if (!root) {
			root = this.add({ id: 'root', label: 'Основные х-ки', fullLabel: 'Основные х-ки', isGroup: true, order: 10000 });
			this.filter(f => !f.get('isGroup') && !f.get('parentId')).forEach(model => {
				model.set('parentId', 'root');
			});
		}
	},
	_prepareGroups() {

		this.byParentId = this.groupBy('parentId');

		this.groups = this.byParentId.undefined; //.filter(f => f.get('isGroup'));
		delete this.byParentId.undefined;
	}
});
