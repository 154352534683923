﻿//define('realties-object-layout', ['bus','link','ui-controls-commentBoardList', 'realties-object-owner'], function (Bus, link, CommentBoardList, OwnerLayout){});

import Bus from 'bus';
import link from 'link';
import CommentBoardList from 'ui/controls/commentBoardList';
import OwnerLayout from 'svc/realties/object/owner';

import template from './layout.html';

import { MneView } from 'core/mne';

var Layout = MneView.extend({
	//template: '#tmpl-service-realties-object-layout',
	template,
	regions: {
		'comments': '.board-list-region.comments',
		'owner': {el:'.owner-region', replaceElement:true},
	},
	ui: {
		'building':'.building-button'
	},
	events: {
		'click @ui.building': function () {
			var bid = this.model.get('building').id;
			var url = link.url('realtiesBuilding:'+bid);
			Bus.app.trigger('navigate', url);
		}
	},
	onRender: function () {
		var view = new CommentBoardList({ entity: 'object', entityId: this.model.get('id') });
		this.showChildView('comments', view);

		var ownerView = new OwnerLayout({ owner: this.model.get('owner'), ownerContacts: this.model.get('ownerContacts') || [], realtyContacts: this.model.get('realtyContacts') || [] });
		this.showChildView('owner', ownerView);
	}
});

export default Layout;