// define('svc/actualization/realties-list/realty', [
// 	'appModule', 
// 	'link', 
//     'svc/actualization/realties-list/realty/edit'
// ], function(
// 	Module, link, realtyEditModule
// ) {});


import Module from 'appModule';
import link from 'link';
import realtyEditModule from './edit';


var module = Module.content({
	name: 'actualization/realties-list/realty',
	label: 'Справочник зданий',
	routeData: () => link.route('actualizationRealtiesListRealty'),
	//link: 'actualizationRealtiesListRealty',
	claims: {
		svcActualizationModule: ['view']
	},
	onRun: function () {

		// let view = new Layout({ realtyId: this.queryParams.id });
		// this.createAndShow(view, 'Страница здания');

	}
});


module.registerModule(realtyEditModule);

export default module;

