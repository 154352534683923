import beh from 'behaviors';
import { BbView } from 'vendors';

import { BbeModel, BbeCollection, history } from 'core/bbe';
import { MneView, MneNextCollectionView } from 'core/mne';
import { flatObject } from '../../../../../utils';
import identity from '_libs/identity';

const TabModel = BbeModel.extend({
	isNotAuthorized() {
		
		const claims = this.get('claims');

		if (claims == null) {
			return false;
		}

		const noClaims = !identity.rights.checkNewClaimsValues(claims);

		console.log(':::::', noClaims, this.attributes, this)

		return noClaims;
	},
	invoke(key, ...args) {
			let val = this.get(key);
			if (typeof val === 'function') {
					if (val === BbView || val.prototype instanceof BbView) {
							return val;
					}
					val = val(...args);
			}
			return val;
	}

});

const TabsCollection = BbeCollection.extend({

    model: TabModel,

    getCurrentView(options) {

        let hash = (document.location.hash || '#main').substring(1);
        let model = this.get(hash);
        if (!model) return;

				if (model.isNotAuthorized()) {
					return 'not-authorized';
				}

        let View = model.invoke('view', options);
        if (!View) return;

        options = Object.assign({}, model.invoke('viewOptions', options), options);

        return new View(options);

    }
});






//import tabs from './tabs'



// const invoke = (model ,key, ...args) => {
//     let val = model.get(key);
//     if (typeof val === 'function') {
//         val = val(...args);
//     }
//     return val;
// }

const TabView = MneView.extend({
    template: _.template('<a <%= attrs %>><%= label %></a>'),
    behaviors:[beh.DynamicClass],
    dynamicClass() {
        //let res = [];
        let hash = (document.location.hash || '#main').substring(1);
        if (hash == this.model.id) {
            return 'active';
        }
    },
    getLinkAttrs() {
        let model = this.model;
        let entity = this.getOption('entity');
        let attrs = {};

        let href = model.invoke('href', entity);
        if (!href) {
            href = "#" + model.id;
        }

        if (href) {
            attrs.href = href;
        }

        if (model.invoke('newTab', entity)) {
            attrs.target = '_blank';
        }


        return attrs;
    },
    _getLinkAttrsText(attrs) {
        if (arguments.length === 0) {
            attrs = this.getLinkAttrs();
        }
        attrs = attrs || {};
        return Object.keys(attrs)
            .reduce((memo, key) => {
                let value = attrs[key];
                memo.push(key + '="' + value + '"');
                return memo;
            }, [])
            .join(' ');
        
    },
    templateContext() {
        let attrs = this._getLinkAttrsText();
        return {
            attrs,
            label: this.model.get('label') || '[no-label]'
        }
    },
    events:{
        click(e) {
            if (!this.model.has('href')) {
                e.preventDefault();
                e.stopPropagation();
                document.location.hash = this.model.id;
                history.trigger('hash:change');
            }
        }
    }
});

export const NavTabsView = MneNextCollectionView.extend({
    tagName: 'nav',
    className: 'sub',
    childView: TabView,
    initialize() {
			console.error('NAVTABS INCOMING');
        let tabs = this.getOption('tabs') || [];
        this.collection = this.createTabsCollection(tabs);
        this.listenTo(history, 'hash:change', this.render);
    },
		createTabsCollection(tabs) {
			const defs = this.getOption('tabModelDefaults', true);
			// const flatDefs = flatObject(defs);
			tabs = tabs.map(tab => {
				//const flatTab = flatObject(tab);
				return Object.assign({}, defs, tab);
			});
			console.log('TABS >>>>>>', tabs);
			return new TabsCollection(tabs);
		},
    childViewOptions() {
			return {
				entity: this.getOption('entity')
			}
    }
});
