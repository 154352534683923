﻿import { isValue } from 'utils/is';

(function (A) {
	A.addValue = function (val) {
		if (!isValue(val) || (typeof val == 'string' && val.trim().length == 0)) return this;
		this.push(val);
		return this;
	};

	A.addValues = function () {
		var args = [].slice.call(arguments);
		for (var x = 0; x < args.length; x++) {
			this.addValue(args[x]);
		}
		return this;
	}

})(Array.prototype);