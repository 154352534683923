import { _, Model, Collection } from 'vendors';
import { isSimpleValue } from 'utils/is';

// function isVal(obj) {
// 	return isSimple(obj) || isObj(obj);
// }

// function isSimple(obj) {
// 	if (obj === null || !_.isObject(obj))
// 		return true;
// 	return _(SimpleCtors).some(function (ctor) { return obj instanceof ctor });
// }

const isSimple = isSimpleValue;

// function isObj(obj) {
// 	return !_.isFunction(obj) && _.isObject(obj);
// }

function simpleValue(obj) {
    //let type = typeof obj;
    if (obj instanceof Date) {
        if (!isNaN(obj.valueOf())) {
            return JSON.stringify(obj).replace(/^"(.+)"$/, '$1');    
        }
    } else {
        return obj;
    }

	// if (!_.isDate(obj))
	// 	return obj;
	// else if (!isNaN(obj.valueOf()))
	// 	return JSON.stringify(obj).replace(/^"(.+)"$/, '$1');		
}


function isBbModel(obj) {
	return obj instanceof Model;
}

function isBbCollection(obj) {
	return obj instanceof Collection;
}


export function toDeepJSON3(obj, opts) {
	opts = (opts || {});
	opts.checked || (opts.checked = []);

	//if (!isVal(obj)) return;
    if (obj == null) { return obj; }
    if (typeof obj === 'function') { return; }

	if (isSimple(obj)) return simpleValue(obj);

	if (opts.checked.indexOf(obj) > -1) return;
	opts.checked.push(obj);

	if (isBbModel(obj))
		return toDeepJSON3(obj.attributes, opts);
	else if (isBbCollection(obj))
		return toDeepJSON3(obj.models, opts);
	else if (_.isArray(obj)) {
		return _.map(obj, (item) => toDeepJSON3(item, opts));
	} else {
		let res = {};
		_.each(obj, function (item, key) {
			let val = toDeepJSON3(item, opts);
			if (val === undefined) return;
			res[key] = val;
		});
		return res;
	}
}