import { BbModel, BbCollection } from 'vendors';
const Model = BbModel;
const Collection = BbCollection;
const SingleArgCtors = [
    String,
    Number,
    Date,
    Boolean
];

if (typeof BigInt == 'function') {
    SingleArgCtors.push(BigInt);
}

export default function deepClone(arg, options = {}) {
    let { simplify = true } = options;
    let type = typeof arg;

    if (type == 'function') return;
    if (arg == null || type !== 'object') return arg;

    if (SingleArgCtors.some(ctor => arg instanceof ctor)) {
        if (simplify) {
            return JSON.parse(JSON.stringify(arg));
        }
        return new arg.constructor(arg.valueOf());
    }

    if (arg instanceof Model) {
        let hash = deepClone(arg.attributes, options);
        if (simplify) {
            return hash;
        } else {
            return new arg.constructor(hash);
        }
    }

    if (arg instanceof Collection) {
        let models = deepClone(arg.models, options);
        if (simplify) {
            return models;
        } else {
            return new arg.constructor(models);
        }
    }

    if (Array.isArray(arg)) {
        return arg.map(arrArg => deepClone(arrArg, options))
    } else {

        return Object.keys(arg).reduce((memo, key) => {
            memo[key] = deepClone(arg[key], options);
            return memo;
        }, {});
    }
}