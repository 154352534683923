import { ButtonView } from 'coms/ui/Button';

export const ActionButtonView = ButtonView.extend({
	template: _.template(`
		<% if (glyph) { %><i class="glyphicon glyphicon-<%= glyph %>"></i><% } %>
		<% if (faIcon) { %><i class="fa fa-<%= faIcon %>"></i><% } %>
		<span><%= name %></span>
	`),
	isActive() {
		return this.model.isVisible();
	},
	clickAction(event) {
		let args = this.getOption('actionArgs') || [];
		return this.model.exec(...args);
	},
	templateContext() {
		return {
			name: this.model.getName()
		}
	}
});
