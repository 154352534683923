import { PropertiesLinesView } from "coms/ui/PropertiesLineView";
import { popupProperties } from "./properties";
import { modelSchema } from '../schema';

import './offer-properties.less';

export const PopupOfferPropertiesView = PropertiesLinesView.extend({
    //popoverClass: 'offer-properties-popup',
    baseClassName: 'ui2-lines',
    thisClassName: 'offer-properties-content with-borders',
    properties: popupProperties,
    modelSchema,
    classNames: [
        v => v.model && v.model.get('operation')
    ]
});