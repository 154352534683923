﻿//define('appModule-navView', ['bus'], function (Bus) {});

import Bus from 'bus';
import template from './nav.html';

import { BbeModel } from 'core/bbe';
import { MneView, MneNextCollectionView } from 'core/mne';
import { VERSION } from '../../version';
import { identity } from 'identity';

var NavLink = MneView.extend({
	tagName: 'li',
	//template: _.template('<a href="<%=href%>" <%=attrsLine%>><span class="before-icon"></span><%=label%><span class="after-icon"></span></a>'),
	template: _.template('<a href="<%=href%>" <%=attrsLine%>><span class="prefix"></span><span><%=label%></span><span class="postfix"></span></a>'),
	templateContext() {
		var attrs = this.model.get('attrs') || {};
		var aln = _(attrs).map(function (value, key) { return key + '="' + value + '"'; }).join(' ');
		return {
			attrsLine: aln,
			href: this.model.get('href'),
			label: this.model.get('label'),
		}
	}

});

var LinksView = MneNextCollectionView.extend({
	tagName: 'ul',
	className: 'nav nav-pills nav-stacked',
	childView: NavLink,
	viewFilter: function (view) {
		const user = identity;
			// Bus.identity.request('identity');
		var m = view.model;
		if (m.get('auth') === true && !(!!user && user.get('authenticated') === true))
			return false;
		else if (m.get('auth') === false && !(!user || user.get('authenticated') === false))
			return false;
		else
			return true;
	}
});


var Layout = MneView.extend({
	tagName: 'nav',
	className: 'module-nav',
	template,
	ui: {
		'backLink': '.back-link-region',
		'mainLinks': '.module-links-region.mainlinks',
		'addLinks': '.module-links-region.addlinks',
	},
	regions: {
		'backLink': '@ui.backLink',
		'mainLinks': '@ui.mainLinks',
		'addLinks': '@ui.addLinks',
	},
	onRender: function () {
		var options = _.extend({}, this.options);
		options.collection = options.mainLinks;
		var mainlinks = options.mainLinks && new LinksView(options);

		var addoptions = _.extend({}, this.options);
		addoptions.collection = addoptions.addLinks;
		var addlinks = options.addLinks && new LinksView(addoptions);

		this.ui.backLink.addClass('off');
		
		var module = this.getOption('module');
		if (module && module.parentModule) {
			var pm = module.parentModule;
			var backlink = pm.getModuleLink();
			if (backlink) {
				this.ui.backLink.removeClass('off');
				var linkModel = new BbeModel(backlink);

						//new options.collection.model(backlink);
				var backview = new LinksView.prototype.childView({ model: linkModel });
				this.showChildView('backLink', backview);
			} 
		}

		if (mainlinks) {
			this.ui.mainLinks.removeClass('off');
			this.showChildView('mainLinks', mainlinks);
		} else
			this.ui.mainLinks.addClass('off');
		if (addlinks) {
			this.ui.addLinks.removeClass('off');
			this.showChildView('addLinks', addlinks);
		} else
			this.ui.addLinks.addClass('off');
	},
	templateContext:{
		VERSION: VERSION
	}
});



export default Layout;