import { flatObject, setValueByPath, unFlatObject } from "../../utils"

export function getInitialKeyValues(model) {

	if (!model) { return {} }

	const obj = model.attributes ? model.attributes : model;
	return flatObject(obj);

}

export function flatArray(arr) {
	if (!arr) { return [] }
	return arr.reduce((m, i) => {
		
		if (Array.isArray(i)) {
			m.push(...flatArray(i));
		} else {
			m.push(i);
		}
		return m;
	}, []);
}

export function getEditChanges(initialKeyValues, changedModel, options = {}) {

	if (!initialKeyValues) { return; }

	const { properties, returnFlat } = options;

	const flatChanges = flatObject(changedModel);

	let keys = Object.keys(initialKeyValues)
	keys.push(...Object.keys(flatChanges));

	const keysHash = keys.reduce((m, k) => {
		m[k] = 1
		return m;
	}, {});

	const changes = {};
	let hasChanges = false;

	for(let key in keysHash) {
		if (properties && properties.indexOf(key) === -1) {
			continue;
		}
		// if (shouldExcludeKey(key)) { continue; }
		const original = initialKeyValues[key];
		const newvalue = flatChanges[key];
		// console.log(key, original, newvalue);
		if (original !== newvalue) {
			changes[key] = newvalue;
			hasChanges = true;
		}
	}

	if (hasChanges) {
		return returnFlat ? changes : unFlatObject(changes);
	}

}

export function applyChangesToModel(model, changes) {
	if (!model) { return; }

	const flatChanges = flatObject(changes);

	for(let key in flatChanges) {
		setValueByPath(model, key, flatChanges[key]);
	}

}