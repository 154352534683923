﻿//define('v-contacts-entityContact-edit', ['bus','modals','behaviors-form2Hash', 'behaviors-dynamicClass'], function (Bus, modals, BehaviorForm2Hash, BehaviorDynamicClass) {});

import Bus from 'bus';
import modals from '_libs/modals';
import BehaviorForm2Hash from 'behaviors/form2Hash';

import template from './edit.html';

import { unFlatObject } from 'utils';
import { MneView } from 'core/mne';

var View = MneView.extend({
	className: 'entityContact-edit',
	//template: '#tmpl-v-contacts-entityContact-edit',
	template,
	behaviors: [BehaviorForm2Hash],
	setOption: function (name, value) {
		this[name] = value;
	},
	getContactType: function () {
		var detected = this.contactType;
		var contactType =
							detected
							|| (!this.model.get('contactName') ? 'person' : 'contact');
		if (!detected)
			this.setOption('contactType', contactType);

		return contactType;
	},
	onEditComplete: function (hash) {
		var contactType = this.getContactType();
		var view = this;

		//var patch = _.extend({}, hash);

		if (contactType == 'person')
			delete hash.contactName;
		else {
			_(hash).each(function (val, name) {
				if (name.startsWith('person.'))
					delete hash[name];
			});
			delete hash.person;
		}

		if ('clientFuncs' in hash) {
			let funcs = hash.clientFuncs;
			if (funcs instanceof Array)
				funcs = funcs.join(', ');

			funcs || (funcs = 'none');
			hash.clientFuncs = funcs;
		}

		if ('ownerFuncs' in hash) {
			let funcs = hash.ownerFuncs;
			if (funcs instanceof Array)
				funcs = funcs.join(', ');

			funcs || (funcs = 'none');
			hash.ownerFuncs = funcs;
		}

		var rdyHash = unFlatObject(hash);

		var model = this.model;
		var parent = this.getOption('parent');

		if (rdyHash && this.getOption('realtyId'))
			rdyHash.realtyId = this.getOption('realtyId');


		if (model.isNew()) {
			model.set(rdyHash);
			model.loudSave(null, {wait:true}).then(function () {
				if (rdyHash.contactName == null) {
					model.set('contactName', null);
				}
				var col = parent.getContacts();
				if (col) col.add(model);
				//parent.trigger('change:contacts', parent);
				view.destroy();
			});
		} else {
			model.loudPatch(hash, { wait: true }).then(function () {
				if (hash.contactName == null) {
					model.set('contactName', null);
				}
				view.destroy();
				//parent.trigger('change:contacts', parent);
				//model.trigger('change', model);
			});
		}
	},
	onEditCancel: function () {
		this.destroy();
	},
	events: {
		'change [data-type="contact-switch"]': function (e) {
			this.setOption('contactType', e.target.value);
			//this.contactType = e.target.value;
			this.render();
		}
	},
	templateContextExtend: function () {
		var m = this.model.toJSON();
		var enumName, inputName;
		var contactType = this.getContactType();
			//this.getOption('contactType')
			//|| (!m.contactName ? 'person' : 'contact');
		console.log(">>", m);
		if (m.clientId) {
			enumName = 'clientContactFunctions';
			inputName = 'clientFuncs';
		} else if (m.ownerId) {
			enumName = 'realtyOwnerContactFunctions';
			inputName = 'ownerFuncs';
		}
		var contactTypeOptions = {
			hash: { contact: 'общий контакт', person: 'контакт сотрудника' },
			value: contactType,
			inputAttrs: {
				'data-form-skip': true,
				'data-type':'contact-switch',
			},
			generateInputName: true,
		};

		return {
			isPerson: contactType == 'person',
			contactTypesHtml: function () {
				return this._radiosHtml(contactTypeOptions);
			},
			funcsCheckboxes: function () {
				if (enumName && inputName)
					return this._enumCheckboxesHtml(enumName, this._m[inputName], inputName);
				else
					return '';
			}
		}
	}
});


View.openInModal = function (opts) {
	var view = new View(opts);
	return modals.modal(view);
}

Bus.Views.reply('entityContact:edit', function () { return View; });

export const EntityContactEdit = View;

export default View;
