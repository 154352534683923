import { displaySchemaViewMixin } from 'coms/schema/displaySchemaViewMixin';
import { View } from './view';

export const TextView = View.extend({
	template: '<%= text %>',
	getText() {
		return this.getOption('text', true);
	},
	templateContext() {
		return {
			text: this.getText()
		}
	},

	...displaySchemaViewMixin,

	// mixinTemplateContext() {
	// 	return MnView.prototype.mixinTemplateContext.apply(this, arguments);
	// },

});