module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="aline">\n	<div class="line">\n		<span class="purposes w50p">'+
((__t=( purposes ))==null?'':__t)+
'</span>\n		<span class="w50p building '+
((__t=( engClassName ))==null?'':__t)+
'">'+
((__t=( realtyName ))==null?'':__t)+
'</span>\n	</div>\n	<div class="line">\n		<span class="square w40p">'+
((__t=( square ))==null?'':__t)+
' </span>\n		<span class="priceMeter w40p">'+
((__t=( priceMeter ))==null?'':__t)+
' </span>\n		<span class="percent  w20p '+
((__t=( contractType ))==null?'':__t)+
'"> '+
((__t=( incomPercent ))==null?'':__t)+
' </span>\n	</div>\n\n	<div class="actions-region">\n		<button class="action-btn toggle-select" data-view="'+
((__t=( _cid ))==null?'':__t)+
'"><span></span></button>\n	</div>\n</div>\n';
}
return __p;
};
