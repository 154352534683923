import { showFullscreenModal } from '_libs/modals';
import OfferCardModalView from './views/cardInModal';

export function showOfferInModal(model) {
	const modalOptions = {
		View: OfferCardModalView,
		cssClasses: { dialog: 'offer-modal-dialog' },
		noFetch: true,
		model,
	};
	return showFullscreenModal(modalOptions);
}