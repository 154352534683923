﻿//define('svc-realties-offers-views-cardInModal-objectRetail', [], function () {});

import template from './objectRetail.html';
import { MneView } from 'core/mne';
var View = MneView.extend({
	className: 'block',
	//template: '#tmpl-service-realties-offers-views-cardInModal-objectRetail',
	template,
});

export default View;
