module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<small>'+
((__t=( _funcNames ))==null?'':__t)+
'</small>\n<div>\n	<div>'+
((__t=( _name ))==null?'':__t)+
'</div>\n	<div>'+
((__t=( _mainContact ))==null?'':__t)+
'</div>\n</div>';
}
return __p;
};
