import { _ } from 'vendors';
export function modelContainsText(model, text) {
	if (text == null || text === '') { return true; }
	if (model.containsText) {
		return model.containsText(text);
	}
	return _.some(model.attributes, value => {
		if (value == null || value === '') { return false; }
		return value.toString().toLowerCase().indexOf(text) > -1;
	});
}