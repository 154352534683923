﻿//define('m-realties-buildings', ['m-realties-buildings-buildings'], function(Buildings) {});

import BuildingsCollection, { allbuildings } from './buildings';
import BuildingModel from './building';
import BuildingImagesCollection, { getBuildingImagesCollection } from './images';

var ns = {
    BuildingsCollection,
    BuildingModel,
    BuildingImagesCollection,
    getBuildingImagesCollection
}

export default ns;

export {
	// allbuildings,
	BuildingsCollection,
	BuildingModel,
	BuildingImagesCollection,
	getBuildingImagesCollection
}