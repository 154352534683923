﻿//define('svc-staff', ['appModule', 'link', '_libs/identity', 'svc-staff-submodules'], function(Module, link, identity, submodules) {});

import Module from 'appModule';
import link from 'link';
import submodules from './submodules';

var module = Module.content({
	name: 'staff',
	urlKey: 'staff',
	label:'Сотрудники и отделы',
	routeData: () => link.route('staff'),
	claims: {
		svcEmployeesModule: ['view']
	},		
	onRun: function () {

		this.showButtonsLayout();

	}
});

_(submodules).each(function (smodule) {
	module.addSubModule(smodule);
});

export default module;
