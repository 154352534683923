import { DropdownActions } from "coms/ui/DropdownActions";
import { HamburgerView } from "coms/ui/HamburgerView";
import { NextIconButtonView } from "coms/ui/Button";
import { View, CollectionView, Collection, Model } from 'core';
import modals from '_libs/modals';
import EditValue from 'ui/controls/editValue';
import { backendApi } from "apis";
// import { getValueByPath } from "utils";
// import { EntityLabelView } from "./EntityLabelView";

function normalizeTagValue(value){
	if (value == null || value === '') {
		return;
	}
	value = value.trim().toLowerCase();
	value = value.replace(/[^a-zа-яё0-9]/gmi, '');
	return value || undefined;
}

const TagModel = Model.extend({
	isNew() { return true; }
});

const TagsCollection = Collection.extend({
	model: TagModel,
	initialize() {
		this.on('destroy', model => {
			this.update({ remove: model.id });
		});
	},
	_getIdsHash(arr, hash = {}, exclude) {
		if (arr == null) {
			arr = this.models;
		}
		return arr.reduce((m, v) => {
			if (typeof v === 'string') {
				m[v] = v;
			} else if (v && v.id) {
				m[v.id] = v.id;
			}
			return m;
		}, hash);
	},
	_getIds(add, remove) {
		if (typeof add === 'string') {
			add = [add]
		}
		if (typeof remove === 'string') {
			remove = [remove];
		}
		const exclude = this._getIdsHash(remove);
		const addHash = this._getIdsHash(add, {}, exclude);
		const idsHash = this._getIdsHash(this.models, addHash, exclude);
		const ids = Object.keys(idsHash);
		return ids;
	},
	update({ add, remove } = {}) {
		const tags = this._getIds(add, remove);
		const url = this.url;
		return backendApi.post({ url, attrs: tags }).then(() => this.fetch());
	}
});

const AddButton = NextIconButtonView.extend({
	thisClassName: 'two-icons',
	iconRight: 'fa:plus',
	icon: 'fa:tags',
	action() {
		var popupSetup = {
			controlType: 'text',
			valueType: 'text',
			header: 'новый тег:',
			//value: this.get('dates').deadline,
		};
		let col = this.collection;
		EditValue.modal.single(popupSetup)
			.then((type, newvalue) => {

				if (type != 'ok') return;

				if (newvalue instanceof Array)
					newvalue = newvalue[0];

				newvalue = normalizeTagValue(newvalue);

				if (!newvalue) return;

				const newtags = Object.keys(col.pluck('id').reduce((m,id) => {
					m[id] = 1
					return m;
				}, {[newvalue]:1}));

				col.update({ add: newvalue });

			});
	}
});


const TagView = View.extend({
	tagName: 'label',
	className: 'tag',
	template: `<span><i class="fa fa-tag"></i></span>
	<span><%= id %></span><span class="close-btn" title="удалить тэг"><i class="fa fa-close"></i>
</span>`,
	events: {
		'click .close-btn'() {
			let model = this.model;
			modals.confirm('подтвердите удаление тега').then(() => {
				model.destroy();
			});
		}
	},
});

const TagsListView = CollectionView.extend({
	className: 'tags-list',
	childView: TagView,
});


export const TagsInfoLine = HamburgerView.extend({
	baseClassName: 'flex-row tags box-row',
	initialize() {
		let tags = this.model.get('tags') || [];
		let models = tags.map(id => ({ id }));
		this.collection = new TagsCollection(models, { model: TagModel });
		this.collection.url = this.model.url() + '/tags';
	},
	childrenViews: [
		AddButton,
		TagsListView
	],
	'click .add-tag'(e) {

	}
});