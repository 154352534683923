// define('svc/actualization/realties-list/layout', [
// 	'ui/layouts/recordset-view', 
// 	'svc/actualization/realties-list/realties-collection',
// 	'svc/actualization/realties-list/filters',
// 	'svc/actualization/realties-list/list-item-view',
// 	'svc/actualization/actions-panel',
// ], 
// function(
// 	BaseLayout, ResultCollection, filtersConfig, ItemView, AcPan
// ) {
// });

import BaseLayout from 'ui/layouts/recordset-view';
import ResultCollection from './realties-collection';
import filtersConfig from './filters';
import ItemView from './list-item-view';
import AcPan from '../actions-panel';
import './edit-realties-list.less';

const actions = [
	{
		id: 'create:building',
		text: 'Добавить здание',			
	},		
];

let counter = 0;
let ItemView2 = ItemView.extend({ template: () => '---', })

export default BaseLayout.extend({
	className: 'recordset-view actualization realties-list',
	instantApplyFilters: true,
	initialize() {
		counter = 0;	
		this.searchResult = new ResultCollection();
		this.registerActionsPanel();

	},
	listResultOptions: {
		//className: 'realties-list',
		className: 'load-more-on-scroll-box scrollY-region edit-realties-list',
		childView: ItemView,
		// () => {
		//     if (counter > 40) return ItemView2;
		//     counter++;
		//     return ItemView
		// },
		childViewOptions: {
			modelType: "realty",
		},
		
		//modelType: "realty",
	},
	filtersConfig,
	...AcPan.resultViewMixin({ actions }),
});


