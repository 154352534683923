﻿//define('m-abstract-selectSingle', ['ui-models-values-collection'], function (VC) {});

import VC from 'ui/models/values/collection';
import { _ } from 'vendors';
var hash = function (opts) {

	var res = {
		initializeSelectSingle: function () {
			var col = this;
			this.on('update reset sync change', function () {
				delete col._singleCollection;
			});
		},
		getForSelect: function (opts) {
			if (this._singleCollection) return this._singleCollection;
			this._singleCollection = VC.Single.map(this, this.forSelectMapper, opts);
			console.log('getForSelect', this._singleCollection);
			return this._singleCollection;
		},
		forSelectMapper: function (model) {
			return model;
		}
	};

	return _.extend({}, res, opts);

}

export default hash;