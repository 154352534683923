module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<td>\n		<div class="dropdown">\n			<button class="btn btn-default dropdown-toggle w100p" type="button" data-toggle="dropdown">\n				<span class="type-icon"></span>\n				<span class="dropdown-toggle-label">'+
((__t=( _enum('contactValueTypes', type) ))==null?'':__t)+
'</span><span class="caret"></span>\n			</button>\n			<ul class="dropdown-menu dropdown-menu-inputed">\n				'+
((__t=(typesHtml))==null?'':__t)+
'\n			</ul>\n		</div>\n	</td>\n	<td class="w100p">\n		<div class="value-region"></div>\n	</td>\n	<td>\n		<button class="btn btn-default w100p edit-complete"><span class="submit-icon"></span><span class="submit-text">добавить</span></button>\n	</td>\n</table>';
}
return __p;
};
