import { Collection, Model } from 'core';
import { _ } from 'vendors';

function _mergeKeys(model, options, keys) {
	if (!Array.isArray(keys)) { return; }
	keys.forEach(key => {
		const value = options[key];
		if (value !== undefined) {
			model[key] = value;
		}
	});
}

function mergeKeys(model, options = {}) {
	_mergeKeys(model, options, model.mergeOptionsKeys);
	_mergeKeys(model, options, options.mergeKeys);
}

export const MapModel = Model.extend({
	constructor: function(data, options = {}) {
		this.map = options.map;
		this.parent = options.parent;
		mergeKeys(this, options);
		Model.call(this, data, options);
	},
});

export const MapCollection = Collection.extend({
	model: MapModel,
	constructor: function(data, options = {}) {
		this.name = _.uniqueId('map-col-');
		this.map = options.map;
		this.parent = options.parent;
		mergeKeys(this, options);
		if (data && !data.length) {
			data = undefined;
		}
		Collection.call(this, data, options);
	},
	_prepareModel(attrs, options = {}) {
		options.map = this.map;
		if (!options.parent && this.parent) {
			options.parent = this.parent;
		}
		return Collection.prototype._prepareModel.call(this, attrs, options);
	}
});

export function parsePoints(data) {
	let models = [];
	for(let id in data) {
		const model = Object.assign({ id }, data[id]);
		models.push(model);
	}
	return models;
}