﻿//define('m-employees-inviteModel', ['bus-app', 'app-paths', ], function (appRadio, paths) {});

import paths from 'app-paths';

import { BbeModel } from 'core/bbe';

var Model = BbeModel.extend({
	urlRoot: function () {
		return paths.api.employeeInvite;
	}
});


export default Model;