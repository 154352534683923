﻿//define('ui-layouts-commentBoardList',['bus-app', 'm-comments'],function(radio, commentsNs){});

//import radio from 'bus/app';
import commentsNs from 'm/comments';
import commentTemplate from './comment.html';
import layoutTemplate from './layout.html';
import './comments.less';

import { MneView, LoadmoreOnScrollCollectionView } from 'core/mne';

	var _trans = function (text, context) {
		text = text.replace(/@do\[([^\]]+)\]/gmi, function (str, _id) {
			console.log('~', _id);
			var id = str.replace(/@do\[([^\]]+)\]/gmi, '$1');
			var name = context.deal && _.isFunction(context.deal.getDealOfferName) && context.deal.getDealOfferName(id);
			return '<span data-id="' + id + '" data-entity="dealOffer" data-role="link">' + name + '</span>';
		});
		return text;
	}

	var CommentView = MneView.extend({
		_viewName: 'comment-item:2',
		className:'comment-item',
		//template: '#tmpl-ui-layouts-commentBoardList-comment',
		template: commentTemplate,
		templateContextExtend: function () {
			return {
				subject: this._transform(this.model.get('subject')),
			}
		},
		_transform: function (text) {
			if (!text) return '';
			var deal = this.getOption('entity') == 'deal' && this.getOption('entityModel');
			return _trans(text, {
				deal: deal
			});

		},
	});

	


	var CommentsView = LoadmoreOnScrollCollectionView.extend({
		className: 'comments-list',
		childView: CommentView,
		fetchOnShow: true,
		initialize: function () {
			this.settleEntityListeners();
		},
		settleEntityListeners: function () {
			if (this.entityListenersAreSet) return;
			var entity = this.getOption('entity');
			var entityModel = this.getOption('entityModel');
			if (!entity || !entityModel) return;

			this.listenTo(entityModel, 'comments:add', function () {
				this.collection.loudFetch();
			}, this);

			this.entityListenersAreSet = true;
		},
		childViewOptions: function () {
			return {
				entityModel: this.getOption('entityModel'),
				entity: this.getOption('entity'),
				//selectionHolder: this.getOption('selectionHolder'),
				//denySelectionFor: this.getOption('denySelectionFor'),
			}
		},
		//collectionViewExtend: {
		//	behaviors: [SelectedForActions]
		//}
	});




	var Layout = MneView.extend({

		//template: '#tmpl-ui-layouts-commentBoardList-layout',
		template: layoutTemplate,

		regions: {
			'comments':'.comments-region'
		},
		ui: {
			'add': 'button.add',
			'commentText':'textarea[name=text]',
		},
		triggers: {
			'click @ui.add':'add:comment'
		},
		onAddComment: function () {
			if (!this.comments) return;
			var text = this.ui.commentText.val() || '';
			if (text.length == 0) return;
			//var model = new commentsNs.Model
			var entityName = this.getOption('entity');
			var entityIdValue = this.getOption('entityId');
			var entityIdKey = entityName + 'Id';
			var json = { text: text };
			json[entityIdKey] = entityIdValue;
			this.comments.create(json, { wait: true });
			this.ui.commentText.val('');
		},
		onRender: function () {
			var view = this;
			var col = this.comments = commentsNs.CommentsCollection.create(this.getOption('entity'), this.getOption('entityId'));

			if (col) {
				//col.loudFetch();
				var comments = new CommentsView({
					collection: col,
					wsRadio: this.getOption('wsRadio'),
					entityModel: this.getOption('entityModel'),
					entity: this.getOption('entity'),
				});
				view.showChildView('comments', comments);
			}
		},
	});


	export default Layout;

