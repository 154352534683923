module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="system">\n	<div>'+
((__t=( _enum(_mv('obj.info.purposes'),'realtyPurposes')))==null?'':__t)+
'</div>\n</div>\n<div class="fields">\n	<div class="square">'+
((__t=( _mfn('getSquare',{formated:true, postfix:false})))==null?'':__t)+
' <small>м<sup>2</sup></small></div>\n	<div class="price">\n		<div>'+
((__t=( _mfn('getPriceMeter',{formated:true, postfix:false})))==null?'':__t)+
' <small>р./м<sup>2</sup></small></div>\n		<div>'+
((__t=( _mfn('getPriceSquare',{formated:true, postfix:false})))==null?'':__t)+
' <small>р.</small></div>\n	</div>\n	<div class="building '+
((__t=( (_mv('bld.cls') || '').replace('+','p') ))==null?'':__t)+
'">\n		'+
((__t=( _mv('bld.cls')))==null?'':__t)+
' '+
((__t=( _mv('bld.name')))==null?'':__t)+
'\n	</div>\n	<div class="metro">'+
((__t=( _ndash(_mfn('getMetroLabel'))))==null?'':__t)+
'</div>\n	<div class="income '+
((__t=( _mv('forAgent.contractType')))==null?'':__t)+
'">\n		<div>'+
((__t=( _mv('forAgent.income')))==null?'':__t)+
'%</div>\n		<div>'+
((__t=( _mfn('getIncome',{ formated:true })))==null?'':__t)+
' р.</div>\n	</div>\n</div>\n<div class="toggle-select"><button><i></i></button></div>\n';
}
return __p;
};
