//define('acc/forgotPassword/restore', ['appModule', 'link', '_libs/identity', 'acc/forgotPassword/restore/layout'], function(Module, link, identity, Layout) {});


import Module from 'appModule';
import link from 'link';
import Layout from './layout';

import { MneView } from 'core/mne';

const ErrorPage = MneView.extend({
    template: _.template(`<p>Страница не найдена</p>`)
});

var module = Module.content({
    name: 'accForgotPasswordRestore',
    noHeaderMenu: true,
    anonymousAllowed: true,
    routeData: function() {
        return link.route('accForgotPasswordRestore',true);
    },
    showErrorPage()
    {
        let footer = {
            options: {
                tagName: 'footer',
                template: _.template('<a href="/acc/login" class="system offset-r">перейти на страницу входа</a>')
            }
        }            
        this.createAndShow(ErrorPage, '404', {  }, { addClass:'short-module', footer });
    },
    onRun: function() {
        let code = this.query.code;
        let userId = this.query.userId;

        if (!code || !userId) {
            return this.showErrorPage();
        }

        console.log('run', this.name, arguments, this);
        let footer = {
            options: {
                tagName: 'footer',
                template: _.template('<a href="/acc/login" class="system offset-r">перейти на страницу входа</a>')
            }
        }
        this.createAndShow(Layout, 'Восстановление пароля', { queryData: { code, userId } }, { addClass:'short-module', footer });
    },
});


export default module;
