﻿//define('m-comments-comments', ['m-comments-comment', 'app-paths'],function(Comment, paths){});

import Comment from './comment';
import paths from 'app-paths';

import { bbeViewComparator } from 'utils';

import { BbeCollection } from 'core/bbe';

var Collection = BbeCollection.extend({		
	model: Comment,
	baseUrl: () => paths.api.comments,
	comparator: function (a, b) {
		return bbeViewComparator(b, a, function () { return this.get('date'); });
	}
});




var entityMap = {
	'task': 'task',
	'employee': 'employee',
	'realty': 'realty',
	'owner': 'owner',
	'object': 'object',
	'deal': 'deal',
	'client': 'client',
}


Collection.create = function (entity, id) {
	var mapping = entityMap[entity];

	if (!mapping || !id) return;
	//console.log('coms', arguments, mapping);
	var col = new Collection();
	col.url = function () {
		return _.result(col, 'baseUrl') + '/' + mapping + '/' + id
	}
	return col;
}

export default Collection;
