import { View } from 'core';
import { CollectionView } from 'vendors';
import { ImageView } from './image-view';

import { imageViewComparator } from './comparator';

export const PreviewsView = View.extend({
    className: 'previews',
    template: '<button class="preview-left">&lt;</button><div class="previews-container"></div><button class="preview-right">&gt;</button>',
    regions: {
        items: '.previews-container'
    },
    onRender() {
        let preview = this;
        let view = new CollectionView({
            className: 'previews-items',
            collection: this.collection,
            childView: ImageView,
            childViewOptions(child) {
                return {
                    imageHash: child.id,
                    imageOptions: 'h100-w100-c-rbc',
                    events: { 
                        'click'() {
                            preview.trigger('preview', this.model);
                        }
                    }
                }
            },
            viewComparator: imageViewComparator
        });
        this.previewsView = view;
        this.showChildView('items', view, { replaceElement: true });
    },
    events: {
        'click .preview-left'() {
            this._scrollPreview(-1);
        },
        'click .preview-right'() {
            this._scrollPreview(1);
        },
    },
    _scrollPreview(coef) {
        let el = this.previewsView.el;
        let current = el.scrollLeft;
        let newScroll = current + 110 * coef;
        if (newScroll > el.scrollWidth) {
            newScroll = el.scrollWidth - 110;
        } else if (newScroll < 0) {
            newScroll = 0;
        }
        el.scrollTo(newScroll, 0);
    }
})