import { displayFileSize, maximumImageSizeInBytes, simpleEditImage } from './helpers';
import hlp from '../../../helpers';

import BehaviorDynamicClass from 'behaviors/dynamicClass';

import { MneView } from 'core/mne';
import { View } from 'core';
import refs from 'references';

export const _UploadMedia = MneView.extend({
    className: 'upload-item',
    behaviors: [BehaviorDynamicClass],
    dynamicClass() {
        let h = {};
        if (this.model.get('error')) {
            h.error = true;
        }
        h[this.model.get('state')] = true;
        return Object.keys(h).join(' ');
    },
    template: _.template(`<div class="wrapper">
    <div class="thumb">
        <% if (isImage) {%>
            <img <%= imgUrlAttr%>/>
        <% } else { %>
        <% } %>
    </div>
    <div class="info">    
        <div class="name"><%= name %></div>
        <div class="type"><%= type %> <%= sizeText %></div>
        <div class="status"><%= state %></div>
        <% if (error) { %>
            <div class="error"><%= error %></div>
        <% } %>
    </div>
    <div class="edit-buttons">
        <i class="fa fa-trash" title="убрать из списка загрузки (не удаляет уже загруженный файл)"></i>
    </div>
</div>`),
    events: {
        'click .fa-trash'(e) {
            this.model.destroy();
        }
    },
    initialize() {
        //this.updateViewState();
    },
    updateViewState() {
        //this.isImage = this.model.isImage;
    },
    onRender() {
        this.tryUpload();
    },
    tryUpload() {
        let { state, uploaded } = this.model.attributes;
        let entity = this.getOption('entity');
        if (this.model._uploading || uploaded || state !== 'iddle') {
            return;
        }
        this.model.upload().then(() => {
            entity.medias.add(this.model.get('uploaded'));
            this.render();
        });
    },
    templateContext() {
        let m = this.model.attributes;
        let url = '';
        if (this.model.isImage && m.uploaded && m.uploaded.media) {
            url = hlp.imgUrl(m.uploaded.media.webId, "w200");
        }        
        return {
            imgUrlAttr: this.model.isImage && url ? `src="${url}"` : '',
            isImage: this.model.isImage,
            sizeText: displayFileSize(this.model.get('size') || 0),
            error: this.model.get('error') || undefined,
            url,
        }
    }
});

export const UploadMedia = View.extend({
	baseClassName: 'upload-item',
	classNames: [
		v => v.model.get('state')
	],

	template: `<div class="wrapper">
    <div class="thumb">
        <% if (isImage) {%>
            <img <%= imgUrlAttr%>/>
        <% } else { %>
						<div class="icon"><i class="fa fa-file"></i></div>
        <% } %>
				<div class="status"><%= stateName %><%= uploadingStateAdd %></div>
    </div>
    <div class="info">    
        <div class="name">(<%= sizeText %>) <%= name %></div>
        <% if (error) { %>
            <div class="error"><%= error %></div>
        <% } %>
        <% if (isUploaded) { %>
					<div class="type"><%= typeName %></div>
				<% } %>				
    </div>
    <div class="edit-buttons">
				<i class="fa fa-pencil-square-o edit-uploaded" title="Редактировать загруженный файл"></i>
        <i class="fa fa-trash" title="убрать из списка загрузки (не удаляет уже загруженный файл)"></i>
    </div>
</div>`,
	templateContext() {
		
		const isImage = this.model.isImage;

		const { uploaded, state, size, error } = this.model.attributes;
		const isUploading = state === 'uploading';
		const isUploaded = state === 'uploaded';
		const imageWebId = uploaded?.media?.webId;
		const type = uploaded?.type;
		const typeName = type ? refs.enum('realtyMediaTypes', type) : '';
		let url = '';
		if (isImage && imageWebId) {
				url = hlp.imgUrl(imageWebId, "w196-h147-ls");
		}
		
		return {
				imgUrlAttr: imageWebId && url ? `src="${url}"` : '',
				isImage: isImage,
				sizeText: displayFileSize(size || 0),
				error: error || undefined,
				url,
				stateName: this.model.getState(),
				uploadingStateAdd: isUploading  ? ' <i class="fa fa-circle-o-notch fa-spin"></i>' : '',
				type,
				typeName,
				isUploaded
		}
	},
	events: {
		'click .fa-trash'(e) {
				this.model.destroy();
		},
		'click .edit-uploaded'(e) {
			if (this.uploadedModel) {
				simpleEditImage(this.uploadedModel);
			}
		}
	},
	onRender() {
		this.updateClassName();
		this.tryUpload();
	},
	tryUpload() {
			// let { state, uploaded } = this.model.attributes;
			// if (this.model._uploading || uploaded || state !== 'iddle') {
			// 	return;
			// }
			let entity = this.getOption('entity');
			this.model.upload(() => {
				const uploadedModel = entity.medias.add(this.model.get('uploaded'));
				this.uploadedModel = uploadedModel;
			}).then(r => console.warn('upload result', r));
			// .then((res) => {
			// 		if (res.ok) {
			// 		}
			// 		this.render();
			// });
	},
	modelEvents: {
		'change:state':'render'
	}
});