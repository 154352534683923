﻿//define('ui-controls-datetime2-valueView', ['behaviors'], function (beh) {});

import beh from 'behaviors';
import timeEditViewTemplate from './valueView-timeEdit.html';
import valueViewRangeTmpl from './valueView-range.html';
import valueViewSingleTmpl from './valueView-single.html';
import { MneView } from 'core/mne';
import { moment } from 'vendors';

const valueViewTypeTemplates = {
	single: valueViewSingleTmpl,
	range: valueViewRangeTmpl
}


function parseNum(num) {
	var v = parseInt(num);
	if (!isNaN(v) && _.isNumber(v))
		return v;
}

function getInput($t) {
	return $t.closest('.time-value').find('input');
}

function get$ElId($el) {
	var id = $el.data('id');
	if (!id) {
		id = _.uniqueId('el');
		$el.data('id', id);
	}
	return id;
}

function getCfgValue(field, cnt, $el) {

	var id = get$ElId($el);

	cnt.cfg || (cnt.cfg = {});
	var cfg = cnt.cfg;

	cfg[id] || (cfg[id] = {});
	var c = cfg[id];
	if (c[field] != null) return c[field];

	var value = parseNum($el.get(0)[field]);
	if (value == null) return;

	c[field] = value;
	return value;
}




var TimeEditView = MneView.extend({
	className:'time',

	//template:'#tmpl-ui-controls-datetime2-valueView-timeEdit',
	template: timeEditViewTemplate,

	initialize: function (opts) {
		this.date = opts.date || new Date();
		this.cfg = {};
	},
	ui: {
		'inp':'input',
	},
	templateContext: function() {
		return {
			date: this.date,
			moment,
		}
	},
	_add: function ($t, dir) {
		let $view = this.$el;
		$(document).one('mouseup', function () {
			clearInterval(i);
			$view.trigger('after:mousedown');
		});

		var $el = getInput($t);


		var min = getCfgValue('min', this, $el);
		var max = getCfgValue('max', this, $el);

		var change = function () {
			var val = parseNum($el.val());
			if (val == null) return;
			var newval = val + dir;
			if ((min == null || newval >= min) && (max == null || newval <= max)) {
				$el.val(newval);
			}
		}

		change();
		var i = setInterval(change, 100);			

		// var _this = this;
		// var $view = this.$el;



	},
	updateDate: function () {
		var h = parseNum(this.ui.inp.get(0).value);
		var m = parseNum(this.ui.inp.get(1).value);
		if (h != null)
			this.date.setHours(h);
		if (m != null)
			this.date.setMinutes(m);

		this.trigger('time:changed');
		//console.log(moment(this.date).format('HH:mm'));
	},
	events: {
		'mousedown i:first-child': function (e) {
			this._add($(e.target), 1);
		},
		'mousedown i:last-child': function (e) {
			this._add($(e.target), -1);
		},
		'after:mousedown': function () {
			this.updateDate();
		},
		'input input': function () { this.updateDate(); },
		'input change': function () { this.updateDate(); },
		'keydown input': function (e) {
			var len = parseNum(e.target.maxLength);
			if (len == null) return;
			len = len - 1;

			var num = parseNum(e.key);				
			var isNum = num != null;
			var isOverflow = e.target.value.length > len;
			if (isNum && isOverflow) {
				e.preventDefault();
			}
		},
		'keyup input': function (e) {
			var el = e.target;
			var max = parseNum(el.max);
			var min = parseNum(el.min);
			var num = parseInt(el.value);

			if (max != null && num > max)
				el.value = max;
			else if (min != null && num < min)
				el.value = min;
			
		},
	},

});

var View = MneView.extend({
	className: 'value-info',
	//template: '#tmpl-ui-controls-datetime2-valueView',
	behaviors: [beh.DynamicClass],
	regions: {
		'fromTime': '.value-from .time-wrapper',
		'toTime': '.value-to .time-wrapper',
		'valueTime': '.value-single .time-wrapper',
	},
	onRender: function () {
		this.showTimes();
	},
	showTimes: function () {
		if (this.model.modelType == 'single') {
			var single = new TimeEditView({
				date: this.model.get('value')
			});
			this.showChildView('valueTime', single);
		} else {
			
			var from = new TimeEditView({
				date: this.model.get('from')
			});
			this.showChildView('fromTime', from);
			var to = new TimeEditView({
				date: this.model.get('to')
			});
			this.showChildView('toTime', to);
			
		}
	},
	getTemplate: function () {
		return valueViewTypeTemplates[this.model.modelType];
		//return '#tmpl-ui-controls-datetime2-valueView-' + this.model.modelType;
	},
	modelEvents: {
		'change':'render',
	},
	childViewTriggers: {
		'time:changed': 'time:changed'
	},
	templateContext() {
		return {
			moment
		}
	}
});

export default View;
