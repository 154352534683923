import { MnObject, Collection, Model } from 'vendors';
import { ABCompare, invokeValue } from 'utils';
import { backendApi } from '../backend';

function replaceParams(text, attrs = {}) {
    return text.replace(/:(\w[\w\d_]*)/gmi, (match, group1) => {
        if (group1 && group1 in attrs) {
            return attrs[group1];
        }
        return match;
    });
}

// new LimitedStore({ url: 'full url', Collection, Model, storeSize, expiration })
// Collection or Model should be provided
// storeSize & expiration are optional
// storeSize: undefined | int | Infinity, if undefined or Infinity - unlimited size, if 0 - will release memory by expiration only
// expiration: undefined | int (milliseconds), if falsy no expiration will be aplied, otherwise will try to cleanup expired models
export const LimitedStore = MnObject.extend({

    initialize(options) {
        this.mergeOptions(options, ['Model', 'Collection'])
        this.accessTimes = {};
        this.initializeCollection();
        this._tryScheduleCleanUp();
    },

    initializeCollection() {
        let Col = this.Collection || Collection;
        this.collection = new Col();
        if (!this.Model) {
            this.Model = this.collection
        }
    },

    // id : 123 || { ... }
    getUrl(id) {
        // let rootUrl = invokeValue(this.getOption('rootUrl'));
        // if (rootUrl) {
        //     return this._buildUrl(rootUrl, id, { root: true });
        // }
        let url = this.getOption('url');        
        let type = typeof url;

        if (type === 'string') {

            return this._buildUrl(url, id);

        } else if (type === 'function') {

            if (url.length > 0) {
                return url(id);
            }

            return this._buildUrl(url(), id, { invoked: true });
        }
    },

    _buildUrl(url, id) {
        let shouldRegexReplace = /:\w+/gmi.test(url);
        if (shouldRegexReplace) {
            let attrs = id !== null && typeof id === 'object' ? id : { id };
            return replaceParams(url, attrs);
        }
        url += (url.endsWith('/') ? '' : '/') + id;
        return url;
    },

    _get(id) {
        let exist = this.collection.get(id);
        if (exist) {
            this._updateTime(exist);
        }
        return exist;
    },

    get(id, shouldFetch, urlParams) {
        let exist = this._get(id);
        if (exist) {
            return exist;
        }
        let model = this._create(id);
        if (shouldFetch) {
            this._fetchAsync(id, urlParams, model);
        }
        return model;
    },

    _create(id) {

        let model = new this.Model();
        model.set(model.idAttribute, id);
        model = this.collection.add(model);
        this._updateTime(model);
        return model;
    },

    getAsync(id, urlParams) {
        let exist = this._get(id);
        if (exist) {
            return Promise.resolve(exist);
        }
        return this._fetchAsync(id, urlParams);
    },

    _fetchAsync(id, urlParams, model) {
        let url = this.getUrl(urlParams || id);
        if (!model) {
            return backendApi.get(url, data => {
                let model = this.collection.add(data, { at: 0 });
                this._updateTime(model);
                return model;
            });
        } else {
            return backendApi.get(url, { sync: true, relativeUrl: false, entity: model });
        }
    },

    _tryScheduleCleanUp() {

        if (this._scheduledCleanUp) { return; }

        let expiration = this.getOption('expiration', true);
        let storeSize = this.getOption('storeSize', true);

        if (!expiration && storeSize == null) { return; }


        if (storeSize == null) {
            storeSize = Infinity;
        }

        this._scheduledCleanUp = setTimeout(() => {
            delete this._scheduledCleanUp;
            let laterThan = expiration ? Date.now() - expiration : undefined;
            this._removeModels(laterThan, storeSize);
            this._tryScheduleCleanUp();
        }, 600000);
    },

    _updateTime(model) {
        if (!model) { return; }
        this.accessTimes[model.id] = Date.now();
    },

    _getLastAccess(model) {
        if (!model) { return 0 };
        return this.accessTimes[model.id] || 0;
    },

    _removeModels(laterThan, keep) {
        //let now = Date.now();

        //if (laterThan && keep !== Infinity && keep > 0) {
        if (laterThan) {
            this.collection.models.sort((m1, m2) => ABCompare(m2, m1, m => this._getLastAccess(m)));
        }

        let toRemove = [];
        let stopIndex = keep === Infinity ? 0 : keep;

        for(let x = this.collection.length - 1; x > keep; x--) {
            let removeModel = this.collection.models[x];
            let shouldRemove = removeModel && (!laterThan || this._getLastAccess(removeModel) < laterThan)
            if (shouldRemove) {
                toRemove.push(removeModel);
            }
        }
        return this.collection.remove(toRemove);
    }

});