import { _, Collection as BaseCollection, Model as BaseModel } from 'vendors';
import { Model as BbeModel } from './model';
import { loudCollectionMixin } from './loud';
import { collectionFactoryMixin } from 'coms/MnFactory';
import { toDeepJSON3 } from './toJSON';

const defaultFetchResult = {
	founded: 0,
	returned: 0,
	skiped: 0,
	fetchedTimes: 0,
}

function updateFetchResult(data) {
	var times = (this.getFetchResult() || {}).fetchedTimes || 0;
	var items = data.items;

	var fetchResult = {
		founded: data.founded || 0,
		returned: data.returned || items.length || 0,
		skiped: data.skiped || 0,
		fetchedTimes: times + 1,
	};
	this.setFetchResult(fetchResult);
}

const BaseCollectionMixin = {

	constructor: function() {
		this.cid = _.uniqueId('col');
		BaseCollection.apply(this, arguments);
	},

	getFiltersData: function () {
		return this.filtersData;
	},

	setFiltersData: function (data) {
		this.filtersData = data;
	},

	getRequestData: function () {
		var fr = this.getFetchResult() || {};
		var skip = (fr.skiped || 0) + (fr.returned || 0);
		var fd = this.getFiltersData();
		if (!fd && !skip) return;

		var data = _.extend({}, this.getFiltersData(), { 'result.skip': skip });
		return data;
	},

	getFetchResult: function () {
		return this.fetchResult;
	},

	isItEndOfData: function () {
		var fr = this.getFetchResult() || defaultFetchResult;
		return (fr.skiped + fr.returned) >= fr.founded;
	},

	setFetchResult: function (hash) {
		this.fetchResult || (this.fetchResult = {});
		var fr = this.fetchResult;
		_(hash).each(function (value, key) {
			fr[key] = value;
		});
	},

	resetFetchResult: function () {
		var fr = this.getFetchResult();
		_(fr).chain().keys().each(function (key) {
			delete fr[key];
		});
	},

	pagedParse: function (data) {

		if (!('founded' in data || 'returned' in data || 'skiped' in data || 'items' in data))
			return data;

		updateFetchResult.call(this, data);

		var items = data.items || [];
		return items;
	},

	parse: function (data) {
		data = this.pagedParse(data);
		if (typeof this.customParse == 'function')
			return this.customParse(data);
		else
			return data;
	},

	onLoadFirst: function (data) {

		this.resetFetchResult();
		this.setFiltersData(data);
		var request = this.getRequestData();
		var opts = _.extend({ firstFetch: true}, request ? { data: request } : undefined);
		this.loadData(opts);
	},

	onLoadNext: function () {
		var request = this.getRequestData();

		var opts = _.extend({ remove: false, merge: true }, (request && { data: request }));
		this.loadData(opts);
	},

	loadData: function (opts) {
		var _this = this;

		return this.loudFetch(opts).then(function () {

			_this.trigger('data:load:complete');
			if (opts.firstFetch)
				_this.trigger('data:load:first:complete');

		});
	},

	toDeepJSON: function () { return toDeepJSON3(this); },

	toJSON: function ({ deepJson, improvedJson, cidHash } = {}) {
		if (deepJson)
			return this.toDeepJSON();
		else if (improvedJson) 
			return this.toJSON2({ cidHash });
		else
			return BaseCollection.prototype.toJSON.call(this);
	},

	toJSON2({ cidHash = {} } = {}) {
		cidHash[this.cid] = true;
		return _.reduce(this.models, (memo, value) => {
			if (value instanceof BaseModel || value instanceof BaseCollection) {
				if (cidHash[value.cid]) {
					value = null;
				} else {
					value = value.toJSON({ improvedJson: true, cidHash }); 
				}
			} else if (value && typeof value.toJSON === 'function') {
				value = value.toJSON({ improvedJson: true, cidHash }); 
			} else if (typeof value === 'function') {
				// skip
			} else {
				// skip
			}
			if (value != null) {
				memo.push(value);
			}
			return memo;
		}, []);
	},
    
	model: BbeModel,
}

const Mixin = Object.assign({}, BaseCollectionMixin, loudCollectionMixin, collectionFactoryMixin);

export const Collection = BaseCollection.extend(Mixin);
export const BbeCollection = Collection;
console.warn('collection triggerMEthod', BbeCollection.prototype.triggerMethod);