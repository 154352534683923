﻿//define('m-leads-statsCollection', ['m-leads-statsModel', 'app-paths'], function (model, paths) {});

import model from './stats-model';
import paths from 'app-paths';

import { BbeCollection } from 'core/bbe';

var LeadsStatsCollection = BbeCollection.extend({
    model: model,
    url: function () {
        return paths.api.leadsStats;
    },
});

export default LeadsStatsCollection;
