import childView from './list-item';
import InfoView from './info-view';
import helpers from '../../../helpers';
import './realty-objects.less';
import ObjectModel from '../../../objects-list/object-model';
import FilterModel from './filter-model';
import FilterView from './filter-view';

import { getValueByPath } from 'utils';
import { _, BbCollection } from 'vendors';

import { BbeCollection } from 'core/bbe';

import { MnView, NextCollectionView } from 'vendors';

import { MneView } from 'core/mne';

function val(value, ifNull) {
    return value != null ? value : ifNull;
}

function AB(a1, a2, path) {
	let v1 = val(getValueByPath(a1, path), -Infinity);
	let v2 = val(getValueByPath(a2, path), -Infinity);
	return v1 == v2 ? 0
			: v1 < v2 ? -1
			: 1
}

const comparator = function(a1, a2, paths) {
    for(let x = 0; x < paths.length; x ++) {
        let path = paths[x];
        let flag = AB(a1, a2, path);
        if (flag)
            return flag;
    }
}

const collectionComparator = (m1,m2) => {
    let a1 = m1.attributes;
    let a2 = m2.attributes;
    return comparator(a1, a2, ['realty_Id', 'owner_Id', 'info.floor', 'info.squareOffer']);
}

const RealtyObjectsEmptyView = MnView.extend({
    className: 'empty-list ui-panel ui-panel-info centered rounded dashed-2 outer-margin',

    waitPlease: _.template('<div class="wait"><span class="icon-preloader icon"><i></i></span> подождите пожалуйста, идёт загрузка</div>'),
    noEntries: _.template('<div class="empty"><span class="icon"><i></i></span> помещения отсутствуют</div>'),
    entriesNotFound:  _.template('<div class="not-found"><span class="icon"><i></i></span> помещения не найдены</div>'),

    initialize() {
        this.allentries = this.getOption('allentries');
    },
    getTemplate() {
        let { fetched, fetching, length } = this.allentries;

        if (fetched && length) {
            return this.entriesNotFound;
        } else if (fetched && !length) {
            return this.noEntries;
        } else {
            return this.waitPlease;
        }
    }
});

const RealtyObjectsView = NextCollectionView.extend({
    className: 'realty-objects',
    childView,
    emptyView: RealtyObjectsEmptyView,

    initialize() {
        this.filterModel = this.getOption('filterModel');
        this.allentries = this.getOption('allentries');
        this.groupentries = this.getOption('groupentries');
        this.collection = new BbCollection();

        this.listenTo(this.filterModel, { 
            'change:modelsSet': this._fillCollection,
            'change:skip': this.sort,
            'filter:changed': this.sort,
            //...changeHash
        });
        
        this.allentries.fetchPromise.then(() => {
            this._fillCollection();
        });


        this.listenTo(this.allentries, 'refresh', (model) => {
            let allowed =  this.filterModel.filter(model);
            if (!allowed) {
                this.collection.remove(model);
            } else {
                if (!this.collection.has(model))
                    this.collection.add(model);
            }
            this.sort();
        });

    },

    _fillCollection() {
        let models = this.allentries.filter(m => this.filterModel.filterSet(m));
        this.collection.reset(models);
    },
    _modelFilter(model, view) {
        if (this.filterModel && model) {
            let allowed =  this.filterModel.filter(model);
            if (allowed) {
                this._filterContext.total++;

                let start = this.filterModel.get('skip') || 0;
                let take = this.filterModel.get('take') || 50;
                let end = start + take;
                let inRange = this._filterContext.index >= start && this._filterContext.index < end;

                this._filterContext.index++;
                view._filterIndex = this._filterContext.index;
                return inRange;

            } else {
                return false;
            }
        }
        return true;
    },

    viewFilter(v) {
        return this._modelFilter(v.model, v);
    },

    viewComparator(v1,v2) {
        return collectionComparator(v1.model, v2.model);
    },

    filter() {
        console.log(' - filter began - ');
        this._filterContext = {
            total: 0,
            index: 0,
        };
        let res = NextCollectionView.prototype.filter.apply(this, arguments);
        console.log('   - filter end - ', this._filterContext);
        this.collection.trigger('after:filter', this._filterContext);
        return res;
    },

    _takeModels() {
        let startIndex = 0;
        let endIndex = 100;
        let models = [];
        if (this.allentries.length < endIndex) {
            endIndex = this.allentries.length;
        }
        for(let x = startIndex; x <= endIndex; x++) {
            let model = this.allentries.models[x];
            model && models.push(model);
        }
        console.log(' -- taked --', models);
        this.collection.add(models);
    },

    // onRender() {
    //     if (this.allentries && !this._partialRendering) {
    //         this.allentries.fetchPromise.then(() => {
    //             this.partialRenderModels();
    //         });
    //     }

    // },

    partialRenderModels() {
        //console.log('partial rendering >', this.allentries.length);
        if (this.collection.length == this.allentries.length)
            return;

        this._partialRendering = true;

        let take = 10;
        let start = this.collection.length;
        let end = start + take;
        //console.log('start - end', start, end);
        let stop;
        if (end > this.allentries.length) {
            end = this.allentries.length;
            stop = true;
        }
        let models = [];
        for (let x = start; x < end; x++) {
            let model = this.allentries.models[x];
            if (model) {
                models.push(model);
            }
        }
        this.collection.add(models);
        //console.log('- added -', models.length, this.collection.length);
        if (!stop) {
            this._partialRendering = false;
            setTimeout(() => this.partialRenderModels(), 50);
        }
    },

    childViewOptions() {
        return {
            withOffers: true,
            modelType: this.getOption('modelType'),
            parentModel: this.getOption('model'), 
            allentries: this.allentries,
        }
    }

});

const RealtyObjectsGroupView = MnView.extend({
    className: 'realty-objects-group',
    template: _.template(`<div class="as-ui-atom">
    <i><%= modelName %></i>
    <div><%= name %></div>
    <b>площадей: <%= count %></b>
</div>
<section></section>
`),
    regions: {
        items: 'section'
    },
    onRender() {
        this.showItems();
    },
    showItems() {
        let view = new RealtyObjectsView({ 
            allentries: this.collection, 
            groupentries: this.getOption('groupentries'),
            model: this.getOption('parentModel'), 
            modelType: this.getOption('parentModelType')
        });
        this.showChildView('items', view, { replaceElement: true });
    },
    templateContext() {
        let ge = this.getOption('groupentries') || [];
        return {
            modelName: this.getOption('owner') ? 'собственник' : 'здание',
            count: ge.length
        }
    }
});

const RealtyObjectsGroupsView = NextCollectionView.extend({
    className: 'realty-objects-groups',
    initialize() {
        this.allentries = this.getOption('allentries');
        this.collection = new BbCollection();
        if (this.allentries.fetched) {
            this._takeModels();
        } else {
            this.allentries.fetchPromise.then(() => {
                this._takeModels();
            })
        }
    },
    _takeModels() {
        this.collection.set(this.allentries.grouped.entities);
    },
    childView: RealtyObjectsGroupView,
    childViewOptions(child) {
        let groupentries = this.allentries.grouped.groups[child.id] || [];
        return {
            allentries: this.getOption('allentries'),
            groupentries,
            modelType: this.getOption('modelType') == 'owner' ? 'realty' : 'owner',
            parentModel: this.model,
            parentModelType: this.getOption('modelType')
        }
    },
    viewComparator: 'name'
});

const grouperConfig = {
    owner: {
        groupKey: 'realty_Id',
        getGroupEntity: models => models[0].get('realty')
    },
    realty: {
        groupKey: 'owner_Id',
        getGroupEntity: models => models[0].get('owner')
    }
}

const AllEntriesCollection = BbeCollection.extend({ 
    model: ObjectModel,
    initialize() {
        this.on('refresh', this.redraw)
    },
    redraw() {
        console.log('chpok in collection');
    }
});

const PaginatorPage = MnView.extend({
    className: 'paginator-page',
    template: _.template('<%= id + 1 %>'),
    initialize() {
        this.filterModel = this.getOption('filterModel');
        this._updateCurrent();
        this.listenTo(this.filterModel, 'change:skip filter:changed', this._updateCurrent);
    },
    _updateCurrent() {
        let newskip = this.filterModel.get('skip') || 0;
        if (newskip == this.model.get('skip')) {
            this.$el.addClass('current');
        } else {
            this.$el.removeClass('current');
        }
    },
    events: {
        'click'(e) {
            this.filterModel.set('skip', this.model.get('skip'));
        }
    }
});

const PaginatorView = NextCollectionView.extend({
    className: 'paginator-pages',
    initialize() {
        let entries = this.entries = this.getOption('entries');
        this.collection = new BbCollection();
        if (entries.length) {
            this.resetCollection();
        }
        this.listenTo(entries, 'after:filter', this.resetCollection);
        //this.listenTo(entries, 'all', e => console.log('    >> - ', e));
    },
    resetCollection(context) {
        let pageSize = 50;
        let pages = Math.floor(context.total / pageSize);
        let models = [];
        for (let x = 0; x <= pages; x++) {
            models.push({ id: x, skip: x * pageSize });
        }
        this.collection.reset(models);
    },
    childView: PaginatorPage,
    childViewOptions() {
        return {
            filterModel: this.getOption('filterModel')
        }
    }
});

export const ObjectsLayout = MneView.extend({
    className: 'realty-objects-page',
    template: _.template(`<div class="info-container"></div>
    <div class="filters-container"></div>
    <div class="paginator-top"></div>
    <div class="realty-objects-container"></div>
    <div class="paginator-bottom"></div>
    `),
    regions: {
			info: '.info-container',
			objects: '.realty-objects-container',
			filters: '.filters-container',
			paginatorTop: '.paginator-top',
			paginatorBottom: '.paginator-bottom',
    },
    initialize() {
        this.filterModel = new FilterModel({ modelsSet: 'brokers' });
        this.modelType = this.getOption('modelType');
        this.collection = this.model.objectCollection;
        if (!this.collection) {

            let col = new AllEntriesCollection([]);

            //console.log(' >>>>>>>> ', AllEntriesCollection.prototype.model);

            col.comparator = collectionComparator;

            col.url = this.model.url() + '/objects';
            this.model.objectCollection = col;
            this.collection = col;
            this.collection.fetching = true;
            this.collection.fetchPromise = new Promise((res, rej) => {
                this.collection.fetch().then((arg) => {
                    this.collection.fetching = false;
                    this.collection.fetched = true;
                    this._prepareCollection();
                    res(arg);
                }, ex => rej(ex));
            });
        } else {
					this.filterModel.updateSetsCounts(this.collection);
        }
    },
    _prepareCollection() {

        let col = this.collection;

        this.filterModel.updateSetsCounts(col);

        
        let cfg = grouperConfig[this.modelType];
        let groups = col.groupBy(cfg.groupKey);
        let entities = _.map(groups, (models, key) => {
            return cfg.getGroupEntity(models);
        });
        this.collection.grouped = {
            groups,
            entities
        }
    },
    onRender() {
        this.showInfo();
        let objectsView = this.showObjects();
        this.showPaginators(objectsView);
        this.showFilters();
    },
    childOptions(opts) {
        return Object.assign({ 
            allentries: this.collection, 
            model: this.model, 
            modelType: this.getOption('modelType'),
            filterModel: this.filterModel 
        }, opts);
    },
    showInfo() {
        let view = new InfoView(this.childOptions());
        this.showChildView('info', view);
    },
    showFilters() {
        let options = this.childOptions();
        let view = new FilterView(options);
        this.showChildView('filters', view);
    },
    showObjects() {
        let options = this.childOptions({});
        let view = new RealtyObjectsView(options);
            //new RealtyObjectsGroupsView(options);
            //new RealtyObjectsView(options);
        this.showChildView('objects', view);
        return view;
    },
    showPaginators(objectsView) {
        let options = this.childOptions({ entries: objectsView.collection });
        let paginatorTop = new PaginatorView(options);
        this.showChildView('paginatorTop', paginatorTop);
    }
});