module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<span class="metro-span metro-line'+
((__t=(line.get('number')))==null?'':__t)+
'" title="'+
((__t=(line.get('number') + ': ' + line.get('name')))==null?'':__t)+
'">\n	<span class="metro-span-name">'+
((__t=(name))==null?'':__t)+
'</span>	\n</span>';
}
return __p;
};
