﻿//define('acc-login', ['appModule', 'link', '_libs/identity', 'acc-login-layout'], function(Module, link, identity, Layout) {});

import Module from 'appModule';
import link from 'link';
import Layout from './layout';

//import identity from '_libs/identity';

var module = Module.content({
    name: 'accLogin',
    noHeaderMenu: true,
    anonymousAllowed: true,
    routeData: function() {
        return link.route('accLogin',true);
    },
    onRun: function() {
        console.log('# login #')
        let footer = {
            options: {
                tagName: 'footer',
                template: _.template('<a href="/acc/forgotPassword" class="system offset-r">забыли пароль?</a>')
            }
        }
        this.createAndShow(Layout, 'Введите свой логин и пароль', {  }, { addClass:'short-module', footer });
    },
});


export default module;
