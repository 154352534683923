﻿//define('svc-staff-emps-invite', ['appModule', 'link', '_libs/identity', 'svc-staff-emps-invite-layout'], function(Module, link, identity, Layout) {});

import Module from 'appModule';
import link from 'link';
import Layout from './layout';


var module = Module.content({
	name: 'staff-employees-invite',
	urlKey: 'staffEmployeesInvite',
	label: 'Пригласить сотрудника',
	routeData: function () {
		return link.route('staffEmployeesInvite');
	},
	onRun: function () {
		//var module = this;
		this.createAndShow(Layout);
	},
	//moduleId: 'staff',
	claims: { svcEmployeesModule: 'invite' }
});


export default module;
