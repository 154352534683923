﻿//define('svc-clients-client-infoEdit', ['bus','modals','behaviors-form2Hash'], function (Bus, modals, BehaviorForm2Hash) {});


import Bus from 'bus';
import modals from '_libs/modals';
import BehaviorForm2Hash from 'behaviors/form2Hash';
import template from './infoEdit.html';

import { MneView } from 'core/mne';

var View = MneView.extend({
	//template: '#tmpl-service-clients-client-infoEdit',
	template,
	behaviors: [BehaviorForm2Hash],
	onEditComplete: function (hash) {
		var view = this;
		this.model.loudPatch(hash, { wait: true }).then(function () {
			view.destroy();
		});
	},
	onEditCancel: function () {
		this.destroy();
	}
});


View.openInModal = function (opts) {
	var view = new View(opts);
	return modals.modal(view);
}

Bus.Views.reply('client:infoEdit', function () { return View; });

export const ClientInfoEdit = View;

export default View;