import { modelSchemaApi } from './modelSchemaApi';
import { propertySchemaApi } from './propertySchemaApi';
import { getOptions } from 'utils';
export const schemaChildViewOptionKeys = ['modelSchema', 'propertySchema', 'property', 'modelHash'];

const getSchemaKeyValueErrorMessage = 'getSchemaKeyValaue has this signatures: getSchemaKeyValaue(propertyKey:string | schemaObject, schemaKey:string[, options: object]) and getSchemaKeyValaue(schemaKey:string[, options: object])';



export const viewSchemaMixin = {

	schemaApi: propertySchemaApi,

	// childViewOptionKeys: schemaChildViewOptionKeys,

	serializeModel () {

		const attrs = this.model.attributes;
		return {
			...attrs
		};
		// const schema = this.getModelSchema();
		// if (!schema) return attrs;
		// const displayKey = this.getOption('templateModelSchemaDisplayKey', true);
		// return {
		// 	...attrs,
		// 	[displayKey]: schema.createTemplateLiteralDisplay(this.model)
		// };

	},

	defaultChildViewModelOptions () {

		const opts = getOptions(this, schemaChildViewOptionKeys, { invoke: true, ignoreUndefined: true });
		if (this.modelHash) {

			opts.modelHash = this.modelHash;

		}
		if (this.model) {

			opts.model = this.model;

		}
		if (this.collection) {

			opts.collection = this.collection;

		}
		return opts;

	},

	getModelSchema () {

		if ('_modelSchema' in this) { return this._modelSchema; }

		let modelSchema = this.getOption('modelSchema', true);
		if (!modelSchema) {

			if (this.model) {

				if (this.model.getSchema) {

					modelSchema = this.model.getSchema();

				} else {

					modelSchema = this.model.schema;

				}

			}

		}
		this._modelSchema = modelSchema;

		return this._modelSchema;

	},

	getSchemaModel () {

		if (this.modelHash) { return this.modelHash; }

		const hash = this.getOption('modelHash', true);
		if (hash) { return hash; }

		return this.model;

	},
	setSchemaModel (modelHash) {

		this.modelHash = modelHash;

	},
	hasSchemaProperty () {

		return this.hasOption('propertySchema') || this.hasOption('property');

	},
	getPropertySchema (key) {

		console.warn('WARN ME');
		if ('_propertySchema' in this) { return this._propertySchema; }

		let propertyDefined;

		if (!arguments.length) {

			key = this.getOption('propertySchema', true) || this.getOption('property', true);
			propertyDefined = !!key;
			if (!propertyDefined) { return; }

		}

		const modelSchema = this.getModelSchema();
		
		const schema = propertySchemaApi.getSchema(key, modelSchema);

		if (propertyDefined) {

			this._propertySchema = schema;

		}
		return schema;

		// if (key != null) {

		// 	return modelSchemaApi.getPropertySchema(key, model, modelSchema);

		// }

		// if (arguments.length) { return; }


		// let propertySchema = this.getOption('propertySchema', true);
		// if (!propertySchema) {

		// 	const modelSchema = this.getModelSchema();
		// 	const model = this.getSchemaModel();
		// 	const property = this.getOption('property', true);

		// 	propertySchema = modelSchemaApi.getPropertySchema(property, model, modelSchema);

		// }

		// this._propertySchema = propertySchema;

		// return this._propertySchema;

	},

	display (key, options) {

		if (arguments.length <= 1 && (key == null || typeof key === 'object')) {

			options = key;
			key = this.getPropertySchema();

		}
		const model = this.getSchemaModel();
		const modelSchema = this.getModelSchema();
		console.error('-display-', key);
		return this.schemaApi.displayValue(key, model, modelSchema, options);

	},

	schemaValue (key) {

		if (!arguments.length) { key = this.getPropertySchema(); }

		const model = this.getSchemaModel();
		const modelSchema = this.getModelSchema();
		return this.schemaApi.getValue(key, model, modelSchema);

	},

	schemaLabel (key, options) {

		if (arguments.length <= 1 && (key == null || typeof key === 'object')) {

			options = key;
			key = this.getPropertySchema();

		}


		const model = this.getSchemaModel();
		const modelSchema = this.getModelSchema();
		return this.schemaApi.getLabel(key, model, modelSchema, options);

	},

	// signatures:
	// getSchemaKeyValaue(propertyKey:string | schemaObject, schemaKey:string[, options: object])
	// getSchemaKeyValaue(schemaKey:string[, options: object])
	getSchemaKeyValue (...args) {

		let schemaKey, options;
		let propertySchema = this.getPropertySchema();

		if (propertySchema) {

			schemaKey = args[0];
			options = args[1];

		} else {

			propertySchema = this.getPropertySchema(args[0]);
			schemaKey = args[1];
			options = args[2];

		}
		const model = this.getSchemaModel();
		const modelSchema = this.getModelSchema();
		if (!schemaKey || !propertySchema) {

			throw new Error(getSchemaKeyValueErrorMessage);

		}
		return this.schemaApi.get(schemaKey, propertySchema, model, modelSchema, options);

	}

};

