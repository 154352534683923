import { controlsApi } from '../../controls';
import { modalsApi } from '../../modalsApi2';
// import { modelSchemaApi } from '../modelSchemaApi';
import { propertySchemaApi } from '../propertySchemaApi';
import { PropertiesView } from './PropertiesView';
import { editValue } from './editValue';
import { editModalOptions } from './editModalOptions';

export const schemaEditApi = {
	editValue,
	// editValue(valueSchema, options = {}) {

	// 	options = normalizeOptions(valueSchema, options);
	// 	valueSchema = options.valueSchema;
	// 	const { model, modelSchema, value } = options;

	// 	let contentOptions = controlsApi.getEditValueViewOptions({
	// 		model,
	// 		valueSchema,
	// 		value,
	// 		onUserInput(value, done) {
	// 			const modalView = this.getOption('modalView');
	// 			value = this.getValue();
	// 			if (done) {
	// 				modalView.trigger('modal:resolve', value);
	// 				return;
	// 			}
	// 			modalView.setSettleWith('resolve', value);
	// 		}
	// 	});

	// 	const header = propertySchemaApi.getEditHeader(valueSchema, model, modelSchema);
	// 	let modalOptions = this.editValueModalOptions(value, header);
		
	// 	let context = modalsApi.show(contentOptions, modalOptions);

	// 	return context;
	// },

	editProperties(properties, options = {}) {
		
		const { 
			header, value, modelSchema, schemaOverrides,
			inlineEdit = true, 
			validate
		} = options;

		const propertiesSchema = buildPropertiesSchema(properties, modelSchema, schemaOverrides);

		const contentOptions = {
			class: PropertiesView,
			propertiesSchema,
			value,
			modelSchema,
			schemaOverrides,
			inlineEdit,
			validate,
			onSetup() {
				// console.log('_SETTING_UP_');
				const modalView = this.getOption('modalView');
				this.listenToOnce(modalView, 'render', this._validate);		
			},
			onUserInput(value) {
				const modalView = this.getOption('modalView');
				modalView.setSettleWith('resolve', value);
			}
		}
	
		const modalOptions = editModalOptions({
			header: header,
			thisClassName: 'edit-properties',
			resolveWith: value,
		});
	

		let context = modalsApi.show(contentOptions, modalOptions);
	
		return context;
	
	}

}


function buildProperties(properties = [], modelSchema, schemaOverrides = {}) {
	const extended = properties.map(property => {
		const valueSchema = propertySchemaApi.getSchema(property, modelSchema);
		if (valueSchema == null) { return null; }
		const override = schemaOverrides[valueSchema.property]
		return Object.assign({}, valueSchema, override);
	}).filter(f => !!f);
	extended.sort((p1, p2) => p2.order - p1.order);
	return extended;
}

function buildPropertiesSchema(properties = [], modelSchema, schemaOverrides = {}) {
	const extended = buildProperties(properties, modelSchema, schemaOverrides);
	return extended.reduce((schema, prop) => {
		schema[prop.property] = prop;
		return schema;
	}, {});
}