import { MneView } from './view';
import { _, NextCollectionView } from 'vendors';



export const MneMixedView = MneView.extend({
	constructor: function () {
		MneView.apply(this, arguments);
		this.on('render', this._renderCollectionView)
	},

	template: _.template('<div class="items-list"></div>'),

	regions: { 'children': { el: '> div.items-list', replaceElement: true } },

	_renderCollectionView: function () {
        let options = _.extend({ childView: this.childView, className: 'items-list' }, this.options);
		var view = new NextCollectionView(options);
		this.showChildView('children', view);
	}
});

