// define('svc/actualization/realties-list/realty/edit/properties/actions-view', [
// ], function(
// ) {});

import bus from './bus';
import beh from 'behaviors';

import { unFlatObject } from 'utils';
import { MnView } from 'vendors';

const Layout = MnView.extend({
    template: _.template(`
        <button class="save">сохранить</button>
    `),
    behaviors:[beh.DynamicClass],
    dynamicClass() {
        let res = [];
        return res.join(' ');
    },
    ui:{
        btn: 'button'
    },
    initialize() {
        this.listenTo(bus, 'model:changes', this.toggle)
    },
    toggle(changes) {
        if (!changes) {
            this.ui.btn.prop('disabled', true);
        } else {
            this.ui.btn.prop('disabled', false);
        }
    },
    onRender() {
        this.toggle(bus.getChanges());
    },
    events: {
        'click .save'() {
            this.saveChanges();
        }
    },
    saveChanges() {
        let changes = bus.getChanges();
        if (!changes) return;

        let compiled = unFlatObject(changes);

        this.model.save(null, {
            method: 'PATCH',
            attrs: compiled,
        }).then(() => {
            bus.afterEntitySave();
        });

        console.log('###MODEL####', this.model);
    }
});

export default Layout;
