module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="datetime-year-list-controls">\n	<div class="datetime-controls year">\n		<button class="decrement"><i></i></button>\n		<span class="years-label">'+
((__t=( first))==null?'':__t)+
' &mdash; '+
((__t=( last))==null?'':__t)+
'</span>\n		<button class="increment"><i></i></button>\n	</div>\n</div>\n<div class="datetime-year-list"></div>';
}
return __p;
};
