﻿//define('ui-controls-datetime2-monthControlsView', ['behaviors'], function (beh) {});

import template from './monthControlsView.html';
import { MneView } from 'core/mne';
import { moment } from 'vendors';
var View = MneView.extend({
	_viewName: 'month-controls',
	className: 'datetime-controls month',
	
	//template: '#tmpl-ui-controls-datetime2-monthControlsView',
	template,

	initialize() {
		console.log('-controls-view-')
	},

	modelEvents: {
		'ready': 'render'
	},
	monthName: function () {
		return moment(this.model.date).format('MMMM');
	},
	events: {
		'click > .decrement': function (e) { this.addMonth(-1); },
		'click > .increment': function (e) { this.addMonth(1); },
	},
	addMonth: function (num) {
		this.model.addMonth(num);
	},
	//triggers: {
	//	'click .year': 'select:year',
	//	'click .month': 'select:month',
	//},
	templateContext: function () {
		console.log('-month-controls-')
		return {
			month: this.monthName()
		}
	},
});

export default View;