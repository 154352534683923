﻿//define('geo', ['geo-reference'],function(refs){});

import refs from './reference';


var ns = {};

ns.runPromise = $.Deferred();
ns.getCurrentRegion = function() {
	var _default = '57c0fa45-e991-4aab-82bb-daeedf4f7d1e';

	var regions = refs.data.get('regions');
	if (!regions) return;
	var currentRegionId = _default;
	return regions.get(currentRegionId);

}
ns.getCurentRegionMetroHash = function() {
	var region = ns.getCurrentRegion();
	if (!region) return {};
	return region.getMetroStationsHash();
}
ns.getCurentRegionMetro = function () {
	var region = ns.getCurrentRegion();
	if (!region) return {};
	var res = region.getMetroStations();	
	return res;
}

$.when(
	//countries.runPromise,
	//regions.runPromise,
	//subjects.runPromise,
	refs.runPromise
).then(function () {
	ns.runPromise.resolve();
}, function () {
	ns.runPromise.reject();
});


export default ns;