// define('svc/actualization/realties-list/realty/edit/properties/utils', [
//     'references'
// ], function(
//     refs
// ) {});

import refs from 'references';

const utils = {

    toLowerFirst(text) {
        return text[0].toLowerCase() + text.substring(1);
    },

    traversePath(model, path, lastCb, everyCb) {

        let hash = model.attributes;
        let chunks = path.split('.');

        while(chunks.length) {
            let key = this.toLowerFirst(chunks.shift());
            if (!chunks.length) {
                return lastCb(hash, key);
            } else {
                hash = everyCb(hash, key);
                if (typeof(hash) != 'object') break;
            }
        }
    },

    setValue(model, path, value) {
        let lastCb = (hash, key) => hash[key] = value;
        let everyCb = (hash, key) => hash[key] = (hash[key] || {});
        return this.traversePath(model, path, lastCb, everyCb);
    },

    getValue(model, path) {
        let lastCb = (hash, key) => hash[key];
        let everyCb = (hash, key) => hash[key];
        return this.traversePath(model, path, lastCb, everyCb);
    },

    __getValue(model, path) {
        if (!model) return;
        let chunks = path.split('.');
        let firstChunk = chunks.shift();
        let initialKey = this.toLowerFirst(firstChunk);
        let initial = model.get(initialKey);
        let keys = [initialKey];
        if (!chunks.length) {
            return initial;
        } else {
            let hash = initial;
            let value;
            while(chunks.length) {
                if (typeof(hash) != 'object') break;
                let key = this.toLowerFirst(chunks.shift());
                keys.push(key);
                hash = hash[key];
                if (!chunks.length) {
                    value = hash;
                }
            }
            return value; 
        }
    },
    
    getSchemaValue(model, schema, path) {
        if (!model) return;
        let value = this.getValue(model, path);
        if (!schema) return value;

        let valueType = schema.get('valueType');
        if (valueType == 'enum' && value != null) {
            let enumType = schema.get('valueSubtype');
            enumType = enumType[0].toLowerCase() + enumType.substring(1);
            return refs.enum(enumType, value);
        } else {
            return value;
        }            

    },

    textContains(text, shouldHave) {
        let pat = new RegExp(shouldHave, 'gmi');
        let res = pat.test(text);
        return res;
    },

    thingContains(value, context)
    {
        if (!context.valid) return true;
        if (value == null) return false;

        let type = typeof(value);

        switch(type) {
            case "string":
                return this.textContains(value, context.text);
            default:
                return value === context.value;
        }
        
    }
}

export default utils;
