import { DropdownButton } from "../../../../coms/ui/DropdownButton";
import { HamburgerView } from "../../../../coms/ui/HamburgerView";
import { UiSchemaMoleculeView } from '../../../../coms/schema';

import { PropertiesBoxView } from 'coms/ui/PropertiesBox';
import { editContactSchema } from './editContactSchema';
import { viewSchemaMixin } from "apis/schema";
import { flatObject, unFlatObject } from "../../../../utils";
import { backendApi } from "../../../../apis/backend";
import { urls } from 'coms/urls';
import { getInitialKeyValues, flatArray, getEditChanges, applyChangesToModel } from "../../../../apis/schema";

/*
export const EditHumanName = PropertiesBoxView.extend({
	thisClassName: 'with-borders bg-white colored-values  blue-values',
	// modelSchema: editContactSchema,
	setValueBySchemaApi: true,
	editEnabled: true,
	// modelValue: this.model.get('lead'),
	properties: [['person.name.last', 'person.name.first', 'person.name.middle']]
		// ['sourceName', 'realtyId', ['operation', 'market'], ['squareFrom','squareTo'], ['priceFrom','priceTo']],
});

export const EditNonHumanName = PropertiesBoxView.extend({
	thisClassName: 'with-borders bg-white colored-values  blue-values',
	// modelSchema: editContactSchema,
	editEnabled: true,
	// modelValue: this.model.get('lead'),
	properties: ['contactName'],
	setValueBySchemaApi: true,
		// ['sourceName', 'realtyId', ['operation', 'market'], ['squareFrom','squareTo'], ['priceFrom','priceTo']],
});
*/




export const EditContactInfo = PropertiesBoxView.extend({
	thisClassName: 'with-borders colored-values track-changes',
	classNames: v => [
		v.hasChanges() ? 'has-changes' : ''
	],
	modelSchema: editContactSchema,
	// modelSchema: editContactSchema,
	setValueBySchemaApi: true,
	editEnabled: true,
	...viewSchemaMixin,
	// modelValue: this.model.get('lead'),
	header: 'описание',
	properties: v => {
		
			const props = [
				['_contactType', 'primary', 'order'],
			(v.contactType 
			? ['person.name.last', 'person.name.first', 'person.name.middle']
			: 'contactName'),

			'ownerFuncs',
			'note'

		];
		return props;
	},
		// ['sourceName', 'realtyId', ['operation', 'market'], ['squareFrom','squareTo'], ['priceFrom','priceTo']],
	onPropertyChange(newvalue, propertySchema, modelValue, modelSchema) {
		this._updateElClassName();
		if (propertySchema.property === '_contactType') {
			this.contactType = newvalue;
			setTimeout(() => {
				this.render();
			}, 50);
		}
	},
	initialize() {
		this.contactType = this.schemaValue('_contactType');
		this.initialKeyValues = getInitialKeyValues(this.model);
		//this.model ? flatObject(this.model.attributes) : {};
	},
	hasChanges() {
		return !!this._getChanges();
	},
	action() {

		if (!this.model) { return; }

		const view = {
			thisClassName: 'save-changes',
			glyph: 'save',
			text: 'сохранить изменения',
			clickAction: () => this._saveChanges()
		}
		return view;

	},
	_getChanges() {


		if (!this.initialKeyValues) { return; }

		const excludeProps = {
			'_contactType':1
		}
		const props = flatArray(this.getOption('properties', true) || []).filter(f => f in excludeProps === false);
		const modelHash = this.getOption('modelHash', true) || {};
		const changes = getEditChanges(this.initialKeyValues, modelHash, { properties: props, returnFlat: false });
		return changes;
		// // console.log('taking props', props);
		// const shouldExcludeKey = key => (key in excludeProps) || props.indexOf(key) === -1;

		// const flatChanges = flatObject(modelHash);
	
		// let keys = Object.keys(this.initialKeyValues)
		// keys.push(...Object.keys(flatChanges));
		// const keysHash = keys.reduce((m, k) => {
		// 	m[k] = 1
		// 	return m;
		// }, {});
	
		// const changes = {};
		// let hasChanges = false;
		// for(let key in keysHash) {
		// 	if (shouldExcludeKey(key)) { continue; }
		// 	const original = this.initialKeyValues[key];
		// 	const newvalue = flatChanges[key];
		// 	console.log(key, original, newvalue);
		// 	if (original !== newvalue) {
		// 		changes[key] = newvalue;
		// 		hasChanges = true;
		// 	}
		// }
		// if (hasChanges) {
		// 	return unFlatObject(changes);
		// }
	},
	_saveChanges() {		
		const changes = this._getChanges();
		if (!changes) { return; }
		if (changes.person) {
			changes.contactName = null;
		}
		const clearPerson = !!changes.contactName;
		const modelHash = this.getOption('modelHash', true) || {};
		const sync = data => {
			this.initialKeyValues = getInitialKeyValues(modelHash);
			if (clearPerson) {
				changes.person = {
					name: null
				};
			}
			if (this.model) {
				applyChangesToModel(this.model, changes);
			}
			this._updateElClassName();
			this.render();
			// console.log(data);
		}
		const owner = this.getOption('owner', true);
		const oid = owner.id;
		const cid = this.schemaValue('id');

		const url = urls.api(`v4/realties/owners/${oid}/contacts/${cid}`);
		backendApi.patch(changes, url, { sync, relativeUrl: false });
		console.log('SAVING CHANGES: ', changes);
	}
});

/*
export const _EditContactName = HamburgerView.extend({
	...viewSchemaMixin,
	thisClassName: 'edit-contact-name-container',
	modelSchema: editContactSchema,
	getChildren() {
		const dropdown = this.getPropertySchema('_contactType');
		const defOpts = this.defaultChildViewModelOptions();
		if ('contactType' in this === false) {
			this.contactType = this.schemaValue('_contactType');			
		}
		let human = !!this.contactType;
		let nonhuman = !this.contactType;

		return [
			{
				class: DropdownButton,
				value: this.contactType,
				// valueSchema: this.getPropertySchema('_contactType'),
				emptyLabel: 'установите тип контакта',
				values: dropdown.sourceValues,
				getModelValue: m => m.id === 'true',
				onUserInput: (value) => {
					this.contactType = value;
					console.error('::', this.contactType, value)
					this.render();
				}
			},
			human && {
				class: EditHumanName,
				...defOpts,
			},
			nonhuman && {
				class: EditNonHumanName,
				...defOpts
			}
		]
	}
})
*/