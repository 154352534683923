import { View } from 'core';
import { mapsApi } from './mapsApi';

export const MapContainerView = View.extend({
	baseClassName: 'map-container',
	template: false,
	onAttach() {
		this.initializeMap();
	},
	initializeMap() {
		// const initializeOptions = this.getMapInitializeOptions();
		// will trigger onMapAttach / 'map:attach'
		const el = this.getMapElement();
		const mapExtraOptions = this.getOption('mapExtraOptions', true);
		this.map = mapsApi.attachMapToView(this, el, mapExtraOptions);
		console.log('- initializeMap end -', mapExtraOptions);
	},
	getMapElement() {
		return this.el;
	},

	// getMapInitializeOptions() {
	// 	const el = this.getMapElement();
	// 	const zoom = this.getOption('zoom');
	// 	const center = this.getOption('center');
	// 	const mapOptions = this.getOption('mapOptions', true);
	// 	const options = Object.assign({ el, zoom, center}, this.getOption('mapInitializeOptions', true));
	// 	options.mapOptions = options.mapOptions || {};
	// 	Object.assign(options.mapOptions, mapOptions);
	// 	return options;
	// },

});