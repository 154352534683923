// define('svc/admin/groups/layout', [
// 	'app-config', 'bus'
// ],
// function(
// 	cfg, bus
// ) {});

import cfg from 'app-config';
import bus from 'bus';
import { _ } from 'vendors';

import { Collection } from 'vendors';

import { MneView, MneNextCollectionView } from 'core/mne';

import { MnView, CollectionView } from 'vendors';
import actions from './actions';

import { ActionsView } from 'apis/actions';

const GroupListItemView = MnView.extend({
	className: 'admin-group-item',
	template: _.template('<header><%= name %></header>'),
	events: {
		'click'(e) {
			let url = cfg.urls.urlS('admin/groups/' +  this.model.id);
			bus.openSmart(url, e);
		}
	}
});

// const GroupListView = CollectionView.extend({
// 	className: 'admin-groups-list',
// 	childView: GroupListItemView
// });



const Layout = MnView.extend({
	className: 'admin-groups-page',
	template: _.template('<nav></nav><section></section>'),
	regions:{
		actions:'nav',
		items: 'section'
	},
	initialize() {
		this.collection = new Collection();
		this.collection.url = function () {
			return cfg.urls.apiV('admin/groups');
		}
		this.collection.fetch();
	},
	onRender() {
		this.renderActions();
		this.renderItems();
	},
	renderActions() {
		//let action = actions.get('add:group');
		//console.log('-- action --', action);
		let view = new ActionsView({ actions, ids: ['add:group'], actionArgs: [this.collection] });
		this.showChildView('actions', view, { replaceElement: true });
	},
	renderItems() {
		let view = new CollectionView({ 
			className: 'admin-groups-list',
			childView: GroupListItemView,
			collection: this.collection 
		});
		this.showChildView('items', view, { replaceElement: true });
	}
});

export default Layout;