﻿//define('m-abstract-fetchableByWebId', [], function () {});

import { BbeModel } from 'core/bbe';

var hash = {
	_hasWebId: true,
	getWebId: function () {
		return this.get('wId') || this.getId();
	},
	fetchByWebId: function (opts) {
		opts || (opts = {});

		var baseUrl;

		if (this.urlRoot)
			baseUrl = _.result(this, 'urlRoot');
		else if (this.collection && this.collection.url)
			baseUrl = _.result(this.collection, 'url');

		if (baseUrl) {
			opts.url = baseUrl + '/' + this.getWebId(); 
		}

		var xhr = BbeModel.prototype.fetch.call(this, opts);
		return xhr;
	},
	fetch: function (opts) {
		opts || (opts = {});
		if (!opts.url && this.getWebId())
			return this.fetchByWebId(opts);
		else
			return BbeModel.prototype.fetch.call(this, opts);
	}
}

export default hash;