import { Behavior } from 'vendors';
import { _ } from 'vendors';
export const ScrollableY = Behavior.extend({
	extendViewClass: function () {
		var vcn, vcnv;
		var view = this.view;
		if (_.isFunction(view.className))
			vcn = this.view.className;
		else
			vcnv = this.view.className;

		this.view.className = function () {
			var res = [];
			if (vcn)
				res.addValue(vcn.apply(view, arguments));
			else
				res.addValue(vcnv);

			res.push('scrollY-region');

			

			return res.join(' ');
		}
	},
	events:						{ 'scroll': _.throttle(function () { this.scrollHandler(); }, 500) },
	isBottomWasReached:			function () { return this.bottomWasReached === true; },
	reresetBottomWasReached:	function () { delete this.bottomWasReached; },
	onScrollResetBottomHit:		function () { this.reresetBottomWasReached(); },
	setBottomWasReached:		function () { this.bottomWasReached = true; this.view.triggerMethod('scroll:bottom:hit'); },
	scrollToInitialPosition:	function () { this.view.$el.scrollTop(0)},
	initialize: function () {
		this.extendViewClass();
	},
	onResetScrollPosition: function () {
		this.scrollToInitialPosition();
		this.triggerMethod('scroll:reset:bottom:hit');
	},
	areWeAtTheBottomNow: function (container) {
		var scrollHeight = container[0].scrollHeight;
		var windowHeight = container.outerHeight();
		var scrolledY = container[0].scrollTop;
		return (scrollHeight - scrolledY) < windowHeight * 2;
	},
	scrollHandler: function (e) {
		if (
			this.isBottomWasReached() || !this.areWeAtTheBottomNow(this.$el)
		) return;

		this.setBottomWasReached();
	},
});

