import { View, CollectionView, Collection } from 'core';
import { NextIconBadgeButtonView } from 'coms/ui/Button';
import { invokeValue } from 'utils';

export const SegmentsCollection = Collection.extend({
	constructor: function(data, optoins) {
		Collection.apply(this, arguments);
		this.afterInitialize();
	},
	afterInitialize() {
		let active = this.findWhere({ active: true });
		if (!active) {
			active = this.first();
		}
		this.makeActive(active);
	},
	makeActive(model) {
		if (this.active) {
			this.active.set('active', false);
		}
		this.active = model;
		model.set('active', true);
	}
});

const SegmentView = NextIconBadgeButtonView.extend({
	baseClassName: 'segment-button',
	stateClassNames: ['active', 'wait', 'disabled'],
	initialize() {
		const onSetup = this.model.get('onSetup');
		if (onSetup) {
			onSetup.call(this, this);
		}
	},
	text: v => v.model.get('name'),
	initialState: v => ({
		active: v.model.get('active')
	}),
	modelEvents: {
		'change'() {
			this.state('active', this.model.get('active'));
		}
	},
	action: 'trigger:select',
	getBadgeValue() {
		return invokeValue(this.model.get('badgeValue'), this, this);
	},
	onRender() {
		console.log('- render -', this.getBadgeHtml(), this.getBadgeValue());
	}
});

export const SegmentsView = CollectionView.extend({
	className: 'select-segments',
	childView: SegmentView,

	initialize() {
		this.collection = this.getOption('segmentsCollection');
	},

	makeActive(model) {
		this.collection.makeActive(model);
	},

	childViewEvents: {
		'select'(event, view) {
			this.makeActive(view.model);
			this.trigger('segment:change', view.model.toJSON());
		}
	}

});