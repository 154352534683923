﻿import { isNotValue } from 'utils/is';

String.prototype.toArray = function (opts) {
	opts = opts || {};
	opts.onlyValues = isNotValue(opts.onlyValues) ? true : opts.onlyValues;
	opts.delimeter = isNotValue(opts.delimeter) ? ',' : opts.delimeter;
	opts.trim = isNotValue(opts.trim) ? true : opts.trim;

	var res = this.split(opts.delimeter);
	if (!opts.trim && !opts.onlyValues) return res;

	var nres = [];
	for (var x = 0; x < res.length; x++) {
		var value = opts.trim ? (res[x] || '').trim() : res[x];

		if (!opts.onlyValues && value.length == 0) continue;

		nres.push(value);
	}
	return nres;

};