import { VisibilityButton } from "coms/visibility-button";
const BaseVisibilityButton = VisibilityButton;

export const ObjectBrokerVisibilityButton = BaseVisibilityButton.extend({
	label: 'брок.',
	clickable: true,
});

export const ObjectAdvertVisibilityButton = BaseVisibilityButton.extend({
	label: 'рек.',
	clickable: true,
	ownVisible: true,
});