﻿//define('register-modules', ['app', 'root-modules', 'bus-app', 'server-cfg'], function(app, modules, busApp, serverCfg) {});


import serverCfg from 'server-cfg';

import app from './app/app';

import { modules } from './root-modules';

import busApp from 'bus/app';
import { _ } from 'vendors';

function registerModules() {
	console.log('> registerModules: ');
	_.each(modules, module => {
		if (!module) return;
		//console.log('app module:', module.name, module.cid);
		if (!serverCfg.isNative && module.name === 'root') {				
			console.error('SKipping ROOT - ' + serverCfg.isNative);
			return;
		}
		app.addSubmodule(module);
	});
	//console.log('added all base modules');
	app._rootModulesPromise && app._rootModulesPromise.resolve();
	busApp.trigger('basemodules:registered');
}

export default registerModules;