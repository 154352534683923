import { smartOpen } from "../../utils";

export const viewClickableMixin = {
	initializeClickableMixin() {
		if (this._clickableMixinInitialized) { return; }
		const url = this.hasOption('url', false);
		let action = this.getOption('clickableAction', false);
		if (typeof action !== 'function') {
			action = undefined;
		}
		if (!url && !action) { return; }

		if (!action && url) {
			action = (view, event) => smartOpen(this.getOption('url', true), event);
		}

		this.delegate('click', event => action.call(this, this, event));
		this.addClassName('clickable');
		this._clickableMixinInitialized = true;
		this._updateElClassName();
	}
}