import Module from 'appModule';
import link from 'link';
import editModule from './edit';


var module = Module.content({
	name: 'actualization/owners-list/owner',
	label: 'Справочник собственников',
	routeData: () => link.route('actualizationRealtyOwnersListOwner'),
    
	//link: 'actualizationRealtiesListRealty',
	claims: {
		svcActualizationModule: ['view']
	},
	onRun: function () {

		// let view = new Layout({ realtyId: this.queryParams.id });
		// this.createAndShow(view, 'Страница здания');

	}
});


module.registerModule(editModule);

export default module;

