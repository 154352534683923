// import _ from 'underscore';
// import $ from 'jquery';
// import Backbone from 'backbone';
// import Marionette from 'backbone.marionette';
// import Radio from 'backbone.radio';
// import moment from 'moment';

/*
import { _, $, Backbone, Radio, moment, Mn } from 'vendors';



window._ = _;
window.$ = $;
window.jQuery = $;
window.Bb = Backbone;
window.Backbone = Backbone;
window.Mn = Mn;
window.Radio = Radio;
window.moment = moment;
*/

//console.log('EXPOSING COMPLETE', window.$);

import { _, $ } from 'vendors';

window._ = _;
window.$ = $;
window.jQuery = $;

// window.onerror = function myErrorHandler(errorMsg, url, lineNumber) {
// 	alert("Error occured: " + errorMsg);//or any message
// 	return false;
// }