import { BbView, MnView, triggerMethod } from 'vendors';

import { viewStateMixin } from 'apis/state';
import { viewClassNameMixin } from 'apis/className';
import { viewCommonMixin } from './view-common-mixin';
import { viewClickableMixin } from './mixins/viewClickableMixin';
const emptyContext = {};


export const CoreBbView = BbView.extend({

    ...viewStateMixin,
    ...viewClassNameMixin,
    ...viewCommonMixin,
		...viewClickableMixin,

    constructor: function(options) {
        this.options = Object.assign({}, options);
        BbView.apply(this, arguments);
        this.initializeStateMixin();
        this.initializeClassNameMixin();
				if (this.getOption('clickableMixinEnabled', true)) {
					console.warn('initializeClickableMixin', this.initializeClickableMixin, this);
					this.initializeClickableMixin();
				}
    },

    triggerMethod(...args){
        return triggerMethod(this, ...args);
    }, //: View.prototype.triggerMethod,
    mergeOptions: MnView.prototype.mergeOptions,



    isRendered() { return this._isRendered === true },
    isAttached() { return this._isAttached === true },
    isDestroyed() { return this._isDestroyed === true },

    render() {
        this.triggerMethod('before:render');
        
        this.renderTemplate();
        
        this._isRendered = true;
        this.triggerMethod('render');
    },

    renderTemplate() {
        let template = this.getTemplate();
        if (template == null || template === false) return;
        let viewContext = this.getOption('templateContext', true);
        let modelContext = this.model && this.model.attributes;
        let context = viewContext || modelContext ? _.extend({}, viewContext, modelContext) : emptyContext;
        let html = template(context);
        this.$el.html(html);
    },



    destroy(options) {
        if (this._isDestroyed || this._isDestroying) { return this; }

        this._isDestroying = true;
        const shouldTriggerDetach = this._isAttached && !this._disableDetachEvents;
    
        this.triggerMethod('before:destroy', this, options);
        if (shouldTriggerDetach) {
          this.triggerMethod('before:detach', this);
        }
    
        // unbind UI elements
        //this.unbindUIElements();
    
        // remove the view from the DOM

        this.$el.off().removeData();
        this.$el.detach(); 
        

        if (shouldTriggerDetach) {
          this._isAttached = false;
          this.triggerMethod('detach', this);
        }

        
        // remove children after the remove to prevent extra paints
        if (this.destroyChildren) {
            this.destroyChildren();
        }
    
        this._isDestroyed = true;
    
        this.stopListening();

        this.triggerMethod('destroy', this, options);
    
        return this;
      },

});

