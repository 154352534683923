﻿//define('geo-reference-mapPoints', ['bus','app-paths'], function (Bus, paths) {});


import Bus from 'bus';
import paths from 'app-paths';
import { BbeModel, BbeCollection } from 'core/bbe';
import './gmaps-point.less';

var MapPoint = BbeModel.extend({
	latlng: function () {
		return { lat: this.get('lat'), lng: this.get('lng') };
	},

	offersCount: function (id) {
		var items = this.offers;
		return _.size(items);
	},
	realtiesCount: function(id) {
		var items = this.realties;
		return _.size(items);
	},
});

var MapPoints = BbeCollection.extend({
	model: MapPoint,
	url: function () {
		return paths.api.mapPoints;
	},
	populate: function () {
		//debugger
		var _this = this;
		var promise = $.Deferred();

		if (this.fetched)
			promise.resolve(this);
		else {
			//console.log("MAPPOINTS START FETCHING");
			_this.loudFetch().then(function () {
				_this.fetched = true;
				//_this.createGrid();
				promise.resolve(_this);
			}, function () {
				promise.reject(_this);
			});
		}

		return promise;
	},
	updatePoints: function(points) {
		this.each(function(point) {
			var exist = points[point.id];
			var offs = {};
			var rlts = {};
			if (exist) {
				offs = exist.offers;
				rlts = exist.realties;
			}
			point.offers = offs;
			point.realties = rlts;
			//point.trigger('change', point);
			//console.log('.');
		});
		this.trigger('mass:change');
	},
	clearCounts: function () {
		this.each(function (point) {
			point.offers = {};
			point.realties = {};
		});
	}
});

var mapPoints = new MapPoints();

Bus.Models.reply('geo:mapPoints', function () {
	return mapPoints;
});

Bus.Models.reply('geo:mapPoint', function() {
	return MapPoint;
});

Bus.Models.reply('geo:mapPoint:latlng', function (id) {
	var p = mapPoints.get(id);
	return p && p.latlng();
});

export default mapPoints;