module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<small>'+
((__t=(caption))==null?'':__t)+
'</small>\n<span>'+
((__t=(label))==null?'':__t)+
'</span>\n<button class="close">×</button>';
}
return __p;
};
