﻿//define('ui-controls-datetime',[],function(){});

import template from './control.html';
import { MneView } from 'core/mne';
import { moment } from 'vendors';

var View = MneView.extend({
	className: 'ui-control-datetime',
	//template: '#tmpl-ui-controls-datetime-control',
	template,
	ui: {
		'datetimepickerInput': 'input[name=datetimelabel]',
		'datetimepickerInline': '[data-role=datetimeInline]',
	},
	events: {
		'change @ui.datetimepickerInline': function (e, value) {
			this.value = value;
			this.ui.datetimepickerInput.val(moment(value).fromNow());
			this.updateValue(e);
		},
	},
	updateValue: _.throttle(function (e) {
		this.triggerMethod('value:inputed', this.value, this.viewName);
		this.triggerMethod('value:changed', this.value, this.viewName);
	}, 100),
	onRender: function() {
		//var picker = this.getOption('noTime') ? 'datepicker' : 'datetimepicker';
		var picker = 'datetimepicker';
		var noTime = this.getOption('noTime') === true;
		this.ui.datetimepickerInline[picker]({
			value: this.value,
			forceRefresh: true,
			noTime: noTime,
		});

	},

	initialize: function () {
		//console.log(this.options);
		this.valueType = this.getOption('valueType') || 'string';
		this.value = this.getOption('initialValue');
		if (!(this.value instanceof Date))
			this.value = new Date(this.value);
	},
	templateContext: function () {

		let valueType = this.getOption('valueType');
		let value = this.value;
		let label = this.getOption('valueType');

		return {
			valueType: valueType,
			value: value,
			label: label, 
			moment
		}
	}
});

View.create = function (opts) {
	return new View(opts);
}

export default View;