export const viewFetchMoreMixin = {
	fetchNext() {
		if (this.getOption('fetchNextAllowed', true) === false) {
			return;
		}
		return this.collection.fetchNext();
	},
	fetchPrev() {
		if (this.getOption('fetchPrevAllowed', true) !== true) {
			return;
		}
		return this.collection.fetchPrev();
	}
}