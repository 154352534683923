export function splitComaString(text) {
    return text.split(/\s*,\s*/);
}

export function textArrayToHash(arr) {
    return arr.reduce((hash, value) => {
        hash[value] = 1;
        return hash;
    }, {});
}

export function getStringHash(text) {
    let arr = splitComaString(text);
    return textArrayToHash(arr);
}

export function textsHaveIntersections(text1, text2) {
    let h1 = getStringHash(text1);
    let h2 = getStringHash(text2);
    return Object.keys(h1).some(key => key in h2);
}

export function toFirstLetters(text, toUpper = true) {
	if (!text) { return text; }
	return text
    .split(/\s+/)
    .map(c => {
        let first = (c[0] || '');
        if (toUpper) {
            first = first.toUpperCase();
        }
        return first;
    })
    .filter(f => !!f)
    .join('');
}