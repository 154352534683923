﻿//define('_libs/identity', ['_libs/identity/model'], function (Model) {});

import { identity } from 'mods/acc/Identity';

// import Model from './model';
// const identity = new Model(); 

const userConfig = identity.cfg;

//Model.create();

export {
	identity, 
	userConfig
}

export default identity;
