﻿//define('acc-login-layout',['server-cfg', 'app-config', 'ui/controls/forms/simple-form-mixin', 'modals', '_libs/appleCookieStore'],function(srvcfg, cfg, formMixin, modals, acs){});


import srvcfg from 'server-cfg';
import cfg from 'app-config';
import formMixin from 'ui/controls/forms/simple-form-mixin';
import modals from '_libs/modals';
import acs from '_libs/appleCookieStore';

import template from './layout.html';

import { MneView } from 'core/mne';
import { urls } from 'coms/urls';
import { newToken } from 'mods/acc/NewToken';
// var template = `
// <div class="form-group">
// 	<input class="form-control" name="userName" type="email"/>
// </div>
// <div class="form-group">
// 	<input class="form-control" name="password" type="password"/>
// </div>
// <div class="form-group">
// 	<label><input type="checkbox" name="rememberMe" checked> Запомнить меня</label>
// </div>
// <button class="btn btn-default submit" data-role="submit">войти</button>
// `;

var Layout = formMixin.mix(MneView).extend({
	template, //: '#tmpl-acc-login-layout',

	//template: () => '',

	//template: () => '',

	formUrl: () => cfg.apiUrl('acc/oldlogin'),
	formUrl: () => urls.api('_token'),
	sendOptions: {
		method: 'POST',
		contentType: 'x-www-form-urlencoded',
		xhrFields: {
			withCredentials: true
		},
	},
	old_validateData(data) {
		let errors = [];

		if (!data.userName) {
			errors.push('Укажите свой логин');
		}
		if (!data.password) {
			errors.push('Укажите свой пароль');
		}

		return errors.length ? errors : void 0;
	},
	new_validateData(data) {
		let errors = [];

		if (!data.username) {
			errors.push('Укажите свой логин');
		}
		if (!data.password) {
			errors.push('Укажите свой пароль');
		}

		return errors.length ? errors : undefined;
	},
	validateData(data) {
		return this.new_validateData(data);
	},
	extendFormData(data) {

		if (!srvcfg.isExternal && process.env.EXT_LOGIN !== 'general') {
			console.log('-extending-');
			data.chunk = process.env.LOGIN_CHUNK;
		}
	},
	getFormData(frm) {
		return {
			grant_type: 'password',
			username: frm.userName,
			password: frm.password,
		}
	},
	getBackUrl() {
		let module = this.getOption('module');
		// console.log('module.query', module.query);
		if (!module || !module.query || !module.query.backUrl) return;
		return decodeURIComponent(module.query.backUrl);
	},
	initialize() {
		console.log('#### chick', this);
		console.log('# LOGIN BACKURL:', this.getBackUrl());
		this._initializeClickHandler();
	},

	onSendSuccess(tokenObject) {
		console.error(tokenObject);
		acs.set(tokenObject && tokenObject.token);
		this._doRedirect();
	},
	_doRedirect() {
		let backUrl = this.getBackUrl() || '/';
		document.location.href = backUrl;
	},
	sendData(url, data) {
		return newToken.loginAsync(data.username, data.password).then(() => this._doRedirect());
	},

	shmeevents: {
		'shclickclick [data-role="submit"]:not(:disabled)'(e) {
			let data = {};
			let toggle = val => this.$('button').prop('disabled', val);
			this.$('input[name]').each((ind, el) => {
				data[el.name] = el.value;
			});
			toggle(true);
			let loginUrl = cfg.apiUrl('acc/oldlogin');
			$.ajax(loginUrl, {
				method: 'POST',
				xhrFields: {
					withCredentials: true
				},
				data
			}).then(res => {

				toggle(false);
				//document.location.href = '/';

				// cfg._createToken(res);
				// identity.populate({ force: true }).then(() => {
				// 	console.log('YIII HAA!');
				// 	busApp.trigger('navigate', '/',true);
				// });
				// console.log('res!', res);

			}, xhr => {
				toggle(false);
				modals.xhrError(xhr);
				//console.log('okay okay');
			});
			//console.log('data-', data);
		}
	},

});
export default Layout;