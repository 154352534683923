import { editInModal } from "coms/ui/EditView";
import { EditContactLayout } from './views/edit-contact';
import modals from '_libs/modals';
import { urls } from 'coms/urls';
import { backendApi } from "../../apis/backend";

export function editContactInModal(model, ext = {}) {

	const isNew = !model;
	const header = isNew ? 'добавление контактного лица' : 'изменение контактного лица';
	const contentArg = {
		header,
		buttons: false,
		content: {
			class: EditContactLayout,
			model,
			isNew,
			...ext
		},
		childViewEvents: {
			'edit:done'() {
				this.destroy();
			}
		}
	}

	return editInModal(contentArg, {
		cssClasses: {
			dialog: "edit-contact-modal-dialogue",
		}
	});
}



export function removeContact(model, opts = {}) {
	modals.confirm('Подтвердите удаление').then(() => {
		
		const collection = model.collection;

		const sync = () => {
			collection.remove(model);
		}

		const { owner } = opts;

		const oid = owner?.id;

		const cid = model.id;

		const url = urls.api(`v4/realties/owners/${oid}/contacts/${cid}`);
		backendApi.delete(url, { sync, relativeUrl: false });		
		

	}, () => {});
}