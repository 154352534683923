import { _ } from 'vendors';
import refs from 'references';

export function getFilters(values) {
	

	var filters =  [
		{
			id: 'my',
			group: 'base',
			order: -30,
			caption: 'каких клиентов показывать',
			//values: ['offices'],
			emptyText: 'всех',
			modelType: 'single',
			valueType: 'string',
			sourceValues: { false: 'всех', true: 'только моих' },
			values: ['true'],
			//flattenValues: true,
			//multiple: false,
			pinned: true,
		},
		{
			id: 'wid',
			group: 'base',
			order: -1,
			caption: 'id клиента',
			emptyText: 'не указан',
			modelType: 'single',
			valueType: 'number',
			multiple: false,
			pinned: true,
		},		
		{
			id: 'text',
			group: 'base',
			order: -2,
			caption: 'текст',
			//values: ['offices'],
			emptyText: 'не задан',
			modelType: 'single',
			valueType: 'string',
			//sourceValues: refs.enum('realtyMarkets'),
			//flattenValues: true,
			//multiple: false,
			pinned: true,
		},
		//{
		//    id: 'person',
		//    group: 'base',
		//    order: -3,
		//    caption: 'контакт',
		//    //values: ['offices'],
		//    emptyText: 'не задан',
		//    modelType: 'single',
		//    valueType: 'string',
		//    //sourceValues: refs.enum('realtyMarkets'),
		//    //flattenValues: true,
		//    //multiple: false,
		//    pinned: true,
		//},
		{
			id: 'dlskey',
			group: 'base',
			order: -1,
			caption: 'ключевое слово по процессу',
			//values: ['offices'],
			emptyText: 'не задано',
			modelType: 'single',
			valueType: 'string',
			//sourceValues: refs.enum('realtyMarkets'),
			//flattenValues: true,
			//multiple: false,
			pinned: true,
		},
		{
			id: 'hastags',
			group: 'base',
			caption: 'содержит тэги #',
			emptyText: 'не заданы',
			modelType: 'single',
			valueType: 'string',
			controlType: 'tagsInput',
			flattenValues: true,
			multiple: true,
			pinned: true,
		},
		{
			id: 'notags',
			group: 'base',
			//order: -2,
			caption: 'не содержит тэги #',
			//values: ['неработаем'],
			emptyText: 'не заданы',
			modelType: 'single',
			valueType: 'string',
			controlType: 'tagsInput',
			flattenValues: true,
			multiple: true,
			pinned: true,
		},		
		{
			id: 'mt',
			group: 'base',
			order: 0,
			caption: 'рынок',
			values: ['offices'],
			emptyText: 'не выбран',
			modelType: 'single',
			valueType: 'string',
			sourceValues: refs.enum('realtyMarkets'),
			flattenValues: true,
			multiple: false,
			pinned: true,
		},
		{
			id: 'mo',
			group: 'base',
			order: 0,
			caption: 'операция',
			values: ['rent'],
			emptyText: 'не выбрана',
			modelType: 'single',
			valueType: 'string',
			sourceValues: refs.enum('realtyOperations'),
			flattenValues: true,
			multiple: false,
			pinned: true
		},
		{
			id: 'sqr',
			group: 'base',
			order: 0,
			caption: 'площадь',
			//values: [''],
			emptyText: 'не выбрана',
			modelType: 'range',
			valueType: 'number',
			//sourceValues: refs.enum('dealStages'),
			//flattenValues: true,
			multiple: false,
			pinned: true
		},
		{
			id: 'type',
			group: 'base',
			order: 0,
			caption: 'тип',
			//values: ['offices'],
			emptyText: 'не выбран',
			modelType: 'single',
			valueType: 'string',
			sourceValues: refs.enum('clientTypes'),
			flattenValues: true,
			multiple: true,
			pinned: true,
		},
		{
			id: 'actions',
			group: 'base',
			order: 10,
			caption: 'задачи',
			//values: ['offices'],
			emptyText: 'не выбран',
			modelType: 'single',
			valueType: 'string',
			sourceValues: refs.enum('clientActionsPredicates'),
			flattenValues: true,
			multiple: true,
			pinned: true,
		},
		{
			id: 'deals',
			group: 'base',
			order: 10,
			caption: 'процессы',
			//values: ['offices'],
			emptyText: 'не выбран',
			modelType: 'single',
			valueType: 'string',
			sourceValues: refs.enum('clientDealsPredicates'),
			flattenValues: true,
			multiple: true,
			pinned: true,
		},
	];

	values || (values = {});


	let webid;

	_.each(values, (value, id) => {
		const filter = _.findWhere(filters, { id: id });
		if (!filter) return;
		filter.values = value;
		if (id == 'wid') {
			webid = value;
		}
	});


	if (webid != null) {
		filters.forEach(filter => {
			if (filter.id !== 'wid') {
				filter.values = undefined;
			}
		})
	}

	return filters;

}