import Module from 'appModule';
import link from 'link';
import Layout from './layout';
import objectModule from './object';


var module = Module.content({
	name: 'actualization/objects-list',
	label: 'Справочник площадей',
	routeData: () => link.route('actualizationRealtyObjectsList'),
	urlKey: 'actualizationRealtyObjectsList',
	claims: {
		svcActualizationModule: ['view']
	},
	onRun: function () {
		let view = new Layout();
		this.createAndShow(view, 'Справочник площадей');
	}
});


module.registerModule(objectModule);

export default module;
