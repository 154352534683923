import { Behavior } from 'vendors';

export const ClickableBehavior = Behavior.extend({

    events() {
        let res;
        let handlerKey = this.buildHandlerKey();
        if (handlerKey != null) {
            res = {
                [handlerKey]: '_click'
            }
        } else {
            let triggers = this.view.getOption('clickTriggers');
            if (triggers) {
                res = _.reduce(triggers, (hash, eventName, selector) => {
                    hash['click ' + selector] = (event) => this._click(event, eventName);
                    return hash;
                }, {});
            }
        }
        //console.log('- - - - > behs', res);
        return res;
    },

    buildHandlerKey() {
        let clicktriggers = this.view.getOption('clickTriggers');
        if (clicktriggers) {
            return;
        }
        let key = 'click';
        let selector;
        let ownSelector = this.getOption('selector');
        if (ownSelector) {
            selector = ownSelector;
        } else {
            let viewSelector = this.view.getOption('clickElementSelector');
            if (viewSelector) {
                selector = viewSelector;
            }
        }
        if (selector) {
            key += ' ' + selector;
        }
        return key;
    },

    _click(event, arg) {
        if (arguments.length == 1) {
            let eventName = this.getOption('triggerEvent') || 'clicked';
            this.view.triggerMethod(eventName, event, this.view);
        } else {
            let type = typeof arg;
            if (type === 'function') {
                return arg.call(this.view, event, this.view);
            } else if (type === 'string') {
                this.view.triggerMethod(arg, event, this.view);
            }
        }
    }

});

/**
 * 
 * @param {string} selector dom jquery selector
 * @param {string} trigger event name to trigger
 * @returns 
 */
ClickableBehavior.options = (selector, triggerEvent) => {
    return { behaviorClass: ClickableBehavior, selector, triggerEvent }
}
