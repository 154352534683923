import busSvc from 'bus/svc';
import EditValue from 'ui/controls/editValue';

// export function selectEmployeesInModal(options, callback) {

// 	const setup = Object.assign({
// 		valueType: 'string',
// 		editValueClassName: 'flexible-edit-modal select-employees-editValue',
// 		controlType: 'selectEmployees',
// 		sourceValues: busSvc.request('employeesSelect'),
// 	}, options);

// 	return EditValue.modal.single(setup, { modalCssCfg: 'flexible' })
		//.done(callback);

// }


export function selectEmployeesInModernModal(options, callback, errorcallback) {
	const setup = Object.assign({
		valueType: 'string',
		editValueClassName: 'flexible-edit-modal select-employees-editValue',
		controlType: 'selectEmployees',
		sourceValues: busSvc.request('employeesSelect'),
	}, options);
	if (errorcallback == null) {
		errorcallback = err => err;
	}
	return EditValue.modal.single_modern(setup, { modalCssCfg: 'flexible' }).then(callback, errorcallback);
}