import { _ } from 'vendors/underscore';
import { $ } from 'vendors/jquery';

import { Model, View as BbView, Collection as BbCollection } from 'backbone';

// import { _, $, Model, BbView } from 'vendors';

const simples = [
    Date, String, Number, Boolean
]

export function isJq (v) {
    if (v == null) return false;
    if (v instanceof $) return true;
    //return v.jquery && v.length > 0; <- ???
}

export function isNotJq (v) {
    return !isJq(v);
}

export function isNotValue(v) {
    return v == null;
}

export function isValue(v) {
    return v != null;
}

export function isSimpleValue(v) {
    let type = typeof v;

    if (type === 'function') { return false; }
    
    let issimple = v == null || type !== 'object' || simples.some(simple => v instanceof simple);

    return issimple;
}

export function isEmpty(v) {
    if (isNotValue(v)) return true;
    let type = typeof v;
    if (type === 'function') return false;
    if (type === 'string' && v.trim() === '') return true;
    if (Array.isArray(v) && v.length === 0) return true;
    if (type === 'object') return _.size(v) === 0;
        //isEmptyObject(v);
    return false;
}

export function isEmptyObject(v) {
    if (typeof v !== 'object') {
        throw new Error('isEmptyObject accepts only objects');
    }
    return _.size(v) === 0;
}

export function isNotEmpty(v) {
    return !isEmpty(v);
}



export function isModel(arg) {
    return arg instanceof Model;
}

export function isView(arg) {
    return arg instanceof BbView;
}

function isClass(arg, ctor) {
    return arg && arg.prototype && (arg === ctor || arg.prototype instanceof ctor);
}

export function isModelClass(arg) {
    return isClass(arg, Model);
}

export function isViewClass(arg) {
    return isClass(arg, BbView);
}

export function isCollection(arg) {
	return arg instanceof BbCollection;
}

export function isNotFalsyValue(value, ...exclude) {
    if (exclude.length && exclude.indexOf(value) > -1) {
        return true;
    }
    return !!value;
}