import { MneView } from './view';



export const MneEmptyView = MneView.extend({
	text: 'подождите, данные не загрузились',
	getTemplate: function () {
		var txt = '';
		var context = this.model || this.collection;
		if (!context)
			txt = this.getOption('emptyText') || 'пусто';
		else if (!context.isFetched())
			txt = this.getOption('waitText') || 'подождите, данные загружаются';
		else if (context.isFetched())
			txt = this.getOption('emptyText') || 'пусто';
		return _.template(txt);
	},
});

