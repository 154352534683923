import { UiSchemaMoleculeView, UiSchemaAtomView } from "coms/schema";
import { contactValueSchema } from "../contactValueSchema";

export const BaseContactValueListItemView = UiSchemaMoleculeView.extend({
	childView: UiSchemaAtomView,
	modelSchema: contactValueSchema,
	childViewOptionsKeys: ['modelSchema'],
	// initialize() {
	// 	console.error('model', this.model);
	// 	this.listenTo(this.model, 'change', () => {
	// 		console.warn('CHANGE');
	// 		this.render();
	// 	})
	// },
	content: [{
		text: v => {
			console.log('breakpoint');
			return v.display('value.value');
		},
		topText: v => v.display('value.type'),
	}],
	modelEvents: {
		'change':'render'
	}
});