module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header>'+
((__t=( name))==null?'':__t)+
'</header>\n';
 if(leads){ 
__p+='<div class="leads" data-placeholderup="руководители">'+
((__t=( leads ))==null?'':__t)+
'</div>';
 } 
__p+='\n';
 if(emps){ 
__p+='<div class="emps" data-placeholderup="сотрудники">'+
((__t=( emps ))==null?'':__t)+
'</div>';
 } 
__p+='';
}
return __p;
};
