import { Collection } from './collection';
import { mergeUrls } from './common';
import { Model } from './model';
import { invokeValue } from 'utils/invoke';
import { objectToUrlParams } from 'utils/object-manipulations';

export const ApiCollection = Collection.extend({
	constructor: function(data, options = {}) {
		const attrs = { take: options.fetchTake };
		this.fetchResult = new Model(attrs);
		Collection.apply(this, arguments);
	},
	skipFieldName: 'result.skip',
	takeFieldName: 'result.take',
	parse(data) {
		if (data && Array.isArray(data)) {
			return data;
		} else if (!Array.isArray(data) && Array.isArray(data.items)) {
			const items = data.items;
			delete data.items;
			const additionals = data.additionals;
			delete data.additionals;
			this.fetchResult.set(data);
			this.parseAdditionals(additionals);
			return items;
		}
	},
	// getFetchResult() {
	// 	return this.fetchResult.attributes;
	// },
	parseAdditionals() { },
	fetchNext() {
		if (this.isEOD()) {
			return Promise.reject('end of data');
		}
		return this.fetch({ fetchDirection: 'next', remove: false });
	},
	fetchPrev() {
		if (this.isSOD()) {
			return Promise.reject('start of data');
		}
		return this.fetch({ fetchDirection: 'prev', remove: false });
	},
	isSOD() {
		let { skiped = 0 } = this.fetchResult.attributes;
		return skiped === 0;
	},
	isEOD() {
		let { skiped = 0, returned = 0, founded = 0  } = this.fetchResult.attributes;
		return skiped + returned >= founded;
	},
	fetch(options = {}) {
		const { url, addUrl } = options;
		options = { ...options };		
		options.url = mergeUrls(url, invokeValue(this.url, this, this), addUrl);
		if (!options.method && this.fetchMethod) {
			options.method = this.fetchMethod;
		}
		const data = this.getDataForFetch(options);
		if (data) {
			if (this.fetchMethod === 'get') {
				let paramString = objectToUrlParams(data);
				if (options.url.indexOf('?') === -1) {
					paramString = '?' + paramString;
				}
				options.url += paramString;
			} else {
				options.attrs = data;
			}
		}
		
		this._fetching = true;
		const error = options.error;
		const _this = this;
		options.error = function () {
			_this._fetching = false;
			if (error) error.apply(_this, arguments);
		}		
		const success = options.success;
		options.success = function() {
			_this._fetching = false;
			_this._lastFetch = Date.now();
			_this._fetchCounts = (_this._fetchCounts || 0) + 1;
			if (success) success.apply(this, arguments);
		};
		return Collection.prototype.fetch.call(this, options);
	},
	isFetching() { return !!this._fetching; },
	isFetched() { return !!this._lastFetch; },
	getDataForFetch(options) {
		const hash = this.getFilterData(options);
		const pagination = this.getPaginationData(options);
		return Object.assign({}, hash, pagination);
	},
	getFilterData(options = {}) {
		const { search, fetchDirection } = options;
		if (!fetchDirection) {
			this._search = search;
		}
		return this._search;
	},
	getPaginationData(options = {}) {
		const { fetchDirection } = options;
		let { skiped, taken, take } = this.fetchResult.attributes;
		if (!take) {
			take = taken || 50;
		}

		const res = { [this.takeFieldName]: take };

		if (!fetchDirection) { return res; }

		take = fetchDirection === 'prev' ? -take : take;
		const skip = skiped + take;
		res[this.skipFieldName] = skip;
		return res;
	},
	fetchMethod: 'get',
});