// import { detectView } from "./detectView";
// import { buildOptions } from "./buildOptions";
// import { optionsByValueType } from "./byValueType";
// import { Input } from "./views/Input";
import { EditValueView } from './EditValueView';
import { LabelValueView } from "./LabelValueView";

const inlinableValueTypes = {
	number: 1,
	date: 1,
	datetime: 1,
	time: 1,
	boolean: 1,	
}

export const controlsApi = {

	getValueControlView(options = {}) {
		const { valueSchema, inlineEdit } = options;
		if (inlineEdit && this.isInlineEditAllowed(valueSchema)) {
			return this.getEditValueViewOptions(options, { inline: true });
		} else {
			return this.getLabelViewOptions(options);
		}
	},

	isInlineEditAllowed(valueSchema) {
		const { valueType, valueSubType, multiple, inlineEdit } = valueSchema;
		
		if (inlineEdit === false) { return false; }

		if (valueType in inlinableValueTypes) {
			return true;
		}
		if (valueType === 'string') {
			return valueSubType !== 'textarea' || inlineEdit === true;
		}
		if (valueType === 'enum' && inlineEdit === true) {
			return true;
		}
		return false;
	},

	getLabelViewOptions(opts) {
		return Object.assign({ 
			class: LabelValueView,
			editable: true,
			// EditControl: this.getEditValueViewOptions(opts)
		}, opts);
	},

	getEditValueViewOptions(opts, { inline } = {}) {
		// const { valueSchema } = options;
		// const { inlineControlOptions, controlOptions } = valueSchema;
		// const add = inline && inlineControlOptions ? inlineControlOptions : controlOptions;
		return Object.assign({ class: EditValueView, inline }, opts);
	},

    // getViewOptions(valueSchema) {
    //     if (!valueSchema) { return };

    //     if (valueSchema.control) {
    //         throw new Error('not implemented. specific control passed');
    //     }

    //     if (valueSchema.valueType) {
    //         return optionsByValueType(valueSchema);
    //     }

    //     throw new Error('not implemented. control or valueType was not specified');

    // }

}