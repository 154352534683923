﻿//define('ui-models-values-base', [], function () {});

import { isValue } from 'utils';
import { Model as BbeModel } from 'core/bbe';
function getConverter(type,func)
{
	if (typeof func === 'function') return func;

	switch (type) {
		case 'number':
			return function (v) {
				return isValue(v) ? parseFloat(v, 10) : undefined;
			}
		case 'datetime':
			return function (v) {
				if (v == null || !_.isDate(v)) return v;
				if (!v.valid()) return;

				return v.toISOString();
			}
			
		default:
			return function (v) { return v;}
	}
}


var Model = BbeModel.extend({
	initialize: function (attrs, options) {
		this.valueType = _.result(options, 'valueType');
	},
	_normalizeUrlHashOptions: function (opts) {
		var options = _.extend({}, opts);

		var prefix = options.prefix ? options.prefix : '';
		var indexedPrefix = options.index ? prefix + '[' + options.index + ']' : prefix;
		var rdyPrefix = indexedPrefix !== '' ? indexedPrefix + '.' : '';
		options.prefix = rdyPrefix || '';



		return options;
	},
	getConverted: function (name,converter) {
		var v = isValue(this.get(name)) && this.get(name) !== '' ? this.get(name) : undefined;
		
		var converted = getConverter(this.valueType, converter)(v);
		return converted;
	},
});

export default Model;