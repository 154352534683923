import { View } from 'core';
import { _, mixinTemplateContext } from 'vendors';
import { buildChildView } from 'utils/build';
import { HamburgerView } from '../HamburgerView';
import { GlyphButtonView } from '../Button';

const abstractTemplate = _.template(`
<% if (hasHeader) {%>
<header><%= headerText %></header>
<% } %>
<section></section>
<% if (hasFooter) {%>
<footer></footer>
<% } %>
`);

const extendedTemplate = _.template(`
<% if (hasHeader) {%>
<header>
    <div class="ui2-atom elastic">
        <div class="header-text"><%= headerText %></div>
    </div>
    <% if (hasCloseButton) {%>
    <button class="ui2-box-close">
        <i class="glyphicon glyphicon-close"></i>
    </button>
    <% } %>
</header>
<% } %>
<section></section>
<% if (hasFooter) {%>
<footer></footer>
<% } %>
`);

_.template(`
<header>
    <div class="ui2-molecule">
        <div class="ui2-atom elastic">сотрудники</div>
        <button class="ui2-box-close">
            <i class="glyphicon glyphicon-close"></i>
        </button>
    </div>
</header>
<section></section>
<footer>
    <div class="add-employees ui2-molecule">
        <div class="icon"><i class="glyphicon glyphicon-plus"></i></div>
        <div class="ui2-atom"><span>добавить сотрудников</span></div>
    </div>
</footer>
`)

const _hasOptionOptions = { notFalsy:true };

export const BoxView = View.extend({
    constructor: function() {
        View.apply(this, arguments);
        this._initializeVariables();
    },
    baseClassName: 'ui2-box',
    template: extendedTemplate,
    regions: {
        header: 'header',
        content: 'section',
        footer: 'footer',
    },
    // initialize() {
    //     this._initializeVariables();
    // },
    onRender() {
        this.renderHeader();
        this.renderContent();
        this.renderFooter();
    },
    _initializeVariables() {
        let hasActions = this.hasOption('action', _hasOptionOptions) || this.hasOption('actions', _hasOptionOptions);
        this._flags = {
            hasHeader: this.hasOption('header', _hasOptionOptions),
            hasFooter: this.hasOption('footer', _hasOptionOptions) || hasActions,
            hasCloseButton: this.hasOption('closeButton', _hasOptionOptions),
        }
        this._values = {
            headerText: this.getTypedOption('header','string','')
        }
    },
    mixinTemplateContext(data) {
        let context = mixinTemplateContext(this, data);
        let mixed = _.extend({}, context, this._flags, this._values);
        return mixed;
    },
    renderHeader() {
        
        let header = this._flags.hasHeader && this.getTypedOption('header','object');
        if (!header) { return; }
				if (this.getOption('noBoxHeader', true)) {
					return;
				}
        let view = buildChildView(this, header, this.getOption('headerOptions'), { model: this.model, collection: this.collection });
        let headerView;
        if (this._flags.hasCloseButton) {
            headerView = new HamburgerView({
                className: 'ui2-molecule',
                childrenViews:[
                    view,
                    {
                        class: View,
                        tagName: 'button',
                        className: 'ui2-box-close',
                        template: '<i class="glyphicon glyphicon-close"></i>',
                        triggers: { 'click': 'click' },
                        onClick: () => this.triggerMethod('click:close')
                    }
                ]
            });
        } else {
            headerView = view;
        }

        this.showChildView('header', headerView);

    },
    renderFooter() {
        if (!this._flags.hasFooter) { return; }

        let replaceElement;

        let footer = this.getOption('footer', true);

        if (!footer) {
            footer = this.getActionsFooter();
            if (!footer) { return; }
            replaceElement = true;
        }

        let view = buildChildView(this, footer, this.getOption('footerOptions'), { model: this.model, collection: this.collection });
        if (!view) { return; }

        this.showChildView('footer', view, { replaceElement });
    },
    getActionsFooter() {
        let actions;
        let action = this.getOption('action', true);
        if (action) {
            actions = [action];
        } else {
            actions = this.getOption('actions', true);
        }
        if (!actions) { return; }
        let childrenViews = actions.map(action => {
            let { text, glyph, clickAction, thisClassName } = action;
            return {
                class: GlyphButtonView,
								thisClassName,
                glyph, text,
                onBeforeRender() {
                    if(this._dbgon) return;
                    this._dbgon = true;
                    // this.on('all', c => console.log('btn', c));
                },
                clickAction,
                // onClick() { console.log('on-click'); }
                // clickAction
                // onClick: clickAction
            }
        });
        return {
            class: HamburgerView,
            tagName: 'footer',
            thisClassName: 'ui2-box-actions',
            childrenViews,
        }

    },

    renderContent() {
        let content = this.getOption('content', true);
        if (content == null) { return; }

				let contentSectionOptions = this.getOption('contentSectionOptions', true) || {};
        
        let childrenViews;
        if (Array.isArray(content)) {
            childrenViews = content;
        } else {
            childrenViews = [content];
        }

        

        let view = new HamburgerView({
            tagName: 'section',
            model: this.model,
            collection: this.collection,
            childrenViews,
						...contentSectionOptions,
        });

        
        this.showChildView('content', view, { replaceElement: true });
    }

});