import { UiSchemaAtomView } from "coms/schema";
// import { TextView } from 'core/view';
import { contactValueSchema } from 'mods/contacts';
import { displayPhone, displayEmail } from 'utils/contact-value';
import { ContactValuesListView } from "./ContactValuesListView";
import { simplePopupMixin } from 'apis/popup';


export const valuesPopupMixin = {
	
	...simplePopupMixin,

	hoverPopup: {
		autoUpdateEnabled: true,
		openEvent: 'mouseenter',
		removeDelay: 500,
		wrapContent: true,
		popupViewOptions: {
			thisClassName: 'yellow contact-values-popup'
		},
		view: {
			class: ContactValuesListView,
		},
		useFlip: true,
		positionOptions: {
			placement: 'bottom-start',
		}
	},
}


export const MainContactValueView = UiSchemaAtomView.extend({
	...valuesPopupMixin,
	initialize() {
		this.initializePopupMixin();
	},
	// initialize() {
	// 	this.contact = this.model;
	// 	this.model = this.model.getMainValue();
	// },
	getMainPhoneUrl() {
		const value = this.model.getMainPhoneValue();
		return displayPhone(value, { url: true });
	},
	getMainEmailUrl() {
		const value = this.model.getMainEmailValue();
		return displayEmail(value, { url: true });

	},
	getModelSchema() {
		return contactValueSchema;
	},
	showSmall: true,
	showBottom: false,
	text: v => v.getMainPhoneUrl(),
	smallText: v => v.getMainEmailUrl()
	// smallText: v => displayPhone(v.value('value.value'))
});
