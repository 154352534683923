// define('ui-controls-selectBuilding', [
// 	'ui-controls-selectRest',
// 	'realties-models',
// 	'modals'
// ], function(
// 	SelectRest,
// 	realtiesNs,
// 	modals
// ){});


import SelectRest from 'ui/controls/selectRest';
import realtiesNs from '../../../service/realties/models';
import modals from '_libs/modals';


const SelectBuilding = SelectRest.extend({
	//initialValue: undefined,
	modelType: "single",
	multiple: false,
	sourceValues: () => realtiesNs.BuildingsRest.create(),
});

SelectBuilding.inModal = function(header, onSuccess, onFail) {
	if (!onSuccess) {
		onSuccess = () => {};
	}
	if (!onFail) {
		onFail = () => {};
	}
	let view = new SelectBuilding({ 
		header, 
		onChange(value) {
			console.log('on change:', ...arguments);
			this.triggerMethod('done', value);
		} 
	});

	let modal = modals.modal(view);
	return new Promise((res, rej) => {
		view.on('done', result => {
			onSuccess(result);
			modal.destroy();
			return res(result);
		});
		modal.on('destroy', () => {
			onFail();
			rej();
		});
	});
}

export default SelectBuilding;
