module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h1>Изменение контакта</h1>\n<p>Внесите изменения и нажмите кнопку <code>сохранить</code></p>\n<div class="form-group radio-btn-group inline header">\n	'+
((__t=(contactTypesHtml()))==null?'':__t)+
'\n</div>\n';
if(!isPerson){
__p+='\n<div class="form-group isNotPerson" data-placeholder="название">\n	<input class="form-control" name="contactName" value="'+
((__t=( _m.contactName ))==null?'':__t)+
'" />\n</div>\n';
}
__p+='\n';
if(isPerson){
__p+='\n<div class="form-group isPerson">\n	<div class="inline" data-placeholder="фамилия">\n		<input class="form-control" name="person.name.last" value="'+
((__t=( _mv('person.name.last') ))==null?'':__t)+
'" />\n	</div>\n	<div class="inline" data-placeholder="имя">\n		<input class="form-control" name="person.name.first" value="'+
((__t=( _mv('person.name.first') ))==null?'':__t)+
'" />\n	</div>\n	<div class="inline" data-placeholder="отчество">\n		<input class="form-control" name="person.name.middle" value="'+
((__t=( _mv('person.name.middle') ))==null?'':__t)+
'" />\n	</div>\n</div>\n';
}
__p+='\n<div class="form-group">\n	<div class="form-control checkbox-btn-group inline" data-placeholder="функции">\n		'+
((__t=( funcsCheckboxes() ))==null?'':__t)+
'\n	</div>\n</div>\n<div class="form-group">\n	<button class="btn btn-default edit-complete">сохранить</button>\n	<button class="btn btn-transparent pull-right edit-cancel">отменить</button>\n</div>';
}
return __p;
};
