//define('acc/logoff', ['appModule', 'app-config', 'modals', '_libs/appleCookieStore'], function(Module, cfg, modals, acs){});

import Module from 'appModule';

import cfg from 'app-config';
import modals from '_libs/modals';
import acs from '_libs/appleCookieStore';
import { newToken, identity } from 'mods/acc';

var module = Module.content({
	auth: true,
	name: 'accLogoff',
	noHeaderMenu: true,
	routeData: function() {
		return 'acc/logoff';
	},
	onRun: function() {

		identity.clear();
		newToken.clear();
		document.location.href = '/';
		//this.createAndShow(Layout, 'Введите свой логин и пароль', { }, {addClass:'short-module'});
	},
	_oldFashionLogoff() {
		let headers = acs.getHeaders();

		$.ajax({
			url: cfg.apiUrl('acc/oldlogoff'),
			method: 'POST',
			xhrFields: {
				withCredentials: true
			},
			headers
		}).then(
			() => {
				acs.set(null);
				document.location.href = '/';
			},
			xhr => {
				console.log('by the way, there is an error', xhr);
				modals.xhrError(xhr);
			}
		)
		console.log('run', this.name);
	}
});


export default module;