module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<button class="decrement"><i></i></button>\n<span class="year">'+
((__t=( year))==null?'':__t)+
'</span>\n<span class="month">'+
((__t=( month))==null?'':__t)+
'</span>\n<button class="increment"><i></i></button>';
}
return __p;
};
