import { ContactView } from './ContactView';
import { BaseContactsListView } from 'mods/contacts';

export const ContactsListView = BaseContactsListView.extend({
	childView: ContactView,
	childViewOptions() {
		return {
			realties: this.getOption('realties', true),
			owner:  this.getOption('owner', true),
		}
	}
});