﻿//define('m-deals-dealOffer', ['bus', 'm-abstract','m-realties-offers-offer', 'action', 'references'],function(Bus, abstractNs, Offer, action, refs){});

//let Bus, abstractNs, Offer, action, refs;

import Bus from 'bus';
import abstractNs from 'm/abstract';
import Offer from 'm/realties/offers/offer'; // 'm-realties-offers-offer'
import action from '_helpers/action';
import refs from 'references';
import { formatNum } from 'utils';

import { BbeModel } from 'core/bbe';

var fnRadio = Bus.channel('helpers');

var DealOffer = abstractNs.ActionableModel.extend({



	getOffer: function () {
		var raw = this.get('realtyOffer');

		if (raw == null || raw instanceof BbeModel) return raw;

		var off = new Offer(raw);
		this.set('realtyOffer', off);
		return off;
	},

	getLabel: function () {
		var _m = this.toJSON();
		//var o = _m.realtyOffer;
		var offer = this.getOffer();

		//var fn = function (v, o) { return fnRadio.request('formatNum'/*DEPRECATED*/, v, o); };
		//let fn = (v,o) => formatNum(v, o);
		let fn = formatNum;
		var res = [];

		if (!offer) {
			res.push(fn(_m.square) + 'м');
			res.push(fn(_m.priceMeter) + 'р.');
			res.push(_m.incomePercent + '%');
		}
		else {
			res.push(offer.getSquare({ formated: true }) + 'м');
			res.push(offer.getPriceMeter({ formated: true }) + 'р.');
			res.push(offer.getIncomePercent({ formated: true }));
		}
		return res.join(' &mdash; ');			
	},


	actions: function () {
		return [
			action('openComment', 'прокомментировать', {}, { order: 0 })
			, action('stageUp', 'на следующую стадию', {}, { order: 0, rule: function () { return this.get('stageId') != "income"; } })
			, action('showOffer', 'показать предложение', {}, { order: 0, rule: function () { return !!this.get('realtyOffer'); } })
			//action('openModal', 'показать', {}, { order: 0 })
			//, action('openSearchPage', 'подобрать предложения', {}, { order: 0 })
			//, action('addVirtualOffer', 'добавить виртуальное предложение', {}, { order: 0 })
			//, action('addEntityTask', 'поставить задачу', {}, { order: 0 })
			//, action('changeStage', 'изменить стадию', {}, { order: 0 })
			//, action('extendTeam', 'расширить команду', {}, { order: 0 })
			//, action('complete', 'завершить', {}, { order: 0 })
			//, action('cancel', 'отказаться', {}, { order: 0 })
			//, action('joinOffersToDeal', 'прикрепить предложения к процессу', {}, { order: 0, hidden: true })
			//, action('openPage', 'перейти на страницу процесса', {}, { order: 0 })
		];
	},

	actionOpenComment: function (options) {
		this.trigger('action', 'openComment', options);
		// var view = Bus.Views.request('dealOffer:edit');
		// view.openInModal({ model: this });
	},
	actionStageUp: function () {
		var currentStage = this.get('stageId');
		var takeNext;
		var newstage;
		_(refs.enum('dealStages')).some(function (v, enumStageId) {
			if (takeNext) newstage = enumStageId;
			if (enumStageId == currentStage) takeNext = true;
			return !!newstage;
		});

		if (!newstage) return;

		this.loudPatch({ stageId: newstage });

		//console.log();
	},
	actionShowOffer: function () {
		var offer = this.getOffer();
		if (!offer) return;
		offer.executeAction({ action: 'openModal' });
	}


});


export default DealOffer;
