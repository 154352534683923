import { NextIconButtonView } from 'coms/ui/Button';
import { View, TextView, CollectionView, Collection  } from 'core';
import { HamburgerView } from "coms/ui/HamburgerView";
import { invokeValue, smartOpen } from 'utils';
import { actorHasClaims } from 'mods/acc';
import { DropdownActions } from "coms/ui/DropdownActions";
import { moment } from 'vendors';
import { takeFirst } from 'utils';


export const executeClickActionMixin = {
	events: {
		'click'(event) {
			const clickAction = this.getOption('clickAction', true);
			const action = this.model.getAction(clickAction);
			if (action.get('multyAction')) {
				console.log('MULTY trigering!!');
				this.trigger('multy:action:click', undefined, action, [this.model]);
				return;
			}
			console.log('EXECUTIN NOT MULTY')
			this.model.executeAction(clickAction, event);
		}
	}
}


export const SelectButton = NextIconButtonView.extend({
	baseClassName: 'select-button',
	icon: 'fa:check-square-o',
	action: 'trigger:toggle:select'
});

export const Separator = View.extend({
	className: 'elastic'
});

export const GroupRow = HamburgerView.extend({
	baseClassName: 'group-row',
});

export const GroupColumn = HamburgerView.extend({
	baseClassName: 'group-column',
});


export const SingleValue = View.extend({
	baseClassName: 'single-property',
	template: '<%= label %><%= value %>',
	initialize() {
		this.property = this.getOption('property') || '';
		this.propertyClassName = this.property.replace(/\./g, '-');
		if (this.propertyClassName) {
			this.addClassName(this.propertyClassName);
			this.updateClassName();
		}
	},
	getDisplaySchemaKeys() {
		if (!this.property) { return [] }
		return { [this.property]: 'value' };
	},
	templateContext() {
		const options = this.getOption('displayOptions');
		const h = this.getDisplayHash(options);
		h.label = '';
		if (this.getOption('withLabel')) {
			const label = this.model.label(this.property) || '';
			h.label = `<label>${label}</label>`
		}
		return h;
	}
});

export const MultyValues = HamburgerView.extend({
	getChildren() {
		const props = this.getOption('properties');
		if (!props) { return; }
		return props.map(property => ({
			class: SingleValue,
			property
		}));
	}
});

function reduceActions(model, key) {
	const rawActions = invokeValue(model[key], model, model) || [];
	return reduceRawActions(rawActions);
	// const actions = rawActions.reduce((memo, action) => {
	// 	action = invokeValue(action, model, model);
	// 	if (!action) { return memo; }
	// 	action = { ...action };
	// 	if (!action.id) {
	// 		console.warn('warning: action missing id! ', action.label);
	// 	}
	// 	memo.push(action);
	// 	return memo;
	// }, []);
	// return actions;
}

function reduceRawActions(rawActions) {
	const actions = rawActions.reduce((memo, action) => {
		// action = invokeValue(action, model, model);
		if (!action) { return memo; }
		action = { ...action };
		if (!action.id) {
			console.warn('warning: action missing id! ', action.label);
		}
		memo.push(action);
		return memo;
	}, []);
	return actions;
}

export function actionFilter(action, model) {
	const { filter, claims } = (action.attributes || action);
	if (claims != null && !actorHasClaims(claims)) {
		return false;
	}
	if (typeof filter === 'function' && !filter(model)) {
		return false;
	}
	return true;
}

export const actionsMixin = {


	_getRawActions(key = 'actions') {
		return invokeValue(this[key], this, this) || [];
	},

	_buildActionsCollection(Ctor = Collection) {
		const raw = this._getRawActions();
		const actions = reduceRawActions(raw);
		return new Ctor(actions);
	},

	_getActions({ models } = {}) {
		if (!this._actions) {
			// const actions = reduceActions(this, 'actions');
			// new Collection(actions);
			this._actions = this._buildActionsCollection();
		}
		return models ? [...this._actions.models] : this._actions;
	},
	getAction(id) {
		const actions = this._getActions();
		return actions.get(id);
	},
	getActions({ place, models } = {}) {
		if (place && !models) {
			models = true;
		}
		let actions = this._getActions({ models });
		if (!place) {
			return actions;
		}

		actions = actions.filter(action => {
			const places = action.get('places');
			if (places && places.indexOf(place) > -1) {
				return true;
			}
			return false;
		});
		return actions;
	},
	executeAction(actionName, event, ...args) {
		const actions = this.getActions();
		const action = actions.get(actionName);
		if (!action) {
			console.warn('action missing: ', actionName);		
			return;	
		}

		let { url, newWindow } = action.attributes;
		if (url) {
			url = invokeValue(url, this, this);
			let options = newWindow ? { ctrlKey: !event.ctrlKey } : event;
			smartOpen(url, options);
			return;
		}

		const cb = action.get('action');
		if (typeof cb !== 'function') {
			console.warn('action\'s action is not a function', action.id || actionName);			
		} else {
			return cb(this, event, ...args);
		}
	}
}

export const plateActionsMixin = {

}

export const PlateActions = DropdownActions.extend({
	thisClassName: 'actions-button',
	classNames: [ 
		v => v.getOption('actionsName')
	],
	actions(){ 
		const actions = this.model.getActions({ place: 'plate' });
		const label = invokeValue(this.model.plateActionsHeader, this.model, this.model);
		if (label)
			actions.unshift({ label, type: 'header' });
		return actions;
	},
	viewFilter(v) {
		return actionFilter(v.model, this.model); //.plateActionViewFilter(v.model);
	},
	tryExecute(child, event) {
		const type = this.getOption('type');
		if (child.model.get('multyAction')) {
			this.trigger('multy:action:click', type, child.model, [this.model]);
			return;
		} 
		return this.model.executeAction(child.model, event);
	}
});


const ActualizeDate = TextView.extend({
	baseClassName: 'last-actualize-date',
	getText() {
		const date = this.model.getActualizeDate();
		if (!date) { return ''; }
		return moment(date).fromNow();
	}
});

const ActualizatorName = TextView.extend({
	baseClassName: 'last-actualize-name',
	getText() {
		return this.model.getByPath('actualize.lastEmployeeName') || this.model.getByPath('actualize.by') || '';
	}
});




export const LastActualize = HamburgerView.extend({
	baseClassName: 'last-actualize',
	thisClassName: 'flex-column',
	childrenViews: [
		ActualizeDate,
		ActualizatorName
	]
})

export const actualizeMixin = {
	getActualizeDate() {
		const actualize = this.get('actualize');
		if (actualize.last) {
			return actualize.last;
		}
		return this.get('modified');
	},
	getActualizeActions() {
		return this.getActions({ place: 'actualize' });
	}
}

const ActualizeAction = NextIconButtonView.extend({
	baseClassName: 'action',
	getText() {
		return this.model.get('label');
	},
	action:'trigger:multy:action:click'
	// action() {
	// 	const entity = this.getOption('entity');
	// 	return entity.executeAction(this.model);
	// }
});

const ActualizeActions = CollectionView.extend({
	className: 'actions flex-row gap-on',
	childView: ActualizeAction,
	childViewOptions() {
		return {
			entity: this.model,
		}
	},
	initialize() {
		const actions = this.model.getActualizeActions();
		this.collection = new Collection(actions);
	},
	viewFilter(v) {
		return actionFilter(v.model, this.model);
	},
	viewComparator: 'order',
	childViewEvents: {
		'multy:action:click'(event, child) {
			console.log('trying...')
			this.trigger('multy:action:click', undefined, child.model, [this.model])
		}
	}
});

export const ActualizeBlock = HamburgerView.extend({
	baseClassName: 'actualize-block',
	thisClassName: 'flex-column',
	childrenViews: [
		ActualizeDate,
		ActualizatorName,
		ActualizeActions,
	],
	childViewTriggers: {
		'multy:action:click':'multy:action:click'
	}
});


export function isNotActualized(last, daysToStale, passedDaysToStale) {

	if (!last) { return true; }

	last = new Date(last);
	if (isNaN(last.valueOf())) { return true; }

	daysToStale = parseInt(takeFirst(daysToStale, passedDaysToStale), 10);
	if (isNaN(daysToStale)) { daysToStale = 15; }

	const date = new Date();
	date.addDays(-daysToStale);
	date.addHours(-date.getHours());
	date.addMinutes(-date.getMinutes());
	date.addSeconds(-date.getSeconds());
	return last < date;

}