﻿//define('svc-staff-deps', ['appModule', 'link', '_libs/identity', 'svc-staff-deps-layout', 'svc-staff-deps-department', 'svc-staff-deps-create'], function (Module, link, identity, Layout, moduleDepartment, moduleDepartmentCreate) {});

import Module from 'appModule';
import link from 'link';
import Layout from './layout';
import moduleDepartment from './department.v2';
import moduleDepartmentCreate from './create';

import './departments.less';

var module = Module.content({
	name: 'staff-departments',
	urlKey: 'staffDepartments',
	label:'Отделы',
	routeData: function() {
		return link.route('staffDepartments');
	},
	//addNavLinks: function () {
	//	return [
	//		link('staffDepartmentCreate','создать отдел')
	//	];
	//},
	onRun: function() {
		
		this.showButtonsLayout();
		this.createAndShow(Layout);
	},
});

module.registerModule(moduleDepartmentCreate);
module.registerModule(moduleDepartment);

export default module;
