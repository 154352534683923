import { EditLayout } from '../../../entity-list-common';

//import APPBUS from 'bus/app';
//import bus from './properties/bus';
//import Properties from './properties';
import tabs from './tabs';
// import RealtySchemaCollection from '../../realty-schema';
import realtySchemaCollection from '../../realty-schema';
import RealtyModel from '../../realty-model';
//import NavTabsView from './nav-tabs';
import './edit.less';

const Layout = EditLayout.extend({
    className: 'entity-edit-page realty-edit-page',

    tabs,
    //bus,
		tabModelDefaults: {
			claims: {
				svcActualizationModule: ['view']
			}
		},
		
    buildModels() {
        this.model = new RealtyModel({ id: this.getOption('realtyId') });
        this.collection = realtySchemaCollection;
				// new RealtySchemaCollection();
    },

    getModelHeader() {
        return this.model.get('prefix') + ' ' + this.model.get('name');
    },

});

export default Layout;

