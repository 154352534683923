import { _, moment } from 'vendors';
import SchemaEdit from 'ui/controls/schemaEdit';
import refs from 'references';
import { schemaEditApi, modelSchemaApi } from 'apis/schema';
import { EditPriceView } from '../../EditPriceView';
import { modalsApi } from '../../../../../../apis/modalsApi2';



const get = res => {
	if (!res) { return }
	return res.value;
}
export function editCommercialProperties(defaultValues = {}, modelSchema) {
	modelSchema = modelSchema || modelSchemaApi.getModelSchema('realtyOffer');
	const properties = [
		'forCustomer.taxType',
		'forCustomer.boma',
		'forAgent.income',
		'forAgent.contractType',
	];
	const header = 'Изменение коммерчиских учсловий';
	const value = {};
	_.each(properties, key => {
		value[key] = defaultValues[key];
	});
	value.operation = defaultValues.operation;
	
	return schemaEditApi.editProperties(properties, { header, value: value, modelSchema, inlineEdit: true }).then(res => get(res));
}

export function editMainProperties(defaultValues = {}, modelSchema) {
	modelSchema = modelSchema || modelSchemaApi.getModelSchema('realtyOffer');
	const properties = [
		'status',
		'visibleFor',
	];
	const header = 'Изменение основных характеристик';
	const value = {};
	_.each(properties, key => {
		value[key] = defaultValues[key];
	});
	return schemaEditApi.editProperties(properties, { header, value: value, modelSchema, inlineEdit: true }).then(res => get(res));
}

export function offerPriceEdit(models) {
	if (models && !Array.isArray(models)) {
		models = [models];
	}
	const ownerInRealty = models[0].collection.ownerInRealty;
	const ids = [];
	models = models.map(mod => {
		ids.push(mod.id);
		return { offer: mod.attributes, object: mod.collection.parent.attributes };
	});
	let editPrice = new EditPriceView({ 
		models, noHeader: true, className: 'offer-price-edit',
		onDone(value) {
			this.trigger('modal:resolve', value);
		}
	});
	// this.listenTo(editPrice, 'done', (price) => {
	// 	//this.takeAction('offer:price', 'установку новой цены', models, price, editView);
	// 	this.takeAction('offer:price', 'установку новой цены', models, price).then(() => {
	// 		editPrice.destroy();
	// 	});
	// });
	return modalsApi.show(editPrice, { header: 'Изменение цены' }).then(res => {
		if (!res.ok) { return; }
		const data = _.reduce(res.value, (memo, priceMeter, offerId) => {
			memo[offerId] = { 'forCustomer.priceMeter': priceMeter }
			return memo;
		}, {});
		console.warn('DATA', data.value);
		return ownerInRealty.massPatchAsync('offers', ids, data, true);
	});
	// modals.modal(editPrice);
}