import { View, CollectionView } from 'core';

const TabButtonView = View.extend({
	tagName: 'button',
	baseClassName: 'tab-item',
	classNames: [
		v => v.model.id,
		v => v.model.get('active') ? 'active' : ''
	],
	attributes() {
		return {
			'data-id': this.model.id
		}
	},
	template: '<i></i>',
	triggers:{
		'click':'click'
	},
	modelEvents: {
		'change:active':'updateClassName'
	}
})

export const SideBarTabs = CollectionView.extend({
	childView: TabButtonView,
	childViewTriggers: {
		'click':'tab:click'
	}
});