﻿//define('behaviors-getFormObject',[],function(){});
import { unFlatObject } from 'utils';
import { Behavior } from 'vendors';
var b = Behavior.extend({
	ui: {
		'getBtn':'[data-role="getFormObject"]'
	},
	triggers: {
		'click @ui.getBtn':'get:form'
	},
	onGetForm: function () {
		var hash = this.$('[name]').serializeModel();

		var opts = this.getOption('parseFields') || {};
		_(opts).each(function (parse, key) {
			if (key in hash) {
				hash[key] = parse(hash[key]);
			}
		});
		console.log(hash);
		var res = unFlatObject(hash);
		this.view.triggerMethod('form:collected', res);
	},
});
export default b;