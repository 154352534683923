import { _, NextCollectionView } from 'vendors';
import { MneView } from './view';

import { PagedCollection } from 'behaviors/pagedCollection';
import { FilteredCollection } from 'behaviors/filteredCollection';
import { ScrollableY } from 'behaviors/scrollableY';
import { OnScrollLoadmorable } from 'behaviors/onScrollLoadmorable';

export const LoadmoreOnScrollCollectionView = MneView.extend({
	className: 'load-more-on-scroll-box',
	behaviors: [PagedCollection, FilteredCollection, ScrollableY, OnScrollLoadmorable],
	template: _.template('<div class="children-region"></div>'),
	regions: {
		'result': 'div',
	},
	// onChildviewRenderChildren: function () {
	// 	this._tryTriggerLoadNext();
	// },
	_tryTriggerLoadNext() {
		console.error('TRYING TO TRIGGER LOAD NEXT');
		//debugger;
		var inner = this.$('> div').height();
		var outer = this.$el.height();
		if (inner < outer && !this.autoFetchDisabled) {
			console.log('trigger \'data:try:load:next\'')
			this.triggerMethod('data:try:load:next');
		}
		this.triggerMethod('after:render:children', this.collection);
	},
	_onRenderChildren() {
		this._tryTriggerLoadNext();
	},
	buildCollectionViewOptions: function () {
		var options = _.extend({}, this.options);
		delete options.className;
		options.childView = this.getOption('childView', false);
		options.emptyView = this.getOption('emptyView', false);
		options.emptyViewOptions = this.getOption('emptyViewOptions', false);
		options.childViewOptions = this.getOption('childViewOptions', false);
		_.extend(options, { className: 'children-list' }, this.getOption('collectionViewOptions'));
		return options;
	},
	onRender: function () {
		//var options = _.extend({}, this.options);
		//if (!options.childView)
		//	options.childView = this.getOption('childView');

		//if (!options.emptyView)
		//	options.emptyView = this.getOption('emptyView');

		//if (this.childViewOptions && !options.childViewOptions)
		//	options.childViewOptions = this.childViewOptions;
		var options = this.buildCollectionViewOptions();
		var extend = this.getOption('collectionViewExtend');

		var BaseCollectionView = this.getOption('CollectionView') || NextCollectionView;

		var CollectionView = extend ? BaseCollectionView.extend(extend) : BaseCollectionView;

		//options.childViewOptions = _.extend({}, options.childViewOptions, { collection: this.getOption('collection') });

		var view = new CollectionView(options);
		this.listenTo(view, 'render:children', this._onRenderChildren);
		//{
		//	collection: this.getOption('collection'),
		//		childView: this.getOption('childView'),
		//			childViewOptions: this.getOption('childViewOptions'),
		//}
		this.showChildView('result', view);

		if (this.getOption('fetchOnShow') == true) {
			this.triggerMethod('filters:apply');
		}

		//console.log(this.$el);
	},
	// onDataLoadNext() {
	// 	console.log('> DATA LOAD NEXT')
	// }
});