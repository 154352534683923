﻿//define('app-router', ['app-config','helpers'], function (hlps, cfg) {});

// import cfg from 'app-config';
// import hlps from 'helpers';

import { isValue } from 'utils/is';

import { history } from 'core/bbe';
import { MnAppRouter } from 'vendors';

const MyRouter = MnAppRouter.extend({
	initialize() {
		this.on('all', e => console.error('#router', e));
	}
});

function getModules() {
	var modules = this.modules || [];
	var modulesArray = _(modules).map(function (m) {
		return m.getAllModules();
	});
	var flattenModules = _.flatten(modulesArray);
	//console.log('routing hash', flattenModules);
	//console.log(_.pluck(flattenModules, 'name'));
	return flattenModules;
}

function populateRoutesHash(modules) {
	var hash = {};

	function addPathHandler(path, handler, hash, module) {
		if (path.indexOf('/') == 0)
			path = path.substring(1);
		if (path in hash) {
			console.warn('dublicate route:', path, 'exists handler:', hash[path], 'dublicate:', handler);
			console.warn('route are not added', hash, module);
			return;
		}
		hash[path] = handler;
	}

	function moduleRunHandler(module) {
		return function () {
			module.run.apply(module, arguments);
		};
	}

	function processModuleRouteData(module, hash, routeData)
	{
		if(!isValue(routeData)) return;

		if (typeof routeData === 'string')
			addPathHandler(routeData, moduleRunHandler(module), hash, module);
		else if (routeData instanceof Array) {
			_(routeData).each(function(data) {
				processModuleRouteData(module, hash, data)
			});
		}
		else if (typeof routeData === 'object')
		{
			_(routeData).each(function(data, route) {
				addPathHandler(route, typeof data === 'function' ? data : moduleRunHandler(module), hash);
			});
		}

	}

	function addModuleRoutes(hash, mod) {

		var routeData = _.result(mod, 'routeData');

		processModuleRouteData(mod, hash, routeData);

		/*
		if (!isValue(routeData)) return;

		if (typeof routeData === 'string')
			addPathHandler(routeData, moduleRunHandler(mod), hash);

		if (typeof routeData === 'object') {
			_(routeData).each(function (data, route) {
				addPathHandler(route, typeof data === 'function' ? data : moduleRunHandler(mod), hash);
			});
		}
		*/
	}

	_(modules).each(function (module, i) {

		addModuleRoutes(hash, module);

	});

	return hash;

}

function createAppRouters(routesHash) {
	//var routers = [];
	var cntrl = {};
	var routes = {};
	_(routesHash).each(function (handler, route) {
		routes[route] = route;
		cntrl[route] = handler;
		//routers.push(router);
	});
		var router = new MyRouter({
			controller: cntrl,
			appRoutes: routes
	});
	return router;
	//return routers;
}

function poppulateRouters() {
	console.warn('populating routers...');
	var modules = getModules.call(this);

	var hash = populateRoutesHash.call(this, modules);
	//console.log('modules', modules, hash);
	var routers = createAppRouters(hash);

	return routers;

}

function ensureRouteExist(path)
{
	return history.isExist(path);
}



var ns = {};
ns.create = function (app) {
	return poppulateRouters.call(app);
}

ns.registerUrlClickHandler = function (app) {

	var selector = 'a[href]:not([href=""]):not([href|="javascript:"]):not([target]):not([data-skiproute="true"])';

	/* explanation
	 * router: router trigger
	 * modal: open in modal
	 * newtab: open in newtab
	 * load: browser navigate (reload)
	 */
	var cfg = {
		urlClick: 'router',
		urlShiftClick: 'modal',
		urlCntrlClick: 'newtab',
		urlAltClick: 'load',
	}

	$(document).on("click", selector, function (e) {
		var $a = $(this);
		//var inModal = false;

		if (e.ctrlKey && cfg.urlCntrlClick == 'newtab') {

			e.stopPropagation();
			return true;
			//e.preventDefault();

		}
		else if (e.altKey && cfg.urlAltClick == 'load') {

			//e.stopPropagation();
			e.preventDefault();
			document.location.href = this.href;
			return;

		}
		else if (
			(e.shiftKey && cfg.urlShiftClick == 'modal')
			||
			($a.data('open') == 'modal')
		) {

			//inModal = true;

		}
		var href = new UUrl(this.href);


		if (!href.isLocal) {
			e.stopPropagation();
			return true;
		}
			
		var routeExists = ensureRouteExist(href.relative());

		if (!routeExists) {
			e.stopPropagation();
			return true;
		}

		var navOptions = { trigger: true };

		e.preventDefault();
		history.navigate(href.relative(), navOptions);

		//console.log('navigated', navOptions);


	});
};

export default ns;
