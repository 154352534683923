module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<span class="select-state"></span><span class="select-label"><span>'+
((__t=(label))==null?'':__t)+
'</span>';
if (description) {
__p+='<span class="select-description">'+
((__t=(description))==null?'':__t)+
'</span>';
}
__p+='</span>';
}
return __p;
};
