import domApi from 'apis/domApi';

export function attachView(view, el, renderIfNot) {

	if (renderIfNot && !view._isRendered && !view._isDestroyed && view.render) {
		view.render();
	}

	view.trigger('before:attach', view);

	if (view.Dom) {
			view.Dom.appendContents(el, view.el);
	} else {
			domApi.appendContents(el, view.el);
	}

	view._isAttached = true;
	view.trigger('attach', view);

}