import { createGroupStore } from './models';

const actionsStore = new Map();

export function getStore(groupKey) {
    // not allowed: undefined, null, ''
    if (groupKey == null || groupKey === '') { throw new Error('getGroupStore: groupKey not defined'); }
    let groupStore = actionsStore.get(groupKey);
    if (!groupStore) {
        groupStore = createGroupStore(groupKey);
        actionsStore.set(groupKey, groupStore);
    }
    return groupStore;
}

// groupKey: any, except '', undefined, null
// action: object | model | Array<object>
export function addAction(groupKey, action) {
    if (!action) { return };
    let groupStore = getStore(groupKey);
    return groupStore.add(action);
}

