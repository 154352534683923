﻿//define('svc-staff-deps-departmentTreeItem', ['bus-svc', 'svc-staff-deps-departmentItem'], function (appRadio, DepartmentItem) {});

import appRadio from 'bus/svc';
import DepartmentItem from './department-item';

import { MneView, MneNextCollectionView } from 'core/mne';

var View = MneView.extend({		
	className: 'tree-item department',
	template: _.template('<div class="item"></div><div class="children"></div>'),
	regions: {
		'item': { el: '.item', replaceElement: true },
		'children': { el: '.children', replaceElement: true },
	},
	onRender: function () {
		this.showItem();
		this.showChildren();
	},
	showItem: function () {
		var view = new DepartmentItem({
			model: this.model,
		});
		this.showChildView('item', view);
	},
	showChildren: function () {

		var level = this.getOption('level') || 0;
		let deps = appRadio.request('departments');

		var view = new MneNextCollectionView({
			className:'department-children',
			model: this.model,
			collection: deps,
			childView: View,
			childViewOptions: function () {
				return {
					level: level + 1,
				};
			},
			viewFilter: function (v) {
				return v.model.get('parentId') == this.model.id;
			},
			attributes: function () {
				return {
					'data-level': level,
				}
			},
		});

		this.showChildView('children', view);
	},
	attributes: function () {
		return {
			'data-level': this.getOption('level') || 0,
		}
	}
});

export default View;