// define('svc/actualization/add-owner', [
// 	'app-config'
// ], function(
// 	cfg
// ) {});


import cfg from 'app-config';

import { BbModel, BbCollection } from 'vendors';
import { MnView, CollectionView } from 'vendors';
import { smartOpen } from '../../../utils';
import { urls } from 'coms/urls';
import modals from '_libs/modals';

const SimilarView = MnView.extend({
	className: 'similar-item',
	template: _.template('<%= name %>')
});

const SimilarsView = CollectionView.extend({
	initialize() {
		let apiUrl = this.getOption('apiUrl');
		this.collection = new BbCollection();
		this.collection.url = function() {
			let url = apiUrl + '/searchByText';
			let kvs = _.map(this.urlArgs, (value, key) => `${key}=${encodeURIComponent(value)}`);
			let query = kvs.join('&');
			query = query ? '?' + query : '';
			return url + query;
		}
	},
	childView: SimilarView,
	onChange(val) {
		this.collection.urlArgs = {
			text: val
		}
		this.collection.fetch();
	}
});

const CreateOwner = MnView.extend({
	template: _.template(`
		<h3>Добавление собственника</h3>
		<div class="padding-v">
			<label>название: </label>
			<input type="text" class="form-control"/>
		</div>
		<div class="actions padding-v">
			<button class="create">Создать</button>
		</div>
		<label>похожие: </label>
		<div class="similars"></div>
	`),
	ui: {
		inp: 'input'
	},
	regions: {
		similars: '.similars'
	},
	onRender() {
		this.apiUrl = cfg.urls.apiV('realties/owners/');
		const similarsView = new SimilarsView({ apiUrl: this.apiUrl });
		this.showChildView('similars', similarsView);
		similarsView.listenTo(this, 'change', (...args) => similarsView.triggerMethod('change', ...args));
	},
	triggers: {
		'input input': 'input',
		'click .create': 'before:create'
	},
	onInput() {
		let val = this.getInputValue();
		if (!val) return;
		this.triggerMethod('change', val);
	},
	getInputValue() {
		let val = this.ui.inp.val();
		if (val && val.length > 2) {
			return val;
		}
	},
	onBeforeCreate() {
		let name = this.getInputValue();
		if (!name) return;
		let model = new BbModel();
		model.url = this.apiUrl;
		model.save({ name }, { wait: true }).then(() => {
			this.triggerMethod('create', model);
			modals.confirm('Перейти на страницу только-что созданного собственника?').then((res) => {
				const url = urls.page('actualization/owners-list/' + model.id +'/edit');
				console.log(url);
				smartOpen(url);
			}, () => {});
		});
	},
	// onCreate(model) {
	// 	this.trigger('edit:done', model);
	// }
});

export default CreateOwner;

