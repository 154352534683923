﻿//define('m-deals-dealVirtualOffer',['app-paths','modals','m-abstract', 'action'],function(paths, modals, abstractNs, action){});


import paths from 'app-paths';
import modals from '_libs/modals';
import abstractNs from 'm/abstract';
import action from '_helpers/action';


var VirtualOffer = abstractNs.ActionableModel.extend({
	initialize: function () {
		this.on('invalid', function (model, errors) {
			modals.notify(errors.join('<br>'));
		});
	},
	getOffer: function () {},
	validate: function (attrs, options) {
		attrs || (attrs = {});
		var errors = [];
		
		if (attrs.square == null || parseInt(attrs.square,10) < 0)
			errors.push('укажите площадь');
		if (attrs.priceMeter == null || parseInt(attrs.priceMeter, 10) < 0)
			errors.push('укажите стоимость метра');
		if (attrs.incomePercent == null || parseInt(attrs.incomePercent, 10) < 0)
			errors.push('укажите % комиссии');
		if (!attrs.name && !attrs.address)
			errors.push('укажите название или адрес');

		return errors.length > 0 ? errors : false;
	},
	urlRoot: function () {
		return paths.api.dealOffers(this.dealId);
	},

	actions: function () {
		return [
			action('openComment', 'прокомментировать', {}, { order: 0 })
			, action('stageUp', 'на следующую стадию', {}, { order: 0, rule: function () { return this.get('stageId') != "income"; } })
			//, action('showOffer', 'показать предложение', {}, { order: 0, rule: function () { return !!this.get('realtyOffer'); } })
			//action('openModal', 'показать', {}, { order: 0 })
			//, action('openSearchPage', 'подобрать предложения', {}, { order: 0 })
			//, action('addVirtualOffer', 'добавить виртуальное предложение', {}, { order: 0 })
			//, action('addEntityTask', 'поставить задачу', {}, { order: 0 })
			//, action('changeStage', 'изменить стадию', {}, { order: 0 })
			//, action('extendTeam', 'расширить команду', {}, { order: 0 })
			//, action('complete', 'завершить', {}, { order: 0 })
			//, action('cancel', 'отказаться', {}, { order: 0 })
			//, action('joinOffersToDeal', 'прикрепить предложения к процессу', {}, { order: 0, hidden: true })
			//, action('openPage', 'перейти на страницу процесса', {}, { order: 0 })
		];
	},



});

VirtualOffer.create = function (arg) {
	var dealId = typeof arg === 'object' ? arg.id : arg;
	var model = new VirtualOffer();
	model.dealId = dealId;
	return model;
}

export default VirtualOffer;

