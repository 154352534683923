//define('assets-backbone-validate', [], function () {});
import { getValueByPath } from 'utils/object-manipulations';
import { isEmpty } from 'utils/is';
import { _ } from 'vendors';

function createError(name, message, options)
{
	return {
		field: name,
		message: message,
		options: options
	};
}

function getValidationHash()
{
	return this.validation || {};
}

function validateField(hash, name, cfg, errors)
{
	var value = getValueByPath(hash, name);

	if (cfg.required && isEmpty(value))
		errors.push(createError(name, cfg.requiredText || 'отсутствует значение', cfg));

}

function validate(_hash ,opts)
{
	var validation = getValidationHash.call(this);
	var hash = _hash || this.toJSON();
	var errors = [];
	var context = this;

	_.each(validation, (cfg, name) => validateField.call(context, hash, name, cfg, errors));

	if (errors.length == 0) return;

	this.trigger('validation:errors', this, errors);

	return errors;
}


export const validateMixin = {
	validate: function () {
		var validation = getValidationHash.call(this);
		if(_.size(validation)==0) return;
		return validate.apply(this, arguments);
	}
};

