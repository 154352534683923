
// import actions from './actions';

import { PrincipalGroupPageLayout } from 'coms/principal-group';

const actions = undefined;

export const Layout = PrincipalGroupPageLayout.extend({
	joinedAsEnabled: true,
	actions,
	actionsIds: ['change:name', 'change:order', 'delete']
});

