import { BaseModel } from './BaseModel';
import { MapCollection, MapModel } from './common';
import { MapPointModel } from './MapPointModel';
import { createMarkerElement, drawDebugMarker } from './marker-stuff';
import { _ } from 'vendors';

export const ClusterModel = BaseModel.extend({
	mergeOptionsKeys: ['mapStateData'],
	markerIdPrefix: 'map-cluster-',
	markerZIndex: 1,
	initialize(attrs, options) {
		this.realtyIds = {};
		this.points = new MapCollection(undefined, { 
			map: this.map, model: MapPointModel, parent: this,
			mapStateData: this.mapStateData,
			mergeKeys: ['mapStateData']
		});
		//this.state('round', 1)
		//this.points.on('all', c => console.log('[pnts]', c));
		this.listenTo(this.points, 'click:point', (event, opts) => {
			opts.cluster = this;
			this.trigger('click:point', event, opts);
			console.log(opts);
		});
		this.setPoints();
		this.on('change:selected', () => {
			// console.warn('- change:selected handler', this.id);
			this.updateMarker();
			// if (this.isShown()) {
			// }
		});
		this.listenTo(this.points, 'update', (cl, options) => {
			const removed = options?.changes?.removed || [];
			for(let model of removed) {
				delete model.cluster
			}
		});
	},
	// _onSelectedChange() {
	// 	const value = this.get('selected') || false;
	// 	this.state('selected', value);
	// 	if (this.info.isPoints) {
	// 		this.points.invoke('selected')
	// 	}
	// },
	bounds() {
		const { minLng, minLat, maxLng, maxLat } = this.attributes;
		return { minLng, minLat, maxLng, maxLat };
	},

	geoBounds() {
		const { minLng: west, minLat: south, maxLng: east, maxLat: north } = this.attributes;
		return { west, south, east, north };
	},

	isInBounds(ll) {
		const [lng, lat] = ll;
		const { minLng, minLat, maxLng, maxLat } = this.attributes;
		return lng >= minLng && lng <= maxLng && lat >= minLat && lat <= maxLat;
	},

	
	hasRealty(id) {
		return id && id in this.realtyIds;
	},

	setPoints(points = [], options = {}) {
		this.joinedInside = false;
		if (!options.leavePoints) {
			this.points.invoke('hideMarker');
		}
		this.points.set(points);
		this.realtyIds = {};
		for(let point of points) {
			point.cluster = this;
			this.realtyIds[point.id] = point;
			if (this.mapStateData.isJoined(point.id)) {
				this.joinedInside = true;
			}
			// if (point.isEntry()) {
			// 	this.entryInside = true;
			// }
		}
		this.info = this._buildInfo();
		this.entryInside = this.hasEntry();
		if (!options.nomarker) {
			this.updateMarker();
		}
	},

	clearPoints(options) {
		this.setPoints(undefined, options);
	},

	toggleRealty(id, selected) {
		const type = this.getClusterType();
		if (!type) { return; }
		if (type > 0) {
			const point = this.points.get(id);
			if (!point) {
				console.warn('point not found in cluster for ', id);
			} else {
				point.toggleRealty(selected);
			}
			return;
		}
		this.state('selected');
		// const el = this.;
		// if (!el) { return; }
		// if (selected) {
		// 	el.classList.add('selected');
		// } else {
		// 	el.classList.remove('selected');
		// }
	},
	
	_buildInfo() {
		const inf = this.points.reduce((memo, model) => {
			memo.buildings++;
			const count = model.offersCount();
			if (count > 0) {
				memo.offers += count;
				memo.buildingsWithOffers++;
			}
			return memo;
		}, { offers: 0, buildings: 0, buildingsWithOffers: 0 });
		const isCluster = inf.buildingsWithOffers > 5;
		const isPoints = !isCluster && inf.offers > 0;
		const type = isPoints ? 1 : isCluster ? -1 : 0;
		return Object.assign(inf, { isCluster, isPoints, type });
		// return isPoints ? 1 : isCluster ? -1 : 0;
	},
	/**
	 *  1: points
	 * -1: cluster
	 *  0: hidden
	 */
	getClusterType() {
		return this.info.type;
		// const { offers, buildingsWithOffers, type } = this.info;
		// const isCluster = buildingsWithOffers > 3;
		// const isPoints = !isCluster && offers > 0;
		// return isPoints ? 1 : isCluster ? -1 : 0;
	},

	hasSelectedForActions() {
		return this.points.where({ selectedForActions: true }).length > 0;
	},
	updateSelectedForActions() {
		const selected = this.hasSelectedForActions();
		this.state('selected-for-actions', selected);
	},

	// getUpdateMarkerOptions() {
	// 	const type = this.getClusterType();
	// 	const contentHtml = type < 0 ? `<big>${this.info.offers}</big><small>${this.info.buildingsWithOffers}</small>` : '';
	// 	const classes = {};
	// 	classes.state = type ? (type > 0 ? 'points' : 'cluster round-content size-40') : 'empty';

	// 	return {
	// 		type,
	// 		classes,
	// 		contentHtml
	// 	}
	// },
	hasEntry() {
		return this.hasRealty(this.mapStateData.entryRealtyId);
	},
	getEntryPoint() {
		return this.points.get(this.mapStateData.entryRealtyId);
	},
	isMarkerVisible() {
		//if (1 === 1) { return true; }
		const type = this.getClusterType();
		const manyClusters = type === -1;
		//return manyClusters || (type === 0 && this.entryInside);
		return true;
		//return false;
	},

	// getBuildMarkerOptions () {
	// 	// const { contentHtml } = opts;
	// 	const { topLeftLat, topLeftLng, width, height } = this.attributes;

	// 	// let style = `width:${width}px;height:${height}px;`;

	// 	let markerOptions = {
	// 		coordinates: [topLeftLng, topLeftLat],
	// 		onClick: () => {
	// 			this.trigger('click:cluster', this);
	// 		}
	// 	}

	// 	return markerOptions;
	// },

	// showMarker(options = {}) {
	// 	BaseModel.prototype.showMarker.apply(this, arguments);
	// 	if (options.type === -1) {
	// 		this.points.invoke('hideMarker');
	// 	}
	// },
	// asideSelect(val) {
	// 	if (this.info.isPoints) {
	// 		this.points.invoke('state', 'selected', val);
	// 	} else if (this.info.isCluster) {
	// 		this.state('selected', val);
	// 	}
	// },
	// onStateHighlighted(_this, val) {
	// 	const type = this.getClusterType();
	// 	if (type > 0) {
	// 		this.points.invoke('state', 'highlighted', val);
	// 	}
	// },
	onMarkerClick: (m, e) => m.info.offers && m.trigger('click:cluster', e, { cluster: m }),
	onMarkerShow() {
		//this.points.invoke('hideMarker');
		const { isCluster, isPoints, type } = this.info;
		let method = isPoints ? 'updateMarker' : 'hideMarker';
		this.points.invoke(method);
		if (!type) {
			const point = this.getEntryPoint();
			point && point.updateMarker();
		}
	},
	onMarkerHide() {
		const type = this.info.type; // this.getClusterType();
		let method = type > 0 ? 'updateMarker' : 'updateMarker' //'hideMarker';

		this.points.invoke(method);
	},
	onMouseOver() {
		this.points.invoke('state', 'hovered', true);
	},
	onMouseLeave() {
		this.points.invoke('state', 'hovered', false);
	},
	// hideMarker(options = {}) {
	// 	BaseModel.prototype.hideMarker.apply(this, arguments);
	// 	if (options.type > 0) {
	// 		this.points.invoke('updateMarker');
	// 	} else {
	// 		this.points.invoke('hideMarker');
	// 	}
	// },
	actualizeMarker(marker) {
		// console.log(this.joinedInside, this.id);
		const bounds = this.bounds();
		const selected = this.mapStateData.isSelectedBoundsIntersects(bounds, true);
		delete marker._justcreated;
		const type = this.getClusterType();
		const stateType = type ? (type > 0 ? 'points' : 'cluster round-content size-40') : 'empty';
		const entry = this.entryInside;
		this.state({ 
			'type': stateType, 
			entry,
			exist: this.joinedInside,
			selected,
			'selected-for-actions': this.hasSelectedForActions()
		});
		// console.log('cluster', this.id, this._state);
	},
	updateMarkerStyle(marker) {
		if (marker.element.style.width) { return; }
		// console.log('marker.element.style.width', marker.element.style.width, marker.element.style)
		const { width, height } = this.attributes;
		marker.element.style = `width:${width}px;height:${height}px;`;
	},
	getCoordinates() {
		const { topLeftLng, topLeftLat } = this.attributes;
		return [topLeftLng, topLeftLat];
	},
	updateMarkerContent(marker) {
		const type = this.getClusterType();
		const contentHtml = type < 0 
			? `<big>${this.info.offers}</big><small>${this.info.buildingsWithOffers}</small>` 
			: '';
		marker.contentEl.innerHTML = contentHtml;
	},

});

const deprecated = {
	updateMarker() {
		const type = this.getClusterType();
		const isCluster = type === -1;
		const isPoints = type === 1;
		if (isCluster || isPoints) {
			this.showMarker(isCluster, isPoints);
		} else {
			this.hideMarker();
			this.points.invoke('hideMarker');
		}
		if (isPoints) {
			this.points.invoke('updateMarker');
		}
	},
	showMarker(isCluster, isPoints) {
		const marker = this._getMarker(true);
		const classes = marker.element.classList;
		const notEmpty = (isCluster || isPoints);
		if (notEmpty) {
			classes.remove('empty');
		} else {
			classes.add('empty');
		}
		let content = '';
		if (isCluster) {
			classes.remove('points');
			classes.add('cluster');
			content = `<big>${this.info.offers}</big><small>${this.info.buildingsWithOffers}</small>`;
		} else if (isPoints) {
			classes.remove('cluster');
			classes.add('points');
		} else {
			classes.remove('cluster');
			classes.remove('points');
		}
		marker.contentEl.innerHTML = content;

		const { centerLng, centerLat, topLeftLat, topLeftLng } = this.attributes;
		marker.update({ coordinates: [topLeftLng, topLeftLat] });
		if (marker.root !== this.map) {
			map.addChild(marker);
		}
		if (isCluster) {
			this.points.invoke('hideMarker');
		}
	},
	hideMarker() {
		const marker = this._getMarker();
		if (marker && marker.root === this.map) {
			this.map.removeChild(marker);
		}
	},
	_getMarker(forceCreate) {
		if (!this.marker && forceCreate) {
			this.marker = this._buildMarker();
		}
		return this.marker;
	},
	getMarkerElement() {
		const m = this._getMarker();
		return m?.element;
	},
	_buildMarker() {
		const { centerLng, centerLat, topLeftLat, topLeftLng, width, height } = this.attributes;
		let elClasses;
		let lat, lng;
		let style;
		// if (this.id % 2) { 
			elClasses = 'round-content size-40';
			lng = topLeftLng;
			lat = topLeftLat;
			style = `width:${width}px;height:${height}px;`;
		// } else {
		// 	elClasses = 'round round-40 cluster';
		// 	lng = centerLng;
		// 	lat = centerLat;
		// }

		const els = createMarkerElement(elClasses, style);
		const coordinates = [lng, lat];

		// const dbgmarker = drawDebugMarker(this.map, coordinates);

		// els.content.innerHTML = this.get('clusterId');
		var marker = new ymaps3.YMapMarker(
			{
				id: _.uniqueId('map-cluster-'),
				source: 'marker-source',
				coordinates,
				zIndex: 1,
				onClick: () => {
					this.trigger('click:cluster', this);
					// console.log('cluster ', this.id, marker);
					// console.log('cluster model', this);
					// console.log('dbg ', dbgmarker);
				}
			},
			els.el
		);
		marker.contentEl = els.content;
		return marker;
	},
}