import { View } from 'core';
import { innerBus } from '../innerBus';

export const ObjectListActionsView = View.extend({
	className: 'items-actions',
	template: _.template(`
		<div></div>
		<div class="right-side actions">
			<button class="action add" data-action="add" title="добавить новое помещение"><i></i></button>
			<button class="action select" data-action="select" title="выделить всё / отменить выделение"><i></i></button>
		</div>
	`),
	events: {
		'click button.action'(e) {
			let $b = $(e.target).closest('button');
			let action = $b.data('action');
			if (!action) return;
			this.triggerAction(action, e);
		},
	},
	triggerAction(action, event) {
		// if (action != 'select') {
		// 	let context = this.getContext();
		// 	innerBus.trigger('multiaction', action, context);
		// } else {
		// 	innerBus.trigger('unselect:all');

		// }

		if (action == 'select') {
			innerBus.trigger('toggle:all');
		}

		if (action == 'add') {
			innerBus.triggerMethod('create:object');
		}
	},
});