// define('svc/actualization/realties-list', [
// 	'appModule', 'link', 'svc/actualization/realties-list/layout'
// 	, 'svc/actualization/realties-list/realty'
// ], function(
// 	Module, link, Layout
// 	, realtyModule
// ) {});

import Module from 'appModule';
import link from 'link';
import Layout from './layout';
import realtyModule from './realty';


var module = Module.content({
	name: 'actualization/realties-list',
	label: 'Справочник зданий',
	routeData: () => link.route('actualizationRealtiesList'),
	urlKey: 'actualizationRealtiesList',
	claims: {
		svcActualizationModule: ['view']
	},
	onRun: function () {
		let view = new Layout();
		this.createAndShow(view, 'Справочник зданий');
	}
});


module.registerModule(realtyModule);

export default module;
