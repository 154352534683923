﻿//define('behaviors-modelActionsInPopup', ['bus'], function (Bus) {});

import Bus from 'bus';

import { BbeCollection } from 'core/bbe';
import { MneView, MneNextCollectionView } from 'core/mne';
import { Behavior } from 'vendors';

var ActionItem = MneView.extend({
	tagName: 'li',
	className: 'list-btn',
	template: _.template('<span><%= _.lbl(_m) %></span>'),
	events: {
		'click': function () { this.model.trigger('action:click', this.model, this.getOption('action'))}
	},
});

var PopupActions = MneNextCollectionView.extend({
	tagName:'ul',
	childView: ActionItem,
	collectionEvents: {
		'action:click': function (model, options) {
			this.model.executeAction({ action: model.get('type'), options: options });
			this.destroy();
		},
	}
});


var b = Behavior.extend({
	initialize: function (opts) {
		this.mergeOptions(opts, ['actionOptions']);
	},
	events: function () {
		var hash = {};

		var key = this.getOption('triggerElement') || '';
		key = 'click ' + key;

		hash[key] = function () {
			this.openPopup();
			//PopupActions.open({ rawActions: this.view.model.getActions(), popupContext: this.view})
		}

		return hash;
	},
	openPopup: function () {
		var model = this.view.model;
		var actions = model && model.getActions && model.getActions();
		console.log('>>>', actions, model);
		if (!actions || actions.length == 0) return;

		var actionOptions = _.result(this, 'actionOptions') || {};
		var opts = {
			model: this.view.model,
			collection: new BbeCollection(this.view.model.getActions()),
			childViewOptions: function (model) {
				return { action: _.extend({}, actionOptions[model.get('type')]) };
			},
		}

		var view = new PopupActions(opts);
		Bus.modals.request('popup', view, { popupContext: this.view, cssClasses: { content: 'block' }, });

	}
});

export default b;