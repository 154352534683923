export const modelDisplaySchemaMixin = {
	getDisplaySchemaKeys() {
		const keys = this.getOption('displaySchemaKeys') || [];
		return keys;
	},
	getDisplayHash(options) {
		if (!this.model || !this.model.display) { return {} }
		const keys = this.getDisplaySchemaKeys() || [];
		return this.model.display(keys, options)
	}
}