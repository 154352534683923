import { _ } from 'vendors';
const scrollInfoKey = Symbol('scroll');
export const scrollToEdgeMixin = {
	initializeScrollToEdgeMixin() {
		if (this._scrollToEdgeMixinInitialized) { return; }
		this._scrollToEdgeMixinInitialized = true;

		this.delegate('scroll', undefined, this._onScrollHandler.bind(this));

	},
	_onScrollHandler(event) {
		const info = this._updateScrollInfo();
		if (!info.edgeTop && !info.edgeLeft) { return; }

		const value = {};
		if (info.edgeTop)
			value.vertical = info.edgeTop;
		if (info.edgeLeft) 
			value.horizontal = info.edgeLeft;	
		console.log('trigger','scroll:edge', value);
		this.triggerMethod('scroll:edge', value);

	},
	_updateScrollInfo() {
		let info = this[scrollInfoKey] || {};
		const { scrollTop : prevScrollTop = 0, scrollLeft : prevScrollLeft = 0, edgeTop : prevEdgeTop, edgeLeft : prevEdgeLeft } = info;
		const { scrollTop, scrollLeft } = this.el;
		const deltaTop = scrollTop - prevScrollTop;
		const growTop = deltaTop > 0 ? true : deltaTop < 0 ? false : undefined;
		let edgeTop = deltaTop < 0 && prevEdgeTop !== 'start' && scrollTop <= 3 ? 'start'
			: deltaTop > 0 && prevEdgeTop !== 'end' && (scrollTop + 3 >= (this.el.scrollHeight - this.el.clientHeight)) ? 'end'
			: undefined;

		const deltaLeft = scrollLeft - prevScrollLeft;
		const growLeft = deltaLeft > 0 ? true : deltaLeft < 0 ? false : undefined;
		let edgeLeft = deltaLeft < 0 && prevEdgeLeft !== 'start' && scrollLeft <= 3 ? 'start'
			: deltaLeft > 0 && prevEdgeLeft !== 'end' && (scrollLeft + 3 >= (this.el.scrollWidth - this.el.clientWidth)) ? 'end'
			: undefined;

		this[scrollInfoKey] = {

			scrollTop,
			deltaTop,			
			growTop,
			edgeTop,

			scrollLeft,
			deltaLeft,
			growLeft,
			edgeLeft,

		}

		return this[scrollInfoKey];
	},
}