﻿//define('m-realties-owners-contactsInRealty', ['bus', 'app-paths'], function (Bus, paths) {});

import Bus from 'bus';
import paths from 'app-paths';

import { BbeModel } from 'core/bbe';

var Model = BbeModel.extend({
	url: function () {
		var ownerId = this.get("ownerId");
		var realtyId = this.get("realtyId");
		if (!ownerId || !realtyId) {
			console.warn('something wrong, owner or realty', ownerId, realtyId)
			return;
		}

		return paths.api.realtiesOwnerContactsInRealty(ownerId, realtyId);
	}
});

Model.create = function (oid, rid) {
	return new Model({ ownerId: oid, realtyId: rid });
};

//console.error('replying', 'realties:get:ownercontactsInRealty:model');
Bus.Models.reply("realties:get:ownercontactsInRealty:model", function (ownerId, realtyId) {
	return Model.create(ownerId, realtyId);
});

export const ContactsInRealtyModel = Model;