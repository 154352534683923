import { MnObject } from "vendors";
import { setValueByPath, getValueByPath } from 'utils/object-manipulations';
import { flatObject } from "../../utils";
import { debounce } from 'vendors';

const valuesForTrigger = {
	on:1,
	off:1,
	true:1,
	false:1
}

export const IdentityConfig = MnObject.extend({

	constructor: function(options) {
		this.value = {};
		this.store = debounce(this._undebouncedStore.bind(this), 50);
		this.identity = options.identity;
		MnObject.apply(this, arguments);
		this.listenTo(this.identity, 'sync', this._onIdentitySync);
	},

	get(key, defaultValue) {
		let val = getValueByPath(this.value, key)
		if (val == null) {
			val = defaultValue;
		}
		return val;
	},

	set(key, value, options = {}) {
		if (key && typeof key === 'object') {
			const flatValues = flatObject(key);
			options = value;
			const setOptions = Object.assign({ changing: true }, options);
			let changes;
			Object.keys(flatValues).forEach(key => {
				const value = flatValues[key];
				const changed = this.set(key, value, setOptions);
				if (!changes && changed) {
					changes = true;
				}
			});
			const { restoring } = options;
			if (!restoring && changes) {
				this.store();
			}
			return;
		}

		const prev = this.get(key);
		if (prev !== value) {

			this._set(key, value);
			this.trigger('change', key, value);

			const normKey = key.replace(/\./gmi, ':');
			this.trigger(normKey + ':change', value);

			if (value in valuesForTrigger) {
				this.trigger(normKey + ':' + value, value);
			}
			const { changing } = options;
			if (!changing) {
				this.store();
			}
			return true;
		}
	},

	_set(key, value) {
		setValueByPath(this.value, key, value);
	},

	_onIdentitySync() {
		this.employeeId = this.identity.getEmployeeId();
		this.restore();
	},


	clear() {
		this.value = {};
		this.trigger('clear');
	},

	_storeKey() { return this.employeeId + ':userConfig'; },

	restore() {
		this.clear();
		if (this.employeeId == null) { return; }
		const value = localStorage.getItem(this._storeKey()) || '{}';
		try {
			let restoredValue = JSON.parse(value) || {};
			if (typeof restoredValue !== 'object') {
				restoredValue = {};
			}
			const setOptions = { restoring: true };
			this.set(restoredValue, setOptions);
			// Object.keys(restoredValue).forEach(key => {
			// 	const value = restoredValue;
			// 	this.set(key, value, setOptions);
			// });
		} catch (exc) {
			console.error('ошибка восстановления пользовательских настроек\r\n', exc);
		}
	},

	_undebouncedStore() {
		if (!this.employeeId) {
			return;
		}
		const key = this._storeKey();
		const text = JSON.stringify(this.value);
		localStorage.setItem(key, text);
	}

});