﻿//define('svc-clients', [
//'appModule', 'link', '_libs/identity', 'svc-clients-layout', 'svc-clients-client', 'v-clients-client'], 
//function(Module, link, identity, Layout, moduleClient) {});

import Module from 'appModule';
import link from 'link';
//import identity from '';
import Layout from './layout';
//import moduleClient from 'v/clients/client';
import moduleClient from './client';

import './clients-search.less';

var module = Module.content({
	name: 'clients',
	label: 'Клиенты',
	routeData: () => link.route('clients'),
	urlKey:'clients',
	claims: {
		svcClientsModule: ['view']
	},
	onRun: function () {

		var layout = Layout.create(this, {urlQuery: this.query});
		this.createAndShow(layout, 'Клиенты');

	}
});


module.registerModule(moduleClient);

export default module;
