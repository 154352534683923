import { Model } from 'core';

export const SideBarModel = Model.extend({
	selectedClassName: 'selected',

	getFetchData(filtersData) {
		let thisData = this._getFetchData();

		if (!this.get('type')) {

		}

		return Object.assign({}, filtersData, thisData);
	},
	_getFetchData() {
		const { type, line, station, cluster, point } = this.attributes;
		if (!type) { return; }

		switch(type) {
			case 'cluster':
				return cluster.geoBounds();
			case 'point':
				return { 'bld.v': this.get('realtyId') }
			case 'station':
				return { 'mls.v': station.id }
			case 'line':
				return { 'ms.v': line.id }
		}

	},

	select(type, options) {
		// const current = this.model.get(this.model.get('type'));
		const { line, station, point, cluster } = options;
		let bounds;
		let realtyId;
		if (type === 'point') {
			realtyId = point.id;
		}
		else if (type === 'cluster') {
			bounds = cluster.bounds();
		}
		this.set({ type, line, station, point, cluster, bounds, realtyId });
	}

});