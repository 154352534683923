import { invokeValue } from './invoke';
import { isModel, isNotFalsyValue } from './is';


function getModel(arg, passedModel) {
    if (passedModel != null) {
        return passedModel ? arg : arg.model;
    }
    if (isModel(arg)) {
        return arg;
    }
    if (isModel(arg.model)) {
        return arg.model;
    } 
}

export function modelValue(arg, name, { method, defaultValue, excludeFalsy = [0], model:_isModel, action } = {})
{
    let model = getModel(arg, _isModel);
	if (!model) { return defaultValue }


    let value;
    if (method) {
        value = invokeValue(model[name], model);
    } else {
        value = invokeValue(model.get(name), model);
    }
    if (!isNotFalsyValue(value, ...excludeFalsy)) {
        value = defaultValue;
    }

    return action ? action(value) : value;
}


export function cssName(name, options) {
    options = _.extend({ model: true }, options);
    return v => modelValue(v.model, name, options);
}

export function cssModelMethod(name) {
    return v => modelValue(v.model, name, { method: true, model: true })
}

export function cssModelValue(name, action) {
    return v => modelValue(v.model, name, { method: false, model: true, action })
}

export function cssModelBooleanMethod(name, ifTrue, ifFalse)
{
    return v => v && v.model && invokeValue(v.model[name], v.model) ? ifTrue : ifFalse
}