//import hlp from '../../../../helpers';
import hlp from '../../../helpers';
import EditValue from 'ui/controls/editValue';
import modals from '_libs/modals';


import { editImage, displayFileSize, simpleEditImage } from './helpers';

import { MneView } from 'core/mne';
import { backendApi } from 'apis/backend';
import { urls } from 'coms/urls';
import refs from 'references';




export default MneView.extend({
    className(){
        let h = {}
        h['media-plate'] = true;
        if (this.model.get('isPrimary')) {
            h.primary = true;
        }
        return Object.keys(h).join(' ');
    },
    template: _.template(`
<div class="order-button">
    <i class="fa fa-check" title="сделать главной"></i>
    <b title="изменить сортировку"><%= order %></b>
    <i class="fa fa-angle-double-left" title="в начало списка"></i>
    <i class="fa fa-angle-left" title="увеличить сортировку на 10"></i>
    <i class="fa fa-angle-right" title="уменьшить сортировку на 10"></i>
    <i class="fa fa-angle-double-right" title="в конец списка"></i>
</div>		
<div class="thumb">
    <% if (_isImg) {%>
        <img src="<%= url %>" />
    <%} else {%>
        <span><i class="fa fa-file"></i></span>
    <% } %>
</div>
<div class="info">
		<div class="download" title="открыть оригинал: <%= name %>"><a href=""><i class="fa fa-download"></i> (<%= size %>) <%= name %></a></div>
    <div><%= words.join(', ') %></div>
</div>
<div class="edit-buttons">
    <i class="fa fa-pencil-square-o" title="редактировать"></i>
    <i class="fa fa-trash-o" title="удалить навсегда"></i>
</div>
`),
        events: {
            'click .fa-check'(event) {
                event.stopPropagation();
                this.model.collection.makePrimary(this.model);
            },
            'click .fa-angle-double-left'(event) {
                event.stopPropagation();
                this.model.collection.makeFirst(this.model);
            },
            'click .fa-angle-double-right'(event) {
                event.stopPropagation();
                this.model.collection.makeLast(this.model);
            },
            'click .fa-angle-left'(event) {
                event.stopPropagation();
                this.model.collection.changeOrder(this.model, 10);
            },
            'click .fa-angle-right'(event) {
                event.stopPropagation();
                this.model.collection.changeOrder(this.model, -10);
            },
            'click b'(event) {
                event.stopPropagation();
                var setup = {
                    valueType: 'number',
                    header: 'Установите номер сортировки',
                    value: this.model.get('order'),
                };
                EditValue.modal.single(setup).then((status, newvalue, oldvalue) => {
                    this.model.set('order', newvalue);
                    this.model.collection.trigger('mass:changes', [this.model]);
                }, () => {})
            },
            async 'click .download a'(event) {
                event.stopPropagation();
								event.preventDefault();
								const webid = this.model.attributes.media.webId;
								const url = urls.api('v4/medias/download/' + webid + '/url');
								const result = await backendApi.post(url);
								const downloadUrl = urls.api('v4/medias/download/' + result.token);
								console.error('result:', result);
                let win = window.open(downloadUrl, '_blank');
                win.focus();
                // let url = hlp.getOriginalImageUrl(this.model.attributes.media.webId);
            },
            'click .fa-pencil-square-o'(event) {
							event.stopPropagation();
							simpleEditImage(this.model);
            },
            'click .fa-trash-o'(event) {
                event.stopPropagation();
                const entityModel = this.getOption('entityModel');
                let model = this.model;
                modals.confirm('подтвердите удаление').then(function () {
									backendApi.delete(entityModel.url() + '/medias/' + model.id, () => {
											model.isNew = () => true,
											model.destroy();
									});
                });
            },
        },
        modelEvents:{
            'change'() {
                // console.log('--- change ---', this.model.get('isPrimary'), this.model.get('order'));
                // console.log(this);
                this.el.className = this.className();
                this.render();
            },
        },
        templateContextExtend() {

						const words = [];
						const pushv = v => v != null && v !== '' ? words.push(v) : undefined;
            let url;
            let a = this.model.attributes;
            //console.log('>->', a);
            let m = a.media;
            let _isImg = m.type == 'image';
            if (_isImg) {
                url = hlp.imgUrl(m.webId, "w196-h147-ls");
            }
            //console.log(this.model.attributes);


						pushv(refs.enum('realtyMediaTypes', a.type));
						pushv(a.groupName);

            return {
							words,
							ext: m.fileExtension || '-',
							url,
							_isImg: m.type == 'image',
							hash: m.webId,
							size: displayFileSize(m.fileSize || 0),
							name: a.name,
							groupName: a.groupName,
							type: a.type
            }
        }
});