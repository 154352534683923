import { Collection } from 'vendors';
import { Model } from './Model';

export const commonCollectionMixin = {
    initialize() {
        this.initCollectionFromItems();
    },
    initCollectionFromItems() {
        let itemsKey = this.getOption('items');
        this.collection = this.model.initCollection(itemsKey);
    }
}

export const childViewOptionsMixin = {
    childViewOptions() {
        return {
            requestModel: this.getOption('requestModel'),
            data: this.getOption('data'),
        }
    },
}