﻿//define('ui-controls-datetime2-dayModel', [], function () {});
import { BbeModel } from 'core/bbe';

var Model = BbeModel.extend({
	getDay: function () {
		return this.get('value').getDay() || 7;
	},
	inMonth: function () {
		return this.inRange(this.collection.firstDay.get('value'), this.collection.lastDay.get('value'));
		//return this.id >= this.collection.firstDay.id && this.id <= this.collection.lastDay.id;
	},
	notInMonth: function () {
		return !this.inMonth();
	},
	inRange: function (left, right) {
		var t = [left, right];
		left = _(t).min();
		right = _(t).max();
		var res = this.dayEqualOrGreater(left) && this.dayEqualOrLess(right);
		return res;
	},
	test: function () {
		return this.collection.test(this);
	},
	sameMonth: function (check) {
		var date = this.get('value');
		return date.getFullYear() == check.getFullYear()
			&& date.getMonth() == check.getMonth();
	},
	dayEqualOrGreater: function (check) {
		var date = this.get('value');
		return this.sameDay(check) || date > check;
	},
	dayEqualOrLess: function (check) {
		var date = this.get('value');
		return this.sameDay(check) || date < check;
	},
	sameDay: function (check) {
		var date = this.get('value');
		return this.sameMonth(check)
			&& date.getDate() == check.getDate()
	},
	isToday: function () {
		var _now = new Date();
		return this.sameDay(_now);
	},
	isSelected: function () {
		return this.test();
	},
	isHoliday: function () {
		return this.getDay() > 5;
	},
});

export default Model;