﻿// define('svc-deals-submodules', [
// 	'svc-deals-deal', 'svc-deals-active', 'svc-deals-closed', 'svc-deals-search', 'svc-deals-create', 'svc-deals-employee',
// ], function () {});

import deal from './deal';
import active from './active';
import closed from './closed';
import search from './search';
import create from './create';
// import create3 from './create3';
import emp from './by-employee'

export default [deal, active, closed, search, create, emp];