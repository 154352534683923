﻿// define('m-abstract', [
// 	'm-abstract-actionable'
// 	, 'm-abstract-cardable'
// 	, 'm-abstract-selectSingle'
// 	, 'm-abstract-fetchableByWebId'
	
// ], function (ActionableModel, CardableModel, SelectSingle, FetchableByWebId) {});

import ActionableModel from './actionable';
import CardableModelMixin from './cardable';
import FetchableByWebIdMixin from './fetchableByWebId';
import SelectSingle from './SelectSingle';

function proxyNestedChanges(parent, child, name) {
	parent.stopListening(child, 'all');
	if (!name) return;
	parent.listenTo(child, 'all', function (rawevent) {
		var event = name + ":" + rawevent;
		var args = [].slice.call(arguments) || [];
		args.unshift(parent);
		args.unshift(event);
		parent.trigger.apply(parent, args);
	});
}

var ns = {};

ns.proxyNestedChanges = proxyNestedChanges;
ns.ActionableModel = ActionableModel;
ns.CardableModel = CardableModelMixin;
ns.SelectSingle = SelectSingle;
ns.FetchableByWebId = FetchableByWebIdMixin;

export default ns;

export {
	ActionableModel,
	CardableModelMixin,
	FetchableByWebIdMixin,
	proxyNestedChanges
}