﻿//define('realties-building-objects', ['bus','link'], function (Bus, link) {});

import Bus from 'bus';
import link from 'link';

import objtemplate from './object.html';
import template from './objects.html';

import { isValue } from 'utils';
import {bbeViewComparator} from 'utils';

import { BbeCollection } from 'core/bbe';

import { MneView, MneNextCollectionView } from 'core/mne';

var Offer = MneView.extend({
	className: 'offer',
	tagName: 'span',
	template: _.template('<span><%=customer.priceMeter%> р.</span><small><%=operation%> <%=agent.income%>%</small>'),
	onRender: function () {

	}
});

var Offers = MneNextCollectionView.extend({
	childView: Offer,
	className:'offers',
	tagName: 'span',
	viewFilter: function (view) {
		return view.model.get('status') == 'active';
	},
});


var ObjectView = MneView.extend({
	className: 'list-btn realtyobject-in-list',
	tagName: 'li',
	//template: '#tmpl-service-realties-building-object',
	template: objtemplate,
	regions: {
		'offers':{el:'.offers',replaceElement:true}
	},
	events: {
		'click': function () {
			console.log('click');
			var url = link.url('realtiesObject:' + this.model.get('id'));
			Bus.app.trigger('navigate', url);
		}
	},
	onRender: function () {
		this.showOffers();
	},
	showOffers: function () {
		var type = this.getOption('type');
		if (type != 'actual') return;

		var col = new BbeCollection(this.model.get('offers') || []);
		var offersView = new Offers({ collection: col });
		this.showChildView('offers', offersView)

	},
	templateContext: function () {
		var _floor = isValue(this.model.get('info').floor) ? this.model.get('info').floor : '&ndash;';
		return {
			_floor: _floor,
		}
	}
});

var ObjectsLayout = MneNextCollectionView.extend({
	tagName: 'ul',
	childView: ObjectView,
	childViewOptions: function () {
		return {
			type: this.getOption('type')
		};
	},
	viewComparator: function (a,b) {
		return bbeViewComparator([
			[b, a, function () { return this.model.get('info').squareOffer || 0 }],
			[b, a, function () { return this.model.get('info').floor || 0 }],
		]);
	},
});

var Layout = MneView.extend({
	//template: '#tmpl-service-realties-building-objects',
	template,
	regions: {
		'actual': { el: '.objects-actual-region', replaceElement: true },
		'off': { el: '.objects-off-region', replaceElement: true },
	},
	onRender: function () {
		var objs = this.getOption('objects');
		var _act = [];
		var _off = [];
		_(objs).each(function (model) {
			var offs = model.offers || [];
			if (offs.length == 0)
				_off.push(model);
			else if (_(offs).some(function (o) { return ['active'].indexOf(o.status) >= 0; })){
				_act.push(model);
			} else {
				_off.push(model);
			}
		});
		var actual = new BbeCollection(_act);
		var off = new BbeCollection(_off);

		var av = new ObjectsLayout({ collection: actual, type:'actual' });
		var ov = new ObjectsLayout({ collection: off, type:'off' });

		this.showChildView('actual', av);
		this.showChildView('off', ov);

	},
});

export default Layout;