import MapInput from '../../input';

import realtiesNs from 'svc/realties/models';
import { BbeCollection } from 'core/bbe';
import { PopupView } from './popup-view';

export const SearchRealtyControl = MapInput.extend({
	initialize: function () {
		this.searchInputed = _.debounce(this._searchInputed, 300).bind(this);
		this.rest = realtiesNs.BuildingsRest.create();
		this.listenTo(this.rest, 'data:arrived', this._onDataArrived);
	},
	className: 'map-control-searchRealty',
	onRender: function () {
		MapInput.prototype.onRender.apply(this, arguments);
		var $inp = this.$('input');
		$inp.off().on('input', this.searchInputed);
		$inp.on('blur', this.destroyPopup.bind(this));
	},
	_searchInputed: function () {
		let text = this.$('input').val();
		this.rest.fetch({ data: { text: text } });
	},
	_onDataArrived: function (col) {
		var pop = this.ensurePopup();
		pop.collection.reset(col.models);
	},
	DEBUG: true,
	destroyPopup() {
		if (this.DEBUG) { return; }
		var pv = this.popupView;
		if (!pv) return;
		var inp = this.$('input');
		setTimeout(function () {
			pv.destroy();
			inp.val('');
		}, 300);
	},
	ensurePopup: function () {
		if (this.popupView && !this.popupView.isDestroyed()) {
			return this.popupView;
		} else {
			let popup = this.popupView = new PopupView({
				collection: new BbeCollection()
			});
			popup.render();
			popup.$el.css('width', (this.$el.outerWidth() - 2) + 'px');
			popup.$el.appendTo(this.$el);
			this.listenTo(popup, 'searched:bld:click', this._onResultClick);
			this.on('destroy', function () {
				popup.destroy();
			});
		}
		return this.popupView;
	},
	inputAttributes: {
		placeholder: 'название бц/ск',
		title: 'перемещает карту по адресу бц/ск'
	},
	getMap() {
		const view = this.getOption('mapView');
		return view.map;
	},
	setMapCenter(center, zoom) {
		const map = this.getMap();
		if (center) {
			map.setCenter(center);
		}
		if (zoom) {
			map.setZoom(zoom);
		}
	},
	_createMarker(coords, animated) {
		var marker = new google.maps.Marker({
			position: coords,
			animation: google.maps.Animation.BOUNCE,
		});
		return marker;
	},
	_showMarker(marker) {
		const map = this.getMap();
		marker.setMap(map);
	},
	_hideMarker(marker) {
		marker.setMap(null);
	},
	showMarker({ coords, animated, destroyAfter} = {}) {
		const marker = this._createMarker(coords, animated);
		this._showMarker(marker);
		if (destroyAfter) {
			setTimeout(() => this._hideMarker(marker), destroyAfter);
		}
	},
	_onResultClick(data) {
		// const view = this.getOption('mapView');
		// view.map.setCenter(data.coords);
		// view.map.setZoom(17);
		this.setMapCenter(data.coords, 17);
		this.showMarker({ coords: data.coords, animated: true, destroyAfter: 3000 });

	},
})

