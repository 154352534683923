import { _, BbView } from "vendors";
import { invokeValue } from "./invoke";
import { isViewClass } from "./is";


export function buildView(arg, buildOptions = {}) {
    let { invokeContext, invokeArgs, defaultViewOptions, viewOptions } = buildOptions;
    let invoked = invokeValue(arg, invokeContext, invokeArgs);


    if (invoked == null || invoked instanceof BbView) return invoked;
    
    let invokedType = typeof invoked;
    

    let ViewClass, baseViewOptions;

    if (invokedType === 'object' && isViewClass(invoked.class)) {
        ViewClass = invoked.class;
        baseViewOptions = _.omit(invoked, 'class');
    } else if (isViewClass(invoked)) {
        ViewClass = invoked;
    } else {
        return;
    }

    let options = Object.assign({}, defaultViewOptions, baseViewOptions, viewOptions);
    
    return new ViewClass(options);

}

export function buildChildView(parentView, arg, viewOptions, defaultViewOptions) {
    return buildView(arg, {
        invokeContext: parentView,
        invokeArgs: parentView,
        viewOptions, 
        defaultViewOptions
    });
}