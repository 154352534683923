import bus from 'bus/app'; //'bus-app'
import app from './app/app'; //'app'

export function busSetup() {

	bus.on({
		'svc:config'(svc) {
			console.warn('svc:config', svc);
		},
		'paths:configured'() {
			console.warn('paths configured');			
		},
		'svcmodules:registered'() {
			console.warn('- svc modules ready');
		},
		'basemodules:registered'() {
			console.warn('- base modules ready');
		}
	});

	bus.once('allmodules:registered', () => {
		console.log('allmodules:registered');
		console.warn('preparation complete. app running...');
		app.registerAwaiting();
		app.run();
	});

}