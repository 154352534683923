// define('svc/actualization/realties-list/realty/edit/properties', [
//     'svc/actualization/realties-list/realty/edit/properties/search-view',
//     'svc/actualization/realties-list/realty/edit/properties/groups-view',
//     'svc/actualization/realties-list/realty/edit/properties/actions-view'
// ], function(
//     SearchView, GroupsView, ActionsView
// ) {});

import SearchView from './search-view';
import GroupsView from './groups-view';
import ActionsView from './actions-view';

import './properties.less';
import { MnView } from 'vendors';

const Layout = MnView.extend({
    className: 'realties-edit-properties',
    template: _.template(`
        <div class="search-container"></div>
        <div class="properties-container"></div>
        <div class="actions-container"></div>
    `),
    regions: {
        search: '.search-container',
        props: '.properties-container',
        actions: '.actions-container'
    },
    onRender() {
        this.showSearch();
        this.showProps();
        this.showActions();
    },
    showSearch() {
        let view = new SearchView();
        this.showChildView('search', view);
    },
    showProps() {
        let options = { model: this.model, schemas: this.collection, filters: this.getOption('filters') };
        let view = new GroupsView(options);
        this.showChildView('props', view);
    },
    showActions() {
        let view = new ActionsView({ model: this.model });
        this.showChildView('actions', view);
    },
});

export default Layout;

