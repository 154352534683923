﻿//define('svc-tasks-submodules', ['svc-tasks-my', 'svc-tasks-awaiting', 'svc-tasks-fromme', 'svc-tasks-create', 'svc-tasks-task'], function () {});

import my from './my';
import awaiting from './awaiting';
import fromme from './fromme';
import create from './create';
import task from './task';

export const submodules = [
	my, 
	awaiting, 
	fromme, 
	create, 
	task
]


// export const submodules = []