//define('ui/models/filters/config', [], function() {});

import { MneObject } from 'core/mne';

function settle(context, key, ifNotAFunction) {
	let value = context.options[key];
	let fn = value;
	if (!_.isFunction(fn)) {
		if (ifNotAFunction) {
			fn = ifNotAFunction
		} else {
			fn = () => value;
		}
	}
	context['_' + key] = fn;
}

const emptyFunc = () => {};

const Config = MneObject.extend({
	initialize() {
		settle(this, 'filters');
		settle(this, 'groups');
		if (_.isFunction(this.options.changeHandler)) {
			let handler = this.options.changeHandler;
			this.changeHandler = (...args) => handler.apply(this, args);
		} else {
			this.changeHandler = emptyFunc;
		}
		settle(this, 'changeHandler', () => {});
	},
	filters() {
		return this._filters.apply(this, arguments);
	},
	groups() {
		return this._groups.apply(this, arguments);
	},
});

export default Config;
