﻿//define('m-contacts', ['bus', 'm-contacts-entityContact', 'm-contacts-contactValue', 'm-contacts-entityContacts', 'm-contacts-contactValuesCollection'], function (Bus, EntityContact, ContactValue, EntityContacts, ContactValueCollection) {});

import EntityContactModel from './entityContact';
import ContactValueModel from './contactValue';
import EntityContacts from './entityContacts';
import ContactValueCollection from './contactValues'

export {
	EntityContactModel,
	ContactValueModel,
	ContactValueCollection,
	EntityContacts
}

var ns = {
	EntityContactModel,
	ContactValueModel,
	ContactValueCollection,
	EntityContacts
};


// ns.EntityContactModel = EntityContact;
// ns.ContactValueModel = ContactValue;
// ns.ContactValueCollection = ContactValueCollection;
// ns.EntityContacts = EntityContacts;

export default ns;