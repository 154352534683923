﻿//define('v-contacts-entityContact-inList', ['bus', 'link', 'm-contacts', 'behaviors', 'references'], function (Bus, link, contactsNs, beh, refs) {});

import Bus from 'bus';
import contactsNs from 'm/contacts';
import beh from 'behaviors';
import refs from 'references';

import template from './inList.html';
import './contact-value-in-list.less';

import { getValueByPath } from 'utils';
import { MneView } from 'core/mne';

export const EntityContactInListView = MneView.extend({
	tagName: 'li',
	className: 'list-btn',
	//template: '#tmpl-v-contacts-entityContact-inList',
	template,
	behaviors: [beh.Datarole.Self, beh.DatarolePopup],
	modelEvents: {
		'change': 'render'
	},
	attributes: function () {
		return {
			'data-role': 'popup',
			'data-type': 'actions',
			'data-id': this.model.get('id'),
		}
	},
	templateContextExtend: function () {
		//console.log('INLIST TMPL');
		var m = this.model.toJSON();

		var pname = getValueByPath(m, 'person.name.first');
		var bottomName = getValueByPath(m, 'person.name.full');

		var name = m.contactName || pname;
		bottomName = m.contactName ? '' : bottomName;
		name || (name = 'неизвестный*');
		name = `<span class="name-text">${name}</span>`;
		//(bottomName && bottomName == pname && (bottomName = ''));
		!bottomName || (bottomName = '<small>' + bottomName + '</small>');

		//var name = m.name ? m.name
		//	: m.contactName ? m.contactName
		//	: pname ? pname
		//	: "неизвестный*";
		var main = this.model.getFirstContact();
		console.log('FRST CNT', main);
		var contactHtml = !main ? '' : main.getHtml({ addClass: 'pull-right' });
		var funcs = m.clientFuncs ? refs.enum('clientContactFunctions', m.clientFuncs)
			: m.ownerFuncs ? refs.enum('realtyOwnerContactFunctions', m.ownerFuncs)
				: 'без функций';
		
		return {
			name: name,
			contact: contactHtml,
			funcs: funcs,
			bottomName: bottomName
		};
	},
});

EntityContactInListView.create = function (options) {
	options || (options = {});
	if (!options.model && !options.modelHash) return;

	if (options.modelHash) {
		var model = new contactsNs.EntityContactModel(options.modelHash);
		options.model = model;
	}
	return new EntityContactInListView(options);
}


Bus.Views.reply('entityContact:inList', () => EntityContactInListView);


export default EntityContactInListView;
