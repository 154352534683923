﻿//define('svc-clients-client-clientInList',['bus','link','behaviors', 'm-clients'],function(Bus, link, beh, clientsNs){});


import Bus from 'bus';
//import link from '';
import beh from 'behaviors';
import clientsNs from 'm/clients';

import template from './clientInList.html';
import './client-in-list.less';

import { MneView } from 'core/mne';

export const ClientInListView = MneView.extend({
	className: 'list-btn client-in-list',
	tagName: 'li',
	//template: '#tmpl-service-clients-client-clientInList',
	template, 

	behaviors: [beh.Datarole.Self, beh.DatarolePopup, beh.DynamicClass, beh.FastactionTrigger],
	modelEvents: {
		'change': 'render'
	},
	//onAction: function (context, modal) {
	//	if (typeof this.model.executeAction === 'function')
	//		this.model.executeAction({ action: context.type, modal: modal, view: this });
	//},
	attributes: function () {
		return {
			'data-role': 'popup',
			'data-type': 'actions',
			'data-id': this.model.get('id'),
		}
	},
	templateContextExtend: function(){
		return {
			selectable: this.getOption('selectable'),
		}
	}
});


ClientInListView.create = function (options) {
	options || (options = {});
	if (!options.model && !options.modelHash) return;

	if (options.modelHash) {
		var model = new clientsNs.ClientModel(options.modelHash);
		options.model = model;
	}
	return new ClientInListView(options);
}

Bus.Views.reply('client:inList', () => ClientInListView);

export default ClientInListView;