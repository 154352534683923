// define('svc/actualization/realties-list/realty/edit/properties/text-control', [
//     'svc/actualization/realties-list/realty/edit/properties/controls'
// ], function(
//     controls
// ) {});


import controls from './controls';

// import { MneView } from 'core/mne';
import { View } from 'core';

const Layout = View.extend({
    baseClassName: 'text-control',
    template: `		
		<div class="info">*: так-же окно можно закрыть нажав <code>Esc</code> или комбинацию <code>Cntrl + Enter</code> <button class="close cross"><i></i></button></div>
		<textarea class="form-control"><%= value %></textarea>
		<button class="close">закрыть</button>
		`,
		stateClassNames:['enlarged'],
    templateContext() {
        return {
            value: this.value
        }
    },

    ...controls.ControlValueMixin,
    ...controls.ControlChangeValueMixin,
    ...controls.InputValueMixin,

    events: {
			...controls.InputValueEventsMixin('textarea'),
			'click textarea'(e) {
				if (!this.state('enlarged')) {
					this.enlargeControl(e.target, e);
				}
			}
    },
    cancelEnlarge(textarea, event) {
			console.warn('... shrinking ...');
			this._removeEscListener();
			this._removeClickoutside();

			// el = this.el;
			// el.title = "";
			// el.style = "";
			// el.blur();
			this.state('enlarged', false);
			this.setElAttribute('style', '');
    },
    enlargeControl(textarea, event) {
			console.warn('... enlarging ...', event);
			this.state('enlarged', true);
			// el = this.el;
			// el.style = "position: fixed; top: 5%; left: 5%; width: 90%; height: 90%";
			// el.title = "Для закрытия окна можно нажать Control + Enter или кликнуть мышкой за пределы этого окна";
			
			this.setElAttribute('style', "position: fixed; top: 0; left: 0; width: 100%; height: 100%");

			this._addEscListener(textarea);
			this._addClickoutsideBlur(textarea);

        //document.addEventListener('keydown', escHandler);

        // let escHandler = function(event) {
        //     if(event.key === "Escape") {
        //         //do something
        //         el.style = "";
        //         document.removeEventListener('keydown', escHandler);
        //         el.blur();
        //     }
        // }
        // document.addEventListener('keydown', escHandler);



    },
    _addEscListener(el) {
			console.log('adding Esc handler');
			this._removeEscListener();
			this._escHandler = (event) => {
					if(event.key === "Escape" || (event.key === "Enter" && event.ctrlKey)) {
							this.cancelEnlarge(el);
					}
			}
			
			document.addEventListener('keydown', this._escHandler);
			console.log('Esc handler added');
    },
    _removeEscListener() {
			console.log('removing exist Esc handler');
			if (this._escHandler) {
				document.removeEventListener('keydown', this._escHandler);
			}
			console.log('Esc handler removed');
    },
    _addClickoutsideBlur(el) {
			console.log('adding click outside handler');
			this._removeClickoutside();
			this._outsideClickHandler = (event) => {
				console.warn('- outside click -', event);
				if (event.target == el) return;
				this.cancelEnlarge(el);
			}
			setTimeout(() => {
				
				document.addEventListener('click', this._outsideClickHandler);
				console.log('click outside handler added');
			}, 200);
        // $('body').on('click', this._outsideClickHandler);
    },
    _removeClickoutside(el) {
			console.log('removing exist click outside handler');
			if (this._outsideClickHandler) {            
					// $('body').off('click', this._outsideClickHandler);
				document.removeEventListener('click', this._outsideClickHandler);
			}
			console.log('exist click outside handler removed');
    }
});

export default Layout;
