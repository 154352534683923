
import { HamburgerView } from '../HamburgerView';
import { isView } from 'utils/is';
import { UiAtomView } from '../UiAtomView';
import { GlyphButtonView, ButtonView } from '../Button';
import { CoreBbView } from '../../../core/coreBbView';
import { invokeValue, isViewClass } from 'utils';

const extras = {
	buttons: {
		left: [
			{
				check: view => view.getOption('optionsButton', true),
				options: textToButton('options:option-vertical')
			},
			{
				check: view => view.getOption('cardButton', true),
				options: textToButton('card:list-alt')
			},
			{
				check: view => view.getOption('removeButtonLeft', true),
				options: textToButton('remove:remove')
			},
			{
				check: view => view.getOption('editButtonLeft', true),
				options: textToButton('edit:edit')
			},
		],
		right: [
			{
				check: view => view.getOption('editButton', true),
				options: textToButton('edit:edit')
			},
			{
				check: view => view.getOption('removeButton', true),
				options: textToButton('remove:remove')
			},
			{
				check: view => view.getOption('deleteButton', true),
				options: textToButton('delete:trash')
			}
		]
	},
	icons: {
		left: undefined,
		right: undefined
	},
	items: {
		left: undefined,
		right: undefined
	}
};

function simpleButton(buttonName) {
	return {
		class: ButtonView,
		thisClassName: buttonName,
		buttonName,
		text: '<i></i>'
	}
}

function textToButton (text) {

	const chunks = text.split(/:/gmi);
	if (chunks.length === 1) {
		chunks.push(chunks[0]);
	}
	let [ buttonName, glyph ] = chunks;

	if (buttonName === 'button') {
		return simpleButton(glyph);
	}

	return {
		class: GlyphButtonView,
		buttonName,
		thisClassName: buttonName,
		glyph,
		// clickEvent: buttonName + ':click'
	};

}


function tryExtendBuildOptions (item, ViewClass, extension) {

	if (
		item && !isView(item) &&
		typeof item === 'object' &&
		'class' in item === false
	) {

		item = Object.assign({ class: ViewClass }, extension, item);

	}
	return item;

}

export const UiMoleculeView = HamburgerView.extend({
	baseClassName: 'ui2-molecule',
	getChildren () {


		return [
			...this.getLeftChildren(),
			...this.getCoreChildren(),
			...this.getRightChildren()
		];

	},
	getCoreChildren () {
		const View = this.getOption('coreChildView')
			|| this.getOption('childView')
			|| UiAtomView
		return this._getAndRemapChildrenItems('content', item => tryExtendBuildOptions(item, View));
	},
	_getAndRemapChildrenItems (key, remaper) {

		let views = this.getOption(key, true);
		if (views == null) {

			return [];

		}
		if (!Array.isArray(views)) {

			views = [views];

		}
		return views.map((raw, index) => {
			if (typeof raw === 'function' && !isViewClass(raw)) {
				raw = raw.call(this, this);
			}
			if (remaper) {
				raw = remaper(raw, index);
			}
			return raw;
		});
		// return remaper ? views.map(remaper) : views;

	},
	getLeftChildren () {

		return this._getSideChildren('left');

	},
	getRightChildren () {

		return this._getSideChildren('right');

	},
	_getSideChildren (side) {

		const icons = this._getSideIcons(side);
		const buttons = this._getSideButtons(side);
		const items = this._getSideItems(side);

		if (side === 'left') {

			return [
				...icons,
				...buttons,
				...items
			];

		} else {

			return [
				...items,
				...buttons,
				...icons
			];

		}

	},
	_getSideIcons (side) {


		const DefaultView = this.getOption(side + 'IconDefaultView', true) 
		|| this.getOption('iconDefaultView', true) 
		|| GlyphIconView;

		const remaper = item => {

			if (item == null) { return; }
			let extension;
			if (typeof item === 'string') {

				extension = { value: item };

			} else if (typeof item !== 'object') {

				return;

			}
			
			return tryExtendBuildOptions(item, DefaultView, extension);

		};

		let icons = this._getAndRemapChildrenItems(side + 'Icons', remaper);
		icons = this._addExtraItems(side, icons, extras.icons[side]);
		return icons;

	},
	_getSideButtons (side) {

		const DefaultView = this.getOption(side + 'ButtonDefaultView', true) 
		|| this.getOption('buttonDefaultView', true) 
		|| GlyphButtonView;


		const remaper = item => {

			// let extension;
			if (typeof item === 'string') {

				item = textToButton(item);
				// { icon: item };

			}
			return tryExtendBuildOptions(item, DefaultView);

		};

		let buttons = this._getAndRemapChildrenItems(side + 'Buttons', remaper);
		buttons = this._addExtraItems(side, buttons, extras.buttons[side]);
		return buttons;

	},
	_getSideItems (side) {
		let remaper = null;
		const DefaultView = this.getOption(side + 'ChildView', true) 
		|| this.getOption('childView', true);

		if (DefaultView) {
			remaper = item => {
				if (item && typeof item === 'object' && !isView(item) && 'class' in item === false) {
					item = {
						class: DefaultView,
						...item,
					}
				}
				return item;
			}
		}

		let items = this._getAndRemapChildrenItems(side + 'Items', remaper);
		items = this._addExtraItems(side, items, extras.items[side]);
		return items;

	},
	_addExtraItems (side, items, extra) {

		if (!Array.isArray(extra)) {

			return items;

		}
		const add = this._buildExtraItemsArray(extra);
		if (!add.length) return items;
		if (side === 'left') {

			items.unshift(...add);

		} else {

			items.push(...add);

		}
		return items;

	},
	_buildExtraItemsArray (itemsArray) {

		return itemsArray.reduce((arr, btn) => {

			const checkResult = btn.check(this);
			if (checkResult) {

				const view = invokeValue(btn.options, this, [this, checkResult]);
				if (view) {

					arr.push(view);

				}

			}
			return arr;

		}, []);

	}

});


const GlyphIconView = CoreBbView.extend({
	tagName: 'span',
	template: '<i class="glyphicon glyphicon-<%=glyph%>"></i>',
	templateContext() {
		return {
			glyph: this.getOption('value', true) || this.getOption('glyph', true)
		}
	}
});