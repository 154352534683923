﻿//define('svc-deals-list-dealInBlock', ['behaviors'], function (beh) {});

import beh from 'behaviors';
import template from './blockItem.html';
import { MneView } from 'core/mne';

var DealView = MneView.extend({
	className: 'deal deal-in-block',
	//template: '#tmpl-service-deals-list-blockItem',
	template,
	behaviors: [beh.DynamicClass, beh.ModelActionsInPopup],
	dynamicClass: function () {
		var res = [];

		var m = this.model;
		var action = m.get('action') || {};
		var when = Date.info(action.when);

		if (when.dateInvalid) {
			res.push('bad');
			res.push('power-100');
		} else {
			var goodness = when.when == Date.PAST ? 'bad' : 'good';
			var past = when.when == Date.PAST;
			var days = when.calendar.days > 9 ? 10 : when.calendar.days;

			var power = (past ? (!days ? 1 : days) : (10 - days)) * 10;
			//when.calendar.days > 9 ? 100 : when.calendar.days * 10;
			//Math.pow(when.absolute.days, 2);
			//console.log(goodness, power, when);
			//if (goodness == 'bad' && power == 0) power = 10;

			res.push(goodness);
			res.push('power-'+power);

		}

		res.push('stage-' + m.get('stageId'));
		res.push('status-' + m.get('status'));


		return res.join(' ');
	},
	modelEvents: {
		'change': function() {
			this.render();
			this.triggerMethod('model:changed');
		}
	},
	templateContextExtend: function () {
		var action = this.model.get('action');
		var _actionHtml;
		if (action && action.toDo) {
			_actionHtml = function () {
				return "<span class='time' title='" + action.when+"'>" + this._tfn(action.when) + "</span><span class='todo'>" + action.toDo + "</span>";
			}
		}
		else {
			_actionHtml = function () { return "&ndash;" };
		}
		return {
			comment: this.model.get('stateText'),
			_actionHtml: _actionHtml,
			name: this.model.get('name') || ('#' + this.model.get('wId') + '[без названия]'),
		}
	}
});


export default DealView;