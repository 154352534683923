import cfg from 'app-config';
import { SchemaCollection } from '../entity-list-common/edit';


const Collection = SchemaCollection.extend({
	url: function () {
		return cfg.urls.apiV('realties/objects/schema');
	},
});

const objectSchemaCollection = new Collection();

export default objectSchemaCollection;
