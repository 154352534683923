import m_tasks from './tasks';
import m_actualization from './actualization';
import m_admin from './admin';
import m_clients from './clients';
import m_leads from './leads';
import m_deals from './deals';
import m_staff from './staff';
import m_realties from './realties';
import m_sites from './sites';

export const submodules = [
     m_tasks,
     m_deals,
     m_realties,
     m_clients,
		 m_sites,
     m_staff,    
     m_leads,
     m_actualization,
     m_admin,
]


