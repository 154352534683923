import { MnView } from 'vendors';
import { invokeValue } from 'utils/invoke';
import { isNotFalsyValue } from 'utils/is';

export const optionsMixin = {

	getOption(key, shouldInvoke) {
		let value = MnView.prototype.getOption.apply(this, arguments);
		if (typeof value !== 'function' || !shouldInvoke) { return value; }
		return invokeValue(value, this, this);
	},
	getOptions(keys, shouldInvoke) {
		return keys.reduce((memo, key) => {
			memo[key] = this.getOption(key, shouldInvoke);
			return memo;
		}, {});
	},
	hasOption(key, options = {}) {
			let strict, notFalsy;
			if (options === true || options === false) {
					strict = options;
					options = {};
			}
			notFalsy = options.notFalsy;


			if (this.options[key] !== undefined) {
					return notFalsy ? isNotFalsyValue(this.options[key], '', 0) : true;
			}

			if (strict) { return false; }

			let value = this[key];
			return notFalsy 
					? isNotFalsyValue(value, '', 0) 
					: value !== undefined;
	},

}