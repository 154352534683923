// import refs from 'references';

export const realtyInterestSchema = {

	market: {
		label: 'рынок',
		valueType: 'enum',
		valueSubType: 'realtyMarkets'
	},

	operation: {
		label: 'операция',
		valueType: 'enum',
		valueSubType: 'realtyOperations'
	},


	squareFrom: { 
		label: 'площадь ОТ',
		valueType: 'number' },
	squareTo: { 
		label: 'площадь ДО',
		valueType: 'number' },

	priceFrom: { 
		label: 'цена ОТ',
		valueType: 'number' },
	priceTo: { 
		label: 'цена ДО',
		valueType: 'number' },
	priceFor: {
		valueType: 'enum',
		valueSubType: 'priceForTypes'
	},


	entry: {
		valueType: 'enum',
		valueSubType: 'leadEntries'
	},

	objectId: { valueType: 'string' },
	objectName: { valueType: 'string' },
	realtyId: { 
		label: 'бц / ск',
		valueType: 'string',
		displayValue(a,b,c) {
			console.log('#realtyId', a, b, c)
			return b.realtyName;
		} 
	},
	realtyName: { valueType: 'string' },
	geoId: { valueType: 'string' },
	geoName: { valueType: 'string' },

	lat: { valueType: 'number' },
	lng: { valueType: 'number' },
	mapRadius: { valueType: 'number' },
	mapZoom: { valueType: 'number' },

	count: { valueType: 'number' },

	comment: { valueType: 'string' },

	json: { valueType: 'object' },


}


export function getClonedRealtyInterestSchema() {
	return Object.keys(realtyInterestSchema).reduce((memo, key) => {
		const value = realtyInterestSchema[key];
		const cloned = value && typeof value === 'object' ? { ...value } : value;
		memo[key] = cloned;
		return memo;
	}, {});
}