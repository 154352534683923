// define('svc/actualization/realties-list/realty/edit/properties/search-view', [
//     'svc/actualization/realties-list/realty/edit/properties/bus'
// ], function(
//     bus
// ) {});

import bus from './bus';
import { MnView } from 'vendors';

const Layout = MnView.extend({
    template: _.template(`
        <input class="form-control"  placeholder="поиск характеристики..."  value="<%- text %>" />
    `),
    events: {
        'input input'(event) {
            bus.setPropertyContains(event.target.value);
        }
    },
    templateContext() {
        return {
            text: bus.getPropertyContainsChange() || ''
        }
    }
})

export default Layout;
