﻿//define('m-logs-log', ['app-paths'],function(paths){});

import paths from 'app-paths';

import { BbeModel } from 'core/bbe';

var Model = BbeModel.extend({
	urlRoot: function () {
		if (this.isNew()) {
			return paths.api.logs;
		} else if (this.collection) {
			return _.result(this.collection, 'url');
		} else {
			return;
		}
	},
});

export default Model;
