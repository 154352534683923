﻿//define('svc-deals-layout', ['bus','behaviors-blockbuttons','_libs/identity', 'bus-svc', 'app-paths', 'link'], function (Bus, BlockbuttonsBehavior, identity, svcBus, paths, link) {});


import Bus from 'bus';
import BlockbuttonsBehavior from 'behaviors/blockbuttons';
import identity from '_libs/identity';
//import svcBus from '';
import paths from 'app-paths';
import link from 'link';

import empTemplate from './employeeItem.html';

import { BbeModel, BbeCollection } from 'core/bbe';

import { MneView, MneNextCollectionView } from 'core/mne';

var Buttons = MneView.extend({
	template: _.noop,
	behaviors: [BlockbuttonsBehavior],
});

var EmpModel = BbeModel.extend({
	parse: function (data) {
		//return data;
		//var statuses = data.byStatus;
		//delete data.byStatus;
		//data.byStatus = {};


		var red = function (cntx, propA, propB, wrap) {
			wrap || (wrap = function (i) { return i.count; });
			if (!cntx || !cntx[propA]) return;
			var hash = {};
			_(cntx[propA]).each(function (item) {
				hash[item[propB]] = wrap(item);
			});
			cntx[propA] = hash;
			return cntx;
			//cntx[propA] = _(cntx[propA]).reduce(function (hash, prop) {
			//	return hash[propB] = _.isFunction(wrap) ? wrap(prop) : prop;
			//}, {});
			//return cntx;
		}
		red(data, 'byStatus', 'status', function (val) { return red(val, 'byStages', 'stage'); })
		//console.log(data);

		return data;
		//_(statuses).reduce(function (hash, model) { hash[model.status] = model }, data.byStatus);
	},
	_status: function (name) {
		var hash = this.get('byStatus') || {};
		if (!name)
			return hash;
		else
			return hash[name] || {};			
	},
	total: function () {
		return this.get('count')
	},
	canceled: function () {
		return this._status('canceled').count || 0;
	},
	completed: function () {
		return this._status('completed').count || 0;
	},
	inProcess: function () {
		return this._status('inProcess').count || 0;
	},
	rating: function () {
		function round(num) {
			return Math.round(num * 100);
		}
		var total = this.total();
		var canceled = this.canceled();
		var completed = this.completed();

		if (total == 0) return 0;
		if (canceled + completed == 0) return -total;
		return round(completed / total);
	}
});

var StatsCollection = BbeCollection.extend({
	model: EmpModel
});

var EmpView = MneView.extend({
	className:'employee',
	//template: '#tmpl-service-deals-employeeItem',
	template: empTemplate,
	ui: {
		'name': '.name',
	},
	events: {
		'click @ui.name': function () {
			Bus.navigate(link.url('dealsEmployee:'+this.model.id));
		}
	}
});

var EmpsColView = MneNextCollectionView.extend({
	className: 'deals-emps-stats-items',
	childView: EmpView
});

var Emps = MneView.extend({
	className:'deals-emps-stats',
	template: _.template('<h3>Статистика по сотрудникам</h3><div></div>'),
	regions: {
		'emps': 'div'
	},
	onRender: function () {
		this.showChildView('emps', new EmpsColView({ collection: this.collection }));
	},
});



var Layout = MneView.extend({
	
	template: _.template('<section class="buttons"></section><section class="employees"></section>'),
	regions: {
		'btns': { el: '.buttons', replaceElement: true },
		'emps': { el: '.employees', replaceElement: true },
	},
	onRender: function () {
		this.showButtons();
		this.showEmployees();
	},
	showButtons: function () {
		var view = new Buttons(this.options);
		this.showChildView('btns', view);
	},
	showEmployees: function () {
		if (!identity.hasEmployees()) return;

		var view = new Emps({
			collection: this._getEmployeesCollection()
		});
		this.showChildView('emps', view);
	},
	_getEmployeesCollection: function () {
		//console.log('???', this.getOption('module').query);
		var query = this.getOption('module').query;
		//var emps = identity.getMyEmployees();
		var col = new StatsCollection(null, { model: EmpModel});
		col.url = paths.api.deals + '/stats/myemployees';
		col.fetch({parse: true, data: query});
		return col; 
	},
});

export default Layout;