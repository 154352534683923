import { scrollToEdgeMixin } from './scrollToEdge';

export const fetchOnScrollMixin = {
	...scrollToEdgeMixin,
	initializeFetchOnScrollMixin() {		
		this.initializeScrollToEdgeMixin();
		if (this._fetchOnScrollMixinInitialized) { return; }
		this._fetchOnScrollMixinInitialized = true;
		this.on('scroll:edge', this._scrollEdgeHandler);
	},
	_scrollEdgeHandler(hash) {
		const direction = this.getOption('fetchScrollDirection', true) || 'vertical';
		const type = hash[direction];
		if (!type) { return; }
		switch(type) {
			case 'start':
				return this.fetchPrev();
			case 'end':
				return this.fetchNext();
		}
	}
}