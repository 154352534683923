import { MnObject } from 'vendors';

export const SearchContext = MnObject.extend({

	constructor: function(options) {

		MnObject.apply(this, arguments);
		this.initializeSelectionHolder();
		
	},


	isJoinedOffer(id) { },

	isJoinedRealty(id) { },

	setJoinedOffers(offers) { },

	isSelectedOffer(id) {
		
	},

	isSelectedRealty(id) { },

	isMapSelectedRealty(id) { },

	setMapSelected(something) { },



	initializeSelectionHolder() {
		if (this.selection) { return; }

		this.selection = this.getOption('selectionHolder', true);
		if (!this.selection) { return; }

	}

});