import { stringValue, displayNum, isModel } from "utils";
import { moment } from 'vendors';
import { invokeValue } from "utils/invoke";
import refs from 'references';
import { modelSchemaApi } from "./modelSchemaApi.js";
import { setValueByPath, getValueByPath } from "utils/object-manipulations.js";
import { wrapAll } from "./displayUtils.js";

export const propertySchemaApi = {

		getModelSchema(model, modelSchema) {
			if (!modelSchema) {
				modelSchema = modelSchemaApi.getModelSchema(model);
			}
			return modelSchema;
		},

		getSchema(propertySchema, modelSchema) {
        if (propertySchema == null || propertySchema === '') {
            return;
        }
        let type = typeof propertySchema;

        if (type === 'object') { return propertySchema; }

        if (typeof propertySchema === 'string') {
            let property = propertySchema;
						propertySchema = undefined;
						if (modelSchema) {
							propertySchema = modelSchemaApi.getPropertySchema(property, modelSchema);
						}
            return propertySchema || { property };            
        }

    },

    getValue(propertySchema, model, modelSchema) {
			// let property;
			// if (typeof propertySchema === 'string') {
			// 	property = propertySchema;
			// }
			modelSchema = this.getModelSchema(model, modelSchema);
			propertySchema = this.getSchema(propertySchema, modelSchema);
			if (!propertySchema) { return; }

      let value;

			if (propertySchema.getValue) {
				value = propertySchema.getValue(model, modelSchema, () => getValueByPath(model, propertySchema.property));
			} else {
				value = getModelValue(model, propertySchema.property);
					//getValueByPath(model, propertySchema.property);
			}

			return value;
    },

		setValue(newvalue, propertySchema, model, modelSchema) {
			propertySchema = this.getSchema(propertySchema, modelSchema);
			if (!propertySchema) { return; }

			if (propertySchema.setValue) {
				propertySchema.setValue(newvalue, model, modelSchema);
			} else {
				setModelValue(model, propertySchema.property, newvalue);
				//setValueByPath(model, propertySchema.property, newvalue);
			}

		},

		hasValue(propertySchema, model, modelSchema) {
        let value = this.getValue(propertySchema, model, modelSchema);
        return value != null && value !== '';
    },

    displayValue(propertySchema, model, modelSchema, options = {}) {
			// let property;
			// if (typeof propertySchema === 'string') {
			// 	property = propertySchema;
			// }
        propertySchema = this.getSchema(propertySchema, modelSchema);
				if (!propertySchema) { return ''; }
        // if (propertySchema.property === "forAgent.contractType") {
					//debugger;
					// }
				let value;
				if ('value' in options) {
					value = options.value;
				} else {
					value = this.getValue(propertySchema, model, modelSchema);
				}
				const displayedValue = displayValue(value, propertySchema, model, modelSchema, options);
				if (propertySchema.wrapDisplayValue) {
					const wrapOptions = Object.assign({}, propertySchema, options);
					return wrapAll(displayedValue, wrapOptions);
				}
				return displayedValue;
    },

    getLabel(propertySchema, model, modelSchema, options) {
        
        propertySchema = this.getSchema(propertySchema, modelSchema);
        if (!propertySchema) { return; }

        let args = [model, modelSchema];
        let label = invokeValue(propertySchema.label, propertySchema, args);
        if (!label) {
            label = invokeValue(propertySchema.name, propertySchema, args);
        }
				if ((label === '' || label == null) && options && options.allowEmpty) {
					return '';
				}
				// console.log('>>>', label || propertySchema.property);
        return label || propertySchema.property;
    },

		getOption(key, propertySchema, model, modelSchema) {
			propertySchema = this.getSchema(propertySchema, modelSchema);
			if (!propertySchema) { return; }
			let args = [model, modelSchema];
			const value = invokeValue(propertySchema[key], propertySchema, args);
			return value;
		},

		getOptions(keys, propertySchema, model, modelSchema) {
			propertySchema = this.getSchema(propertySchema, modelSchema);
			if (!propertySchema) { return; }
			let args = [model, modelSchema];
			const options = keys.reduce((memo, key) => {
				memo[key] = invokeValue(propertySchema[key], propertySchema, args);
				return memo;
			}, {});
			return options;
		},

		getEditHeader(propertySchema, model, modelSchema) {
			
        propertySchema = this.getSchema(propertySchema, modelSchema);
        if (!propertySchema) { return; }
        let header = invokeValue(propertySchema.editHeader, propertySchema, [model, modelSchema]);
        if (!header) {
            header = this.getLabel(propertySchema, model, modelSchema) + ': установите новое значение';
        }
        return header;
    }
}

function setModelValue(model, key, value) {

	if (!isModel(model)) {
		model[key] = value;
		return;
	}

	if (model.flat) {
		model.set(key, value);
	} else {
		setValueByPath(model, key, value);
	}


}

function getModelValue(model, key) {
	if (model == null) {
		console.warn('passed model is not materialized');
		return;
	}
	if (!isModel(model)) {
		return model[key];
	}
	if (model.flat) {
		return model.get(key);
	} else {
		return getValueByPath(model, key);
	}	
}





function displayValue(value, propertySchema, model, modelSchema, options) {

	if (propertySchema.displayValue) {
			return stringValue(propertySchema.displayValue(value, model, modelSchema, options), propertySchema.displayIfEmpty);
	}

	let defaultEmptyValue = propertySchema.displayIfEmpty != null ? propertySchema.displayIfEmpty : '&mdash;';

	const isEmpty = propertySchema.isEmpty 
			? propertySchema.isEmpty(value)
			: (value == null || value === '');

	if (isEmpty) {
			return stringValue(defaultEmptyValue);
	}

	if (propertySchema.valueType === 'enum') {
			const enumType = propertySchema.enumType || propertySchema.valueSubType;
			if (enumType) {
					value = refs.enum(enumType, value);
			}
			return stringValue(value, defaultEmptyValue);
	}

	if (propertySchema.valueType === 'datetime') {
			if (value instanceof Date && isNaN(value.valueOf())) {
					return stringValue(defaultEmptyValue);
			}
			return moment(value).format('DD.MM.YYYY hh:mm');
	}

	if (propertySchema.valueType === 'boolean') {
			let src = propertySchema.sourceValues || { true: 'да', false: 'нет' };
			return stringValue(src[value]);
	}

	if (propertySchema.valueType === 'number') {
			if (propertySchema.valueSubType === 'year') {
				return stringValue(value, defaultEmptyValue);
			}
			let digits = propertySchema.displayDigits != null ? propertySchema.displayDigits : 1;
			return stringValue(displayNum(value, digits));
	}

	return value;
}