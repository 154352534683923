﻿//define('ui-models-selectionHolder', ['bus'], function (Bus) {});


import Bus from 'bus/core';
import { Collection as BbeCollection } from 'core/bbe';
import { MneObject } from 'core/mne';
var radio = Bus.channel('selectForActions');

var Holder = MneObject.extend({
	initialize: function (options) {
		this.mergeOptions(options, ['eventName']);

		this.cidHash = {};
		this.idHash = {};
		this.collection = new BbeCollection();
		this.listenTo(this.collection, 'clear:all', this.clearAll);
		this.listenTo(this.collection, 'update remove', this.changed);

		this.realties = new Map();

		this._onchangeChanges = undefined;

		this._afterChange = _.debounce(() => {

			const selectedRealties = Object.keys(this._onchangeChanges.selected);
			const unselectedRealties = Object.keys(this._onchangeChanges.unselected);
			this._onchangeChanges = undefined;

			this.triggerMethod('after:change', { selectedRealties, unselectedRealties });

		}, 300);

	},
	channelName: radio.channelName,
	radioEvents: function () {
		var event = this.getOption('eventName');
		if (!event) return;
		var res = {};
		res[event] = 'toggle';
		return res;
	},
	updateSelectedRealties(changes = {}) {
		const { added = [], removed = [] } = changes;
		const selected = this._addSelectedRealties(added);
		const unselected = this._removeSelectedRealties(removed);
		return { selected, unselected }
	},
	_addSelectedRealties(models) {
		const changes = {};
		models.forEach(model => {
			const realtyId = model.get('realtyId');

			changes[realtyId] = 1;

			let rlt = this.realties.get(realtyId);
			if (!rlt) {
				rlt = new Set();
				this.realties.set(realtyId, rlt);
			}
			rlt.add(model.id)
		});
		return changes;
	},
	_removeSelectedRealties(models) {
		const changes = {};
		models.forEach(model => {
			const realtyId = model.get('realtyId');
			let rlt = this.realties.get(realtyId);

			// console.log('removed', realtyId, this.realties);

			if (!rlt) { return; }

			rlt.delete(model.id);

			if (!rlt.size) {
				changes[realtyId] = 1;
				this.realties.delete(realtyId);
			}
		});
		return changes;
	},
	isRealtySelected(realtyId) {
		if (!this.realties) { return false; }
		return this.realties.has(realtyId);
	},
	isRealtiesSelected(realties) {
		if (realties == null) {
			throw new Error('realties is null');
		}

		if (typeof realties !== 'object') {
			throw new Error('realties is not object');
		}

		const ids = Object.keys(realties);
		const res = ids.some(id => this.isRealtySelected(id));

		return res;
	},
	changed: function(collection, { changes = {} } = {}) {
		
		if (!this._onchangeChanges) {
			this._onchangeChanges = { selected: {}, unselected: {} };
		}

		const rltChanges = this.updateSelectedRealties(changes);
		Object.assign(this._onchangeChanges.selected, rltChanges.selected);
		Object.assign(this._onchangeChanges.unselected, rltChanges.unselected);
		

		this.triggerMethod('changed', this.collection.length);
		this._afterChange();
		//this.triggerMethod('changed', this.collection.length);
	},
	radioRequests: function () {
		var event = this.getOption('eventName');
		if (!event) return;
		var res = {};
		res[event + ":selected"] = 'isSelected';

		//console.log('request hash', res);
		return res;
	},
	clearAll: function () {
		
		const models = [...this.collection.models];

		models.forEach(model => {
			this.unselect(model);
			this.collection.remove(model);
		});

		// var models = [].concat(this.collection.models);
		this.collection.reset();
		this.cidHash = {};
		this.idHash = {};
		// _(models).invoke('trigger', 'selected:state:changed');
	},
	toggle: function (action, model) {
		if (_.isFunction(this[action]))
			this[action](model);
	},
	select: function (model) {
		console.log('+')
		var id = model.id; //model.getId();
		var cid = model.cid;
		this.cidHash[cid] = model;
		if (id != null) this.idHash[id] = model;
		this.collection.unshift(model);
		model.trigger('selected:state:changed', true);
	},
	unselect: function (model) {
		console.log('-')
		var id = model.id; //model.getId();
		var cid = model.cid;
		delete this.cidHash[cid];
		delete this.idHash[id];
		this.collection.remove(model);
		model.trigger('selected:state:changed');
	},
	isSelected: function (model) {
		var id = model.id; //model.getId();
		var cid = model.cid;
		var res = false;
		if (id == null) {
			res = (cid in this.cidHash);
		} else {
			res = (id in this.idHash);
		}
		//console.log(id, cid, res);
		return res;
	},
	onBeforeDestroy: function () {
		this.cidHash = null;
		this.idHash = null;
		this.collection = null;
	}
});

Holder.radio = radio;


Holder.create = function (eventName) {
	return new Holder({ eventName: eventName });
}

export default Holder;