﻿//define('m-realties-buildings-buildings', ['bus', 'bus-svc', 'm-singleFetch', 'm-realties-buildings-building', 'app-paths', '_libs/identity', 'm-realties-buildings-images', 'geo-reference-model'], function (Bus, appBus, SingleFetch, Model, paths, identity, Images, georefs) {});


import Bus from 'bus';
import appBus from 'bus/app';
import SingleFetch from 'm/abstract/singleFetch';
// import Model from './building';
import BuildingModel from './building';
import paths from 'app-paths';
import identity from '_libs/identity';

// import { BbeCollection } from 'core/bbe';
import { isModel } from 'utils';
import { geoRefs } from '../../../geo/reference/model';
import { authPromise } from 'mods/acc';
import { svcAuthAction } from '../../../../mods/acc';
import { Collection } from 'core';
import { urls } from 'coms/urls';

// import { realties } from 'mods/realties';

// var Collection = BbeCollection.extend({
// 	model: Model,
// });

//var bldAllow = $.Deferred();

//const buildingsAllowed = 

// const BldMixin = _.extend({
// 	_typeLabel: 'справочник недвижимости',
// 	storeKey: 'allbuildings',
// 	storeOptions: {
// 		absolute: Date.MINUTES(5)
// 	},
// 	populateOptions: function () {
// 		//let allowedPromise = Promise.all([identity.getPromise(), paths.readyPromise])
// 		let allowedPromise = authPromise.then(() => {
// 			if (identity.rights.checkClaimValue('svcRealtiesModule', ['view'])) {
// 				return Promise.resolve();
// 			}
// 			return Promise.reject();
// 		}, () => Promise.reject());
// 		return {
// 			promises: allowedPromise
// 			//[identity.ifAuth(), bldAllow]
// 		};
// 	},
// 	url: function () {
// 		return paths.api.realtiesBuildingsAll;
// 	},
// }, SingleFetch);

// var AllCollection = Collection.extend(BldMixin);
// const allbuilds = new AllCollection();
// export const allbuildings = allbuilds;


const BuildingsCollection = Collection.extend({
	model: BuildingModel,
	url() {
		let url = paths.api.realtiesBuildingsAll;
		url = urls.api('v4/realties/realties/all-shorts')
		if (this._lastFetch) {
			url = url + '?modified.from=' + this._lastFetch.toJSON()
		}
		return url;
	},
	refetch() {
		this.once('sync', () => {
			this._lastFetch = new Date();
		});
		return this.fetch({ merge: true, add: true, remove: false });
	}
});

// export const allbuildings = new BuildingsCollection();

// export const allbuildings = realties;


// moved to mods/realties
// export function ensureBuildingsPromise() {
// 	return allbuildings.refetch();
// 	// deprecated
// 	// const promise =  allbuilds.populate().then(() => geoRefs.populate()).then(() => {
// 	// 	allbuilds.invoke('getAddress');
// 	// 	return true;
// 	// });
// 	// promise.catch(exc => console.error(exc));
// 	// return promise;
// }


// svcAuthAction(() => {
// 	if (1 === 1) { return; }

// 	if (identity.rights.checkClaimValue('svcRealtiesModule', ['view'])) {



// 		//console.log('populating realties...', allbuilds);
// 		allbuilds.populate().then(() => {
// 			var geo = geoRefs;
// 			// Bus.app.request('geo:refs');
// 			//console.log('populating geos...', geo);
// 			geo.populate().then(function () {
// 				allbuilds.invoke('getAddress');
// 			});
// 		});

// 	} else {
// 		console.error('not enough rights for realties and geo references');
// 	}
// });

// paths.readyPromise.then(function() {
// });

// allbuilds.populate({ promises: [ identity.ifSvcAuth(), bldAllow ] }).then(function() {
// 	var geo = Bus.app.request('geo:refs');
// 	geo.populate().then(function () {
// 		allbuilds.invoke('getAddress');
// 	});
// });



//console.log(identity)



// Bus.Models.reply('realties:buildings:all', function () {
// 	return allbuilds;
// });

// appBus.reply('buildings', function (id) {

// 	if (arguments.length == 0) {
// 		return allbuilds;
// 	}
// 	else {
// 		const model = allbuilds.get(id);
// 		// console.log('#buildings result: ', model, 'for id:', id, 'total:', allbuilds.length)
// 		return model;
// 	}
// });

// export function getBuildingName(id) {
// 	let bld;
// 	if (isModel(id)) {
// 		bld = id;
// 	} else {
// 		bld = allbuildings.get(id);
// 		// appBus.request('buildings', id);
// 	}
// 	return bld && bld.get('name');
// }

// export function getRealtyAddress(id) {
// 	const bld = allbuildings.get(id);
// 	return bld && bld.get('address');
// }

// appBus.reply('building:name', function (id) {
// 	let bld;
// 	if (isModel(id)) {
// 		bld = id;
// 	} else {
// 		bld = allbuildings.get(id);
// 		// appBus.request('buildings', id);
// 	}
// 	return bld && bld.get('name');
// });

// export function getBuildingCssClass(id) {
// 	if (id == null) return '';
// 	var bld = allbuildings.get(id);
// 	//appBus.request('buildings', id);
// 	return (bld && bld.getCssClass()) || '';
// }

// appBus.reply('building:css:class', function (id) {
// 	//console.log('?', id, bld);
// 	if (id == null) return '';
// 	var bld = allbuildings.get(id);
// 	//appBus.request('buildings', id);
// 	return (bld && bld.getCssClass()) || '';
// });



export default Collection;

