// define('svc/actualization/realties-list/realty/edit', [
// 	'appModule', 'link', 'svc/actualization/realties-list/realty/edit/layout'
// ], function(
// 	Module, link, Layout
// ) {});

import Module from 'appModule';
import link from 'link';
import Layout from './layout';
import { getCommonNavLinks } from '../../../entity-list-common';

var module = Module.content({
	name: 'actualization/objects-list/object/edit',
	label: 'Страница помещения',
	routeData: () => link.route('actualizationRealtyObjectsListObjectEdit'),
    
	//urlKey: 'actualizationRealtiesListRealtyEdit',
	// claims: {
	// 	svcActualizationModule: ['view']
	// },

	onRun: function () {

		let view = new Layout({ objectId: this.queryParams.id });
		this.createAndShow(view, 'Редактирование помещения');
	},
	navLinks() {
		return getCommonNavLinks();
	}
});


//module.registerModule(moduleClient);

export default module;

