import appCfg from 'app-config';
import { HamburgerView } from "coms/ui/HamburgerView";
import { buildTree } from "./normalize-blocks";
import { Model } from './Model';
import { Model as BaseModel } from 'vendors';
import { BlocksTreeView } from "./BlocksTreeView";
import getWs from 'ws';
import backend from './backend';
import bus from './bus';
import { BuildStagesView } from './BuildStagesView';
export const LayoutView = HamburgerView.extend({
    thisClassName: 'presentation-dialog-content',
    _getSocketGroupName() {
        let presentationId = this.model.get('presentationId');
        let socketGroup = 'presentation:building-stages:' + presentationId;
        return socketGroup;
    },
    joinSocketGroup() {
        let socketGroup = this._getSocketGroupName();
        let ws = getWs();
        return ws.joinGroup(socketGroup);
    },
    leaveSocketGroup() {
        let socketGroup = this._getSocketGroupName();
        let ws = getWs();
        return ws.leaveGroup(socketGroup);
    },
    ensureSocket() {
        let ws = getWs();
        return ws.reconnect();
    },
    initialize() {

        this.building = this.model.get('isReady') || this.model.get('isBuilding');
        this.buildingStages = new BaseModel();

        this.listenTo(bus, {
            'render:all':() => {
                delete this.treeModel;
                this.model.data = this.prepareData();
                this.render();
            },
            // 'before:download': () => {
            //     this.building = true;
            //     this.render();
            // },
            // 'download:end': () => this.building = false,
        });

        // let presentationId = this.model.get('presentationId');
        // let socketGroup = 'presentation:building-stages:' + presentationId;
        
        // let ws = getWs();
        // ws.joinGroup(socketGroup);
        
        this.joinSocketGroup();

        this.on('before:destroy', () => {
            // delete bus.requestModel;
            // delete bus.buildingStages;
            // ws.leaveGroup(socketGroup);
            this.leaveSocketGroup();
        });

        if (this.building) {
            this.waitForDownload();
        }
    },
    async _feedbackPromise() {
        if (this.model.get('isReady')) {
            return Promise.resolve();
        }
        let ws = getWs();
				console.log('joining socket group...');
        await this.joinSocketGroup();
				console.log('socket group joined');

        let wsEvent = 'presentation:download';
        let buildingStages = this.buildingStages;
        let stopWs = () => this.stopListening(ws, wsEvent);

				const result = new Promise((resolve, reject) => {
						console.log('setting listener to ws events');
						const timeoutRejector = setTimeout(() => reject('от бэкэнда не поступают ответы'), 60000);

            this.listenTo(ws, wsEvent, (data, socketmessage) => {
							console.log('ws event emited', data);
							clearTimeout(timeoutRejector);
                if (!data) { return; }
                console.log(this.cid, '[socket]', data.message, data);
                buildingStages.set(data);

                if (data.status !== 200) {
                    stopWs();
                    reject(data);
                    buildingStages.trigger('error', this.model);
                    return;
                }

                if (data.message === 'ready') {
                    // const downloadLink = `<a href="${url}" target="_blank">скачать PDF</a>`
                    // buildingStages.set('message', downloadLink)
                    stopWs();
                    resolve();
                } else {
                    bus.trigger('progress', data);
                }
            });            
        });

        try {
            await result;
            return { ok: true }
        } catch(exc) {
            return { ok: false, error: exc }
        }
    },
    async download() {
        let requestModel = this.model;
        let treeModel = this.getTreeModel();
        let blocks = treeModel.toBackendJson();
        requestModel.set('blocks', blocks);
        this.building = true;
        this.render();
				console.log('- creating pdf document -');
        await backend.createDocument(requestModel);
				console.log('- waiting for download -');
        const res = await this.waitForDownload();
				console.log('waiting result: ', res);
    },
    async waitForDownload() {
        
        let ws = getWs();
				console.log('reconnecting ws...');
        await ws.reconnect();
				console.log('ws reconected.');

        this.buildingStages.clear();
        let presentationId = this.model.get('presentationId');

        console.log('getting feedbackPromise');
        const res = await this._feedbackPromise();
				console.log('feedbackPromise obtained');

        if (res.ok) {
					console.log('feedbackPromise is positive');
            let url = appCfg.urls.apiV('realties/presentation/download/' + presentationId);
            const downloadLink = `<a href="${url}" target="_blank">скачать PDF</a>`
            this.buildingStages.set({ 'message': downloadLink, progress: 100 });
    
            let win = window.open(url,'_blank');
            win.focus();        
        } else {
					return res;
					console.log('feedbackPromise error result', res);
				}
    },

    getData() {
        if (this.model.data) {
            return this.model.data;            
        }
        this.model.data = this.prepareData();
        return this.model.data;
    },
    prepareData() {
        let data = this.model.get('data');
        let prepared = {};
        data.forEach(realty => {
            prepared[realty.id] = realty;
            (realty.objects || []).forEach(realtyObject => {
                prepared[realtyObject.id] = realtyObject;
                (realtyObject.offers || []).forEach(offer => {
                    prepared[offer.id] = offer;
                    //offer.realtyObject = realtyObject;
                });
            });
        });
        return prepared;
    },
    prepareTree(data) {
        let blocks = this.model.get('blocks');
        return buildTree(blocks, data);
    },
    childrenViews() {
        let view;
        if (this.building) {
            view = this._getStagesView();
        } else {
            view = this._getTreeView();
        }
        return [view];
    },
    getTreeModel() {
        if (this.treeModel) {
            return this.treeModel;
        }
        let data = this.getData();
        let tree = this.prepareTree(data);
        this.treeModel = new Model(tree);
        return this.treeModel;
    },
    _getTreeView() {
        let data = this.getData();
        // let tree = this.prepareTree(data);
        // let model = new Model(tree);
        let model = this.getTreeModel();
        return {
            class: BlocksTreeView,
            model,
            data,
            requestModel: this.model,
            parentView: this
        }
    },
    _getStagesView() {
        return {
            class: BuildStagesView,
            model: this.buildingStages
        }
    },
    
});