import { AddressModel } from "./AddressModel";
import { RealtiesCollection } from "./RealtiesCollection";
import { Collection, Model } from 'core';
import { BbModel} from 'vendors';
import { urls } from 'coms/urls';
import { getValueByPath } from "../../../utils";

// export const realties = new RealtiesCollection();
const RealtiesCoords = BbModel.extend({
	get(id) {
		//if (id === 'id') { return false; }
		let ll = this.attributes[id];
		if (ll) { return ll; }
		return getRealtyLatLng(id);
	},
	isNew() { return false; },
	async fetch(ids) {
		if (!Array.isArray(ids)) {
			throw new Error('ожидался массив ID')
		}
		
		const url = urls.api('v4/map/realties-coords');
		const call = BbModel.prototype.save.call(this, null, { 
			url,
			attrs: ids, method: 'POST' 
		});
		await call;
		return ids.map(id => this.get(id));
	}
});
export const realtiesCoords = new RealtiesCoords();
realtiesCoords.url = urls.api('v4/map/realties-coords');

export const shortRealties = new Collection();
export const addresses = new Collection([], { model: AddressModel });
const idNames = {};
export function ensureRealtiesPromise() {
	return shortRealties.refetch();
}

export function setRealtyName(id, name) {
	idNames[id] = name;
}

export function getRealtyName(id) {
	let bld = shortRealties.get(id);
	if (bld) {
		return bld.get('name');
	}
	return idNames[id];
}

export function getRealtyAddress(id) {
	const bld = shortRealties.get(id);
	return bld && bld.get('address');
}

export function getRealtyCssClass(id) {
	if (id == null) return '';
	var bld = shortRealties.get(id);
	if (!bld) { return; }
	return 'bld-' + bld.get('cssEnum') || '';
}

export function getRealtyLatLng(id) {
	
	const address = getRealtyAddress(id) || {};
	const { lat, lng } = address;
	if (lat != null) {
		return { lat, lng };
	}
} 

export function getRealty(id) {
	return shortRealties.get(id);
}

export async function getRealtyAsync(id) {
	console.log('getting realty model');
	let model = shortRealties.get(0);
	if (!model)  {
		console.log('model does not exist. fetching...');
		model = await fetchRealtyAsync(id);
		if (model) {
			console.log('model fetched');
			model = shortRealties.add(model);
		}
	}
	return model;
}

async function fetchRealtyAsync(id) {
	const model = new Model({ id });
	model.urlRoot = urls.api('v4/realties/realties/shorts');
	await model.fetch();
	if (Object.keys(model.attributes).length > 1) {
		return { ...model.attributes };
	}
}

export function getOfferRealty(model) {
	let realtyId = getValueByPath(model, 'realtyId');
	if (!realtyId) {
		realtyId = getValueByPath(model, 'obj.realtyId');
	}
	return shortRealties.get(realtyId);
}