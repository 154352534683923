import { displayPhone, displayEmail } from 'utils/contact-value';

export const displayContactValue = {
	other: undefined,
	phone: displayPhone,
	mobilePhone: displayPhone,
	email: displayEmail,
	messenger: undefined,
	site: undefined,
	social: undefined,
}

export const phoneTypes = {
	phone: 1,
	mobilePhone: 1,
}