﻿//define('app-paths',['app-config', 'bus-app'], function(cfg, busApp){});

import cfg from 'app-config';
import busApp from 'bus/app';
import identity from '_libs/identity';
import { authPromise } from 'mods/acc';
import { identityPromise } from '../../mods/acc';

var ns = {};

ns.url = {};
ns.route = {};



ns.url.root = '';
ns.route.root = '';

ns.url.selectService = cfg.paths.app.native ? ns.url.root : ns.url.root; //'#selectsvc';
ns.route.selectService = ns.url.selectService

//ns.url._serviceRoot = function (id) { return cfg.appSvcRootUrl(id); }
//ns.route._serviceRoot = function (id) { return cfg.appSvcRootUrl(id); }





//ns.url.root = '';
//ns.route.root = ns.url.root;

ns.url._serviceRoot = cfg.paths.app.native ? ns.url.root + 's' : '';



ns.url.acc = 'acc';
ns.route.acc = ns.url.acc;
ns.url.accLogin = ns.url.acc + '/login';
ns.route.accLogin = ns.url.accLogin;
ns.url.accLogoff = ns.url.acc + '/logoff';
ns.route.accLogoff = ns.url.accLogoff;
ns.url.accForgotPassword = ns.url.acc + '/forgotPassword';
ns.route.accForgotPassword = ns.url.accForgotPassword;
ns.url.accForgotPasswordRestore = ns.url.accForgotPassword + '/restore';
ns.route.accForgotPasswordRestore = ns.url.accForgotPasswordRestore;

ns.url.accCreateService = ns.url.acc + '/createService';
ns.route.accCreateService = ns.url.accCreateService;










ns.api = {};	
ns.api.identity = cfg.paths.api.identity;

ns.api.oldLogin = '/acc/oldlogin';

busApp.on('svc:selected', (svc) => {
	cfg.service = svc;
	setupCfgFirst();
	setupCfgSecond();
});

1 == 2 && cfg.whenReady.then(() => {
	if (cfg.service) {
		var svcId = cfg.service.idString;
		ns.url.svc = cfg.paths.app.native ? ns.url._serviceRoot + '/' + svcId : '';
		ns.route.svc = ns.url.svc;


		ns.url.realties = ns.url.svc + '/rlt';
		ns.route.realties = ns.url.realties;

		ns.url.realtiesSearch = ns.url.realties + '/search';
		ns.route.realtiesSearch = ns.url.realtiesSearch;
		ns.route.realtiesSearchDeal = ns.url.realtiesSearch + '/:dealWebId';


		ns.url.realtiesSearchMap = ns.url.realties + '/searchmap';
		ns.route.realtiesSearchMap = ns.url.realtiesSearchMap;
		ns.route.realtiesSearchMapDeal = ns.url.realtiesSearchMap + '/:dealWebId';

		ns.url.realtiesObject = ns.url.realties + '/o';
		ns.route.realtiesObject = ns.url.realties + '/o/:id';

		ns.url.realtiesBuilding = ns.url.realties + '/r';
		ns.route.realtiesBuilding = ns.url.realties + '/r/:id';


		ns.url.tasks = ns.url.svc + '/tasks';
		ns.route.tasks = ns.url.tasks;

		ns.url.taskCard = ns.url.svc + '/task';
		ns.route.taskCard = ns.url.taskCard + '/:tid';

		ns.url.tasksCreate = ns.url.tasks + '/create';
		ns.route.tasksCreate = ns.url.tasksCreate;

		ns.url.tasksMy = ns.url.tasks + '/my';
		ns.route.tasksMy = ns.url.tasksMy;
		ns.url.tasksFromme = ns.url.tasks + '/fromme';
		ns.route.tasksFromme = ns.url.tasksFromme;
		ns.url.tasksAwaitingcheck = ns.url.tasks + '/awaiting';
		ns.route.tasksAwaitingcheck = ns.url.tasksAwaitingcheck;


		//ns.url.tasksToday = ns.url.tasks + '/today';
		//ns.url.tasksTomorrow = ns.url.tasks + '/tomorrow';
		//ns.url.tasksNearest = ns.url.tasks + '/nearest';
		////ns.url.tasksList = ns.url.tasks + '/:listType';
		//ns.route.tasksList = ns.url.tasks + '/:listType';


		ns.url.leads = ns.url.svc + '/leads';
		ns.route.leads = ns.url.leads;
		ns.url.leadsStats = ns.url.leads + '/stats';
		ns.route.leadsStats = ns.url.leadsStats;


		ns.url.dealCard = ns.url.svc + '/deal';
		ns.route.dealCard = ns.url.dealCard + '/:tid';
		ns.url.deals = ns.url.svc + '/deals';
		ns.route.deals = ns.url.deals;
		ns.url.dealsCreate = ns.url.deals + '/create';
		ns.route.dealsCreate = ns.url.dealsCreate;

		ns.url.dealsActive = ns.url.deals + '/active';
		ns.route.dealsActive = ns.url.dealsActive;

		ns.url.dealsEmployee = ns.url.deals + '/emp';
		ns.route.dealsEmployee = ns.url.dealsEmployee + '/:id';


		ns.url.dealsClosed = ns.url.deals + '/closed';
		ns.route.dealsClosed = ns.url.dealsClosed;


		ns.url.dealsNear = ns.url.deals + '/near';
		ns.route.dealsNear = ns.url.dealsNear;
		ns.url.dealsPast = ns.url.deals + '/past';
		ns.route.dealsPast = ns.url.dealsPast;
		ns.url.dealsFar = ns.url.deals + '/far';
		ns.route.dealsFar = ns.url.dealsFar;

		ns.url.dealsSearch = ns.url.deals + '/search';
		ns.route.dealsSearch = ns.url.dealsSearch;


		ns.url.clientCard = ns.url.svc + '/client';
		ns.route.clientCard = ns.url.clientCard + '/:cid';

		ns.url.clients = ns.url.svc + '/clients';
		ns.route.clients = ns.url.clients;

		ns.url.clientsSearch = ns.url.clients;
		ns.route.clientsSearch = ns.url.clientsSearch;


		ns.url.staff = ns.url.svc + '/staff';
		ns.route.staff = ns.url.staff;
		ns.url.staffEmployees = ns.url.staff + '/emps';
		ns.route.staffEmployees = ns.url.staffEmployees;

		ns.url.staffEmployeesInvite = ns.url.staffEmployees + '/invite';
		ns.route.staffEmployeesInvite = ns.url.staffEmployeesInvite

		ns.url.staffEmployeesCreate = ns.url.staffEmployees + '/create';
		ns.route.staffEmployeesCreate = ns.url.staffEmployeesCreate


		ns.url.staffDepartments = ns.url.staff + '/deps';
		ns.route.staffDepartments = ns.url.staffDepartments;
		ns.url.staffDepartmentCreate = ns.url.staffDepartments + '/create';
		ns.route.staffDepartmentCreate = ns.url.staffDepartmentCreate;

		ns.url.staffDepartmentCard = ns.url.staffDepartments;
		ns.route.staffDepartmentCard = ns.url.staffDepartmentCard + '/:depId';

	}
	if (cfg.service) {
		
		//var svcId = cfg.service.id;

		ns.ws = {};
		
		ns.api.svc = cfg.apiSvcUrl();


		ns.api.appData = ns.api.svc + '/cfg';
		ns.api.appDataAuth = ns.api.appData + '/auth';

		ns.api.svcIdentity = ns.api.svc;
		ns.api.rgadmin = ns.api.svc + '/rgadmin';

		ns.api.comments = ns.api.svc + '/comments';
		ns.api.logs = ns.api.svc + '/logs';

		ns.api.contactValues = ns.api.svc + '/contactValues';

		ns.api.employees = ns.api.svc + '/employees';
		ns.api.employeeInvite = ns.api.employees + '/invite';
		ns.api.employeeCreate = ns.api.employees + '/create';

		ns.api.departments = ns.api.svc + '/departments';
		ns.api.departmentsAll = ns.api.departments + '/all';
		ns.api.departmentCreate = ns.api.departments + '/create';

		ns.api.deals = ns.api.svc + '/deals';
		ns.api.dealOffers = function (id) { return ns.api.deals + '/'+id+'/offers'; };

		ns.api.leads = ns.api.svc + '/leads';
		ns.api.leadsStats = ns.api.leads + '/stats';


		ns.api.dealsNear = ns.api.deals + '/near';
		ns.api.dealsPast = ns.api.deals + '/past';
		ns.api.dealsFar = ns.api.deals + '/far';
		ns.api.dealsSearch = ns.api.deals + '/search';

		ns.api.tasks = ns.api.svc + '/tasks';
		ns.api.tasksMy = ns.api.tasks + '/my';
		ns.api.tasksFromme = ns.api.tasks + '/fromme';
		ns.api.tasksAwaitingcheck = ns.api.tasks + '/awaitingCheck';
		ns.api.tasksEntity = ns.api.tasks + '/e';
		ns.api.tasksDeal = ns.api.tasksEntity + '/deal';
		ns.api.tasksClient = ns.api.tasksEntity + '/client';
		ns.api.tasksEmployee = ns.api.tasksEntity + '/employee';

		ns.api.contacts = ns.api.svc + '/contacts';
		ns.api.contactContactValues = function (id) { return ns.api.contacts + '/'+id +'/values'; };
		ns.api.contactValues = ns.api.svc + '/contactValues'

		ns.api.clients = ns.api.svc + '/clients';
		ns.api.clientsSearch = ns.api.clients + '/search';

		ns.api.clientContacts = function (id) {
			return ns.api.clients + '/' + id + '/contacts';
		};

		ns.api.map = ns.api.svc + '/map';
		ns.api.mapPoints = ns.api.map + '/points';

		ns.api.realties = ns.api.svc + '/realties';
		ns.api.realtyImages = function (id) { return ns.api.realties + '/' + id + '/images'; }

		ns.api.realtiesOffer = ns.api.realties + '/offers';
		ns.api.realtiesObject = ns.api.realties + '/objects';
		ns.api.realtiesBuilding = ns.api.realties + '/buildings';
		ns.api.realtiesBuildingsAll = ns.api.realtiesBuilding + '/all';
		ns.api.realtiesBuildingSearch = ns.api.realtiesBuilding + '/searchByText';

		ns.api.realtiesOwner = ns.api.realties + '/owners';
		ns.api.realtiesOwnerContacts = function (id) {
			return ns.api.realtiesOwner + '/' + id + '/contacts';
		};
		ns.api.realtiesOwnerContactsInRealty = function (oid, rid) { return ns.api.realtiesOwner + '/' + [oid, 'contactsInRealty', rid].join('/'); }


		ns.api.principals = ns.api.svc + '/principals';
		ns.api.principalEmployees = function (id) { return ns.api.principals + '/' + id + '/employees' };

	}
});


function setupCfgFirst() {
	if (1 == 1 || cfg.service) {
		
		var svcId = cfg.service && cfg.service.idString;

		ns.url.svc = cfg.paths.app.native ? ns.url._serviceRoot + '/' + svcId : '';

		ns.route.svc = ns.url.svc;

		ns.url.notNativeRoot = cfg.paths.app.native ? '#disabled' : '';
		ns.route.notNativeRoot = ns.url.notNativeRoot;

		ns.url.admin = ns.url.svc + '/admin';
		ns.route.admin = ns.url.admin;
		ns.url.adminGroups = ns.url.admin + '/groups';
		ns.route.adminGroups = ns.url.adminGroups;
		ns.url.adminGroupCard = ns.url.adminGroups;
		ns.route.adminGroupCard = ns.url.adminGroupCard + '/:gid';

		ns.url.realties = ns.url.svc + '/rlt';
		ns.route.realties = ns.url.realties;

		ns.url.realtiesSearch = ns.url.realties + '/search';
		ns.route.realtiesSearch = ns.url.realtiesSearch;
		ns.route.realtiesSearchDeal = ns.url.realtiesSearch + '/:dealWebId';


		ns.url.realtiesSearchMap = ns.url.realties + '/searchmap';
		ns.route.realtiesSearchMap = ns.url.realtiesSearchMap;
		ns.route.realtiesSearchMapDeal = ns.url.realtiesSearchMap + '/:dealWebId';

		ns.url.realtiesObject = ns.url.realties + '/o';
		ns.route.realtiesObject = ns.url.realties + '/o/:id';

		ns.url.realtiesBuilding = ns.url.realties + '/r';
		ns.route.realtiesBuilding = ns.url.realties + '/r/:id';


		ns.url.tasks = ns.url.svc + '/tasks';
		ns.route.tasks = ns.url.tasks;

		ns.url.taskCard = ns.url.svc + '/task';
		ns.route.taskCard = ns.url.taskCard + '/:tid';

		ns.url.tasksCreate = ns.url.tasks + '/create';
		ns.route.tasksCreate = ns.url.tasksCreate;

		ns.url.tasksMy = ns.url.tasks + '/my';
		ns.route.tasksMy = ns.url.tasksMy;
		ns.url.tasksFromme = ns.url.tasks + '/fromme';
		ns.route.tasksFromme = ns.url.tasksFromme;
		ns.url.tasksAwaitingcheck = ns.url.tasks + '/awaiting';
		ns.route.tasksAwaitingcheck = ns.url.tasksAwaitingcheck;

		ns.url.actualization = ns.url.svc + '/actualization';
		ns.route.actualization = ns.url.actualization;

		ns.url.actualizationOffers = ns.url.actualization + '/offers';
		ns.route.actualizationOffers = ns.url.actualizationOffers;

		ns.url.actualizationOwners = ns.url.actualization + '/owners';
		ns.route.actualizationOwners = ns.url.actualizationOwners;
		
		ns.url.actualizationRealties = ns.url.actualization + '/realties';
		ns.route.actualizationRealties = ns.url.actualizationRealties;

		ns.url.actualizationOwnerInRealty = ns.url.actualization + '/ownerInRealty';
		ns.route.actualizationOwnerInRealty = ns.url.actualizationOwnerInRealty;

		ns.url.actualizationChanges = ns.url.actualization + '/changes';
		ns.route.actualizationChanges = ns.url.actualizationChanges;

		ns.url.actualizationRealtiesList = ns.url.actualization + '/realties-list';
		ns.route.actualizationRealtiesList = ns.url.actualizationRealtiesList;			

		ns.url.actualizationRealtiesListRealty = ns.url.actualizationRealtiesList;
		ns.route.actualizationRealtiesListRealty = ns.url.actualizationRealtiesList + '/:id';
		ns.route.actualizationRealtiesListRealtyEdit = ns.route.actualizationRealtiesListRealty + '/edit';


		ns.url.actualizationRealtyObjectsList = ns.url.actualization + '/objects-list';
		ns.route.actualizationRealtyObjectsList = ns.url.actualizationRealtyObjectsList;			

		ns.url.actualizationRealtyObjectsListObject = ns.url.actualizationRealtyObjectsList;
		ns.route.actualizationRealtyObjectsListObject = ns.url.actualizationRealtyObjectsList + '/:id';
		ns.route.actualizationRealtyObjectsListObjectEdit = ns.route.actualizationRealtyObjectsListObject + '/edit';


		ns.url.actualizationRealtyOwnersList = ns.url.actualization + '/owners-list';
		ns.route.actualizationRealtyOwnersList = ns.url.actualizationRealtyOwnersList;

		ns.url.actualizationRealtyOwnersListOwner = ns.url.actualizationRealtyOwnersList;
		ns.route.actualizationRealtyOwnersListOwner = ns.url.actualizationRealtyOwnersList + '/:id';
		ns.route.actualizationRealtyOwnersListOwnerEdit = ns.route.actualizationRealtyOwnersListOwner + '/edit';

		
		//ns.url.tasksToday = ns.url.tasks + '/today';
		//ns.url.tasksTomorrow = ns.url.tasks + '/tomorrow';
		//ns.url.tasksNearest = ns.url.tasks + '/nearest';
		////ns.url.tasksList = ns.url.tasks + '/:listType';
		//ns.route.tasksList = ns.url.tasks + '/:listType';


		ns.url.leads = ns.url.svc + '/leads';
		ns.route.leads = ns.url.leads;
		ns.url.leadsStats = ns.url.leads + '/stats';
		ns.route.leadsStats = ns.url.leadsStats;


		ns.url.dealCard = ns.url.svc + '/deal';
		ns.route.dealCard = ns.url.dealCard + '/:tid';
		ns.url.deals = ns.url.svc + '/deals';
		ns.route.deals = ns.url.deals;
		ns.url.dealsCreate = ns.url.deals + '/create';
		ns.route.dealsCreate = ns.url.dealsCreate;

		ns.url.dealsActive = ns.url.deals + '/active';
		ns.route.dealsActive = ns.url.dealsActive;

		ns.url.dealsEmployee = ns.url.deals + '/emp';
		ns.route.dealsEmployee = ns.url.dealsEmployee + '/:id';


		ns.url.dealsClosed = ns.url.deals + '/closed';
		ns.route.dealsClosed = ns.url.dealsClosed;


		ns.url.dealsNear = ns.url.deals + '/near';
		ns.route.dealsNear = ns.url.dealsNear;
		ns.url.dealsPast = ns.url.deals + '/past';
		ns.route.dealsPast = ns.url.dealsPast;
		ns.url.dealsFar = ns.url.deals + '/far';
		ns.route.dealsFar = ns.url.dealsFar;

		ns.url.dealsSearch = ns.url.deals + '/search';
		ns.route.dealsSearch = ns.url.dealsSearch;


		ns.url.clientCard = ns.url.svc + '/client';
		ns.route.clientCard = ns.url.clientCard + '/:cid';

		ns.url.clients = ns.url.svc + '/clients';
		ns.route.clients = ns.url.clients;

		ns.url.clientsSearch = ns.url.clients;
		ns.route.clientsSearch = ns.url.clientsSearch;


		ns.url.staff = ns.url.svc + '/staff';
		ns.route.staff = ns.url.staff;
		ns.url.staffEmployees = ns.url.staff + '/emps';
		ns.route.staffEmployees = ns.url.staffEmployees;

		ns.url.staffEmployeesInvite = ns.url.staffEmployees + '/invite';
		ns.route.staffEmployeesInvite = ns.url.staffEmployeesInvite

		ns.url.staffEmployeesCreate = ns.url.staffEmployees + '/create';
		ns.route.staffEmployeesCreate = ns.url.staffEmployeesCreate


		ns.url.staffDepartments = ns.url.staff + '/deps';
		ns.route.staffDepartments = ns.url.staffDepartments;
		ns.url.staffDepartmentCreate = ns.url.staffDepartments + '/create';
		ns.route.staffDepartmentCreate = ns.url.staffDepartmentCreate;

		ns.url.staffDepartmentCard = ns.url.staffDepartments;
		ns.route.staffDepartmentCard = ns.url.staffDepartmentCard + '/:depId';

	}
}
function setupCfgSecond() {

	if (1 == 1 || cfg.service) {
		
		//var svcId = cfg.service.id;

		ns.ws = {};
		
		ns.api.svc = cfg.apiSvcUrl();
		console.log('----------------')
		console.log(ns.api.svc);
		console.log('----------------')
		
		ns.api.appData = ns.api.svc + '/cfg';
		ns.api.appDataAuth = ns.api.appData + '/auth';

		ns.api.svcIdentity = ns.api.svc;
		ns.api.rgadmin = ns.api.svc + '/rgadmin';

		ns.api.comments = ns.api.svc + '/comments';
		ns.api.logs = ns.api.svc + '/logs';

		ns.api.contactValues = ns.api.svc + '/contactValues';

		ns.api.employees = ns.api.svc + '/employees';
		ns.api.employeeInvite = ns.api.employees + '/invite';
		ns.api.employeeCreate = ns.api.employees + '/create';

		ns.api.departments = ns.api.svc + '/departments';
		ns.api.departmentsAll = ns.api.departments + '/all';
		ns.api.departmentCreate = ns.api.departments + '/create';

		ns.api.deals = ns.api.svc + '/deals';
		ns.api.dealOffers = function (id) { return ns.api.deals + '/'+id+'/offers'; };

		ns.api.leads = ns.api.svc + '/leads';
		ns.api.leadsStats = ns.api.leads + '/stats';


		ns.api.dealsNear = ns.api.deals + '/near';
		ns.api.dealsPast = ns.api.deals + '/past';
		ns.api.dealsFar = ns.api.deals + '/far';
		ns.api.dealsSearch = ns.api.deals + '/search';

		ns.api.tasks = ns.api.svc + '/tasks';
		ns.api.tasksMy = ns.api.tasks + '/my';
		ns.api.tasksFromme = ns.api.tasks + '/fromme';
		ns.api.tasksAwaitingcheck = ns.api.tasks + '/awaitingCheck';
		ns.api.tasksEntity = ns.api.tasks + '/e';
		ns.api.tasksDeal = ns.api.tasksEntity + '/deal';
		ns.api.tasksClient = ns.api.tasksEntity + '/client';
		ns.api.tasksEmployee = ns.api.tasksEntity + '/employee';

		ns.api.contacts = ns.api.svc + '/contacts';
		ns.api.contactContactValues = function (id) { return ns.api.contacts + '/'+id +'/values'; };
		ns.api.contactValues = ns.api.svc + '/contactValues'

		ns.api.clients = ns.api.svc + '/clients';
		ns.api.clientsSearch = ns.api.clients + '/search';

		ns.api.clientContacts = function (id) {
			return ns.api.clients + '/' + id + '/contacts';
		};

		ns.api.map = ns.api.svc + '/map';
		ns.api.mapPoints = ns.api.map + '/points';

		ns.api.realties = ns.api.svc + '/realties';
		ns.api.realtyImages = function (id) { return ns.api.realties + '/' + id + '/images'; }

		ns.api.realtiesOffer = ns.api.realties + '/offers';
		ns.api.realtiesObject = ns.api.realties + '/objects';
		ns.api.realtiesBuilding = ns.api.realties + '/buildings';
		ns.api.realtiesBuildingsAll = ns.api.realtiesBuilding + '/all';
		ns.api.realtiesBuildingSearch = ns.api.realtiesBuilding + '/searchByText';

		ns.api.realtiesOwner = ns.api.realties + '/owners';
		ns.api.realtiesOwnerSearch = ns.api.realtiesOwner + '/searchByText';
		ns.api.realtiesOwnerContacts = function (id) {
			return ns.api.realtiesOwner + '/' + id + '/contacts';
		};
		ns.api.realtiesOwnerContactsInRealty = function (oid, rid) { return ns.api.realtiesOwner + '/' + [oid, 'contactsInRealty', rid].join('/'); }


		ns.api.principals = ns.api.svc + '/principals';
		ns.api.principalEmployees = function (id) { return ns.api.principals + '/' + id + '/employees' };

		ns.api.adminGroups = ns.api.svc + '/groups';

	}		
}

ns.readyPromise = new Promise((resolve, reject) => {
	// busApp.on('svc:config', svc => {
	// 	cfg.service = svc;
	// 	setupCfgFirst();
	// 	setupCfgSecond();
	// 	resolve();
	// 	busApp.trigger('paths:configured');
	// });
	identityPromise.then(() => {
		cfg.service = identity.getSvc();
		setupCfgFirst();
		setupCfgSecond();
		resolve();
	})
});

//console.log('===', ns);
export default ns;
