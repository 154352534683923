import { destroyView as originalDestroyView } from 'backbone.marionette';

const destroyView = originalDestroyView || function(view, disableDetachEvents) {
    if (view.destroy) {
        // Attach flag for public destroy function internal check
        view._disableDetachEvents = disableDetachEvents;
        view.destroy();
        return;
    }

    // Destroy for non-Marionette Views
    if (!view.supportsDestroyLifecycle) {
        view.triggerMethod('before:destroy', view);
    }

    const shouldTriggerDetach = view._isAttached && !disableDetachEvents;

    if (shouldTriggerDetach) {
        view.triggerMethod('before:detach', view);
    }

    view.remove();

    if (shouldTriggerDetach) {
        view._isAttached = false;
        view.triggerMethod('detach', view);
    }

    view._isDestroyed = true;
        
    if (!view.supportsDestroyLifecycle) {
        view.triggerMethod('destroy', view);
    }
}

export {
    destroyView
}