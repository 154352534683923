import { BaseObject } from 'core';
import { imageModelComparator } from 'coms/ImageGalery';
import { BbeCollection } from 'core/bbe';
import cfg from 'app-config';


// const Collection = BbeCollection.extend({
// 	initialize: function (data, opts) {
// 		this.setListType(opts.listType);
// 		this.setParentId(opts.parentId);
// 	},
// 	setListType(id) {
// 		this.listType = id;
// 	},
// 	setParentId(id) {
// 		this.parentId = id;
// 	},
// 	url: function () {
// 		if (!this.parentId) return;
// 		let url = cfg.urls.apiV(`realties/realties/${this.parentId}/images`);
// 		return url;
// 		//return paths.api.realtyImages(this.parentId);
// 	},
// });

export const BaseImageStore = BaseObject.extend({

	constructor: function(options) {
		this.store = {};
		BaseObject.apply(this, arguments);
		this.mergeOptions(options, ['entityType', 'buildCollection']);
	},

	Collection: BbeCollection,

	comparator: imageModelComparator,

	buildCollection(entityId) {
		const col = new this.Collection();
		const listType = this.getOption('listType');
		col.listType = listType;
		col.parentId = entityId;
		col.url = () => cfg.urls.apiV(`realties/${listType}/${entityId}/images`);
		col.once('sync', () => {
			col.models.sort(this.comparator);
		});
		return col;
	},

	getImages: function (id) {
		if (!id) return;
		var col = this.store[id];
		if (!col) {
			col = this.buildCollection(id);
				// new this.Collection([], { parentId: id });
			this.store[id] = col;
			this._fetchCollection(col);
		}
		return col;
	},

	_fetchCollection(col) {
		if (col.loudFetch) {
			return col.loudFetch();
		} else {
			return col.fetch();
		}
	}
});