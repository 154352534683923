﻿//define('m-realties-offers-offers', ['app-paths','m-realties-offers-offer'], function (paths, Model) {});

import paths from 'app-paths';
import Model from './offer';
import appCfg from 'app-config';

import { BbeCollection } from 'core/bbe';

var Collection = BbeCollection.extend({
	model: Model,
	url: function () {
		return paths.api.realtiesOffer;
	}
});

export const OffersCollectionV4 = BbeCollection.extend({
	model: Model,
	url() {
		return appCfg.urls.apiV('realties/offers');
	},
	fetch() {
		//debugger;
		return Promise.resolve();
	}
});


export default Collection;