﻿//define('behaviors-datarolePopup', ['ui-layouts-popupActions'], function (PopupActions) {});


import PopupActions from 'ui/layouts/popupActions';
import { Behavior } from 'vendors';
var b = Behavior.extend({
	onPopup: function (context) {
		var res = _.result(this.view.model, 'getActions');
		if (!res) {
			console.warn('model has no getActions method')
			return;
		}
		if (res.length == 0) return;

		PopupActions.open({ rawActions: res, popupContext: context, triggerMethodOn: this.view, model: this.view.model });


	}
});

export default b;
