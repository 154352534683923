import { _, moment } from 'vendors';
import StringControl from './string-control';

const DatetimeControl = StringControl.extend({
	className: 'datetime-control',
	template: _.template(`
			<input value="<%= value %>" type="datetime-local" class="form-control" />
	`),
	transformIncomingValue(value) {
		if (!value) { return ''; }
		const dateValue = new Date(value);
		if (isNaN(dateValue.valueOf())) {
			return '';
		}
		const result = moment(dateValue).format('YYYY-MM-DDTHH:mm:ss');
		console.warn(']]] => compare date: ', value, result);
		console.error(moment);
		return result;
	},
	onRender() { console.error(' == date control =>', this)}
});

export default DatetimeControl;