﻿//define('svc-deals-employee', ['bus','appModule', 'link', 'm-deals', 'svc-deals-list-layout2'], function (Bus, Module, link, dealsNs, Layout) {});


import Bus from 'bus';
import Module from 'appModule';
import link from 'link';
//import dealsNs from '';
import Layout from '../list/layout2';

var module = Module.content({
	name: 'deals-by-employee',
	label: 'процессы сотрудника',
	//urlKey: 'dealsActive',
	routeData: function () {
		return link.route('dealsEmployee');
	},
	onBeforeRun: function (id) {

		var Col = Bus.Models.request('dealsEmployee', id);
		var col = new Col();
		
		this.addRunPromise(col.loudFetch());
		this.addRunArgument(col);

	},
	onRun: function (col) {

		this.createAndShow(Layout, this.label, { collection: col, dealsType: 'active', itemFilter: function (model) { return model.isNotCompleted(); }, });

	}
});


export default module;
