// define('svc/actualization/ownerInRealty/layout',
// [
// 	'app-config',
// 	'behaviors',
// 	'modals',
// 	'references',
// 	'svc/actualization/helpers',
// 	'ui-controls-editValue',
// 	'svc/actualization/ownerInRealty/model',
// 	'svc/actualization/ownerInRealty/objects'
// ],
// function(
// 	cfg,
// 	beh,
// 	modals,
// 	refs,
// 	hlp,
// 	EditValue,
// 	Model,
// 	ObjectView
// ){});


import cfg from 'app-config';
import beh from 'behaviors';
import modals from '_libs/modals';
import refs from 'references';
import hlp from '../helpers';
import EditValue from 'ui/controls/editValue';
import Model from './model';
import { ObjectsLayout } from './objects';

import { bbeComparator } from 'utils';

import { history } from 'vendors';

import { BbeModel, BbeCollection } from 'core/bbe';

import { MneView, MneNextCollectionView } from 'core/mne';
import { innerBus } from './innerBus';
import { HamburgerView } from '../../../../coms/ui/HamburgerView';
import { OwnerInRealtyView } from './OwnerInRealtyView';

import NewLayout from './new-layout';

function phoneToString(value)
{
	if (!value) return value;
	return value.split('.').join(' ');
}

function contactValueHtml(item)
{
	switch (item.type) {
		default: return item.value;
		case 'phone':
		case 'mobilePhone':
			return phoneToString(item.value);
	}
}


function normalizeTagValue(value){
	if (value == null || value === '') {
		return;
	}
	value = value.trim().toLowerCase();
	value = value.replace(/[^a-zа-яё0-9]/gmi, '');
	return value || undefined;
}


var PopoverView = MneView.extend({
	className: 'popover-contacts padding-vh',
	template: _.template('<%= personNameFull %>'),
	templateContext() {
		//console.log('popov', this.model.toJSON())
		return {
			personNameFull: this.model.getValueByPath('person.name.full')
		}
	}
});

var PopoverBehavior = beh.BootstrapPopover.extend({
	content: PopoverView,
	contentOptions: function () {
		return {
			model: this.view.model
		}
	},
	popoverTemplateContext: function () {
		return { popoverClass: 'popover-flat popover-offer-details bg-' + this.view.model.get('operation') };
	},
	placement: 'bottom auto',
	container: 'body',
});


const TagModel = BbeModel.extend({
	isNew() { return true; }
});


const waitPleaseTemplate = _.template('... подождите пожалуйста ...');
const layoutTemplate = _.template(`
	<div class="info">
		<div class="oir">
			<div class="owner-info" title="собственник"></div>
			<div class="realty-info" title="недвижимость"></div>
		</div>
		<div class="contacts-info" title="контакты"></div>
		<div class="tags-info" title="тэги"></div>
		<div class="actualize-info" title="заметки для актуализатора"></div>
	</div>
	<div class="objects"></div>
`);



const InfoView = MneView.extend({
	className: 'inner',
	template: _.template(`
	<div class="icon"><i></i></div>
	<div class="entity flex-grow"><a href="<%= url %>" target="_blank"><%= name %></a></div>
	<div class="show-list btn-icon"><i class="glyphicon glyphicon-align-justify"></i></div>
	`),
	behaviors: [beh.DynamicClass],
	dynamicClass() {
		return this.getOption('modelType');
	},
	GetEntity() {
		return this.model.get(this.getOption('modelType'));
	},
	events:{
		'click .show-list':'selectOther'
	},
	isOwner() {
		return this.getOption('modelType') == 'owner';
	},
	selectOther(event) {
		let options;
		if (this.isOwner()) {
			options = {
				header: 'Выберите собственника',
				controlType: 'selectOwners',
			}				
		} else {
			options = {
				header: 'Выберите здание',
				controlType: 'selectBuildings',
			}
		}
		EditValue.modal.single(options).then((type, models = []) => {
			if (type != 'ok') return;
			this.triggerMethod('select:other:done', models[0], event.ctrlKey)
		}, () => {});
	},
	onSelectOtherDone(model, ctrlKey) {
		let url = this.getOiRUrl(model.id, ctrlKey);
		if (ctrlKey) {
			let win = window.open(url, '_blank');
			win.focus();				
		} else {
			history.navigate(url, true);
		}
	},
	_getOiRUrl: hlp.getOiRUrl,
	getOiRUrl(otherId, absolute) {
		let realtyId, ownerId;
		if (this.isOwner()) {
			ownerId = otherId;
			realtyId = this.model.get('realtyId');
		} else {
			realtyId = otherId;
			ownerId = this.model.get('ownerId');
		}
		return this._getOiRUrl(ownerId, realtyId, absolute);
	},			
	templateContext() {

		let entity = this.GetEntity();
		let chunk = this.getOption('modelType') == 'owner' ? 'owners' : 'buildings';
		let id = this.model.get(this.getOption('modelType') + 'Id');
		let url = `http://adm.nrg.plus/rlt/${chunk}/${id}`;
		if (chunk == 'buildings')  {
			url = cfg.appSvcUrl('actualization/realties-list', id, 'edit');
		} else {
			url = cfg.appSvcUrl('actualization/owners-list', id, 'edit');
		}
		return {
			name: entity.name,
			url
			//url: `http://adm.nrg.plus/rlt/${chunk}/${id}`
		}
	}
});


const TagView = MneView.extend({
	tagName: 'label',
	className: 'tag',
	template: _.template('<span><i class="glyphicon glyphicon-tag"></i></span><span><%= id %></span><span class="close-btn" title="удалить тэг"><i class="glyphicon glyphicon-remove"></i></span>'),
	events: {
		'click .close-btn'() {
			let model = this.model;
			modals.confirm('подтвердите удаление тега').then(() => {
				model.destroy();
			});
		}
	},
	// initialize() {
	// 	console.log('>>> ###', this.model);
	// }
});

const TagsListView = MneNextCollectionView.extend({
	childView: TagView,
});

const TagsView = MneView.extend({
	className: 'inner',
	template: _.template(`
		<div class="icon">
			<i class="glyphicon glyphicon-tags"></i>
			<button class="add-tag" title="добавить тег"><i class="glyphicon glyphicon-plus"></i></button>
			<!-- <button class="save-tags"><i class="glyphicon glyphicon-save"></i></button> -->
		</div>
		<div class="tags-list flex-grow"></div>
	`),
	regions: {
		tags:'.tags-list'
	},
	initialize() {
		this.initializeTagsCollection();
	},
	initializeTagsCollection() {
		let tags = this.model.get('tags') || [];
		let models = tags.map(id => ({ id }));
		this.collection = new BbeCollection(models, { model: TagModel });
		//this.collection.url = this.model.url() + '/tags';
		//this.listenTo(this.collection, 'all', n => console.log('# col ev', n));
	},
	onRender() {
		let view = new TagsListView({ model: this.model, collection: this.collection });
		this.showChildView('tags', view);
	},
	collectionEvents: {
		update() {
			let tags = this.collection.pluck('id');
			let saver = new BbeModel();
			saver.save(null, { url: this.model.url() + '/tags', attrs: tags, wait: true, method: 'post' }).then(
				tags => console.log('ok', tags),
				xhr => console.log('err', xhr)
			);
		}
	},
	events: {
		'click .add-tag'(e) {
			var popupSetup = {
				controlType: 'text',
				valueType: 'text',
				header: 'новый тег:',
				//value: this.get('dates').deadline,
			};
			let col = this.collection;
			EditValue.modal.single(popupSetup)
				.done(function (type, newvalue) {

					if (type != 'ok') return;

					if (newvalue instanceof Array)
						newvalue = newvalue[0];

					newvalue = normalizeTagValue(newvalue);

					if (!newvalue) return;
					col.add({ id: newvalue });

				});
				


		}
	}
});


const NotesView = MneView.extend({
	className: 'inner',
	renderOnModelChange: true,
	template: _.template(`
	<div class="icon">
		<i class="glyphicon glyphicon-exclamation-sign"></i>
		<button class="edit-info" title="внести изменение"><i class="glyphicon glyphicon-edit"></i></button>
	</div>
	<div class="notes"><%= actualizeNotes %></div>		
	`),
	events: {
		'click .edit-info'() {
			let options = {
				header: 'Внесение изменений в заметки для актуализатора',
				valueType: 'bigtext',
				initialValue: this.model.getByPath('actualize.notes')
			}				
			EditValue.modal.single(options).then((type, text) => {
				if (type != 'ok') return;

				const attrs = { 'actualize.notes': text };
				const options = { method: 'patch', attrs };
				// options.wait = true;
				// this.model.save(null, { attrs: { 'actualize.notes': text }, wait: true, method: 'patch' });
				this.model.send(options).then(data => {
					this.model.setByPath(data)
				});
				//this.triggerMethod('select:other:done', models[0], event.ctrlKey)


			}, () => {});
		}
	},
	modelEvents: {
		change: 'render'
	},
	templateContext() {
		return {
			actualizeNotes: this.model.getByPath('actualize.notes')
		}

	},		
});


const ContactView = MneView.extend({
	className: 'contacts-list-item',
	behaviors:[ PopoverBehavior ],
	template: _.template(`			
		<div>
			<span class="name"><%- name %></span>
			<span class="value"><%= valueHtml %></span>
		</div>
		<div class="funcs"><%= roles %></div>
	`),
	getValueHtml() {
		let l1 = this.model.get('values') || [];
		let raw = l1.map(l => {
			return {
				..._.omit(l, 'value'),
				...(l.value || {}),
				orderType: l.type == 'mobilePhone' 
					? 100
					: l.type == 'phone'
						? 50
						: l.type == 'email'
							? 25
							: 0
			}
		});

		let first = _.sortBy(raw, (a,b) => bbeComparator(b, a, [i => i.orderType, i => i.type]))[0];
		if (!first) return '';

		return contactValueHtml(first)
	},
	templateContext() {
		//console.log(this.model.toJSON());
		return {
			name: this.model.getValueByPath('person.name.short') || '<имя не указано>',
			valueHtml: this.getValueHtml(),
			roles: refs.enum('realtyOwnerContactFunctions', this.model.get('ownerFuncs'))
		}
	}
});

const NoContactsView = MneView.extend({
	template: _.template('контакты не установлены')
});

const ContactsView = MneNextCollectionView.extend({
	className: 'contacts-list',
	emptyView: NoContactsView,
	childView: ContactView,
	initialize() {
		let models = this.model.get('contacts') || [];
		this.collection = new BbeCollection(models);
	}
});

const ContactsInfoView = MneView.extend({
	className: 'inner',
	template: _.template(`
	<div class="icon">
		<i class="glyphicon glyphicon-user"></i>
	</div>
	<div class="contacts"></div>			
	`),
	regions: {
		contacts: '.contacts'
	},
	onRender() {
		let view = new ContactsView({ model: this.model });
		this.showChildView('contacts', view);
	}
});

let Layout = MneView.extend({
	className: 'ownerInRealty',
	template: layoutTemplate,
	initialize() {
		this.model = new Model({
			ownerId: this.getOption('ownerId'),
			realtyId: this.getOption('realtyId')
		});
		this.model.firstFetch().then(() => this.render());
		this.listenTo(this.model, 'fetched', this.render);
		this.listenTo(innerBus, 'main:re:render', this.reFetch);

	},
	getTemplate() {
		if (this.model.isFetched()) {
			//console.log('> already fetched');
			return layoutTemplate;
		} else {
			//console.log('> not yet fetched');
			return waitPleaseTemplate;
		}
		// return layoutTemplate;
	},
	onRender() {
		//console.log('#> RENDER MAIN');
		// if (!this.fetched) {
		// 	this.model.firstFetch();
		// 	return;
		// }
		if (!this.model.isFetched()) {
			return;
		} 

		let defs = { model: this.model };

		let owner = new InfoView({ ...defs, modelType: "owner" });
		this.showChildView('owner', owner);

		let realty = new InfoView({ ...defs, modelType: "realty" });
		this.showChildView('realty', realty);

		let tagsView = new TagsView(defs);
		this.showChildView('tags', tagsView);

		let objectsView = new ObjectsLayout(defs);
		this.showChildView('objects', objectsView);

		let actualizeInfo = new NotesView(defs);
		this.showChildView('actualizeInfo', actualizeInfo);

		let contacts = new ContactsInfoView(defs);
		this.showChildView('contactsInfo', contacts);

		// this.listenTo(objectsView, 'main:re:render', () => {
		// 	console.log('GOING REFETCH');
		// 	setTimeout(() => {
		// 		this.reFetch();
		// 	}, 2000);
		// });


	},
	reFetch() {
		return this.model.fetch().then(
			() => {
				this.fetched = true;
				this.render();
			},
			() => {}
		);
	},
	regions: {
		owner: '.owner-info',
		realty: '.realty-info',
		tags: '.tags-info',
		objects: '.objects',
		actualizeInfo: '.actualize-info',
		contactsInfo: '.contacts-info'
	},


});


// const NewLayout = HamburgerView.extend({
// 	className: 'ownerInRealty',
// 	childrenViews: [
// 		OwnerInRealtyView
// 	]
// });


export default Layout;
//export default NewLayout;
