﻿// define('svc-clients-layout', ['bus', 'behaviors-blockbuttons', 'm-clients', 'bus-svc', 'references', 'svc-clients-client-clientInBlock', 'ui-models-filters',
//  'realties-search-actionsPanel', 'ui-controls-fastfilters', '_libs/identity'], function (Bus, BlockbuttonsBehavior, clientsNs, svcRadio, refs, ClientInBlockView, Filters, ActionsPanel, FastFilters, identity) {});

// import Bus from '';
// import BlockbuttonsBehavior from '';
import clientsNs from 'm/clients';
//import svcRadio from '';
// import refs from 'references';
import ClientInBlockView from './client/clientInBlock';
import Filters from 'ui/models/filters';
// import ActionsPanel from 'svc/realties/search/actionsPanel';
import ActionsPanel from 'ui/layouts/actions-panel';
// import FastFilters from 'ui/controls/fastfilters';
// import identity from '_libs/identity';

import template from './searchLayout.html';

import { Backbone } from 'vendors';

import { MneView, LoadmoreOnScrollCollectionView } from 'core/mne';
import { canBeDownloaded } from './utils';
import { getFilters } from './filters';
import { buildUrlHashByKeys } from 'utils';





var CollectionView = LoadmoreOnScrollCollectionView.extend({
	className: 'clients-items-list clients-search',
	childView: ClientInBlockView,
	//behaviors: [MneBehaviors.OnScrollLoadmorable, MneBehaviors.ScrollableY],
});


var DownloadView = MneView.extend({
	className:'block',
	template: _.template('<ul><li class="list-btn"><span>скачать в эксель</span></li></ul>'),
	events: {
		'click li': function () {
			var cntx = this.getOption('context');
			if (cntx)
				cntx.triggerMethod('downloadXls');
		}
	}
});


var Layout = MneView.extend({
	//template: '#tmpl-service-clients-searchLayout',
	template,
	regions: {
		'actionsPanel': { el: '.actions-panel-left', replaceElement: true },
		'result': '.result',
	},
	onRender: function () {
		//this.resultRadio = this.getOption('radio');
		this.initializeFilters();
		this.showSearchResult();
		this.showActionPanel();
	},
	initialFiltersValues: function () {
		// var res = {};
		// if (!this.getOption('searchText'))
		// 	return res;
		// res.txt = decodeURIComponent(this.getOption('searchText'));
		// return res;

		const urlHash = buildUrlHashByKeys(this.getOption('urlQuery'), 'wid', 'text');
		return urlHash;

	},
	initializeFilters: function () {
		if (this.filterObject) return;
		this.filterObject = Filters.create({
			filters: getFilters(this.initialFiltersValues()),
		});
	},
	showSearchResult: function () {
		var resultView = new CollectionView({ collection: this.collection, filterObject: this.filterObject });
		this.showChildView('result', resultView);
		if (this.getOption('searchText'))
			this.filterObject.apply();
	},
	showActionPanel: function () {
		const additionalView = canBeDownloaded() ? { class: DownloadView, context: this } : undefined;
		var actionspanel = new ActionsPanel({
			filterObject: this.filterObject,
			additionalView,
		});
		this.showChildView('actionsPanel', actionspanel);
	},
	onDownloadXls: function () {
		var chunks = _.result(this.collection, 'url').split('/');
		chunks.pop();
		chunks.push('download');
		var url = chunks.join('/');

		Backbone.ajax({
			method: 'get',
			url: url,
			data: this.filterObject.getUrlHash(),
		}).then(function (data) {
			document.location = url + '/' + data;
		});

	}
});

Layout.create = function (module, opts) {
	opts || (opts = {});

	var col = new clientsNs.ClientsSearchCollection();
	return new Layout(_.extend(opts, { collection: col, module: module }));
}


export default Layout;

