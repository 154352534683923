﻿//define('realties-sendByEmail-preview', ['bus','app-paths'], function(Bus, paths) {});

import Bus from 'bus';
import paths from 'app-paths';

import offtemplate from './offer.html';
import rlttemplate from './realty.html';
import pretemplate from './preview.html';

import { BbeModel } from 'core/bbe';
import { Collection } from 'core';

import { MneView, MneNextCollectionView } from 'core/mne';
import { PreviewOffer } from './PreviewOffer';
import { PreviewRealty } from './PreviewRealty';

// var PreviewOffer = MneView.extend({
//     tagName: 'tr',
//     //template: '#tmpl-service-realties-sendByEmail-offer',
//     template: offtemplate,
// });

var PreviewOffers = MneNextCollectionView.extend({
    className: 'offer-wrapper',
    tagName:'table',
    childView: PreviewOffer,
		initialize() {
			const models = this.getOption('offers') || [];
			this.collection = new Collection(models);
		}
});

// var PreviewRealty = MneView.extend({
//     //template: '#tmpl-service-realties-sendByEmail-realty',
//     template: rlttemplate,
//     className: 'realty-wrapper',
//     tagName:'table',
//     templateContextExtend: function() {
//         var thumbid = this.model.get('thumbnail');
//         var thumb = (thumbid && Bus.media.request('realty:thumbnail', thumbid, 'w150-h150-c-rbc')) || '';
//         return {
//             thumb: thumb,
//         }
//     }
// });

var PreviewRealtyWrapper = MneView.extend({
    tagName: 'tr',		
    template: _.template('<td><table class="realty-preview"><tr><td class="realty"></td></tr><tr><td class="offers"></td></tr></table></td>'),
    regions: {
        'realty': '.realty',
        'offers': '.offers',
    },
    onRender: function() {
        this.showRealty();
        this.showOffers();
    },
    showRealty: function() {
        var view = new PreviewRealty({ model: this.model.get('realty') });
        this.showChildView('realty', view);
    },
    showOffers: function() {
        var view = new PreviewOffers({ offers: this.model.get('offers') });
        this.showChildView('offers', view);
    },
});

var PreviewRealties = MneNextCollectionView.extend({
    tagName: 'table',
    className: 'presentation',
    childView: PreviewRealtyWrapper
});


var EmailView = MneView.extend({
    tagName: 'table',
    className: 'presentation',
    template: _.template('<tr><td contenteditable=true></td></tr><tr><td><div></div></td></tr><tr><td contenteditable=true></td></tr>'),
    regions: {
        'presa': { el: 'div', replaceElement:true },
    },
    onRender: function() {
        var view = new PreviewRealties({ collection: this.collection });
        this.showChildView('presa', view);
    },
});

var View = MneView.extend({
    className:'send-by-email-editor',
    //template: '#tmpl-service-realties-sendByEmail-preview',
    template: pretemplate,
    regions: {
        'style': { el: '.style', replaceElement: true },
        'preview': { el: '.presentation', replaceElement:true },
    },
    events: {
        'click button': 'onSendClick',
        'focus input[name=to]': function(e) {
            $(e.target).dropdown();
        },
        'blur input[name=to]': function(e) {
            //$(e.target).dropdown('hide');
        },
        'click [data-email]': function(e) {
            var $el = $(e.target);
            var clicked = ($el.data('email') || '').toLowerCase();
            var $to = this.$('input[name=to]');
            var aemails = ($to.val() || '').split(',');
            var hemails = {}
            _(aemails).each(function(e) {
                var n = e.trim().toLowerCase();
                if(n)
                hemails[n] = true;
            });

            if (clicked in hemails)
                delete hemails[clicked];
            else
                hemails[clicked] = true;

            var setemails = _(hemails).map(function(x, email) { return email; }).join(', ');
            $to.val(setemails);

        }

    },
    onRender: function() {
        this.showPreview();
    },
    showPreview: function() {
        var view = new EmailView({ collection: this.collection });
        this.showChildView('preview', view);
    },
    getHtml: function() {
        var props = {
            'vertical-align':1,
            'width': 1,
            'max-width': 1,
            'margin-left': 1,
            'margin-right': 1,
            'margin-top': 1,
            'margin-bottom': 1,
            'padding-left': 1,
            'padding-right': 1,
            'padding-top': 1,
            'padding-bottom': 1,
            'border-top-width': 1,
            'border-top-style': 1,
            'border-top-color': 1,
            'border-left-width': 1,
            'border-left-style': 1,
            'border-left-color': 1,
            'border-right-width': 1,
            'border-right-style': 1,
            'border-right-color': 1,
            'border-bottom-width': 1,
            'border-bottom-style': 1,
            'border-bottom-color': 1,
            'font-weight': 1,
            'color': 1,
        };
        function inliner($el){
            var css = {};
            _(props).each(function(v, name) {
                css[name] = $el.css(name);
            });
            $el.css(css);
        }

        var root = this.$('.holder').clone();
        root.appendTo($('body'));

        var elements = root.find('*');
        console.log(root);
        console.log(elements);
        elements.each(function(i, el) {
            if (!el.className) return;
            var $el = $(el);
            inliner($el);
        });
        var imgs = root.find('img');
        imgs.each(function(i, el) {
            if (el.src) {
                el.src = el.src.replace('.nrg.loc/', '.nrg.plus/');
                console.log('.');
            }
        });
        var html = root.html();
        root.detach();
        return html;
    },
    onSendClick: function(e) {
        var $btn = $(e.target).closest('button');
        var self = $btn.hasClass('self-send');
        var to = self ? undefined : this.$('input[name=to]').val();
        var subject = this.$('input[name=subject]').val();
        var addMe = this.$('input[name=copy]:checked').val() == 'true';

        var html = this.getHtml();
        var rawpost = {
            subject: subject,
            addMe: addMe,
            self: self,
            to: to,
            html: html,
        }
        var post = new BbeModel(rawpost);
        post.urlRoot = paths.api.svc + '/sendHtmlEmail';
        var _this = this;
        if (!self && !!to) {
            Bus.modals.request('confirm').then(function() {
                _this.sendEmail(post);
            });
        } else if (self) {
            _this.sendEmail(post);
        }
    },
    sendEmail: function(email) {
        let preloader = Bus.modals.request('preloader');
        email.loudSave().always(function() {
            preloader.destroy();
        });
    },
    templateContext: function() {
        return {
            emails: this.getOption('emails'),
        };
    },
});

export default View;