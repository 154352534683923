import { ModalView } from './modal-view';
import { BbView, View } from 'base/views';

const emptyArr = [];

function normalizeOptions(options, add) {
    return Object.assign({
        shouldShowBg: true,
        shouldShowClose: true,
        shouldShowPromiseButtons: true
    }, options, add);
}

function normalizeContents(contents) {
    if (contents == null) 
        return emptyArr;

    if (contents instanceof BbView)
        return [contents];

    if (Array.isArray(contents))
        return contents.filter(view => view instanceof BbView);

    return emptyArr;
}

function buildModalView(contents, options)
{
    contents = normalizeContents(contents);
    options = normalizeOptions(options, { modalContents: contents });
    return new ModalView(options);
}

function attachModalView(modalView, options)
{
    modalView.triggerMethod('before:attach');
    modalView.$el.appendTo(document.body);
    modalView._isAttached = true;
    modalView.triggerMethod('attach');
}

export function flexModal(contents, options) {

    let modalView = buildModalView(contents, options);

    attachModalView(modalView, options);

    modalView.render();

    return modalView;
}