﻿//define('m-tasks-task', ['bus', 'm-abstract','app-paths', '_libs/identity', 'action', 'link', 'ui-controls-editValue', 'm-factory'], function (Bus, abstractNs, paths, identity, action, link, EditValue, factory) {});

import Bus from 'bus';
import paths from 'app-paths';
import link from 'link';
import identity from '_libs/identity';

//import abstractNs from '../abstract';
import { ActionableModel, CardableModelMixin, FetchableByWebIdMixin } from '../abstract';
// import ActionableModel from '../abstract/actionable';
// import CardableModelMixin from '../abstract/cardable';
// import FetchableByWebIdMixin from '../abstract/fetchableByWebId';

import factory from 'm/factory';
import EditValue from 'ui/controls/editValue';
import action from '_helpers/action';

import { unFlatObject } from 'utils/object-manipulations';
import { _ } from 'vendors';

// const action = () => {};
// const EditValue = {};
// const CardableModelMixin = () => ({});
// const ActionableModel = { extend() {} };
// const FetchableByWebIdMixin = {};
// const mixin = {};
// const factory = {};

const mixin = {

		state: function(name){
			switch (name) {
				case 'initial':
					return this.get('state') == 'initial';
				case 'closed':
					return this.get('state') == 'closed';
				case 'onChecking':
					return this.get('state') == 'onChecking';
				case 'active':
					return this.state('initial') || this.state('inProcess');
				default:
					return !this.state('initial') && !this.state('closed') && !this.state('onChecking');
			}
		},
		isMyOrMyEmployee: function () {
			return this.isMy() || this.isMyEmployee();
		},
		isMyEmployee: function () {
			return identity.isMyEmployee(this.get('responsibleId'));
		},
		isMy: function () {
			return this.get('responsibleId') == identity.get('employeeId');
		},
		isFromMe: function () {
			return this.get('responsibleId') != identity.get('employeeId') && this.get('creatorId') == identity.get('employeeId');
		},
		isForMyCheck: function () {
			return this.get('checkerId') == identity.get('employeeId') && this.state('onChecking');
		},
		isClosed: function () {
			return this.state('closed');
		},
		actions: function () {
			return [
				action('openModalDeal', 'показать процесс', {}, { order: 0, rule: function () { return !!this.get('deal'); } }),
				action('openModalClient', 'показать клиента', {}, { order: 0, rule: function () { return !!this.get('client'); } }),
				action('openModal', 'показать', {}, { order: 0 }),
				action('accept', 'взять в работу', {}, {
					order: 0, rule: function () {
						var res = this.state('initial') && this.isMy();
						return res;
					}
				}),
				action('addStrictDeadline', 'установить жесткий дедлайн', {}, {
					order: 0, rule: function () {
						return !(this.get('dates') || {}).strictDeadline;
					}
				}),
				action('removeStrictDeadline', 'снять жесткий дедлайн', {}, {
					order: 0, rule: function () {
						return !!(this.get('dates') || {}).strictDeadline;
					}
				}),					

				//action('sendOnCheck', 'Отправить на проверку', {}, {
				//	order: 0, rule: function () {
				//		var res = this.state() && this.get('checkerId') && this.get('checkerId') != identity.get('employeeId');
				//		return res;
				//	}
				//}),
				action('checkFailed', 'Вернуть в работу', {}, {
					order: 0, rule: function () {
						var res = this.state('onChecking') && this.get('checkerId') && this.get('checkerId') == identity.get('employeeId');
						return res;
					}
				}),
				action('setNewDeadline', 'установить новый срок', {}, {
					order: 0, rule: function () {
						return this.state();
					}
				}),
				action('completeTask', 'завершить задачу', {}, {
					order: 0, rule: function () {
						return (this.state() && this.get('responsibleId') == identity.get('employeeId'))
							|| (this.state('onChecking') && this.get('checkerId') == identity.get('employeeId')) ;
					} }),
				action('cancelTask', 'отменить задачу', {}, {
					order: 0, rule: function () {
						return this.state() || (this.state('initial') || this.isMyEmployee());
					}
				}),
				action('openTaskPage', 'перейти на страницу задачи', {}, { order: 0 }),
			];
		},
		defaults: function () {
			return {
				toDo: undefined,
				type: 'common',
				dates: {
					strictDeadline: false,
					deadline: (new Date()).toMinute().addMinutes(15),
					duration: 15,
				},
				responsibleId: identity.get('employeeId'),
				checkerId: undefined,
			}
		},


		getWhenInfo: function () {
			var raw = (this.get('dates') || {}).deadline;
			var i = Date.info(raw);
			return i;
		},
		getWhen: function () {
			var i = this.getWhenInfo();
			return i.date;
		},
		getWhenValue: function () {
			var when = this.getWhen();
			if (!when)
				return 0;
			return when.valueOf();
		},

		validateCreate: function () {
			console.log((this.get('toDo') || '').length, this.get('responsibleId'), Date.valid(this.get('dates').deadline), this.get('dates').deadline.valueOf() > Date.now());
			console.log(this.get('dates').deadline)
			return (this.get('toDo') || '').length > 0
				&& this.get('responsibleId')
				&& Date.valid(this.get('dates').deadline)
				&& this.get('dates').deadline.valueOf() > Date.now();
		},
		urlRoot: function () {
			return paths.api.tasks;
		},

		actionOpenModalDeal: function (context, modal) {
			var deal = this.get('deal');
			if (!deal) return;
			var model = factory.model('JsonDeal', deal.wId);
			var View = Bus.Views.request('deal:page');
			var hash = _.result(model, 'modalOptions') || {};
			model.loudFetch().then(function () {
				var options = _.extend(hash, {
					View: View,
					model: model
				}, model.openInModalOptions);
				return Bus.modals.request('show:fullscreen', options);
			});
		},
		actionOpenModalClient: function (context, modal) {
			var client = this.get('client');
			if (!client) return;
			var model = factory.model('JsonClient', client.wId);
			var View = Bus.Views.request('client:page');
			var hash = _.result(model, 'modalOptions') || {};
			model.loudFetch().then(function () {
				var options = _.extend(hash, {
					View: View,
					model: model
				}, model.openInModalOptions);
				return Bus.modals.request('show:fullscreen', options);
			});
		},


		actionAddStrictDeadline: function() {
			this.loudPatch({ dates: { strictDeadline: true } }, { wait: true });
		},
		actionRemoveStrictDeadline: function () {
			this.loudPatch({ dates: { strictDeadline: false } }, { wait: true });
		},

		actionOpenTaskPage: function (options) {
			if (options.modal)
				options.modal.destroy();

			var url = _.result(this,'cardUrl');
			Bus.navigate(url);

		},

		actionAccept: function (options) {
			if (options.modal)
				options.modal.destroy();

			this.loudPatch({ state: 'inProcess' });
		},
		actionCompleteTask: function (options) {
			if (options.modal)
				options.modal.destroy();

			this.loudPatch({ state: 'closed', result: 'succeeded' });
		},
		actionCancelTask: function (options) {
			if (options.modal)
				options.modal.destroy();

			this.loudPatch({ state: 'closed', result: 'canceled' });
		},
		actionSendOnCheck: function (options) {
			if (options.modal)
				options.modal.destroy();
			this.loudPatch({ state: 'onChecking' });
		},
		actionCheckFailed: function (options) {
			if (options.modal)
				options.modal.destroy();
			this.loudPatch({ state: 'inProcess' });
		},
		actionSetNewDeadline: function (options) {
			console.log('act', options);
			if (options.modal)
				options.modal.destroy();
			var model = this;
			var popupSetup = {
				controlType:'datetime2',
				valueType: 'datetime',
				header: 'Установите крайний срок',
				value: this.get('dates').deadline,
			};
			var field = 'dates.deadline';
			EditValue.modal.single(popupSetup)
				.done(function (type, newvalue) {

					if (type != 'ok') return;

					// if (newvalue instanceof Array && !setup.multiple)
					// 	newvalue = newvalue[0];
					// TODO: check this case ^^^^ 
					if (newvalue instanceof Array)
						newvalue = newvalue[0];

					var hash = {};
					hash[field] = newvalue;
					var unflatHash = unFlatObject(hash);
					model.loudPatch(unflatHash);
					model.trigger('change', model);
				});

		}
};

const cardable = 	CardableModelMixin({
	cardView: 'task:page',
	cardUrl: function () {
		return link.url('taskCard:'+this.get('wId'));
	},
});



var Model = ActionableModel.extend({
	...cardable,
	...FetchableByWebIdMixin,
	...mixin
});

export default Model;

