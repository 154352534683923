import { _ } from 'vendors';
import { computePosition, enableAutoUpdate } from 'vendors/floating-ui';
import { PopupView } from './PopupView';
import { popupApi } from './api';

export const basePopupMixin = {

    initializePopupMixin() {
        this._popups = {};
        let popups = this.getPopupConfigs();
        if (!popups) { return; };
        _.each(popups, (config, index) => {
					popupApi.initializePopup(this, config, index);
				});

        this.on('before:destroy before:detach', () => {
            _.each(this._popups, (popup) => {
                popupApi.removePopup(this, popup, true);
            });
        });
    },

    getPopupConfigs() {
        return this.getOption('popups');
    },

    buildPopupView(View, options, triggerEl, config) {

        let model, collection;

        if (config.passModel !== false) { model = this.model }
        if (config.passCollection !== false) { collection = this.collection }

        if (model || collection) {
            let shouldAddModel = model && (!options || !options.model);
            model = shouldAddModel ? model : undefined;
    
            let shouldAddCollection = collection && (!options || !options.collection);
            collection = shouldAddCollection ? collection : undefined;
    
            if (shouldAddModel || shouldAddCollection) {
                options = _.extend({ model, collection }, options);
            }
        }

        if (!config.wrapContent) {
            return new View(options);
        }

        let popupViewOptions = _.extend({ 
            model, collection, 
            contentView: View, 
            contentOptions: options 
        }, config.popupViewOptions);

        return new PopupView(popupViewOptions);

    },

    buildPopupComputePositionOptions(popupView, config) {
        let { popupOffset, popupShift, useShift, useFlip, autoPlacement } = config;

        let arrowSelector = popupView.getOption('arrowSelector');
        let arrowEl;
        if (arrowSelector) {
            arrowEl = popupView.$(arrowSelector).get(0);
        }

        let options = Object.assign({ arrowEl, popupOffset, popupShift, useShift, useFlip, autoPlacement }, config.positionOptions);

        // if (options.arrowSelector) {
        //     let arrowEl = popupView.$(options.arrowSelector).get(0);
        //     if (arrowEl) {
        //         options.arrowEl = arrowEl;
        //     }
        // }

        return options;
    },

    updatePopupPosition(popupView, triggerEl, config) {
        let referenceEl = this.getPopupReferenceElement(config, triggerEl);
        let options = this.buildPopupComputePositionOptions(popupView, config);
				// console.log('-popup-position-', options);
        computePosition(referenceEl, popupView.el, options);
    },

    getPopupReferenceElement(config, eventEl) {
        let selector = config.referenceSelector || config.openSelector;
        if (selector) {
            let $el = this.$(selector);
            if ($el && $el.length) {
                return $el.get(0);
            }
        }
        if (config.referenceElement === 'event') {
            return eventEl;
        }
        return this.el;
    },

    setupPopupView(popupView, config, triggerEl) {
        
        //this.on('before:destroy before:detach', () => popupView.destroy());

        // this.on('detach', () => {
        //     console.log('++++++++++ D E T A C H E D ++++++++++++++');
        // })

        if (!config.autoUpdateEnabled) { return; }

        let referenceEl = this.getPopupReferenceElement(config, triggerEl);
        let options = this.buildPopupComputePositionOptions(popupView, config);

        popupView.on('attach',() => {
            popupView._disablePopupAutoUpdate = enableAutoUpdate(referenceEl, popupView.el, options);
        });
        popupView.on('before:detach', () => {
            if (popupView._disablePopupAutoUpdate) {
                popupView._disablePopupAutoUpdate();
            }
        });
    },

    // defaultPopupConfig: {
    //     addArrow: true,
    //     arrowSelector: '[data-role="arrow"]',
    //     popupOffset: 6,
    //     popupShift: { padding: 5 }
    // }

}
