﻿//define('bus-ws', ['bus-core', 'bus-app', '_libs/identity'], function (b, appRadio, identity) {});

import b from './core';
//import appRadio from './app';
import identity from '_libs/identity';

import { MneObject } from 'core/mne';	


var Bus = MneObject.extend({
	broadcast: function (broadcast) {
		if (typeof this[broadcast.type] == 'function')
			this[broadcast.type](broadcast);
		else
			console.warn('broadcast not found', broadcast.type);
	},
	radios: {
		task: function (id) {
			return b.channel('ws:task:' + (id || ''));
		},
		notify: function () {
			return b.channel('ws:employee:' + identity.get('employeeId'));
		},
	},
	comment: function (data)
	{
		var radios = [];

		radios.push(this.radios.notify());

		var model = data.data;
		if (model.taskId) 
			radios.push(this.radios.task(model.taskId))

		this.triggerOn(radios, data)
		//conso
	},
	triggerOn(radios, data)
	{
		_(radios).each(function (radio) {
			radio.trigger(data.type, data.data);
		});
	}
});

export default new Bus();
