﻿//define('svc-clients-client-clientInBlock', ['bus', 'link', 'behaviors', 'server-cfg'], function (Bus, link, beh, serverCfg) {});

import Bus from 'bus';
//import link from '';
import beh from 'behaviors';
//import serverCfg from '';
import testTemplate from './inPopup.html';
import clientTemplate from './clientInBlock.html';
import DealInLine from 'svc/deals/deal/inLine';

import { MneView, MneNextCollectionView } from 'core/mne';

import { _ } from 'vendors';

var TestView = MneView.extend({
	className: 'client client-in-popup',
	//template: '#tmpl-service-clients-client-inPopup',
	template: testTemplate,
	onShowed: function () {
		this.fetchDeals();
	},
	fetchDeals: function () {
		var view = this;
		if (view.model.isFetched()) {
			view.render();
		}
		else {
			view.model.fetch().then(function () {
				view.render();
				view.trigger('content:change');
			});
		}
	},
	regions: {
		'deals':'.deals-region'
	},
	onRender: function () {
		this.showDeals();
	},
	hasDeals: function () {
		var deals = this.model.getDeals();
		return !!deals.length;
	},
	showDeals: function () {
		// if (serverCfg.isNewApi) {
		// 	return;
		// }
		var _this = this;
		_this.dealsFetched = _this.model.isFetched();
		var Deal = DealInLine;
		var view = new MneNextCollectionView({
			childView: Deal,
			collection: this.model.getDeals(),
			emptyView: MneView.extend({
				getTemplate: function () {
					if (_this.dealsFetched)
						return _.template('нет процессов');
					else
						return _.template('<i class="fa fa-refresh fa-spin"></i> процессы подгружаются');
				},
			})
		});
		this.showChildView('deals', view);
	}
});

var Client = MneView.extend({
	className: 'client client-in-block',
	//template: '#tmpl-service-clients-client-clientInBlock',
	template: clientTemplate,
	behaviors: [beh.Datarole.Self, beh.DatarolePopup, beh.BootstrapPopover.extend({
		content: TestView,
		contentOptions: function () {
			return {
				model:this.view.model
			}
		},
	})],
	modelEvents: {
		'change':'render'
	},
	attributes: function () {
		return {
			'data-role': 'popup',
			'data-type': 'actions',
			'data-id': this.model.get('id'),
		}
	},
});

export default Client;
