import { SmartPropertiesLinesView } from 'coms/ui/PropertiesLineView';
import { OldSchema, modelSchema } from '../schema';
import { claimsApi } from 'apis/claims';
import { fastEditAction } from '../../fast-edit-action';

const oldProperties = [
    'address',
    'subway',
]

const newProperties = [
    'realtyType',
    'buildingType',
    'building.buildYear',
    'building.reBuildYear',
    'building.squareTotal',
    'building.squareUsefull',
    'taxSvcNumber',
    'address.fullAddress',
    'subwayDetails'
]

export const RealtyPropertiesView = SmartPropertiesLinesView.extend({
    thisClassName: 'with-borders  colored-values',
    modelSchema: modelSchema,
    properties: newProperties,
    editEnabled: () => claimsApi.canEditRealtiesInCard(),
    editAction: fastEditAction,
    //showValueless: false
});