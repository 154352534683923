﻿// define('behaviors', [
//     'behaviors-submit'
// 	, 'behaviors-subdivision'
// 	, 'behaviors-getFormObject'
// 	, 'behaviors-dynamicClass'
// 	, 'behaviors-datarole'
// 	, 'behaviors-datarolePopup'
// 	, 'behaviors-form2Hash'
// 	, 'behaviors-fastactionTrigger'
// 	, 'behaviors-selectedForActions'
// 	, 'behaviors-bootstrapPopover'
//     , 'behaviors-modelActionsInPopup'
//     , 'behaviors-wsMonitor'
// ], function (Submit, Subdivision, GetFormObject, DynamicClass, Datarole, DatarolePopup, Form2Hash, FastactionTrigger, SelectedForActions, BootstrapPopover, ModelActionsInPopup, WsMonitor) {});


import Submit from './submit';
import Subdivision from './subdivision';
import GetFormObject from './getFormObject';
import DynamicClass from './dynamicClass';
import Datarole from './datarole';
import DatarolePopup from './datarolePopup';
import Form2Hash from './form2Hash';
import FastactionTrigger  from './fastactionTrigger';
import SelectedForActions from './selectedForActions';
import BootstrapPopover from './bootstrapPopover';
import ModelActionsInPopup from './modelActionsInPopup';
import WsMonitor from './wsMonitor';
import { ClickableBehavior } from './clickable';
import { BasePopup, DynamicPositionPopup, SimplePopup } from './simplePopup';
import { FilteredCollection } from './filteredCollection';
import { ScrollableY } from './scrollableY';
import { OnScrollLoadmorable } from './onScrollLoadmorable';
var ns = {};

ns.Submit = Submit;
ns.Subdivision = Subdivision;
ns.GetFormObject = GetFormObject;
ns.DynamicClass = DynamicClass;
ns.Datarole = Datarole;
ns.DatarolePopup = DatarolePopup;
ns.Form2Hash = Form2Hash;
ns.FastactionTrigger = FastactionTrigger;
ns.SelectedForActions = SelectedForActions;
ns.BootstrapPopover = BootstrapPopover;
ns.ModelActionsInPopup = ModelActionsInPopup;
ns.WsMonitor = WsMonitor;
ns.Clickable = ClickableBehavior;
ns.BasePopup = BasePopup;
ns.DynamicPositionPopup = DynamicPositionPopup;
ns.SimplePopup = SimplePopup;
ns.FilteredCollection = FilteredCollection;
ns.ScrollableY = ScrollableY;
ns.OnScrollLoadmorable = OnScrollLoadmorable;
//ns.Dyan

export default ns;

export {
    Submit,
    Subdivision,
    GetFormObject,
    DynamicClass,
    Datarole,
    DatarolePopup,
    Form2Hash,
    FastactionTrigger,
    SelectedForActions,
    BootstrapPopover,
    ModelActionsInPopup,
    WsMonitor,
    ClickableBehavior as Clickable,
    BasePopup, DynamicPositionPopup, SimplePopup,
    FilteredCollection, ScrollableY, OnScrollLoadmorable

}