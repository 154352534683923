﻿//define('app-config', ['server-cfg', 'assets-identity-token', 'bus-app'], function (serverCfg, Token, busApp) {});

import serverCfg from 'server-cfg';
import { _ } from 'vendors';
// import Token from '../../assets/identity';
// import busApp from 'bus/app';

	// function getMetaValue(name) {
	// 	var el = document.querySelector('head > meta[name="' + name + '"]');
	// 	var value = (((el || {}).attributes || {}).value || {}).value;
	// 	return value;
	// }

	function initRootPaths(ns) {
		var p = ns.paths = {};

		p.app = _.extend({}, serverCfg.paths.app, {
			root: '',
		});

		var approot = p.app.root || '';
		var approot$1 = (approot !== '' ? p.app.root + '/' : '');
		p.app.svcRoot = p.app.native ? approot$1 + 's' : '';
		p.app.svcRoute = !p.app.svcRoot ? '' : p.app.svcRoot + '/:svcIdString';	
			//p.app.svcRoot == '' ? '' : p.app.svcRoot + '/:svcIdString';

		var versionNum = 'v01';

        var root = serverCfg.paths.api;
        var server = serverCfg.paths.apiServer;
		var version = root + versionNum;
		var identity = version;
        p.api = {
			server: server,
			root: root,
			version: version,
			identity: identity,
		};
		
	}

	// function getSvcHashFromMeta()
	// {
	// 	var svc = {
	// 		id: getMetaValue('gsvc'),
	// 		idString: getMetaValue('ssvc'),
	// 		name: getMetaValue('nsvc'),
	// 	};
	// 	return svc;
	// }

	//var isNative = serverCfg.isNative;

	//console.log('djs', serverCfg);

	//var svcIdString = getMetaValue('ssvc');
	//var svcId = getMetaValue('gsvc');

	function normurl(url) {
		if (!url.startsWith('/')) {
			url = '/' + url;
		}
		return url;
	}



	var ns = {};



	//init current service
	// var svc = getSvcHashFromMeta();
	// if (svc.id) {
	// 	ns.service = svc;
	// }

	initRootPaths(ns);
	

	ns.sockets = serverCfg.sockets;

	//define app templates source
	ns.templates = {
		type: 'inline',
		//container: '#bndls-tmpls-app-' + ns.apiVersion,
		container: '#app-tmpls',
		//type: 'ajax',
		//url: '/bndls/tmpls/app-' + ns.apiVersion + '/'
		//type: 'meta',
		//meta: 'templates-path',
	};

	//set initial navigation hash
	ns.navigation = serverCfg.navigation;

	ns.apiUrl = function () {
		var args = [].slice.call(arguments);
		args.unshift(this.paths.api.version);
		// return args.onlyValues().join('/');
		return args.filter(f => !!f).join('/');
	}
	ns.urls = {
		api(url) {
			url = normurl(url);
			return ns.paths.api.server + url;
		},
		apiV(url, version = 'v4') {
			return ns.urls.api(version + normurl(url));
		},
		url(path, service = true, absolute = true) {
			let root = absolute ? document.location.origin : '';
			if (service && serverCfg.isNative) {
				root += '/s/' + serverCfg.svcWebId;
			}
			if (!path.startsWith('/')) {
				path = '/' + path;
			}
			return root + path;
		},
		urlS(path, { withRoot = false, service = true } = {}) {
			let root = withRoot ? document.location.origin : '';
			if (service && serverCfg.isNative) {
				root += '/s/' + serverCfg.svcWebId;
			}
			if (!path.startsWith('/')) {
				path = '/' + path;
			}
			return root + path;
		}
	}

	ns.apiSvcUrl = function () {
		
		var args = [].slice.call(arguments);

		if (serverCfg.paths.svcIdInApiUrls && this.service && this.service.id) {
			args.unshift(this.service.id);
		}

		// if (typeof this.service !== 'object') {
		// 	console.warn("apiSvcUrl: service not defined",arguments);
		// } else {
		// 	let serviceId = this.service.id;
		// 	if (serviceId) {
		// 		args.unshift(serviceId);
		// 	} else {
		// 		console.warn("apiSvcUrl: service has no id",arguments);
		// 	}
		// }
		return this.apiUrl.apply(this, args);
		//return args.join('/');
	}

	ns.appUrl = function () {
		var args = [].slice.call(arguments);
		if ((this.paths.app.root || '').length > 0)
			args.unshift(this.paths.app.root);
		return args.onlyValues().join('/');
	}

	ns.appSvcRootUrl = function () {
		var args = [].slice.call(arguments);
		if ((this.paths.app.svcRoot || '').length > 0)
			args.unshift(this.paths.app.svcRoot);
		return this.appUrl.apply(this, args);
	}

	ns.appSvcUrl = function () {
		if (typeof this.service !== 'object')
			throw "appSvcUrl: service not defined";
		var svcroot = '';
		if (this.paths.app.native) {
			svcroot = this.paths.app.svcRoot + '/' + this.service.idString
		} else {
			svcroot = this.paths.app.svcRoot;
		}

		var args = [].slice.call(arguments);
		if(svcroot!='')
			args.unshift(svcroot);
		var res = this.appUrl.apply(this, args);

		if (!res.startsWith('/'))
			res = '/' + res;

		return res;
	}

	ns.appSvcRoute = function () {
		var args = [].slice.call(arguments);
		args.unshift(this.paths.app.svcRoute);
		return args.onlyValues().join('/');
	}

	ns.paths.media = serverCfg.paths.app.media; //'//media.nrg.plus';
	ns.paths.mediaImage = ns.paths.media + 'imgs/';
	ns.paths.mediaFile = ns.paths.media + 'files/';

	ns.getSvcIdString = () => {
		return serverCfg.svcWebId;
	}

	if (ns.service) {
		/*
		ns.paths.api.realtiesObject = ns.apiSvcUrl('realties','objects');
        ns.paths.api.realtiesBuilding = ns.apiSvcUrl('realties', 'buildings');
        ns.paths.api.realtiesBuildingSearch = ns.apiSvcUrl('realties', 'buildings', 'searchByText');

		ns.paths.api.tasks = ns.apiSvcUrl('tasks');
		ns.paths.api.tasksDeal = ns.apiSvcUrl('tasks', 'e', 'deal');
		ns.paths.api.tasksClient = ns.apiSvcUrl('tasks', 'e', 'client');
		ns.paths.api.tasksEmployee = ns.apiSvcUrl('tasks', 'e', 'employee');
		ns.paths.api.tasksToday = ns.apiSvcUrl('tasks', 'today');
		ns.paths.api.tasksTomorrow = ns.apiSvcUrl('tasks', 'tomorrow');
		ns.paths.api.tasksNearest = ns.apiSvcUrl('tasks', 'nearest');

		ns.paths.api.deals = ns.apiSvcUrl('deals');
		ns.paths.api.dealsNearest = ns.apiSvcUrl('deals', 'nearest');
		ns.paths.api.dealsOutofdate = ns.apiSvcUrl('deals', 'outofdate');

		ns.paths.api.clients = ns.apiSvcUrl('clients');

		ns.paths.api.comments = ns.apiSvcUrl('comments');

		ns.paths.api.contactValues = ns.apiSvcUrl('contactValues');
		*/
	}



	// ns._createToken = function(raw) {
	// 	if (Token.exists) {
	// 		console.error('token already exists');
	// 		return;
	// 	}
	// 	ns.authenticated = true;
	// 	Token.create(ns.paths.app.bearerToken, raw);
	// }


	/* TEMP

	ns.tokenPromise = Token.init(ns.apiUrl('acc/token')).then(() => {
		ns.authenticated = true;
		console.warn('prom: cfg.tokenPromise resolved', ns);
	});

	ns.whenReady = new Promise((resolveCfg, rejectCfg) => {
		busApp.once('svc:config', (svc) => {
			if (svc && svc.id) {
				ns.service = svc;
			}
			resolveCfg();
		});
	});

	*/

	//initialize bearer token
	//token.renewUrl = ns.paths.app.bearerToken;
	//token.setHash(serverCfg.token);
	//token.get();

	/* TEMP
	
	if (serverCfg.token) {

		ns._createToken(serverCfg.token);

		// ns.authenticated = true;
		// //var token =
		// 	Token.create(ns.paths.app.bearerToken, serverCfg.token);



		//var tokenValue = token.get();
		//console.log('token', token.checkExpire());
		//$.ajaxSetup({
		//	headers: {
		//		'Authorization': 'Bearer ' + tokenValue,
		//		'Accept': "application/json"
		//	}
		//});
	}
	console.log('cfg', ns);

	*/

	//ns.isNative = serverCfg.isNative;
	//ns.svcIdString = svcIdString;
	//ns.svcId = svcId;

	//ns.apiVersion = 'v01';
	//ns.apiRoot = serverCfg.apiRoot + ns.apiVersion + '/';
	//ns.apiSvcRoot = svcId ? ns.apiRoot + svcId + '/' : undefined;

	//ns.identityRoot = ns.apiRoot;


	//ns.appRoot = "";
	//ns.svcRoot = ns.appRoot + (serverCfg.isNative ? 's': "");
	//ns.svcIdRoot = !ns.svcRoot ? '' :  ns.svcRoot + '/:svcIdString';

	//ns.navigationUrl = serverCfg.navigationUrl;
	//ns.navigation = serverCfg.navigation;







	//ns.apiSvcUrl = function () {
	//	return ns.apiSvcRoot + [].slice.call(arguments).join('/');
	//}


	//console.warn('app-config', ns);
	export default ns;
