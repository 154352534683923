import appModule from 'appModule';
import link from 'link';
import { Layout } from './layout';
import Model from './model';

// import './group.less';

	
var module = appModule.content({

	name: 'department',
	routeData: () => link.route('staffDepartmentCard'),
	// claims: {
	// 	svcAdminModule: ["manageGroups"]
	// },			
	onBeforeRun: function (id) {
		var model = new Model({ id });
		this.addRunArgument(model);
		this.addRunPromise(model.fetch());
	},
	onRun: function (model) {
		var module = this;
		module.createAndShow(Layout, { header: function () { return 'отдел ' + this.model.get('name'); }, model: model }, { model: model }, { navLinks: this.navLinks() });
	}


});

export default module;

