﻿//define('svc-staff-deps-layout', ['svc-staff-deps-addOne', 'svc-staff-deps-departments', '_libs/identity'], function (AddOne, Departments, identity) {});


import AddOne from './addOne';
import Departments from './departments';
import identity from '_libs/identity';
import { MneView } from 'core/mne';

var View = MneView.extend({
	className:'departments-layout',
	template: _.template('<div class="add-department-region"></div><div class="departments-region"></div>'),
	regions: {
		'addOne': { el: '.add-department-region', replaceElement: true },
		'entries': { el: '.departments-region', replaceElement: true },
	},
	onRender: function () {
		console.error('-started-');
		this.showAddOne();
		this.showEntries();
	},
	showAddOne: function () {
		if (!identity.rights.checkModuleRights('staff', 'manageDepartments')) return;

		var view = new AddOne();
		this.showChildView('addOne', view);

	},
	showEntries: function () {
		var view = new Departments();
		this.showChildView('entries', view);
	},
});

export default View;