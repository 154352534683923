import { _, View } from 'vendors';
import { templateFuncs } from './template-funcs';
import { getValueByPath } from 'utils/object-manipulations';



const MneView = View.extend({
	/*
	DEPRECATED ANIMATIONS
	render: function () {
		var args = arguments;
		var animation = (this.animations || {}).render || (this.options.animations || {}).render;
		var view = this;

		if (!animation) {
			try {
				View.prototype.render.apply(view, args);
			} catch(e) {
				console.error(view);
				throw e;
			}
		}
		else {
			view.$el.fadeTo(1, 0);
			View.prototype.render.apply(view, args);
			view.$el.fadeTo(100, 1);
		}
	},
	destroy: function () {
		var view = this;
		var args = arguments;
		var animations = this.getOption('animations') || {};
		//console.log(animations, !animations.destroy);
		if(!animations.destroy)
			View.prototype.destroy.apply(view, args);
		else {
			view.$el.fadeTo(400, 0).promise().done(function () {
				View.prototype.destroy.apply(view, args);
			});
		}
	},
	*/

	defaultTemplateContext: function () {
		var model = this.model;

		var cntx = {
			_cid: this.cid,
			_m: this.model && this.model.toJSON() || {},
			_mv: function (path, options) {
				options || (options = {});
				var val = getValueByPath(this._m, path);
				var res = val;
				if (!options.format)
					return res;
				else
					return this._format(val, options);
			},
			_mfn: function () {
				return this._modelfunc.apply(this, arguments);
			},
			_modelfunc: function () {
				if (!model) return '';
				var args = [].slice.call(arguments);
				var func = args.shift();
				if (typeof model[func] === 'function') {
					return model[func].apply(model, args);
				} else {
					console.warn('template. no such model function:', func);
					return '';
				}
			}
		};
		var add = _.result(this, 'templateContextExtend') || {};
		//console.log('- invoked defaultTemplateContext -');
		return _.extend(cntx, templateFuncs, add);
	},
	mixinTemplateContext(target = {}) {
		let defaultTemplateContext;
		let templateContext = _.result(this, 'templateContext');
		if (!templateContext) {
			defaultTemplateContext = _.result(this, 'defaultTemplateContext');
		}
		return _.extend({}, target, defaultTemplateContext, templateContext);
	},
	// templateContext: function () {
	// 	var hash = _.result(this, 'defaultTemplateContext') || {};
	// 	return hash;
	// },
	getOptionValue: function (name) {
		var res = this.getOption(name);
		if (!_.isFunction(res))
			return res;
		else
			return res.call(this);
	}
});

MneView.create = function () {
	var options = arguments[0] || {};
	return new this(options);
};

export {
    MneView
}