module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<ul>\n	<li class="list-lbl halfed"><small>id</small><span>'+
((__t=( _m.id ))==null?'':__t)+
'</span></li>\n	<li class="list-lbl halfed"><small>площадь</small><span>'+
((__t=( _mfn('getSquare',{formated:true}) ))==null?'':__t)+
'</span></li>\n	<li class="list-lbl halfed"><small>рынок</small><span>'+
((__t=( _enum('realtyMarkets',_m.market)))==null?'':__t)+
'</span></li>\n	<li class="list-lbl halfed"><small>операция</small><span>'+
((__t=( _enum('realtyOperations',_m.operation)))==null?'':__t)+
'</span></li>\n	<li class="list-lbl halfed"><small>процент</small><span>'+
((__t=( _mfn('getIncomePercent',{formated:true}) ))==null?'':__t)+
'</span></li>\n	<li class="list-lbl halfed"><small>договор</small><span>'+
((__t=( _enum('contractTypes',_mv('forAgent.contractType')) ))==null?'':__t)+
'</span></li>\n	<li class="list-lbl halfed"><small>цена за метр</small><span>'+
((__t=( _mfn('getPriceMeter',{formated:true}) ))==null?'':__t)+
'</span></li>\n	<li class="list-lbl halfed"><small>цена за площадь</small><span>'+
((__t=( _mfn('getPriceSquare',{formated:true}) ))==null?'':__t)+
'</span></li>	\n	<li class="list-lbl halfed"><small>налог</small><span>'+
((__t=( _enum('taxTypes', _mv('forCustomer.taxType')) ))==null?'':__t)+
'</span></li>\n	<li class="list-lbl halfed"><small>кор. коэф. (BOMA)</small><span>'+
((__t=( _mv('forCustomer.boma') ))==null?'':__t)+
'</span></li>\n	<li class="list-lbl halfed"><small>статус</small><span>'+
((__t=( _enum('realtyOfferStatuses',_m.status)))==null?'':__t)+
'</span></li>\n	<li class="list-lbl halfed"><small>обновлялось</small><span>'+
((__t=( _tfn(_m.modified)))==null?'':__t)+
'</span></li>\n</ul>';
}
return __p;
};
