//define('svc/actualization/realties', ['appModule', 'link', '_libs/identity', 'svc/actualization/realties/layout'], function(Module, link, identity, Layout) {});

import Module from 'appModule';
import link from 'link'; 
import Layout from './layout';


var module = Module.content({
	name: 'actualization/realties',
	label: 'Актуализация зданий',
	routeData: () => link.route('actualizationRealties'),
	urlKey:'actualizationRealties',
	claims: {
		svcActualizationModule: ['view']
	},
	onRun: function () {

		var layout = new Layout();
		this.createAndShow(layout, 'Актуализация зданий');

	}
});


//module.registerModule(moduleClient);

export default module;

