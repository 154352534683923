import { _ } from 'vendors';
import { backendApi } from 'apis/backend';
import { flatObject } from 'utils';

const dbg = e => {
    if (e.startsWith('change')) {
        console.log('-> trigered: ', e);
    }
}

export function fastEditAction(hash, model) {

    //if (hash != null) { return }

    const sync = responseData => {
        let flatenOne = flatObject(hash);
        
        _.each(flatenOne, (value, key) => {
            console.warn('triggering', 'change:' + key, hash, responseData);
            model.setValueByPath(key, value);
            model.trigger('change:' + key, model);

        });
        //model.off('all', dbg);
        
    };

    backendApi.patch(hash, model, sync);

    //sync();

}