import { HamburgerView } from "coms/ui/HamburgerView";
import { TextView, CollectionView, Collection } from 'core';
import { VisibilityButton } from "coms/visibility-button";
import { ObjectBrokerVisibilityButton, ObjectAdvertVisibilityButton } from "mods/realties/objects/views";
import { DropdownActions } from "coms/ui/DropdownActions";
import { createCompare } from 'utils';
import { mediaApi } from "apis/media";
import { NextIconButtonView } from 'coms/ui/Button';
import { OfferListItem } from "./OfferListItem";
import { OfferModel } from "./OfferModel";

const NoOffersView = TextView.extend({
	baseClassName: 'no-offers',
	text: 'Предложения не созданы <button>добавить предложение</button>',
	triggers: {
		'click button':'add:offer:click'
	}
});

export const OffersContainer = CollectionView.extend({
	name: 'objectOffersView',
	className: 'offers-list',
	initialize() {
		this.filterModel = this.getOption('filterModel');
		this.initCollection();
		this.listenTo(this.filterModel, 'after:change', this.sort);
	},
	initCollection() {
		// const models = this.model.get('offers') || [];
		this.collection = this.model.getOffers();
			// new Collection(models, { model: OfferModel });
	},
	childView: OfferListItem,
	emptyView: NoOffersView,
	childViewOptions() {
		return {
			realtyObject: this.model,
			objectsSelector: this.getOption('objectsSelector'), 
			offersSelector: this.getOption('offersSelector'), 
		}
	},
	childViewTriggers: {
		'toggle:offer:select':'toggle:offer:select',
		'multy:action:click':'multy:action:click'
	},
	childViewEvents: {
		'add:offer:click'() {
			this.model.executeAction('addoffer');
		}
	},
	viewFilter(v, index) {
		const neededActualize = this.filterModel.object?.neededActualize;
		// const isNotActualized = v.model.isNotActualized();
		if (neededActualize) {
			if (!v.model.isNotActualized()) {
				return false;
			}
		}
		return this.filterModel.filterOffer(v.model, index, v);
	},
});