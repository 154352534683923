﻿//define('svc-deals-search', ['appModule', 'link', 'svc-deals-search-layout'], function (Module, link, Layout) {});

import Module from 'appModule';
import link from 'link';
import Layout from './layout';

import './deals-search.less';

var module = Module.content({
	name: 'deal-search',
	label: 'поиск процессов',
	urlKey: 'dealsSearch',
	routeData: function () {
		return link.route('dealsSearch');
	},
	onRun: function () {
		//this.showButtonsLayout();
		var layout = Layout.create(this, {
			searchText: this.query.text,
			searchId: this.query.id,
			urlQuery: this.query,
		});
		this.createAndShow(layout);
		//var module = this;
		//var model = new dealsNs.DealModel();
		//module.createAndShow(Layout, this.label, { model: model }, {});
	}
});


export default module;