const simplies = [
    Number,
    String,
    Date
];

function isArray(arg) {
    return arg && Array.isArray(arg);
}



function isSimple(arg) {
    return arg && simplies.some(simple => arg instanceof simple);
}

function isObject(arg) {
    return !isArray(arg) && arg && typeof(arg) === 'object' && !isSimple(arg)
}

function shouldBeSimplified(arg) {
    return isArray(arg) || isObject(arg);
}

function shouldBeSkiped(arg) {
    return typeof arg === 'function';
}

const addValue = {
    toArray(obj, value, key) {
        obj.push(value);
    },
    toObject(obj, value, key) {
        obj[key] = value;
    },
}


export default function simplifyValue(value) {
 
    if (shouldBeSkiped(value))
        return;
    
    if (shouldBeSimplified(value)) {
        return isArray(value) ? simplifyArray(value) : simplifyObject(value)
    }

    return value
}

function reducer(getValue, addValue, memo, key) {
    let value = getValue(key);
    if (value == null) {
        addValue(value, memo, key);
    } else {
        value = simplifyValue(value);
        if (value !== undefined)
            addValue(value, memo, key);
    }
    return memo;
}

function simplifyObject(obj) {
    if (!isObject(obj)) return obj;

    // Object.keys(obj).reduce((memo, key) => {
    //     let value = obj[key];
    //     if (value == null) {
    //         memo[key] = value;
    //     } else {
    //         value = simplifyValue(value);
    //         if (value !== undefined)
    //             memo[key] = value;
    //     }
    //     return memo;
    // }, {});

    return Object.keys(obj).reduce(reducer.bind(null, key => obj[key], (v,m,k) => m[k] = v), {})


}

function simplifyArray(arr) {
    if (!isArray(arr)) return arr;

    // arr.reduce((memo, value) => {
    //     if (value == null) {
    //         memo.push(value);            
    //     } else {
    //         value = simplifyValue(value);
    //         if (value !== undefined)
    //             memo.push(value);
    //     }
    // }, []);

    return arr.reduce(reducer.bind(null, key => key, (v,m,k) => m.push(v)), []);

}