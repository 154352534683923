﻿//define('ui-controls-range', ['ui-controls-input'], function(Input) {});

//import Input from '../input';

import template from './rangeControl.html';
import './form-control-range.less';
import { isNotValue } from 'utils';

import { isValue } from 'utils';

import { MneView } from 'core/mne';

var View = MneView.extend({
	//childViewEventPrefix: true,
	className:'form-control-range',
	//template: '#tmpl-ui-controls-range-rangeControl',
	template,
	regions: {
		'from': '.range-from-region',
		'to':'.range-to-region'
	},
	initialize: function () {
		//console.log('-range-', this);
		this.newValue = _.extend({
			from: undefined,
			to: undefined,
		}, this.getOption('initialValue'));

	},
	onRender: function () {
		//this.on('all', console.log);
		var from = this.getOption('fromView');
		var to = this.getOption('toView');
		if (!from || !to) {
			console.warn('range views missing');
			return;
		}
		from.viewName = "from";
		to.viewName = "to";
		to.doNotFocus = true;
		this.showChildView('from', from);
		this.showChildView('to', to);
	},
	// childViewEvents: {
	// 	'value:changed'(value, name) {
	// 		console.log(':value:changed:', arguments);
	// 		this.onChildviewValueChanged(value, name);
	// 	}
	// },
	onChildviewValueChanged: function (value, name) {
		//console.log('on:childview:value:changed', arguments);
		//this.newValue || (this.newValue = _.extend({}, { from: undefined, to: undefined }, this.getOption('initialValue')));

		if (isNaN(value))
			value = undefined;

		value = isValue(value) && value !== '' ? value : undefined;

		this.newValue[name] = value;

		var empty_f = !this.newValue || (this.newValue.from === '' || isNotValue(this.newValue.from));
		var empty_t = !this.newValue || (this.newValue.to === '' || isNotValue(this.newValue.to));
		var empty_all = empty_f && empty_t;

		var triggerVal = !empty_all ? this.newValue : undefined;

		this.triggerMethod('value:changed', triggerVal);

	}
});

View.create = function(options)
{
	return new View(options);
}

export default View;