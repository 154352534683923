//import Bbe from './namespace'; //'assets-backbone-namespace'

import { BbView, Model as BbModel } from 'vendors';


function getModel(arg) {
	//console.log(':::',arg)
	if (arg instanceof BbModel)
		return arg;
	else if (arg instanceof BbView)
		return arg.model;
	else
		return;
}
function getView(arg) {
	if (arg instanceof BbView)
		return arg;
	else
		return;
}

export function compareValues(a,b) {
	return	a < b ? -1
	: a > b ? 1
	: 0;
}

export function bbeCompareAB(a, b, func) {
	if (typeof func === 'function') {
		a = func.call(a, getModel(a), getView(a));
		b = func.call(b, getModel(b), getView(b));
	}
	return	a < b ? -1
			: a > b ? 1
			: 0;
};

/*
*	accepts:
*		variant #1: a, b, function
*		variant #2: [[a,b,function], [a,b,function]]
*		function can be undefined
*		example:
*			ascending	:	return bbeViewComparator(viewA, viewB, function(){ return this.model.get('someTextField') });
*			descending	:	return bbeViewComparator(viewB, viewA, function(){ return this.model.get('someTextField') });
*/
export function bbeViewComparator() {
	var compareArray = [];
	var result = 0;

	if (arguments.length >= 2)	// single compare
		return bbeCompareAB.apply(null, arguments);
	else if (arguments.length === 1 && arguments[0] instanceof Array)	// array of compare
		compareArray = arguments[0];

	_(compareArray).every(function (singleCompare) {
		result = bbeCompareAB.apply(null, singleCompare);
		return result === 0;
	});
	return result;
}


export function bbeComparator(a, b, funcs) {
	var result = 0; 
	var afuncs = [];
	if (funcs instanceof Array)
		afuncs = funcs;
	else if (typeof funcs === 'function')
		afuncs = [['a',funcs]];

	_(afuncs).every(function (args) {

		if (!args || !(args instanceof Array) || args.length < 2) return false;

		var av = args[0] == 'a' ? args[1].call(a) : args[1].call(b);
		var bv = args[0] == 'b' ? args[1].call(a) : args[1].call(b);


		result = bbeCompareAB(av, bv);
		return result == 0;

		//if (av == bv)
		//	return true;
					
		//result = (args[0] == 'a' ? ((av < bv) ? -1 : 1)
		//						: ((av > bv) ? -1 : 1));
		//return false;

	});
	return result;
}

const defaultGetter = v => v;
let dbg = a => a && a.render ? a.cid : a;

export function ABCompare(a, b, arr, level = '->') {
	let v1, v2;
	let arrType = typeof arr;
	
	if (!arr) {
		
		v1 = a; v2 = b;
	} else if (arrType === 'function') {
		
		v1 = arr(a);
		v2 = arr(b);
	} else if (Array.isArray(arr)) {
		
		for(let x = 0; x < arr.length; x ++) {
			let rule = arr[x];
			let result = ABCompare(a, b, rule, level + '->');
			if (result !== 0) { return result; }
		}
		return 0;
	} else if (arrType === 'object') {
		
		let get = arr.get || defaultGetter;
		v1 = get(arr.reverse ? b : a);
		v2 = get(arr.reverse ? a : b);
	}

	let res = v1 < v2 ? -1
	: v1 > v2 ? 1
	: 0;
	
	

	return res;

}

export function createCompare(arg) {
	if (arg && typeof arg === 'object' && Array.isArray(arg)) {
		const comparators = arg.map(cmp => createCompare(cmp));
		return function(v1, v2) {
			for (let cmp of comparators) {
				const value = cmp(v1, v2);
				if (value !== 0) {
					return value;
				}
			}
			return 0;
		}
	}

	if (typeof arg !== 'function') {
		throw new Error('comparer should be function or array of functions');
	}

	if (arg.length === 1) {
		return function (v1, v2) {
			const m1 = arg(v1);
			const m2 = arg(v2);
			return compareValues(m1, m2);
		}
	} else if (arg.length === 2) {
		return arg;
	} else {
		throw new Error('provided comparator function has wrong count of arguments. allowed 1 or 2 arguments only');
	}
}