import { Model } from 'core/model';
import { getContactValuesCollection } from './ContactValueModel';
import { Collection } from 'core/collection';

export const ContactModel = Model.extend({
	getMainValue() {
		const col = getContactValuesCollection(this);
		return col.getMainValue();
	},
	getMainPhoneValue() {
		const col = getContactValuesCollection(this);
		return col.getMainPhoneValue();
	},
	getMainEmailValue() {
		const col = getContactValuesCollection(this);
		return col.getMainEmailValue();
	},
});

export const ContactsCollection = Collection.extend({
	model: ContactModel
});