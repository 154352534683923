import { MneView } from './view';
import { _ } from 'vendors';


export const MneButtonView = MneView.extend({
	tagName: 'button',
	className: 'btn btn-default',
	autoRender:true,
	template: _.template('<i class="<%=iconClass%>"></i><span><%=text%></span>'),
	initialize: function () {
		if (this.getOption('autoRender') == true)
			this.render();
	},
	_getValue: function (key) {
		var model = this.model;
		var collection = this.collection;
		return (model && model.get(key)) || (_.result(collection, key)) || (_.result(this, key)) || this.getOption(key);
	},
	_defaultViewTrigger: function () {
		var prefix = this.getOption('eventPrefix') || 'btn';
		var event = this.getOption('event') || 'click';
		var trigger = [prefix, event].join(':');
		return trigger;
	},
	triggers: function () {
		var trigger = this._getValue('triggerOnClick') || this._defaultViewTrigger();
		return {
			'click': trigger
		}
	},
	templateContext: function () {
		return {
			text: this._getValue('text') || '',
			iconClass: this._getValue('iconClass') || '',
		}
	},
});