import { invokeValue } from 'utils';

const deniedAttributes = {
	class: 1,
	id: 1,
}

export const htmlAttributesMixin = {
	getElAttributes() {
		const title = this.getOption('tagTitle', true);

		const attrs = Object.assign(
			{ title }, 
			invokeProperties(this, this.getOption('attributes', true)),
			invokeProperties(this, this._runtimeElAttributes)
		);
		return attrs;
	},
	setElAttributes(attrs, replace, el) {
		attrs = attrs || {};
		el = el || this.el;
		if (replace) {
			const toRemove = el.getAttributeNames().reduce((hash, name) => {
				if (name in deniedAttributes) { return hash; }
				hash[name] = undefined;
				return hash;
			}, {});
			attrs = {
				...toRemove,
				attrs
			}
		}
		Object.keys(attrs).forEach(name => {
			this.setElAttribute(name, attrs[name], el);
		});
	},
	setElAttribute(name, value, el) {
		if (name in deniedAttributes) { return; }
		el = el || this.el;
		if (value == null || value === '') {
			el.removeAttribute(name);
		} else {
			el.setAttribute(name, value);
		}
	},
	updateElAttributes() {
		const attrs = this.getElAttributes();
		this.setElAttributes(attrs);
	},
	addElAttribute(name, value) {
		if (!this._runtimeElAttributes) {
			this._runtimeElAttributes = {};
		}
		this._runtimeElAttributes[name] = value;
	},
	initializeHtmlAttributesMixin() {
		if (this._htmlAttributesMixinInitialized) { return; }
		this._htmlAttributesMixinInitialized = true;
		this.updateElAttributes();
		if (this.getOption('dynamicAttributesAllowed', true)) {
			this.on('before:render', () => this.updateElAttributes());
			if (this.model) {
				this.listenTo(this.model, 'change refresh:view', () => this.updateElAttributes());
			}
		}
	}
}


function invokeProperties(view, attrs) {
	if (!attrs) { return {}; }
	return Object.keys(attrs).reduce((hash, key) => {
		hash[key] = invokeValue(attrs[key], view, view);
		return hash;
	}, {});
}