﻿//define('svc-deals-closed', ['bus','appModule', 'link', 'm-deals', 'svc-deals-list-layout2'], function (Bus, Module, link, dealsNs, Layout) {});

import Bus from 'bus';
import Module from 'appModule';
import link from 'link';
import Layout from '../list/layout2';

var module = Module.content({
	name: 'deal-closed',
	label: 'завершённые процессы',
	urlKey: 'dealsClosed',
	routeData: function () {
		return link.route('dealsClosed');
	},
	onBeforeRun: function () {
		var Col = Bus.Models.request('dealsClosed');
		var col = new Col();

		if (this.query.emp)
			col.setEmployeeId(this.query.emp);
		
		this.addRunPromise(col.loudFetch());
		this.addRunArgument(col);

	},
	onRun: function (col) {

		this.createAndShow(Layout, this.label, { collection: col, dealsType: 'closed', itemFilter: function (model) { return !model.isNotCompleted(); } });

	}
});


export default module;