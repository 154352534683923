// import appData from 'm/appData';
// import busData from 'bus/data';
import svcRadio from 'bus/svc';
import { BuildingsRest } from '../../../realties/models';


export const main = {
	name: {
		caption: 'название',
	},
	market: {
		caption: 'рынок',
		valueType: 'enum',
		valueSubType: 'realtyMarkets'
	},
	operation: {
		caption: 'операция',
		valueType: 'enum',
		valueSubType: 'realtyOperations'
	},
	square: {
		caption: 'метраж',
		valueType: 'number',
		modelType: 'range'
	},
	price: {
		caption: 'стоимость метра',
		valueType: 'number',
		modelType: 'range'
	},

};
export const entry = {
	offerId: {
		caption: 'предложение',
	},
	realtyId: {
		caption: 'здание',
		controlType: 'selectRest',
		sourceValues: BuildingsRest.create(),
		display: (v, ...rest) => {
			console.log('::::', v, rest)
			return v & v.name
		}
	},
	geography: {
		caption: 'география'
	}
};
export const additional = {
	additional: {
		caption: 'дополнительно',
	},
	source: {
		caption: 'площадка',
	},
	responsibleId: {
		caption: 'ответственный',
		controlType: 'selectEmployees',
		sourceValues: () => {
			console.log('=====/r/n-----/r/n......')
			const col = svcRadio.request('employeesSelect');
			console.warn(col);
			return col;
		},
		display: v => svcRadio.request('employeeName', v)
	}
};



const buildingSchema = {

	"тфьу": {
		caption: 'Год постройки',
		valueType: 'number',
	},
	"building.rebuildYear": {
		caption: 'Год реконструкции',
		valueType: 'number',
	},
	"building.floors": {
		caption: 'Этажность',
		valueType: 'number',
	},
	"building.squareTotal": {
		caption: 'Общая площадь',
		valueType: 'number',
	},
	"building.squareUsefull": {
		caption: 'Полезная площадь',
		valueType: 'number',
	},
	"inner.powerType": {
		caption: 'Электроснабжение',
		valueType: 'enum',
		valueSubType: 'buildingPowerSupplies'
	},

	"inner.powerAmount": {
		caption: 'Мощность электроснабжения, в кВт',
		valueType: 'number',
	},
	"inner.powerAmountPerMeter": {
		caption: 'Мощность электроснабжения на квадратный метр, в Вт',
		valueType: 'number',
	},
	"inner.autonomPowerSupliesCount": {
		caption: 'Количество автономных генераторов электроснабжения',
		valueType: 'number',
		
	},
	"inner.waterSupply": {
		caption: 'Водоснабжение',
		valueType: 'enum',
		valueSubType: 'buildingWaterSupplies'
	},
	"inner.heating": {
		caption: 'Отопление',
		valueType: 'enum',
		valueSubType: 'buildingHeatingTypes'
	},
	"inner.ventilation": {
		caption: 'Вентиляция',
		valueType: 'enum',
		valueSubType: 'realtyVentilationTypes'
	},
	"inner.fireSafety": {
		caption: 'Пожарная безопасность',
		valueType: 'enum',
		valueSubType: 'fireSafety'
	},
	"inner.airConditioning": {
		caption: 'Кондиционирование',
		valueType: 'enum',
		valueSubType: 'realtyAirConditioningTypes'
	},
	"inner.ceilingHeight": {
		caption: 'Высота потолков',
		valueType: 'number',
	},
	"inner.ceilingHeightWork": {
		caption: 'Рабочая высота потолков',
		valueType: 'number',
		
	},
	"inner.floorLoad": {
		caption: 'Максимальная нагрузка на пол',
		valueType: 'number',
		
	},
	"entry.type": {
		caption: 'Безопасность',
		valueType: 'enum',
		valueSubType: 'realtyEntryOptions',
		multiple: true,
	},
	"communicate.phoneProviders": {
		caption: 'Количество провайдеров телефонии',
		valueType: 'number',
		
	},
	"communicate.internetProviders": {
		caption: 'Количество провайдеров интернет',
		valueType: 'number',
		
	},
	"features.type": {
		caption: 'Особенности',
		valueType: 'enum',
		valueSubType: 'realtyFeaturesOptions',
		multiple: true,
	},
	"parking.type": {
		caption: 'Парковка',
		valueType: 'enum',
		valueSubType: 'realtyParkings',
		multiple: true,
	},
	"parking.placesTotal": {
		caption: 'Вместимость стоянки (количество машиномест)',
		valueType: 'number',
		
	},
	"lift.passengerLifts": {
		caption: 'Количество пассажирских лифтов',
		valueType: 'number',
		
	},
	"lift.freightLifts": {
		caption: 'Количество грузовых лифтов',
		valueType: 'number',
		
	},
	"lift.freightTonnage": {
		caption: 'Тоннаж грузового лифта',
		valueType: 'number',
		
	}
}