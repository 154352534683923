import { View } from 'core';
import appCfg from 'app-config';
import { smartOpen } from 'utils';
import { editBuildingCharacteristics } from './edit';

export const BlockErrorView = View.extend({
    tagName: 'li',
    template: '<%= error %> <button class="btn btn-default">исправить</button>',
    getErrorText() {
        let { message, dataType, property }= this.model.attributes;
        if (message) {
            return message;
        } else {
            return 'ошибка в описании: ' + property;
        }

    },
    templateContext() {
        return {
            error: this.getErrorText()
        }
    },
    events: {
        'click button':'fixError'
    },
    fixError() {

        let action = this._getEditAction();
        if (!action) { 
            return; 
        }

        action();

        

    },
    _getEditAction() {


        let blockModel = this.getOption('blockModel');
        let requestModel = this.getOption('requestModel')
        const errorProperty = this.model.get('property');
        const data = this.getOption('data');

        switch(errorProperty) {
            case 'building.buildingInfo.characteristics':
                return editBuildingCharacteristics({ error: this.model, block: blockModel, data, requestModel });
        }

        console.log('error', this.model.attributes);
        console.log('block', blockModel.attributes);

        const url = this._getEditUrl();
        if (url) {
            return () => smartOpen(url, { ctrlKey: true });
        }

    },
	_getEditUrl() {
        
        let { message, dataType, property }= this.model.attributes;

        const datatypes = {
            realty:1,
            object:1,
            realtyObject:1,
            offer:1,
            realtyOffer: 1,
        }
        if (dataType in datatypes === false) { return; }

        let type = this._editUrlTypeMapper[dataType];
        let idKey;
		switch(dataType) {
			case 'realty':
				idKey = 'realtyId';
                break;
			case 'object':
			case 'realtyObject':
			case 'offer':
			case 'realtyOffer':				
				idKey = 'realtyObjectId';
                break;

		}

        let blockModel = this.getOption('blockModel');
        let blockId = blockModel.get('blockId');
        let id = blockModel.attributes[idKey];
        let url = appCfg.urls.url(`actualization/${type}-list/${id}/edit`);
        if (['area_photo', 'area_schema', 'building_photo'].indexOf(blockId) > -1) {
            url += '#media';
        }
        return url;
	},
	_editUrlTypeMapper: {
		realty: 'realties',
		object: 'objects',
		realtyObject: 'objects',
		offer: 'objects',
		realtyOffer: 'objects',
	}
});