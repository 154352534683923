import { Collection } from 'core';
import { ObjectModel } from './ObjectModel';
export const ObjectsCollection = Collection.extend({
	constructor: function(data, options = {}) {
		this.parent = options.parent;
		Collection.apply(this, arguments);
	},
	model: ObjectModel,
	getAllOffersHash() {
		return this.reduce((memo, obj) => obj.getOffers().reduce((offmemo, off) => {
			memo[off.id] = off;
			return memo;
		}, memo), {});
	}
});