module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="popover '+
((__t=( popoverClass ))==null?'':__t)+
'" role="tooltip">\n	<div class="arrow"></div>\n	<h3 class="popover-title"></h3>\n	<div class="popover-content '+
((__t=( contentClass || ''))==null?'':__t)+
'"></div>\n</div>';
}
return __p;
};
