﻿//define('m-tasks', ['m-tasks-task', 'm-tasks-tasks', 'm-tasks-entityTasks'], function (Task, Tasks, EntityTasks) {});

import TaskModel from './task';
import TasksCollection from './tasks';
import EntityTasks from './entityTasks';

var ns = {};

ns.TaskModel = TaskModel;
ns.TasksCollection = TasksCollection;
ns.EntityTasksCollection = EntityTasks;
export default ns;
export {
	TaskModel,
	TasksCollection
}
