﻿//define('svc-tasks-my', ['appModule', 'link', 'svc-tasks-list-layout', 'm-tasks'], function (Module, link, Layout, tasksNs) {});

import Module from 'appModule';
import link from 'link';
import Layout from '../_list/layout';
// import tasksNs from 'm/tasks';

import { TasksCollection } from 'm/tasks';

var module = Module.content({
	auth: true,
	name: 'tasks-my',
	label: 'Мои задачи',
	routeData: () => link.route('tasksMy'),
	urlKey: 'tasksMy',
	onBeforeRun: function () {
		var col = TasksCollection.my(this.query.emp); //tasksNs.TasksCollection.my(this.query.emp);
		let promise = col.loudFetch();
		this.addRunPromise(promise);
		this.addRunArgument(col);
		//console.log('br', this._runPromises);
	},
	onRun: function (col) {
		console.log('tasks my started');
		const opts = { 
			collection: col, 
			tasksType: 'my', 
			itemFilter: m => m.state('active'),
		}
		var result = this.createAndShow(Layout, this.label, opts, { navLinks: this.navLinks() });
		// console.log('res', result);
	}
});

export default module;
