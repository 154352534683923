import { Radio } from 'vendors';
import { Collection as BbeCollection } from './collection';


function Loadmoreable(models, options)
{
	this.fetchResult = {};
}

function onFetchDone(special)
{
	this.trigger('fetched:' + special, this.fetchResult, this);
	this.trigger('fetched', special, this.fetchResult, this);

	if (this.radio) {
		this.radio.reply('result', _.extend({}, this.fetchResult));
		this.radio.trigger('fetched',special);
	}

}

export const LoadmoreableCollection = BbeCollection.extend({
	constructor: function (models, options) {
		Loadmoreable.apply(this, arguments);
		BbeCollection.apply(this, arguments);
		this.uid = _.uniqueId("lmc");
		//this.radio = Bbe[.]Radio.channel('loadmore:' + this.uid);
		this.radio = Radio.channel('loadmore:' + this.uid);
		//this.listenTo(this.radio, 'filters:apply', this.applyAndFetch);
		this.autorefetch = true;
		//console.log('Loadmoreable Collection init');
	},
	parse: function (data) {
		var items = data.items || [];

		this.fetchResult = this.fetchResult || {};
		this.fetchResult.founded = data.founded || 0;
		this.fetchResult.returned = data.returned || items.length || 0;
		this.fetchResult.skiped = data.skiped || 0;

		this.fetchedTimes = (this.fetchedTimes || 0) + 1;

		return items;
	},
	isLoaded: function () {
		return this.fetchResult && this.fetchResult.skiped + this.fetchResult.returned >= this.fetchResult.founded;
	},
	getPaginatorData: function () {
		var paginatorKey = 'result';
		var fr = this.fetchResult || {};
		var skip = (fr.skiped || 0) + (fr.returned || 0);
		var res = {};
		if (this.fetchedTimes)
			res[paginatorKey + '.skip'] = skip;
		if (fr.order)
			res[paginatorKey + '.order'] = fr.order;

		return res;
	},
	//getLoadMoreData: function () {
	//	var filtersData = normalizeData(this.fetchFilters);
		

	//	var dataHash = _.extend({}, this.getPaginatorData(), filtersData);
	//	var res = { data: dataHash };

	//	console.log('==-', dataHash);

	//	return res;
	//},
	getFetchOptions: function () {
		var args = [].slice.call(arguments);
		args.unshift({});
		args.push(this.getLoadMoreData());
		return _.extend.apply(_,args);
	},
	load: function (opts) {
		var col = this;
		var options = this.getFetchOptions(opts || {}, { reset: true });			
		return this.loudFetch(options).done(function () {
			onFetchDone.call(col, 'first');
		});
	},
	loadMore: function (opts) {
		if (!this.fetchedTimes) return this.load.apply(this, arguments);
		var col = this;
		var options = this.getFetchOptions(opts, { update: true, remove: false });
		return this.loudFetch(options).done(function () {
			onFetchDone.call(col, 'next');
		});
	},
	/*
	applyAndFetch: function () {
		console.log('applyAndFetch',this);
		var newFetchFilters = this.radio.request('filters');
		var newFetchFiltersHash = (newFetchFilters && newFetchFilters.toHash()) || {};
		if (newFetchFilters && !isSameData(this.fetchFilters, newFetchFiltersHash)) {
			this.fetchFilters = newFetchFiltersHash;
			this.fetchedTimes = 0;
		}

		var order = this.radio.request('order');
		if (order && order != this.fetchResult.order) {
			this.fetchResult.order = options.order;
			this.fetchedTimes = 0;
		}

		this.fetchPromise = this.loadMore();
		console.log('done apply');
	}
	*/
});


