import { CollectionView, View, Collection } from 'core';
import { debounce } from 'vendors';
import { tryAsync } from 'utils';
import './search.less';
const SuggestItem = View.extend({
	className: 'suggest-item',
	template: '<div class="name"><%= g("properties.name") %></div><div class="desc"><%= g("properties.description") %></div>',
	templateContext() {
		return {
			g: k => this.model.smartGet(k)
		}
	},
	triggers:{
		'click':'click'
	}
});

export const SearchSuggest = CollectionView.extend({
	childViewEventPrefix: false,
	className: 'yandex-search-suggestions',
	constructor: function() {
		CollectionView.apply(this, arguments);
		this.afterInitialize();
	},	
	// template: false,
	childView: SuggestItem,
	initialize(options) {
		this.mergeOptions(options, ['inputEl','controlEl']);
		this.collection = new Collection();
		const inputHandler = debounce(this.inputHandler.bind(this), 500);
		this.inputEl.addEventListener('keyup', inputHandler);
	},
	inputHandler(event) {
		const text = event.target.value;
		if (text.length < 3) {
			this.clearSuggest();
		} else {
			this.requestSuggest(text);
		}
	},
	clearSuggest() {
		this.collection.reset();
	},	
	async requestSuggest(text) {
		const options = Object.assign({ text }, this.getOption('searchOptions'));
		const res = await tryAsync(() => ymaps3.search(options));
		if (res.ok) {
			this.collection.set(res.value);
		}
	},
	afterInitialize() {
		const parent = this.controlEl || this.inputEl.closest('.map-control-wrapper');
		parent.append(this.el);
		this.render();
	},
	childViewEvents: {
		click(view) {
			const coords = view.model.smartGet('geometry.coordinates');
			console.log('coords', coords, view.model);
			this.triggerMethod('new:coordinates', coords, view.model);
			this.collection.reset();
		}
	}
})
