﻿//define('realties-building-images', ['link'], function (link) {});

import link from 'link';

import imgtemplate from './image.html';
import template from './images.html';

import { BbeCollection } from 'core/bbe';

import { MneView, MneNextCollectionView } from 'core/mne';

var ImageView = MneView.extend({
	className:'block offset-b',
	//template: '#tmpl-service-realties-building-image',
	template: imgtemplate,
	templateContext: function () {
		var id = this.model.get('id');
		var _thumbUrl = link.img(id, 'w300-h200-c-rbc');
		return {
			_thumbUrl: _thumbUrl,
		};
	}
});

var ImagesView = MneNextCollectionView.extend({
	childView: ImageView,
	viewFilter: function (view) {
		return view.model.get('mediaType') == 'image';
	},
});


var Layout = MneView.extend({
	//template: '#tmpl-service-realties-building-images',
	template,
	regions: {
		'medias': {el:'.medias-region', replaceElement:true }
	},
	onRender: function () {
		var col = new BbeCollection(this.getOption('medias'));
		var view = new ImagesView({ collection: col });
		this.showChildView('medias', view);
	},
});

export default Layout