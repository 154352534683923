import { View } from 'core';
import { getValueByPath } from 'utils';
import filters from '../filters';
import htmllib from '_libs/html';
import { _, moment } from 'vendors';
import { objectProperties } from './objectProperties';
import { HamburgerView } from '../../../../../coms/ui/HamburgerView';
import { UiMoleculeView } from '../../../../../coms/ui/UiMoleculeView';
import { UiAtomView } from '../../../../../coms/ui/UiAtomView';
import { objectSchema } from './objectSchema';
import { propertySchemaApi } from '../../../../../apis/schema';
import { UiSchemaAtomView, UiSchemaMoleculeView } from '../../../../../coms/schema';
import { ActionsView, LastModifiedView } from './object-property-views';
import { innerBus } from '../innerBus';
import { smartOpen } from 'utils';

import { offerProperties } from '../offerProperties';
import helpers from '../../helpers';
import SchemaEdit from 'ui/controls/schemaEdit';
import refs from 'references';
import { patchRealtyObject } from 'mods/realties/objects/crud';

const { html, span } = htmllib;

const props = objectProperties;

const buildValueView = function({ prop, value })
{
	value = value && value.toString() || '';
	return new View({ className: prop, template: _.template(value) });
}

const modelSchemaMixin = {
	getModelValue(key) {
		return this.model.getValueByPath(key);
	},
	getModelSchema() {
		return this.getOption('modelSchema', true);
	},
	display(property) {
		const modelSchema = this.getModelSchema();
		// console.log(property, this.model.attributes);
		return propertySchemaApi.displayValue(property, this.model, modelSchema);
	},
	value(property) {
		const modelSchema = this.getModelSchema();
		// console.log(property, this.model.attributes);
		return propertySchemaApi.getValue(property, this.model, modelSchema);
	}	
}

const regions = _.reduce(props, (memo, cntx, prop) => { 
	memo[prop] = '.' + prop; 
	return memo; 
} , {});

export const _BaseObjectView = View.extend({
	baseClassName: 'realty-objectoffer',
	// behaviors: [beh.DynamicClass],
	classNames: [
		v => v.getModelValue('offer.status'),
		v => v.getModelValue('offer.operation'),
		v => v.isSelected() ? 'selected' : '',
	],
	
	...modelSchemaMixin,

	isSelected() {},
	getModelValue(key) {
		return this.model.getValueByPath(key);
	},
	dynamicClass() {
		
		let res = [];
		// _.each(props, (cntx, prop) => {
		// 	if (cntx.hideOnFilter) {
		// 		//let filterPropsToListen = [];
		// 		if (_.every(cntx.hideOnFilter, (filled, key) => {
		// 			let fv = filters.get(key);
		// 			let res = (fv != null && filled) || (fv == null && !filled);
		// 			return res;
		// 		})) {
		// 			res.push('no-' + prop);
		// 			return;
		// 		}
		// 	}
		// });
		let status = this.model.getValueByPath('offer.status');
		if (status) {
			res.push(status);
		}
		let operation = this.model.getValueByPath('offer.operation');
		if (operation) {
			res.push(operation);
		}			
		if (this.isSelected()) {
			res.push('selected');
		}
		return res.join(' ');
	},
	initialize(options) {

		

		this.mergeOptions(options, ['isSelected']);
		this.listenTo(filters, 'change', this.onFiltersChange)
		this.listenTo(this.model, 'selected unselected', this._updateClassName);
		this.listenTo(this.model, 'refresh', this.render);
		this.listenTo(this.model, 'not:exists', () => {
			//console.log('> destro');
			this.model.destroy();
		});

		// throw new Error('ИСправь динамический класс!');

	},
	onFiltersChange() {
		// console.log('chpock', this.isRendered(), this.isAttached());
		this._updateClassName();
	},
	_updateClassName() {
		if (this.isRendered() && this.isAttached()) {
			this.triggerMethod('refresh:css:class');
		}
		
	},
	template: _.template(
		html(..._.map(props, (cntx, prop) => span(prop)))
	),

	regions,

	onRender() {
		_.each(props, (cntx, prop) => {
			this.showProp(prop, cntx);
		});
	},

	getPropValue(context) {
		let { entity, path, display, postfix } = context;
		let model = entity == 'offer' ? this.model.get('offer') : this.model.get('object');
		if (!model || !path) return;
		//console.log('~ ~ M O D E L ~ ~', model);
		let value = getValueByPath(model, path);
		if (display) {
			value = display(value);
		}
		if (value && postfix) {
			value = value + postfix;
		}
		return value;
	},

	showProp(prop, context) {
		// if (context.hideOnFilter) {
		// 	//let filterPropsToListen = [];
		// 	if (_.every(context.hideOnFilter, (filled, key) => {
		// 		let fv = filters.get(key);
		// 		let res = (fv != null && filled) || (fv == null && !filled);
		// 		console.log('HIDE?', res, fv, filled);
		// 		return res;
		// 	})) {
		// 		return;
		// 	}
		// }
		let region = this.getRegion(prop);
		let value = this.getPropValue(context)
		let build = context.buildView || buildValueView;
		//let view = new MneView({ template: _.template(value ? value.toString() : '')});
		region.show(build({ prop, context, value, parentView: this }), { replaceElement: true });
	},


});


const LastModifiedView2 = UiSchemaAtomView.extend({
	__template: _.template('<span class="<%= howLongAgo %>" title="последнее изменение: <%= modified %>"><%= date %></span>'),
	getPotentialDate() {
		let potentialDate;

		const m = this.model;
		let last = m.getValueByPath('offer.actualize.last')
								|| m.getValueByPath('offer.modified')
								|| m.getValueByPath('object.actualize.last')
								|| m.getValueByPath('object.modified');

		const dates = [
				new Date(m.getValueByPath('offer.actualize.last'))
			, new Date(m.getValueByPath('offer.modified'))
			, new Date(m.getValueByPath('object.actualize.last'))
			, new Date(m.getValueByPath('object.modified'))
		];
		dates.sort((a,b) => b.valueOf() - a.valueOf());
		last = dates.filter(f => !isNaN(f.valueOf()))[0];
		if (last) {
			return last;
		}
		console.warn('?? ', last);

		// if (last && _.isString(last)) {
		// 	potentialDate = new Date(last);
		// 	if (isNaN(potentialDate.valueOf())) {
		// 		potentialDate = undefined;
		// 	}
		// }
		// return potentialDate;
	},
	getModifiedDate() {
		if ('_modifiedDate' in this) {
			return this._modifiedDate;
		}
		this._modifiedDate = this.getPotentialDate();
		return this._modifiedDate;
	},
	getDisplayDate(val) {
		if (!arguments.length) {
			val = this.getModifiedDate();
		}
		console.log(':: last', val, this.el);
		//let ts = this.getModifiedDate();
		if (!val) {
			return "&mdash;";
		}
		return moment(val).fromNow();
	},
	___templateContext() {
		let howLongAgo = 'never';
		let modified = this.getModifiedDate();
		let date = this.getDisplayDate(modified);
		if (_.isDate(modified)) {
			let ts = modified;
			modified = modified.toLocaleString('ru');
			let today = (new Date()).toDay();
			let twoweeks = (new Date()).toDay();
			twoweeks.addDays(-14);

			if (ts.valueOf() >= today.valueOf()) {
				howLongAgo = 'today';
			} else if (ts.valueOf() >= twoweeks.valueOf()) {
				howLongAgo = 'twoweeks';
			} else {
				howLongAgo = 'longago';
			}				
		} else {

			modified = '';
		}
		
		return {
			modified,
			date,
			howLongAgo
		}
	},

	thisClassName: 'modified',

	classNames: [
		v => v.getHowLongAgo()
	],

	getHowLongAgo() {
		let howLongAgo = '';
		let ts = this.getModifiedDate();
		if (!ts) { return howLongAgo; }
		let today = (new Date()).toDay();
		let twoweeks = (new Date()).toDay();
		twoweeks.addDays(-14);
		let month = (new Date()).toDay();
		month.addDays(-30);

		const v = v => v.valueOf();

		if (v(ts) < v(month)) {
			howLongAgo = 'longago';
		} else if (v(ts) < v(twoweeks)) {
			howLongAgo = 'twoweeks';
		} else {
			howLongAgo = 'fresh';
		}


		// if (ts.valueOf() >= today.valueOf()) {
		// 	howLongAgo = 'today';
		// } else if (ts.valueOf() >= twoweeks.valueOf()) {
		// 	howLongAgo = 'twoweeks';
		// } else {
		// 	howLongAgo = 'longago';
		// }
		return howLongAgo;
	},

	getModifier() {
		const last = getValueByPath(this.model, 'offer.actualize.lastEmployeeName')
		 							|| getValueByPath(this.model, 'object.actualize.lastEmployeeName');
		return last;
	},

	tagTitle: v => `последнее изменение: ${v.getModifiedDate()}`,

	text: v => v.getDisplayDate(),
	bottomText: v => v.getModifier(),

});

function editObjectInBlueAdmin (view, event) {
	event.preventDefault();
	event.stopPropagation();
	const id = getObjectId(this);
	const url = `http://adm.nrg.plus/rlt/objects/${id}`;
	smartOpen(url, { ctrlKey: true });
}

function editObjectInThisAdmin (view, event) {
	event.preventDefault();
	event.stopPropagation();
	const id = getObjectId(this);
	const url = `actualization/objects-list/${id}/edit#props`;
	
	smartOpen(url, { ctrlKey: event.ctrlKey !== true, isServiceUrl: true });	
}

function editMainOptions (view, event) {

	let properties = {
		'info.floor': {
			caption: 'этаж',
			emptyText: 'не установлен',
			modelType: 'single',
			valueType: 'number',
			display: v => v != null ? v + ' эт.' : '',
			// validate: required,	
		},
		'info.roomNumber': {
			caption: 'номер кабинета',
			emptyText: 'не установлен',
			modelType: 'single',
			valueType: 'string',
			//display: v => v ? v + ' эт.' : '',
			//validate: required,	
		},
		// 'info.squareOffer': {
		// 	caption: 'площадь',
		// 	emptyText: 'не указана',
		// 	modelType: 'single',
		// 	valueType: 'number',
		// 	display: v => v != null ? v + ' кв. м' : '',
		// 	// validate: required,	
		// },
		'info.purposes': {
			caption: 'возможные назначения',
			emptyText: 'не выбраны',
			// display: v => {
			// 	console.log('v::', v);
			// 	let gg = (v || []).map(mv => refs.enum('realtyPurposes', mv));
			// 	return gg.join(', ');
			// },
			sourceValues: refs.enum('realtyPurposes'),
			modelType: 'single',
			valueType: 'enum',
			//flattenValues: true,
			multiple: true,
			// validate: required,	
		},		
		'info.currentPurpose': {
			caption: 'текущее назначение',
			emptyText: 'не выбрано',
			//display: v => v ? refs.enum('realtyPurposes',v) : undefined,
			sourceValues: refs.enum('realtyPurposes'),
			modelType: 'single',
			valueType: 'enum',
			//flattenValues: true,
			multiple: false,
		},

	}
	const valuesHash = view.model.toJSON().object;
	let options = {
		header: 'изменение характеристик',
		applyText: 'сохранить',
		beforeApply: (data) => {
			return patchRealtyObject(valuesHash.id, data).then((data) => {
				innerBus.trigger('main:re:render');
			});
		},
		valuesHash,
		// beforeApply: (value) => {
		// 	return this.takeAction('create:object', 'добавление помещени' + (many ? 'й' : 'я'), [], value).then(() => this.model.fetch());
		// },
	}
	console.error(options.valuesHash);
	SchemaEdit.inModal(properties, options);

}

export const BaseObjectView = UiSchemaMoleculeView.extend({
	baseClassName: 'realty-objectoffer',
	childView: UiSchemaAtomView,

	modelSchema: objectSchema,

	classNames: [
		v => v.getModelValue('offer.status'),
		v => v.getModelValue('offer.operation'),
		v => v.isSelected() ? 'selected' : '',
	],

	isSelected() {},

	...modelSchemaMixin,

	initialize(options) {
		// console.log('v.cid', this.cid, this.model.attributes);
		this.mergeOptions(options, ['isSelected']);
		this.listenTo(filters, 'change', this._updateElClassName)
		this.listenTo(this.model, 'change selected unselected', this._updateElClassName);
		this.listenTo(this.model, 'refresh', () => this.render());
		this.listenTo(this.model, 'not:exists', () => this.model.destroy());
	},

	childViewOptions () {
		return {
			showTop: true,
			showSmall: true,
			modelSchema: this.getModelSchema(),
			parentView: this,
		}
	},

	leftItems:[
		// { 
		// 	thisClassName: 'modified',
		// 	class: LastModifiedView 
		// },
		LastModifiedView2,
		b => b.hasOffer() ? {
			thisClassName: 'id',
			text: v => v.display('offer.id'),
			smallText: v => v.display('operationMarket'),
			//topText: v => `<a href="http://adm.nrg.plus/rlt/objects/${getObjectId(v)}" target="_blank">в синюю</a>`,
			bottomText: v => v.display('offer.status'),
			// clickAction: editObjectInBlueAdmin,
			
			// (view, event) {
			// 	event.preventDefault();
			// 	event.stopPropagation();
			// 	const id = getObjectId(this);
			// 	const url = `http://adm.nrg.plus/rlt/objects/${id}`;
			// 	smartOpen(url, { ctrlKey: true });
			// }
		} : {
			text: 'нет предложений',
			clickAction: editObjectInBlueAdmin,
		},

		{
			thisClassName: 'info',
			text: v => [v.display('floor'), v.display('square')].join('&nbsp;'),
			smallText: v => '<span class="postfix">номер: </span>' + v.display('object.info.roomNumber'),
			clickAction: editObjectInThisAdmin
		},
		b => b.hasOffer() ? {
			thisClassName: 'price',
			text: v => v.display('priceMeterPostfix'),
			smallText: v => v.display('priceSquarePostfix'),	
			bottomText: v => v.display('priceDetailsWords') || '&ndash;',
			topText: 'стоимость',
			clickAction: 'offer:price:edit', // (v,e) => v.trigger('click:action', 'offer:price:edit', e)
		} : { thisClassName: 'price' },
		b => b.hasOffer() ? {
			// showTop: false,
			// showSmall: true,
			thisClassName: 'contract',
			text: v => v.display('offer.forAgent.income') + '%',
			smallText: v => v.display('offer.forAgent.contractType'),
			bottomText: v => v.display('offer.forCustomer.taxType'),
			topText: 'условия',
			clickAction: 'commercial:edit',
		} : { thisClassName: 'contract' },
		{
			// showTop: false,
			// showSmall: true,
			thisClassName: 'purposes',
			smallText: v => v.display('object.info.purposes'),
			text: v => v.display('object.info.currentPurpose') || '&ndash;',
			topText: 'назначения',
			clickAction: editMainOptions
		}
	],
	rightItems: [
		{
			class: ActionsView,
			baseClassName: 'elastic',
			thisClassName: 'actions'
		}
	],
	childViewEvents: {
		'click:action'(action, event) {
			if (action === 'select') { return; }
			const context = {
				view: this,
				model: this.model,
				// propView: this,
				object: this.model.get('object'),
				offer: this.model.get('offer'),
			}
			innerBus.triggerMethod('action', action, context);
			console.log('triggered');
		}
	},
	triggerAction(action, event) {
		if (action != 'select') {
			let context = this.getContext();
			innerBus.triggerMethod('action', action, context);
		} else {
			this.triggerToggleSelection(event);
		}
	},
	hasOffer() {
		return this.model.get('offer') != null;
	}
});


function getObjectId(view) {
	return getValueByPath(view.model, 'object.id');
}