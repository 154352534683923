module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="top-static">\n	<h3>'+
((__t=(header))==null?'':__t)+
'</h3>\n	<div class="description"></div>\n</div>\n<div class="auto">\n	<div class="control-region"></div>\n	<div class="predefined-region"></div>\n</div>\n<div class="actions">\n	<button class="ok">'+
((__t=(okLabel))==null?'':__t)+
'</button>\n	<button class="cancel transparent">'+
((__t=(cancelLabel))==null?'':__t)+
'</button>\n	<button class="reset transparent">'+
((__t=(resetLabel))==null?'':__t)+
'</button>\n	';
 if(showApply) {
__p+='<button class="apply transparent">'+
((__t=(applyLabel))==null?'':__t)+
'</button>';
 } 
__p+='\n</div>\n';
}
return __p;
};
