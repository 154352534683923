﻿//define('realties-offers-views-inList2', ['references', 'realties-models', 'link', 'behaviors'], function (refs, rltNs, link, beh) {});

//let refs, rltNs, link, beh;

import beh from 'behaviors';

import inListTemplate from './inList2.html';
import './deal-offer.less';
import './offer-list-item.less';
import './offer-view-list.less';

import offices from './popover/offices.html';
import retail from './popover/retail.html';
import warehouses from './popover/warehouses.html';

import { simplePopupMixin, popupView } from 'apis/popup';

import { PopupOfferPropertiesView } from 'mods/realties/offers/popup-properties';

import { MneView } from 'core/mne';

import './popover/popover-offer-details.less';

const inListTemplates = {
	offices,
	retail,
	warehouses
}


var PopoverView = MneView.extend({
	getTemplate: function () {
		let type = this.model.get('market');
		return inListTemplates[type];
		//return '#tmpl-service-realties-offers-views-popover-' + this.model.get('market');
	},
	events: {
		'click .metro': function () {
			var mId = this.model.getMetroId();
			if (mId && this.parent)
				this.parent.trigger('metro:station:click', mId);
		},
		'click .address': function () {
			var address = this.model.getAddress();
			if (address && this.parent)
				this.parent.trigger('address:click', address);
		},

	}
});

var OfferPopoverBehavior = beh.BootstrapPopover.extend({
	content: PopoverView,
	contentOptions: function () {
		return {
			model: this.view.model
		}
	},
	popoverTemplateContext: function () {
		return { popoverClass: 'popover-flat popover-offer-details bg-' + this.view.model.get('operation') };
	},
	placement: 'right auto',
	container: 'body',
});



var View = MneView.extend({

	behaviors: [
		beh.DynamicClass, 
		beh.SelectedForActions.ChildView.extend({ triggerSelector: '.toggle-select > button' }), 
		//OfferPopoverBehavior, 
		beh.ModelActionsInPopup
	],

	className: function () {
		var cls = [
			'offer-list-item2'
			, 'mo-' + this.model.get('operation')
			, 'mt-' + this.model.get('market')
			, 'status-' + this.model.get('status')
		];
		return cls.join(' ');
	},

	/*
	events: {
		'mouseenter': function (e) {
			e.stopPropagation();
			if (this._hover) return;
			this._hover = true;
			this.triggerMethod('hover', this);
			var _this = this;
			this.once('unhover', function () {
				_this._hover = false;
			});
		},
		'mouseleave': function (e) {
			e.stopPropagation();
			if (!this._hover) return;
			this.triggerMethod('unhover');
		},
	},
	onHover: function () {
		
		let address = this.model.getAddress();
		if (!address) return;

		this._hoverTimeout = setTimeout(() => {
			this.trigger('address:hover', address);
		}, 1000);

	},
	onUnhover: function () {
		var address = this.model.getAddress();
		if (!address) return;
		clearTimeout(this._hoverTimeout);
		this.trigger('address:unhover', address);
	},
	*/
	template: inListTemplate,

	...simplePopupMixin,

	initialize() {
		this.initializePopupMixin();
	},

	hoverPopup: {
		autoUpdateEnabled: true,
		openEvent: 'mouseenter',
		removeDelay: 500,
		wrapContent: true,
		popupViewOptions: {
			thisClassName: 'offer-properties-popup'
		},
		view: {
			class: PopupOfferPropertiesView,
		},
		useFlip: true,
		positionOptions: {
			placement: 'left-start',
		}
	},
	// cclickPopup: {
	// 	autoUpdateEnabled: true,
	// 	openEvent: 'click',
	// 	//referenceElement: 'event',
	// 	//openSelector: '.block.selected-pane',
	// 	//removeBehavior: 'detach',
	// 	//removeDelay: 50000,


	// 	wrapContent: true,

	// 	popupViewOptions: {
	// 		//maxWidth: '150px',
	// 		thisClassName: 'offer-actions-popup'
	// 	},

	// 	view: {
	// 		class: MneView,
	// 		template: _.template('-chick-')
	// 	},
	// 	useFlip: true,
	// 	// autoPlacement:{
	// 	// 	autoAlignment: true,
	// 	// 	//allowedPlacements: ['bottom', 'bottom', 'bottom']
	// 	// 	//allowedPlacements: ['left-start', 'left-end']
	// 	// 	//allowedPlacements: ['left', 'bottom', 'top']
	// 	// },
	// 	positionOptions: {
	// 		placement: 'left-start',
	// 	}

	// }

});

export default View;