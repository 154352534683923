﻿//define('assets-underscore', ['assets-underscore-equalValues', 'assets-underscore-camelCase'], function () {});
import { _ } from 'vendors';
import './equalValues'; // 'assets-underscore-equalValues'
import './camelCase'; // 'assets-underscore-camelCase'

//WARNING: this import was not found and it was without define(...)
import './eResult';

_.mixin({
	rangeLabel(val, options) {
		options || (options = {});
		var empty = options.empty || '';
		var maxFractions = options.maxFractions || 0;
		var postfix = options.postfix || '';

		if (val == null)
			return empty;

		if (typeof val == 'object') {
			var res = [];
			var prefix = '';
			if (val.from) {
				prefix = 'от ';
				res.push(val.from.toLocaleString('ru', { maximumFractionDigits: maxFractions }));
			}
			if (val.to) {
				prefix = prefix ? '' : 'до ';
				res.push(val.to.toLocaleString('ru', { maximumFractionDigits: maxFractions }));
			}
			var resstr = prefix + res.join('-');
			return resstr ? resstr + postfix : empty;
		} else {
			return val.toLocaleString('ru', { maximumFractionDigits: 0 }) + (options.postfix || '');
		}
	},
	displayNum(value, digits, options = {}) {
		if (_.isObject(digits)) {
			options = digits;
			digits = undefined;
		}
		digits = digits || options.digits || 0;
		let empty = options.empty || '';
		value = parseFloat(value, 10);
		if (isNaN(value)) {
			return empty;
		}
		let power = Math.pow(10, digits);
		if (power > 0) {
			value = Math.round(value * power) / power;
		}

		if (value.toLocaleString) {
			return value.toLocaleString('ru');
		} else {
			return _.rusNum(value);
		}

	},
	rusNum(value) {
		if (value == null || isNaN(value)) return;
		let num = value.toString().split('.');
		let int = _.map(num[0], a => a);
		let fl = num[1];
		int.reverse();
		int = _.reduce(int, (memo, char, index) => {
			if (index > 0 && (index % 3) == 0) {
				char = char + ' ';
			}
			memo = char + memo;
			return memo;
		}, '');
		if (fl) {
			fl = ',' + fl;
		}
		return int + fl;
	}
});
