import { DropdownActions } from "coms/ui/DropdownActions";
import { HamburgerView } from "coms/ui/HamburgerView";
import { NextIconButtonView } from "coms/ui/Button";
import { TextView } from 'core';
import EditValue from 'ui/controls/editValue';
// import { getValueByPath } from "utils";
// import { EntityLabelView } from "./EntityLabelView";


function editNote(model) {
	let options = {
		header: 'Внесение изменений в заметки для актуализатора',
		valueType: 'bigtext',
		initialValue: model.getByPath('actualize.notes')
	}				
	EditValue.modal.single(options).then((type, text) => {
		if (type != 'ok') return;
		model.send({ attrs: { 'actualize.notes': text }, wait: true, method: 'patch' }).then((data) => {
			model.setByPath(data);
		});
	}, () => {});		
}
const EditButton = NextIconButtonView.extend({
	icon: 'fa:info-circle',
	action() {
		return editNote(this.model);
	}
});
const NoteView = TextView.extend({
	baseClassName: 'just-text',
	thisClassName: 'note',
	text: v => v.model.getByPath('actualize.notes'),
	modelEvents: {
		'change':'render'
	},
	events: {
		click() {
			editNote(this.model);
		}
	}
})

export const NoteInfoLine = HamburgerView.extend({
	baseClassName: 'flex-row note box-row',
	childrenViews: [
		EditButton,
		NoteView
	]
});