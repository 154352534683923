import { displayPhone, displayEmail } from 'utils/contact-value';
import { propertySchemaApi } from 'apis/schema';
import { displayContactValue, phoneTypes } from './schema-common';

// const displayContactValue = {
// 	other: undefined,
// 	phone: displayPhone,
// 	mobilePhone: displayPhone,
// 	email: displayEmail,
// 	messenger: undefined,
// 	site: undefined,
// 	social: undefined,
// }

// export const phoneTypes = {
// 	phone: 1,
// 	mobilePhone: 1,
// }

export const contactValueSchema = {
	isPhone: {
		valueType: 'boolean',
		virtual: true,
		getValue(m, s) {
			const type = propertySchemaApi.getValue('value.type', m, s);
			return type in phoneTypes;
		}
	},
	'value.value': {
		displayValue(v, m, s) {

			const type = propertySchemaApi.getValue('value.type', m, s);
			const add = propertySchemaApi.getValue('addValue', m, s);
			const display = displayContactValue[type];
			if (display) {
				return display([v, add], { url: true });
			}
			return v;
		}
	},
	'value.type': {
		valueType: 'enum',
		valueSubType: 'contactValueTypes'
	},
	purposes: {
		name: 'назначение',
		valueType: 'enum',
		valueSubType: 'contactValuePurposes'
	},
}