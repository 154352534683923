import { HamburgerView } from "coms/ui/HamburgerView";
import InfoBoxView from "./InfoBoxView";
import './owner-in-realty-info.less';

const InfoView = HamburgerView.extend({
	baseClassName: 'owner-in-realty-info',
	childrenViews: [InfoBoxView],
	modelEvents: {
		'change':'render'
	}
});

export default InfoView;