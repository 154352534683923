﻿//define('m-departments-departmentEmployee', ['app-paths', 'bus-svc', 'references'], function (paths, appRadio, refs) {});

import paths from 'app-paths';
import appRadio from 'bus/svc';
import refs from 'references';

import { BbeModel } from 'core/bbe';

var EmployeeModel = BbeModel.extend({
	initialize: function (hash, opts) {
		this.parent = opts.parent;
		this.parentId = opts.parentId;
	},
	urlRoot: function () {
		return paths.api.principalEmployees(this.parentId);
	},
	getLabel: function () {
		return appRadio.request('employeeName', this.get('eId'));
	},
	getRole: function (asNum) {
		if (!asNum)
			return refs.enum('employeeGroupJoins', this.get('joinedAs'));
		else {
			return _.keys(refs.enum('employeeGroupJoins')).indexOf(this.get('joinedAs'));
		}
	},
	changeRole(role) {
		let url = _.result(this, 'urlRoot');
		url += '/' + this.get('eId');
		//var model = this;
		return this.loudSave(null, { url: url, method: 'PATCH', attrs: { joinedAs: role } });
	}

});

export default EmployeeModel;