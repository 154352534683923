import { View } from 'core';
import { BbeModel } from 'core/bbe';
import { ManagersListView } from './ManagersListView';
import { selectEmployeesInModernModal } from 'ui/controls/selectEmployees/in-modal';
import { Collection } from 'core';

export const ManagersView = View.extend({
	baseClassName: 'agency',
	template: `<div class="actions"><button class="add-manager">добавить менеджера</button></div>
	<div class="managers-container"></div>`,
	regions: {
		managers: '.managers-container'
	},
	initializeCollection() {
		if (this.managersCollection) { return; }

		const models = this.model.get('agencyAgents') || [];
		console.error('####', models);
		this.managersCollection = new Collection(models);

	},
	onRender() {
		console.warn('managers show content', this.model.attributes.agencyAgents);
		this.showManagers();
	},
	showManagers() {
		this.initializeCollection();
		const view = new ManagersListView({ collection: this.managersCollection });
		this.showChildView('managers', view);
	},
	events: {
		'click .add-manager'() {
			this.addEmployees();
		}
	},
	agencyManagersUrl() {
		return this.model.url() + '/agency-managers';
	},
	addEmployees() {
		var setup = {
			header: 'добавление менеджеров',
			multiple: true,
		};
		
		
		let model = this.model;
		const agencyUrl = this.agencyManagersUrl();
		const managersCollection = this.managersCollection;
		const callback = function (res) {

			if (!res.ok) { return; }

			const type = res.value.type;
			const newvalue = res.value.value;
			if (type != 'ok') return; // || newvalue[0] == owner) return;
			
			let saver = new BbeModel();

			saver.url = agencyUrl;

			saver
				.save(null, { attrs: newvalue, wait: true})
				.then(data => {
					model.set('agencyManagers', data);
					managersCollection.reset(data);
				}, () => {});

		};

		
		selectEmployeesInModernModal(setup, callback);

	},
	removeEmployee(id) {
		let saver = new BbeModel({ id });
		saver.url = this.agencyManagersUrl() + '/' + id;
		saver.destroy({ wait: true }).then(() => {
			console.log('after destroy');
			const exist = this.managersCollection.get(id);
			console.log('exist', exist);
			if (exist) {
				this.managersCollection.remove(exist);
			}
		})
	},
	childViewEvents: {
		'remove:employee'(id) {
			this.removeEmployee(id);
		}
	}
});