import { _, CollectionView as Base } from 'vendors';
import { viewCommonMixin } from './view-common-mixin';
import { viewStateMixin } from 'apis/state';
import { viewClassNameMixin } from 'apis/className';

export const CollectionView = Base.extend({

	...viewStateMixin,
	...viewClassNameMixin,
	...viewCommonMixin,

	constructor: function(options) {
			// this.options = Object.assign({}, options);
			Base.apply(this, arguments);
			this.initializeStateMixin();
			this.initializeClassNameMixin();
			this.initializeHtmlAttributesMixin();
	},

	_setOptions() {
		const res = Base.prototype._setOptions.apply(this, arguments);

		const keys = this.getOption('mergeOptionsKeys', true);
		if (keys && Array.isArray(keys)) {
			this.mergeOptions(this.options, keys);
		}

		return res;
	},

});

const sides = {
	top: 'bottom',
	bottom: 'top',
	left: 'right',
	right: 'left',
}

export const ApiCollectionView = CollectionView.extend({
	mergeOptionsKeys: ['getFetchData'],
	constructor: function() {
		CollectionView.apply(this, arguments);
		this._initScrollHandler();
		this.once('render:children', () => setTimeout(() => this._onFirstChildrenRender(),0));
		// try fetch collection
		if (this.collection && this.getOption('shouldFetchOnCreate') && !this.collection.isFetched() && !this.collection.isFetching()) {
			this.fetch();
		}
		
	},
	fetch() {
		const search = this.getFetchData();
		console.warn('api-fetch', search);
		this.collection.fetch({ search });
	},
	_initScrollHandler() {
		//const handler = _.debounce(this._scrollHandler.bind(this), 50);
		this.delegate('scroll', undefined, this._scrollHandler.bind(this));
	},
	_scrollHandler(e) { 
		const { scrollTop, scrollLeft } = this.el;
		const res = this.testScrollEdge();
		if (res.v) {
			this.triggerMethod('scroll:' + res.v, res);
			this._scrollEdgeHandler(res.v, res.h);
		}
		if (res.h) {
			this.triggerMethod('scroll:' + res.h, res);
			this._scrollEdgeHandler(res.h, res.v);
		}
		this._scrollInfo = {
			prevScrollLeft: scrollLeft,
			prevScrollTop: scrollTop
		}
	},
	testScrollEdge(options = {}) {
		const { scrollTop, scrollLeft, clientHeight, scrollHeight, clientWidth, scrollWidth } = this.el;
		const { prevScrollTop = 0, prevScrollLeft = 0 } = (this._scrollInfo || {});
		const { onTop, onBottom, onLeft, onRight } = options;
		let v;
		let h;
		if (prevScrollTop !== scrollTop) {
			const testRes = this._testScrollEdge(scrollTop, prevScrollTop, clientHeight, scrollHeight);
			//console.log('vertical testRes', testRes);
			if (testRes) {
				v = testRes > 0 ? 'bottom' : 'top';
				const method = testRes > 0 ? onBottom : onTop;
				if (method) { method(); }
			} 
		}
		if (prevScrollLeft !== scrollLeft) {
			const testRes = this._testScrollEdge(scrollLeft, prevScrollLeft, clientWidth, scrollWidth);
			//console.log('horizontal testRes', testRes);
			if (testRes) {
				h = testRes > 0 ? 'right' : 'left';
				const method = testRes > 0 ? onRight : onLeft;
				if (method) { method(); }
			}
		}
		const res = { v, h };
		//console.log(res);
		return res;
	},
	_testScrollEdge(scrollValue, prevScrollValue, edgeSize, scrollSize) {
		if (scrollValue === prevScrollValue) { return 0; }

		let normalDirection = scrollValue > prevScrollValue;

		if (normalDirection) {
			const clientEdge = scrollValue + edgeSize;
			const remainedSize = scrollSize - clientEdge;
			if (remainedSize < edgeSize) {
				return 1;
			}
		} else {
			if (scrollValue <= edgeSize) {
				return -1;
			}
		}

		return 0;
	},
	_scrollEdgeHandler(edge, otherEdge) {
		let cfg = this._getFetchOnScrollConfig();
		let prevEnabled = this.getOption('fetchPreviousOnScrollEnabled');
		const fetchingInProcess = this.collection.isFetching();
		//console.warn(' - edge handler -', edge, fetchingInProcess, cfg);
		if (!cfg || fetchingInProcess) { return; }

		const { vertical, horizontal } = cfg;
		let nextEdge, prevEdge;
		if (vertical in sides) {
			nextEdge = vertical;
			prevEdge = prevEnabled ? sides[vertical] : null;
		} else if (horizontal in sides) {
			nextEdge = horizontal;
			prevEdge = prevEnabled ? sides[horizontal] : null;
		}
		
		

		if (edge === nextEdge) {
			if (!this.collection.isEOD()) {
				console.warn('-fetch-next-', this.collection.length);
				this.collection.fetchNext()
			}
		} else if (edge === prevEdge) {
			if (!this.collection.isSOD()) {
				this.collection.fetchPrev()
			}
		}


	},
	_getFetchOnScrollConfig() {
		let cfg = this.getOption('fetchOnScrollEnabled');
		if (!cfg) { return; }
		switch (cfg) {
			case true:
			case 'bottom':
				return { vertical: 'bottom' };
			case 'top':
				return { vertical: 'top' }
			case 'right':
				return { horizontal: 'right' };
			case 'left':
				return { horizontal: 'left' };
		}

		// if (typeof cfg === 'object' && (cfg.vertical in sides || cfg.horizontal in sides)) {
		// 	return cfg;
		// }

	},
	_onFirstChildrenRender() {
		console.error(' = = first:children:render = = ');
		if (this._childrenRenderedAtleastOnce) { return; }
		if (this.collection.isFetched() || this.collection.length) {
			this._childrenRenderedAtleastOnce = true;
			this.triggerMethod('first:render:children');
			const autofetch = this.getOption('fetchUntilScrollEnabled');
			if (autofetch) {
				this.tryFetchToScroll();
			}
			return;
		}
		this.listenToOnce(this.collection, 'sync', this._onFirstChildrenRender);
	},

	// в случаи когда контейнер больше чем отображаемые данные не появляется прокрутка
	// этот метод пытается подсосать дополнительные данные чтобы заполнить контейнер
	tryFetchToScroll() {
		const cfg = this._getFetchOnScrollConfig();
		if (!cfg) { return; }

		const edge = cfg.vertical || cfg.horizontal;

		this._fetchToScrollAttempts = (this._fetchToScrollAttempts || 0) + 1;
		console.log('tryFetchToScroll attempt', this._fetchToScrollAttempts);
		const { scrollTop, scrollHeight, clientHeight, scrollWidth, clientWidth } = this.el;
		let clientSize;
		let scrollSize;
		let shouldTry;
		let eod;
		if (edge === 'bottom') {
			clientSize = clientHeight;
			scrollSize = scrollHeight;
			eod = this.collection.isEOD();
			shouldTry = !eod && !(clientSize < scrollSize);
		} else if (edge === 'right') {
			clientSize = clientWidth;
			scrollSize = scrollWidth;
			eod = this.collection.isEOD();
			shouldTry = !eod && !(clientSize < scrollSize);
		}
		if (shouldTry && this._fetchToScrollAttempts < 5) {
			console.log('edge: ' + edge, 'sending request...', clientSize, scrollSize, 'already fetched:', this.collection.length);
			this.listenToOnce(this.collection, 'sync', this.tryFetchToScroll);
			this.collection.fetchNext();
		} else {
			if (!shouldTry) {
				console.log('fetch not needed', 'edge:' + edge, 'eod:', eod, clientSize, scrollSize);
			} else {
				console.log('fetch canceled due to attemps limit', 'edge:' + edge);
			}
		}		

	},

	getFetchData() { }
});