﻿//define('m-employees', ['m-employees-collection', 'm-employees-inviteModel', 'm-employees-createModel', 'm-employees-model', 'm-employees-modelInGroup'], function (EmployeesCollection, InviteModel, CreateModel, EmployeeModel, EmployeeInGroupModel) {});


import EmployeesCollection, { employees } from './collection';
import InviteModel from './inviteModel';
import CreateModel from './createModel';
import EmployeeModel from './model';
import EmployeeInGroupModel from './modelInGroup';

export {
	employees,
	EmployeesCollection,
	InviteModel,
	CreateModel,
	EmployeeModel, 
	EmployeeInGroupModel
}

var ns = {};

ns.InviteModel = InviteModel;
ns.CreateModel = CreateModel;
ns.EmployeesCollection = EmployeesCollection;
ns.EmployeeModel = EmployeeModel;
ns.EmployeeInGroupModel = EmployeeInGroupModel

export default ns;