import SendByEmail from 'svc/realties/sendByEmail';
import { pdfApi } from 'apis';

function getActions(deal, actionOn) {

	const actions = [
		{ text: "отправить по email", action: 'sendAsEmail', actionOn },
		{ text: "сформировать PDF", action: 'createPdf', actionOn }
	];

	if (deal) {
		actions.unshift({ text: "прикрепить отобранное к процессу", action: 'joinOffersToDeal', actionOn: deal });
	}


	return actions;

}

export const actionsMixin = {
	actionSendAsEmail: SendByEmail.actionSendByEmail,
	actionCreatePdf (offers) {
		offers = offers.map(arg => typeof arg === 'string' ? arg : arg.id);
		pdfApi.showBlocks(offers);
		
	},
	getActionsPanelActions() {
		const deal = this.getOption('deal');
		return getActions(deal, this);
	}
}