import { loadScript, okResult, tryAsync } from "utils"

export class MapInitializer {

	constructor (url, callback, windowCallbackName) {
		this.url = url;
		// if defined means that script will try to find callback function in window by this name window[windowCallbackName]();
		this.windowCallbackName = windowCallbackName;
		this.afterLoadCallback = callback || (() => {});
	}

	async inject() {
		if (this._injected) { return okResult(); }

		const scriptOnloadPromise = new Promise((res, rej) => {
			if (!this.windowCallbackName) {
				res(okResult());
			}
			window[this.windowCallbackName] = async (...args) => {
				const result = await tryAsync(() => this.afterLoadCallback(...args));
				res(result);
			};
		});


		const res = await this.load();
		if (!res.ok) { console.error('map script failed to load', res); return res; }		
		else { console.log('	mapsApi: map script successfully loaded'); }

		const onloadRes = await scriptOnloadPromise;
		if (!onloadRes.ok) { console.error('map script callback failed during execution', onloadRes.error); return onloadRes; }
		else { console.log('	mapsApi: map script onload callback successfully executed'); }

		if (this.afterLoadCallback && !this.windowCallbackName) {
				const res = await tryAsync(() => this.afterLoadCallback());
				if (!res.ok) { console.error('map afterLoadCallback failed during execution', res.error); return res; }
				console.log('	mapsApi: afterLoadCallback successfully executed');
		} 
		if (!this.afterLoadCallback) {
			console.log('	mapsApi: no afterLoadCallback founded');
		}

		const nsResult = await this._importNamespace();
		console.log('# 2, import namespace result', res);
		if (!nsResult.ok) {
			console.error('	mapsApi: engine namespace import fail', nsResult.error); 
			return nsResult;
		} else {
			console.log('	mapsApi: engine namespace imported');
		}

		console.log('import ns', nsResult);
		this._injected = true;
		return okResult();
	}


	load() {
		return loadScript(this.url);
	}

	async _importNamespace() {
		const res = await tryAsync(() => this.importNamespace());
		console.log('import namespace result', res);
		if (res.ok) {
			this.namespace = res.value?.default;
		}
		return res;
	}

	importNamespace() {
		throw new Error('importNamespace not implemented');
	}

	_ensureInjected() {
		if (!this._injected) {
			throw new Error('map not yet injected. await use of inject() before access');
		}
	}

	attachMapToView(view, el, mapExtraOptions) {
		//const el = options.el || view.el;
		const mapOptions = this.buildMapOptions(view);
		try {
			const map = this.buildMap(el, mapOptions, mapExtraOptions);
			view.triggerMethod('map:attach', map, mapOptions);
			view.on('destroy', () => this.destroyMap(map));
			return map;
		} catch (exc) {
			console.error('map build error, options was used', mapOptions);
			throw exc;
		}
	}

	buildMapOptions(view) {
		const zoomCenter = this.getViewZoomCenter(view);
		const mapOptions = this.getViewMapOptions(view);
		return this._buildMapOptions(zoomCenter, mapOptions);
	}	

	getViewZoomCenter(view) {
		const zoom = view.getOption('zoom', true);
		const center = view.getOption('center', true);
		return { zoom, center };
	}

	getViewMapOptions(view) {
		return view.getOption(this.identifier + 'MapOptions', true);
	}


	setupMapEvents() {
		throw new Error('setupEvents not implemented');
	}

	getBounds(map) {
		throw new Error('getBounds not implemented');
	}

	updateBounds(model, map) {
		const bounds = this.getBounds(map);
		model.updateBounds(bounds);
	}

	createClusterOverlayView() {
		throw new Error('createClusterOverlayView not implemented');
	}

	on (map, eventEntity, appEventName, callback) {
		throw new Error('on not implemented. specify events listener for map engine');
	}
	off (map, eventEntity, appEventName, listener, callback) {
		throw new Error('off not implemented. specify events listener remove logic for map engine');
	}

	createMetroLayer() {
		throw new Error('createMetroLayer not implemented. should return instace of MetroLayer specific to engine');
	}
}