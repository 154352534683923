import { Collection } from 'core';
import { _ } from 'vendors';
import { MapPointModel } from './MapPointModel';

export const MapPointsCollection = Collection.extend({
	constructor: function(data, options) {
		this.map = options.map;
		Collection.apply(this, arguments);
		// this.on('sync', this.redraw);
		this.on('update', this._onUpdate);
	},
	model: MapPointModel,
	setPoints(data, bounds) {
		for (let id in data) {
			const model = Object.assign({ id }, data[id]);
			
		}
		this.set(items, { remove: false, merge: true, add: true });
	},
	parsePoints(data) {
		if (data == null || Array.isArray(data)) { return data; }
		const items = _.map(data, (hash, id) => {
			const model = Object.assign({ id }, hash);
			if (!model.id) {
				// console.error('no id', model);
			}
			return model;
		});
		return items;
	},
	_onUpdate(col, options) {
		console.warn('_on_update_', options);
		this.redraw();
	},
	redraw() {
		console.log('redrawing');
		for(let model of this.models) {
			model.updateMarker(this.map)
		}
	}
});