import modals from '_libs/modals';
import { BbeModel, BbeCollection } from 'core/bbe';
import { MneNextCollectionView } from 'core/mne';
import { EmployeeListItemView } from './EmployeeListItemView';
import { bbeViewComparator } from 'utils';
import SchemaEdit from 'ui/controls/schemaEdit';
import { invokeValue } from '../../utils';

const joinedIndexes = {
	beginner: 4,
	regular: 3,
	leadHelper: 2,
	insteadLead: 1,
	lead: 0,
}

const joinedAsIndexes = {
	beginner: -1,
	regular: 0,
	leadHelper: 1,
	insteadLead: 2,
	lead: 3,
}

function joinedAsToIndex(enumValue) {
	let val = joinedAsIndexes[enumValue];
	console.log('--', enumValue, val);
	return val == null ? -1 : val;
}


export const EmployeesListView = MneNextCollectionView.extend({
	className: 'employees-list with-borders elastic',
	initialize() {
		let models = this.model.get('employees');
		this.collection = new BbeCollection(models);
		this.collection.joinedAsEnabled = this.getOption('joinedAsEnabled');
		this.collection.patchUrl = invokeValue(this.model.url, this.model) + '/employees/';
		this.listenTo(this.model, 'change:employees', () => this.collection.reset(this.model.get('employees')));
	},
	viewComparator(v1,v2) {
		return bbeViewComparator([
			// [v1,v2, m => joinedIndexes[m.get('joinedAs')]],
			[v2, v1, m => joinedAsToIndex(m.get('joinedAs'))],
			[v2, v1, m => console.log("**", m.get('name'), m.get('order')) || (m.get('order') || 0)],
			[v1, v2, m => m.get('name')]
		]);
	},
	childView: EmployeeListItemView,
	childViewEvents: {
		'delete:click'(view, event) {

			const model = view.model;
			const parent = this.model;

			modals.confirm("подтвердите удаление").then(v => {
				let saver = new BbeModel();
				saver.id = model.id;
	
				model.url = parent.url() + '/employees/' + model.id;				
				model.destroy({ wait: true }).then(data => {
					parent.set('employees', data);
				}, () => {});
			});
		},
		'edit:click'(view, event) {
			const model = view.model;
			const patchRootUrl = this.collection.patchUrl;
			const patchUrl = patchRootUrl + model.id;
			const schema = {
				joinedAs: {
					caption: 'Роль',
					valueType: 'enum',
					valueSubType: 'employeeGroupJoins'
				},
				order: {
					caption: "Порядок сортировки",
					valueType: 'number'
				}
			}
			const hash = {
				joinedAs: model.attributes.joinedAs,
				order:model.attributes.order
			}
			const options = {
				header: 'Изменение сотрудника',
				valuesHash: hash,
				beforeApply: async (value) => {
					console.error("hididish", value, patchUrl)
					return model.save(null, {
						wait:true,
						url: patchUrl,
						method: 'patch',
						attrs: value,
						success: () => {
							console.warn("SUCCESS")
							this.sort();
						}
					})
				},
			}
			SchemaEdit.inModal(schema, options);
		}
	}
});