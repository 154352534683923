import { View } from 'core';
import { commonMixin } from './common';
export const ValueLabelView = View.extend({
	...commonMixin,
	baseClassName: 'value-label-view',
	classNames: [ v => v.getValueNameClassName()],
	template: '<span class="value-label-text"><%= text %></span>',
	templateContext() {
		const schema = this.getOption('valueSchema', true);
		return {
			text: schema.label || schema.property
		}
	}
});