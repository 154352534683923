//define('ui/controls/forms/simple-form-mixin', ['modals'], function(modals) {});

import modals from '_libs/modals';
import { $, _ } from 'vendors';
	
const mixin = {
	getTemplate() {
		let template = this.getOption('template');
		if (typeof template === 'string') {
			template = _.template(template);
		}
		let afterTemplate = this.getOption('templateAfterSend') || template;
		if (typeof afterTemplate === 'string') {
			afterTemplate = _.template(afterTemplate);
		}
		if (this._sended) {
			return afterTemplate;
		} else {
			return template;
		}
	},		
	getSendUrl() {
		let url = this.getOption('formUrl');
		if (typeof url === 'function') {
			url = url.call(this, this);
		}
		return url;
	},
	_initializeClickHandler() {
		this.delegate('click', 'button[data-role="submit"]:not(:disabled)', this._clickHandler.bind(this));
	},
	getDefaultFormData() {
		let data = this.getOption('defaultFormData');
		if (typeof data === 'function') {
			data = data.call(this, this);
		}
		return data || {};
	},
	_clickHandler(event) {
		let defaultData = this.getDefaultFormData();
		let data = this._getFormData(defaultData);
		let errors = this.validateData(data);

		if (!errors) {
			let url = this.getSendUrl();
			this.sendData(url, data);
		} else {
			this.triggerMethod('form:invalid', errors);
		}
		// let url = cfg.apiUrl('acc/forgot-password');
		// console.log('DAta', data);
		// data = JSON.stringify(data);
		// console.log('DAta #2', data);
	},
	onFormInvalid(errors) {
		if (Array.isArray(errors)) {
			errors = errors.join('<br/>\r\n');
		}
		modals.error('Некорректно заполнена форма', errors);
	},
	_getFormData(mergeWith)
	{
		let data = {};
		let add = (key, value) => {
			if (key in data) {
				if (!Array.isArray(data[key])) {
					data[key] = [data[key]];
				}
				data[key].push(value);
			} else {
				data[key] = value;
			}
		}
		this.$('input[name]').each((ind, el) => {
			let value = el.value;
			if (el.type === 'checkbox') {
				if (!el.checked) return;
				value = true;
			} else if (el.type === 'radio' && !el.checked) {
				return
			}
			add(el.name, value);
		});
		this.$('textarea[name]').each((ind, el) => {
			add(el.name, el.value);
		});
		this.$('select[name]').each((ind, el) => {
			add(el.name, el.value);
		});
		Object.assign(data, mergeWith);
		this.extendFormData(data);
		return this.getFormData(data);
	},
	getFormData(data)
	{
		return data;
	},
	extendFormData() { },
	validateData() { },
	submitButtonSelector: 'button[data-role="submit"]',
	getSubmitButtonSelector() {
		return this.getOption('submitButtonSelector');
	},
	toggleSubmit(bool) {
		let selector = this.getSubmitButtonSelector();
		this.$(selector).prop('disabled', bool);
	},
	disableSubmit(){
		this.toggleSubmit(true);
	},
	enableSubmit(){
		this.toggleSubmit(false);
	},
	sendOptions: {
		method: 'POST',
		contentType: 'application/json',
		xhrFields: {
			withCredentials: true
		},
	},
	getDefaultSendOptions() {
		return this.getOption('sendOptions');
	},
	getSendOptions(data) {
		let def = this.getDefaultSendOptions();
		if (typeof data == 'object') {
			// for(let key in data) {
			// 	data[key] = encodeURIComponent(data[key]);
			// }
			if (def.contentType.indexOf('json') > -1) {
				data = JSON.stringify(data);
			} else {
				data = _.map(data, (value, key) => key + '=' + encodeURIComponent(value)).join('&');
			}
		}
		return Object.assign({}, def, { data });
	},
	sendData(url, data, onsuccess, onfail)
	{
		this.triggerMethod('before:send', data);

		let options = this.getSendOptions(data);

		return $.ajax(url, options).then(res => {
			this._sended = true;
			this.triggerMethod('send:done', res);
			this.triggerMethod('send:success', res);
			if (typeof onsuccess == 'function') {
				onsuccess.call(this, res);
			}
		}, xhr => {
			//this._sended = true;
			this.triggerMethod('send:done', xhr);
			this.triggerMethod('send:fail', xhr);
			if (typeof onfail == 'function') {
				onfail.call(this, xhr);
			}				
		});
	},
	onBeforeSend() {
		this.disableSubmit();
	},
	onSendDone() {
		this.enableSubmit();
	},
	onSendFail(xhr) {
		console.log('SEND fail', xhr);
		modals.xhrError(xhr);
	}
}

export default {
	mixin,
	mix(View) {
		return View.extend(mixin);
	}
}
