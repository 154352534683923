import { _ } from 'vendors';
import { Claims } from './claims';
// import { storeAdd } from './store';



function _processArgument(arg, desiredType) {
    if (arg == null) { return; }

    let t = typeof desiredType;
    if (
        (t === 'function' && arg instanceof desiredType)
        || (t === 'string' && typeof arg === desiredType)
    ) {
        return arg;
    }
}

function addTo(context, key, value) {
    if (value == null) { return; }
    context[key] = value;
}

function toOpts(context) {
    let _options = context.options;
    let options = Object.keys(context).reduce((hash, key) => {
        let value = context[key];
        if (value !== undefined) { 
            hash[key] = value;
        }
        return hash;
    }, {});
    delete options.options;
    if (_options) {
        Object.assign(options, _options);
    }
    return options;
}

function processArgument(arg, context) {

    if (arg == null || context.options) { return; }

    let shortName = !context.shortName ? _processArgument(arg, 'string') : undefined;
    let claims = !context.claims && !shortName ? _processArgument(arg, Claims) : undefined;
    let options = !claims ? _processArgument(arg, 'object') : undefined;

    addTo(context, 'shortName', shortName);
    addTo(context, 'claims', claims);
    addTo(context, 'options', options);

}

//name, claims, options
function actionOptions(arg1, arg2, arg3) {
    
    let context = {};
    processArgument(arg1, context);
    processArgument(arg2, context);
    processArgument(arg3, context);
    return toOpts(context);
}

function _toAction(groupKey, item, index, isFromObject) {
    let action;
    if (Array.isArray(item)) {
        let args = [ groupKey, ...item ];
        action = toAction.apply(null, args);
    } else {
        action = Object.assign({}, item);
    }
    if (isFromObject && !action.id) {
        action.id = index;
    }
    return action;
}

export function toAction(groupKey, id, callback, ...rest) {

    if (typeof id === 'object') {
        let isFromObject = !Array.isArray(id);
        return _.map(id, (item, index) => _toAction(groupKey, item, index, isFromObject));
    }

    let options = actionOptions(...rest);
    let action = _.extend({ groupKey, id, action: callback }, options);
    return action;
}

// export function action(groupKey, id, callback, ...rest) {
//     let action = toAction.apply(null, arguments);
//     return storeAdd(groupKey, action);
// }
