import { View, CollectionView, Collection, Model } from 'core';
//import filters from '../../filters';
import { _ } from 'vendors';
import { ObjectListItem } from './ObjectListItem';
import { ObjectModel } from './ObjectModel';
import { createCompare } from 'utils/comparators';

import './objects-list.less';

const EmptyObjectListItem = View.extend({
	baseClassName: 'empty-object-list-item',
	template: '<div><span><%= text %></span></div>',
	templateContext() {
		return {
			text: this.getText()
		}
	},
	getText() {
		const filterModel = this.getOption('filterModel');
		if (filterModel.isEmpty()) {
			return 'В данном здании отсутствуют площади выбранного собственника';
		}
		return 'По выбранным фильтрам площадей не найдено<br>Чтобы получить другой результат измените фильтры'
	}
});

export const ObjectsList = CollectionView.extend({
	className: 'objects-list',
	initialize(opts) {

		this.mergeOptions(opts, ['objectsSelector', 'offersSelector', "filterModel"]);
		this.initCollection();
		this.listenTo(this.filterModel, 'after:change', this.sort);

		// this.selector.listenTo(innerBus, {
		// 	'selection': context => sel.click(context),
		// 	'select:all': () => sel.select(this.availableViews),
		// 	'unselect:all': () => sel.clear(),
		// 	'toggle:all': () => sel.toggle(this.availableViews),
		// 	'action:applied': this.onActionApplied.bind(this)
		// });
		// this.selector.on('change', () => innerBus.triggerMethod('selector:change', sel));

		// this.listenTo(innerBus, 'selector:change', this.onSelectorChange);
		// this.on('destroy', () => this.hideMultiActions());


	},
	initCollection() {
		// let items = this.model.get('objects') || [];
		// this.collection = new Collection(items, { model: ObjectModel });
		// this.collection.parent = this.model;
		//debugger;
		this.collection = this.model.getObjects();
	},
	collectionEvents: {
		'change'(model) {
			if (!this.viewFilter({ model })) {
				this.sort();
			}
		}
	},
	viewFilter(v, index) {
		//console.log(':filter:', this.filterModel.object);
		return this.filterModel.filterObject(v.model, index, v);
	},
	viewComparator: createCompare([
		v => v.model.getByPath('info.floor'), 
		v => v.model.getByPath('info.squareOffer')
	]),
	childView: ObjectListItem,
	emptyView: EmptyObjectListItem,
	childViewOptions() {
		return {
			ownerInRealty: this.model,
			objectsSelector: this.objectsSelector,
			offersSelector: this.offersSelector,
			filterModel: this.filterModel,
		}
	},
	childViewTriggers: {
		'multy:action:click':'multy:action:click'
	},
	childViewEvents: {
		'toggle:object:select'(event, objView) {
			this.selectObject(event, objView);
		},
		'toggle:offer:select'(event, offView, objView) {
			this.selectOffer(event, objView, offView);
		},
	},
	selectObject(event, objView) {
		const models = this._getSelectObjectModels(objView, event.shiftKey);
		this._lastClickedObject = objView;
		this._lastClickedOffer = undefined;
		this.objectsSelector.toggle(models);
	},
	selectOffer(event, objView, offView) {
		const models = this._getSelectOfferModels(offView, objView, event.shiftKey);
		this._lastClickedOffer = offView;
		this._lastClickedObject = objView;
		this.offersSelector.toggle(models);
	},
	_getSelectObjectModels(objView, shiftKey) {

		const prevOff = this._lastClickedOffer;
		const prevObj = this._lastClickedObject;
		const multy = prevObj && !prevOff && shiftKey;
		if (!multy) {
			return [objView.model];
		}
		return extractChildrenModelsByViews(this.children, objView, prevObj);

	},
	_getSelectOfferModels(offView, objView, shiftKey) {
		const prevObj = this._lastClickedObject;
		const prevOff = this._lastClickedOffer;
		const multy = prevObj && prevOff && shiftKey;
		if (!multy) {
			return [offView.model];
		}
		const prevObjIndex = this.children.findIndexByView(prevObj);
		const nextObjIndex = this.children.findIndexByView(objView);
		if (prevObjIndex === -1 || nextObjIndex === -1) {
			return [];
		}

		if (prevObjIndex === nextObjIndex) {
			const offersView = objView.getNamedView('objectOffersView');
			if (!offersView) {
				return [];
			}
			return extractChildrenModelsByViews(offersView.children, offView, prevOff);
		}

		const grow = nextObjIndex > prevObjIndex;
		const start = grow ? prevObjIndex : nextObjIndex;
		const startObjView = grow ? prevObj : objView;
		const startOffView = grow ? prevOff : offView;
		const end = grow ? nextObjIndex : prevObjIndex;
		const endObjView = grow ? objView : prevObj;
		const endOffView = grow ? offView : prevOff;
		const res = [];
		for (let index = start; index <= end; index++) {
			const parent = this.children.findByIndex(index);
			const objectOffersChildren = extractOffersChildren(parent);
			if (!objectOffersChildren) { continue; }
			
			if (index === start || index === end) {
				const isStart = index === start;
				// let offObjectView = isStart ? startObjView : endObjView;
				// let offersContainer = offObjectView.getNamedView('objectOffersView');
				let offChildren = objectOffersChildren;
					// extractOffersChildren(isStart ? startObjView : endObjView); //offersContainer.children;
				let offView = isStart ? startOffView : endOffView;
				let offIndex = offChildren.findIndexByView(offView);
				if (offIndex === -1) {
					throw new Error('Start-End case: offer view index = -1');
				}
				let offStart;
				let offEnd;
				if (grow) {
					offStart = isStart ? offIndex + 1 : 0;
					offEnd = isStart ? offChildren.length - 1 : offIndex;
				} else {
					offStart = isStart ? offIndex : 0;
					offEnd = isStart ? offChildren.length - 1 : offIndex - 1;
				}

				const ms = _extractChildrenModels(offChildren, offStart, offEnd);
				addModels(res, ms);


			} else {

				const models = objectOffersChildren._views.map(v => v.model);
				addModels(res, models);

			}

		}
		return res;
	}
});

function extractOffersChildren(objectListItemView) {
	const offersContainer = objectListItemView?.getNamedView('objectOffersView');
	return offersContainer?.children;
}

function extractChildrenModelsByViews(children, clickedView, prevView) {
	const clickedIndex = children.findIndexByView(clickedView);
	const prevIndex = children.findIndexByView(prevView);
	if (clickedIndex === -1 || prevIndex === -1) {
		return [];
	}
	return extractChildrenModels(children, clickedIndex, prevIndex);
}

function extractChildrenModels(children, clickedIndex, prevIndex) {
	if (clickedIndex === prevIndex) {
		const view = children.findByIndex(clickedIndex);
		return view ? [view.model] : [];
	}
	const grow = clickedIndex > prevIndex;
	const indexInc = 1;
	const start = grow ? prevIndex + indexInc : clickedIndex;
	const end = grow ? clickedIndex : prevIndex - indexInc;
	return _extractChildrenModels(children, start, end);
}

function _extractChildrenModels(children, start, end) {
	const models = [];
	for (let index = start; index <= end; index++) {
		const view = children.findByIndex(index);
		if (view) {
			models.push(view.model);
		}
	}
	return models;
}

function addModels(arr, models) {
	arr.push.apply(arr, models);
}
