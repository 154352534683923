import { UploadModel } from './upload-model';

import { BbeCollection } from 'core/bbe';

export const UploadCollection = BbeCollection.extend({

    model: UploadModel,

    initialize(data, options) {
        options = options ?? {};
        if (!this.url && options.url) {
            this.url = options.url;
        }
    }


});