﻿//define('m-departments-departments', ['bus-svc', 'm-appData', 'app-paths', '_libs/identity', 'm-departments-department'], function (svcRadio, appData, paths, identity, Model) {});


import svcRadio from 'bus/svc';
import appData from 'm/appData';
// import paths from '';
// import identity from '';
import Model from './department';

import { BbeCollection } from 'core/bbe';

const DepartmentsCollection = BbeCollection.extend({
	model: Model,
	
	initialize: function () {
		var col = this;
		appData.authData.whenReady().then(function () {
			col.reset(appData.authData.get('departments'));
		});
	},

	getNested(parentId) {
		return this.where({ parentId });
	},

	getName(id) {
		const dep = this.get(id);
		return dep && dep.get('name');
	},

	//url: function () {
	//	return paths.api.departmentsAll;
	//}
});



export const departments = new DepartmentsCollection();


svcRadio.reply('departments', function () {
	return departments;
});

svcRadio.reply('nestedDepartments', function(parentId) {
	return departments.filter(function(m) { return m.get('parentId') == parentId; });
});

svcRadio.reply('department', function(id) {
	return departments.get(id);
});

svcRadio.reply('departmentName', function(id) {
	var dep = svcRadio.request('department', id);
	if (!dep) return;
	return dep.get('name');
});

//svcRadio.reply('departments:remove', function(id) {
//	var col = svcRadio.request('departments');
//       col.remove(id);
//       return col;
//   });


export default DepartmentsCollection;