import { UploadMedia } from "./upload-media-plate";
import { UploadCollection } from "./upload-collection";
import { _, $ } from 'vendors';
import { MneView, MneNextCollectionView } from 'core/mne';
import { modalsApi } from "apis/modals";
import { View } from 'core';

const EmptyView = MneView.extend({
    className: 'no-entries-view',
    template: _.template(' перетащите сюда файлы ')
});

const InputFieldView = MneView.extend({
    template: _.template('<input type="file" style="display: none" class="input-file" />')
});

export default MneNextCollectionView.extend({
    className: 'upload-medias',
    template: _.template('<div> перетащите сюда файлы или выберите их с диска <input type="file" multiple /> </div><section></section>'),
    childViewContainer: 'section',
    // emptyView: EmptyView,
    childView: UploadMedia,
    childViewOptions() {
        return {
            entity: this.model
        }
    },
    initialize() {
        this.collection = this.model.uploadMedias;
        if (!this.collection) {
            this.collection = new UploadCollection([], { url: this.model.url() + '/medias-upload' });
            this.model.uploadMedias = this.collection;
        }
    },
    onRender() {
        // this.addChildView(new InputFieldView());
    },
    events: {
        'click > *'(event) {
            const insideCard = $(event.target).closest('.upload-item').length > 0;
            const insideInput = $(event.target).closest('input[type=file]').length > 0;
            if (insideCard || insideInput) {
                console.log('inside card or input')
                return;
            }
            const input = this.$('input[type=file]');
            input.click();
        },
        async 'change input[type=file]'(event) {
					if (!event.target.files.length) { return; }
					const files = [];
					for(let file of event.target.files) {
						files.push(file);
					}
					event.target.value = '';

					const dataRes = await this.askForAdditionalData();
					console.error(dataRes);
					if (!dataRes.ok) {
						return;
					}

					files.forEach(file => this._processDropedFile(file, dataRes.value))


					
        },
        async drop(e) {
					e.preventDefault();
					e.stopPropagation();
					this.$el.removeClass('drag-over');

					let dt = this._getDataTransfer(e);
					const files = this._extractFiles(dt);
					console.warn('drop: ', files);
					const dataRes = await this.askForAdditionalData();
					console.warn('drop: ', files, dt, dataRes);
					
					if (!dataRes.ok) {
						return;
					}
					this._processDropedFiles2(files, dataRes.value);
        },
        dragover(e) {
					e.preventDefault();
					e.stopPropagation();            
        },        
        dragenter(e) {
					e.preventDefault();
					e.stopPropagation();            
					this.$el.addClass('drag-over');
					
        },
        dragleave(e) {
            e.preventDefault();
            e.stopPropagation();
            this.$el.removeClass('drag-over');
        }
    },
		askForAdditionalData() {
			// const view = new View({ template: () => 'подскажите что вы загружаете'});
			const popupSetup = {
				valueType: 'enum',
				valueSubType: 'realtyMediaTypes',
				header: 'Подскажите что именно вы загружаете',
			}

			return modalsApi.oldEditPropertyAsync(popupSetup);
			
		},
    _getDataTransfer(e) {
        if (e.dataTransfer)
            return e.dataTransfer;
        if (e.originalEvent && e.originalEvent.dataTransfer)
            return e.originalEvent.dataTransfer;
    },
		_extractFiles(dataTransfer) {
			const files = [];
			let itemsWasNotProcessed;
			if (dataTransfer.items) {
				itemsWasNotProcessed = false;
				// Use DataTransferItemList interface to access the file(s)
				for (var i = 0; i < dataTransfer.items.length; i++) {
						// If dropped items aren't files, reject them
						let item = dataTransfer.items[i];
						if (item.kind === 'file') {
								var file = dataTransfer.items[i].getAsFile();
								files.push(file);
						}
				}
			}
			if (itemsWasNotProcessed && dataTransfer.files && dataTransfer.files.length) {
				// Use DataTransfer interface to access the file(s)
				console.warn('dataTransfer files case');
				for (let i = 0; i < dataTransfer.files.length; i++) {
						let file = dataTransfer.files[i];
						files.push(file);
				}
			}
			return files;
		},
    _processDropedFiles(dataTransfer, data) {
				console.log('process dropped files', data, dataTransfer);
        if (!dataTransfer) {
            console.warn('dataTransfer was not provided')
        }

        let itemsWasNotProcessed = true;
        if (dataTransfer.items) {
            itemsWasNotProcessed = false;
            // Use DataTransferItemList interface to access the file(s)
            for (var i = 0; i < dataTransfer.items.length; i++) {
                // If dropped items aren't files, reject them
                let item = dataTransfer.items[i];
                if (item.kind === 'file') {
                    var file = dataTransfer.items[i].getAsFile();
                    this._processDropedFile(file, data);
                }
            }
        }

        if (itemsWasNotProcessed && dataTransfer.files && dataTransfer.files.length) {
            // Use DataTransfer interface to access the file(s)
            console.warn('dataTransfer files case');
            for (let i = 0; i < dataTransfer.files.length; i++) {
                let file = dataTransfer.files[i];
                this._processDropedFile(file, i, data);
            }
        }
    },
		_processDropedFiles2(files, data) {
			for(let file of files) {
				this._processDropedFile(file, data);
			}
		},
    _processDropedFile(file, data) {
				console.log('process dropped file', data, file);
        if (!file) return;
        let hash = _.pick(file, 'lastModifiedDate', 'name', 'size', 'type');
				Object.assign(hash, { mediaType: data });
        hash.state = 'iddle';
        hash.file = file;
        this.collection.add(hash);
    }
});