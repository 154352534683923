﻿//define('svc-deals-deal-inLine', ['bus'], function (Bus) {});

import Bus from 'bus';

import template from './inLine.html';
import { MneView } from 'core/mne';

var View = MneView.extend({
	className: 'deal deal-inLine',
	//template: '#tmpl-service-deals-deal-inLine',
	template,
	events: {
		'click':'openInModal'
	},
	openInModal: function () {
		return this.model.actionOpenModal();
	},
});

Bus.Views.reply('deal:inLine', function () {
	return View;
});

export default View;
