﻿//define('svc-deals-active', ['bus','appModule', 'link', 'm-deals', 'svc-deals-list-layout2'], function (Bus, Module, link, dealsNs, Layout) {});

import Bus from 'bus';
import Module from 'appModule';
import link from 'link';
//import dealsNs from '';
import Layout from '../list/layout2';


var module = Module.content({
	name: 'deal-active',
	label: 'процессы в работе',
	urlKey: 'dealsActive',
	routeData: function () {
		return link.route('dealsActive');
	},
	onBeforeRun: function () {

		var Col = Bus.Models.request('dealsActive');
		var col = new Col();
		
		if (this.query.emp)
			col.setEmployeeId(this.query.emp);

		
		this.addRunPromise(col.loudFetch());
		this.addRunArgument(col);

	},
	onRun: function (col) {

		this.createAndShow(Layout, this.label, { collection: col, dealsType: 'active', itemFilter: function (model) { return model.isNotCompleted(); }, });

	}
});


export default module;
