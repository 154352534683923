// import { onGmapsLoad } from "../../v01/_libs/gmaps/initialize";
import { MapInitializer } from "./MapInitializer";
import { _ } from 'vendors';
import { knownBounds } from "./knownBounds";
import { errResult, invokeValue, mergeObjects, okResult } from "../../utils";

// itrealtyguidecloud@gmail.com
const key = 'AIzaSyBFP9iPCzRPOKKQ8I6tpKVkSk0A5QRXhfk';
const libraries = [
	'visualization',
	//'draw',
	'places',
	'geometry'
];
const url = 'https://maps.googleapis.com/maps/api/js?key=' + key + '&language=ru-RU&libraries=' + libraries.join(',');

const globalMapDefaults = {
	zoom: 11,
	center: { lat: 55.745829121494936, lng: 37.60493597412108 },
}

export class GooglemapsInitializer extends MapInitializer {
	constructor() {
		const callbackName = _.uniqueId('gmap') + 'LoadCallback';
		const onGmapsLoad = () => {};
		super(url + '&callback=' + callbackName, onGmapsLoad, callbackName);
	}

	identifier = 'google';

	name = 'google.next';
	
	supportsMapClusters = true;
	supportsMetroLayer = true;
	
	importNamespace() {
		return import('./google');
	}

	destroyMap(map) {
		// unknown how to destroy google map
	}

	// _attachMapToView(view, options = {}) {
	// 	const el = options.el || view.el;
	// 	const mapOptions = this.buildMapOptions(options);
	// 	console.log('MAP OPTIONS', mapOptions, options);
	// 	const map = this.buildMap(el, mapOptions);
	// 	return { map, mapOptions };
	// }

	_defaultMapOptions() {
		const mapControls = {
			mapTypeControl: true,
			fullscreenControl: false,
			clickableIcons: false,
			mapTypeControlOptions: {
				style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
				position: google.maps.ControlPosition.BOTTOM_RIGHT
			},
		}
		return mapControls
	}

	_buildMapOptions(zoomCenter, mapOptions) {
		let { zoom, center } = zoomCenter;
		const optionsObjects = [{}, globalMapDefaults, this._defaultMapOptions(), { zoom, center }, mapOptions];
		const result = mergeObjects(optionsObjects);
		console.log(result);
		return result;
	}

	buildMap(el, options) {
		return new google.maps.Map(el, options);
	}

	getBounds(map) {
		const bounds = map.getBounds();
		console.log('getBounds', bounds);
		return bounds?.toJSON();
	}

	createClusterOverlayView(options) {
		const { ClusterOverlayView } = this.namespace.views;
		return new ClusterOverlayView(options);
	}

	on (map, eventEntity, appEventName, callback) {
		const listener = eventEntity.addListener(appEventName, callback);
		console.log('map on:', appEventName, eventEntity);
		return listener;
	}

	off (map, eventEntity, appEventName, listener, callback) {
		if (listener) {
			listener.remove();
		} else if (appEventName && google.maps.event.hasListeners(eventEntity, appEventName)) {
			google.maps.event.clearListeners(eventEntity, appEventName);
		}
	}

	updateMap(map, options = {}) {
		let { lat, lng, center, position, zoom, viewport } = options;
		center = (lat && lng ? { lat, lng } : undefined) || position || center;
		if (center) {
			map.setCenter(center);
		}
		if (viewport) {
			map.fitBounds(viewport);
		}
		if (zoom) {
			map.setZoom(zoom);
		}
	}

	setMapCenter(map, center, zoom) {
		
		map.setCenter(center);
		if (zoom) {
			this.setMapZoom(map, zoom);
		}
	}

	setMapZoom(map, zoom) {
		map.setZoom(zoom);
	}

	createMarker({ coords, animated } = {}) {
		const markerOpts = {
			position: coords,			
		}
		if (animated) {
			markerOpts.animation = google.maps.Animation.BOUNCE;
		}
		const marker = new google.maps.Marker(markerOpts);
		return marker;
	}

	showMarker(map, marker) {
		marker.setMap(map);
	}

	hideMarker(map, marker) {
		marker.setMap(null);
	}

	updateMarker(map, marker, options = {}) {
		const { coords } = options;
		if (coords) {
			marker.setPosition(coords);
		}
		if ('draggabale' in options) {
			marker.setDraggable(options.draggabale);
		}
	}

	// deprecated
	_setupSearchInput(map, inputEl) {

		// map.controls[google.maps.ControlPosition.TOP_RIGHT].push(controlEl);
		// console.log('- places input - ', inputEl);

		const bounds = knownBounds.moscow;
		const sw = {
			lat: bounds.south,
			lng: bounds.west,
		}
		const ne = {
			lat: bounds.north,
			lng: bounds.east,
		}
		const searchBounds = new google.maps.LatLngBounds(sw, ne);
		// view.getOption('searchBounds') || knownBounds.get('moscow');

		//inputEl.placeholder = view.attributes.placeholder;
		const searchBox = new google.maps.places.SearchBox(inputEl, {
			bounds: searchBounds, //this.map.getCenter(),
		});
		// var _this = this;

		let searchPlaceMarker;
		searchBox.addListener('places_changed', () => {
			var places = searchBox.getPlaces();

			if (searchPlaceMarker)
				searchPlaceMarker.setMap(null);

			var first = places[0];
			if (!first) return;

			map.fitBounds(first.geometry.viewport);

			map.setZoom(14);

			searchPlaceMarker = new google.maps.Marker({
				position: first.geometry.location,
				map: map,
				title: first.name,
				icon: '//maps.google.com/mapfiles/kml/paddle/wht-blank.png'
				// icon: 'https://mts.googleapis.com/vt/icon/name=icons/spotlight/spotlight-waypoint-blue.png&text=&psize=16&font=fonts/Roboto-Regular.ttf&color=ff333333&ax=44&ay=48&scale=1'
			});

			searchPlaceMarker.addListener('click', () => {
				searchPlaceMarker.setMap(null);
				// view.triggerMethod('search:place:marker:click', this.searchPlaceMarker);
				// if (this.getOption('hideSearchPlaceMarkerOnClick') !== false) {
				// }
			});			
		});

	}

	_getSearchBounds(boundsName = 'moscow') {
		const bounds = knownBounds[boundsName];
		const sw = {
			lat: bounds.south,
			lng: bounds.west,
		}
		const ne = {
			lat: bounds.north,
			lng: bounds.east,
		}
		const searchBounds = new google.maps.LatLngBounds(sw, ne);
		return searchBounds;
	}
	// deprecated
	setupSearchInput(map, inputEl, options = {}) {
		console.log('- setup search input -', options);
		const { bindBounds, callback, controlEl } = options;
		const searchBounds = this._getSearchBounds('moscow');

		// Create the search box and link it to the UI element.
		const searchBox = new google.maps.places.SearchBox(inputEl, { bounds: searchBounds });
		if (controlEl) {
			map.controls[google.maps.ControlPosition.TOP_RIGHT].push(controlEl);
		}
	
		// Bias the SearchBox results towards current map's viewport.
		if (bindBounds) {
			map.addListener("bounds_changed", () => {
				searchBox.setBounds(map.getBounds());
			});
		}
	
		//let markers = [];
		//let placeMarker;
		// Listen for the event fired when the user selects a prediction and retrieve
		// more details for that place.
		if (callback) {
			console.log('- listen for places change -');
			searchBox.addListener("places_changed", () => {
				const places = searchBox.getPlaces();
				console.log('places changed', places);
				callback(places);
			});
	
		}
	}

	// deprecated
	getFindAndShowAddressCallback(map, options = {}) {
		const { destroyMarkerOnClick, animated, onDrag, onPositionChange, title, changePositionByClick } = options;
		const markerOptions = { title };
		if (animated) {
			markerOptions.animation = google.maps.Animation.BOUNCE;
		}
		if (onDrag) {
			markerOptions.draggable = true;
		}

		let marker = new google.maps.Marker(markerOptions);

		if (destroyMarkerOnClick) {
			marker.addListener('click', () => {
				marker.setMap(null);
			});
		}

		if (onDrag) {
			marker.addListener('dragend', () => {
				const coords = marker.position.toJSON();
				onDrag(coords);
			});
		}



		const changeMarkerPosition = (latLng) => {
			marker.setPosition(latLng);
			if (onPositionChange) {
				const coords = marker.position.toJSON();
				onPositionChange(coords);
			}
		}

		if (changePositionByClick) {
			map.addListener('click', eventContext => {
				changeMarkerPosition(eventContext.latLng);
			});
		}

		const callback = places => {
			marker.setMap(null);

			var first = places[0];
			if (!first) return;
			map.fitBounds(first.geometry.viewport);
			map.setZoom(14);

			marker.setMap(map);
			changeMarkerPosition(first.geometry.location);

			// marker.setPosition(first.geometry.location);
			// if (onPositionChange) {
			// 	const coords = marker.position.toJSON();
			// 	onPositionChange(coords);
			// }

			// searchPlaceMarker = new google.maps.Marker({
			// 	position: first.geometry.location,
			// 	map: map,
			// 	title: first.name,
			// 	icon: '//maps.google.com/mapfiles/kml/paddle/wht-blank.png'
			// 	// icon: 'https://mts.googleapis.com/vt/icon/name=icons/spotlight/spotlight-waypoint-blue.png&text=&psize=16&font=fonts/Roboto-Regular.ttf&color=ff333333&ax=44&ay=48&scale=1'
			// });
			// searchPlaceMarker.addListener('click', () => {
			// 	searchPlaceMarker.setMap(null);
			// });			
		}
		console.log(' - creating search places callback -');
		return callback;
	}


	setupAddressApi(map, options = {}) {
		const { searchControl, marker } = options;
		let addressPin;
		let onPositionChange;
		let addressPinPosition;
		let searchBox;
		let shouldUpdateMarker;
		let shouldUpdateMarkerBySearch;

		const onMarkerPositionChange = async (places) => {
			if (!addressPin || !onPositionChange) { return; }
			const center = addressPin.position.toJSON();
			if (!places) {
				let placesRes = await this.geodecode(center.lat, center.lng);
				places = placesRes.value || {};
			}
			const hash = this.geodecodeToHash(places, center);
			console.log(' - geodecodeToHash - ', places, center);
			onPositionChange(hash);
		}

		const changeMarkerPosition = async (center, places) => {
			if (!addressPin) { return; }
			addressPin.setMap(map);
			addressPin.setPosition(center);
			onMarkerPositionChange(places);
		}

		const changeMarkerPositionByPlaces = places => {
			if (!places) { return; }
			const geometry = places[0]?.geometry || {};

			const { viewport, location } = geometry;
			const center = location?.toJSON() || undefined;
			
			this.updateMap(map, { viewport, center });
			const shouldUpdate = invokeValue(shouldUpdateMarkerBySearch);

			if (center && shouldUpdate) {
				changeMarkerPosition(center, places);
			}
		}

		const changeMarkerPositionByTextAddress = async (text) => {
			const geocodeRes = await this.geocode(text);
			changeMarkerPositionByPlaces(geocodeRes.value);
		}



		if (marker) {
			onPositionChange = marker.onPositionChange;
			const { updateAllowed, destroyMarkerOnClick, updatePositionOnMapClick, updatePositionOnDragend, lat, lng } = marker;
			shouldUpdateMarker = updateAllowed;
			let position = (lat != null && lng != null) ? { lat, lng } : undefined;
			addressPinPosition = position;
			const addressPinOptions = {
				position
			};
			addressPin = new google.maps.Marker(addressPinOptions);
			if (position) {
				addressPin.setMap(map);
			}
			if (destroyMarkerOnClick) {
				console.log('DESTROY', 'click');
				addressPin.addListener('click', () => {
					addressPin.setMap(null);
				});
			}
			if (updatePositionOnMapClick) {
				map.addListener('click', event => {
					console.log('- map click -');
					const shouldUpdate = invokeValue(shouldUpdateMarker);
					if (shouldUpdate) {
						changeMarkerPosition(event.latLng)
					}					
				});
			}
			if (updatePositionOnDragend) {
				addressPin.addListener('dragend', () => {
					console.log('- drag end -');
					onMarkerPositionChange()
				})
			}
		}

		
		if (searchControl?.inputEl) {
			const { inputEl, controlEl, updateMarker } = searchControl;
			shouldUpdateMarkerBySearch = updateMarker;
			const searchBounds = this._getSearchBounds('moscow');
			// Create the search box and link it to the UI element.
			searchBox = new google.maps.places.SearchBox(inputEl, { bounds: searchBounds });
			if (controlEl) {
				map.controls[google.maps.ControlPosition.TOP_RIGHT].push(controlEl);
			}

			searchBox.addListener("places_changed", () => {
				const places = searchBox.getPlaces() || [];
				changeMarkerPositionByPlaces(places);
			});			

			if (addressPin && !addressPinPosition && inputEl.value) {
				changeMarkerPositionByTextAddress(inputEl.value);
			}

		}

		return { addressPin, searchBox };

	}


	createMetroLayer(map) {
		const { GoogleMetroLayer } = this.namespace.layers;
		return new GoogleMetroLayer({ map, engine: this });
	}

	_getGeocoder() {
		if (!this.geocoder) {
			this.geocoder = new google.maps.Geocoder();
		}
		return this.geocoder;
	}

	geocode(addressText, options) {
		const geocoder = this._getGeocoder();
		return new Promise((resolve, rej) => {
			geocoder.geocode({ address: addressText }, geocodeCallback(resolve, options));
		});
	}

	geodecode(lat, lng, options) {
		const geocoder = this._getGeocoder();
		const latlng = { lat, lng };
		return new Promise((resolve, rej) => {
			geocoder.geocode({ location: latlng }, geocodeCallback(resolve, options));
		});
	}

	_processGeodecodeResults (results = [], parse)  {
		if (!results.length) {
			return;
		}
	
		let found;
		let types = ['street_address', 'route'];
		
		results.some(result => {
			if (result.types && result.types.some(type => types.indexOf(type) > -1)) {
				found = result;
				return true;
			}
		});
	
		if (!found) {
			return;
		}
		
		console.log(' -- convertGeocodeResults -- ', found, parse);
	
		let components;
		if (parse == 'hash' || parse == null) {
			components = found.address_components.reduce((memo, cmp) => {
				memo[getComponentType(cmp.types)] = cmp.short_name;
				return memo;
			}, {});
		} else if (parse == 'array') {
			components = found.address_components.map((cmp) => {
				return {
					key: getComponentType(cmp.types),
					value: cmp.short_name
				}
			});
		}
	
		return {
			fullAddress: found.formatted_address,
			components
		}
	}

	geodecodeToHash(results = [], add = {}) {

		if (!results.length) {
			return;
		}
	
		let found;
		let routeFounded;
		// let street_address_res, route_res;
		// let types = ['street_address', 'route'];
		
		for (let result of results) {
			if (result.types) {
				for (let resultType of result.types) {
					if (resultType === 'street_address') {
						found = result;
						break;
					}
					if (resultType === 'route') {
						routeFounded = result;
					}
				}
				if (found) {
					break;
				}
			}
		}
		
		found = found || routeFounded;
		if (!found) {
			return;
		}

		// results.some(result => {
		// 	if (result.types && result.types.some(type => types.indexOf(type) > -1)) {
		// 		found = result;
		// 		return true;
		// 	}
		// });
		
		// if (!found) {
		// 	return;
		// }
	
		let components = found.address_components.reduce((memo, cmp) => {
			const key = getComponentType(cmp.types);
			const value = getComponentValue(key, cmp);
			memo[key] = value;
			// cmp.short_name;
			return memo;
		}, {});

		const result = mergeObjects([ {}, components, add ]);
		result.fullAddress = found.formatted_address;

		return result;
	}

	geodecodeHashToAddress(hash) {

		// House 
		// Route 
		// Sublocality
		// Locality 
		// AdministrativeArea2
		// AdministrativeArea1
		// Country 
		// PostalCode 


		var obj = {
				lat: lat,
				lng: lng
		};
		var components = [];
		_.each(gResults, function (entry) {
				components = components.concat(entry.address_components);
		});
	
		applyComponents(obj, components);
		let { administrativeArea1, administrativeArea2 } = obj;
	
		if (administrativeArea1 === "Мосцощ Област"
				&& administrativeArea2 === "Москва") {
				obj.administrativeArea1 = administrativeArea2;
		}
	
		var full = findComponent(gResults, "street_address") || findComponent(gResults, "postal_code");
		obj.fullAddress = full && full.formatted_address
	
		if (obj.locality == "Москва" && !!obj.slocality1) {
				obj.slocality1 = _(obj.slocality1.split(' ')).map(function (s) { return s[0]; }).join('').toUpperCase();
		}
		console.log('###', obj);
		return obj;
	}
}

function geocodeCallback(resolvePromise, options = {}) {
	return (results, status) => {
		let ok = status === "OK";
		let promiseResult;
		if (!ok) {
			promiseResult = errResult(results);
		} else {
			let value = results;
			let { parse } = options;
			if (typeof parse === 'function') {
				value = parse(value);
			}
			promiseResult = okResult(value);
		}

		resolvePromise(promiseResult);
	}
}
const keyRemap = {
	'street_number': 'house',
	'route': 'route',
	'sublocality_level_1': 'sublocality',
	'sublocality_level_2': 'sublocality2',
	'locality': 'locality',
	'administrative_area_level_3': 'administrativeArea3',
	'administrative_area_level_2': 'administrativeArea2',
	'administrative_area_level_1': 'administrativeArea1',
	'country': 'country',
	'postal_code': 'postalCode'
}
const keyValueFields = {
	country: 'long_name',
	administrativeArea1: 'long_name',
	administrativeArea2: 'long_name',
	administrativeArea3: 'long_name',
}

function getComponentType(types = []) {
	for(let x = 0; x< types.length;x++) {
		let type = types[x];
		if (type != 'political') {
			if (type in keyRemap) {
				return keyRemap[type];
			}
			return type;
		}
	}
}

function getComponentValue(key, cmp) {
	const valueKey = keyValueFields[key] || 'short_name';
	return cmp[valueKey];
}

function findComponent(arr, type) {
	return _(arr).find(function (c) {
			return c.types.indexOf(type) >= 0;
	});
}

function applyComponents(obj, arr) {
	var fields = {
			'house': 'street_number',
			'route': 'route',
			'sublocality2': 'sublocality_level_2',
			'sublocality': 'sublocality_level_1',
			'locality': 'locality',
			'administrativeArea2': 'administrative_area_level_2',
			'administrativeArea1': 'administrative_area_level_1',
			'country': 'country',
			'postalCode': 'postal_code'
	}

	_(fields).each(function (type, field) {

			var found = findComponent(arr, type);

			var prop = 'long_name';
			switch (field) {
					case "route":
							prop = 'short_name';
							break;
					case "sublocality":
							prop = 'short_name';
							break;
					default:
							prop = 'long_name';
							break;
			}
			// console.log('>>>:', type, field, prop, found);
			obj[field] = found && found[prop];
	});

}
