﻿//define('m-deals-dealsSearch', ['m-deals-deal','app-paths'], function (Deal, paths) {});

import { DealModel } from './deal';
import paths from 'app-paths';

import { BbeCollection } from 'core/bbe';


var DealsSearch = BbeCollection.extend({
	model: DealModel,

	url: function () {
		return paths.api.dealsSearch;
	},
});

export default DealsSearch;
