import { factory } from './factory';

//let Model, factory;

// STATIC MIXIN
// Model.factory();
export const modelFactoryStaticMixin = {
	factory(hash, options) {

		if (this.prototype.factoryName) {
			var args = [].slice.call(arguments);
			args.unshift(this.prototype.factoryName);
			return factory.model.apply(factory, args);
		} else {
			return new this(hash, options);
		}	
	}
}