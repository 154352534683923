﻿//define('geo-reference-model', ['bus','app-config','m-singleFetch', '_libs/identity', 'app-paths'], function (Bus, cfg, SingleFetch, identity, paths) {});

import Bus from 'bus';
import cfg from 'app-config';
import SingleFetch from 'm/abstract/singleFetch'; 
import identity from '_libs/identity';
import paths from 'app-paths';

import metroTemplate from './metro.html';

import { bbeViewComparator } from 'utils';

import { BbeModel, BbeCollection } from 'core/bbe';

import { MneView } from 'core/mne';
import { svcAuthAction } from '../../../mods/acc';

// import { authPromise } from 'coms/acc';

function processParent(model, data, options) {


	var parentCol = options.parentCol;
	if (!parentCol) return;
	var parentKey = options.parentKey ;
	var key = options.key;

	var parentId = parentKey + 'Id';
	var thisMany = key + 's';
	if (key.endsWith('y'))
		thisMany = key.substring(0, key.length - 1) + 'ies';


	var parent = parentCol.get(data[parentId]); //??

	if (parent) {
		model[parentKey] = parent;
		let parentLocalCol = parent[thisMany];
		if (!parentLocalCol) {
			parentLocalCol = parent[thisMany] = new BbeCollection([], {comparator:this.collection.comparator});
		}
		parentLocalCol.add(this);
		//data[parentKey] = parent;
		// if (!parent.get(thisMany)) {
		//     var hash = {};
		//     hash[thisMany] = new BbeCollection([], {comparator:this.collection.comparator});
		//     parent.set(hash, { silent: true });
		// }
		// var parentLocalCol = parent.get(thisMany);
		// if (parentLocalCol) parentLocalCol.add(this);
	} else {
		console.log(parentId, thisMany, data);
	}
}


var NestedModel = BbeModel.extend({
	parse: function (data, options) {
		var model = this;
		if (options.parents) {
			_(options.parents).each(function(opts) {
				processParent.call(model, model, data, opts);
			});
		}
		return data;
	}
});

var defaultStationComparator = function (a, b) {
	a = a instanceof MneView ? a.model : a;
	b = b instanceof MneView ? b.model : b;
	return bbeViewComparator(
		[[a, b, function () { 
			const lineId = this.get('lineId');
			const line = getLine(lineId);
			const number = line.get('number');
			return number;
		}]
		, [a, b, function () { return this.get('orderInLine'); }]]
	);
		//[
		//	['a', function () { return this.get('line').get('number'); }],
		//	['a', function () { return this.get('orderInLine'); }]
		//]);
}

var RegionModel = NestedModel.extend({
	getMetroLines: function () {
		return this.lines; // this.get('lines');
	},
	getStations() {
		//console.log('# EXAMINE', this);
		return this.stations.models;
	},
	getMetroStationsHash: function() {


		var stations = this.stations; //this.get('stations');
		if (!stations) return;
		if (!stations.comparator) {
			stations.comparator = defaultStationComparator;
		}
		//console.log('???',stations.comparator)


		return stations;

	},
	getMetroStations: function () {
		return this.getMetroStationsHash();
	}
});

var MetroModel = NestedModel.extend({
	latlng: function () {
		return { lat: this.get('lat'), lng: this.get('lng') };
	},
	getLabel: function() {

		//return this.get('name');

		var template = metroTemplate; //MnTemplateCache.get("#tmpl-geo-reference-metro");
		var html = template({ 
			...this.toJSON(),
			line: this.line
		});
		return html;

	}
});

var NestedCol = BbeCollection.extend({
	model: NestedModel,
	getByIdString: function (id) {
		return this.findWhere({ idString: id });
	}
});


var Address = BbeModel.extend({
	latlng: function (google) {
		if (!google)
			return { lat: this.get('lat'), lng: this.get('lng') };
		else
			return new google.maps.LatLng(this.get('lat'), this.get('lng'));
	},
	initialize: function () {
		var metro = this.get('metro');
		if (metro && metro.stationId) {
			var station = Bus.app.request('geo:refs:metro:stations', metro.stationId);
			(station && (station = station.toJSON())) || (station = {});
			var line = Bus.app.request('geo:refs:metro:lines', station.lineId);
			(line && (line = line.toJSON())) || (line = {});
			var json = {
				station: station.name,
				line: line.name,
				lineNumber: line.number
			}
			
			_.extend(metro, json);
		}
	}
});

var Col = BbeModel.extend({
	//initialize: function () {
	//	this.runPromise = $.Deferred();
	//},
	storeKey: 'allgeos',
	storeOptions: {
		absolute: Date.MINUTES(5),
		parseBeforeSet: true,
	},
	get(key) {
		return this[key];
	},
	parse: function (data) {
		//console.log('[ ## geo parse ]', data);
		this.allMetroLines = new BbeCollection(data.metroLines);
		this.allMetroStations = new BbeCollection(data.metroStations, { model: MetroModel });

		var countries = new BbeCollection((data.countries || []));
		// delete data.countries;
		// data.countries = countries;
		this.countries = countries;

		var regions = new NestedCol((data.regions || []), { 
			model: RegionModel, 
			parents: [
				{ parentCol: countries, key: 'region', parentKey: 'country', }
			], parse:true
		});
		// delete data.regions;
		// data.regions = regions;
		this.regions = regions;

		var subjects = new NestedCol((data.subjects || []), { parents: [{ parentCol: regions, key: 'subject', parentKey: 'region', }], parse: true });
		// delete data.subjects;
		// data.subjects = subjects;
		this.subjects = subjects;

		var metroLines = new NestedCol((data.metroLines || []), { parents: [{ parentCol: regions, key: 'line', parentKey: 'region', }], parse: true });
		// delete data.metroLines;
		// data.metroLines = metroLines;
		this.metroLines = metroLines;



		var metroStations = new NestedCol((data.metroStations || []), {
			model: MetroModel,
			parents: [{ parentCol: metroLines, key: 'station', parentKey: 'line', }, { parentCol: regions, key: 'station', parentKey: 'region', }],
			parse: true,
		});
		//console.log(metroStations);
		// delete data.metroStations;
		// data.metroStations = metroStations;
		this.metroStations = metroStations;


		var addresses = new BbeCollection(data.addresses, {model: Address});
		//data.addresses = addresses;
		this.addresses = addresses;

		//console.log('## GEO MODEL', this);
		return data;
	},
	getRegions: function () {
		// var regions = this.get('regions');
		// return regions;
		//console.log('# get regions', this);
		return this.regions;
	},
	getRegionByIdString: function (id) {
		var regions = this.getRegions();
		if (!regions) return;
		var region = regions.getByIdString(id);
		return region;
	},
	url: function() {
		let url = cfg.apiSvcUrl('geo', 'reference', 'all');
		return url;
	},

	...SingleFetch,
	// populate() {
	//debugger;
	// 	return SingleFetch.populate.apply(this, arguments);
	// }
});

//_.extend(Col.prototype, SingleFetch);

var col = new Col();
const geoRefs = col;

function getLine(id) {
	return col.allMetroLines.get(id);
}

col.apiPromise = new Promise((res, rej) => {
	col.listenTo(identity, 'change:currentService', () => {
		const svc = identity.get('currentService');
		if (svc) {
			res();
		}
	})
	// authPromise.then(() => col.populate());
})

svcAuthAction(() => {
	// return col.populate();
});

// paths.readyPromise.then(() => {
// 	if (identity.isLogged()) {
// 		col.populate();
// 	}
// });

//col.populate({ promises: [identity.ifSvcAuth()]});
export {
	geoRefs
}

Bus.app.reply('geo:refs', function () {
	return col;
});

Bus.app.reply('geo:refs:address', function (id) {
	var addresses = col.addresses; //col.get('addresses');
	return addresses.get(id);
});

Bus.app.reply('geo:refs:metroByAddress', function (id) {
	var address = Bus.app.request('geo:refs:address', id);
	if (!address) return;
	return address.get('metro');
});

Bus.app.reply('geo:refs:metroLabelByAddress', function (id) {
	var metro = Bus.app.request('geo:refs:metroByAddress', id);
	if (!metro) return;
	if (metro.label) return metro.label;



	var station = Bus.app.request('geo:refs:metro:stations', metro.stationId);
	//console.log(station, metro.stationId, metro);
	if (!station) return;

	if (station.label) {
		metro.label = station.label;
		return station.label;
	}

	if (!station.lineNumber) {
		var line = Bus.app.request('geo:refs:metro:lines', station.get('lineId'));
		if (!line) return;
		station.lineNumber = line.get('number');
	}
	var d = Math.round(Math.round(metro.distance) / 50) * 50;
	station.label = '<span class="clr-metro-' + station.lineNumber + ' txt">' + station.get('name') + ' (' + d + 'м)</span>';
	metro.label = station.label;
	return station.label

});


Bus.app.reply('geo:refs:metro:stations', function (id) {
	var metro = col.allMetroStations;
	if (id == undefined)
		return metro;
	return metro.get(id);
});

Bus.app.reply('geo:refs:metro:lines', function (id) {
	var metro = col.allMetroLines;
	if (id == undefined)
		return metro;
	return metro.get(id);
});


//console.log('GEO', col);
export default col;