//define('ui/layouts/actions-panel', ['ui-layouts-selectedPane'], function(SelectedPane) {});

import SelectedPane from 'ui/layouts/selectedPane'
import FastFilters from 'ui/controls/fastfilters';
import { HamburgerView } from 'coms/ui/HamburgerView';
import { isView, isViewClass } from 'utils';
	
// let template = _.template('<div class="result-stats-region"></div><div class="fast-filters-region"></div><div class="selected-pane-region"></div><div class="additional-pane-region"></div>')
// import { MneView } from 'core/mne';
// var View = MneView.extend({
// 		className: 'actions-panel actions-panel-left',
// 		template,
// 		regions: {
// 			'fast-filters': { el: '.fast-filters-region', replaceElement: true },
// 			'stats': { el: '.result-stats-region', replaceElement: true },
// 			'selected': { el: '.selected-pane-region', replaceElement: true },
// 			'additional': { el: '.additional-pane-region', replaceElement: true },
// 		},
// 		onRender: function () {
//debugger;
// 			this.showStats();
// 			this.showFastFilters();
// 			this.showSelectedView();
// 			this.showAdditionalPane();
// 		},
// 		showStats: function() {
// 			var view = this.getOption('statsView');
// 			if (view) this.showChildView('stats',view);
// 		},
// 		showFastFilters: function() {
// 			var view = this.getOption('fastFiltersView');
// 			if (view) this.showChildView('fast-filters', view);
// 		},
// 		showSelectedView: function () {
//debugger;
// 			const selectionHolder = this.getOption('selectionHolder');
// 			if (!selectionHolder) { return; }
// 			var view = new SelectedPane({
// 				selectionHolder: this.getOption('selectionHolder'),
// 				filterObject: this.getOption('filterObject'),
// 				selectedComparators: this.getOption('selectedComparators'),
// 				selectedActions: this.getOption('selectedActions'),
// 			});

// 			if (view)
// 				this.showChildView('selected', view);
// 		},
// 		showAdditionalPane: function () {
// 			var View = this.getOption('additionalView');
// 			var options = this.getOption('additionalViewOptions');
// 			if (!View) return;
// 			var view = new View(options);
// 			this.showChildView('additional', view);
// 		}
// });

// export default View;

const ActionsPanel = HamburgerView.extend({
	baseClassName: 'actions-panel actions-panel-left',
	getChildren() {
		const views = [
			this.getStats(),
			this.getFastFilters(),
			this.getSelectedView(),
			this.getAdditionalPane(),
		];
		return views;
	},
	getStats: function() {
		return this._getView('statsView');
		// var view = this.getOption('statsView');
		// return view;
		// if (view) this.showChildView('stats',view);
	},
	getFastFilters: function() {
		const predefined = this._getView('fastFiltersView');
		if (predefined) {
			return predefined;
		}
		const filterObject = this.getOption('filterObject');
		if (!filterObject) { return; }

		return {
			class: FastFilters,
			filters: filterObject
		};
		// if (view) this.showChildView('fast-filters', view);
	},
	getSelectedView: function () {
		const predefined = this._getView('selectedView');
		if (predefined) {
			return predefined;
		}

		const selectionHolder = this.getOption('selectionHolder');
		if (!selectionHolder) { return; }

		const selectedViewOptions = this.getOption('selectedViewOptions');

		return Object.assign({
			class: SelectedPane,
			selectionHolder: this.getOption('selectionHolder'),
			filterObject: this.getOption('filterObject'),
			selectedComparators: this.getOption('selectedComparators'),
			selectedActions: this.getOption('selectedActions'),
		}, selectedViewOptions);

	},
	getAdditionalPane: function () {
		return this._getView('additionalView');
		// var View = this.getOption('additionalView');
		// var options = this.getOption('additionalViewOptions');
		// if (!View) return;
		// var view = new View(options);
		// return view;
		// this.showChildView('additional', view);
	},
	_getView(key) {
		const View = this.getOption(key, true);
		if (isView(View)) {
			return View;
		} else if (isViewClass(View)) {
			const viewOptions = Object.assign({ class: View, }, this.getOption(key + 'Options', true));
			return viewOptions;
		} else if (View && typeof View === 'object') {
			return View;
		}
	}
});

export default ActionsPanel;