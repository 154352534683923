import { View } from 'core';
import { _ } from 'vendors';

import './PopupView.less';

// export const PopupView = View.extend({
//     className: 'floatui-popup',
//     template: _.template('<section></section><div data-role="arrow"></div>'),
//     regions: {
//         content: 'section'
//     },
//     onRender() {
//         let View = this.getOption('contentView');
//         let options = this.getOption('contentOptions');
//         this.showChildView('content', new View(options), { replaceElement: true });
//     }
// });


export const PopupView = View.extend({
    baseClassName: 'own-popup',
    // className() {
    //     let cls = ['own-popup'];
    //     let p = this.getOption('popupClass');
    //     if (p) {
    //         cls.push(p);
    //     }
    //     return cls.join(' ');
    // },
    initialize() {
        let maxWidth = this.getOption('maxWidth');
        if (maxWidth) {
            this.$el.css('max-width', maxWidth);
        }
    },
    // attributes: {
    //     role: 'tooltip'
    // },

    template: `<div class="popup-content <%= contentClass || ''%>"></div><div class="arrow"></div>`,

    arrowSelector: '.arrow',

    templateContext() {
        return {
            contentClass: this.getOption('contentClass')
        }
    },

    regions: {
        content: '.popup-content',
    },

    onRender() {
        let content = this.buildContentView();
        content && this.showChildView('content', content, { replaceElement: true });
    },

    buildContentView() {
        let View = this.getOption('contentView');
        let options = this.getOption('contentOptions');
        if (View) {
            return new View(options);
        }
    },

})


export function popupView(contentView, contentOptions) {
    return {
        class: PopupView,
        contentView, 
        contentOptions
    }
}