﻿//define('ui-controls-filter', ['ui-controls-editValue','behaviors-dynamicClass'], function(EditValue, BehaviorDynamicClass) {});

import EditValue from '../editValue';
import BehaviorDynamicClass from 'behaviors/dynamicClass';

import template from './control.html';
import './filter-view.less';
import { MneView } from 'core/mne';
import { moment } from 'vendors';

var View = MneView.extend({
	className: 'filters-control-item',
	//template: '#tmpl-ui-controls-filter-control',
	template,
	behaviors: [BehaviorDynamicClass],
	dynamicClass: function() {
		if (this.model.hasValue()) {
			//console.log('valued', this.model);
		}
		return (this.model.hasValue() ? "valued" : "");
	},
	modelEvents: {
		'view:refresh':'render',
	},
	triggers: {
		'click span': 'clicked',
		'click button.close': 'reset',
	},
	onReset: function() {
		this.model.clearValues();
	},
	onClicked: function (view, event) {
		var m = this.model;

		//console.log(m);

		var dependency = m.get('dependency');
		var viewFilter = m.get('viewFilter');
		if (dependency && dependency.filterSourceValues) {
			var fcfg = dependency.filterSourceValues;
			var dependOn = m.collection.get(_.result(fcfg, 'filters'));
			var dependKeys = dependOn && dependOn.getValue({ asArray: true }) || [];
			if (!(dependKeys instanceof Array)) dependKeys = [dependKeys];
			var allowed = _(dependKeys).chain().map(function (dk) {
								return fcfg.allowed[dk] || []
							}).flatten().value();

			var func = function(view) {
				return allowed.indexOf(view.model.get('value')) >= 0;
			};
			viewFilter = func;
		}

		var opts = {
			header: m.get('caption'),
			multiple: m.get('multiple') === true,
			modelType: m.get('modelType'),
			valueType: m.get('valueType'),
			initialValue: m.getValue({asArray:true}),
			sourceValues: m.get('sourceValues'),
			controlType: m.get('controlType'),
			viewFilter: viewFilter,
			suggestions: m.get('suggestions'),
			noComparator: m.get('noComparator'),
			onChange: function (values) {
				console.log('on:change', values);
				m.applyValues(values);
			},
			
		}
		_.extend(opts, m.get('extOpts'));
		//console.log('filter', opts);
		EditValue.modal(opts, { modalCssCfg: 'shortAutoHeight'}).done(function (type, value, initialValue) {
			//apply values if you dont need instant changes
		}).fail(function (type, newValue, initialValue) {
			if (type === 'cancel') {
				m.resetValuesTo(initialValue);
			}
			else if (type === 'reset') {
				m.clearValues();
			}
		});
	},
	templateContext: function () {
		//console.log(this.model);
		var caption = this.model.get('caption');
		var display = this.model.get('display');
		var label;
		//console.log('	FILTER >', this);
		if (_.isFunction(display)) {
			label = display.call(this.model, this, this.model);
		} else {
			let displayValue = this.model.getValue();
			if (_.isDate(displayValue)) {
				label = moment(displayValue).format("DD.MM.YYYY");
			} else {
				label = _.lbl(this.model);
			}
		}

		return {
			caption: caption,
			label: label,
		}
	}
});

export default View;
