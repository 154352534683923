module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="value-from value">\n	<b> С </b>\n	<div class="time-wrapper"></div>\n	<div class="date-wrapper">\n		<span class="date">'+
((__t=( moment(from).format('DD MMM, YYYY') ))==null?'':__t)+
'</span>\n	</div>\n</div>\n<div class="value-to value">\n	<b> ПО </b>\n	<div class="time-wrapper"></div>\n	<div class="date-wrapper">\n		<span class="date">'+
((__t=( moment(to).format('DD MMM, YYYY') ))==null?'':__t)+
'</span>\n	</div>\n</div>';
}
return __p;
};
