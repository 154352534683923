import { BaseObject } from 'core';
import { userFeature } from './common';
import { IdSetApi } from 'utils';
import { _, debounce } from 'vendors';
import { mapsBackend } from './mapsBackend';
import { userConfig } from 'mods/acc';
// import { MapEventsManager } from './MapEventsManager';
// import { geoRefs } from 'vXX/geo/reference/model';
//import { getRealtyLatLng } from '../../v01/m/realties';
import { getRealtyLatLng } from 'mods/realties';
import { geoReference } from '../../mods/geo';
import { MapStateDataModel, MapStateData } from './MapStateDataModel';

export const SearchMapApi = BaseObject.extend({

	initialize(options) {
		this.realtyClusterViews = new IdSetApi();
		this.mergeOptions(options, ['mapFilters', 'mapsApi']);
		// const entryRealtyId = this.entry?.realtyId;
		const opts = this.getOptions(['entry', 'selectionHolder', 'existRealtyIds', 'denySelect', 'asideModel']);
		this.mapStateData = new MapStateData(opts);
	},

	start({ mapView, map, engine } = {}) {
		if (this._isStarted) { return; }
		this._isStarted = true;

		Object.assign(this, { mapView, map, engine });

		// this.mapEvents = new MapEventsManager(this.engine, this.map);
		// this.on('before:destroy', () => this.mapEvents.off());

		this._setupMapEvents();
		this._createMetroLayer();
		this._setupFeaturesListeners();
		this._setupSelectionHolder();

		userConfig.on('all', (c,a) => console.log('[user conf]', c, a));

	},

	_setupMapEvents() {
		const mapsApi = this.mapsApi;
		let firstIddle = true;
		
		const map = this.map;

		// const idleCallback = debounce(() => {
		// 	console.warn('	- map idle callback -', map.bounds);
		// 	let newbounds = this.updateBounds();
		// 	if (firstIddle) {
		// 		this.listenTo(this.mapFilters, 'change', this.fetchClusters);
		// 		if (newbounds) {
		// 			this.fetchClusters();				
		// 		}
		// 	}
		// 	firstIddle = false;
		// }, 500);
		
		const clusters = this.getClusters();
		this.on('before:destroy', () => clusters.clear());
		this.listenTo(clusters, {
			'click:point': (event, opts) => this.trigger('map:click', 'point', opts, event),
			'click:cluster': (event, opts) => this.trigger('map:click', 'cluster', opts, event),
		});

		const onActionStart = () => {
			console.log('on:action:start');
			clusters.clearClusters({ leavePoints: true });
			//this.removeMapClusters();
			console.log('clusters removed');
		}

		const fetchData = () => clusters.fetchClusters({ mapFilters: this.mapFilters, entryRealtyId: this.mapStateData.entryRealtyId, showOnMap: userFeature('points') });
		//this.fetchMapPoints.bind(this); // this.fetchClusters.bind(this);
		const onUpdateEndCallback = () => {
			let newbounds = this.updateBounds();
			if (firstIddle) {
				this.listenTo(this.mapFilters, 'change', fetchData);
				if (newbounds) {
					// this.removeMapClusters();
					// this.fetchClusters();
					fetchData();
				}
			}
			firstIddle = false;
			console.log('on:update:end');
		}

		mapsApi.addOnResize({ 
			map, view: this.mapView,
			delay: 500,
			onStart: onActionStart,
			onEnd: () => document.location.reload() 
		});

		mapsApi.addOnUpdate({
			map, view: this.mapView,
			delay: 500,
			onStart: onActionStart,
			onEnd: onUpdateEndCallback,
			invokeStart: true,
			invokeEnd: true,
		});

		console.warn('MAP EVENTS HANDLERS SETTLED');
		
		// mapsApi.addViewEvent(map, map, 'idle', idleCallback, this);

	},


	_createMetroLayer: function () {
		if (!this.engine.supportsMetroLayer) { return; }


		// const feature = new ymaps3.YMapFeature({
		// 	geometry: {
		// 			type: 'Circle',
		// 			radius: 10,
		// 			coordinates: [37.40108963847453, 55.70173382087952]
		// 	},
		// 	style: {
		// 			stroke: [{width: 12, color: 'rgb(14, 194, 219)'}]
		// 	}
		// });
		// this.map.addChild(feature);

		const metro = this.engine.createMetroLayer(this.map);
		if (!metro) return;

		this.metro = metro;
		this.on('before:destroy', () => metro.destroy());
		// this.listenTo(metro, 'station:click', (station) => this.trigger('station:click', station));
		// this.listenTo(metro, 'line:click', (line) => this.trigger('line:click', line));

		this.listenTo(metro, {
			'station:click': (event, opts) => this.trigger('map:click', 'station', opts, event),
			'line:click': (event, opts) => this.trigger('map:click', 'line', opts, event),
		});

		const visible = !!userFeature('subway');
		if (visible) {
			metro.show();
		}
		// const region = geoRefs.getRegionByIdString('r_moscow');
		// const lines = region.getMetroLines();
		// const stations = region.stations;
		
		// this.metro = new Metro();
		// this.metro.populate(lines, stations);
		// this.metro.setMap(this.map);

		// const visible = !!userFeature('subway');
		// if (visible) {
		// 	this.metro.show();
		// }

		// this.listenTo(this.metro, { 
		// 	'station:click': station => this.trigger('station:click', station),
		// 	'line:click': line => this.trigger('line:click', line)
		// });

	},

	_setupFeaturesListeners() {
		this.listenTo(userConfig, {
			'map:search:features:points:change': value => {
				if (!this.engine.supportsMapClusters) { return; }
				this.toggleMapClusters(value);
				// if (value) {
				// } else {
				// 	this.removeMapClusters();
				// }
			},
			'map:search:features:subway:change': value => {
				if (!this.engine.supportsMetroLayer) { return; }
				if (value) {
					this.metro.show();
				} else {
					this.metro.hide();
				}
			},
		});
	},

	toggleMapClusters(tru) {
		const clusters = this.getClusters();
		clusters.toggleClusters(tru);
	},
	_setupSelectionHolder() {
		const selectionHolder = this.mapStateData.selectionHolder;
		if (!selectionHolder) { return console.error('NO SELECTION HOLDER'); }
		console.error('SELECTION HOLDER HANDLED')
		this.listenTo(selectionHolder, 'after:change', ({ selectedRealties = [], unselectedRealties = [] } = {}) => {
			console.log('SELECTIONHOLDER','after:change', selectedRealties, unselectedRealties);
			const clusters = {};
			//selectedRealties.forEach(id => this._toggleSelectedForActions(id, true, clusters));
			for (let id of selectedRealties) {
				this._toggleSelectedForActions(id, true, clusters);
			}

			for (let id of unselectedRealties) {
				this._toggleSelectedForActions(id, false, clusters);
			}

			for (let id in clusters) {
				const cluster = clusters[id];
				cluster.updateSelectedForActions();
			}

		});
	},
	highlightRealty(realtyId, value) {
		console.log('BOOM!', arguments);
		const clusters = this.getClusters();
		const point = clusters.getRealtyPoint(realtyId);
		if (!point) { console.error('point not found for', realtyId, clusters); return; }
		point.state('highlighted', value);
		const cluster = point.cluster || clusters.getRealtyCluster(realtyId);
		if (cluster) {
			cluster.state('highlighted', value);
		} else {
			console.warn('cluster not found for', realtyId, clusters);
		}
	},
	_toggleSelectedForActions(id, selectedForActions, workedClusters = {}) {
		console.warn('toggleSelectedForActions:', id);
		
		const clusters = this.getClusters();
		const point = clusters.getRealtyPoint(id);
		if (point) {
			workedClusters[point.cluster.id] = point.cluster;
			point.selectedForActions(selectedForActions);
		}
		// clusters.toggleRealty(id, selectedForActions);

		// const view = this.realtyClusterViews.get(id);
		// if (!view) { return; }

		// if (selectedForActions) {
		// 	view.state('selected-for-action', true);
		// 	return;
		// } else {

		// 	if (!this.selectionHolder.isRealtiesSelected(view.getOption('realties'))) {
		// 		view.state('selected-for-action', false);
		// 	}
			
		// }

	},

	updateBounds() {
		const bounds = this.engine.getBounds(this.map);
		if (!bounds) { return console.warn('NO BOUNDS!'); }

		this.mapFilters.updateBounds(bounds);
		return bounds;
	},

	async fetchClusters() {
		const mapFilters = this.mapFilters;
		const entryRealtyId = this.mapStateData.entryRealtyId;
		const data = await mapsBackend.fetchClusters({ mapFilters, entryRealtyId });
		this.setMapClusters(data);
	},

	// async fetchMapPoints(bounds) {
	// 	this._mapPointsCollection?.updateBounds(); //clearPoints();
	// 	const mapFilters = this.mapFilters;
	// 	const data = await mapsBackend.fetchMapPoints({ mapFilters });
	// 	this.drawMapPoints(data);
	// 	console.warn(data);
	// },

	setMapClusters(data, fetchId) {

		// if (this._fetching !== fetchId) { return; }
		// this.currentClustersData = data;
		if (userFeature('points')) {
			this.removeMapClusters();
			this.drawMapClusters(data);
		}

	},

	removeMapClusters() {
		this.realtyClusterViews.clear();
		if (this.overlays) {
			this.overlays.forEach(overlay => overlay.destroy());
		}
	},

	drawMapClusters(data) {
		if (!this.engine.supportsMapClusters) { return }

		const entryRealtyId = this.entry?.realtyId;

		let nowSelected = this._unselectOverlays();
		let existRealtyIds = this._getExistRealtyIds();

		data = data || this.currentClustersData || {};
		this.currentClustersData = data;

		const { cluster, points = [] } = data;
		let isEntry;

		const selectedForActions = this.selectionHolder?.realties.size > 0 ? this.selectionHolder.realties : undefined;

		this.realtyClusterViews.clear();
		
		console.log('CREATE CLUSTERS', cluster, nowSelected, existRealtyIds, selectedForActions);

		this.overlays = points.map((item, index) => {

			const overlay = this._createClusterOverlay(item, index, cluster, nowSelected, existRealtyIds, selectedForActions);

			if (entryRealtyId && !isEntry) {
				isEntry = overlay.state('entry') === true;
			}
			if (overlay.state('selected')) {
				this._sideBarSelected.overlays.push(overlay);
			}

			return overlay;
		});

		if (!isEntry && entryRealtyId) {
			const ll = getRealtyLatLng(entryRealtyId) || {};
			const entryItem = {
				...ll,
				offersCount: 0,
				realtyId: entryRealtyId
			}
			const entryOverlay = this._createClusterOverlay(entryItem, this.overlays.length, cluster);
			this.overlays.push(entryOverlay);
		}
		
	},

	getClusters() {
		if (!this.engine.supportsMapPoints) { return; }
		if (!this._clusters) {
			this._clusters = this.engine.buildClustersCollection(this.map, this.mapStateData);
			this.listenTo(this._clusters, {
				'before:fetch': () => this.trigger('before:fetch:clusters'),
				'fetch': () => this.trigger('fetch:clusters'),
			});
		}
		return this._clusters;
	},

	// drawMapPoints(data) {
	// 	if (!this.engine.supportsMapPoints) { return; }
	// 	if (!this._mapPointsCollection) {
	// 		this._mapPointsCollection = this.engine.buildClustersCollection(this.map, this.mapStateData);
	// 		//this._mapPointsCollection.on('all', c => console.warn('[cl]',c));
	// 	}
	// 	this._mapPointsCollection.setPoints(data, this.map.bounds);

	// 	console.log('mapPointsCollection', this._mapPointsCollection.name, this._mapPointsCollection);
	// },

	_unselectOverlays() {
		const sideBarSelected = this._sideBarSelected;
		if (!sideBarSelected) { return; }
		const sideBarSelectedOverlays = sideBarSelected.overlays;

		let nowSelected = sideBarSelected.realties;
		if (nowSelected) {
			nowSelected = { ...nowSelected };
			if (sideBarSelectedOverlays) {
				_.invoke(sideBarSelectedOverlays, 'state', 'selected', false);	
			}
			sideBarSelectedOverlays.length = 0;
			// this._sideBarSelected.overlays = [];
		}
		return nowSelected;
	},

	_getExistRealtyIds() {
		if (this.existRealtyIds) {
			return { ...this.existRealtyIds };
		}
	},

	_createClusterOverlay(item, index, cluster, nowSelected, existRealtyIds, selectedForActions) {
		const { lat, lng } = item;
		if (!lat) {
			//debugger;
		}
		const latlng = { lat, lng };
		const realties = item.realties || { [item.realtyId]: item.offersCount };
		const entryRealtyId = this.entry?.realtyId;
		
		const isEntry = entryRealtyId && (entryRealtyId in realties) === true;
		const clusterOverlayOptions = { 
			latlng, realties, index, 
			cluster, 
			map: this.map,
			mapApi: this,
		}

		const view = this.engine.createClusterOverlayView(clusterOverlayOptions);


		let isExist, isSelected, isActionSelected;
		const checkExist = !!existRealtyIds;
		const checkSelected = !!nowSelected;
		const checkActionSelected = selectedForActions && selectedForActions.size > 0;

		for (let rid in realties) {

			this.realtyClusterViews.set(rid, view);

			// this.overlaysByRealties[rid] = view;

			if (checkExist && !isExist && rid in existRealtyIds) {
				isExist = true;
				delete existRealtyIds[rid];
			}

			if (checkSelected && !isSelected && rid in nowSelected) {
				isSelected = true;
				delete nowSelected[rid];
			}

			if (checkActionSelected && !isActionSelected && selectedForActions.has(rid)) {
				isActionSelected = true;
			}

		}

		const newState = {
			exist: isExist,
			entry: isEntry,
			selected: isSelected,
			'selected-for-action': isActionSelected
		}
		view.state(newState);
		// console.log('> state', view.el.innerHTML, view.el);

		
		view.show();

		return view;
	},

	highlightCluster(options = {}, state = true) {
		const realtyId = this._getRealtyIdFromOptions(options);
		const view = this.realtyClusterViews.get(realtyId);
		if (view) {
			view.state('highlighted', state);
		}
	},

	_getRealtyIdFromOptions(options = {}) {
		let { realtyId, realty, offer } = options;
		if (realtyId) {
			return realtyId;
		}
		if (realty?.id) {
			return realty.id
		}
		if (offer) {
			return (offer.attributes || offer).realtyId;
		}
	},


	onInternalClusterClick(realties, overlays) {
		this.unselectSelected();
		this._makeOverlaysSelected(overlays);
		this._sideBarSelected = {
			realties,
			overlays
		}
		this.trigger('cluster:click', realties, this.mapFilters.attributes);
		console.error('onClusterClick', arguments);
	},

	_makeOverlaysUnselected() {
		if (this._sideBarSelected && this._sideBarSelected.overlays) {
			_.invoke(this._sideBarSelected.overlays, 'state', 'selected', false);	
		}
	},

	_makeOverlaysSelected(overlays) {
		// this._makeOverlaysUnselected();
		_.invoke(overlays, 'state', 'selected', true);
	},

	unselectSelected() {
		this._makeOverlaysUnselected();
		this._sideBarSelected = undefined;
	}

});