const emptyObject = {};

export class IdSetApi {

	constructor() {
		this.idset = {};
	}


	containsId(value, any = false) {
		return contains(this.idset, value, any);
	}

	_set(id, value) {
		if (value === null) {
			delete this.idset[id];
		} else {
			this.idset[id] = value;
		}
	}
	set(idArgument, value) {

		if (idArgument == null) { return; }

		const isSingleValue = value !== undefined;

		if (value === undefined) {
			value = 1;
		}


		if (typeof idArgument !== 'object') {
			return this._set(idArgument, value);
		}

		let valueSource;

		if (!Array.isArray(idArgument)) {
			valueSource = idArgument;
			idArgument = Object.keys(idArgument);
		}

		for(let i = 0; i < idArgument.length; i++) {

			const id = idArgument[i];

			const idValue = isSingleValue 
				? value
				: valueSource
					? valueSource[id]
					: 1;

			this._set(id, idValue);

		}

	}

	get(id) {
		return this.idset[id];
	}

	clear() {
		this.idset = {};
	}
}

export class MultipleIdSetsApi {

	constructor() {
		this.sets = {};
	}

	containsId(setname, value, any) {
		const api = this.sets[setname];
		if (!api) { return false; }
		return api.containsId(value, any);
	}

	set(setname, value) {
		let api = this.sets[setname];
		if (api == null) {
			api = new IdSetApi();
		}
		api.set(value);
	}

	get(setname, id) {
		let api = this.sets[setname];
		if (!api) { return; }
		return api.get(id);
	}

	clear() {
		this.sets = {};
	}

}


function contains(ids, value, any) {

	if (value == null) { return false; }

	if (typeof value !== 'object') {
		return (value in ids) === true;
	}


	if (!Array.isArray(value)) {
		value = Object.keys(value);
	}

	for(let i = 0; i < value.lenght; i++) {
		const iValue = value[i];
		const exist = (iValue in ids) === true;
		if (any && exist) {
			return true;
		} 
		else if (!any && !exist) {
			return false;
		}
	}
	return !any;
}