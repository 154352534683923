﻿//define('app', ['store','bus','app-router', 'app-modules-behavior', '_libs/identity'], function (store, Bus, AppRouter, AppModulesBehavior, identity) {});

//import store from '../_libs/store'; //'store'
import Bus from 'bus/core';
import AppRouter from './app-router'; //'app-router'
import AppModulesBehavior from './modules-behavior'; //'app-modules-behavior'
import identity from '_libs/identity';
import { isNotValue, isValue } from 'utils/is';
import { history } from 'core/bbe';

// import { MneApplication } from 'core/mne';
import { Application as MneApplication } from 'vendors';

var App = MneApplication.extend({
	region: { el: 'body > section.module',replaceElement:true },
	initialize: function () {
		this._awaitingRegistration = [];
		this.runPromises = [];
		this.started = $.Deferred();
		AppModulesBehavior.call(this);
		this.listenTo(Bus.app, 'navigate', this.onNavigate);
	},
	addSubmodule: function (module) {
		if (!module) {
			console.warn('app module registration: undefined module');
			return;
		}
		// console.log('> addSubmodule', module);
		this._awaitingRegistration.push(module);
	},
	registerAwaiting: function () {
		var thisapp = this;
		_(this._awaitingRegistration).each(function (module) {
			if (!module) return;
			thisapp.registerModule(module);
		});
	},
	onNavigate: function(url, trigger) {
		trigger = trigger == false ? false : true;

		if (!url) return;
		if (url[0] === '/') {
			url = url.substring(1);
		}
		

		this.routers.navigate(url, trigger);
	},
	registerModule: function (module) {

		if (!isValue(module)) return;

		this.modules = this.modules || [];
		this.modules.push(module);
		// console.log('> module', module, 'registered');
		module.triggerMethod('registered');
	},
	addRunPromise: function (promise) {
		if (isNotValue(promise))
			throw "app addRunPromise: promise is undefined";

		this.runPromises.push(promise);
	},
	onBeforeStart: function () {
		//console.log('on before start');
		this.routers = AppRouter.create(this);
		//console.log('app router', this.routers);
		//console.log('~~~ !!! #1.1', this);
	},
	onStart: function () {
		
		console.warn('app:', 'started');
		Bus.app.trigger('app:started');

		history.start({ pushState: true });
		history.on('all', v => console.error('#history', v));
		console.warn('history:', 'started');

		AppRouter.registerUrlClickHandler(this);
		this.started.resolve();
		if (!identity.isLogged()) {
			console.warn('identity', 'not logged');
			let loc = document.location;
			let backurl = loc.pathname + loc.search + loc.hash;
			//console.log('## query', backurl);
			if (!backurl.startsWith('/acc/login')) {
				this.routers.navigate('acc/login?backUrl=' + encodeURIComponent(backurl), true);
			}
		}
	},
	run: function () {
		var app = this;


		$.when.apply(null, app.runPromises)
		.then(function () {
			//console.log('app run promises resolved');
			//if (!identity.isLogged()) return;
			app.started.resolve();
			app.start();
		}).catch(function (reason) {
			console.warn(reason,arguments);
		});

		return app.started;
	}
});

var app = new App();

export default app;