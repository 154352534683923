import { maximumImageSizeInBytes } from "./helpers";

import { BbeModel } from 'core/bbe';

export const UploadModel = BbeModel.extend({
    initialize() {
        console.log('[upload model]', arguments, this.collection);

        this.isImage = (this.get('type') || '').startsWith('image');
        let size = this.get('size');
        let maxSize = maximumImageSizeInBytes;
        let error = this.isImage && size > maxSize && 'картинка слишком большая, превышает 8Мб, воспользуйтесь редактором изображений чтобы уменьшить размер' || undefined; 
        if (error) {
            this.set({ error, state: 'error' }, { silent: true });
        }

    },
		states: {
			iddle: 'Подготовка к загрузке',
			uploading: 'Идёт загрузка',
			uploaded: 'Файл загружен',
			error: 'Ошибка загрузки файла'
		},
		getState() {
			const state = this.get('state');
			return this.states[state] || state;
		},
    upload(onSuccess) {
				const { error, state } = this.attributes;
        if (error || state !== 'iddle') {
            return Promise.resolve({ ok: 0, error: error || state });
        }

        let url = this.collection.url
        let formData = new FormData()
        // let file = this.attributes.file;
				let { file, mediaType } = this.attributes;
        formData.append('file', file)
        formData.append('groupName', 'неразобранное');
				formData.append('mediaType', mediaType);
        // url: requestURL,
        // cache: false,
        // iframe: true,
        // emulateJSON: true,
        // contentType: false,
        this._uploading = true;
        
				this.set('state', 'uploading');

        return this.save(null, {
            url,
            method: 'POST',
            processData: false,
            data: formData,
            contentType: false, //'multipart/form-data'
        }).then(ok => {
            console.log('OK', ok);
						const uploaded = this.get('uploaded');
						if(onSuccess) {
							onSuccess(uploaded);
						}
						this.set('state', 'uploaded');
            return { ok: 1, value: uploaded };
        }, err => {
            console.log('ERR', err);
            this.set({ error: 'не удалось загрузить картинку', state: 'error' });
            return Promise.resolve({ ok: 0, error: err });
        });



    }
});