import { HamburgerView } from "coms/ui/HamburgerView";
import { ValueLabelView } from "./ValueLabelView";
import { controlsApi } from "../../controls";
import { commonMixin } from "./common";
// import { schemaEditApi } from "./schemaEditApi";
import { propertySchemaApi } from "../propertySchemaApi";
import { modalsApi } from "apis/modalsApi2";
import { editValue } from "./editValue";

export const ValueWithLabelView = HamburgerView.extend({
	...commonMixin,

	initialize() {
		const model = this.getValuesModel();
		const dependsOn = this.getSchemaOption('dependsOn');
		if (dependsOn) {
			const events = dependsOn.map(event => {
				return 'change:' + event
			}).join(' ');
			this.listenTo(model, events, this.render);
		}
	},

	mergeOptionsKeys: ['property', 'valueSchema', 'modelSchema'],
	baseClassName: 'value-with-label-view',

	classNames: [ 
		v => v.getValueNameClassName(),
		v => v.getSchemaOption('required') ? 'required' : '',
		v => v.hasValue() ? 'valuable' : 'empty'
	],

	hasValue() {
		const value = this.getValue();
		return value != null && value !== '';
	},

	getValue() {
		const useModel = this.getOption('useModelForValues');
		if (!useModel) {
			if ('value' in this) {
				return this.value;
			} else {
				return this.getOption('value');
			}
		}
		const model = this.getValuesModel();
		const val = propertySchemaApi.getValue(this.valueSchema, model, this.schemaModel);
		return val;
	},

	getValuesModel() {
		return this.getOption('valuesModel') || this.model;
	},

	getSchemaOption(key) {
		const valueSchema = this.valueSchema || {};
		return valueSchema[key];
	},

	getChildren() {
		return [
			this.getLabelView(),
			this.getValueView(),
		]
	},

	childViewOptions() {
		const options = this.getOptions(['valueSchema', 'modelSchema', 'value', 'inlineEdit']);
		options.model = this.getValuesModel();
		return options;
	},

	getLabelView() {
		return ValueLabelView;
	},

	getValueView() {
		const options = this.getOptions(['valueSchema', 'modelSchema', 'inlineEdit', 'property']);
		options.model = this.getValuesModel();
		options.value = this.getValue();
		return controlsApi.getValueControlView(options)
	},

	childViewEvents: {
		'user:input'(value) {
			console.log('- user-input -')
			this.setValue(value);
		},
		'open:editor'() {
			const options = this.getOptions(['value', 'valueSchema', 'modelSchema']);
			const { valueSchema, value, modelSchema } = options;
			const model = this.getValuesModel();
			if (valueSchema.isEditNotAllowed) {
				let errors = valueSchema.isEditNotAllowed(model);
				if (errors) {
					if (Array.isArray(errors)) {
						errors = errors.join('<br>');
					}
					modalsApi.message(errors);
					return;
				}
			}

			editValue(valueSchema, { value, modelSchema, model }).then(res => {
				console.warn(res);
				if (res.ok) {
					this.setValue(res.value);
				} else if (res.type === 'hard') {
					this.setValue(undefined);
				}
			});
		}
	},

	setValue(value) {
		//debugger;
		const useModel = this.getOption('useModelForValues');
		if (!useModel) {
			this.value = value;
		} else {
			const model = this.getValuesModel();
			propertySchemaApi.setValue(value, this.valueSchema, model, this.modelSchema);
		}
		this.updateClassName();
		this.trigger('user:input', this.valueSchema.property, value);
	}

});