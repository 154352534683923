﻿//define('behaviors-selectedForActions', ['ui-models-selectionHolder'], function (SelectionHolder) {});


import SelectionHolder from 'ui/models/selectionHolder';
import { Behavior, BbView } from 'vendors';
var b = Behavior.extend({

	initialize() {
		console.warn('=selectable-init=', this.view);
	},

	_getModel: function (view) {
		return view.model;
	},
	_getCollection: function () {
		return this.view.collection;
	},
	_getIndex: function (view) {
		var col = this._getCollection();
		var model = this._getModel(view);
		if (!col || !model) return;
		return col.indexOf(model);
	},
	_getPrevIndex: function (selected) {
		return this.lastIndex;
	},
	_getViewByModelIndex: function (index) {
		if (index == null) return;
		var col = this._getCollection();
		var model = col.at(index);
		if (!model) return;
		var mcid = model.cid;
		var children = this.view.children;
		var vcid = children._indexByModel[mcid];
		if (vcid instanceof BbView) {
			return vcid;
		}
		var view = children._viewsByCid[vcid];
		return view;
	},
	_getEventName: function () {
		var event = this.getOption('eventName');
		if (event) return event;

		var selhol = this.getOption('selectionHolder');
		if (selhol && selhol.eventName) return selhol.eventName;

		selhol = this.view.getOption('selectionHolder');
		if (selhol && selhol.eventName) return selhol.eventName;
	},
	_getRadio: function () { return SelectionHolder.radio; },
	_radioTrigger: function (action, view) {
		var event = this._getEventName();

		if (!event) return;

		var radio = this._getRadio();
		var model = this._getModel(view);

		if (radio) radio.trigger(event, action, model);
	},

	_isSelected: function (view) {
		var radio = this._getRadio();
		var event = this._getEventName();
		var model = this._getModel(view);
		if (!radio || !event || !model) return false;
		var request = event + ":selected";
		var result = radio.request(request, model);
		return result;
	},
	_select: function (view, index) {
		this._radioTrigger('select', view);
		this._changeViewState(view, true);
	},
	_unselect: function (view, index) {
		this._radioTrigger('unselect', view);
		this._changeViewState(view, false);
	},


	_changeViewState: function (view, dir) {
		if (view.state) {
			view.state('selected', dir);
		}
		//var h = view.dynamicClassHash || {};
		//if (dir) h['in-selected'] = true;
		//else delete h['in-selected'];
		//view.triggerMethod('refresh:css:class');
		//view.triggerMethod('selected:state:changed', dir);
	},

	_singleToggle: function (view, clicked) {
		var index = clicked ? this._getIndex(view) : undefined;
		var selected = this._isSelected(view);
		
		console.log('selectable','toggle', selected);
		// console.log('-> ', view.cid, !selected);

		if (!selected)
			this._select(view, index);
		else
			this._unselect(view, index);

		if (index != null) {
			this.lastIndex = index;
		}
	},
	_multipleToggle: function (view, clicked) {

		if (this.lastIndex == null)
			return this._singleToggle(view, clicked);

		var index = this._getIndex(view);
		var selected = this._isSelected(view);
		var prevIndex = this._getPrevIndex(selected);
		if (prevIndex == null) return;

		var low = index > prevIndex ? prevIndex + 1 : index;
		var high = index > prevIndex ? index : prevIndex - 1;
		for (var i = low; i <= high; i++) {
			var _view = this._getViewByModelIndex(i);
			if (_view) {
				view.triggerMethod('selectable:toggle', _view);
			}
		}

		delete this.lastIndex;

	},

	// onChildviewSelectableToggle: function (view, clicked, withShift) {
	// 	console.log('old -wtf-')
	// 	if (!withShift)
	// 		this._singleToggle(view, clicked);
	// 	else
	// 		this._multipleToggle(view, clicked);

	// },
	onSelectableToggle(view, clicked, withShift) {

		if (!withShift) {
			this._singleToggle(view, clicked);
		}
		else {
			this._multipleToggle(view, clicked);
		}
		// console.log('-wtf-', view, clicked, withShift)
	},
	onChildviewBeforeFirstRender: function (view) {
		var dir = this._isSelected(view);
		view.triggerMethod('selected:state:changed', dir);
	}
});

b.ChildView = Behavior.extend({
	triggerKey: 'trig',
	triggerSelector: 'button.toggle-select',
	isDisabled: function () {
		if (_.isFunction(this.view.isSelectable)) {
			return !this.view.isSelectable();
		}
		var deny = this.view.getOption('denySelectionFor') || [];
		var id = this.view.model.id;
		return deny.indexOf(id) >= 0;
	},
	ui: function () {

		//var key = 'trig';
		var selector = this.getOption('triggerSelector');
		var hash = {};
		hash[this.getOption('triggerKey')] = selector;
		return hash;

	},
	onBeforeRender: function () {

		if (!this.view.dynamicClassHash)
			this.view.dynamicClassHash = {};

		//var deny = this.view.getOption('denySelectionFor') || [];
		//var id = this.view.model.getId();
		if (this.isDisabled())
			this.view.dynamicClassHash['no-selection'] = true;
		else
			delete this.view.dynamicClassHash['no-selection'];

		if (!this.view.isRendered())
			this.view.triggerMethod('before:first:render', this.view);

	},
	modelEvents: {
		'selected:state:changed':'onSelectedStateChanged'
	},
	triggers: function () {
		var hash = {};

		if (this.isDisabled())
			return hash;

		hash['click @ui.' + this.getOption('triggerKey')] = 'selectable:trigger:click';
		return hash;
	},
	onSelectableTriggerClick: function (view, e) {

		if (this.isDisabled())
			return;

		this.view.triggerMethod('selectable:toggle', this.view, true, e.shiftKey);

	},
	onSelectedStateChanged: function (selected) {

		var view = this.view;
		var h = view.dynamicClassHash || {};
		if (selected) h['in-selected'] = true;
		else delete h['in-selected'];
		view.triggerMethod('refresh:css:class');

	}
});

export default b;
