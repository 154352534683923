﻿//define('modals-headerAndText', [], function () {});


import viewTemplate from './headerAndText.html';
import confirmTemplate from './confirm.html';

import { BbeModel } from 'core/bbe';
import { MneView } from 'core/mne';

const CommonMixin = {
    _getText(key) {
        if (this.model) {
            return this.model.get(key);
        }
        return this.getOption(key, true);
    },
    getHeaderAndText() {
        return {
            header: this._getText('header'),
            text: this._getText('text')
        }
    }
}

var View = MneView.extend({
    className: 'header-n-text-box',
    //template: '#tmpl-libs-modals-headerAndText',
    template: viewTemplate,

    ...CommonMixin,

    templateContext() {
        return this.getHeaderAndText();
        // var hash = {};
        // hash.text = (this.model && this.model.get('text')) || this.options.text;
        // hash.header = (this.model && this.model.get('header')) || this.options.header;
        // return hash;
    }
});

var Confirm = MneView.extend({
    //template: '#tmpl-libs-modals-confirm',

    ...CommonMixin,

    template: confirmTemplate,
    ui: {
        'confirm': '.confirm',
        'cancel': '.cancel'
    },
    initialize: function() {
        this.promise = new Promise((resolve, reject) => {
            this.on({ 
                'cancel': () => this._fulfill(reject, true),
                'confirm': () => this._fulfill(resolve, true),
                'destroy': () => this._fulfill(reject),
            });
        });
    },
    _fulfill(cb, shouldDestroy){
        if (this.fulfilled) return;
        this.fulfilled = true;
        cb();
        shouldDestroy && this.destroy();
    },
    triggers: {
        'click @ui.cancel': 'cancel',
        'click @ui.confirm': 'confirm',
    },
    // onConfirm: function() {
    //     this.promise.resolve();
    //     this.destroy();
    // },
    // onCancel: function() {
    //     this.promise.reject();
    //     this.destroy();
    // },
    // onBeforeDestroy: function() {
    //     if (this.promise.state() === 'pending')
    //         this.promise.reject();
    // },
    templateContext() {
        var cancel = !(this.getOption('cancel') == false);
        var cancelText = this.getOption('cancelText') || "отмена";
        var okText = this.getOption('okText') || "подверждаю";
        return {
            okText: okText,
            cancelText: cancelText,
            cancel: cancel,

            ...this.getHeaderAndText()
            
        }
    }
});



View.create = function (header, text, opts) {
    //var options = _.extend({ model: new BbeModel({ header: header, text: text }) }, opts);
//      return new View(options);

    var options = _.extend({ model: new BbeModel({ header: header, text: text }) }, opts);
    var sView = View;
    if (options.confirm) {
        sView = Confirm;
    }
    return new sView(options);

}

export default View;
