import { NextIconButtonView } from './NextIconButtonView';

export const NextIconBadgeButtonView = NextIconButtonView.extend({
	template: '<span><%= iconLeftHtml %><%= textHtml %><%= iconRightHtml %><%= badgeHtml %></span>',
	templateContext() {
		const ctx = {
			iconLeftHtml: this.getIconHtml(),
			textHtml: this.getTextHtml(),
			iconRightHtml: this.getIconHtml('Right'),
			badgeHtml: this.getBadgeHtml()
		}
		return ctx;
	},
	getBadgeHtml() {
		let value = this.getBadgeValue();
		if (value == null) {
			value = '';
		}
		if (!this.getOption('showZeroBadge') && value === 0) {
			value = '';
		}
		const html = `<span class="badge-container"><span class="badge">${value}</span></span>`;
		return html;
	},
	getBadgeValue() {
		return this.getOption('badgeValue');
	}
});