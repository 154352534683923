// import Properties from './properties';
// import Medias from './medias';
// import RealtyObjects from './realty-objects';

import { Properties } from '../../../entity-list-common/edit';
import { ObjectsLayout } from '../../../entity-list-common/edit/nested-objects';
import { OwnersInRealtiesLayout } from '../../../entity-list-common/edit/owners-in-realties';
import { ContactsLayout } from './contacts';
const ignoreGroups = ['Address', 'Actualize'];

export default [
    // {
    //     id:'main',
    //     label: 'Основные х-ки',
    //     view: Properties,
    //     viewOptions: () => ({
    //         singleGroup: true,
    //         filters: {
    //             prop: prop => prop.get('parentId') === 'root',
    //             group: group => group.id === 'root'
    //         }
    //     }),
    // },
    {
        id:'main',
        label: 'Все х-ки',
        view: Properties,
        viewOptions: () => ({
            filters: {
                group: group => ignoreGroups.indexOf(group.id) < 0, // !== 'Actualize'
            }
        })
    },
    {
        id:'actualize',
        label: 'Статус актуализации',
        view: Properties,
        viewOptions: () => ({
            singleGroup: true,
            filters: {
                prop: prop => prop.get('parentId') === 'Actualize',
                group: group => group.id === 'Actualize'
            }
        }),
    },    
    // {
    //     id:'media',
    //     label: 'Фотографии и файлы',
    //     view: Medias,
    //     viewOptions() {
    //         return {
                
    //         }
    //     }
    //     //view: Properties,
    // },
		{
			id: 'contacts',
			label: 'Контакты',
			view: ContactsLayout,
			// viewOptions: {
			// 	modelType: 'owner'
			// }
		},
		{
			id: 'realties',
			label: 'Здания',
			view: OwnersInRealtiesLayout,
			viewOptions: {
				modelType: 'owner'
			}
		},
    {
        id:'objects',
        label: 'Площади',
        view: ObjectsLayout,
        viewOptions: {
            modelType: 'owner'
        }
    },
    // {
    //     id:'adm',
    //     label: 'в синюю',
    //     newTab: true,
    //     href: model => 'http://adm.nrg.plus/rlt/owners/' + model.id,
    // },
]
