﻿import { _ } from 'vendors';
import { isValue } from 'utils/is';

_.mixin({
    eResult: function(context, property, defaultValue) {
        var result = _.result(context, property, defaultValue);
        return !isValue(result) || result === '' ? defaultValue : result;
    },
	v: function (val, context) {
		var args = [].slice.call(arguments);
		args.shift(); args.shift();
		var funcargs = args.length ? args : undefined;
        return _.isFunction(val) ? val.apply((context || val),funcargs) : val;
    },
});