module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<small>'+
((__t=( _enum(stageId,'dealStages') ))==null?'':__t)+
' - '+
((__t=( _enum(status,'dealStatuses') ))==null?'':__t)+
' - '+
((__t=( _empn(ownerId) ))==null?'':__t)+
'</small>\n<span>#'+
((__t=(wId))==null?'':__t)+
': '+
((__t=(name))==null?'':__t)+
'</span>';
}
return __p;
};
