﻿//define('ui-controls-map-input', ['bus', 'ui-controls-input', 'behaviors-dynamicClass'], function (Bus, Input, DynamicClass) {});

import Bus from 'bus';
import Input from 'ui/controls/input';
import DynamicClass from 'behaviors/dynamicClass';

import template from './control.html';


import { MneView } from 'core/mne';
import './map-control-searchPlace.less';
import './map-control-searchRealty.less';
import './map-control-wrapper.less';

var View = MneView.extend({
	//className: 'map-control-searchPlace map-control-wrapper',
	behaviors: [DynamicClass],
	dynamicClass: function () {
		return 'map-control-wrapper';
	},
	//template: '#tmpl-ui-controls-map-input-control',
	template,
	regions: {
		'input': { el: '.input-region', replaceElement: true },
	},
	onRender: function () {
		this.mapView = this.getOption('mapView');
		var view = new Input({
			tagName: 'span',
			className: 'input-view',
			inputAttributes: this.getOption('inputAttributes'),
			mapView: this.mapView,
			doNotFocus: this.getOption('doNotFocus')
		});
		this.showChildView('input', view);
	},
	onChildviewValueChanged: function (value) {
		//console.log('value', value);
	},

});
Bus.Views.reply('ui:map:input', function () {
	return View;
});

export default View;
