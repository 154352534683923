﻿//define('m-logs', ['m-logs-log', 'm-logs-logs'],function(Log, Logs){});


import Log from './log';
import Logs from './logs';


var ns = {};

ns.LogsCollection = Logs;
ns.LogModel = Log;

export default ns;
