import { CollectionView, View } from 'core';
import { commonMixin } from './common';
import { HamburgerView } from 'coms/ui/HamburgerView';
import { fetchOnScrollMixin, viewFetchMoreMixin } from 'mixins';

const EntryLabelView = View.extend({
	baseClassName: 'select-entry-label',
	thisClassName: 'default',
	template: '<span><%= label %></span>',
	mergeOptionsKeys: ['getLabel'],
	templateContext() {
		return {
			label: this.getLabel() // model.get('label')
		}
	},
	getLabel() {
		return this.model.get('label')
	}
});

const EntryView = HamburgerView.extend({
	...commonMixin,
	classNames: ['select-entry', v => v.selectedClassName()],
	stateClassNames: ['selected'],
	passDownModels: true,
	template: '<span class="select-icon"><i></i></span>',
	modelEvents: {
		'selected unselected':'updateClassName'
	},
	selectedClassName() {
		return this.selector.has(this.model) ? 'selected' : '';
	},
	getChildren() {
		const labelView = this.getLabelView();
		return [
			labelView
		]
	},
	childViewOptions() {
		return {
			getLabel: this.getOption('getLabel', false)
		}
	},
	getLabelView() {
		return this.getOption('labelView') || EntryLabelView
	},
	triggers: { 'click': 'click' },
	// events: {
	// 	'click'() {
	// 		this.state('selected', !this.state('selected'));
	// 	}
	// }
});

export const EntriesView = CollectionView.extend({
	...fetchOnScrollMixin,
	...viewFetchMoreMixin,
	// ...commonMixin,
	initialize(options) {
		this.mergeOptions(options, ['selector', 'labelView', 'multiple', 'isBackendSelect']);
		this.initializeFetchOnScrollMixin();
	},
	baseClassName: 'select-entries',
	childView: EntryView,
	childViewOptions() {
		return {
			selector: this.selector,
			labelView: this.labelView,
			getLabel: this.getOption('getLabel', false),
		}
	},
	childViewEvents: {
		'click'(view, event) {
			if (!this.multiple) {
				return this.toggleOne(view);
			}

			const shift = event.shiftKey;
			if (this._lastClick && shift) {
				this.toggleMany(view);
			} else {
				this.toggleOne(view);
			}
			this._lastClick = view;

		}
	},
	toggleMany(view) {
		const before = this._lastClick;
		const beforeIndex = this.children.findIndexByView(before);
		const viewIndex = this.children.findIndexByView(view);
		if (beforeIndex === viewIndex || viewIndex === beforeIndex + 1 || viewIndex === beforeIndex - 1) {
			return this.toggleOne(view);
		}

		const start = beforeIndex < viewIndex ? beforeIndex + 1 : viewIndex;
		const end = beforeIndex < viewIndex ? viewIndex : beforeIndex - 1;
		const models = []; //this.getManyModels(view);

		for(let x = start; x <= end; x++) {
			const found = this.children.findByIndex(x);
			if (found) {
				models.push(found.model);
			}
		}

		this.selector.toggle(models);

	},
	toggleOne(view) {
		this.selector.toggle(view.model);
	},
	// _filterChildren() {
	//debugger;
	// 	return CollectionView.prototype._filterChildren.apply(this, arguments);
	// },
	onFilter() {
		this._lastClick = undefined;
	},
	onChildrenRender() {
		this._lastClick = undefined;
	},
	// viewFilter() {

	// },
	modelEvents: {
		'change'() {
			if (this.isBackendSelect) {
				this.collection.fetch({ search: this.model.attributes });
			} else {
				this.sort();
			}
		}
	}
});