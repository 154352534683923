export function contactsModelComparator(m1, m2) {

	let { primary : p1 , order : o1 } = m1.attributes;
	let { primary : p2 , order : o2 } = m2.attributes;
	
	if (p1 !== p2) {
		return (p2 ? 1 : 0) - (p1 ? 1 : 0)
	}
	
	return o2 - o1;

}

export function contactsComparator(v1, v2) {
	const m1 = v1.model;
	const m2 = v2.model;
	return contactsModelComparator(m1, m2);
}


const typeValue = {

	other: 7,
	
	phone: 2,
	
	mobilePhone: 1,
	
	email: 3,
	
	messenger: 4,
	
	site: 5,
	
	social: 6,
}

function getValueTypeOrder(type) {
	const value = typeValue[type] || 10;
	return value;
}

export function contactValuesModelComparator(m1, m2) {
	let { value : v1 , order : o1 } = m1.attributes;
	let { value : v2 , order : o2 } = m2.attributes;

	if (o1 !== o2) {
		return o2 - o1;
	}

	const t1 = getValueTypeOrder(v1.type);
	const t2 = getValueTypeOrder(v2.type);

	return t1 - t2;

}

export function contactValuesComparator(v1, v2) {
	return contactValuesModelComparator(v1.model, v2.model);
}