// define('svc/admin', [
// 	'appModule', 'link', 'svc/admin/groups'
// ], 
// function (
// 	appModule, link, groupsModule
// 	) {
// });

import appModule from 'appModule';
import link from 'link';
import groupsModule from './groups';
import featuresModule from './features';
	
var module = appModule.content({
	name: 'admin',
	label: 'Администрирование',
	urlKey: 'admin',
	routeData: () => link.route('admin'),
	claims: {
		svcAdminModule: true
	},			
	onRun: function () {
		this.showButtonsLayout();
	}
});

module.addSubModule(groupsModule);
module.addSubModule(featuresModule);

// module.addSubModule(searchOnMap);
// module.addSubModule(moduleRealtyObject);
// module.addSubModule(moduleRealtyBuilding);

export default module;


