﻿//define('svc-deals', ['appModule', 'link', '_libs/identity', 'svc-deals-layout', 'svc-deals-submodules'], function (Module, link, identity, Layout, submodules) {});


import Module from 'appModule';
import link from 'link';
//import identity from '';
import Layout from './layout';
import submodules from './submodules';

import './deals-emps-stats.less';


var module = Module.content({
	name: 'deals',
	label:'Процессы',
	routeData: () => link.route('deals'),
	urlKey:'deals',
	claims: {
		svcProcessesModule: ['view']
	},
	onRun: function () {

		//this.showButtonsLayout();
		this.createAndShow(Layout, { header: 'Процессы' }, undefined, {addSectionClass: 'flex-page flex-column'});
//        console.log('runed:', this.name);
//        var module = this;
//        if (identity.isLogged()) {

//            var links = [
			//	link('на главную', 'root', { attrs: { class: 'go-up' } }),
			//	link('создать сделку', 'dealsCreate'),
			//	link('активные', 'dealsNearest'),
			//	link('просроченные', 'dealsOutofdate'),
//                //link('завтра', 'tasksTomorrow'),
//                //link('на этой неделе', 'tasksWeek'),
//                //link('просроченные задачи', 'tasksPast'),
//            ];
			//this.createAndShow(Layout, 'Сделки', { navLinks: links }, { navLinks: links });

//        } else {
//            console.log('not logged');
//        };
	}
});


_(submodules).each(function (smodule) {
	module.addSubModule(smodule);
});

//moduleObj.registerModule(moduleDealCreate)
//moduleObj.registerModule(moduleDealsList);
//moduleObj.registerModule(moduleDeal);
export default module;
