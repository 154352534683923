import { ActionButtonView } from "./actionButtonView";
import { CollectionView, Collection } from 'vendors';

export const ActionsView = CollectionView.extend({
	className: 'admin-groups-actions',
	initialize(options) {
		this.mergeOptions(options, ['actions']);
		let actions = this.getActionModels();
		this.collection = new Collection(actions);
	},
	getActionModels() {
		let ids = this.getOption('ids');
		if (ids) {
			return this.actions.find(ids);
		}
		let placement = this.getOption('placement');
		if (placement) {
			return this.action.find(placement);
		}
		return [];
	},
	childViewOptions() {
		return {
			actionArgs: this.getOption('actionArgs')
		}
	},
	childView: ActionButtonView,
	//template: _.template('<button><i class="glyphicon glyphicon-plus"></i><span>добавить группу</span></button>'),
});