﻿// define('svc-deals-create-lead', ['bus', 'bus-svc', 'references', 'ui-models-filters', 'ui-controls-fastfilters', 'ui-models-values-collection', 'realties-models']
// ,function(Bus, svcRadio, refs, Filters, FiltersView, Values, realtiesNs){});

import Bus from 'bus';
import svcRadio from 'bus/svc';
import appBus from 'bus/app';
import refs from 'references';
import Filters from 'ui/models/filters';
import FiltersView from 'ui/controls/fastfilters';
//import Values from '';
import realtiesNs from 'svc/realties/models';
import { _ } from 'vendors';
// import template from './lead.html'; // moved to string const

import { MneView } from 'core/mne';
import { ManagersView } from './ManagersView';
// import { getBuildingName } from '../../../m/realties/buildings/buildings';
import { getRealtyName, setRealtyName } from 'mods/realties/realties/realties';

function RawDataToLead(data, oldLead)
{
	console.warn('# data #', data);
	function v(data) {
		return (data[0] || {}).v;
	}
	function f(data) {
		return (data[0] || {}).f;
	}
	function t(data) {
		return (data[0] || {}).t;
	}

	function sv(data)
	{
		return _(data).pluck('v').join(', ');
	}


	var result = {
		lead: _.extend({}, oldLead),
	};
	var keyHash = { mt: 'market', mo: 'operation', sq: ['squareFrom', 'squareTo'], price: ['priceFrom', 'priceTo'], obj: 'objectName', geo: 'geoName', bld: 'realtyId', note: 'comment', src: 'sourceValue', emp: 'ownerId' };
	var hash = result.lead;
	_(data).each(function (val, key) {
		delete keyHash[key];
		switch (key) {
			case 'mt':
				hash['market'] = sv(val); break;
			case 'mo':
				hash['operation'] = sv(val);
				break;
			case 'sq':
				hash['squareFrom'] = f(val);
				hash['squareTo'] = t(val);
				break;
			case 'price':
				hash['priceFrom'] = f(val);
				hash['priceTo'] = t(val);
				break;
			case 'obj':
				hash['objectName'] = v(val); break;
			case 'geo':
				hash['geoName'] = v(val); break;
			case 'bld':
				hash['realtyId'] = v(val); break;
			case 'note':
				hash['comment'] = v(val); break;
			case 'src':
				hash['sourceJson'] = v(val);
				break;
			case 'emp':
				result['ownerId'] = v(val); break;
		}
	});
	_(keyHash).each(function (leadKeys, key) {
		!(leadKeys instanceof Array) && (leadKeys = [leadKeys]);
		_(leadKeys).each(function (k) {
			hash[k] = undefined;
		});
	});


	return result;
}


const template = _.template(`<div class="block"><ul class="values-region1"></ul></div>
<div class="block offset-t"><ul class="values-region2"></ul></div>
<div class="block offset-t"><ul class="values-region3"></ul></div>
<div class="block offset-t managers-container"><ul class="managers"></ul></div>`)

var Layout = MneView.extend({
	//template: '#tmpl-service-deals-create-lead',
	template,
	regions: {
		'values1': { el: '.values-region1', replaceElement: true },
		'values2': { el: '.values-region2', replaceElement: true },
		'values3': { el: '.values-region3', replaceElement: true },
		'managers': '.managers-container'
	},
	onRender: function () {
		this.showValues();
		this.showManagers();
		this.updateLead();
	},
	modelEvents: {
		'recalc:name': function() {
			//debugger;
			var f = this.leadData[0].filters.first();
			var m = this.model.toDeepJSON();
			m.lead || (m.lead = {});
			m.client || (m.client = {});

				//_.extend({}, { client: {}, lead: {} }, this.model.toJSON());
			var res = '';

			var chunks = [];

			res += ((refs.enum('realtyOperations', m.lead.operation) || '')[0] || '').toUpperCase();
			res += ((refs.enum('realtyMarkets', m.lead.market) || '')[0] || '').toUpperCase();
			chunks.addValue(res);

			if (m.lead.realtyId) {
				//chunks.addValue(//appBus.request('building:name', m.lead.realtyId));
				let name = getRealtyName(m.lead.realtyId);
				if (name) {
					chunks.push(name);
				}
				//chunks.addValue(getRealtyName(m.lead.realtyId));
			}

			res = '';
			var sq = [];
			sq.addValue(m.lead.squareFrom)
			sq.addValue(m.lead.squareTo)
			res = sq.join('-');
			res && (res += 'м');
			chunks.addValue(res);
			var newname = m.lead.companyName || m.lead.personName || '';

			if (m.client && m.client.name) {
				chunks.addValue(m.client.name);
			} 
			else if (newname)
			{
				chunks.addValue(newname);
			}

			var g = m.lead.geoName;
			chunks.addValue(g);

			chunks.addValue(m.lead.comment);

			res = chunks.join(', ');

			f.resetValuesTo(res, { modelSilent: true });

			//var newdata = this.getFormData();
			//var fname = newdata.name[0].v;
			console.warn('name:', res);
			this.model.set('name', res);

		}
	},
	showValues: function () {
		var lead = this.model.get('lead') || {};
		console.error('# lead', lead);
		var arr = [
			{
				id: 'name',
				order: -1,
				caption: 'название',
				emptyText: '&ndash;',
				modelType: 'single',
				valueType: 'string',
				pinned: true
			},
			{
				id: 'mt',
				order: 0,
				caption: 'рынок',
				values: lead.market || 'offices',
				emptyText: 'не выбран',
				modelType: 'single',
				valueType: 'string',
				sourceValues: refs.enum('realtyMarkets'),
				flattenValues: true,
				multiple: false,
				pinned: true
			},
			{
				id: 'mo',
				group: 'base',
				order: 1,
				caption: 'операция',
				values: lead.operation || 'rent',
				emptyText: 'не выбрана',
				modelType: 'single',
				valueType: 'string',
				sourceValues: refs.enum('realtyOperations'),
				multiple: false,
				pinned: true
			},
			{
				id: 'sq',
				order: 2,
				caption: 'площадь',
				emptyText: 'не задана',
				values: (lead.squareFrom || lead.squareTo) ? {from:lead.squareFrom,to:lead.squareTo} : undefined,
				valueType: 'number',
				modelType: 'range',
				pinned: true
			},
			{
				id: 'price',
				order: 3,
				caption: 'стоимость метра',
				values: (lead.priceFrom || lead.priceTo) ? { from: lead.priceFrom, to: lead.priceTo } : undefined,
				emptyText: 'не задана',
				valueType: 'number',
				modelType: 'range',
				pinned: true
			},


		];
		this.showBlock(1, arr);

		var bldRest = realtiesNs.BuildingsRest.create();
		var bldValueHash = lead.realtyId ? bldRest.getModelById(lead.realtyId) : undefined;
		console.log('#bldValueHash# ', bldValueHash);
		if (bldValueHash) {
			var reg = this.getRegion('values2');
			
			bldValueHash.set({ value: lead.realtyId });
			bldValueHash.once('change', function () {
				reg && reg.currentView && reg.currentView.options.filters && reg.currentView.options.filters.refreshById('bld');
				bldValueHash.trigger('view:refresh', bldValueHash);
			});
		}


		var arr2 = [
			{
				id: 'bld',
				order: 5,
				caption: 'здание',
				emptyText: 'не задано',
				valueType: 'string',
				modelType: 'single',
				controlType: 'selectRest',
				values: bldValueHash,
				sourceValues: realtiesNs.BuildingsRest.create({ 
					onSync(col, data) {
						data.forEach(r => setRealtyName(r.id, r.name));
					},
					onDataArrived(col, opts, data) { 
						console.error(data);
						//console.error(arguments); 
					} 
				}),
				pinned: true,
			},
			{
				id: 'geo',
				order: 6,
				caption: 'география',
				emptyText: 'не задано',
				values: lead.geoName,
				valueType: 'string',
				modelType: 'single',
				pinned: true
			},
			{
				id: 'obj',
				order: 4,
				caption: 'предложение',
				emptyText: 'не задано',
				values: lead.objectName,
				valueType: 'string',
				modelType: 'single',
				pinned: true
			},
		];
		this.showBlock(2, arr2, (filters) => this._setupBlock2(filters));

		//var emps = svcRadio.request('employeesSelect');
		//console.log('>>>>>', lead.sourceJson);

		var arr3 = [

			{
				id: 'note',
				order: 4,
				caption: 'дополнительно',
				emptyText: 'не задано',
				values: lead.comment,
				valueType: 'string',
				modelType: 'single',
				pinned: true
			},
			{
				id: 'src',
				order: 5,
				caption: 'площадка',
				emptyText: 'не задано',
				valueType: 'object',
				display: function () {
					var val = this.getValue();
					//console.log('????', val);
					if (!val) {

						return this.get('emptyText');

					} else {

						var lbl = _.filter([refs.enum('leadOriginTypes', val.type || 'callIn'), val.name, val.instrument], function (v) {
							return !!v;
						}).join(' ');

						return lbl;
					}
					//console.log('this', this);
					//return 'hoho';
				},
				values: lead.sourceJson,					
				//suggestions: ['Заявка РГ-сайт', 'Звонок РГ-сайт', 'Звонок Циан', 'Заявка циан', 'Звонок Яндекс', 'Звонок Google', 'Заявка pvt-лендинги', 'Звонок pvt-лендинги', 'Заявка PVT-сайт', 'Звонок PVT-сайт', 'Заявка Facebook', 'Звонок Facebook', 'Заявка Диспетчер', 'Звонок Диспетчер',],
				//"циан: ТОП3", "заявка: *.vr.ru лэндинги", "звонок: *.vr.ru лэндинги", "заявка: РГ", "звонок: РГ", "заявка: VR", "звонок: VR", "звонок: БЦ", "звонок: Баннер1", "звонок: Баннер2", "звонок: Гросс"
				modelType: 'single',
				controlType: 'leadSourceEdit',
				pinned: true
			},
			{
				id: 'emp',
				order: 8,
				caption: 'ответственный',
				emptyText: 'не задано',
				values: this.model.get('ownerId'),
				valueType: 'string',
				modelType: 'single',
				pinned: true,
				controlType: 'selectEmployees',
				sourceValues: svcRadio.request('employeesSelect'),
			},
		];
		this.showBlock(3, arr3, fltrs => this._setupBlock3(fltrs));


	},
	showBlock: function (num, arr, action) {
		this.leadData || (this.leadData = []);
		var Pinned = FiltersView.GetPinnedCollectionView();
		var filters = Filters.create({
			filters: arr,
			radio: Bus.channel('create-deal-lead' + num),
		});
		if (action) {
			action(filters);
		}
		this.leadData.push(filters);
		var view = new Pinned({
			tagName: 'ul',
			filters: filters, collection: filters.filters
		});
		this.showChildView('values' + num, view);			
		this.listenTo(filters.filters, 'values:change', this.updateLead);
	},
	_setupBlock2(filters) {
		const bldFilter = filters.getFilterById('bld');
		if (!bldFilter) {
			console.warn('NO-BLD-FILTER');
			return;
		}
		this.listenTo(bldFilter, 'values:change', (...rst) => {
			console.error('...rst', rst);
			const realtyId = bldFilter.getValue();
			this.showManagers(realtyId);
		});
	},
	_setupBlock3(filters) {
		this.employeeFilter = filters.getFilterById('emp');
	},
	updateLead: function () {
		var data = this.getFormData();
		var oldLead = this.model.get('lead') || {};
		var result = RawDataToLead(data, oldLead);


		this.model.set(result);
		this.model.trigger('recalc:name');
	},
	getFormData: function () {
		var res = {};
		_(this.leadData).each(function (_fltr) {
			_.extend(res, _fltr.filters.getFiltersData());
		});
		return res;
	},
	showManagers(realtyId) {
		if (!this.managersView) {
			const view = new ManagersView();
			this.listenTo(view, 'known:responsible:click', (view) => {
				this.setKnownResponsible(view.model.id);
			});
			this.managersView = view;
			this.showChildView('managers', view);	
		}
		this.managersView.fetchManagers(realtyId);
	},
	setKnownResponsible(id) {
		console.error('fltr', this.employeeFilter);
		this.employeeFilter.applyValues(id);
	}

});

export default Layout;