const Surrogate = [];

const mixin = {
	setItem(key, value) {
		if (key in mixin) { throw new Error('key not allowed'); }
		// var nvalue = value === null ? "null"
		// 	: typeof value === 'undefined' ? 'undefined'
		// 		: typeof value.toString === 'function' ? value.toString()
		// 			: value;
		this[key] = value;
	},
	getItem(key) {
		return this[key];
	},
	clear() {
		this.length = 0;
		Object.assign(this, mixin);
	}
}

Object.assign(Surrogate, mixin);

export { Surrogate }