import { _ } from 'vendors';

const defaultEnumResultOptions = {
	returnAs: 'string',
	returnAsStringDelimeter: ', ',
};

export function enumArray(en, array) {
	if (!en) return;
	return _.map(array, (v) => ({ [v]: en[v] }));
}

export function enumResult(data, opts) {
	var options = _.extend({}, defaultEnumResultOptions, opts);
	let result = [];
	//var _this = this;
	if (options.returnAs == 'hash') {
		result = {};
		if (!data) return result;

		_(data).each(function (element) { return _.extend(result, element); });

		return result;
	} else if (options.returnAs == 'array') {

		if (!data) return result;
		_(data).each(function (d) { return _(d).each(function (value) { return result.push(value); }); });

		return result;
	} else if (options.returnAs == 'string') {

		if (!data) return;

		_(data).each(function (d) { return _(d).each(function (value) { return result.push(value); }); });

		return result.join(options.returnAsStringDelimeter);
	}
}

export function toHash(arr) {
	
	if (!arr) { return {}; }

	if (typeof arr === 'string') {
		arr = arr.split(/\s*,\s*/);
	}

	if (!Array.isArray(arr)) {
		arr = [];
	}

	// var r = {};
	// _(this).each(function (v) { r[v] = undefined });

	return arr.reduce((res, key) => {
		res[key] = 1;
		return res;
	}, {});

	// return r;

}