module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="comment-info"><span class="comment-author">'+
((__t=( _empn(authorId) ))==null?'':__t)+
'</span><span class="comment-date">'+
((__t=( _tfn(date) ))==null?'':__t)+
'</span></div>\n';
 if(_m.subject){ 
__p+='<div class="comment-subject">'+
((__t=( subject ))==null?'':__t)+
'</div> ';
 } 
__p+='\n<div class="comment-text">'+
((__t=( text))==null?'':_.escape(__t))+
'</div>';
}
return __p;
};
