﻿//define('store-model', [], function () {});

import { MneObject } from 'core/mne';

var Surrogate = [];

Surrogate.setItem = function (key, value) {
	// var nvalue = value === null ? "null"
	// 	: typeof value === 'undefined' ? 'undefined'
	// 		: typeof value.toString === 'function' ? value.toString()
	// 			: value;
	Surrogate[key] = value;
}

Surrogate.getItem = function (key) {
	return Surrogate[key];
}


var Store = MneObject.extend({
	addKey: 'app',
	channelName: 'store',
	_getNativeStore() {
		let useSession = !!this.getOption('useSession');
		return useSession ? window.sessionStorage : window.localStorage;
	},
	initialize: function () {
		if (this.getOption('addKey')) {
			this.addKey = this.getOption('addKey');
		}
		this.KEYS = {
			currentDeal: 'cdeal'
		};
		this._store = this._getNativeStore() || Surrogate;
		// if (localStorage)
		// 	this._store = localStorage;
		// else
		// 	this._store = Surrogate;
	},
	_createItem: function (value, { absolute, sliding } = {}) {
		var item = {
			date: new Date(),
			created: Date.now(),
			absolute,
			sliding,
			value: value,
		};
		return item;
	},
	_serialize: function (item) {
		return JSON.stringify(item);
	},
	_deserialize: function (data) {
		if (data == null) return;
		try {
			return JSON.parse(data);
		} catch (e) {
			console.log('data is', data);
			throw e;
		}
	},
	_getKey: function (_key) {
		return this.addKey + ":" + _key;
	},
	_getRawValue: function (_key) {
		var key = this._getKey(_key);
		return this._store.getItem(key);
	},
	_clear: function (key) {
		this._set(key, null);
	},
	_set: function (key, value) {
		var normalizedKey = this._getKey(key);
		this._store.setItem(normalizedKey, value);
	},
	set: function (key, value, options) {
		var item = this._createItem(value, options);
		var normalizedValue = this._serialize(item);
		this._set(key, normalizedValue);
	},
	getRaw: function (key, noKey) {
		var rawItem = this._getRawValue(key);
		var item = this._deserialize(rawItem);
		if (item != null && !noKey) {
			item.key = key;
		}
		return item;
	},
	_isExpired(expireValue, createdValue) {
		let expire = new Date(expireValue + createdValue);
		return Date.now() > expire.valueOf();
	},
	_updateCreated(key) {
		let item = this.getRaw(key, true);
		if (!item) return;
		item.created = Date.now();
		this._set(key, item);
	},
	expireItem(item) {
		let { key, created, absolute, sliding } = item;
		let expired;
		if (absolute > 0) {
			expired = this._isExpired(absolute, created);
			if (expired) {
				this._clear(key);
				return true;
			}
		}

		if (sliding > 0) {
			expired = this._isExpired(sliding, created);
			if (expired) {
				this._clear(key);
				return true;
			} else {
				this._updateCreated(key);
			}
		}
	},
	get: function (key, options) {
		options || (options = {});
		var item = this.getRaw(key);
		if (item == null) return;
		let expired = this.expireItem(item);
		return expired ? void 0 : item.value;
	}
});

export default Store;