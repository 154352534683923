import appModule from 'appModule';
// import link from 'link';
// import Layout from './layout';
// import groupModule from './group';
import { urls } from 'coms/urls';
import link from 'link';
// import './admin-groups.less';
	
var module = appModule.content({
	name: 'admin/features',
	label: 'Управление модулями',
	link: () => {
		// const url = link(urls.page('admin/features'), 'Управление модулями');
		// console.error('check url', url);
		return {
			href: urls.page('admin/features'),
			label: 'Управление модулями',
			attrs: {}
		};
	},
	// route: '',
	routeData: () => urls.page('admin/features'),
	claims: {
		svcAdminModule: ["view"]
	},			
	onRun: function () {
		//this.showButtonsLayout();
		// this.createAndShow(Layout);
	}
});

// module.addSubModule(groupModule);
// module.addSubModule(searchOnMap);
// module.addSubModule(moduleRealtyObject);
// module.addSubModule(moduleRealtyBuilding);

export default module;

