import { Behavior, triggerMethod } from 'vendors';

export const PagedCollection = Behavior.extend({
	_isEoD(col) {
		if (!col) { return true; }
		if ('isItEndOfData' in col) {
			return col.isItEndOfData();
		}
		if ('isEOD' in col) {
			return col.isEOD();
		}
		return true;
	},
	onDataTryLoadNext: function () {
		//console.log('onDataTryLoadNext')
		var col = this.view.collection;
		if (this._isEoD(col)) {
			this.view.autoFetchDisabled = true;
			return;
		}
		// if (!col || !col.isItEndOfData || col.isItEndOfData()) {
		// 	this.view.autoFetchDisabled = true;
		// 	return;
		// }

		if (!this.view.autoFetchDisabled) {
			this.view.triggerMethod('data:load:next');
			return;
		}

	},
	onFiltersDataChange: function () {
		//console.log('onDataLoadFirst')
		this._triggerDataLoad('load:first', arguments);
	},
	onDataLoadNext: function () {
		//console.log('onDataLoadNext')
		this._triggerDataLoad('load:next', arguments);
	},
	_triggerDataLoad: function (type, _args) {
		var args = [].slice.call(_args);
		args.unshift(type);
		args.unshift(this.view.collection);
		// console.log('-ARGU-', args);
		triggerMethod.apply(null, args);		
	},
	collectionEvents: {
		'data:load:first:complete': function () {
			this.view.triggerMethod('data:filtered:first');
		}
	}
});
