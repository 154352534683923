export function textToBuffer(text) {
	

	const el = document.createElement("input");
	el.value = text;
	document.body.append(el);
	el.select();
	try {
		document.execCommand('copy');
	} catch (error) {
			console.error(error);
	} finally {
			el.remove();
	}
}