import { View } from 'core';
import './visibility-button.less';
export const VisibilityButton = View.extend({
	baseClassName: 'visibility-button',
	
	classNames: [
		v => v.getVisibleCssClass(),
		v => v.getOwnVisibleCssClass(),
		v => v.getOption('clickable') ? 'clickable' : ''
	],

	template: `<div><i class="eye-icon"></i></div>
	<b><%= label %></b>`,
	
	templateContext() {
		return {
			label: this.getLabel()
		}
	},
	getLabel() {
		return this.getOption('label', true);
	},
	getVisibleCssClass() {
		const visible = this.getOption('visible', true);
		return visible ? 'visible' : 'not-visible';
	},
	getOwnVisibleCssClass() {
		const visible = this.getOption('ownVisible', true);
		return visible ? 'own-visible' : '';
	}
});