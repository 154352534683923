import { _ } from './underscore';

import MnAppRouter from 'marionette.approuter';

import { 
    Behavior, MnObject, 
    Application,
    View, 
    CollectionView as _CollectionView,
    NextCollectionView as _NextCollectionView,
    DomApi, 
    triggerMethod, triggerMethodOn as vendorTriggerMethodOn,
    setEnabled,
		Events as MnEvents,
		VERSION 
} from 'backbone.marionette';

import { Events as BbEvents } from 'backbone';

const Events = Object.assign({}, BbEvents, MnEvents);

console.error('MN VERSION:', VERSION);

const MnView = View;
const CollectionView = _NextCollectionView || _CollectionView;
const NextCollectionView = _NextCollectionView || _CollectionView;

Behavior.forView = function (opts) {
	return _.extend({ behaviorClass: this }, opts);
};

if (!vendorTriggerMethodOn) {
    console.warn('vendorTriggerMethodOn is missing');
}

const triggerMethodOn = vendorTriggerMethodOn || function(context, ...args) {
    return View.prototype.triggerMethod.apply(context, args);
}

// View.prototype.childViewEventPrefix = true;
// CollectionView.prototype.childViewEventPrefix = true;

setEnabled('childViewEventPrefix', true);


const baseMixinTemplateContext = View.prototype.mixinTemplateContext;

function mixinTemplateContext(context, ...args) {
    return baseMixinTemplateContext.apply(context, args)
}

export {
    MnView, View, CollectionView, NextCollectionView,
		Events,
    Behavior, MnObject, Application, MnAppRouter,
    triggerMethod, triggerMethodOn,
    DomApi,
    mixinTemplateContext,

}