﻿//define('ui-layouts-board-list-layout', [], function() {});

import template from './layout.html';

import { MneView, MneNextCollectionView } from 'core/mne';

var View = MneView.extend({
    //template: '#tmpl-ui-layouts-board-list-layout',
    template,
    regions: {
        'children': { el: 'section', replaceElement: false },
    },
    ui: {
        counter: 'header .badge',
    },
    onRender: function() {
        this.showChildren();
    },
    showChildren: function() {
        var view = this.buildListView();
        this.showChildView('children', view);
    },
    buildListView: function() {
        var View = this.getListViewClass();
        var options = this.getListViewOptions();
        return new View(options);
    },
    getListViewClass: function() {
        return this.getOption('listView') || MneNextCollectionView;
    },
    getListViewOptions: function() {
        var opts = this.getOption('listViewOptions');

        if (_.isFunction(opts))
            opts = opts();

        return _.extend({
            collection: this.collection,
            childView: this.getOption('childView'),
            childViewOptions: this.getOption('childViewOptions'),
            viewComparator: this.getOption('viewComparator'),
            }, opts);
    },
    collectionEvents: {
        'add remove update': function () {
            this.recalcCount();
        }
    },
    recalcCount: function () {
        this.ui.counter.html((this.collection && this.collection.length) || 0);
    },
    templateContext: function(){
        return {
            label: this.getOption('label') || this.getOption('name'),
            name: this.getOption('name'),
            count: this.collection && this.collection.length
        };
    },
});
export default View;