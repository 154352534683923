import { HamburgerView } from "coms/ui/HamburgerView";
import { View, CollectionView, Collection } from 'core';
import { VisibilityButton } from "coms/visibility-button";
import { ObjectBrokerVisibilityButton, ObjectAdvertVisibilityButton } from "mods/realties/objects/views";

import { createCompare } from 'utils';
import { mediaApi } from "apis/media";
import { NextIconButtonView } from 'coms/ui/Button';
import { getFullPriceInfo } from "utils/price";
import { MultyValues, SelectButton, PlateActions, Separator, LastActualize, ActualizeBlock, executeClickActionMixin } from "./common";


const priceInfoMixin = {
	getRealtyObject() {
		return this.getOption('realtyObject');
	},
	getPriceInfo() {
		const realtyObject = this.getRealtyObject();
		const info = getFullPriceInfo(this.model, { realtyObject });
		return info;
	},
	getPriceItem() {
		const type = this.getOption('priceType');
		const info = this.getPriceInfo();
		return info[type];
	},
	getPriceHtml(priceInfo) {
		priceInfo = priceInfo || this.getPriceItem() || {};
		return `<span class="price-value">${priceInfo.priceString} р.</span><span class="price-postfix"> ${priceInfo.postfix} </span>`;
	},
	getWords(wordsType, priceInfo) {
		priceInfo = priceInfo || this.getPriceItem() || {};
		if (wordsType === 'includes') {
			return priceInfo.priceIncludesWords || [];
		} else if (wordsType === 'excludes') {
			return priceInfo.priceExcludesWords || [];
		}
		return ['ухты'];
	},
	wordWrapper: word => `<span class="small-badge">${word}</span>`,
	getWordsHtml(words, priceInfo) {
		priceInfo = priceInfo || this.getPriceItem() || {};
		const wrapper = this.getOption('wordWrapper', false);
		return words.map(wrapper).join('');
	},

}


const MarketOperation = View.extend({
	baseClassName: 'market-operation',
	template: '<span class="value operation"><%= operation %></span><span class="value market"><%= market %></span><span class="value status"><%= status %></span>',
	displaySchemaKeys: ['market', 'operation', 'status'],
	templateContext() { return this.getDisplayHash(); }
});




const PriceItem = View.extend({
	//template: '<div><%= price %></div><div><%= words %></div>',
	template: '<%= price %>',
	classNames: [
		v => 'price-' + v.getOption('priceType')
	],
	...priceInfoMixin,
	templateContext() {
		const priceInfo = this.getPriceItem() || {};
		if (!priceInfo.postfix && !priceInfo.isRent) {
			priceInfo.postfix = 'за площадь';
		}
		return {
			price: this.getPriceHtml(priceInfo),
			//words: this.getWordsHtml(priceInfo),
		}
	},
});




const PriceBlock = HamburgerView.extend({
	...executeClickActionMixin,
	clickAction: 'editprice',		
	baseClassName: 'flex-row price-group',
	thisClassName: 'clickable-block',
	tagTitle: 'Кликните для изменения цены',
	childrenViews: [
		{
			class: PriceItem,
			priceType: 'meter',
		},
		{
			class: PriceItem,
			priceType: 'square',
		},
	],
	childViewOptions() {
		return {
			realtyObject: this.getOption('realtyObject')
		}
	}
});





const OfferActions = PlateActions.extend({
	actionsName: 'offer-actions',
	type: 'offers'
});



const Commercial1 = 	MultyValues.extend({
	baseClassName: 'offer-details table-properties-column', 
	properties: ['forAgent.income', 'forAgent.contractType'],
	childViewOptions: {
		withLabel: true
	}
});


const Commercial2 = 	MultyValues.extend({
	baseClassName: 'offer-details table-properties-column', 
	properties: ['forCustomer.taxType', 'forCustomer.boma'],
	childViewOptions: {
		withLabel: true
	}
});

const Commercials = HamburgerView.extend({
	...executeClickActionMixin,
	clickAction: 'editcommercial',		
	baseClassName: 'flex-row commercials gap-on',
	thisClassName: 'clickable-block',
	tagTitle: 'Кликните для изменения коммерческих условий',
	childrenViews: [
		Commercial1,
		Commercial2,
	],
	// childViewTriggers: {
	// 	'multy:action:click':'multy:action:click'
	// },
})


const MiniAdditionals = View.extend({
	...priceInfoMixin,
	priceType: 'square',
	baseClassName: 'price-additionals just-words',
	template: '<%= includes %>',
	wordWrapper: word => `<span class="word">${word}</span>`,
	templateContext() {
		return {
			includes: this.getWordsHtml(this.getWords('includes')) || '',
		}
	}
});



const MainInfo = HamburgerView.extend({
	baseClassName: 'flex-column',
	childrenViews: [
		MarketOperation,
		PriceBlock,
		MiniAdditionals
	],
	childViewOptions() {
		return {
			realtyObject: this.getOption('realtyObject')
		}
	},
	childViewTriggers: {
		'multy:action:click':'multy:action:click'
	},
});

const MainBlockView = HamburgerView.extend({
	baseClassName: 'flex-row',
	childrenViews: [
		OfferActions,
		{ class: SelectButton, thisClassName: 'offer' },
		MainInfo,
	],
	childViewOptions() {
		return {
			realtyObject: this.getOption('realtyObject'),
			offersSelector: this.getOption('offersSelector'), 
		}
	},
	childViewTriggers: {
		'toggle:select':'toggle:offer:select',
		'multy:action:click':'multy:action:click'
	},


});





export const OfferListItem = HamburgerView.extend({
	baseClassName: 'offer-item flex-row centered gap-on wrap-on',
	updateElClassBeforeRender: true,
	classNames: [
		v => 'offer-' + v.model.get('status'),
		v => v.model.get('operation'),
		v => v.getSelectedClassName()
	],
	modelEvents:{
		'change': 'render',
		'toggle:select':'updateClassName'
	},
	getSelectedClassName() {
		const selector = this.getOption('offersSelector');
		return selector.isSelected(this.model) ? 'selected' : '';
	},
	childrenViews: [
		MainBlockView,
		Commercials,
		Separator,
		ActualizeBlock,

		// MarketOperation,
		// PriceBlock,
		// Separator,
		// { class: VisibilityButton, label: 'брок.' },
		// { class: VisibilityButton, label: 'рек.' },		
	],

	
	childViewOptions() {
		return {
			realtyObject: this.getOption('realtyObject'),
			objectsSelector: this.getOption('objectsSelector'), 
			offersSelector: this.getOption('offersSelector'), 
		}
	},	
	childViewEvents: {
		'toggle:offer:select'(event) {
			this.trigger('toggle:offer:select', event, this);
		},
		'multy:action:click'(type = 'offers', action, models) {
			console.log('trying offer...');
			this.trigger('multy:action:click', type, action, models);
		}
	},

});



/*



const PriceAdditionals = View.extend({
	...priceInfoMixin,
	priceType: 'square',
	baseClassName: 'price-additionals',
	template: '<div><label>В стоимость включено:</label><span class="value"><%= includes %></span></div><div><label>В стоимость не включено:</label><span><%= excludes %></span></div>',
	templateContext() {
		return {
			includes: this.getWordsHtml(this.getWords('includes')) || '&mdash;',
			excludes: this.getWordsHtml(this.getWords('excludes')) || '&mdash;',
		}
	}
});


const Commercial = 	MultyValues.extend({
	baseClassName: 'offer-details table-properties-column', 
	properties: ['forAgent.income', 'forAgent.contractType', 'forCustomer.taxType', 'forCustomer.boma'],
	childViewOptions: {
		withLabel: true
	}
});



// const MainBlock = HamburgerView.extend({
// 	baseClassName: 'flex-row',
// 	childrenViews: [
// 		Actions,
// 		SelectButton,
// 		PriceBlock,
// 	],
// 	childViewOptions() {
// 		return {
// 			realtyObject: this.getOption('realtyObject')
// 		}
// 	}
// });

// const MainOfferView = HamburgerView.extend({
// 	//baseClassName: 'flex-row centered gap-on wrap-on',
// 	baseClassName: 'flex-column',
// 	childrenViews: [
// 		MarketOperation,
// 		MainBlock
// 	],
// 	childViewOptions() {
// 		return {
// 			realtyObject: this.getOption('realtyObject')
// 		}
// 	}
// });



const ActualizeActions = HamburgerView.extend({
	baseClassName: 'actions',
	getChildren() {

	}
});


// const ActualizeBlock = HamburgerView.extend({
// 	baseClassName: 'actualize-block',
// 	thisClassName: 'flex-column',
// 	childrenViews: [
// 		LastActualize,
// 		ActualizeActions
// 	]
// })

*/