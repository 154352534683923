// define('svc/actualization/realties-list/realties-collection', [
// 	'svc/actualization/realties-list/config'
// ], function(
// 	cfg
// ){});

import cfg from '../config'
import { BbeCollection } from 'core/bbe';
const Collection = BbeCollection.extend({
	url: cfg.realtiesApiUrl
});

export default Collection;
