import { CoreBbView } from "../../../core/coreBbView";
import { HamburgerView } from "../HamburgerView";
import { getButtonsView } from "./ButtonsView";
import modals from '_libs/modals';
import './modern-edit-layout.less';

export const EditView = HamburgerView.extend({
	baseClassName: 'modern-edit-layout',
	getChildren() {

		return [
			this.getHeader(),
			this.getContent(),
			getButtonsView(this),
		];
	},
	getHeader() {
		let header = this.getOption('header', true);
		if (typeof header === 'string') {
			header = {
				class: CoreBbView,
				tagName: 'header',
				template: header
			}
		}
		return header ? header : undefined;
	},
	getContent() {
		return this.getOption('content', true);
	},
	async _beforeEditDone(value, ...args) {
		console.log('... before edit done start');
		const action = this.getOption('beforeEditDone', false);
		let result = { ok: true };
		if (action) {
			console.log('		trying action');
			try {
				const actionResult = await action(value, ...args);
				if (actionResult && typeof actionResult === 'object' && 'ok' in actionResult) {
					console.log('		own result', actionResult);
					result = actionResult
				} else {
					console.log('		creating OK result', actionResult);
					result.ok = true;	
					result.value = actionResult;
				}
			} catch (exc) {
				console.log('		exc catched', exc);
				result.ok = false;
				result.error = exc;
			}			
		} else {
			result.value = value;
			console.log('		no action');
		}
		if (result.ok) {
			console.log('		resolving', result);
			this.triggerMethod('edit:done', result);
		} else {
			this.triggerMethod('edit:error', result);
		}
		return result;
	},
	childViewEvents:{
		'before:edit:done'(...args) {
			this._beforeEditDone(...args);
		}
	},
	onEditError(res = {}) {
		let errors = res.error;
		if (!errors) { return; }
		if (!Array.isArray(errors)) {
			errors = [errors];
		}
		const text = errors.map(err => `<div>• ${err}</div>`).join('');
		modals.error('Ошибка', text);
	}
});