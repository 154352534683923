import { CoreBbView } from "core";
import { _ } from 'vendors';
import { getValueByPath } from "utils";
//import { invokeValue } from "utils/invoke";
import { propertySchemaApi, modelSchemaApi } from "apis/schema";
import { modelValueMixin } from "../PropertiesLineView/common";

function asString(value, defaultValue = '') {
    return value == null ? defaultValue : value;
}

export const ValueWithLabelView = CoreBbView.extend({

    ...modelValueMixin,

    baseClassName: 'ui2-value-with-label',

    template: `<div data-role="label"><%= labelHtml %></div>
    <div data-role="value"><%= valueHtml %></div>
    <% if (descHtml) { %>
    <div data-role="desc"><%= descHtml %></div>
    <% } %>`,

    labelSelector: 'data-role="label"',
    valueSelector: 'data-role="value"',

    templateContext() {
        let ctx = {
            labelHtml: this.getLabelHtml(),
            valueHtml: this.getValueHtml(),
            descHtml: this.getDescHtml()
        }
        return ctx;
    },

    initialize(options) {
        this.initProperty(options);
        this.initModelListeners();
    },

    initProperty(options = {}) {
        this.mergeOptions(options, ['displayValue', 'label', 'modelSchema']);        
        this.initSchema(options);
    },

    initSchema(options = {}) {
			
			let { property, schema } = options;

			let propertySchema = propertySchemaApi.getSchema(schema, this.modelSchema);
			if (!propertySchema) { 
					this.property = property;
					return; 
			}

			this.schema = propertySchema;
			this.property = propertySchema.property;
			this.modelValue = this.getModelValue();

			if (propertySchema.editProperty) {
					this.editProperty = propertySchema.editProperty;
			}
    },

    initModelListeners() {
        let property = this.editProperty || this.property;
        if (!this.model) { return; }

        const changeEvents = [];
        if (property) {
            changeEvents.push(property);
        }

        if (this.schema && this.schema.refreshOnChange) {
            let also = this.schema.refreshOnChange;
            if (!Array.isArray(also)) {
                also = [also];
            }
            changeEvents.push.apply(changeEvents, also);
        }

        if (changeEvents.length) {
            const event = changeEvents.map(e => 'change:' + e).join(' ');
            if (changeEvents.length > 1) {
                console.warn('listen-to', property, changeEvents);
            }
            this.listenTo(this.model, event, () => this.update());
        }

        // if (property && this.model) {
        //     if (this.editProperty) {
        //         console.log('listen: ', 'change:' + property, this.cid, this.model.attributes);
        //     }
        //     this.listenTo(this.model, 'change:' + property, this.update);
        // } else {
        //     //console.log('missing:', this.property, this.model);
        // }
    },

    onRender() {
        this.update();
    },

    update() {
        this.updateLabel();
        this.updateValue();
        this.updateDesc();
        this.updateCssClasses();
    },
    updateCssClasses() {
			const model = this.getOption('modelHash') || this.model;
        if (!this.schema || !model) { return; }
        let hasValue = propertySchemaApi.hasValue(this.schema, model, this.modelSchema);
        if (hasValue) {
            this.$el.removeClass('empty');
            this.$el.addClass('filled');
        } else {
            this.$el.removeClass('filled');
            this.$el.addClass('empty');
        }
    },
    updateLabel() {
        let el = this.getLabelElement();
        let value = this.getLabelHtml();
        this._setElContent(el, value);
    },

    updateValue() {
        let el = this.getValueElement();
        let value = this.getValueHtml();
        
        this._setElContent(el, value);
    },

    updateDesc() {
        const value = this.getDescHtml();
        const el = this._getElement('desc', '[data-role=desc]');
        this._setElContent(el, value);
    },

    _setElContent($el, html) {
        $el.html(html);
    },

    getLabelElement() {
        return this._getElement('label', '[data-role=label]');
    },

    getValueElement() {
        return this._getElement('value', '[data-role=value]');
    },

    _getElement(key, defaultSelector) {
        let selector = this.getOption(key, 'Selector') || defaultSelector;
        return this.$(selector);
    },


    getLabelHtml() {
        if (this.schema) {
            return propertySchemaApi.getLabel(this.schema, this.model, this.modelSchema);
        }
        let label = this.getOption('label', true);
        return asString(label);
    },

    getValueHtml() {
        if (this.displayValue) {
            let value = this.getValue();
            let context = this.schema || this;
            return asString(this.displayValue.call(context, value));
        } else if (this.schema && this.modelValue) {
            return propertySchemaApi.displayValue(this.schema, this.modelValue, this.modelSchema);
        }
        return asString(this.getValue());
    },

    getDescHtml() {
        if (!this.schema || !this.schema.displaySmallValue) { return ''; }

        const value = this.schema.getSmallValue 
            ? this.schema.getSmallValue(this.modelValue, this.modelSchema)
            : undefined;

        return asString(this.schema.displaySmallValue(value, this.modelValue, this.modelSchema));
    },

    getValue() {
        const modelValue = this.getModelValue();
        if (this.property && modelValue) {
            if (this.schema) {
                return propertySchemaApi.getValue(this.schema, modelValue, this.modelSchema);
            }
            return getValueByPath(modelValue, this.property);
        } else {
            return this.getOption('value', true);
        }
    }

});


