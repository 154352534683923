// define('svc/admin/groups/group', [
// 	'appModule', 'link', 'svc/admin/groups/group/layout', 'svc/admin/groups/model'
// ], function (
// 	appModule, link, Layout, Model
// 	) {
// });

import appModule from 'appModule';
import link from 'link';
import Layout from './layout';
import Model from '../model';

import './group.less';

	
var module = appModule.content({
	name: 'admin/group',
	//label: 'Управление группами',
	routeData: () => link.route('adminGroupCard'),
	claims: {
		svcAdminModule: ["manageGroups"]
	},			
	onBeforeRun: function (id) {
		var model = new Model({ id });
		this.addRunArgument(model);
		this.addRunPromise(model.fetch());
	},
	onRun: function (model) {
		var module = this;
		module.createAndShow(Layout, { header: function () { return 'группа ' + this.model.get('name'); }, model: model }, { model: model }, { navLinks: this.navLinks() });
	}
});

// module.addSubModule(search);
// module.addSubModule(searchOnMap);
// module.addSubModule(moduleRealtyObject);
// module.addSubModule(moduleRealtyBuilding);

export default module;

