﻿//define('svc-tasks-task-layout', ['bus','bus-ws', 'ui-layouts-dataroleActions', 'ui-layouts-commentBoardList', 'ui-layouts-logsBoardList', 'behaviors-datarole', 'svc-deals-deal-dealInList', 'svc-clients-client-clientInList'], function (Bus, wsBus, DataroleActions, CommentBoardList, LogsBoardList, DataroleBehavior, DealInList, ClientInList){});


// import Bus from 'bus';
import wsBus from 'bus/ws';
import DataroleActions from 'ui/layouts/dataroleActions';
import CommentBoardList from 'ui/layouts/commentBoardList';
import LogsBoardList from 'ui/layouts/logsBoardList';
import DealInList from 'svc/deals/deal/dealInList';
import ClientInList from 'svc/clients/client/clientInList';

import template from './layout.html';
import { MneView } from 'core/mne';
import DataroleBehavior from 'behaviors/datarole';

var Layout = MneView.extend({
	className:'board-container',
	//template: '#tmpl-service-tasks-task-layout',
	template,
	behaviors: [DataroleBehavior],
	regions: {
		'actions': { el: '.task-actions', replaceElement: true },
		'comments': '.board-list-region.comments',
		'logs': '.board-list-region.logs',
		'deal': '.deal-region',
		'client': '.client-region',
	},
	modelEvents: {
		'change':'render',
	},
	onRender: function () {
		this.showActionsLayout();
		this.showCommentsLayout();
		//this.showLogsLayout();
		this.showDeal();
		this.showClient();
	},

	onAction: function (context, modal) {
		if (typeof this.model.executeAction === 'function')
			this.model.executeAction({ action: context.type, modal: modal, view: this });
	},
	showDeal: function () {
		var deal = this.model.get('deal');
		if (!deal) return;
		var view = DealInList.create({ modelHash: deal });
		if(view)
			this.showChildView('deal', view);
	},
	showClient: function () {
		var client = this.model.get('client');
		if (!client) return;
		var view = ClientInList.create({ modelHash: client });
		if (view)
			this.showChildView('client', view);
	},
	showCommentsLayout: function () {
		var taskId = this.model.get('id');
		var view = new CommentBoardList({ entity: 'task', entityId: taskId, wsRadio: wsBus.radios.task(taskId)  });
		this.showChildView('comments', view);
	},
	showLogsLayout: function () {
		var view = new LogsBoardList({ entity: 'task', entityId: this.model.get('id') });
		this.showChildView('logs', view);
	},
	showActionsLayout: function () {
		var skipActions = ['openModal', 'openPage'];
		var filter = function (act) {
			return skipActions.indexOf(act.type) == -1;
		}
		//var filter = function (act) {
		//	return act.type != 'openTaskPage'
		//}

		let model = this.model;

		var view = DataroleActions.create({ model: model, layoutType: 'board-list-block', actionsFilter: filter });
		if (view)
			this.showChildView('actions', view);
	},
});

// Bus.Views.reply('task:page', function () {
// 	return Layout;
// });

export default Layout;
