import { viewIconApiMixin } from "apis/iconApi";
import { NextButtonView } from "./NextButtonView";

export const NextIconButtonView = NextButtonView.extend({
	
	template: '<span><%= iconLeftHtml %><%= textHtml %><%= iconRightHtml %></span>',
	wrapEmptyText: false,
	templateContext() {
		const ctx = {
			iconLeftHtml: this.getIconHtml(),
			textHtml: this.getTextHtml(),
			iconRightHtml: this.getIconHtml('Right')
		}
		return ctx;
	},
	...viewIconApiMixin,
});