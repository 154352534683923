import appCfg from 'app-config';

export function getRootUrl() {
    return appCfg.urls.apiV('realties/offers');
}

export function getSimpleRootUrl() {
    let url = getRootUrl();
    return url + '/:id/simple';
}

export function getFullRootUrl() {
	let url = getRootUrl();
	return url + '/:id';
}