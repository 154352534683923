﻿//define('link', ['app-config', 'app-paths'], function (cfg, Paths) {});

import cfg from 'app-config'; //'app-config';
import Paths from 'app-paths'; //'app-paths'
import serverCfg from 'server-cfg';

function link(_urlkey, label, opts, auth, skipRoute) {
	opts || (opts = {});
	opts.attrs || (opts.attrs = {});
	skipRoute = skipRoute != null ? skipRoute : opts.skipRoute;
	if (auth === true || auth === false)
		_.extend(opts, { auth: auth });
	if (skipRoute === true)
		_.extend(opts.attrs, { 'data-skiproute': "true" });

	if (label)
		_.extend(opts, { label: label });


	var url = link.url(_urlkey);
	if (_urlkey)
		_.extend(opts, { href: url });

	return opts;
	//return getLink(opts);
}

function path(type, _key) {
	var key = _key;
	var url = Paths[type][key];
	if (url == null) {
		console.warn('Paths missing: ', type, ', ', key);
	}
	return url;

}

link.url = function (_key) {
	var allKeys = _key.split(':');
	var key = allKeys.shift();

	var url = path('url', key);

	//console.log('^', _key, url);

	if (url != null && !url.startsWith('/'))
		url = '/' + url;
	if (url != null && allKeys.length > 0)
		url += '/' + allKeys.join('/');

	return url;
}

link.route = function (key) {
	var url = path('route', key);
	return url;
	// return () => {
	// }
}

link.img = function (id, opts, ext) {
	if (!id) console.warn('image id is empty');
	ext || (ext = 'jpg');
	opts || (opts = 'asis');

	if (serverCfg.svcWebId) {
		return cfg.paths.media + 'svc/' + serverCfg.svcWebId + '/' + id + '/' + opts + '/' + ext;
	} else {
		return cfg.paths.mediaImage + id + '/' + opts + '/' + ext;
	}


}

link.url_v4 = function(...args) {
	return cfg.appUrl(...args);
}
link.svcurl_v4 = function(...args) {
	return cfg.appSvcUrl(...args);
}

link.svcv4 = function(label, path, options) {
	return Object.assign({
		label,
		href: cfg.appSvcUrl(path)
	}, options);
}


export default link;
