﻿//define('svc-deals-create-createLayout', ['m-deals', 'm-clients', 'ui-controls-phone', 'ui-layouts-contactsList'], function (dealsNs, clientsNs, phoneControl, ContactsList) {});

import phoneControl from 'ui/controls/phone';
import ContactsList from 'ui/layouts/contactsList';

import newClientTemplate from './newclient.html';
import template from './createLayout.html';

import { MneView } from 'core/mne';

var NewClient = MneView.extend({
	tagName:'ul',
	//template: '#tmpl-service-deals-create-newclient',
	template: newClientTemplate,
	templateContext: function(){
		return {
			name: this.getOption('company-name') || '',
		};
	}
});


var View = MneView.extend({
	//template: '#tmpl-service-deals-create-createLayout',
	template,
	regions: {
		'client': { el: '.client-region', replaceElement: true },
		'phone1': '.person-phone1',
		'phone2': '.person-phone2',
	},
	initialize: function () {
		this.personHash = {};
	},
	modelEvents: {
		'change:client': function() {
			this.showClient();
		}
	},
	events: {
		'input [data-name]': function (e, val) {
			var $el = $(e.target);
			var name = $el.data('name');
			val = val || $el.val();
			this.personHash[name] = val;
			if (name == 'person-name')
				this.model.get('lead').personName = val;
			if (name == 'company-name')
				this.model.get('lead').companyName = val;
			if (name == 'person-phone1')
				this.model.get('lead').personPhone1 = val;
			if (name == 'person-phone2')
				this.model.get('lead').personPhone2 = val;
			if (name == 'person-email')
				this.model.get('lead').personEmail = val;

			this.triggerMethod('custom:person:change', _.extend({}, this.personHash));
			this.model.trigger('storeable:change', this.model);

		},
	},
	showClient: function() {
		var _this = this;
		var client = this.model.getClient();
		if (client && client.getId()) {
			let view = ContactsList.ForClient(client, {
				addElClass: 'fastaction fastaction-close',
				childViewOptions: function () {
					return {
						fastaction: true,
						fastactionContext: _this,
						fastactionEvent: 'joined:client:remove',
					};
				}					
			});
			if (view)
				this.showChildView('client', view);

		} else {
			var lead = this.model.get('lead') || {};
			var opts = {};
			if (lead.companyName) {
				_this.personHash['company-name'] = lead.companyName;
				opts['company-name'] = lead.companyName;
			}
			let view = new NewClient(opts);
			this.showChildView('client', view);

		}
	},
	onRender: function () {
		
		var lead = this.model.get('lead') || {};

		var search = this.getOption('search') || {};
		var phone = lead.personPhone1 || search.phone;
		this.personHash['person-phone1'] = phone;

		var arr = (phone || '').split('.');

		var phone1 = new phoneControl({
			data: { country: arr[0], region: arr[1], number: arr[2] },
			triggerOnEl: 'input',
			attributes: {
				'data-name':'person-phone1'
			},
		});

		var phopt2;
		if (lead.personPhone2) {
			var arr2 = lead.personPhone2.split('.');
			phopt2 = { country: arr2[0], region: arr2[1], number: arr2[2] };
		}

		var phone2 = new phoneControl({
			data: phopt2,
			triggerOnEl: 'input',
			attributes: {
				'data-name': 'person-phone2'
			},
		});
		this.showChildView('phone1', phone1);
		this.showChildView('phone2', phone2);
		this.showClient();

		if (lead.personName)
			this.triggerMethod('custom:person:change', _.extend({}, this.personHash, {'person-name': lead.personName}));


	},
	templateContextExtend: function () {
		var lead = this.model.get('lead') || {};

		return {
			personEmail: lead.personEmail || '',
			personName: lead.personName || '',
		};
	}
});

export default View;