﻿//define('m-abstract-cardable', ['bus','_libs/identity'], function (Bus, identity) {});


import Bus from 'bus/core';
//import identity from '_libs/identity';

import { templateFuncs } from 'core/mne';

import { _, BbView } from 'vendors'

// import { modalsApi } from 'apis';
// import { View as CoreView } from 'core';


var mixin = function (options) {
	options || (options = {});

	return {
		cardView: options.cardView,
		cardRootUrl: options.cardRootUrl,
		cardUrl: options.cardUrl || function () {
			return _.result(this, 'cardRootUrl') + '/' + this.get('id')
		},
		actionOpenPage: function (context, modal) {
			//var url = _.result(this, 'cardUrl');
			Bus.navigate(_.result(this, 'cardUrl'));
		},
		actionOpenModal: function (context, modal) {
			var viewName = _.result(this, 'cardViewModal') || _.result(this, 'cardView');
			if (viewName) {

				var View;

				if (typeof viewName === 'string') {
					View = Bus.Views.request(viewName);
				}
				else if (typeof viewName == 'function' && viewName.prototype instanceof BbView) {
					View = viewName;
				}


				var hash = _.result(this, 'modalOptions') || {};
				
				

				if (View) {

					var options = _.extend(hash, {
						View: View,
						model: this
					}, this.openInModalOptions);

					console.warn('#cardable#', options);
					return Bus.modals.request('show:fullscreen', options);

				}
				//console.log(!!View, 2);
			} else {
				console.warn('cardView undefined');
			}
		},

	}

};

// var assetsRadio = Bus.assets;

// var templateFuncs = 
	// assetsRadio.request('templateFuncs'/*DEPRECATED*/, templateFuncs);
_.extend(templateFuncs, {
	_cardUrl: function (id) {
		return this._modelfunc('cardUrl');
	},
});


export default mixin;