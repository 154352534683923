
export const dateConstants = {}

let D = dateConstants;

D.MILLISECOND = 1;
D.MILLISECONDS = n => D.MILLISECOND * n;

D.SECOND = D.MILLISECOND * 1000;
D.SECONDS = n => D.SECOND * n;

D.MINUTE = D.SECOND * 60;
D.MINUTES = n => D.MINUTE * n;

D.HOUR = D.MINUTE * 60;
D.HOURS = n => D.HOUR * n;

D.DAY = D.HOUR * 24;
D.DAYS = n => D.DAY * n;

D.NOW = 'now';
D.FUTURE = 'future';
D.PAST = 'past';

D.MonthNames = {
    'ru': [
        { short: 'янв', full: 'январь', genetive: 'января' },
        { short: 'фев', full: 'февраль', genetive: 'февраля' },
        { short: 'мар', full: 'март', genetive: 'марта' },
        { short: 'апр', full: 'апрель', genetive: 'апреля' },
        { short: 'май', full: 'май', genetive: 'мая' },
        { short: 'июн', full: 'июнь', genetive: 'июня' },
        { short: 'июл', full: 'июль', genetive: 'июля' },
        { short: 'авг', full: 'август', genetive: 'августа' },
        { short: 'сен', full: 'сентябрь', genetive: 'сентября' },
        { short: 'окт', full: 'октябрь', genetive: 'октября' },
        { short: 'ноя', full: 'ноябрь', genetive: 'ноября' },
        { short: 'дек', full: 'декабрь', genetive: 'декабря' },
    ],
};
