import helpers from '../../../helpers';

import { BbeModel } from 'core/bbe';

import { MneView } from 'core/mne';
import modals from '_libs/modals';
/*
        <div class="as-ui-atom item " data-filter="all">
            <i>всего площадей</i>
            <div>xxx</div>
            <b></b>
        </div>
        <div class="as-ui-atom item " data-filter="brokers">
            <i>доступных брокерам</i>
            <div>xxx</div>
            <b></b>
        </div>        
        <div class="as-ui-atom item " data-filter="advert">
            <i>доступных в рекламе</i>
            <div>xxx</div>
            <b></b>
        </div>
        <div class="as-ui-atom item " data-filter="hidden">
            <i>недоступных</i>
            <div>xxx</div>
            <b></b>
        </div>
        <div class="as-ui-atom item " data-filter="archived">
            <i>в архиве</i>
            <div>xxx</div>
            <b></b>
        </div>

*/

export default MneView.extend({
    className: 'wrapper',
    template: _.template(`
        <%= filtersButtonsHtml %>

        <div class="long-separator"></div>
        <button class="add-object">создать площадь</button>
    `),
    _makeActive(filter, $el, trigger) {
        this.$('[data-filter]').removeClass('active');
        if (!$el) {
            $el = this.$('[data-filter="'+filter+'"]');
        }
        $el.addClass('active');
        if (trigger) {
            this.filterModel.set('modelsSet', filter);
            this.trigger('new:filter', filter);
        }
    },
		_accentNewObject(model) {
			this._makeActive('all', undefined, true);
			model.trigger('apply:accent');
		},
    events: {
        'click button.add-object'() {
            let modelType = this.getOption('modelType');
            let withOwner, withRealty;

            if (modelType == 'owner')
                withRealty = true

            if (modelType == 'realty')
                withOwner = true

            let options = { 
                withOwner, 
                withRealty, 
                beforeApply: data => {

                    data = helpers.fixCreateObjectData(data);

                    let post = new BbeModel();

                    post.url = this.model.url() + '/create-object';

                    post.save(null, { attrs: data, wait: true }).then((apiData) => {
                        console.log('*** data ***', apiData);
                        let newObjectModel = this.model.objectCollection.add(apiData);
                        newObjectModel.trigger('refresh', newObjectModel);
												this._accentNewObject(newObjectModel);
												modals.notify('Площадь добавлена');

                    }, {

                    });
                    //console.log('oplya!', helpers.fixCreateObjectData(data)) 
                }
            };

            console.log('BAM', this.getOption('modelType'), options);

            helpers.createObjectInModal(options);

        },
        'click [data-filter]:not(.active)'(event) {
            let $el = $(event.target).closest('[data-filter]');
            let filter = $el.data('filter');
            this._makeActive(filter, $el, true);
        }
    },
    initialize() {
        this.allentries = this.getOption('allentries');
        this.filterModel = this.getOption('filterModel');
        this.listenTo(this.allentries, 'refresh', () => this.filterModel.updateSetsCounts(this.allentries));
        this.listenTo(this.filterModel, 'models:sets:counts', this._updateCounters)
    },
    _updateCounters() {
        let fm = this.filterModel;
        let counts = fm.modelsSetsCounts;
        Object.keys(fm.modelsSetFilters).forEach(id => {
            let amount = counts[id] || 0;
            this.$('[data-filter-count="'+id+'"]').html(amount);
        });
        console.log(' == update counters ==', fm.modelsSetsCounts);
    },
    onRender() {
        this._makeActive(this.filterModel.get('modelsSet'));
        this._updateCounters();
    },
    _buildFiltersButtonsHtml() {
        return _.map(this.filterModel.modelsSetFilters, (cfg, id) => {
            return `<div class="as-ui-atom item " data-filter="${id}">
                <i>${cfg[1]}</i>
                <div data-filter-count="${id}">0</div>
                <b></b>
            </div>`;
        }).join('');
    },
    templateContext() {
        let filtersButtonsHtml = this._buildFiltersButtonsHtml();
        return {
            filtersButtonsHtml
        }
    }
})