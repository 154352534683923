import { _ } from './underscore';

import Backbone, { Model, Collection, View as BbView, Router } from 'backbone';
//import { knownCtors } from 'utils/knownCtors.js';
import { getValueByPath, setValueByPath } from 'utils/object-manipulations';

_.extend(Model.prototype, {
    getValueByPath(...args){
        return getValueByPath(this, ...args);
    },
    setValueByPath(...args){
        return setValueByPath(this, ...args);
    },
});

// knownCtors.push(Model);
// knownCtors.push(Collection);
// knownCtors.push(BbView);

const BbModel = Model;
const BbCollection = Collection;


/** extending history */
const history = Backbone.history;

function findRouteHandler(fragment) {
	console.log('	-findRouteHandler- ', fragment);
	var path = this.getFragment(fragment);
	return _(this.handlers).find(function (h) {
		return h.route.test(path);
	});
}


history.isExist = function (fragment, options) {
	return !!findRouteHandler.call(this, fragment, options);
}


export {
    Backbone, history, Model, BbModel, Collection, BbCollection, BbView, Router
}