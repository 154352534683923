//define('assets-marionette-behaviors-filteredCollection', [], function () {});
import { _, Behavior } from 'vendors';

export const FilteredCollection = Behavior.extend({
	getFetchResult: function () {
		return this.view.collection.getFetchResult();
	},
	getFilterObject: function () {
		var fo = this.view.getOption('filterObject');
		return fo;
	},
	onInitialize: function () {
		//console.log('filtered collection')
		var fo = this.getFilterObject();
		if(fo)
			this.listenTo(fo, 'filters:apply', this.onFiltersApply);
	},
	normalizeFiltersHash: function (hash) {
		var extender;
		var extenderFunc = this.view.getOption('extendFiltersWith');
		if (extenderFunc && _.isFunction(extenderFunc))
			extender = extenderFunc.call(this.view);
		else
			extender = _.extend({}, extenderFunc);
		_.extend(hash, extender)
		//console.log('normalizing...', hash);
		return hash;
	},
	getFiltersData: function (hash) {
		var fo = this.getFilterObject();
		var data = this.view.getOption('data');
		if (fo)
			return fo.getUrlHash(hash);
		else
			return data || {};
	},
	onFiltersApply: function (hash) {
		//var fo = this.view.getOption('filterObject');
		var data = this.getFiltersData(hash);
		var normHash = this.normalizeFiltersHash(data);
		var same = _.compareHashs(this.currentHash, normHash);

		if (same && this.filtersWasApplied) return;
		this.currentHash = normHash;
		//var normalizedHash = getUrlHashfromFilters(hash);
		//console.log('normalizedHASH', normHash);
		this.filtersWasApplied = true;
		this.view.triggerMethod('filters:data:change', normHash);
		//this.view.triggerMethod('filters:data:new', normalizedHash);
		//this.view.triggerMethod('data:load:first', normalizedHash);
	},
	onDataFilteredFirst: function () {
		var f = this.view.getOption('filterObject');
		var fo = this.getFiltersData();
		if (fo && fo.triggerMethod)
			fo.triggerMethod('data:filtered:first', this.getFetchResult());
		if (f && f.triggerMethod)
			f.triggerMethod('data:filtered:first', this.getFetchResult());
	}
});


