const splitter = /(^|:)(\w)/gi;

// take the event section ("section1:section2:section3")
// and turn it in to uppercase name onSection1Section2Section3
function getEventName(match, prefix, eventName) {
    return eventName.toUpperCase();
}

const getFixedName = _.memoize(function(name) {
    let val = name.replace(splitter, getEventName);
    return val;
});

export function lowerFirst(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}

export function eventToMethod(eventName, addOn) {
    if (addOn === true)
        addOn = 'on';

    addOn = addOn || '';

    let name = getFixedName(eventName);

    return lowerFirst(addOn + name);
}