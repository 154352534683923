﻿//define('behaviors-dynamicClass', [], function () {});
import { Behavior } from 'vendors';
var b = Behavior.extend({
	modelEvents: {
		'change': 'refreshClass',
		'refresh': 'refreshClass',
	},
	refreshClass: function () {
		var gov = !!this.view.getOptionValue;
		
		var res = [];
		var css1 = _.result(this.view, 'className') || '';
		var css2 = _.result(this.view, 'dynamicClass') || '';
		var css3 = (gov ? this.view.getOptionValue('addElClass') : _.result(this.view, 'addElClass')) || '';

		var css4 = _.result(this.view, 'dynamicClassHash') || {};
		var css4Keys = _(css4).map(function (v, k) { return k; });

		res.addValue(css1);
		res.addValue(css2);
		res.addValue(css3);
		res = res.concat(css4Keys);
		this.$el.attr({
			class: res.join(' ')
		});

	},
	onRefreshCssClass: function () {
		this.refreshClass();
	},
	onBeforeRender: function () {
		this.triggerMethod('refresh:css:class');
	},
});

export default b;