import { history } from 'vendors';
import appCfg from 'app-config';

function normalizeOptions(options = {}, _url) {
    let navigateType = 'router';

    let { trigger, ctrlKey, newTab, isServiceUrl } = options;
		let url = _url;
    if (ctrlKey || newTab) {
        navigateType = 'newtab';
    }

		if (_url.startsWith('http')) {
			const { host, protocol } = document.location;
			const left = protocol + '//' + host;
			if (_url.startsWith(protocol + '//' + host)) {
				url = _url.replace(left, '');
			} else {
				navigateType = 'window';
			}
		} else if (!_url.startsWith('/') && isServiceUrl) {
			const svcIdString = appCfg.getSvcIdString();
			url = `/s/${svcIdString}/${_url}`;
		}

    return { navigateType, trigger, url };
}

export function smartOpen(_url, options) {
    options = normalizeOptions(options, _url);
    let { navigateType, trigger = true, url } = options;
		let win;
    switch(navigateType) {
				case 'window':
					window.open(url);
					return;
        case 'router':
						history.navigate(url, { trigger });
            return;
        case 'newtab':
            win = window.open(url, '_blank');
						win.focus();
						return;
        case 'modal':
            throw new Error('modal open not implemented');
    }
}