import { CollectionView, View } from 'core';
import { _ } from 'vendors';
import { createTagsCollection } from './TagsCollection';

import './tags-list.less';

export const TagItem = View.extend({
	className: 'tag-item',
	template: _.template('<span>#<%= id %></span><button>&times;</button>'),
	events: {
		'click button'(e) {
			this.trigger('remove:tag', this.model);
		}
	}
});

const NoTags = View.extend({
	className: 'no-tags',
	template: _.template('<span>тэгов нет</span>')
})

export const TagsList = CollectionView.extend({
	className: 'tags-list-view',
	emptyView: NoTags,
	childView: TagItem,
	childViewEvents: {
		'remove:tag'(tagModel) {
			tagModel.destroy({ wait: true });
		}
	}
});


export function showTagsList(view, options) {
	const tags = view.model.get('tags');
	if (!view.tagsCollection) {
		view.tagsCollection = createTagsCollection(tags, options);
	}
	const tagsView = new TagsList({ collection: view.tagsCollection });
	view.showChildView('tags', tagsView);
}