﻿//define('bbe-loud', ['bus-core', 'notifier', 'bus-modules'], function(Bus, notifier) {});

import Bus from './core'; //'bus-core'
import notifier from '../_helpers/notifier'; //'notifier'
import { MnObject } from 'vendors';
//'bus-modules'

var loudchannel = 'loudsync';
Bus.loud = Bus.channel(loudchannel);

var Loud = MnObject.extend({
    channelName: loudchannel,
    initialize: function() {
        this.listenTo(Bus.modules, 'before:module:run', this.onModuleBeforeStart);
        this.listenTo(Bus.modules, 'module:run', this.onModuleStart);
        this.listenTo(Bus.modules, 'module:run:fail', this.onModuleFail);
    },
    radioEvents: {
        //'all':'onAll',
        'request:starts': 'onRequestStarts',
        'request:done': 'onRequestDone',
        'request:fail': 'onRequestFail',
        'request:always': 'onRequestAlways',
//         'module:before:start': 'onModuleBeforeStart',
        //'module:start': 'onModuleStart',
        //'module:run:fail': 'onModuleFail'
    },
    //onAll: function() {
    //    console.log('loud sync', arguments);
    //},
    onRequestStarts: function(requestContext) {
        var notify = notifier.createFromLoud(requestContext);
        requestContext.notify = notify;
    },
    onRequestDone: function(requestContext) {
        if (requestContext.notify)
            requestContext.notify.done();
    },
    onRequestFail: function(requestContext) {
        if (requestContext.notify)
            requestContext.notify.fail({ xhr: requestContext.xhr });

    },
    onRequestAlways: function(requestContext) {
        if (requestContext.notify)
            requestContext.notify.always();
    },
    onModuleBeforeStart: function(module) {

        module._notify = notifier.createFromModule(module);
    },
    onModuleStart: function(module) {
        if (module._notify)
            module._notify.always();
    },
    onModuleFail: function (module) {
        if (module._notify)
            module._notify.fail({ xhr: module.xhr });
        
    },
});

//var loudMediator = new Loud();
Bus.loudMediator = new Loud();
export default Bus.loud;
