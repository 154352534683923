﻿//define('ui-layouts-dataroleActions', ['_libs/identity', 'ui-controls-dataroleActionItem'], function (identity, ActionItem) {});


import boardListBlockTemplate from './boardListBlock.html';
import ActionItem from 'ui/controls/dataroleActionItem';

import { BbeCollection } from 'core/bbe';
import { MneView, MneNextCollectionView } from 'core/mne';

var Layout = MneView.extend({
	getModelActions: function () {
		var model = this.model;
		//var col = this.getOption('collection');
		var _actions = this.getOption('actions');
		var actions = typeof _actions === 'function' ? _actions()
			: typeof model.getActions === 'function' ? model.getActions({ filter: this.getOption('actionsFilter') })
				: _actions || [];
		return actions;
	},
	resetCollection: function () {
		var actions = this.getModelActions();
		this.collection.reset(actions);
	},
	modelEvents: {
		'change': function () {
			this.resetCollection();
		}
	},
	initialize: function () {
		this.resetCollection();
	}
});


var BoardListBlock = Layout.extend({
	className: 'block',
	//template: '#tmpl-ui-layouts-dataroleActions-boardListBlock',
	template: boardListBlockTemplate,
	regions: {
		'items': { el: 'ul', replaceElement: true }
	},
	onRender: function () {
		var model = this.getOption("model");
		var view = new MneNextCollectionView({
			tagName: 'ul',
			childView: ActionItem.BoardListItem,
			collection: this.getOption('collection'),
			childViewOptions: function () {
				return {
					parent: model,
				};
			}
		});
		this.showChildView('items', view);
		//this.listenTo(view, 'all', console.log);
	},
});

// var BoardList = Layout.extend({
// });

var ns = {};
ns.create = function(options) {
	var type = options.layoutType || 'board-list';
	var View;
	switch (type) {
		case 'board-list-block':
			View = BoardListBlock; break;
	}
	if (typeof View === 'undefined') return;

	options.collection = new BbeCollection();
	var view = new View(options);

	
	return view;
}

export default ns;
