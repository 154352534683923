module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="small bg-info">предложения</header>\n<section>\n	<button class="btn-xs btn btn-info pull-right download-pdf" style="margin-left: 10px;" title="скачать PDF файл">\n		<span>pdf</span>\n		<i class="badge selected-items-badge">0</i>\n	</button>	\n	<button class="btn-xs btn btn-info pull-right send-by-email" title="отправить email с предложениями">\n		<span>email</span>\n		<i class="badge selected-items-badge">0</i>\n	</button>\n	<div class="dropdown" style="display:inline-block">\n		<button data-toggle="dropdown" class="btn-xs"><span class="dropdown-toggle-label offers-stage-label">'+
((__t=( _enum('dealStages', _m.stageId)))==null?'':__t)+
'</span> <span class="caret"></span></button>\n		<ul class="dropdown-menu dropdown-menu-inputed">\n			'+
((__t=( _enumDropdownRadiosHtml('dealStages', _dealStage, 'selectedStage') ))==null?'':__t)+
'\n		</ul>\n	</div>\n</section>\n<ul class="offers"></ul>';
}
return __p;
};
