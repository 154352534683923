import { HamburgerView } from "coms/ui/HamburgerView";
import { View } from 'core';
import { ObjectsFilter } from './ObjectsFilter';
import { ObjectsDefaultActions } from './ObjectsDefaultActions';


export const ObjectsHeader = HamburgerView.extend({
	tagName: 'header',
	childrenViews: [
		ObjectsFilter,
		ObjectsDefaultActions,
	],
	childViewOptions() {
		return {
			selector: this.getOption('selector'),
			filterModel: this.getOption('filterModel')
		}
	},
});