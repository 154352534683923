﻿//define('ui-layouts-tabs', ['behaviors-dynamicClass'], function(DynamicClass) {})

import DynamicClass from 'behaviors/dynamicClass';

import { Collection as BbeCollection } from 'core/bbe';

import { MneView, MneNextCollectionView } from 'core/mne';

var TabView = MneView.extend({
    template: _.template('<%- label %>'),
    behaviors: [DynamicClass],
    dynamicClass: function() {
        var base = 'tab-item';
        if (this.model.get('active'))
            base += ' active';
        return base;
    },
    events: { 'click': 'click' },
    click: function() {
        this.triggerMethod('click', this.model)
    },
    modelEvents: {'change':'render'},
});

var View = MneNextCollectionView.extend({
    className: 'tabs-list',
    initialize: function(options) {

        var _active = this.getOption('active') || [];
        if (typeof _active === 'string')
            _active = [_active];


        var allOn = this.getOption('allActive') === true;

        var multiple = this.getOption('multiple');

        var _this = this;
        var tabs = this.collection = new BbeCollection();
        console.log('-=>', tabs);
        //var rawtabs = 
        _(options.tabs).map(function(label, name) {
            var active = allOn ? true
                : _active.indexOf(name) > -1 ? true
                    : false;

            var tab = tabs.add({ name: name, label: label, active: active });

            if (!multiple && active)
                _this.current = tab;

        });

    },
    childView: TabView,
    childViewEvents: { 'click':'onTabClick' },
    onTabClick: function(model) {
        model.set('active', !model.get('active'));
    },
    collectionEvents: { 'change:active': 'onActiveChange' },
    onActiveChange: function (model) {
        //console.log('>>', model);
        var multiple = this.getOption('multiple');
        if (!multiple && model.get('active')) {
            if (this.current)
                this.current.set('active', false);
            this.current = model;
        }
        var postfix = model.get('active') === true ? 'on' : 'off';
        this.triggerMethod('tab:' + postfix, model.get('name'));
        this.triggerMethod('tab', postfix, model.get('name'));
    },
    getActive: function() {

        var multiple = this.getOption('multiple');

        if (!multiple)
            return this.current;
        else
            return this.collection.where({ active: true });
    },
});


export default View;