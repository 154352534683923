import { _, BbModel } from "vendors";
import { getValueByPath } from 'utils';

function _def(value, ifNull) {
    return value != null ? value : ifNull;
}

function compareRanges(ffrom, fto, vfrom, vto) {
    if (ffrom == null && fto == null) return true;
    if (vfrom == null && vto == null) return false;

    ffrom = _def(ffrom, -Infinity);
    vfrom = _def(vfrom, -Infinity);
    fto = _def(fto, Infinity);
    vto = _def(vto, Infinity);

    return (fto >= vfrom) && (ffrom <= vto);

}

function compareRange(ffrom, fto, value)
{
    if (ffrom == null && fto == null) return true;
    if (value == null) return false;
    return compareRanges(ffrom, fto, value, value);
}


export default BbModel.extend({
    initialize() {
        this.modelsSetsCounts = {};
        let keys = Object.keys(this.objectFilters);
        let changeHash = _.reduce(keys, (memo, key) => {
            memo['change:' + key] = this._filterChanged;
            return memo;
        }, {});
        this.on(changeHash);
    },
    _filterChanged() {
        this.set('skip', 0, { silent: true });
        this.trigger('filter:changed');
    },
    modelsSetFilters: {
        all: [m => true, 'все площади'],
        brokers: [m => m.isAvailable('services'), 'доступные брокерам'],
        advert: [m => m.isAvailable('public'), 'доступные в рекламе'],
        //onlyAdvert: [m => m.isAvailable('public') && !m.isAvailable('services'), 'доступные только в рекламе'],
        hidden: [m => !m.isAvailable() && !m.isArchived(), 'недоступные'],
        archived: [m => m.isArchived(), 'в архиве'],
    },
    objectFilters:{
        ownerId: {            
            getValue: obj => obj.get('owner_Id') || getValueByPath(obj, 'owner.id')
        },
        realtyId: {
            getValue: obj => obj.get('realty_Id') || getValueByPath(obj, 'realty.id')
        },
        square: {
            modelType: 'ranged',
            valueModelType: 'single', // 'ranged' для пересечения двух интервалов
            getValue: obj => obj.getSquare(),
        },
        floor: {
            //valueType: 'number',
            getValue: obj => (obj.get('info') || {}).floor
        },
        roomNumber: {
            getValue: obj => (obj.get('info') || {}).roomNumber,
            compareValues: (fval, oval, opts) => oval && oval.toLowerCase().indexOf(fval.toLowerCase()) > -1
        },
        specialSet: {
            compare(filterValue, model, opts) {
                if (filterValue == null || !filterValue.length)
                    return true;
                return _.every(filterValue, entriesSetId => {
                    switch(entriesSetId) {
                        case 'limitedVisibility':
                            return model.hasLimitedVisibility();
                        case 'noOffers': 
                            return model.hasNoOffers();
                        case 'noActiveOffers':
                            return model.notInMarket();
                        case 'onModeration':
                            return model.isOnModeration();
                        case 'noFloor':
                            return (model.get('info') || {}).floor == null;
                        case 'noNumber':
                            return (model.get('info') || {}).roomNumber == null;
                        default: return true;
                    }
                });
            }
        }
    },
    filterSet(m) {
        let modelsSet = this.get('modelsSet') || 'all';
        let setFilterContext = this.modelsSetFilters[modelsSet];
        let setFilter = setFilterContext && setFilterContext[0];
        if (setFilter && setFilter(m) === false) {
            return false;
        }
        return true;
    },
    filter(m) {
        if (!this.filterSet(m)) {
            return false;
        }
        return _.every(this.objectFilters, (opts, key) =>  this._filterModelByFilterValue(m, opts, key));

    },
    _filterModelByFilterValue(model, opts, key) {
        let filterValue = this.get(key);

        if (typeof opts.compare === 'function') {
            return opts.compare(filterValue, model, opts);
        }        

        if (filterValue === undefined) return true;

        let objectValue;

        if (opts.modelType == 'ranged') {
            if (opts.valueModelType == 'ranged') {
                objectValue = [
                    opts.getFrom(model),
                    opts.getTo(model),
                ];
            } else {
                objectValue = opts.getValue(model);
            }
        } else {
            objectValue = opts.getValue(model);
        }
        return this._compareValues(filterValue, objectValue, opts);
    },
    _compareValues(fval, oval, opts) {

        if (typeof opts.compareValues === 'function') {
            return opts.compareValues(fval, oval, opts);
        }        

        if (opts.modelType == 'ranged') {
            if (opts.valueModelType == 'ranged') {
                return compareRanges(fval.from, fval.to, oval[0], oval[1]);
            } else {
                return compareRange(fval.from, fval.to, oval);
            }
        }

        return fval == oval;
    },
    updateSetsCounts(col) {
        let filters = this.modelsSetFilters;
        let filtersKeys = Object.keys(filters);
        let hash = filtersKeys.reduce((memo, key) => {
            memo[key] = 0;
            return memo;
        }, {});

        this.modelsSetsCounts = col.reduce((memo, model) => {
            _.each(filtersKeys, key => {
                memo[key] = memo[key] + (filters[key][0](model) ? 1 : 0)
            });
            return memo;
        }, hash);

        this.trigger('models:sets:counts', this.modelsSetsCounts);
    }
});