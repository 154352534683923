import { View } from 'core';
import {_} from 'vendors';

export const BuildStagesView = View.extend({
	className: 'presentation-building-process',
	template: _.template('<div class="progress"><div class="progress-bar progress-bar-info"></div></div><div class="message"></div><div class="error" style="display:none"></div>'),
	ui: {
		progress:'.progress > .progress-bar',
		message: '.message',
		error: '.error'
	},
	modelEvents:{
		'change':'update',
		'error':'showError'
	},
	update() {
		let progress = this.model.get('progress') || 0;
		this.ui.progress.css('width',progress + '%');
		this.ui.message.html(this.model.get('message') || '');
		console.log(this.model.attributes);
	},
	showError(requestModel) {
		const rc = requestModel.requestContext || {};
		
		const el = this.ui.error.get(0);
		el.innerHTML = `<textarea style="width: 100%;min-height:150px">данные разработчику для анализа ошибки:
${JSON.stringify(rc, null, 2)}
</textarea>`;
		el.style.display = 'block';
		console.log('ERROR!!!', requestModel.requestContext);
	},
	events: {
		'click textarea'(event) {
			$(event.target).select();
		}
	}
})