﻿//define('behaviors-submit', ['modals'], function (modals) { });

import modals from '_libs/modals';
import { Behavior } from 'vendors';
var b = Behavior.extend({
	ui: {
		'submit':'.submit'
	},
	events: {
		'click @ui.submit':'onSubmitClick',
	},
	onSubmitClick: function () {
		var hash = this.$el.serializeObject();
		var model = hash;
		var view = this.view;
		if (typeof this.options.Model === 'function')
			model = new this.options.Model(hash);

		this.view.triggerMethod('submit:result', model);


		if (this.options.doSave) {

			this.listenToOnce(model, 'invalid', function () {
				modals.error("Неправильно заполнена форма","К сожалению вы заполнили не все поля или заполнили их не правильно. Исправьте ошибки и нажмите ту кнопку ещё раз");
			});
			model.save(undefined, { wait: true }).then(function () {
				view.triggerMethod('model:saved', model);
			}, function () {
				modals.error("Произошла ошибка");
			});
		}

	},
});
export default b;