﻿//define('svc-staff-emps-create', ['appModule', 'link', '_libs/identity', 'svc-staff-emps-create-layout'], function(Module, link, identity, Layout) {});

import Module from 'appModule';
import link from 'link';
import Layout from './layout';


var module = Module.content({
	name: 'staff-employees-create',
	urlKey: 'staffEmployeesCreate',
	label:'Добавить виртуального сотрудника',
	routeData: function() {
		return link.route('staffEmployeesCreate');
	},
	onRun: function() {
		//var module = this;
		this.createAndShow(Layout);
	},
	rightsMask: {
		staff: 'createVirtualEmployee'
	}
});


export default module;
