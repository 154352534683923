import { View, CollectionView } from 'core';
import { HamburgerView } from "coms/ui/HamburgerView";
import { UiAtomView } from "coms/ui/UiAtomView";
import { UiMoleculeView } from "coms/ui/UiMoleculeView";

const ContactItem = UiMoleculeView.extend({
	getContactName() {
		const { contactName, person } = this.model.attributes;
		if(contactName) { return contactName; }
		if (!person) { return '[без названия]' }
		return person.name.full;
	},
	content:[
		v => ({
			class: UiAtomView,
			thisClassName: 'contact-name',
			text: v.getContactName(),
			topText: 'название/имя'
		})
	],	
});


export const OwnerRealtyContacts = HamburgerView.extend({
	className: 'owner-realty-contacts-in-modal',
	passDownModels: true,
	getChildren() {
		return [
			{
				class: CollectionView,
				childView: ContactItem,
				collection: this.collection
			}
		]
	}
})
