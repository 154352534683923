import { Model } from 'core';
import { propertySchemaApi } from 'apis/schema';
import { unFlatObject, flatObject } from 'utils';
import { modelSchemaApi } from 'apis/schema';
import refs from 'references';

const  valuable = (arg) => arg != null && arg !== '';
const hasValuableProperty = (obj = {}) => Object.keys(obj).some(key => valuable(obj[key]));
export const FilterModel = Model.extend({

	constructor: function() {
		Model.apply(this, arguments);
		this._processChanges();
		this.on('change', this._processChanges);
	},

	_processChanges() {
		const uo = unFlatObject(this.attributes);
		this.offer = flatObject(uo.offer);
		this.object = flatObject(uo.object);
		
		this.hasObjectPredicate = hasValuableProperty(this.object); 
		this.hasOfferPredicate = hasValuableProperty(this.offer); 
		this.hasTextPredicate = valuable(this.attributes.searchText);


		//console.warn('new filter', this.object, this.offer);
		this.trigger('after:change');
	},

	getState() {
		const { hasObjectPredicate, hasTextPredicate, hasOfferPredicate } = this;
		return { hasObjectPredicate, hasTextPredicate, hasOfferPredicate };
	},

	isEmpty() {
		const { hasObjectPredicate, hasTextPredicate, hasOfferPredicate } = this;
		return !hasObjectPredicate && !hasTextPredicate && !hasOfferPredicate;
	},

	setSearchText(text) {
		if (text) {
			text = text.trim();
		}
		const textNum = text != null ? text.replace(',', '.') : undefined;
		let num;
		if (textNum != null) {
			num = parseFloat(textNum, 10);
			if (isNaN(num)) {
				num = undefined;
			}
		}
		this.set({
			searchTextNumber: num,
			searchText: text != null && text !== '' ? text.toLowerCase() : undefined,
		});
	},

	filterObject(obj, index) {
		const { hasObjectPredicate, hasTextPredicate, hasOfferPredicate } = this;
		// console.log('oops', { hasObjectPredicate, hasTextPredicate, hasOfferPredicate });
		if (!this.hasObjectPredicate && !this.hasTextPredicate && !this.hasOfferPredicate) {
			return true;
		}

		let objectResult;
		let objectTextResult;

		if (this.hasObjectPredicate) {
			objectResult = this._filterModelValues(this.object, obj);
			if (objectResult === false) {
				return false;
			}
		} else {
			objectResult = true;
		}

		if (!this.hasTextPredicate && !this.hasOfferPredicate && objectResult)  {
			return true;
		}

		if (this.hasTextPredicate) {
			objectTextResult = this.filterByText(obj);
		}
		

		

		const offers = obj.getOffers({ models: true });
		if (this.hasOfferPredicate && !offers.length) {
			return false;
		}

		let anyOffersResult = !this.hasOfferPredicate;
		let anyOffersTextResult;
		let anyOffer;

		for (let offIndex = 0; offIndex < offers.length; offIndex++) {
			const off = offers[offIndex];
			let offerResult;
			let offerTextResult;

			if (this.hasOfferPredicate) {
				offerResult = this._filterModelValues(this.offer, off);
				if (offerResult === false) {
					continue;
				} else if (offerResult === true) {
					anyOffersResult = true;
					if (!this.hasTextPredicate) {
						break;
					}
				}
			}

			if (this.hasTextPredicate) {
				offerTextResult = this.filterByText(off);
				if (offerTextResult) {
					anyOffersTextResult = true;
				}
			} 

		}

		if (!anyOffersResult) {
			return false;
		}

		if (this.hasTextPredicate) {
			return objectTextResult || anyOffersTextResult;
		}

		// anyOffer = anyOffersResult || anyOffersTextResult;

		//console.log(obj.getByPath('info.floor'), objectResult, objectTextResult, anyOffersResult, anyOffersTextResult);

		return true;

	},
	_filterObjectOffers(obj) {

	},
	_filterObject(obj) {
		return this._filterModelValues(this.object, obj);
		// let result = this._filterModelValues(this.object, obj);
		// if (result === false) {
		// 	return result;
		// }
		// const textResult = this.filterByText(obj, result);
		// return textResult;
	},

	filterOffer(off, index) {
		const { hasTextPredicate, hasOfferPredicate } = this;
		if (hasOfferPredicate) {
			const res = this._filterModelValues(this.offer, off);
			if (res === false) {
				return false;
			}
		}
		return true;
	},

	_filterOffer(off, index, objectResult) {
		let result = this._filterModelValues(this.offer, off);
		if (result === false) {
			return result;
		}
		if (objectResult !== true) {
			result = this.filterByText(off);
		}
		return result;
	},	

	_filterModelValues(filterValues, model) {
		
		if (!filterValues) { return; }
		const keys = Object.keys(filterValues);
		if (!keys.length) { return; }

		const modelSchema = modelSchemaApi.getModelSchema(model);
		
		let result;
		for (let key of keys) {

			const filterValue = filterValues[key];
			if (filterValue === undefined) { continue; }
			const modelValueSchema = propertySchemaApi.getSchema(key, modelSchema);
			const modelValue = propertySchemaApi.getValue(key, model);
			result = this._filterModelValue(filterValue, {}, modelValue, modelValueSchema);
			if (result === false) {
				break;
			}
		}
		return result !== false;
	},

	_filterModelValue(filterValue, filterValueSchema, modelValue, modelValueSchema = {}) {
		// console.error(modelValueSchema.property);
		// if (modelValueSchema.property === 'neededActualize') {
		// 	console.warn('chpok');
		// }
		if (filterValue === modelValue) {
			return true;
		}
		const { valueType : modelValueType, valueSubType : modelValueSubType, multiple : modelValueMultiple } = modelValueSchema;
		if (modelValueType === 'boolean' || modelValueType == null) {			
			return filterValue === modelValue;
		}
		if (typeof filterValue === 'string' && typeof modelValue === 'string') {
			if (modelValueType === 'enum') {
				return refs.enumHasFlag(modelValue, filterValue);
			} else {
				return modelValue.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
			}
		}

	},

	filterByText(obj, defaultResult) {
		const { searchText, searchTextNumber } = this.attributes;
		if (searchText == null) {
			return defaultResult;
		}
		const schema = obj.getSchema ? obj.getSchema() : undefined;
		if (schema) {
			const schemaKeys = Object.keys(schema);
			for (let key of schemaKeys) {

				const objValue = propertySchemaApi.getValue(key, obj);  
				const { valueType } = schema[key];
					
				if (valueType === 'number' && searchTextNumber != null) {
					if (objValue === searchTextNumber || parseInt(objValue, 10) === parseInt(searchTextNumber, 10)) {
						return true;
					}
				}

				if ((valueType === 'string' || valueType === 'enum') && objValue != null) {

					let objTextValue = (obj.display(key) || '').toLowerCase();
					
					if (objTextValue.indexOf(searchText) > -1) {
						return true;
					}

				}

			}
		}
		return false;
	}

});