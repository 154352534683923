
import { propertySchemaApi } from "apis/schema"

export const contactModelSchema = {
	'isPerson': {
		valueType: 'boolean',
		getValue(m, s) {
			const val = propertySchemaApi.getValue('contactName', m, s);
			return !val;
		}
	},
	'name': {
		displayValue(v, m, s) {
			const name = propertySchemaApi.getValue('contactName', m, s);
			if (name) {
				return name;
			}
			const person = propertySchemaApi.displayValue('personName', m, s);
			return person;
		}
	},
	'personName': {
		getValue(m, s) {
			const name = propertySchemaApi.getValue('person.name', m, s);
			if (!name) {
				return '&mdash;';
			}
			const { last, middle, first } = name;
			const textName = [last, first, middle].filter(f => !!f).join(' ');
			return textName;
		}
	},
	'ownerFuncs': {
		valueType: 'enum',
		valueSubType: 'realtyOwnerContactFunctions'
	}
}

