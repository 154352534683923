﻿//define('notifier',['behaviors-dynamicClass', 'websockets-config', 'bus-core', 'link', 'bus-app']
//,function(DynamicClassBehavior, wsCfg, Bus, link, busApp){});

import DynamicClassBehavior from 'behaviors/dynamicClass'; // 'behaviors-dynamicClass'
import wsCfg from '../../websocket/config'; //'websockets-config'
import Bus from 'bus/core'; // 'bus-core', should be aliased
import link from 'link'; //'link'
import { bbeViewComparator } from 'utils/comparators';

import { NextCollectionView, MnObject } from 'vendors';
import { BbeModel, BbeCollection } from 'core/bbe';
import { MneView } from 'core/mne';

// 'bus-app' was simplified to Bus.app

import './notifier.less';



var Note = BbeModel.extend({
    defaults: function() {
        return {
            id: _.uniqueId('notify'),
            timestamp: Date.now(),
            type: 'system',
            message: 'подождите пожалуйста...',
            failMessage: "произошла ошибка",
            actorId: undefined,
            header: undefined,
            url: undefined,
        }
    },
    isNew: function() {
        return true;
    },
    done: function() {
        this.set('type', 'success');
    },
    fail: function(opts) {
        var add=''
        if (opts.xhr) {
            add = '<br/>' + opts.xhr.status + ": " + opts.xhr.statusText;
            console.log('FAIL XHR', opts.xhr);
        }
        this.set({
            type: 'fail',
            message: this.get('failMessage') + add,
        });
    },
    always: function() {
        var m = this;
        var delay = 1000;
        if (m.get('type') == 'fail') delay = 5000;

        setTimeout(function() {
            m.destroy()
        }, delay);
    },
});

var Notifies = BbeCollection.extend({
    model:Note,
});

var notifies = new Notifies();

var NotifyItem = MneView.extend({
    className: 'notifier-item',
    template: _.template('<%if(header){%><div class="note-header"><%=header%></div><%}%><div class="message"><%=message%></div><%if(actorId){%><div class="actor"><%=_empn(actorId)%></div><%}%><div class="close">&times;</div>'),
    dynamicClass: function() {
        var res = [];
        var m = this.model;
        if (!m) return '';

        if (m.get('type'))
            res.push('notify-' + m.get('type'));

        if (m.get('url'))
            res.push('clickable');

        return res.join(' ');
    },
    behaviors: [DynamicClassBehavior],
    modelEvents: {
        'change':'render',
    },
    onRender: function() {
        //console.log('notify render', this.model.get('id'));
    },
    events: {
        'click .close': 'onDestroyClick',
        'click': 'onClick',
    },
    onClick: function () {
        //console.log(this.model.get('cntx'));
        //return;
        if(this.model.get('type') == 'system')
            this.model.destroy();
        else if (this.model.get('url')) {
            var url = this.model.get('url');
            this.model.destroy();
            Bus.navigate(url);
        }

    },
    onDestroyClick: function () {
        this.model.destroy();
    }
});

var NotifiesView = NextCollectionView.extend({
    className:'notifies-container',
    childView: NotifyItem,
    collection: notifies,
    viewComparator: function(a,b) {
        return bbeViewComparator(b, a, function() { return this.model.get('timestamp'); });
    }
});

var NotifyLayout = MneView.extend({
    el: '#notifier',
    template:_.template('<div></div>'),
    initialize: function() {
        this.render();
    },
    regions: {
        'items':'> div'
    },
    onRender: function() {
        var view = new NotifiesView();
        this.showChildView('items', view);
    }
});

NotifyLayout.create = function() {
    if ($('#notifier').length == 0)
        $('body').append($('<div id="notifier"/></div>'));
    return new NotifyLayout();
}



var Notifier = MnObject.extend({
    // initialize: function () {
    // 	var obj = this;
    // },
    createFromLoud: function(loudContext) {
        var opts = loudContext.loud || {};
        var cntx = loudContext.context || {};
        var message = opts.message || ("Подождите, загружаются " + (cntx._typeLabel || 'данные') + "...");
        var failMessage = opts.failMessage || "произошла ошибка";
        var modelHash = { message: message, failMessage: failMessage };
        modelHash.cntx = cntx;
        var type = opts.notifyType || 'system';
        //console.log('??', arguments);
        return this[type](modelHash);
    },
    createFromModule: function(module, _message, _failMessage) {
        var message = _message || module.loudMessage || ("Подождите, раздел &laquo;" + module.name + "&raquo; загружается...");
        var failMessage = _failMessage || module.loudFailMessage || ("произошла ошибка при загрузке модуля " + module.id);
        var modelHash = { message: message, failMessage: failMessage };
        modelHash.cntx = module;
        var type = 'system';
        return this[type](modelHash);
    },
    add: function(hash) {
        return notifies.add(hash);
    },
    system: function(modelHash) {
        return this.add(modelHash);
        //var notify = notifies.add(modelHash);
        //return notify;
    },
    onSocketData: function (type, model) {
        if (type == 'comment')
            this.notifyAboutComment(model);
    },
    notifyAboutComment: function (model) {
        var header = 'У вас новый комментарий!';
        var actorId = model.authorId;
        var message = model.text;
        var url = '';
        if (model.taskId) {
            var cp = Bus.app.request('current:page') || {};
            if (cp.name == 'task' && cp.id == model.taskId) return;
            header = "Комментарий к задаче №" + model.taskId;
            url = link.url('taskCard:' + model.taskId);
        }
        this.add({ type: 'new-comment', header: header, message: message, actorId: actorId, url: url });
    },
    addSockectHandlers: function() {
        var handlers = [].slice.call(arguments);
        var radio = Bus.channel(wsCfg.channel);
        var _this = this;
        _(handlers).each(function(handler) {
            _this.listenTo(radio, handler.event, _.bind(handler.handler, handler, _this));
        });

    },
});

var notifier = new Notifier();

NotifyLayout.create();




export default notifier;

