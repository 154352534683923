﻿//define('ui-models-filters-normalizer', [], function () {});


function normalizePropertyValue(prop) {
	if (prop instanceof Array)
		return prop.join(', ')
	else
		return prop;
}

function getUrlHashfromValue(value, index, key) {

	var hash = {};

	_(value).each(function (propValue, propName) {
		var propKey = key + (index ? '[' + index + ']' : '') + '.' + propName;
		var propVal = normalizePropertyValue(propValue);
		hash[propKey] = propVal;
	});


	return hash;
}

function getUrlHashfromFilters(filters) {

	if (!filters || _.size(filters) == 0) return undefined;
	var hash = {};

	_(filters).each(function (keyValue, key) {
		var whoK = _.who(keyValue);

		if (whoK.primitive)
			hash[key] = keyValue;
		else if (whoK.array) {
			_(keyValue).each(function (itemValue, index) {
				_.extend(hash, getUrlHashfromValue(itemValue, index, key));
			});
		}

	});
	return hash;

}

export default {
	getHash: getUrlHashfromFilters
};
