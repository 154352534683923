import cfg from '../config';
import refs from 'references';

import SchemaEdit from 'ui/controls/schemaEdit';
import { getChanges } from '_helpers/getChanges';
import { View, NextCollectionView } from 'base/views';
import { View as CoreView, CollectionView } from 'core';

import { flexModal, flexConfirm } from '_libs/flex-modals';
import { getValueByPath } from 'utils';

import { BbModel, BbCollection } from 'vendors';

import { BbeModel } from 'core/bbe';
import { BoxView } from '../../../../coms/ui/Box/BoxView';
import { HamburgerView } from '../../../../coms/ui/HamburgerView';
import { createPriceOptions, getFullPriceMeterDisplay, getFullPriceSquareDisplay } from '../../../../utils/price';

function getOfferProperties(offer) {

	let isRent = offer.operation == 'rent';

	let properties = {
		status: {
			caption: 'статус публикации',
			emptyText: 'не указан',
			modelType: 'single',
			valueType: 'enum',
			sourceValues: refs.enum('realtyOfferStatuses'),
			required: true,                    
		},
		visibleFor: {
			caption: 'видимость',
			emptyText: 'не указана',
			multiple: true,
			modelType: 'single',
			valueType: 'enum',
			sourceValues: refs.enum('entityVisibilities'),
			//required: true,                    
		},
		'forCustomer.taxType': {
			caption: 'налогообложение',
			emptyText: 'не указано',
			multiple: true,
			modelType: 'single',
			valueType: 'enum',
			sourceValues: refs.enum('taxTypes'),
			required: true,                    
		},
		'forCustomer.boma': {
			caption: 'Boma (Коридорный коэф.)',
			emptyText: 'не указан',
			modelType: 'single',
			valueType: 'number',
			validate: (v) => {
				if (v == null) return false;
				if (typeof v != 'number') {
					v = parseFloat(v, 10);
				}
				return !isNaN(v) && v >= 1;
			}
			//sourceValues: refs.enum('realtyOfferStatuses'),
			//required: true,
		},
		// price: {
		// 	caption: 'стоимость',
		// 	controlType: 'editPrice2',
		// 	modelType: 'single',
		// 	getEditValue: () => ({ priceMeter: offer && getValueByPath(offer, 'forCustomer.priceMeter') || undefined }),
		// 	emptyText: 'не установлена',
		// 	display(v) {
		// 		if (!v) return '&mdash;';
		// 		let value = _.displayNum(v.priceMeter || 0, 2) + ' р.';
		// 		return value;
		// 		// let type = v.perSquare ? ' за помещение' : ' за метр';
		// 		// let nds = v.ndsInside ? ' (ндс внутри)' : '';
		// 		// return value + type + nds;
		// 	},
		// 	description: 'базовая ставка, без ндс',
		// 	required: true,
		// 	//validate: required,
		// 	//valueType: 'number',
		// },
		'forCustomer.priceMeter': {
			caption: 'стоимость',
			// controlType: 'editPriceMeter',
			// controlType: 'editOfferPriceMeter',
			controlType: 'editOfferPriceBoth',
			modelType: 'single',
			clearOnChange: ['operation', 'square'],
			refreshOnChange: ['forCustomer.boma', 'forCustomer.taxType'],
			editAvailableError: all => {
				if (all.operation != null && all.square > 0) {
					return;
				}
				return 'Укажите операцию, площадь и налогообложение';
			},
			controlOptions(hash1 = {}, hash2 = {}) {

				let { square, operation } = hash2;
				let { taxType, boma, priceMeter } = (hash1.forCustomer || {});
				// const hash = Object.assign({}, hash2, hash1);
				// let { boma, operation, square, price, taxType } = hash;
				const opts = {
					multipleOffers: false,
					models: [
						{
							object: {
								info: {
									squareOffer: square,
								}
							},
							offer: {
								operation,
								forCustomer: {
									boma,
									priceMeter,
									taxType
								}
							}
						}
					]
				}
				console.warn('#', opts);
				return opts;
			},
			display(v, prop) {
				if (v == null) return '';
				if (v === 0) { return v; }
				
				
				const all = prop.getAllValues();
				const { square, operation, forCustomer = {} } = all;
				const { taxType, boma } = forCustomer;

				const priceOptions = createPriceOptions(v, square, operation, taxType, boma);
				
				const meterPrice = getFullPriceMeterDisplay(priceOptions, true);
				const squarePrice = getFullPriceSquareDisplay(priceOptions, true);
				return squarePrice + ' (' + meterPrice + ')';
			},
			validate: v => v != null && v > 0,
			//valueType: 'number',
		},		
		'forCustomer.priceIncludes': {
			caption: 'в стоимость включено',
			emptyText: 'не указано',
			multiple: true,
			modelType: 'single',
			valueType: 'enum',
			sourceValues: refs.enum('priceAdditionals'),
			//required: true,                    
		},
		'forCustomer.priceExcludes': {
			caption: 'в стоимость НЕ включено',
			emptyText: 'не указано',
			multiple: true,
			modelType: 'single',
			valueType: 'enum',
			sourceValues: refs.enum('priceAdditionals'),
			//required: true,                    
		},
		'forCustomer.offerContractType': {
			caption: 'клиентский договор',
			emptyText: 'не указан',
			modelType: 'single',
			valueType: 'enum',
			sourceValues: refs.enum('offerContractTypes'),
			excludeValues: isRent ? ['sell'] : ['directRent', 'subRent']
			//required: true,                    
		},
		'forAgent.income': {
			caption: '% коммиссии агенту',
			emptyText: 'не указан',
			modelType: 'single',
			valueType: 'number'
		},
		'forAgent.contractType': {
			caption: 'агентский договор',
			emptyText: 'не указан',
			modelType: 'single',
			valueType: 'enum',
			sourceValues: refs.enum('contractTypes'),
		},
		'allowedForOrderWhileInactive': {
			caption: 'Разрешить бронирование пока не публикуется',
			valueType: 'boolean',
			sourceValues: { true: 'Разрешить', false: 'Запретить' },
			emptyText: 'не установлено',
			display(v) {
				const src = this.get('sourceValues');
				if (v == null) {
					return 'не установлено';
				}
				return src[v];
			}
		}
	};
	return properties;
}

function getObjectProperties(model)
{
	let properties = {
		status: {
			caption: 'статус публикации',
			emptyText: 'не указан',
			modelType: 'single',
			valueType: 'enum',
			sourceValues: refs.enum('realtyObjectStatuses'),
			required: true,                    
		},
		visibleFor: {
			caption: 'видимость',
			emptyText: 'не указана',
			multiple: true,
			modelType: 'single',
			valueType: 'enum',
			sourceValues: refs.enum('entityVisibilities'),
			//required: true,                    
		},
		'info.floor': {
			caption: 'этаж',
			emptyText: 'не указан',
			modelType: 'single',
			valueType: 'number',
			required: true
		},
		'info.roomNumber': {
			caption: 'номер кабинета(идентификатор площади)',
			emptyText: 'не указан',
			modelType: 'single',
			valueType: 'string',
		},
		'info.squareOffer': {
			caption: 'доступная площадь',
			emptyText: 'не указана',
			modelType: 'single',
			valueType: 'number',
			required: true
		},
		'info.purposes': {
			caption: 'возможные назначения',
			emptyText: 'не указаны',
			modelType: 'single',
			valueType: 'enum',
			multiple: true,
			sourceValues: refs.enum('realtyPurposes'),
			required: true,
		},
		'info.currentPurpose': {
			caption: 'текущее назначение',
			emptyText: 'не указано',
			modelType: 'single',
			valueType: 'enum',
			sourceValues: refs.enum('realtyPurposes'),
			required: true,
		},
		'info.state': {
			caption: 'состояние',
			emptyText: 'не указано',
			modelType: 'single',
			valueType: 'enum',
			sourceValues: refs.enum('realtyObjectStates'),
			required: true,
		},
		'info.spaceLayout': {
			caption: 'планировка',
			emptyText: 'не указана',
			modelType: 'single',
			valueType: 'enum',
			sourceValues: refs.enum('spaceLayouts'),
			required: true,
		},
		'info.roomsCount': {
			caption: 'количество кабинетов',
			emptyText: 'не указано',
			modelType: 'single',
			valueType: 'number',
		},
	}
	return properties;
}

function fixOfferEditData(data, oldValues) {
	let price = data.price;
	delete data.price;
	if (price) {
		let forCustomer = (data.forCustomer || {});
		forCustomer.priceMeter = price.priceMeter;
		data.forCustomer = forCustomer;
	}

	if (data.visibleFor === '') {
		data.visibleFor = 'none';
	}

	if (!oldValues.id)
		return data;

	data = getChanges(oldValues, data);
	return data;
}

function buildOfferName(offer) {
	if (offer) {
		return (offer.id ? offer.id + ' ' : '')
		+ refs.enum('realtyOperations', offer.operation) 
		+ ' ' + refs.enum('realtyMarkets', offer.market);

	} else {
		return 'добавление предложения';
	}
}

function editOfferInModal(realtyObject, offer, beforeApplyAction) {

	if (offer && offer.attributes) {
		offer = offer.attributes
	}


	let isRent = offer.operation == 'rent';
	let square = realtyObject.getSquare();
	let isNds = getValueByPath(offer, 'forCustomer.taxType') == 'nds';
	let boma = getValueByPath(offer, 'forCustomer.boma');
	let properties = getOfferProperties(offer);

	let valuesHash = { 
		operation: offer.operation,
		square,
		isNds,
		isRent,
		boma,
		currentPricePerMeter: getValueByPath(offer, 'forCustomer.priceMeter')
	};

	let offerName = buildOfferName(offer);

	SchemaEdit.inModal(properties, {
		destroyOnDone: true,
		applyText: 'применить изменения',

		valuesModel: offer,
		valuesHash,

		header: realtyObject.getName() + ' &mdash; ' + offerName,
		beforeApply(data) {

			data = fixOfferEditData(data, offer);
			if (data) {
				return beforeApplyAction(data)
			} else {
				console.log("#DATA", 'nochanges');
			}
		},
	});

}


function editMainInModal(model, beforeApplyAction) {

	let oldValues = model.attributes;
	let properties = getObjectProperties();
	SchemaEdit.inModal(properties, {
		destroyOnDone: true,
		applyText: 'применить изменения',

		valuesModel: model,
		//valuesHash,

		header: model.getName(),
		beforeApply(data) {
			if (data.visibleFor === '') {
				data.visibleFor = 'none';
			}
			data = getChanges(oldValues, data);
			if (data) {
				return beforeApplyAction(data)
			} else {
				console.log("#DATA", 'nochanges');
			}
		},
	});
}



const AvailableOfferType = View.extend({
    className:'as-ui-atom clickable',
    template: _.template('<i></i><div><%= label %></div><b></b>'),
    triggers:{
        'click':'click'
    }
});

const NoOfferTypesView = View.extend({
    className:'as-ui-atom',
    template: _.template('<i></i><div>нет доступных вариантов для предложения</div><b></b>'),
});

const SelectAvailableOfferTypesColl = CollectionView.extend({
	initialize() {
		let models = this.getOption('allowedModels');
		this.collection = new BbCollection(models);        
	},
	childView: AvailableOfferType,
	emptyView: NoOfferTypesView,
	childViewEvents:{
		'click'(view) {
			let { operation, market } = view.model.attributes;
			this.trigger('modal:resolve', { operation, market });
		}
	},
});

const SelectAvailableOfferTypes = HamburgerView.extend({
	childViewOptions() {
		return {
			allowedModels: this.getOption('allowedModels')
		}
	},
	childrenViews: [
		{
			class: CoreView,
			tagName: 'h3',
			template: 'выберите рынок и операцию'
		},
		SelectAvailableOfferTypesColl
	],
	content: {
		class: SelectAvailableOfferTypesColl
	},
	childViewTriggers:{
		'modal:resolve':'modal:resolve'
	}
})

const Model = BbeModel.extend({
	//model: Model,
	urlRoot: cfg.objectsApiUrl,
	getName() {
		let { info = {} } = this.attributes;
		let res = [
			_.displayNum(info.squareOffer,1) + 'м<sup>2</sup>'
		];

		if(info.roomNumber) {
			res.unshift('#' + info.roomNumber);
		}

		if (info.floor != null) {
			res.unshift(info.floor + 'эт.');
		} else {
			res.unshift('[без этажа]');
		}
		return res.filter(f => !!f).join(' &ndash; ');
	},

	getSquare() {
		let info = this.get('info');
		if (!info) return 0;
		return info.squareOffer || 0;
	},

	isActual() {
		return this.get('status') === 'actual';
	},
	isSquareOk() {
		let square = this.getSquare();
		//(this.get('info') || {}).squareOffer || 0;
		return square > 0;
	},
	isArchived() {
		return this.get('status') === 'archived';
	},
	isVisible(forWhom) {

		if (arguments.length === 0) {
			return this.isVisible('services') && this.isVisible('public');
		}

		let visibleFor = this.get('visibleFor');


		if (this._visibleFor != visibleFor || ('_visibleForArr' in this === false)) {
			this._visibleFor = visibleFor;
			this._visibleForArr = visibleFor ? visibleFor.split(/\s*,\s*/gmi) : [];
		}

		return this._visibleForArr.indexOf(forWhom) > -1;
		
	},
	isSelfAvailable(forWhom) {
		let self = this.isActual() && this.isSquareOk() && this.isVisible(forWhom);
		return self;
	},
	isAvailable(forWhom) {
		if (forWhom) {
			return this.isSelfAvailable(forWhom) && this.hasActiveOffers(forWhom);
		} else {
			return this.isAvailable('public') || this.isAvailable('services');
		}
	},
	getRawOffers() {
		return this.get('offers') || [];
	},
	hasActiveOffers(forWhom) {
		let offs = this.getRawOffers();
		let checkBoth = forWhom == null;
		if (!offs.length) return false;
		return offs.some(off => {
			if (checkBoth) {
				return this.rawOfferApi.isSelfAvailable(off, 'public') || this.rawOfferApi.isSelfAvailable(off, 'services');
			} else {
				return this.rawOfferApi.isSelfAvailable(off, forWhom);
			}
		});
	},
	hasLimitedVisibility() {
		let vf = this.get('visibleFor');
		return vf == 'public' || vf == 'services' || vf == 'none';
	},
	hasNoActiveOffers() {
		return !this.hasActiveOffers();
	},
	hasNoOffers() {
		return this.getRawOffers().length == 0;
	},
	notInMarket() {
		let offs = this.getRawOffers();
		if (!offs.length) return false;
		return offs.some(off => off.status == 'off');
	},
	isOnModeration() {
		let status = this.get('status');

		if (status == 'notModerated' || status == 'notVerified')
			return true;

		let offs = this.getRawOffers();
		return offs.some(off => off.status == 'notModerated');
	},
	getSelfAvailabilityErrors(forWhom) {
		let errors = [];
		if (!this.isActual()) {
			errors.push('статус площади \'' + refs.enum('realtyObjectStatuses', this.get('status')) + '\'');
		}
		if (!this.isVisible(forWhom)) {
			errors.push('видимость площади \'' + refs.enum('entityVisibilities', this.get('visibleFor')) + '\'');
		}
		if (!this.isSquareOk()) {
			errors.push('не устновлен метраж площади');
		}
		return errors;
	},

	getAvailabilityErrors(forWhom) {
		let errors = [];
        let objectErrors = this.getSelfAvailabilityErrors(forWhom);
        errors.push(...objectErrors);
		if (!this.hasActiveOffers(forWhom)) {
			errors.push('нет доступных предложений');
		}
		return errors;
	},

	_buildAvailabilityTitle(forWhom, notAvailableMessage, availableMessage, errors) {
		if (errors == null) {
			errors = this.getAvailabilityErrors(forWhom);
		}
		let message = errors.length ? notAvailableMessage : availableMessage;
		errors.unshift(message);
		let title = errors.join(', ');
		return title;
	},

	rawOfferApi: {
		isActive(off) {
			return off.status == 'active';
		},
		isPriceOk(off) {
			let price = (off.forCustomer || {}).priceMeter || 0;
			return price > 0;
		},
		isSelfVisible(off, forWhom) {
			if (!forWhom) return false;
			let visibleFor = off.visibleFor || '';
			//console.log(' - check args -', off, forWhom, visibleFor, visibleFor.indexOf(forWhom) > -1);
			return visibleFor.indexOf(forWhom) > -1; 
		},
		isSelfAvailable(off, forWhom) {
			return this.isActive(off) && this.isPriceOk(off) && this.isSelfVisible(off, forWhom);
		},
		getSelfAvailabilityErrors(off, forWhom) {
			let errors = [];
			if (!this.isActive(off)) {
				errors.push('статус предложения \'' + refs.enum('realtyOfferStatuses', off.status) + '\'');
			}
			if (!this.isSelfVisible(off, forWhom)) {
				errors.push('видимость предложения \'' + refs.enum('entityVisibilities', off.visibleFor) + '\'');
			}
			if (!this.isPriceOk(off)) {
				errors.push('не установлена цена');
			}
			return errors;
		}
	},

	editMain() {
		editMainInModal(this, data => this.patchMain(data))
	},

	patchMain(attrs) {
		let url = this.url();
		let model = new BbModel();
		model.url = url;
		return model.save(null, { attrs, method:'PATCH'}).then(data => this.fetch()).then(() => this.trigger('refresh', this));
	},

	editOffer(offer) {
		editOfferInModal(this, offer, (data) => this.patchOffer(offer.id, data))
	},
	_getAvailableOffersForCreate() {
		let markets = _.map(refs.enum('realtyMarkets'), (v,k) => k);
        let operations = _.map(refs.enum('realtyOperations'), (v,k) => k);
        let allowed = {};
        _.each(operations, operation => {
            if (operation === 'none' || !operation) return;
            _.each(markets, market => {
                if (market === 'none' || !market) return;
                allowed[operation + ':' + market] = [operation, market];
            });
        });

        let offs = this.getRawOffers();
        _.each(offs, off => {
            allowed[off.operation + ':' + off.market] = false;
        });

        let allowedModels = _.reduce(allowed, (memo, arr, _id) => {
            if (!arr) return memo;

            let model = { 
                operation: arr[0], 
                market: arr[1], 
                label: refs.enum('realtyOperations', arr[0]) + ' ' + refs.enum('realtyMarkets', arr[1]) 
            }
            memo.push(model);
            return memo;
        }, []);

		return allowedModels;
	},
	addOffer() {

		let allowedModels = this._getAvailableOffersForCreate();

		const modalView = new SelectAvailableOfferTypes({ 
			allowedModels,
		});

		//debugger;
		let modal = flexModal(
				modalView, 
				{ header: 'выберите вариант предложения', rejectHardText: false }
		);
		modal.promise.then((_offer = {}) => {
			//debugger;
			let offer = {
				..._offer,
				status: 'notModerated',
				visibleFor: 'services',
				forCustomer: {
					boma: 1,
				}
			}
			editOfferInModal(this, offer, (data) => this.createOffer(Object.assign({}, _offer, data)));
		});

	},
	patchOffer(id, hash) {
		let url = this.url() + '/offers/' + id;
		let model = new BbModel();
		model.url = url;
		return model.save(null, { attrs: hash, method:'PATCH'}).then(data => this.fetch()).then(() => this.trigger('refresh', this));
	},
	createOffer(hash) {
		let url = this.url() + '/offers';
		let model = new BbModel();
		model.url = url;
		console.log('HASH', hash);
		return model.save(null, { attrs: hash, method:'POST'}).then(data => this.fetch()).then(() => this.trigger('refresh', this));
	}

});

export default Model;