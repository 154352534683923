﻿//define('svc-staff-emps-create-layout', ['behaviors-blockbuttons', 'modals', 'm-employees-createModel'], function (BehaviorBlockButtons, modals, CreateEmployee) {});

import modals from '_libs/modals';
import CreateEmployee from 'm/employees/createModel';
import template from './layout.html';

import { MneView } from 'core/mne';

var Layout = MneView.extend({
	className: 'board-container',
	//template: '#tmpl-service-staff-emps-create-layout',
	template,
	ui: {
		'birthdayField': 'input[name="birthday.date"]',
		'create':'button.create'
	},
	events: {
		'click @ui.create': function () {
			var hash = this.$el.serializeObject();

			var model = new CreateEmployee(hash);
			var view = this;
			//var wait = modals.notify('Подождите пожалуйста, приглашение отправляется и это может занять какое-то время.');
			model.loudSave(hash, { wait: true }).then(function () {
				//wait.destroy();
				modals.notify('Сотрудник добавлен');
				view.render();
			}, function (xhr) {
				//wait.destroy();
				var addMessage = xhr.responseJSON && xhr.responseJSON.exceptionMessage;
				modals.error('Не получилсоь добавить сотрудника:<br/>'+addMessage);
			});
		}
	},
	onRender: function () {
		this.ui.birthdayField.datepicker({ yearRange: "-100:+0"});
	}
});

export default Layout;