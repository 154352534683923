module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="contact-value-main">\n<span class="contact-value-type">'+
((__t=( _enum('contactValueTypes', _m.type)))==null?'':__t)+
'</span>\n<span class="contact-value">'+
((__t=( contactHtml ))==null?'':__t)+
'</span>\n<span class="contact-value-remove pull-right"><button class="btn btn-xs btn-danger delete-value">&times;</button></span>\n</div>\n<div class="radio-btn-group inline purposes">\n	'+
((__t=( _enumRadiosHtml('contactValuePurposes',_m.purposes, _cid + ':purposes')))==null?'':__t)+
'\n</div>\n';
}
return __p;
};
