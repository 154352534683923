﻿//define('ui-behaviors-editValue', [], function () {});
import { Behavior } from 'vendors';

var b = Behavior.extend({
	ui: {
		'ok': '.ok',
		'apply': '.apply',
		'cancel': '.cancel',
		'reset':'.reset',
	},
	triggers: {
		'click @ui.ok': 'beh:ok',
		'click @ui.apply': 'beh:apply',
		'click @ui.cancel': 'beh:cancel',
		'click @ui.reset': 'beh:reset',
	},
	onBehCancel: function() {
		var type = 'cancel';
		this.view.triggerMethod(type);
		this.editDone(type);
	},
	onBehReset: function () {
		var type = 'reset';
		this.view.triggerMethod(type);
		this.editDone(type);
	},
	onBehOk: function () {
		var type = 'ok';
		this.view.triggerMethod(type);
		this.editDone(type);
	},
	onBehApply: function () {
		var type = 'apply';
		this.view.triggerMethod(type);
		this.editDone(type);
	},
	editDone: function (type) {
		this.view.triggerMethod('before:edit:done',type);
	},
});

export default b;