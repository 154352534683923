import { editInModal } from "coms/ui/EditView";
import { backendApi } from "../../apis/backend";
import { flatObject, getValueByPath } from "../../utils";
import { EditContactValueLayout } from './views/edit-contact-value';
import { urls } from 'coms/urls';
import { modalsApi } from "../../apis/modals";
import { addArray, normArr } from "./edit-common";




function invalidateContactValue(flatValues, isNew) {

	const errors = [];
	const add = v => addArray(errors, v);

	const valueType = flatValues['value.type'];
	const valueValue = flatValues['value.value'];

	if (isNew) {
		
		if (!valueType) {
			add('укажите тип контакта');
		}
		if (valueValue == null) {
			add('добавьте значение контакта');
		}
	} else {
		if ('value.type' in flatValues && !valueType) {
			add('укажите тип контакта');
		}
		if ('value.value' in flatValues && (valueValue == null || valueValue === '')) {
			add('добавьте значение контакта');
		}

	}

	return normArr(errors);
}

export function editContactValueInModal(model, options = {}) {
	const { isNewContact, owner, contact } = options;

	const isNew = !model;
	const header = isNew ? 'добавление контактных данных' : 'изменение контактных данных';
	const contentArg = {
		header,
		buttons: false,
		content: {
			class: EditContactValueLayout,
			model,
			isNew,
			isNewContact
		},
		childViewTriggers: {
			'before:edit:done':'before:edit:done'
		},
		async beforeEditDone(changes) {
			const flatChanges = flatObject(changes);
			const errors = invalidateContactValue(flatChanges, isNew);
			if (errors) {
				return {
					ok: false,
					error: errors
				}
			}

			const oid = owner.id;
			const cid = contact?.id;



			if (isNew) {
				if (!cid) {
					return {
						ok: true,
						value: changes
					}
				}
				const url = urls.api(`v4/realties/owners/${oid}/contacts/${cid}/values`);
				try {
					const sync = () => {};
					const result = await backendApi.post(changes, url, { relativeUrl: false });	
					return {
						ok: true,
						value: result
					}
				} catch (exc) {
					return {
						ok: false,
						error: ['не удалось добавить контакт']
					}
				}
			}


			const vid = model.id;
			const url = urls.api(`v4/realties/owners/${oid}/contacts/${cid}/values/${vid}`);
			try {
				const sync = () => {};
				await backendApi.patch(changes, url, { sync, relativeUrl: false });
				return {
					ok: true,
					value: changes
				}
			} catch (exc) {
				return {
					ok: false,
					error: ['не удалось сохранить изменения']
				}
			}

		}
	}

	const result = editInModal(contentArg, {
		cssClasses: {
			dialog: "edit-contact-value-modal-dialogue flex-modal-dialog",
			box: 'block shadowed in-middle',
		}
	});
	console.warn('editContactValueInModal', result);
	return result;

}



export async function removeContactValue(model, options = {}) {
	const { collection, owner, contact } = options;
	const oid = owner.id;
	const cid = contact.id;
	const vid = model.id;
	const url = urls.api(`v4/realties/owners/${oid}/contacts/${cid}/values/${vid}`);
	try {

		const sync = () => {
			collection.remove(model);
		};
		await backendApi.delete(url, { sync, relativeUrl: false });

	} catch (exc) {
		modalsApi.oldError('не удалось удалить');
	}
}