import { _ } from 'vendors';

export function updateElClass() {

    var css = _.chain([
        _.result(this, 'dynamicClass'), 
        _.result(this, 'className')
    ]).flatten()
    .uniq()
    .value()
    .onlyValues()
    .join(' ') || '';

    this.$el.attr({
        class: css
    });
};
