//define('svc/actualization/owners', ['appModule', 'link', '_libs/identity', 'svc/actualization/owners/layout'], function(Module, link, identity, Layout) {});

import Module from 'appModule';
import link from 'link';
//import identity from '';
import Layout from './layout';

var module = Module.content({
	name: 'actualization/owners',
	label: 'Актуализация собственников',
	routeData: () => link.route('actualizationOwners'),
	urlKey:'actualizationOwners',
	claims: {
		svcActualizationModule: ['view']
	},
	onRun: function () {

		var layout = new Layout();
		this.createAndShow(layout, 'Актуализация собственников');

	}
});


//module.registerModule(moduleClient);

export default module;

