﻿//define('svc-staff-emps-invite-layout', ['bus-svc','behaviors-blockbuttons', 'modals', 'm-employees-inviteModel', 'ui-controls-editValue'], function (svcRadio, BehaviorBlockButtons, modals, InviteEmployee, EditValue) {});

import svcRadio from 'bus/svc';
import modals from '_libs/modals';
import InviteEmployee from 'm/employees/inviteModel';
import EditValue from 'ui/controls/editValue';

import template from './layout.html';

import { MneView } from 'core/mne';

var Layout = MneView.extend({
	className: 'board-container',
	//template: '#tmpl-service-staff-emps-invite-layout',
	template,
	ui: {
		'birthdayField': 'input[name="birthday.date"]',
		'invite': 'button.invite',
		'employee': '.join-employee span'
	},
	events: {
		'click .join-employee': 'joinEmployee',
		'click @ui.invite': function () {
			var hash = this.$el.serializeObject({ unflat: true });
			hash = hash || {};

			if (!hash.email) {
				modals.notify('Email - Обязательное поле, на email будет отправлено приглашение');
				return;
			}
			console.log('# invite hash: ', hash);

			var model = new InviteEmployee(hash);
			var view = this;

			if (view.employeeId)
				hash.employeeId = view.employeeId;

			var wait = modals.notify('Подождите пожалуйста, приглашение отправляется и это может занять какое-то время.');
			model.save(hash, { wait: true }).then(function () {
				wait.destroy();
				modals.notify('Приглашение отправлено');
				view.render();

				var coll = svcRadio.request('employees');
				coll.add(model.toJSON());

			}, function (xhr) {
				wait.destroy();
				var addMessage = xhr.responseJSON && xhr.responseJSON.exceptionMessage;
				modals.error('Не получилсоь отправить приглашение:<br/>'+addMessage);
			});
		}
	},
	onJoinedEmployee: function () {
		console.log('e handler');
		var cntx = this.templateContext();
		var val = cntx.joinedEmployee();
		console.log('val', val);
		this.ui.employee.html(val);
	},
	joinEmployee: function () {
		var view = this;
		var setup = {
			valueType: 'string',
			header: 'Выберите виртуального сотрудника',
			value: view.emplolyeeId,
			sourceValues: svcRadio.request('employeesSelect', {
				filter: function (model) {
					return !this.userId;
				}
			}),
		};
		if (!setup) return;
		//var model = view.model;
		EditValue.modal.single(setup)
			.done(function (type, newvalue) {
				if (type != 'ok') return;

				if (newvalue instanceof Array && !setup.multiple)
					newvalue = newvalue[0];
				view.employeeId = newvalue;
				

			})
			.fail(function (type, newval, initval) {
				if (type != 'reset') return;
				view.employeeId = null;
			}).always(function () {
				console.log('always handler')
				view.triggerMethod('joined:employee');
			});			
	},
	onRender: function () {
		this.ui.birthdayField.datepicker({ yearRange: "-100:+0"});
	},
	templateContextExtend: function () {
		var view = this;
		return {
			joinedEmployee: function () {
				return this._ift(view.employeeId, '&mdash;', this._empn(view.employeeId));
			}
		}
	}
});

export default Layout;

