import { _, BbView } from 'vendors';
import { invokeValue } from 'utils/invoke';

let showedPopup;
//let removeShowedPopup;

function tryRemoveShowedPopup(view, condition) {
    if (!showedPopup) { return; }
    let showedView = showedPopup.view;
    if (!showedView || showedView.isDestroyed()) { return; }
    if ((view === showedView) === condition) {
        showedPopup.remove();
        showedPopup = undefined;
    }
}

export const popupApi = {

    initializePopup(parentView, config, index) {
        if (!config || !config.openEvent) return;
        let buildedConfig = Object.assign({}, parentView.getOption('defaultPopupConfig'), config);
        let { openEvent, openSelector } = buildedConfig;
        parentView.delegate(openEvent, openSelector, event => this.open(parentView, buildedConfig, index, event));
    },


    open(parentView, config, index, event) {

        //console.log('#open', index, config);
        
        let popup = this.getPopup(parentView, index, config);
        
        // убираем таймер разрушения если он установлен 
        this.clearRemoveTimeout(popup);
        
        // если попап уже показывается
        if (popup.view && popup.view.isAttached()) { 
            // разрушаем попап если выбрано поведение "TOGGLE" или просто выходим
            if (config.toggle) {
                this.removePopup(parentView, popup);
            } 
            //console.log('   •popup exist');
            return; 
        }
        
        // если установлен таймер показа, значит всё уже сделано и нужно просто подождать
        if (popup.showTimer) {
            return;
        }

        let showDelay = config.showDelay || (config.openEvent === 'mouseenter' ? 500 : 50);

        popup.showTimer = setTimeout(() => {

            let view;
            let isNew;
            if (popup.view) {
                view = popup.view;
            } else {
                view = this._buildPopupView(parentView, config, event.target);
                popup.view = view;
                isNew = true;
            }
            
            if (!view) { return; }
            
            tryRemoveShowedPopup(view, false);

            let removalCallback = () => {
                this.removePopup(parentView, popup, true);
                tryRemoveShowedPopup(view, true);
            };

            if (isNew) {
                if (config.openEvent === 'mouseenter') {
                    view.delegate('mouseenter', null, () => {
                        //console.log('•popup mouseenter');
                        this.clearRemoveTimeout(popup, view)
                    });
                    view.delegate('mouseleave', null, () => {
                        //console.log('•popup mouseleave');
                        popup.scheduleRemove();
                    });
                }
                

                view.on('destroy', removalCallback);

                this.setupPopup(parentView, popup, config, event.target);
            }
            
            showedPopup = { view: popup.view, remove: removalCallback };

            this.attachPopupView(parentView, view, config, event.target);
            

        }, showDelay);


    },

    getPopup(parentView, index, config) {
        let popup = parentView._popups[index];
        if (popup) { return popup; }

        let isMouseEnter = config.openEvent === 'mouseenter';
        let removeDelay = config.removeDelay || 1000;
        popup = { index, removeBehavior: config.removeBehavior }

        popup.scheduleRemove = (...callArgs) => {
            //console.log('#scheduleRemove', callArgs);
            this.clearShowTimer(popup);
            this.clearRemoveTimeout(popup);
            popup.removeTimeout = setTimeout(() => this.removePopup(parentView, popup), removeDelay);
        }


        if (isMouseEnter) {
            parentView.delegate('mouseleave', config.openSelector, () => {
                //console.log('•mouseleave');
                popup.scheduleRemove();
            });
        }

        parentView._popups[index] = popup;
        return popup;
    },

    clearRemoveTimeout(popup, view) {
        //console.log('   #clearRemoveTimeout', popup.removeTimeout);
        if (!popup.removeTimeout) { return; }
        clearTimeout(popup.removeTimeout);
        delete popup.removeTimeout;
    },

    clearShowTimer(popup) {
        //console.log('   #clearShowTimer');
        if (!popup.showTimer) { return; }
        clearTimeout(popup.showTimer);
        delete popup.showTimer;
    },

    removePopup(parentView, popup) {

        if (popup._isDestroying === true) { return; }

        let forceDestroy = arguments.length >= 2 ? arguments[1] : undefined;

        let shouldDestroy = popup.removeBehavior !== 'detach' || forceDestroy;

        //console.log('#removePopup');

        this.clearRemoveTimeout(popup);
        this.clearShowTimer(popup);

        if (popup.view) {
            if (shouldDestroy) {
                popup._isDestroying = true;
                popup.view.destroy();
                delete popup._isDestroying;
                delete popup.view;
                //delete this._popups[popup.index];
            } else {
                this.detachPopupView(parentView, popup.view);
                //throw new Error('detach popup view not implemented');
            }
        }
    },

    detachPopupView(parentView, view) {
        view.triggerMethod('before:detach');

        view._isAttached = false;
        view.$el.detach();

        view.triggerMethod('detach');
    },

    _buildPopupView(parentView, config, triggerEl) {
        let opts = invokeValue(config.view, parentView, [parentView, triggerEl, config]);
        if (!opts) return;
        if (opts instanceof BbView) { return opts }

        let View = opts.class;
        let options = _.omit(opts, 'class');
        return parentView.buildPopupView(View, options, triggerEl, config)
    },

    setupPopup(parentView, popup, config, triggerEl) {
        if (parentView.setupPopupView) {
            parentView.setupPopupView(popup.view, config, triggerEl);
        }
    },

    attachPopupView(parentView, view, cfg, triggerEl) {

        !view.isRendered() && view.render();

        view.triggerMethod('before:attach');

        let popupContainer = _.result(cfg, 'containerEl') || document.body;

        popupContainer.appendChild(view.el);

        view._isAttached = true;

        view.triggerMethod('attach');

        
        parentView.triggerMethod('popup:attach', view, triggerEl, cfg);
        parentView.updatePopupPosition(view, triggerEl, cfg);


    },

}