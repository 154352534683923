//define('assets-backbone-dependedCollection', ['assets-backbone-namespace', 'assets-backbone-collection'], function (Bbe) {});

//import Bbe from './namespace'; // 'assets-backbone-namespace';
import { Collection } from './collection'; //'assets-backbone-collection';


export const DependedCollection = Collection.extend({

	initialize: function (data, opts) {

		this.filterModel = opts.filterModel;

		if (opts.collection && opts.filterModel)
			this.listenTo(opts.collection, 'change', this.sourceChanged);

		if (opts.radio && opts.filterModel)
			this.listenTo(opts.radio, 'change', this.sourceChanged);

		if (opts.collection && opts.collection.comparator)
			this.comparator = opts.collection.comparator;
	},

	sourceChanged: function (model) {
		var res = this.filterModel(model);
		if (res)
			this.add(model);
		else
			this.remove(model);
	}
});

DependedCollection.create = function (options) {
	var newcol = new DependedCollection([], {
		filterModel: options.filterModel || options.filter,
		collection: options.collection,
		radio: options.radio,
	});
	if (!options.collection) return newcol;

	var src = options.collection;
	var models = newcol.filterModel ? src.filter(newcol.filterModel) : src.models;
	newcol.reset(models);
	return newcol;
}

