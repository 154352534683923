﻿//define('behaviors-datarole', [], function () {});



var allowed = ['popup', 'action'];
import { Behavior } from 'vendors';
var b = Behavior.extend({
	ui: function () {
		return { 'trigger': '[data-role]' };
	},
	triggerSelector: ' @ui.trigger',
	events: function () {
		var hash = {};
		hash['click' + (this.triggerSelector || '')] = 'triggerClick';
		return hash;
	},
	triggerClick: function (e) {
		e.stopPropagation();
		if (e.target.href)
			return;
		var $trig = $(e.target).closest('[data-role]');
		var role = $trig.data('role');
		var type = $trig.data('type');

		//console.log('triggered!!', role, type, this.view.cid);
		if (allowed.indexOf(role) === -1) return;

		var options = {
			role: role,
			type: type,
			el: e.target,
			$el: $trig,
			attrs: _.extend({}, $trig.attrs()),
			data: _.extend({}, $trig.data()),
			event: e,
			view: this.view,
			model: this.model,
		};

		this.view.triggerMethod(role, options);
		this.view.triggerMethod(role + ':' + type, options);
	}
});

b.Self = b.extend({
	triggerSelector: ''
});

export default b;
