import { _ } from 'vendors';

let splitter = /(^|:)(\w)/gi;

// take the event section ("section1:section2:section3")
// and turn it in to uppercase name onSection1Section2Section3
function getEventName(match, prefix, eventName) {
	return eventName.toUpperCase();
}

export const getOnMethodName = _.memoize(function (event) {
	return 'on' + event.replace(splitter, getEventName);
});