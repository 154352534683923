﻿//define('svc-realties-offers-views-cardInModal-objectOffer', [], function () {});

import template from './objectOffer.html';
import { MneView } from 'core/mne';
var View = MneView.extend({
	className: 'block',
	//template: '#tmpl-service-realties-offers-views-cardInModal-objectOffer',
	template,
});

export default View;
