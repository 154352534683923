﻿//define('svc-leads-layout', ['m-leads','svc-leads-stats-filters', 'svc-leads-stats-resultsView', 'realties-search-actionsPanel', 'ui-models-filters', 'ui-controls-fastfilters'], function (leadsNs, getFilters, ResultsView, ActionsPanel, Filters, FastFilters) {});

import leadsNs from 'm/leads';
import getFilters from './get-filters';
import ResultsView from './results-view';
import Filters from 'ui/models/filters';

import template from './layout.html';

import { MneView } from 'core/mne';

function urlObj(text) {
	if (!text) return {};

	var pairs = text.split('&');
	var result = _.reduce(pairs, function (hash, pair) {
		var chunks = pair.split('=');
		var key = chunks.shift();
		var value = chunks.join('=');

		if (!key) return hash;
		if (value == null || value === '') return hash;

		key = decodeURIComponent(key);
		value = decodeURIComponent(value);

		if (key.endsWith('[]')) {
			key = key.substring(0, key.length - 2);
		}
		var subkey;
		if (key.indexOf('.')) {
			var keys = key.split('.');
			key = keys.shift();
			subkey = keys.join('.');
		}


		if (!(key in hash)) {
			if (!subkey) {
				hash[key] = value;
			} else {
				hash[key] = {};
				hash[key][subkey] = value;
			}
		} else {
			if ((_.isObject(hash[key])) && !_.isArray(hash[key]) && subkey) {
				hash[key][subkey] = value;
			} else if (!_.isArray(hash[key])) {
				hash[key] = [hash[key]];
				hash[key].push(value);
			} else if (_.isArray(hash[key])) {
				hash[key].push(value);
			}
		}
		return hash;
	}, {});

	return result;
}

function changeUrlSearch(search) {
	var url;
	if (!search) {
		url = document.location.origin + document.location.pathname
	} else {
		url = document.location.origin + document.location.pathname + '?' + search;
	}
	history.pushState(null, '', url);
}



var Layout = MneView.extend({
	className: 'leads-layout',
	//template: '#tmpl-service-leads-stats-layout',
	template,
	regions: {
		'actionsPanel': { el: '.actions-panel-left', replaceElement: true },
		'result': '.result',
	},
	initialize: function () {
		this.collection = new leadsNs.StatsCollection();
		this.urlHash = urlObj(document.location.search.substring(1));
	},
	onRender: function () {

		this.initializeFilters();
		this.showSearchResult();
		this.showActionPanel();

	},
	initializeFilters: function () {

		if (this.filterObject) return;
		this.filterObject = Filters.create({
			filters: getFilters(),
		});

		var hash = this.urlHash;
		var filter = this.filterObject;
		filter.setValues(hash);
		var _this = this;
		this.filterObject.filters.on('values:change', function () {
			_this.changeUrl();
			//var hash = filter.getUrlHash();
			//var search = (hash == null || !_.size(hash)) ? '' : $.param(hash);
			//ResultsView.changeUrlSearch(search);

		});

		

	},
	showSearchResult: function () {

		var resultView = new ResultsView({
			collection: this.collection,
			filterObject: this.filterObject,
			urlHash: this.urlHash
		});
		this.showChildView('result', resultView);
		this.segments = resultView.segments;
		this.listenTo(this.segments, 'refresh', this.changeUrl);
	},
	showActionPanel: function () {

		//var actionspanel = new ActionsPanel({
		//    className: 'actions-panel actions-panel-left',
		//    statsView: undefined,
		//    filterObject: this.filterObject,
		//    fastFiltersView: FastFilters.create({
		//        filters: this.filterObject,
		//        onlyPined: true,
		//    }),
		//});
		//this.showChildView('actionsPanel', actionspanel);

	},

	changeUrl() {
		var hash = this.filterObject.getUrlHash() || {};
		var segments = this.segments && this.segments.pluck('id') || undefined;
		if (segments && segments.length) {
			hash.segments = segments;
		}
		var search = '';
		if (_.size(hash)) {
			search = $.param(hash);
		}

		changeUrlSearch(search);

	}

});

export default Layout;
