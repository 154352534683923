module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<ul>\n	<li class="list-lbl"><small>Адрес</small><span>'+
((__t=( _mv('address.fullAddress') ))==null?'':__t)+
'</span></li>\n	';
if(_mv('address.metro')){
__p+='\n	<li class="list-lbl"><small>'+
((__t=( _mv('address.metro.line') ))==null?'':__t)+
'</small><span class="clr-metro-'+
((__t=(_mv('address.metro.lineNum') ))==null?'':__t)+
' txt">'+
((__t=( _mv('address.metro.station') ))==null?'':__t)+
' ('+
((__t=( Math.round(_mv('address.metro.distance') || 0) ))==null?'':__t)+
'м)</span></li>\n	';
}
__p+='\n</ul>';
}
return __p;
};
