import { View } from 'core';
import { mediaApi } from 'apis/media';
const template = `<tr>
<td colspan="2"><h3><%= name %></h3></td>
</tr>
<tr>
<td class="realty-thumb"><% if (thumb) { %><img src="<%= thumb %>" /><% } %></td>
<td class="realty-info">
	<div contenteditable="true"><%= prefix %>, класс <%= cls %></div>
	<div contenteditable="true"><%= address.fullAddress %></div>
	<div contenteditable="true"><%= metroHtml %></div>
	<div contenteditable="true"></div>
</td>
</tr>`
export const PreviewRealty = View.extend({
	//template: '#tmpl-service-realties-sendByEmail-realty',
	template,
	baseClassName: 'realty-wrapper',
	tagName:'table',
	templateContext() {
			var thumbid = this.model.get('thumbnail');
			const thumb = thumbid ? mediaApi.url(thumbid, "w150-h150-ls", "jpg") : '';
			// var thumb = (thumbid && Bus.media.request('realty:thumbnail', thumbid, 'w150-h150-c-rbc')) || '';
			return {
					thumb,
					metroHtml: ''
			}
	}
});