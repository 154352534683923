﻿//define('m-deals', ['m-deals-deal', 'm-deals-deals', 'm-deals-dealsSearch', 'm-deals-dealVirtualOffer', 'm-deals-dealOffer', 'm-deals-dealOffers', 'references'], function (Deal, Deals, DealsSearch, DealVirtualOffer, DealOffer, DealOffers, refs) {});

//let Deal, Deals, DealsSearch, DealVirtualOffer, DealOffer, DealOffers, refs;

import { DealModel } from './deal';
import DealsCollection from './deals';
import DealsSearch from './dealsSearch';
import DealVirtualOffer from './dealVirtualOffer';
import DealOffer from './dealOffer';
import DealOffers from './dealOffers';
import refs from 'references';

var ns = {};
ns.DealModel = DealModel;
ns.DealsCollection = DealsCollection;
ns.DealsSearchCollection = DealsSearch;
ns.DealVirtualOfferModel = DealVirtualOffer;
ns.DealOfferModel = DealOffer;
ns.DealOffersCollection = DealOffers;
ns.DealStageOrder = {};


refs.on('ready', () => {
	var stages = refs.enum('dealStages');
	var i = 0;
	_(stages).each(function (label, stage) {
		ns.DealStageOrder[stage] = i;
		i++;
	});
});

// refs.runPromise.then(function () {
// 	var stages = refs.enum('dealStages');
// 	var i = 0;
// 	_(stages).each(function (label, stage) {
// 		ns.DealStageOrder[stage] = i;
// 		i++;
// 	});
// });

export default ns;

export {
	DealModel,
	DealsCollection
}