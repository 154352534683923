import { BbModel, _ } from 'vendors';
import { urls } from 'coms/urls';
import { headers, updateHeaders } from './headers';
import refs from 'references';
import { IdentityConfig } from './IdentityConfig';


const rightsFuncs = {
	get: function() {
		const cs = this.get('currentService');
		if (!cs) { return {}; }
		if ('rights' in cs) {
			return cs.rights.modulesRighgts || {};
		}
		if ('claims' in cs) {
			return cs.claims || {};
		}
		return {};
		// return (this.get('currentService') || {}).rights || {};
	},
	getAvailableModules: function() {
		return Object.keys(this.rights.get()); // this.rights.get().availableModules || [];
	},
	getModulesRights: function() {
		return this.rights.get();
		//return this.rights.get().modulesRighgts || {};
	},
	getModuleRights: function(name) {
		return this.rights.getModulesRights()[name];
	},
	isModuleExists: function(name) {
		return name != null && this.rights.getAvailableModules().indexOf(name) > -1;
	},
	getSvcClaims: function(moduleId) {

		var all = (this.get('currentService') || {}).claims || {};
		if (arguments.length == 0) {
			return all;
		}
		let moduleClaims = all[moduleId];
		if (!moduleClaims || moduleClaims.toLowerCase() === 'none') {
			return [];
		}
		return moduleClaims.split(/\s*,\s*/gmi);
	},
	checkClaimValue: function (moduleId, moduleClaims) {
		var userModuleClaims = this.rights.getSvcClaims(moduleId);
		//console.log('AGAINST:', userModuleClaims, moduleId);

		// if (moduleClaims === true && userModuleClaims.length > 0) {
		// 	return true;
		// }
		if (moduleClaims === true) {
			return userModuleClaims.length > 0;
		}

		// if (moduleClaims === false && userModuleClaims.length == 0) {
		// 	return true;
		// }
		if (moduleClaims === false) {
			return userModuleClaims.length === 0;
		}

		if (!userModuleClaims.length) return false;

		if (typeof moduleClaims === 'string') {
			moduleClaims = moduleClaims.split(/\s*,\s*/gm);
		}

		return _.every(moduleClaims, function(mclaim){ 
			return userModuleClaims.indexOf(mclaim) > -1;
		});

	},
	checkNewClaims: function(module) {
		var claims = module.getOption('claims');
		return this.rights.checkNewClaimsValues(claims);
		// if (claims) {
		// 	//console.log('CLAIMS:', claims);
		// 	var _this = this;
		// 	var check = _.every(claims, function(moduleClaims, moduleId) { 
		// 		// if (moduleClaims === true) {

		// 		// }
		// 		// if (!_.isArray(moduleClaims) || moduleClaims.length == 0) return true;
		// 		return _this.rights.checkClaimValue(moduleId, moduleClaims);
		// 	});
		// 	return check;
		// } else {
		// 	return true;
		// }
	},
	checkNewClaimsValues(claims) {
		if (!claims) { return true; }

		if (Array.isArray(claims)) {
			return claims.some(claimsSet => this.rights.checkNewClaimsValues(claimsSet));
		}

		var check = _.every(claims, (moduleClaims, moduleId) => { 
			return this.rights.checkClaimValue(moduleId, moduleClaims);
		});

		return check;
	},
	isModuleAvailable: function (module) {
		const oldway = module.rightsMask;
		const newway = ('claims' in module.options === true || 'claims' in module === true);
		if (newway) {
			return this.rights.checkNewClaims(module);
		}
		if (oldway) {
			var isauth = this.get('authenticated');
			if (module.auth === true && module.auth != isauth) return false;
	
			if (!module.moduleId && !module.rightsMask) return true;
	
	
			if (module.moduleId && !this.rights.isModuleExists(module.moduleId)) {
				return false;
			}
	
			if (module.rightsMask) {
				//var myRights = this.get('modulesRights') || {};
				var moduleRights = _.result(module, 'rightsMask');
				var _this = this;
				return _(moduleRights).every(function (mask, module) {
					var myRights = _this.rights.getModuleRights(module);					
					const ok = refs.enumHasFlag(myRights, mask);
					return ok;
				});
	
			}
			return false;
		}

		return true;


	},
	checkModuleRights: function (a1, a2) {

		var check;
		if (typeof a1 === 'string' && typeof a2 === 'string') {
			(check = {})[a1] = a2; //{ module: a1, mask: a2 };
		}
		else if (typeof a1 === 'object')
			check = a1;
		else {
			console.warn('wrong arguments in checkModuleRights:', a1);
			return false;
		}
		
		return this.rights.checkNewClaimsValues(check);
		// var allUserRights = this.rights.getModulesRights();
		// var result = _(check).every(function (mask, module) {
		// 	var userMask = allUserRights[module] || '';
		// 	return refs.enumHasFlag(userMask, mask);
		// });
		// return result;
	},

	canChangeDepartment: function () {
		var check = { 'staff': 'changeDepartmentRights' };
		var result = this.rights.checkModuleRights(check);
		return result;
	},
	canViewEmployeeInnerDetails: function () {
		var check = { 'staff': 'viewEmployeesInnerDetails' };
		return this.rights.checkModuleRights(check);
	},
	canChangeEmployee: function () {
		var check = { 'staff': 'changeEmployees' };
		return this.rights.checkModuleRights(check);
	},
	canChangeEmployeeInnerDetails: function () {
		var check = { 'staff': 'changeEmployeesInnerDetails' };
		return this.rights.checkModuleRights(check);
	},
	canChangeEmplyeeState: function () {
		var check = { 'staff': 'changeEmployeesState' };
		return this.rights.checkModuleRights(check);
	},
};

const MyEmployees = {
	getMyEmployees: function () {
		var svc = this.getSvc() || {};
		var emps = svc.employees || [];
		return emps;
	},
	hasEmployees: function () {
		return this.getMyEmployees().length > 0;
	},
	isMyEmployee: function (id) {
		var emps = this.getMyEmployees();
		var res = emps.indexOf(id) > -1;
		return res;
	},
	getMyLeaders: function () {
		var svc = this.getSvc() || {};
		var emps = svc.leaders || [];
		return emps;
	},
	hasLeaders: function () {
		var leads = this.getMyLeaders();
		return leads.length > 0;
	},
};

const IdentityModel = BbModel.extend({

	initialize() {
		this.initConfig();
		this.initRights();

		if (document.location.pathname.startsWith('/s/')) {
			headers.svcwebid = document.location.pathname.split('/')[2];
		} else {
			delete headers.svcwebid;
		}

		updateHeaders();


	},
	initConfig() {
		if (this.cfg) { return; }
		this.cfg = new IdentityConfig({ identity: this });
	},
	initRights() {

		if (this.rights) { return; }

		this.rights = {};

		_.each(rightsFuncs, (func, name)  => {
			this.rights[name] = _.bind(func, this);
		});

	},

	url() {
		let url = urls.api('v4/acc/old-identity');
		if (this.svcwebid) {
			url += '/' + this.svcwebid;
		}
		return url;
	},
	isLogged(){
		return this.isAuthenticated();
	},
	isAuthenticated() {
		return this.get('authenticated') === true;
	},
	isAuthorized() {
		return this.get('authorized') === true;
	},
	getEmployeeId: function () {
		return this.get('employeeId');
	},
	getSvc: function () {
		return this.get('currentService');
	},
	getServiceName() {
		const cs = this.getSvc();
		return cs?.name || '';
	},
	getSvcWebId() {
		const svc = this.getSvc();
		return svc?.idString;
	},
	isMe: function(id) {
		var srvs = this.get('services') || [];
		var res = _(srvs).some(function (s) { return s.employeeId == id });
		
		return res;
	},
	isAdmin: function () {
		return (this.getEmployeeId() || '').toLowerCase() == "8d65b93c-fcbc-4277-9838-bf1e88722aa4";
	},
	...MyEmployees,

});

export const identity = new IdentityModel();
export const userConfig = identity.cfg;