module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<tr>\n	<td colspan="2"><h3>'+
((__t=( _m.name ))==null?'':__t)+
'</h3></td>\n</tr>\n<tr>\n	<td class="realty-thumb"><img src="'+
((__t=(thumb ))==null?'':__t)+
'" /></td>\n	<td class="realty-info">\n		<div contenteditable="true">'+
((__t=( _m.prefix ))==null?'':__t)+
', класс '+
((__t=( _m.cls))==null?'':__t)+
'</div>\n		<div contenteditable="true">'+
((__t=( _mfn('getAddressString')))==null?'':__t)+
'</div>\n		<div contenteditable="true">'+
((__t=( _mfn('getMetroHtml')))==null?'':__t)+
'</div>\n		<div contenteditable="true"></div>\n	</td>\n</tr>\n';
}
return __p;
};
