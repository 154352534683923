﻿//define('svc-realties-building-views-inList', ['bus','behaviors'], function (Bus, beh) {});

import Bus from 'bus';
import beh from 'behaviors';

import template from './inList.html';
import './realty-in-list.less';
import { MneView } from 'core/mne';

var View = MneView.extend({
	className: 'realty-item realty-in-list',
	//template: '#tmpl-service-realties-building-views-inList',
	template,
	dynamicClass: function () {
		if (this.getOption('clickable'))
			return 'clickable';
	},
	behaviors: [beh.DynamicClass],
	templateContextExtend: function () {
		var thumbid = this.model && this.model.get('thumbnail');
		//console.log('THUMB', thumbid, this.model.attributes);
		var thumb = (thumbid && Bus.media.request('realty:thumbnail', thumbid)) || '';
		return {
			thumb: thumb,
		};
	}
});

export const BuildingInList = View;

Bus.Views.reply('realties:building:inList', function () {
	return View;
});

export default View;