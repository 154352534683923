﻿//define('ui-controls-leadSourceEdit', [], function () {});

import template from './layout.html';
import { BbeCollection } from 'core/bbe';
import './lead-source-edit.less';

import { MneView, MneNextCollectionView } from 'core/mne';

var sources = [
	'm2data',
	'Циан',
	'Яндекс',
	'Яндекс.Недвижимость',
	'ГдеДом',
	'Афай',
	'Гугл',
	'Фейсбук',
	'Креба',
	'РГ Сайт',
	'РГ лендинг',
	'Диспетчер лендинг',
	'ПВТ лендинги',
	'ПВТ Сайт',
	'Авито',
	'Рассылка',
	'Старая реклама',
	'Ручной ввод',
	'Неизвестный источник'
];

var SuggestionView = MneView.extend({
	className: 'source-suggestion',
	template: _.template('<i></i><span><%= id %></span>'),
	onRender() {
		var selected = this.model.get('selected');
		if (selected) {
			this.$el.addClass('active');
		} else {
			this.$el.removeClass('active');
		}
	},
	modelEvents: {
		'change':'render',
	},
	triggers: {
		'click':'selected'
	}
});

var View = MneView.extend({
	//template: '#tmpl-ui-controls-leadSourceEdit-layout',
	template,
	className: 'lead-source-edit',
	ui: {
		'source': 'input[name=name]',
	},
	regions: {
		'suggestions':'.name-container .suggestions'
	},
	initialize() {
		this.value = this.getOption('initialValue') || {};
		if (!this.value.type) {
			this.value.type = 'callIn';
		}
	},
	events: {
		'input input': function (e) {
			this._setVal(e.target.name, e.target.value);
		},
	},
	_setVal: function (key, val) {
		this.value[key] = val;
		this.triggerMethod('value:changed', { value: _.clone(this.value) });
	},
	onRender() {
		this.showSuggestions();
	},
	showSuggestions() {
		var models = _.map(sources, function (a) { return { id: a } });
		var col = new BbeCollection(models);
		var view = new MneNextCollectionView({
			collection: col,
			childView: SuggestionView
		});			
		this.showChildView('suggestions', view);
	},
	childViewEvents: {
		'childview:selected': function (v) {
			this.ui.source.val(v.model.id);
			this._setVal('name', v.model.id);
		}
	},
	templateContext: function () {
		var type = this.value.type;
		return _.extend({
			ch: function (val) {
				return val == type ? ' checked ' : '';
			},
			instrument: undefined,
		}, this.value);
	}
});

View.create = function (opts) {
	var options = _.extend({}, opts);
	//console.log('create select', opts)
	return new View(options);
};

View.sources = sources;

export default View;
