// import { Model } from 'core/model';
// import { CollectionView } from 'core/colview';
// import { Collection } from 'core/collection';
import { Model, CollectionView, Collection } from 'core';
import { HamburgerView } from "coms/ui/HamburgerView";
// import { propertySchemaApi } from "../propertySchemaApi";
import { ValueWithLabelView } from "./ValueWithLabelView";
// import { modalsApi } from "../../modalsApi2";
import { _ } from 'vendors';
import './edit-properties-view.less';

export const _PropertiesView = HamburgerView.extend({
	baseClassName: 'edit-properties-view',
	mergeOptionsKeys: ['validate'],
	stateClassNames: ['valid'],
	initialize() {
		const values = this.getOption('value') || {};
		this.initialValues = { ...values };
		this.model = new Model(values);
		this.model.flat = true;
	},

	getChildren() {
		const modelSchema = this.getOption('modelSchema');
		const propertiesSchema = this.getProperties();
		const values = this.getOption('value') || {};
		const inlineEdit = this.getOption('inlineEdit');
		return _.map(propertiesSchema, valueSchema => {
			return {
				class: ValueWithLabelView,
				name: valueSchema.property,
				property: valueSchema.property,
				propertiesSchema,
				valueSchema,
				modelSchema,
				initialValues: this.initialValues,
				initialValue: values[valueSchema.property],
				value: this.model.smartGet(valueSchema.property),
				inlineEdit
			}
		});
	},
	getPropertiesSchema() {
		const schema = this.getOption('propertiesSchema') || {};
		return schema;
	},
	getProperties() {
		const schema = this.getPropertiesSchema();
		return schema;
		// const modelSchema = this.getOption('modelSchema');
		// const properties = this.getOption('properties') || [];
		// const schemaOverrides = this.getOption('schemaOverrides') || {};
		// return properties.map(property => {
		// 	const valueSchema = propertySchemaApi.getSchema(property, modelSchema);
		// 	const override = schemaOverrides[valueSchema.property]
		// 	return Object.assign({}, valueSchema, override);
		// }).filter(f => !!f);
	},
	childViewOptions() {
		return {
			useModelForValues: true,
		}
	},

	_validate() {
		let errors;
		if (typeof this.validate === 'function') {
			errors = this.validate();
		} else if (this.validate === true) {
			errors = this._defaultValidate();
		}
		this.state('valid', errors ? 'invalid' : 'valid');
		this.triggerMethod('validate', errors);
		this.triggerMethod('disable:resolve:button', !!errors);
	},

	_defaultValidate() {
		const schema = this.getPropertiesSchema();
		const errors = [];
		_.each(schema, (valueSchema, path) => {
			const { validate, required, label} = valueSchema;
			let error;
			let value = this.model.smartGet(path);
			if (typeof validate === 'function') {
				error = validate(value, this.model.attributes, schema);
			} else {
				if (required && isEmpty(value)) {
					error = 'требуется значение для ' + (label || path)
				}
			}
			addError(errors, error);
		});

		return returnErrors(errors);
	},

	childViewEvents: {
		'user:input' (path, value) {
			this._validate();
			this.triggerMethod('user:input', this.model.attributes);
		}
	}

});

export const PropertiesView = CollectionView.extend({
	baseClassName: 'edit-properties-view',
	mergeOptionsKeys: ['validate'],
	stateClassNames: ['valid'],
	initialize() {
		const values = this.getOption('value') || {};
		this.initialValues = { ...values };
		this.model = new Model(values);
		this.model.flat = true;
		const schema = this.getPropertiesSchema();
		const sortOnEvents = [];
		const models = _.map(schema, (valueSchema, path) => {
			if (typeof valueSchema.isExistForEdit === 'function') {
				const dependsOn = valueSchema.dependsOn;
				if (dependsOn) {
					sortOnEvents.push(...dependsOn.map(prop => 'change:' + prop));
				}
			}
			return valueSchema;
		});
		this.collection = new Collection(models);
		this.collection.schema = schema;
		if (sortOnEvents.length) {
			this.listenTo(this.model, sortOnEvents.join(' '), this.sort);
		}
	},	
	childView: ValueWithLabelView,
	childViewOptions(child) {
		const valueSchema = child.attributes;
		const {	propertiesSchema, modelSchema, inlineEdit } = this.getOptions(['propertiesSchema', 'modelSchema', 'inlineEdit'])
		const options = {
			name: valueSchema.property,
			property: valueSchema.property,
			propertiesSchema,
			valueSchema,
			modelSchema,
			initialValues: this.initialValues,
			initialValue: this.initialValues[valueSchema.property],
			value: this.model.smartGet(valueSchema.property),
			inlineEdit,
			valuesModel: this.model,
			useModelForValues: true,
		}
		return options;
	},
	childViewEvents: {
		'user:input' (path, value) {
			this._validate();
			this.triggerMethod('user:input', this.model.attributes);
		}
	},
	_validate() {
		let errors;
		if (typeof this.validate === 'function') {
			errors = this.validate();
		} else if (this.validate === true) {
			errors = this._defaultValidate();
		}
		this.state('valid', errors ? 'invalid' : 'valid');
		this.triggerMethod('validate', errors);
		this.triggerMethod('disable:resolve:button', !!errors);
	},
	viewFilter(v) {
		const isExistForEdit = v.model.get('isExistForEdit');
		if (isExistForEdit && !isExistForEdit(this.model)) {
			console.log('- not exist for edit -')
			return false;
		}
		return true;
	},
	_defaultValidate() {
		const schema = this.getPropertiesSchema();
		const errors = [];
		_.each(schema, (valueSchema, path) => {
			const { validate, required, label} = valueSchema;
			if (valueSchema.isExistForEdit && !valueSchema.isExistForEdit(this.model)) {
				return;
			}
			let error;
			let value = this.model.smartGet(path);
			if (typeof validate === 'function') {
				error = validate(value, this.model.attributes, schema);
			} else {
				if (required && isEmpty(value)) {
					error = 'требуется значение для ' + (label || path)
				}
			}
			addError(errors, error);
		});

		return returnErrors(errors);
	},
	getPropertiesSchema() {
		const schema = this.getOption('propertiesSchema') || {};
		return schema;
	},	
});

function returnErrors(errors) {
	if (errors && Array.isArray(errors)) {
		return errors.length ? errors : undefined;
	}
}

function addError(errors, error) {
	if (error) {
		errors.push(error);
	}
}

function isEmpty(value) {
	return value == null || value === '';
}