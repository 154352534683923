import { MnObject } from 'vendors';
import { enumArray, enumResult, toHash } from './utils';
import sstore from './sessionStore';
import { backendApi } from 'apis/backend';
import { urls } from '../urls';
import { commonRefs } from './commonRefs';
import { updateTemplateFuncs } from './templateFuncs';

export const Refs = MnObject.extend({

	getPromise({ triggerReady = true } = {}) {
		if (this.runPromise) { return this.runPromise; }

		this.runPromise = new Promise((resolve, reject) => {

			let stored = sstore.get('refs');

			if (document.location.hostname == 'app.nrg.loc') {
				stored = undefined;
			}

			if (stored) {
				this._updateModel(stored);
				resolve();
				return;
			}

			this._fetch().then(() => {
				resolve();
			}, xhr => reject(xhr));


		});

		if (triggerReady) {
			this.runPromise = this.runPromise.then(() => {
				this.trigger('ready');
				return Promise.resolve();
			});
		}

		return this.runPromise;
	},
	getOldPromise() {
		const promise = this.getPromise({ triggerReady: false });
		return promise.then(() => {
			updateTemplateFuncs(this);
			this.trigger('ready');
			return Promise.resolve();
		});
	},
	_fetch() {
		const url = urls.api('references', { root: 'svc/v01' });
		return backendApi.get(url, data => {
			console.log('<????>', data);
			this._updateModel(data);
			sstore.set('refs', data, { absolute: Date.MINUTES(10) });
		});
	},

	_updateModel(data) {
		console.log('[data] ->? ', data);
		this.Enums = data.enums;
		this.Models = data.models;
		Object.assign(this.Enums, commonRefs);
	},
	enum: function (name, arg1, arg2) {
		var en = (typeof name === 'string' ? this.Enums[name] : name);
		if (!en) return;
		if (arg1 == null) return en;
		else if (typeof arg1 === 'string' && arg1.indexOf(',') == -1)
			return en[arg1];
		else if (typeof arg1 === 'string' && arg1.indexOf(',') >= 0) {
			var result = enumArray(en, arg1.toArray());
			return enumResult(result, arg2);
		} else if (arg1 instanceof Array) {
			return enumResult(arg1, arg2);
		} else {
			return en[arg1.toString()];
		}
	},
	enumToggleFlag: function (enumString, toggle) {
		enumString = enumString || '';
		toggle = toggle || '';
		var enumHash = toHash(enumString); //toHash.call(enumString.toArray());
		var toggleHash = toHash(toggle); //toHash.call(toggle.toArray());
		var res = _.extend({}, enumHash, toggleHash);
		var resArray = [];
		_(res).each(function (v, n) {
			if (n in enumHash && n in toggleHash) return;
			resArray.push(n);
		});
		var result = resArray.onlyValues().join(', ')
		return result;
	},
	enumHasFlag: function (enumString, check, all = false) {
		enumString = enumString || '';
		check = check || '';

		var enumArr = enumString.toArray();
		var checkArr = check.toArray();
		let method = all ? Array.prototype.every : Array.prototype.some;
		return method.call(checkArr, ch => enumArr.indexOf(ch) > -1);
		//return _(checkArr).some(function (ch) { return enumArr.indexOf(ch) >= 0; });
	},
	enumHasAllFlags: function(enumString, check) {
		return this.enumHasFlag(enumString, check, true);
	},
	buildingClassString: function (_num) {
		var num = typeof _num === 'number' ? _num
			: typeof _num === 'string' ? parseFloat(_num, 10)
				: _num;
		if (!(typeof num === 'number') || isNaN(num)) throw Error('Class must be a number');

		var letters = ['A', 'B', 'C', 'D', 'E', 'F'];
		var letter = letters[Math.ceil(num) - 1];
		var plus = ((num | 0) < num ? "+" : "");
		return letter + plus;
	},
});