// define('ui/layouts/recordset-view', [
// 	'ui-models-filters', 
// 	'ui-controls-fastfilters', 
// 	'ui-models-selectionHolder',
// 	'ui/layouts/actions-panel',
// 	'ui/layouts/searchresult-view'
// ], 
// 	function (
// 		Filters, 
// 		FastFilters, 
// 		SelectionHolder,
// 		ActionsPanel,
// 		ListResultView
// 	) {
// });

import Filters from 'ui/models/filters';
import FastFilters from 'ui/controls/fastfilters';
import SelectionHolder from 'ui/models/selectionHolder';
import ActionsPanel from 'ui/layouts/actions-panel';
import ListResultView from 'ui/layouts/searchresult-view';

import './recordset-view.less';

import { MnView } from 'vendors';

	//let ListResultView = CollectionView.V2;

let template = _.template('<div class="actions-panel actions-panel-left"></div><div class="result scrolled-region"></div>');


var Layout = MnView.extend({
	className: 'recordset-view',
	template,
	regions: {
		'actionsPanel': { el: '.actions-panel-left', replaceElement: true },
		'result': '.result',
	},

	//#region FILTERs

	getEntityFiltersOptions() {
		return { }
	},		
	_getFiltersOptions(entityFilters) {
		if (!entityFilters) return;
		let options = {
			groups: entityFilters.groups(),
			filters: entityFilters.filters(this.getEntityFiltersOptions()),
			allFiltersDisabled: entityFilters.getOption('allFiltersDisabled'),
			changeHandlers: [
				entityFilters.changeHandler
			],
		}
		return options;
	},
	getFiltersOptions() { },

	initializeFilters() {
		if (this.filterObject) return;

		let filtersConfig = this.getOption('filtersConfig');
		if (!filtersConfig) return;

		let options = Object.assign(
			{}, 
			this._getFiltersOptions(filtersConfig), 
			this.getFiltersOptions()
		);

		this.filterObject = Filters.create(options);

	},
	//#endregion 


	initSelectionHolder: function () {
		if (this.selectionHolder) return this.selectionHolder;
		let name = this.getOption('selectionHolderName');
		if (!name) return;
		this.selectionHolder = SelectionHolder.create(name);
		return this.selectionHolder;
	},

	//#region Result View
	getDenySelect() { },
	getListResultOptions() {	
		let denySelect =this.getDenySelect();

		let defs = {
			collection: this.searchResult,
			filterObject: this.filterObject,
			selectionHolder: this.selectionHolder,
			denySelectionFor: denySelect
		}
		return Object.assign({}, defs, this.getOption('listResultOptions'));
	},
	buildListResultView() {
		let options = this.getListResultOptions();
		return new ListResultView(options);
	},
	showListResult: function () {
		var result = this.buildListResultView();
		this.showChildView('result', result);
	},

	//#endregion

	//#region ActionsPanel

	getPanelActions() {
		// var selectedActions = [];
		// var deal = this.getOption('deal');
		// if (deal)
		//     selectedActions.push({ text: "прикрепить отобранное к процессу", action: 'joinOffersToDeal', actionOn: deal });
		// selectedActions.push({ text: "отправить по email", action: 'sendAsEmail', actionOn: this });
		
		return [];
	},
	buildActionsPanel() {
		let actions = this.getPanelActions();
		//if (!actions || !actions.length) return;

		let fastFiltersView = this.filterObject && FastFilters.create({
			filters: this.filterObject,
		});

		let view = new ActionsPanel({
			statsView: undefined,
			filterObject: this.filterObject,
			selectionHolder: this.selectionHolder,
			// selectedComparators: {
			// 	square: function (a, b) {
			// 		return bbeViewComparator([
			// 			[a, b, function () { return this.model.get('object').info.squareOffer; }]
			// 		]);
			// 	}
			// },
			selectedActions: actions,
			fastFiltersView,
			additionalView: this.getOption('additionalView'),
			additionalViewOptions: this.getOption('additionalViewOptions'),
		});
		return view;
	},
	showActionPanel: function() {
		var actionspanel = this.buildActionsPanel();
		actionspanel && this.showChildView('actionsPanel', actionspanel);
	},
	
	//#endregion ActionsPanel


	
	onRender: function () {

		this.initializeFilters();
		this.initSelectionHolder();
		this.showListResult();
		this.showActionPanel();

		if (this.___firstRender) return;
		if (this.getOption('instantApplyFilters')) {
			this.filterObject.apply();
		}
		this.___firstRender = true;

	},

});

export default Layout;
