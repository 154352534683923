﻿// define('svc-deals-list-layout2', ['bus-app', '_libs/identity', 'references', 'modals', 'ui-controls-editValue', 'behaviors', 'svc-deals-list-dealInBlock', 'ui-layouts-board-list-layout', 'ui-layouts-tabs', 'ui-models-regionsCollections'], 
// function (appRadio, identity, refs, modals, EditValue, beh, DealView, BoardList, Tabs, RegionsCollections) {});

import refs from 'references';
import beh from 'behaviors';
import DealView from './dealInBlock';
import BoardList from 'ui/layouts/board/list/layout';
import Tabs from 'ui/layouts/tabs';
import RegionsCollections from 'ui/models/regionsCollections';

import template from './layout2.html';

import {bbeViewComparator} from 'utils';

import './deal-in-block.less';
import './deal-search-in-block.less';
import './deals-container.less';
import './deals-static-board.less';

import { MneView } from 'core/mne';

var Layout = MneView.extend({
	className: 'deals-static-board',
	//template: '#tmpl-service-deals-list-layout2',
	template,
	behaviors: [beh.WsMonitor.extend({modelType: "JsonDeal", handleAll: true})],
	regions: {
		'tabs':'.lists-tabs'
	},
	initialize: function () {
		this.groupper = this.createGroups();			
	},
	onRender: function() {
		this.showTabs();
		this.groupper.apply(this, '.lists');
		this.showGroups();
		//console.log(this.groupper);
	},
	showTabs: function() {
		var tabs = {};
		_(this.groupper.groups).each(function(c, n) { tabs[n] = refs.enum('dealStages', n); });
		this.tabs = new Tabs({
			tabs: tabs,
			multiple: true,
			allActive: true
		});
		this.showChildView('tabs', this.tabs);

	},
	showGroups: function () {
		var _this = this;
		_(this.groupper.groups).each(function (group) {
			_this.showGroup(group);
		});
	},
	showGroup: function (group) {
		var region = this.getRegion(group.name);
		if (!region) {
			console.warn('region missing', group.name);
			return;
		}
		var view = this.groupper.getView(group.name);
		//console.log('42',view);
		region.show(view);
	},
	getGroupConfig: function () {
		var allgroups = refs.enum('dealStages');
		var res = {};

		res.iteratee = 'stageId';
		res.allGroups = _(allgroups).map(function (label, name) { return name; });
		res.labels = _.extend({}, allgroups);

		return res;
	},
	createGroups: function () {

		var groupConfig = this.getGroupConfig();
		var groupedCollection = new RegionsCollections({
			collection: this.collection,
			iteratee: groupConfig.iteratee,
			itemFilter: this.getOption('itemFilter'),
			groups: groupConfig.allGroups,				
			regionTagClass: function(name) { return 'list l-' + name; },
			View: BoardList,
			viewOptions: function(name) {
				return {
					childView: DealView,
					label: refs.enum('dealStages', name),
					viewComparator: function (v1, v2) {
						var res = bbeViewComparator([
							[v2, v1, function (m) { return m.isTodayFuture(); }],
							[v2, v1, function (m) { return m.isTodayPast(); }],
							[v1, v2, function (m) { return m.getWhenValue(); }],
						]);
						//console.log('-->', res);
						return res;
					}
				};
			}
		});

		return groupedCollection;
	},
	onBeforeDestroy: function() {
		this.groupper.destroy();
	},
	onChildviewTabOn: function(name) { this.$el.removeClass('hide-' + name); },
	onChildviewTabOff: function(name) { this.$el.addClass('hide-' + name); },
});

export default Layout;