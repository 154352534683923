﻿//define('ui-controls-commentBoardList',['bus-svc','svc-comments'],function(radio, commentsNs){});

import radio from 'bus/svc';
import commentsNs from 'svc/comments';

import commtemplate from './comment.html';
import template from './layout.html';

import { MneView, MneMixedView } from 'core/mne';

import beh from 'behaviors';


var CommentView = MneView.extend({
	_viewName: 'comment-item',
	className:'comment-item',
	//template: '#tmpl-ui-controls-commentBoardList-comment',
	template: commtemplate,
	templateContext: function () {
		var _dateText = '';
		var _date = this.model.get('date');
		var authorId = this.model.get('authorId');
		var date = new Date(_date);
		var _authorName = radio.request('employeeName', authorId);
		
		if (Date.valid(date)) {
			_dateText = date.toLocaleTimeString('ru') + ' ' + date.toLocaleDateString('ru');
		}
		return {
			_dateText: _dateText,
			_authorName: _authorName
		}
	},
});

var EmptyView = MneView.extend({
	className: 'comment-item empty',
	template: _.template('Комментарии отсутствуют'),
});

// var _CommentsView = MneNextCollectionView.extend({
// 	childView: CommentView,
// 	emptyView: EmptyView
// });

var CommentsView = MneMixedView.extend({
	className: 'comments-list scrollY-region',
	childView: CommentView,
	emptyView: EmptyView,
	behaviors: [beh.OnScrollLoadmorable, beh.ScrollableY],
	onRender: function () {
		this.triggerMethod('load');
		//this.on('all', console.log);
	},
	//collectionEvents: {
	//	'all': console.log
	//}
});



var Layout = MneView.extend({
	//template: '#tmpl-ui-controls-commentBoardList-layout',
	template,
	regions: {
		'comments':'.comments-region'
	},
	ui: {
		'add': 'button.add',
		'commentText':'textarea[name=text]',
	},
	triggers: {
		'click @ui.add':'add:comment'
	},
	onAddComment: function () {
		if (!this.comments) return;
		var text = this.ui.commentText.val() || '';
		if (text.length == 0) return;
		//var model = new commentsNs.Model
		var entityName = this.getOption('entity');
		var entityIdValue = this.getOption('entityId');
		var entityIdKey = entityName + 'Id';
		var json = { text: text };
		json[entityIdKey] = entityIdValue;
		this.comments.create(json, { wait: true });
		this.ui.commentText.val('');
	},
	onRender: function () {
		var view = this;
		//console.log('ON RENDER', col);
		var col = this.comments = commentsNs.createCollection(this.getOption('entity'), this.getOption('entityId'));
		if (col) {
			//col.loudFetch();
			var comments = new CommentsView({collection:col});
			view.showChildView('comments', comments);
		}
	},
});


export default Layout;
