﻿//define('m-abstract-actionable', ['_libs/identity'], function (identity) {});

import identity from '_libs/identity';

import { BbeModel } from 'core/bbe';


function normalizeActionMethodName(action) {
	action || (action = '');
	return 'action' + (action.substring(0, 1).toUpperCase() + action.substring(1));
}

var ActionableModel = BbeModel.extend({
	initialize: function () {
		var model = this;
		this.on('change', function () {
			delete model._preparedActions;
		});
	},
	methodExists: function (name) {
		var normalizedName = normalizeActionMethodName(name);
		return typeof this[normalizedName] === 'function';
	},
	executeAction: function (opts) {
		
		var action = opts.action;
		var acts = this.getActions({hidden:true});
		var exaction = _(acts).findWhere({ type: action });
		var method = exaction && normalizeActionMethodName(action);
		if (!exaction || typeof this[method] !== 'function') {
			var prom = $.Deferred();
			prom.reject();
			if (!exaction)
				console.warn('action ' + action + ' on model not found');
			else
				console.warn('method ' + method + ' on model not found');
			return prom;
		}
		return this[method](opts);
	},
	getActions: function (opts) {
		opts || (opts = {});
		var model = this;
		var filtered;

		if (!model._preparedActions) {
			model._preparedActions = this._getProtoActions().filter(function (action, index) {
				var res = identity.rights.checkModuleRights(action.rights || {});
				//console.log(res, action, index);
				if(!res)
					return res;

				if (typeof action.rule == 'function')
					res = action.rule.call(model, action, 1);

				if (!res) return res;
				
				if (opts.filter) {
					filtered || (filtered = []);
					if (opts.filter(action))
						filtered.push(action);
				}

				return !!res;
			});
		}

		var rdy;
		if (opts.filter == null)
			rdy = model._preparedActions;
		else if (filtered)
			rdy = filtered;
		else
			rdy = _.filter(model._preparedActions, function (act) { return opts.filter(act); });

		if (opts.hidden)
			return rdy;
		else {
			var notHidden = _(rdy).filter(function (m) { return m.hidden != true; });
			return notHidden;
		}
		
		//return model._preparedActions;
	},
	getAction(name) {
		const acts = this.getActions({hidden:true});
		const action = _.findWhere(acts, { type: name });
		return action;
	},
	_getProtoActions: function () {
		var res = [];
		if (this.attributes)
			res = res.concat(this.get('actions') || []);

		var proto = this.__proto__;
		//if (proto.hasOwnProperty('actions'))
		if (Object.prototype.hasOwnProperty.call(proto, 'actions'))
			res = res.concat(_.result(proto, 'actions'));

		if (proto._getProtoActions)
			return (res || []).concat(_.result(proto, '_getProtoActions'));
		else
			return res;

	},
});

export default ActionableModel;
