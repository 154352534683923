module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="board-container">\n	<div class="board-list-region info">\n		<div>\n			<!--\n			<div class="block">\n				<header class="small bg-primary">действия</header>\n				<ul>\n					<li class="list-btn"><span>запомнить</span></li>\n					<li class="list-btn"><span>добавить к сделке</span></li>\n				</ul>\n			</div>\n			-->\n			<div class="block">\n				<header class="small bg-default">здание</header>\n				<ul>\n					<li class="list-btn building-button"><small>'+
((__t=(building.prefix))==null?'':__t)+
'</small><span>'+
((__t=(building.name))==null?'':__t)+
' '+
((__t=(building.cls))==null?'':__t)+
'</span></li>\n					<li class="list-lbl"><span>'+
((__t=(building.fullAddress))==null?'':__t)+
'</span></li>\n					<li class="list-lbl"><small>метро</small><span>'+
((__t=(building.metroName))==null?'':__t)+
'</span></li>\n				</ul>\n			</div>\n\n			<div class="owner-region"></div>\n\n\n			<div class="block offset-t">\n				<ul>\n					<li class="list-lbl"><small>создана</small><span>'+
((__t=(created))==null?'':__t)+
'</span></li>\n					<li class="list-lbl"><small>изменена</small><span>'+
((__t=(modified))==null?'':__t)+
'</span></li>\n				</ul>\n			</div>\n\n		</div>\n	</div>\n	<div class="board-list-region info">\n		<div>\n			<div class="block">\n				<header class="small bg-default">характеристики</header>\n				<ul>\n					<li class="list-lbl"><small>возможные назначения</small><span>'+
((__t=(info.purposes))==null?'':__t)+
'</span></li>\n					<li class="list-lbl"><small>текущее назначение</small><span>'+
((__t=(info.currentPurpose))==null?'':__t)+
'</span></li>\n					<li class="list-lbl"><small>площадь на реализцию</small><span>'+
((__t=(info.squareOffer))==null?'':__t)+
'</span></li>\n					<li class="list-lbl"><small>планировка</small><span>'+
((__t=(info.spaceLayout))==null?'':__t)+
'</span></li>\n					<li class="list-lbl"><small>состояние</small><span>'+
((__t=(info.state))==null?'':__t)+
'</span></li>\n					<li class="list-lbl"><small>этаж</small><span>'+
((__t=(info.floor))==null?'':__t)+
'</span></li>\n					<li class="list-lbl"><small>занимает этажей</small><span>'+
((__t=(info.floorsCount))==null?'':__t)+
'</span></li>\n					<li class="list-lbl"><small>опции</small><span>'+
((__t=(info.features))==null?'':__t)+
'</span></li>\n					<li class="list-lbl"><small>вся площадь</small><span>'+
((__t=(info.squareTotal))==null?'':__t)+
'</span></li>\n				</ul>\n			</div>\n		</div>\n	</div>\n	<div class="board-list-region comments"></div>\n\n</div>\n';
}
return __p;
};
