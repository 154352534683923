import { _ } from 'vendors';
import cfg from 'app-config';
import { BbeModel } from 'core/bbe';
import { BbeCollection } from 'core/bbe';

const Model = BbeModel.extend({
	setNewModuleClaims(moduleId, value) {

		if (!this.newClaims) {
			this.newClaims = {};
		}

		if (value == null || value === '') {
			value = 'none';
		}

		this.newClaims[moduleId] = value;

		this.trigger('new:claims:value', this);
		console.log('-new values-', this.hasClaimsChanges(moduleId), value);
	},

	hasClaimsChanges(moduleId) {
		let newClaims = this.newClaims;
		if (!newClaims || !_.size(newClaims)) { return false; }

		let currentClaims = this.get('claims');

		if (moduleId) {
			if (newClaims[moduleId]) {
				return !currentClaims || (currentClaims[moduleId] !== newClaims[moduleId]);
			} else {
				return false;
			}
		}

		if (!currentClaims || !_.size(currentClaims)) { return true }

		return _.some(newClaims, (newValue, _moduleId) => currentClaims[_moduleId] !== newValue);
	}
});

const departmentsApiUrl = () => cfg.urls.apiV('departments');
const rightsGroupsApiUrl = () => cfg.urls.apiV('admin/groups');

export const DepartmentModel = Model.extend({
	urlRoot: departmentsApiUrl,
});


export const DepartmentsCollection = BbeCollection.extend({
	url: departmentsApiUrl,
	model: DepartmentModel
});


export const RightsGroupModel = Model.extend({
	urlRoot: rightsGroupsApiUrl,
});




export const RightsGroupsCollection = BbeCollection.extend({
	url: rightsGroupsApiUrl,
	model: RightsGroupModel
});

