import { EditLayout } from '../../../entity-list-common';
import cfg from 'app-config';
//import APPBUS from 'bus/app';
// import bus from './properties/bus';

//import Properties from './properties';
import tabs from './tabs';
import objectSchemaCollection from '../../object-schema';
import ObjectModel from '../../object-model';
//import NavTabsView from './nav-tabs';
//import './edit.less';

const postfix = (arg, add) => {
    if (arg == null) return arg;
    return arg + add;
}

const getSvcIdString = () => (cfg.service || {}).idString;

const Layout = EditLayout.extend({
    className: 'entity-edit-page object-edit-page',

    tabs,
    //bus,
		tabModelDefaults: {
			claims: {
				svcActualizationModule: ['view']
			}
		},
    buildModels() {
        this.model = new ObjectModel({ id: this.getOption('objectId') });
        this.collection = objectSchemaCollection;
    },

    getModelHeader() {
        return 'помещение - ' + this.model.getName();
    },
    getHeaderLinks() {
        let svcIdString = getSvcIdString();
        let urlBuilder = (listType, id) => `/s/${svcIdString}/actualization/${listType}-list/${id}/edit`;
        let realtyName = this.model.get('realty').name;
        let ownerName = this.model.get('owner').name;
        return [
            {
                icon: 'realty',
                name: realtyName,
                url: urlBuilder('realties', this.model.get('realty').id)
            },
            {
                icon: 'owner',
                name: ownerName,
                url: urlBuilder('owners', this.model.get('owner').id)
            },
        ]
    }
});

export default Layout;

