﻿import { $ } from 'vendors';
import { unFlatObject } from 'utils/object-manipulations';

$(function () {

	var defaults = {
		hierarchy: true,
		onKeyExist:'toString', //'toArray' 
	};


	function getKeyOptions(key, opts) {
		
		let options = _.extend({},opts);
		options.keys = options.keys || {};
		var keyoptions = _.extend(defaults, options, options.keys[key] || {});
		return keyoptions;
	}

	//TODO: implement convert logic
	function convertValue(value, name, options, result) {

		return value;

	}


	function addValue(result, name, value, options, $context) {
		var keyOptions = getKeyOptions(name, options);
		var behavior = keyOptions.onKeyExist;
		var convertedValue = convertValue(value, name, options, result);

		var storedValue = getValue(result, name, options);

		if (storedValue instanceof Array)
			storedValue.push(convertedValue);
		else
			switch (behavior) {
				case 'toString':
					result[name] += ', ' + convertedValue.toString();
					break;
				case 'toArray':
					if (!(result[name] instanceof Array)) result[name] = [result[name]];
					result[name].push(convertedValue);
					break;
			}

	}

	function getValue(result,name,options){
		var keyOptions = getKeyOptions(name, options);
		if (typeof result.getValueByPath === 'function' && keyOptions.hierarchy)
			return result.getValueByPath(name);
		else
			return result[name];
	}

	function setValue(result, name, value, options, $context)
	{
		var keyOptions = getKeyOptions(name, options);

		var convertedValue = convertValue(value, name, options, result, $context);

		if (typeof result.setValueByPath === 'function' && keyOptions.hierarchy)
			result.setValueByPath(name, convertedValue);
		else
			result[name] = convertedValue;
	}
	
	function processObjectValue(value, result, options, $context)
	{
		if (typeof value !== 'object' || !value.name) return;

		if (!(value.name in result))
			setValue(result, value.name, value.value, options, $context);
		else
			addValue(result, value.name, value.value, options, $context);
	}

	console.error(' SERIALIZEOBJECT ')
	$.fn.serializeObject = function (options) {

		options || (options = {});
		var $context = this.find('[name]');

		var keyvalues = $context.serializeArray();

		var result = {};
		//console.log($context);
		_(keyvalues).each(function (value) {
			processObjectValue(value, result, options, $context);
		});

		if (!options.unflat) {
			console.error('# not unflat serialize', options);
			return result;
		}
		else {
			console.error('# unflat serialize');
			var unflat = unFlatObject(result);
			return unflat;
		}
	};


});
