import { Model, Collection } from 'vendors';
import { invokeValue, takeFirst, textsHaveIntersections } from 'utils';
import { toAction } from './helper';


function intersectionExist(placement, place) 
{

}

export const ActionModel = Model.extend({

    defaults: {

        placement: undefined,
        glyph: undefined,
        faIcon: undefined,

    },

    getName() {
        let { shortName, name, id } = this.attributes;
        let taked = takeFirst(shortName, name, id);
        return invokeValue(taked, this, this);
    },

    isVisible(place) {
        let placement = this.get('placement');
        if (placement && place) {
            if (!textsHaveIntersections(placement, place)) {
                return false;
            }
        }
        return !this.canNotBeExecuted();
    },

    //isNotVisible() { },

    canNotBeExecuted() { },

    _execute() {
        let action = this.get('action');
        if (action) {
            return action.apply(this, arguments);
        }
    },

    exec() {
        if (this.canNotBeExecuted()) { return; }
        return this._execute.apply(this, arguments);
    }

});

export const ActionsCollection = Collection.extend({
    constructor: function(groupKey) {
        this.groupKey = groupKey;
        Collection.call(this);
    },
    model: ActionModel,
    createHelper() {
        return (...args) => {
            let action = toAction(this.groupKey, ...args);
            return this.add(action);
        }
    },

    find(arg) {
        if (!arg) { return [] };
        let type = typeof arg;

        if (type === 'string') {
            return this.getForPlace(arg);
        } else if (Array.isArray(arg)) {
            return arg.reduce((models, id) => { 
                let action = this.getAction(id);
                if (action) {
                    models.push(action);
                }
                return models;
            }, []);
        }
    },
    getForPlace(place) {
        return this.models.filter(model => model.isVisible(place));
    },
    getAction(id, place) {
        let action = this.get(id);
        if (action.isVisible(place)) {
            return action;
        }
    }
});


export function createGroupStore(groupKey) {
    let store = new ActionsCollection(groupKey);
    return store;
}