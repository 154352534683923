﻿//define('m-contacts-entityContacts', ['m-abstract', 'm-contacts-entityContact', 'app-paths'], function (abstractNs, EntityContact, paths) {});

import EntityContact from './entityContact';
import paths from 'app-paths';

import { BbeModel, BbeCollection } from 'core/bbe';

var ns = {};

function createModel(idField, id, data, opts)
{
	var hash = _.extend({},data);
	hash[idField] = id;
	return new EntityContact(hash, opts);
}

var Collection = BbeCollection.extend({
	model: EntityContact,
	initialize: function (data, opts) {
		opts || (opts = {});
		this.realtyId = opts.realtyId;
		this.idField = opts.idField;
		this[this.idField] = opts[this.idField];
	},
	add: function (data, options) {
		var _this = this;
		if (data instanceof BbeModel)
			return BbeCollection.prototype.add.call(this, data, options);

		if (data instanceof Array) {

			_(data).each(function (d) {
				_this.add(d, options);
			});
			return;
		}

		var model = createModel(this.idField, this[this.idField], data, options);
		return this.add(model, options);
	},
	url: function () {

		if (this.idField == 'clientId')
			return paths.api.clientContacts(this[this.idField]);

		else if (this.idField =='ownerId')
			return paths.api.realtiesOwnerContacts(this[this.idField]);

	},
});

function createCollection(entity, id, models, opts)
{
	var options = _.extend({}, opts);
	options.entity = entity;
	options.idField = entity + "Id";
	options[options.idField] = id;
	return new Collection(models, options);
}

ns.Client = function (id, data, opts) {
	return Entity("client", id, data, opts);
	/*opts || (opts = {});
	if (data == null) return;
	var models = [];
	if (typeof data == 'object' && !(data instanceof Array))
	{
		return clientModel(id,data);
	}
	else if (data instanceof Array) {
		models = _(data).map(function (item) {
			return clientModel(id, item);
		});
		if (opts.asCollection) {
			var col = new BbeCollection(models);
			return col;
		} else {
			return models;
		}
	}*/
}
ns.Owner = function (id, data, opts)
{
	return Entity("owner", id, data, opts);
}

var factory = function (entity, id, data, opts) {
	return createModel(entity + "Id", id, data, opts);
}

var Entity = function (entity, id, data, opts) {
	opts || (opts = {});
	if (data == null) return;
	var models = [];


	if (typeof data == 'object' && !(data instanceof Array)) {
		// TODO: check this case, there was undefined `type` instead of `entity`
		return factory(entity, id, data, opts);
	}
	else if (data instanceof Array) {
		models = _(data).map(function (item) {
			return factory(entity, id, item, opts);
		});
		if (opts.asCollection) {
			//var col = new BbeCollection(models);
			//return col;
			return createCollection(entity, id, models, opts);
		} else {
			return models;
		}
	}
}


export default ns;

