/*
const keyListeners = [];

function removeKeyListener(handler) {

    let $doc = $(document);
    $doc.off('keyup', handler);
    let index = keyListeners.indexOf(handler);
    if (index == -1) return;
    keyListeners.splice(index, 1);

}

function addKeyListener(handler) {
    keyListeners.push(handler);
    let $doc = $(document);
    $doc.on('keyup', handler);
}
function isLastAddedListener(handler) {
    return _.last(keyListeners) == handler;
}
*/

export default {
    handlers: [],
    getDoc() {
        if (!this.$doc)  {
            this.$doc = $(document);
        }
        return this.$doc;
    },
    addHandler(handler){
        this.handlers.push(handler);
        this.getDoc().on('keyup', handler);
    },
    removeHandler(handler){
        this.getDoc().off('keyup', handler);
        let index = this.handlers.indexOf(handler);
        if (index == -1) return;
        this.handlers.splice(index, 1);
    },
    isLastHandler(handler){
        return _.last(this.handlers) == handler;
    }
}