﻿//define('m-departments-rights', ['app-paths', 'm-principalRights'], function(paths, PrincipalRights) {});

import paths from 'app-paths';
import PrincipalRights from 'm/principalRights';

var Collection = PrincipalRights.extend({
    url: function() {
        return paths.api.departmentRights(this.departmentId);
    }
});

Collection.create = function(dId) {
    var m = new Collection();
    m.departmentId = dId;
    return m;
};

export default Collection;