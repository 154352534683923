//import Properties from './_properties';
import Properties from '../../../entity-list-common/edit/properties';
import { MediaLayout } from '../../../entity-list-common/edit/medias';
import { ObjectsLayout } from '../../../entity-list-common/edit/nested-objects';
import { OwnersInRealtiesLayout } from '../../../entity-list-common/edit/owners-in-realties';
//import Medias from './medias';
//import RealtyObjects from './realty-objects';
import AddressView from './address';
import AgencyView from './agency';
import { AdditionalLayout } from './additional';

const ignoreGroups = ['Address', 'Actualize'];

const tabs = [
    // {
    //     id:'main',
    //     label: 'Основные х-ки',
    //     view: Properties,
    //     viewOptions: () => ({
    //         singleGroup: true,
    //         filters: {
    //             prop: prop => prop.get('parentId') === 'root',
    //             group: group => group.id === 'root'
    //         }
    //     }),
    // },
    {
			id:'main',
			label: 'Все х-ки',
			view: Properties,
			viewOptions: () => ({
				filters: {
					group: group => ignoreGroups.indexOf(group.id) < 0, // !== 'Actualize'
				}
			})
    },
    {
			id:'actualize',
			label: 'Статус актуализации',
			view: Properties,
			viewOptions: () => ({
				singleGroup: true,
				filters: {
					prop: prop => prop.get('parentId') === 'Actualize',
					group: group => group.id === 'Actualize'
				}
			}),
    },    
    {
			id: 'addr',
			label: 'Адрес',
			view: AddressView
    },
    {
			id:'media',
			label: 'Фотографии и файлы',
			view: MediaLayout,
			claims: [
				{
					svcActualizationModule: 'view', //'editRealtiesFast'
				},
				{
					svcActualizationModule: 'editRealtiesFast'
				},
			],
			viewOptions() {
				return {
					modelType: 'realty'
				}
			}
        //view: Properties,
    },
		{
			id: 'owners',
			label: 'Собственники',
			view: OwnersInRealtiesLayout,
			viewOptions: {
				modelType: 'realty'
			}
		},
    {
        id:'objects',
        label: 'Площади',
        view: ObjectsLayout,
        viewOptions: {
            modelType: 'realty'
        }
    },
    {
        id: 'agency',
        label: 'realty-guide',
        view: AgencyView
    },
    {
			id:'additional',
			label: 'доп. материалы',
			view: AdditionalLayout,
			claims: [
				{
					svcAdvancedRealties: 'view', //'editRealtiesFast'
				},
				{
					svcActualizationModule: 'editRealtiesFast'
				},
			],
			// viewOptions() {
			// 	return {
			// 		modelType: 'realty'
			// 	}
			// }
    },		
    // {
    //     id:'adm',
    //     label: 'в синюю',
    //     newTab: true,
    //     href: model => 'http://adm.nrg.plus/rlt/buildings/' + model.id
    // },
]

// const TabModel = BbeModel.extend({

//     invoke(key, ...args) {
//         let val = this.get(key);
//         if (typeof val === 'function') {
//             if (val === BbView || val.prototype instanceof BbView) {
//                 return val;
//             }
//             val = val(...args);
//         }
//         return val;
//     }

// });

// const Tabs = BbeCollection.extend({

//     model: TabModel,

//     getCurrentView(options) {

//         let hash = (document.location.hash || '#main').substring(1);
//         let model = this.get(hash);
//         if (!model) return;


//         let View = model.invoke('view', options);
//         if (!View) return;

//         options = Object.assign({}, model.invoke('viewOptions', options), options);

//         return new View(options);

//     }
// });


//const tabsCol = new Tabs(tabs);


export default tabs;