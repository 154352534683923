﻿//define('m-clients', ['bus','m-contacts', 'm-clients-client', 'm-clients-clientsSearch', 'm-clients-clients'],function(Bus, contactsNs,  Client, ClientsSearch, Clients){});


import Bus from 'bus';
import ClientModel from './client';
import ClientsSearchCollection from './clientsSearch';
import ClientsCollection from './clients';

var ns = {
	ClientModel,
	ClientsSearchCollection,
	ClientsCollection,
};

export {
	ClientModel,
	ClientsSearchCollection,
	ClientsCollection,
}


Bus.Models.reply('clients', ns);

export default ns;
