// import { MnView } from 'vendors';
// import { invokeValue } from 'utils/invoke';
// import { isNotFalsyValue } from 'utils/is';

import { _ } from 'vendors';
import stringTemplateCache from './string-template-cache';
import { optionsMixin } from './mixins';
import { htmlAttributesMixin } from './mixins/htmlAttributesMixin';
import { modelDisplaySchemaMixin } from './model-display-schema';
export const viewCommonMixin = {
    
    ...optionsMixin,

    ...htmlAttributesMixin,

		...modelDisplaySchemaMixin,

    getTypedOption(key, type, defaultValue) {
        let value = this.getOption(key, true);
        return typeof value === type ? value : defaultValue
    },

    // getOption(key, shouldInvoke) {
    //     let value = MnView.prototype.getOption.apply(this, arguments);
    //     if (typeof value !== 'function' || !shouldInvoke) { return value; }
    //     return invokeValue(value, this, this);
    // },

    // hasOption(key, options = {}) {
    //     let strict, notFalsy;
    //     if (options === true || options === false) {
    //         strict = options;
    //         options = {};
    //     }
    //     notFalsy = options.notFalsy;


    //     if (this.options[key] !== undefined) {
    //         return notFalsy ? isNotFalsyValue(this.options[key], '', 0) : true;
    //     }

    //     if (strict) { return false; }

    //     let value = this[key];
    //     return notFalsy 
    //         ? isNotFalsyValue(value, '', 0) 
    //         : value !== undefined;
    // },
		_getTemplate() {
			if (this._template !== undefined) {
				return this._template;
			}

			let tmpl = this.getOption('template', false);
			if (tmpl == null || tmpl === false) {
				this._template = false;
				return this._template;
			}

			let tmplType = typeof tmpl;
			if (tmplType === 'string') {

					let cached = stringTemplateCache.get(tmpl);
					if (cached) {
							this._template = cached;
							return cached;
					}

					this._template = _.template(tmpl);
					stringTemplateCache.set(tmpl, this._template);

					return this._template;
			}

			if (tmplType === 'function') {
					this._template = tmpl;
					return this._template;
			}

			this._template = null;
		},

		getTemplate() {
			return this._getTemplate();
    },



}