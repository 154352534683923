//import { Model } from 'core';
import { BaseModel } from './BaseModel';
import { MapModel } from './common';
import { getMarkerElement, createMarkerElement, drawDebugMarker } from './marker-stuff';
export const MapPointModel = BaseModel.extend({
	markerIdPrefix: 'map-point-',
	markerZIndex: 5,
	thisClassName: 'point round round-32',
	initialize() {
		//console.log('-ini-', this.mapStateData.entryRealtyId);
		if (this.isEntry()) {
			this.state('entry', true);
		}
	},
	offersCount() {
		return this.get('off') || 0;
	},
	hasOffers() {
		return this.offersCount() > 0;
	},
	toggleRealty(selected) {
		this.state('selected', selected);
		// const el = this.getMarkerElement();
		// if (!el) { return; }
		// if (selected) {
		// 	el.classList.add('selected');
		// } else {
		// 	el.classList.remove('selected');
		// }
	},
	// getUpdateMarkerOptions() {
	// 	const offersCount = this.offersCount();
	// 	const contentHtml = (offersCount || 0);
	// 	return {
	// 		offersCount,
	// 		classes: this.state(),
	// 		contentHtml
	// 	}
	// },
	getCoordinates () {
		return this.get('coo');
	},
	isEntry() {
		const res = this.mapStateData.entryRealtyId === this.id;
		return res;
	},
	isMarkerVisible(options = {}) {
		const offers = this.hasOffers();
		const isentry = this.isEntry();
		return offers || isentry;
	},
	// getBuildMarkerOptions() {
	// 	// const { centerLng, centerLat, topLeftLat, topLeftLng, width, height } = this.attributes;
	// 	// let style = `width:${width}px;height:${height}px;`;
	// 	let markerOptions = {
	// 		coordinates: this.attributes.coo,
	// 		onClick: () => {
	// 			//drawDebugMarker(this.map, this.get('coo'));
	// 			this.trigger('click:point', this, this.marker);
	// 		},
	// 	}

	// 	return markerOptions;
	// },
	selectedForActions(selectedForActions) {
		this.set('selectedForActions', selectedForActions);
		this.state('selected-for-actions', selectedForActions);
	},
	actualizeMarker() {
		//const coords = this.getCoordinates();
		const selected = this.mapStateData.isPointSelected(this);
		// console.log('[a:p]', selected, this.state('hovered'));
		if (selected)
			console.log(selected, this.id);
		this.state({
			hovered: false,
			exist: this.mapStateData.isJoined(this.id),
			entry: this.isEntry(),
			selected,
			'selected-for-actions': this.get('selectedForActions')
		});
	},
	onMouseOver() {		
		this.cluster?.marker?.element.classList.add('hovered');
	},
	onMouseLeave() {
		this.cluster?.marker?.element.classList.remove('hovered');
	},
	onMarkerClick: (m,e) => m.trigger('click:point', e, { point: m }),

	updateMarkerContent(marker) {
		marker.contentEl.innerHTML = this.offersCount();
	}
});



const deprecated = {
	updateMarker() {
		const offersCount = this.offersCount();
		const marker = this._getMarker(offersCount > 0);
		if (!offersCount) {
			this._hideMarker(marker);
		} else {
			marker.element.innerHTML = `<span>${(offersCount || 0)}</span>`;
			this._showMarker(marker);
		}
	},
	_getMarker(createIfNotExist) {
		if (!this.marker && createIfNotExist) {
			this.marker = this._buildMarker();
		}
		return this.marker;
	},
	getMarkerElement() {
		const m = this._getMarker();
		return m?.element;
	},
	_buildMarker() {
		const els = createMarkerElement('point round round-32');
		els.content.innerHTML = this.offersCount();
		var marker = new ymaps3.YMapMarker(
			{
					id: this.id,
					source: 'marker-source',
					coordinates: this.attributes.coo,
					zIndex: 2,
					onClick: (e) => {
						console.error('-e-', e);
						drawDebugMarker(this.map, this.get('coo'));
						this.trigger('click:point', this, marker)
					},
					// {

					// 	console.log('point', marker);
					// 	console.log('point model', this);
					// }
			},
			els.el
		);
		marker.contentEl = els.content;
		return marker;
	},

	showMarker() {
		const marker = this._getMarker(true);
		this._showMarker(marker);
	},
	_showMarker(marker) {
		if (marker && marker.root !== this.map) {
			this.map.addChild(marker);
		}
	},
	hideMarker() {
		const marker = this._getMarker();
		this._hideMarker(marker);
	},
	_hideMarker(marker) {
		if (marker && marker.root === this.map) {
			this.map.removeChild(marker);
		}
	},

}