﻿//define('svc-tasks-create', ['app-config', 'store-service', 'appModule', 'link', '_libs/identity', 'svc-tasks-create-layout', 'm-tasks', 'm-deals', 'm-clients', 'm-factory'], function (cfg, store, modules, link, identity, Layout, tasksNs, dealsNs, clientsNs, factory) {});

//import cfg from 'app-config';
//import store from '';
import modules from 'appModule';
import link from 'link';
//import identity from '';
import Layout from './layout';
import tasksNs from 'm/tasks';
//import dealsNs from '';
//import clientsNs from '';
import factory from 'm/factory';


function extendWithDeal(model, dealId)
{
	if (!dealId) return;
	//var deal = store.get(store.KEYS.currentDeal + ":" + dealId);
	//if (deal) {
	//	model.set({
	//		dealId: deal.id,
	//	});
	//	return;
	//} else {
	//}
	//var deal = new dealsNs.DealModel({ id: dealId });
	var deal = factory.init('Deal', dealId);
		return deal.loudFetch().then(function () {
			model.set({
				dealId: deal.get('id'),
			});
		});
}
function extendWithClient(model, clientId) {
	if (!clientId) return;
	//var deal = store.get(store.KEYS.currentDeal + ":" + dealId);
	//if (deal) {
	//	model.set({
	//		dealId: deal.id,
	//	});
	//	return;
	//} else {
	//}
	//var client = new clientsNs.ClientModel({ id: clientId });
	var client = factory.init('Client', clientId);
	return client.loudFetch().then(function () {
		model.set({
			clientId: client.get('id'),
		});
	});
}


var module = modules.content({
	auth: true,
	needSvc: true,
	name: 'tasks-create',
	label: 'Постановка задачи',
	routeData: () => link.route('tasksCreate'),
	urlKey: 'tasksCreate',
	onBeforeRun: function () {
		var model = new tasksNs.TaskModel();

		var dpromise = extendWithDeal(model, this.query.dealId)
		if (dpromise)
			this.addRunPromise(dpromise);

		var cpromise = extendWithClient(model, this.query.clientId)
		if (cpromise)
			this.addRunPromise(cpromise);

		this.addRunArgument(model);
	},
	onRun: function (model) {
		var module = this;



		//var result = 
		module.createAndShow(Layout, this.label, { model: model, }, { navLinks: this.navLinks() });

	}
});


export default module;
