import BehaviorDynamicClass from 'behaviors/dynamicClass';
import beh from 'behaviors';
import bus from 'bus';
import deepClone from '_helpers/deepClone';

import SchemaEdit from 'ui/controls/schemaEdit';

//import helpers from '../helpers';
import refs from 'references';
import helpers from '../../../helpers';

import { price as _getPrice } from '_helpers/offer-price';
import ActionsButton from '../actions-button';
// import { editObjectInModalAsync } from './edit-object';

// import { flexModal, flexConfirm } from '_libs/flex-modals';
// import { getChanges } from '_helpers/getChanges';


// import { View, NextCollectionView } from 'base/views';

import { OffersView } from './offer-in-object';
import { MneView } from 'core/mne';
import { _ } from 'vendors';
import { View } from 'core';

//import consts from 'vXX/app/constants';
//const { NDS_KOEF } = consts;


function splitComaString(arg)
{
    if (arg) {
        return arg.split(/\s*,\s*/gmi);
    } else {
        return [];
    }
}

const log =(arg, msg, ...rest) => {
    console.log(msg, arg, ...rest);
    return arg;
}








// export default View.extend({
// 	baseClassName: 'realty-object-list-item',
// 	classNames: [
// 		v => v.model.get('status'),

// 	],
// });

export default View.extend({
    baseClassName: 'realty-object-list-item yogogo',
		stateClassNames: ['accent'],

		classNames: [
			v => v.model.get('status'),
			v => v.getVisibilityCssClasses(),
			v => v.getActualizeCssClasses(),
			v => v.getAvailabilityCssClasses(),
			v => v.model.hasNoOffers() ? 'no-offers' : '',
			v => v.model.hasNoActiveOffers() ? 'no-active-offers' : '',
			v => v.model.isSquareOk() ? '' : 'zero-square',
		],
		getVisibilityCssClasses() {
			let statuses = splitComaString(this.model.get('visibleFor'));
			const words = statuses.map(s => 'v-'+s);
			if (statuses.indexOf('public') === -1 || statuses.indexOf('services') === -1) {
					words.push('visibility-missing');
			}
			return words.join(' ');
		},
		getActualizeCssClasses() {
			const actualize = this.model.get('actualize');
			let astatuses = splitComaString(actualize?.dataStatus); //.split(/\s*,\s*/gmi);
			const words = astatuses.map(s => 'a-'+s);
			return words.join(' ');
		},
		getAvailabilityCssClasses() {
			let advOk = this.model.isAvailable('public');
			let brkOk = this.model.isAvailable('services');
			const h = {}
			if (advOk && brkOk) {
					h['completely-available'] = true;
			} else if (advOk || brkOk) {
					h['partial-available'] = true;
					let _tkey = advOk ? 'public' : 'services';
					h[_tkey + '-available'] = true;
			}
			return Object.keys(h).join(' ');
		},


//     behaviors: [BehaviorDynamicClass, beh.Clickable],
//     dynamicClass() {
//         let h = {};
//         let m = this.model;
//         let a = m.attributes;

//         h[a.status] = true;

//         let statuses = splitComaString(this.model.get('visibleFor'));
				
//         if (a.visibleFor) {
//             statuses.forEach(s => h['v-' + s] = true);
//         }
//         if (statuses.indexOf('public') == -1 || statuses.indexOf('services') == -1) {
//             h['visibility-missing'] = true;
//         }
// 				/// ^^^^^^^^^^^^^^^^
//         if (a.actualize && a.actualize.dataStatus) {
//             let astatuses = splitComaString(a.actualize.dataStatus); //.split(/\s*,\s*/gmi);
//             astatuses.forEach(s => h['a-' + s] = true);
//         }
// /// ^^^^^^^^^^^^^^^^
//         if (this.model.hasNoOffers()) {
//             h['no-offers'] = true;
//         }
// /// ^^^^^^^^^^^^^^^^
//         if (this.model.hasNoActiveOffers()) {
//             h['no-active-offers'] = true;
//         }
// /// ^^^^^^^^^^^^^^^^

//         if (!this.model.isSquareOk()) {
//             h['zero-square'] = true;
//         }
// /// ^^^^^^^^^^^^^^^^
//         let advOk = this.model.isAvailable('public');
//         let brkOk = this.model.isAvailable('services');
//         if (advOk && brkOk) {
//             h['completely-available'] = true;
//         } else if (advOk || brkOk) {
//             h['partial-available'] = true;
//             let _tkey = advOk ? 'public' : 'services';
//             h[_tkey + '-available'] = true;
//         }


//         return Object.keys(h).join(' ');
//     },

    

    // getVisibleStatuses() {
    //     let res = this.model.get('visibleFor');
    //     if (res) {
    //         return res.split(/\s*,\s*/gmi);
    //     } else {
    //         return [];
    //     }
    // },

    // getRawOffers() {
    //     return this.model.get('offers') || [];
    // },

    // hasNoOffers() {
    //     return this.getRawOffers().length == 0;
    // },
    // hasActiveOffers() {
    //     return this.getRawOffers().some(o => o.status == 'active');
    // },
    // hasNoActiveOffers() {
    //     return !this.hasActiveOffers();
    // },

  events: {
		'click .index'(event) {
			console.log(' - attrs -', this.model.attributes);
			console.log(' - model -', this.model);
		},
		'click .square-info'(event) {
			// let url = helpers.getEditUrl('object',this.model.id);
			// bus.openSmart(url, event);
			this._openPage(event, 'object');
		},
		'click .owner'(event) {
			// let owner = this.getRawOwner();
			// let url = helpers.getEditUrl('owner', owner.id);
			// bus.openSmart(url, event);
			this._openPage(event, 'owner');
		},
		'click .realty'(event) {
			// let realty = this.getRawRealty();
			// let url = helpers.getEditUrl('realty', realty.id);    
			// bus.openSmart(url, event);
			this._openPage(event, 'realty');
		}
	},

    // clickTriggers: {
    //     '.index'(event) {
    //         console.log(' - attrs -', this.model.attributes);
    //         console.log(' - model -', this.model);
    //     },
    //     '.square-info'(event) {
    //         // let url = helpers.getEditUrl('object',this.model.id);
    //         // bus.openSmart(url, event);
    //         this._openPage(event, 'object');
    //     },
    //     '.owner'(event) {
    //         // let owner = this.getRawOwner();
    //         // let url = helpers.getEditUrl('owner', owner.id);
    //         // bus.openSmart(url, event);
    //         this._openPage(event, 'owner');
    //     },
    //     '.realty'(event) {
    //         // let realty = this.getRawRealty();
    //         // let url = helpers.getEditUrl('realty', realty.id);    
    //         // bus.openSmart(url, event);
    //         this._openPage(event, 'realty');
    //     }
    // },
    _getEntityId(entity) {
        switch(entity) {
            case 'object':
                return this.model.id;
            case 'owner':
                return this.getRawOwner().id;
            case 'realty':
                return this.getRawRealty().id;
        }
    },
    _openPage(event, entity) {
        let id = this._getEntityId(entity);
        let url = helpers.getEditUrl(entity, id);
        bus.openSmart(url, event);
    },
    template: _.template(`<div class="wrapper">
<div class="flex-row">
    <div class="options fixed btn-group object-options"></div>
    <div class="broker-visible fixed as-ui-atom <%= isBrokerVisible %>" title="<%= brokerTitle %>">
        <i><%= ownBrokerVisible %></i>
        <div class="is-visible"><i></i></div>
        <b>брок.</b>
    </div>
    <div class="advert-visible fixed as-ui-atom <%= isAdvertVisible %>" title="<%= advertTitle %>">
        <i><%= ownAdvertVisible %></i>
        <div class="is-visible"><i></i></div>
        <b>рек.</b>
    </div>
    <div class="square-info as-ui-atom clickable" title="редактировать помещение">
        <i><%= statusDisplay %></i>
        <div><%= name %></div>
        
    </div>
    <div class="purposes as-ui-atom">
        <i></i>
        <div><%= purposesDisplay %></div>
        <b>возможные назначения</b>
    </div>
    <div class="current-purpose as-ui-atom">
        <i></i>
        <div><%= purposeDisplay %></div>
        <b>текущее назначение</b>
    </div>   
    <div class="square-status as-ui-atom">
        <i></i>
        <div><%= stateDisplay %></div>
        <b>состояние</b>
    </div>
    <% if (showOwner) { %>
    <div class="owner as-ui-atom clickable" title="редактировать собственника">
        <i></i>
        <div><i class="fa fa-user"></i> <%= owner.name %></div>    
        <b>собственник</b>
    </div>
    <% } %>
    <% if (showRealty) { %>
    <div class="realty as-ui-atom clickable" title="редактировать здание">
        <i></i>
        <div><i class="fa fa-building-o"></i> <%= realty.name %></div>
        <b>здание</b>
    </div>
    <% } %>
    <div class="index fixed as-ui-atom">
        <i></i>
        <div>#<%= index %></div>
        <b></b>
    </div>
</div>
<div class="offers-container"></div>
</div>
    `),

    templateContext() {
			let owner = this.getRawOwner();
			let realty = this.getRawRealty();
			let editRealtyUrl = helpers.getEditUrl('realty', realty.id);
			let editOwnerUrl = helpers.getEditUrl('owner', owner.id);
			let actualizeUrl = helpers.getOiRUrl(owner.id, realty.id);

			const modelType = this.getOption('modelType');

			let showRealty = modelType != 'realty';
			let showOwner = modelType != 'owner';

			let brokerTitle = this.model._buildAvailabilityTitle('services', 'недоступно брокерам', 'доступно брокерам');
			let advertTitle = this.model._buildAvailabilityTitle('public', 'недоступно в рекламе', 'доступно в рекламе');

			let ownBrokerVisible = this.model.isVisible('services') ? '•' : '';
			let ownAdvertVisible = this.model.isVisible('public') ? '•' : '';

			const { status, info } = this.model.attributes;

			let statusDisplay = refs.enum('realtyObjectStatuses', status); //_enum('realtyObjectStatuses', status)
			let stateDisplay = refs.enum('realtyObjectStates', info.state); // _enum('realtyObjectStates', info.state)
			let purposesDisplay = refs.enum('realtyPurposes', info.purposes); //_enum('realtyPurposes', info.purposes)
			let purposeDisplay = refs.enum('realtyPurposes', info.currentPurpose); //_enum('realtyPurposes', info.currentPurpose)
			return {
				statusDisplay,
				purposesDisplay,
				purposeDisplay,
				stateDisplay,

					isBrokerVisible: this.model.isAvailable('services'),
					isAdvertVisible: this.model.isAvailable('public'),
					name: this.model.getName(),
					modelType: this.getOption('modelType'),
					editUrl: helpers.getEditUrl('object',this.model.id),
					owner,
					realty,
					editRealtyUrl,
					editOwnerUrl,
					actualizeUrl,
					showRealty, showOwner,
					index: this._getIndex(),
					brokerTitle, advertTitle,
					ownBrokerVisible, ownAdvertVisible
			}
		},

    regions: {
        objectOptions: '.object-options.options',
        offers: '.offers-container'
    },

    onRender() {
        this.showOffers();
        this.showObjectActionsButton();
    },
    
    showOffers() {
        let view = new OffersView({ model: this.model });
        this.showChildView('offers', view);
    },

    _getActions() {
        let rawActions = {
            'edit:object': 'Редактировать основные характеристики',
            'create:offer': 'Добавить предложение'
        }
    
        let rawLinks = {
            'open:object' : 'Открыть страницу помещения',
            'open:realty' : 'Открыть страницу здания',
            'open:owner' : 'Открыть страницу собственника',
        }

        let actions = [];

        _.reduce(rawActions, (memo, text, name) => {
            memo.push({ text, name })
            return memo;
        }, actions);

        actions.push({ separator: true });

        let showRealty = this.getOption('modelType') != 'realty';
        let showOwner = this.getOption('modelType') != 'owner';

        _.reduce(rawLinks, (memo, text, name) => {

            if ((name == 'open:realty' && !showRealty) || (name == 'open:owner' && !showOwner))
                return memo;

            memo.push({ text, name })
            return memo;
        }, actions);

        return actions;
    },

    onTakedAction() {
        console.log(' - taked action - ', arguments);
    },
    onActionCreateOffer() {
        this.model.addOffer();
    },
    onActionEditObject() {
        this.model.editMain();
        // editObjectInModalAsync(this.model.id).then(
        //     () => {
        //         flexConfirm('проверка связи', 'попытка номер два?', {
        //             modalResolveAction: v => {
        //                 return new Promise((res, rej) => {
        //                     setTimeout(() => res(100), 5000)
        //                 });
        //             }
        //         })
        //             .then(() => console.log('hura!'))
        //             .catch(() => console.log('провал'));
        //         // flexModal([
        //         //     new MnView({ template: _.template(' - 1 - ') }),
        //         //     new MnView({ template: _.template(' - 2 - ') }),
        //         //     new MnView({ template: _.template(' - 3 - '), elastic: true }),
        //         //     new MnView({ template: _.template(' - 4 - ') }),
        //         // ], { header: 'какойто модаль' });
        //     },
        //     () => {}
        // );
    },
    onActionOpenObject(view, event) {
        return this._openPage(event, 'object');
    },
    onActionOpenRealty(view, event) {
        return this._openPage(event, 'realty');
    },
    onActionOpenOwner(view, event) {
        return this._openPage(event, 'owner');
    },

    showObjectActionsButton() {

        let actions = this._getActions();
        let view = new ActionsButton({
            className: 'options fixed object-options',
            actions, 
            onActionClicked: (action, ...args) => {
                this.triggerMethod('taked:action', action, ...args);
                this.triggerMethod('action:' + action, ...args);
            }
        });
        this.showChildView('objectOptions', view, { replaceElement: true });

    },

    getModelType() {
        return this.getOption('modelType');
    },
    getParentModel() {
        return this.getOption('parentModel');
    },
    getRawOwner() {
        let type = this.getModelType();
        if (type == 'owner') {
            let parent = this.getParentModel();
            return parent.attributes;
        }
        return this.model.attributes.owner || {};
    },
    getRawRealty() {
        let type = this.getModelType();
        if (type == 'realty') {
            let parent = this.getParentModel();
            return parent.attributes;
        }
        return this.model.attributes.realty || {};
    },
    
    

    _getIndex() {
        if ('_filterIndex' in this) {
            return this._filterIndex;
        } else {
            return this.model.collection.indexOf(this.model) + 1;
        }
    },


    modelEvents: {
        'refresh':'render',
				'apply:accent':'applyAccent'
    },
		applyAccent() {
			this.el.scrollIntoView();
			this.state('accent', true);
			const dismiss = () => this.state('accent', false);
			this.delegate('mouseover', undefined, dismiss);
			setTimeout(() => {
				this.undelegate('mouseover', undefined, dismiss);
				dismiss();
			}, 10000);
		}
});


