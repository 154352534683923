import { HamburgerView } from "coms/ui/HamburgerView";
import { showImageGalery, ImageView } from 'coms/ImageGalery';
import { View } from 'core';

export const CommonImageView = ImageView.extend({
	imageHash: v => v.model.id,
	imageExt: 'jpg',
	imageOptions: 'w270-c-rbc',
	events: {
		click(e) {
			this.trigger('click', this.model);
		}
	},
})

export const CommonThumbView = HamburgerView.extend({
	constructor: function(options) {
		HamburgerView.apply(this, arguments);
		this.mergeOptions(options, ['buildCollection']);
		this.initializeCollection();
	},

	thisClassName: 'realty-box-thumb',

	initializeCollection() {
		if (this.collection || !this.buildCollection) { return; }
		this.collection = this.buildCollection.call(this, this.model.id);
		this.listenToOnce(this.collection, 'sync', () => {
			this.render();
		});
	},

	getThumbView() {

		let thumbnail = this.model.get('thumbnail') || (this.collection.first() || {}).id;
		
		if (!thumbnail) { return }

		if (typeof thumbnail === 'string') {
			return {
				class: CommonImageView,
				thisClassName: 'image',
				wrap: true,
				imageHash: thumbnail,
				triggers: { 'click': 'click' }
			}
		}

		return thumbnail;
	},

	getLabelView() {
		return {
			class: View,
			thisClassName: 'realty-box-thumb-label',
			template: '<%= label %>',
			triggers: { 'click': 'click' },
			collectionEvents: {
				'sync':'render'
			},
			templateContext() {
				let len = this.collection.length;
				let label = 'фотографии отсутствуют';
				if (len) {
					label = `посмотреть фотографии (${len})`;
				}
				return {
					label
				}
			}
		}
	},
	getChildren() {
		let thumbView = this.getThumbView();
		let labelView = this.getLabelView();
		let views = [
			thumbView,
			labelView
		];
		return views;
	},
	childViewEvents() {
		return {
			click() {
				if (this.collection.length) {
					showImageGalery(this.collection);
				}
			}
		}
	}
});