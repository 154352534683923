module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<ul>\n	<li class="list-lbl"><small>материал пола</small><span>'+
((__t=( _enum('warehouseFloors',_m.floorMaterial)))==null?'':__t)+
'</span></li>\n	<li class="list-lbl"><small>особенности складского помещения</small><span>'+
((__t=( _enum('warehouseFeatures',_m.features)))==null?'':__t)+
'</span></li>\n	<li class="list-lbl"><small>отопление</small><span>'+
((__t=( _enum('realtyObjectHeatings',_m.heating)))==null?'':__t)+
'</span></li>\n	<li class="list-lbl"><small>морозильное оборудование</small><span>'+
((__t=( _enum('warehouseFreezeEquipments',_m.freezeEquipment)))==null?'':__t)+
'</span></li>\n	<li class="list-lbl"><small>особенности разгрузки</small><span>'+
((__t=( _enum('warehouseUnloadingFeatures',_m.unloadingFeatures)))==null?'':__t)+
'</span></li>\n	<li class="list-lbl"><small>оснащение</small><span>'+
((__t=( _enum('warehouseUnloadingEquipments',_m.unloadingEquipment)))==null?'':__t)+
'</span></li>\n	<li class="list-lbl halfed"><small>количество ворот</small><span>'+
((__t=( _ndash(_m.gatesCount)))==null?'':__t)+
'</span></li>\n	<li class="list-lbl halfed"><small>количество паллетомест</small><span>'+
((__t=( _ndash(_m.palletPlaces)))==null?'':__t)+
'</span></li>\n	<li class="list-lbl"><small>заметка</small><span>'+
((__t=( _ndash(_m.note)))==null?'':__t)+
'</span></li>\n</ul>';
}
return __p;
};
