import { _ } from 'vendors';
import './new-yandex-map-point.less';

const SVG_MARKER = `<svg>
	<g id="svg-point" transform="translate(0 -1028.4)">
		<path d="m12 0c-4.4183 2.3685e-15 -8 3.5817-8 8 0 1.421 0.3816 2.75 1.0312 3.906 0.1079 0.192 0.221 0.381 0.3438 0.563l6.625 11.531 6.625-11.531c0.102-0.151 0.19-0.311 0.281-0.469l0.063-0.094c0.649-1.156 1.031-2.485 1.031-3.906 0-4.4183-3.582-8-8-8zm0 4c2.209 0 4 1.7909 4 4 0 2.209-1.791 4-4 4-2.2091 0-4-1.791-4-4 0-2.2091 1.7909-4 4-4z" fill="currentColor" transform="translate(0 1028.4)"/>
	</g>
</svg>`;
const sprite = document.createElement('div');
sprite.innerHTML = SVG_MARKER;
sprite.style.display = 'none';
document.body.append(sprite);

export const DEFMARKER = `<svg viewBox="0 0 60 68" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <path d="M23.51 51.523a.5.5 0 0 1-.5.477c-.29 0-.51-.21-.52-.477-.145-3.168-1.756-5.217-4.832-6.147C7.53
            42.968 0 33.863 0 23 0 10.297 10.297 0 23 0s23 10.297 23 23c0 10.863-7.53 19.968-17.658 22.376-3.076.93-4.687
            2.98-4.83 6.147z" id="&amp;-id-svg-filter">
        </path>
        <filter x="-21.7%" y="-15.4%" width="143.5%" height="138.5%" filterUnits="objectBoundingBox" id="&amp;-svg-filter">
        <feGaussianBlur in="SourceGraphic" stdDeviation="3"></feGaussianBlur>
        <feComponentTransfer>
            <feFuncA type="linear" slope=".3"></feFuncA>
        </feComponentTransfer>
        </filter>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <g fill-rule="nonzero" transform="translate(7 5)" fill="currentColor">
            <use filter="url(#&amp;-svg-filter)" xlink:href="#&amp;-id-svg-filter"></use>
            <use xlink:href="#&amp;-id-svg-filter"></use>
        </g>
        <path d="M30 68c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z" fill="#fff" fill-rule="nonzero"></path>
        <path d="M30 66a2 2 0 1 0 .001-3.999A2 2 0 0 0 30 66z" fill="currentColor"></path>
    </g>
</svg>`;

const DEFMARKER2 = `<svg version="1.1" viewBox="24 20 44 54" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g>
	<path d="M66.9,41.8c0-11.3-9.1-20.4-20.4-20.4c-11.3,0-20.4,9.1-20.4,20.4c0,11.3,20.4,32.4,20.4,32.4S66.9,53.1,66.9,41.8z    M37,41.4c0-5.2,4.3-9.5,9.5-9.5c5.2,0,9.5,4.2,9.5,9.5c0,5.2-4.2,9.5-9.5,9.5C41.3,50.9,37,46.6,37,41.4z"/>
</g>
</svg>`

const SVG_MARKER_USE = size => `<svg><use xlink:href="#svg-point"/></svg>`;
const MARKER_ELEMENT = document.createElement('div');
MARKER_ELEMENT.className = 'new-yandex-map-point round round-40';
//.classList.add('new-yandex-map-point precise');
//MARKER_ELEMENT.innerHTML = SVG_MARKER_USE;

export function getMarkerElement(i = 100) {
    const marker = MARKER_ELEMENT.cloneNode(true);
    //marker.style.setProperty('--point-bg-color', `hsl(${i % 365}deg 50% 50%)`);
    return marker;
}

export function createMarkerElement(classes, style) {
	const cls = ['new-yandex-map-point'];
	if (classes) {
		cls.push(classes);
	}
	const el = document.createElement('div');
	if (style) {
		el.style = style;
	}
	const content = document.createElement('div');
	el.append(content);
	el.className = cls.join(' ');
	return { el, content }
}

export function drawDebugMarker(map, coordinates) {
	const el = document.createElement('div');
	el.className = 'new-yandex-map-point precise'
	var marker = new ymaps3.YMapMarker(
		{
			id: _.uniqueId('map-dbg-point-'),
			source: 'marker-source',
			coordinates,
			onClick: () => {
				console.log('dbg', coordinates);
			}
		},
		el
	);
	map.addChild(marker);
	return marker;
}

export function createDefaultMarkerElement(size = 40) {
	const div = document.createElement('div');
	div.className = 'yandex-default-marker m-size-' + size;
	div.innerHTML = DEFMARKER2; //SVG_MARKER_USE('100%');
	return div;
}

export function createDefaultMarker(markerOptions, _options = {}) {
	const { size } = _options;
	const options = Object.assign({ 
		id: _.uniqueId('def-mrk-'), 
		source: 'marker-source' 
	}, markerOptions);
	const el = createDefaultMarkerElement(size);
	var marker = new ymaps3.YMapMarker(options, el);
	return marker;
}

window.dbgmarker = function (coordinates) {
	drawDebugMarker(window.map, coordinates);
}
window.dbgbounds = function(bounds) {
	const { minLng, maxLng, minLat, maxLat } = bounds;
	window.dbgmarker([minLng, minLat]);
	window.dbgmarker([maxLng, maxLat]);
	window.dbgmarker([minLng, maxLat]);
	window.dbgmarker([maxLng, minLat]);
}


