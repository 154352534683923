import { renderView as originalRenderView } from 'backbone.marionette';

const renderView = originalRenderView || function(view) {
    if (view._isRendered) {
        return;
    }

    if (!view.supportsRenderLifecycle) {
        view.triggerMethod('before:render', view);
    }

    view.render();
    view._isRendered = true;

    if (!view.supportsRenderLifecycle) {
        view.triggerMethod('render', view);
    }
}

export { renderView };