﻿//define('behaviors-subdivision', ['bus'], function(Bus) {});

//import Bus from 'bus';
import { Behavior } from 'vendors';
var b = Behavior.extend({
    ui: {
        'item':'.subdivision-item[data-url]'
    },
//     triggers: {
        //'click @ui.item':'subdivision:click'
//     },
//     onSubdivisionClick: function(view, e) {
//         var sub = $(e.target).closest('.subdivision-item');
//         var url = sub.data('url');
//         Bus.app.trigger('navigate', url);
//     }
});

export default b;