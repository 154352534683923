// define('svc/actualization/realties-list/realty/edit/properties/bus', [
//     'references',
//     'svc/actualization/realties-list/realty/edit/properties/utils'
// ], function(
//     refs, utils
// ) {});


import refs from 'references';
import utils from './utils';
import { MnObject } from 'vendors';	

const booleanWords = {
    'да':true, 
    'нет':false
}

const Bus = MnObject.extend({
    initialize() {
			this.clean();
			this._onPropertyContainsChange = _.debounce(this._onPropertyContainsChangeNotThrottled.bind(this), 300);
    },

    init(model, schemas) {
			this.release();

			this.setModel(model, schemas);
    },

    clean() {
        this.propertyContains = {};
        this.entityChanges = {};
        this.entityValues = {};
				this.difference = undefined;
    },

    release() {
			if (this.model) {
				this.stopListening(this.model);
				delete this.model;
			}
    },

    setModel(model, schemas) {
			this.clean();
			this.schemas = schemas;
			this.model = model;
			this.updateEntityValues();
    },

    updateEntityValues() {
			this.entityValues = {};
			if (this.schemas && this.model) {
				this.schemas.each(schema => this.entityValues[schema.id] = utils.getValue(this.model, schema.id));
			}
    },

    afterEntitySave() {
        this.entityChanges = {};
        this.updateEntityValues();
        this.trigger('model:changes');
    },

    setPropertyContains(text) {
        
        let o = this.propertyContains;

        o.valid = (text || '').length >= 2;

        o.text = text;

        let num = parseFloat(text, 10);
        o.num = isNaN(num) ? void 0 : num;

        let normalizedText = text.toLowerCase();

        if (Object.keys(booleanWords).indexOf(normalizedText) > -1) {
            o.bool = booleanWords[normalizedText] === true;
        } else {
            o.bool = void 0;
        }

        o.value = o.bool != null ? o.bool
            : o.num != null ? o.num
            : o.text;

        this._onPropertyContainsChange();
    },

    getPropertyContainsChange() {
        let o = this.propertyContains;
        return o.text;
    },

    _onPropertyContainsChangeNotThrottled() {
        this.trigger('filter:change', Object.assign({}, this.propertyContains));
    },

    _propertyKeys: ['group', 'label', 'fullLabel'],

    filterProperty(prop, model, filters) {

        if (filters && !filters.every(filter => !filter || filter(prop, model))) {
            //console.log('property false ', prop.id, prop.attributes);
            return false;
        }

        if (!this.propertyContains.valid) return true;

        let propId = prop.id;
        let hash = prop.attributes;

        let inProperty = this._propertyKeys.some(key => {
            let value = hash[key];
            let vtype = typeof(value);
            if (vtype !== 'string') return;

            let res = utils.textContains(value, this.propertyContains.text);
            if (res) {
                //console.log('#', propId, `[${key}]:`, value);
            }
            return res;
        });

        if (inProperty) {
            return true;
        }

        if (hash.valueType == 'enum') {
            let enumType = hash.valueSubtype;
            enumType = enumType[0].toLowerCase() + enumType.substring(1);
            let enumHash = refs.enum(enumType) || {};
            let enumRes = Object.keys(enumHash).some(enumKey => {
                return utils.textContains(enumKey, this.propertyContains.text)
                || utils.textContains(enumHash[enumKey], this.propertyContains.text)
            });
            if (enumRes) {
                return true;
            }
        }

        let value = utils.getSchemaValue(model, prop, propId);
        // if (prop.get('valueType') == 'enum') {
        //     console.log('###', propId, value);
        // }
        let res = utils.thingContains(value, this.propertyContains);
        if (res) {
            console.log('# in value', propId, this.propertyContains.value);
        }
        return res;
    },

    filterProperties(properties, model, ...filters) {
        return properties.some(prop => this.filterProperty(prop, model, filters));
    },


    setEntityValue(entity, schema, value) {

        if (value === '')  {
            value = null;
        }

        this.entityChanges[schema.id] = value;
				this.difference = undefined;
        utils.setValue(entity, schema.id, value);
        let changes = this.getChanges();
				
        this.trigger('model:changes', changes);
				console.log('-triggered-changes-', changes);
    },

    getChanges() {
			if (this.difference) { return this.difference; }
			if (this.difference === null) { return; }

			let difference = {}

			Object.keys(this.entityChanges).forEach(changeKey => {

				let changeValue = this.entityChanges[changeKey];
				let entityValue = this.entityValues[changeKey]; 
				// let ek_exist = changeKey in this.entityValues;

				let changes = false;
				if (changeValue === null && (entityValue == null || entityValue === '')) {
					changes = false;
				} else {
					changes = changeValue !== entityValue;
				}
				if (changes) {
						difference[changeKey] = this.entityChanges[changeKey];    
				} else {
					delete this.entityChanges[changeKey];
				}
				console.warn(changeKey, changes);
			});

			if (Object.keys(difference).length > 0) {
				this.difference = difference;
				return difference;
			} else {
				this.difference = null;
			}
    }

});

const bus = new Bus();

export default bus;
