import modals from '_libs/modals';
import { flatObject, unFlatObject, invokeValue, isView, isViewClass  } from 'utils';
import { EditView } from './EditView';
// import { EditContactLayout } from './views/edit-contact';

const defaultShowOptions = {
	modalCssCfg: "fullscreen",
	cssClasses: {
		content: 'no-padding'
	}
}
const flatDefaultShowOptions = flatObject(defaultShowOptions);

function cloneOptions(options) {
	const flatOptions = flatObject(options);
	const flat = Object.assign({}, flatDefaultShowOptions, flatOptions);
	return unFlatObject(flat);
}

export function editInModal(contentArg, modalOptions = {}, editOptions = {}) {
	const view = buildView(contentArg);
	modalOptions = cloneOptions(modalOptions);
	const modalView = modals.modal(view, modalOptions);
	const promise = new Promise((res, rej) => {
		view.once('destroy', () => {
			res({ ok: false, error: 'cancel' });
		});
		view.once('edit:done', (data) => {
			res(data);
			view.destroy();
		});
	});
	return promise;
}

function buildView(contentArg) {
	contentArg = invokeValue(contentArg);
	if (isView(contentArg)) {
		return contentArg;
	} else if (isViewClass(contentArg)) {
		return new contentArg();
	} else if (typeof contentArg === 'object') {
		const Ctor = contentArg.class || EditView;
		const options = Object.assign({}, contentArg);
		delete options.class;
		return new Ctor(options);
	}

}