import modals from '_libs/modals';
import { MneView } from 'core/mne';
import { RealtyAgencyDataCollection, addNewSchema, editExistSchema } from 'mods/realties/agency';
import { HamburgerView } from 'coms/ui/HamburgerView';
import { CollectionView } from 'core/collection-view';
import { View } from 'core/view';
import { Model } from 'core';
import { ButtonView } from 'coms/ui/Button';
import SchemaEdit from 'ui/controls/schemaEdit';
import refs from 'references';
import './agency-additional-data.less';
import { urls } from 'coms/urls';
import { UiMoleculeView } from 'coms/ui/UiMoleculeView';
import { UiAtomView } from 'coms/ui/UiAtomView';

console.error({addNewSchema, editExistSchema});

function coalesce(val, ifNull) {
	if (val == null || val === '') {
		return ifNull;
	}
	return val;
}

function displayValue(model) {
	const { valueType, booleanValue, numberValue, stringValue } = model.attributes;
	switch(valueType) {
		case 'boolean':
			return booleanValue ? "Да" : "Нет";
		case 'number':
			return coalesce(numberValue, '&mdash;');
		default:
			return coalesce(stringValue, '&mdash;');
	}
}

function adoptValue(hash) {
	const { valueType, booleanValue, numberValue, stringValue } = hash;
	switch(valueType) {
		case 'boolean':
			hash.value = booleanValue;
			break;
		case 'number':
			hash.value = numberValue;
			break;
		default:
			hash.value = stringValue;
			break;
	}
	return hash;
}

function adoptTypedValue(hash, defValueType) {
	const { value, valueType = defValueType } = hash;
				
	delete hash.value;

	switch(valueType) {
		case 'text':
			hash.stringValue = value;
			break;
		case 'boolean':
			hash.booleanValue = parseBool(value);
			break;
		case 'number':
			hash.numberValue = parseNum(value);
			break;
	}
	return hash;
}

const DataItemView = UiMoleculeView.extend({
	initialize() {
		this.on('all', c => console.log(c));
	},
	modelEvents: {
		'sync':'render'
	},
	thisClassName: 'data-item ui2-plate',
	editButton: true,
	deleteButton: true,
	content:{
		class: UiAtomView,
		thisClassName: 'info',
		text: v => displayValue(v.model),
		topText: 'значение',
		bottomText: v => refs.enum('realtyAgencyDataTypes', v.model.get('valueType'))
		// bottomText: v => refs.enum('employeeGroupJoins', v.model.get('joinedAs')),
		// topText: v => {
		// 	//console.log(v.model.attributes);
		// }
	},
	leftItems: [
		{
			class: UiAtomView,
			thisClassName: 'key fixed',
			topText: 'идентификатор',
			text: v => v.model.get('key') || '<small>[не указан]</small>'
			// v => v.model.get('name') ||  || '[без названия]',	
		},
		{
			class: UiAtomView,
			thisClassName: 'group fixed',
			topText: 'группа',
			text: v => v.model.get('groupName') || '<small>[не указано]</small>'
		},
		{
			class: UiAtomView,
			thisClassName: 'purpose fixed',
			topText: 'назначение',
			text: v => v.model.get('purpose') || '<small>[не указано]</small>'
		},		
		{
			class: UiAtomView,
			thisClassName: 'name fixed',
			topText: 'название',
			text: v => v.model.get('name') || '<small>[не указано]</small>'
		},

	],
	onChildviewEditClick() {
		
		const model = this.model;
		const valuesHash = adoptValue(model.toJSON());

		console.log('======', {valuesHash})

		let options = {
			header: 'Изменение характеристк здания',
			model: this.model,
			valuesHash: valuesHash,
			beforeApply: async (hash) => {

				hash = adoptTypedValue(hash, model.get('valueType'));
				console.warn('\r\n',hash,'\r\n');
				try {
					await this.model.save(null, { attrs: hash, wait: true, method: 'patch' });
				} catch (exc) {
					console.error(exc);
				}
			},
		}

		SchemaEdit.inModal(editExistSchema, options);
	},
	onChildviewDeleteClick() {
		return modals.confirm('Подтвердите удаление').then(() => this.model.destroy({ wait: true }));
	}
});

const DataCollectionView = CollectionView.extend({
	thisClassName: 'data-list',
	childView: DataItemView
});

const AddButton = ButtonView.extend({
	buttonName: 'add:new',
	text: 'добавить',
	initialize() {
		this.on('all', e => console.log('?', e));
	}
});

const MenuView = HamburgerView.extend({
	thisClassName: 'data-menu',
	childrenViews: [
		AddButton
	],
	childViewTriggers: {
		'add:new:click':'add:new'
	}
});

export const AdditionalLayout = HamburgerView.extend({
	thisClassName: 'agency-additional-data',
	initialize() {
		this.collection = new RealtyAgencyDataCollection([], { parent: this.model });		
		this.collection.fetch();
	},

	childrenViews: [
		MenuView,
		DataCollectionView
	],

	
	childViewEvents: {
		'add:new'(...args) {

			const model = new Model({
				valueType: 'text',
			});

			let options = {
				header: 'Изменение характеристк здания',
				model: model,
				valuesHash: model.toJSON(),
				beforeApply: async (hash) => {

					hash = adoptTypedValue(hash, 'text');

					try {
						await this.collection.create(hash, { wait: true });
					} catch (exc) {
						console.error(exc);
					}
				},
			}


			SchemaEdit.inModal(addNewSchema, options);
			
		}
	}

});