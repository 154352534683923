﻿// define('root-modules', [
// 	'root'
// 	, 'root-notnative'
// 	, 'acc'
// 	//, 'acc-createService'
// //	'p-root'
// //	, 'app-notFound'
// //	, 'ws'
// //	, 'app-error'
// ], function () {

// 	var args = [].slice.call(arguments);
//     return args;

// });

import root from './root';
import rootNotNative from './root-not-native';
import acc from './acc';

// export default [root, rootNotNative, acc];
// const acc = null;
// const rootNotNative = null;
// const root = null;

export const modules = [root, rootNotNative, acc];