import { View } from 'core';
import { _ } from 'vendors';
import { modelSchema } from 'mods/realties/offers/schema';
import { propertySchemaApi } from 'apis/schema';



export const PreviewOffer = View.extend({
	template: `
	<td class="offer-id" contenteditable="true"><%= operation %></td>
	<td class="floor" contenteditable="true"><%= floorPostfix %></td>
	<td class="square" contenteditable="true">
		<%= squarePostfix %>
	</td>
	<td class="priceMeter" contenteditable="true">
		<%= forCustomer_priceMeter %> р.<br> 
		<small><%= postfixForPriceMeter %></small> <span style="color:#444;">&nbsp;</span>
	</td>
	<td class="priceMeter" contenteditable="true">
		<%= forCustomer_priceSquare %> р.<br>
		<small><%= postfixForPriceSquare %></small> <span style="color:#444;">&nbsp;</span>
	</td>
	<td contenteditable="true">&nbsp;</td>
	`,
	initialize() {
		this.modelSchema = modelSchema;
	},
	modelTemplateKeys: {
		id: 0,
		operation: 1,
		'floorPostfix': 1,
		'squarePostfix': 1,
		'forCustomer.priceMeter': 1,
		'forCustomer.priceSquare': 1,
		'priceMeterPostfix': 1,
		'priceSquarePostfix': 1,		
	},
	templateContext() {
		const keysHash = this.getOption('modelTemplateKeys');
		const isRent = this.model.get('operation') === 'rent';
		const context = _.reduce(keysHash, (memo, options, key) => {
			const args = [this, key];
			const memoKey = key.replace(/\./g, '_');
			if (options && typeof options !== 'object') {
				options = {};
				args.push(options);
			} else {
				options = undefined;
			}
			const method = options ? this.displayModelValue : this.modelValue;
			const value = method.call(...args);
			memo[memoKey] = value;
			return memo;
		}, {
			postfixForPriceMeter: 'за м<sup>2</sup>' + (isRent ? 'в год' : ''),
			postfixForPriceSquare: (isRent ? 'в месяц' : '')
		});
		console.log('~', context, this.model);
		return context;
	},
	modelValue(key) {
		const val = propertySchemaApi.getValue(key, this.model, this.modelSchema);
		console.log('val', key, val);
		return val;
	},
	displayModelValue(key, options) {
		const val = propertySchemaApi.displayValue(key, this.model, this.modelSchema, options);
		console.log('disp val', key, val);
		return val;
	},
});