import { propertySchemaApi } from "apis/schema/propertySchemaApi";
import { invokeValue } from 'utils/invoke';

export const displaySchemaViewMixin = {
	getModelSchema() {
		return this.getOption('modelSchema', true);
	},
	getModelHash() {
		return this.model;
	},
	display(property) {
		const modelSchema = this.getModelSchema();
		const modelHash = this.getModelHash();
		// console.log(property, this.model.attributes);
		return propertySchemaApi.displayValue(property, modelHash, modelSchema);
	},
	modelValue(property) {
		const modelSchema = this.getModelSchema();
		const modelHash = this.getModelHash();
		// console.log(property, this.model.attributes);
		return propertySchemaApi.getValue(property, modelHash, modelSchema);
	},
	isValueEqual(property, compare) {
		const modelValue = this.modelValue(property);
		return compare === modelValue;
	},
	ifValueEqual(property, compare, ifTrue, ifNot) {
		
		const equal = this.isValueEqual(property, compare);
		if (equal) {
			return invokeValue(ifTrue, this, this);
		}
		return invokeValue(ifNot, this, this);
	}
}