import { View } from 'core';
import { _, $ } from 'vendors';
import { innerBus } from '../innerBus';

export const MultiActionsView = View.extend({
	template: _.template(`
		<div class="state"></div>
		<div class="actions">
			<span class="count">Выделено: <span class="value"><%= count %></span></span>
			<button class="action select" data-action="select" title="очистить выбранное"><i></i></button>
			<div class="buttons">
				<label>действия с предложениями</label>
				<% if (sameStatus && status && status != "active") {%><button class="action publish" data-action="offer:publish" title="опубликовать выбранные"><i class="glyphicon glyphicon-eye-open"></i></button><% } %>
				<% if (sameStatus && status && status == "active" ) {%><button class="action unpublish" data-action="offer:unpublish" title="снять с публикации выбранные"><i class="glyphicon glyphicon-eye-close"></i></button><% } %>
				<% if (sameOperation) {%><button class="action price" data-action="offer:price:edit" title="изменить ставку в выбранном"><i class="glyphicon glyphicon-ruble"></i></button><% } %>
				<% if (sameOperation) {%><button class="action commercial" data-action="commercial:edit" title="изменить коммерческие условия"><i class="glyphicon glyphicon-piggy-bank"></i></button><% } %>
				<button class="action refresh" data-action="offer:refresh" title="в выбранном всё без изменений"><i class="glyphicon glyphicon-refresh"></i></button>
			</div>
			<div class="buttons">
				<label>действия с помещениями</label>
				<button class="action createoffer no-accent" data-action="offer:create" title="добавить предложение по выбранным площадям"><i class="glyphicon glyphicon-plus-sign"></i></button>
				<button class="action newowner" data-action="object:newowner" title="поменять собственника"><i class="glyphicon glyphicon-transfer"></i></button>
			</div>
		</div>
	`),
	initialize() {
		$(document.body).append(this.el);
	},
	refresh(store)  {
		this.store = store;
		let distinct = {
			'offer.status': {},
			'offer.operation': {},
		}
		_.each(store.items, view => {
			_.each(distinct, (data, key) => {
				let value = view.model.getValueByPath(key);
				data[value] = true;
			});
		});
		_.each(distinct, (data, key) => {				
			let count = _.size(data);
			let value = count == 1 ? this._firstKey(data) : undefined;
			distinct[key] = { 
				count,
				value
			};
		});
		this.distinct = distinct;

		this.render();
	},
	onRender() {
		this.$el.addClass('multi-actions-box');
	},
	templateContext() {
		let res = {
			count: this.store.length,
			sameStatus: this.distinct['offer.status'].count == 1,
			status: this.distinct['offer.status'].value,
			sameOperation: this.distinct['offer.operation'].count == 1,
			operation: this.distinct['offer.operation'].value,
		}
		return res;
	},
	events: {
		'click button.action'(e) {
			let $b = $(e.target).closest('button');
			let action = $b.data('action');
			if (!action) return;
			this.triggerAction(action, e);
		},
	},
	triggerAction(action, event) {
		if (action != 'select') {
			innerBus.triggerMethod('multiaction', action, this.store);
		} else {
			innerBus.trigger('unselect:all');
		}
	},
	_firstKey(hash) {
		for(let k in hash) {
			return k;
		}
	}
});