// define('svc/actualization/ownerInRealty', [
// 	'appModule', 'link', 'svc/actualization/ownerInRealty/layout'
// ], function(
// 	Module, link, Layout
// ) {});


import Module from 'appModule';
import link from 'link';
import Layout from './layout';

import './ownerInRealty.less';

var module = Module.content({
	name: 'actualization/ownerInRealty',
	label: 'Актуализация здания собственника',
	routeData: () => link.route('actualizationOwnerInRealty'),
	// hidden: true,
	// notInMenu: true,
	// urlKey:'actualizationOwnerInRealty',
	claims: {
		svcActualizationModule: ['view']
	},
	onRun: function () {
		let view = new Layout({ ownerId: this.query.o, realtyId: this.query.r });
		this.createAndShow(view, 'Актуализация здания собственника', {}, { addSectionClass: 'flex-page flex-column' });
	}
});


//module.registerModule(moduleClient);

export default module;


