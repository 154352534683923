import { backendApi } from "../backend";
import { urls } from 'coms/urls';

export const mapsBackend = {


	_getFetchClustersUrl (options = {}, addUrl = '') {
		const { mapFilters, attrs } = options;
		if (!mapFilters) { return; }
		if (mapFilters.method === 'get') {
			addUrl += toUrlSearch(attrs);
		}
		const url = urls.api('v4/map/points' + addUrl);
		return url;
	},

	fetchClusters(options) {
		const { mapFilters, attrs } = options;
		const url = this._getFetchClustersUrl(mapFilters);
		//let attrs;
		if (mapFilters.method === 'post') {
			// attrs = { ...mapFilters.attributes };
			return backendApi.post(url, attrs, { relativeUrl: false });
		} else if (mapFilters.method === 'get') {
			return backendApi.get(url, { attrs, relativeUrl: false });
		}
		throw new Error('method ' + mapFilters.method + ' not implemented');
	},

	fetchMapPoints(options) {
		const { mapFilters, entryRealtyId } = options;
		const attrs = { ...(mapFilters?.attributes || {}), 'entryId.v': entryRealtyId };

		const url = this._getFetchClustersUrl({ mapFilters, attrs }, '-v2');
		// let attrs;
		if (mapFilters.method === 'post') {
			// attrs = { ...mapFilters.attributes };
			return backendApi.post(url, attrs, { relativeUrl: false });
		} else if (mapFilters.method === 'get') {
			return backendApi.get(url, { attrs, relativeUrl: false });
		}
		throw new Error('method ' + mapFilters.method + ' not implemented');
	}

}


function toUrlSearch(hash) {

	if (!hash) { return ''; }

	let result = Object.keys(hash).map(key => {
		const raw = hash[key];
		if (raw == null) {
			return;
		}
		const value = encodeURIComponent(raw);
		key = encodeURIComponent(key);
		return `${key}=${value}`;
	}).filter(f => !!f)
		.join('&');

	if (result.length) {
		result = '?' + result;
	}

	return result;

}