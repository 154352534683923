import { invokeValue } from 'utils/invoke';
import { NextCollectionView } from 'vendors';

export const CustomsView = NextCollectionView.extend({
	monitorViewEvents: false,
	childViewEventPrefix: false,
	_showChildren() {

		let views = this.getCustomViews() || [];

		let addedViews = [];
		
		views.forEach((view, index) => {
				if (!view) return;
				this._addChild(view, index);
				addedViews.push(view);
		});

		if (addedViews.length) {
				this._addedViews = addedViews;
		}
		return NextCollectionView.prototype._showChildren.apply(this, arguments);
	},
	getCustomViews() {
			console.error('# [ CUSTOM - VIEWS ] #');
			let val =  this.getOption('customViews');
			return invokeValue(val, this, this);
	},
    // attachHtml(els) {
    //     let selector = this.getOption('childViewContainer');
    //     let $el = !selector ? this.$el : this.$(selector);
    //     let el = $el.get(0);
    //     console.log('- attach -', selector, el);
    //     this.Dom.appendContents(el, els, { _$el: $el });
    // },
});