import { HamburgerView } from "coms/ui/HamburgerView";
import { View } from 'core';

const HeaderOptionsView = View.extend({});
const OwnerView = View.extend({});
const RealtyView = View.extend({});

const Header = HamburgerView.extend({
	childrenViews: [
		HeaderOptionsView,
		OwnerView,
		RealtyView
	]
})

export const OwnerInRealtyView = HamburgerView.extend({
	getChildren() {
		return [
			Header
		]
	}
});