import { _ } from 'vendors';

export const collectionFactoryMixin = {
	_prepareModel: function (attrs, options) {
		if (this._isModel(attrs)) {
			if (!attrs.collection) attrs.collection = this;
			return attrs;
		}
		options = options ? _.clone(options) : {};
		options.collection = this;

		var model = this._buildModel(attrs, options);
		if (!model.validationError) return model;

		this.trigger('invalid', this, model.validationError, options);
		return false;
	},
	_buildModel: function (attrs, options) {

        if (!this.model)
			throw Error('Model class not defined');

		if (typeof this.model.factory == 'function')
			return this.model.factory(attrs, options);
		else
			return new this.model(attrs, options);
	}
}

