// define('svc/actualization/changes/item-view', 
// [
// 	'references',
// 	'bus-svc',
// 	'behaviors',
// ],
// function (
// 	refs,
// 	busSvc,
// 	beh
// ) {});

import refs from 'references';
import busSvc from 'bus/svc';
import beh from 'behaviors';

import { MneView } from 'core/mne';
import { moment } from 'vendors';

const View = MneView.extend({
	behaviors: [beh.DynamicClass],
	dynamicClass() {
		let res = [];
		res.push(this.getEventType());
		let operation = this.getOperation();
		if (operation) {
			res.push(operation);
		}
		let mark = this.getMarket();
		if (mark) {
			res.push(mark);
		}

		return res.join(' ');
	},		
	className: 'changes-entry',
	getOperation() {
		return this.model.get('operation');
	},
	getMarket() {
		return this.model.get('market');
	},
	getEventType() {
		if (this._eventType) {
			return this._eventType;
		}
		let event = this.model.get('type').replace('realtyOffer', '').replace('realtyObject', '').replace('realtyOwner', '');
		event = event[0].toLowerCase() + event.substr(1);
		this._eventType = event;
		return this._eventType;
	},
	template: _.template(`
		<div class="taked-action">
			<span class="what"><%= takedAction %></span>
			<span class="who"><%= actorName %></span>
			<span class="when" title="<%= date %>"><%= dateFromNow %></span>
		</div>
		<div class="short-info">
			<% if (operation != null) {%><i><%= operation %></i><% } %>
			<big><%= num(info.squareOffer) %> <small>м<sup>2</sup></small></big>
		</div>
		<div class="object">
			<div class="info">
				<b class="realty"><%= realtyName %></b>
				<% if (info.floor != null) {%><b class="floor"><%= info.floor %> <small>эт.</small></b><% } %>
			</div>
			<div class="owner">
				<small><i><%= ownerName %></i></small>
			</div>
		</div>
	`),
	templateContext() {
		let date = new Date(this.model.get('date'));
		let dateFromNow = moment(date).fromNow();
		let event = this.getEventType();
		return {
			num(v, x) {
				return _.displayNum(v, x);
			},
			actorName: busSvc.request('employeeName', this.model.get('actorId')),
			takedAction: refs.enum('actualizeEvents', event),
			date,
			dateFromNow,
			operation: refs.enum('realtyOperations', this.model.get('operation'))
		}
	}
});


export default View;
