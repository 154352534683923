import { _ } from 'vendors';
import { CollectionView } from 'core/collection-view';
import { View } from 'core/view';
import { Collection } from 'vendors';
import appCfg from 'app-config';

const ManagersCollection = Collection.extend({
	url() {
		if (!this.realtyId) { return; }
		return appCfg.urls.apiV(`realties/realties/${this.realtyId}/agency-managers`);
	}
});

const ManagerView = View.extend({
	tagName: 'li',
	className: 'whole-btn',
	template: _.template('<small></small><span><%= name.full %></span>'),
	triggers: {
		'click':'click'
	}
});

export const ManagersView = CollectionView.extend({
	tagName: 'ul',
	className: '',
	childView: ManagerView,
	initialize() {
		this.collection = new ManagersCollection();
	},
	fetchManagers(realtyId) {
		this.collection.reset();
		if (realtyId) {
			this.collection.realtyId = realtyId;
			this.collection.fetch();
		}
	},
	childViewTriggers:{
		'click': 'known:responsible:click'
		// () {
		// 	console.log('oblya')
		// }
	}
});