module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h3>'+
((__t=(header))==null?'':__t)+
'</h3>\n<div>'+
((__t=(text))==null?'':__t)+
'</div>\n<div class="offset-t">\n	<button class="btn btn-primary confirm">'+
((__t=( okText ))==null?'':__t)+
'</button>\n	';
if(cancel){
__p+='<button class="btn btn-default pull-right cancel">'+
((__t=( cancelText))==null?'':__t)+
'</button>';
}
__p+='\n</div>';
}
return __p;
};
