import { View } from "base/views";
import { ModalBoxView } from './modal-box-view';
import escapeApi from './escape-api';
import './new-flex-modal.less';




export const ModalView = View.extend({
    monitorViewEvents: false,
    className: 'new-flex-modal',
    template: _.template('<% if(bg) { %><div class="new-flex-modal__bg"></div><% } %><div class="new-flex-modal__wrapper"></div>'),
    templateContext() {
        return {
            bg: this.shouldShowBg === true
        }
    },
    regions: {
        box: '.new-flex-modal__wrapper',
    },
    _onKeyboardKey(event) {

        if (!escapeApi.isLastHandler(this._onKeyboardKey)) {
            return;
        }
        //esc key
        if (event.which == 27) {
            if (this._tryClose()) {
                event.stopPropagation();
            } 
        }
    },
    _outsideClickClose(event) {
        if (event && event.target) {
            let $box = $(event.target).closest('.new-flex-modal-box');
            if ($box.get(0)) {
                return;
            }
        }
        this._tryClose();
    },
    initialize(options) {
        this.mergeOptions(options, ['shouldShowBg']);
        this.initializePromise();

        this._initializeOutsideClick();
        
        this._registerEscKeyHandler();

        let addClass = this.getOption('modalClassName');
        if (addClass) {
            this.$el.addClass(addClass);
        }

        //this.on('all', e => console.log(' :modal: ', e));
    },
    _initializeOutsideClick() {
        this.delegate('click', '.new-flex-modal__bg', this._outsideClickClose.bind(this));
        this.delegate('click', '.new-flex-modal__wrapper', this._outsideClickClose.bind(this));
    },
    _registerEscKeyHandler()
    {
        this._onKeyboardKey = this._onKeyboardKey.bind(this);
        let handler = this._onKeyboardKey;
        escapeApi.addHandler(handler);
        //addKeyListener(handler);
        this.on('destroy', () => escapeApi.removeHandler(handler));

    },
    initializePromise() {
        this.promise = new Promise((resolve, reject) => {

            let _reject = (type, arg) => this._fullfillPromise(reject, arg, type);
            let _resolve = arg => this._fullfillPromise(resolve, arg);

            this.on({
                'resolve': (arg) => _resolve(arg),
                'reject': (arg) => _reject('reject', arg),
                'reject:hard': (arg) => _reject('rejectHard', arg),
                'rejectHard': (arg) => _reject('rejectHard', arg),
                'destroy': () => _reject('reject'),
                'close': () => _reject('reject'),
            });

        });
        if (this.getOption('destroyOnDone') !== false) {
            this.promise.then(arg => {
                console.log(' - promise then succ -');
                this._tryClose();
                return Promise.resolve(arg);
            },
            arg => {
                console.log(' - promise then fail -');
                this._tryClose();
                return Promise.reject(arg);
            });
        }
    },
    _fullfillPromise(fullfil, value, type) {
        if (this._fullfiled)
            return;
            
        this._fullfiled = true;
        this._fullfiledValue = value;
        this._fullfiledType = type || 'resolve';

        if (arguments.length === 3) {
            value = { type, value };
        }

        console.log('promise fullfiled ', type || 'resolve', value);
        fullfil(value);
    },
    onRender() {
        let options = this.getModalBoxOptions();
				const view = new ModalBoxView(options);
				console.error('modal-box-view', view);
        this.showChildView('box', view);
    },
    getModalBoxOptions() {
        return Object.assign({}, this.options);
    },
    _tryClose(event) {

        if (this.getOption('undestroyable') && !this._fullfiled) {
            return;
        }
        this.destroy();
        return true;
    },
    childViewEvents: {
        'modal:close': '_tryClose'
    },
    childViewTriggers: {
        'modal:resolve':'resolve',
        'modal:reject':'reject',
        'modal:reject:hard':'reject:hard',
        'modal:rejectHard':'reject:hard',
    }
});