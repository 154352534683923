import BaseLayout from 'ui/layouts/recordset-view';
import ResultCollection from './owners-collection';
import filtersConfig from './filters';
import ItemView from './list-item-view';
import AcPan from '../actions-panel';

//import './edit-realties-list.less';

const actions = [
	{
		id: 'create:owner',
		text: 'Добавить собственника',			
	},		
];
let counter = 0;
let ItemView2 = ItemView.extend({ template: () => '---', })

export default BaseLayout.extend({
	className: 'recordset-view actualization owners-list',
	instantApplyFilters: true,
	initialize() {
		counter = 0;	
		this.searchResult = new ResultCollection();
		this.registerActionsPanel();

	},
	listResultOptions: {
		//className: 'realties-list',
		className: 'load-more-on-scroll-box scrollY-region edit-owners-list',
		childView: ItemView,
		// () => {
		//     if (counter > 40) return ItemView2;
		//     counter++;
		//     return ItemView
		// },
		childViewOptions: {
			modelType: "owner",
		},
		
		//modelType: "realty",
	},
	filtersConfig,
	...AcPan.resultViewMixin({ actions }),
});


