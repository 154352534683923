import { _, $, DomApi } from 'vendors';

function to$El(el) {
    return el instanceof $ ? el : $(el);
}

_.extend(DomApi, {
    addEventListener(target, type, listener, options) {
        let $el = to$El(target);
        return $el.on(type, listener);
    },
    removeEventListener(target, type, listener, options) {
        let $el = to$El(target);
        return $el.off(type, listener);
    }
});

export default DomApi;

// export default {
//     getDoc() {
//         if (!this.$doc)  {
//             this.$doc = $(document);
//         }
//         return this.$doc;
//     },

//     getBody() {
//         if (!this.$body)  {
//             this.$body = $(document.body);
//         }
//         return this.$body;
//     },

//     settleEvent($el, event, handler) {
//         return $el.on(event, handler);
//     },

//     removeEvent($el, event, handler) {
//         return $el.off(event, handler);
//     },    
// }