// import { templateFuncs } from 'core/mne';
import { _ } from 'vendors';

export async function updateTemplateFuncs(refs) {

	const module = await import('core/mne');

	_.extend(module.templateFuncs, {
		_enum: function(_a1, _a2, empty) {
			empty || (empty = '&ndash;');
			var type;
			var value;
			if (_a1 == null && _a2 == null) return empty;
			else if (_a1 == null && !!_a2) {
				value = _a1;
				type = _a2;
			} else if (_a2 == null && !!_a1) {
				value = _a2;
				type = _a1;
			} else {
				//var t = refs.enum(_a1);
				if (_a1 in refs.Enums) {
					type = _a1;
					value = _a2;
				} else {
					type = _a2;
					value = _a1;
				}
			}


			value || (value = '');
			var val = refs.enum(type, value);
			return val ? val : '&ndash;';
		},
		_enumRadiosHtml: function(enumName, val, inputName, opts) {
			opts || (opts = {});
			return buildRadioCheckboxes(_.extend({
				type: 'radio',
				hash: refs.enum(enumName),
				value: val,
				inputName: inputName,
			},opts));
			//var en = refs.enum(enumName);
			//if (!en) return '';
			//var res = '';
			//_(en).each(function (label, value) {
			//	var checked = value == val ? ' checked="checked"':'';
			//	res += '<div class="radio">';
			//	res += '<label>';
			//	res += '<input type="radio" name="' + inputName + '" value="' + value + '" '+checked+'>';
			//	res += '<span class="icon"></span>';
			//	res += '<span class="input-label">'+label+'</span>';
			//	res += '</label>';
			//	res += '</div>';
			//});
			//return res;
		},
		_enumCheckboxesHtml: function(enumName, val, inputName) {

			return buildRadioCheckboxes({
				type: 'checkbox',
				hash: refs.enum(enumName),
				value: val,
				inputName: inputName,
				check: function($value, $modelValue) {
					return !!$value && refs.enumHasFlag($modelValue, $value);
				}
			});

			//var en = refs.enum(enumName);
			//if (!en) return '';
			//var res = '';
			//_(en).each(function (label, value) {
			//	if (!label) return;
			//	var checked = !!value && refs.enumHasFlag(val, value);
			//			//value == val ? ' checked="checked"' : '';
			//	res += '<div class="checkbox">';
			//	res += '<label>';
			//	res += '<input type="checkbox" name="' + inputName + '" value="' + value + '" ' + checked + '>';
			//	res += '<span class="icon"></span>';
			//	res += '<span class="input-label">' + label + '</span>';
			//	res += '</label>';
			//	res += '</div>';
			//});
			//return res;
		},
		_checkboxesHtml: function(options) {
			options || (options = {});
			options.type = 'checkbox';
			return buildRadioCheckboxes(options);
		},
		_radiosHtml: function(options) {
			options || (options = {});
			options.type = 'radio';
			return buildRadioCheckboxes(options);
		},
		_enumDropdownRadiosHtml: function (enumName, val, inputName, opts) {
			opts || (opts = {});
			return buildRadioCheckboxes(_.extend({
				type: 'radio',
				hash: refs.enum(enumName),
				value: val,
				inputName: inputName,
				itemTagName: 'li',
				itemClassName: 'dropdown-item',
			}, opts));	
		}
	});



}


function buildRadioCheckbox(options) {
	options || (options = {});
	options.type || (options.type = 'radio');
	options.checked || (options.checked = '');
	options.value || (options.value = '');
	options.label || (options.value && (options.label = options.value)) || (options.label = '');
	
	var inputAttrs = '';
	if (options.inputAttrs) {
		let res = [];
		_(options.inputAttrs).each(function (_value, name) {
			var value = _.v(_value, options, options);
			res.push(name + '="' + value + '"');
		});
		inputAttrs = res.length > 0 ? ' ' + res.join(" ") : '';
	}

	var tag = options.itemTagName || 'div';

	//var sName = options.inputName ? name = ' name="' + options.inputName + '" ' : '';
	var sName = options.inputName ? ' name="' + options.inputName + '" ' : '';
	let res = '';
	res += '<' + tag + ' class="' + options.type + ' ' + options.itemClassName + '">';
	res += '<label>';
	res += '<input type="' + options.type + '"' + sName + ' value="' + options.value + '" ' + options.checked + inputAttrs + '>';
	res += '<span class="icon"></span>';
	res += '<span class="input-label">' + options.label + '</span>';
	res += '</label>';
	res += '</' + tag + '>';
	return res;
}
function buildRadioCheckboxes(opts) {
	opts || (opts = {});
	if (!('skipEmptyLabel' in opts))
		opts.skipEmptyLabel = true;
	opts.inputName || (opts.generateInputName && (opts.inputName = _.uniqueId(opts.type)));


	if (!opts.hash || !opts.type) return '';

	//var type = opts.type;
	var res = '';
	_(opts.hash).each(function (label, value) {
		if (!label && opts.skipEmptyLabel) return;

		var checked = (
			typeof opts.check == 'function'
			? opts.check.call(opts.context, value, opts.value)
			: value == opts.value
		)
			? ' checked="checked"'
			: '';
		var itemOptions = _.extend({}, opts, { value: value, label:label, checked: checked });
		res += buildRadioCheckbox(itemOptions);
	});
	return res;
}