﻿//define('svc-tasks-task', ['bus', 'bus-app', 'ws', 'appModule', 'link', 'svc-tasks-task-layout', 'm-tasks'], function (Bus, appRadio, ws,  Module, link, Layout, tasksNs) {});


//let Bus, appRadio, ws,  Module, link, Layout, tasksNs;
import Bus from 'bus';
import appRadio from 'bus/app';
import getWs from 'ws';
import Module from 'appModule';
import link from 'link';
import Layout from './layout';
// import tasksNs from 'm/tasks';
import { TaskModel } from 'm/tasks';

var module = Module.content({
	auth: true,
	name: 'tasks-card',
	label: 'Задача',
	routeData: () => link.route('taskCard'),
	//urlKey: 'tasksMy',
	onBeforeRun: function (taskId) {
		
		var model = new TaskModel({ id: taskId });
			//new tasksNs.TaskModel({ id: taskId });
		model.loudFetch().then(() => {}, function (xhr) {
			Bus.moduleXhrError(xhr);
		});
		//console.log('in module', model._preparedActions);
		this.addRunArgument(model);
		this.addRunPromise(model.apiPromise);

		//var col = tasksNs.TasksCollection.my();
		//col.loudFetch();
		//this.addRunPromise(col.apiPromise);
		//this.addRunArgument(col);
	},
	onRun: function (model) {
		let ws = getWs();
		this.cardId = model.get('id');
		appRadio.reply('current:page', { name: 'task', id: this.cardId });
		try {
			ws.joinGroup('task:' + this.cardId);
		} catch (e) {
			console.warn(e);
		}

		this.label = 'задача &ndash; ' + model.get('toDo');

		//var result = 
		module.createAndShow(Layout, this.label, { model: model }, { navLinks: this.navLinks() });
	},
	onStop: function () {
		let ws = getWs();
		ws.leaveGroup('task:' + this.cardId);
	}
});

export default module;