module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--<td class="offer-id" contenteditable="true">'+
((__t=( id ))==null?'':__t)+
'</td>-->\n<td class="floor" contenteditable="true">'+
((__t=( _mv('obj.info.floor')))==null?'':__t)+
' <small>эт.</small> </td>\n<td class="square" contenteditable="true">\n	'+
((__t=( _mfn('getSquare',{formated:true, postfix:false})))==null?'':__t)+
' <small>м<sup>2</sup></small>\n</td>\n<td class="priceMeter" contenteditable="true">\n	'+
((__t=( _mfn('getPriceMeter',{formated:true, postfix:false})))==null?'':__t)+
' <small>р./м<sup>2</sup></small><span style="color:#444;">&nbsp;</span>\n</td>\n<td class="priceMeter" contenteditable="true">\n	'+
((__t=( _mfn('getPriceSquare',{formated:true, postfix:false})))==null?'':__t)+
' <small>р.</small><span style="color:#444;">&nbsp;</span>\n</td>\n<td contenteditable="true">&nbsp;</td>\n';
}
return __p;
};
