﻿// define('svc-modules', [
// 	'websockets','service'
// ], function () {

// 	var args = [].slice.call(arguments);
//     return args;

// });


import websockets from './websocket';
import service from './service';

// export default [websockets, service];
//const service = {};

export default [websockets, service];