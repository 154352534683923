﻿//define('behaviors-bootstrapPopover', [], function () {});
import template from './bootstrapPopover.html';
import { Behavior } from 'vendors';
var b = Behavior.extend({


	popoverTemplate: template,
	popoverTemplateContext: {
		popoverClass: 'popover-flat'
	},
	initialize: function (opts) {
		this.mergeOptions(opts, ['popoverTemplate']);
	},
	content: '',
	contentOptions: {},


	eventOpen: 'mouseenter',
	eventClose: 'mouseleave',
	events: function () {
		var eventsOptions = this._getEventOptions();
		var openEvent = eventsOptions.open;
		var closeEvent = eventsOptions.close;
		var uiTrigger = this._getEventTriggerSelector();
		var openkey = openEvent + (uiTrigger ? (' ' + uiTrigger) : '');
		var closekey = closeEvent + (uiTrigger ? (' ' + uiTrigger) : '');
		var events = {};

		if(openkey)
			events[openkey] = 'openPopover';

		if(closekey)
			events[closekey] = 'closePopover';

		events['shown.bs.popover'] = function () {
			if (this.contentView)
				this.contentView.triggerMethod('showed');
		}

		return events;
	},

	_openPopover: function () {
		var cntx = this;			
		if (cntx.$popover) return;
		//console.log('> _open');
		cntx._initPopover();
		cntx.openTimeoutTimer2 = setTimeout(function () {
			//return;
			//console.log('> timed out _open');
			cntx.$trigger.popover('show');
			cntx._refreshPopoverElement();
		}, 200);
	},
	openPopover: function () {
		var cntx = this;

		cntx._tryClearPopoverCloseInterval();
		//console.log('> open');
		if(!this.popoverId)
			cntx.openTimeoutTimer = setTimeout(
				_.bind(cntx._openPopover, cntx)
				, 500
			);
	},
	_closePopover: function () {
		if(this.DEBUG === true) return;
		var cntx = this;

		if (cntx.$popover)
			cntx.$popover.find('.popover-content > *').detach();

		cntx.$trigger.popover('hide');

		cntx._removePopoverElement();
	},
	closePopover: function () {
		var cntx = this;

		this._tryClearPopoverOpenTimeouts();

		if (!cntx.popoverId) return;

		var tm = cntx._getPopoverCloseTimeouts();

		tm[cntx.popoverId] = setTimeout(
			_.bind(cntx._closePopover, cntx)
			, 100
		);

	},

	_getEventTriggerSelector: function () {
		return this.getOption('eventTriggerSelector');
	},
	_getTriggerElement: function () {
		var selector = this._getEventTriggerSelector();
		if (!selector)
			return this.$el;
		else
			return this.$(selector);
	},
	_getEventOptions: function () {
		var res = {};
		res.open = this.getOption('eventOpen');
		res.close = this.getOption('eventClose');
		return res;
	},


	_getPopoverCloseTimeouts: function (key) {
		this.popoverCloseTimeouts || (this.popoverCloseTimeouts = {});
		var tm = this.popoverCloseTimeouts;
		if (!key)
			return tm;
		else
			return tm[key];
	},
	_getPopoverOpenTimeouts: function (key) {
		this.popoverOpenTimeouts || (this.popoverOpenTimeouts = {});
		var tm = this.popoverOpenTimeouts;
		if (!key)
			return tm;
		else
			return tm[key];
	},
	_tryClearPopoverOpenTimeouts: function () {
		var cntx = this;

		if (cntx.openTimeoutTimer)
			clearTimeout(cntx.openTimeoutTimer);

		if (cntx.openTimeoutTimer2)
			clearTimeout(cntx.openTimeoutTimer2);
	},
	_tryClearPopoverCloseInterval: function () {

		var cntx = this;
		if (!cntx.$popover || !cntx.popoverId || cntx.$popover.attr('id') != cntx.popoverId)
			return;

		cntx._clearPopoverCloseInterval(cntx.popoverId);

	},
	_clearPopoverCloseInterval: function (piid) {
		
		var interval = this._getPopoverCloseTimeouts(piid);
		if (!interval) return;
		clearTimeout(interval);

	},

	_initPopover: function () {
		var cntx = this;
		if (cntx.popoverInitialized) return;
		//console.log('> _init');
		var triggerElement = cntx._getTriggerElement();
		var content = cntx._getPopoverContent();

		var tmpl = cntx._getPopoverTemplate()
		triggerElement.popover({
			placement: this.getOption('placement') || 'right',
			container: this.getOption('container') || false,
			content: content,
			html: true,
			template: tmpl,
			trigger:'manual',
		});
		cntx.popoverInitialized = true;

		this.$trigger = triggerElement;
	},
	_removePopoverElement: function () {
		var cntx = this;
		if (!cntx.$popover) return;

		cntx.$popover.off();
		cntx.$popover.remove();
		delete cntx.$popover;

		cntx._clearPopoverCloseInterval(cntx.popoverId);
		delete cntx.popoverId;
	},
	_refreshPopoverElement: function () {
		var cntx = this;

		cntx._removePopoverElement();

		cntx.popoverId = cntx.$trigger.attr('aria-describedby');
		cntx.$popover = $('#' + cntx.popoverId);
		cntx.$popover.on('mouseleave', function () {
			cntx.closePopover();
		});
		cntx.$popover.on('mouseenter', function () {
			cntx._clearPopoverCloseInterval(cntx.popoverId);
		});
	},
	_getPopoverContent: function (check) {
		//console.log('get pc:', this.contentView, this.contentView && this.contentView.isRendered());
		var rawcontent = check || this.getOption('content');
		if (typeof rawcontent == 'string') return rawcontent;

		if (rawcontent instanceof Backbone.View) {
			rawcontent.parent = this.view;
			if (!this.contentView) {
				this.contentView = rawcontent;

				if (!this.contentView.isRendered())
					this.contentView.render();

				this.listenTo(this.contentView, 'content:change', this._updatePopoverContent);

			}

			return this.contentView.$el;
		}

		if (typeof rawcontent == 'function') {
			if (rawcontent.prototype instanceof Backbone.View) {
				var opts = _.result(this, 'contentOptions');
				var view = new rawcontent(opts);
				view.parent = this.view;
				return this._getPopoverContent(view);
			} else {
				return this._getPopoverContent(rawcontent());
			}
		}


		return '';
	},
	_getPopoverTemplateContext: function () {
		var hash = this.getOption('popoverTemplateContext') || {};
		if (_.isFunction(hash))
			hash = hash.call(this);
		if (this.contentView) {
			var viewClass = _.result(this.contentView, 'className') || '';
			hash.contentClass || (hash.contentClass = '');
			hash.contentClass += (viewClass && (' ' + viewClass)) || '';
		}
		return hash;
	},
	_getPopoverTemplate: function () {

		var tmpl = _.isFunction(this.popoverTemplate) 
			? this.popoverTemplate 
			: _.template(this.popoverTemplate || '');
		//MnTemplateCache.get(this.popoverTemplate);

		//var tmpl = MnTemplateCache.get(this.getOption('popoverTemplate'));
		var html = tmpl(this._getPopoverTemplateContext());
		return html;
	},
	_updatePopoverContent: function () {
		//this.$popover.find('.popover-content').empty();
		//this.$popover.append(this._getPopoverContent());
		//console.log('updated!!!');
		//var content = this._getPopoverContent(this.contentView);
		//console.log('content changed', this.contentView.model);
	},
	onDebugShowBehavior: function () {
		//console.log('POP BEH:', this);
	},
	onBeforeDestroy: function () {
		this._tryClearPopoverOpenTimeouts();
		this._tryClearPopoverCloseInterval();
		if(this.$popover)
			this._closePopover();
	},
});

export default b;