﻿//define('modals', ['bus-core', 'modals-modal', 'modals-engine', 'modals-headerAndText'], function (busCore, Modal, engine, HeaderAndText) {});

import busCore from 'bus/core';
import Modal from './modal';
import engine from './modals-engine';
import HeaderAndText from './headerAndText';

import { MneView } from 'core/mne';

import './modals.less';

var cssHash = function (name) {
	var hash = {
		preloader: { box: 'preloader' },
		flexible: { dialog: 'flex-modal-dialog' },
		shortBlock: { dialog: 'narrow', wrapper: '', box: 'top10 block shadowed', content: '' },
		flexFullScreen: { dialog: 'flex-modal-dialog', wrapper: '', box: '', content: '' },
		fullscreen: { box: 'block shadowed fullwidth fullheight' },
		fullscreenNoPadding: { box: 'block shadowed fullwidth fullheight', content: 'no-padding' },
		autoCenter: { box: 'block shadowed autoCenter'},
		shortAutoHeight: { dialog: 'narrow autoheight', wrapper: '', box: 'block shadowed', content: '' },
		popup: { dialog: 'popup', box: 'block shadowed no-border' },
		'as-page': { dialog: 'as-page' }
	};
	return _.extend({}, hash[name]);
}


function convertEmptyResponseToHeaderAndText(xhr)
{
	let header = xhr.status;
	let text = xhr.statusText;
	switch(xhr.status) {
		case 404:
			return { header, text: 'запрашиваемый ресурс не найден' };
		default:
			return { header, text };

	}
}

function convertJSONResponseToHeaderAndText(xhr)
{
	var json = xhr.responseJSON;
	let header;
	let text;

	if (json.StackTraceString && json.Message) {
		header = json.Message;
		text = json.StackTraceString;
	} else if (json.code && json.message) {
		header = json.code;
		text = json.message;
	} else if (json.status && json.title) {
		header = json.status;
		text = json.title;
		if (json.errors) {
			switch(typeof json.errors) {
				case 'string':
					text += '\r\n<br/>' + json.errors; break;
				case 'object':
					text += '\r\n<br/>' + _.map(json.errors, (value, key) => key + ':' + value).join('\r\n<br/>');
					break;
			}
		}
	}

	if (header || text) return { header, text }

	let throwText = 'convertJSONResponseToHeaderAndText case not implemented';
	console.error(throwText);
	throw new Error(throwText);
}



var ns = {};

ns.error = function (header, text) {

	return new Modal({
		cssClasses: cssHash('shortBlock'),
		content: HeaderAndText.create(header, text),
	});
}

ns.xhrError = function(xhr) {
	if (!xhr.responseText) {
		let { header, text } = convertEmptyResponseToHeaderAndText(xhr);
		return ns.error(header, text);			
	} else {
		console.log('Not Implemented xhrError case');
		if (xhr.responseJSON) {
			let { header, text } = convertJSONResponseToHeaderAndText(xhr);
			return ns.error(header, text);			
		}
		throw new Error('Not Implemented xhrError case');
	}
}

ns.notify = function (header, text) {

	return new Modal({
		cssClasses: cssHash('shortBlock'),
		content: HeaderAndText.create(header, text),
	});
}

ns.preloader = function (text) {
	return new Modal({
		cssClasses: cssHash('preloader'),
		content: new MneView({
			template: _.template('<i class="fa fa-refresh fa-spin fa-4x fa-fw"></i><span><%=text%></span>'),
			templateContext: function () {
				return {
					text: text || '',
				};
			},
		})
	});
}



ns.confirm = function (header, text, opts) {
	//var defOpts = { confirm: true };

	opts || (opts = {});

	if (typeof header == 'object') {
		_.extend(opts, header);
		header = opts.header;
		text = opts.text;
	} else if (typeof text == 'object') {
		_.extend(opts, text);
		text = opts.text;
	}
	opts.confirm = true;

	header || (header = 'подтвердите действие');
	
	const content = HeaderAndText.create(header, text, opts);

	new Modal({
		cssClasses: cssHash('shortBlock'),
		content,
	});

	return content.promise;
	//mymodal.content.promise;

}


ns.modal = function (content, options) {
	options || (options = {});
	var csscfg = options.modalCssCfg ? options.modalCssCfg : 'shortBlock';
	var opts = $.extend(true, {
		cssClasses: cssHash(csscfg),
		content: content,
	}, options);

	return new Modal(opts);
}


ns.popup = function (content, options) {
	options || (options = {});
	var csscfg = options.modalCssCfg ? options.modalCssCfg : 'popup';
	var opts = $.extend(true, {
		cssClasses: cssHash(csscfg),
		content: content,
		type: 'popup',
	}, options);
	//console.log('popup opts', opts);
	return new Modal(opts);

}


var radio = busCore.modals = busCore.channel('modals');

radio.reply('preloader', function (text) {
	return ns.preloader(text);
});

radio.reply('as-page', showAsPageModal);
// function(view, options) {
// 	options || (options = {});
// 	options.modalCssCfg = "as-page";
// 	return ns.modal(view, options);
// });

radio.reply('fullscreen', function (view, options) {
	options || (options = {});
	options.modalCssCfg = "fullscreen";
	return ns.modal(view, options);
});

radio.reply('modal', function (view, options){
	return ns.modal(view, options);
});
radio.reply('confirm', function (view, options) {
	return ns.confirm(view, options);
});
radio.reply('popup', function (view, options) {
	return ns.popup(view, options);
});
radio.reply('notify', function (view, options) {
	return ns.notify(view, options);
});
radio.reply('error', function (header, text) {
	return ns.error(header, text);
});

export function showAsPageModal(view, options) {
	options || (options = {});
	options.modalCssCfg = "as-page";
	return ns.modal(view, options);
}

export function showFullscreenModal(options) {

	var promise = $.Deferred();

	var preloader;


	var model = options.model;


	if (model) {
		if (options.noFetch || model.isFetched()) {

			promise.resolve();

		} else {

			model.loudFetch().then(() => {
				promise.resolve();
			}, (xhr) => {
				promise.reject(xhr);
			});
	
		}

	}

	// if (model && model.isFetched()) {
	// 	promise.resolve();
	// }
	// else {
	// 	model.loudFetch().then(function () {
	// 		promise.resolve();
	// 	}, function (xhr) {
	// 		promise.reject(xhr);
	// 	});
	// }

	if (promise.state() == 'pending') {
		preloader = radio.request('preloader');
	}

	var modalView;

	promise.then(function () {
		var view = new options.View({ model: model });
		//console.log('then', view);
		modalView = radio.request('fullscreen', view, options);
	}, function (xhr) {
		//console.log('FAIL',options, xhr)
		var fail = options.fail || {};
		var message = fail[xhr.status];
		radio.request('error', message || "Произошла непредвиденная ошибка, перезагрузите страницу");
	}).always(function () {
		if (preloader)
			preloader.destroy();
	});

	return modalView;
}

radio.reply('show:fullscreen', showFullscreenModal);

radio.reply('clear:modals', function () {
	engine.clearModals();
});


//Bus.modals.request()
//var model = this;
//model.fetch().then(function () {
//	model.trigger('fetched', model);
//});
//var View = Bus.Views.request('deal:page');
//var view = new View({ model: model });



export default ns;

