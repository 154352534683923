﻿//define('realties-sendByEmail', ['bus', 'realties-sendByEmail-preview'], function(Bus, Preview) {});

import Bus from 'bus';
import Preview from 'svc/realties/sendByEmail/preview';
import { getOfferRealty } from 'mods/realties';
import { BbeCollection } from 'core/bbe';
import { Collection } from 'core';
import { _ } from 'vendors';
import { showAsPageModal } from '_libs/modals';
import './send-by-email-editor.less';

export default {
    actionSendByEmail: function(models, emails) {
        // var realties = new Collection();
				const realties = {};
				const ctxmodels = [];
        _.each(models, (model) => {

            var bld = getOfferRealty(model);
						let context = realties[bld.id];
						if (!context) {
							context = { id: bld.id, realty: bld, offers: [] };
							realties[bld.id] = context;
							ctxmodels.push(context);
						}
						context.offers.push(model);
						// model.getRealty();
            //bld.addOffer(model);
            //realties.add(bld);
        });

				const collection = new Collection(ctxmodels);

        var view = new Preview({ collection, emails: emails });
				
				showAsPageModal(view);
        
				//Bus.modals.request('as-page', view);

        //console.log(realties);
    },
}