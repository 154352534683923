﻿//define('m-realties-buildings-images', ['app-paths', 'bus'], function (paths, Bus) {});

import paths from 'app-paths';
import Bus from 'bus';
import cfg from 'app-config';
import { BbeCollection } from 'core/bbe';

import { MneObject } from 'core/mne';
import { imageModelComparator } from 'coms/ImageGalery';
import { BaseImageStore } from '../BaseImageStore';

var Collection = BbeCollection.extend({
	initialize: function (data, opts) {
		this.setParentId(opts.parentId);
	},
	setParentId: function (id) {
		this.parentId = id;
	},
	url: function () {
		if (!this.parentId) return;
		let url = cfg.urls.apiV(`realties/realties/${this.parentId}/images`);
		return url;
		//return paths.api.realtyImages(this.parentId);
	},
});


Collection.create = function(parentId){
	return new Collection([], { parentId: parentId });
}

// var ImageStore = BaseImageStore.extend({
// 	listType: 'realties'
// 	//Collection: Collection,

// 	// initialize: function () {
// 	// 	this.store = {};
// 	// },
// 	// getImages: function (id) {
// 	// 	if (!id) return;
// 	// 	var col = this.store[id];
// 	// 	if (!col) {
// 	// 		col = new Collection([], { parentId: id });
// 	// 		this.store[id] = col;
// 	// 		col.once('sync', () => {
// 	// 			col.models.sort(imageModelComparator);
// 	// 		});
// 	// 		col.loudFetch();
// 	// 	}
// 	// 	return col;
// 	// }
// });

var imageStore = new BaseImageStore({ listType: 'realties' });

//console.error('-reply-','get:realty:images');
Bus.Models.reply('get:realty:images', function (id) {
	return imageStore.getImages(id);
});

export default Collection;

export function getBuildingImagesCollection(id) {
	return imageStore.getImages(id);
}
