﻿//define('svc-tasks-awaiting', ['appModule', 'link', 'svc-tasks-list-layout', 'm-tasks'], function (Module, link, Layout, tasksNs) {});

import Module from 'appModule';
import link from 'link';
import Layout from '../_list/layout'
import tasksNs from 'm/tasks';

var module = Module.content({
	auth: true,
	name: 'tasks-awaiting',
	label: 'Ожидающие моей проверки',
	routeData: () => link.route('tasksAwaitingcheck'),
	urlKey: 'tasksAwaitingcheck',
	onBeforeRun: function () {
		var col = tasksNs.TasksCollection.awaiting();
		col.loudFetch();
		this.addRunPromise(col.apiPromise);
		this.addRunArgument(col);
	},
	onRun: function (col) {
		//this.showButtonsLayout();
		//var result = 
		module.createAndShow(Layout, this.label, { collection: col, tasksType: 'awaiting', itemFilter: function (m) { return m.isForMyCheck(); }, }, { navLinks: this.navLinks() });
	}
});
export default module;
