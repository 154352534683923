import './expose-vendors';
import '../vendors';
import './console';
import './assets';
import 'less/bundle.less';
import './v01';

if (process.env.ENV_MODE === 'production') {
	console.log('##::', 'production');
} else if (process.env.ENV_MODE === 'development') {
	console.log('##::', 'development');
}

// import start from './v02';
// start();






// alert('kuku');