import { Collection } from 'core';
// import { RealtyModel } from './RealtyModel';
import { urls } from 'coms/urls';

export const RealtiesCollection = Collection.extend({
	// model: RealtyModel,
	url() {
		//let url = paths.api.realtiesBuildingsAll;
		let url = urls.api('v4/realties/realties/all-shorts')
		if (this._lastFetch) {
			url = url + '?modified.from=' + this._lastFetch.toJSON()
		}
		return url;
	},
	refetch() {
		this.once('sync', () => {
			this._lastFetch = new Date();
		});
		return this.fetch({ merge: true, add: true, remove: false });
	}
});