﻿//define('svc-realties-offers-views-cardInModal-objectCommon',[],function(){});

import template from './objectCommon.html';
import { MneView } from 'core/mne';

import { HamburgerView } from 'coms/ui/HamburgerView';

var View = MneView.extend({
	className:'block',
	//template: '#tmpl-service-realties-offers-views-cardInModal-objectCommon',
	template,
});

const NewView = HamburgerView.extend({
	getChildren() {
		return [
			{
				class: View
			}
		]
	}
});

export const ObjectCommonView = NewView;

export default NewView;
