import { attachView, isView } from 'utils';
import { stack } from './stack.js';
import { ModalView } from './ModalView.js';
import { TextView } from 'core';
import './modalsApi.less';
// import { textDialogueModalViewOptions } from './utils.js';

// const L = localizeApi.get;





export const modalsApi = {

	'config.message.resolveText': 'Закрыть',

	'config.confirm.resolveText': 'Подтвердить',
	'config.confirm.resolveText': 'Отмена',

	ModalView: ModalView,
	modalClassName: 'default',

	attachTo: () => document.body,

	showView (view, showOptions = {}) {

		if (!isView(view)) {

			throw new Error('modals: unable to show modal view, provided view is not a view instance');

		}

		const el = showOptions.attachTo || this.attachTo();
		if (!el) {
			throw new Error('modals: unable to attach modal view, attachTo element is undefined');
		}

		const result = stack.create(view, showOptions, () => {
			attachView(view, el, true);
		});
		return result;

	},

	// message(...args) {
	// 	const destroyOnOutsideClick = true;

	// 	const modalViewOptions = {
	// 		thisClassName: 'message small',
	// 		destroyOnOutsideClick,
	// 		modalBoxOptions: textDialogueModalViewOptions(args, {
	// 			footerButtons: ['resolve'],
	// 			resolveText: this['config.message.resolveText'],
	// 		})
	// 	};
		

	// 	const view = new this.ModalView(modalViewOptions);

	// 	const modalOptions = {
	// 		destroyOnEsc: true, 
	// 		destroyOnSettle: true,
	// 		destroyOnOutsideClick, 
	// 	}
	// 	return this.showView(view, modalOptions);
	// },
	
	// confirm(...args) {
	// 	const destroyOnOutsideClick = true;

	// 	const modalViewOptions = {
	// 		thisClassName: 'message small confirm',
	// 		destroyOnOutsideClick,
	// 		modalBoxOptions: textDialogueModalViewOptions(args, {
	// 			footerButtons: ['resolve', Separator, 'reject'],
	// 			resolveText: this['config.confirm.resolveText'],
	// 			rejectText: this['config.confirm.rejectText']
	// 		})
	// 	};

	// 	const modalOptions = {
	// 		destroyOnEsc: true, 
	// 		destroyOnSettle: true,
	// 		destroyOnOutsideClick, 
	// 	}

	// 	const view = new ModalView(modalViewOptions);
	// 	return this.showView(view, modalOptions);
	// },

	show(modalContent, modalViewOptions, modalOptions) {
		if (typeof modalViewOptions === 'string') {
			modalViewOptions = { thisClassName: modalViewOptions };
		}

		modalViewOptions = Object.assign({ thisClassName: this.modalClassName }, modalViewOptions, { modalContent });
		
		modalOptions = Object.assign({
			destroyOnEsc: true, 
			destroyOnSettle: true,
			destroyOnOutsideClick: true, 
		}, modalOptions);
		
		// console.log('=== modal ===', modalOptions);
		const view = new this.ModalView(modalViewOptions);
		// view.on('all', c => console.log('[m]',c));
		return this.showView(view, modalOptions);
	},

	message(arg) {
		const view = {
			baseClassName: 'message-text',
			class: TextView,
			text: arg
		}
		const modal = {
			thisClassName: 'message',
			resolveButton: 'Ok'
		}
		return this.show(view, modal);
	},

	error(message) {
		return this.message(message);
	},

	confirm(message) {
		return this.message(message);
	}

};

