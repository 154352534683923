﻿//define('svc-deals-create', ['app-config', 'appModule', 'link', 'svc-deals-create-layout', 'm-deals', 'm-clients','m-factory'], function (cfg, Module, link, Layout, dealsNs, clientsNs, factory) {});

import Module from 'appModule';
import link from 'link';
import Layout from './layout'
import factory from 'm/factory';

import './deal-create.less';
// import { ensureRealtiesPromise } from 'mods/realties';


var module = Module.content({
    name: 'deal-create',
    label: 'создание процесса',
    urlKey:'dealsCreate',
    claims: {
        svcProcessesModule: ['create']
    },
    routeData: function () {
        return link.route('dealsCreate');
    },
    onBeforeRun: function() {
			// this.addRunPromise(ensureRealtiesPromise());
			if (this.query.clientId) {
				var model = factory.model('JsonClient', this.query.clientId);
				this.addRunPromise(model.loudFetch());
				this.addRunArgument(model);
			}
    },
    onRun: function(client) {
        console.log('RUN:', client);
        var module = this;
        module.createAndShow(Layout, this.label, { client: client }, { navLinks: this.navLinks() });
    }
});


export default module;