﻿//define('ui-layouts-popupActions', ['modals', 'ui-controls-dataroleActionItem', 'behaviors-datarole'], function (modals, ActionItem, BehaviorDatarole) {});


import modals from '_libs/modals';
import ActionItem from 'ui/controls/dataroleActionItem';
import BehaviorDatarole from 'behaviors/datarole';

import { bbeViewComparator } from 'utils';

import { BbeCollection } from 'core/bbe';
import { MneNextCollectionView } from 'core/mne';

function normalizeOptions(opts) {
	var options = _.extend({},opts);

	if (opts.rawActions)
		options.collection = new BbeCollection(opts.rawActions);

	return options;
}

var Layout = MneNextCollectionView.extend({
	className:'like-list-group',
	tagName: 'ul',
	behaviors: [BehaviorDatarole],
	childView: ActionItem.BoardListItem,
	childViewOptions: function () {
		var model = this.getOption('model');
		return {
			parent: model,
			renderedInModal: this.getOption('renderedInModal')
		};
	},
	viewComparator: function (a,b) {
		return bbeViewComparator(a, b, function () { return this.model.get('order') });
	},
	onAction: function (context) {
		var tp = this.getOption('triggerPrefix') || '';
		var tmo = this.getOption('triggerMethodOn');
		if (!tmo) return;
		var trig = [];
		trig.addValue(tp);
		trig.addValue(context.role);
		var event = trig.join(":");

		tmo.triggerMethod(event, context, this.getOption('renderedInModal'));
		tmo.triggerMethod(event + ":" + context.type, context, this.getOption('renderedInModal'));
	}
});
Layout.open = function (opts) {
	opts || (opts = {});

	var options = normalizeOptions(opts);
	
	var view = new Layout(options);

	return modals.popup(view, opts);

}

export default Layout;