﻿//define('behaviors-blockbuttons', ['ui-controls-blockbutton'], function (BlockbuttonView) {});

import BlockbuttonView from 'ui/controls/blockbutton';
import { bbeViewComparator } from 'utils/comparators';
import { BbeCollection } from 'core/bbe';
import { MneNextCollectionView } from 'core/mne';
import { Behavior } from 'vendors';

var b = Behavior.extend({
	onRender: function () {
		var navLinks = this.view.getOption('navLinks') || [];
		
		if (navLinks.length == 0) {
			
			var module = this.view.getOption('module');
			
			if (module && module.navLinks)
			navLinks = _.result(module, 'navLinks');
			
		}
		
		if (!navLinks || navLinks.length == 0) {
			navLinks = [];
		}
		console.log('#navlinks render# !', navLinks);
		
		var col = new BbeCollection(navLinks);
		var colView = new MneNextCollectionView({
			emptyView: this.view.getOption('noButtonsView', false),
			childView: BlockbuttonView,
			collection: col,
			viewComparator: function (a, b) {
				return bbeViewComparator(a, b, function () { return this.$el.hasClass('go-up'); });
			}
		});
		console.log('#colView#', colView);

		var region = this.view.getRegion('blockbuttons');
		if (!region) {
			if (this.$('.blockbuttons-region').length == 0)
				this.$el.append($('<div class="blockbuttons-region"/>'));
			this.view.addRegion('blockbuttons', {
				el: '.blockbuttons-region',
				replaceElement: true,
			});
		}
		this.view.showChildView('blockbuttons', colView);
	}
});


export default b;
