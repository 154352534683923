﻿//define('m-tasks-tasks', ['app-paths', '_libs/identity', 'm-tasks-task'], function (paths, identity, Task) {});

import paths from 'app-paths';
import Task from './task';

import { bbeViewComparator } from 'utils/comparators';

import { BbeCollection } from 'core/bbe';
import { moment } from 'vendors';

var Collection = BbeCollection.extend({
	model: Task,
	comparator: function (a,b) {
		return bbeViewComparator(a, b, function () { return +moment(this.get('deadline')); });
	}
});

Collection.my = function (empId) {
	var col = new Collection();
	col.employeeId = empId;
	col.url = function() {
		var add = this.employeeId ? "?empId=" + this.employeeId : "";
		return paths.api.tasksMy + add;
	}
	return col;
}

Collection.fromme = function(empId) {
	var col = new Collection();
	col.employeeId = empId;
	col.url = function () {
		//var add = this.employeeId ? "?empId=" + this.employeeId : "";
		return paths.api.tasksFromme;
	}
	return col;
}

Collection.awaiting = function(empId) {
	var col = new Collection();
	col.employeeId = empId;
	col.url = function () {
		//var add = this.employeeId ? "?empId=" + this.employeeId : "";
		return paths.api.tasksAwaitingcheck;
	}
	return col;
}






export default Collection;
