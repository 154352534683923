import { CustomsView } from 'base/views';
import { price as _getPrice } from '_helpers/offer-price';
import consts from 'vXX/app/constants';
const { NDS_KOEF } = consts;

import { getValueByPath } from 'utils';

import { BbModel, MnView } from 'vendors';
import { MneView } from 'core/mne';
const tmpl = _.template;

const __EditPriceView = MneView.extend({
	className: 'edit-price-dialogue',
	template: _.template(`		
	<input type="hidden" data-role="price" name="customer.priceMeter" class="form-control" value="<%= priceMeter %>" />
	<div>
		<label title="<%= _priceMeter.base %>" class="current-price-label">
			<span>текущая стоимость метра: </span><big><%= _f(_priceMeter.total) %></big>
			<% if(_priceMeter.nds) { %> <small>(ндс внутри, базовая: <%= _f(_priceMeter.base, 2)%>)</small> <% } %>
		</label>
	</div>
	<div>
		<label class="header-label">новая цена: </label>
		<input type="number" data-role="newprice-value" value="<%= priceMeter %>" class="form-control" /> 
		<label><input type="checkbox" data-role="newprice-nds" /> ввожу с ндс </label>
		<% if (_squareAllowed) { %>, <label><input type="checkbox" data-role="newprice-square" /> за всю площадь </label> 
		<% } else { %>
			, стоимость метра
		<% }%>
	</div>
	<div>
		<label class="new-price-label">установитcя: <big data-role="newprice-label">без изменений</big></label>
	</div>
	<div>
		<button class="btn btn-primary apply">Установить</button>
	</div>
	`),
	events: {
		'click button.apply'(e) {
			this.triggerMethod('done', this._getNewPriceValue().newValue, this);
		}
	},
	initialize(opts) {
		this.mergeOptions(opts, ["models"]);
		this.manyObjects = this.models.length > 1;
		this.isRent = !this.manyObjects && getValueByPath(this.models[0], 'offer.operation') == 'rent' || false;

		this.delegate('input', '[data-role="newprice-value"]', this._onPriceChange.bind(this));
		this.delegate('change', '[data-role="newprice-nds"]', this._onPriceChange.bind(this));
		this.delegate('change', '[data-role="newprice-square"]', this._onPriceChange.bind(this));

	},
	_getNewPrice: function () {
		return {
			$label: this.$('[data-role="newprice-label"]'),
			$el: this.$('[data-role="price"]'),
			value: this.$('[data-role="newprice-value"]').val(),
			nds: this.$('[data-role="newprice-nds"]').get(0).checked,
			square: this.manyObjects ? undefined : this.$('[data-role="newprice-square"]').get(0).checked,
			rent: this.isRent,
		};
	},
	_getNewPriceValue: function () {
		var price = this._getNewPrice();
		var value = parseFloat(price.value);
		value = isNaN(value) ? 0 : value;
		if (value < 0) price.value = 0;
		if (price.nds) {
			value = value / NDS_KOEF;
		}
		if (price.square) {
			var sq = this.getSquare();
			if (sq > 0) {
				value = value / sq;
			}
			if (price.rent) {
				value = value * 12;
			}
		}
		price.newValue = value;
		return price;
	},
	_onPriceChange: function () {

		var c = this._getNewPriceValue();
		var base = c.newValue;
		var total = base;
		var nds = this.hasNds() ? NDS_KOEF : 1;
		total = base * nds;
		base = this.formatPrice(base, 2);
		total = this.formatPrice(total, 2);
		if (nds > 1) {
			c.$label.html(total + ' за кв.м (ндс внутри, базовая: ' + base + ')');
		} else {
			c.$label.html(total + ' за кв.м');
		}
		c.$el.val(c.newValue);
	},
	hasNds: function () {
		if (this._hasNds != null) return this._hasNds;
		let tax = this.getValue('offer.forCustomer.taxType', true);
		if (!Array.isArray(tax)) {
			tax = [tax];
		}
		tax = _.reduce(tax, (memo, t) => {
			if (t == null) {
				return memo;
			}
			t = t.toLowerCase();
			t = t.replace(/\s/gmi,'');
			let ta = t.split(',');
			memo.push(...ta);
			return memo;
		}, []);
		this._hasNds = tax.indexOf('nds') > -1;
		return this._hasNds;
	},
	getValue(key, distinct) {
		let values = this.models.map(m => getValueByPath(m, key));
		if (this.manyObjects && !distinct) {
			return values;
		}
		let hash = _.reduce(values, (memo, val) => {
			memo[val] = val;
			return memo;
		}, {});
		values = _.map(hash, (val, key) => val);
		if (values.length == 1) {
			return values[0];
		}
		return values;
	},
	getSquare() {
		let value = this.getValue('object.info.squareOffer', true);
		if (Array.isArray(value)) return 0;
		return value;
	},
	_getPriceMeter() {
		if (this._priceMeter != null) return this._priceMeter;
		let val = this.getValue('offer.forCustomer.priceMeter');
		val = Array.isArray(val) ? 0 : val;
		this._priceMeter = val;
		return val;
	},
	getPriceMeter: function() {
		var price = this._getPriceMeter();
		var nds = this.hasNds();
		return {
			base: price,
			nds: nds,
			total: nds ? price * NDS_KOEF : price
		};
	},
	formatPrice: function (num, period) {
		return _.displayNum(num, period || 0);
	},
	templateContext: function () {
		return {
			_v: this,
			_f: this.formatPrice,
			_priceMeter: this.getPriceMeter(),
			_many: this.manyObjects,
			priceMeter: this._getPriceMeter(),
			_squareAllowed: !this.manyObjects
		};
	}	
});


const EditPriceView = MnView.extend({
    initialize(options) {
        this.mergeOptions(options, ['valuesHash', 'priceModel']);

    },
    template: tmpl(`
        <div class="as-ui-molecule" style="margin: 15px 0; padding: 30px 0; border-top: 5px solid rgba(0,0,0,.1); border-bottom: 5px solid rgba(0,0,0,.1);">
            <div class="formgroup fixed">
                <select class="form-control bolder blue" name="full">
                    <option value="false"<%= optionSelected('full', false)%>>цена за м<sup>2</sup></option>
                    <% if (hasSquare) {%><option value="true"<%= optionSelected('full', true)%>>цена за площадь</option><% } %>
                </select>
            </div>
            <div class="formgroup fixed">
                <select class="form-control bolder blue" name="ndsInside">
                <% if (hasNds) { %><option value="true"<%= optionSelected('ndsInside', true)%>>ввожу с ндс</option><% } %>
                    <option value="false"<%= optionSelected('ndsInside', false)%>>ввожу без ндс</option>
                </select>
            </div>
            <div class="formgroup elastic">
                <input class="form-control bolder blue" type="number" name="price" value="<%= price %>"/>
            </div>
        </div>
    `),
    templateContext() {
        let a = this.priceModel.attributes;

        return {
            optionSelected(key, value) {
                if (a[key] === value)
                    return ' selected';
                else
                    return '';
            },
            hasNds: this.priceModel.isNds,
            hasSquare: this.priceModel.square >= 0,
            price: a.price == null ? '' : a.price,
        }
    },
    ui:{
        full: 'select[name=full]',
        ndsInside: 'select[name=ndsInside]',
        price: 'input[name=price]'
    },
    events:{
        'input select'(e) {
            this._change();
        },
        'input input'(e) {
            this._change();
        }
    },
    _getFormValue() {
        let price = this.ui.price.val();
        if (price === '') {
            price = undefined
        } else {
            price = price.replace(/,/gmi, '.');
            price = parseFloat(price, 10);
            if (isNaN(price))
                price = undefined;
        }
        return {
            full: this.ui.full.val() == 'true',
            ndsInside: this.ui.ndsInside.val() == 'true',
            price: price || 0,
        };
    },
    _calcNewPriceMeter(form) {
        let price = form.price;

        if (form.full) {
            price = this.priceModel.square > 0 ? price / this.priceModel.square : 0;
            if (this.priceModel.isRent) {
                price = price * 12;
            }
        }

        if (form.ndsInside) {
            price = price / NDS_KOEF;
        }

        return price;
    },
    _change() {
        let form = this._getFormValue();
        let priceMeter = this._calcNewPriceMeter(form);
        let newValue = { ...form, priceMeter };
        this.priceModel.set(newValue);
        
        //this.triggerMethod('change', priceMeter);
        this.triggerMethod('value:changed', newValue);
    },

});

const valuesHashSchema = { 
    square: { awaitedType: 'number', defaultValue: 0 },
    isNds: { awaitedType: 'boolean', defaultValue: false },
    isRent: { awaitedType: 'boolean', required: true },
    boma: { awaitedType: 'number', defaultValue: 1 },
    currentPricePerMeter: { awaitedType: 'number', defaultValue: 0 }
}

const AsUiAtom = MnView.extend({
    className: 'as-ui-atom',
    template: tmpl('<i><%= top %></i><div><%= text %></div><b><%= bottom %></b>'),
    getTop() {
        return this.getOption('top')
    },
    getBottom() {
        return this.getOption('bottom')
    },
    getText() {
        return this.getOption('text')
    },
    templateContext() {
        return {
            top: this.getTop(),
            bottom: this.getBottom(),
            text: this.getText(),
        }
    }
});

const CurrentInfo = CustomsView.extend({
    className: 'as-ui-molecule space-between',
    initialize(options) {
        this.mergeOptions(options, ['valuesHash', 'priceModel']);
    },
    getCustomViews() {
        let currMeter = this.priceModel.getPriceArray(false, true, this.priceModel.currentPricePerMeter);
        let currSquare = this.priceModel.getPriceArray(true, true, this.priceModel.currentPricePerMeter);
        return [
            new AsUiAtom({
                text: currMeter[0],
                bottom: 'текущая стоимость метра'
            }),
            new AsUiAtom({
                text: currSquare[0],
                bottom: 'текущая стоимость площади ' + currSquare[1]
            }),
            new AsUiAtom({
                text: this.priceModel.displayOperation(),
                bottom: 'операция'
            }),
            new AsUiAtom({
                text: this.priceModel.displayNds(),
                bottom: 'налог'
            }),
            this.valuesHash.isRent && (
                new AsUiAtom({
                    text: this.priceModel.boma,
                    bottom: 'кор. коэф.'
                })
            ),
        ]
    }
});

const WillBeSetInfo = CustomsView.extend({
    initialize(options) {
        this.mergeOptions(options, ['valuesHash', 'priceModel']);
        this.listenTo(this.priceModel, 'change', this.render);
    },
    getCustomViews() {
        let currMeter = this.priceModel.getPriceArray(false, true);
        let currSquare = this.priceModel.getPriceArray(true, true);
        return [
            new MnView({
                tagName: 'label',
                template: tmpl('будет установлена такая цена')
            }),
            new CustomsView({
                className: 'as-ui-molecule space-around',
                customViews: () => [
                    new AsUiAtom({
                        text: currMeter[0],
                        bottom: 'стоимость метра'
                    }),
                    new AsUiAtom({
                        text: currSquare[0],
                        bottom: 'стоимость площади ' + currSquare[1]
                    }),
                ]
            })
        ];
    }
});

const PriceModel = BbModel.extend({
    displayPrice(full, priceMeter) {
        return this.getPrice(full, true, priceMeter);
    },
    getPrice(full, display, priceMeter)
    {
        priceMeter = (priceMeter != null ? priceMeter : this.get('priceMeter')) || 0;
        if (!full) {
            return _getPrice(priceMeter, this.isNds, null, null, null, display);
        } else {
            return _getPrice(priceMeter, this.isNds, this.square, this.isRent, this.boma, display);
        }
    },
    getPriceArray(full, display, priceMeter) {
        let price = this.getPrice(full, display, priceMeter);
        let add = full 
            ? this.isRent
                ? 'в месяц'
                : ''
            : 'м<sup>2</sup>'
        return [price, add];
    },
    displayOperation() {
        return this.isRent ? 'Aренда' : 'Продажа';
    },
    displayNds() {
        return this.isNds ? 'НДС' : 'БЕЗ НДС';
    }
});

export default CustomsView.extend({
    //template:_.template('edit price 2'),
    initialize(options) {
        this.mergeOptions(options, ['valuesHash']);
        let errors = this.validateValuesHash(this.valuesHash);
        if (errors) {
            console.error(errors);
            throw Error('invalid valuesHash');
        }

        let hash = Object.assign({ priceMeter: this.valuesHash.currentPricePerMeter }, this.getOption('initialValue'));
        let priceModel = new PriceModel(hash);
        Object.assign(priceModel, this.valuesHash);
        this.priceModel = priceModel;

    },
    validateValuesHash(hash) {
        let errors = [];
        if (hash == null) {
            return ['не передан valuesHash для изменения цены'];
        }

        _.each(valuesHashSchema, (schema, key) => {

            if (typeof schema === 'string') {
                schema = { awaitedType: schema }
            }

            let { awaitedType, required, defaultValue } = schema;

            let providedValue = hash[key];

            if (providedValue == null && defaultValue != null) {
                providedValue = defaultValue;
            }

            if (providedValue == null && required) {
                errors.push(key + ' ' + awaitedType + ': ' + (providedValue || ''));
                return;
            }

            if (typeof providedValue != awaitedType) {
                errors.push(key + ' ' + awaitedType + ': ' + (hash[key] || ''));
            }
        });

        if (errors.length) {
            return errors;
        }

    },
    getCustomViewOptions(add) {
        return Object.assign({
            valuesHash: this.valuesHash,
            priceModel: this.priceModel,
        }, add);
    },
    getCustomViews() {
        let options = this.getCustomViewOptions();
        return [
            new CurrentInfo(options),
            new EditPriceView(options),
            new WillBeSetInfo(options),
        ];
    },
    childViewTriggers: {
        'value:changed':'change'
    }
});