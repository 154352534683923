﻿//define('ui-controls-phone', [], function () {});

import template from './control.html';
import './phone-control.less';
import { MneView } from 'core/mne';

const ignoreKeys = {
	Backspace: 1,
	Delete: 1,
	ArrowLeft: 1,
	ArrowRight: 1,
	Tab: 1,
	Home: 1,
	End: 1,
}

var View = MneView.extend({
	className: 'phone-control',
	//template: '#tmpl-ui-controls-phone-control',
	template,
	ui: {
		'input': 'input[data-name]',			
	},
	initialize: function () {
		var data = this.data = (this.getOption('data') || {});
		//this.data = data;
		data.country || (data.country = 7);
		//this.on('all', e => console.warn('# phone: ', e));
	},
	events: {
		'keydown @ui.input': function (e) {
			e.stopPropagation();

			if (e.key in ignoreKeys) { return; }

			var inp = e.target;
			var $inp = $(inp);
			var max = parseInt($inp.attr('max'), 10);
			if (!e.key || !/^[\d-]$/gmi.test(e.key) || inp.value.length >= max) {
				e.preventDefault();
			}
			console.log(e.key);
			// if (e.keyCode >= 48 && e.keyCode <= 57 && inp.value.length >= max) {
			// 	e.preventDefault();
			// }
		},
		'input @ui.input': 'broadcast',
		'focus @ui.input': function (e) {
			e.stopPropagation();
			$(e.target).select();
		},
	},
	grabInputValues() {
		var hash = {};
		this.ui.input.each(function () {
			const key = $(this).data('name');
			let val = $(this).val();
			if (key === 'number' && val) {
				val = val.replace(/-/gm, '');
			}
			hash[key] = val;
		});
		return hash;
	},
	getReturnValue(valueHash) {
		valueHash = valueHash || this.grabInputValues();
		const stringValue = this._toStringValue(valueHash);

		const transform = this.getOption('transformValue');
		if (typeof transform === 'function') {
			const res = transform(valueHash, stringValue);
			console.error('return transform', res);
			return res;
		}

		var type = this.getOption('returnType') || 'string';
		if (type === 'string') {
			console.error('return string', stringValue);
			return stringValue;
		}

		console.error('return valueHash', valueHash);
		return valueHash;
	},
	_toStringValue(hash) {
		return hash.country + '.' + hash.region + '.' + hash.number;
	},
	broadcast: function (e) {
		e.stopPropagation();
		//var hash = _.extend.apply(null, this.ui.input.serializeArray().map(function (aa) { var a = {}; a[aa.name] = aa.value; return a; }));
		
		//var hash = this.ui.input.serializeModel();
		// var type = this.getOption('returnType') || 'string';
		// var value;
		// if (type == 'string') {
		// 	value = hash.country + '.' + hash.region + '.' + hash.number;
		// }
		// else value = hash;

		const hash = this.grabInputValues();
		const value = this.getReturnValue(hash);

		this.triggerMethod('value:changed', value, hash.add);
		if (this.getOption('triggerOnEl'))
			this.$el.trigger(this.getOption('triggerOnEl'), value);
	},
	getWrapperClass() {
		const cls = this.getOption('wrapperClass', true);
		if (cls) {
			return ` class="${cls}"`
		}
		return '';
	},
	templateContext: function () {
		const ctx = {
			wrapperClass: this.getWrapperClass(),
			country: this.data.country,
			region: this.data.region,
			number: this.data.number,
			add: this.data.add,
			withAdd: this.getOption('withAdd') == true,
		};
		return ctx;
	}
});

export default View;