import { $ } from 'vendors';

export function ajaxSetup() {
	$.ajaxSetup({
		crossDomain: true,
		xhrFields: {
			withCredentials: true
		},
		// headers: {
		// 	"_requeststamp": stamp
		// }
	});
}