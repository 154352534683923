﻿// define('ui-controls-datetime2', [
// 	'behaviors',
// 	'ui-models-values-single',
// 	'ui-models-values-range',
// 	'ui-controls-datetime2-monthView',
// 	'ui-controls-datetime2-selectMonthView',
// 	'ui-controls-datetime2-selectYearView',
// 	'ui-controls-datetime2-valueView'
// ], function (beh, Single, Range, MonthLayout, SelectMonthLayout, SelectYearLayout, ValueView) {});

import template from './index.html';
import './datetime2.less';


import Single from 'ui/models/values/single';
import Range from 'ui/models/values/range';
import MonthLayout from './month-view';
import SelectMonthLayout from './select-month-view';
import SelectYearLayout from './select-year-view';
import ValueView from './value-view';

import { MneView } from 'core/mne';

function toDate(iv) {
	if (iv) {
		if (_.isString(iv)) {
			iv = Date.info(iv).date
		}
		if (!_.isDate(iv)) {
			iv = undefined;
		}
	} else {
		iv = undefined;
	}
	return iv;
}


var Datetime = MneView.extend({
	className: 'datetime-control',
	//template: '#tmpl-ui-controls-datetime2-index',
	template,
	regions: {
		'content': '.content-region',
		'selected': '.value-region',
	},
	initialize: function (opts) {
		//this.on('all', (c) => console.log('all:>', c));
		this.mergeOptions(opts, ['modelType']);
		this.initializeModel();
	},
	initializeModel: function () {

		var iv = this.getOption('initialValue');
		if (iv instanceof Array)
			iv = iv[0];

		let isSingle = this.modelType == 'single';

		if (isSingle) {
			iv = { value: toDate(iv) };
		} else {
			if (_.isObject(iv) && ('from' in iv || 'to' in iv)) {
				iv.from = toDate(iv.from);
				iv.to = toDate(iv.to);
			} else {
				iv = { from: undefined, to: undefined }
			}
		}

		// if (this.modelType == 'single' && _.isDate(iv))
		// 	iv = { value: iv }
		// else if (this.modelType == 'single' && !_.isObject(iv))
		// 	iv = { value: Date.info(iv).date };
		// else if (this.modelType == 'range' && _.isObject(iv)) {
		// 	iv.from = Date.info(iv.from).date;
		// 	iv.to = Date.info(iv.to).date;
		// }
		//console.log('-datetime2-', iv);
		var Model = this.modelType == 'single' ? Single : Range;
		this.model = new Model(iv);
		this.model.modelType = this.modelType;
		this.model.valueType = 'datetime';
		this.takeTime();
		//console.log('moidel', this.model);
	},


	onRender: function () {
		this.showMonth();
		this.showSelectedValue();
		//this.showSelectMonth();
	},

	showMonth: function () {
		var view = new MonthLayout({
			model: this.monthModel,
			valueModel: this.model,
			//modelType: this.modelType,
		});
		this.monthModel = view.model;
		this.showChildView('content', view);
	},
	showSelectMonth: function () {
		var view = new SelectMonthLayout();
		this.showChildView('content', view);
	},
	showSelectYear: function () {
		var view = new SelectYearLayout({
			model: this.monthModel,
			//year: this.monthModel.get('year')
		});
		this.showChildView('content', view);
	},
	showSelectedValue: function () {
		var view = new ValueView({
			model:this.model
		});
		this.showChildView('selected', view);
	},

	childViewEvents: {
		'year:change': function (v) {
			this.monthModel.setYear(v.model.id);
			this.showMonth();
		},
		'month:change': function (v) {
			this.monthModel.setMonth(v.model.id);
			this.showMonth();
		},
		'select:month': function () {
			this.showSelectMonth();
		},
		'select:year': function () {
			this.showSelectYear();
		},
		'day:click': function (model) {
			this.handleDayClick(model);
		},
		'time:changed': function () {
			if (this.model.modelType == 'single' || this.model.filled())
				this.triggerMethod('value:changed', this.model.getValue());
			if (this.model.modelType == 'single') {
				this.takeTime();
			}
		}
	},
	takeTime() {

		var date = this.model.get('value');
		if (!date) return;
		var time = [date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()];
		this.model.time = time;
	},
	setTime() {
		if (!this.model.time) return;
		var date = this.model.get('value');
		if (!date) return;
		date.setHours.apply(date, this.model.time);
		this.model.trigger('change', this.model);
		console.log('time updated');
	},
	handleDayClick: function (view) {
		//console.log('handle', arguments);
		this.updateModel(view.model);
	},
	updateModel: function (model) {
		if (this.modelType == 'single') {

			this._updateSingleModel(model);

		} else {

			this._updateRangeModel(model);

		}
		model.collection.invoke('trigger', 'refresh');
		//console.log(this.model);
	},
	_updateSingleModel(model) {
		let getValue = model.get('value')
		this.model.set('value', getValue);
		this.setTime();
		model.collection.trigger('day:selected');
		let modelValue = this.model.getValue();
		this.triggerMethod('value:changed', modelValue);
		console.log('_updateSingleModel', getValue, modelValue, this.model);
	},
	_updateRangeModel(model) {
		var prop = this.model.clickCounter ? 'to' : 'from';
		if (prop == 'to') {
			var hash = {
				from: new Date((this.model.get('from') < model.get('value') ? this.model.get('from') : model.get('value')).valueOf()),
				to: new Date((this.model.get('from') < model.get('value') ? model.get('value') : this.model.get('from')).valueOf())
			}

			hash.from.setHours(0,0,0,0);
			hash.to.setHours(23,59,59,999);


			this.model.set(hash);
			this.triggerMethod('value:changed', this.model.getValue());
		} else {
			this.model.set({ from: model.get('value'), to: undefined });
		}
		//model.collection.trigger('day:selected');
		//model.trigger('selected', prop);
		this.model.clickCounter = !this.model.clickCounter;

		

		
		//this.model.trigger('property:selected');
	}
});

export default Datetime;