import { DropdownButton } from "../../../../coms/ui/DropdownButton";
import { HamburgerView } from "../../../../coms/ui/HamburgerView";

import { UiSchemaMoleculeView, UiSchemaAtomView } from "coms/schema";
import { PropertiesBoxView } from 'coms/ui/PropertiesBox';
import { editContactSchema } from './editContactSchema';
import { viewSchemaMixin } from "apis/schema";
import { CoreBbView } from "../../../../core/coreBbView";
import { CollectionView } from 'core/collection-view';
import { Collection, Model } from 'core';

import { BoxView } from 'coms/ui/Box';
import { BaseContactValueListItemView } from "../BaseContactValueListItemView";
import { modalsApi } from "../../../../apis/modals";

import { backendApi } from "../../../../apis/backend";
import { schemaEditApi } from 'apis/schema/edit';
import { urls } from 'coms/urls';
import { getValueByPath } from "../../../../utils";
import modals from '_libs/modals';
// import { SmartPropertiesLinesView } from "../PropertiesLineView";

// export const PropertiesBoxView = BoxView.extend({
// 	// thisClassName: 'employees-box with-borders fixed-height',
// 	// header: 'сотрудники',
// 	content: v => ({
// 		class: SmartPropertiesLinesView,
// 		model: v.model,
// 		modelValue: v.getOption('modelValue', true),
// 		modelHash: v.getOption('modelHash', true),
// 		modelSchema: v.getOption('modelSchema'),
// 		properties: v.getOption('properties', true),
// 		editEnabled: v.getOption('editEnabled'),
// 		editAction: v.getOption('editAction', false),
// 		setValueBySchemaApi: v.getOption('setValueBySchemaApi', true),
// 		onPropertyChange: (newvalue, propertySchema, modelValue, modelSchema) => v.triggerMethod('property:change', newvalue, propertySchema, modelValue, modelSchema)
// 	}),
// 	// action: v => ({
// 	// 	glyph: 'plus',
// 	// 	text: 'добавить сотрудников',
// 	// 	clickAction: () => v.addEmployeesAction()
// 	// }),
// });



const BuildingsListItemView = UiSchemaMoleculeView.extend({
	removeButton: true,
	childView: UiSchemaAtomView,
	className: 'ui2-molecule contact-building-item',
	content: {
		text: v => {
			const x = v.display('ownerInRealty.realtyName');
			return x;
		}
	},
	rightItems: [
		{
			topText: 'порядок сортировки:',
			text: v => v.model.get('order') || '&ndash;',
			bottomText: v => v.model.get('primary') ? 'основной' : '&ndash;',
			clickAction() {
				this.trigger('edit:click', this);
				//console.log('chpock', this.model.attributes)
			}
		}
	],
	childViewTriggers: {
		'remove:click':'remove:click',
		'edit:click':'edit:click'
	}
});
// UiSchemaMoleculeView.extend({
// 	content: {
// 		topText: v => v.display('value.type'),
// 		text: v => v.display('value.value')
// 	}
// });

const BuildingsList = CollectionView.extend({
	thisClassName: 'contact-buildings-list with-borders',
	childView: BuildingsListItemView,

	childViewTriggers: {
		'remove:click':'remove:click',
		'edit:click':'edit:click'
	}
});

export const EditContactBuildings = BoxView.extend({
	thisClassName: 'contact-buildings-container with-borders',
	header: 'прикреплён к зданиям',
	initialize() {
		const modelHash = this.getOption('modelHash', true) || {};
		const models = modelHash.inRealties	|| [];
		this.collection = new Collection(models);
		this.realties = this.getOption('realties', true);
		const isNew = !this.model;

		this.listenTo(this.collection, 'update remove change', () => {
			const values = this.collection.toJSON();
			if (isNew) {
				modelHash.inRealties = values;
			} else {
				this.model.set('inRealties', values);
			}
		});		

	},
	content: v => ({
		class: BuildingsList,
		modelHash: v.getOption('modelHash', true),
		collection: v.collection,
	}),
	contentSectionOptions: {
		childViewTriggers: {
			'remove:click':'remove:click',
			'edit:click':'edit:click'
		}
	},

	_removeBuilding(model) {
		const isNew = !this.model;
		if (isNew) {
			this.collection.remove(model);
			return;
		} else {
			modals.confirm('подтвердите удаление').then(() => {
				const rid = getValueByPath(model, 'ownerInRealty.realtyId');
				// const owner = this.getOption('owner', true);
				// const oid = owner.id;
				// const cid = this.model.id;				
				// const url =  urls.api(`v4/realties/owners/${oid}/contacts/${cid}/in-realties/${rid}`);
				const url = this._getOwnerInRealtyApiUrl(rid);
				const sync = () => {
					this.collection.remove(model);
				};
				backendApi.delete(url, { sync, relativeUrl: false });
			}, () => { console.log('NO') });
		}
		console.log('REMOVE BLD', model);
	},
	_getOwnerInRealtyApiUrl(rid) {
		const owner = this.getOption('owner', true);
		const oid = owner.id;
		const cid = this.model.id;
		const url =  urls.api(`v4/realties/owners/${oid}/contacts/${cid}/in-realties/${rid}`);
		return url;
	},
	_editContactOwnerInRealty(model) {
		const _thisView = this;
		const oir = getValueByPath(model, 'ownerInRealty') || {};
		const rid = getValueByPath(model, 'ownerInRealty.realtyId');
		const url = this._getOwnerInRealtyApiUrl(rid);
		const options = {
			inlineEdit: true,
			modelSchema: {
				primary: {
					label:'основной',
					valueType: 'boolean',
					//sourceValues: { true: 'да', false: 'нет' }
				},
				order: {
					label: 'порядок сортировки',
					valueType: 'number'
				}
			},
			value: {
				primary: model.get('primary'),
				order: model.get('order')
			}
		}
		schemaEditApi
		.editProperties(['primary', 'order'], options)
		.then(res => {
			if (!res.ok) { return; }
			return this._patchOwnerInRealty(url, res.value);
			// oir.order = res.value.order;
			// oir.primary = res.value.primary;
			// model.set(res.value);
			// console.log('chpock', url);
			// _thisView.render();
		}).then((data) => {
			const patchData = {
				order: data.order,
				primary: data.primary
			}
			// oir.order = data.order;
			// oir.primary = data.primary;
			model.set(patchData);
			_thisView.render();
		}, exc => console.error(exc));
	},
	_patchOwnerInRealty(url, attrs) {
		const patcher = new Model();
		patcher.url = url;
		return patcher.save(null, { method: 'patch', attrs })
	},

	_getExistRealtiesIds() {
		const realtiesIds = this.collection.reduce((memo, model) => {
			const oir = model.get('ownerInRealty') || {};
			memo[oir.realtyId] = 1;
			return memo;
		}, {});
		return realtiesIds;
	},

	_addBuilding() {
		const isNew = !this.model;

		const callback = (type, ids) => {
			if (type !== 'ok') { return; }
			console.log('IDS', ids);
			const rlts = ids.map(id => this.realties.get(id)).filter(f => !!f);
			if (isNew) {
				const add = rlts.map(m => ({ ownerInRealty: m.attributes }));
				this.collection.add(add);
			} else {

				const owner = this.getOption('owner', true);
				const oid = owner.id;
				const cid = this.model.id;
		
				const sync = (data) => {
					this.collection.add(data);
				};
				const url = urls.api(`v4/realties/owners/${oid}/contacts/${cid}/in-realties`);
				const rltIds = rlts.map(m => m.get('realtyId'));
				backendApi.post(rltIds, url, { sync, relativeUrl: false });
			}
			// console.log('ADD BLD', rlts);
			// console.log(this.model);
			// console.log(this.getOption('isNew'));
		}

		const realtiesIds = this._getExistRealtiesIds();

		const popupSetup = {
			sourceValues: this.realties,
			// valueType: 'string',
			header: 'прикрепление контакта к зданиям',
			editValueClassName: 'flexible-edit-modal no-padding-section',
			returnModel: true,
			
			// IMPLEMENT EXCLUDE VALUE
			viewFilter: v => {
				return v.model.get('realtyId') in realtiesIds === false;
				// return ['fc918c3f-8aaa-4d14-b396-ae27d2870e8c'].indexOf(v.model.id) === -1;
			},

			// value: members,
			multiple: true,
			controlType: 'select',
			// viewFilter: function (v) {
			// 	return v.model.get('value') != owner;
			// }			
		};
		console.warn('!!', popupSetup);
		modalsApi.oldEditProperty(popupSetup, callback);
		
	},
	action: v => ({
		glyph: 'plus',
		text: 'добавить здание',
		clickAction: () => {
			v._addBuilding();
			//console.log('chpock', v.getOption('modelHash', true))
		}
	}),
	childViewEvents: {
		'remove:click'(childView) {
			this._removeBuilding(childView.model);
		},
		'edit:click'(childView) {
			this._editContactOwnerInRealty(childView.model)
		}
	}
});