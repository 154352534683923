// define('m-realties-owners-collection', [
// 	'app-config',
// 	'ui-models-values-rest',
// 	'm-realties-owners-owner', 
// 	'app-paths', 
// 	'ui-models-values-collection', 
// ], function (
// 	cfg,
// 	Rest,
// 	OwnerModel, 
// 	paths, 
// 	VC, 
// 	) {

// });


import cfg from 'app-config';
//import paths from 'app-paths';
import Rest from 'ui/models/values/rest';
import OwnerModel from 'm/realties/owners/owner';
import VC from 'ui/models/values/collection';

import { BbeCollection } from 'core/bbe';


const OwnersCollection = BbeCollection.extend({
	model: OwnerModel
});



OwnersCollection.Rest = Rest.extend({

	modelParse: function(data) {
		return {
			id: data.id,
			value: data.id,
			label: data.name
		};
	},
	Collection: VC.Single,
	prepareData: function(txt) {
		var data = { text: txt };
		return data;
	},
	url: function() {
		let url = cfg.urls.apiV('realties/owners/searchByText');
		//console.log('#url', url);
		return url;
			//paths.api.realtiesOwnerSearch;
	},
	cardUrl: function () {
		return cfg.urls.apiV('realty-owners/' + this.getId());
		//paths.api.realtiesOwner + '/' + this.getId() +  "?view=reference";
	},
});


export default OwnersCollection;
