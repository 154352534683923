﻿//define('root-services', ['_libs/identity', 'app-config', 'link', 'behaviors-blockbuttons', 'behaviors-subdivision'], function (identity, cfg, link, BlockbuttonsBehavior, SubdivisionBehavior) {});


import BlockbuttonsBehavior from 'behaviors/blockbuttons';
import SubdivisionBehavior  from 'behaviors/subdivision';
import { MneView } from 'core/mne';


var NewLayout = MneView.extend({
	template: _.noop,
	behaviors: [SubdivisionBehavior, BlockbuttonsBehavior],
});

export default NewLayout;