import serverCfg from 'server-cfg'; // alias
import appCfg from 'app-config'; // alias
import Token from '../assets/identity'; //'assets-identity-token'
import identity from '_libs/identity'; //'_libs/identity'
import refs from 'references'; // 'references'
import paths from 'app-paths';
import appData from './m/appData'; //'m-appData'
import { newToken, identityPromise, authPromise, svcAuthPromise, svcAuthAction } from 'mods/acc';




export function _buildStartPrommises() {
	console.log(':::: serverCfg svcwebid', serverCfg.svcWebId);
	
	

	let tokenPromise = Promise.resolve(); //Token.new(appCfg.apiUrl('acc/token'), serverCfg.isNative, serverCfg.svcWebId);
	let appDataPromise = appData.authData.whenReady();
	
	let newTokenPromise = newToken.apiPromise;
	// newToken.refreshAsync();
	let identityPromise = identity.apiPromise;
	// identity.getPromise();

	// let identityPromise = newTokenPromise.then(() => {
	// 	console.log(' == == == :: ', newToken.get('access_token'));
	// 	return identity.populate();
	// });

	let promisesArray = [
		newTokenPromise,
		identityPromise,
		refs.getOldPromise(), 
		// tokenPromise,
		paths.readyPromise,
		appDataPromise
	];
	
newToken.refreshAsync().then(() => identity.fetch());
identity.authSvcPromise.then(() => {	
	appData.authData.initAndFetch();
});

	// Promise.all([identityPromise, paths.readyPromise]).then(() => {
	// 	appData.authData.initAndFetch();
	// });
	
	let startPrommises = Promise.all(promisesArray);
	startPrommises.then(() => console.log('URA!!!!!!!!'))
	console.warn('PROMS', promisesArray);
	return startPrommises;

}

export async function buildStartPrommises() {

	await identityPromise;
	await paths.readyPromise;
	await refs.getOldPromise();

	await svcAuthAction(() => appData.authData.initAndFetch())
	
	console.log('-start promises-');
}