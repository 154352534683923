module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="block">\n	<header class="small bg-warning">клиент</header>\n	<ul class="client-region">\n		<li class="list-lbl"><div><div data-placeholder="название компании"><input name="company-name" class="form-control" /></div></div></li>\n	</ul>\n</div>\n\n<div class="block">\n	<header class="small bg-info">контактные данные</header>\n	<ul>\n		<li class="list-lbl"><div><div data-placeholder="имя"><input data-name="person-name" class="form-control" value="'+
((__t=( personName ))==null?'':__t)+
'" /></div></div></li>\n		<li class="person-phone1"><div></div></li>\n		<li class="person-phone2"><div></div></li>\n		<li class="list-lbl"><div><div data-placeholder="email"><input type="email" data-name="person-email" class="form-control" value="'+
((__t=(personEmail))==null?'':__t)+
'" /></div></div></li>\n	</ul>\n</div>\n\n';
}
return __p;
};
