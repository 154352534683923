// import cfg from 'app-config';
// import refs from 'references';
// import { BbModel, BbCollection } from 'vendors';

// const trueWords = ["true", "on", "да", "yes"];
// const SchemaModel = BbModel.extend({
// 	defaults: {
// 		order: 0,
// 	},
// 	fromString(stringValue) {

// 		if (stringValue == null) return stringValue;
// 		if (stringValue === '') return null;

// 		let value;

// 		let valueType = this.get('valueType');
// 		if (valueType == 'number') {

// 			if (typeof(stringValue) == "number") {
// 				return stringValue;
// 			}
// 			stringValue = stringValue.replace(',', '.');
// 			value = parseFloat(stringValue, 10);
// 			return isNaN(value) ? null : value;

// 		} else if (valueType == 'boolean') {

// 			if (typeof(stringValue) == "boolean") {
// 				return stringValue;
// 			}
// 			return trueWords.indexOf(stringValue.toLowerCase()) > -1;

// 		} else if (valueType == "datetime") {

// 			if (typeof(stringValue) == "date") {
// 				return stringValue;
// 			}
// 			value = new Date(stringValue);
// 			return isNaN(value.valueOf()) ? void 0 : value;
// 		}
// 		else {
// 			return stringValue;
// 		}
// 	},
// 	toValue(value) {
// 		let valueType = this.get('valueType');
// 		if (valueType == 'enum') {
// 			let enumType = this.get('valueSubtype');
// 			enumType = enumType[0].toLowerCase() + enumType.substring(1);
// 			return refs.enum(enumType, value);
// 		} else {
// 			return value;
// 		}
// 	}
// });

// const Collection = BbCollection.extend({
// 	model: SchemaModel,
// 	initialize() {
// 		this._fetched = false;
// 		// this.once('sync', () => {
// 		// 	this._fetched = true;
// 		// 	this.onAfterSync();
// 		// });
// 	},
// 	//model: Model,
// 	url: function () {
// 		return cfg.urls.apiV('realties/realties/schema');
// 		//return '/svc/v04/actualization/offers';
// 	},
// 	fetch() {
// 		if (this._fetched) {
// 			return Promise.resolve(this);
// 		} else {
// 			return BbCollection.prototype.fetch.call(this).then(arg => this.onAfterFetch(arg));
// 		}
// 	},
// 	onAfterFetch(arg) {
// 		console.log('ON AFTER SYBC')
// 		this._fixRootGroup();

// 		this.byParentId = this.groupBy('parentId');

// 		this.groups = this.byParentId.undefined; //.filter(f => f.get('isGroup'));
// 		delete this.byParentId.undefined;

// 		this._fetched = true;

// 		return arg;
// 	},
// 	_fixRootGroup() {
// 		let root = this.get('root');
// 		if (!root) {
// 			root = this.add({ id: 'root', label: 'Основные х-ки', fullLabel: 'Основные х-ки', isGroup: true, order: 10000 });
// 			this.filter(f => !f.get('isGroup') && !f.get('parentId')).forEach(model => {
// 				model.set('parentId', 'root');
// 			});
// 		}
// 	},
// });

// export default Collection;



import cfg from 'app-config';
import { SchemaCollection } from '../entity-list-common/edit';


const Collection = SchemaCollection.extend({
	url: function () {
		return cfg.urls.apiV('realties/realties/schema');
	},
});

const realtiesSchemaCollection = new Collection();

export default realtiesSchemaCollection;