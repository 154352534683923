import { CustomsView, View } from "base/views";

const defaultsTexts = {
    resolve: 'OK',
    reject: 'CANCEL',
    rejectHard: 'DROP'
}

const ButtonView = View.extend({
    className() {
        return this.getOption('type');
    },
    tagName: 'button', 
    template: _.template('<%= text %>'),
    templateContext() {
        return {
            text: this.getOption('text')
        }
    },
    events: {
        click(e) {
            if (this.$el.prop('disabled'))
                return;
            let type = this.getOption('type');
            let action = 'modal:' + type;
                //eventToMethod('modal:' + type);
            this.trigger('before:action:start', action);
            this.trigger('action:start', action);
        }
    }
});

const Separator = View.extend({ 
    template: false, 
    className: 'separator' 
});

export const PromiseButtons = CustomsView.extend({
    tagName: 'footer',
    className: 'new-flex-modal-promise-buttons',

    initialize() {
        this.on('before:action:start', this.disable);
        this.on('action:success action:fail', this.enable);
        console.log('promise-buttons', this);
    },

    _addButton(type, views) {
        let text = this._getText(type);
        if (!text) return;
        let buttonView  = new ButtonView({ text, type });
        if (views) {
            views.push(buttonView);
        }
        return buttonView;
    },


    _getText(type) {
        let text = this.getOption(type + 'Text');

        if (text)
            return text;

        if (text === false)
            return;

        return defaultsTexts[type];
    },

    getCustomViews() {
        let views = [];

        this._addButton('resolve', views);
        this._addButton('reject', views);
        this._addButton('rejectHard', views);

        if (views.length > 1) {
            views.splice(views.length - 1, 0, new Separator());
        }

        return views;
    },


    _toggleDisabled(value) {
        this.children._views.forEach(view => view.$el.prop('disabled', value));
    },

    disable() {
        this._toggleDisabled(true);
    },
    enable() {
        this._toggleDisabled(false);
    },

    childViewTriggers: {
        'action:start':'action:start',
        'before:action:start':'before:action:start'
    }

});