﻿//define('jquery-ajax-patch', [], function () {});


$.patch = function (url, data, options) {
		
	var patchOptions = _.extend({},
	{
		url: url,
		data: data,
		type: 'patch',
		dataType: 'json',
		contentType: "application/json; charset=UTF-8",
	}, options);

	return $.ajax(patchOptions);
}