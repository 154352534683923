import { Model as BaseModel, _ } from 'vendors';
import { invokeValue } from 'utils/invoke';
import { mergeAttributesMixin } from './mixins/mergeAttributes';
import { setValueByPath, getValueByPath, flatObject, unFlatObject } from 'utils/object-manipulations';
// import { modelSchemaApi, propertySchemaApi } from 'apis/schema';
import { propertySchemaApi } from 'apis/schema/propertySchemaApi';
import { modelSchemaApi } from 'apis/schema/modelSchemaApi';
import { mergeUrls } from './common';

export const Model = BaseModel.extend({
    ...mergeAttributesMixin,
		fetch() {
			this._fetching = true;
			this.once('sync', () => {
				this._fetching = false;
				this._lastFetch = Date.now();
				this._fetchCounts = (this._fetchCounts || 0) + 1;
			});
			return BaseModel.prototype.fetch.apply(this, arguments);
		},

		setByPath(attrs, value, options) {
			if (attrs == null) {
				return;
			}

			if (typeof attrs !== 'object') {
				attrs = { [attrs]: value };
			} else {
				options = value;
				attrs = flatObject(attrs);
				value = undefined;
			}

			// if (arguments.length === 2) {
			// 	attrs = { [attrs]: value }
			// } else {
			// 	attrs = flatObject(attrs);
			// }
			Object.keys(attrs).forEach(key => {
				setValueByPath(this, key, attrs[key], options);
			});

		},

		getByPath(path) {
			return getValueByPath(this, path);
		},

		smartGet(path) {
			if (this.flat) {
				return this.get(path);
			} else {
				return this.getByPath(path);
			}
		},

		smartSet() {
			if (this.flat) {
				return this.set.apply(this, arguments);
			} else {
				return this.setByPath.apply(this, arguments);
			}
		},

		display(path, options = {}) {
			if (path && typeof path === 'object') {
				return displayObject(this, path, options);
			}
			const schema = this.getSchema();
			if (schema) {
				return propertySchemaApi.displayValue(path, this, schema, options)
			}
			const value = this.getByPath(path);
			if (options.displayValue) {
				return options.displayValue(value, this);
			}

			if (value == null) {
				return '';
			}

			return value;

		},
		label(path, options = {}) {
			const schema = this.getSchema();
			if (schema) {
				return propertySchemaApi.getLabel(path, this, schema, options)
			}
			return '';
		},
		getSchema() {
			return modelSchemaApi.getModelSchema(this);
		},
		send(options = {}) {
			let backboneMethod;
			let { attrs, method, addUrl, url } = options;
			if (attrs) {
				switch((method || '').toLowerCase()) {
					case 'patch':
						backboneMethod = 'patch';
					case 'put':
						backboneMethod = 'create';
					case 'post':
						backboneMethod = 'update';
				}
			}

			options.url = mergeUrls(url, invokeValue(this.url, this, this), addUrl);

			// if (!url && addUrl) {
			// 	let thisUrl = invokeValue(this.url, this, this);
			// 	if (thisUrl) {
			// 		const hasEndSlash = thisUrl.endsWith('/');
			// 		const hasStartSlash = addUrl.startsWith('/');
			// 		if (hasEndSlash && hasStartSlash) {
			// 			addUrl = addUrl.substring(1);
			// 		} else if (!hasEndSlash && !hasStartSlash) {
			// 			addUrl = '/' + addUrl
			// 		}
			// 		options.url = thisUrl + addUrl;
			// 	}
			// }
			return this.sync(backboneMethod, this, options);
		},
		isFetched() {
			return !!this._fetchCounts;
		}
}, {
	defineSchema(schema, name) {
		this.schemaName = name;
		this.modelSchema = modelSchemaApi.define(this, schema);
	}
});

function displayObject(model, arg, options) {
	const isArray = Array.isArray(arg);
	const ready = _.reduce(arg, (hash, value, index) => {
		const path = isArray ? value : index;
		const hashKey = value;
		hash[hashKey] = model.display(path, options);
		return hash;
	}, {});
	return unFlatObject(ready);
}