module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="realty-thumb">\n	<img src="'+
((__t=(thumb ))==null?'':__t)+
'" />\n</div>\n<div class="realty-info">\n	<span></span>\n	<div>\n		<div class="name">'+
((__t=( _m.name ))==null?'':__t)+
'</div>\n		<div class="details"><div>'+
((__t=( _m.prefix ))==null?'':__t)+
', класс '+
((__t=( _m.cls))==null?'':__t)+
'</div><address>'+
((__t=( _mv('address.fullAddress')))==null?'':__t)+
'</address></div>\n	</div>\n</div>';
}
return __p;
};
