import { _, $, Backbone, Radio, Collection } from 'vendors';

//import Bbe from './namespace'; //'assets-backbone-namespace'


var radio = Radio.channel('loudsync');

var defaultLoudOptions = {};

function exposeMethod(prom, method) {
	if (method in prom === false) {
		prom[method] = function (arg) {
			if (typeof arg === 'function') {
				return arg();
			}
		}
	}
}

function oldifyPromise(prom) {
	exposeMethod(prom, 'always');
	exposeMethod(prom, 'done');
	return prom;
}

function prepareOptions(context, func, args) {


	var data;
	var options;
	var loudOptions = { func: func, context: context };

	if (['create', 'save', 'patch'].indexOf(func) >= 0) {  //== 'create' || func == 'save') {
		var key = args[0];
		if (key == null || typeof key === 'object') {
			data = args[0];
			options = args[1];
		}
		else {
			(data = {})[args[0]] = args[1];
			options = args[2];
		}
	} else {
		options = args[0]; // || {};
	}

	_.extend(loudOptions, defaultLoudOptions, (options || {}).loud);

	if ((func == 'save' || func == 'create') && !loudOptions.message)
		loudOptions.message = 'данные сохраняются...'

	if (typeof options === 'object' && 'loud' in options)
		delete options.loud;

	return {
		data: data,
		context: context,
		loud: loudOptions,
		options: options || {},
	};

}

function abstractLoudFunc(context, func, _args) {

	var loudOptions = prepareOptions(context, func, _args);


	var apiPromise = context.apiPromise = $.Deferred();
	

	//var xhr;
	let returnPromise;

	if (func == 'fetch') {
		context.setFetchStatus('fetching');
	}

	if (context instanceof Collection && func == 'create') {
		returnPromise = $.Deferred();
		var opts = loudOptions.options;

		var success = opts.success;
		var error = opts.error;
		opts.success = function () {
			returnPromise.resolve();
			if (_.isFunction(success))
				success.apply(this, arguments);
		}
		opts.error = function (model, _xhr) {

			loudOptions.xhr = _xhr;
			returnPromise.reject();
			if (_.isFunction(error))
				error.apply(this, arguments);
		}

		context.create(loudOptions.data, opts);

	} else if (func == 'save' || func == 'patch') {

		if (loudOptions.options.wait == null)
			loudOptions.options.wait = true;

		returnPromise = context[func](loudOptions.data, loudOptions.options);
		loudOptions.xhr = returnPromise;
	}
	else {
		returnPromise = context[func](loudOptions.options);
		loudOptions.xhr = returnPromise;
	}

	if (func == 'fetch') {
		returnPromise.then(function () {
			context.setFetchStatus('fetched');
		});
	}


  if (returnPromise !== false) {
		radio.trigger('request:starts', loudOptions);

		// if (!returnPromise.always) {
		// 	returnPromise.always = function(arg) {
		// 		if (typeof arg === 'function') {
		// 			return arg();
		// 		}
		// 	}
		// }

		// if (!returnPromise.done) {
		// 	returnPromise.done = function(arg) {
		// 		if (typeof arg === 'function') {
		// 			return arg();
		// 		}
		// 	}
		// }		

		// const hasAlways = !!returnPromise.always;

		const doAlways = function () {
			radio.trigger('request:always', loudOptions);
		}

		returnPromise = returnPromise
			.then(function () {
				radio.trigger('request:done', loudOptions);
				// if (!hasAlways) {
				// 	doAlways();
				// }
				apiPromise.resolve();
			}, function () {
				radio.trigger('request:fail', loudOptions);
				apiPromise.reject();
				// if (!hasAlways) {
				// 	doAlways();
				// }
			});

			returnPromise = oldifyPromise(returnPromise);
			returnPromise.always(() => doAlways());
			// if (hasAlways) {
			// 	retprom = retprom.always(doAlways);
			// }
			
	}

	return returnPromise;

};

export const loudModelMixin = {
    getFetchStatus: function () { return this.fetchStatus; },
    setFetchStatus: function (status) {
        this.fetchStatus = status; 
        this.trigger('fetch:status:change', status, this);
    },
    loudFetch: function () {
        return abstractLoudFunc(this, 'fetch', arguments);
    },
    loudSave: function () {
        return abstractLoudFunc(this, 'save', arguments);
    },
    loudDestroy: function () {
        return abstractLoudFunc(this, 'destroy', arguments);
    },
    loudPatch: function () {
        return abstractLoudFunc(this, 'patch', arguments);
    },
    getApiPromise: function () {
        return this.apiPromise;
    },
    send: function (method, url, data) {
        !_.isString(data) && (data = JSON.stringify(data));
        return Backbone.ajax({
            url: url,
            method: method,
            data,
            contentType:'application/json'
        });
    }
}

export const loudCollectionMixin = {
    getFetchStatus: function () { return this.fetchStatus; },
    setFetchStatus: function (status) {
        this.fetchStatus = status;
        this.trigger('fetch:status:change', status, this);
    },
    loudFetch: function () {
        return abstractLoudFunc(this, 'fetch', arguments);
    },
    loudCreate: function () {
        return abstractLoudFunc(this, 'create', arguments);
    },
    getApiPromise: function () {
        return this.apiPromise;
    },
}
