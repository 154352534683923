﻿//define('ui-controls-datetime2-selectYearView', ['behaviors', 'ui-controls-datetime2-selectYearItemView'], function (beh, childView) {});

//import beh from 'behaviors';
import childView from './select-year-item-view';
import template from './selectYearView.html';
import { BbeCollection } from 'core/bbe';
import { MneView, MneNextCollectionView } from 'core/mne';

var View = MneView.extend({
	//template: '#tmpl-ui-controls-datetime2-selectYearView',
	template,
	regions: { 'years': '.datetime-year-list', },
	initialize: function () {
		this.step = 20
		this.startYear = (this.getOption('year') || this.model.get('year') || (new Date()).getFullYear()) - 10;
		this.collection = new BbeCollection();
		this.resetCollection();
	},
	resetCollection: function () {
		var x = []; x[this.step - 1] = true;
		var start = this.startYear;

		var models = _(x).map(function (v, id) { return { id: start + id } });
		this.collection.reset(models);
		var label = this.collection.first().id + ' &mdash; ' + this.collection.last().id;
		this.$('.years-label').html(label);
	},
	events: {
		'click .decrement': function (e) { this.showAnotherYears(-1); },
		'click .increment': function (e) { this.showAnotherYears(1); },
	},
	showAnotherYears: function (where) {
		this.startYear += this.step * where;
		this.resetCollection();
	},
	showYears: function () {
		var view = new MneNextCollectionView({
			collection: this.collection,
			childView: childView,
		});
		this.showChildView('years', view);
	},
	onRender: function () {
		this.showYears();
	},
	childViewTriggers: {
		'childview:selected': 'year:change'
	},
	templateContext: function () {
		return {
			first: this.collection.first().id,
			last: this.collection.last().id,
		}
	},
});


export default View;