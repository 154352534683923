export const modelValueMixin = {
	getModelValue() {
		let value = this.getOption('modelValue', true);
		if (value && typeof value === 'object') {
				return value;
		}
		value = this.getOption('modelHash', true);
		if (value && typeof value === 'object') {
				return value;
		}
		return this.model;
	}
}