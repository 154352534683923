module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<ul>\n	<li class="list-lbl"><small>номер помещения</small><span>'+
((__t=( _ndash(_m.info.roomNumber)))==null?'':__t)+
'</span></li>\n	<li class="list-lbl"><small>назначения</small><span>'+
((__t=( _enum('realtyPurposes',_m.info.purposes) ))==null?'':__t)+
'</span></li>\n	<li class="list-lbl"><small>текущее назначение</small><span>'+
((__t=( _enum('realtyPurposes',_m.info.currentPurpose) ))==null?'':__t)+
'</span></li>\n	<li class="list-lbl halfed"><small>вся площадь</small><span>'+
((__t=( _m.info.squareTotal ))==null?'':__t)+
'</span></li>\n	<li class="list-lbl halfed"><small>деление от</small><span>'+
((__t=( _ndash(_m.info.squareMin) ))==null?'':__t)+
'</span></li>\n	<li class="list-lbl halfed"><small>состояние</small><span>'+
((__t=( _enum('realtyObjectStates',_m.info.state)))==null?'':__t)+
'</span></li>\n	<li class="list-lbl halfed"><small>планировка</small><span>'+
((__t=( _enum('spaceLayouts',_m.info.spaceLayout)))==null?'':__t)+
'</span></li>\n	<li class="list-lbl halfed"><small>этаж</small><span>'+
((__t=( _ndash(_m.info.floor) ))==null?'':__t)+
'</span></li>\n	<li class="list-lbl halfed"><small>кол. этажей в помещении</small><span>'+
((__t=( _m.info.floorsCount ))==null?'':__t)+
'</span></li>\n	<li class="list-lbl halfed"><small>количество кабинетов</small><span>'+
((__t=( _ndash(_m.info.roomsCount) ))==null?'':__t)+
'</span></li>\n	<li class="list-lbl halfed"><small>высота потолков</small><span>'+
((__t=( _ndash(_m.info.floorsHeight) ))==null?'':__t)+
'</span></li>\n	<li class="list-lbl"><small>мокрая точка</small><span>'+
((__t=( _enum('waterPoints',_m.info.waterPoint)))==null?'':__t)+
'</span></li>	\n	<li class="list-lbl"><small>опции</small><span>'+
((__t=( _enum('realtyObjectInfoFeatures',_m.info.features)))==null?'':__t)+
'</span></li>\n	<li class="list-lbl"><small>электричество, кВт</small><span>'+
((__t=( _ndash(_m.info.power)))==null?'':__t)+
'</span></li>\n	<li class="list-lbl"><small>заметка</small><span>'+
((__t=( _ndash(_m.info.note)))==null?'':__t)+
'</span></li>\n</ul>';
}
return __p;
};
