// define('svc/actualization/realties-list/realty/edit/properties/group-view', [
//     'svc/actualization/realties-list/realty/edit/properties/properties-view',
// ], function(
//     PropertiesView
// ) {});
import { BbCollection } from 'vendors';
import PropertiesView from './properties-view';
import { MnView } from 'vendors';
export default MnView.extend({
    className: 'propertygroup-item',
    template: _.template('<header><span><%= label %></span></header><section></section>'),

    regions: {
        items: '> section'
    },
    initialize() {
        let schemas = this.schemas = this.getOption('schemas');
        this.entity = this.getOption('entity');
        this.collection = new BbCollection(schemas.byParentId[this.model.id]);
    },
    onRender() {
        let view = new PropertiesView({ 
            collection: this.collection,
            model: this.entity
        });
        this.propsView = view;
        this.showChildView('items', view);
    }

});