﻿//define('ui-controls-datetime2-selectMonthItemView', ['behaviors', 'ui-controls-datetime2-selectItemView'], function (beh, SelectItemView) {});

import SelectItemView from './select-item-view';
import { moment } from 'vendors';

var View = SelectItemView.extend({
	getLabel: function () {
		return moment().month(this.model.id).format('MMMM');
	},
	isCurrent: function () {
		return (new Date()).getMonth() == this.model.id;
	},
});

export default View;
