import { View, TextView } from 'core';
import { HamburgerView } from "coms/ui/HamburgerView";
import { _ } from 'vendors';
import { DropdownButton } from 'coms/ui/DropdownButton';
import refs from 'references';

const SearchInput = View.extend({
	initialize() {
		console.log('- init filter -', this.cid);
		this.filterModel = this.getOption('filterModel');
		this.handleInput = _.debounce(this._handleInput.bind(this), 300);
		this.delegate('input', 'input', this.handleInput);
	},
	baseClassName: 'simple-search-input',
	template: '<input class="form-control" /><i class="fa fa-search"></i>',
	ui: {
		input: 'input'
	},
	_handleInput() {
		console.log('handled input')
		const text = this.getInputValue();
		this.filterModel.setSearchText(text);
	},
	getInputValue() {
		return this.ui.input.val();
	}
});

const FilterDropDown = DropdownButton.extend({
	buttonWithReset: true,
	emptyLabel: '&mdash;',
	value() {
		const fm = this.getOption('filterModel');
		const property = this.getOption('property', true);
		return fm.get(property);
	},
	buildValuesCollection(_values, CollectionClass) {
		const values = this.buildValues(_values);
		const emptyValue = this.getOption('emptyValue', true);
		if (emptyValue) {
			values.unshift({ id: 'empty', label: emptyValue, value: undefined });
		}	
		return new CollectionClass(values);
	},	
	onUserInput(value) {
		const fm = this.getOption('filterModel');
		const property = this.getOption('property', true);
		fm.set(property, value);
	}
});

const FilterEnumDropdown = FilterDropDown.extend({
	emptyValue() {
		if (this.getOption('multiple', true)) {
			return;
		}
		return 'Все';
	},
	values() {
		let name = this.getOption('enumName', true);
		if (name) {
			return refs.enum(name);
		}
		let obj = this.getOption('enumValues', true);
		if (obj) {
			return obj
		}
	},
});

const DropdownLabel = TextView.extend({ baseClassName: 'header' });

const LabeledDropdown = HamburgerView.extend({
	baseClassName: 'labeled-dropdown',
	childrenViews:[
		DropdownLabel,
		FilterEnumDropdown,
	],
	childViewOptions() {
		return {
			buttonWithReset: this.getOption('buttonWithReset'),
			property: this.getOption('property'),
			enumName: this.getOption('enumName'),
			enumValues: this.getOption('enumValues'),
			multiple: this.getOption('multiple'),
			emptyValue: this.getOption('emptyValue'),
			text: this.getOption('text'),
			filterModel: this.getOption('filterModel')
		}
	}
});


export const ObjectsFilter = HamburgerView.extend({
	baseClassName: 'objects-filter flex-row centered',
	childrenViews: [
		SearchInput,
		{ class: LabeledDropdown, property: 'object.neededActualize', enumValues: { 'notActualized': 'Требуется актуализация' }, text: 'Вид списка', buttonWithReset: false },
		{ class: LabeledDropdown, property: 'object.status', enumName: 'realtyObjectStatuses', text: 'Статус площади', buttonWithReset: false },
		{ class: LabeledDropdown, property: 'offer.status', enumName: 'realtyOfferStatuses', text: 'Статус предложения' },
		{ class: LabeledDropdown, property: 'offer.operation', enumName: 'realtyOperations', text: 'Операция' },
	],
	childViewOptions() {
		return {
			selector: this.getOption('selector'),
			filterModel: this.getOption('filterModel')
		}
	},
});