﻿import { isNotValue } from 'utils/is';
import { padNumber } from 'utils/number';
import { moment } from 'vendors';

//CONSTANTS
(function (D) {

	D.MILLISECOND = 1;
	D.MILLISECONDS = n => D.MILLISECOND * n;
	
	D.SECOND = D.MILLISECOND * 1000;
	D.SECONDS = n => D.SECOND * n;
	
	D.MINUTE = D.SECOND * 60;
	D.MINUTES = n => D.MINUTE * n;

	D.HOUR = D.MINUTE * 60;
	D.HOURS = n => D.HOUR * n;

	D.DAY = D.HOUR * 24;
	D.DAYS = n => D.DAY * n;

	D.NOW = 'now';
	D.FUTURE = 'future';
	D.PAST = 'past';

	D.MonthNames = {
		'ru': [
			{ short: 'янв', full: 'январь', genetive: 'января' },
			{ short: 'фев', full: 'февраль', genetive: 'февраля' },
			{ short: 'мар', full: 'март', genetive: 'марта' },
			{ short: 'апр', full: 'апрель', genetive: 'апреля' },
			{ short: 'май', full: 'май', genetive: 'мая' },
			{ short: 'июн', full: 'июнь', genetive: 'июня' },
			{ short: 'июл', full: 'июль', genetive: 'июля' },
			{ short: 'авг', full: 'август', genetive: 'августа' },
			{ short: 'сен', full: 'сентябрь', genetive: 'сентября' },
			{ short: 'окт', full: 'октябрь', genetive: 'октября' },
			{ short: 'ноя', full: 'ноябрь', genetive: 'ноября' },
			{ short: 'дек', full: 'декабрь', genetive: 'декабря' },
		],
	};

})(Date);


//STATIC extensions
(function (D) {

	function validateNum(n) {
		if (isNaN(n)) throw "argument is not a number";
	}
	
	function validate(instance, soft) {
		if (!(instance instanceof Date) || isNaN(instance.valueOf()))
			if (soft) {
				return false;
			}
			else
				throw Error("argument is not a valid date");
		else
			return true;
	}

	//Parse from string
	D.fromString = function (str) {
		if (isNotValue(str)) return;

		if (str instanceof Date)
			return str;

		var pat = /(\d{4}).(\d{2}).(\d{2})((.)(\d{2}):(\d{2})(:(\d{2})(\.(\d{1,3}))?)?)?(Z||[+-]\d{2}:\d{2})?/;

		if (!pat.test(str))
			return;

		var matches = str.match(pat);

		var year = matches[1];
		var month = matches[2];
		var day = matches[3];

		var hour = matches[6] || '00';
		var minute = matches[7] || '00';
		var seconds = matches[9] || '00';
		var mseconds = matches[11] || '000';

		//var isTime = !!matches[4];
		//var isSecTime = !!matches[8];
		//var isMiliTime = !!matches[10];
		//var isSoneOffset = !!matches[12];

		var zoneOffset = (function (litera, _offset) {


			if (litera == 'T' && !_offset) //litera = ' ';
				return 'Z';
			if (litera == ' ' && _offset)
				return _offset;

			if ((litera || ' ') == ' ' && !_offset) {
				var t = new Date();
				var offset = t.getTimezoneOffset();
				var sing = offset < 0 ? '+' : '-';
				offset = Math.abs(offset);
				var h = padNumber(parseInt(offset / 60, 10), 2);
				var m = padNumber(parseInt(offset % 60, 10), 2);
				return sing + h + ':' + m;
			}
			return 'Z';
		})(matches[5], matches[12]);



		var newstr = [
			[year, month, day].join('-') + 'T',
			[hour, minute, seconds].join(':') + '.' + mseconds,
			zoneOffset
		].join('');

		var d = new Date(newstr);
		return d;

	};

	D.nowDate = function () {
		return new Date();
	}
	D.today = function () {
		return (new D()).toDay();
	}
	D.tomorrow = function () {
		return (new D()).toDay().addDays(1);
	}
	D.yesterday = function () {
		return (new D()).toDay().addDays(-1);
	}

	D.thisweek = function () {
		return (new D()).toWeek();
	}
	D.lastweek = function () {
		return (new D()).toWeek().addDays(-7);
	}
	D.nextweek = function () {
		return (new D()).toWeek().addDays(7);
	}

	D.thismonth = function () {
		return (new D()).toMonth();
	}
	D.lastmonth = function () {
		return (new D()).toMonth().addMonths(-1);
	}
	D.nextmonth = function () {
		return (new D()).toMonth().addMonths(1);
	}

	D.valid = function (d) {
		return (d instanceof Date && !isNaN(d.valueOf()));
	}


	D.when = (function () {
		function DatesWhen(d, dnow) {
			validate(d);
			var now = dnow || new Date();

			return ValuesWhen(d.valueOf(), now.valueOf());
		}
		function ValuesWhen(d, now) {

			validateNum(d);
			validateNum(now);

			return d === now ? D.NOW
				: d > now ? D.FUTURE
					: D.PAST

		}
		function DifValueWhen(v) {
			validateNum(v);

			return v == 0 ? D.NOW
				: v > 0 ? D.FUTURE
					: D.PAST;

		}

		return function (arg1, arg2) {
			if (arguments.length == 0) throw "arguments missing";
			if (arguments.length == 1) {
				if (arg1 instanceof Date)
					return DatesWhen(arg1);
				else if (typeof arg1 == 'number')
					return DifValueWhen(arg1);
				else
					throw "wrong argument type";
			}
			else {
				if (arg1 instanceof Date)
					return DatesWhen(arg1, arg2);
				else if (typeof arg1 == 'number')
					return ValuesWhen(arg1, arg2);
				else
					throw "wrong argument type";
			}
		};
	})();

	D.absoluteDifference = function (d1, d2) {
		validate(d1);
		(d2 instanceof Date) || (d2 = new Date());
		validate(d2);

		var dif = Math.abs(d1.valueOf() - d2.valueOf());
		var fn = Math.floor;
		var r = {
			ms: dif,
			seconds: fn(dif / Date.SECOND),
			minutes: fn(dif / Date.MINUTE),
			hours: fn(dif / Date.HOUR),
			days: fn(dif / Date.DAY),
		}
		return r;
	}
	D.calendarDifference = function (d1, d2) {

		validate(d1);
		(d2 instanceof Date) || (d2 = new Date());
		validate(d2);

		var y1 = d1.getFullYear();
		var y2 = d2.getFullYear();
		var years = y1 - y2;
		var m1 = d1.getMonths();
		var m2 = d2.getMonths();
		var months = m1 - m2;
		var da1 = d1.getDays();
		var da2 = d2.getDays();

		var weeks = parseInt((Math.abs(d1.toWeek().getDays() - d2.toWeek().getDays()) / 7), 10);
		var days = da1 - da2;

		var r = {
			years: Math.abs(years),
			months: Math.abs(months),
			days: Math.abs(days),
			weeks: weeks
		};
		return r;
	}

	D.info = function (d1, d2) {
		if (typeof d1 === 'string') d1 = new Date(d1);
		var d1valid = validate(d1, true);
		(d2 instanceof Date) || (d2 = new Date());
		var d2valid = validate(d2, true);

		if (!d1valid || !d2valid)
			return {
				dateInvalid: !d1valid,
				compareWithInvalid: !d2valid
			}

		return {
			date: d1,
			compareWith: d2,
			when: D.when(d1),
			absolute: D.absoluteDifference(d1, d2),
			calendar: D.calendarDifference(d1, d2),
		}
	}

	D.infoLabel = function (date, opts) {
		opts || (opts = {});
			
		var info = D.info(date);
		if (info.dateInvalid) return '';

		var mdate = moment(info.date);

		if (info.calendar.days == 0)
			return opts.noTime ? mdate.format('Do') : mdate.format('LT');

		else if (info.calendar.months == 0 && info.calendar.days < 7)
			return mdate.format('Do') + (opts.noTime ? '' : ', в ' + mdate.format('LT'));

		else if (info.calendar.years == 0)
			return mdate.format('Do MMM');

		else
			return mdate.format('l');
	}

	D.info.label = D.infoLabel;

})(Date);

//Instance extensions
(function (d) {

	var getTimeDefaults = {
		nomilliseconds: true,
	};

	d.getTimeString = function (opts) {
		var options = _.extend(getTimeDefaults, opts);
		if (!this.valid()) throw 'Date value is NaN';
		var res = [];
		var ms = padNumber(this.getMilliseconds(), 3);

		if (!options.nohours)
			res.push(padNumber(this.getHours(),2))
		if (!options.nominutes)
			res.push(padNumber(this.getMinutes(),2))
		if (!options.noseconds)
			res.push(padNumber(this.getSeconds(),2))

		var time = res.join(':');
		if (!options.nomilliseconds)
			time += '.' + ms;

		return time;
	}

	d.setTimeFromString = function (str) {
		if (!this.valid()) throw 'Date value is NaN';
		if (!str) return;

		var data = str.split(':');
		if ((data[0] || '').length > 0 && typeof (+data[0]) == 'number')
			this.setHours(data[0]);
		if ((data[1] || '').length > 0 && typeof (+data[1]) == 'number')
			this.setMinutes(data[1]);
		if ((data[2] || '').length > 0 && typeof (+data[2]) == 'number')
			this.setSeconds(data[2]);
	}

	d.valid = function () {
		return !isNaN(this.valueOf());
	}
	d.clone = function () {
		if (!this.valid()) throw 'Date value is NaN';
		return new Date(this.valueOf());
	}

	d.isPast = function () {
		if (!this.valid()) throw 'Date value is NaN';
		return this.valueOf() < Date.now();

	}

	d.toSecond = function () {
		var d = this.clone();
		d.setMilliseconds(0);
		return d;
	}
	d.toMinute = function () {
		var d = this.toSecond();
		d.setSeconds(0);
		return d;
	}
	d.toHour = function () {
		var d = this.toMinute();
		d.setMinutes(0);
		return d;
	}
	d.toDay = function () {
		var d = this.toHour();
		d.setHours(0);
		return d;
	}
	d.toWeek = function () {
		var day = (this.getDay() || 7) - 1;
		var d = this.toDay();
		d.addDays(-day);
		return d;
	};
	d.toMonth = function () {
		var d = this.toDay();
		d.setDate(1);
		return d;
	}
	d.toYear = function () {
		var d = this.toMonth();
		d.setMonth(0);
		return d;
	}

	d.equal = function (date) {
		if (!Date.valid(date) || !Date.valid(this))
			throw "arguments must be valid Date instance";
		return this.valueOf() == date.valueOf();
	}
	d.compare = function (date) {
		if (!Date.valid(date) || !Date.valid(this))
			throw "arguments must be valid Date instance";
		var a = this.valueOf();
		var b = this.valueOf();
		return a < b ? -1
			: a > b ? 1
				: 0;
	}

	d.getMonths = function () {
		var y = this.getFullYear();
		var m = y * 12;
		return m + this.getMonth() + 1;
	}

	d.getDays = function () {
		var td = this.toDay();
		var val = td.valueOf();
		return Math.floor(val / Date.DAY);
	}

	d.getWeek = function () {
		var y = this.toYear();
		var yw = y.toWeek();
		var w = this.toWeek();
		var d = Math.floor((w.valueOf() - yw.valueOf()) / Date.DAY);
		return d / 7 + 1;
	};

	function addFunc(func, num) {
		var get = this['get' + func];
		var set = this['set' + func];
		set.call(this, get.call(this) + num);
		return this;
	}

	d.addMs = function (num) {
		if (!this.valid()) throw "instance is not a valid date";
		if (isNaN(num)) throw "argument is not a number";

		var func = "Milliseconds";
		return addFunc.call(this, func, num);
	}
	d.addSeconds = function (num) {
		if (!this.valid()) throw "instance is not a valid date";
		if (isNaN(num)) throw "argument is not a number";

		var func = "Seconds";
		return addFunc.call(this, func, num);
	}
	d.addMinutes = function (num) {
		if (!this.valid()) throw "instance is not a valid date";
		if (isNaN(num)) throw "argument is not a number";

		var func = "Minutes";
		return addFunc.call(this, func, num);
	}
	d.addHours = function (num) {
		if (!this.valid()) throw "instance is not a valid date";
		if (isNaN(num)) throw "argument is not a number";

		var func = "Hours";
		return addFunc.call(this, func, num);
	}
	d.addDays = function (num) {
		if (!this.valid()) throw "instance is not a valid date";
		if (isNaN(num)) throw "argument is not a number";

		var func = "Date";
		return addFunc.call(this, func, num);
	}
	d.addWeeks = function (num) {
		if (!this.valid()) throw "instance is not a valid date";
		if (isNaN(num)) throw "argument is not a number";



		var func = "Date";
		return addFunc.call(this, func, num * 7);
	}
	d.addMonths = function (num) {
		if (!this.valid()) throw "instance is not a valid date";
		if (isNaN(num)) throw "argument is not a number";

		var func = "Month";
		return addFunc.call(this, func, num);
	}
	d.addYears = function (num) {
		if (!this.valid()) throw "instance is not a valid date";
		if (isNaN(num)) throw "argument is not a number";

		var func = "FullYear";
		return addFunc.call(this, func, num);
	}


	d.toShortTime = function () {
		if (!this.valid()) throw "instance is not a valid date";
		return this.getTimeString({ noseconds: true });
	}

	// var defsGetMonthNames = {
	// 	full: true,
	// 	genetive: false,
	// };

	d.getMonthName = function (opts) {
		if (!this.valid()) throw "instance is not a valid date";
		var num = this.getMonth();
		//var options = _.extend(defsGetMonthNames, opts);
		var key = opts.full && opts.genetive ? 'genetive'
			: opts.full ? 'full'
				: 'short';

		return Date.MonthNames.ru[num][key];
	}

	d.toShortDate = function () {
		if (!this.valid()) throw "instance is not a valid date";
		var name = this.getMonthName({ full: false });
		return padNumber(this.getDate(),2) + ' ' + name;
	}

})(Date.prototype);
