// define('svc/actualization/realties-list/realty/edit/properties/groups-view', [
//     'svc/actualization/realties-list/realty/edit/properties/bus',
//     'svc/actualization/realties-list/realty/edit/properties/group-view',
//     'svc/actualization/realties-list/realty/edit/properties/properties-view',
// ], function(
//     bus, GroupVuew, PropertiesView
// ) {});

import bus from './bus';
import GroupVuew from './group-view';
import { keycompare, ordercompare } from './comparators';
import { BbCollection } from 'vendors';
//import PropertiesView from './properties-view';
import { NextCollectionView } from 'vendors';

export default NextCollectionView.extend({
    className: 'propertygroups-list',
    childView: GroupVuew,

    initialize(options) {
        this.mergeOptions(options, ['filters']);
        this.filters = this.filters || {};

        let schemas = this.schemas = this.getOption('schemas');
        this.collection = new BbCollection(schemas.groups);

        this.listenTo(bus, 'filter:change', () => {
            this.filter()
        });

    },

    childViewOptions() {
        return {
            schemas: this.schemas,
            entity: this.model
        }
    },

    viewFilter(groupView) {
        if (this.filters.group && !this.filters.group(groupView.model)) {
            return false;
        }
        return bus.filterProperties(groupView.collection, this.model, this.filters.prop);
    },
    viewComparator: ordercompare
    //template: () => 'group',


});