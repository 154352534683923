﻿// define('assets-config', [], function () {
// });



const ns = {
	radios: {
		token:'identity-token'
	}
};
export default ns;