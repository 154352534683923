﻿//define('v-realties-owners-owner-inList',['bus','behaviors'],function(Bus, beh){});

//let Bus, beh;

import Bus from 'bus';
import beh from 'behaviors';

import template from './inList.html';
import { MneView } from 'core/mne';

export const OwnerInListView = MneView.extend({
	className: 'list-btn client-in-list',
	tagName: 'li',
	//template: '#tmpl-v-realties-owners-owner-inList',
	template,
	behaviors: [beh.ModelActionsInPopup.extend({
		actionOptions: function () {
			var realtyId = this.view.getOption('realtyId');
			return {
				addContact: {
					realtyId: realtyId
				},
			};
		}
	})],
	modelEvents: {
		'change': 'render'
	},
	//attributes: function () {
	//	return {
	//		'data-role': 'popup',
	//		'data-type': 'actions',
	//		'data-id': this.model.get('id'),
	//	}
	//},
	templateContextExtend: function () {
		return {
			selectable: this.getOption('selectable'),
		}
	}
});

Bus.Views.reply('owner:inList',  () => OwnerInListView);

export default OwnerInListView;
