﻿// define('realties-search-layout', ['bus', 'm-realties-offers', 'realties-offers-views-collection', 'ui-models-filters', 'realties-offers-filters'
// , 'realties-search-actionsPanel', 'ui-controls-fastfilters', 'ui-models-selectionHolder', 'realties-sendByEmail'], function (Bus, offersNs, CollectionView, Filters, offersFilters, ActionsPanel, FastFilters, SelectionHolder, SendByEmail) {});


import offersNs from 'm/realties/offers';
// import CollectionView from 'svc/realties/offers/views/collection';
import Filters from 'ui/models/filters';
import offersFilters from 'svc/realties/offers/filters';
// import ActionsPanel from 'svc/realties/search/actionsPanel';
import ActionsPanel from 'ui/layouts/actions-panel';

import FastFilters from 'ui/controls/fastfilters';
import SelectionHolder from 'ui/models/selectionHolder';
import SendByEmail from 'svc/realties/sendByEmail';

import template from './layout.html';

import { getValueByPath, bbeViewComparator } from 'utils';



//import beh, { BasePopup, SimplePopup } from 'behaviors';
import { _ } from 'vendors';

import { PopupOfferPropertiesView } from 'mods/realties/offers/popup-properties';
import { PropertiesLineView } from 'coms/ui/PropertiesLineView';


import { MnView } from 'vendors';
import { actionsMixin } from '../search-selected-actions';
import { OffersSearchListView_V2 } from '../offers/views/collection';
import { OffersCollection } from 'mods/realties/offers';
//import { simplePopupMixin } from 'apis/popup';

// const TestView = BaseView.extend({
// 	className: 'my-super-puper-popup-2',
// 	template: _.template('hello! darling <%= cid %>'),	
// 	templateContext() {
// 		return { cid: this.cid };
// 	},
// 	events:{
// 		'click'() {
// 			this.destroy();
// 		}
// 	}
// });

// console.warn('TEST', new PopupOfferPropertiesView());
// console.warn('TEST2', new PropertiesLineView());

var Layout = MnView.extend({
	className:'realties-search-content',
	//behaviors: [SimplePopup],

	// ...simplePopupMixin,

	// hoverPopup: {
	// 	openEvent: 'mouseenter',
	// 	openSelector: '.block.selected-pane',
	// 	//removeBehavior: 'detach',
	// 	removeDelay: 1000,
	// 	view: v => ({
	// 		class: TestView,
	// 		model: v.model
	// 	}),
	// 	positionOptions: {
	// 		placement: 'right'
	// 	}
	// },

	//template: '#tmpl-service-realties-search-layout',
	template,
	regions: {
		'actionsPanel': { el: '.actions-panel-left', replaceElement: true },
		'result': '.result',
	},
	initialize: function() {
		this.searchResult = new OffersCollection();
		// new offersNs.OffersCollection();
		//this.initializePopupMixin();
	},
	initializeFilters: function () {
		if (this.filterObject) return;
		var filters = offersFilters.filters({ deal: this.getOption('deal') });
		console.log('filters', filters);
		var groups = offersFilters.groups();
		//console.log('HEYYYYY!!!')
		this.filterObject = Filters.create({
			groups: groups,
			filters: filters,
			changeHandlers: [
				offersFilters.changeHandler
			],
			deal: this.getOption('deal'),
		});
		console.log('filterObject', this.filterObject);
	},
	initSelectionHolder: function () {
		if (this.selectionHolder) return this.selectionHolder;
		this.selectionHolder = SelectionHolder.create('searchoffers');
		return this.selectionHolder;
	},
	onRender: function () {

		this.initializeFilters();
		this.initSelectionHolder();
		this.showListResult();
		this.showActionPanel();

	},
	showListResult: function () {
		//console.log('==1',this.selectionHolder);
		var denySelect;
		var deal = this.getOption('deal');
		if (deal) {
			var offers = deal.getOffers();
			denySelect = offers.map(function (m) { return getValueByPath(m, 'realtyOffer.id'); }).onlyValues();
			//console.log('deny', ids, offers);
		}

		var result = new OffersSearchListView_V2({
			collection: this.searchResult,
			filterObject: this.filterObject,
			selectionHolder: this.selectionHolder,
			denySelectionFor: denySelect
		});

		this.showChildView('result', result);
	},
	showActionPanel: function() {
		// var selectedActions = [];
		// var deal = this.getOption('deal');
		// if (deal)
		// 	selectedActions.push({ text: "прикрепить отобранное к процессу", action: 'joinOffersToDeal', actionOn: deal });

		// selectedActions.push({ text: "отправить по email", action: 'sendAsEmail', actionOn: this });

		const selectedActions = this.getActionsPanelActions();

		var actionspanel = new ActionsPanel({
			// className: 'actions-panel actions-panel-left',
			// statsView: undefined,
			filterObject: this.filterObject,
			selectionHolder: this.selectionHolder,
			selectedComparators: {
				square: function (a, b) {
					return bbeViewComparator([
						[a, b, function () { return this.model.get('object').info.squareOffer; }]
					]);
				}
			},
			selectedActions: selectedActions,
			selectedViewOptions: {
				header: 'Отобранные предложения'
			}
			// fastFiltersView: FastFilters.create({
			// 	filters: this.filterObject,
			// }),
		});
		this.showChildView('actionsPanel', actionspanel);
	},

	// actionSendAsEmail: SendByEmail.actionSendByEmail

	...actionsMixin

});

export default Layout;