module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="type-container">\n	<label><input name="type" type="radio" value="callIn" '+
((__t=( ch('callIn') ))==null?'':__t)+
' /> <span>Звонок</span></label>\n	<label><input name="type" type="radio" value="siteForm" '+
((__t=( ch('siteForm') ))==null?'':__t)+
' /> <span>Заявка</span></label>\n</div>\n<div class="name-container">\n	<label>площадка</label>\n	<input name="name" type="text" value="'+
((__t=( name ))==null?'':__t)+
'" class="form-control" autocomplete="off" />\n	<div class="suggestions"></div>\n</div>\n<div class="instrument-container">\n	<label>инструмент продвижения</label>\n	<input name="instrument" value="'+
((__t=( instrument ))==null?'':__t)+
'"  class="form-control" autocomplete="off"/>\n</div>';
}
return __p;
};
