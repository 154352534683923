﻿//define('bus-core',['assets-radio'],function(radio){});

//import radio from '../../assets/radio';
import { Radio } from 'vendors';

Radio.DEBUG = true;

var b = {};
b.channel = function (name) {
	return Radio.channel(name);
};
b.assets = b.channel('assets');
b.identity = b.channel('identity');
b.trigger = function () {
	return Radio.trigger.apply(Radio, arguments);
}
b.request = function () {
	return Radio.request.apply(Radio, arguments);
}
b.reply = function () {
	return Radio.reply.apply(Radio, arguments);
}

export default b;
