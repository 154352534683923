import { identity, userConfig } from './Identity';
import { newToken } from './NewToken';

export { actorHasClaims } from './actorHasClaims';

export const identityPromise = new Promise(async (res, rej) => {
	try {
		await newToken.refreshAsync();
		await identity.fetch();
	}
	catch(exc) { }

	res();

	if (identity.isAuthorized()) {
		res();
	} else {
		rej();
	}

});


export const authPromise = new Promise(async (res, rej) => {

	await identityPromise;

	if (identity.isAuthorized()) {
		res();
	} else {
		rej();
	}

});

export const svcAuthPromise = new Promise(async (res, rej) => {
	await identityPromise;
	const svc = identity.getSvc();
	if (svc) { 
		res(svc);
	} else {
		rej();
	}
});


export function authAction(action) {
	return authPromise.then(() => action(), exc => Promise.resolve());
}

export function svcAuthAction(action) {
	return svcAuthPromise.then(() => action(), exc => Promise.resolve());
}

export {
	identity,
	newToken,
	userConfig
}