import { PropertiesBoxView } from 'coms/ui/PropertiesBox';
import { applyChangesToModel, flatArray, getEditChanges, getInitialKeyValues, viewSchemaMixin } from '../../../../apis/schema';
import { editContactValueSchema } from './editContactValueSchema';
import { urls } from 'coms/urls';
import { backendApi } from '../../../../apis/backend';

export const EditContactValueInfo = PropertiesBoxView.extend({
	thisClassName: 'with-borders colored-values track-changes',
	modelSchema: editContactValueSchema,
	// modelSchema: editContactSchema,
	setValueBySchemaApi: true,
	editEnabled: true,
	...viewSchemaMixin,
	classNames: v => [
		v.hasChanges() ? 'has-changes' : ''
	],
	// modelValue: this.model.get('lead'),
	// header: 'описание',
	properties: v => {
		// console.log('THIS', v);
		const props = [
			['value.type', 'value.value'],
			['order', 'purposes'],
			'note',
		];

		return props;
	},
		// ['sourceName', 'realtyId', ['operation', 'market'], ['squareFrom','squareTo'], ['priceFrom','priceTo']],
	// onPropertyChange(newvalue, propertySchema, modelValue, modelSchema) {
	// 	if (propertySchema.property === '_contactType') {
	// 		this.contactType = newvalue;
	// 		setTimeout(() => {
	// 			this.render();
	// 		}, 50);
	// 	}
	// },
	onPropertyChange(newvalue, propertySchema, modelValue, modelSchema) {
		this._updateElClassName();
	},
	initialize() {
		// this.contactType = this.schemaValue('_contactType');	
		this.initialKeyValues = getInitialKeyValues(this.model);
	},
	action: v => {
		const isNew = v.getOption('isNew', true);
		const text = isNew ? 'добавить' : 'сохранить изменения';
		const editEvent = 'before:edit:done';
		//isNew ? 'add:contact:value' : 'edit:contact:value';
		const view = {
			thisClassName: 'save-changes',
			glyph: 'save',
			text,
			//clickAction: () => isNew ? this._createNew() : this._saveChanges(),
			clickAction: () => {
				const changes = v._getChanges();
				if (!changes) { return; }
				console.warn('click action', changes);
				v.triggerMethod(editEvent, changes, isNew);
			}
			// () => {
			// 	const modelValue = v.getSchemaModel();
			// 	v.triggerMethod(editEvent, modelValue, isNew);
			// }
		}
		return view;
	},
	_getChanges() {


		if (!this.initialKeyValues) { return; }

		// const excludeProps = {
		// 	'_contactType':1
		// }
		const props = flatArray(this.getOption('properties', true) || []); //.filter(f => f in excludeProps === false);
		const modelHash = this.getOption('modelHash', true) || {};
		const changes = getEditChanges(this.initialKeyValues, modelHash, { properties: props, returnFlat: false });
		return changes;
		// // console.log('taking props', props);
		// const shouldExcludeKey = key => (key in excludeProps) || props.indexOf(key) === -1;

		// const flatChanges = flatObject(modelHash);
	
		// let keys = Object.keys(this.initialKeyValues)
		// keys.push(...Object.keys(flatChanges));
		// const keysHash = keys.reduce((m, k) => {
		// 	m[k] = 1
		// 	return m;
		// }, {});
	
		// const changes = {};
		// let hasChanges = false;
		// for(let key in keysHash) {
		// 	if (shouldExcludeKey(key)) { continue; }
		// 	const original = this.initialKeyValues[key];
		// 	const newvalue = flatChanges[key];
		// 	console.log(key, original, newvalue);
		// 	if (original !== newvalue) {
		// 		changes[key] = newvalue;
		// 		hasChanges = true;
		// 	}
		// }
		// if (hasChanges) {
		// 	return unFlatObject(changes);
		// }
	},
	
	hasChanges() {
		return !!this._getChanges();
	},
});
