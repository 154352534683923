﻿//paths: { 'server-cfg': '/bundles/djs/?v=123' }

let DEBUG = /\.loc$/gi.test(document.location.hostname) || /console=on/gmi.test((document.location.search || ''));

function getSessionDebug()
{
	return sessionStorage && sessionStorage.getItem ? sessionStorage.getItem('DEBUG') : false;
}

function setSessionDebug(value = true)
{
	
	if (!(sessionStorage && sessionStorage.setItem)) {
		return;		
	}
	value = (value === true).toString();
	return sessionStorage.setItem('DEBUG', value);
}

if (getSessionDebug()) {
	DEBUG = true;
}

//window.APPDEBUG = /\.loc$/gi.test(document.location.hostname) || /console=on/gmi.test((document.location.search || ''));

console.warn('console: ', DEBUG === true);

const noOp = () => {};
const { log, warn, error } = console;

function consoleOn(dontUpdateSession) {
	if (!dontUpdateSession) {
		setSessionDebug(true);
	}
	console.log = log;
	console.warn = warn;
	console.error = error;
}

function consoleOff(dontUpdateSession) {
	if (!dontUpdateSession) {
		setSessionDebug(false);
	}
	console.log = noOp
	console.warn = noOp
	console.error = noOp
}


if (!DEBUG) {
	consoleOff(true);
}

window.consoleOn = consoleOn;
window.consoleOff = consoleOff;