﻿//define('svc-tasks-list-layout', ['bus-app', '_libs/identity', 'references', 'modals', 'ui-controls-editValue', 'behaviors', 'ui-layouts-board-list-layout', 'ui-layouts-tabs', 'ui-models-regionsCollections'], function (appRadio, identity, refs, modals, EditValue, beh, BoardList, Tabs, RegionsCollections) {});


//let appRadio, identity, refs, modals, EditValue, beh, BoardList, Tabs, RegionsCollections

import beh from 'behaviors';
import BoardList from 'ui/layouts/board/list/layout';
import Tabs from 'ui/layouts/tabs';
import RegionsCollections from 'ui/models/regionsCollections';

import taskBlockViewTemplate from './blockItem.html';

import { bbeViewComparator } from 'utils';

import { Model as BbModel } from 'vendors';

import { MneView } from 'core/mne';

import 'm/employees';

import './task-in-block.less';
import './task-in-line.less';
import './tasks-static-board.less';
import './tasks.less';


	// var commonFilters = {
	// 	my: function (m) {
	// 		return m.state() || m.state('initial');
	// 	},
	// 	fromme: function (m) {
	// 		return m.get('creatorId') == identity.get('employeeId');
	// 	},
	// 	awaiting: function (m) {
	// 		return m.get('checkerId') == identity.get('employeeId') && m.state('onChecking');
	// 	}

	// }

	// function toView(arg) {
	// 	return arg instanceof MneView && arg;
	// }
	function toModel(arg) {
		return (arg instanceof BbModel && arg) || (!!arg && arg.model);
	}

	var filters = {
		bad: function (arg) {
			//var view = arg instanceof MneView ? arg : undefined;
			//var m = arg instanceof MneView ? arg.model : arg;
			var m = toModel(arg);
			var date = Date.info(m.get('dates').deadline, true);
			return date.calendar.days >= 7 && date.when == Date.PAST;
		},
		past: function (arg) {
			//var view = arg instanceof MneView ? arg : undefined;
			//var m = arg instanceof MneView ? arg.model : arg;
			var m = toModel(arg);
			var date = Date.info(m.get('dates').deadline, true);
			return date.calendar.days >= 0 && date.calendar.days < 7 && date.when == Date.PAST;
		},
		today: function (arg) {
			//var view = arg instanceof MneView ? arg : undefined;
			//var m = arg instanceof MneView ? arg.model : arg;
			var m = toModel(arg);
			var date = Date.info(m.get('dates').deadline,true);
			return date.calendar.days == 0 && date.when == Date.FUTURE;
		},
		tomorrow: function (arg) {
			//var view = arg instanceof MneView ? arg : undefined;
			//var m = arg instanceof MneView ? arg.model : arg;
			var m = toModel(arg);
			var date = Date.info(m.get('dates').deadline, true);
			return date.calendar.days == 1 && date.when == Date.FUTURE;
		},
		thisweek: function (arg) {
			var m = toModel(arg);
			var date = Date.info(m.get('dates').deadline, true);
			return date.calendar.weeks == 0 && date.when == Date.FUTURE && date.calendar.days >= 2;
		},
		nextweek: function (arg) {
			var m = toModel(arg);
			var date = Date.info(m.get('dates').deadline, true);
			return date.when == Date.FUTURE && date.calendar.weeks == 1 && date.calendar.days >= 2;
		},
		later: function (arg) {
			//var view = arg instanceof MneView ? arg : undefined;
			//var m = arg instanceof MneView ? arg.model : arg;
			var m = toModel(arg);
			var date = Date.info(m.get('dates').deadline, true);

			return date.when == Date.FUTURE && date.calendar.days > 1 && !filters.thisweek(m);
		},
	};

	var filtersNames = {
		bad: 'Срочно разобраться',
		past: 'Просроченные',
		today: 'Сегодня',
		tomorrow: 'Завтра',
		thisweek: 'На этой неделе',
		nextweek: 'На следующей неделе',
		later: 'Через некоторое время',
	}

	function dateNames() {
		var res = _(filtersNames).map(function (label, name) { return name; });
		return res;
	}

	function dateName(model) {
		var foundName;
		_(filters).some(function (fn, name) {
			var res = fn(model);
			if (res) foundName = name;
			return res;
		});
		return foundName;
	}
	function dateLabel(name) {
		return filtersNames[name];
	}



	var TaskBlockView = MneView.extend({

		className: 'task task-in-block item',
		//template: '#tmpl-service-tasks-list-blockItem',
		template: taskBlockViewTemplate,

		behaviors: [beh.DynamicClass, beh.ModelActionsInPopup],

		dynamicClass: function () {
			var m = this.model;
			var dates = m.get('dates') || {};
			var d = Date.info(dates.deadline);
			var res = [];
			res.push(d.when);
			res.push('type-' + m.get('type'));
			res.push('state-' + m.get('state'));
			if (dates.strictDeadline)
				res.push('strict-date');

			if (d.calendar.days == 0)
				res.push('date-today');

			if (m.get('deal'))
				res.push('on-deal');
			else if (m.get('client'))
				res.push('on-client');

			return res.join(' ');
		},
		modelEvents: {
			'change':'render'
		}
	});

	// var ListView = MneNextCollectionView.extend({
	// 	className:'tasks-list-region',
	// 	childView: TaskView,
	// 	initialize: function () {
	// 		this.attachedViews = {};
	// 		//var view = this;
	// 	},
	// 	onChildviewModelChanged: function () {
	// 		this.collection.sort();
	// 	},
	// 	onChildviewAttach: function(view) {
	// 		if (this.getOption('countEmptyView') || !this.emptyView || (this.emptyView && !(view instanceof this.emptyView)))
	// 			this.attachedViews[view.cid] = view;
	// 		this.triggerMethod('attached:change', this);
	// 	},
	// 	onChildviewDetach: function (view) {
	// 		delete this.attachedViews[view.cid];
	// 		this.triggerMethod('attached:change', this);
	// 	},
	// 	getAttachedViewsCount: function () {
	// 		return _.size(this.attachedViews);
	// 	},
	// 	onAttach: function () {
	// 		this.triggerMethod('attached:change', this);
	// 	},
	// 	toggleViewDisplaying: function () {
	// 		var hasViews = this.getAttachedViewsCount();
	// 		if (hasViews) 
	// 			this.$el.parent().show();
	// 		else
	// 			this.$el.parent().hide();
	// 	},
	// });



	// var OldLayout = MneView.extend({
	// 	className:'tasks-container tasks-inline',
	 	//template: '#tmpl-service-tasks-list-layout',
	// 	regions: {
	// 		'bad': { el: '.bad-region', replaceElement: true },
	// 		'past': { el: '.past-region', replaceElement: true },
	// 		'today': { el: '.today-region', replaceElement: true },
	// 		'tomorrow': { el: '.tomorrow-region', replaceElement: true },
	// 		'thisweek': { el: '.thisweek-region', replaceElement: true },
	// 		'nextweek': { el: '.nextweek-region', replaceElement: true },
	// 		'later': { el: '.later-region', replaceElement: true },
	// 	},
	// 	onRender: function () {
	// 		this.on('childview:attached:change', function (view) {
	// 			view.toggleViewDisplaying();
	// 		});
	// 		var col = this.getOption('collection');
	// 		this.showColl('bad', col);
	// 		this.showColl('past', col);
	// 		this.showColl('today', col);
	// 		this.showColl('tomorrow', col);
	// 		this.showColl('thisweek', col);
	// 		this.showColl('nextweek', col);
	// 		this.showColl('later', col);
	// 	},
	// 	showColl: function (type, col) {

	// 		var boardType = this.getOption('tasksType');

	// 		var cmnfltr = commonFilters[boardType];
	// 		var typefltr = filters[type];

	// 		var newCol = DependedCollection.create({
	// 			collection: col,
	// 			filter: function (model) {
	// 				if (!cmnfltr(model))
	// 					return false;
	// 				return typefltr(model);
	// 			},
	// 		});
			
	// 		var view = new ListView({
	// 			collection: newCol,
	// 			listType: type,
	// 			childViewOptions: function () {
	// 				return {
	// 					listType: type
	// 				}
	// 			},
	// 		});
	// 		this.showChildView(type, view);
	// 		return view;
	// 	}
	// });

	var Layout = MneView.extend({
		className: 'tasks-static-board',
		template: _.template('<div class="lists-tabs"></div><div class="lists"></div>'),
		regions: {
			'tabs': '.lists-tabs'
		},
		initialize: function () {
			this.groupper = this.createGroups();
		},
		createGroups: function () {

			var groupConfig = this.getGroupConfig();

			var groupedCollection = new RegionsCollections({
				collection: this.collection,
				iteratee: groupConfig.iteratee,
				itemFilter: this.getOption('itemFilter'),
				groups: groupConfig.allGroups,
				regionTagClass: function (name) { return 'list l-' + name; },
				View: BoardList,
				viewOptions: function (name) {
					return {
						childView: TaskBlockView,
						label: dateLabel(name),
						viewComparator: function (v1, v2) {
							var res = bbeViewComparator([
								[v2, v1, function (m) { return (m.get('dates') || {}).strictDeadline == true }],
								[v1, v2, function (m) { return m.getWhenValue(); }],
							]);
							return res;
						}
					};
				}
			});

			return groupedCollection;
		},
		getGroupConfig: function () {
			var allgroups = dateNames();
			var res = {};

			res.iteratee = function (m) { return dateName(m) };
			res.allGroups = dateNames();
				//_(allgroups).map(function (label, name) { return name; });
			res.labels = _.extend({}, allgroups);

			return res;
		},
		collectionEvents: {
			'change add remove update': function () {
				this.refreshListsVisibility();
			}
		},
		refreshListsVisibility: function () {
			var cntx = this.groupper.getContexts();
			console.log('doing refresh...', cntx);
			setTimeout(function () {
				_(cntx).each(function (c) {
					if (c.collection.length)
						c.region.$el.removeClass('r-hidden');
					else
						c.region.$el.addClass('r-hidden');
					});
			}, 100);
		},
		onRender: function () {
			console.log('RENDERED', this.cid);
			this.showTabs();
			this.groupper.apply(this, '.lists');
			this.showGroups();
			this.refreshListsVisibility();
		},
		showTabs: function () {
			this.tabs = new Tabs({
				tabs: _.extend({}, filtersNames),
				multiple: true,
				allActive: true
			});
			this.showChildView('tabs', this.tabs);
		},
		showGroups: function () {
			var _this = this;
			_(this.groupper.groups).each(function (group) {
				_this.showGroup(group);
			});
		},
		showGroup: function (group) {
			var region = this.getRegion(group.name);
			if (!region) {
				console.warn('region missing', group.name);
				return;
			}
			var view = this.groupper.getView(group.name);
			region.show(view);
		},
		onBeforeDestroy: function () {
			this.groupper.destroy();
		},
		onChildviewTabOn: function (name) {
			//console.log('nn ->', name);
			this.$el.removeClass('hide-' + name);
		},
		onChildviewTabOff: function (name) { this.$el.addClass('hide-' + name); },

	});

	export default Layout;
