import { BbeModel } from 'core/bbe';
import { flatObject, ubFlatObject, setValueByPath  } from 'utils';
import { _ } from 'vendors';

export const ObjectModel = BbeModel.extend({
	merge(data) {
		const flat = flatObject(data);
		_.each(flat, (value, path) => {
			console.warn(path, value, flat);
			setValueByPath(this, path, value);
		});
		this.trigger('refresh:view', this);
		console.log('# this', this);
	}
});