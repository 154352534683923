import { _ } from 'vendors';
import { flatObject } from './object-manipulations';

function normalizePath(key)
{
	return ('.'+ (key || '')).replace(/\.+/gmi, '/');
}

function normalizePathValue(value) {
	return value == null ? null : value;
}

export function hashToPatch(attrs, options) {

    if (attrs == null) return attrs;

    var hash = _.extend({}, attrs);

    if (options.deepKeys)
        hash = flatObject(attrs);

    return _.map(hash, function (value, key) {
        var path = normalizePath(key);
        value = normalizePathValue(value);
        return { op: 'replace', path: path, value: value };
    });			
}


