// define('svc/actualization/realties-list/config', [
// 	'app-config'
// ], function(
// 	cfg
// ){});

import cfg from 'app-config';

const config = {
    realtiesApiUrl() {
        return cfg.urls.apiV('realties/realties');
    },
    objectsApiUrl() {
        return cfg.urls.apiV('realties/objects');
    },
    ownersApiUrl() {
        return cfg.urls.apiV('realties/owners');
    }
}
export default config;
