﻿// define('service', [
// 	'app-config', 'appModule', 'link', 'service-layout', 'svc-tasks', 'realties', 'svc-deals', 'svc-clients', 'svc-staff', 'svc-leads'
// 	, 'svc/actualization', 'svc/admin'
// ], function(
// 	cfg, modules, link, Layout, moduleTasks, moduleRealties, moduleDeals, moduleClients, moduleStaff, moduleLeads
// 	, moduleActualization, moduleAdmin
// 	) {



// });


import cfg from 'app-config';
import link from 'link';
import modules from 'appModule';
import { submodules } from './submodules';
import identity from '_libs/identity';
var module = modules.content({
	name: 'service',
	routeData: () => {
		console.warn('route-',link.route('svc'))
		return link.route('svc');
	},
	urlKey: 'svc',
	label: () => 'Аккаунт ' + identity.getServiceName(), //cfg.service.name,
	onRun: function () {
		console.error('#svc-run#');
		this.showButtonsLayout();
	}
});

submodules.forEach(sub => module.registerModule(sub))

//console.log(' >> [', 'добавил таски', module)

//module.registerModule(m_tasks);
// module.registerModule(moduleDeals);
// module.registerModule(moduleRealties);
// module.registerModule(moduleClients);
// module.registerModule(moduleStaff);
// module.registerModule(moduleLeads);
// module.registerModule(moduleActualization);
// module.registerModule(moduleAdmin);

export default module;
