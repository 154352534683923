﻿//define('behaviors-fastactionTrigger',[],function(){});
import './fastaction-trigger.less';
import { Behavior, triggerMethod } from 'vendors';

var B = Behavior.extend({
	events: function () {
		var fastaction = this.view.getOption('fastaction');
		if (!fastaction) return;
		return {
			'click .item-fastaction-trigger': function (e) {
				e.stopPropagation();

				var opts = this.view.options;

				var method = _.result(opts, 'fastactionMethod');
				var context = _.result(opts, 'fastactionContext') || this.view;
				var event = _.result(opts, 'fastactionEvent');

				if (method) {
					method = _.isFunction(method) ? method : context[method];
					method.call(context, this.view.model, this.view);
				} else if (event) {
					triggerMethod(context, event, this.view.model, this.view);
				}

			},
		}
	}
});

export default B;