import { ApiCollection } from 'core';
import { urls } from 'coms/urls';
import { shortRealties, addresses } from '../realties/realties';

export const OffersCollection = ApiCollection.extend({
	constructor: function() {
		ApiCollection.apply(this, arguments);
		this.on('sync', (...args) => this.trigger('data:load:complete'));
		//this.on('load:first', (...args) => console.warn(args) )
	},
	url() { return urls.api('v4/realties/offers'); },
	getFetchResult() {
		return this.fetchResult.attributes;
	},
	parseAdditionals(additionals) {
		if (!additionals?.realties) { return; }
		const realties = additionals.realties || [];
		const rawaddresses = realties.map(r => r.address);
		shortRealties.add(realties, { add: true, remove: false, merge: true });
		addresses.add(rawaddresses, { add: true, remove: false, merge: true });
		console.log('parsed addreses', shortRealties, addresses);
	},
	onLoadFirst(search) {
		this.fetch({ search });
	},
	onLoadNext(search) {
		this.fetchNext({ search });
	}
});