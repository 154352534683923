
import APPBUS from 'bus/app';
import bus from './properties/bus';
//import Properties from './properties';
//import tabs from './tabs';
//import RealtySchemaCollection from '../../realty-schema';
//import RealtyModel from '../../realty-model';
import { NavTabsView }  from './layout-nav-tabs';
import './edit-layout.less';


import { history, MnView } from 'vendors';
import { MneView } from 'core/mne';

export const EditLayout = MnView.extend({
    //className: 'realty-edit-page',
    //addClass: 'entity-edit-page',
    template: _.template(`
<nav class="sub"></nav>
<div class="content">
    <div class="loading-page-info ui-panel ui-panel-info outer-margin-2 centered rounded-2 dashed-4">
        <span>- страница загружается -</span>
    </div>
</div>
    `),
    ui: {
        loadingInfo: '.content > .loading-page-info',
        loadingInfoText: '.content > .loading-page-info > span'
    },
    regions: {
        content: '> .content',
        nav: { el: '> nav', replaceElement: true }
    },

    buildModels() {
        // this.model = new RealtyModel({ id: this.getOption('realtyId') });
        // this.collection = new RealtySchemaCollection();
    },

    getModelHeader() {
        //return this.model.get('prefix') + ' ' + this.model.get('name');
    },
    getHeaderLinks() {

    },

    initialize(options) {
        // this.mergeOptions(options, ['bus']);
        // if (!this.bus) {
        //     throw new Error('bus was not provided to edit layout');
        // }
        this.buildModels();
        this.fetchAsync();
        this.once('destroy', () => bus.release())
        this.listenTo(history, 'hash:change', this.render);
    },
    




    fetchAsync() {
        let modelPromise = this.model ? this.model.fetch() : Promise.resolve();
        let colPromise = this.collection ? this.collection.fetch() : Promise.resolve();
        return Promise.all([modelPromise, colPromise])
            .then(
                () => this.triggerMethod('data:fetched'),
                exc => this.triggerMethod('data:fetch:error', exc)
            );
    },
    onDataFetchError(exc) {
        this.dataFetchError = exc;
        if (this.isRendered()) {
            this.render();
        }
    },
    onDataFetched() {

        this._dataFetched = true;

        bus.init(this.model, this.collection);

        if (this.isRendered()) {
					this.showContent();
        }

        let text = this.getModelHeader();
        let links = this.getHeaderLinks();

        APPBUS.trigger('update:page:header:model', { text, links });
        //APPBUS.trigger('update:page:header:text', header);

    },
    isDataFetched() {
        return this._dataFetched === true;
    },
    onRender() {
        if (this.dataFetchError) {
					this.showError();
        }
        else if (this.isDataFetched()) {
					this.showContent();
        } 
        else {
					console.log('# data not yet fetched');
        }
    },
    showError() {
        let text = this.dataFetchError.responseJSON.message;
        this.ui.loadingInfoText.html(text);
        this.ui.loadingInfo.removeClass('ui-panel-info');
        this.ui.loadingInfo.addClass('ui-panel-error');
    },
    showNav() {
        let view = new NavTabsView({ entity: this.model, tabs: this.getOption('tabs'), tabModelDefaults: this.getOption('tabModelDefaults', true) });
        this.showChildView('nav', view);
        this.tabsCollection = view.collection;
    },
    showContent() {

        this.showNav();

        console.warn('editlayout show content');
        
        let options = { collection: this.collection, model: this.model };
        let view = this.tabsCollection.getCurrentView(options);
        if (!view) {
            view = new MneView({
                template: () => '<ul>эта страница пока в разработке</ul>'
            });
        } else if (view === 'not-authorized') {
					view = new MneView({
						template: () => '<div class="module-run-error-message"><h3>У вас не достаточно прав для просмотра этой страницы</h3></div>'
					});

				}
        this.showChildView('content', view);
    }
});



