﻿//define('m-appData', ['app-paths', '_libs/identity'], function (paths, identity) {});

import paths from 'app-paths';
import identity from '_libs/identity';

import { BbeModel } from 'core/bbe';


var AuthData = BbeModel.extend({

	initialize: function () {
		this._singleFetchPromise = new Promise(resolveAppData => {
			this.once('single:fetched', () => {
				this._singleFetchResolved = true;
				//console.log('appdata single:fetched rised. resolving appdata');
				resolveAppData();
			});
		});
		this.once('sync', () => this.trigger('single:fetched'));
		//$.Deferred();
	},

	// fetch: function () {
	// 	var promise = this._singleFetchPromise;
	// 	return BbeModel.prototype.fetch.apply(this, arguments).then(function () {
	// 		promise.resolve();
	// 	}, function () {
	// 		promise.reject();
	// 	});
	// },

	whenReady: function () {
		return this._singleFetchPromise;
	},

	initAndFetch: function () {
		if (!this._singleFetchResolved) {
			let svc = identity.getSvc();
			if (identity.isLogged() && svc) {
				//console.log('fetching app data....', svc);
				this.fetch();
			} else {
				//console.log('identity is not logged, appdata won\'t be fetched');
				this.trigger('single:fetched');
			}
		} else {
			console.error('appdata already initialized', this._singleFetchResolved);
		}
		return this._singleFetchPromise;
	},

	url: function () {
		return paths.api.appDataAuth;
	}



});

var Model = BbeModel.extend({
	initialize: function () {
		this.authData = new AuthData();
	}
});

export default new Model();