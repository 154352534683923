import { _, Behavior } from 'vendors';

export const OnScrollLoadmorable = Behavior.extend({
	//onScrollBottomHit: function () {
	//	this.view.triggerMethod('load');
	//},
	//onLoadComplete: function () {
	//	var fr = this.radio.request('fetchResult');
	//	var theend = (fr.returned + fr.skiped >= fr.founded);
	//	if (!theend)
	//		this.view.triggerMethod('scroll:reset:bottom:hit')
	//}
	//onFiltersDataNew: function () {
	//	this.view.triggerMethod('reset:scroll:position');
	//},
	onScrollBottomHit: function () {
		console.log('bottom hit');
		this.view.triggerMethod('data:load:next');
	},
	collectionEvents: {
		'data:load:complete': function () {
			var col = this.view.collection;
			if (!col || !col.getFetchResult) return;

			var fr = col.getFetchResult();
			//this.radio.request('fetchResult');
			var theend = (fr.returned + fr.skiped >= fr.founded);
			if (!theend)
				this.view.triggerMethod('scroll:reset:bottom:hit')
		}
	},

});

