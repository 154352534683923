//define('acc/forgotPassword/restore/layout',['app-config', 'ui/controls/forms/simple-form-mixin', '_libs/identity', 'bus-app', 'modals'],function(cfg, formMixin, identity, busApp, modals){});



import cfg from 'app-config';
import formMixin from 'ui/controls/forms/simple-form-mixin';

import { MneView } from 'core/mne';

var template = `
<div class="form-group">
	<label>Укажите свой логин</label>
	<input class="form-control" name="userName" type="email"/>
</div>
<div class="form-group">
	<label>Новый пароль</label>
	<input class="form-control" name="password" type="password"/>
</div>
<div class="form-group">
	<label>Новый пароль ещё раз</label>
	<input class="form-control" name="passwordConfirm" type="password"/>
</div>
<button class="btn btn-default submit" data-role="submit">установить пароль</button>	
`;

var afterSendTemplate = `
<div class="form-group">
<label>Ваш пароль изменён.</label>
</div>
<p><a href="/acc/login">Перейти на страницу входа</a></p>
`;

var Layout = formMixin.mix(MneView).extend({

	template: template,
	templateAfterSend: afterSendTemplate,

	formUrl: () => cfg.apiUrl('acc/forgot-password/restore'),
	defaultFormData: v => v.getOption('queryData'),

	initialize() {
		this._initializeClickHandler();
	},
	validateData(data) {
		let errors = [];
		if (!data.code) {
			errors.push('Отсутствует токен безопасности. Перейдите на страницу восстановления пароля и запросите новое письмо.');
			return errors;
		}
		if (!data.userId) {
			errors.push('Отсутствует идентификатор пользователя. Перейдите на страницу восстановления пароля и запросите новое письмо.');
			return errors;
		}
		if (!data.userName) {
			errors.push('Укажите свой логин');
		}
		if (!data.password) {
			errors.push('Укажите новый пароль');
		}
		if (data.password != data.passwordConfirm) {
			errors.push('Несовпадают пароль и подтверждение пароля');
		}

		return errors.length ? errors : void 0;
	},
	// getTemplate() {
	// 	if (this._sended) {
	// 		return _.template(afterSendTemplate);
	// 	} else {
	// 		return _.template(template);
	// 	}
	// },
	onSendSuccess() {
		this.render();
	},

	onRender() {
		console.log('QUERY DATA: ', this.getOption('queryData'));
	},

});
export default Layout;
