import { Collection } from 'core';
import { View } from 'core';
import { CollectionView } from 'core';
import { OwnerInRealtyListItemView } from './OwnerInRealtyListItemView';
import './owners-in-realties.less';

export const OwnersInRealtiesLayout = CollectionView.extend({
	thisClassName: 'owners-in-realties-container',
	childView: OwnerInRealtyListItemView,
	childViewOptions() {
		return {
			modelType: this.getOption('modelType')
		}
	},
	initialize() {
		this.initializeCollection();
		console.error('# -- #', this.model.url());
	},
	initializeCollection() {
		const models = []; //this.getRawModels();
		this.collection = new Collection(models);
		const urlPath = this.getOption('modelType') === 'owner' ? '/realties' : '/owners';
		this.collection.url = this.model.url() + urlPath;
		this.collection.fetch();
	},
	
	getRawModels() {
		const modelsKey = this.getOption('modelType') === 'owner' 
			? 'realties'
			: 'owners';
		return this.model.get(modelsKey) || [];
	},
	viewComparator: (v1,v2) => v2.model.get('count') - v1.model.get('count')

});