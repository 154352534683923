import { UiMoleculeView } from 'coms/ui/UiMoleculeView';
import { UiAtomView } from 'coms/ui/UiAtomView';
import refs from 'references';

import './employee-in-list.less';
import { claimsApi } from '../../apis/claims';

export const EmployeeListItemView = UiMoleculeView.extend({
	thisClassName: 'employee',
	// initialize() {
	// 	console.warn(this.model.attributes);
	// },
	// optionsButton: true,
	// editButton: true,
	// removeButton: true,
	classNames: [
		v => 'role-' + v.model.get('joinedAs')
	],
	deleteButton: true,
	editButton: v => v.model.collection.joinedAsEnabled && claimsApi.hasClaims('svcEmployeesModule','edit'),
	content:{
		class: UiAtomView,
		thisClassName: 'info',
		text: v => v.model.get('name') || v.model.get('email'),
		bottomText: v => refs.enum('employeeGroupJoins', v.model.get('joinedAs')),
		topText: v => {
			//console.log(v.model.attributes);
		}
	},
	childViewTriggers: {
		'delete:click': 'delete:click',
		'edit:click': 'edit:click'
	},
	modelEvents: {
		change: 'render'
	},
	// childViewEvents: {
	// 	'all'() {
	// 		console.log('ALL HANDLER');
	// 	},
	// 	'delete:click'() {
	// 		this.trigger('delete:click', this.model);
	// 		console.log(this.model.attributes)
	// 	}
	// }
	// rightButtons:['remove:trash']
});