﻿//define('svc-leads-stats', ['appModule', 'link', '_libs/identity', 'svc-leads-layout'], function (Module, link, identity, Layout) {});

import Module from 'appModule';
import link from 'link';
import Layout from './layout';

import './stats-result.less';

var module = Module.content({
    name: 'leads-stats',
    label: 'Статистка по лидам',
    routeData: () => link.route('leadsStats'),
    urlKey: 'leadsStats',
    onRun: function () {
        this.createAndShow(Layout, 'Статистка по лидам');
    }
});


export default module;
