import { View } from 'core';
import { HamburgerView } from 'coms/ui/HamburgerView';
// import { BoxView } from 'coms/ui/Box';
// import { getBuildingImagesCollection } from 'm/realties/buildings';
// import { showImageGalery, ImageView } from 'coms/ImageGalery';

// import './realty-box-thumb.less';
// import './realty-name.less';

import { RealtyObjectPropertiesView } from './properties-lines';
import { realtyObjectsStore } from '../store';
import { modelSchema } from '../schema';
// import { imageModelComparator } from 'coms/ImageGalery';
import { getSchema } from 'apis/schema';

import { AsyncModelBoxView, AsyncModelBoxWraperView } from 'coms/ui/Box';
import { CommonThumbView } from '../../common-views/CommonThumbView';
import { getObjectImagesCollection } from 'm/realties/objects';
import { propertySchemaApi } from 'apis/schema';

const commonMixin = {
	initialize(options) {
		this.mergeOptions(options, ['offer', 'realtyObject']);
		this.initializeModel();
	},
	initializeModel() {
		if (!this.model && this.offer) {
			let id = this.offer.getValueByPath('obj.id');
			this.model = realtyObjectsStore.get(id, true);
		} else if (!this.model && this.realtyObject) {
			this.model = this.realtyObject;
		}
		this.syncModels();
	},
	async syncModels() {
		let schema = getSchema(this.model);
		try {

			await this.model.whenFetched;

			if (schema) {
					await schema.whenBackendSchemaFetched;
			}
			this.triggerMethod('data:ready');

		} catch(exc) {
			console.error('-syncModels', exc);
		}
	}
}

function isWarehouse(v) {
    return false;
}

const NameView = HamburgerView.extend({
	baseClassName: 'ui2-molecule',
	thisClassName: 'realty-box-name',
	getChildren() {
			let actions;
			let open;
			let name = {
					class: View,
					baseClassName: 'ui2-atom',
					thisClassName: 'realty-name',
					modelSchema,
					template: '<big><%= name %></big><small></small>',
					templateContext() {
						return {
							name: propertySchemaApi.displayValue('name', this.model, modelSchema)
						}
					}
			};
			return [ actions, open, name ];
	}
});

export const RealtyObjectBoxView = AsyncModelBoxView.extend({
	thisClassName: 'realty-object-box',
	content: v => [

		{
			class: RealtyObjectPropertiesView,
			propertiesSetName: v.getOption('propertiesSetName', true),
		},
	],
    // ...commonMixin,

    // onDataReady() {
    //     console.log('data:ready');
    //     this.render();
    // }
});

export const _RealtyObjectBoxViewWraper = HamburgerView.extend({

	thisClassName: 'realty-object-box-holder',

	childrenViews: v => [
		{
			class: RealtyObjectBoxView,
			propertiesSetName: v.getOption('isWarehouse', true) ? 'warehouse' : 'office'
		},
		{
			class: RealtyObjectBoxView,
			propertiesSetName: 'lessee'
		},
	],

	...commonMixin,

});




export const RealtyObjectBoxViewWraper = AsyncModelBoxWraperView.extend({
	thisClassName: 'realty-object-box-holder',
	initialize(options) {
		this.mergeOptions(options, ['offer']);
	},
	childrenViews: v => [
		{
			class: RealtyObjectBoxView,
			propertiesSetName: v.getOption('isWarehouse', true) ? 'warehouse' : 'office',
			content: v => [
				NameView,
				{
					class: CommonThumbView,
					buildCollection: getObjectImagesCollection
				},
				{
					class: RealtyObjectPropertiesView,
					propertiesSetName: v.getOption('propertiesSetName', true),
				},
			],
		},
		{
			class: RealtyObjectBoxView,
			propertiesSetName: 'lessee'
		},
	],
	isWarehouse() {
		let offer = this.offer;
		return offer && offer.get('market') === 'warehouses';
	},
	// childViewOptions() {
	// 	let offer = this.getOption('offer', true);
	// 	let isWarehouse = offer.get('market') === 'warehouses';
	// 	return {
	// 			offer,
	// 			isWarehouse
	// 	}
	// },
	childViewOptions() {
		return {
			offer: this.offer,
			isWarehouse: this.isWarehouse()
			// thisClassName: 'realty-box',
			// content: [
			// 	RealtyNameView,
			// 	RealtyThumbView,
			// 	RealtyPropertiesView
			// ],
		}
	},
	initializeModel() {

        let id = this.offer.getValueByPath('obj.id');
        this.model = realtyObjectsStore.get(id, true);
  },
});

