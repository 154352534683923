module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="comment-info"><span class="comment-author">'+
((__t=( _authorName ))==null?'':__t)+
'</span><span class="comment-date">'+
((__t=( _dateText ))==null?'':__t)+
'</span></div>\n<div class="comment-text">'+
((__t=(text))==null?'':__t)+
'</div>';
}
return __p;
};
