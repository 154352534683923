﻿import { _, $ } from 'vendors';

$(function () {

	$.fn.attrs = function () {
		if (this.length == 0) return;
		var el = this.first().get(0);
		var attrs = {};
		$.each(el.attributes, function () {
			if (this.specified) {
				var h = {};
				h[this.name] = this.value;
				_.extend(attrs, h);
			}
		});

		return attrs;
	}

});