// import Properties from './properties';
// import Medias from './medias';
// import RealtyObjects from './realty-objects';

import { Properties } from '../../../entity-list-common/edit';
import { MediaLayout } from '../../../entity-list-common/edit/medias';

import CardLayout from './card-layout';

import Offers from './offers';

const ignoreGroups = ['Address', 'Actualize', 'Realty', 'Owner'];

export default [
    // {
    //     id:'main',
    //     label: 'Основные х-ки',
    //     view: Properties,
    //     viewOptions: () => ({
    //         singleGroup: true,
    //         filters: {
    //             prop: prop => prop.get('parentId') === 'root',
    //             group: group => group.id === 'root'
    //         }
    //     }),
    // },
    {
        id: 'main',
        label: 'карточка',
        view: CardLayout
    },
    {
        id: 'offers',
        label: 'предложения',
        view: Offers
    },
    {
        id:'props',
        label: 'Все х-ки',
        view: Properties,
        viewOptions: () => ({
            filters: {
                group: group => ignoreGroups.indexOf(group.id) < 0, // !== 'Actualize'
            }
        })
    },
    {
        id:'actualize',
        label: 'Статус актуализации',
        view: Properties,
        viewOptions: () => ({
            singleGroup: true,
            filters: {
                prop: prop => prop.get('parentId') === 'Actualize',
                group: group => group.id === 'Actualize'
            }
        }),
    },    
    {
        id:'media',
        label: 'Фотографии и файлы',
				claims: [
					{
						svcActualizationModule: 'view', //'editRealtiesFast'
					},
					{
						svcActualizationModule: 'editRealtiesFast'
					},
				],
        view: MediaLayout,
        viewOptions() {
            return {
                
            }
        }
        //view: Properties,
    },
    // {
    //     id:'objects',
    //     label: 'Площади',
    //     view: RealtyObjects,
    // },
    // {
    //     id:'adm',
    //     label: 'в синюю',
    //     newTab: true,
    //     href: model => 'http://adm.nrg.plus/rlt/objects/' + model.id,
    // },
]
