﻿//define('action', [], function() {});

function normalizeHash(hash)
{
    //console.log('normalize', hash);
    return hash;
    //return _(hash).map(function(mask, module) {
    //    return { module: module, mask: mask };
    //});
}

function action(a1, a2, a3, a4, a5) {
    var options = {};
    var len = arguments.length;
    if (len == 1 && typeof a1 === 'object')
        _.extend(options, a1);
    else if ((len == 1 && typeof a1 === 'string') || (len > 1 && typeof a1 === 'string' && typeof a2 !== 'string')) 
        _.extend(options, { type: a1, rights: normalizeHash((a2 || {})) }, (a3 || {}));
    else if (typeof a1 === 'string' && typeof a2 === 'string' && typeof a3 === 'object')
        _.extend(options, { type: a1, label: a2, rights: normalizeHash((a3 || {})) }, (a4 || {}));
    else if (typeof a1 === 'string' && typeof a2 === 'string' && typeof a3 === 'string' && typeof a4 === 'string')
        _.extend(options, { type: a1, label: a2, rights: (({})[a3] = a4) });

    return options;
}

export default action;