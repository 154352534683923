import { flatObject, setValueByPath } from 'utils/object-manipulations';
export const mergeAttributesMixin = {

	mergeAttributes(hash) {
		if (hash == null || typeof hash !== 'object') { return; }
		const flatHash = flatObject(hash);
		for (let key in flatHash) {
			setValueByPath(this, key, flatHash[key])
		}
	}

}