﻿//define('svc-realties-offers-views-cardInModal-realty', ['bus'], function (Bus) {});

import Bus from 'bus';

import adrtemplate from './realtyAddress.html';
import rlttemplate from './realty.html';


import { MneView, MneNextCollectionView } from 'core/mne';

import { MnView, CollectionView } from 'vendors';

import { getBuildingImagesCollection } from 'm/realties/buildings';
import { showImageGalery, imageViewComparator, ImageView } from 'coms/ImageGalery';

import { BoxView } from 'coms/ui/Box';
import { HamburgerView } from 'coms/ui/HamburgerView';
import { View } from 'core';
import { BuildingInList } from '../../../building/views/inList';

var Address = MneView.extend({
	className: 'block',
	//template: '#tmpl-service-realties-offers-views-cardInModal-realtyAddress',
	template: adrtemplate,
});

var _ImageView = MneView.extend({
	tagName: 'img',
	template:_.noop,
	attributes: function () {
		return {
			src: Bus.media.request('url', this.model.get('id'), 'w270-c-rbc', 'jpg'),
		}
	},
	triggers: { click: 'click' }
});

const RealtyImageView = ImageView.extend({
	imageHash: v => v.model.id,
	imageExt: 'jpg',
	imageOptions: 'w270-c-rbc',
	events: {
		click(e) {
			this.trigger('click', this.model);
		}
	},
})


const ImagesView = CollectionView.extend({
	tagName:'section',
	childView: RealtyImageView,
	viewComparator: imageViewComparator,
	// childViewOptions(child) {
	// 	return {
	// 		imageHash: child.id,
	// 		imageExt: 'jpg',
	// 		imageOptions: 'w270-c-rbc',
	// 		triggers: { click: 'click' }
	// 	}
	// },
	childViewEvents:{
		click(model) {
			//console.log(arguments);
			showImageGalery(this.collection, model);
		}
	}
});


var OldView = MneView.extend({
	//template: '#tmpl-service-realties-offers-views-cardInModal-realty',
	template: rlttemplate,
	regions: {
		'card': { el: '.realtyBlock-region', replaceElement: false },
		'address': { el: '.address-region', replaceElement: true },
		'images': { el: '.realtyImages-region', replaceElement: false }
	},
	onRender: function () {


		this.showCard();
		this.showAddress();
		this.showImages();

	},
	showCard: function () {
		var View = BuildingInList;
		// Bus.Views.request('realties:building:inList');
		var offer = this.getOption('offer');
		//var realties = //Bus.Models.request('realties:buildings:all');
		/*
		var trueHash = offer.get('bld');
		var bldHash = offer.get('building');
		
		var rltId = offer.get('bld').id;
		var rlt = realties.get(rltId);
		if (!rlt) return;
		rlt.set(bldHash);
		*/
		var rlt = offer.getRealty();
		this.realty = rlt;
		this.realtyId = rlt.id;



		var view = new View({ model: rlt });
		this.showChildView('card', view);
	},
	showAddress: function () {
		if (!this.realty) return;
		var view = new Address({ model: this.realty });
		this.showChildView('address', view);
	},
	showImages: function () {

		var col = getBuildingImagesCollection(this.realtyId);
			//Bus.Models.request('get:realty:images', this.realtyId);
		var view = new ImagesView({
			collection: col,
		});
		//console.log(col);
		this.showChildView('images', view);
	}
});



/*
const RealtyThumbView = HamburgerView.extend({
	initialize() {
		this.collection = getBuildingImagesCollection(this.model.id);
	},
	getChildren() {
		let thumbnail = this.model.get('thumbnail');
		let views = [
			{
				class: RealtyImageView,
				imageHash: thumbnail,
				triggers: { 'click': 'click' }
			},
			{
				class: View,
				template: '<%= label %>',
				triggers: { 'click': 'click' },
				collectionEvents: {
					'sync':'render'
				},
				templateContext() {
					let len = this.collection.length;
					let label = 'фотографии отсутствуют';
					if (len) {
						label = `посмотреть фотографии (${len})`;
					}
					return {
						label
					}
				}
			}
		];
		return views;
	},
	childViewEvents() {
		return {
			click() {
				if (this.collection.length) {
					showImageGalery(this.collection);
				}
			}
		}
	}
})

const RealtyBoxView = BoxView.extend({
	content:[
		{
			class: MnView,
			template: () => 'realty',
			onRender() { console.log('-+THIS+-', this); }
		},
		{
			class: RealtyThumbView,
			//template: () => 'thumb'
		},
		{
			class: MnView,
			template: () => 'properties'
		},
	]
});

const RealtyBoxViewWraper = HamburgerView.extend({
	initialize(options) {
		this._debugName = 'realty-box-wrapper';
		this.mergeOptions(options, ['offer']);
		if (this.offer) {
			this.model = this.offer.getRealty();
		}
		//console.log('-+WRAPER+-', this.getOption('offer').getRealty());
	},
	// childViewOptions() {
	// 	return {
	// 		model: this.model,
	// 	}
	// },
	childrenViews: [
		{
			class: RealtyBoxView
		}
	]
})

export default RealtyBoxViewWraper;
*/
export default OldView;