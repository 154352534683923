﻿//define('realties', ['appModule', 'link', 'realties-layout', 'realties-search', 'realties-searchOnMap', 'realties-object', 'realties-building','geo'], function (appModule, link, Layout, search, searchOnMap, moduleRealtyObject, moduleRealtyBuilding, geo) {});


import appModule from 'appModule';
import link from 'link';
import search from './search';
import searchOnMap from './searchOnMap';
import moduleRealtyObject from './object';
import moduleRealtyBuilding from './building';

import './offers-v2.less';

var module = appModule.content({
	name: 'realties',
	label: 'Недвижимость',
	urlKey: 'realties',
	routeData: () => link.route('realties'),
	claims: {
		svcRealtiesModule: ['view']
	},			
	onRun: function () {

		this.showButtonsLayout();

//         var links = [
//             link('на главную', 'root', { attrs: { class: 'go-up' } })
		//	, link('подбор по списку', 'realtiesSearch')
		//	, link('подбор по карте', 'realtiesSearchMap')
//         ];
		//var result = this.createAndShow(Layout, 'Недвижимость', { navLinks: links}, { navLinks: links });
	}
});
//module.addRunPromise(geo.runPromise);
module.addSubModule(search);
module.addSubModule(searchOnMap);
//console.log("SEARCHONMAP ADDED");
module.addSubModule(moduleRealtyObject);
module.addSubModule(moduleRealtyBuilding);

export default module;
