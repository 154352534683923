export const testData = {
    "presentationId": "xxx123zzz888",
    "blocks": [
      {
        "blockId": "header",
        "isOptional": false,
        "name": "Заголовок презентации"
      },
      {
        "blockId": "building_header",
        "realtyId": "6560a77b-914e-4c71-bef7-0072b493d298",
        "isOptional": false,
        "name": "Заголовок объекта"
      },
      {
        "blockId": "building_location",
        "realtyId": "6560a77b-914e-4c71-bef7-0072b493d298",
        "isOptional": true,
        "name": "Расположение объекта"
      },
      {
        "blockId": "building_info",
        "realtyId": "6560a77b-914e-4c71-bef7-0072b493d298",
        "isOptional": true,
        "name": "Информация об объекте"
      },
      {
        "blockId": "building_photo",
        "realtyId": "6560a77b-914e-4c71-bef7-0072b493d298",
        "isOptional": true,
        "name": "Фотографии объекта"
      },
      {
        "blockId": "area_commercial",
        "realtyId": "6560a77b-914e-4c71-bef7-0072b493d298",
        "realtyObjectId": "67b8c9e4-2e84-4f28-afac-0dbc1385285b",
        "realtyOfferId": "so40704",
        "isOptional": false,
        "name": "Коммерческое предложение помещения",
        "errors": [
          {
            "dataType": "object",
            "property": "info.spaceLayout"
          }
        ]
      },
      {
        "blockId": "area_photo",
        "realtyId": "6560a77b-914e-4c71-bef7-0072b493d298",
        "realtyObjectId": "67b8c9e4-2e84-4f28-afac-0dbc1385285b",
        "realtyOfferId": "so40704",
        "isOptional": true,
        "name": "Фотографии помещения",
        "errors": [
          {
            "dataType": "object",
            "property": "medias",
            "message": "слишком мало фото, должно быть не менее 3-х"
          }
        ]
      },
      {
        "blockId": "area_schema",
        "realtyId": "6560a77b-914e-4c71-bef7-0072b493d298",
        "realtyObjectId": "67b8c9e4-2e84-4f28-afac-0dbc1385285b",
        "realtyOfferId": "so40704",
        "isOptional": true,
        "name": "Планировка помещения",
        "errors": [
          {
            "dataType": "object",
            "property": "medias",
            "message": "схема помещения не задана"
          }
        ]
      },
      {
        "blockId": "building_header",
        "realtyId": "2d2dc898-eb9f-47f5-bc4e-039b8ff59eb8",
        "isOptional": false,
        "name": "Заголовок объекта"
      },
      {
        "blockId": "building_location",
        "realtyId": "2d2dc898-eb9f-47f5-bc4e-039b8ff59eb8",
        "isOptional": true,
        "name": "Расположение объекта"
      },
      {
        "blockId": "building_info",
        "realtyId": "2d2dc898-eb9f-47f5-bc4e-039b8ff59eb8",
        "isOptional": true,
        "name": "Информация об объекте"
      },
      {
        "blockId": "building_photo",
        "realtyId": "2d2dc898-eb9f-47f5-bc4e-039b8ff59eb8",
        "isOptional": true,
        "name": "Фотографии объекта"
      },
      {
        "blockId": "area_commercial",
        "realtyId": "2d2dc898-eb9f-47f5-bc4e-039b8ff59eb8",
        "realtyObjectId": "4289424a-9449-469d-9946-1a63811cbc7c",
        "realtyOfferId": "ro61797",
        "isOptional": false,
        "name": "Коммерческое предложение помещения",
        "errors": [
          {
            "dataType": "object",
            "property": "info.spaceLayout"
          }
        ]
      },
      {
        "blockId": "area_photo",
        "realtyId": "2d2dc898-eb9f-47f5-bc4e-039b8ff59eb8",
        "realtyObjectId": "4289424a-9449-469d-9946-1a63811cbc7c",
        "realtyOfferId": "ro61797",
        "isOptional": true,
        "name": "Фотографии помещения",
        "errors": [
          {
            "dataType": "object",
            "property": "medias",
            "message": "слишком мало фото, должно быть не менее 3-х"
          }
        ]
      },
      {
        "blockId": "area_schema",
        "realtyId": "2d2dc898-eb9f-47f5-bc4e-039b8ff59eb8",
        "realtyObjectId": "4289424a-9449-469d-9946-1a63811cbc7c",
        "realtyOfferId": "ro61797",
        "isOptional": true,
        "name": "Планировка помещения",
        "errors": [
          {
            "dataType": "object",
            "property": "medias",
            "message": "схема помещения не задана"
          }
        ]
      },
      {
        "blockId": "area_commercial",
        "realtyId": "2d2dc898-eb9f-47f5-bc4e-039b8ff59eb8",
        "realtyObjectId": "1871ae33-dc3b-4d2d-9872-5ae4a0423ee5",
        "realtyOfferId": "ro61793",
        "isOptional": false,
        "name": "Коммерческое предложение помещения",
        "errors": [
          {
            "dataType": "object",
            "property": "info.spaceLayout"
          }
        ]
      },
      {
        "blockId": "area_photo",
        "realtyId": "2d2dc898-eb9f-47f5-bc4e-039b8ff59eb8",
        "realtyObjectId": "1871ae33-dc3b-4d2d-9872-5ae4a0423ee5",
        "realtyOfferId": "ro61793",
        "isOptional": true,
        "name": "Фотографии помещения",
        "errors": [
          {
            "dataType": "object",
            "property": "medias",
            "message": "слишком мало фото, должно быть не менее 3-х"
          }
        ]
      },
      {
        "blockId": "area_schema",
        "realtyId": "2d2dc898-eb9f-47f5-bc4e-039b8ff59eb8",
        "realtyObjectId": "1871ae33-dc3b-4d2d-9872-5ae4a0423ee5",
        "realtyOfferId": "ro61793",
        "isOptional": true,
        "name": "Планировка помещения",
        "errors": [
          {
            "dataType": "object",
            "property": "medias",
            "message": "схема помещения не задана"
          }
        ]
      },
      {
        "blockId": "broker_contacts",
        "isOptional": false,
        "name": "Контакты брокера"
      }
    ],
    "isNdsInclude": false,
    "isGreyscalePhotos": false,
    "dataType": "multipleOffers",
    "data": [
      {
        "id": "6560a77b-914e-4c71-bef7-0072b493d298",
        "idString": "minskaya-plaza",
        "webId": 839,
        "created": "1900-01-01T00:00:00Z",
        "modified": "2022-05-11T14:27:31.247Z",
        "modifierId": "855de67a-2e56-433f-ab78-0d20a17e8a78",
        "name": "Victory Park Plaza",
        "prefix": "Бизнес-центр",
        "markets": "offices",
        "realtyType": "multistorey",
        "buildingType": "officeBuilding",
        "taxSvcNumber": 30,
        "buildingClass": 1.50,
        "buildingClassLetter": "B+",
        "land": {},
        "building": {
          "status": "complete",
          "buildYear": 2014,
          "squareTotal": 35045.00,
          "squareUsefull": 26417.00,
          "floors": 7,
          "material": "none",
          "note": "бизнес-центр\r\n\r\nготово к чистовой отделке\r\n\r\nцентральное кондиционирование, системы приточно-вытяжной вентиляции, современный комплекс противопожарной защиты",
          "anons": "бизнес-центр\r\n\r\nготово к чистовой отделке\r\n\r\nцентральное кондиционирование, системы приточно-вытяжной вентиляции, современный комплекс противопожарной защиты."
        },
        "inner": {
          "powerType": "none",
          "ventilation": "none",
          "airConditioning": "none",
          "fireSafety": "none",
          "heating": "none",
          "waterSupply": "none",
          "hotWater": "none",
          "note": "В собственном дворе бизнес-парка «Минская плаза» предусмотрены парковочные места на охраняемых паркингах — вместительном внутреннем и гостевом. На территории делового центра планируется создание богатой собственной инфраструктуры для максимально возможного удовлетворения потребностей владельцев и арендаторов офисов: будет открыта столовая для сотрудников, кафе и рестораны высокой кухни, магазины, салон красоты, отделения банков, химчистка, будут предоставляться разнообразные бытовые услуги.",
          "anons": "В собственном дворе бизнес-парка «Минская плаза» предусмотрены парковочные места на охраняемых паркингах — вместительном внутреннем и гостевом."
        },
        "outer": {
          "type": "none",
          "infrastructure": "none",
          "note": "В новом бизнес-центре «Victory Park Plaza» продаются офисные помещения. Офисный многоэтажный комплекс класса «В+» находится в престижном районе Москвы, рядом с Кутузовским проспектом, ТТК и в семи минутах ходьбы от строящейся станции метро «Минская».В офисном парке, который будет сдан в сентябре 2014 года, по привлекательной инвестиционной цене предлагаются готовые к чистовой отделке светлые офисные помещения с панорамным остеклением, свободной планировки, и эксклюзивные офисы с собственными террасами и видом на озеро. Здание оборудовано системами центрального кондиционирования и приточно-вытяжной вентиляции, охраны и контроля доступа (СКУД),современным комплексом противопожарной защиты, автоматизированной системой контроля проезда автомобилей на территорию, установлены лифты «Kone». Качественную связь обеспечивают компании «Билайн Бизнес» и «Роснет». По внешнему периметру комплекса установлено круглосуточное видеонаблюдениес записью на цифровой регистратор. За эксплуатацию БЦ «Victory Park Plaza» отвечает профессиональная управляющая компания.",
          "anons": "В новом бизнес-центре «Victory Park Plaza» продаются офисные помещения. Офисный многоэтажный комплекс класса «В+» находится в престижном районе Москвы, рядом с Кутузовским проспектом, ТТК и в семи минутах ходьбы от строящейся станции метро «Минская»."
        },
        "communicate": {},
        "lift": {
          "type": "none"
        },
        "parking": {
          "type": "none",
          "note": "вместительный внутренний (охраняемый) паркинг\r\n",
          "anons": "вместительный внутренний (охраняемый) паркинг."
        },
        "lux": {
          "type": "none",
          "infrastructure": "none",
          "note": "В собственном дворе бизнес-парка «Минская плаза» предусмотрены парковочные места на охраняемых паркингах — вместительном внутреннем и гостевом. На территории делового центра планируется создание богатой собственной инфраструктуры для максимально возможного удовлетворения потребностей владельцев и арендаторов офисов: будет открыта столовая для сотрудников, кафе и рестораны высокой кухни, магазины, салон красоты, отделения банков, химчистка, будут предоставляться разнообразные бытовые услуги.",
          "anons": "В собственном дворе бизнес-парка «Минская плаза» предусмотрены парковочные места на охраняемых паркингах — вместительном внутреннем и гостевом."
        },
        "entry": {
          "type": "security, postControl",
          "note": "системы охраны и контроля доступа в здания, круглосуточное видеонаблюдение по внешнему периметру комплекса, система контроля доступа (СКУД), автоматизированная система контроля проезда автомобилей на территорию, бюро пропусков для посетителей",
          "anons": "системы охраны и контроля доступа в здания, круглосуточное видеонаблюдение по внешнему периметру комплекса, система контроля доступа (СКУД), автоматизированная система контроля проезда автомобилей на территорию, бюро пропусков для посетителей."
        },
        "features": {
          "type": "none"
        },
        "transfer": {
          "type": "none",
          "note": "Бизнес-центр «Victory Park Plaza» находится  в районе Дорогомилово Западного административного округа (ЗАО) Москвы по адресу: Минская улица, д. 2ж.\r\nРядом с БЦ проходят Мосфильмовская улица, Ломоносовский проспект, недалеко находится развязка Минской улицы с Кутузовским проспектом. Расстояние до МКАД составляет 8,2 км, до ТТК —4,1 км.\r\nВ семи минутах ходьбы от офисного центра планируется строительство станции метро «Минская». За 18 минут по Мосфильмовской улице можно доехать на машине до Киевского вокзала, откуда регулярно отправляется аэроэкспресс в аэропорт «Внуково».\r\n",
          "anons": "Бизнес-центр «Victory Park Plaza» находится  в районе Дорогомилово Западного административного округа (ЗАО) Москвы по адресу: Минская улица, д. 2ж."
        },
        "addressId": "801cf792-47db-41bd-9231-869163feb0ab",
        "address": {
          "id": "801cf792-47db-41bd-9231-869163feb0ab",
          "lat": 55.727073834323612,
          "lng": 37.495619058609009,
          "route": "Минская ул.",
          "house": "2Г",
          "fullAddress": "Минская ул., 2Г, Москва, Россия, 121096",
          "countryId": "7425865f-0e67-4016-9edd-1cd471cb2d08",
          "regionId": "57c0fa45-e991-4aab-82bb-daeedf4f7d1e",
          "gd": {
            "house": "2Г",
            "route": "Минская ул.",
            "sublocality": "ЗАО",
            "locality": "Москва",
            "administrativeArea2": "Москва",
            "administrativeArea1": "Москва",
            "country": "Россия",
            "postalCode": "121096"
          },
          "metro": {
            "id": "e3c107d8-d8ac-483d-b90b-3b702952ab05",
            "name": "Филёвский парк",
            "idString": "moskva_filevskaya_liniya_filevskiy_park",
            "idNum": 65,
            "prefix": "станция метро",
            "lat": 55.7396,
            "lng": 37.4833,
            "countryId": "7425865f-0e67-4016-9edd-1cd471cb2d08",
            "regionId": "57c0fa45-e991-4aab-82bb-daeedf4f7d1e",
            "lineId": "ea71a452-175a-4e1c-a840-00c0f9ea86b9"
          },
          "metroDistance": 1594.96,
          "subwayStations": [
            {
              "id": "e3c107d8-d8ac-483d-b90b-3b702952ab05",
              "name": "Филёвский парк",
              "lineId": "ea71a452-175a-4e1c-a840-00c0f9ea86b9",
              "lineName": "Филёвская линия",
              "lineNumber": "4",
              "distance": 1594.96
            }
          ]
        },
        "realtyObjects": [
          {
            "id": "67b8c9e4-2e84-4f28-afac-0dbc1385285b",
            "webId": 40704,
            "created": "2018-11-30T10:41:20.347Z",
            "modified": "2018-11-30T00:00:00Z",
            "status": "actual",
            "markets": "offices",
            "alsoOffering": "none",
            "realtyId": "6560a77b-914e-4c71-bef7-0072b493d298",
            "info": {
              "features": "none",
              "squareTotal": 90.70,
              "squareOffer": 90.70,
              "floor": 1,
              "floorsCount": 1,
              "state": "unknown",
              "spaceLayout": "undefined",
              "purposes": "office",
              "currentPurpose": "none",
              "waterPoint": "none",
              "parking": "none"
            },
            "lessee": {},
            "office": {
              "features": "none"
            },
            "realtyOffers": [
              {
                "id": "so40704",
                "created": "2018-12-01T01:00:21.38Z",
                "modified": "2021-12-22T08:30:17.33Z",
                "status": "off",
                "objectId": "67b8c9e4-2e84-4f28-afac-0dbc1385285b",
                "operation": "sell",
                "market": "offices",
                "forCustomer": {
                  "taxType": "none",
                  "boma": 1.00,
                  "priceMeter": 204000.00,
                  "priceSquare": 18502800.00
                },
                "forAgent": {
                  "contractType": "verbaly",
                  "income": 0.00
                }
              }
            ]
          }
        ],
        "medias": [
          {
            "hash": "bb5290fd-5660-4c9f-a67e-63409140c3d5",
            "type": "commonImage",
            "groupName": "неразобранное",
            "name": "DSC_2626.JPG",
            "order": 90,
            "isPrimary": false
          },
          {
            "hash": "2ffb7ef1-5ef7-4666-852b-dd678c4cc44c",
            "type": "commonImage",
            "groupName": "неразобранное",
            "name": "DSC_2640.JPG",
            "order": 90,
            "isPrimary": false
          },
          {
            "hash": "f6be0794-857f-49e4-96cd-400cc9f30ee2",
            "type": "commonImage",
            "groupName": "неразобранное",
            "name": "DSC_2649.JPG",
            "order": 90,
            "isPrimary": false
          },
          {
            "hash": "ec082db9-d9c6-40f1-974a-5feb1d910bed",
            "type": "commonImage",
            "groupName": "неразобранное",
            "name": "DSC_2643.JPG",
            "order": 94,
            "isPrimary": false
          },
          {
            "hash": "3c9747ed-3aed-4261-8d48-6bf5ab03395f",
            "type": "commonImage",
            "groupName": "неразобранное",
            "name": "DSC_2759.JPG",
            "order": 103,
            "isPrimary": false
          },
          {
            "hash": "bc180d1e-b16a-46df-8742-af7815ac34bb",
            "type": "commonImage",
            "groupName": "неразобранное",
            "name": "DSC_2646.JPG",
            "order": 90,
            "isPrimary": false
          },
          {
            "hash": "e8ac24c2-933d-4cd3-a7c2-b918cb3b15c6",
            "type": "commonImage",
            "groupName": "4200x2800",
            "name": "DSC_2745.JPG",
            "order": 160,
            "isPrimary": true
          },
          {
            "hash": "6a8f15fe-f523-417a-a42b-2e26c25ad652",
            "type": "commonImage",
            "groupName": "неразобранное",
            "name": "DSC_2658.JPG",
            "order": 90,
            "isPrimary": false
          },
          {
            "hash": "ee4323cc-10c5-4a88-9bab-9a85c4792d1f",
            "type": "commonImage",
            "groupName": "неразобранное",
            "name": "DSC_2744.JPG",
            "order": 110,
            "isPrimary": false
          },
          {
            "hash": "ed3732e2-03c7-4774-9018-e66f0b13e22c",
            "type": "commonImage",
            "groupName": "неразобранное",
            "name": "DSC_2634.JPG",
            "order": 95,
            "isPrimary": false
          }
        ]
      },
      {
        "id": "2d2dc898-eb9f-47f5-bc4e-039b8ff59eb8",
        "idString": "Nikolskaya-Plaza",
        "webId": 475,
        "created": "1900-01-01T00:00:00Z",
        "modified": "2021-09-10T16:43:26.257Z",
        "modifierId": "855de67a-2e56-433f-ab78-0d20a17e8a78",
        "name": "Nikolskaya Plaza",
        "prefix": "Бизнес-центр",
        "markets": "offices",
        "realtyType": "multistorey",
        "buildingType": "officeBuilding",
        "taxSvcNumber": 10,
        "buildingClass": 1.00,
        "buildingClassLetter": "A",
        "land": {},
        "building": {
          "status": "complete",
          "buildYear": 2005,
          "squareTotal": 36000.00,
          "floors": 7,
          "material": "none",
          "note": "бизнес центр\r\n\r\nстандарт\r\n\r\nприточно-вытяжная вентиляция, кондиционирование\r\n",
          "anons": "бизнес центр\r\n\r\nстандарт\r\n\r\nприточно-вытяжная вентиляция, кондиционирование."
        },
        "inner": {
          "powerType": "none",
          "ventilation": "none",
          "airConditioning": "none",
          "fireSafety": "none",
          "heating": "none",
          "waterSupply": "none",
          "hotWater": "none",
          "note": "Бизнес-центр «Nikol’skaya Plaza» имеет наземную и подземную парковку.\r\nВнутренняя инфраструктура включает множество важных для посетителей компонентов. Речь идёт о банках и терминалами оплаты, в которых можно совершать необходимые финансовые операции в удобное для сотрудников офиса время.\r\nРестораны и кафе, расположенных непосредственно в здании бизнес-центра «Nikol’skaya Plaza», предлагают широкий выбор блюд и напитков ддля проведения деловой встречи или бизнес-ланча в компании коллег.\r\nПоблизости с объектом находятся многие известные достопримечательности Москвы. Прогуливаясь по историческим местам, можно провести свободное время в перерывах между плодотворной работой.\r\n",
          "anons": "Бизнес-центр «Nikol’skaya Plaza» имеет наземную и подземную парковку.\r\nВнутренняя инфраструктура включает множество важных для посетителей компонентов."
        },
        "outer": {
          "type": "none",
          "infrastructure": "none",
          "note": "Бизнес-центр класса «А» «Nikol’skaya Plaza» расположен в центре Москвы возле станции метро «Площадь Революции». Более века этот участок принадлежал роду Шереметьевых. В архитектурном оформлении благородство ушедшей эпохи сочетается с последними дизайнерскими идеями, благодаря чему здание гармонично вписывается в исторический район столицы. Сквозь окна бизнес-центра открывается завораживающий вид на калейдоскоп древних и современных сооружений.\r\nВ аренду предлагаются полностью готовые к въезду офисы премиум-класса, оснащённые климатическим оборудованием и другими удобствами, необходимыми для организации комфортного рабочего пространства. Подземный и наземный паркинг бизнес-центра «Nikol’skaya Plaza» обеспечивает высокий парковочный коэффициент для сотрудников и посетителей.\r\n",
          "anons": "Бизнес-центр класса «А» «Nikol’skaya Plaza» расположен в центре Москвы возле станции метро «Площадь Революции». Более века этот участок принадлежал роду Шереметьевых."
        },
        "communicate": {},
        "lift": {
          "type": "none"
        },
        "parking": {
          "type": "paidClosedWarm",
          "placesTotal": 140,
          "note": "наземная, подземная около  140 м/м\r\n",
          "anons": "наземная, подземная около  140 м/м."
        },
        "lux": {
          "type": "none",
          "infrastructure": "none",
          "note": "Бизнес-центр «Nikol’skaya Plaza» имеет наземную и подземную парковку.\r\nВнутренняя инфраструктура включает множество важных для посетителей компонентов. Речь идёт о банках и терминалами оплаты, в которых можно совершать необходимые финансовые операции в удобное для сотрудников офиса время.\r\nРестораны и кафе, расположенных непосредственно в здании бизнес-центра «Nikol’skaya Plaza», предлагают широкий выбор блюд и напитков ддля проведения деловой встречи или бизнес-ланча в компании коллег.\r\nПоблизости с объектом находятся многие известные достопримечательности Москвы. Прогуливаясь по историческим местам, можно провести свободное время в перерывах между плодотворной работой.\r\n",
          "anons": "Бизнес-центр «Nikol’skaya Plaza» имеет наземную и подземную парковку.\r\nВнутренняя инфраструктура включает множество важных для посетителей компонентов."
        },
        "entry": {
          "type": "security",
          "note": "круглосуточная охрана",
          "anons": "круглосуточная охрана."
        },
        "features": {
          "type": "none"
        },
        "transfer": {
          "type": "none",
          "note": "Бизнес-центр расположен в районе Китай-города Центрального административного округа (ЦАО) Москвы по адресу: ул. Никольская, 10. Рядом находятся Театральный проезд, Большой Черкасский переулок, Лубянская, Тетральная площадь и площадь Революции. Объект находится в 210 метрах от станции метро «Лубянка», что эквивалентно 5 минутам ходьбы.\r\nДорога пешком до станции «Площадь Революции» также займёт 5 минут.",
          "anons": "Бизнес-центр расположен в районе Китай-города Центрального административного округа (ЦАО) Москвы по адресу: ул. Никольская, 10. Рядом находятся Театральный проезд, Большой Черкасский переулок, Лубянская, Тетральная площадь и площадь Революции."
        },
        "addressId": "68fd3dc4-5524-4444-9549-2c325ba22661",
        "address": {
          "id": "68fd3dc4-5524-4444-9549-2c325ba22661",
          "lat": 55.758067990211863,
          "lng": 37.624096870422363,
          "route": "Никольская улица",
          "house": "10",
          "fullAddress": "Никольская улица, 10, Москва, Россия, 109012",
          "countryId": "7425865f-0e67-4016-9edd-1cd471cb2d08",
          "regionId": "57c0fa45-e991-4aab-82bb-daeedf4f7d1e",
          "gd": {
            "house": "10",
            "route": "Никольская улица",
            "locality": "Москва",
            "administrativeArea2": "город Москва",
            "administrativeArea1": "Москва"
          },
          "metro": {
            "id": "c26ee3d9-32c6-490c-ae64-b22e6ae067c8",
            "name": "Площадь Революции",
            "idString": "moskva_arbatsko-pokrovskaya_liniya_ploshadj_revolucii",
            "idNum": 54,
            "prefix": "станция метро",
            "lat": 55.7566,
            "lng": 37.6216,
            "countryId": "7425865f-0e67-4016-9edd-1cd471cb2d08",
            "regionId": "57c0fa45-e991-4aab-82bb-daeedf4f7d1e",
            "lineId": "eb97ab45-ccc8-49ce-a6db-c658371aa94c"
          },
          "metroDistance": 226.47,
          "subwayStations": [
            {
              "id": "c26ee3d9-32c6-490c-ae64-b22e6ae067c8",
              "name": "Площадь Революции",
              "lineId": "eb97ab45-ccc8-49ce-a6db-c658371aa94c",
              "lineName": "Арбатско-Покровская линия",
              "lineNumber": "3",
              "distance": 226.47
            }
          ]
        },
        "realtyObjects": [
          {
            "id": "4289424a-9449-469d-9946-1a63811cbc7c",
            "webId": 61797,
            "created": "2021-07-09T08:44:44.843Z",
            "modified": "2021-07-09T08:44:45.283Z",
            "status": "actual",
            "markets": "none",
            "alsoOffering": "none",
            "realtyId": "2d2dc898-eb9f-47f5-bc4e-039b8ff59eb8",
            "info": {
              "features": "none",
              "squareTotal": 60.00,
              "squareOffer": 60.00,
              "floor": 2,
              "state": "ready",
              "spaceLayout": "undefined",
              "purposes": "retail",
              "currentPurpose": "none",
              "waterPoint": "none",
              "parking": "none"
            },
            "lessee": {},
            "office": {
              "features": "none"
            },
            "warehouse": {
              "floorMaterial": "none",
              "features": "none",
              "heating": "none",
              "freezeEquipment": "none",
              "unloadingFeatures": "none",
              "unloadingEquipment": "none"
            },
            "retail": {},
            "apartment": {},
            "realtyOffers": [
              {
                "id": "ro61797",
                "created": "2021-07-09T08:44:45.283Z",
                "modified": "2022-04-13T05:18:26.603Z",
                "status": "active",
                "objectId": "4289424a-9449-469d-9946-1a63811cbc7c",
                "operation": "rent",
                "market": "offices",
                "forCustomer": {
                  "taxType": "nds",
                  "boma": 1.00,
                  "priceMeter": 37500.00,
                  "priceSquare": 187500.00
                },
                "forAgent": {
                  "contractType": "verbaly",
                  "income": 72.00
                }
              }
            ],
            "medias": [
              {
                "hash": "55437791-cb88-4391-8b49-e2163e36f02f",
                "type": "commonImage",
                "groupName": "неразобранное",
                "name": "60.jpg",
                "order": 0,
                "isPrimary": false
              }
            ]
          },
          {
            "id": "1871ae33-dc3b-4d2d-9872-5ae4a0423ee5",
            "webId": 61793,
            "created": "2021-07-09T08:38:31.627Z",
            "modified": "2021-08-18T09:49:15.257Z",
            "status": "actual",
            "markets": "none",
            "alsoOffering": "none",
            "realtyId": "2d2dc898-eb9f-47f5-bc4e-039b8ff59eb8",
            "info": {
              "features": "none",
              "squareTotal": 100.00,
              "squareOffer": 100.00,
              "floor": 4,
              "state": "ready",
              "spaceLayout": "undefined",
              "purposes": "office",
              "currentPurpose": "office",
              "waterPoint": "none",
              "parking": "none"
            },
            "lessee": {},
            "office": {
              "features": "none"
            },
            "warehouse": {
              "floorMaterial": "none",
              "features": "none",
              "heating": "none",
              "freezeEquipment": "none",
              "unloadingFeatures": "none",
              "unloadingEquipment": "none"
            },
            "retail": {},
            "apartment": {},
            "realtyOffers": [
              {
                "id": "ro61793",
                "created": "2021-07-09T08:38:32.213Z",
                "modified": "2022-04-13T05:18:26.527Z",
                "status": "active",
                "objectId": "1871ae33-dc3b-4d2d-9872-5ae4a0423ee5",
                "operation": "rent",
                "market": "offices",
                "forCustomer": {
                  "taxType": "nds",
                  "boma": 1.00,
                  "priceMeter": 41666.66,
                  "priceSquare": 347222.22
                },
                "forAgent": {
                  "contractType": "verbaly",
                  "income": 72.00
                }
              }
            ],
            "medias": [
              {
                "hash": "d0fb5306-77ae-434f-acbf-e37caa5031a4",
                "type": "commonImage",
                "groupName": "неразобранное",
                "name": "100.jpg",
                "order": 0,
                "isPrimary": false
              }
            ]
          }
        ],
        "medias": [
          {
            "hash": "e297bec4-a76c-4776-96f7-058c4ab7d91f",
            "type": "commonImage",
            "groupName": "неразобранное",
            "name": "Nikolskaya Plaza           ",
            "order": 110,
            "isPrimary": false
          },
          {
            "hash": "48a0338e-4ee6-4348-9d64-2050440a2c9f",
            "type": "commonImage",
            "groupName": "3300х2200",
            "name": "Nikolskaya Plaza",
            "order": 126,
            "isPrimary": false
          },
          {
            "hash": "78ae892d-e47a-4e76-bb5a-5ea872bea0c9",
            "type": "commonImage",
            "groupName": "1800х1200",
            "name": "Nikolskaya Plaza",
            "order": 130,
            "isPrimary": false
          },
          {
            "hash": "c1c8b7f3-4632-440b-b1f1-bea660c924fd",
            "type": "commonImage",
            "groupName": "неразобранное",
            "name": "Nikolskaya Plaza              vxodnaya-group1.jpg",
            "order": 10,
            "isPrimary": false
          },
          {
            "hash": "1E093554-A61E-4E6A-B509-CCB9B60088CC",
            "type": "commonImage",
            "groupName": "общий вид 3200х2400",
            "name": "Nikol’skaya Plaza",
            "order": 5,
            "isPrimary": false
          },
          {
            "hash": "be42a53e-18ab-41a3-91a8-f3f0bb9a1261",
            "type": "commonImage",
            "groupName": "неразобранное",
            "name": "Nikolskaya Plaza                  main-entrance.jpg",
            "order": 105,
            "isPrimary": false
          },
          {
            "hash": "c837820c-042a-480f-9fbf-0bdac6ac99f3",
            "type": "commonImage",
            "groupName": "неразобранное",
            "name": "parking-entrance.jpg",
            "order": 13,
            "isPrimary": false
          },
          {
            "hash": "5d740e2f-32c6-49cc-9cdb-6845d9f8a799",
            "type": "commonImage",
            "groupName": "неразобранное",
            "name": "Nikolskaya Plaza        vr.jpg",
            "order": -1,
            "isPrimary": false
          },
          {
            "hash": "6dd963f0-68ad-467e-abf7-17206de4cb92",
            "type": "commonImage",
            "groupName": "неразобранное",
            "name": "entrance3.jpg",
            "order": 95,
            "isPrimary": false
          },
          {
            "hash": "b7c0908d-7208-40a3-9a01-21f8a5b2540c",
            "type": "commonImage",
            "groupName": "2300х2100",
            "name": "Nikolskaya Plaza",
            "order": 150,
            "isPrimary": true
          }
        ]
      }
    ],
    "templateId": "real_estate_offer_template",
    "broker": {
      "name": "Дмитрий Витальевич",
      "email": "dmitry.v.averyanov@gmail.com",
      "phone": "+7 495 54 54 158"
    }
  }