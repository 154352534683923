module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="show-on-hover-context">\n	<div class="task-responsive">'+
((__t=( _empn(responsibleId) ))==null?'':__t)+
'</div>\n	<div class="task-state">'+
((__t=(_enum(state,'employeeTaskStates')))==null?'':__t)+
'</div>\n	<div class="task-text"><span class="task-icon"><span></span></span><span class="task-text-todo">'+
((__t=(toDo))==null?'':__t)+
'</span></div>\n	<div class="task-info"><span class="task-date">'+
((__t=( _tfn(dates.deadline) ))==null?'':__t)+
'<small class="show-on-hover"> ('+
((__t=( _fromNow(dates.deadline)))==null?'':__t)+
')</small></span></div>\n</div>';
}
return __p;
};
