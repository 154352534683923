import { Select } from './Select';

export const ApiSelect = Select.extend({
	constructor: function() {
		Select.apply(this, arguments);
		this.collection.fetch();
	},
	isBackendSelect: true,
	searchEnabled: true,
	getEntriesViewFilter() { },
});