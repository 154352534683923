import { View, CollectionView, Collection, Model } from 'core';
import { HamburgerView } from 'coms/ui/HamburgerView';
import { NextIconButtonView } from 'coms/ui/Button';
import { SearchRealtyControl, NewSearchPlaceControl } from 'ui/controls/map/control-input';
import { _ } from 'vendors';
import { mapsApi, MapContainerView } from 'apis/mapsApi';
import { getRealtyLatLng } from 'mods/realties';
import { GifLoaderIndicator, CssLoaderIndicator } from 'coms/ui/LoaderIndicator';
// import { userConfig } from 'identity';
// import { userConfig } from 'mods/acc';

const NextSearchRealtyControl = SearchRealtyControl.extend({
	setMapCenter(center, zoom) {
		const map = this.getMap();
		mapsApi.setMapCenter(map, center, zoom);
	},
	_createMarker(coords, animated) {
		return mapsApi.createRealtySearchMarker({ coords, animated, zIndex: 10 });
		// var marker = new google.maps.Marker({
		// 	position: coords,
		// 	animation: google.maps.Animation.BOUNCE,
		// });
		// return marker;
	},
	_showMarker(marker) {
		const map = this.getMap();
		mapsApi.showMarker(map, marker);
	},
	_hideMarker(marker) {
		const map = this.getMap();
		mapsApi.hideMarker(map, marker);
	},
	getMap() {
		let map = this.getOption('map', true);
		if (typeof map === 'function') {
			map = map();
		}
		return map;
	}
});

const SearchInputes = HamburgerView.extend({
	baseClassName: 'inputs-container',
	childrenViews: [
		{ class: NextSearchRealtyControl, name: 'searchRealty' }, { class: NewSearchPlaceControl, name: 'searchPlace' }
	],
	initialize(options) {
		this.mergeOptions(options, ['mapApi']);
		this.listenTo(this.mapApi, 'map:ready', map => {
			this.map = map;
			this.setupSearchInput();
		});
	},
	setupSearchInput() {
		const place = this.getNamedView('searchPlace');
		if (!place) { return; }

		mapsApi.setupAddressApi(this.map, {
			searchControl: {
				inputEl: place.inputEl,
				controlEl: undefined,
				updateMarker: true,
			},
			marker: {
				destroyMarkerOnClick: true,
				animated: true,
				autohide: 3000
			}
		});
		
		// const callback = mapsApi.getFindAndShowAddressCallback(this.map, { destroyMarkerOnClick: true, animated: true });
		// mapsApi.setupSearchInput(this.map, place.inputEl, { callback });
		console.error('== search place ready ==', place.inputEl);
	},
	childViewOptions() {
		return {
			map: () => this.map
		}
	}
}); 

const FeatureButton = NextIconButtonView.extend({
	classNames: [
		v => v.getToggleStatus()
	],
	action() {
		const current = this.getValue();
		this.setValue(!current);
	},
	_configKey() {
		return this.model.id;
	},
	getValue() {
		let value = mapsApi.userFeature(this._configKey());
		// userConfig.get(this._configKey());
		if (value == null) {
			const defVal = this.model.get('defaultValue');
			if (defVal != null) {
				value = defVal;
				this.setValue(value);
			}
		}
		return value;
	},
	setValue(value) {
		mapsApi.userFeature(this._configKey(), value);
		this.updateClassName();
	},
	getToggleStatus() {
		const value = this.getValue();
		const status = value === true ? 'on' : 'off';
		return status;
	},
	getIcon(type = '') {
		return this.model.get('icon' + type);
	}
});

const FeaturesView = CollectionView.extend({
	baseClassName: 'features-container',
	childView: FeatureButton,
	initialize() {
		const models = [
			{				
				id: 'points',
				icon:'fa:th',
				defaultValue: true,
			},
			{
				id: 'subway',
				icon: 'fa:subway',
				defaultValue: true,
			}
		]
		this.collection = new Collection(models);
	},
	childViewOptions() {
		return {
			featuresModel: this.model
		}
	},
});

const MapContainer = MapContainerView.extend({
	// baseClassName: 'map-container',
	// template: false,
	mapExtraOptions: {
		manyMarkers: true
	},
	initialize(options) {
		this.DBGNAME = 'map-container';
		this.mergeOptions(options, ['mapApi']);
	},
	// destroy() {
	// 	debugger;
	// 	MapContainerView.prototype.destroy.apply(this, arguments);
	// },
	// ___onAttach() {
	// 	console.log('map view attached', this._isAttached);
	// 	this.initializeMap();
	// },
	// ___initializeMap() {
	// 	console.log('initialize map....');
	// 	const entry = this.getOption('entry');
	// 	const realtyId = entry?.realtyId;
	// 	const center = realtyId ? getRealtyLatLng(realtyId) : undefined;
	// 	const zoom = realtyId ? 16 : undefined;
	// 	const el = this.getMapElement();
	// 	const map = mapsApi.attachMapToView(this, { el, center, zoom });
	// 	mapsApi.startSearchApi(this.mapApi, { mapView: this, map });
	// 	this.mapApi.triggerMethod('map:ready', map);
	// },
	// getMapOptions() {
	// 	const entry = this.getOption('entry');
	// 	if (entry && entry.realtyId) {
	// 		return { realtyId: entry.realtyId }
	// 		// const center = getRealtyLatLng(entry.realtyId);
	// 		// const zoom = 16;
	// 		// return {
	// 		// 	zoom,
	// 		// 	center: center || globalMapDefaults.center,
	// 		// }
	// 	}
	// },
	getEntryRealtyId() {
		// if (1 === 1) return;
		return this.mapApi.mapStateData.entryRealtyId;
		// const entry = this.getOption('entry');
		// const realtyId = entry?.realtyId;
		// return realtyId;
	},
	center() {
		const rid = this.getEntryRealtyId();
		return rid ? getRealtyLatLng(rid) : undefined;
	},
	zoom() {
		const rid = this.getEntryRealtyId();
		return rid ? 16 : undefined;
	},
	// mapOptions() {
	// 	const entry = this.getOption('entry');
	// 	const realtyId = entry?.realtyId;
	// 	const center = realtyId ? getRealtyLatLng(realtyId) : undefined;
	// 	const zoom = realtyId ? 16 : undefined;
	// 	return { zoom, center };
	// },
	onMapAttach(map) {
		if (!this.mapApi) {
			console.warn('mapApi missing!');
			// const update_cb = (a,b) => {
			// 	console.log('type', a, b.type);
			// }
			// const before = _.debounce(update_cb.bind(null, 'before'), 500, true);
			// const after = _.debounce(update_cb.bind(null, 'after'), 500);
			// const handler = ctx => {
			// 	before(ctx);
			// 	after(ctx);
			// }
			// //mapsApi.on(map, map, 'resize', cb.bind(null, 'dbg'));
			// mapsApi.on(map, map, 'update', handler);
		} else {
			mapsApi.startSearchApi(this.mapApi, { mapView: this, map });
			this.mapApi.triggerMethod('map:ready', map);
		}
	},
	// getMapElement() {
	// 	return this.el;
	// },

});

export const MapView = HamburgerView.extend({
	baseClassName: 'map-wrapper',
	stateClassNames: ['fetching'],
	childrenViews: [
		MapContainer,
		SearchInputes,
		FeaturesView,
		CssLoaderIndicator
	],
	initialize(options) {
		this.DBGNAME = 'map-wrapper';
		this.mergeOptions(options, ['mapApi']);
		this.listenTo(this.mapApi, {
			'before:fetch:clusters': () => this.state('fetching', true),
			'fetch:clusters': () => this.state('fetching', false)
		});
		//this.initializeMapApi();
	},
	// initializeMapApi() {
	// 	if (this.mapApi) { return; }
	// 	const filter = this.getOption('filterObject');
	// 	const mapFilters = mapsApi.createMapFilters({ 
	// 		getFiltersHash: () => filter.getUrlHash(), 
	// 		method: 'get',
	// 		setup: mapFilters => {
	// 			mapFilters.listenTo(filter, 'filters:apply', mapFilters.updateFilters)
	// 		} 
	// 	});
	// 	this.mapApi = mapsApi.createSearchApi({ mapFilters });
	// 	this.on('before:destroy', () => this.mapApi.destroy());

	// },
	childViewOptions() {
		return {
			// mapFilters: this.mapFilters,			
			mapApi: this.mapApi,
			entry: this.getOption('entry'),
			filterObject: this.getOption('filterObject'),
			selectionHolder: this.getOption('selectionHolder'),
			joinedOffers: this.getOption('joinedOffers')
		}
	},
	onSidebarOfferHover(offer) {
		const realtyId = (offer.attributes || offer).realtyId;
		const view = this.mapApi.realtyClusterViews.get(realtyId);
		if (view) {
			view.state('highlighted', true);
		}
	},
	onSidebarOfferUnhover(offer) {
		//toggleOfferHighlight(this.mapApi, offer, false);
	}	
	// childViewEvents: {
	// 	'map:initialized'(mapApi) {

	// 	}
	// }
});