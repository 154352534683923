import { View } from 'base/views';

export const CloseButtonView = View.extend({
    className: 'new-flex-modal__close fa fa-close',
    initialize() {
        let addClass = this.getOption('addClass');
        if (addClass) {
            this.$el.addClass(addClass);
        }
    },
    template: false,
    events: {
        'click'() {
            this.trigger('modal:close');
        }
    }
});