import MapInput from '../input';
import { View } from 'core';
import { _ } from 'vendors';

export const SearchPlaceControl = MapInput.extend({
	className: 'map-control-searchPlace nested-pac-container',
	doNotFocus: true,
	// events: {
	// 	'focus input': function (e) {
	// 		console.log('- focused -');
	// 		var cnt = $('.pac-container');
	// 		$(e.target).parent().append(cnt);
	// 	}
	// },
	events: {
		'focus input'() {
			setTimeout(() => this.updatePacContainer(), 50);
		},
		'keypress input'() {
			setTimeout(() => this.updatePacContainer(), 50);
		}
	},
	updatePacContainer() {
		const rect = this.el.getBoundingClientRect();
		const right = window.innerWidth - (rect.x + rect.width) + 'px';

		const $el = $('.pac-container');
		const pacEl = $el.get(0);
		if (pacEl == null) {
			return;
		}
		Object.assign(pacEl.style, {
			width: '300px',
			left: 'unset',
			right
		});
	},
	inputAttributes: {
		placeholder: 'название места',
		title: 'перемещает карту на выбранное место'
	}
});


export const NewSearchPlaceControl = View.extend({
	baseClassName: 'map-control-searchPlace map-control-wrapper',
	template: false,
	initialize() {
		const value = this.getOption('value') || '';
		this.el.innerHTML = `<span class="input-view">
		<input class="form-control pac-target-input" type="text" value="${value}" placeholder="название места" title="перемещает карту на выбранное место" autocomplete="off" />
		</span><span class="icon"></span>`;
		this.inputEl = this.$('input').get(0);
	},
	classNames: [
		v => v.getOption('mapType', true) === 'yandex' ? '' : 'nested-pac-container',
	],

});