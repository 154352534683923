﻿//define('svc-comments',['app-config'],function(cfg){});

import cfg from 'app-config';
import {bbeViewComparator} from 'utils';

import { BbeModel, BbeCollection } from 'core/bbe';

	//dbe.Id = Guid.NewGuid();
	//dbe.ServiceAccount = svc;
	//dbe.Actor = emp;
	//dbe.Text = post.Text;
	//dbe.TaskId = post.TaskId;
	//dbe.Subject = post.Subject;
	//dbe.Result = post.Result;
	//dbe.RealtyId = post.RealtyId;
	//dbe.OwnerId = post.OwnerId;
	//dbe.ObjectId = post.ObjectId;
	//dbe.EmployeeId = post.EmployeeId;
	//dbe.DealId = post.DealId;
	//dbe.Date = DateTime.UtcNow;
	//dbe.ClientId = post.ClientId;
	var Model = BbeModel.extend({
		urlRoot: function () {
			if (this.isNew()) {
				return cfg.paths.api.comments;
			} else if (this.collection) {
				return _.result(this.collection, 'url');
			} else {
				return;
			}
		},
	});
	var Collection = BbeCollection.extend({		
		model: Model,
		baseUrl: cfg.paths.api.comments,
		comparator: function (a, b) {
			return bbeViewComparator(b, a, function () { return this.get('date'); });
		}
	});


	//dbe.TaskId = post.TaskId;
	//dbe.RealtyId = post.RealtyId;
	//dbe.OwnerId = post.OwnerId;
	//dbe.ObjectId = post.ObjectId;
	//dbe.EmployeeId = post.EmployeeId;
	//dbe.DealId = post.DealId;
	//dbe.ClientId = post.ClientId;

	var entityMap = {
		'task': 'task',
		'realty':'realty',
		'owner': 'owner',
		'object': 'object',
		'employee': 'employee',
		'deal': 'deal',
		'client':'client',
	}


	var ns = {};

	ns.Collection = Collection;
	ns.Model = Model;
	ns.createCollection = function (entity, id) {
		console.log('arguments', arguments);
		var mapping = entityMap[entity];
		if (!mapping || !id) return;

		var col = new Collection();
		col.url = function () {
			return col.baseUrl + '/' + mapping + '/' + id
		}
		return col;
	}

	export default ns;
