// define('svc/actualization/helpers',[
// 	'app-config'
// ],function(
// 	cfg
// ){});


import cfg from 'app-config';
import link from 'link';
import refs from 'references';
import SchemaEdit from 'ui/controls/schemaEdit';
import { getValueByPath } from 'utils';
import { urls } from 'coms/urls';
function compare(v1, v2, options) {

	if (options.ifEmpty) {
		if (v1 == null) v1 = options.ifEmpty;
		if (v2 == null) v2 = options.ifEmpty;
	}

	if (v1 == v2)
		return 0;

	let dec = options.decrease;
	if (v1 == null && v2 != null) {
		return dec ? 1 : -1;
	}
	if (v1 != null && v2 == null) {
		return dec ? -1 : 1;
	}
	if (dec) {
		let tmp = v1;
		v1 = v2;
		v2 = tmp;
	}
	return v1 < v2 ? -1 : 1;
}

function abvalue(m, options)
{
	if (!options || !options.getter) return;

	if (options.path != null)
		return options.getter.call(m, options.path);
	else
		return options.getter.call(m, m, options);
}

function abstractStringGetter(...args) {
	return getValueByPath(this, ...args);
}

function toOptions(arg) {
	let type = typeof(arg);
	if (arg && type === 'object' && !Array.isArray(arg)) {
		return arg;
	}
	let stringGetter = abstractStringGetter;
						//Object.prototype.getValueByPath
	let options = {
		path: void 0,
		getter: void 0,
		decrease: false,
		ifEmpty: void 0
	}


	if (type === 'function') {
		options.getter = arg;
	} else if (type === 'string') {
		options.getter = stringGetter;
		options.path = arg;
	} else if (arg && Array.isArray(arg)) {
		options.ifEmpty = arg[2];
		options.decrease = !!arg[1];
		arg = arg[0];
		type = typeof(arg);
		if (type === 'string') {
			options.path = arg;
			options.getter = stringGetter;
		} else if (type === 'function') {
			options.getter = arg;
		} else {
			return;
		}
	}

	return options;
}

function AB(a1, a2, path) {
	let options = toOptions(path);
    let v1 = abvalue(a1, options);
    let v2 = abvalue(a2, options);
	return compare(v1, v2, options);
}

function comparator(a1, a2, paths) {
    for(let x = 0; x < paths.length; x ++) {
        let path = paths[x];
        let flag = AB(a1,a2, path);
        if (flag)
            return flag;
    }
}

function comparatorOptions(args) {
	return args.map(arg => toOptions(arg));
}

const required = value => value != null && value !== '' ? void 0 : 'required';
function objectProperties(options) {
	options = options || {};
	let { withOwner, withRealty } = options;
	const props = {
		floor: {
			caption: 'этаж',
			emptyText: 'не установлен',
			modelType: 'single',
			valueType: 'number',
			display: v => v != null ? v + ' эт.' : '',
			validate: required,	
		},
		roomNumber: {
			caption: 'номер кабинета',
			emptyText: 'не установлен',
			modelType: 'single',
			valueType: 'string',
			//display: v => v ? v + ' эт.' : '',
			//validate: required,	
		},
		square: {
			caption: 'площадь',
			emptyText: 'не указана',
			modelType: 'single',
			valueType: 'number',
			display: v => v != null ? v + ' кв. м' : '',
			validate: required,	
		},
		purposes: {
			caption: 'возможные назначения',
			emptyText: 'не выбраны',
			// display: v => {
			// 	console.log('v::', v);
			// 	let gg = (v || []).map(mv => refs.enum('realtyPurposes', mv));
			// 	return gg.join(', ');
			// },
			sourceValues: refs.enum('realtyPurposes'),
			modelType: 'single',
			valueType: 'enum',
			//flattenValues: true,
			multiple: true,
			validate: required,	
		},		
		currentPurposes: {
			caption: 'текущее назначение',
			emptyText: 'не выбрано',
			//display: v => v ? refs.enum('realtyPurposes',v) : undefined,
			sourceValues: refs.enum('realtyPurposes'),
			modelType: 'single',
			valueType: 'enum',
			//flattenValues: true,
			multiple: false,
		},
	}

	let owner = {
		caption: 'собственник',
		modelType: 'single',
		controlType: 'selectOwners',
		display: m => {
			if (!m)
				return 'собственник не выбран';
			let a = (m.attributes || m);
			let out = a.name || a.label;
			return out;
		},
		//header: 'Выберите собственника',
		required
	}				
	let realty = {
		caption: 'здание',
		modelType: 'single',
		controlType: 'selectBuildings',
		display: m => {
			if (!m)
				return 'здание не выбрано';
			let a = (m.attributes || m);
			let out = a.name || a.label;
			return out;
		},
		required
	}


	if (withOwner) {
		props.owner = owner;
	}
	if (withRealty) {
		props.realty = realty;
	}

	return props;
}


export default {
	imgUrl: function(hash, options) {
		return link.img(hash, options)
	},
	getOriginalImageUrl(hash) {
		return urls.api('v4/medias/download/' + hash);
		//return `http://adm.nrg.plus/__dwnld/${hash}/img`;
	},
	getEditUrl: function(modelType, id) {
		let entries = modelType == 'realty' ? 'realties' : modelType == 'owner' ? 'owners' : 'objects';
		return cfg.appSvcUrl(`actualization/${entries}-list`, id, 'edit')
	},
	getOiRUrl: function(ownerId, realtyId, absolute) {
		let url = 'actualization/ownerInRealty?r=' + realtyId + '&o=' + ownerId;
		return cfg.urls.url(url, true, absolute !== false);
	},
	addActualizeCss(a, h) {
		if (a.actualize && a.actualize.dataStatus) {
            let statuses = a.actualize.dataStatus.split(/\s*,\s*/gmi);
            statuses.forEach(s => h['a-' + s] = true);
        } else if (a.actualize && !a.actualize.dataStatus) {
            h['a-undefined'] = true;
        }
	},
	actualizeStatus(actualize) {
		if (!actualize) return '';
		let status = actualize.dataStatus || 'undefined';
		return refs.enum('actualizeDataStatuses', status);
	},
	actualizeComment(actualize) {
		let empty = '';
		if (!actualize) return empty;
		let comment = actualize.dataStatusComment;
		return comment && comment != 'null' ? comment : empty;
	},
	comparator,
	comparatorOptions,
	objectProperties,
	createObjectInModal(options) {
		options = options || {};
		let { many, valuesHash, valuesModel, beforeApply } = options;
		let properties = objectProperties(options);
		let createOptions = {
			header: 'Добавление помещени' + (many ? 'й':'я'),
			applyText: 'Добавить помещени' + (many ? 'я':'е'),
			valuesHash, 
			valuesModel, 
			beforeApply
		}

		SchemaEdit.inModal(properties, createOptions);		
	},
	fixCreateObjectData(data) {
		if (!data) return data;
		if (data.realty) {
			data.realtyId = data.realty.value;
			delete data.realty;
		}
		if (data.owner) {
			data.ownerId = data.owner.value;
			delete data.owner;
		}
		return data;
	}
}
