import { BbCollection } from 'vendors';

import { CustomsView } from 'base/views';

import { MnView, NextCollectionView } from 'vendors';
import { HamburgerView } from '../../../../../../coms/ui/HamburgerView';


const CommonMixin = {
    getFilterKey() {
        return this.getOption('filterKey');
    },
    getFilterValue() {
        let key = this.getFilterKey();
        return this.filterModel.get(key);
    },
    setFilterValue(value) {
        let key = this.getFilterKey();
        if (value === '')
            value = undefined;
        return this.filterModel.set(key, value);
    },
}

const CommonInputMixin = {
    initializeInput(options) {
        this.mergeOptions(options, ['valueType', 'validate', 'emptyInputValue', 'value']);
    },
    _onInput() {
        let value = this.getInputValue();
        let inputValid = this.validateInput(value);
        console.log('- input value -', value, inputValid);
        if (inputValid) {
            this.triggerMethod('user:input', value);
        } else {
            this.triggerMethod('user:input:invalid', value);
        }
    },

    getInputValue() {
        let inputValue = this.ui.input.val();
        return this._convert(inputValue);
    },

    _convert(val) {
        if (val == null)
            return val
        else if(val === '') {
            return this.emptyInputValue;
        } else {
            switch(this.valueType) {
                case 'number': 
                    val = val.replace(/\,/gmi, '.');
                    return parseFloat(val, 10);
                default: return val;
            }
        }
    },

    validateInput(val) {
        if (arguments.length == 0) {
            val = this.getInputValue();
        }
        if (typeof this.validate === 'function') {
            return this.validate(val);
        }
        return true;
    },

}

const RawInputView = MnView.extend({
    className: 'input-group',
    template: _.template(`
        <% if (label) { %><label><%= label %></label><% } %>
        <% if (prefix) { %><span class="input-group-addon" id="sizing-addon2"><%= prefix %></span><% } %>
        <input type="<%= inputType %>" class="form-control" placeholder="<%= placeholder %>" aria-describedby="sizing-addon2" value="<%= value %>">
    `),
    emptyInputValue: undefined,
    initialize(options) {
        this.initializeInput(options);
    },
    ui: {
        input: 'input',
    },
    events: {
        'input input':'_onInput'
    },

    templateContext() {
        return {
            prefix: this.getOption('prefix'),
            value: this.getOption('value'),
            inputType: this.getInputType(),
            placeholder: this.getOption('placeholder'),
            label: this.getOption('label')
        }
    },



       
    getInputType() {
        let inputType = this.getOption('inputType');
        if (inputType != null) {
            return inputType;            
        }
        if (this.valueType == 'number') {
            return 'number';
        }
        return 'text';
    },



    ...CommonInputMixin,


});

const BaseInputView = CustomsView.extend({
    className: 'input-range',
    _broadcastChange() {
        this.triggerMethod('user:input', this.value);
    },    
    initialize() {
        this.initializeValue();
        this.broadcastChange = _.debounce(this._broadcastChange, 500);
    },
    initializeValue() {
        let value = this.getOption('value');
        this.value = value;
    },
    getCustomViews() {
        return [
            this.buildLabelView(),
            ...this.buildInputViews(),
        ];
    },

    buildLabelView() {
        let label = this.getOption('label');
        return new MnView({ tagName: 'label', template: _.template(label) });
    },

    buildInputViews() {},
    setValue(newvalue) {
        this.value = newvalue;
        this.broadcastChange();
    },

});

const InputRangeView = BaseInputView.extend({

    initializeValue() {
        let value = this.getOption('value');
        if (!value || !(('from' in value) || ('to' in value))) {
            value = {
                from: this.getOption('valueFrom'),
                to: this.getOption('valueTo')
            }
        }
        this.value = value;
    },

    buildInputViews() {
        let valueFrom = this.value.from;
        let valueTo = this.value.to;
        let valueType = this.getOption('valueType');
        let views = [
            new RawInputView({ value: valueFrom, valueType, prefix: 'от', onUserInput: (val) => this.setRangeValue('from', val) }),
            new RawInputView({ value: valueTo, valueType, prefix: 'до', onUserInput: (val) => this.setRangeValue('to', val) }),
        ];
        return views;
    },
    
    setRangeValue(key, val) {
        let newvalue = { ...this.value };
        newvalue[key] = val;
        this.setValue(newvalue);
    },

});

const InputSingleView = BaseInputView.extend({
    className: 'input-single',
    buildInputViews() {
        let valueType = this.getOption('valueType');
        let views = [
            new RawInputView({ className: 'just-input', value: this.value, valueType, onUserInput: val => this.setValue(val) }),
        ];
        return views;
    }
});


const SelectEntityView = MnView.extend({
    className: 'form-group',
    template: _.template(`
    <label><%= label %></label>
    <select class="form-control"><%= optionsHtml %></select>
    `),
    initialize(options) {
        //this.filterModel = this.getOption('filterModel');
        this.initializeInput(options);
    },

    ...CommonInputMixin,

    // getFilterKey() {
    //     return this.getOption('filterKey');
    // },
    // getFilterValue() {
    //     let key = this.getFilterKey();
    //     return this.filterModel.get(key);
    // },
    // setFilterValue(value) {
    //     let key = this.getFilterKey();
    //     if (value == '')
    //         value = undefined;
    //     return this.filterModel.set(key, value);
    // },

    //...CommonMixin,

    buildOptionsHtml() {
        let selectedValue = this.value;
        let sources = this.getOption('sourceValues');
        let raw = _.map(sources, (entity, id) => {
            let isSelected = selectedValue && id == selectedValue ? ' selected' : '';
            return { value: id, label: entity.name, isSelected };
        });
        let sorted = _.sortBy(raw, 'label');

        let options = sorted.map(i => `<option value="${i.value}"${i.isSelected}>${i.label}</option>`);

        options.unshift('<option value="">все</option>');

        return options.join('');
    },
    templateContext() {
        let optionsHtml = this.buildOptionsHtml();
        return {
            label: this.getOption('label'),
            optionsHtml
        }
    },

    ui: {
        input: 'select',
    },

    events: {
        'change select':'_onInput'
    },

    // events: {
    //     'change select'(e) {
    //         let val = $(e.target).val();
    //         this.setFilterValue(val);
    //     }
    // }
});

const specialSets = {
    limitedVisibility: 'ограниченная видимость',
    noOffers: 'без предложений',
    noActiveOffers: 'не в рынке',
    onModeration: 'ожидает модерации',
    noNumber: 'без номера кабинета',
    noFloor: 'без этажа'
}


const SetButtonsMixin = {
    triggers: {
        'click':'toggle'
    },
    onBeforeRender() {
        this._ensureActive();
    },
    _ensureActive() {
        if (this.model.get('active')) {
            this._makeActive();
        } else {
            this._makeInactive();
        }
    },
    modelEvents: {
        'change':'_ensureActive'
    }
}

const EntriesSetButton = MnView.extend({
    tagName: 'button', 
    className: 'btn btn-default', 
    attributes: { type: 'button' }, 
    template: _.template('<%= label %>'),

    ...SetButtonsMixin,

    _makeActive() {
        this.$el.addClass('btn-primary');
        this.$el.removeClass('btn-default');
    },

    _makeInactive() {
        this.$el.removeClass('btn-primary');
        this.$el.addClass('btn-default');
    },

});

const EntriesSetButtons = NextCollectionView.extend({
    className: 'btn-group',
    childView: EntriesSetButton,
    childViewEvents:{
        toggle(view) {
            let isActive = view.model.get('active');
            view.model.set('active', !isActive);
            this.broadcastChange();
            //console.log('args::', arguments);
        }
    },
    broadcastChange() {
        let result = this.collection.reduce((memo, model) => {
            if (model.get('active')) {
                memo.push(model.id);
            }
            return memo;
        }, []);
        this.triggerMethod('user:input', result);
    },
    initialize() {
        let buttons = _.map(specialSets, (label, id) => ({ id, label }));
        this.collection = new BbCollection(buttons);
    }
});

const EntriesSetSelector = MnView.extend({
    className: 'form-group', 
    template: _.template(`<label>наборы</label><div></div>`),
    regions: {
        buttons: '> div'
    },
    onRender() {
        let view = new EntriesSetButtons();
        this.showChildView('buttons', view);
    },
    childViewTriggers: {
        'user:input':'user:input'
    },
    initialize() {
        console.log('-buttons-', this);
    },
});


const EntriesSetDropdownItem = MnView.extend({
    tagName: 'li',
    template: _.template(`<a href="javascript:"><%= label %></a>`),
    ...SetButtonsMixin,
    _makeActive() {
        this.$el.addClass('active');
    },

    _makeInactive() {
        this.$el.removeClass('active');
    },
});

const EntriesSetDropdownItems = EntriesSetButtons.extend({
    tagName: 'ul',
    className: 'dropdown-menu',
    childView: EntriesSetDropdownItem,
});

const EntriesSetDropdownSelector = MnView.extend({
    className: 'dropdown input-single',
    template: _.template(`
<label>&nbsp;</label>
<button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">    
<%= label %>
<span class="caret"></span>
</button>
<ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
    <li class="active"><a href="javascript:">Action</a></li>
    <li><a href="javascript:">Another action</a></li>
    <li><a href="javascript:">Something else here</a></li>
    <li role="separator" class="divider"></li>
    <li><a href="#">Separated link</a></li>
</ul>`),
    regions: {
        items: '> ul'
    },
    onRender(){

        let view = new EntriesSetDropdownItems();
        this.showChildView('items', view, { replaceElement: true });
    },
    templateContext() {
        return {
            label: this.getOption('label') || 'наборы'
        }
    },
    childViewTriggers: {
        'user:input':'user:input'
    },
});

// deprecated
CustomsView.extend({
	className: 'wrapper',
	initialize(options) {
			this.mergeOptions(options, ['filterModel', 'modelType', 'allentries']);
			if (!this.allentries.fetched) {
					this.allentries.fetchPromise.then(() => {
							this._prepareData();
							this.render();
					});
			}
	},
	_prepareData() {
			let realties = {};
			let owners = {};
			this.allentries.models.forEach(model => {
					let a = model.attributes;
					if (!realties[a.realty_Id]) {
							realties[a.realty_Id] = a.realty;
					}
					if (!owners[a.owner_Id]) {
							owners[a.owner_Id] = a.owner;
					}
			});
			this.rawRealties = realties;
			this.rawOwners = owners;
	},
	_controlOptions(key, opts) {
			return Object.assign({
					value: this.getFilterValue(key),
					onUserInput: val => this.setFilterValue(key, val)
			}, opts);
	},
	getCustomViews() {
			let views = [];
			if (!this.allentries.fetched) return views;


			if (this.modelType != 'owner') {
					let opts = this._controlOptions('ownerId', {
							sourceValues: this.rawOwners, 
							label: 'собственник', 
					});    
					views.push(new SelectEntityView(opts));
			}

			if (this.modelType != 'realty') {
					let opts = this._controlOptions('realtyId', {
							sourceValues: this.rawRealties, 
							label: 'здание', 
					});    
					views.push(new SelectEntityView(opts));
			}


			views.push(new InputSingleView(this._controlOptions('floor', { label: 'этаж', valueType: 'number' })));

			views.push(new InputSingleView(this._controlOptions('roomNumber', { label: 'номер кабинета' })));

			let squareOpts = this._controlOptions('square', {
					label: 'площадь', 
					valueType: 'number'
			});

			views.push(new InputRangeView(squareOpts));

			views.push(new EntriesSetDropdownSelector(this._controlOptions('specialSet')));

			//views.push(new EntriesSetSelector(this._controlOptions('specialSet')));



			return views;
	},
	setFilterValue(key, value) {
			console.log('- set filter value -', key, value);
			this.filterModel.set(key, value);
	},
	getFilterValue(key) {
			return this.filterModel.get(key);
	}
});

export default HamburgerView.extend({
	baseClassName: 'wrapper',
	initialize(options) {
		this.mergeOptions(options, ['filterModel', 'modelType', 'allentries']);
		if (!this.allentries.fetched) {
			this.notYetFetched = true;
			this.allentries.fetchPromise.then(() => {
				this.notYetFetched = false;
				this._prepareData();
				this.render();
			});
		}
	},
	_prepareData() {
			let realties = {};
			let owners = {};
			this.allentries.models.forEach(model => {
				let a = model.attributes;
				if (a.realty) {
					let realtyId = a.realty.id;
					if (!realties[realtyId]) {
							realties[realtyId] = a.realty;
					}
				}
				if (a.owner) {
					let ownerId = a.owner.id;
					if (!owners[ownerId]) {
							owners[ownerId] = a.owner;
					}
				}
			});
			this.rawRealties = realties;
			this.rawOwners = owners;
	},
	_controlOptions(key, opts) {
		return Object.assign({
			value: this.getFilterValue(key),
			onUserInput: val => this.setFilterValue(key, val)
		}, opts);
	},
	getChildren() {
		if (this.notYetFetched) { return []; }

			let views = [];
			if (!this.allentries.fetched) return views;


			if (this.modelType != 'owner') {
					let opts = this._controlOptions('ownerId', {
							sourceValues: this.rawOwners, 
							label: 'собственник', 
					});    
					views.push(new SelectEntityView(opts));
			}

			if (this.modelType != 'realty') {
					let opts = this._controlOptions('realtyId', {
							sourceValues: this.rawRealties, 
							label: 'здание', 
					});    
					views.push(new SelectEntityView(opts));
			}


			views.push(new InputSingleView(this._controlOptions('floor', { label: 'этаж', valueType: 'number' })));

			views.push(new InputSingleView(this._controlOptions('roomNumber', { label: 'номер кабинета' })));

			let squareOpts = this._controlOptions('square', {
					label: 'площадь', 
					valueType: 'number'
			});

			views.push(new InputRangeView(squareOpts));

			views.push(new EntriesSetDropdownSelector(this._controlOptions('specialSet')));

			//views.push(new EntriesSetSelector(this._controlOptions('specialSet')));



			return views;
	},
	setFilterValue(key, value) {
			console.log('- set filter value -', key, value);
			this.filterModel.set(key, value);
	},
	getFilterValue(key) {
			return this.filterModel.get(key);
	}
})
