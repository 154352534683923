
import { RightsContentView } from "./RightsContentView";
import { BoxView } from 'coms/ui/Box';
import { backendApi } from 'apis';


export const PrincipalRightsBoxView = BoxView.extend({
	thisClassName: 'rights-box with-borders fixed-height',
	header: 'модули и права',
	content: v => ({
		class: RightsContentView,
		model: v.model
	}),
	action: v => ({
		glyph: 'floppy-disk',
		text: 'сохранить изменения',
		clickAction: () => v.saveClaimsChangesAction()
	}),
	saveClaimsChangesAction() {
		return backendApi.post(this.model.newClaims, this.model, 'new-claims').then(resClaims => {
			let claims = _.extend({}, this.model.get('claims'), resClaims);
			this.model.newClaims = {};
			this.model.set({ claims });
			this.render();
		});
	}
});