import { TabTriggersView } from 'ui/layouts/tabs-v2';

const tabs = [
    {
        id: 'images',
        label: 'фотографии'
    },
    {
        id: 'files',
        label: 'файлы'
    },
    {
        id: 'upload',
        label: 'загрузить'
    }
];

export default TabTriggersView.extend({
    getTabs() {
        return tabs;
    },
    
});