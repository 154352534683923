﻿//define('m-realties-owners-owner',['bus','m-abstract','action', 'm-contacts'],function(Bus, abstractNs, action, contactsNs){});

import Bus from 'bus';
import abstractNs from 'm/abstract';
import action from '_helpers/action';
import contactsNs from 'm/contacts';

import { BbeCollection } from 'core/bbe';
import { EntityContactEdit } from '../../../v/contacts/entityContact/edit';

var Model = abstractNs.ActionableModel.extend({
	actions: function () {
		return [
			action('addContact', 'добавить контактное лицо', {}, { order: 0 })
			//action('openModal', 'показать', {}, { order: 0 })
			///, action('openSearchPage', 'подобрать предложения', {}, { order: 0 })
			//, action('changeInfo', 'редактировать', {}, { order: 0 })
			//, action('addEntityTask', 'поставить задачу', {}, { order: 0 })
			//, action('startDeal', 'запустить процесс', {}, { order: 0 })
			//, action('openPage', 'перейти на страницу клиента', {}, { order: 0 })
		];
	},
	addContactsFromInRealtyContact: function (oirc) {
		var rcontacts = oirc.get("contactsInRealty") || [];
		var ocontacts = oirc.get("contacts") || [];
		this.addRealtyContacts(oirc.get('realtyId'), rcontacts);
		this.addContacts(ocontacts);
	},
	hasRealtyContacts: function (realtyId) {
		return this.getRealtyContacts(realtyId).length > 0;
	},
	addRealtyContacts: function (realtyId, data) {
		if (realtyId == null || data == null) return;

		this.addContacts(data);
		var rltContacts = this.getRealtyContacts(realtyId);
		//console.log(rltContacts);
		rltContacts.add(data, {realtyId: realtyId});
	},
	addContacts: function (data) {
		var contacts = this.getContacts();
		contacts.add(data);
	},
	getRealtyContactsModels: function (realtyId, ifEmptyReturnAllContacts) {
		if (realtyId == null) return;

		var rltContacts = this.getRealtyContacts(realtyId);

		if (!rltContacts.length && ifEmptyReturnAllContacts !== false)
			return this.getContacts().models;
		else
			return rltContacts.models;
	},
	getRealtyContacts: function (rltId) {
		if (rltId == null) return;

		this.realtyContacts || (this.realtyContacts = {});

		if (!(this.realtyContacts[rltId] instanceof BbeCollection))
			this.realtyContacts[rltId] = contactsNs.EntityContacts.Owner(this.id, [], { realtyId: rltId, asCollection: true });//new BbeCollection();

		return this.realtyContacts[rltId];
	},
	getContacts: function () {
		var rawcontacts = this.get('contacts') || [];
		if (rawcontacts instanceof BbeCollection) return rawcontacts;
		var entries = contactsNs.EntityContacts.Owner(this.getId(), rawcontacts, { asCollection: true });
		var model = this;
		this.listenTo(entries, 'add remove', function () {
			model.trigger('change:contacts', model);
		});
		this.set('contacts', entries);
		return entries;
	},
	actionAddContact: function (options) {
		if (options.modal)
			options.modal.destroy();
		console.log('action:add:contact',this);
		var View = EntityContactEdit;
		// Bus.Views.request('entityContact:edit');
		if (View) {
			//var allcontacts = this.get('contacts');
			var contact = new contactsNs.EntityContactModel({ ownerId: this.getId() });
			contact.once('sync', function () {
				console.log('triggerd');
				Bus.channel('ownerInRealty').trigger('add:contact', contact);
			});
			View.openInModal(_.extend({ model: contact, parent: this }, options.options));
		}
		else
			console.warn('entityContact:edit View not returned');
	},
});


export default Model;
