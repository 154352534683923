import appCfg from 'app-config';
import { HamburgerView } from "coms/ui/HamburgerView";
import { BlocksView } from "./BlocksView";
import { CollectionView, Model } from 'vendors';
import { childViewOptionsMixin, commonCollectionMixin } from "./mixins";
import { View } from 'core';
import { BlockStateView } from "./BlockStateView";
import { PromiseButtonsView } from "coms/ui/PromiseButtonsView";
import { ButtonView } from "coms/ui/Button";
import bus from './bus';
import backend from './backend';
import getWs from 'ws';

const PresentationActions = HamburgerView.extend({
    tagName: 'footer',
    childrenViews() {
        let parentView = this.getOption('parentView');
        const requestModel = parentView.model;
        return [
            {
                class: ButtonView,
                text: 'скачать pdf',
                canBeEnabled(){ return this.model.isOK(); },
                modelEvents:{
                    change:'render'
                },
                clickAction: () => parentView.download(),
                async __clickAction() {

                    let blocks = this.model.toBackendJson();
                    bus.requestModel.set('blocks', blocks);
                    bus.buildingStages = new Model();

                    bus.trigger('before:download');
                    let presentationId = bus.requestModel.get('presentationId');
                    let socketGroup = 'presentation:building-stages:' + presentationId;
                    let wsEvent = 'presentation:download';
                    let ws = getWs();
                    let url = appCfg.urls.apiV('realties/presentation/download/' + presentationId);

                    let promise = new Promise((res, rej) => {
                        this.listenTo(ws, wsEvent, (data, socketmessage) => {
                            if (!data) { return; }

                            console.log(this.cid, '[socket]', data.message, data);

                            bus.buildingStages.set(data);

                            if (data.status !== 200) {
                                rej(data);
                                return;
                            }

                            if (data.message === 'ready') {
                                
                                const downloadLink = `<a href="${url}" target="_blank">скачать PDF</a>`
                                bus.buildingStages.set('message', downloadLink)
                                res();
                            } else {
                                bus.trigger('progress', data);
                            }
                            
                            // if (data && data.type && data.type == 'ready:for:download' && data.presentationId === this.model.get('presentationId')) {
                            //     console.log('{DOWNLOADING}');
                            //     backend.downloadDocument(data.presentationId);
                            //     return;
                            // }
                        });
                    });

                    try {
                        await ws.joinGroup(socketGroup);
                        console.log('joined to:', socketGroup);
                        await backend.createDocument(bus.requestModel);
                        console.log('sended data');
                        await promise;
                    } catch(e) {
                        console.log('catched boy', e);
                    }
                    console.log('going to cancel this shit');
                    this.stopListening(ws, wsEvent);

                    try {
                        await ws.leaveGroup(socketGroup)
                    } catch(e) {
                        console.error('leave group fails')
                        console.error(e);
                    }
                    bus.trigger('download:end');
                    console.log('downloading complete');
                    let win = window.open(url,'_blank');
                    win.focus();
                }
            },
            {
                class: ButtonView,
                text: 'обновить данные для презентации',
                async clickAction() {
                    console.log('CLICK ACTION');
                    await requestModel.fetch();
                    bus.trigger('render:all');
                }

            },
        ]
    }
});

const LayerInfoView = HamburgerView.extend({
    baseClassName: 'pdf-layer-info',

    //template: '<header><%= name %></header>',
    childrenViews() {
        return [
            { 
                class: BlockStateView,
                name: this.getEntityName(),
                model: this.model
            }
        ]
    },
    getEntityName() {
        let type = this.model.get('type');
        let id = this.model.get('entityId');
        let data = this.getOption('data');
        let entity = data[id];
        let name = this._getEntityName(type, entity);
        return name;
    },
    _getEntityName(type, entity) {
        switch(type) {
            case 'realty':
                return 'здание: ' + entity.name;
            case 'realtyObject':
                return 'площадь: ' + entity.info.squareOffer + 'м<sup>2</sup>';
            case 'realtyOffer':
                return 'предложение: ' + entity.market + ' ' + entity.operation + ' ' + entity.forCustomer.priceMeter + 'р.';
            case 'root':
                return 'Презентация';
        }
    },
    templateContext() {
        return {
            name: this.getEntityName()
        }
    }
})

const BlocksTreeItemView = HamburgerView.extend({
    baseClassName: 'pdf-layer',
    modelStateKeys: ['excluded'],
    stateClassNames: ['excluded'],
    classNames: [
        v => v.model.hasErrors(true) ? 'invalid' : 'valid'
    ],
    stateful: true,
    ...childViewOptionsMixin,
    childrenViews() {
        return [
            {
                class: LayerInfoView,                
            },
            {
                class: HamburgerView,
                className: 'pdf-layer-content',
                ...childViewOptionsMixin,
                childrenViews: [
                    {
                        class: BlocksView,
                        className: 'pdf-blocks top-blocks',
                        items: 'tops', //this.model.get('tops')
                    },
                    {
                        class: BlocksTreeItemsView,
                        items: 'children', //this.model.get('children')
                    },
                    {
                        class: BlocksView,
                        className: 'pdf-blocks bottom-blocks',
                        items: 'bottoms', // this.model.get('bottoms')
                    },
                ]
            },

        ]
    }
});

const BlocksTreeItemsView = CollectionView.extend({
    className: 'pdf-layer-children',
    childView: BlocksTreeItemView,
    ...commonCollectionMixin,
    ...childViewOptionsMixin,
});

export const BlocksTreeView = BlocksTreeItemView.extend({
    thisClassName: 'root-layer',
    getChildren() {
        let children = BlocksTreeItemView.prototype.getChildren.apply(this, arguments);
        children.push({
            class: PresentationActions,
            parentView: this.getOption('parentView')
        });
        return children;
    }
});