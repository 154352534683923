module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header>'+
((__t=( label ))==null?'':__t)+
' <b class="badge">'+
((__t=( count ))==null?'':__t)+
'</b></header>\n<section></section>';
}
return __p;
};
