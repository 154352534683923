﻿//define('ui-models-filters-groups', ['ui-models-filters-group'], function (Group) {});

import Group from 'ui/models/filters/group';
import { Collection as BbeCollection } from 'core/bbe';

var Groups = BbeCollection.extend({
	model:Group
});

export default Groups;
