// import { _ } from 'vendors';
import { invokeValue } from "utils/invoke";
import { _, Backbone } from 'vendors';
import { Model, Collection } from 'vendors';
// const _ = {};
// const Backbone = {};

// function invokeValue() {}

function isEntity(arg) {
    return arg && (arg instanceof Model || arg instanceof Collection);
}

function constructUrl(options = {}) {
    let { url, entity, relativeUrl = true } = options;

		if (!entity || !relativeUrl) { return url; }

		let entityUrl = entity ? invokeValue(entity.url, entity, entity) : undefined;

		if (!url) { return entityUrl }

		const pattern = new RegExp("(?<!:)/+", 'mi'); //was: /(?<!:)\/+/gmi

		url = (entityUrl + '/' + url).replace(pattern,'/');

		return url;
}

function constructOptions(requestOptions = {}) {
    let url = constructUrl(requestOptions);
    let { attrs } = requestOptions;
    return {
        url,
        attrs
    }
}
// var methodMap = {
//     'create': 'POST',
//     'update': 'PUT',
//     'patch': 'PATCH',
//     'delete': 'DELETE',
//     'read': 'GET'
//   };

const changeSync = (entity, data) => {
    console.log('-change-sync-');
    entity.set(data);
    return entity;
}

const deleteSync = entity => {
    if (!entity.destroy) { return; }
    entity.isNew = () => true;
    entity.destroy();
}

// const xhrWithEntitySync = (entity, method) => data => {
//     console.log('-entity-sync-');
//     entity.trigger('before:sync', entity, data);

//     //let syncres = sync(entity, data);
    
//     if (method !== 'delete') {
//         syncres = changeSync(entity, data);
//         entity.trigger('sync', entity, data);
//     } else {
//         syncres = deleteSync(entity);
//     }

//     if (syncres !== undefined) {
//         return syncres;
//     }
//     return data;
// }

// const xhrWithSync = data => {
//     let syncres = sync(entity, data);
//     if (syncres !== undefined) {
//         return syncres;
//     }
//     return data;
// }

const dumbModel = {
    trigger() { },
		toJSON() { return {}; }
}

function send(method, requestOptions = {}) {
    let entity = requestOptions.entity;
    let options = constructOptions(requestOptions);
    let xhr = Backbone.sync(method, entity || dumbModel, options);
    let { sync } = requestOptions;

    if (sync === true) {
        sync = entity 
            ? method === 'delete' ? deleteSync : changeSync
            : null;
    }

    if (sync) {
        //let xhrSync = entity ? xhrWithEntitySync(entity, method) : xhrWithSync;
        let xhrSync = data => {

            let syncResult = entity ? sync(entity, data) : sync(data);

            if (entity && method !== 'delete') {
                entity.trigger('sync', entity, data);
            }
            
            return syncResult !== undefined ? syncResult : data;
        }
        xhr = xhr.then(xhrSync);
    }
    return xhr;
}


const postAndPatchOptionsByArguments = [
    //post()
    function() {
        throw new Error('no send arguments')
    },

    //post('asd/qwe')
    //post(options)
    function(arg) {
        let type = arg && typeof arg;
        let options = type === 'object' ? arg : { url: arg }
        return options;
    },

    //post(attrs, 'asd/qwe')
    //post(attrs, entity)
    //post(attrs, options)
    function(attrs, arg) {
        let type = arg && typeof arg;
        let url = type === 'string' ? arg : undefined;
        let entity = url == null && isEntity(arg) ? arg : undefined;
        let _options = !url && !entity && type === 'object' ? arg : {};
        let options = _.extend({ attrs, url, entity }, _options);
        return options;
    },

    //post(attrs, 'asd/qwe', options)
    //post(attrs, 'asd/qwe', sync)
    //post(attrs, entity, 'asd/qwe')
    //post(attrs, entity, options)
    //post(attrs, entity, sync)
    function(a1, a2, arg) {
        let options = postAndPatchOptionsByArguments[2](a1, a2);
        let _options;
        let type = arg && typeof arg;
        if (type === 'function' || type === 'boolean') {
            _options = { sync: arg }
        } else if (type === 'string') {
            _options = { url: arg }
        } else if (type === 'object') {
            _options = arg;
        }
        Object.assign(options, _options);
        return options;
    },

    //post(attrs, 'asd/qwe', sync, options)
    //post(attrs, entity, 'asd/qwe', sync)
    //post(attrs, entity, 'asd/qwe', options)
    //post(attrs, entity, sync, options)
    function(a1, a2, a3, arg) {
        let options = postAndPatchOptionsByArguments[3](a1,a2,a3);
        let _options;
        let type = arg && typeof arg;
        if (type === 'function' || type === 'boolean') {
            _options = { sync: arg }
        } else if (type === 'object') {
            _options = arg;
        }
        Object.assign(options, _options);
        return options;
    },

    //post(attrs, entity, 'asd/qwe', sync, options)
    function(a1, a2, a3, a4, arg) {
        let options = postAndPatchOptionsByArguments[4](a1,a2,a3,a4);
        let _options = arg;
        Object.assign(options, _options);
        return options;
    }
]

export const backendApi = {

    //post('asd/qwe')
    //post(options)
    //post(attrs, options)
    //post(attrs, 'asd/qwe')
    //post(attrs, 'asd/qwe', options)
    //post(attrs, 'asd/qwe', sync)
    //post(attrs, 'asd/qwe', sync, options)
    //post(attrs, entity)
    //post(attrs, entity, options)
    //post(attrs, entity, sync)
    //post(attrs, entity, sync, options)
    //post(attrs, entity, 'asd/qwe')
    //post(attrs, entity, 'asd/qwe', options)
    //post(attrs, entity, 'asd/qwe', sync)
    //post(attrs, entity, 'asd/qwe', sync, options)
    post() {
        //debugger

        let argsLen = arguments.length;
        if (argsLen > 5) { argsLen = 5; }
        let reqOptions = postAndPatchOptionsByArguments[argsLen].apply(null, arguments);

        return send('create', reqOptions);
    },

    // same as post(...)
    patch() {

        let argsLen = arguments.length;
        if (argsLen > 5) { argsLen = 5; }
        let reqOptions = postAndPatchOptionsByArguments[argsLen].apply(null, arguments);

        return send('patch', reqOptions);
    },

    // same as post(...)
    put() {
        let argsLen = arguments.length;
        if (argsLen > 5) { argsLen = 5; }
        let reqOptions = postAndPatchOptionsByArguments[argsLen].apply(null, arguments);        
        return send('update', reqOptions);
    },

    //delete(options)
    //delete('asd/qwe')
    //delete('asd/qwe', options)
    //delete('asd/qwe', sync)
    //delete('asd/qwe', sync, options)
    //delete(entity)
    //delete(entity, options)
    //delete(entity, sync)
    //delete(entity, sync, options)
    //delete(entity, 'asd/qwe')
    //delete(entity, 'asd/qwe', options)
    //delete(entity, 'asd/qwe', sync)
    //delete(entity, 'asd/qwe', sync, options)
    delete(...args) {
        if (args.length > 0) {
            args.unshift(undefined);
        }
        let argsLen = args.length;
        if (argsLen > 5) { argsLen = 5; }


        let reqOptions = postAndPatchOptionsByArguments[argsLen].apply(null, args);

        return send('delete', reqOptions);
    },

    // same as delete(...)
    get(...args) {

        if (args.length > 0) {
            args.unshift(undefined);
        }
        let argsLen = args.length;
        if (argsLen > 5) { argsLen = 5; }

        let reqOptions = postAndPatchOptionsByArguments[argsLen].apply(null, args);
        return send('read', reqOptions);
    },





}