import BehaviorDynamicClass from 'behaviors/dynamicClass';
import { price as _getPrice } from '_helpers/offer-price';

import { BbeCollection } from 'core/bbe';
import { MneView, MneCollectionView } from 'core/mne';
// import beh from 'behaviors';
// import bus from 'bus';
// import deepClone from '_helpers/deepClone';
// import SchemaEdit from 'ui/controls/schemaEdit';
// import refs from 'references';
// import helpers from '../../../helpers';


// import ActionsButton from '../actions-button';
// import { editObjectInModalAsync } from './edit-object';
// import { flexModal, flexConfirm } from '_libs/flex-modals';
// import { getChanges } from '_helpers/getChanges';
// import { View, NextCollectionView } from 'base/views';

export const OfferInObject = MneView.extend({
    className: 'object-offer',
    behaviors: [BehaviorDynamicClass],
    dynamicClass() {
        let h = {};
        let m = this.model;
        let a = m.attributes;

        h[a.status] = true;

        h[a.market] = true;
        h[a.operation] = true;

        if (a.visibleFor) {
            let statuses = a.visibleFor.split(/\s*,\s*/gmi);
            statuses.forEach(s => h['v-' + s] = true);
        }

        return Object.keys(h).join(' ');
    },

    template: _.template(`
<div class="broker-visible as-ui-atom <%= isBrokerVisible %>" title="<%= brokerTitle %>">
    <i><%= ownBrokerVisible %></i>
    <div class="is-visible"><i></i></div>
    <b>брок.</b>
</div>
<div class="advert-visible as-ui-atom <%= isAdvertVisible %>" title="<%= advertTitle %>">
    <i><%= ownAdvertVisible %></i>
    <div class="is-visible"><i></i></div>
    <b>рек.</b>
</div>
<div class="as-ui-atom market-operation-cell operation-cell">
    <i></i>
    <div><%= _enum('realtyOperations', operation)%></div>
    <b></b>
</div>
<div class="as-ui-atom market-operation-cell market-cell">
    <i></i>
    <div><%= _enum('realtyMarkets', market)%></div>
    <b></b>
</div>
<div class="as-ui-atom main-info">
    <i><span><%= _enum('realtyOfferStatuses', status) %></span></i>
    <div><span><%= id %></span></div>
    <b><span><%=_enum("realtyOperations", operation) %></span> <span><%= _enum("realtyMarkets", market)%></span></b>
</div>
<div class="as-ui-atom price-cell price-meter">
    <i></i>
    <div><span><%= displayMeterPrice %></span></div>
    <b>стоимость метра</b>
</div>
<div class="as-ui-atom price-cell price-square">
    <i><%= korKoeff %></i>
    <div><span><%= displayPrice %></span></div>
    <b>стоимость площади</b>
</div>
<div class="as-ui-atom tax-cell">
    <i></i>
    <div><span><%= _enum('taxTypes', forCustomer.taxType) %></span></div>
    <b>налогообложение</b>
</div>
<div class="as-ui-atom agent-contract-cell">
    <i><%= forAgent.income%>%</i>
    <div><span><%= _enum('contractTypes', forAgent.contractType) %></span></div>
    <b>договор</b>
</div>
`),
    templateContextExtend() {
        let add = this.model.get('operation') == 'rent' ? ' за месяц' : '';
        let brokerTitle = this._buildAvailabilityTitle('services', 'недоступно брокерам', 'доступно брокерам');
        let advertTitle = this._buildAvailabilityTitle('public', 'недоступно в рекламе', 'доступно в рекламе');
        let ownBrokerVisible = this.realtyObject.rawOfferApi.isSelfVisible(this.model.attributes, 'services') ? '•' : '';
        let ownAdvertVisible = this.realtyObject.rawOfferApi.isSelfVisible(this.model.attributes, 'public') ? '•' : '';
        let tmp = this.getPrice(false, true);
        let korKoeff = this.getBoma();
        if (korKoeff) {
            let bad = korKoeff < 1;
            korKoeff = korKoeff + ' кор. коэф.';
            if (bad) {
                korKoeff = `<big style="color:red!important">${korKoeff}</big>`;
            }
        }
        return {
            korKoeff,
            displayMeterPrice: this.getPrice(false, true),
            displayPrice: this.getPrice(true, true) + add,
            isBrokerVisible: this.isBrokerAvailable(),
            isAdvertVisible: this.isAdvertAvailalbe(),
            brokerTitle, advertTitle,
            ownBrokerVisible, ownAdvertVisible
        }
    },

    initialize() {
        this.realtyObject = this.getOption('realtyObject');
    },
    getSquare() {
        let obj = this.getOption('realtyObject');
        return obj.get('info').squareOffer;
    },
    getBoma() {
        let a = this.model.attributes;
        let { taxType, boma, priceMeter } = a.forCustomer;
        return a.operation === 'rent' && boma != null ? boma : undefined;
    },
    getPrice(full = true, display) {
        let a = this.model.attributes;
        let { taxType, boma, priceMeter } = a.forCustomer;
        let isNds = taxType == 'nds';

        if (!full)
            return _getPrice(priceMeter, isNds, null, null, null, display);

        let isRent = a.operation == 'rent';
        let square = this.getSquare();

        return _getPrice(priceMeter, isNds, square, isRent, boma, display);

    },

    isActive() {
        return this.realtyObject.rawOfferApi.isAction(this.model.attributes);
            //this.model.get('status') == 'active';
    },

    isSelfVisible(forWhom) {
        return this.realtyObject.rawOfferApi.isSelfVisible(this.model.attributes, forWhom);
        // if (!forWhom) return false;
        // let visibleFor = this.model.get('visibleFor') || '';
        // return visibleFor.indexOf(forWhom) > -1; 
    },
    isSelfAvailable(forWhom) {
        return this.realtyObject.rawOfferApi.isSelfAvailable(this.model.attributes, forWhom);
        //return this.isActive() && this.isSelfVisible(forWhom);
    },
    isSelfAdvertAvailable() {
        return this.isSelfAvailable('public');
    },
    isSelfBrokerAvailable() {
        return this.isSelfAvailable('services');
    },

    isAdvertAvailalbe() {
        if (!this.realtyObject.isSelfAvailable('public'))
            return false;
        return this.isSelfAdvertAvailable();
    },

    isBrokerAvailable() {
        if (!this.realtyObject.isSelfAvailable('services'))
            return false;
        return this.isSelfBrokerAvailable();
    },

    getAvailabilityErrors(forWhom) {
        let errors = [];
        let objectErrors = this.realtyObject.getSelfAvailabilityErrors(forWhom);
        errors.push(...objectErrors);
        let offerErrors = this.realtyObject.rawOfferApi.getSelfAvailabilityErrors(this.model.attributes, forWhom, this.realtyObject);
        errors.push(...offerErrors);
        return errors;
    },

    _buildAvailabilityTitle(forWhom, notAvailableMessage, availableMessage)
    {
        let errors = this.getAvailabilityErrors(forWhom);
        return this.realtyObject._buildAvailabilityTitle(forWhom, notAvailableMessage, availableMessage, errors);
        // let message = errors.length ? notAvailableMessage : availableMessage;
        // errors.unshift(message);
        // let title = errors.join(', ');
        // return title;
    },



    events: {
        click(event) {
            this.realtyObject.editOffer(this.model);
        }
    }

});


const NoOffersInObject = MneView.extend({
    template: _.template('<div class="as-ui-atom"><i></i><div>предложения отсутствуют</div><b></b></div>')
});

export const OffersView = MneCollectionView.extend({
    className: 'object-offers-table',
    childView: OfferInObject,
    emptyView: NoOffersInObject,
    childViewOptions() {
        return {
            realtyObject: this.model
        }
    },
    initialize() {
        let offers = this.model.getRawOffers();
        this.collection = new BbeCollection(offers);
    }
});