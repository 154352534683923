﻿//define('ui-controls-taskBoardList', ['bus', 'bus-svc', 'link', 'm-tasks', 'behaviors'], function (Bus, radio, link, tasksNs, beh) {});

//let Bus, radio, link, 
import tasksNs from 'm/tasks';
import beh from 'behaviors';

import tasktemplate from './task.html';
import template from './layout.html';

import { bbeViewComparator } from 'utils';

import { MneView, MneNextCollectionView } from 'core/mne';
import { moment } from 'vendors';

import './boadr-task-list-item.less';

var TaskView = MneView.extend({
	tagName:'li',
	className: 'task board-task-list-item list-btn',
	//template: '#tmpl-ui-controls-taskBoardList-task',
	template: tasktemplate,
	dynamicClass: function () {
		var res = [];
		res.push('type-' + this.model.get('type'));
		var dl = Date.info(this.model.get('dates').deadline);
		if (dl.when) {
			res.push(dl.when);
			if (dl.calendar.days == 0)
				res.push('today');	
		}
		return res.join(' ');
	},
	behaviors: [beh.ModelActionsInPopup, beh.DynamicClass],
	modelEvents: {
		'change':'render'
	},
});

var EmptyView = MneView.extend({
	tagName:'li',
	className: 'task-list-item empty list-lbl',
	template: _.template('<span>задачи отсутствуют</span>'),
});

var TasksView = MneNextCollectionView.extend({
	tagName:'ul',
	childView: TaskView,
	emptyView: EmptyView,
	//viewFilter: function (view) {
	//	return view.model.get('state') != 'closed';
	//},
	viewComparator: function (a,b) {
		return bbeViewComparator(a,b, function () { return moment(this.model.get('dates').deadline); })
	},
	collectionEvents: {
		'change': function(model) {

			if (model.get('state') === 'closed') {
				this.collection.remove(model);

			}

			this.sort();
		}
	}
});



var Layout = MneView.extend({
	className:'block',
	//template: '#tmpl-ui-controls-taskBoardList-layout',
	template,
	regions: {
		'tasks': { el: '.tasks-list', replaceElement:true }
	},
	initialize: function() {
		var entity = this.getOption('entityModel');
		this.tasks = tasksNs.EntityTasksCollection.init(this.getOption('entity'), this.getOption('entityId'));
		console.log('- entity tasks -', this.tasks);
		var _this = this;
		this.tasks && this.tasks.loudFetch().then(function() {
			_this.listenTo(_this.tasks, 'change update', function() {
				entity.fetch();
			});
		});
		if (entity) {
			this.listenTo(entity, 'task:add', this.addNewTask);
		}
		console.log('-tasks board-', this.tasks);
	},
	onRender: function () {
		var view = this;
		var col = this.tasks;
		if (col) {
			var tasks = new TasksView({ collection: col });
			view.showChildView('tasks', tasks);
		}
	},
	addNewTask: function(model) {
		this.tasks.add(model);
	}
});

export default Layout;
