﻿//define('m-leads-statsModel', ['app-paths'], function (model, paths) {}); // model was not provided, how it ever worked ?


//import model from '';
import paths from 'app-paths';

var squareRanges = [
	//{ square: 0, name: 'без метража' },
	{ square: 100, name: 'до 100м' },
	{ square: 200, name: '100-200м' },
	{ square: 500, name: '200-500м' },
	{ square: 1000, name: '500-1000м' },
	{ square: 3000, name: '1000-3000м' },
	{ square: 5000, name: '3000-5000м' },
	{ square: Infinity, name: 'от 5000м' },
];

import { BbeModel } from 'core/bbe';


var LeadsStatsModel = BbeModel.extend({

	//check top comments
	//model: model,

	urlRoot: function () {
		return paths.api.leadsStats;
	},
	squareName: function () {
		var from = this.get('squareFrom');
		var to = this.get('squareTo');
		if (to == null && from == null)
			return 'без метража'; // squareRanges[0].name;

		from == null && (from = to * .7);
		to == null && (to = from * 1.3);


		var lastPrev = 0;
		var good = _(squareRanges).filter(function (v) {
			var prev = lastPrev;
			lastPrev = v.square;

			if (to < prev || from > v.square) return false;
			if ((to > prev && to <= v.square) || (from >= prev && from < v.square)) {
				return true;
			}
		});

		var last = _(good).last();
		
		return last && last.name || 'без метража';

	},
});


export default LeadsStatsModel;
