import { DropdownButton } from "../DropdownButton";

export const DropdownActions = DropdownButton.extend({
	buttonWithCaret: true,
	selectionDisabled: true,
	buttonIcon: 'fa fa-ellipsis-v',
	buttonText() {
		const icon = this.getOption('buttonIcon') || 'icon';
		return `<i class="${icon}"></i>`;
	},
	values() {
		return this.getOption('actions', true);
	},
	tryExecute(child, event) {		
		this.model.executeAction(child.model, event);
	}
});