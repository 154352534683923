//define('assets-marionette-factory-privateMethods', ['assets-marionette-factory-types'], function(types){});

import { types } from './types'; //'assets-marionette-factory-types'
import { BbModel } from 'vendors';

import { valueIn } from 'utils/array-manipulations';

//`this` is always Factory instance;
const p = {

	//returns type store object
	getTypeStore: function (type) {
		if (!type) return;
		if (!(type in this.store))
			this.store[type] = {};
		return this.store[type];
	},

	//retrievs model id property name
	getModelIdKey: function (type) {
		var Model = p.getModelType.call(this, type);
		if (!Model) return;
		return Model.prototype.idAttribute;
	},


	//returns true if argument is clean Object
	isHashable: function (obj) {
		return obj instanceof Object && obj.constructor === Object;
	},

	normalizeHash: function (hash, Model) {
		if (hash == null) return {};
		if (p.isHashable(hash)) return hash;
		var res = {};
		res[Model.prototype.idAttribute] = hash;
		return res;
	},

	//returns model Id value 
	getModelIdValue: function (type, hash) {
		if (hash == null || typeof hash == 'function' || hash instanceof Array) return;

		if (
			typeof hash != 'object' 
			|| 
			(
				hash.valueOf 
				&& valueIn(typeof hash.valueOf(), 'number', 'string', 'boolean')
				//(typeof hash.valueOf()).in(/*deprecated*/'number', 'string', 'boolean')
			)
		) {
			return hash.valueOf();
		}

		if (typeof hash == 'object') {
			var key = p.getModelIdKey.call(this, type);
			if (!key) return;
			return hash[key];
		}

	},

	//returns model from typeStore
	//modelHash is id value or model hash data with defined id
	getModelFromStore: function (type, modelHash) {
		var typeStore = p.getTypeStore.call(this, type);
		var id = p.getModelIdValue.call(this, type, modelHash);
		if (id == null) return;
		return typeStore[id];
	},

	//instantiate model with initial hash
	createModel: function (type, modelHash, options) {
		var Model = p.getModelType.call(this, type, modelHash);
		if (!Model) {
			console.warn('ModelFactory: ' + type + ' not defined');
			return;
		}

		modelHash = p.normalizeHash(modelHash, Model);
		var model = new Model(modelHash, options);

		p.modelIdChangeHandler.call(this, type, model);

		return model;
	},
	
	modelIdChangeHandler: function (type, model) {
		var idValue = model.getId();

		if (idValue == null) {
			var idKey = model.idAttribute;
			var _this = this;
			model.once('change:' + idKey, function () {
				//console.log('model id changed')
				p.storeModel.call(_this, type, model);
			});
		}
	},

	//return Model Class of specified type
	getModelType: function (type) {
		var modelCfg = this.types[type];
		if (!modelCfg) return;
		var Model = modelCfg.model;
		return Model;
	},

	//set hash to the model
	applyHashToModel: function (model, hash, options) {
		options || (options = {});
		if (model == null) return;
		if (!(model instanceof BbModel)) {
			console.warn('ModelFactory: applyHashToModel fails, model is not a Backbone.Model instance');
			return;
		}
		if (!p.isHashable(hash)) {
			console.warn('ModelFactory: applyHashToModel fails, hash is not Hashable');
			return;
		}
		model.set(hash, options);
	},

	//store model in typeStore
	storeModel: function (type, stored) {
		var typeStore = p.getTypeStore.call(this, type);

		var key = stored.getFetchId();
		if (key == null) {
			console.warn('ModelFactory: storeModel fails, id key is undefined', type);
			return;
			
		}
		typeStore[key] = stored;
	},

};

export function getTypesHash() {
    return types;
}

export function buildModel(opts) {
    opts || (opts = {});

    if (!opts.type) return;

    var id = p.getModelIdValue.call(this, opts.type, opts.hash);
    var shouldStore, stored;
    if (!id) {
        shouldStore = false;
        stored = p.createModel.call(this, opts.type, opts.hash);
        p.applyHashToModel.call(this, stored, opts.hash);
    }
    else {
        stored = p.getModelFromStore.call(this, opts.type, opts.hash);
        if (!stored ) {
            shouldStore = true;
            stored = p.createModel.call(this, opts.type, opts.hash, opts.options);
        }
    }


    if (shouldStore)
        p.storeModel.call(this, opts.type, stored);

    return stored;
}