
import { CollectionView} from 'vendors';
import { BlockView } from './BlockView';
import { childViewOptionsMixin, commonCollectionMixin } from './mixins';






export const BlocksView = CollectionView.extend({
    childView: BlockView,
    ...commonCollectionMixin,
    ...childViewOptionsMixin
});