// define('svc/actualization/changes/filters', [
// 	'ui/models/filters/config', 
// 	'references',
// 	'bus-svc'
// ], function(
// 	FiltersConfig,
// 	refs,
// 	busSvc
// ){});


import FiltersConfig from 'ui/models/filters/config';
import refs from 'references';
import busSvc from 'bus/svc';



const config = new FiltersConfig({
	filters: () => [
		// {
		// 	id: 'txt',
		// 	group: 'base',
		// 	order: -2,
		// 	caption: 'текст',
		// 	//values: ['offices'],
		// 	emptyText: 'не задан',
		// 	modelType: 'single',
		// 	valueType: 'string',
		// 	//sourceValues: refs.enum('realtyMarkets'),
		// 	//flattenValues: true,
		// 	//multiple: false,
		// 	pinned: true,
		// },			

		{
			id: 'period',
			group: 'base',
			order: 0,
			caption: 'период',
			values: ['thisWeek'],
			emptyText: 'не выбран',
			modelType: 'single',
			valueType: 'string',
			sourceValues: refs.enum('datesRangeEnum'),
			flattenValues: true,
			multiple: false,
			pinned: true
		},
		{
			id: 'eid',
			group: 'base',
			order: 0,
			caption: 'сотрудник',				
			emptyText: 'не выбран',
			controlType: 'selectEmployees',
			sourceValues: busSvc.request('employeesSelect'),
			multiple: false,
			pinned: true,
		},
		{
			id: 'event',
			group: 'base',
			order: 0,
			caption: 'событие',
			//values: ['thisWeek'],
			emptyText: 'не установлено',
			modelType: 'single',
			valueType: 'string',
			sourceValues: refs.enum('actualizeEvents'),
			flattenValues: true,
			multiple: false,
			pinned: true
		},			
		// {
		// 	id: 'mt',
		// 	group: 'base',
		// 	order: 0,
		// 	caption: 'рынок',
		// 	values: ['offices'],
		// 	emptyText: 'не выбран',
		// 	modelType: 'single',
		// 	valueType: 'string',
		// 	sourceValues: refs.enum('realtyMarkets'),
		// 	flattenValues: true,
		// 	multiple: false,
		// 	pinned: true
		// },
		// {
		// 	id: 'mo',
		// 	group: 'base',
		// 	order: 1,
		// 	caption: 'операция',
		// 	values: ['rent'],
		// 	emptyText: 'не выбрана',
		// 	modelType: 'single',
		// 	valueType: 'string',
		// 	sourceValues: refs.enum('realtyOperations'),
		// 	multiple: false,
		// 	pinned: true
		// },			
	],
	groups: () => [
		{
			id: 'base',
			order: 100,
			name: 'основные'
		},			
	],
	allFiltersDisabled: true
});


export default config;
