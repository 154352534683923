import modals from '_libs/modals';
import { GaleryView } from './galery-view';
export function showImageGalery(collection, model) {
    let options = {
        modalCssCfg: "fullscreen",
        cssClasses: {
            dialog: "image-galery-dialog"
        }
    }
    let view = new GaleryView({ collection, model });
	return modals.modal(view, options);
}