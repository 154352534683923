﻿//define('svc-staff-employees-listView', ['link','behaviors'], function(link, beh) {});

//import link from 'link';
import beh from 'behaviors';
import template from './itemView.html';

import { MneView, MneNextCollectionView } from 'core/mne';
import { _, CollectionView, moment } from 'vendors';
import { View } from 'core';

import { cssModelBooleanMethod, cssModelValue } from 'utils/view-helpers';

import { modalsApi } from 'apis/modals';
import { modelSchema } from 'mods/staff/emps/schema';

import { PopupOfferPropertiesView } from 'mods/realties/offers/popup-properties';
import { simplePopupMixin, popupView } from 'apis/popup';

import { HamburgerView } from "coms/ui/HamburgerView";
import { getValueByPath } from "utils";
import { actorHasClaims } from 'mods/acc';
import { textToBuffer } from '../../../../utils';







var __ListItemView = MneView.extend({
	className:'block block-card employee-card',
	//template: '#tmpl-service-staff-emps-itemView',
	template,
	behaviors: [beh.DynamicClass, beh.ModelActionsInPopup],
	dynamicClass: function () {
		var res = [];
		//var fired = this.model.isFired();
		if (this.model.isActive())
			res.push('real');
		else if (this.model.isVirtual())
			res.push('virtual');
		else if (this.model.isFired())
			res.push('fired');

		var last = Date.info(this.model.get('lastAction'));
		if (!last.dateInvalid && last.absolute.minutes < 2) {
			res.push('online');
		}

		return res.join(' ');
	}
})

const InfoView = View.extend({
	tagName: 'label',
	template: '<span><%= text %></span>',
	templateContext() {
		return {
			text: this.getOption('text')
		}
	}
});

const ActionView = View.extend({
	tagName: 'button',
	template: '<span><span><%= text %></span></span>',
	templateContext() {
		return {
			text: this.getOption('text')
		}
	},
	events: {
		click(e) {
			const action = this.getOption('action', false);
			if (typeof action === 'function') {
				action(e);
			}
		}
	}
})

const EmpPopupView = HamburgerView.extend({
	initialize() {
		console.log('look', this);
	},
	getChildren() {
		return [
			{ class: InfoView, text: this.model.getName() || getValueByPath(this.model, 'fullName.full') || this.model.get('email') },
			{ class: ActionView, text: 'Скопировать емейл в буфер', action: () => textToBuffer(this.model.get('email')) },
			{ class: ActionView, text: 'Скопировать id в буфер', action: () => textToBuffer(this.model.id)  },
			this.isNotFired() ? actorHasClaims({ svcEmployeesModule: 'fire' }, { class: ActionView, text: 'Уволить', action: () => this.takeAction('fire')  }) : undefined,
			this.isFired() ? actorHasClaims({ svcEmployeesModule: 'hire' }, { class: ActionView, text: 'Принять на работу', action: () => this.takeAction('hire')  }) : undefined,
		]
	},
	
	isNotFired() {
		return this.model.isActive();
	},
	isFired() {
		return this.model.isFired();
	},
	takeAction(actionName) {
		return this.model.executeAction({ action: actionName });
	},
	// events: {
	// 	'click a'() {
	// 		this.destroy();
	// 	}
	// }
})

const ListItemView = View.extend({
	stateful: true,
	modelStateKeys: ['state'],
	stateClassNames: ['state'],
	classNames: [
		cssModelBooleanMethod('isActive', 'real'),
		cssModelBooleanMethod('isVirtual', 'virtual'),
		cssModelBooleanMethod('isFired', 'fired'),
		cssModelValue('lastAction', value => value && moment(value).valueOf() > Date.now() - 120000 ? 'online' : undefined)
	],
	thisClassName:'block block-card employee-card',
	//template: '#tmpl-service-staff-emps-itemView',
	// behaviors: [beh.ModelActionsInPopup],
	template: _.template(`
	<section>
		<div><big><%= name %></big></div>
		<div><%= email %></div>
		<div><%= id %></div>
		<div class="system system-top-right">
			<small class="last-action"><%= lastActionFromNow %></small>
			<span class="icon"></span>
		</div>
	</section>	
	`),
	templateContext() {
		return {
			lastActionFromNow: moment(this.model.get('lastAction')).fromNow(),
			email: this.model.get('email') || '&mdash;'
		}
	},
	initialize() {
		this.initializePopupMixin();
	},
	// events: {
	// 	'click'(e) {
	// 		modalsApi.editProperty(modelSchema.state, { model: this.model, modelSchema });
	// 	}
	// }


	...simplePopupMixin,

	hoverPopup: {
		autoUpdateEnabled: true,
		openEvent: 'click',
		toggle: true,
		removeDelay: 500,
		wrapContent: true,
		popupViewOptions: {
			thisClassName: 'employee-actions-popup'
		},
		view: { class: EmpPopupView },
		useFlip: true,
		positionOptions: {
			placement: 'bottom-start',
		}
	},

});

var Layout = CollectionView.extend({
	childView: ListItemView
});

export default Layout;
