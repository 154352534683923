module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<a href="'+
((__t=( url ))==null?'':__t)+
'" class="url"><i></i>задачи</a>\n<div class="search-process" data-role="deals">	\n	<input placeholder="поиск процесса..."/><button><i></i></button>\n</div>\n<div class="search-client" data-role="clients">\n	<input placeholder="поиск клиента..." /><button><i></i></button>\n</div>\n<a href="'+
((__t=( urlLogoff ))==null?'':__t)+
'" class="url"><i></i>выйти</a>\n<a href="javascript:" class="action" data-action="clearAll"><i></i>сбросить кэш</a>\n';
}
return __p;
};
