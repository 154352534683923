﻿//define('svc-deals-deal', ['app-config', 'appModule', 'link', '_libs/identity', 'm-deals', 'svc-deals-deal-layout', 'm-factory'], function (cfg, Module, link, identity, dealsNs, Layout, factory) {});


//import cfg from '';
import Module from 'appModule';
import link from 'link';
//import identity from '';
//import dealsNs from '';
import Layout from './layout';
import factory from 'm/factory';

import './deal.less';

var module = Module.content({
	name: 'deal',
	routeData: function () {
		return link.route('dealCard');
	},
	onBeforeRun: function (id) {
		//var model = new dealsNs.DealModel({ id: id })

		var model = factory.model('JsonDeal', id);
		var options = undefined;
		if (this.query.empId) {
			options = {
				data:this.query
			}
		}
		model.loudFetch(options);
		this.addRunPromise(model.apiPromise);
		this.addRunArgument(model);

	},
	onRun: function (model) {
		//var module = this;
		this.createAndShow(Layout, 'Процесс ' + model.get('name'), { model: model }, { navLinks: this.navLinks() });
	}
});


export default module;
