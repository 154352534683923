import { BaseObject, Model, Collection } from 'core';
import { geoReference } from '../../mods/geo';

export const MetroLayer = BaseObject.extend({
	// constructor: function(options) {		
	// 	this.lines = new Collection();
	// 	MetroLayer.apply(this, arguments);
	// },

	_setOptions(options) {
		BaseObject.prototype._setOptions.apply(this, arguments);
		this.mergeOptions(options, ['map', 'engine']);
	},

	show() {
		const lines = this.getLines();
		lines.forEach(line => this._showLine(line));
		// throw new Error('MetroLayer show not implemented');
	},


	
	getLines(regionId) {
		if (!this.lines) {
			const models = geoReference.getLines({ raw: true, regionId });
			this.lines = new Collection(models);
		}
		return this.lines;
	},


	_showLine(line) {
		this._showLineStations(line);
		this.showLine(line);
	},

	showLine(line) {
		if (!line.shape) {
			line.shape = this.buildLineShape(line);
			this.addLineOnClick(line, 'click', (event) => this.triggerMethod('line:click', event, { type: 'line', line }))
		}
		this.drawLineShape(line)
	},


	_showLineStations(line) {
		const stations = this.getLineStations(line);
		stations.forEach(station => this.showLineStation(station));
	},

	showLineStation(station) {
		if (!station.shape) {
			station.shape = this.buildLineStationShape(station);
			this.addStationOnClick(station, (event) => this.triggerMethod('station:click', event, { type: 'station', station }));
			this.addStationRadiusShowEvents(station);
		}
		this.drawLineStationShape(station);
	},

	getLineStations(line) {
		if (!line.stations) {
			const models = geoReference.getStations({ lineId: line.id, raw: true });
			line.stations = new Collection(models);
			line.stations.parent = line;
			line.stations.lineNumber = line.get('lineNumber');
		}
		return line.stations;
	},

	hide(options) {
		const lines = this.getLines();
		lines.forEach(line => this._hideLine(line, options));
		// throw new Error('MetroLayer hide not implemented');
	},

	_hideLine(line, options) {
		this._hideLineStations(line, options);
		this.hideLine(line, options);
	},

	_hideLineStations(line, options) {
		const stations = this.getLineStations(line);
		stations.forEach(station => this.hideLineStation(station, options))
	},

	getLineColor(num) {
		return metroLineColors[num] || colors[0];
	},

	onBeforeDestroy() {
		this._destroying = true;
		this.hide({ destroy: true })
	},

	lineShapeDefaults: {
		strokeOpacity: .5,
		strokeWeight: 4,
	},

	stationShapeDefaults: {
		strokeOpacity: 0.2,
		strokeWeight: 1,
		fillOpacity: .5,
		radius: 300
	},

	stationRadiusShapeDefaults: {
		strokeOpacity: 0.2,
		strokeWeight: 1,
		fillOpacity: 0.1,
		radius: 2000
	},

});

export const metroLineColors = [
	'#000000',
	'#ED1B35',
	'#2a9a41',
	'#0078BF',
	'#19C1F3',
	'#894E35',
	'#F58631',
	'#8E479C',
	'#E0AB16',
	'#999999',
	'#93B50E',
	'#4fafaf',
	'#708ab9',
	'#350d42',
	'#de62be',
	'#2C87C5'
];
metroLineColors[800] = metroLineColors[8];
metroLineColors[400] = metroLineColors[4];
