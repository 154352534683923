import { collectionActiveTabKey } from './config';

import { MneView } from 'core/mne';

export const TabTriggerView = MneView.extend({
    className: 'ui-tab-trigger',
    template: _.template('<%- label %>'),
    initialize() {
        this.listenTo(this.model, 'activate disactivate', this._toggleActive);
        this._toggleActive();
    },
    triggers: {
        'click':'tab:click'
    },
    _toggleActive() {
        if (this.model[collectionActiveTabKey]) {
            this.$el.addClass('active');
        } else {
            this.$el.removeClass('active');
        }
    }
});