﻿//define('ui-models-values-range', ['ui-models-values-base'], function (Base) {});

import Base from './base';
import { isNotValue } from 'utils';
import { isValue } from 'utils';
var Model = Base.extend({
	defaults: {
		from: undefined,
		to: undefined,
	},
	parse: function (data) {
		if (!data.id)
			data.id = _.uniqueId('range');
		return data;
	},
	filled: function () {
		return this.get('to') != null && this.get('from') != null;
	},
	getLabel: function() {
		function normalize(v) {
			if (!_.isDate(v)) return v;

			return Date.info.label(v, { noTime:true });
		}
		var from = isValue(this.get('from')) && this.get('from') !== '' ? this.get('from') : undefined;
		var to = isValue(this.get('to')) && this.get('to') !== '' ? this.get('to') : undefined;
		var res = [];
		if (isValue(from))
			res.push('от ' + normalize(from));
		if (isValue(to))
			res.push('до ' + normalize(to));
		return res.join(' ');
	},
	getValue: function () {
		return this.toJSON();
	},
	getUrlHashProperty: function (name, options) {
		var value = this.getConverted(name, options.valueConverter);
		return {
			value: value,
			empty: isNotValue(value)
		};
	},
	getUrlHash: function (opts) {
		var options = this._normalizeUrlHashOptions(opts);


		var from = this.getUrlHashProperty('from',options);
		var to = this.getUrlHashProperty('to', options);

		if (to.empty && from.empty) return;

		var res = {};
		if (!from.empty) res['f'] = from.value;
		if (!to.empty) res['t'] = to.value;

		return res;

	},

});
Model.create = function(attrs, opts) {
	if (attrs instanceof Model) return attrs;
	var who = _.who(attrs);

	var from = undefined;
	var to = undefined;
	var valueType = undefined;

	if (who.bbm && attrs instanceof Model)
		return attrs;
	else if (who.hash) {
		from = attrs.from;
		to = attrs.to;
	} else
		throw Error('Range initialization fails', attrs);


	valueType = opts.valueType || (from && typeof from !== 'undefined' && typeof from) || (to && typeof to !== 'undefined' && typeof to) || 'number';

	//valueType || opts.valueType || (valueType = (from && typeof from !== 'undefined' && typeof from )) || (valueType = (to && typeof to !== 'undefined' && typeof to)) || 'number');

	if (typeof Model[valueType] === 'function')
		return Model[valueType](from, to);
	else
		return Model.number(from, to);

};
Model.number = function(from, to) {
	return new Model({ id: _.uniqueId('range'), from: from, to: to }, { valueType: 'number' });
};
Model.date = function (from, to) {
	return new Model({ id: _.uniqueId('range'), from: from, to: to }, { valueType: 'date' });
};
Model.string = function (from, to) {		
	return new Model({ id: _.uniqueId('range'), from: from, to: to }, { valueType: 'string' });
};
Model.text = function (from, to) {
	return new Model({ id: _.uniqueId('range'), from: from, to: to }, { valueType: 'text' });
};
Model.email = function (from, to) {
	return new Model({ id: _.uniqueId('range'), from: from, to: to }, { valueType: 'email' });
};
Model.phone = function (from, to) {
	return new Model({ id: _.uniqueId('range'), from: from, to: to }, { valueType: 'phone' });
};

export default Model;