export function getChanges(_old, _new) {

    if (_new === undefined) 
        return;

    if (_old === _new)
        return;

    if (_old == null)
        return _new;

    let oldType = typeof _old;
    let oldIsArray = Array.isArray(_old);
    let newType = typeof _new;
    let newIsArray = Array.isArray(_new);


    if (oldType !== newType || oldIsArray !== newIsArray)
        return _new;

    if (newIsArray) {
        if (_old.length !== _new.length)
            return _new;

        let length = _new.length;
        for(let x = 0; x < length; x++) {
            let new_i = _new[x];
            let old_i = _old[x];
            if (getChanges(old_i, new_i))
                return _new;
        }
    } else if (newType === 'object'){
        let changes = _.reduce(_new, (memo, value, key) => {
            let changedValue = getChanges(_old[key], value);
            console.log('> changedvalue', changedValue, 'old', _old[key], 'new', value);
            if (changedValue !== undefined) {
                memo[key] = changedValue;
            }
            return memo;
        }, {});
        if (_.size(changes)) {
            return changes;
        }
    } else {
        return _new;
    }

}