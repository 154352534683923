﻿//define('bus', ['bus-core', 'bus-app', 'bus-modules', 'bbe-loud', 'bus-svc', 'bus-media', 'bus-controls'], function (core) {});

import core from './core';

import './app'; // 'bus-app'
import './modules'; //'bus-modules'
import './loud'; //'bbe-loud'
import './svc'; //'bus-svc'
import './media'; //'bus-media'
import './controls'; //'bus-controls'


core.navigate = function (url, trigger) {
	core.app.trigger('navigate', url, trigger);
};
core.moduleXhrError = function (xhr) {
	core.app.trigger('execute:xhr:error', xhr);
};
core.error = function (code, message) {
	core.app.trigger('execute:custom:error', code, message);
};

core.openSmart = function(url, options = {}) {
	if (options.ctrlKey) {
		let win = window.open(url,'_blank');
		win.focus();
	} else {
		core.navigate(url);
	}
}

core.Models = core.channel('models');
core.Views = core.channel('views');

export default core;
