﻿//define('ui-controls-datetime2-dayView', ['behaviors'], function (beh) {});

import beh from 'behaviors';
import { MneView } from 'core/mne';

var View = MneView.extend({
	className: 'datetime-day',
	template: _.template('<%= value.getDate() %>'),
	behaviors: [beh.DynamicClass],
	initialize: function (opts) {
		//this.mergeOptions(opts, ['modelType', 'valueModel']);
	},
	dynamicClass: function () {
		var cls = [];

		if (this.model.isToday()) {
			cls.push('current');
		}

		if (this.model.notInMonth())
			cls.push('out-of-month');

		if (this.model.isHoliday())
			cls.push('holiday');

		if (this.model.isSelected())
			cls.push('selected');


		/*


		if (this.valueModel.equalOrBetween(this.model.get('value'))) {
			cls.push('selected');
		}

		}*/

		return cls.join(' ');
	},
	events: {
		'click': function () {
			this.model.trigger('clicked', this);
		}
	},
	modelEvents: {
		'refresh': 'render'
	},
});

export default View;