import { Collection, BaseObject } from 'core';

export const Selector = BaseObject.extend({

	constructor: function(options = {}) {
		this.items = new Collection();
		BaseObject.apply(this, arguments);
		this.mergeOptions(options, ['multiple', 'singleToggleAllowed', 'valueMapper', 'valuesMapper']);
	},
	initializeValues(values, collection) {

		if (values == null) { return; }
		if (!Array.isArray(values)) {
			values = [values];
		}

		let found = [];
		let notfound = [];
		for (let value of values) {
			const model = collection.get(value);
			if (model) {
				found.push(model);
			} else {
				notfound.push(value);
			}
		}
		this.toggle(found);
		if (notfound.length) {
			console.warn('not found values: ', notfound, values);
			return notfound;
		}
	},
	has(model) {
		return this.items.has(model);
	},

	get() {
		if (this.valuesMapper) {
			return this.valuesMapper(this.items.models);
		}
		const mapper = this.valueMapper || (model => model);
		return this.items.models.map(mapper);
	},

	toggle(arg) {
		if (arg == null) {
			throw new Error('unable to select, argument is null');
		}
		const result = {
			selected: [],
			unselected: [],
		}

		
		if (Array.isArray(arg)) {
			if (!this.multiple && arg.length > 1) {
				throw new Error('unable to select multiple models when selector defined for single selection');
			}

			for(let model of arg) {
				this._toggle(model, result);
			}

		} else {

			this._toggle(arg, result)
			
		}

		result.hasChanges = result.selected.length + result.unselected.length;

		if (result.hasChanges)
			this.trigger('change', result);
	},

	_toggle(model, result) {

		const exist = this.items.get(model);

		if (exist && (this.multiple || this.singleToggleAllowed)) {
			this.remove(exist);
			result.unselected.push(exist);

		} else if (!exist) {
			if (!this.multiple) {
				const unselected = this.unselectAll();
				result.unselected.push(...unselected);
			}

			this.add(model);
			result.selected.push(exist);
			
		}

	},

	add(model, silent) {
		const result = this.items.add(model);
		if (!silent)
			model.trigger('selected', model);
		return result;
	},

	remove(model, silent) {
		const result = this.items.remove(model);
		if (!silent)
			model.trigger('unselected', model);
		return result;
	},

	unselectAll() {
		const models = [...this.items.models];
		const result = [];
		for (let model of models) {
			this.remove(model);
			result.push(model);
		}
		return result;
	}

});