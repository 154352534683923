﻿//define('svc-deals-deal-offers', ['behaviors', 'm-deals', 'ui-models-selectionHolder', 'realties-sendByEmail'], function(beh, dealsNs, SelectionHolder, sbe) {});

import beh from 'behaviors';
import dealsNs from 'm/deals';
import SelectionHolder from 'ui/models/selectionHolder';
import sbe from 'svc/realties/sendByEmail';
import refs from 'references';

import template from './offersBlock.html';
//import config from '../../actualization/owners/filters';
import cfg from 'app-config';

import getWs from 'ws';

import modals from '_libs/modals';

import {bbeViewComparator} from 'utils';

import { BbModel, BbCollection } from 'vendors';

import { MnView, NextCollectionView } from 'vendors';

import { MneView, MneNextCollectionView } from 'core/mne';
import { pdfApi } from '../../../../apis/pdf';
import DealOfferEdit from './dealOfferEdit';

var OfferInList = MneView.extend({
	tagName: 'li',
	className: function () {
		var cls = 'list-btn deal-offer';
		if (this.isVirtual())
			cls += ' virtual';
		var result = this.model.get('result');
		if (result != null)
			cls += ' result-' + result;
		return cls;
	},
	isVirtual: function () {
		return !this.model.get('realtyOffer');
	},
	isSelectable: function () {
		return !this.isVirtual();
	},
	behaviors: [
		beh.DynamicClass,
		beh.ModelActionsInPopup.extend({ triggerElement: 'span' }),
		beh.SelectedForActions.ChildView.extend({ triggerSelector: 'i' }),
	],
	template: _.template(`
		<small><%= _enum(_m.stageId,"dealStages")%></small>
		<span><%= getname() %> <small><%= getbottom() %></small></span>
		<i class="selection"></i>
	`),
	templateContextExtend: function () {
		var offer = this.model.getOffer();
		var message = this.model.get('comment') || '';
		var model = this.model;
		return {
			getname: function () {
				return model.getLabel();
			},
			getbottom: function () {
				// debugger;
				var line = '';
				if (!offer)
					line = this._m.name || this._m.address;
				else
					line = offer.getName();

				if (message) {
					//if (line)
					//	line += '<hr/>';
					line += '<span class="comment">' + message + '</span>';
				}
				return line;
			}
		}
	},
	modelEvents: {
		'change':'render',
		'action':'_handleAction'
	},
	onActionOpenComment() {
		DealOfferEdit.openInModal({ model: this.model });
		// var view = Bus.Views.request('dealOffer:edit');
		// view.openInModal({ model: this });
		// console.log('- CHPOCK -');
	},
	_handleAction(type, options) {
		this.triggerMethod('action:' + type, options);
	}
});

// var EmptyView = MneView.extend({
// 	className: 'list-lbl',
// 	tagName: 'li',
// 	template: _.template('<span>нет добавленных предложений</span>')
// });

// var PreloadView = MneView.extend({
// 	className: 'list-lbl text-center',
// 	tagName: 'li',
// 	template: _.template('<span><span class="icon-preloader"></span></span>')
// });

var SmartEmptyView = MneView.extend({
	className: 'list-lbl text-center',
	tagName: 'li',
	getTemplate: function () {
		if (this.collection.getFetchStatus() == 'fetching')
			return _.template('<span><span class="icon-preloader"></span></span>');
		else
			return _.template('<span>нет добавленных предложений</span>');
	},
	collectionEvents: {
		'fetch:status:change':'render'
	},
});



var OffersList = MneNextCollectionView.extend({
	behaviors: [beh.SelectedForActions],
	childView: OfferInList,
	viewComparator: function(a, b) {
		return bbeViewComparator(b, a, function(m, v) { return dealsNs.DealStageOrder[m.get('stageId')]; });
	},
	viewFilter: function(view) {
		var deal = this.getOption('deal');
		var parentView = this.getOption('parentView');
		var thisStageId = parentView.offersStageId || deal.get('stageId');
		var modelNum = dealsNs.DealStageOrder[view.model.get('stageId')];
		var thisNum = dealsNs.DealStageOrder[thisStageId];
		return modelNum >= thisNum;
		//					view.model.get('stageId') == (parentView.offersStageId || deal.get('stageId'));
	},
	emptyViewOptions: function() {
		return {
			collection: this.collection
		}
	},
	collectionEvents: {
		'change add': function() {
			this.sort();
		},
	},
	childViewOptions: function() {
		return {
			selectionHolder: this.getOption('selectionHolder'),
			denySelectionFor: this.getOption('denySelectionFor'),
		}
	},
	emptyView: SmartEmptyView,
	tagName: 'ul',
	childViewTriggers: {
		'selectable:toggle':'selectable:toggle'
	}
});


var ProgressView = MnView.extend({
	className: 'progress-in-modal',
	template: _.template(`
		<header><%= header %></header>
		<progress max="100" value="<%= progress %>"><%= progress %>%</progress>
		<label><%= stage %></label>
	`),
	ui: {
		progress: 'progress',
		label: 'label'
	},
	initialize() {
		this.model = new BbModel({ message: '', progress: 0 });
		this.listenTo(this.model, 'change', this.onModelChange);
	},
	getProgress() {
		let field = this.getOption('progressField');
		return this.model.get(field) || 0;
	},
	getStage() {
		let field = this.getOption('stageField');
		return this.model.get(field) || '';
	},
	templateContext() {
		return {
			header: this.getOption('header'),
			progress: this.getProgress(),
			stage: this.getStage()
		}
	},
	updateProgress() {
		if (!this.isRendered()) return;
		let progress = this.getProgress();
		this.ui.progress.html(progress + '%')
		this.ui.progress.get(0).value = progress;
		this.ui.label.html(this.getStage());
	},
	onModelChange() {
		this.updateProgress();
	},

});

var BlockErrorView = MnView.extend({
	className: 'presentation-error',
	initialize() {
		console.log(this.model.attributes);
	},
	template: _.template(`
		<h5>Ошибка в блоке <<%=name%>>:</h5>
		<% for(var x = 0; x < errors.length; x++) {
			var error = errors[x];
		%>
			<li>
				<%=error.property%> в <%= entity(error.dataType) %><%= error.message ? '<br><code>' + error.message + '</code>' : '' %>
				<a href="<%= editUrl(error.dataType) %>" target="_blank">открыть карточку</a>
			</li>
		<%}%>

	`),
	templateContext() {
		let m = this.model;
		let attrs = m.attributes;


		let link = (type, id) => cfg.urls.url(`actualization/${type}-list/${id}/edit`);
		let getIdKey = (type) => {
			switch(type) {
				case 'realty':
					return 'realtyId';
				case 'object':
				case 'realtyObject':
				case 'offer':
				case 'realtyOffer':
					return 'realtyObjectId';
			}
		}

		return {
			entity(type) {
				if (type == 'realty') {
					return m.get('realtyId');
				}
				return type;
			},
			editUrl: (dataType) => this._editUrl(dataType),
			// {
			// 	let idKey = getIdKey(dataType);
			// 	let id = attrs[idKey];
			// 	return link(typeMapper[dataType], id);
			// },
			errors: attrs.errors || []
		}
	},
	_editUrl(dataType) {
		let attrs = this.model.attributes;
		let idKey = this._getEditUrlIdKey(dataType);
		let id = attrs[idKey];
		return this._getEditUrlLink(this._editUrlTypeMapper[dataType], id);
	},
	_getEditUrlIdKey(type) {
		switch(type) {
			case 'realty':
				return 'realtyId';
			case 'object':
			case 'realtyObject':
			case 'offer':
			case 'realtyOffer':
				return 'realtyObjectId';
		}
	},
	_getEditUrlLink(type, id) {
		return cfg.urls.url(`actualization/${type}-list/${id}/edit`);
	},
	_editUrlTypeMapper: {
		realty: 'realties',
		object: 'objects',
		realtyObject: 'objects',
		offer: 'objects',
		realtyOffer: 'objects',
	}
});

var ErrorsView = NextCollectionView.extend({
	className: 'presentation-errors',
	initialize() {
		this.collection = new BbCollection(this.model.get('errors'));
	},
	childView: BlockErrorView
});

var PresentationProgress = ProgressView.extend({
	header: 'Презентация',
	stageField: 'message',
	progressField: 'progress',
	onModelChange() {
		let { type, isExist, presentationId, errors, errorMessage } = this.model.attributes;
		if (errors) {
			type = 'failure';
			this.model.set({
				progress: 0,
				type,
				message: errorMessage || 'Не удалось сделать презентацию - требуется внести правки в данные о недвижимости'
			}, { silent: true });
			console.error(errors);
		}
		else if (isExist && presentationId && type != 'ready:for:download') {
			type = 'ready:for:download';
			this.model.set({
				progress: 100,
				type
			}, { silent: true });
		}
		this.updateProgress();
		this.triggerMethod(type);
	},
	onReadyForDownload() {
		let url = this.getOption('url') + '/' + encodeURIComponent(this.model.get('presentationId'));
		this.model.set('message', `<a href="${url}" target="_blank">Скачать презентацию</a>`, { silent: true });
		this.updateProgress();
		window.open(url, '_blank');
	},
	onFailure() {
		if (this.model.get('internalError'))
			return;
		let errorsView = new ErrorsView({ model: this.model });
		modals.modal(errorsView);
	}
})


var Layout = MneView.extend({
	className: 'block',
	//template: '#tmpl-service-deals-deal-offersBlock',
	template,
	initialize: function() {
		// this.ws = getWs();
		// this.ws.joinGroup('presentation:ready');
		// this.on('before:destroy', () => {
		// 	this.stopListening(this.ws);
		// 	this.ws.leaveGroup('presentation:ready');
		// });
		// this.listenTo(this.ws, 'presentation:download', (data, socketmessage) => {
		// 	//console.log('[SKCTS]>', data && data.type, data);
		// 	if (this.downloadDialog) {
		// 		this.downloadDialog.model.set(data);
		// 		// this.downloadDialog.render();
		// 		// console.log('dialog model change', data);
		// 	}
		// });
	},
	onBeforeDestroy() {

	},
	getSelectionHolder: function () {
		if (this.selectionHolder) return this.selectionHolder;
		this.selectionHolder = SelectionHolder.create('deal:offers:' + this.model.id);
		this.listenTo(this.selectionHolder, 'changed', this.selectionChanged);
		return this.selectionHolder;
	},
	regions: {
		'offers': { el:'ul.offers',replaceElement:true}
	},
	events: {
		'change input[name=selectedStage]': 'onOfferSelectedStageChange',
		'click .send-by-email' : 'sendByEmail',
		'click .download-pdf': 'downloadPdf',
	},        
	ui: {
		offersStageLabel: '.offers-stage-label'
	},
	actionSendByEmail: sbe.actionSendByEmail,
	sendByEmail: function() {
		var data = this.selectionHolder.collection.map(function(m) { return m.getOffer(); });
		if (!data.length) return;

		var client = this.model.getClient();
		var emails = client && client.getEmails();

		this.actionSendByEmail(data, emails);
	},
	downloadPdf: function() {
		var data = this.selectionHolder.collection.map(function(m) { return m.getOffer().id; });
		if (!data || !data.length) return;
		pdfApi.showBlocks(data)
		// var model = new BbModel();
		// model.url = cfg.urls.apiV('realties/presentation/request');
		// model.save(null, { wait: true, attrs: data }).then(
		// 	(res) => {
		// 		let attrs = _.pick(res, 'isExist', 'isBuilding', 'type', 'presentationId', 'errors')
		// 		this.downloadDialog.model.set(attrs);
		// 		console.log('> res', res);
		// 	},
		// 	xhr => {
		// 		console.log(xhr);
		// 	}
		// );
		// this.downloadDialog = this.showDownloadModal();

		// // model.url = cfg.urls.apiV('realties/presentation/request-blocks');
		// // model.save(null, { wait: true, attrs: data}).then((res) => {
		// // 	console.log('res >>>', res);
		// // })

	},
	showDownloadModal: function() {
		let dialog = new PresentationProgress({
			url: cfg.urls.apiV('realties/presentation/download')
		});
		modals.modal(dialog);
		return dialog;
	},
	selectionChanged: function(count) {
		//this.$('.send-by-email i').html(count);
		this.$('.selected-items-badge').html(count);
	},
	onOfferSelectedStageChange: function (e) {
		this.offersStageId = $(e.target).val();
		this.ui.offersStageLabel.html(refs.enum('dealStages', this.offersStageId));
		this.offersView.filter();
	},
	onRender: function () {
		var col = this.getOption('collection');
		var deal = this.model;
		//var parentView = this;

		var view = this.offersView = new OffersList({
			deal: deal,
			parentView: this,
			collection: col,
			selectionHolder: this.getSelectionHolder(),
		});
		this.showChildView('offers', view);
	},
	collectionEvents: {
		'change:comment': function () {
			this.model.trigger('comments:add', this.model);
			//this.collection.loudFetch();
		}
	},
	templateContextExtend: function () {
		return {
			_dealStage: this.model.get('stageId'),
		}
	},
});

var ns = {};
ns.getView = function (opts) {
	var options = _.extend({}, opts);
	var deal = opts.deal;
	if (!deal)
		return;

	var col = deal.getOffers();
	console.log('offers', col);
	
	options.collection = col;
	options.model = deal;

	var view = new Layout(options);
	return view;
}

export default ns;