module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<header class="small">вход</header>\n<ul class="">\n	';
if(_sourceName){
__p+='\n	<li class="list-lbl"><small>источник</small><span>'+
((__t=( _sourceName))==null?'':__t)+
'</span></li>\n	';
}
__p+='\n	<li class="list-lbl">\n		<small>рынок / операция / метраж</small>\n		<span>'+
((__t=( _enum(_mv('subject.operation'),'realtyOperations')))==null?'':__t)+
' '+
((__t=( _enum(_mv('subject.market'),'realtyMarkets')))==null?'':__t)+
' '+
((__t=(_meters))==null?'':__t)+
'</span>\n	</li>\n	';
if(_price){
__p+='\n	<li class="list-lbl"><small>цена</small><span>'+
((__t=( _price))==null?'':__t)+
'</span></li>\n	';
}
__p+='\n	';
if(_realtyName){
__p+='\n	<li class="list-lbl"><small> бц / ск</small><span>'+
((__t=( _realtyName))==null?'':__t)+
'</span></li>\n	';
}
__p+='\n	';
if(_geoName){
__p+='\n	<li class="list-lbl"><small>география</small><span>'+
((__t=( _geoName))==null?'':__t)+
'</span></li>\n	';
}
__p+='\n	';
if(_comment){
__p+='\n	<li class="list-lbl"><small>дополнительно</small><span>'+
((__t=( _comment))==null?'':__t)+
'</span></li>\n	';
}
__p+='\n</ul>';
}
return __p;
};
