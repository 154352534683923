import { propertySchemaApi } from "apis/schema/propertySchemaApi";
import { Input, Select, PriceMeter, ApiSelect } from "./views";

const controlNames = {
	priceMeter: PriceMeter,
	apiSelect: ApiSelect
}

function isSelectable(valueSchema) {
	const { sourceValues, valueType, controlName } = valueSchema;
	if (sourceValues || controlName === 'select' || controlName === 'inline-select' || valueType === 'enum' || valueType === 'boolean') {
		return true;
	}
}

export function detectView({ valueSchema = {}, modelSchema }) {
	const { controlName, control } = valueSchema;
	if (control) {
		return control;
	}

	if (controlName) {
		return controlNames[controlName];
	}

	valueSchema = propertySchemaApi.getSchema(valueSchema, modelSchema);
	
	if (isSelectable(valueSchema)) {
		return Select;
	}

	return Input;
}