import cfg from 'app-config';
import { _ } from 'vendors';
import SchemaEdit from 'ui/controls/schemaEdit';
import { buildingSchema } from './buildingSchema';
import { getValueByPath, unFlatObject, flatObject } from 'utils';
import { backendApi } from '../../backend';
import bus from '../bus';

//import helpers from '../helpers';

const notflat = {
	building: {
		buildYear: 1,
		rebuildYear: 1,
		floors: 1,
		squareTotal: 1,
		squareUsefull: 1,
	},
	inner: {
		powerType: 1,
		powerAmount: 1,
		powerAmountPerMeter: 1,
		autonomPowerSupliesCount: 1,
		waterSupply: 1,
		heating: 1,
		ventilation: 1,
		fireSafety: 1,
		airConditioning: 1,
		ceilingHeight: 1,
		ceilingHeightWork: 1,
		floorLoad: 1,
	},
	entry: {
		type: 1
	},
	communicate: {
		phoneProviders: 1,
		internetProviders: 1,
	},
	features: {
		type: 1,
	},
	parking: {
		type:1,
		placesTotal: 1,
	},
	lift: {
		passengerLifts: 1,
		freightLifts: 1,
		freightTonnage: 1,
	},
}


//['building.floors', 'inner.powerType'];


let editSchema;
let properties;

function getProperties() {
	if (properties) { return properties; }
	const propsHash = flatObject(notflat);
	console.log('PROPS', propsHash);
	properties = Object.keys(propsHash);

	return properties;
}

function getEditSchema() {
	if (editSchema) { return editSchema }
	const properties = getProperties();
	editSchema = _.pick(buildingSchema, ...properties);
	return editSchema;
}


function buildValuesHash(block, data) {
	const realtyId = block.get('realtyId');
	const realty = data[realtyId];
	console.log(realty);
	
	const flat = getProperties().reduce((hash, prop) => {
		hash[prop] = getValueByPath(realty, prop);
		return hash;
	}, {});
	const rdy = unFlatObject(flat);
	return rdy;
}

export function editBuildingCharacteristics({ error, block, data, requestModel}) {
	const realtyId = block.get('realtyId');
	console.log(data, block);
	let options = {
		header: 'Изменение характеристк здания',
		valuesHash: buildValuesHash(block, data),
		beforeApply: async (value) => {
			
			const url = cfg.urls.apiV('realties/realties') + '/' + realtyId;
			await backendApi.patch(value, url);
			
			const fetchRes = requestModel.fetch();
			await fetchRes;

			bus.trigger('render:all');

			// setTimeout(() => {
			// }, 1000);


		},
	}
	SchemaEdit.inModal(getEditSchema(), options);


}