import { HamburgerView } from "coms/ui/HamburgerView";
import { View } from 'core';
export const BlockStateView = HamburgerView.extend({
    baseClassName: 'block-state ui2-molecule',
    childrenViews() {
        return [
            {
                class: View,                
                className: 'included-icon',
                tagName: 'button',
                template: '<i></i>',
                model: this.model,
                attributes:{
                    title: this.getOption('исключить из презентации / включить в презентацию')
                },
                events: {
                    click(e) {
                        this.model.toggleExcluded();
                    }
                }
            },
            {
                class: View,
                tagName: 'header',
                className: 'elastic',
                template: '<%= name %>',
                templateContext: {
                    name: this.getOption('name')
                },
                events: {
                    click() {
                        console.log('model >', this.model);
                    }
                }
            },
            {
                class: View,                
                className: 'state-icon',
                template: '<i></i>'
            }
        ]
    }
});