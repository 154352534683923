module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="bg-holder"></div>\n<div class="system">\n	<span class="wid">#'+
((__t=(wId))==null?'':__t)+
'</span>\n	<span class="status">'+
((__t=( _enum(status,'dealStatuses')))==null?'':__t)+
'</span>\n	<span class="stage">'+
((__t=( _enum(stageId,'dealStages')))==null?'':__t)+
'</span>\n	<span class="owner">'+
((__t=( _empn(ownerId)))==null?'':__t)+
'</span>\n</div>\n<div class="name">'+
((__t=(name))==null?'':__t)+
'</div>\n';
if(comment){
__p+='<div class="comment"><i></i> '+
((__t=(comment))==null?'':__t)+
'</div>';
}
__p+='\n';
if(_actionHtml){
__p+='<div class="action">'+
((__t=(_actionHtml()))==null?'':__t)+
'</div>';
}
__p+='\n';
}
return __p;
};
