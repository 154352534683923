import { actionsApi, C } from "apis";
import EditValue from 'ui/controls/editValue';
import { backendApi } from 'apis';

function addGroupAction(collection) {
    var popupSetup = {
        valueType: 'string',
        header: 'Укажите название группы',
    };
    EditValue.modal.single(popupSetup)
        .done((type, name) => {

            if (type != 'ok') return;
            backendApi.post({ name }, collection)
                .then(newone => collection.add(newone));

        });

};

const actions = actionsApi.getCollection('admin-groups');
const A = actions.createHelper();

/*
A('add:group', addGroupAction, 'добавить группу', C({ svcAdminModule: 'manageGroups' }));

A([
    {
        id: 'add:group2',
        claims: C({ svcAdminModule: 'manageGroups' }),
        action: addGroupAction
    }
]);
*/

A({
    'add:group': {
        placement: 'page',
        name: 'добавить группу',
        claims: C({ svcAdminModule: 'manageGroups' }),
        action: addGroupAction,
        glyph: 'plus'
    }
});

console.log('-groupsAction-', actions);

export default actions;