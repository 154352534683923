﻿//define('ui-controls-datetime2-selectMonthView', ['behaviors', 'ui-controls-datetime2-selectMonthItemView'], function (beh, childView) {});

import template from './selectMonthView.html';
import childView from './select-month-item-view';
import { BbeCollection } from 'core/bbe';
//let beh;

import { MneView, MneNextCollectionView } from 'core/mne';

var View = MneView.extend({
	//template: '#tmpl-ui-controls-datetime2-selectMonthView',
	template,
	regions: { 'months': '.datetime-month-list', },
	initialize: function () {
		var x = []; x[11] = true;
		var months = _(x).map(function (v, id) { return { id: id } });
		this.collection = new BbeCollection(months);
	},
	showMonths: function () {
		var view = new MneNextCollectionView({
			collection: this.collection,
			childView: childView,
		});
		this.showChildView('months', view);
	},
	onRender: function () {
		this.showMonths();
	},
	childViewTriggers: {
		'childview:selected': 'month:change'
	},
});

export default View;
