﻿//define('svc-clients-client', ['app-config', 'appModule', 'link', '_libs/identity', 'm-clients', 'svc-clients-client-layout', 'svc-clients-client-deals', 'm-factory'], function (cfg, Module, link, identity, clientsNs, Layout, Deals, factory) {});

//import cfg from '';
import Module from 'appModule';
import link from 'link';
// import identity from '';
// import clientsNs from '';
import Layout from './layout';
//import Deals from '';
import factory from 'm/factory';

import './client.less';
import './client-in-list.less';
import './client-in-popup.less';


var module = Module.content({
	name: 'client',
	routeData: function () {
		return link.route('clientCard');
	},
	onBeforeRun: function (id) {
		//var model = new clientsNs.ClientModel({ id: id })
		var model = factory.model('JsonClient', id);
		model.loudFetch();
		this.addRunPromise(model.apiPromise);
		this.addRunArgument(model);
	},
	navLink: function () {
		return this.parentModule.navLinks();
	},
	onRun: function (model) {
		var module = this;
		module.createAndShow(Layout, { header: function () { return 'Клиент ' + this.model.get('name'); }, model: model }, { model: model }, { navLinks: this.navLinks() });
	}
});


export default module;

