
// import EditValue from 'ui/controls/editValue';
// import svcRadio from 'bus/svc';
import { BbeModel } from 'core/bbe';






import { BoxView } from '../ui/Box';
import { EmployeesListView } from './EmployeesListView';
import { selectEmployeesInModernModal } from 'ui/controls/selectEmployees/in-modal';

export const EmployeeBoxView = BoxView.extend({
	thisClassName: 'employees-box with-borders fixed-height',
	header: 'сотрудники',
	content: v => ({
		class: EmployeesListView,
		model: v.model,
		joinedAsEnabled: v.getOption('joinedAsEnabled'),
	}),
	action: v => ({
		glyph: 'plus',
		text: 'добавить сотрудников',
		clickAction: () => v.addEmployeesAction()
	}),
	addEmployeesAction() {
		var setup = {
			header: 'добавление сотрудника',
			multiple: true,
		};
		
		
		let model = this.model;
		const callback = function (result) {
			console.log(":::", arguments);
			//debugger;
			if (!result.ok) return; // || newvalue[0] == owner) return;
			
			//const { type, newvalue } = result.value;
			const newvalue = result.value.value;
			let saver = new BbeModel();
			saver.url = model.url() + '/employees';
			saver.save(null, { attrs: newvalue, wait: true}).then(data => {
				model.set('employees', data);
			}, () => {});

		};


		selectEmployeesInModernModal(setup, callback);

	}
})