﻿//define('m-departments-createModel', ['bus-app', 'app-paths',], function (appRadio, paths) {});

//import appRadio from '';
import paths from 'app-paths';

import { BbeModel } from 'core/bbe';

var Model = BbeModel.extend({
	urlRoot: function () {
		return paths.api.departmentCreate;
	}
});


export default Model;