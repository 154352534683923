import { HamburgerView } from "coms/ui/HamburgerView";
import { View } from 'core';
import { propertySchemaApi } from "apis/schema/propertySchemaApi";
import './display-value-view.less';

const LabelView = View.extend({
	baseClassName: 'display-value-text',
	classNames: [v => v.isEditAllowed() ? 'editable' : ''],
	template: '<span><%= textValue %></span>',

	templateContext() {
		const options = this.getOptions(['valueSchema', 'modelSchema'])
		return {
			textValue: this.getTextValue(options)
		}
	},
	getTextValue({ valueSchema, modelSchema } = {}) {
		//const value = this.getValue()
		const text = propertySchemaApi.displayValue(valueSchema, this.model, modelSchema);
		return text;
	},
	getValue() {
		const prop = this.getOption('property');
		return this.model.smartGet(prop);
	},
	isEditAllowed() {
		return this.getOption('editable');
	},
	modelEvents: {
		'change':'render',
	},
	events() {
		if (!this.isEditAllowed()) { return; }
		return {
			click: (e) => this.trigger('open:editor', e),
		}
	},

});

export const LabelValueView = HamburgerView.extend({
	baseClassName: 'display-value-view',
	getChildren() {
		return [
			LabelView,
			this.getClearButton(),
		]
	},
	childViewOptions() {
		return this.getOptions(['valueSchema', 'modelSchema', 'value', 'editable', 'property']);
	},
	getClearButton() {

	},
	childViewTriggers: {
		'open:editor':'open:editor'
	}
});