import { ButtonView } from "../Button";
import { HamburgerView } from "../HamburgerView";

export const buttonsKeys = ['resolve', 'reject', 'rejectHard'];

const buttonsConfig = {
	resolve: {
		enabled: true,
		text: 'сохранить',
		className: ' btn btn-primary',
	},
	reject: {
		enabled: false,
		text: 'отменить',
		className: ' btn btn-default',
	},
	rejectHard: {
		enabled: false,
		text: 'обнулить',
		className: ' btn btn-warning',
	}
}


export function getButtonsOptions(view, hash = {}) {
	return buttonsKeys.reduce((memo, key) => {
		memo[key] = view.getOption(key, true);			
		return memo;
	}, hash);
}



export function getButtonsView(view) {
	if (view.getOption('buttons', true) === false) {
		return;
	}
	const buttonsView = getButtonsOptions(view, {
		class: ButtonsView,
		model: view.model,
		collection: view.collection
	});
	return buttonsView;
}

export const ButtonsView = HamburgerView.extend({
	viewName: 'buttons',
	tagName: 'footer',
	baseClassName: 'action-buttons',
	getChildren() {
		return buttonsKeys.map(key => {
			const defCfg = buttonsConfig[key];
			let button = this.getOption(key, true);
			const enabled = !!button || !!defCfg.enabled;
			if (!enabled) { return; }

			const text = typeof button === 'string' 
				? button
				: typeof button === 'object'
					? button.text
					: defCfg.text;

			return {
				class: ButtonView,
				viewName: key,
				thisClassName: key + defCfg.className,
				text
			}
		});
	}
});