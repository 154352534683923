﻿//define('appModule-header', ['bus', 'behaviors-dynamicClass', 'link'], function (Bus, BehaviorDynamicClass, link) {});
import Bus from 'bus';
import appBus from 'bus/app';
import link from 'link';
import BehaviorDynamicClass from 'behaviors/dynamicClass';

import template from './header.html';
import fastlinksTemplate from './fastlinks.html';
//import smartOpen
import { _, BbModel, BbCollection } from 'vendors';

import { MneView, MneNextCollectionView } from 'core/mne';

import { MnView } from 'vendors';

import './header-links.less';

function appendTriggerButton(className)
{
	var btn = $('<div class="'+className+'"><span></span></div>');
		btn.appendTo(this.ui.container);
}

function initActionsMenu() {
	var optionValue = this.options.hiddenActions;
	if (optionValue instanceof Array && optionValue > 0)
		appendTriggerButton.call(this, 'actions');
}
function initClose() {
	if (this.options.showClose)
		appendTriggerButton.call(this, 'x-close');
}
function initActionsButtons() {
	var optionValue = this.options.buttonActions;
	if (!(optionValue instanceof Array && optionValue > 0)) return;

	//TODO
	//implement buttons drawing;

}

function deleteAllCookies() {
	var cookies = document.cookie.split(";");

	for (var i = 0; i < cookies.length; i++) {
			var cookie = cookies[i];
			var eqPos = cookie.indexOf("=");
			var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
	}
}


var FastLinks = MneView.extend({
	className: 'fast-links-container',
	template: fastlinksTemplate,
	initialize: function () {
		this.outsideClickHandler = _.bind(this._outsideClickHandler, this);
		this.$el.appendTo($('body'));
		this.render();
		$(document).on('click', this.outsideClickHandler);
	},
	_outsideClickHandler: function (e) {
		if (!$.contains(this.el, e.target))
			this.destroy();
	},
	onBeforeDestroy: function () {
		$(document).off('click', this.outsideClickHandler);
	},
	templateContext() {
		return {
			url: link.url('tasks:my'),
			urlLogoff: link.url('accLogoff')
		}
	},
	events: {
		'click a.url': function (e) {
			e.stopPropagation();
			e.preventDefault();
			let href = $(e.target).closest('a').attr('href');
			Bus.navigate(href);
			this.destroy();
		},
		'click a.action'(e) {
			e.stopPropagation();
			e.preventDefault();
			const action = $(e.target).closest('a').data('action');
			if (this.actions[action]) {
				this.actions[action].call(this, e);
			}
		},
		'keypress input': function (e) {
			if (e.keyCode == 13) {
				this.applySearch(e.target);
			}
		},
		'click button': function (e) {
			this.applySearch(e.target);
		},
	},
	actions:{
		clearAll() {
			sessionStorage.clear();
			localStorage.clear();
			deleteAllCookies();
			console.log(sessionStorage);
			console.log(localStorage);
			console.log(document.cookies);
			console.log(document.cookie);
		}
	},
	applySearch: function (el) {
		var $p = $(el).closest('div');
		var txt = $p.find('input').val();
		
		let id = parseInt(txt);
		id = !isNaN(id) && id > 0 && id.toString() === txt ? id : undefined;
		let key = id ? 'wid' : 'text';
		var url = link.url($p.data('role') + 'Search') + `?${key}=` + txt;
		Bus.navigate(url);
		this.destroy();
	}
});


const LinkView = MnView.extend({
	tagName: 'span',
	className: 'header-link',
	template: _.template('<%= iconHtml %><%= labelHtml%>'),
	attributes() {
		return {
			title: this.model.get('name')
		}
	},
	templateContext() {
		let iconHtml = '';		
		let labelHtml = '';
		let icon = this.model.get('icon');
		if (icon) {
			iconHtml = `<i class="icon ${icon}"></i>`;
		}
		return {
			iconHtml,
			labelHtml
		}
	},
	events: {
		'click'(event) {
			Bus.openSmart(this.model.get('url'), event);
		}
	}
});


const LinksView = MneNextCollectionView.extend({
	className: 'header-links',
	initialize() {
		this.collection = new BbCollection();
	},
	childView: LinkView,
	setLinks(links) {
		this.collection.reset(links);
	}
});

var Layout = MneView.extend({
	tagName:'header',
	template,
	dynamicClass: function () {
		var cls = [];
		var module = this.getOption('module');
		if (module && module.parentModule) {
			var pm = module.parentModule;
			var backlink = pm.getModuleLink();
			if (backlink)
				cls.push('show-back');
		}
		if (this.getOption("backUrl"))
			cls.push('show-back');
		return cls.join(' ');
	},
	behaviors: [BehaviorDynamicClass],
	ui:{
		'container': '> div',
		'text': '> div > .header > span',
		'back': '.header-back',
	},
	regions: {
		'links': '.header-links'

	},
	events: {
		'click @ui.back': function () {
			var module = this.getOption('module');
			var ownBackUrl = this.getOption('backUrl');

			if (ownBackUrl) {
				Bus.navigate(ownBackUrl);
			}
			else if (module && module.parentModule) {
				var pm = module.parentModule;
				var backlink = pm.getModuleLink();
				if (!backlink) return;
				var url = backlink.href;
				Bus.navigate(url);
			}
		},
		'click .fast-links': function (e) {
			e.stopPropagation();
			this.toggleFastLinks();
		},
	},
	modelEvents: {
		'change':'render'
	},
	onRender: function () {
		if (!this.getOption('noHeaderMenu')) {
			initActionsButtons.call(this);
			initActionsMenu.call(this);
			appendTriggerButton.call(this, 'header-action fast-links');
		}
		initClose.call(this);
		this.showLinks();
	},
	showLinks() {
		this.linksView = new LinksView();
		this.showChildView('links', this.linksView, { replaceElement: true });
	},
	initialize() {
		this.listenTo(appBus, 'update:page:header:text', text => {
			if (this.isRendered()) {
				this.ui.text.html(text);
			}
			this.linksView.setLinks([]);
		});
		this.listenTo(appBus, 'update:page:header:model', hash => {
			if (this.isRendered()) {
				console.log(' = = 2:', hash);
				if (hash.text) {
					this.ui.text.html(hash.text);
				}
				let links = hash.links || [];
				this.linksView.setLinks(links);
			}
		});
	},
	toggleFastLinks: function () {
		if (this.fastLinks && !this.fastLinks.isDestroyed()) {
			this.fastLinks.destroy();
			delete this.fastLinks;
		} else {
			this.fastLinks = new FastLinks();
		}
	},
	templateContext() {
		//var optsHeader = this.options.header;
		var header = 'раздел';
		if (this.options.header) {
			header = _.v(this.options.header, this);
		} else if (this.model) {
			header = this.model.get('header') || _.lbl(this.model);
		}
		//var header = (this.model ? _.lbl(this.model) : this.options.header) || '';
		//if(typeof header)
		return {
			header: header,
		}
	}
});


Layout.createOptions = function (_value, _view, _options) {
	var options = {};
	options.options = _options || {};

	if (typeof _view === 'function')
		options.View = _view;
	if (_value instanceof BbModel)
		options.options.model = _value;
	else if (typeof _value == 'object') {
		_.extend(options.options, _value);
	}
	else
		options.options.header = (_value || '').toString();

	return options;
}

export default Layout;

