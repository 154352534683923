module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="board-container">\n	<div class="board-list-region info">\n		<div>\n			<!--\n			<div class="block">\n				<header class="small bg-primary">действия</header>\n				<ul>\n					<li class="list-btn"><span>запомнить</span></li>\n					<li class="list-btn"><span>добавить к сделке</span></li>\n				</ul>\n			</div>\n			-->\n			<div class="block">\n				<header class="small bg-default">здание</header>\n				<ul>\n					<li class="list-lbl"><small>'+
((__t=(prefix))==null?'':__t)+
'</small><span>'+
((__t=(name))==null?'':__t)+
' '+
((__t=(cls))==null?'':__t)+
'</span></li>\n					<li class="list-lbl"><small>адрес</small><span>'+
((__t=(address.fullAddress))==null?'':__t)+
'</span></li>\n					<li class="list-lbl"><small>метро</small><span>'+
((__t=((address.metro || {}).station))==null?'':__t)+
'</span></li>\n					<li class="list-lbl"><small>тип здания</small><span>'+
((__t=(realtyType))==null?'':__t)+
' '+
((__t=(buildingType))==null?'':__t)+
'</span></li>\n				</ul>\n			</div>\n\n			<div class="owner-region"></div>\n\n\n			<div class="block offset-t">\n				<ul>\n				</ul>\n			</div>\n\n		</div>\n	</div>\n	<div class="board-list-region objects"></div>\n	<div class="board-list-region images"></div>\n	<div class="board-list-region comments"></div>\n</div>\n';
}
return __p;
};
