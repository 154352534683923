
import appCfg from 'app-config';
import BehaviorDynamicClass from 'behaviors/dynamicClass';
import helpers from '../helpers';
import refs from 'references';
import { MneView } from 'core/mne';
import '../entity-list-common/edit/edit-entity-list-item.less';


function buildPageUrl(attr) {
    return helpers.getEditUrl('owner', attr.id);
    //return appCfg.appSvcUrl('actualization/realties-list', attr.id, 'edit')
}


export default MneView.extend({
    className: 'edit-entity-list-item edit-owner-list-item',
    behaviors: [BehaviorDynamicClass],
    template: _.template(`
        <div class="thumbnail-holder">
            <div class="thumbnail-place">
                <% if(thumbnail) { %>
                    <img src="<%= thumbnail %>" />
                <% } else {%>
                    <%= fakeThumbnailHtml %>
                <% } %>
            </div>
            <div class="vertical-bar class-bar"></div>
        </div>
        <div class="info">
            <div class="name"><%= name %></div>
						<div class="amounts">
							<span class="objects amount-<%=objectsCount%>">Всего помещений: <big><%= objectsCount %></big></span>
							<span class="realties amount-<%=realtiesCount%>">Всего адресов: <big><%= realtiesCount %></big></span>
							<span class="contacts amount-<%=contactsCount%>">Контакты: <big><%= contactsCount %></big></span>
						</div>
            <a class="as-btn xs" href="<%= page_url %>">редактировать</a>
            <div class="actualize-data"><span class="status"><%= actualizeStatus %></span><span class="comment"><%= actualizeComment %></span></div>
        </div>
        <div class="actions"></div>
    `),
    dynamicClass() {
        let h = {};
        let m = this.model;
        let a = m.attributes;

        //h[a.buildingClassEnum] = true;

        helpers.addActualizeCss(a, h);

        return Object.keys(h).join(' ');
    },
    templateContext() {
        let m = this.model;
        let a = m.attributes;
        let thumbnail;
        if (a.thumbnail) {
            thumbnail = helpers.imgUrl(a.thumbnail, 'w100-h100-rbc');
        }

        let actualizeStatus = helpers.actualizeStatus(a.actualize);
        let actualizeComment = helpers.actualizeComment(a.actualize);

        return {
            name: a.name || '[нет названия]',
            //prefix: a.prefix || '',
            page_url: buildPageUrl(this.model.attributes),
            //fullAddress: a.address.fullAddress || '[нет адреса]',
            thumbnail,
            fakeThumbnailHtml: '<span class="fake"></span>',
            //classLetter: a.buildingClassEnum.replace('plus', '+'),
            actualizeStatus,
            actualizeComment,
        }
    }
        //_.template(`<img src="${appCfg.paths.mediaImage}<%= webId %>/asis"/>`)
});
