export function mergeUrls(forcedUrl, baseUrl, addUrl) {
	if (forcedUrl) {
		return forcedUrl;
	}
	let url = baseUrl;
	if (baseUrl && addUrl) {
		const hasEndSlash = baseUrl.endsWith('/');
		const hasStartSlash = addUrl.startsWith('/');
		if (hasEndSlash && hasStartSlash) {
			addUrl = addUrl.substring(1);
		} else if (!hasEndSlash && !hasStartSlash) {
			addUrl = '/' + addUrl
		}
		url = baseUrl + addUrl;
	}
	return url;
}