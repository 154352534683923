function getValue(val)
{
    if (val instanceof Number
        || val instanceof String
        || val instanceof Boolean
        || val instanceof Date
    )
        return val.valueOf();
    else
        return val;
}

function compare(a1, a2)
{
    return getValue(a1) == getValue(a2);
}


// Object.defineProperty(Obj, 'in', {
//     enumerable: false,
//     value: function (data) {
//         if (arguments.length == 0) return false;
//         if (arguments.length > 1) {
//             data = [].slice.call(arguments);
//         }
//         if (data == null) return false;
//         if (data instanceof Array) {
//             var finded = false;
//             for (var i = 0; i < data.length; i++) {
//                 finded = compare(data[i], this);
//                 if (finded) break;
//             }
//             return finded;
//         }
//         return false;
//     },
// });


export function valueIn(value, ...arr) {
    if (arr.length === 0) { return false; }
    return arr.some(arrValue => compare(value, arrValue));
}

export function lastItem(arr) {
	return arr[arr.length - 1];
}