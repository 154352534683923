// define('svc/actualization', [
// 	'appModule', 'link', '_libs/identity', 'svc/actualization/layout'
// 	, 'svc/actualization/owners'
// 	, 'svc/actualization/realties'
// 	, 'svc/actualization/ownerInRealty'
// 	, 'svc/actualization/changes'
// 	, 'svc/actualization/realties-list'
// 	//, 'svc/actualization/offers'
// ], function(
// 		Module, link, identity, Layout
// 		, ownersModule
// 		, realtiesModule
// 		, ownerInRealtyModule
// 		, changesModule
// 		, realtiesListModule
// 		//, offersModule
// 	) {});


import Module from 'appModule';
import link from 'link';
// import identity from '';
// import Layout from '';
import ownersModule from './owners';
import realtiesModule from './realties';
import ownerInRealtyModule from './ownerInRealty';
import changesModule from './changes';
import realtiesListModule from './realties-list';
import objectsListModule from './objects-list';
import ownersListModule from './owners-list';



var module = Module.content({
	name: 'actualization',
	label: 'Актуализация',
	routeData: () => link.route('actualization'),
	urlKey:'actualization',
	// hidden: true,
	claims: {
		svcActualizationModule: ['view']
	},
	onRun: function () {
		this.showButtonsLayout();
		// var layout = new Layout();
		// this.createAndShow(layout, 'Актуализация');

	}
});


//module.registerModule(offersModule);
module.registerModule(ownersModule);
module.registerModule(realtiesModule);
module.registerModule(ownerInRealtyModule);
module.registerModule(changesModule);
module.registerModule(realtiesListModule);
module.registerModule(ownersListModule);
module.registerModule(objectsListModule);
export default module;
