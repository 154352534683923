import { Schema } from 'apis/schema';
import appCfg from 'app-config';
import { getValueByPath, displayNum, addPostfix, addPrefix } from 'utils';
import refs from 'references';

export const modelSchema = {
    'name': {
        editable: false,
        
        getValue(model, modelSchema) {
            //console.log('---->>>', propertySchema, model, modelSchema);
            let square = getValueByPath(model, 'info.squareOffer');
            let room = getValueByPath(model, 'info.roomNumber');
            let floor = getValueByPath(model, 'info.floor');
            return [floor, room, square];
        },

        displayValue(value, model, modelSchema) {
            let [floor, room, square] = value || [];
            return [addPostfix(floor, 'эт.'), addPostfix(displayNum(square, 1), 'м<sup>2</sup>'), addPrefix(room, '#')].join(' &mdash; ');
            // let res = "";
            // if (floor != null) {
            //     res += floor + 'эт.';
            // }
            // if (room != null && room !== '') {
            //     if (res) { res += ' &mdash; '; }
            //     res += room;
            // }
            // if (square) {
            //     if (res) { res += ' &mdash; '; }
            //     res += square + 'м<sup>2</sup>';
            // }
            // return res;
        },

        label: 'помещение'
    },
    'status': {
        valueType: 'enum',
        enumType: 'realtyObjectStatuses',
        label: 'статус'
    },
    "info.squareOffer": {
        valueType: 'number',
        displayDigits: 1,
        //enumType: 'dtoRealtyClasses',
        label: 'площадь'
    },
    'info.purposes': {
        valueType: 'enum',
        enumType: 'realtyPurposes',
        label: 'возможные назначения',
        multiple: true,
    },
    'info.currentPurpose': {
        valueType: 'enum',
        enumType: 'realtyPurposes',
        label: 'текущее назначение'
    },
    'info.squareMin': {
        valueType: 'number',
        label: 'деление от',
        displayDigits: 1,
    },
    'info.state': {
        valueType: 'enum',
        enumType: 'realtyObjectStates',
        label: 'состояние'
    },
    'info.spaceLayout': {
        valueType: 'enum',
        enumType: 'spaceLayouts',
        label: 'планировка'
    },
    'info.roomsCount': {
        label: 'комнат',
        valueType: 'number',
        displayDigits: 0,
    },
    'info.floor': {
        valueType: 'number',
        label: 'этаж',
        displayDigits: 0,
    },
    'info.floorsCount': {
        valueType: 'number',
        label: 'количество этажей',
        displayDigits: 0,        
    },
    'info.roomNumber': {        
        label: 'номер',
    },
    'info.floorsHeight': {
        valueType: 'number',
        label: 'высота потолков',
        displayDigits: 1,
    },

    'info.features.windows':{
        label: 'окна',
        valueType: 'boolean',
        editProperty: 'info.features',
        getValue(model) {
            let features = model.getValueByPath('info.features');
            return !refs.enumHasFlag(features, 'noWindows');
        }
    },
    'info.features.legalAddress':{
        label: 'юр. адрес',
        valueType: 'boolean',
        editProperty: 'info.features',
        getValue(model) {
            let features = model.getValueByPath('info.features');
            return refs.enumHasFlag(features, 'legalAddress');
        }
    },
    'info.features.ownEntrance':{
        label: 'Отдельный вход',
        valueType: 'boolean',
        editProperty: 'info.features',
        getValue(model) {
            let features = model.getValueByPath('info.features');
            return refs.enumHasFlag(features, 'ownEntrance');
        }
    },
		'info.features.wholeBuilding':{
			label: 'Здание целиком',
			valueType: 'boolean',
			editProperty: 'info.features',
			getValue(model) {
					let features = model.getValueByPath('info.features');
					return refs.enumHasFlag(features, 'wholeBuilding');
			}
		},
    'info.features.personalLift':{
        label: 'Собственный лифт',
        valueType: 'boolean',
        editProperty: 'info.features',
        getValue(model) {
            let features = model.getValueByPath('info.features');
            return refs.enumHasFlag(features, 'personalLift');
        }
    },
}


const BaseSchema = Schema.extend({
	initialize(options) {
		if (options.isForbiddenProperty) {
			this.isForbiddenProperty = options.isForbiddenProperty.bind(this);
		}
	},
	onBackendFetch() {
		this.backendSchema.forEach(element => this.processBackendProperty(element));
	},
	isForbiddenProperty(model) { },
	processBackendProperty(model) {
		if (this.isForbiddenProperty(model)) { return; }
		let attrs = model.attributes;
		let id = attrs.id.split(/\./g).map(c => c[0].toLowerCase() + c.substring(1)).join('.');
		let propertySchema = this.createPropertySchema(attrs);
		this.updateModelSchema(id, propertySchema);
			//console.log('>', propertySchema, attrs);
	},
	createPropertySchema(attrs) {
		let { label, valueType, valueSubtype, multiple } = attrs;
		let prop = {
			label, valueType, multiple
		}
		if (valueType == 'enum') {
			prop.enumType = valueSubtype[0].toLowerCase() + valueSubtype.substring(1);
		}
		return prop;
	},
	updateModelSchema(id, property) {
		let schema = this.getOption('modelSchema');
		if (id in schema === false) {
			schema[id] = property;
		}
	}
});

export const schema = new BaseSchema({ 
	name: 'realtyObject',
	schemaUrl: () => appCfg.urls.apiV('realties/objects/schema'),
	modelSchema,
	isForbiddenProperty(model) {
		if (model.get('isGroup')) {
			return true;
		}
		let parentId = model.get('parentId');
		let res = parentId && (/^realty\./gi.test(parentId) || /^owner\./gi.test(parentId));
		return res;
	}
});