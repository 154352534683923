// define('svc/admin/groups/group/layout', [
// 	'ui-controls-editValue', 'ui-controls-selectEmployees',
// 	'bus-svc'

// ], function (
// 	EditValue, SelectEmployees, svcRadio
// 	) {});


import EditValue from 'ui/controls/editValue';
//import SelectEmployees from '';
import svcRadio from 'bus/svc';
import modals from '_libs/modals';
import { BbeModel, BbeCollection } from 'core/bbe';

import { MneView, MneNextCollectionView } from 'core/mne';

import { BoxView } from 'coms/ui/Box';
import { RightsContentView } from 'coms/principal-rights';
 // from './rights-content';
import { backendApi } from 'apis';
import { _ } from 'vendors';

import { ActionsView } from 'apis/actions';

import actions from './actions';
import { UiMoleculeView } from '../../../../../coms/ui/UiMoleculeView';
import { UiAtomView } from '../../../../../coms/ui/UiAtomView';
import { GlyphButtonView } from '../../../../../coms/ui/Button';

import { EmployeeListItemView, EmployeesListView, EmployeeBoxView, PrincipalGroupPageLayout } from 'coms/principal-group';

const _EmployeeListItemView = MneView.extend({
	className: 'employee-item ui2-molecule',
	template: _.template(`
		<div class="ui2-atom info">
			<i>state</i>
			<b>role</b>
			<span><%= name.full %></span>
		</div>
		<button class="remove-employee">
			<i class="glyphicon glyphicon-trash"></i>
		</button>
	`),
	events: {
		'click .remove-employee'(event) {
			this.triggerMethod('remove:click', this.model);
		}
	}
});


const __EmployeeListItemView = UiMoleculeView.extend({
	thisClassName: 'employee',
	// optionsButton: true,
	// editButton: true,
	// removeButton: true,
	deleteButton: true,
	content:{
		class: UiAtomView,
		thisClassName: 'info',
		text: v => v.model.get('name').full,
		// topText: 'работает, онлайн',
		bottomText: v => {
			console.log(v.model.attributes);
		}
	},
	childViewTriggers: {
		'delete:click': 'delete:click',
		//'edit:click': 'edit:click'
	}
	// childViewEvents: {
	// 	'all'() {
	// 		console.log('ALL HANDLER');
	// 	},
	// 	'delete:click'() {
	// 		this.trigger('delete:click', this.model);
	// 		console.log(this.model.attributes)
	// 	}
	// }
	// rightButtons:['remove:trash']
});


const __EmployeesListView = MneNextCollectionView.extend({
	className: 'employees-list with-borders elastic',
	initialize() {
		let models = this.model.get('employees');
		this.collection = new BbeCollection(models);
		this.listenTo(this.model, 'change:employees', () => this.collection.reset(this.model.get('employees')));
	},
	childView: EmployeeListItemView,
	childViewEvents: {
		'delete:click'(view, event) {
			console.log('[roxy', arguments);
			const model = view.model;
			const parent = this.model;

			modals.confirm("подтвердите удаление").then(v => {
				let saver = new BbeModel();
				saver.id = model.id;
	
				model.url = parent.url() + '/employees/' + model.id;				
				model.destroy({ wait: true }).then(data => {
					parent.set('employees', data);
				}, () => {});
			});
		},
	}
});


const __EmployeeBoxView = MneView.extend({
	className: 'ui2-box with-borders fixed-height employees-box',
	template: _.template(`
		<header><div class="ui2-molecule"><span class="elastic">сотрудники</span></div></header>
		<section></section>
		<footer>
			<div class="add-employees ui2-molecule">
				<div class="icon"><i class="glyphicon glyphicon-plus"></i></div>
				<div class="ui2-atom"><span>добавить сотрудников</span></div>
			</div>
		</footer>
	`),
	regions: {
		employees: '> section',
	},
	showEmployees() {
		let view = new EmployeesListView({ model: this.model });
		this.showChildView('employees', view, { replaceElement: true });
	},
	onRender() {
		this.showEmployees();
	},
	events: {
		'click .add-employees'(event) {
			
			var setup = {
				valueType: 'string',
				header: 'добавление сотрудника',
				//value: [owner],
				multiple: true,
				controlType: 'selectEmployees',
				sourceValues: svcRadio.request('employeesSelect'),
				// viewFilter: function (v) {
				// 	return v.model.get('value') != owner && v.model.isActive();
				// }
			};
			let model = this.model;
			EditValue.modal.single(setup).done(function (type, newvalue) {
				if (type != 'ok') return; // || newvalue[0] == owner) return;
				
				let saver = new BbeModel();
				saver.url = model.url() + '/employees';
				saver.save(null, { attrs: newvalue, wait: true}).then(data => {
					model.set('employees', data);
				}, () => {});

			});

			
		}
	}
});


const Layout = MneView.extend({
	className: 'ui2-rows fixed-height',
	template: _.template(`
		<div class="actions"></div>
		<div class="ui2-columns fixed-height">
			<div class="ui2-column employees fixed-height"></div>
			<div class="ui2-column rights fixed-height"></div>
		</div>
	`),
	regions: {
		actions: '.actions',
		employees: '.employees',
		rights: '.rights',
	},
	onRender() {
		this.showActions();
		this.showEmployees();
		this.showRights();
	},
	showActions() {
		let view = new ActionsView({ actions, ids: ['change:name', 'change:order', 'delete'], actionArgs: [this.model] });
		this.showChildView('actions', view, { replaceElement: true });
	},
	showEmployees() {
		//let view = new EmployeeBoxView({ model: this.model });
		let model = this.model;
		// let view = new BoxView({
		// 	thisClassName: 'employees-box with-borders fixed-height',
		// 	header: 'сотрудники',
		// 	content: {
		// 		class: EmployeesListView,
		// 		model
		// 	},
		// 	action: {
		// 		glyph: 'plus',
		// 		text: 'добавить сотрудников',
		// 		clickAction: () => this.addEmployeesAction()
		// 	}
		// })
		let view = new EmployeeBoxView({ model });
		this.showChildView('employees', view);
	},
	addEmployeesAction() {
		var setup = {
			valueType: 'string',
			header: 'добавление сотрудника',
			//value: [owner],
			multiple: true,
			controlType: 'selectEmployees',
			sourceValues: svcRadio.request('employeesSelect'),
			// viewFilter: function (v) {
			// 	return v.model.get('value') != owner && v.model.isActive();
			// }
		};
		let model = this.model;
		EditValue.modal.single(setup).done(function (type, newvalue) {
			if (type != 'ok') return; // || newvalue[0] == owner) return;
			
			let saver = new BbeModel();
			saver.url = model.url() + '/employees';
			saver.save(null, { attrs: newvalue, wait: true}).then(data => {
				model.set('employees', data);
			}, () => {});

		});
	},
	showRights() {
		let model = this.model;
		let view = new BoxView({
			thisClassName: 'rights-box with-borders fixed-height',
			header: 'модули и права',
			content: {
				class: RightsContentView,
				model
			},
			action: {
				glyph: 'floppy-disk',
				text: 'сохранить изменения',
				clickAction: () => this.saveClaimsChangesAction()
			}
		})
		this.showChildView('rights', view);
	},
	saveClaimsChangesAction() {
		return backendApi.post(this.model.newClaims, this.model, 'new-claims').then(resClaims => {
			let claims = _.extend({}, this.model.get('claims'), resClaims);
			this.model.newClaims = {};
			this.model.set({ claims });
			this.render();
		});
	}
});

const NewLayout = PrincipalGroupPageLayout.extend({
	joinedAsEnabled: false,
	actions,
	actionsIds: ['change:name', 'change:order', 'delete']
});

export default NewLayout;
