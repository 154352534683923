import { Model, BaseObject } from 'core';

export const MapStateDataModel = Model.extend({
	
});


export const MapStateData = BaseObject.extend({
	initialize(options) {
		this.mergeOptions(options, ['entry', 'selectionHolder', 'existRealtyIds', 'denySelect']);
		this.entryRealtyId = this.entry?.realtyId;
	},
	isJoined(id) { 
		if (!id || !this.existRealtyIds) { return false; }
		return id in this.existRealtyIds;
	},
	isRealtySelected(id) { return id === this.selectedRealtyId },
	isSelectedForActions(id) { },
	isEntry(id) { },
	setSelected(options = {}) {
		const { bounds: selectedBounds , realtyId: selectedRealtyId, lineId: selectedLineId, stationId: selectedStationId } = options;
		const prev = {
			bounds: this.selectedBounds,
			realtyId: this.selectedRealtyId,
			lineId: this.selectedLineId,
			stationId: this.selectedStationId,
		}
		Object.assign(this, { selectedBounds, selectedRealtyId, selectedLineId, selectedStationId });
		return prev;
	},
	setSelectedBounds(bounds) {
		this.selectedBounds = bounds;
	},
	isSelectedBoundsIntersects(bounds, dbg) {
		if (!bounds || !this.selectedBounds) { return false };
		return isBoundsIntersects(bounds, this.selectedBounds, dbg);
	},
	isPointSelected(point) {
		if (this.selectedRealtyId) {
			return this.selectedRealtyId === point.id;
		} else if (this.selectedBounds) {
			return isInBounds(point.getCoordinates(), this.selectedBounds);
		}
	},
	// isInSelectedBounds(point) {
	// 	if (!point || !this.selectedBounds) { return false; }
	// 	return isInBounds(point, this.selectedBounds);
	// },
	unselectAll() {
		this.trigger('unselect:all');
	}
});

function between(v, min, max) {
	return v > min && v <= max;
}
function isInBounds(point, bounds) {
	return between(point[0], bounds.minLng, bounds.maxLng)
	&& between(point[1], bounds.minLat, bounds.maxLat)
}
function isBoundsIntersects(b1, b2, dbg) {

	const b1LngRange = { min: b1.minLng, max: b1.maxLng };
	const b2LngRange = { min: b2.minLng, max: b2.maxLng };
	
	const b1LatRange = { min: b1.minLat, max: b1.maxLat };
	const b2LatRange = { min: b2.minLat, max: b2.maxLat };

	const seed = 0.00000000000001;
	const lngRes = rangesIntersects(b1LngRange, b2LngRange, seed);
	const latRes = rangesIntersects(b1LatRange, b2LatRange, seed);
	if (dbg) {
		
		// console.error({ b1, b2, lngRes, latRes });
	}
	return lngRes && latRes;

}


function rangesIntersects(r1, r2, seed = 0) {
	const notIntersects = (r1.max - seed) < r2.min || (r2.max - seed) < r1.min;
	return !notIntersects;
}