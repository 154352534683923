module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<span class="name">'+
((__t=( _empn(_m.id)))==null?'':__t)+
'</span>\n<span>'+
((__t=( _mfn('total') ))==null?'':__t)+
'<i>всего</i></span>\n<span>'+
((__t=( _mfn('canceled') ))==null?'':__t)+
'<i>отменено</i></span>\n<span>'+
((__t=( _mfn('completed') ))==null?'':__t)+
'<i>завершено</i></span>\n<span>'+
((__t=( _mfn('inProcess') ))==null?'':__t)+
'<i>в работе</i></span>\n<span class="rating">'+
((__t=( _mfn('rating') ))==null?'':__t)+
'<i>рейтинг</i></span>';
}
return __p;
};
