import { invokeValue } from '../../../../utils';
import { MapModel } from './common';
import { createMarkerElement } from './marker-stuff';

export const BaseModel = MapModel.extend({

	markerIdPrefix: 'map-',
	markerZIndex: 1,
	baseClassName: 'new-yandex-map-point',

	state(key, value, silent) {
		if (arguments.length === 0) {
			return this._state || {};
		}
		let attrs;
		if (key && typeof key === 'object') {
			attrs = key;
			silent = value;
		} else {
			attrs = { [key]: value };
		}
		let changes = [];
		let state = this._state || {};
		for (let key in attrs) {
			let val = attrs[key];
			let oldVal = state[key];
			if (val !== oldVal) {
				state[key] = val;
				changes.push({ key, val });
			}
		}
		this._state = state;
		if (changes.length && !silent) {
			this.updateClassName();
		}
		for (let change of changes) {
			let name = change.key[0].toUpperCase() + change.key.substring(1);
			invokeValue(this['onState' + name], this, [this, change.val]);
		}
	},

	// setHtml(html, _el) {
	// 	this._html = html;
	// 	const el = _el || this.getMarkerElement();
	// 	if (!el) { return; }
	// 	el.innerHTML = htlm;
	// },

	// setContentHtml(html, _el) {
	// 	this._contentHtml = html;
	// 	const el = _el || this.getMarkerContentElement();
	// 	if (!el) { return; }
	// 	el.innerHTML = html;
	// },

	getMarker(options = {}) {
		if (options === true) { options = { createMarker: true };}
		const { createMarker } = options;
		if (!this.marker && createMarker) {
			this.marker = this.buildMarker();
			this.contentEl = this.marker.contentEl;
			this.markerEl = this.marker.element;
		}
		return this.marker;
	},

	// getMarkerElement() {
	// 	const m = this.getMarker();
	// 	return m?.element;
	// },

	// getMarkerContentElement() {
	// 	const m = this.getMarker();
	// 	return m?.contentEl;
	// },

	showMarker(options) {
		const marker = this.getMarker(options);
		this._showMarker(marker);
	},
	_showMarker(marker) {
		this._actualizeMarker(marker);
		if (marker && marker.root !== this.map) {
			this.map.addChild(marker);
		}
		invokeValue(this.onMarkerShow, this, this);
	},
	hideMarker() {
		const marker = this.getMarker();
		this._hideMarker(marker);
	},
	_hideMarker(marker) {
		if (marker && marker.root === this.map) {
			this.map.removeChild(marker);
		}
		invokeValue(this.onMarkerHide, this, this);
	},
	
	// getUpdateMarkerOptions() { },
	isMarkerVisible() { return true; },

	updateMarker() {
		// options = this.getUpdateMarkerOptions(options);
		// this._updateMarker(options);
		const shouldShow = this.isMarkerVisible();
		// console.log('-', this.id, shouldShow);
		if (shouldShow) {
			this.showMarker(true);
		} else {
			this.hideMarker();
		}
	},

	isShown() {
		const marker = this.getMarker();
		return marker && marker.root === this.map;
	},

	updateClassName() {
		const marker = this.getMarker();
		if (!marker) { return; }
		marker.element.className = this._buildClassNameString(this._state);
	},

	// _updateClassName(hash = {}) {
	// 	const el = this.getMarkerElement();
	// 	if (!el) { return; }
	// 	el.className = this._buildClassNameString(hash);
	// },

	_buildClassNameString(hash = {}) {
		const cls = [];
		if (this.baseClassName) {
			cls.push(this.baseClassName);
		}
		if (this.thisClassName) {
			cls.push(this.thisClassName);
		}
		for (let key in hash) {
			let val = hash[key];
			if (val) {
				cls.push(typeof val === 'string' ? val : key);
			}
		}
		return cls.join(' ');
	},

	// _updateMarker(updateOptions = {}) {
	// 	const { html, contentHtml, classes } = updateOptions;
	// 	if (html !== undefined) {
	// 		this.setHtml(html)
	// 	}
	// 	if (contentHtml !== undefined) {
	// 		this.setContentHtml(contentHtml)
	// 	}
	// 	if (classes && typeof classes === 'object') {
	// 		this.state(classes);
	// 	}
	// },


	// getBuildMarkerOptions() {
	// 	return {};
	// },
	
	buildMarker(opts) {
		// const { markerOptions, style, contentHtml } 
		const markerOptions = this.getBuildMarkerOptions();
		const { el, content } = this.createMarkerElements();
		this.setupMarkerElements(el, content);
		// const elClasses = this._buildClassNameString(this.state());

		const options = Object.assign({ 
			id: _.uniqueId(this.markerIdPrefix), 
			zIndex: this.markerZIndex, 
			source: 'marker-source' 
		}, markerOptions);

		var marker = new ymaps3.YMapMarker(options, el);
		marker.contentEl = content;
		marker._usedLng = options.coordinates[0];
		marker._usedLat = options.coordinates[1];
		marker._justcreated = true;
		return marker;

		// const { centerLng, centerLat, topLeftLat, topLeftLng, width, height } = this.attributes;
		// let elClasses;
		// let lat, lng;
		// let style;
		// // if (this.id % 2) { 
		// 	elClasses = 'round-content size-40';
		// 	lng = topLeftLng;
		// 	lat = topLeftLat;
		// 	style = `width:${width}px;height:${height}px;`;
		// // } else {
		// // 	elClasses = 'round round-40 cluster';
		// // 	lng = centerLng;
		// // 	lat = centerLat;
		// // }

		// const coordinates = [lng, lat];

		// // const dbgmarker = drawDebugMarker(this.map, coordinates);

		// // els.content.innerHTML = this.get('clusterId');
		// var marker = new ymaps3.YMapMarker(
		// 	{
		// 		id: _.uniqueId('map-cluster-'),
		// 		source: 'marker-source',
		// 		coordinates,
		// 		zIndex: 1,
		// 		onClick: () => {
		// 			this.trigger('click:cluster', this);
		// 			console.log('cluster ', this.id, marker);
		// 			console.log('cluster model', this);
		// 			// console.log('dbg ', dbgmarker);
		// 		}
		// 	},
		// 	els.el
		// );
		// marker.contentEl = els.content;
		// return marker;
	},

	getBuildMarkerOptions () {
		// const { contentHtml } = opts;
		// const { topLeftLat, topLeftLng, width, height } = this.attributes;

		// let style = `width:${width}px;height:${height}px;`;

		let markerOptions = {
			coordinates: this.getCoordinates(),
			onClick: (event) => {
				console.log('UHTI!', this);
				invokeValue(this.onMarkerClick, this, [this, event]);
				// this.trigger('click:cluster', this);
			}
		}

		return markerOptions;
	},

	createMarkerElements() {
		const el = document.createElement('div');
		const content = document.createElement('div');
		el.append(content);
		return { el, content }
	},

	setupMarkerElements(el, content) {
		el.addEventListener('mouseover', (e) => invokeValue(this.onMouseOver, this, [this, e]));
		el.addEventListener('mouseleave', (e) => invokeValue(this.onMouseLeave, this, [this, e]));
	},

	_actualizeMarker() {
		const marker = this.getMarker();
		if (!marker) { return; }
		this.actualizeMarker(marker);
		if (marker._justcreated) {
			delete marker._justcreated;
			this.updateClassName();
		}
		this.updateMarkerStyle(marker);
		this.updateMarkerContent(marker);
		this.updateMarkerCoordinates(marker);
	},
	actualizeMarker(marker) {

	},
	updateMarkerStyle(marker) {
	},
	updateMarkerContent(marker) {
	},
	updateMarkerCoordinates(marker) { 
		const [lng, lat] = this.getCoordinates();
		if (marker._usedLng !== lng || marker._usedLat !== lat) {
			marker.update({
				coordinates: [lng, lat]
			})
		}
	},
});