import { _, BbView } from 'vendors';
import { buildView, invokeValue, attachView, renderView } from "utils";

import { modalOptionsToBuildOptions } from './modalOptionsToBuildOptions.js';

import { stack } from './stack.js';

import { config } from './config.js';
import { propertySchemaApi } from '../schema/propertySchemaApi.js';

import { View } from 'core';
import { controlsApi } from '../controls/index.js';
import busControls from 'bus/controls';
import modals from '_libs/modals';
//import EditValue from 'ui/controls/editValue';

function getModalView(modalOptions, showOptions) {
    if (!modalOptions) return;

    let contentView;
    let buildContentViewOptions;

    if (modalOptions instanceof BbView) {
        if (showOptions.showAsIs) {
            return modalOptions;
        } else {
            contentView = modalOptions;
        }
    } else {
        buildContentViewOptions = modalOptions;
    }

    let buildOptions = modalOptionsToBuildOptions(contentView, buildContentViewOptions, showOptions);
    let context, options, defOptions, defClass;
    return buildView(buildOptions, context, options, defOptions, defClass);

}

function _tryDestroyOnSettle(view) {
    let destroyOnPromiseSettle = view.getOption('destroyOnPromiseSettle', true);
    //console.log('?', destroyOnPromiseSettle);
    if (destroyOnPromiseSettle) {
        view.destroy();
    }
}


function setupModalView(view, options) {

    stack.add(view);
    let promise = addSettledPromise(view, options);

    if (options.autoDestroyAfter > 0) {
        view._autoDestroyTimeout = setTimeout(() => view.destroy(), options.autoDestroyAfter);
    }

    return {
        view,
        promise,
        then() {
            return this.promise.then.apply(this.promise, arguments);
        },
        catch() {
            return this.promise.catch.apply(this.promise, arguments);
        },
    }
}


function addSettledPromise(view, options) {
    let promise = new Promise((res, rej) => {
			let settled;
			view.once({
				'resolve:modal'(value) {
					if (settled) { return; }
					settled = true;
					res({ ok: true, value });
					_tryDestroyOnSettle(this);
				},
				'reject:modal'(type, value) {
					if (settled) { return; }
					settled = true;
					rej({ ok: false, value, type });
					_tryDestroyOnSettle(this);
				},
				'destroy'() {
					if (settled) { return; }
					settled = true;
					rej({ ok: false });
				}
			});
    });

    if (options.catchPromise) {
        promise = promise.catch(rejected => Promise.resolve(rejected));
    }

    return promise;
}


function getAttachToElement({ attachTo } = {}) {
    if (attachTo) { return invokeValue(attachTo); }
    return invokeValue(modalsApi.attachTo);
}



function normalizeShowOptions(arg) {
    if (arg === true) {
        return Object.assign({}, config.showOptions, { showAsIs: true });
    }
    return _.extend({}, config.showOptions, arg);
}


const modalsApi = {

    attachTo: () => document.body,

    show(modalOptions, showOptions) {

        showOptions = normalizeShowOptions(showOptions);

        let view = getModalView(modalOptions, showOptions);

        if (!view) {
            throw new Error('modals: unable to show modal view, view is undefined');
        }

        //view.on('all', c => console.log(c));

        let el = getAttachToElement(showOptions);
        if (!el) {
            throw new Error('modals: unable to attach modal view, attachTo element is undefined');
        }

        let context = setupModalView(view, showOptions);

        renderView(view);
        attachView(view, el);


        return context;

    },
    
    editProperty(propertySchema, { model, modelSchema, resolveButtonText = "установить", rejectButtonText = "отмена", rejectHardButtonText = "обнулить" } = {}) {
			// NOT IMPLEMENTED, DEPRECATED
        if (!propertySchema) {
            throw new Error('необходима схема свойства');
        }

        let modalOptions = controlsApi.getViewOptions(propertySchema, model, modelSchema);
        
        // modalOptions = {
        //     class: View,
        //     template: 'hello!'
        // }

        

        let showOptions = {
            header: propertySchemaApi.getEditHeader(propertySchema, model, modelSchema),
            modalClassName: 'edit-property',
            //showBg: false,
            resolveButtonText,
            rejectButtonText,
            rejectHardButtonText
        }

        let context = this.show(modalOptions, showOptions);

        return context;
    },

    oldEditProperty(popupSetup, callback) {
        const EditValue = busControls.request('edit:value');
        EditValue.modal.single(popupSetup).done(callback);
    },

		async oldEditPropertyAsync(popupSetup, callback) {
			const EditValue = busControls.request('edit:value');
			const res = await EditValue.modal.single(popupSetup).then((state, value) => {
				if (Array.isArray(value) && value.length === 1 && !popupSetup.multiple) {
					value = value[0];
				}
				return Promise.resolve({ ok: 1, value});
			}, (err, ...rest) => {
				return Promise.resolve({ ok: 0, error: 'cancel' });
			});
			console.warn('res:: > ', res);
			return res;
		},		

		oldError(errors, header = 'Ошибка') {
			if (!errors) { return; }
			if (!Array.isArray(errors)) {
				errors = [errors];
			}
			const text = errors.map(err => `<div>• ${err}</div>`).join('');
			modals.error(header, text);
		}
}

export {
    modalsApi
}