import { _ } from 'vendors';
import { Claims, C } from './claims';
import { addAction, getStore } from './store';
import { toAction } from './helper';

export * from './views';

export const actionsApi = {
    getCollection(groupKey) {
        return getStore(groupKey);
    },
    createHelper(groupKey) {
        return function(...args) {
            let action = toAction(groupKey, ...args);
            return addAction(groupKey, action);
        }
    }
}

export { C }