import { HamburgerView } from "coms/ui/HamburgerView";
import { controlValueMixin } from "./views/common";
import { detectView } from "./detectView";
import { invokeValue } from "utils/invoke";
import './edit-value-view.less';

export const EditValueView =  HamburgerView.extend({
	...controlValueMixin,
	baseClassName: 'edit-value-view',
	stateClassNames: ['validation'],
	initialize() {
		this.initializeValue();
		this.initializeControl();
	},
	getChildren() {
		return [
			this.getControlView(),
			this.getClearButton(),
			this.getErrorView(),
		];
	},
	childViewOptions() {
		return Object.assign({ value: this.value }, this.controlOptions);
	},
	getControlView() {
		//console.log('get control view')
		const View = detectView(this.controlOptions);
		const { valueSchema } = this.controlOptions;
		const { inlineControlOptions, controlOptions } = valueSchema;
		const inline = this.getOption('inline');
		let add = inline && inlineControlOptions ? inlineControlOptions : controlOptions;
		add = invokeValue(add, valueSchema, this.model);
		const view = Object.assign({ class: View }, add);

		return view;
	},
	getClearButton() {

	},
	getErrorView() {

	},
	childViewEvents: {
		'user:input'(value, done) {
			this.setValue(value, done);
		}
	}
});