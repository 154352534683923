import { CoreBbView } from "core/coreBbView";
import { HamburgerView } from "../HamburgerView";
import { CollectionView } from 'core/collection-view';
import { Collection } from 'core/collection';
import { smartOpen } from "utils";
import { isModel } from "utils";
import { invokeValue } from "utils";
import './dropdown-toggle.less';

function getValue(model) {
	if ('value' in model.attributes) {
		return model.get('value');
	}
	return model.id;
}

const SelectedCollection = Collection.extend({
	initialize(models, options) {
		this.multiple = !!options.multiple;
		this.emptyAllowed = options.emptyAllowed;
		this._getModelValue = options.getModelValue || getValue;
		if (this.multiple && this.emptyAllowed == null) {
			this.emptyAllowed = true;
		}
	},
	toggle(model) {
		let changes;
		if (!this.multiple) {
			this.models
				.filter(f => f !== model)
				.forEach(existModel => {
					changes = true;
					this.unselect(existModel);
				});
		}

		const exist = this.get(model);
		if (exist) {
			const unselectAllowed = this.length > 1 || this.emptyAllowed === true;
			if (unselectAllowed) {
				changes = true;
				this.unselect(exist);	
			}
		} else {
			changes = true;
			this.select(model);
		}
		if (changes) {
			this.trigger('after:select');
		}
	},
	select(model) {
		this.add(model);
		this._triggerModel(model, 'selected');
	},
	unselect(model) {
		this.remove(model);
		this._triggerModel(model, 'selected');
	},
	unselectAll() {
		const models = [...this.models];
		for(let model of models) {
			this.unselect(model);
		}
		if (models.length) {
			this.trigger('after:select');
		}
	},
	_triggerModel(model, event) {
		model.trigger(event, model);
	},
	displayValue() {
		const values = this.map(m => m.get('label')).join(', ');
		return values;
	},
	getValue() {
		let values = this.map(this._getModelValue);
		if (!this.multiple) {
			values = values[0];
		}
		return values;
	}
})

const ButtonItself = CoreBbView.extend({
	tagName: 'button',
	baseClassName: 'dropdown-toggle',
	thisClassName: 'btn btn-default',
	template: '<span data-role="current-value"><%= text %></span><% if (withCaret) {%><span class="caret"></span><% } %><% if (withReset) {%><span class="reset"><i class="fa fa-times"></i></span><% } %>',
	initialize() {
		this.selected = this.getOption('selected', true);
		this.listenTo(this.selected, 'after:select', this.render);
	},
	templateContext() {
		const withCaret = this.getOption('withCaret') === false;
		const withReset = this.getOption('withReset');
		return {
			text: this.getText(), //this.selected.displayValue() || this.getOption('emptyLabel', true),
			withCaret,
			withReset
		}
	},
	getText() {
		if (this.selected) {
			const emptyLabel = this.getOption('emptyLabel', true);
			return this.selected.displayValue() || emptyLabel;
		} else {
			const text = this.getOption('text', true);
			return  text != null ? text : '';
		}
	}
});


const DropdownItem = CoreBbView.extend({
	tagName: 'li',
	baseClassName() {
		const type = this.getType();
		switch(type) {
			case 'header':
				return 'dropdown-header';
			default:
				return 'dropdown-item';
		}
	},
	stateClassNames: ['active'],
	updateElClassBeforeRender: true,
	template: '<%= html %>',
	// '<a href="<%= href %>"><i></i><span><%= label %></span></a>',
	templateContext() {
		return {
			// href: this.model.get('href') || 'javascript:',
			html: this.buildHtml() || '',
		}
	},
	getType() {
		const type = this.model.get('type');
		// if (!type) {
		// 	if (this.model.has('label') && Object.keys(this.model.attributes).length) {
		// 		return 'header';
		// 	}
		// }
		return type;
	},
	buildHtml() {
		const type = this.getType();
		let label = this.model.get('label');
		switch(type) {
			case 'header':
				return label;
			default:
				let href = this.model.get('href') || 'javascript:'
				return `<a href="${href}"><i></i><span>${label}</span></a>`;
		}		
	},
	isClickable() {
		return this.selected || !this.model.get('type') || this.model.has('action') || this.model.has('trigger');
	},
	events: {
		'click'(event) { 
			const href = this.model.get('href');
			if (!href && this.isClickable()) {
				event.preventDefault();
				this.trigger('click', this, event);
			}
		}
	},
	initialize() {
		this.multiple = this.getOption('multiple', true);
		this.selected = this.getOption('selected', true);
		this.updateActive();
		this.listenTo(this.model, 'selected', this.updateActive);
	},
	updateActive() {
		if (!this.selected) { return; }
		const selected = this.selected.has(this.model);
		this.state('active', selected);		
	},
});

const DropdownMenu = CollectionView.extend({
	tagName: 'ul',
	childView: DropdownItem,
	baseClassName: 'dropdown-menu',
	updateElClassBeforeRender: true,
	stateClassNames: [
		'multiple'
	],
	initialize() {
		this.multiple = this.getOption('multiple', true);
		this.state('multiple', this.multiple);
		this.selected = this.getOption('selected', true);
		
	},
	childViewOptions() {
		return {
			selected: this.selected
		}
	},

	childViewTriggers: {
		'click':'click'
	},
	viewComparator: 'order',

});

export const DropdownButton = HamburgerView.extend({
	baseClassName: 'btn-group',
	classNames:[
		v => v.getOption('withIcons', true) ? 'with-icons' : ''
	],
	stateClassNames: ['open'],
	initialize() {
		this.values = this.buildValuesCollection(this.getOption('values', true), Collection);

		// initializes this.selected collection, if not disabled by 'selectionDisabled' option
		this.initializeSelectedCollection();
		
		
		this.on('user:input', (value, done) => {
			if (done) {
				this.state('open', false);
			}
			console.log('VALUE', value, true, false);
		});
	},
	
	initializeSelectedCollection() {
		if (this.getOption('selectionDisabled', true)) {
			return;
		}
		const multiple = this.multiple = this.getOption('multiple', true);
		const getModelValue = this.getOption('getModelValue', false);
		let value = this.getOption('value', true);
		let selectedModels = [];
		if (value != null) {
			value = Array.isArray(value) ? value : [value];
			for (let val of value) {
				const founded = this.values.get(val);
				if (founded) {
					selectedModels.push(founded);
					if (!this.multiple) { break; }
				}
			}
		}
		this.selected = new SelectedCollection(selectedModels, { multiple, getModelValue });
		
		this.listenTo(this.selected, 'after:select', () => {
			const value = this.selected.getValue();
			const done = !this.multiple && this.getOption('closeOnSelect') !== false;
			this.triggerMethod('user:input', value, done);
		});
	},
	getChildren() {
		return [
			{
				viewName: 'triggerView',
				class: ButtonItself,
				selected: this.selected,
				multiple: this.multiple,
				emptyLabel: this.getOption('emptyLabel', true),
				text: this.getOption('buttonText', true),
				withCaret: this.getOption('buttonWithCaret', true),
				withReset: this.getOption('buttonWithReset', true)
			},
			{
				viewName: 'dropdownView',
				class: DropdownMenu,
				collection: this.values,
				selected: this.selected,
				multiple: this.multiple,
				viewFilter: this.getOption('viewFilter', false)
			}
		]
	},
	events: {
		'click button.dropdown-toggle > .reset'(event) {
				event.stopPropagation();
				event.preventDefault();
				this.selected.unselectAll();
		},
		'click button.dropdown-toggle' () {
			this.state('open', !this.state('open'));
		}
	},
	buildValue(id, label) {
		id = invokeValue(id, this, this);
		if (typeof id === 'object') { return id; }

		let obj;

		if (label && typeof label === 'object') {
			obj = label;
			label = undefined;
		} else {
			label = label != null ? label : id;
		}
		obj =  Object.assign({ id, label }, obj);

		return obj;
	},
	buildValues(args = []) {
		if (args && typeof args === 'object' && !Array.isArray(args)) {
			return Object.keys(args)
										.map(key => this.buildValue(key, args[key]));
		} else if (Array.isArray(args)) {
			return args.map(arg => this.buildValue(arg));
		}		
		return args;
	},
	buildValuesCollection(_values, CollectionClass) {
		const values = this.buildValues(_values);
		return new CollectionClass(values);
	},

	childViewEvents: {
		// 'action:trigger'(eventName, event) {
		// 	this.triggerMethod(eventName, event);
		// },
		// 'action:taked'() {
		// 	this.state('open', false);
		// },
		'click'(child, event) {

			event.stopPropagation();
			if (this.selected) {
				
				const model = child.model;
				this.selected.toggle(model);

			} else {

				this.handleClick(child, event);
				this.state('open', false);

			}


		}
	},
	smartOpen(event, url, newWindow) {
		let options = event;
		if (newWindow) {
			options = { ctrlKey: !event.ctrlKey };
		}
		smartOpen(url, options);
		return;
	},
	handleClick(child, event) {
		const { url, newWindow } = child.model.attributes;
		if (url) {
			this.smartOpen(event, url, newWindow);
		} else {
			return this.tryExecute(child, event);
		}
	},
	tryExecute(child, event) {
		const model = child.model;
		const action = model.attributes.action;

		const actionType = typeof action;

		if (actionType === 'function') {
			action.call(model, model, event);
			this.trigger('action:taked');
			return;
		}

		if (actionType === 'string' && action.startsWith('trigger:')) {
			action = action.replace(/^trigger:/,'');
			this.trigger('action:trigger', action, event);
			this.trigger('action:taked');
			return;
		}
	}

});