﻿//define('m-deals-deals', ['bus','m-deals-deal', 'app-paths', '_libs/identity', 'notifier'], function (Bus, Deal, paths, identity, notifier) {});


import Bus from 'bus';
import { DealModel } from './deal';
import paths from 'app-paths';
import identity from '_libs/identity';
import notifier from '_helpers/notifier';

import { bbeViewComparator } from 'utils';

import { BbeCollection } from 'core/bbe';
import { moment } from 'vendors';
import { svcAuthPromise } from 'mods/acc';

var Deals = BbeCollection.extend({
	model: DealModel,
	comparator: function (a, b) {
		return bbeViewComparator(a, b, function () { return +moment(this.get('action').when); });
	},
	url: function () {
		return paths.api.deals;
	},
	//applyFiltersAndFetch: function (hash) {
	//	this.loudFetch({ data: {status:'inProcess'} });
	//}
});

Deals.near = function () {
	var col = new Deals();
	col.url = function () {
		return paths.api.dealsNear;
	}
	return col;
};


Deals.far = function () {
	var col = new Deals();
	col.url = function () {
		return paths.api.dealsFar;
	}
	return col;
};

Deals.past = function () {
	var col = new Deals();
	col.url = function () {
		return paths.api.dealsPast;
	}
	return col;
};


var Deals2 = BbeCollection.extend({
	model: DealModel,
	setEmployeeId: function (emp) {
		this.employeeId = emp;
	},
	url: function () {
		if (this.type == 'employee') {
			return paths.api.deals + '/' + this.type + '/' + this.employeeId;
		}

		var add = this.employeeId ? "?empId=" + this.employeeId : "";
		if(!this.type)
			return paths.api.deals + add;
		else
			return paths.api.deals + '/' + this.type + add;
	},
});
Deals2.Active = Deals2.extend({ type: "active" });
Deals2.Closed = Deals2.extend({ type: "closed" });
Deals2.Employee = Deals2.extend({ type: "employee" });


Bus.Models.reply('deals', function () {
	return Deals;
});

Bus.Models.reply('deals2', function () {
	return Deals2;
});

Bus.Models.reply('dealsActive', function () {
	return Deals2.Active;
});

Bus.Models.reply('dealsClosed', function () {
	return Deals2.Closed;
});

Bus.Models.reply('dealsEmployee', function (id) {
	if(!id)
		return Deals2.Employee;
	else
		return Deals2.Employee.extend({ employeeId: id });
});


svcAuthPromise.then(function() {
	console.warn("deals socket notifier launched");
	//JsonDealOwnerChange
	notifier.addSockectHandlers({
		event: 'JsonDeal:add',
		handler: function(noty, raw, options) {
			//if (identity.isMe(options.actor)) return;

			noty.add(this.create(raw.wId, raw.name, options.actor));
			
		},
		create: function(wId, message, actorId) {
			var header = 'Вам назначен процесс';
			//var message = message;
			var url = paths.url.realtiesSearchMap + '/' + wId;
			return { type: 'new-deal', header: header, message: message, actorId: actorId, url: url };
		}
	}, {
			event: 'JsonDealOwnerChange',
			handler: function(noty, raw, options) {
				//if (identity.isMe(options.actor)) return;

				noty.add(this.create(raw.wId, raw.name, options.actor));

			},
			create: function(wId, message, actorId) {
				var header = 'Вам передан процесс';
				//var message = message;
				var url = paths.url.dealCard + '/' + wId;
				return { type: 'new-deal', header: header, message: message, actorId: actorId, url: url };
			}

	});


});



export default Deals;
