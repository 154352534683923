import { MetroLayer } from "../MetroLayer";

export const YandexMetroLayer = MetroLayer.extend({


	addLineOnClick(line, event, callback, options) {
		line.shape.update({
			onClick: callback
		});
	},

	drawLineShape(line) {
		this.map.addChild(line.shape);
		// line.shape.setMap(this.map);
	},

	hideLine(line, options = {}) {
		// const { destroing } = options;
		if (line.shape) {
			this.map.removeChild(line.shape);
			// line.shape.setMap(null);
			// if (destroing) {
			// 	this.engine.off(line.shape);
			// }
		}
	},

	buildLineShape(line) {

		const lineNumber = line.get('lineNumber');
		const paths = line.stations.map(st => [st.get('lng'), st.get('lat')]); // lat: st.get('lat'), lng: st.get('lng') }));

		if (!paths.length) {
			console.error('no stations in line ', line.get('name'));
		}

		if (lineNumber == 5) {
			paths.push(paths[0]);
		}
		const defaults = this.getOption('lineShapeDefaults');
		const shapeOptions = {
			id: 'map-line-' + lineNumber,
			zIndex: 2,
			// onClick: () => { console.log('chpok') },
			// draggable: true,
			geometry: {
				type: 'LineString',
				coordinates: paths
			},
			style: {stroke: [{color: this.getLineColor(lineNumber), width: defaults.strokeWeight, opacity: defaults.strokeOpacity }]}
		};

		const shape = new ymaps3.YMapFeature(shapeOptions);

		// const shape = new google.maps.Polyline(shapeOptions);

		return shape;
	},


	drawLineStationShape(station) {

		this.map.addChild(station.shape);
		
	},

	addStationOnClick(station, callback) {
		station.shape.update({
			onClick: callback
		});
		// station.shape.addListener('click', callback);
	},
	addStationRadiusShowEvents(station) {
		// station.shape.addListener('mouseover', () => this.toggleLineStationRadius(station, true))
		// station.shape.addListener('mouseout', () => this.toggleLineStationRadius(station, false))
	},

	toggleLineStationRadius(station, shouldShow) {
		if (shouldShow) {
			// this.showLineStationRadius(station);
		} else {
			// this.hideLineStationRadius(station);
		}
	},




	showLineStationRadius(station) {
		// if (!station.radiusShape) {
		// 	station.radiusShape = this.buildLineStationRadiusShape(station);
		// }
		// station.radiusShape.setMap(this.map);
	},



	buildLineStationRadiusShape(station) {
		// const lineNumber = station.collection.lineNumber;
		// const lineColor = this.getLineColor(lineNumber);
		// const { lat, lng } = station.attributes;
		// const shapeOptions = Object.assign({}, this.getOption('stationRadiusShapeDefaults'), {
		// 	strokeColor: lineColor,
		// 	fillColor: lineColor,
		// 	center: { lat, lng },
		// });
		// const shape = new google.maps.Circle(shapeOptions);
		// return shape;
	},

	buildLineStationShape(station) {
		// if (1 == 1) { return; }
		const lineNumber = station.collection.lineNumber;
		const lineColor = this.getLineColor(lineNumber);
		const { lat, lng } = station.attributes;
		//var metroShape = this.metroShape = 

		// const shapeOptions = Object.assign({}, this.getOption('stationShapeDefaults'), {
		// 	strokeColor: lineColor,
		// 	fillColor: lineColor,
		// 	center: { lat, lng },
		// });
		// const shape = new google.maps.Circle(shapeOptions);
		// return shape;


		const el = document.createElement('div');
		el.className = 'yandex-metro-marker line-' + lineNumber;
		el.style = "background-color: " + lineColor;
		el.title = station.get('name');
		const options = { 
			id: 'map-station-' + station.id, 
			source: 'marker-source',
			zIndex: 3,
			coordinates: [lng, lat],
		};

		var marker = new ymaps3.YMapMarker(options, el);

		return marker;

	},

	hideLineStation(station, options = {}) {
		// const { destroing } = options;
		// if (station.shape) {
		// 	station.shape.setMap(null);
		// 	if (destroing) {
		// 		this.engine.off(station.shape);
		// 	}
		// }
		this.map.removeChild(station.shape);
		this.hideLineStationRadius(station, options);

	},
	hideLineStationRadius(station, options = {}) {
		// const { destroing } = options;
		// if (station.radiusShape) {
		// 	station.radiusShape.setMap(null);
		// 	if (destroing) {
		// 		this.engine.off(station.radiusShape);
		// 	}
		// }
	},
});