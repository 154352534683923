import { CollectionView, Collection } from 'core';
import { UiMoleculeView } from '../../../../coms/ui/UiMoleculeView';


function 	getName(v) {
	const name = v.model.attributes.name || {};
	let { first, last, middle } = name;
	return [last, first, middle].join(' ');
}

const AgentView = UiMoleculeView.extend({
	content: {
		text: v => getName(v)
	},

});

export const AgentsListView = CollectionView.extend({
	childView: AgentView,
	initialize() {
		this.collection = new Collection(this.model.get('agencyAgents'));
	}
})