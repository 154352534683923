﻿// define('register-svcmodules', ['app'
// 	, 'm-appData'
// 	, 'svc-modules'
// 	, 'bus-app'
// ], function (app, appData, modules, busApp) {});

import app from './app/app';
import busApp from 'bus/app';
import modules from './svc-modules';
import { _ } from 'vendors';


function registerModules() {

	//app.addRunPromise(appData.authData.initAndFetch());
	// let waitFor = [];
	
	console.log('> registerSVCModules: ');

	_.each(modules, function(module) {
		if (!module) return;
		if (typeof(module) === 'function') {
			// console.log('module', module);
			// throw new Error('module should not be function');
			let res = module(app);
			// console.log('-svc-module-', module, res);
		} else {
			app.addSubmodule(module);
		}
		//console.log('app svc module:', module.name, module.cid);
	});
	//console.log('added all svc modules');
	app._svcModulesPromise && app._svcModulesPromise.resolve();
	busApp.trigger('svcmodules:registered');

}

//registerModules();
export default registerModules;