import { _ } from 'vendors';
// import { objectMap, objectReduce } from '../utils/index.js';
import { View } from 'core';

import './input.less';
import { controlValueMixin } from './common';

export const Input = View.extend({
	...controlValueMixin,
	constructor: function() {
		View.apply(this, arguments);
		this._initializeDelegates();
	},
	initialize() {
		this.initializeValue();
	},

	baseClassName: 'input-control',
	template: '<%= html %>',
	templateContext() {
		const html = this.buildHtml();
		return {
			html
		}
	},
	buildHtml() {
		const isTextarea = this.isTextarea();
		const attrs = this.getInputAttributes();
		const htmlBefore = this.getHtmlBefore();
		const htmlAfter = this.getHtmlAfter();
		if (isTextarea) {
			let value = this.getInitialValue();
			if (value == null) {
				value = '';
			}
			return htmlBefore + `<textarea${attrs}>${value}</textarea>` + htmlAfter;
		} else {
			return htmlBefore + `<input${attrs}/>` + htmlAfter;
		}
	},
	_getAdditionalHtml(key) {
		let html = this.getOption('html' + key);
		if (html != null) {
			return html;
		}
		let text = this.getOption('text' + key);
		if (text == null) {
			return '';
		}
		return `<span class="html-${key.toLowerCase()}">${text}</span>`;
	},
	getHtmlBefore() {
		return this._getAdditionalHtml('Before');
	},
	getHtmlAfter() {
		return this._getAdditionalHtml('After');
	},
	getInputAttributes() {
		const baseAttrs = {
			name: this.getInputName(),
			class: 'control',
		};
		const hash = this.isTextarea()
							? this._textareaAttributes(baseAttrs)
							: this._inputAttributes(baseAttrs);
		let attrs = _.map(hash, (value, key) => {
			if (value == null) {
				return;
			}
			return key + '="' + encodeURIComponent(value) + '"';
		}).filter(f => !!f).join(' ');

		if (attrs.length) {
			attrs = ' ' + attrs;
		}
		return attrs;
	},
	_textareaAttributes(attrs) {
		return Object.assign(attrs, this.getOption('textareaAttributes'));
	},

	_inputAttributes(attrs) {
		attrs = Object.assign(
			attrs, 
			{
				type: this.getInputType(),
				value: this.getInitialValue()
			},
			this.getOption('inputAttributes')
		);
		return attrs;
	},

	isTextarea() {
		const schema = this.getValueSchema();
		return schema.valueSubType === 'textarea';
	},

	isTabCharAllowed() {
		const schema = this.getValueSchema();
		const { valueType } = schema;
		if (!(valueType === 'string' || valueType == null)) {
			return false;
		}
		let allowed = this.getOption('tabCharAllowed')
		if (allowed == null) { allowed = schema.inputTabCharAllowed; }
		if (allowed != null) { return allowed; }
		return this.isTextarea();
	},

	getValueSchema() {
		if ('valueSchema' in this === false) {
			this.valueSchema = this.getOption('valueSchema') || {};
		}
		return this.valueSchema;
	},

	getInputName() {
		const schema = this.getValueSchema();
		return this.getOption('inputName') || schema.inputName || schema.property
	},

	getInputTagName() {
		return this.isTextarea() ? 'textarea' : 'input';
	},

	getInputType() {
		const schema = this.getValueSchema();
		const type = this.getOption('inputType') || schema.inputType;
		if (type) { return type; }
		const { valueType, valueSubType } = schema;

		if (valueType === 'number' || valueType === 'date' || valueType === 'time') {
			return valueType;
		}

		if (valueType === 'datetime') {
			return 'datetime-local';
		}

		if (valueType === 'string') {
			return valueSubType || 'text';
		}

	},

	getInputPlaceholder() {
		const schema = this.getValueSchema();
		return this.getOption('inputPlaceholder') || schema.inputPlaceholder;
	},

	// getInitialValue() {
	// 	if ('_initialValue' in this === false) {
	// 		this._initialValue = this.getOption('value', true);
	// 	}
	// 	return this._initialValue;
	// },


	_initializeDelegates() {
		const inputDelay = this.getOption('inputDelay') || 150;
		const debouncedHandler = _.debounce(inputHandler.bind(this), inputDelay);
		const tagName = this.getInputTagName();
		this.delegate('input', tagName, debouncedHandler);
		const isTextarea = this.isTextarea();

		this.delegate('keyup', tagName, event => {
			const isDone = isUserInputDone(isTextarea, event);
			if (isDone == null) { return; }
			const value = event.target.value;
			this.triggerUserInput(value, isDone);
		});

		if (this.isTabCharAllowed()) {
			this.delegate('keydown', tagName, (e) => {
				if (e.key == 'Tab') {
					e.preventDefault();
					const el = e.target;
					var start = el.selectionStart;
					var end = el.selectionEnd;
			
					// set textarea value to: text before caret + tab + text after caret
					el.value = el.value.substring(0, start) + "\t" + el.value.substring(end);
			
					// put caret at right position again
					el.selectionStart = el.selectionEnd = start + 1;
					
					this.triggerUserInput(el.value, false);
				}
			});
		}

		let selectOnFocus = this.getOption('selectOnFocus'); // !== false;
		if (selectOnFocus == null) {
			selectOnFocus = !this.isTextarea();
		}
		if (selectOnFocus) {
			this.delegate('focus', tagName, () => this.$(tagName).select());
		}
		this.on('attach', this.tryAutofocus);
	},

	tryAutofocus() {
		let focus = this.getOption('autofocus');		
		if (focus == null && !this.isTextarea()) { focus = true; }
		if (!focus) { return; }
		this.$(this.getInputTagName()).focus();
	},

	triggerUserInput(value, done) {
		let addEvent = this.getOption('eventPostfix') || '';
		if (addEvent) {
			addEvent = ":" + addEvent;
		}
		this.triggerMethod('user:input' + addEvent, value, done);
	},

});


function isUserInputDone (textarea, event) {

	if (textarea && event.ctrlKey && event.keyCode === 13) {

		return true;

	} else if (!textarea && event.keyCode === 13) {

		return true;

	}

}

function inputHandler(event) {
	const value = event.target.value;
	this.triggerUserInput(value, false);
}