// define('svc/actualization/realties-list/realty/edit/properties/number-control', [
//     'svc/actualization/realties-list/realty/edit/properties/controls'
// ], function(
//     controls
// ) {});

import controls from './controls';
import { MneView } from 'core/mne';
const Layout = MneView.extend({
    className: 'number-control',
    template: _.template(`
        <input value="<%= value %>" class="form-control" />
    `),

    templateContextExtend() {
        return {
            value: this.value
        }
    },

    ...controls.ControlValueMixin,
    ...controls.ControlChangeValueMixin,
    ...controls.InputValueMixin,

    events: {
        ...controls.InputValueEventsMixin('input'),
    }
});

export default Layout;
