module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<section>\n	<div><big>'+
((__t=(name))==null?'':__t)+
'</big></div>\n	<div>'+
((__t=( _ift(_m.email,'&mdash;')))==null?'':__t)+
'</div>\n	<div>'+
((__t=( id ))==null?'':__t)+
'</div>\n	<div class="system system-top-right">\n		<small class="last-action">'+
((__t=( _fromNow(_m.lastAction)))==null?'':__t)+
'</small>\n		<span class="icon"></span>\n	</div>\n</section>';
}
return __p;
};
