import { DynamicClass } from 'behaviors';

import { HamburgerView } from 'coms/ui/HamburgerView';

import { _, MnView, NextCollectionView } from 'vendors';

import { MneView } from 'core/mne';

export const TabView = MneView.extend({
	tagName:'li',
	template: _.template('<a href="#">#<%=name%></a>'),
	modelEvents: {
		'change':'render',
	},
	dynamicClass: function () {
		var cur = this.options.getCurrentModel && this.options.getCurrentModel();
		if (cur && this.model == cur)
			return 'active';
	},
	events: {
		'click': function (e) {
			var model = this.model || 'new';
			this.triggerMethod('switch:to', model);
		},
	},
	behaviors: [DynamicClass],
	templateContext: function () {
		var name = this.model && this.model.get('name') || this.getOption('text');
		return {
			name: name,
		}
	}
});

const BaseItem = MnView.extend({
    tagName: 'li',
    template: _.template('<a href="#">#<%=name%></a>'),
    events:{
        'click'(e) {
            if (this.$el.hasClass('active')) { return; }
            let triggerValue = this.model || 'new';
            this.triggerMethod('click', triggerValue);
        }
    },
    templateContext() {
        let name = this.model ? this.model.get('name') : 'создать новый';
        return {
            name
        }
    }
})


const TabDropdownItem = BaseItem.extend({
    className() {
        let cm = this.getOption('currentModel');
        if (this.model === cm) { return 'active'; }
    },
    modelEvents: {
        'change':'render'
    },

});

const TabDropdownItems = NextCollectionView.extend({
    tagName: 'ul',
    className(){
         return 'dropdown-menu';
    },
    childView: TabDropdownItem,
    childViewOptions() {
        return {
            currentModel: this.getOption('currentModel')
        }
    },
    onRender() {

        this.$el.append(`<li role="separator" class="divider"></li>
<li class="clear-all"><a href="#">Стереть все процессы</a></li>`);

    },
    childViewTriggers:{
        'click':'click'
    },
    events: {
        'click .clear-all'() {
            this.triggerMethod('clear:all');
        }
    }
});

const TabsDropdown = MnView.extend({
    tagName: 'li',
    template: _.template(`<div class="dropdown">
    <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
        <span class="current-name"><%= currentName %></span>
        <span class="caret"></span>
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenu1"></ul>
</div>`),
    regions: {
        items: '.dropdown-menu'
    },
    initialize() {
        this.currentModel = this.getOption('currentModel', true);
        if (this.currentModel) {
            this.listenTo(this.currentModel, 'change:name', this.updateName);
        }
    },
    onRender() {
        let view = new TabDropdownItems({
            collection: this.collection,
            currentModel: this.currentModel
        });
        this.showChildView('items', view, { replaceElement: true });
    },
    updateName() {
        let name = this.currentModel.get('name') || '&mdash;';
        this.$('.current-name').html(name);
    },
    templateContext() {
        let currentModel = this.currentModel;
        console.log('~currentModel~', currentModel, currentModel.attributes.name);
        return {
            currentName: currentModel && currentModel.get('name') || '&mdash;'
        }
    },
    childViewTriggers:{
        'click':'click',
        'clear:all':'clear:all'
    }
});

/*var tabsView = new MneNextCollectionView({
    tagName: 'ul',
    className: 'nav nav-tabs before-boardcontainer',
    childView: TabView,
    collection: col,
    childViewOptions: function () {
        return {
            collection: col,
            getCurrentModel: function () {
                return view.model;
            },
        };
    },
    onRender: function () {
        var createView = new TabView({ text: 'создать новый' });
        this.addChildView(createView, this.collection.length);
    },
    childViewEvents: {
        'switch:to': function (model) {
            console.log('=switch:to=', model);
            view.switchTo(model);
        }
    }
});
*/

export const TabsView = HamburgerView.extend({
    thisClassName: 'nav nav-tabs before-boardcontainer',
    childViewOptions() {
        return {
            currentModel: this.getOption('currentModel', true),
            onClick: arg => this.triggerMethod('switch:to', arg),
            
        }
    },
    childrenViews: [
        {
            class: BaseItem,
            //text: 'создать новый'
        },
        // v => {
        //     let model = v.getOption('currentModel', true);
        //     console.log('=== ???? ==== ', model, v)
        //     if (!model) { return; }
        //     return {
        //         DEBUG: 'yes',
        //         class: TabView,
        //         model
        //     }
        // },
        v => ({
            class: TabsDropdown,
            onClearAll: () => v.triggerMethod('clear:all')
            //onClick: model => v.triggerMethod('switch:to', model)
        })
    ],
    
})