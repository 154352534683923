// define('svc/actualization/actions-panel', [
// 	'svc/actualization/add-owner',
// 	'svc/actualization/add-building',
// 	'modals'
// ], function(
// 	CreateOwner, CreateBuilding, modals
// ) {});


import CreateOwner from './add-owner';
import CreateBuilding from './add-building';
import modals from '_libs/modals';

import { BbeCollection } from 'core/bbe';

import { MneObject } from 'core/mne';

import { MneView, MneCollectionView } from 'core/mne';

import './action-buttons-panel.less';

const ActionView = MneView.extend({
	className: 'action-button',
	tagName: 'button',
	template: _.template('<%= text %>'),
	triggers: {
		'click':'click'
	},
});

const bus = new MneObject();



const actions = [
	{
		id: 'create:owner',
		text: 'Добавить собственника',			
	},
	{
		id: 'create:building',
		text: 'Добавить здание',			
	},		
];

const ActionsView = MneCollectionView.extend({
	className: 'action-buttons-panel',
	initialize() {
		let models = this.buildModels(this.getOption('actions'));
		this.collection = new BbeCollection(models);
	},
	buildModels(actions = []) {
		return actions;
	},
	childView: ActionView,
	onChildviewClick(view) {
		bus.triggerMethod('aside:action', view.model.id);
	}
});

export default {

	actions,

	resultViewMixin: function({ options, actions } = {}) {

		const def = {
			additionalView: ActionsView,
			additionalViewOptions: {
				actions
			},
			registerActionsPanel() {
				this.listenTo(bus, 'aside:action', (action) => {
					this.triggerMethod('action:'+action);
				});
			},
			_showInModal(view, destroyOnEvent, options) {
				modals.modal(view, options);
				view.on(destroyOnEvent, () => view.destroy());
			},
			onActionCreateOwner() {
				let view = new CreateOwner();
				this._showInModal(view, 'create');
			},
			onActionCreateBuilding() {
				let view = new CreateBuilding();
				this._showInModal(view, 'create', { modalCssCfg: 'fullscreenNoPadding' });
			}
		}

		return Object.assign({}, def, options);
	}
}
