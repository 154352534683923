import { tryAsync } from "./tryAsync";

const scriptsStore = new Set();

function internalLoadScript(url) {

	if (scriptsStore.has(url)) {
		return Promise.resolve();
	}

	return new Promise((resolve, reject) => {
		const tag = document.createElement('script');
		tag.src = url;
		tag.onload = () => {
			scriptsStore.add(url);
			tag.remove();
			resolve();
		}
		tag.onerror = (exc) => {
			tag.remove()
			reject();
		}
		document.body.append(tag);
	});

}

export async function loadScript(url) {
	const res = tryAsync(() => internalLoadScript(url));
	return res;
}