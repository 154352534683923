import beh from 'behaviors';
import Bus from 'bus';
import { BbModel } from 'vendors';
import { BbeCollection } from 'core/bbe';
import { MneNextCollectionView, MneButtonView } from 'core/mne';

import { SearchRealtyControl, SearchPlaceControl } from 'ui/controls/map/control-input';

import { knownBounds } from './knownBounds';
import { isViewClass } from 'utils';
import { userConfig } from 'identity';

function normalizeControlOption(arg, DefView) {
	if (!arg) {
		return;
	}

	let View, options;

	if (arg === true) {
		View = DefView;
		options = {};
	} else if (isViewClass(arg)) {
		View = arg;
		options = {};
	} else {
		View = DefView;
		options = arg;
	}

	return [View, options];
}


const FeatureKeyModel = BbModel.extend({
	// constructor: function() {
	// 	BbModel.apply(this, arguments);
	// }
	parse(data, { mapApi } = {}) {
		data.iconClass = 'fa fa-' + data.faIconName;
		data.state = mapApi.userFeature(data.id + '.state');
		return data;
	},
	toggle() {
		const api = this.collection.mapApi;
		const featureKey = this.id + '.state';
		const newvalue = api.userFeature(featureKey) === 'on' ? 'off' : 'on';
		this.set('state', newvalue);
		api.userFeature(featureKey, newvalue);
	}
});

const featuresHashes = [
		{ id: 'clusters', faIconName: 'th' },
		{ id: 'subway', faIconName: 'subway'},
]


const ControllButtonView = MneButtonView.extend({
	className: 'map-control-toggle-button',
	autoRender: false,
	dynamicClass: function () {
		return this.model.get('state');
	},
	triggerOnClick: 'toggle',
	behaviors: [beh.DynamicClass],
});

const ControllsButtonsView = MneNextCollectionView.extend({
	childView: ControllButtonView,
	className: 'map-control-wrapper',
	initialize: function () {
		this.render();
	},
	childViewOptions() {
		return {
			map: this.getOption('map'),
			mapApi: this.getOption('mapApi'),
		}
	},
	childViewEvents:{
		'toggle'(view) {
			view.model.toggle();
		}
	}
	//regions: {
	//	'btn1': '.btn1',
	//	'btn2': '.btn2',
	//},
	//template: _.template('<span class="btn1"></span><span class="btn2"></span>'),
	//onRender: function () {
	//	this.showChildView('btn1', grid);
	//	this.showChildView('btn2', metro);
	//}
});

export const setupControlsMixin = {
	setupControls: function (controls) {
		if (!controls) { return; }
		const { searchPlace, searchRealty, searchOnMapButtons } = controls;

		if (searchPlace)
			this.addControlSearchPlace(searchPlace);

		if (searchRealty)
			this.addControlSearchRealty(searchRealty);

		if (searchOnMapButtons) {
			this.addGeneralButtonsControls()
		}

	},

	_buildMapControlView(arg, DefView) {
		const res = normalizeControlOption(arg, DefView);
		if (!res) { return; }

		let [View, options] = res;
		options = Object.assign({ mapView: this }, options);

		let view = new View(options);
		view.render();
		return view;
	},

	addControlSearchPlace: function (Input) {

		const view = this._buildMapControlView(Input, SearchPlaceControl);
		if (!view) { return; }



		// this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(view.el);
		// this.alsoDestroy.push(view);

		// var inputEl = view.$('input').get(0);

		// setTimeout(() => {

		// }, 0);

		// const inputEl = document.createElement('input');
		// const div = document.createElement('div');
		// div.appendChild(inputEl);

		const controlEl = view.el;
		const inputEl = view.$('input').get(0);

		this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(controlEl);
		// console.log('- places input - ', inputEl);

		const searchBounds = view.getOption('searchBounds') || knownBounds.get('moscow');

		//inputEl.placeholder = view.attributes.placeholder;
		this.searchBox = new google.maps.places.SearchBox(inputEl, {
			bounds: searchBounds, //this.map.getCenter(),
		});
		// var _this = this;
		this.searchBox.addListener('places_changed', this.searchBoxAddressSelected.bind(this, view));
		// function () {
		// 	_this.searchBoxAddressSelected.apply(_this, arguments);
		// });




	},

	addControlSearchRealty: function (Input) {

		const view = this._buildMapControlView(Input, SearchRealtyControl);
		if (!view) { return; }

		this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(view.el);
		this.alsoDestroy.push(view);
	},

	searchBoxAddressSelected: function (view) {

		if (this.searchPlaceMarker)
			this.searchPlaceMarker.setMap(null);

		var places = this.searchBox.getPlaces();
		var first = places[0];
		if (!first) return;

		this.map.fitBounds(first.geometry.viewport);

		this.map.setZoom(14);

		this.searchPlaceMarker = new google.maps.Marker({
			position: first.geometry.location,
			map: this.map,
			title: first.name,
			icon: '//maps.google.com/mapfiles/kml/paddle/wht-blank.png'
			// icon: 'https://mts.googleapis.com/vt/icon/name=icons/spotlight/spotlight-waypoint-blue.png&text=&psize=16&font=fonts/Roboto-Regular.ttf&color=ff333333&ax=44&ay=48&scale=1'
		});

		this.searchPlaceMarker.addListener('click', () => {
			view.triggerMethod('search:place:marker:click', this.searchPlaceMarker);
			if (this.getOption('hideSearchPlaceMarkerOnClick') !== false) {
				this.searchPlaceMarker.setMap(null);
			}
		});

		//console.log(first);
	},

	addGeneralButtonsControls: function () {



	
		const mapApi = this.mapApi;
		var btns = new BbeCollection([], { model: FeatureKeyModel });
		btns.mapApi = mapApi;
		btns.set(featuresHashes, { parse: true, mapApi });		

		var control = new ControllsButtonsView({ collection: btns, map: this.map, mapApi });

		this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(control.el);
		
	},
}