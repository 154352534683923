﻿//define('ui-models-values-single', ['ui-models-values-base'], function (Base) {});

import Base from './base';
import { isNotValue } from 'utils';

function firstValue(...args) {
	for(let val of args) {
		if (val != null && val !== '') {
			return val;
		}
	}
}

var Model = Base.extend({
    defaults: {
        value: undefined,
        label: undefined,
    },
    parse: function (data) {
        if (!data.id)
            data.id = _.uniqueId('single');
        return data;
    },
    filled: function () {
        return this.get('value') != null;
    },
    getLabel: function () {
        return firstValue(this.get('label'), this.get('value'), this.get('id'));
    },
    getValue: function () {
        return firstValue(this.get('value'), this.get('id'));
    },
    getUrlHash: function (opts) {
        var options = this._normalizeUrlHashOptions(opts);
        var value = this.getConverted('value', options.valueConverter);
        if (isNotValue(value)) return;
        return { 'v': value };
    },
});
Model.create = function(attrs, opts) {
    if (attrs instanceof Model) return attrs;
    var who = _.who(attrs);

    var value = undefined;
    var label = undefined;
    var valueType = undefined;

    if (who.primitive && who.value) {
        value = attrs;
        label = attrs;
        //valueType = typeof value;
    } else if (who.date) {
        value = attrs;
        label = attrs;
        //valueType = 'date';
    }
    else if (_.isObject(attrs)) {
        var obj = _.v(attrs);
        value = _.val(obj);
        label = _.lbl(obj);
        //valueType = typeof value;
    } else {
        //valueType = typeof attrs;
        value = attrs;
        label = attrs;
    }

    valueType = opts.valueType || 'string';

    if (typeof Model[valueType] === 'function')
        return Model[valueType](value, label);
    else
        return Model.string(value, label);

};
Model.number = function(value, label) {
    return new Model({ id: _.uniqueId('single'), value: value, label: label }, { valueType: 'number' });
};
Model.date = function(value, label) {
    return new Model({ id: _.uniqueId('single'), value: value, label: label }, { valueType: 'date' });
};
Model.string = function(value, label) {		
    return new Model({ id: _.uniqueId('single'), value: value, label: label }, { valueType: 'string' });
};
Model.text = function(value, label) {
    return new Model({ id: _.uniqueId('single'), value: value, label: label }, { valueType: 'text' });
};
Model.email = function(value, label) {
    return new Model({ id: _.uniqueId('single'), value: value, label: label }, { valueType: 'email' });
};
Model.phone = function(value, label) {
    return new Model({ id: _.uniqueId('single'), value: value, label: label }, { valueType: 'phone' });
};



export default Model;