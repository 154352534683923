import { View } from 'core';
import { _, CollectionView, Collection } from 'vendors';
import { getModuleValuesCollection, getModuleName, containsValue, getModuleEnum } from 'm/serviceModules';
import { ABCompare  } from 'utils';



function getGroupModuleClaimsValue(group, moduleId)
{
    let claims = group.get('claims');
		if (claims) {
			return claims[moduleId];
		}
}

const RightsValueView = View.extend({
    tagName: 'li',
    thisClassName: 'module-rights-value-item',
    template: '<i class="checked-icon"></i><span><%= name %></span>',
    stateful: true,
    modelStateKeys: ['checked'],
    stateClassNames: ['checked'],
    //updateElClassBeforeRender: 'once',
    initialize(options) {
        this.mergeOptions(options, ['group', 'moduleId']);   
        //this.model.set('checked', this.isEnabled());
        //console.log('-', this.moduleId, this.model.get('checked'));
    },
    isEnabled() {
        // let claims = this.group.get('claims');
        // let claimsValue = claims[this.moduleId];
        let claimsValue = getGroupModuleClaimsValue(this.group, this.moduleId);
        return containsValue(claimsValue, this.model.get('value'));
    },
    events:{
        'click'() {
            this.model.set('checked', !this.model.get('checked'));
        }
    }
    // templateContext() {
    //     let state = this.isEnabled();
    //     return {
    //         state: state ? 'on' : 'off'
    //     }
    // }
});

const RightsValuesView = CollectionView.extend({
    tagName: 'ul',
    className: 'module-rights-values',
    initialize(options) {
        this.mergeOptions(options, ['group','moduleId']);
        if (!this.collection) {
            let claimsValue = getGroupModuleClaimsValue(this.group, this.moduleId);
            this.collection = getModuleValuesCollection(this.moduleId, claimsValue);
        }
    },
    childView: RightsValueView,
    childViewOptions() {
        return {
            group: this.group,
            moduleId: this.moduleId
        }
    },
    collectionEvents: {
        'new:value'(value) {
            this.group.setNewModuleClaims(this.moduleId, value);            
        }
    }
    // viewFilter(v) {
    //     return v.model.get('value') !== 'none';
    // }
});

const RightsItemView = View.extend({
    thisClassName: 'module-item',
    template:`
        <div class="module-item-info">
            <span class="name"><%= moduleName %></span>
            <span class="caret"></span>
        </div>
        <section></section>
    `,
    regions: { items: 'section' },
    stateClassNames: ['off', 'collapsed'],
    classNames:[
        v => {
            return v.group 
                ? v.group.hasClaimsChanges(v.model.get('moduleId')) ? 'has-changes' : ''
                : ''
        }
    ],
    initialize() {
        this.group = this.getOption('group');
        let moduleId = this.model.get('moduleId');
        this.updateElClassOn(this.group, 'new:claims:value');
        this.updateElClassOn(this, 'before:render');
        let claimsValue = getGroupModuleClaimsValue(this.group, moduleId);
        this.collection = getModuleValuesCollection(moduleId, claimsValue);
        let offStateUpdater = () => this.state('off', !this.collection.hasValues());
        this.listenTo(this.collection, 'new:value', offStateUpdater);
        offStateUpdater();
        if (this.state('off')) {
            this.state('collapsed', true);
        }
    },
    onRender() {
        let view = new RightsValuesView({
            group: this.group,
            moduleId: this.model.get('moduleId'),
            collection: this.collection
        });
        this.showChildView('items', view, { replaceElement: true });
    },
    templateContext() {
        return {
            moduleName: getModuleName(this.model.get('moduleId'))
        }
    },
    events: {
        'click .module-item-info'() {
            this.state('collapsed', !this.state('collapsed'));
        }
    }
});

export const RightsContentView = CollectionView.extend({
    className: 'modules-list',
    initialize() {
        let claims = this.model.get('claims');
        let models = _.reduce(claims, (memo, moduleClaims, moduleId) => { 
					if (!getModuleEnum(moduleId)) { return memo; }
					const model = { moduleId }
					memo.push(model);
					return memo;
				}, []);
        this.collection = new Collection(models);
    },
    childView: RightsItemView,
    childViewOptions() {
        return {
            group: this.model
        }
    },
    viewComparator(v1, v2) {
        let reverse = true;
        return ABCompare(v1, v2, [
            v => v.state('off') ? 1 : 0,
            { reverse, get: v => v.model.get('order') },
            v => v.model.get('name')
        ]);
    }
});