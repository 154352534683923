import consts from 'vXX/app/constants';
import { displayNum } from './number';
import { getValueByPath, unFlatObject } from './object-manipulations';
import { isModel } from './is';
import refs from 'references';
const { NDS_KOEF } = consts;


function getModelValue(model, path) {
	if (!model) {
		console.warn('model is undefined');
		return;
	}
	if (model.smartGet) {
		return model.smartGet(path);
	}
	if (path in model) {
		return model[path];
	}
	return getValueByPath(model, path);
}

export function createPriceOptions(priceMeter, square, operation, taxType, boma) {
	
	const isNds = (taxType || '').indexOf('nds') > -1;
	const isRent = operation === 'rent';
	const isBoma = isRent && boma > 1;
	const bomaKoef = isBoma ? boma : 1;
	return { 
		isNds, 
		isRent, 
		isBoma, 
		boma: bomaKoef, 
		priceMeter, 
		square 
	};
}

export function getFullPriceSquare(options) {
	
	const fullMeterPrice = getFullPriceMeter(options);
	const { boma, isRent, square } = options;
	const rentKoef = isRent ? 12 : 1;
	const bomaKoef = isRent && boma > 1 ? boma : 1;
	return square * fullMeterPrice / rentKoef * bomaKoef;
}


export function getFullPriceMeter(options) {
	const { isNds, priceMeter } = options;
	const nds = isNds ? NDS_KOEF : 1;
	return priceMeter * nds;
}

function getPriceFostfix(add, nds, boma) {
	if (add == null) {
		add = '';
	}
	let txt = 'р. ' + add;
	const words = [];
	if (nds) {
		words.push('ндс');
	}
	if (boma) {
		words.push('кор. коэф.');
	}
	if (words.length) {
		txt += ' включая ' + words.join(' и ');
	}
	return txt;
}

export function getFullPriceMeterPostfix(options) {
	let { isNds } = options;
	return getPriceFostfix(' за м<sup>2</sup>', isNds);
}

export function getFullPriceMeterDisplay(options, withPostfix) {
	const value = getFullPriceMeter(options);
	let txt = displayNum(value, 2);
	if (withPostfix) {
		txt += ' ' + getFullPriceMeterPostfix(options);
	}
	return txt;
}

export function getFullPriceSquarePostfix(options) {
	let { isRent, isNds, isBoma, bomaKoef } = options;
	if (isBoma == null) {
		isBoma = bomaKoef > 1;
	}
	const add = isRent ? ' в месяц' : '';
	return getPriceFostfix(add, isNds, isBoma);
}

export function getFullPriceSquareDisplay(options, withPostfix) {
	const value = getFullPriceSquare(options);
	let txt = displayNum(value, 2);
	if (withPostfix) {
		txt += ' ' + getFullPriceSquarePostfix(options);
	}
	return txt;
}

function getOffer(model, { offerPath, objectPath } = {}) {
	if (model == null) { return; }
	if (offerPath) {
		return getModelValue(model, offerPath);
	}
	let attrs = model;
	if (isModel(model)) {
		attrs = model.attributes;
		if (model.flat) {
			attrs = unFlatObject(attrs);
		}
	}
	return attrs;
}

function getObject(model, options = {}) {
	if (model == null) { return; }

	let { objectPath, realtyObject } = options;
	if (realtyObject) { return realtyObject; }

	if (objectPath) {
		return getValueByPath(model, objectPath);
	}
	var off = getOffer(model, options);
	if (off) {
		return off.obj || off.object;
	}
}

function getOfferForCustomer(model, options) {

	let { offerForCustomerPath } = options;

	if (offerForCustomerPath) {
		return getModelValue(model, offerForCustomerPath)
			// getValueByPath(model, offerForCustomerPath);
	}

	const offer = getOffer(model, options);

	const forCustomer = getModelValue(offer, 'forCustomer');
	return forCustomer;
	//offer?.forCustomer;

}

function getObjectSquare(model, options) {
	if (model == null) { return; }

	let { objectSquarePath } = options;

	if (objectSquarePath) {
		return getModelValue(model, objectSquarePath);
			//getValueByPath(model, objectSquarePath);
	}
	const obj = getObject(model, options);
	const squareOffer = getModelValue(obj, 'info.squareOffer');
	return squareOffer;
	// if (obj) {
	// 	return getValueByPath(obj, 'info.squareOffer');
	// }

}


export function extractPriceOptions (model, options = {}) {
	if (!model) { return {}; }
	
	const forCustomer = getOfferForCustomer(model, options);
	if (!forCustomer) { return {}; }

	const { priceIncludes, priceExcludes } = forCustomer;

	const { boma, priceMeter, taxType } = forCustomer;
	
	const square = getObjectSquare(model, options);
	
	const offer = getOffer(model, options);

	// const attrs = model.attributes || model;

	// if (!attrs.forCustomer) { return {}; }

	// const { boma, priceMeter, taxType } = attrs.forCustomer;

	const isRent = offer.operation === 'rent';
	
	const isNds = (taxType || '').indexOf('nds') > -1;
	// const square = getValueByPath(attrs.obj || attrs.object, 'info.squareOffer');
	const bomaKoef = isRent && boma > 1 ? boma : 1;
	
	const isBoma = bomaKoef > 1;

	const priceOptions = { 
		isNds, isRent, isBoma, boma: bomaKoef, 
		priceMeter, square, 
		priceIncludes, priceExcludes,
		priceIncludesWords: getPriceAdditionalsWords(priceIncludes),
		priceExcludesWords: getPriceAdditionalsWords(priceExcludes),
	};

	return priceOptions;
}

function getPriceAdditionalsWords(additionalValue) {
	if (additionalValue == null || additionalValue === '') {
		return []
	}
	const wordsText = refs.enum('priceAdditionals', additionalValue);
	if (wordsText == null) {
		wordsText = '';
	}
	return wordsText.split(/\s*,\s*/);
}



export function getFullPriceSquareInfo(model, options, priceOptions) {
	if (!priceOptions) {
		priceOptions = extractPriceOptions(model, options);
	}
	const { isRent, isNds, isBoma } = priceOptions;
	let priceIncludesWords = priceOptions.priceIncludesWords;
	if (priceIncludesWords) {
		priceIncludesWords = [...priceIncludesWords];
	}
	if (isRent && isBoma) {
		priceIncludesWords.unshift('КОР. КОЭФ');
	}
	if (isNds) {
		priceIncludesWords.unshift('НДС');
	}
	const price = getFullPriceSquare(priceOptions);
	return {
		price,
		priceString: displayNum(price, 2), 
		postfix: isRent ? 'в месяц' : '',
		...priceOptions,
		priceIncludesWords
	}
}

export function getFullPriceMeterInfo(model, options, priceOptions) {

	if (!priceOptions) {
		priceOptions = extractPriceOptions(model, options);
	}
	
	

	const { isRent, isNds } = priceOptions;

	let priceIncludesWords = [];

	if (isNds) {
		priceIncludesWords.unshift('НДС');
	}

	const price = getFullPriceMeter(priceOptions);
	return {
		price,
		priceString: displayNum(price, 2), 
		postfix: 'за м<sup>2</sup>' + (isRent ? ' в год' : ''),
		...priceOptions,
		priceIncludesWords
	}
}

export function getFullPriceInfo(model, options) {
	const priceOptions = extractPriceOptions(model, options);
	return {
		square: getFullPriceSquareInfo(model, options, priceOptions),
		meter: getFullPriceMeterInfo(model, options, priceOptions)
	}
}