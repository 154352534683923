import { userConfig } from "mods/acc";

export function userFeature(key, value) {
	key = 'map.search.features.' + key;
	if (arguments.length < 2) {
		return userConfig.get(key)
	} else {
		return userConfig.set(key, value)
	}
}


export const defaultZoom = 11;
export const defaultCenter = { lat: 55.745829121494936, lng: 37.60493597412108 };


const positionConfig = {
	CENTER: {
		xKey: 'left',
		xKeyOp: 'right',
		yKey: 'top',
		yKeyOp: 'bottom',
		calc(x,y,w,h) {
			return [x - w/2, y - h/2];
		}
	},
	TOPLEFT: {
		xKey: 'left',
		xKeyOp: 'right',
		yKey: 'top',
		yKeyOp: 'bottom',
		calc(x,y,w,h) {
			return [x,y];
		}
	},
}

export function getXYStyle(position, x, y, width, height, addDimensions)
{
	const res = {};
	const { xKey, xKeyOp, yKey, yKeyOp, calc } = positionConfig[position] || {};
	const [ modX, modY ] = calc(x, y, width, height);
	res[xKey] = modX + 'px';
	res[xKeyOp] = '';
	res[yKey] = modY + 'px';
	res[yKeyOp] = '';
	if (addDimensions) {
		res.width = width + 'px';
		res.height = height + 'px';
	}
	return res;
}