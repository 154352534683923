﻿//define('websockets-monitor', ['bus','websockets-config'], function(Bus, cfg) {});

//import Bus from 'bus';
import cfg from './config';
import { Behavior } from 'vendors';
var channelName = cfg.channel;
//var channel = Bus.channel(channelName);

var h = {
    _initializeMonitor: function(options) {
        this.mergeOptions(options, ['getCollection','handleAll','handleId']);
    },
    radioEvents: function() {
        var result = {};
        var modelType = this.getOption('modelType');
        var isHandleAll = this.isHandleAll();
        if (isHandleAll) {
            result[modelType] = this._onAllChanges;
        }
        var isHandleId = this.isHandleId();
        if (isHandleId) {
            var id = this.getModelId();
            result[modelType + ":" + id] = this._onIdChanges;
        }
        return result;
    },

    isHandleAll: function() {
        return _.result(this, 'handleAll');
    },
    isHandleId: function() {
        return _.result(this, 'handleId');
    },
    getModelId: function() {
        var model = this.getModel();
        return model.id;
    },
    getModel: function() {
        return this.model || (this.view && this.view.model);
    },
    getCollection: function() {
        return this.collection || (this.view && this.view.collection);
    },

    _onAllChanges: function(action, model, options) {
        var col = this.getCollection();
        if (!col || col._lastSocketMessage === options.messageId) return;
        if (action === 'remove') {
            this._tryTriggerMethod('remove:outside', model);
            col.remove(model);
        }
        else {
            col.set(model, {remove: false}); //on change there should be gentle update
        }

        col._lastSocketMessage = options.messageId;
    },

    _onIdChanges: function(action, model, options) {
        model = this.getModel();
        var col = this.getCollection();
        if (!model || model.id != model.id || model._lastSocketMessage === options.messageId) return;

        if (action === 'remove') {
            this._tryTriggerMethod('remove:outside', model);
            model.destroy();
        }
        else
            col.set(model); 
    },
    _tryTriggerMethod: function(event, model) {
        var context = this.getTriggerContext();
        if (_.isFunction(context.triggerMethod))
            context.triggerMethod(event, model);
    },
    getTriggerContext: function() {
        if (this.view)
            return this.view;
        else
            return this;
    },
}

var Monitor = {};

Monitor.Behavior = Behavior.extend(h).extend({
    channelName: channelName,
    initialize: function(options) {
        this._initializeMonitor(options);
    },
});



export default Monitor;


