﻿//define('svc-deals-deal-addVirtualOffer', ['modals', 'm-deals-dealVirtualOffer'], function (modals, DealVirtualOfferModel) {});

	
import modals from '_libs/modals';
import DealVirtualOfferModel from 'm/deals/dealVirtualOffer';
import template from './addVirtualOffer.html';

import { MneView } from 'core/mne';

var View = MneView.extend({
	//template: '#tmpl-service-deals-deal-addVirtualOffer',
	template,
	initialize: function () {
		this.donePromise = $.Deferred();
	},
	events: {
		'input [name]': function (e) {
			let $inp = $(e.target);
			var field = $inp.attr('name');
			var value = $inp.val();
			this.model.set(field, value);
		},
		'click .add': function () {
			var view = this;
			var model = this.model;
			var deal = this.getOption('deal');
			//var offers = 
			deal.getOffers();
			model.loudSave({ wait: true }).then(function () {
				//offers.add(model);
				view.donePromise.resolve(model);
				view.destroy();
			}, function () {
				view.donePromise.reject();
			});
		}
	},
	onDestroy: function () {
		if (this.donePromise.state() == 'pending')
			this.donePromise.reject();
	}
});


var ns = {};
ns.showInModal = function (opts) {
	var options = _.extend({}, opts);
	options.model = DealVirtualOfferModel.create(opts.deal.id);
	var view = new View(options);
	var modal = modals.modal(view);

	view.donePromise.always(function () {
		modal.destroy();
	});


	return view.donePromise;
}

export default ns;
