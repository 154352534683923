import { OfferModel } from './OfferModel';
import { Collection } from 'core';

function takeFirst(...args) {
	for(let arg of args) {
		if (arg != null) {
			return arg;
		}
	}
}

export const OffersCollection = Collection.extend({
	constructor: function(data, { parent, ownerInRealty } = {}) {
		this.parent = parent;
		this.ownerInRealty = ownerInRealty;
		Collection.apply(this, arguments);
	},
	model: OfferModel,
	hasNotActualized(objDaysToStale, glbDaysToStale) {
		const passedDaysToStale = takeFirst(objDaysToStale, glbDaysToStale);
		return this.some(model => model.isNotActualized(passedDaysToStale))
	}
});