﻿//define('m-realties-buildings-building', ['bus'], function(Bus) {});

import Bus from 'bus';

import { Collection as BbCollection } from 'vendors';

import { BbeModel, BbeCollection } from 'core/bbe';
import { geoRefs } from '../../../geo/reference/model';

var Model = BbeModel.extend({
	getCssClass: function () {
		return 'bld-' + (this.get('cls') || '').replace('+', 'p');
	},
	getAddress: function () {
		if (this.get('address'))
			return this.get('address');

		var aId = this.get('addressId');
		var geo = geoRefs;
		// Bus.app.request('geo:refs');
		// var geo = Bus.app.request('geo:refs', function () {
		// 	return col;
		// });

		var addresses = geo.get('addresses');
		if (!addresses) return;
		var address = addresses.get(aId);
		//console.log('address');
		this.set('address', address, { silent: true });
		return address;
	},
	getAddressString() {
		var pat1 = /\s*,\s*/gmi;
			//new RegExp("\s*,\s*", 'gmi');
		var address = this.getAddress();
		//console.log('???', address);
		var chunks = (address.get('fullAddress') || '').split(pat1);
		var res = _(chunks).filter(function (chunk) {

			var ch = chunk.toLowerCase().trim();
			var notKnown = ['москва', 'россия'].indexOf(ch) == -1;
			var notIndex = !(/^\d{6,6}$/gmi.test(chunk.trim()));
			//console.log('===', chunk, notKnown, notIndex);
			return notKnown && notIndex;				
		});
		//console.log('!!!!!!!!', res);
		return res.join(', ');
	},
	getMetroHtml() {
		var address = this.getAddress();
		if (!address) return '';
		var metro = Bus.app.request('geo:refs:metroLabelByAddress', address.id);
		return metro || '';
	},
	getOffers: function() {
		var raw = this.get('offers') || [];
		if (raw instanceof BbCollection)
			return raw;
		raw = new BbeCollection(raw);
		this.set('offers', raw);
		return raw;
	},
	addOffer: function(offer) {
		var offers = this.getOffers();
		offers.add(offer);
	},
});


export default Model;
