import { _, Model as BaseModel, Collection } from 'vendors';


export const Model = BaseModel.extend({
    initCollection(name) {
        if (!this.nestedCollections) {
            this.nestedCollections = {};
        }
        let collectionKey = name + 'Collection';
        let col = this.nestedCollections[collectionKey];
        if (col) {
            return col;
        }
        let items = this.get(name) || [];
        col = new Collection(items, { model: Model });
        this.nestedCollections[collectionKey] = col;
        this.listenTo(col, 'change:excluded', this._setNewExcluded);
        return col;
    },
    toggleExcluded() {
        let current = this.get('excluded');
        if (this._hasNesteds()) {
            let allexcluded = this._isAllExcluded();
            if (allexcluded && current) {
                return;
            }
        }
        this.set('excluded', !current);
    },
    isOK() {
        let noErrors = !this.hasErrors(true);
        let included = !this.isExcluded();
        return noErrors && included;
    },
    isOptional() {
        return this.get('isOptional') === true;
    },
    isExcluded() {
        return this.get('excluded') === true;
    },
    hasErrors(withNested) {
        let errors = this.get('errors');
        let hasOwnErrors = errors && !!errors.length;
        if (hasOwnErrors || !withNested) {
            return hasOwnErrors;
        }
        return this._hasAnyNestedErrors();
    },
    _hasNesteds() {
        return _.some(this.nestedCollections, models => models.length > 0);
    },
    _isAllExcluded() {
        let allexcluded = _.every(this.nestedCollections, (models) => models.every(model => !!model.get('excluded')));
        return allexcluded;
    },
    _hasAnyNestedErrors() {
        return _.some(this.nestedCollections, (models) => models.some(model => {
            let excluded =  model.isExcluded();
            let withNested = model._hasNesteds();
            let optional = model.isOptional();

            if (excluded && withNested) {
                return false;
            }

            if (excluded && optional) {
                return false;
            }
            let errs = model.hasErrors(true);
            
            return errs;
        }));
    },
    _setNewExcluded() {
        let allexcluded = this._isAllExcluded();
        if (allexcluded) {
            this.set('excluded', true);
        } else if (this.get('excluded')) {
            this.set('excluded', false);
        } else {
            this.trigger('change:excluded', this);
            this.trigger('change', this);
        }
    },
    toBackendJson() {
        if (this.hasErrors(true) || this.isExcluded()) {
            return [];
        }
        if (this._hasNesteds()) {
            let res = _.reduce(this.nestedCollections, (memo, models) => {
                models.forEach(model => memo.push(...model.toBackendJson()));
                return memo;
            }, []);
            if (this.id === 'root') {
                res.sort((a1, a2) => a1._index - a2._index);
            }
            return res;
        } else {
            return [{ ...this.attributes }];
        }
    }
});