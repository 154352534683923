// define('svc/admin/groups', [
// 	'appModule', 'link', 'svc/admin/groups/layout', 'svc/admin/groups/group'
// ], function (
// 	appModule, link, Layout, groupModule
// 	) {
// });

import appModule from 'appModule';
import link from 'link';
import Layout from './layout';
import groupModule from './group';

import './admin-groups.less';
	
var module = appModule.content({
	name: 'admin/groups',
	label: 'Управление группами',
	urlKey: 'adminGroups',
	routeData: () => link.route('adminGroups'),
	claims: {
		svcAdminModule: ["manageGroups"]
	},			
	onRun: function () {
		//this.showButtonsLayout();
		this.createAndShow(Layout);
	}
});

module.addSubModule(groupModule);
// module.addSubModule(searchOnMap);
// module.addSubModule(moduleRealtyObject);
// module.addSubModule(moduleRealtyBuilding);

export default module;

