import { UiAtomView } from "coms/ui/UiAtomView";
import { UiMoleculeView } from "coms/ui/UiMoleculeView";
import appCfg from 'app-config';
import { smartOpen } from 'utils';
import { urls } from 'coms/urls';
import { Collection } from 'core';
import { modalsApi } from 'apis/modalsApi2';
import { OwnerRealtyContacts } from './OwnerRealtyContacts'
function realtyOwnerContactsModal(rid, oid) {
	
	const collection = new Collection();
	collection.url = urls.api(`v4/realties/realties/${rid}/owners/${oid}/contacts`);
	collection.fetch();

	const content = {
		class: OwnerRealtyContacts,
		collection: collection
	}

	modalsApi.show(content, { header: 'Список контактных лиц' });

}


const AtomView = UiAtomView.extend({
	isRealty() {
		return this.getOption('modelType') === 'realty';
	},
	getName() {
		const key = this.isRealty()
			? 'ownerName'
			: 'realtyName';
		return this.model.get(key);
	},
	bottomText: v => v?.model?.attributes?.actualize?.lastEmployeeName || '&mdash;'
});

export const OwnerInRealtyListItemView = UiMoleculeView.extend({
	thisClassName: 'owner-in-realty-list-item',
	classNames: [
		v => 'for-' + v.getOption('modelType')
	],
	childView: AtomView,
	childViewOptions() {
		return {
			modelType: this.getOption('modelType')
		}
	},
	leftButtons:[
		'button:card'
	],
	content:[
		{
			thisClassName: 'item-name',
			text: v => v.getName(),
			topText: 'название'
		}
	],
	rightItems: [
		{
			class: UiAtomView,
			thisClassName: 'objects-count',
			text: v => v.model.get('count'),
			topText: 'площадей',
			// clickAction() {
			// 	console.log('chpok');
			// }
		},
		{
			class: UiAtomView,
			thisClassName: 'contacts-count',
			text: v => {
				const count = v.model.get('realtyContacts') || 0;
				if (count > 0) {
					return count;
				}
				return 'не установлены';
			},
			topText: 'контакты для здания',
			clickAction() {
				const { realtyId, ownerId } = this.model.attributes;
				realtyOwnerContactsModal(realtyId, ownerId);
				//console.log('chpok', this.model.id);
			}
		},		
	],
	childViewEvents() {
		return {
			'card:click'(view, event) {
				console.log(event);
				this.openCard(event)
			}
		}
	},
	openCard(event) {
		const isRealty = this.getOption('modelType') === 'realty';
		
		const listType = isRealty ? 'owners' : 'realties';
		const idKey = isRealty ? 'ownerId' : 'realtyId';
		const id = this.model.get(idKey);
		const url = appCfg.urls.url(`actualization/${listType}-list/${id}/edit`);
		console.log('# url', url);
		smartOpen(url, event);
	}
});