import Bus from 'bus/core';
import { $ } from 'vendors';
import { svcAuthPromise, newToken } from 'mods/acc';

function mixinMethods(WS, hubId, channel) {

	const hubClientMixin = {
		modelAdd: function (name, model, options) {
			console.log('ADD ->', arguments);
			Bus.trigger(channel, name + ':add', model, options);
			Bus.trigger(channel, name, 'add', model, options);
		},
		modelChange: function(name, model, options) {
			//console.log('options>', options);
			console.log('change ->', arguments);
			if (model.id) {
				Bus.trigger(channel, name + ':' + model.id + ':change', model, options);
				Bus.trigger(channel, name + ':' + model.id, 'change', model, options);
			}
			Bus.trigger(channel, name + ':change', model, options);
			Bus.trigger(channel, name, 'change', model, options);
		},
		modelRemove: function (name, model, options) {
			console.log('remove ->', arguments);
			if (model.id) {
				Bus.trigger(channel, name + ':' + model.id + ':remove', model, options);
				Bus.trigger(channel, name + ':' + model.id, 'remove', model, options);
			}
			Bus.trigger(channel, name + ':remove', model, options);
			Bus.trigger(channel, name, 'remove', model, options);
		},
		dataMessage: function(msg) {
			WS.trigger('data:message', msg);
			if (msg.data && msg.name) {
				var event = msg.name;
				WS.trigger(event, msg.data, msg);
				if (msg.data.type) {
					WS.trigger(event + ':' + msg.data.type, msg.data, msg);
				}
			}
			//console.warn('socket >', msg);
		}
	}
	
	const hubServerMixin = {
		_invokeMethod(socketMethod) {
			return this._ensureReady.then(() => {
				return this.reconnect().then(
					() => socketMethod(),
					err => { 
						console.error('socket error', err);
						return Promise.reject(err);
					}
				);
				// try {
				// 	const res = socketMethod();
				// 	return res;
				// } catch(e) {
				// 	WS.start().then(
				// 		() => {
				// 			try {
				// 				const res = socketMethod();
				// 				return res;
				// 			} catch(e) {
				// 				console.error('after reconnect try');
				// 				console.error(e);
				// 			}
				// 		}, 
				// 		(err) => {
				// 			console.error('on reconnect try');
				// 			console.error(err)
				// 		}
				// 	);
				// }
			});
		},
		joinGroup(group) {
			const socketMethod = this._hub.server.joinGroup.bind(this._hub.server.joinGroup, group);
			return this._invokeMethod(socketMethod);
		},
		leaveGroup(group) {
			const socketMethod = this._hub.server.leaveGroup.bind(this._hub.server.joinGroup, group);
			return this._invokeMethod(socketMethod);
		},
		isConnected () {
			//console.log('-socket:state-', this._conn.state, $.signalR.connectionState.connected);
			return this._conn.state === $.signalR.connectionState.connected;
		},
		isDisconnected () {
			//console.log('-socket:state-', this._conn.state, $.signalR.connectionState.disconnected);
			return this._conn.state === $.signalR.connectionState.disconnected;
		},
		reconnect() {
			if (this.isConnected()) {
				return Promise.resolve();
			}
			return this.start();
		}
	}

	const wsApiHub = $.connection[hubId];
	WS._hub = wsApiHub;
	WS._conn = $.connection.hub;

	_.extend(wsApiHub.client, hubClientMixin);
	_.extend(WS, hubServerMixin);

	return wsApiHub;

}

const connectionMixin = {
	// error() {
	// 	console.error('-socket:error-', arguments);
	// },
	connectionSlow: (...args) => console.error('socket connection slow'),
	reconnecting: (...args) => console.error('socket reconnecting'),
	reconnected: (...args) => console.error('socket reconnected'),
	disconnected: (...args) => console.error('socket disconnected'),
}

export function getOldInitializeMethod(WS, channel, hubId, hubUrl, identity) {
	return () => {
		
		if (WS._initialized) {
			return WS;
		}
		WS._initialized = true;
	
	
		const wsApiHub = mixinMethods(WS, hubId, channel);
	
	
		$.connection.transports._logic.ajax = function (connection, options) {
			var tokenValue = newToken.get('access_token');
				// Bus.channel('identity-token').request('value');
			var ajaxOptions = $.extend(true, {}, $.signalR.ajaxDefaults, {
				type: "GET",
				data: {},
				//xhrFields: { withCredentials: connection.withCredentials },
				contentType: connection.contentType,
				dataType: connection.ajaxDataType
			}, options);
			ajaxOptions.headers || (ajaxOptions.headers = {});
	
			if (tokenValue) {
				ajaxOptions.beforeSend = function (request) {
					//console.log('CHECK ME', request);
					request.setRequestHeader("Authorization", null);
				};
			}
	
			return $.ajax(ajaxOptions);
		}
	
		Object.keys(connectionMixin).forEach(methodName => {
			const method = connectionMixin[methodName];
			$.connection.hub[methodName].call($.connection, method);
		});

		WS._startHubTask = () => {
			WS.isConnected();
			WS.isDisconnected();
			return $.connection.hub.start().then(function() {
				const message = WS._started ? 'restarted' : 'started';
				WS._started = true;
				addConnectionIdToHeaders(wsApiHub.connection.id);
				console.warn('old sockets:', message);
				WS.isConnected();
				WS.isDisconnected();
	
			}, (xhr) => {
				console.error('old sockets:', 'start failed', xhr);
				return xhr;
			});
		}
	
	
		let ensure = svcAuthPromise.then(() => {
			console.warn('loggedSvcPromise SETTLED');
			$.connection.hub.qs = { 
				'said': identity.get('currentService').id, 
				token: newToken.get('access_token')
				 // Bus.channel('identity-token').request('value') 
			}
			$.connection.hub.url = hubUrl;
			return WS.start();
			// $.connection.hub.start().then(function() {
			// 	console.warn('old sockets:', 'started', wsApiHub.start);
			// 	addConnectionIdToHeaders(wsApiHub.connection.id);
			// }, (xhr) => {
			// 	console.error('old sockets:', 'start failed', xhr);
			// 	return xhr;
			// });
		});
	
		WS._ensureReady = ensure;
	
		return WS;
		

	}
}

function addConnectionIdToHeaders(id) {
	$.ajaxSetup({
		headers: {
			'wscid': id,
		}
	});
}