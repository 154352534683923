import './edit-address-container.less';

//import gmaps from '_libs//gmaps';
import { mapsApi, buildSearchControl } from 'apis/mapsApi';
import { MapContainerView } from 'apis/mapsApi/MapContainerView';
// import geoModel from 'vXX/geo/reference/model';
// import mapPoints from 'vXX/geo/reference/mapPoints';

import geodecode from './geodecode';

import { editAddressModal } from './address-schema';

import { BbeModel } from 'core/bbe';
import { Model } from 'core';
// import { MnView } from 'vendors';

import { MneView } from 'core/mne';

import { setupControlsMixin } from 'mapApi/google/setupControls-mixin'
import { SearchRealtyControl, NewSearchPlaceControl } from 'ui/controls/map/control-input';

import { modalsApi } from 'apis/modalsApi2';
import { MnObject } from 'vendors';

const defaultMapOptions = {
	zoom: 11,
	center: { lat: 55.745829121494936, lng: 37.60493597412108 },
}
/*
const MapView = MnView.extend({
    template: false,
    onAttach() {
        this.createMap();
    },
    onRender() {
    },
    createMap() {
        if (this.map) return;

        let options = this.getOption('mapOptions') || defaultMapOptions;
        this.map = new google.maps.Map(this.el, options);

    }
});
*/
function createMap(el, options) {
    return new google.maps.Map(el, options || defaultMapOptions)
}


const AddressInfo = MneView.extend({
    className: 'wrapper',
    template: _.template(`<div class="full-address"><%= fullAddress %></div>
<div class="clean"><span><%= lat %></span><span><%= lng %></span></div>
<div class="buttons">
    <% if (state == 'iddle') { %>
        <button class="edit-pin" title="Можно изменить только координату или координату и текстовый адрес"><i class="fa fa-pencil-square-o"></i> изменить </button>
        <button class="edit-fields" title="Для исправления текстового адреса, координата не меняется"><i class="fa fa-pencil-square-o"></i> исправить </button>
    <% } %>
    <% if (state == 'editpin') { %>
        <ul class="button-info">
        <li><label>Перетаскивайте пин удерживая левую кнопку мыши.</label></li>
        <li><label>Клик левой кнопки мыши установит пин в выбранное место.</label></li>
        <li><label>После текстового поиска пин установится в найденное место.</label></li>
        <li class="clickable"><label><input type="checkbox" class="only-coords-checkbox" <%= onlycoordsChecked%>> изменить только пин, не меняя текстовый адрес.</label></li>
        </ul>
        <div>
            <button class="apply-edit-pin btn-primary"><i class="fa fa-check-square-o"></i> сохранить</button>
            <button class="cancel cancel-edit-pin">отмена</button>
        </div>
    <% } %>
</div>
    `),
		templateContext() {
			const onlycoordsChecked = this.model.get('onlycoords') ? ' checked="checked"' : '';
			return {
				onlycoordsChecked
			}
		},
    ___templateContextExtend() {
        let a = this.model.attributes.address;

        return {
            fullAddress: a.fullAddress,
            lat: a.lat,
            lng: a.lng
        }
    },
    modelEvents: {
			'change': 'render'
    },

    events: {
        'click .edit-pin'(event) {

            let state = this.model.get('state');
            if (state !== 'iddle') return;

            this.triggerMethod('pin:edit:start');
            //this.model.set('state', 'editpin');

        },
        'click .cancel-edit-pin'(event) {

					this.triggerMethod('pin:restore');
					this.triggerMethod('pin:edit:stop');
            //this.model.set('state', 'iddle');
        },
        'click .apply-edit-pin'(event) {

            let state = this.model.get('state');
            if (state !== 'editpin') return;

						const onlyCoords = this.model.get('onlycoords');
						//this.$('.only-coords-checkbox').is(':checked');

						this.triggerMethod('pin:edit:apply', onlyCoords);
            this.triggerMethod('pin:edit:stop');
            //this.model.set('state', 'editpin');

        },
				'change input.only-coords-checkbox'(e) {
					this.model.set('onlycoords', !!e.target.checked);
				},
        'click .edit-fields'(event) {
            this.triggerMethod('edit:address:click');
        },
    },

		onRender() {
			console.error('--- info render ---');
		}
});


const AddressChanges = Model.extend({
	apply(attrs, onSuccess, errorMessage) {
		this.showPreloader();
		return this.save(attrs, { wait: true, method: 'post' })
			.then(
				data => this._onSuccess(data, onSuccess),
				err => this._onFail(err, errorMessage)
			)
	},
	_onSuccess(data, onSuccess) {
		if (typeof onSuccess === 'function') {
			onSuccess(this.attributes);
		}
		this.destroyPreloader();
	},
	_onFail(err, message) {
		this.destroyPreloader();
		modalsApi.error(message, err);
	},
	showPreloader() { },
	destroyPreloader() { },
});


function normalizeAddress(model) {
	let isUnnamed = (model.route || 'unnamed road').toLowerCase() == 'unnamed road';
	let noLocality = (model.fullAddress || '').toLowerCase().indexOf((model.locality || '').toLowerCase()) == -1;
	if (isUnnamed && noLocality) {
		var a = [model.locality, model.area2, model.area1, model.country];
		var result = a.filter(t => !!t).join(', ');
		model.fullAddress = result;
	}
	let addrr = _.pick(model, 'fullAddress', 'lat', 'lng', 'route', 'house');
	addrr.gd = _.omit(model, 'fullAddress', 'lat', 'lng');
	console.log(' > ADDRESS > ', addrr, model);
	return addrr;
}


export default MneView.extend({
    className: 'edit-address-container map-container',
    template: _.template('<div class="map-holder">... подождите карта инициализируется ...</div><div class="address-info"></div><div class="controls-container"></div>'),
    regions: {
			map: '.map-holder',
			info: '.address-info',
			search: '.controls-container'
    },
    ui: {
			map: '.map-holder'
    },
    initialize() {
			this.realty = this.model;
			this.model = new BbeModel();
			this.initializeModel();
    },
    initializeModel() {
			let a = this.realty.attributes.address;
			this.model.set({
				state: 'iddle',
				fullAddress: a.fullAddress,
				lat: a.lat,
				lng: a.lng
			});
    },
    onRender() {
			this.showMap();
			this.showInfo();
			this.showSearch();
    },
		showSearch() {
			this.searchView = new NewSearchPlaceControl();
			this.showChildView('search', this.searchView)
		},
		initLibrary() {
        // return Promise.all([
        //     // gmaps.initialize(),
				// 		// mapsApi.initialize(),
        //     // geoModel.populate(),
        //     // mapPoints.populate()
        // ]);
			return mapsApi.whenReady();
    },

		async showMap () {
			const mapRes = await this.initLibrary();
			if (!mapRes.ok) {
				console.error(mapRes.error);
				return;
			}

			const coords = this.getRealtyCoords();

			const view = new MapContainerView({
				baseClassName: 'map-holder',
				zoom: 17,
				center: coords,
				onMapAttach: map => this._afterMapAttach(map),
			});
			this.showChildView('map', view, { replaceElement: true });
		},
		_afterMapAttach(map) {
			this.map = map;
			const coords = this.getRealtyCoords();
			// const marker = this.addressPin = mapsApi.createMarker({ coords });
			// mapsApi.showMarker(map, marker);
			//const { controlEl, inputEl } = buildSearchControl();
			const inputEl = this.searchView.inputEl;
			// mapsApi.setupSearchInput(map, inputEl, { controlEl });

			const onPositionChange = hash => {
				const { lat, lng, fullAddress } = hash;
				if (this.model.get('onlycoords')) {
					this.model.set({ lat, lng });
				} else {
					this.model.set({ lat, lng, fullAddress });
				}
				this._addressHash = hash;
			}

			const bus = new MnObject();
			bus.listenTo(this.model, 'change:state', () => bus.trigger('change'));

			const context = mapsApi.setupAddressApi(map, {
				searchControl: {
					inputEl: inputEl,
					//controlEl: controlEl,
					updateMarker: () => {
						console.log('marker update by search allowed ?')
						return this.model.get('state') === 'editpin';
					},
				},
				marker: {
					updateAllowed: () => {
						console.log('marker update allowed ?')
						return this.model.get('state') === 'editpin'
					},
					isDraggable: () => this.model.get('state') === 'editpin',
					updatePositionOnMapClick: () => this.model.get('state') === 'editpin',
					updatePositionOnDragend: () => this.model.get('state') === 'editpin',
					lat: coords.lat,
					lng: coords.lng,
					changeBus: bus,
					onPositionChange
					// onPositionChange: hash => this.updateAddressForm(hash)
				},
			});
			this.addressPin = context.addressPin;
		},

		getRealtyCoords() {
			let { lat, lng } = this.realty.attributes.address;
			let coords = { lat, lng };
			return coords;
		},

		getShortAddress() {
			let { lat, lng, fullAddress } = this.realty.attributes.address;
			let shorts = { lat, lng, fullAddress };
			return shorts;
		},

		async showMapPrev() {
			let addrr = this.realty.attributes.address;
			let point = { lat: addrr.lat, lng: addrr.lng };

			const mapRes = await this.initLibrary();
			if (!mapRes.ok) {
				console.error(mapRes.error);
				return;
			}

			// let mapView = new MapView();
			// this.showChildView('map', mapView);
			let options = {
				clickableIcons: false,
				disableDefaultUI: true,
				zoom: 18,
				center: point
			}

			let map = this.map = createMap(this.ui.map.get(0), options);
			map.setOptions({ disableDoubleClickZoom: true });
			this.buildingRadiusCircle = new google.maps.Circle({
				strokeColor: '#FFFFFF',
				strokeOpacity: 0.9,
				strokeWeight: 2,
				fillColor: '#FF00FF',
				fillOpacity: 0.07,
				map: map,
				center: point,
				radius: 1000,
			});


			this.addressPin = new google.maps.Marker({
				position: point,
				map
			});
			let view = this;
			let model = this.model;

			const update = (latlng) => {

				model.set({
					lat: latlng.lat(),
					lng: latlng.lng()
				});
				view.updateMapObjects();

			}

			this.addressPin.addListener('dragend', function () {
				var latlng = this.getPosition();
				update(latlng);
			});

			this.addControlSearchPlace({
				onSearchPlaceMarkerClick: (marker) => {
					let latlng = marker.getPosition();
					marker.setMap(null);
					if (model.get('state') === 'editpin') {
						this.addressPin.setPosition(latlng);
						update(latlng);
					}
				}
			});

			const dblclick = function (obj) {
				if (model.get('state') !== 'editpin') { return; }
				update(obj.latLng);
			}
			map.addListener('dblclick', dblclick);

    },

    showInfo() {
			let view = new AddressInfo({ model: this.model, realty: this.realty });
			this.showChildView('info', view);
    },
    childViewEvents: {
			'pin:edit:start'() {
				delete this._addressHash;
				delete this._modelBackup;

				this.model.set('state', 'editpin');
				const { lat, lng, fullAddress } = this.model.attributes;
				this._modelBackup = { lat, lng, fullAddress };
				
				mapsApi.updateMarker(this.map, this.addressPin, { draggabale: true });

			},
			'pin:edit:stop'() {
				
				mapsApi.updateMarker(this.map, this.addressPin, { draggabale: false });
				this.model.set('state', 'iddle');

			},
			'pin:edit:apply'(onlyCoords) {

				let { lat, lng } = this.model.attributes;

				if (onlyCoords) {
					this.editAddress({ lat, lng }, { onlyCoords, rollback: true });
					return;
				} else if (this._addressHash) {
					this.editAddress(normalizeAddress(this._addressHash), { rollback: true });
					return;
				}

				//DEPRECATED
				// geodecode(lat, lng, model => {
				// 	const addrr = normalizeAddress(model)
				// 	this.editAddress(addrr);
				// });
			},
			'pin:restore'() {
				this.restorePinAfterCancel();
			},
			'edit:address:click'() {
				this.editAddress(this.realty.get('address'));
			}
    },
		restorePinAfterCancel() {

			const { lat, lng } = this._modelBackup;
			const coords = { lat, lng };
			mapsApi.updateMarker(this.map, this.addressPin, { draggabale: false, coords });
			this.model.set(this._modelBackup);
		},
    editAddress(addrr, { onlyCoords, rollback } = {}) {

			let realty = this.realty;

			let changes = new AddressChanges();
			changes.url = realty.url() + '/change-address';

			if (onlyCoords) {
				const attrs = {
					...addrr, onlyCoords
				}
				changes.apply(attrs, attrs => this.updateAddress(attrs), 'не удалось изменить координаты');
				return;
			}

			let valuesModel = new BbeModel(addrr);
			editAddressModal({
				valuesModel,
				beforeApply: data => {
					let tmp = _.pick(data.gd, 'house', 'route');
					_.extend(data, tmp);
					changes.apply(data, attrs => this.updateAddress(attrs), 'не удалось изменить адрес');
				}
			}).then(res => {
				// console.log('RES', res, this._modelBackup);
				if (!res.ok && rollback) {
					this.restorePinAfterCancel();
				}
			});
    },
    updateAddress(data, mapObjectsOptions) {

			this.realty.set('address', data);

			this.initializeModel();

			this.showInfo();

			this.updateMapObjects(mapObjectsOptions);

		},
    updateMapObjects({ nopin, noradius } = {}) {
			if (nopin) { return; }
			const { lat, lng } = this.model.attributes;
			const coords = { lat, lng };

			mapsApi.updateMarker(this.map, this.addressPin, { coords });

    },
    ...setupControlsMixin,
});