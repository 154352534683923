﻿//define('ui-controls-blockbutton', ['bus'], function (Bus) {});

import Bus from 'bus';
import template from './control.html';
import { MneView } from 'core/mne';

var View = MneView.extend({
	className: function () {
		const attrs = (this.model.get('attrs') || {});
		return 'block block-card subdivision-item ' + (attrs.class || '');
	},
	//template: '#tmpl-ui-controls-blockbutton-control',
	template,
	events: {
		'click': function () {
			var url = this.model.get('href');
			var skip = this.model.get('skipRoute');
			if (!skip)
				Bus.app.trigger('navigate', url);
			else
				document.location.href = url;
		}
	},
	attributes: function () {
		var hash = { 'data-url': this.model.get('href'), };
		return hash;
	},
	templateContextExtend: function () {
		return { skipRoute: this.model.get('skipRoute') === true ? ' data-skiproute="true"' : '', label: this.model.get('label') };
	}
});

export default View;
