import { Model, Collection } from 'core';
import { urls } from 'coms/urls';
import { _ } from 'vendors'

export const GeoReference = Model.extend({

	defaultRegionId: '57c0fa45-e991-4aab-82bb-daeedf4f7d1e',

	initialize() {
		this.on('sync', () => this.parse(this.attributes));
	},

	parse(data) {
		const options = { merge: true, add: true, remove: false };
		Object.keys(data).forEach(key => {
			if (!this[key]) {
				this[key] = new Collection();
			}
			this[key].set(data[key] || [], options);
		});
		return data;
	},

	url() {
		let url = urls.api('v4/geo/reference');
		if (this._lastFetch) {
			const lf = new Date(this._lastFetch);
			url = url + '?modified.from=' + lf.toJSON()
		}
		return url;
	},

	refetch() {
		return this.fetch({ merge: true, add: true, remove: false, parse: true });
	},

	getLines(options) {
		let regionId;
		let raw;
		if (!options) {
			regionId = this.defaultRegionId;
		} else if (typeof options !== 'object') {
			regionId = options;
		} else {
			regionId = options.regionId || this.defaultRegionId;
			raw = options.raw;
		}
		
		let items = this.metroLines.filter(f => f.get('regionId') === regionId);
		if (raw) {
			items = items.map(item => item.attributes);
		}
		return items;
	},

	getStations(options) {
		let regionId;
		let lineId;
		let raw;
		if (!options) {
			regionId = this.defaultRegionId;
		} else if (typeof options !== 'object') {
			regionId = options;
		} else {
			regionId = options.regionId || this.defaultRegionId;
			lineId = options.lineId;
			raw = options.raw;
		}

		const col = this.metroStations;
		let items = [];
		if (lineId) {
			items = col.filter(f => f.get('lineId') === lineId);
			items = _.sortBy(items, model => model.get('orderInLine'));
			
		} else {
			items = this.metroStations.filter(f => f.get('regionId') === regionId);
		}
		if (raw) {
			items = items.map(item => item.attributes);
		}
		return items;
	},




});