﻿

//define('assets-identity-token', ['assets-config', 'server-cfg', 'bus-app', '_libs/appleCookieStore'], function (cfg, srv, busApp, acs) {});


import cfg from '../config'; // 'assets-config'

import srv from 'server-cfg'; // alias

import busApp from 'bus/app'; // alias

import acs from '../../v01/_libs/appleCookieStore';

import { MnObject } from 'vendors';

var nativeAjax = $.ajax;


var Token = MnObject.extend({
	channelName: cfg.radios.token,
	radioRequests: {
		'value': function () {
			return this.get();
		},
		'object': function () {
			return this;
		}
	},
	initialize: function () {
		//console.log('token', this);
		this.renewUrl = this.getOption('url');
		this.setHash(this.getOption('data'));
		this.addAjaxHeaders();
	},
	addAjaxHeaders: function () {
		var tokenValue = this.get();
		//console.log('token value', tokenValue);
		$.ajaxSetup({
			headers: {
				'Authorization': 'Bearer ' + tokenValue,
				'Accept': "application/json"
			}
		});
	},
	setHash: function (data) {
		this.data = data || {};
		this.token = (data || {}).token;

		acs.set(this.token);

		this.expires = Date.info(this.data.expires).date;
		this.issued = Date.info(this.data.issued).date;
		this.dateOffset = this.issued && (Date.now() - this.issued.valueOf());

	},
	_setExpires: function(date) {
		this.expires = Date.info(date).date;
	},
	_setIssued: function(date) {
		this.issued = Date.info(date).date;
	},
	checkExpire: function() {

		//var expires = Date.info((this.data || {}).expires);
		if (this.expires == null) {
			console.warn('token expires is null');
			return;
		}

		var expiresInfo = Date.info(new Date(this.expires.valueOf() + this.dateOffset));
		if (expiresInfo.dateInvalid) {
			console.warn('token expiration date missing');
			return;
		}

		//var offset = (Date.MINUTE * 5);
		//offset = 0;

		//var exp = expiresInfo.date;
		//var deadline = exp.valueOf(); // - offset;
		
		//var now = Date.now();
		//var deadlineMs = deadline - now;			
		//var res = deadlineMs > 0 ? deadlineMs / Date.SECOND : false;

		//console.log('check expire', res, expiresInfo);
		if (expiresInfo.when == 'future')
			return expiresInfo;
		else
			return false;

		//return res;
	},
	get: function () {
		//console.log(this);
		return (this.data || {}).token;
	},

	// renew: function () {
	// 	if (this.refetching === true) return this.promise;

	// 	var promise = this.promise = $.Deferred();

	// 	var token = this;

	//     if (this.checkExpire() === true)
	//         promise.resolve();
	// 	else if (this.checkExpire() === false) {
	// 		token.refetching = true;
	// 		var refetch = this.refetch().then(function () {
	// 			console.log('token expires -- success');
	// 			token.renew(promise);
	// 		}, function () {
	// 			console.log('token expires -- fail');
	// 			acs.set(null);
	// 			promise.reject();
	// 		}).always(function () {
	// 			token.refetching = false;
	//         });
	// 		return refetch;
	// 	}
	//     else {
	// 		acs.set(null);
	// 		promise.reject();
	// 	}

	// 	return promise;
	// },

	fetch: function () {
		var token = this;
		var url = token.renewUrl
		if (!token.fetching) {
			token.fetching = true;
			token.fetchPromise = nativeAjax({
				url: url,
				dataType:'json',
			}).then(function (data) {
				token.setHash(data);
				token.addAjaxHeaders();
				token.fetching = false;
			}, function () {
				token.fetching = false;
			});
		}

		return token.fetchPromise;
	},

	authAjax: function () {

		var baseargs = arguments;
		var result = this.checkExpire();
		if (result && result.absolute.seconds > 60) {
			//console.log('token ok');
			return nativeAjax.apply($, baseargs);
		}
		else {
			//console.log('token expired', result && result.absolute && result.absolute.minutes);
			//var _this = this;
			var promise = $.Deferred();
			this.fetch().then(function () {
				//console.log('new token info', Date.info(_this.expires));
				setTimeout(function () {
					var xhr = nativeAjax.apply($, baseargs).then(function (data , status, xhr) {
						promise.resolveWith(xhr, arguments);
					}, function () {
						promise.rejectWith(xhr, arguments);
					});
					_.extend(promise, xhr);
				}, 50);
			});
			return promise;
		}
	}
});




Token.create = function (url, hash) {
	if (Token.exists) {
		console.error('Token already exists');
		return;
	}
	Token.exists = true;
	var token = new Token({ url: url, data: hash });
	$.ajax = function () {
		return token.authAjax.apply(token, arguments);
	};
	console.warn('ajax changed');
	return token;
}

Token.init = function(url) {
	let data;
	if (srv.isNative && srv.svcWebId) {
		data = {
			svcWebId: srv.svcWebId
		}
	} else if (!srv.isNative && !srv.svcWebId) {
		data = {
			byHostName: true
		}
	}
	return new Promise((resTok, rejTok) => {
		console.error('#token-init#');
		$.ajax(url, {
			method: 'POST',
			data: JSON.stringify(data),
			contentType: 'application/json',
			xhrFields: {
				withCredentials: true
			},
		}).then(data => {
			if (data && data.svc) {
				busApp.reply('svc', data.svc);
			}
			console.log('TOKEN HAS SVC', data.svc);
			busApp.trigger('svc:config', data.svc);
			if (_.size(data.token)) {
				Token.create(url, data.token);
				busApp.reply('token-exists', true);
			}
			resTok();
			console.log('	> prom: Token.init resolved', data);
		}, (xhr) => {
			rejTok();
			console.error('	> prom: Token.init rejected', xhr);
		});
	});
	
}

Token.new = function(url, isNative, svcWebId) {
	let data = {};
	if (isNative && svcWebId) {
		data = { svcWebId };
	} else if (!isNative) {
		data = { byHostName: true };
	}

	if (data) {
		data = JSON.stringify(data);
	}
	let promise = new Promise((res, rej) => {

		console.error('#-token-#');
		let headers = acs.getHeaders();
		$.ajax(url, {
			method: 'POST',
			data,
			contentType: 'application/json',
			xhrFields: {
				withCredentials: true,
			},
			headers
		}).then(data => {
			let tokenModel;
			console.error('#-token-model-#', data);
			if (!data) {
				console.error('token response incorect');
				rej('invalid response');
			}

			busApp.reply('svc', data.svc);

			busApp.trigger('svc:config', data.svc);

			if (data.token) {
				tokenModel = Token.create(url + '-renew', data.token);
			}

			res(tokenModel);
			busApp.trigger('token:initialized', tokenModel);

		}, (xhr) => {
			acs.set(null);
			console.error('token initialization fail', xhr);
			rej(xhr);
		});
	});
	return promise.catch(() => {});
}

//var token = new Token();
export default Token;