module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="sys">id:<code>'+
((__t=( _m.wId))==null?'':__t)+
'</code></div>\n<div class="sys header">процессы</div>\n<div class="deals-region"></div>\n<div class="sys header">заметка</div>\n<div class="note">'+
((__t=( _m.note ))==null?'':__t)+
'</div>\n<div class="sys created">'+
((__t=( _tfn(_m.created) ))==null?'':__t)+
'</div>\n<div class="sys"><code>'+
((__t=( _m.id))==null?'':__t)+
'</code></div>';
}
return __p;
};
