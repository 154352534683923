module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h3>'+
((__t=(header))==null?'':__t)+
'</h3>\n<div>'+
((__t=(text))==null?'':__t)+
'</div>';
}
return __p;
};
