

export const knownBounds = {
	moscow: {
		"south": 55.543948393452496,
		"west": 37.21663793945311,
		"north": 55.946670672558355,
		"east": 37.993234008789045
	},
	get(key) {
		let bounds = this[key];
		if (!bounds) { return; }
		if (bounds instanceof google.maps.LatLngBounds === false) {
			const sw = {
				lat: bounds.south,
				lng: bounds.west,
			}
			const ne = {
				lat: bounds.north,
				lng: bounds.east,
			}
			bounds = this[key] = new google.maps.LatLngBounds(sw, ne);
		}
		return bounds;
	}
}