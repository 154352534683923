import { 
    computePosition as baseComputePosition,
    autoUpdate,
    flip,
    shift,
    offset,
    arrow,
    autoPlacement as autoPlacementMw
} from '@floating-ui/dom';

// const referenceElement = document.querySelector('#button');
// const floatingElement = document.querySelector('#tooltip');

function applyStyles(el, { x = 0, y = 0, strategy = 'absolute' }) {
    Object.assign(el.style, {
        position: strategy,
        left: `${x}px`,
        top: `${y}px`,
    });
}

function buildMiddlewares(popupOptions = {}) {
    let middlewares = [];
    let { useFlip, popupOffset = 2, useShift, popupShift, arrowEl, autoPlacement } = popupOptions;

    if (popupOffset != null) {
        middlewares.push(offset(popupOffset));
    }

    if (useFlip) {
        middlewares.push(flip());
    }

    if (useShift || popupShift) {
        let mw = popupShift == null ? shift() : shift(popupShift);
        middlewares.push(mw);
    }

    if (arrowEl) {
        middlewares.push(arrow({ element: arrowEl }));
    }

    if (autoPlacement) {
        if (!useFlip) {
            middlewares.push(autoPlacementMw(autoPlacement));
        } else {
            console.warn('floatui: flip can not be used with autoPlacement');
        }
    }

    return middlewares.length ? middlewares : undefined;
}

const staticSides = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
}

const axises = {
    top: 'y',
    bottom: 'y',
    left: 'x',
    right: 'x'
}

const axisSides = {
    x: ['left','right'],
    y: ['top', 'bottom']
}

function toPx(arg) {
    if (arg == null) {
        return '';
    }
    return arg + 'px';
}

function getAxisSide(axis, placementSide) {
    let sides = axisSides[axis];
    let index = placementSide === 'end' ? 1 : 0;
    return sides[index];
}

function getNormalizedStyle(value, axis, placement, placementSide, staticSide) {
    let disallowedAxis = axises[staticSide];
    if (axis === disallowedAxis) { return; }
    if (value === 0) {
        value = 10;
    }
    let pxValue = toPx(value);
    let side = getAxisSide(axis, placementSide);
    return { key: side, value: pxValue }
}

function applyArrow(el, data, position, options) {

    let [placement, placementSide = 'center'] = position.placement.split('-');

    const staticSide = staticSides[placement];



    //let {x: arrowX, y: arrowY} = data;

    let { x, y } = data;

    let styleX = getNormalizedStyle(x, 'x', placement, placementSide, staticSide);
    let styleY = getNormalizedStyle(y, 'y', placement, placementSide, staticSide);
    
    let valuedStyle = styleX || styleY;

    let newStyle = {
        [valuedStyle.key] : valuedStyle.value,
        // [styleX.key]: styleX.value,
        // [styleY.key]: styleY.value,
        // left, top,
        // left: arrowX != null ? `${arrowX}px` : '',
        // top: arrowY != null ? `${arrowY}px` : '',
        // right: '',
        // bottom: '',
        [staticSide]: '-4px',
    }


    //console.log('-arrow-', placement, newStyle, data, position, options);

    Object.assign(el.style, newStyle);
}

export function computePosition(referenceElement, floatingElement, popupOptions = {}) {

    let middleware = buildMiddlewares(popupOptions);
    let arrowEl = popupOptions.arrowEl;

    let options = Object.assign({
        placement: 'right',
        middleware
      }, popupOptions);
      
      //console.log('-floatingui-', options);

    return baseComputePosition(referenceElement, floatingElement, options).then(position => {

        applyStyles(floatingElement, position);

        if (position.middlewareData) {
            let data = position.middlewareData;
            if (data.arrow && arrowEl) {
                applyArrow(arrowEl, data.arrow, position, options);
            }
        }
    });
}

export function enableAutoUpdate(referenceEl, floatingEl, options) {

    const update = async () => await computePosition(referenceEl, floatingEl, options);
    const cleanup = autoUpdate(referenceEl, floatingEl, update);
    return cleanup;
}