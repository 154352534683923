import { View } from 'core';
import { _ } from 'vendors';

export const SearchView = View.extend({
	constructor: function() {
		this.handleInput = _.debounce(() => this._onInputEnd(), 300);
		View.apply(this, arguments);

	},
	className: 'select-search',
	template: `<span class="control"><input/></span><span class="icon"><i></i></span>`,
	events: {
		'input input':'handleInput',
		'focus input'(e) {
			this.$('input').select();
		}
	},
	onAttach() {
		this.$('input').focus();
	},
	_onInputEnd() {
		const value = this.$('input').val();
		this.trigger('search:change', value);
	}
});