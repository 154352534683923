﻿//define('appModule-layout', ['appModule-header', 'appModule-navView'], function (HeaderView, NavView) {});

import HeaderView from './header_view';
import NavView from './nav_view';

import template from './layout.html';

import { BbeCollection } from 'core/bbe';

import { AppModule } from './appModule';

import { MneView } from 'core/mne';

import { MnView } from 'vendors';

var Layout = MneView.extend({
	DBGNAME: 'module-layout',
	name: 'module-layout',
	className: 'module',
	tagName: 'section',
	template,
	templateContext() {
		const sectionClass = this.getOption('addSectionClass');
		const addSectionClass = sectionClass ? ` class="${sectionClass}"` : '';
		return {
			addSectionClass
		}
	},
	regions: {
		'header': { el: '> header', replaceElement: true },
		'content': { el: '> section', replaceElement: false },
		'nav': { el: '> nav', replaceElement: true },
	},
	onRender: function(){
		this.drawHeader();
		this.drawContent();
		this.drawNav();
		this.drawFooter();
		this.fixCssClass();
	},
	fixCssClass: function() {
		var css = this.getOption('addClass');
		if (css) {
			this.$el.attr({
				class: this.className + ' ' + css
			});
		}
	},
	drawHeader: function () {
		var header = this.getHeaderView();
		if (!header) {
			this.removeRegion('header');
			this.$('> header').remove();
			this.$el.addClass('no-header');
			return;
		} else {
			this.$el.removeClass('no-header');
			this.showChildView('header', header);
		}
	},
	drawContent: function(){
		var content = this.getContentView();
		if (!content) return;
		this.showChildView('content', content);
	},
	drawFooter: function() {
		var footer = this.getFooterView();
		if (!footer) return;
		var $el = $('<div/>');
		this.$el.append($el);
		let region = this.addRegion('footer', { el: $el.get(0), replaceElement: true });
		region.show(footer);
		//console.log('FOOTER', region);
	},
	drawNav: function() {

		this.$el.removeClass('module-with-nav');
		var nav = this.getNavView();
		if (!nav ) return;

		this.$el.addClass('module-with-nav');
		this.showChildView('nav', nav);

	},

	getNavView: function () {
		//console.log('**-', this.options);
		var module = this.getOption('module');
		var pmodule = module && module.parentModule;
		var ppmodule = pmodule && pmodule.parentModule;

		var navlinks = this.getOption('navLinks') || [];

		if (!navlinks && this.options.content.options.navLinks) {
			navlinks = _.result(this.options.content.options, 'navLinks') || [];
			//console.log(' --- NAV LINKS 1 === ', navlinks);
		}


		// if (!navlinks) {
		// 	navlinks = _.result(module, 'navLinks') || [];
		// 	console.log(' --- NAV LINKS 2 === ', navlinks);
		// }



		if (!navlinks.length && module) {
			navlinks = _.result(module, 'navLinks') || [];
			//console.log(' --- NAV LINKS 2 === ', navlinks);
		}

		if (!navlinks.length && pmodule) {
			navlinks = _.result(pmodule, 'navLinks') || [];
			//console.log(' --- NAV LINKS 3 === ', navlinks, pmodule);
		}

		if (!navlinks.length && ppmodule) {
			navlinks = _.result(ppmodule, 'navLinks') || [];
			//console.log(' --- NAV LINKS 4 === ', navlinks, ppmodule);
		}

		//console.log('--', navlinks, module, pmodule);

		var addLinks = this.getOption('addLinks');
		if (!addLinks)
			addLinks = _.result(module, 'addNavLinks') || [];

		//console.log(' --- NAV LINKS === ', navlinks, addLinks);

		if (!navlinks.length && !addLinks.length) return;


		var main = !!navlinks.length && new BbeCollection(navlinks);
		var add = !!addLinks.length && new BbeCollection(addLinks);

		var options = { mainLinks: main, addLinks: add, module: module };
		var nav = new NavView(options);

		return nav;


	},
	getHeaderView: function() {
		var header = this.getOption('header');
		if (!header || !(header.options.header || header.options.model || header.View))
			return;


		//var hobj = this.options.header || {};
		var options = header.options || {};
		options.module = this.getOption('module');
		var View = header.View || HeaderView;
		const view = new View(options);
		view.DBGNAME = 'main-header';
		return view;
	},
	getFooterView() {
		var footer = this.getOption('footer');
		if (!footer) return;
		var View = footer.View || MneView;
		var options = footer.options;
		return new View(options);
	},
	getContentView: function () {
		var obj = this.options.content || {};
		var options = obj.options || {};

		options.module = this.getOption('module');

		if (obj.View) {
			const view = new obj.View(options);
			// if (!view.DBGNAME) {
			// 	view.DBGNAME = 'main-content';
			// }
			return view;
		}
		if (obj.view) {
			// if (!view.DBGNAME) {
			// 	obj.view.DBGNAME = 'main-content';
			// }
			return obj.view;
		}
	}
});

Layout.smartCreate = function () {
	var options = {};

	var content = options.content = {};
	content.options = {};

	var args = [].slice.call(arguments);
	var _module = args[0];
	var module = undefined;
	if (_module instanceof AppModule) {
		module = args.shift();
		options.module = module;
	}

	var _view = args[0];
	if (_view instanceof MnView)
		content.view = _view;
	else if (typeof _view === 'function')
		content.View = _view;
	
	var _header = args[1] || module.label;
	options.header = HeaderView.createOptions(_header);
	if (module.getOption('noHeaderMenu') && options.header && options.header.options) {
		options.header.options.noHeaderMenu = true;
	}
	var _contentOptions = args[2];
	_.extend(content.options, _contentOptions);

	if (typeof args[3] === 'object')
		_.extend(options, args[3]);

	console.log('# CREATE OPTIONS', options);
	return this.create(options);

}

export default Layout;