import { View } from 'core';
import { HamburgerView } from 'coms/ui/HamburgerView';
import { BoxView } from 'coms/ui/Box';
import { getBuildingImagesCollection } from 'm/realties/buildings';
import { showImageGalery, ImageView } from 'coms/ImageGalery';

import './realty-box-thumb.less';
import './realty-name.less';
import { RealtyPropertiesView } from './properties-lines';
import { realtiesStore } from '../store';
import { modelSchema } from '../schema';
import { imageModelComparator } from 'coms/ImageGalery';
import { getSchema } from 'apis/schema';

import { AsyncModelBoxView, AsyncModelBoxWraperView } from 'coms/ui/Box';
import { AgentsListView } from './AgentsView';
import { CommonThumbView } from '../../common-views/CommonThumbView';

const RealtyImageView = ImageView.extend({
	imageHash: v => v.model.id,
	imageExt: 'jpg',
	imageOptions: 'w270-c-rbc',
	events: {
		click(e) {
			this.trigger('click', this.model);
		}
	},
})

const RealtyThumbView = HamburgerView.extend({
	thisClassName: 'realty-box-thumb',
	initialize() {
		this.initializeCollection();
	},
	initializeCollection() {
		this.collection = getBuildingImagesCollection(this.model.id);
		this.listenToOnce(this.collection, 'sync', () => {
			//this.collection.models.sort(imageModelComparator);
			this.render();
		});
	},
	getThumbView() {

		let thumbnail = this.model.get('thumbnail') || (this.collection.first() || {}).id;
		
		if (!thumbnail) { return }

		if (typeof thumbnail === 'string') {
			return {
				class: RealtyImageView,
				thisClassName: 'image',
				wrap: true,
				imageHash: thumbnail,
				triggers: { 'click': 'click' }
			}
		}

		return thumbnail;
	},
	getLabelView() {
		return {
			class: View,
			thisClassName: 'realty-box-thumb-label',
			template: '<%= label %>',
			triggers: { 'click': 'click' },
			collectionEvents: {
				'sync':'render'
			},
			templateContext() {
				let len = this.collection.length;
				let label = 'фотографии отсутствуют';
				if (len) {
					label = `посмотреть фотографии (${len})`;
				}
				return {
					label
				}
			}
		}
	},
	getChildren() {
		let thumbView = this.getThumbView();
		let labelView = this.getLabelView();
		let views = [
			thumbView,
			labelView
		];
		return views;
	},
	childViewEvents() {
		return {
			click() {
				if (this.collection.length) {
					showImageGalery(this.collection);
				}
			}
		}
	}
});

const RealtyNameView = HamburgerView.extend({
    baseClassName: 'ui2-molecule',
    thisClassName: 'realty-box-name',
    getChildren() {
				let model = this.model;
				
        let actions;
        let open;
        let name = {
            class: View,
            baseClassName: 'ui2-atom',
            thisClassName: 'realty-name',
            modelSchema,
            template: '<big><%= name %></big><small><%= prefix %>, <%= buildingClassEnum %></small>',
						templateContext() {
							const { name, prefix, buildingClassEnum } = this.model.attributes;
							return { name, prefix, buildingClassEnum }
						}
        };
        return [ actions, open, name ];
    }
});

const commonMixin = {
	initialize(options) {
		this.mergeOptions(options, ['offer', 'realtyObject']);
        this.initializeModel();
	},
    initializeModel() {
		if (!this.model && this.offer) {
			//this.model = this.offer.getRealty();
            let id = this.offer.get('realtyId');
            this.model = realtiesStore.get(id, true);
		} else if (!this.model && this.realtyObject) {
            //this.model = this.realtyObject.getRealty();
        }
        this.syncModels();
    },
    async syncModels() {
        let schema = getSchema(this.model);
        try {

            await this.model.whenFetched;

            if (schema) {
                await schema.whenBackendSchemaFetched;
            }
            this.triggerMethod('data:ready');

        } catch(exc) {
            console.error('-syncModels', exc);
        }
    }
}

export const RealtyBoxView = BoxView.extend({
	thisClassName: 'realty-box',
	content:[
		RealtyNameView,
		RealtyThumbView,
		RealtyPropertiesView
	],
	...commonMixin,
	modelEvents: {
			'sync': 'render',
	},
	onDataReady() {
		console.log('data:ready');
		this.render();
	}
});

export const _RealtyBoxViewWraper = HamburgerView.extend({
    thisClassName: 'realty-box-holder',
	childrenViews: [RealtyBoxView],
    ...commonMixin,
});

export const RealtyBoxViewWraper = AsyncModelBoxWraperView.extend({
	initialize(options) {
		this.mergeOptions(options, ['offer', 'realtyObject']);
	},	
	// childViewOptions() {
	// 	return {
	// 		thisClassName: 'realty-box',
	// 		content: [
	// 			RealtyNameView,
	// 			RealtyThumbView,
	// 			RealtyPropertiesView
	// 		],
	// 	}
	// },

	childrenViews: v => [
		{
			class: AsyncModelBoxView,
			thisClassName: 'realty-box',
			content: [
				RealtyNameView,
				{
					class: CommonThumbView,
					buildCollection: getBuildingImagesCollection
				},
				// RealtyThumbView,
				RealtyPropertiesView
			],
		},
		{
			class: AsyncModelBoxView,
			thisClassName: 'agents-box',
			content: [
				AgentsListView
			]
		},
	],


	initializeModel() {

		let id = this.offer.get('realtyId');
		this.model = realtiesStore.get(id, true);
		
  },
});

