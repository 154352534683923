import { backendApi } from '../backend';
import modals from '_libs/modals';
import appCfg from 'app-config';
import { Model } from 'vendors';
import { BlocksView } from './BlocksView';
import { LayoutView } from './LayoutView';
import { testData } from './test-data';

import './pdf.less';
import backend from './backend';
import { preloaderApi } from '../preloader';
export const pdfApi = {

    // 1, 2, ... n offers case
    showBlocks(offers) {
        let preloader = preloaderApi.fullScreen();
        
        backend.requestBlocks(offers).then(model => {
            let blocksView = new LayoutView({ model, offers });
            modals.modal(blocksView, { modalCssCfg: 'flexFullScreen', cssClasses: { dialog: 'flex-modal-dialog pdf-modal-dialog' }  });
        }, err => {
            console.log(err)
            return Promise.resolve();
        })
        .then(() => preloader.destroy());

    },

    showTest() {
        let model = new Model(testData);
        console.log('MODEL INI', model);
        let blocksView = new LayoutView({ model });
        modals.modal(blocksView, { modalCssCfg: 'flexFullScreen', cssClasses: { dialog: 'flex-modal-dialog pdf-modal-dialog' } });

    }

}