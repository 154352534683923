import serverCfg from '../../server-cfg';

export const urls = {
	api(url, options = {}) {
		let { root } = options;
		const server = serverCfg.paths.apiServer;
		const builded = [server, root, url].filter(f => !!f).join('/');
		return builded;
	},
	page(url) {
		return `/s/${serverCfg.svcWebId}/${url}`;
	}
}