
const engines = {
	'': {
		getHtml(arg) {
			return `<i class="${arg.iconId}"></i>`;
		}
	},
	bi: {
		getHtml(arg) {
			arg = normalizeIconArgument(arg);
			return `<i class="bi bi-${arg.iconId}"></i>`;
		}
	},
	fa: {
		getHtml(arg) {
			arg = normalizeIconArgument(arg);
			return `<i class="fa fa-${arg.iconId}"></i>`;
		}
	},
	far: {
		getHtml(arg) {
			arg = normalizeIconArgument(arg);
			return `<i class="far fa-${arg.iconId}"></i>`;
		}
	},
}

const normalizedSymbol = Symbol('iconApiOptionsNormalized');

function normalizeIconArgument(arg) {
	if (arg && typeof arg === 'object' && arg[normalizedSymbol]) {
		return arg;
	}

	if (typeof arg !== 'string') { 
		throw new Error((typeof arg) + ' icon normalizing not implemented') 
	}

	const chunks = arg.split(':');
	const normalized = {
		[normalizedSymbol]: 1
	};

	if (chunks.length > 1) {
		let [ engineId, iconId, ] = chunks;
		Object.assign(normalized, { engineId, iconId });
	} else {
		Object.assign(normalized, { engineId: '', iconId: arg });
	}

	return normalized;
}

export const iconApi = {

	getEngine(arg) {
		arg = normalizeIconArgument(arg);
		return engines[arg?.engineId];
	},

	getHtml(arg) {
		if (arg == null || arg === '') { return ''; }
		arg = normalizeIconArgument(arg);
		const engine = this.getEngine(arg);
		return engine?.getHtml(arg) || '';
	}

}

export const viewIconApiMixin = {

	getIconHtml(suffix) {
		const iconValue = this.getIcon(suffix);
		const iconHtml = iconApi.getHtml(iconValue);
		return iconHtml || '';
	},

	getIcon(suffix = '') {
		return this.getOption('icon' + suffix);
	},

}