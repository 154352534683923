﻿//define('ui-controls-datetime2-selectItemView', ['behaviors'], function (beh) {});
import { MneView } from 'core/mne';

var View = MneView.extend({
	tagName: 'button',
	className: function () { return 'select-item ' + ((this.isCurrent() && 'current') || '') },
	template: _.template('<%= name %>'),
	isCurrent: function () {},
	getLabel: function () {
		return this.model.id;
	},
	templateContext: function () {
		return {
			name: this.getLabel(),
		};
	},
	triggers: {
		'click': 'selected',
	}
});

export default View;