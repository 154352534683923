import { CollectionView, Collection } from 'vendors';
import { BlockErrorView } from './BlockErrorView';
export const BlockErrorsView = CollectionView.extend({
    childView: BlockErrorView,
    className: 'errors',
    tagName: 'ul',
    initialize() {
        this.collection = new Collection(this.getOption('errors'));
    },
    childViewOptions() {
        return {
            blockModel: this.model,
            data: this.getOption('data'),
            requestModel: this.getOption('requestModel')
        }
    }
});