import { View, CustomsView, BbView } from 'base/views';
import { proxyUpPromiseEvents } from './utils';

export const HeaderView = CustomsView.extend({
    tagName: 'header',
    className: 'new-flex-modal-box__header',
    // initialize() {
    //     this.on('all', e => console.log(' :header: ', e));
    // },
    getCustomViews() {
        
        let views = [
            this.getHeaderView()
        ];

        let CloseView = this.getOption('closeView');
        if (CloseView) {
            views.push(new CloseView());
        }

        return views;
    },
    getHeaderView() {
        let header = this.getOption('header');
        if (header instanceof BbView)
            return header;
        else {
            header = header || '';
            return new View({ 
                className: 'new-flex-modal-header-text',
                template: _.template(header) 
            });
        }
    },
    childViewTriggers: {
        ...proxyUpPromiseEvents
    }
})