import { urls } from 'coms/urls';
import { Model, ApiCollection } from 'core';

export const OwnerModel = Model.extend({});

export const OwnersCollection = ApiCollection.extend({
	url() {
		return urls.api('v4/realties/owners')
	}
});
