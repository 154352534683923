module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="board-list-region">\n	<div>\n		<div class="block">\n			<header class="small bg-primary">Задача</header>\n			<ul>\n				<li class="list-btn" data-change="type"><small>Тип задачи?</small><span>'+
((__t=( _enum(type, 'employeeTaskTypes') ))==null?'':__t)+
'</span></li>\n				<li class="list-btn" data-change="toDo"><small>Суть задачи?</small><span>'+
((__t=( _ift(toDo,'...') ))==null?'':__t)+
'</span></li>\n				<li class="list-btn" data-change="dates.deadline"><small>Когда должно быть сделано?</small><span>'+
((__t=(_tfnf(dates.deadline)))==null?'':__t)+
'</span></li>\n			</ul>\n		</div>\n		<button class="list-btn btn-success offset-v create" '+
((__t=( _submitDisabled))==null?'':__t)+
'>поставить задачу</button>\n	</div>\n</div>\n<div class="board-list-region">\n	<div>\n		<div class="block">\n			<header class="small bg-info">Детали</header>\n			<ul>\n				<li class="list-btn" data-change="dates.strictDeadline"><small>Жесткий дедлайн?</small><span>'+
((__t=( _ift(dates.strictDeadline,'Условный дедлайн','Жёсткий дедлайн') ))==null?'':__t)+
'</span></li>\n				<li class="list-btn" data-change="dates.remindAt"><small>Когда напомнить?</small><span>'+
((__t=( _tfnf(dates.remindAt, {empty:'по умолчанию'}) ))==null?'':__t)+
'</span></li>\n				<li class="list-btn" data-change="dates.duration"><small>Предположительная длительность в минутах</small><span>'+
((__t=( dates.duration))==null?'':__t)+
'</span></li>\n				<li class="list-btn" data-change="responsibleId"><small>Ответственный</small><span>'+
((__t=( _ift(_empn(responsibleId),'&ndash;')))==null?'':__t)+
'</span></li>\n				<li class="list-btn" data-change="checkerId"><small>Требует проверки?</small><span>'+
((__t=( _ift(_empn(checkerId),'не требует') ))==null?'':__t)+
'</span></li>\n			</ul>\n		</div>\n	</div>\n</div>';
}
return __p;
};
