﻿//define('m-realties-offers-offer', ['bus','m-abstract', 'references', 'action','app-paths', 'link'], function (Bus, abstractNs, refs, action, paths, link) {});

import Bus from 'bus';
// import abstractNs from 'm/abstract';
import refs from 'references';
import action from '_helpers/action';
import paths from 'app-paths';
import link from 'link';

import { getValueByPath } from 'utils';

import { BbeModel } from 'core/bbe';
import { ContactsInRealtyModel } from 'm/realties/owners';
import '../buildings/buildings';
// import CardableMixin from '../../abstract/cardable';
import { ActionableModel, CardableModelMixin } from '../../abstract';

import OfferCardModalView from '../../../service/realties/offers/views/cardInModal';
// import { allbuildings, getRealtyAddress } from '../buildings/buildings';
import { shortRealties, getRealtyAddress } from 'mods/realties/realties';

var NDS_KOEF = 1.2;

function formatednum(num, dig)
{
	return num.toLocaleString('ru', { maximumFractionDigits: dig });
}

// const cardableMixin = abstractNs.CardableModel({
// 	cardUrl: function () {
// 		return link.url('offerCard:' + this.get('id'));
// 	},
// });




let cardable = CardableModelMixin({
	cardUrl: function () {
		return link.url('offerCard:' + this.get('id'));
	},
});

let hash = 	{

	...cardable,

	initialize: function () {
		this.getRealty();
	},
	modalOptions: {
		cssClasses: { dialog: 'offer-modal-dialog' },
		noFetch: true,
	},
	getMetroId: function () {
		var metro = this.getMetro();
		if (!metro) return;
		return metro.stationId;
	},
	getMetroLabel: function () {
		var metro = Bus.app.request('geo:refs:metroLabelByAddress', this.get('addressId'));
		return metro;
	},
	getMetro: function () {
		var address = this.getAddress();
		if (address?.get) {
			return address.get('metro');
		}
		return address?.metro;
	},
	getAddress: function () {
		const realtyId = this.get('realtyId');
		const address = getRealtyAddress(realtyId);
		//var address = //Bus.app.request('geo:refs:address', this.get('addressId'));
		return address;
	},
	getRealty: function () {
		if (this.get('bld') && this.get('bld') instanceof BbeModel)
			return this.get('bld');

		var rlts = shortRealties;
		//Bus.Models.request('realties:buildings:all');
		var rId = this.get('realtyId');
		var rlt = rlts && rlts.get(rId);
		if (!rlt) return;
		this.set('bld', rlt, { silent: true });
		return rlt;
	},
	getOwnerContacts: function () {
		if (this.ownerContacts) return this.ownerContacts;
		//console.log(this);
		this.ownerContacts = ContactsInRealtyModel.create(this.get("ownerId"), this.get("realtyId"));
			//Bus.Models.request("realties:get:ownercontactsInRealty:model", this.get("ownerId"), this.get("realtyId"));
		if (this.ownerContacts)
			this.ownerContacts.loudFetch();
		return this.ownerContacts;
	},
	getRealtyObject: function () {
		return this.get('obj') || {};
	},
	getSquare: function (opts) {
		var obj = this.getRealtyObject();
		var val = parseFloat(getValueByPath(obj, 'info.squareOffer'), 10);
		if (isNaN(val))
			return;
		else {
			opts || (opts = {});
			if (opts.formated)
				return formatednum(val,1);
			else
				return val;
		}
	},
	getPriceSquare: function (opts) {
		var nds = refs.enumHasFlag(this.getValueByPath('forCustomer.taxType'), 'nds');
		var val = parseFloat(this.getValueByPath('forCustomer.priceSquare'), 10);
		if (isNaN(val))
			return;
		else {
			opts || (opts = {});
			val = opts.clean ? val
				: nds ? val * NDS_KOEF
					: val;
			if (opts.formated)
				return formatednum(val, 0);
			else
				return val;
		}
	},
	getPriceMeter: function (opts) {
		//console.log(refs.enumHasFlag, )
		var nds = refs.enumHasFlag(this.getValueByPath('forCustomer.taxType'), 'nds');
		var val = parseFloat(this.getValueByPath('forCustomer.priceMeter'), 10);
		if (isNaN(val))
			return;
		else {
			opts || (opts = {});
			val = opts.clean ? val
				: nds ? val * NDS_KOEF
					: val;
			if (opts.formated)
				return formatednum(val, 0);
			else
				return val;
		}
	},
	getName: function () {
		var bld = this.getRealty() || { name: this.get('realtyName'), prefix: this.get('realtyPrefix'), cls: decimalBuildingClassToString(this.get('realtyClassNumber')) };
		var name = [];
		name.addValue(getValueByPath(bld, 'name'));
		name.addValue(getValueByPath(bld, 'prefix'));
		name.addValue(getValueByPath(bld, 'cls'));
		return name.join(' ');
	},
	getIncomePercent: function (opts) {
		var val = parseFloat(this.getValueByPath('forAgent.income'), 10);
		if (isNaN(val))
			return val;
		else {
			opts || (opts = {});
			if (opts.formated)
				return formatednum(val, 0) + '%';
			else
				return val;
		}
	},
	getIncome: function (opts) {
		var price = this.getPriceSquare();
		var percent = this.getIncomePercent() / 100;
		var val = price * percent;

		if (isNaN(val))
			return;
		else {
			opts || (opts = {});
			if (opts.formated)
				return formatednum(val, 0);
			else
				return val;
		}
	},

	cardViewModal: () => OfferCardModalView,

	actions: function () {
		return [
			action('openModal', 'показать', {}, { order: 0 })
		];
	},

	urlRoot: function () {
		return paths.api.realtiesOffer;
	},

}
const CLASSLETTERS = "AABCDEFG";
function decimalBuildingClassToString(value) {
	if (typeof value !== 'number') { return; }

	const rised = Math.ceil(value);
	const plus = rised != value ? '+' : '';
	const letter = CLASSLETTERS[rised];
	if (!letter) {
		return letter + plus;
	}
}

var Model = ActionableModel.extend(hash);

 // abstractNs.ActionableModel.extend(hash);

export default Model;
