export function normArr(arg) {
	if (Array.isArray(arg)) {
		return arg.length ? arg : undefined;
	}
	if (arg) {
		return [arg];
	}
}

export function addArray(arr, val) {
	val = normArr(val);
	if (val) {
		arr.push(...val);
	}
}