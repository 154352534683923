import Plate from './media-plate';
import helpers from '../../../helpers';
import { _ } from 'vendors';

import { BbeModel, BbeCollection } from 'core/bbe';

import { MneNextCollectionView } from 'core/mne';

const MediasCollection = BbeCollection.extend({
    initialize() {
        this.on('mass:changes', (changes) => this.applyMassChanges(changes));
    },
    initializePrimary() {
        this.primary = this.find({ isPrimary: true });
    },
    makePrimary(model) {
        let changes = [];
        let prev;
        if (this.primary) {
            prev = this.primary;
            this.primary.set('isPrimary', false);
            changes.push(this.primary);
            this.primary = void 0;
        }
        if (model && (!prev || prev != model)) {
            let prim = model.get('isPrimary');
            model.set('isPrimary', !prim);

            if (!prim)
                this.primary = model;

            changes.push(model);
        }
        this.trigger('mass:changes', changes);
    },
    makeFirst(model) {
        let first = this.max('order');
        if (!first || first == model) return;

        model.set('order', (first.get('order') || 0) + 10);
        this.trigger('mass:changes', [model]);
    },
    makeLast(model) {
        let last = this.min('order');
        if (!last || last == model) return;

        model.set('order', (last.get('order') || 0) - 10);
        this.trigger('mass:changes', [model]);
    },
    changeOrder(model, num) {
        if (!model) return;
        
        model.set('order', (model.get('order') || 0) + num);
        this.trigger('mass:changes', [model]);
    },
		applyMassChanges(changes) {
			let changeHashes = changes.map(change => _.omit(change.attributes, 'media'));
			// let url = this.model.url() + '/medias-changes';

			let massUpdate = new BbeModel();
			massUpdate.url = this.massChangesUrl;
			massUpdate.save(null, { attrs: changeHashes }).then(
					() => {
							console.log('mass changes', changeHashes);
							this.trigger('after:mass:changes');
					},
					() => {}
			);			
		}
});

export default MneNextCollectionView.extend({
    className: 'media-plates',
    childView: Plate,
    initialize() {
        this.collection = this.model.medias;
        if (!this.collection) {
            let medias = this.model.get('medias') || [];
            let mediasCollection = new MediasCollection(medias);
            mediasCollection.url = this.model.url() + '/medias';
						mediasCollection.massChangesUrl = this.model.url() + '/medias-changes';
            this.model.medias = mediasCollection;
            this.collection = mediasCollection;
        }
        this.collection.initializePrimary();
    },
    childViewOptions() {
        return {
            entityModel: this.model,
        }
    },
    compareOptions: helpers.comparatorOptions([['isPrimary', 1], ['order', 1]]),
    viewComparator(v1, v2) {
        return helpers.comparator(v1.model, v2.model, this.compareOptions);
    },
    collectionEvents: {
			'after:mass:changes'() {
				this.sort();
			},
			// 'mass:changes'(changes) {
			// 		let changeHashes = changes.map(change => _.omit(change.attributes, 'media'));
			// 		let url = this.model.url() + '/medias-changes';

			// 		let massUpdate = new BbeModel();
			// 		massUpdate.url = url;
			// 		massUpdate.save(null, { attrs: changeHashes }).then(
			// 				() => {
			// 						console.log('mass changes', changeHashes);
			// 						this.sort();
			// 				},
			// 				() => {}
			// 		);

			// }
    }
});