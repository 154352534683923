﻿//define('ui-controls-selectRest', ['bus', 'ui-controls-option', 'ui-models-values-collection'], function(Bus, Option, Collection) {});


// import Bus from 'bus';
// import Collection from 'ui/models/values/collection';
import Option from 'ui/controls/option';

import template from './control.html';

import { isNotEmpty } from 'utils';

import { MneView, MneNextCollectionView } from 'core/mne';

var UlView = MneNextCollectionView.extend({
	tagName: 'ul',
	className: 'select-control',
	viewComparator: 'label',
	viewFilter: function(view) {

		if (isNotEmpty(this.textFilter)) {
			var pattern = new RegExp(this.textFilter);
			var sel = pattern.test((view.model.getLabel() || '').toLowerCase());
			if (!sel) return false;
		}

		return true;
	},
	initialize: function() {
		var sel = this.selected = {};
		var col = this.collection;
		if (!col) return;
		//var multiple = this.getOption('multiple');
		var initial = this.getOption('initialValue');
		if (!(initial instanceof Array))
			initial = [initial];

		var func = this.getOption('viewFilter');
		if (typeof func === 'function') this.viewFilter = func;

		//var comparator = 

		this.childViewOptions = {
			selected: this.selected
		};
		_(initial).each(function (value) {
			var m = col.findWhere({ value: value });
			if (!m) return;
			sel[m.cid] = m;
		});
	},
	dynamicClass: function() {
		return this.getOption('multiple') ? 'multiple' : '';
	},
	childView: Option,
	unselect: function (model, viewCid) {
		if (!model) return;
		//var selected = this.selected[model.cid];
		delete this.selected[model.cid];
		model.trigger('select-control:unselect', viewCid)
	},
	select: function (model, viewCid) {
		this.selected[model.cid] = model;
		model.trigger('select-control:select', viewCid)
	},
	broadcastChanges: function () {
		//var values = _(this.selected).chain().toArray().map(function(m) { return _.val(m); }).value();
		var values = _(this.selected).toArray();
		this.triggerMethod('value:changed', values);
	},
	onChildviewClicked: function (view) {
		var mult = this.getOption('multiple');
		if (mult) {
			var sel = this.selected[view.model.cid];
			if (sel)
				this.unselect(view.model);
			else
				this.select(view.model);
		} else {
			var selected = _(this.selected).chain().toArray().first().value();
			this.unselect(selected, view.cid);
			this.select(view.model, view.cid);
		}

		this.broadcastChanges();
	},
});


var View = MneView.extend({
	constructor: function() {
		MneView.apply(this, arguments);
		this.initializeRestSource();
	},
	className:'select-control-box',

	//template: '#tmpl-ui-controls-selectRest-control',
	template,

	ui: {
		'filtertext':'input[name="filter-label"]'
	},
	events: {
		'keypress @ui.filtertext': _.debounce(function() {
			//console.log('chpok ?');
			if (this.isDestroyed()) return;
			this.getFromRestbyText(this.ui.filtertext.val());
		}, 500),
		'focus @ui.filtertext': function (e) {
			$(e.target).select();
		},
	},
	
	initializeRestSource: function() {
		var rest = this.getOption('sourceValues');
		if (typeof rest == 'function') {
			rest = rest.call(this, this);
		}
		this.rest = rest;
		this.listenTo(rest, 'data:arrived', this.onDataArrived)
	},
	onRender: function () {
		var $inp = this.ui.filtertext;
		setTimeout(function () {
			$inp.focus();
			$inp.select();
		}, 0);
	},
	onDataArrived: function(col, _opts) {
		if (this.timer < _opts.timer) {
			console.warn('we are awaiting next data');
			return;
		}
		var opts = _.extend({}, this.options, { collection: col });
		var view = new UlView(opts);
		this.showChildView('select', view);
		this.triggerMethod('refresh');
	},
	getFromRestbyText: function(text) {
		var timer = this.timer = _.now();
		this.rest.get(text, {timer:timer});
	},
	onItemsFilterInput: function() {
		this.valuesView.textFilter = this.ui.filtertext.val();
		this.valuesView.sort();
	},
	regions: {
		'select': { el: 'ul', replaceElement: true }
	},
	onChildviewValueChanged: function(values) {
		this.triggerMethod('value:changed', values);
		//console.log('###', arguments);
	}
});

View.create = function(opts) {
	var options = _.extend({}, opts);

	return new View(options);
}



export default View;