// define('ui-controls-selectOwners', [
//     'm-realties-owners-collection',
// 	'ui-controls-selectRest',
// 	'modals'
// ], function (
//     OwnersCollection,
// 	SelectRest,
// 	modals	
// ) {});


import OwnersCollection from 'm/realties/owners/collection';
import SelectRest from 'ui/controls/selectRest';
import modals from '_libs/modals';



const SelectOwners = SelectRest.extend({
	//initialValue: undefined,
	modelType: "single",
	multiple: false,
	sourceValues: () => new OwnersCollection.Rest(),
});

SelectOwners.inModal = function(header, onSuccess, onFail) {
	if (!onSuccess) {
		onSuccess = () => {};
	}
	if (!onFail) {
		onFail = () => {};
	}
	let view = new SelectOwners({ 
		header, 
		onChange(value) {
			this.triggerMethod('done', value);
		} 
	});

	let modal = modals.modal(view);
	return new Promise((res, rej) => {
		view.on('done', result => {
			onSuccess(result);
			modal.destroy();
			return res(result);
		});
		modal.on('destroy', () => {
			onFail();
			rej();
		});
	});
}

export default SelectOwners;
