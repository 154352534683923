import Module from 'appModule';
import link from 'link';
import Layout from './layout';
import ownerModule from './owner';


var module = Module.content({
	name: 'actualization/owners-list',
	label: 'Справочник собственников',
	routeData: () => link.route('actualizationRealtyOwnersList'),
	urlKey: 'actualizationRealtyOwnersList',
	claims: {
		svcActualizationModule: ['view']
	},
	onRun: function () {
		let view = new Layout();
		this.createAndShow(view, 'Справочник собственников');
	}
});


module.registerModule(ownerModule);

export default module;
