module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<ul>\n	<li class="halfed"><small>статус</small><span>'+
((__t=( _enum('realtyOfferStatuses',_m.status)))==null?'':__t)+
'</span></li>\n	<li class="halfed"><small>состояние</small><span>'+
((__t=( _enum('realtyObjectStates',_mv('obj.info.state'))))==null?'':__t)+
'</span></li>\n	<li class="halfed"><small>планировка</small><span>'+
((__t=( _enum('spaceLayouts',_mv('obj.info.spaceLayout'))))==null?'':__t)+
'</span></li>\n	<li class="halfed"><small>количество кабинетов</small><span>'+
((__t=( _ndash(_mv('obj.info.roomsCount')) ))==null?'':__t)+
'</span></li>\n	<li class="halfed"><small>этаж</small><span>'+
((__t=( _ndash(_mv('obj.info.floor')) ))==null?'':__t)+
'</span></li>\n	<li class="halfed"><small>кол. этажей в помещении</small><span>'+
((__t=( _ndash(_mv('obj.info.floorsCount')) ))==null?'':__t)+
'</span></li>\n\n	<li class="list-lbl"><small>материал пола</small><span>'+
((__t=( _enum('warehouseFloors',_mv('obj.wh.floorMaterial'))))==null?'':__t)+
'</span></li>\n	<li class="list-lbl"><small>особенности складского помещения</small><span>'+
((__t=( _enum('warehouseFeatures',_mv('obj.wh.features'))))==null?'':__t)+
'</span></li>\n	<li class="list-lbl"><small>отопление</small><span>'+
((__t=( _enum('realtyObjectHeatings',_mv('obj.wh.heating'))))==null?'':__t)+
'</span></li>\n	<li class="list-lbl"><small>морозильное оборудование</small><span>'+
((__t=( _enum('warehouseFreezeEquipments',_mv('obj.wh.freezeEquipment'))))==null?'':__t)+
'</span></li>\n	<li class="list-lbl"><small>особенности разгрузки</small><span>'+
((__t=( _enum('warehouseUnloadingFeatures',_mv('obj.wh.unloadingFeatures'))))==null?'':__t)+
'</span></li>\n	<li class="list-lbl"><small>оснащение</small><span>'+
((__t=( _enum('warehouseUnloadingEquipments',_mv('obj.wh.unloadingEquipment'))))==null?'':__t)+
'</span></li>\n	<li class="list-lbl halfed"><small>количество ворот</small><span>'+
((__t=( _ndash(_mv('obj.wh.gatesCount'))))==null?'':__t)+
'</span></li>\n	<li class="list-lbl halfed"><small>количество паллетомест</small><span>'+
((__t=( _ndash(_mv('obj.wh.palletPlaces'))))==null?'':__t)+
'</span></li>\n\n	<li><small>номер налоговой</small><span>'+
((__t=( _mv('bld.taxNum')))==null?'':__t)+
'</span></li>\n	<li class="address"><span>'+
((__t=( _mv('bld.address.fullAddress')))==null?'':__t)+
'</span></li>\n	';
 if(_mv('bld.address.metro.station')){ 
__p+='\n	<li class="metro"><small>'+
((__t=( _mv('bld.address.metro.line')))==null?'':__t)+
'</small><span>'+
((__t=( _mv('bld.address.metro.station')))==null?'':__t)+
' ('+
((__t=( Math.round(_mv('bld.address.metro.distance'))))==null?'':__t)+
' м)</span></li>';
 } 
__p+='\n	<li><small>последнее измение</small><span>'+
((__t=( _tfn(_m.modified) ))==null?'':__t)+
'</span></li>\n</ul>';
}
return __p;
};
