module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<input class="form-control" name="datetimelabel" type="'+
((__t=(valueType))==null?'':__t)+
'" value="'+
((__t=( moment(value).fromNow() ))==null?'':__t)+
'" readonly="readonly"/>\n<div data-role="datetimeInline" class="offset-v"></div>\n';
}
return __p;
};
