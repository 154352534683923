﻿//define('m-deals-deal', ['bus', 'bus-svc', 'references', '_libs/identity', 'modals', 'store-service', 'm-factory', 'app-paths', 'm-abstract', 'action', 'link', 'ui-controls-selectEmployees', 'ui-controls-editValue', 'svc-deals-deal-addVirtualOffer', 'm-clients', 'm-deals-dealOffers'], function (Bus, svcRadio, refs, identity, modals, store, factory, paths, abstractNs, action, link, SelectEmployees, EditValue, AddVirtualOffer, clientsNs, DealOffersCollection) {});

//let EditValue, AddVirtualOffer, clientsNs, DealOffersCollection;
import factory from 'm/factory';
import link from 'link';
import { ActionableModel, CardableModelMixin, FetchableByWebIdMixin } from 'm/abstract';

import mixin from './deal-mixin';
// const mixin = {};



const cardable = CardableModelMixin({
	cardView: 'deal:page',
	cardUrl: function () {
		return link.url('dealCard:' + this.get('wId'));
	},
});



export const DealModel = ActionableModel.extend({
	factoryName: 'JsonDeal',
	...cardable,
	...mixin,
	...FetchableByWebIdMixin
});


factory.setType(DealModel);



// export default Model;
