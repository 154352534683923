import { Model } from 'core';
import { geoReference } from '../../geo';
export const AddressModel = Model.extend({
	getNearestSubwayDto() {
		if ('subwayDto' in this) {
			return this.subwayDto;
		}
		const subway = this.getNearestSubway();
		if (!subway) { 
			this.subwayDto = null;
			return; 
		}
		const station = subway.name;
		const distance = subway.distance;

		const { lineName, lineNumber } = this.getLineDto(subway.subwayLineId);

		return {
			station,
			distance,
			lineNumber,
			lineName,
		}
	},
	getLineDto(lineId) {
		const line = this.getLine(lineId);
		if (!line) { return {}; }
		const lineNumber = line.get('lineNumber');
		const lineName = line.get('name')
		return { lineName, lineNumber };
	},
	getLine(lineId) {
		if ('line' in this === false) {
			this.line = geoReference.metroLines.get(lineId);
		}
		return this.line;
	},
	getNearestSubway() {
		if ('subway' in this === false) {
			const subways = (this.get('nearestSubwaysOrHighways') || []).filter(f => f.type === 'subway');
			subways.sort((s1, s2) => s1.distance - s2.distance);
			this.subway = subways[0];
		}
		return this.subway;
	},
})