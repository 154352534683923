﻿//define('ui-controls-option', ['behaviors-dynamicClass'], function (BehaviorDynamicClass) {});

import BehaviorDynamicClass from 'behaviors/dynamicClass';

import template from './control.html';
import { MneView } from 'core/mne';

var View = MneView.extend({
	tagName: 'li',
	className: 'select-control-item',
	behaviors:[BehaviorDynamicClass],
	dynamicClass: function() {
		var res = this.isSelected();
		return res ? "selected" : "";
	},
	isSelected: function () {
		var selected = this.getOption('selected');
		var issel = selected && !!selected[this.model.cid];
		return issel;
	},
	modelEvents: {
		'change': 'render',
		'select-control:unselect': 'render',
		'select-control:select': 'render',
	},

	//template: '#tmpl-ui-controls-option-control',
	template,

	triggers: {
		'click':'clicked'
	},
	templateContext: function () {
		return {
			label: _.lbl(this.model),
			description: this.model.get('description')
		};
	}
});
export default View;
