module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<ul>\n	<li class="list-lbl" data-trigger="changeInfo"><small>название</small><span>'+
((__t=( _ndash(_m.name)))==null?'':__t)+
'</span></li>\n	<li class="list-lbl" data-trigger="changeInfo"><small>тип клиента</small><span>'+
((__t=( _enum('clientTypes',_m.type)))==null?'':__t)+
'</span></li>\n	<li class="list-lbl" data-trigger="changeInfo"><small>род деятельности</small><span>'+
((__t=( _ndash(_m.occupation)))==null?'':__t)+
'</span></li>\n	<li class="list-lbl" data-trigger="changeInfo"><small>кол. сотрудников</small><span>'+
((__t=( _ndash(_m.employees)))==null?'':__t)+
'</span></li>\n</ul>';
}
return __p;
};
