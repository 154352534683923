﻿// define('acc', ['appModule', 'link', '_libs/identity', 'acc-login', 'acc/logoff', 'acc/forgotPassword'], 
// function(Module, link, identity, moduleLogin, moduleLogoff, moduleForgotPassword) {});



import Module from 'appModule';
import link from 'link';
import identity from '_libs/identity';

import moduleLogin from './login' ;
import moduleLogoff from './logoff' ;
import moduleForgotPassword from './forgotPassword' ;
// const link = {};
// const identity = {};
// const Module = {
//     content() {}
// }

var module = Module.content({
    name: 'acc',
    routeData: function() {
        return link.route('acc',true);
    },
    onRun: function() {
        console.log('run', this.name);
        if (identity.isLogged()) {
            //this.createAndShow(, 'Выберите аккаунт', {});
        } else {
            //this.createAndShow(Layout, undefined, {template:_.noop});
        }
    },
});

//module.registerModule(moduleLogin)

module.addSubModule(moduleLogin);
module.addSubModule(moduleForgotPassword);
module.addSubModule(moduleLogoff);
export default module;
