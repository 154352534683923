import { View, Collection, Model } from 'core';
import { SideBarTabs } from './SideBarTabs';
import SelectedPane from 'ui/layouts/selectedPane';
import FastFilters from 'ui/controls/fastfilters';
import { SideBarOffers } from './SideBarOffers';
import { OffersCollection } from 'mods/realties/offers';
import { SideBarModel } from './SideBarModel';

/*
{
	result: {
		View: ResultView,
		options: {
			filterObject: this.filterObject,
			//data: this.filterObject.getUrlHash(),
			selectionHolder: selectionHolder,
			joinedOffers: this.joinedOffers
		}
	},
	filters: {
		view: new FastFilters({
			filters: this.filterObject,
		}),
	},
	selected: {
		view: new SelectedPane({
			selectionHolder: this.selectionHolder,
			filterObject: this.filterObject,
			selectedComparators: selectedComparators,
			selectedActions: selectedActions,
		})
	}
}
*/

export const SideBar = View.extend({
	mergeOptionsKeys: ['selectionHolder', 'filterObject', 'joinedOffers', 'mapApi'],
	baseClassName: 'side-bar',
	template: '<div class="tabs"></div><div class="content"></div><div class="slider"></div>',
	regions: {
		'tabs': '.tabs',
		'content': '.content'
	},
	getTabButtons() {
		const selectedComparators = this.getOption('selectedComparators', false);
		const selectedActions = this.getOption('selectedActions');
		return [
			{
				id: 'result', active: true,
				view: SideBarOffers, viewOptions: {
					model: this.model,
					collection: this.collection,
					selectionHolder: this.selectionHolder,
					filterObject: this.filterObject,			
					selectedComparators,
					selectedActions,
					joinedOffers: this.joinedOffers,
					mapStateData: this.mapApi.mapStateData,
					mapApi: this.mapApi
				}
			},
			{ 
				id: 'filters', 
				view: FastFilters, viewOptions: {
					filters: this.filterObject,
				} 
			},
			{ 
				id: 'selected',
				view: SelectedPane, viewOptions: {
					selectionHolder: this.selectionHolder,
					filterObject: this.filterObject,
					selectedComparators,
					selectedActions,
				}
			},
		];
	},
	initialize() {
		if (!this.model) {
			this.model = new SideBarModel();
		}
		this.collection = new OffersCollection();
		const buttons = this.getTabButtons();
		this.tabsCollection = new Collection(buttons);
		this.tabsCollection.active = this.tabsCollection.find({ active: true });
		this.listenTo(this.mapApi, 'map:click', this._onMapClick);
		this.listenTo(this.filterObject, 'filters:apply', () => {
			// debugger;
			// this.collection.fetch();
			console.warn('on:filters:apply', this.collection.length, this.collection.isFetching());
			this.swapTab('result');
			
			setTimeout(() => {
				this.model.set('timestamp', Date.now());
				console.warn('on:filters:apply:2', this.collection.length, this.collection.isFetching());
			}, 10);
			
		});
		// console.log('MAPAPI	',this.mapApi);
		// this.mapApi.on('all', e => console.error('[] => ', e));
	},
	_onMapClick(type, options = {}) {
		console.error('MAP CLICK', type, options);
		this.model.select(type, options);
		this.swapTab('result');
	},
	onRender() {
		this.showTabs();
		this.showContent();
	},
	showTabs() {
		const view = this.tabsView = new SideBarTabs({ collection: this.tabsCollection });
		this.showChildView('tabs', view);
	},
	showContent(tab) {
		tab = tab || this.tabsCollection.active;
		const View = tab.get('view');
		const options = tab.get('viewOptions');
		const view = new View(options);
		this.showChildView('content', view);
	},
	swapTab(tab) {
		tab = this.tabsCollection.get(tab);
		if (!tab || tab === this.tabsCollection.active) { return; }

		if (this.tabsCollection.active) {
			this.tabsCollection.active.set({ active: false });
		}
		this.tabsCollection.active = tab;
		tab.set({ active: true });

		this.showContent(tab);
	},
	childViewEvents: {
		'tab:click'(view) {
			this.swapTab(view.model);
			// if (view.model === this.tabsCollection.active) { return; }
			// this.tabsCollection.active.set({ active: false });
			// this.tabsCollection.active = view.model;
			// view.model.set({ active: true });
			// this.showContent(view.model);
			// console.log('tab clicked ', view.model);
		}
	},
	// showTabContent: function (tab, opts) {

	// 	var view = tab instanceof BbView ? tab : this.getTabView(tab);
	// 	if (!view) return;
	// 	this.detachChildView('content');
	// 	var doRefresh = view.isRendered();


	// 	this.showChildView('content', view);
	// 	if (doRefresh && view.refresh)
	// 		view.refresh(opts);
	// },
	// getTabView: function (tab) {

	// 	if (!this.tabViews) {
	// 		this.tabViews = {};
	// 	}
		
	// 	var view = this.tabViews[tab];
	// 	if (view) {
	// 		// console.warn('exist tab view');
	// 		return view;
	// 	}

	// 	view = this.buildTabView(tab);
	// 	// console.warn('new tab view');
	// 	this.tabViews[tab] = view;

	// 	return view;
	// },
	// buildTabView: function (tab) {
	// 	var views = this.getOption('views') || {};
	// 	var context = views[tab];
	// 	if (context.view) {
	// 		return context.view;
	// 	}
	// 	else {
	// 		const options = Object.assign({ collection: this.collection }, context.options);
	// 		return new context.View(options);
	// 	}
	// },
});