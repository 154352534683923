﻿//define('svc-staff-deps-create-layout', ['behaviors-blockbuttons', 'modals', 'm-departments-createModel', 'bus-svc'], function (BehaviorBlockButtons, modals, CreateDepartment, svcBus) {});

import modals from '_libs/modals';
import CreateDepartment from 'm/departments/createModel';
import svcBus from 'bus/svc';

import { MneView } from 'core/mne';

var Layout = MneView.extend({
	className: 'board-container',
	template: _.template('<div data-placeholder="название отдела"><input name="name" tupt="text" class="form-control" /></div><button class="btn btn-success offset-t create">Создать</button>'),
	getQs(key) {
		var qs = (this.getOption('module') || {}).query || {};
		if (arguments.length == 0) return qs;
		return qs[key];
	},
	ui: {
		'create': 'button.create'
	},
	getParentId() {
		var pid = this.getQs('pid');
		return pid;
	},
	events: {
		'click @ui.create': function () {
			console.log('this', this)
			var hash = this.$el.serializeObject();
			var pid = this.getParentId();
			if (pid) {
				hash.parentId = pid;
			}
			var model = new CreateDepartment(hash);
			var view = this;
			//var wait = modals.notify('Подождите пожалуйста, приглашение отправляется и это может занять какое-то время.');
			model.loudSave(hash, { wait: true }).then(function () {
				//wait.destroy();
				modals.notify('Отдел создан');
				var col = svcBus.request('departments');
				col.add(model.toJSON());
				view.render();
			}, function (xhr) {
				//wait.destroy();
				var addMessage = xhr.responseJSON && xhr.responseJSON.exceptionMessage;
				modals.error('Не получилсоь создать отдел:<br/>' + addMessage);
			});
		}
	},
});

export default Layout;