import { BbModel } from 'vendors';

import consts from 'vXX/app/constants';
const { NDS_KOEF } = consts;

function _displayPrice(price) {
    if (price == null || isNaN(price)) {
        return '&mdash;'
    }
    return _.displayNum(price, 2) + ' р.';
}

function _price(permeter, { rent, square, display, tax, nds, boma } = {}) {

    let price = permeter;
    
    if (square != null) {

        //full or per year
        price = permeter * square;

        if (rent) {
            // per month for rent
            price = price / 12;

            // if (boma > 1) {
            //     price = price * boma;
            // }

            if (boma != null) {
                price = price * boma;
            }

        }

    }

    if ((tax && tax == 'nds') || nds) {
        price = price * NDS_KOEF;
    } else {
        // console.log(' - nds - ', tax, nds);
    }

    return display ? _displayPrice(price) : price;
}

function _offerPrice(forCustomer, square, operation, display) {
    let rent = operation == 'rent';
    let boma = rent ? forCustomer.boma : undefined;
    let tax = forCustomer.taxType;
    //forCustomer.priceMeter
    return _price(forCustomer.priceMeter, { rent, square, display, tax, boma});
}

function _getOffer(offer) {
    if (!offer) return;
    if (offer instanceof BbModel) {
        offer = offer.attributes;
    }
    if (offer.operation && offer.forCustomer)
        return offer;
    else
        return;
}

function _getSquare(arg) {
    let type = typeof arg;
    if (arg == null || type == 'number') return arg;
    
    let error = 'square argument can be undefined, number or realtyObject object';
    
    if (type != 'object') {
        return new Error(error);
    }

    if (arg instanceof BbModel) {
        arg = arg.attributes;
    }

    if (arg.info && arg.info.squareOffer != null) {
        return arg.info.squareOffer;
    }

    return new Error(error);
}

function _getPostFix(off, sq) {
    let postfix = ' р.';
    if (sq == null) {
        postfix += ' за метер';
    } else {
        if (off.operation != 'rent') {
            postfix += ' за площадь';
        } else {
            postfix += ' в месяц';
        }
    }
    return postfix;
}

export function offerPrice(offer, square, display) {
    offer = _getOffer(offer);
    if (!offer) {
        throw new Error('provided offer is wrong');
    }
    square = _getSquare(square);
    if (square instanceof Error) {
        throw square;
    }
    let price = _offerPrice(offer.forCustomer, square, offer.operation, display);
    return price;
    // let postfix = _getPostFix(offer, square);
    // return price + postfix;
}

export function displayOfferPrice(offer, square) {
    let display = true;
    let price = offerPrice(offer, square, display);
    return price;
}

export function price(permeter, nds, square, rent, boma, display)
{
    
    if (typeof nds === 'string') {
        nds = nds == 'nds';
    }
    if (typeof rent === 'string') {
        rent = rent == 'rent';
    }
    return _price(permeter, { nds, square, rent, boma, display });
}

export function displayPrice(permeter, nds, square, rent, boma) {
    let display = true;
    return price(permeter, nds, square, rent, boma, display);
}