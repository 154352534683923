import { _, MnView } from 'vendors'
import { viewStateMixin } from 'apis/state';
import { viewClassNameMixin } from 'apis/className';
import { viewCommonMixin } from './view-common-mixin';
// import { propertySchemaApi } from '../apis/schema';
import { viewClickableMixin } from './mixins';

export const View = MnView.extend({

    ...viewStateMixin,
    ...viewClassNameMixin,
    ...viewCommonMixin,
		...viewClickableMixin,

		// childViewEventPrefix: false,

		constructor: function(options) {
        this.options = Object.assign({}, options);
        MnView.apply(this, arguments);
        this.initializeStateMixin();
        this.initializeClassNameMixin();
        this.initializeHtmlAttributesMixin();
				if (this.getOption('clickableMixinEnabled', true)) {
					this.initializeClickableMixin();
				}
    },

		_setOptions() {
			const res = MnView.prototype._setOptions.apply(this, arguments);

			const keys = this.getOption('mergeOptionsKeys', true);
			if (keys && Array.isArray(keys)) {
				this.mergeOptions(this.options, keys);
			}

			return res;
		},

    // mixinTemplateContext(data) {
    //     let context = MnView.prototype.mixinTemplateContext.call(this, data);
    //     let mixed = _.extend({
    //         _d: (...args) => this.displayModelValue(...args)
    //     }, context);
    //     return mixed;
    // },

    // displayModelValue(key) {

    //     let modelHash = this.getModelHash 
    //         ? this.getModelHash()
    //         : this.model;

    //     if (!modelHash) { return; }

    //     let modelSchema = this.getOption('modelSchema');

    //     if (modelSchema) {
    //         return propertySchemaApi.displayValue(key, modelHash, modelSchema);
    //     }

    //     if (modelHash.display) {
    //         return modelHash.display(key);
    //     }

    //     return propertySchemaApi.getValue(key, modelHash, modelSchema);
    // }

});

