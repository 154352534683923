﻿//define('m-departments-department', ['bus', 'bus-app', 'bus-svc', 'app-paths', 'link', 'm-abstract', 'action', 'modals', 'm-departments-rights', 'ui-controls-editValue', 'm-departments-departmentEmployee'], function (Bus, appRadio, svcRadio, paths, link, abstractNs, action, modals, RightsCollection, EditValue, EmployeeModel) {});


import Bus from 'bus';
//import appRadio from '';
import svcRadio from 'bus/svc';
import paths from 'app-paths';
import link from 'link';
import abstractNs from 'm/abstract';
import action from '_helpers/action';
import modals from '_libs/modals';
import RightsCollection from './rights';
import EditValue from 'ui/controls/editValue';
import EmployeeModel from './department-employee';

import { BbeCollection } from 'core/bbe';

var Model = abstractNs.ActionableModel.extend(
	abstractNs.CardableModel({
		cardView: 'department:page',
		cardUrl: function () {
			return link.url('staffDepartmentCard:' + this.id);
		},
	})
)
.extend({
	constructor: function () {
		abstractNs.ActionableModel.apply(this, arguments);
		this.on('change:employees', this.refreshEmployees);
	},
actions: [
	action('openPage', 'перейти на страницу отдела', {}, { order: 0 })
	, action('editDepartmentName', 'изменить название', { staff: 'manageDepartments' }, { order: 0 })
	, action('addEmployees', 'добавить сюда сотрудников', { staff: 'manageDepartments' }, { order: 10 })
	, action('addDepartment', 'добавить подотдел', { staff: 'manageDepartments' }, { order: 20 })
	, action('removeDepartment', 'удалить этот отдел', { staff: 'manageDepartments' }, { order: 30 })
],
urlRoot: function() {
	return paths.api.departments;
},
getRights: function() {
	return RightsCollection.create(this.get('id'));
},
	refreshEmployees: function () {
		this._refreshEmployees(this.buildEmployees());
	},
	_refreshEmployees: function (models) {
		if (!this.employeesCollection)
			this.employeesCollection = new BbeCollection();
		this.employeesCollection.reset(models);				
	},
	buildEmployees: function () {
		var parentId = this.id;
		var raw = this.get('employees') || [];
		var models = _(raw).map(function (r) {
			return new EmployeeModel(r, { parentId: parentId });
		});
		return models;
	},
	getEmployees: function (opts) {
		opts || (opts = {});


		var models = this.buildEmployees();
		this._refreshEmployees(models);

		if (opts.asCollection)
			return this.employeesCollection;
		else
			return models;

	},

	getEmployeesCollection: function () {
		return this.getEmployees({ asCollection: true });
	},


actionEditDepartmentName: function (options) {
	if (options.modal)
		options.modal.destroy();

	var model = this;

	EditValue.modal.string({ header: 'Введите новое название', value: this.get('name') }).done(function (type, newvalue, oldvalue) {
		if (type == 'ok') {
			model.set('name', newvalue);
			console.log(model.changedAttributes());
			//model.loudPatch();
		}
		//console.log(arguments);
	});
	//console.log('o-lya-lya');

},

addEmployees: function (ids) {
	var emps = _(ids).map(function (eId) {
		return {
			eId: eId,
		}
	});
	var model = this;
	this.send('post', paths.api.principalEmployees(this.id), emps).then(function (data) {
		model.set('employees', data);
	});

},
removeEmployee: function (emp) {
	var col = this.employeesCollection;
	var eId = emp.get('eId');
	var url = emp.urlRoot() + '/' + eId;
	var model = this;
	emp.send('delete', url, null).then(function () {
		col.remove(emp);
		var hash = col.toJSON();
		model.set('employees', hash);
		console.log('REMOVED:', hash);
	});
},
actionAddEmployees: function () {
	//var department = this; //this.model;
	var _exists  = this.getEmployees();
	var exists = _(_exists).map(function (v) { return v.get('eId') });

	var model = this;
	EditValue.modal.single({
		valueType: 'string',
		header: 'Выберите сотрудников',
		controlType: 'selectEmployees',
		sourceValues: svcRadio.request('employeesSelect'),
		viewFilter: function (v) {
			return exists.indexOf(v.model.id) == -1;
		},
	}).then(function (type, ids) {
		model.addEmployees(ids);
	}, function (type, ids) {

	});

},
actionRemoveDepartment: function () {
	//var model = this; //this.model;
	modals.confirm('Вы действительно хотите удалить этот отдел?').done(function () {
		//var parentId = model.get('parentId');
		//model.loudDestroy({ wait: true }).then(function () {
		//	appRadio.request('departments:remove', model.get('id'));
		//	modals.notify('Отдел удалён')
		//	var url = parentId ? link.url('staffDepartmentCard:' + parentId) : link.url('staffDepartments');
		//	Bus.navigate(url);
		//});
	});
},
actionAddDepartment: function () {
	var model = this;
	var url = link.url('staffDepartmentCreate:' + model.get('id'));
	Bus.navigate(url);
},

});
//Model.Employees = function (id) {
//	var col = new BbeCollection([],{ model: EmployeeModelInGroup});
//	col.url = paths.api.departmentEmployees(id);
//	return col;
//}

export default Model;