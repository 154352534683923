﻿//define('svc-deals-deal-dealOfferEdit', ['bus', 'modals', 'behaviors'], function (Bus, modals, beh) {});

import Bus from 'bus';
import modals from '_libs/modals';
import beh from 'behaviors';

import template from './dealOfferEdit.html';
import { MneView } from 'core/mne';

const DealOfferEdit = MneView.extend({
	//template: '#tmpl-service-deals-deal-dealOfferEdit',
	template,
	behaviors: [beh.Form2Hash],
	onEditComplete: function (hash) {
		var view = this;
		var unsetResult = hash.result === '';
		var model = this.model;
		//var isComment = model.get('comment') != hash.comment;
		model.loudPatch(hash).then(function () {
			if (unsetResult)
				model.unset('result');
			

			view.destroy();
		}, function () {
			modals.error('не удалось сохранить изменения');
		});
	},
});


DealOfferEdit.openInModal = function (opts) {
	var view = new DealOfferEdit(opts);
	return modals.modal(view);
}

Bus.Views.reply('dealOffer:edit', function () {
	return DealOfferEdit;
});

export default DealOfferEdit;
