function wrap(value, kind) {
	return `<span class="schema-${kind}">${value}</span>`;
}

export function wrapValue(value) {
	return wrap(value,'value');
}

export function wrapPostfix(value) {
	return wrap(value,'postfix');
}

export function wrapPrefix(value) {
	return wrap(value,'prefix');
}

export function wrapAll(value, options = {}) {
	let empty;
	if (value == null || value === '' || value === options.displayIfEmpty) {
		value = options.displayIfEmpty != null ? options.displayIfEmpty : '';
		empty = true;
	}

	let add = '';
	if (options.property) {
		add = ' ' + options.property.replace(/\./g, '-');
	}

	const wrapedValue = (options.wrapEmptyValue !== false || !empty) ? wrapValue(value) : value;
	const wrapedPostfix = ((!empty && options.postfix) || (empty && options.postfix && options.addPostfixAlways)) ? wrapPostfix(options.postfix) : '';
	const wrapedPrefix = ((!empty && options.prefix) || (empty && options.prefix && options.addPrefixAlways)) ? wrapPrefix(options.prefix) : '';
	return `<span class="schema-value-container${add}">${wrapedPrefix}${wrapedValue}${wrapedPostfix}</span>`;
}