import { Collection } from 'core';
import { urls } from 'coms/urls';
import EditValue from 'ui/controls/editValue';

export function createTagsCollection(tagsNames, options = {}) {
	const models = (tagsNames || []).map(id => ({ id }));
	const col = new Collection(models);
	col.url = urls.api(options.url);
	return col;
}

export function startTagAdding(view, id) {
	// return view.tagsCollection.add({ id });
	return view.tagsCollection.create({ id }, { wait: true });
}

export function handleAddTagClick(view, event) {
	const popupSetup = {
		valueType: 'string',
		header: 'Введите тег русскими буквами без знаков и пробелов',
		inputAttributes: {
			preventPattern: '[^А-Яа-яЁё0-9]',
		}
	}
	EditValue.modal.single(popupSetup)
		.done( (type, newvalue) => {
			if (type != 'ok') return;
			return startTagAdding(view, newvalue);
		});
}