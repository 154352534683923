import { getValueByPath, displayDistance , displayNum, addPostfix } from "utils";
import { propertySchemaApi } from "../../../../../apis/schema";
import { extractPriceOptions, importantFunc, postfixFunc, postfixMeters } from "../../../../../mods/realties/common";
import { getFullPriceSquare, getFullPriceMeter } from 'utils/price';

const priceOptions = {
	offerPath: 'offer',
	offerForCustomerPath: 'offer.forCustomer',
	objectSquarePath: 'object.info.squareOffer',
	objectPath: 'object'
}

export const objectSchema = {
	'square': {
		editable: false,
		displayValue(v,m,s) {
				let square = propertySchemaApi.displayValue('object.info.squareOffer', m, s);
				let squareText = displayNum(square, 1);
				let important = importantFunc(squareText, 'area');
				return addPostfix(important, postfixMeters);
		}
	},
	'floor': {
			editable: false,
			getValue(m, s) {
					return propertySchemaApi.getValue('object.info.floor', m, s);
			},
			displayValue(v, m ,s) {
					if (v != null) {
							return v + postfixFunc('эт.');
					}
			}
	},
	'priceMeterPostfix': {
		editable: false,
		displayValue(v, m, s) {
				const price = propertySchemaApi.displayValue('offer.forCustomer.priceMeter', m, s);
				if (!price) { return; }
				const postfixValue = 'р. за м<sup>2</sup>';
				const postfix = postfixFunc(postfixValue);
				const priceText = importantFunc(price, 'price meter');
				return priceText + postfix;
		}
	},
	'priceSquarePostfix': {
			editable: false,
			displayValue(v, m, s) {
					const price = propertySchemaApi.displayValue('offer.forCustomer.priceSquare', m, s);
					if (!price) { return; }
					const isRent = propertySchemaApi.getValue('isRent', m, s);
					const postfixValue = 'р.' + (isRent ? ' в месяц' : '');
					const postfix = postfixFunc(postfixValue);
					const priceText = importantFunc(price, 'price square');
					return priceText + postfix;
			}
	},
	'offer.forCustomer.priceMeter': {
		label: 'цена за метр',
		valueType: 'number',
		displayDigits: 2,
		postfix: 'р.',
		//controlType: 'editOfferPriceMeter',
		displayValue: (v, model, schema) => {
				const options = extractPriceOptions(model, priceOptions);
				const fullPrice = getFullPriceMeter(options);
				const d = displayNum(fullPrice, 2);
				return d;
		},
		// getControlValue: (value, model) => {
		// 		let attrs = model.attributes || model;
				
		// 		const square = getValueByPath(attrs, 'object.info.squareOffer');
		// 		const nds = attrs.forCustomer.taxType.indexOf('nds') > -1;
		// 		const boma = attrs.forCustomer.boma;
		// 		const operation = attrs.operation;
		// 		return {
		// 				value,
		// 				square,
		// 				nds,
		// 				boma,
		// 				operation
		// 		}
		// },
		// applyNewValue: (forCustomer) => {
		// 		return { forCustomer };
		// }
		// editable: false,
	},
	'offer.forCustomer.priceSquare': {
		label: 'цена за площадь',
		valueType: 'number',
		displayDigits: 2,
		postfix: 'р.',
		controlType:'editOfferPriceSquare',
		refreshOnChange: ['forCustomer.priceMeter'],
		displayValue: (v, model, schema) => {
				const options = extractPriceOptions(model, priceOptions);
				const fullPrice = getFullPriceSquare(options);
				const d = displayNum(fullPrice, 2);
				return d;
		},
		// getControlValue: (value, model) => {
		// 		let attrs = model.attributes || model;
		// 		const square = getValueByPath(attrs, 'object.info.squareOffer');
		// 		const nds = attrs.forCustomer.taxType.indexOf('nds') > -1;
		// 		const boma = attrs.forCustomer.boma;
		// 		const operation = attrs.operation;
		// 		const priceMeter = attrs.forCustomer.priceMeter;
		// 		return {
		// 				value,
		// 				square,
		// 				nds,
		// 				boma,
		// 				operation,
		// 				priceMeter,
		// 		}
		// },
		// applyNewValue: (forCustomer) => {
		// 		return { forCustomer };
		// }
		// editable: false,
	},
	'offer.forCustomer.taxType': {
		valueType: 'enum',
		enumType: 'taxTypes',
		label: 'налогообложение',
	},	
	'isRent': {
		editable: false,
		getValue(m, s) {
				const op = propertySchemaApi.getValue('offer.operation', m, s);
				return op === 'rent';
		}
	},
	'isNds': {
		editable: false,
		getValue(m, s) {
				const tax = propertySchemaApi.getValue('offer.forCustomer.taxType', m, s);
				return !!(tax && tax.indexOf('nds') > -1);
		}
	},
	'isBoma': {
		editable: false,
		getValue(m, s) {
				const op = propertySchemaApi.getValue('offer.operation', m, s);
				const boma = propertySchemaApi.getValue('offer.forCustomer.boma', m, s);
				return op === 'rent' && boma > 1;
		}
	},	
	'priceDetailsWords': {
		editable: false,
		getValue(m,s) {
				const words = [];

				const isNds = propertySchemaApi.getValue('isNds', m, s);
				if (isNds) {
						words.push('НДС');
				}

				const isBoma = propertySchemaApi.getValue('isBoma', m, s);
				if (isBoma) {
						words.push('КОР. КОЭФ.');
				}
				return words;
		},
		displayValue(v, m, s) {
				if (v == null) {
						return;
				}
				if (!Array.isArray(v)) {
						return v;
				}
				return v.join(', ');
		}
	},
	'offer.forAgent.contractType': {
		label: 'агентский договор',
		valueType: 'enum',
		enumType: 'contractTypes',
		displayIfEmpty: '',
	},
	'offer.forAgent.income': {
		label: '% агенту',
		valueType: 'number',
		displayDigits: 2,
		postfix: '%'
	},
	'operationMarket': {
		editable: false,
		displayValue(value, model, modelSchema) {
			let market = propertySchemaApi.displayValue('offer.market', model, modelSchema);
			let operation = propertySchemaApi.displayValue('offer.operation', model, modelSchema);
			return [operation, market].join(' ');
		}
	},
	'offer.status': {
		valueType: 'enum',
		enumType: 'realtyOfferStatuses',
		label: 'статус',
		editable: false,
	},
	'offer.market': {
		valueType: 'enum',
		enumType: 'realtyMarkets',
		label: 'рынок',
		editable: false,
	},
	'offer.operation': {
		valueType: 'enum',
		enumType: 'realtyOperations',
		label: 'операция',
		editable: false,
	},
	'object.info.purposes': {
		valueType: 'enum',
		enumType: 'realtyPurposes',
		label: 'возможные назначения',
		isEmpty: v => !v || v === 'none',
		displayIfEmpty: 'не указаны возможные назначения!'
	},
	'object.info.currentPurpose': {
		valueType: 'enum',
		enumType: 'realtyPurposes',
		label: 'возможные назначения',
		// isEmpty: v => !v || v === 'none',
		isEmpty: v => !v || v === 'none',
		displayIfEmpty: '&nbsp;'
	},	
}