﻿//define('realties-models', ['app-config', 'app-paths', 'realties-offers-models', 'ui-models-values-rest', 'ui-models-values-collection'], function(cfg, paths, offersNs, Rest, ValCol) {});


import cfg from 'app-config';
import paths from 'app-paths';
import Rest from 'ui/models/values/rest';
import ValCol from 'ui/models/values/collection';
import { BbeModel } from 'core/bbe';

var ns = {};

ns.ObjectModel = BbeModel.extend({
    urlRoot: function () {
        if (!cfg.service) return;
        return paths.api.realtiesObject;
    }
});

ns.BuildingModel = BbeModel.extend({
    urlRoot: function () {
        if (!cfg.service) return;
        return paths.api.realtiesBuilding;
    }
});

ns.BuildingsRest = Rest.extend({
    modelParse: function(data) {
        var res = {};
        res.id = data.id;
        res.value = data.id;
        var dd = [];
        dd.addValue(data.name);
        dd.addValue(data.cls);
        var lbl = [dd.join(' ')].addValue(data.address && data.address.metro && data.address.metro.station);
        res.label = lbl.join(', ');
        res.description = [data.prefix, data.address && data.address.fullAddress].onlyValues().join(', ');
        var addr = data.address || {};
        res.coords = { lat: addr.lat, lng: addr.lng };
        res.agency = data.agency;
        return res;
    },
    Collection: ValCol.Single,
    prepareData: function(txt) {
        var data = { text: txt };
        return data;
    },
    defaultUrl: () => paths.api.realtiesBuildingSearch,
    // url: function() {
    //     console.error('# check again:', this);
    //     // const url = this.getOption('url');
    //     return url || paths.api.realtiesBuildingSearch;
    // },
    cardUrl: function () {
        return paths.api.realtiesBuilding + '/' + this.getId() +  "?view=reference";
    },
});


ns.objectUrl = function(oid) {
    return cfg.appSvcUrl('rlt', 'o', oid);
}
ns.buildingUrl = function(bid) {
    return cfg.appSvcUrl('rlt', 'b', bid);
}

export const BuildingsRest = ns.BuildingsRest;

console.error('# check: ', new BuildingsRest());

export default ns;