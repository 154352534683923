import { View } from 'core';
import img from './loading2.gif';
import './loader.less';

export const GifLoaderIndicator = View.extend({
	className: 'loader loader-1',
	template: '<img src="'+img+'">'
});

export const CssLoaderIndicator = View.extend({
	className: 'loader loader-ellipsis',
	template: '<div></div><div></div><div></div><div></div>',
});