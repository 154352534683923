﻿//define('realties-object-owner', [], function () {});
import contacttemplate from './contact.html';
import template from './owner.html';

import { BbeCollection } from 'core/bbe';

import { MneView, MneNextCollectionView } from 'core/mne';

var ContactView = MneView.extend({
	tagName: 'li',
	className: 'list-lbl',
	//template: '#tmpl-service-realties-object-contact',
	template: contacttemplate,
	templateContext: function () {
		var name = this.model.getValueByPath('person.name');
		var _name = name && name.full || this.model.get('contactName');
		var _mainContact = ((this.model.get('values') || [])[0].value || {}).value;
		return {
			_funcNames: this.model.get('ownerFuncs'),
			_name: _name,
			_mainContact: _mainContact,
		}
	},
});

var ContactsView = MneNextCollectionView.extend({
	tagName:'ul',
	childView: ContactView
});

var Layout = MneView.extend({
	className: 'block offset-t',
	//template: '#tmpl-service-realties-object-owner',
	template,
	regions: {
		'contacts':{el:'.contacts-region',replaceElement:true},
	},
	onRender: function () {
		var ocnt = this.getOption('ownerContacts') || [];
		var rcnt = this.getOption('ownerContacts') || [];
		var data = rcnt.length > 0 ? rcnt : ocnt;
		this.showContacts(data);
	},
	showContacts: function (data) {
		if (data.length == 0) return;
		var col = new BbeCollection(data);
		var view = new ContactsView({ collection: col });
		this.showChildView('contacts',view);
	},
	templateContext: function () {
		var _owner = this.getOption('owner');
		var _ownerName = _owner && _owner.name || '';
		return {
			_ownerName: _ownerName
		}
	}
});

export default Layout;