﻿import { $ } from 'vendors';

$(function(){

	$('body').on('click', '.collapse-neighbors-trigger', function (e) {
		var col = $(e.target).closest('.collapse-neighbors');
		var els = col.find('~ *');

		els.slideToggle();

		//els.animate({
		//	'height': '100%'
		//}, 1000);
		//console.log(els.height());
	});
});