import { View } from 'core';
import { _ } from 'vendors';
import './preloader.less';
const PreloaderView = View.extend({
	baseClassName: 'preloader-container',
	template: _.template('<div class="preloader-box"><i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i></div>')
});

export const preloaderApi = {
	fullScreen() {
		let view = new PreloaderView({ thisClassName: 'fullscreen' });
		view.render();
		document.body.appendChild(view.el);
		return view;
	}
}