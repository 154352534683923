module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="actions">\n	<div class="row">\n		<div class="col-xs-12 col-sm-6 text-right">\n			<div class="acion-col">\n				<div>\n					<label>кому: </label>\n					<div class="dropdown" style="display:inline-block">\n						<input class="form-control" name="to" type="email" data-toggle="dropdown"/>\n						<ul class="dropdown-menu">\n							';
 _(emails).each(function(email, label){
__p+='\n							<li><a href="javascript:" data-email="'+
((__t=( email ))==null?'':__t)+
'">'+
((__t=( label))==null?'':__t)+
'</a></li>\n							';
})
__p+='\n						</ul>\n					</div>\n				</div>\n				<div>\n					<label>тема письма: </label>\n					<input class="form-control" name="subject" type="text" />\n				</div>\n			</div>\n		</div>\n		<div class="col-xs-12 col-sm-6">\n			<div class="acion-col">\n				<div>\n					<label>копию мне: </label>\n					<span>\n						<label><input type="radio" value="true" name="copy" checked /> Да</label>\n						<label><input type="radio" value="false" name="copy"  /> Нет</label>\n					</span>\n				</div>\n				<div>\n					<button class="btn btn-default send">отправить</button>\n					<button class="btn btn-primary self-send">отправить себе для проверки</button>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>\n<div class="preview-container">\n\n<div class="holder">\n	<style>\n		td {\n			vertical-align:top;\n		}\n		table.content-row {\n			width: 100%;\n			max-width: 600px;\n		}\n		table.presentation {\n			width:100%;\n			max-width:600px;\n		}\n\n		table.realty-preview {\n			width: 100%;\n			margin-bottom: 15px;\n			border-bottom: 1px solid #eeeeee;\n		}\n\n		table.realty-wrapper {\n			width: 100%;\n		}\n\n		table.realty-wrapper td.realty-thumb {\n			width:88px;\n		}\n\n		table.realty-wrapper td.realty-info {\n			padding-left:15px;\n		}\n\n		table.realty-wrapper td.realty-info p { margin-bottom: 4px }\n\n		table.offer-wrapper {\n			width: 100%;\n			margin:15px 0;\n		}\n		table.offer-wrapper td {\n			padding:5px;\n			font-weight:700;\n		}\n\n		table.offer-wrapper td.offer-id {\n			width:103px;\n		}\n		table.offer-wrapper td.floor {\n			width:50px;\n		}\n\n		table.offer-wrapper td.square {\n			color:#1060e0;\n			width:70px;\n		}\n		table.offer-wrapper td.priceMeter {\n			color: #308020;\n			width:100px;\n		}\n\n	</style>\n	<div>\n		<table class="presentation"></table>\n	</div>\n\n</div>\n<div class="mobile-preview"></div>\n\n</div>\n';
}
return __p;
};
