﻿
import { _ } from 'vendors';
import FilterView from '../filter';
import AllFilters from '../allfilters';
import modals from '_libs/modals';

import template from './layout.html';

import { bbeComparator } from 'utils';

import './fastfilters-control.less';

import { MneView, MneNextCollectionView } from 'core/mne';

var PinnedCollection = MneNextCollectionView.extend({
	childView: FilterView,
	viewComparator: function (a, b) {
		var funcs = [
			['b', function () { return (this.model.group && this.model.group.get('order')) || 0; }],
			['a', function () { return this.model.get('order'); }],
		];
		return bbeComparator(a, b, funcs);
	},
	viewFilter: function (view) {
		//return true;

		var model = view.model;
		if (this.collection.filterPredicate) {
			var predicate = this.collection.filterPredicate(model);
			if (!predicate) return false;
		}


		var pinned = model.get('pinned');
		var hasValues = model.hasValue();
		//console.log(model.get('id'), pinned, hasValues);
		return (pinned === true)
			|| (typeof pinned === 'undefined' && hasValues);
	},
	collectionEvents: {
		'view:refresh': _.debounce(function () {
			this.sort();
		}, 70)
	}
});

var View = MneView.extend({
	className: 'fastfilters-control',
	//template: '#tmpl-ui-controls-fastfilters-layout',
	template,
	ui: {
		'apply': 'button.apply',
		'reset': 'button.reset',
		'allfilters': 'button.allfilters',
		'itemsAmount': '[data-role="itemsCount"]'
	},
	triggers: {
		'click @ui.apply': 'apply',
		'click @ui.reset': 'reset',
		'click @ui.allfilters': 'show:allfilters',
	},
	initialize: function () {
		var filters = this.getFilters();
		if (!filters) return;
		if (!this.collection) {
			this.collection = filters.filters;
		}
		//filters.on('all', (c) => console.log('--=-=-', c));

		//console.log('wasup??')
		this.listenTo(filters, 'data:filtered:first', this.afterFilterApplied);
		this.listenTo(filters, 'data:count:change', this.updateFilterResult);
		//var filters = this.filters = this.getOption('filters');
		//if (!filters) return;


		//var view = this;
		//var radio = this.getOption('radio');
		//if (radio)
		//	this.listenTo(radio, 'collection:filtered', function (fr) {
		//		fr || (fr = {});
		//		if (isValue(fr.founded))
		//			view.updateFilterResult(fr.founded);
		//		else
		//			view.updateFilterResult('-');
		//	});

	},
	getFilters() {
		return this.getOption('filters');
	},
	afterFilterApplied: function (fr) {
		//console.log('after fr', fr)
		this.updateFilterResult(fr.founded);
	},
	updateFilterResult: function (amount) {
		if (!this.ui.itemsAmount.html) return;
		this.ui.itemsAmount.html(amount);
	},
	onApply: function () {
		//var hash = this.collection.getFiltersData();
		//var radio = this.getOption('radio');
		//if (radio)
//             radio.trigger('filters:apply', hash);

		var filters = this.getFilters();
		if (!filters) return;

		filters.apply();

	},
	onReset: function () {
		var filters = this.getFilters();
		if (!filters) return;
		filters.clearValues();
		this.render();
	},
	onShowAllfilters: function () {
		var filters = this.getFilters();
		if (!filters) return;

		var allfilters = AllFilters.create({ filters: filters });
		modals.modal(allfilters, {modalCssCfg:'shortAutoHeight'});

	},
	regions: {
		'pinned':'.pinned-filters-region'
	},
	onRender: function () {
		this.showPinnedCollection();
	},
	showPinnedCollection: function () {
		var colview = new PinnedCollection({
			collection: this.collection,
		});
		this.showChildView('pinned', colview);
	},
	collectionEvents: {
		'data:fetched': function(a1,a2) {
			console.log('data Fetched', a1, a2);
		}
	},
	templateContextExtend: function () {
		let filters = this.getFilters();
		let allFilters = !this.getOption('onlyPined');
		if (filters && filters.getOption('allFiltersDisabled')) {
			allFilters = false;
		}
		return {
			showHeader: this.getOption('noHeader') !== true,
			allFilters
		}
	}
});
View.create = function(opts) {
	var options = _.extend({}, opts);

	if (options.filters && !options.collection)
		options.collection = options.filters.filters;

	return new View(options);
}
View.GetPinnedCollectionView = function () {
	return PinnedCollection;
}

export default View;
