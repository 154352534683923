﻿//define('m-deals-dealOffers', ['m-deals-dealOffer','app-paths'], function (Model, paths) {});

import Model from './dealOffer';
import paths from 'app-paths';

import { BbeCollection } from 'core/bbe';

var Collection = BbeCollection.extend({
	model: Model,
	initialize: function (data, opts) {
		this.parentId = opts.parentId;
	},
	url: function () {
		return paths.api.dealOffers(this.parentId);
	},
});

Collection.create = function (deal, data) {
	var col = new Collection(data, { parentId: deal.getId() });
	return col;
}

export default Collection;
