﻿//define('svc-deals-deal-dealInList',['link','m-deals', 'behaviors'],function(link, dealsNs, beh){});

//let link, dealsNs, beh

// import dealsNs from 'm/deals';
import { DealModel } from 'm/deals/deal';
import beh from 'behaviors';
import dealtemplate from './dealInList.html';
import { MneView } from 'core/mne';


const DealInListView = MneView.extend({
	tagName: 'li',
	className: 'deal deal-list-item list-btn',
	//template: '#tmpl-service-deals-deal-dealInList',
	template: dealtemplate,
	dynamicClass: function () {
		var res = [];
		//res.push('type-' + this.model.get('type'));
		return res.join(' ');
	},
	behaviors: [beh.Datarole.Self, beh.DatarolePopup, beh.DynamicClass],
	modelEvents: {
		'change': 'render'
	},
	onAction: function (context, modal) {
		if (typeof this.model.executeAction === 'function')
			this.model.executeAction({ action: context.type, modal: modal, view: this });
	},
	attributes: function () {
		return {
			'data-role': 'popup',
			'data-type': 'actions',
			'data-id': this.model.get('id'),
		}
	},
	templateContextExtend: function () {
		var name = this.model.get('name') || 'без названия*';
		return {
			name: name,
		}
	}
});


DealInListView.create = function (options) {
	options || (options = {});
	if (!options.model && !options.modelHash) return;

	if (options.modelHash) {
		var model = new DealModel(options.modelHash);
		options.model = model;
	}
	return new DealInListView(options);
}

export default DealInListView;
