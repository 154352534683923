﻿//define('websockets', ['websockets-config', 'websockets-core'], function(cfg, initialize) {});

import initialize from './core';

export default () => {
    return initialize();
}

/*

function loadScript(url, dependOn) {
    //console.log('loading script', url);
    var deferred = $.Deferred();

    var script = document.createElement('script');
    script.src = url;
    script.onload = function () {
        //console.log('success', url);
        return deferred.resolve();
    }
    script.onerror = function() {
        return deferred.reject();
    } 

    $.when(dependOn).then(function() {
        document.body.appendChild(script);
    }, function() {
        deferred.reject();
    });


    return deferred.promise();
}

$(function() {
//     var s = $('#js-hub').get(0);
//     s.onload = function() {
        //console.log('PRI VET')
//     }

    //var jq = loadScript(cfg.jsApiUrl);
    //var hb = loadScript(cfg.jsHubUrl);

    //$.when(jq, hb).then(function() {
    // $.when(hb).then(function() {
    //     //return;
    //     var sspecified = require.specified('websockets-core');
    //     var sdefined = require.defined('websockets-core');
    //     if (sspecified && !sdefined) {
    //         require(['websockets-core']);
    //     }

    // },
    // function() {
    //     console.log('load failed');
    // });


});

*/
