import { propertySchemaApi } from "../../../../apis/schema";
import { getValues, setValueByPath } from "../../../../utils";
import { displayContactValue, phoneTypes } from "../../schema-common";
import { ContactValueControl } from "../ContactValueControl";

export const editContactValueSchema = {
	isPhone: {
		valueType: 'boolean',
		virtual: true,
		getValue(m, s) {
			const type = propertySchemaApi.getValue('value.type', m, s);
			return type in phoneTypes;
		}
	},
	order: {
		name: 'порядок сортировки',
		valueType: 'number'
	},
	purposes: {
		name: 'назначение',
		valueType: 'enum',
		valueSubType: 'contactValuePurposes'
	},
	note: {
		name: 'описание',
		valueType: 'bigtext'
	},
	// value: {
	// 	name: 'контакт',
	// 	controlType: ContactValueControl,
	// 	getControlValue(value, model) {
	// 		const hash = {
	// 			addValue: getValues(model, 'addValue'),
	// 			value: { ...value }
	// 		}
	// 		return hash;
	// 	}
	// },
	'value.value': {
		name: 'значение',
		refreshOnChange:'value.type',
		editAllowed(modelValue, modelSchema, opts = {}) {
			const { isNew } = opts;
			console.warn('[value.value] ::edit allowed?::', opts);
			return !!isNew;
		},
		valueType(m,s) {
			const type = propertySchemaApi.getValue('value.type', m, s);
			if (type in phoneTypes) {
				return 'phone';
			}
			return type;
		},
		displayValue(v, m, s) {
			const type = propertySchemaApi.getValue('value.type', m, s);
			const add = propertySchemaApi.getValue('addValue', m, s);
			const display = displayContactValue[type];
			if (display) {
				return display([v, add], { url: true });
			}
			return v;
		},
		setValue(newValue, m, s) {
			const type = propertySchemaApi.getValue('value.type', m, s);
			if (type in phoneTypes) {
				this._setPhoneValue(newValue, m, s);
				return 'phone';
			}
			setValueByPath(m, 'value.value', newValue);
		},
		_setPhoneValue(newValue, m, s) {
			//debugger;
			console.error('NEW PHONE', newValue, m, s);
			newValue = newValue || {};
			const { value, addValue } = newValue;
			m['value.value'] = value;
			m['addValue'] = addValue;
			// setValueByPath(m, 'value.value', value);
			// propertySchemaApi.setValue(addValue, 'addValue', m, s);
		},
		controlType: ContactValueControl,
		getControlValue(value, m, s) {
			// const phone = propertySchemaApi.getValue('isPhone', m, s);
			// if (!phone) {
			// 	return value;
			// }
			return {
				value,
				addValue: propertySchemaApi.getValue('addValue', m, s)
			}
		}
	},
	'value.type': {
		name: 'тип контакта',
		valueType: 'enum',
		valueSubType: 'contactValueTypes',
		editAllowed(modelValue, modelSchema, opts = {}) {
			const { isNew } = opts;
			console.log('::edit::', opts);
			return !!isNew;
		},

	}
}