﻿//define('m-clients-client', ['bus', 'app-paths', 'm-abstract', 'link', 'action', 'm-contacts', 'm-factory'], function (Bus, paths, abstractNs, link, action, contactsNs, factory) {});


import Bus from 'bus';
import paths from 'app-paths';
import abstractNs from 'm/abstract';
import link from 'link';
import action from '_helpers/action';
import contactsNs from 'm/contacts';
import factory from 'm/factory';

import { BbeModel, BbeCollection } from 'core/bbe';
import { ClientInfoEdit } from '../../service/clients/client/infoEdit';
import { EntityContactEdit } from '../../v/contacts/entityContact/edit';

var Model = abstractNs.ActionableModel.extend(_.extend(
	abstractNs.CardableModel({
			cardView:'client:card',
			cardUrl: function () {
				return link.url('clientCard:' + this.get('wId'));
			},
		}),
		{
			actions: function () {
				return [
					action('openModal', 'показать', {}, { order: 0 })
					///, action('openSearchPage', 'подобрать предложения', {}, { order: 0 })
					, action('changeInfo', 'редактировать', {}, { order: 0 })
					, action('addEntityTask', 'поставить задачу', {}, { order: 0 })
					, action('startDeal', 'запустить процесс', {}, { order: 0 })
					, action('addContact', 'добавить контактное лицо', {}, { order: 0 })
					, action('openPage', 'перейти на страницу клиента', {}, { order: 0 })
				];
			},
			factoryName: 'JsonClient',
			getContacts: function () {
				var rawcontacts = this.get('contacts') || [];
				var entries;
				if (!(rawcontacts instanceof BbeCollection)) {
					entries = contactsNs.EntityContacts.Client(this.getId(), rawcontacts, { asCollection: true });
					this.set('contacts', entries);
					var model = this;
					this.listenTo(entries, 'add remove', function () {
						model.trigger('change:contacts', model);
					});

				} else {
					entries = rawcontacts;
				}
				return entries;
			},
			getEmails: function() {
				var contacts = this.getContacts();
				var result = {};
				contacts.each(function(cnt) {
					var name = cnt.getName();
					var email = cnt.getEmail();
					if(!email)
						return;
					result[name] = email;
				});
				return result;
			},
			getDeals: function () {
				var raw = this.get('deals') || [];
				if (raw instanceof BbeCollection)
					return raw;

				var Entries = Bus.Models.request('deals');
				var entries = new Entries(raw);
				this.set('deals', entries);

				return entries;
			},
			//channel:'Client',
			fetch: function(options) {
				options || (options = {});
				var root = this.urlRoot();
				var chunk = this.get('wId') || this.getId();
				options.url = root + '/' + chunk;
				return BbeModel.prototype.fetch.call(this, options);
			},
			urlRoot: function () {
				return paths.api.clients;
			},
			//actionOpenClientPage: function (options) {
			//	if (options.modal)
			//		options.modal.destroy();

			//	var url = this.cardUrl();
			//	Bus.navigate(url);
			//},
			actionChangeInfo: function (options) {
				if (options.modal)
					options.modal.destroy();

				var View = ClientInfoEdit;
					// Bus.Views.request('client:infoEdit');
				if (View)
					View.openInModal({ model: this });
				else
					console.warn('ClientInfoEdit View not returned');
			},
			actionAddContact: function (options) {
				if (options.modal)
					options.modal.destroy();
				
				var View = EntityContactEdit;
				// Bus.Views.request('entityContact:edit');
				if (View) {
					var contact = new contactsNs.EntityContactModel({ clientId: this.getId() });
					View.openInModal({ model: contact, parent: this });
				}
				else
					console.warn('entityContact:edit View not returned');
			},
			actionAddEntityTask: function (options) {
				if (options.modal)
					options.modal.destroy();
				//store.set(store.KEYS.currentDeal + ":" + this.get('wId'), this.toJSON());
				//var url = link.url('tasksCreate') + "?clientId=" + this.get('wId');
				//Bus.navigate(url);

				//var modal = 
				Bus.app.request('entityTask:create', {
					entity: this,
					entityType: 'client',
				});


			},
			actionStartDeal: function(options) {
				if (options.modal)
					options.modal.destroy();

				//var modal = 
				Bus.app.request('clientDeal:create', {
					client: this,
				});
			},
		}));

factory.setType(Model);

export default Model;