export const buildingSchema = {

	"building.buildYear": {
		caption: 'Год постройки',
		valueType: 'number',
	},
	"building.rebuildYear": {
		caption: 'Год реконструкции',
		valueType: 'number',
	},
	"building.floors": {
		caption: 'Этажность',
		valueType: 'number',
	},
	"building.squareTotal": {
		caption: 'Общая площадь',
		valueType: 'number',
	},
	"building.squareUsefull": {
		caption: 'Полезная площадь',
		valueType: 'number',
	},
	"inner.powerType": {
		caption: 'Электроснабжение',
		valueType: 'enum',
		valueSubType: 'buildingPowerSupplies'
	},

	"inner.powerAmount": {
		caption: 'Мощность электроснабжения, в кВт',
		valueType: 'number',
	},
	"inner.powerAmountPerMeter": {
		caption: 'Мощность электроснабжения на квадратный метр, в Вт',
		valueType: 'number',
	},
	"inner.autonomPowerSupliesCount": {
		caption: 'Количество автономных генераторов электроснабжения',
		valueType: 'number',
		
	},
	"inner.waterSupply": {
		caption: 'Водоснабжение',
		valueType: 'enum',
		valueSubType: 'buildingWaterSupplies'
	},
	"inner.heating": {
		caption: 'Отопление',
		valueType: 'enum',
		valueSubType: 'buildingHeatingTypes'
	},
	"inner.ventilation": {
		caption: 'Вентиляция',
		valueType: 'enum',
		valueSubType: 'realtyVentilationTypes'
	},
	"inner.fireSafety": {
		caption: 'Пожарная безопасность',
		valueType: 'enum',
		valueSubType: 'fireSafety'
	},
	"inner.airConditioning": {
		caption: 'Кондиционирование',
		valueType: 'enum',
		valueSubType: 'realtyAirConditioningTypes'
	},
	"inner.ceilingHeight": {
		caption: 'Высота потолков',
		valueType: 'number',
	},
	"inner.ceilingHeightWork": {
		caption: 'Рабочая высота потолков',
		valueType: 'number',
		
	},
	"inner.floorLoad": {
		caption: 'Максимальная нагрузка на пол',
		valueType: 'number',
		
	},
	"entry.type": {
		caption: 'Безопасность',
		valueType: 'enum',
		valueSubType: 'realtyEntryOptions',
		multiple: true,
	},
	"communicate.phoneProviders": {
		caption: 'Количество провайдеров телефонии',
		valueType: 'number',
		
	},
	"communicate.internetProviders": {
		caption: 'Количество провайдеров интернет',
		valueType: 'number',
		
	},
	"features.type": {
		caption: 'Особенности',
		valueType: 'enum',
		valueSubType: 'realtyFeaturesOptions',
		multiple: true,
	},
	"parking.type": {
		caption: 'Парковка',
		valueType: 'enum',
		valueSubType: 'realtyParkings',
		multiple: true,
	},
	"parking.placesTotal": {
		caption: 'Вместимость стоянки (количество машиномест)',
		valueType: 'number',
		
	},
	"lift.passengerLifts": {
		caption: 'Количество пассажирских лифтов',
		valueType: 'number',
		
	},
	"lift.freightLifts": {
		caption: 'Количество грузовых лифтов',
		valueType: 'number',
		
	},
	"lift.freightTonnage": {
		caption: 'Тоннаж грузового лифта',
		valueType: 'number',
		
	}
}