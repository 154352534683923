﻿//define('svc-leads', ['appModule', 'link', '_libs/identity', 'svc-leads-submodules'], function (Module, link, identity, submodules) {});

import Module from 'appModule';
import link from 'link';
import submodules from './submodules';

var module = Module.content({
    name: 'leads',
    label: 'Лиды',
    routeData: () => link.route('leads'),
    urlKey: 'leads',
    claims: {
        svcLeadsModule: ['view']
    },	        
    onRun: function () {
        this.showButtonsLayout();
    }
});


_(submodules).each(function (smodule) {
    module.addSubModule(smodule);
});

export default module;
