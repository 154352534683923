﻿//define('websockets-core', ['bus','_libs/identity','app-config','websockets-config'], function(Bus, identity, cfg, wsCfg) {});

// import Bus from 'bus';

import { MnObject } from 'vendors';	
import identity from '_libs/identity';
// const identity = {};
import cfg from 'app-config';
import wsCfg from './config';
import { getOldInitializeMethod } from './core-old';
import { getNewInitializeMethod } from './core-new';

///
// wiil be called on wrong socket configuration
///
let initialize = function() {
	console.warn('wrong socket initialization');
	//console.log('sockets initialize wrapper called.')
};


const WSObj = MnObject.extend({
	_initialized: false,
	joinGroup(groupName) { console.log('abstract joinGroup', groupName); },
	leaveGroup(groupName) { console.log('abstract leavenGroup', groupName); },
	start() {
		if (this._startHubTask) {
			return this._startHubTask();
		} else {
			console.warn('sockets','hub start task is not defined');
		}
	}
});

const WS = new WSObj();
if (cfg.sockets.old) {
	let channel = wsCfg.channel;
	initialize = getOldInitializeMethod(WS, channel, wsCfg.hubId, wsCfg.hubUrl, identity);
} else {
	let channel = wsCfg.channel;
	initialize = getNewInitializeMethod(WS, channel, identity, wsCfg.hubUrl);
}


export default initialize;

// // DEPRECATED:

// ///
// // new sockets methods
// ///

// let channel = wsCfg.channel;

// function onModelAdd (name, model, options) {
// 	Bus.trigger(channel, name + ':add', model, options);
// 	Bus.trigger(channel, name, 'add', model, options);
// }
// function onModelChange(name, model, options) {
// 	if (model.id) {
// 		Bus.trigger(channel, name + ':' + model.id + ':change', model, options);
// 		Bus.trigger(channel, name + ':' + model.id, 'change', model, options);
// 	}
// 	Bus.trigger(channel, name + ':change', model, options);
// 	Bus.trigger(channel, name, 'change', model, options);
// }
// function onModelRemove(name, model, options) {
// 	if (model.id) {
// 		Bus.trigger(channel, name + ':' + model.id + ':remove', model, options);
// 		Bus.trigger(channel, name + ':' + model.id, 'remove', model, options);
// 	}
// 	Bus.trigger(channel, name + ':remove', model, options);
// 	Bus.trigger(channel, name, 'remove', model, options);
// }
// ///
// // END new sockets methods
// ///


// function addConnectionIdToHeaders(id) {
// 	$.ajaxSetup({
// 		headers: {
// 			'wscid': id,
// 		}
// 	});
// }



// if (cfg.sockets.old) {
// 	console.warn('sockets:','using old sockets');

// 	var hubClientMixin = {
// 		modelAdd: function (name, model, options) {
// 			console.log('ADD ->', arguments);
// 			Bus.trigger(channel, name + ':add', model, options);
// 			Bus.trigger(channel, name, 'add', model, options);
// 		},
// 		modelChange: function(name, model, options) {
// 			//console.log('options>', options);
// 			console.log('change ->', arguments);
// 			if (model.id) {
// 				Bus.trigger(channel, name + ':' + model.id + ':change', model, options);
// 				Bus.trigger(channel, name + ':' + model.id, 'change', model, options);
// 			}
// 			Bus.trigger(channel, name + ':change', model, options);
// 			Bus.trigger(channel, name, 'change', model, options);
// 		},
// 		modelRemove: function (name, model, options) {
// 			console.log('remove ->', arguments);
// 			if (model.id) {
// 				Bus.trigger(channel, name + ':' + model.id + ':remove', model, options);
// 				Bus.trigger(channel, name + ':' + model.id, 'remove', model, options);
// 			}
// 			Bus.trigger(channel, name + ':remove', model, options);
// 			Bus.trigger(channel, name, 'remove', model, options);
// 		},
// 		dataMessage: function(msg) {
// 			WS.trigger('data:message', msg);
// 			if (msg.data && msg.name) {
// 				var event = msg.name;
// 				WS.trigger(event, msg.data, msg);
// 				if (msg.data.type) {
// 					WS.trigger(event + ':' + msg.data.type, msg.data, msg);
// 				}
// 			}
// 			//console.warn('socket >', msg);
// 		}
// 	}

// 	var hubServerMixin = {
// 		joinGroup(group) {
// 			return this._ensureReady.then(() => {
// 				try {
// 					return this._hub.server.joinGroup(group);
// 				} catch(e) {
// 					console.error(e);
// 				}
// 			});
// 		},
// 		leaveGroup(group) {
// 			return this._ensureReady.then(() => {
// 				try {
// 					return this._hub.server.leaveGroup(group);
// 				} catch (e) {
// 					console.error(e);
// 				}
// 			});
// 		}
// 	}

// 	var wsApiHub = $.connection[wsCfg.hubId];
// 	_.extend(wsApiHub.client, hubClientMixin);

// 	WS._hub = wsApiHub;

// 	_.extend(WS, hubServerMixin);

// 	//////
// 	// wsApiHub.client.modelAdd = function (name, model, options) {
// 	// 	console.log('ADD ->', arguments);
// 	// 	Bus.trigger(channel, name + ':add', model, options);
// 	// 	Bus.trigger(channel, name, 'add', model, options);
// 	// }
// 	// wsApiHub.client.modelChange = function(name, model, options) {
// 	// 	//console.log('options>', options);
// 	// 	console.log('change ->', arguments);
// 	// 	if (model.id) {
// 	// 		Bus.trigger(channel, name + ':' + model.id + ':change', model, options);
// 	// 		Bus.trigger(channel, name + ':' + model.id, 'change', model, options);
// 	// 	}
// 	// 	Bus.trigger(channel, name + ':change', model, options);
// 	// 	Bus.trigger(channel, name, 'change', model, options);
// 	// }
// 	// wsApiHub.client.modelRemove = function (name, model, options) {
// 	// 	console.log('remove ->', arguments);
// 	// 	if (model.id) {
// 	// 		Bus.trigger(channel, name + ':' + model.id + ':remove', model, options);
// 	// 		Bus.trigger(channel, name + ':' + model.id, 'remove', model, options);
// 	// 	}
// 	// 	Bus.trigger(channel, name + ':remove', model, options);
// 	// 	Bus.trigger(channel, name, 'remove', model, options);
// 	// }

// 	// wsApiHub.client.dataMessage = function(msg) {
// 	// 	Bus.trigger('socket:data:message', msg);
// 	// 	if (msg.data && msg.type) {
// 	// 		var event = 'socket:' + msg.type;
// 	// 		Bus.trigger(event, msg.data, msg);
// 	// 		if (msg.data.type) {
// 	// 			Bus.trigger(event + ':' + msg.data.type, msg.data, msg);
// 	// 		}
// 	// 	}
// 	// 	console.warn('socket >', msg);
// 	// }
// 	///////
	

// 	initialize = () => {
// 		//$.connection.hub.withCredentials = false;

// 		if (WS._initialized)
// 			return WS;

// 		WS._initialized = true;

// 		$.connection.transports._logic.ajax = function (connection, options) {
// 			var tokenValue = Bus.channel('identity-token').request('value');
// 			var ajaxOptions = $.extend(true, {}, $.signalR.ajaxDefaults, {
// 				type: "GET",
// 				data: {},
// 				//xhrFields: { withCredentials: connection.withCredentials },
// 				contentType: connection.contentType,
// 				dataType: connection.ajaxDataType
// 			}, options);
// 			ajaxOptions.headers || (ajaxOptions.headers = {});

// 			if (tokenValue) {
// 				ajaxOptions.beforeSend = function (request) {
// 					//console.log('CHECK ME', request);
// 					request.setRequestHeader("Authorization", null);
// 				};
// 			}

// 			return $.ajax(ajaxOptions);
// 		}


		
// 		let ensure = identity.loggedSvcPromise.then(() => {

// 			console.warn('loggedSvcPromise SETTLED');

// 			$.connection.hub.qs = { 'said': identity.get('currentService').serviceId, token: Bus.channel('identity-token').request('value') }
// 			$.connection.hub.url = wsCfg.hubUrl;
		
// 			//console.log('wsCFG', wsCfg);

// 			//	if (wsCfg.hubUrl.indexOf('nrg.loc') > -1) return;
// 			// WS._startHubTask = startTask;
// 			//WS._hub = $.connection.hub;

// 			return $.connection.hub.start().then(function() {
// 				console.warn('old sockets:', 'started');
// 				addConnectionIdToHeaders(wsApiHub.connection.id);
// 			}, (xhr) => {
// 				console.error('old sockets:', 'start failed', xhr);
// 				return xhr;
// 			});

// 		});

// 		WS._ensureReady = ensure;

// 		return WS;
// 	}
	



// } else {
// 	console.warn('sockets:','using <NEW> sockets');

// 	initialize = () => {

// 		if (WS._initialized)
// 			return WS;		

// 		WS._initialized = true;

// 		identity.loggedSvcPromise.then(() => {
// 			var connection = new signalR.HubConnectionBuilder().withUrl(wsCfg.hubUrl).build();

// 			//Disable send button until connection is established
// 			// document.getElementById("sendButton").disabled = true;
			
// 			connection.on("ReceiveMessage", function (user, message) {
// 				console.log('socket message:', arguments);
// 				// var msg = message.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
// 				// var encodedMsg = user + " says " + msg;
// 				// var li = document.createElement("li");
// 				// li.textContent = encodedMsg;
// 				// document.getElementById("messagesList").appendChild(li);
// 			});
			
// 			connection.on('ConnectionId', function(connId){
// 				console.log('socket connection:', connId);
// 			});

// 			connection.on('modelAdd', onModelAdd);
// 			connection.on('modelChange', onModelChange);
// 			connection.on('modelRemove', onModelRemove);

// 			connection.on('testMessage', (...args) => console.log('socket LC', ...args));
// 			connection.on('TestMessage', (...args) => console.log('socket UC', ...args));

// 			connection.start().then(function(){
// 				console.warn('new socket started');
// 				// document.getElementById("sendButton").disabled = false;
// 			}).catch(function (err) {
// 				return console.error(err.toString());
// 			});
// 		});


// 		return WS;

// 	}
// 	// document.getElementById("sendButton").addEventListener("click", function (event) {
// 	// 	var user = document.getElementById("userInput").value;
// 	// 	var message = document.getElementById("messageInput").value;
// 	// 	connection.invoke("SendMessage", user, message).catch(function (err) {
// 	// 		return console.error(err.toString());
// 	// 	});
// 	// 	event.preventDefault();
// 	// });


// }
