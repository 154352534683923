﻿//define('m-contacts-contactValue', ['app-paths'], function (paths) {});

import paths from 'app-paths';

import { BbeModel } from 'core/bbe';

function normalizePhone(str, onlyValue)
{
	if (onlyValue) {
		return str.replace(/\D/gmi, '');
	}
	var a = str.split('.');
	var num = a.pop();
	var pref = '+' + a.join(' ');
	var refnum = num.split('').reverse();
	var res = [];
	_(refnum).each(function (c, ind) {
		var base = ind < 5 ? 2 : 0;
		res.push(c);
		if (base && ((ind + 1) % base) == 0)
			res.push("-");
	});
	return pref + ' ' + res.reverse().join('');
}

function normalizeUrl(str)
{
	if (!str) return '';
	if (str.startsWith('http')) {
		return str.replace(/^https*:\/\//, '');
	} else
		return str;
}

var Model = BbeModel.extend({
	getHtml: function (opts) {
		//console.log(JSON.stringify(this.toJSON()), this.get('type'));
		opts || (opts = {});
		var type = this.get('type');
		var phone = type.indexOf('hone') >= 0;
		var email = type == 'email';
		var _value = this.get('value');

		var pref = phone ? 'tel:'
			: email ? 'mailto:'
			: '';

		var value = phone ? normalizePhone(_value, true)
			: _value;
		var txt = phone ? normalizePhone(_value)
			: normalizeUrl(_value);

		if (this.get('addValue'))
			txt += ', доб. ' + this.get('addValue');

		var attrs = email | phone ? ''
			: ' target="_blank"';

		
		var res = '<span class="contact-value contact-type-' + type + (opts.addClass ? ' ' + opts.addClass : '') + '"><span class="contact-icon"></span><a href="' + pref + value + '" ' + attrs + '>' + txt + '</a></span>';
		return res;
	},
	urlRoot: function () {
		if (this.collection)
			return _.result(this.collection, 'url');

		var cid = this.get('contactId');
		if (!cid) return;

		return paths.api.contactContactValues(cid);
	},
	initialize: function () {
		//console.log('contactValue initialize', this.getParentId(), this);
	},
	getParentId: function () {
		var parentId = this.get('contactId') || this.contactId || (this.collection && this.collection.getOptionValue('contactId'));
		return parentId;
	},
	//channel: function () {			
	//	return Model.channel;
	//},
	//broadcastChannels: function () {
	//	var pid = this.getParentId();
	//	return {
	//		EntityContact: pid
	//	};
	////	//console.log('broadcast', pid)
	////	if(pid)
	////		return 'EntityContact:' + pid;
	//}
});
Model.channel = 'ContactValue';

Model.baseValidate = function (value, type) {
	if (!value) return;
	var hash = typeof value === 'object' ? value : { type: type, value: value };
	if (!(hash.value && hash.type)) return false;

	if (hash.type === 'email') {
		if (/[^a-z0-9\-_.%@]/.test(value))
			return false;
		return /[^@]+@[^.]+\..+/.test(value);
	}
	else if (hash.type.indexOf('hone') > 0) {
		return /\d{1,2}\.\d{2,4}\.\d{5,7}/.test(hash.value);
	}
	else if (hash.type == 'site') {
		return /\S+\.\S+/.test(value);
	}
	else
		return value.length > 3;
};

export default Model;