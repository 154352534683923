﻿//define('realties-building', ['appModule', 'realties-models', 'link', 'realties-building-layout'], function (appModule, realtiesNs, link, Layout) {});

import appModule from 'appModule';
import realtiesNs from 'svc/realties/models';
import link from 'link';
import Layout from 'svc/realties/building/layout';

import './building-classes.less';
import './realties-building.less';
import './realtyobject-in-list.less';


var module = appModule.content({
	name: 'realties-building',
	routeData: function () {
		return link.route('realtiesBuilding');
	},
	onBeforeRun: function (id) {
		var model = new realtiesNs.BuildingModel({ id: id });
		model.loudFetch();
		this.addRunPromise(model.apiPromise);
		this.addRunArgument(model);
	},
	onRun: function (model) {
		//console.log('hello man', model)
		var links = [
			link('на главную', 'root', { attrs: { class: 'go-up' } })
			, link('подбор по списку', 'realtiesSearch')
			, link('подбор по карте', 'realtiesSearchMap')
		];
		//var result = 
		this.createAndShow(Layout, 'Карточка здания ' + model.get('name'), { navLinks: links, model: model }, { navLinks: links });
	}
});

export default module;