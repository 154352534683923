import { Collection } from 'vendors';
import { containsValue, getModuleEnum } from './modules';

const ModuleValuesCollection = Collection.extend({
    initialize() {
        this._updateValues({ silent: true });
        this.on('change:checked', this._updateValues);
        //this.on('all', e => console.log('-[', e));
    },
    getValues() {
        let hash = this.reduce((values, model) => {
            if (model.get('checked')) { 
                values[model.get('value')] = 1;
            }
            return values;
        }, {});
        return Object.keys(hash);
    },
    getTextValue(arr) {
        if (!arguments.length) {
            arr = this.getValues();
        }
        return arr.join(', ');
    },
    hasValues() {
        return this.some(model => model.get('checked'));
    },
    _updateValues({ silent } = {}) {
        //console.log('-on:checked-');
        this.values = this.getValues();
        this.value = this.getTextValue(this.values);
        if (!silent) {
            this.trigger('new:value', this.value);
        }
    }
});


export function getModuleValuesCollection(moduleId, moduleClaimsValue) {
    let enumHash = getModuleEnum(moduleId);
    if (!enumHash) { return; }

    let models = _.map(enumHash, (name, value) => ({ value, name, checked: containsValue(moduleClaimsValue, value) }))
        .filter(f => f.value !== 'none' && !/\w/gmi.test(f.name));

    let collection = new ModuleValuesCollection(models);
    collection.moduleId = moduleId;
    return collection;
}