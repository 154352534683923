import { HamburgerView } from "coms/ui/HamburgerView";
import { ObjectsHeader } from './ObjectsHeader';
import { ObjectsList } from './ObjectsList';
import { Selector } from "./Selector";
import { FilterModel } from "./FilterModel";
import { SelectedContainer } from './SelectedContainer';
import { flatObject } from 'utils';


const ObjectsView = HamburgerView.extend({
	baseClassName: 'owner-in-realty-objects',
	childrenViews: [
		ObjectsHeader,
		ObjectsList,
		SelectedContainer,
	],
	childViewOptions() {
		return {
			objectsSelector: this.objectsSelector,
			offersSelector: this.offersSelector,
			filterModel: this.filterModel
		}
	},
	initialize() {
		this.objectsSelector = new Selector();
		this.offersSelector = new Selector();
		this.filterModel = new FilterModel({ 'object.status': 'actual' });
	},
	childViewEvents: {
		async 'multy:action:click'(type, action, models) {
			console.log('MULTY!');
			let attrs = action.get('attrs');
			const getAttrs = action.get('getAttrs');
			if (!attrs && getAttrs) {
				const defValues = flatObject(models[0]?.toJSON());
				attrs = await getAttrs(defValues);
				if (attrs) {
					const data = await this.model.massPatchAsync(type, models.map(m => m.id), attrs);
					console.log(data);
					return;
				}
			}

			let cb = action.get('action');
			if (typeof cb === 'function') {
				const data = await cb(models);
				console.warn(data);
				return;
			}

			//debugger;
			// const ids = models.map(m => m.id);
			// const context = {
			// 	ids,
			// 	attrs
			// }

			// const data = await this.model.send({ addUrl: 'mass-patch/' + type, method: 'post', attrs: context });
			// this.model.applyMassActionResult(data);
			// console.error('--- data ---', data);
		}
	}
});

export default ObjectsView;