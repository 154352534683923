import { View } from 'core';
import { tryAsync } from 'utils';

export const NextButtonView = View.extend({

	constructor: function() {
		View.apply(this, arguments);
		this._action = this.getOption('action', false);
		this.delegate('click', this.clickHandler.bind(this));
	},

	tagName: 'button',
	wrapEmptyText: true,
	stateClassNames: ['wait', 'disabled'],

	disable(v) {
		if (arguments.length === 0) {
			v = true;
		}
		this.$el.prop('disabled', !!v);
		this.state('disabled', !!v);
	},

	wait(v) {
		if (arguments.length === 0) {
			v = true;
		}
		this.state('wait', !!v);
	},

	getText() {
		const txt = this.getOption('text');
		return txt != null ? txt : '';
	},

	wrapText: text => `<span class="button-text">${text}</span>`,

	getTextHtml() {
		let text = this.getText() || '';
		text = this._tryWrapText(text);
		return text;
	},

	_tryWrapText(text) {
		if (text === '' && !this.getOption('wrapEmptyText')) {
			return '';
		}
		const wrapper = this.getOption('wrapText', false);
		if (typeof wrapper === 'function') {
			const wraped = wrapper.call(this, text, this);
			return wraped;
		}
		return text;
	},

	clickHandler(event) {
		if (this.state('disabled') || this.state('wait')) { return; }
		event.stopPropagation();
		this.takeAction(event);
	},

	async takeAction(event) {
		this.wait();

		this.triggerMethod('before:action:executed', this);

		const res = await tryAsync(() => this._invokeAction(event));
		if (!res.ok && res.error instanceof Error) {
			console.error(res.error);
		}
		this.triggerMethod('action:executed', this, res);

		this.wait(false);
	},

	_invokeAction(event) {
		
		const action = this._action;

		const actionType = typeof action;
		if (actionType === 'function') {
			return action.call(this, event);
		}

		if (actionType === 'string' && action.startsWith('trigger')) {
			let eventName = action.replace(/^trigger:/, '');
			if (!eventName) {
				eventName = 'click';
			}
			return this.triggerMethod(eventName, event, this);
		}

		return action;

	}

	
});