module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h1>Изменение клиента</h1>\n<p>Внесите изменения и нажмите кнопку <code>сохранить</code></p>\n<div class="form-group" data-placeholder="название">\n	<input class="form-control" name="name" value="'+
((__t=( _m.name ))==null?'':__t)+
'" />\n</div>\n<div class="form-group" data-placeholder="род деятельности">\n	<input class="form-control" name="occupation" value="'+
((__t=( _m.occupation ))==null?'':__t)+
'" />\n</div>\n<div class="form-group" data-placeholder="количество сотрудников">\n	<input class="form-control" type="number" name="employees" value="'+
((__t=( _m.employees ))==null?'':__t)+
'" />\n</div>\n<div class="form-group">\n	<div class="form-control radio-btn-group inline" data-placeholder="тип клиента">\n		'+
((__t=( _enumRadiosHtml('clientTypes', _m.type, 'type') ))==null?'':__t)+
'\n	</div>\n</div>\n<div class="form-group">\n	<textarea class="form-control" name="note">'+
((__t=( _m.note))==null?'':__t)+
'</textarea>\n</div>\n<div class="form-group">\n	<button class="btn btn-default edit-complete">сохранить</button>\n	<button class="btn btn-transparent pull-right edit-cancel">отменить</button>\n</div>';
}
return __p;
};
