﻿//define('svc-clients-client-deals',['link', 'svc-deals-deal-dealInList'],function(link, Deal){});


import Deal from 'svc/deals/deal/dealInList';
import { MneNextCollectionView } from 'core/mne';

var Deals = MneNextCollectionView.extend({
	tagName:'ul',
	childView:Deal
});


export default Deals;
