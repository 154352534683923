// import { CoreBbView } from "../../../core/coreBbView";
import { PropertiesBoxView } from 'coms/ui/PropertiesBox';
// import { viewSchemaMixin } from '../../../../apis/schema';
// import { editContactValueSchema } from './editContactValueSchema';
import { viewSchemaMixin } from "../../../apis/schema";
import { HamburgerView } from '../../../coms/ui/HamburgerView';
import { CoreBbView } from '../../../core/coreBbView';
import { contactValueValueSchema } from '../contactValueValueSchema';
import PhoneControl from '../../../v01/ui/controls/phone';
import InputControl from '../../../v01/ui/controls/input';
export const ContactValueControl = HamburgerView.extend({
	getChildren() {
		const valueSchema = this.getValueSchema();
		console.error(this, valueSchema);
		const mixin = {
			onValueChanged: (...args) => {
				this.triggerMethod('value:changed', ...args);
			}
		}
		if (valueSchema.valueType === 'phone') {
			const data = this.getPhoneData();
			return [
				{
					class: PhoneControl,
					// className: 'ui-edit-phone',
					wrapperClass: 'modern-edit',
					data,
					withAdd: true,
					transformValue(hash, stringValue) {
						console.log('---->', stringValue, hash);
						return {
							value: stringValue,
							addValue: hash.add
						}
					},
					...mixin
				}
			]
		} 
		return [
			{
				class: InputControl,
				initialValue: this.getValueValue(),
				...valueSchema,
				...mixin
			}
		]
	},
	getValueValue() {
		const value = this.getOption('value') || {};
		return value.value;
	},
	getPhoneData() {
		const value = this.getOption('value') || {};
		const add = value.addValue;
		const [country, region, number ] = (value.value || '').split(/\./);
		return {
			country, region, number, add
		}
	},
	getValueSchema() {
		return this.getOptions(['valueType','multiple', 'valueSubType', 'sourceValues']);
	},
	// template: 'установи тип, затем значение',
	// thisClassName: 'with-borders colored-values',
	// modelSchema: editContactSchema,
	// setValueBySchemaApi: true,
	// editEnabled: true,
	// ...viewSchemaMixin,
	// modelSchema: contactValueValueSchema,
	// properties: ['value.type', 'value.value'],
	// action: v => ({
	// 	glyph: 'save',
	// 	text: 'сохранить изменения',
	// 	clickAction: () => console.log('chpock', v.getOption('modelHash', true))
	// }),
	
});