// define('svc/actualization/owners/result-collection', [
// 	'app-config'
// ], function(
// 	cfg
// ){});

import cfg from 'app-config';
import { BbeCollection } from 'core/bbe';
const Collection = BbeCollection.extend({
	//model: Model,
	url: function () {
		return cfg.urls.apiV('actualization/owners');
		//return '/svc/v04/actualization/offers';
	}
});
export default Collection;
