﻿//define('svc-realties-searchOnMap-polyInfo', ['bus','m-realties-offers-offers', 'realties-offers-views-collection'], function (Bus, Offers, OffersCollectionView) {});

import Bus from 'bus';
import Offers from 'm/realties/offers/offers';
import OffersCollectionView from 'svc/realties/offers/views/collection';

import template from './polyInfo.html';
import { getValueByPath } from 'utils';
import { triggerMethodOn } from 'vendors';
import { MneView, MneNextCollectionView, MneEmptyView } from 'core/mne';
import { BuildingInList } from '../building/views/inList';

var View = MneView.extend({
	className: 'actions-panel actions-panel-left poly-info',
	//template: '#tmpl-service-realties-searchOnMap-polyInfo',
	template,
	initialize: function (options) {
		this.mergeOptions(options, ['filterObject', 'selectionHolder', 'deal', 'realtyId','poly']);
	},
	triggers: {
		'click header .close':'return:filters'
	},
	regions: {
		'data': { el: '.data-region', replaceElement:true},
	},
	onRender: function () {
		//var offers = this.searchResult = new Offers();
		//if (this.viewState != 'realty')
			this.showRealties();
		//this.showResult();
	},
	getFiltersExtender: function (realtyId) {
		var extender = {};
		extender['bld'] = realtyId;
		return extender;
	},
	showData: function (view) {
		var region = this.getRegion('data');
		if (region.hasView())
			this.detachChildView('data');

		this.showChildView('data', view);
	},
	showRealties: function () {
		if (!this.realtiesView)
			this.realtiesView = this._getRealtiesView();
		var view = this.realtiesView;
		this.showData(view);
	},
	_getRealtiesCollection: function () {
		var col = Bus.request('realties:layer', 'getRealtiesInPoly', this.poly);
		return col;
	},
	_getRealtiesView: function () {
		var _this = this;
		var col = this.realties = this._getRealtiesCollection();
		var childView = BuildingInList;
		// Bus.Views.request('realties:building:inList');
		var view = new MneNextCollectionView({
			className: 'scrollY-region',
			childViewOptions: {
				clickable: true,
			},
			onAttach: function () {
				if (this.lastScrollPosition)
					this.$el.scrollTop(this.lastScrollPosition);
			},
			collection: col,
			emptyView: MneEmptyView.extend({ emptyText: 'подождите, справочник загружается' }),
			onChildviewRememberScroll: function () {
				this.lastScrollPosition = this.$el.scrollTop();
			},
			childView: childView.extend({
				events: {
					'click': function () {
						var id = this.model.getId();
						this.triggerMethod('remember:scroll');
						triggerMethodOn(_this, 'show:realty', id);
					}
				}
			}),
		});
		return view;
	},
	showRealtyOffers: function () {
		this.realtyOffersViews || (this.realtyOffersViews = {});
		this.realtyOffersCollections || (this.realtyOffersCollections = {});
		var rlt = this.selectedRealty;
		if (!this.realtyOffersViews[rlt.id])
			this.realtyOffersViews[rlt.id] = this._getRealtyOffersView(rlt);

		var view = this.realtyOffersViews[rlt.id];

		this.showData(view);

	},
	_getRealtyOffersView: function (rlt) {
		if (!this.realtyOffersCollections[rlt.id])
			this.realtyOffersCollections[rlt.id] = new Offers();
		var col = this.realtyOffersCollections[rlt.id];

		var denySelect;
		var deal = this.getOption('deal');
		if (deal) {
			var offers = deal.getOffers();
			denySelect = offers.map(function (m) { return getValueByPath(m, 'realtyOffer.id'); }).onlyValues();
			//console.log('deny', ids, offers);
		}
		//var view = this;
		var offersExtender = this.getFiltersExtender(rlt.id);

		var OffersView = OffersCollectionView.extend({				
			fetchOnShow: true,
			extendFiltersWith: offersExtender,
			storePosition: function () {
				this.lastScrollPosition = this.$el.scrollTop();
			},
			onAttach: function () {
				if (this.lastScrollPosition)
					this.$el.scrollTop(this.lastScrollPosition);
			},
			childViewOptions: function () {
				return {
					popoverPlacement: 'right',
				};
			}
		});

		var offersView = new OffersView({
			collection: col,
			filterObject: this.filterObject,
			selectionHolder: this.selectionHolder,
			denySelectionFor: denySelect,
		});
		var RealtyView = BuildingInList;
		// Bus.Views.request('realties:building:inList');
		var realtyView = new RealtyView({ model: rlt });
		var _this = this;
		var View = MneView.extend({
			template: _.template('<div class="backButton"><span></span></div><div class="realty-card"></div><div class="offers"></div>'),
			regions: {
				'realty': { el: '.realty-card', replaceElement: true },
				'offers': { el: '.offers', replaceElement: true }
			},
			onRender: function () {
				this.showChildView('realty', realtyView);
				this.showChildView('offers', offersView);
			},
			events: {
				'click .backButton': function () {
					this.getRegion('offers').currentView.storePosition();
					_this.triggerMethod('show:all:realties');
				},
			}
		});

		return new View();
	},
	onShowRealty: function (id) {
		this.selectedRealty = this.realties.get(id);
		this.showRealtyOffers();
	},
	onShowAllRealties: function () {
		delete this.selectedRealty;
		this.showRealties();
	},
	templateContext: function () {
		return {
			header: 'область на карте',
		};
	}
});

export default View;
