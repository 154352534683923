﻿//define('v-contacts-entityContact-editContacts', ['bus', 'modals', 'm-contacts', 'references', 'behaviors-form2Hash', 'behaviors-dynamicClass', 'v-contacts-entityContact-addContactValue'], function (Bus, modals, contactsNs, refs, BehaviorForm2Hash, BehaviorDynamicClass, AddContactValue) {});

import Bus from 'bus';
import modals from '_libs/modals';
import BehaviorForm2Hash from 'behaviors/form2Hash';
import AddContactValue from 'v/contacts/entityContact/addContactValue';

import vvtemplate from './valueView.html';
import ectemplate from './editContacts.html';
import { getOnMethodName } from 'utils';

import './entityContact-editContacts.less';

import { MneView, MneNextCollectionView } from 'core/mne';

var ContactValueView = MneView.extend({
	tagName: 'li',
	className:'list-group-item contact-value-in-list',
	//template: '#tmpl-v-contacts-entityContact-valueView',
	template: vvtemplate,
	regions: {
		'value':'.value-region'
	},
	ui: {
		'delete':'button.delete-value'
	},
	behaviors: [BehaviorForm2Hash.forView({ broadcast: true })],
	events: {
		'click @ui.delete': function () {
			var model = this.model;
			modals.confirm('Подтвердите удаление').then(function () {
				model.destroy();
			});
		},
	},
	onHashChange: function (field, value) {
		var method = getOnMethodName('change:prop:' + field);
		if (_.isFunction(this[method]))
			return this[method].apply(this, arguments);
		else {
			this.model.loudPatch(field, value, { wait: true });
		}
	},
	templateContextExtend: function () {
		return {
			contactHtml: this.model.getHtml(),
		}
	},
});


var View = MneView.extend({
	className: 'entityContact-editContacts',
	//template: '#tmpl-v-contacts-entityContact-editContacts',
	template: ectemplate,
	regions: {
		'contacts': '.contacts-region',
		'add':'.addcontactvalue-region'
	},
	onRender: function () {
		this.showContacts();
		this.showAddContact();
	},
	showContacts: function () {
		var view = new MneNextCollectionView({
			tagName: 'ul',
			className:'list-group',
			childView: ContactValueView,
			collection: this.getOption('collection'),
			//broadcastModel: this.model,
			//broadcastPrefix: contactsNs.ContactValueModel.channel
		});
		this.showChildView('contacts', view);
	},
	showAddContact: function () {
		var opts = _.extend({}, this.options);
		var view = AddContactValue.create(opts);
		this.showChildView('add', view);
	},
	templateContextExtend: function () {
		var m = this.model;
		var name = m.get('contactName') || m.get('person').name.full;
		return {
			name: name
		}
	}
});


View.openInModal = function (opts) {
	var model = opts.model;
	if (model) {
		opts.collection = model.get('values');
		//contactsNs.ContactValueCollection.create({
		//	data: model.get('values') || [],
		//	contactId: model.getId(),
		//	parent: model,
		//});
	} else {
		return;
		//opts.collection = new contactsNs.ContactValueCollection();
	}
	//console.log(opts.collection);
	//var view = new View(opts);
	return modals.modal(new View(opts));
}

export const EntityContactEditContacts = View;

Bus.Views.reply('entityContact:editContacts', function () { return View; });

export default View;