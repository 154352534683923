module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="form-group">\n	<label>комментарий</label>\n	<textarea class="form-control" '+
((__t=( _f2h("comment")))==null?'':__t)+
'>'+
((__t=( _m.comment ))==null?'':__t)+
'</textarea>\n</div>\n<div class="form-group">\n	<div class="form-control radio-btn-group inline" data-placeholder="флаг">\n		<div class="radio"><label><input type="radio" value="" '+
((__t=( _f2h("result")))==null?'':__t)+
' '+
((__t=( _ift(_m.result != true && _m.result !== false, '', 'checked')))==null?'':__t)+
' /><span class="input-label">без флага</span></label></div>\n		<div class="radio"><label><input type="radio" value="true" '+
((__t=( _f2h("result")))==null?'':__t)+
' '+
((__t=( _ift(_m.result == true,'','checked')))==null?'':__t)+
'/><span class="input-label">положительный</span></label></div>\n		<div class="radio"><label><input type="radio" value="false" '+
((__t=( _f2h("result")))==null?'':__t)+
' '+
((__t=( _ift(_m.result == false,'','checked')))==null?'':__t)+
'/><span class="input-label">отрицательный</span></label></div>\n	</div>\n</div>\n<div class="form-group">\n	<button class="edit-complete">сохранить</button>\n</div>';
}
return __p;
};
