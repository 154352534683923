﻿// define('m-realties-owners', [
// 	'm-realties-owners-owner', 'm-realties-owners-collection'
// 	, 'm-realties-owners-contactsInRealty'
// ], function (Model, Collection) {});

import OwnerModel from './owner';
import OwnersCollection from './collection';

import { ContactsInRealtyModel } from './contactsInRealty';

// const OwnerModel = Model;
// const OwnersCollection = Collection;

var ns = {
    OwnerModel,
    OwnersCollection
};

// ns.OwnerModel = Model;
// ns.OwnersCollection = Collection;

export default ns;

export {
    OwnerModel,
    OwnersCollection,
    ContactsInRealtyModel
}