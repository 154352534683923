﻿//define('m-employees-model', ['bus', 'references', 'link', 'app-paths', 'm-abstract', 'action', 'ui-controls-editValue'], function (Bus, refs, link, paths, abstractNs, action, EditValue) {});


//let Bus, refs, link, paths, abstractNs, action, EditValue;
import refs from 'references';
import paths from 'app-paths';
import abstractNs from 'm/abstract';
import action from '_helpers/action';
import EditValue from 'ui/controls/editValue';

import { unFlatObject } from 'utils';

var Model = abstractNs.ActionableModel.extend({
	getName: function () {
		var name = this.get('name');
		var fullName = this.get('fullName');
		if (fullName && fullName.full)
			return fullName.full;

		if (!!name && _.isString(name))
			return name;
		
		return (this.get('name') || {}).shortFull || this.get('email') || 'безымянный сотрудник';
	},
	actions: function () {
		const actions = [
			action('openPage', 'перейти на страницу сотрудника'),
			action('changeState', 'изменить статус', { staff: 'changeEmployeeState' }, { order: 0 }),
			action('fire', 'Уволить', { svcEmployeesModule: 'fire' }, { order: 0 }),
			action('hire', 'Взять на работу', { svcEmployeesModule: 'hire' }, { order: 0 }),
		];
		console.error('emnployee actions', actions);
		return actions;
	},
	isActive: function () {
		return !this.isFired() && this.isReal();
	},
	isFired: function () {
		return this.get('state') == 'fired';
	},
	isVirtual: function () {
		return !this.get('uId');
	},
	isVirtalActive: function () {
		return this.isVirtual() && !this.isFired();
	},
	isReal: function () {
		return !this.isVirtual();
	},

	//actionOpenEmployeePage: function (options) {
	//	if (options.modal)
	//		options.modal.destroy();

	//	var url = link.url('staffEmployeeCard:' + this.get('id'));
	//	Bus.navigate(url);

	//},

	actionHire() {
		return this.actionChangeState();
	},
	actionFire() {
		return this.actionChangeState();
	},

	actionChangeState () {

		var model = this;
		var popupSetup = {
			valueType: 'string',
			header: 'Установите новый статус',
			value: this.get('state'),
			sourceValues: refs.enum('employeeStates'),
			noComparator: true,
		};
		var field = 'state';
		EditValue.modal.single(popupSetup)
			.done(function (type, newvalue) {

				if (type != 'ok') return;

				if (newvalue instanceof Array && !popupSetup.multiple)
					newvalue = newvalue[0];

				var hash = {};
				hash[field] = newvalue;
				var unflatHash = unFlatObject(hash);
				model.loudPatch(unflatHash);

			});

	},

	urlRoot: function () {
		return paths.api.employees
	}

});
//console.log('actionable', Model);
export default Model;