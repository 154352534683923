﻿//define('ui-layouts-contactsList', ['bus', 'm-clients', 'm-contacts', 'm-realties-owners', 'behaviors-dynamicClass'], function (Bus, clientsNs, contactsNs, ownersNs, BehaviorDynamicClass) {});

import Bus from 'bus';

// import clientsNs from 'm/clients';
// import contactsNs from 'm/contacts';
// import ownersNs from 'm/realties/owners';
// import BehaviorDynamicClass from 'behaviors/dynamicClass';

import { BbeCollection } from 'core/bbe';

// import { MneView, MneNextCollectionView } from 'core/mne';

import { CollectionView } from 'core/collection-view';
import { OwnerInListView } from '../../../v/realties/owners/owner/inList';
import { ClientInListView } from '../../../service/clients/client/clientInList';
import { EntityContactInListView } from '../../../v/contacts/entityContact/inList';
import { EntityContactModel } from '../../../m/contacts';
import { ClientModel } from '../../../m/clients';
import { OwnerModel } from '../../../m/realties/owners';

// var ListItem = MneView.extend({
// 	tagName: 'li',
// 	template:_.template('<span>blyam</span>'),
// });


const List = CollectionView.extend({
	tagName: 'ul',
	// behaviors: [BehaviorDynamicClass],
	initialize() {
		console.warn('<<contacts list view>>', this);
	},
	childView: function (child) {
		//console.log(child instanceof contactsNs.EntityContactModel, child);
		if (child instanceof ClientModel) {

			return ClientInListView;

		} else if (child instanceof OwnerModel) {

			return OwnerInListView;
			// let view = Bus.Views.request('owner:inList');
			// console.log('- owner model CV - ', view);
			// return view;
		}
		else if (child instanceof EntityContactModel) {
			return EntityContactInListView;
			// let view = Bus.Views.request('entityContact:inList');
			// console.log('- entity contact model CV - ', view);
			// return view;
		}
		else {
			console.log('view not found', child);
		}
		// return ListItem;
	},
	childViewOptions: function () {
		//console.log('cvo:', this.getOption('realtyId'))
		return {
			realtyId: this.getOption('realtyId')
		}
	}
});


List.ForClient = function(client, options) {
	if (!client) return;
	var contacts = client.getContacts();
	var raw = [client].concat(contacts.models);
	var entries = new BbeCollection(raw);
	return new List(_.extend({ collection: entries, parent: client },options));
}

List.ForOwnerInRealty = function (ownerInRealtyContacts, options) {
	options || (options = {});
	if (!ownerInRealtyContacts) return;
	
	
	var realtyId = ownerInRealtyContacts.get('realtyId');
	var owner = new OwnerModel(ownerInRealtyContacts.get("owner"));

	owner.addContactsFromInRealtyContact(ownerInRealtyContacts);

	var ready = owner.getRealtyContactsModels(realtyId);


	//var rcontacts = ownerInRealtyContacts.get("contactsInRealty") || [];
	//var ocontacts = ownerInRealtyContacts.get("contacts") || [];

	//var contacts = contactsNs.EntityContacts.Owner(owner.getId(), (rcontacts.length ? rcontacts : ocontacts), {  });
	////var contacts = (rcontacts.length ? rcontacts : ocontacts).map(function (m) { return new contactsNs.EntityContactModel(m); });
	//var rdy = [owner].concat(contacts);

	var entries = new BbeCollection([owner].concat(ready));
	if (owner.hasRealtyContacts(realtyId))
		options.hasRealtyContacts = true;

	var opts = _.extend({ collection: entries, parent: owner, realtyId: realtyId }, options);
	//console.log('??', realtyId, opts,)
	var listView = new List(opts);
	//console.log(Bus.channel('ownerInRealty'));
	listView.listenTo(Bus.channel('ownerInRealty'), 'add:contact', function (newContact) {
		console.log('on triggrd', entries);
		entries.add(newContact);
		console.log('>>', newContact);
		console.log('>>', entries.first().toJSON());
	});
	
	return listView;
};

export default List;