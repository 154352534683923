import appCfg from 'app-config';

export function getRootUrl() {
    return appCfg.urls.apiV('realties/objects');
}

export function getSimpleRootUrl() {
    let url = getRootUrl();
    return url + '/:id/simple';
}

export const modelMixin = {
    urls: {
        root: getRootUrl,
        simple: getSimpleRootUrl
    }
}