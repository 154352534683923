﻿// define('svc-deals-create-layout', [
// 	'store','bus', 'modals', 'link', 'references', 
// 	'm-deals', 'm-contacts', 'm-clients', 'ui-models-filters', 'ui-controls-fastfilters', 
// 	'svc-deals-create-clientLayout', 'svc-deals-create-lead', 'svc-deals-create-createLayout', 'behaviors-dynamicClass', 'm-factory'], 
// function (store, Bus, modals, link, refs, 
// 	dealsNs, contactsNs, clientsNs, Filters, FastFiltersView, 
// 	ClientLayout, LeadLayout, CreateLayout, BehaviorDynamicClass, factory) {});


import store from '_libs/store';
import Bus from 'bus';
import modals from '_libs/modals';
//import link from '';
//import refs  from '';

import dealsNs from 'm/deals';
import contactsNs from 'm/contacts';
import clientsNs from 'm/clients';
//import Filters from '';
//import FastFiltersView  from '';

import ClientLayout from './client';
import LeadLayout from './lead';
import NewLeadLayout from './lead-block';
import CreateLayout from './createLayout';
import BehaviorDynamicClass from 'behaviors/dynamicClass';
//import factory from 'm/factory';

import { Model as BbModel, _ } from 'vendors';

import template from './layout.html';

import { BbeCollection } from 'core/bbe';

import { JsGuid } from 'coms/JsGuid';

import { TabView, TabsView } from './nav';

import { MneView } from 'core/mne';
// import { getBuildingName } from '../../../m/realties/buildings/buildings';
import { getRealtyName } from 'mods/realties/realties/realties';

import link from 'link';

var DealStore = BbeCollection.extend({
	model: dealsNs.DealModel
});


// var TabView = MneView.extend({
// 	tagName:'li',
// 	template: _.template('<a href="#">#<%=name%></a>'),
// 	modelEvents: {
// 		'change':'render',
// 	},
// 	dynamicClass: function () {
// 		var cur = this.options.getCurrentModel && this.options.getCurrentModel();
// 		if (cur && this.model == cur)
// 			return 'active';
// 	},
// 	events: {
// 		'click': function (e) {
// 			var model = this.model || 'new';
// 			this.triggerMethod('switch:to', model);
// 		},
// 	},
// 	behaviors: [BehaviorDynamicClass],
// 	templateContext: function () {
// 		var name = this.model && this.model.get('name') || this.getOption('text');
// 		return {
// 			name: name,
// 		}
// 	}
// });

var Layout = MneView.extend({
	//template: '#tmpl-service-deals-create-layout',
	template,
	regions: {
		'predeals': { el: '.predeals-region', replaceElement: true },
		'client': { el: '.client-region', replaceElement: false },
		'lead': { el: '.lead-region', replaceElement: false },
		'create': { el: '.create-region', replaceElement: false },
	},
	ui: {
		'create': 'button.create-process',
		'drop': 'button.drop-process',
	},
	initialize: function () {
		var data = store.get('deal:create:data', { local: true }) || [];
		var storeCol = this.storeCol = new DealStore(data);
		if (this.getOption('client')) {
			var client = this.getOption('client');
			var founded = storeCol.filter(function (f) {
				return f.get('client').id === client.id
			})[0];
			this.tryToGetModel = founded || 'new';
			if (!founded) {

				this.tryToGetModel = this.initModel({ client: client });
			}
		}
	},
	isInModal: function () {
		return !!this.renderedInModal || this.getOption('modaled');
	},
	onRender: function () {
		this.initModel();

		if (!this.isInModal()) {
			this.showTabs();
			if (!this.getOption('client'))
				this.showSearch();
		}
		this.showLead();

		if (this.getOption('client'))
			this.showCreate();

		this.toggleCreateBtn();
	},
	switchTo: function (model) {
		console.log('++SWITCH:TO++', model);
		this.tryToGetModel = model;
		this.releaseModel();
		this.render();
	},
	initModel: function (options) {
		options || (options = {});

		var storeCol = this.storeCol;
		var selected = storeCol.last();

		if (this.tryToGetModel) {
			if (this.tryToGetModel == 'new')
				selected = null;
			else
				selected = this.tryToGetModel;
		}

		var model = this.model = selected ? selected : new dealsNs.DealModel();
		var newModel = false;
		if (!model.getId()) {
			newModel = true;
			var newhash = { id: JsGuid() };
			if (options.client) {
				newhash.client = options.client;
				newhash.clientId = options.client.id;

			}
			model.set(newhash, { silent: true });
		}

		var client = model.getClient();
		if (!client) {

			model.set('client', new clientsNs.ClientModel(), { silent: true });
		}

		this.listenTo(model, 'change:client', this.toggleCreateBtn);
		this.listenTo(model, 'recalc:name', this.onModelChanged);
		this.listenTo(model, 'storeable:change', this.onModelChanged);

		if (newModel && !this.isInModal()) {

			storeCol.add(model);
			store.set('deal:create:data', storeCol.toDeepJSON(), { local: true });

		}
		return model;
	},
	showTabs: function () {
		var col = this.storeCol;
		var view = this;
		// console.log('==МШУЦ==', view.model, view.model.attributes.name);

		// var tabsView = new MneNextCollectionView({
		// 	tagName: 'ul',
		// 	className: 'nav nav-tabs before-boardcontainer',
		// 	childView: TabView,
		// 	collection: col,
		// 	childViewOptions: function () {
		// 		return {
		// 			collection: col,
		// 			getCurrentModel: function () {
		// 				return view.model;
		// 			},
		// 		};
		// 	},
		// 	onRender: function () {
		// 		var createView = new TabView({ text: 'создать новый' });
		// 		this.addChildView(createView, this.collection.length);
		// 	},
		// 	childViewEvents: {
		// 		'switch:to': function (model) {
		// 			console.log('=switch:to=', model);
		// 			view.switchTo(model);
		// 		}
		// 	}
		// });

		let tabsView = new TabsView({
			collection: col,
			currentModel: view.model,
			onSwitchTo(model) {
				console.log('_TRY_TO_SWITCH_');
				view.switchTo(model);
			},
			onClearAll() {
				view.clearAll();
			}
		});

		this.showChildView('predeals', tabsView);
	},
	onModelChanged: function () {
		var json = this.storeCol.toDeepJSON();
		store.set('deal:create:data', json, { local: true });
	},
	releaseModel: function (success, oldId) {
		var model = this.model;
		this.stopListening(this.model);
		delete this.personHash;
		delete this.contactChecked;
		if (success) {
			delete this.tryToGetModel;
			var col = this.storeCol;
			col.remove(model);
			this.onModelChanged();
			model.clear();
		}
	},
	clearAll() {
		let thisModel = this.model;
		let models = [...this.storeCol.models];
		models.forEach(model => {
			if (thisModel === model) { return; }
			this.storeCol.remove(model);
		});
		this.releaseModel(true);
		this.render();
	},
	showLead: function () {
		// var lead = this.leadView = new NewLeadLayout({ model: this.model });
		var lead = new LeadLayout({ model: this.model });
		this.showChildView('lead', lead);
	},
	showSearch: function () {
		var client = this.clientView = new ClientLayout({ model: this.model });
		this.showChildView('client', client);
	},
	showCreate: function (result) {
		var create = this.createView = new CreateLayout({ model: this.model, search: result });
		this.showChildView('create', create);
	},
	childViewEvents: {
		'exist:client:select': function (client) {
			this.model.set('client', client, { silent: true });
			this.model.trigger('change:client', this.model);
			this.model.trigger('recalc:name', this.model);
			this.triggerMethod('model:changed');
		},
		'joined:client:remove': function () {
			this.model.set('client', null);
			this.model.trigger('recalc:name', this.model);
			this.triggerMethod('model:changed');
		},
		'phonesearch:result': function (result) {
			
			var cntval = {
				type: 'phone',
				value: result.phone
			};

			this.model.get('lead').contactValue = cntval;

			this.contactChecked = true;
			delete this.personHash;
			this.showCreate(result);
			this.toggleCreateBtn();
			this.model.trigger('recalc:name', this.model);
			this.triggerMethod('model:changed');
		},
		'custom:person:change': function (hash) {
			this.personHash = hash;
			//this.model.newPersonName = hash['person-name'];
			//this.model.newCompanyName = hash['company-name'];
			this.model.trigger('recalc:name', this.model);
			this.triggerMethod('model:changed');
			this.toggleCreateBtn();
		}
	},
	toggleCreateBtn: function () {
		var lead = this.model.get('lead') || {};
		var client = this.model.getClient();
		var isClient = client && client.getId();
		var isPersonhash = !isClient && this.validatePersonHash(lead);
		var valid = isClient || isPersonhash;
		this.ui.create.prop('disabled', !valid);
	},
	validatePersonHash: function (h) {
		if (!h) return false;
		return !!h.personName;
	},
	events: {
		'click @ui.create': 'createDeal',
		'click @ui.drop': function () {
			var view = this;
			modals.confirm('подтвердите удаление черновика процесса').then(function () {
				view.releaseModel(true);
				view.render();
			});
		}
	},
	createDeal: function () {
		//var _client = this.model.get('client');
		if (this._dealCreating) {
			console.error('Deal already creating. wait while this will be done');
			return;
		}
		this._dealCreating = true;

		var client = this.model.getClient();
		var personHash = this.personHash || {};


		if ((!client || !client.getId()) && personHash['company-name']) {
			if (!client) {
				client = new clientsNs.ClientModel();
				this.model.set('client', client); // WARNING! "this.model.set('client') = client;"
			}
			client.set('name', personHash['company-name']);
		}
		var check = [];
		var phone1 = personHash['person-phone1'];
		var phone2 = personHash['person-phone2'];
		var email = personHash['person-email'];
		if (contactsNs.ContactValueModel.baseValidate(phone1, 'phone')) {
			check.push({ type: 'mobilePhone', value: phone1 });
		}
		if (contactsNs.ContactValueModel.baseValidate(phone2, 'phone')) {
			check.push({ type: 'mobilePhone', value: phone2 });
		}
		if (contactsNs.ContactValueModel.baseValidate(email, 'email')) {
			check.push({ type: 'email', value: email });
		}
		if (personHash['person-name']) {
			console.log('person present');
			var contacts = client.getContacts();
			var rawcontacts = contacts.toDeepJSON();
			var cv = _(rawcontacts).chain().pluck('values').flatten().value();
			var add = _(check).filter(function (ch) {
				return !_(cv).some(function (cvi) {
					var _cvitype = (cvi.type || '').indexOf('hone') > 0 ? 'phone' : cvi.type;
					var _chtype = ch.type.indexOf('hone') > 0 ? 'phone' : ch.type;
					return _cvitype == _chtype && cvi.value == ch.value;
				});
			});
			if (add.length) {
				var entityContact = new contactsNs.EntityContactModel({ person: { name: { first: this.personHash['person-name'] } } });
				var cvalues = entityContact.getValues();
				cvalues.add(add);
				contacts.add(entityContact);
				console.log('person added to client')
			}
			console.log('result', phone1, phone2, check, add);
		}

		var view = this;
		var oldId = this.model.getId();
		var lead = this.model.get('lead');
		if (lead.realtyId) {
			lead.realtyName = getRealtyName(lead.realtyId);
			// Bus.svc.request('building:name', lead.realtyId);
		}
		this.model.unset('id');

		var _this = this;
		var inModal = this.isInModal();

		if (this.model.get('client') instanceof BbModel) {
			var json = this.model.get('client').toDeepJSON();
			this.model.set('client', json, {silent:true});
			console.log('is backbone model', this.model.get('client'), json);
		} else {
			console.log('client is not a model');
		}

		//console.log('saved model', this.model.toJSON());			
		//return;

		this.model.loudSave().then(() => {
			this._dealCreating = false;
			let wId = this.model.get('wId');
			let dealUrl = link.svcurl_v4('deal', wId);
			let txt = `<div class="padding-v"><big>Создан процесс #${wId}.</big> <small><a href="${dealUrl}" target="_blank">открыть в новой вкладке</a></small></div>`;
			modals.confirm('Процесс добавлен', { cancel: false, okText: 'OK', text: txt }).then(() => {
				if (inModal) {
					_this.destroy();
				} else {
					view.releaseModel(true, oldId);
					view.render();
				}
			});
		}, () => {
			this._dealCreating = false;
		});

	},
	childViewTriggers: {
		'known:responsible:click':'known:responsible:click'
	},
	onKnownResponsibleClick() {
		console.warn('#chpok');
	}
});

Bus.app.reply('clientDeal:create', function(options) {
	options || (options = {});
	var view = new Layout({ client: options.client, modaled: true });
	return Bus.modals.request('fullscreen', view);
});



export default Layout;
