const asyncResultSymbol = Symbol("async-result");

export async function tryAsync(method, options) {
	let value;
	if (typeof method === 'function') {
		value = tryCall(method, options);
	} else {
		value = method;
	}
	const result = await tryAwait(value, options)
	return result;
		//toAsyncResult(result);
}

function tryCall(method, options) {
	try {
		const value = method();
		return value;
	} catch (error) {
		return errResult(error);
	}
}

async function tryAwait(promise, options) {
	try {
		const value = await promise;
		return toAsyncResult(value, options);
	} catch (error) {
		return errResult(error);
	}
}


function toAsyncResult(value, options) {
	if (isLooksLikeAsyncResult(value)) {
		return value;
	}

	const error = validate(value, options);
	
	return error ? errResult(error) : okResult(value);
}

function isLooksLikeAsyncResult(value) {
	const isObj = !!value && typeof value === 'object' && !Array.isArray(value);
	if (!isObj) { return false };
	return (asyncResultSymbol in value === true)
	|| (hasAny(value, 'ok') && hasAny(value, 'error', 'value'));

}

function hasAny(obj, ...keys) {
	return keys.some(key => key in obj === true);
}

// should return undefined for OK;
// empty array will be converted to undefined
// any other value become error, 
function validate(value, options) {
	let method = options?.validate || options;
	if (typeof method === 'function') {
		let res = method(value);
		if (Array.isArray(res) && res.length === 0) {
			res = undefined;
		}
		return res;
	}
}







export function okResult(value, ext = {}) {
	return {
		ok: 1,
		...ext,
		value,
		[asyncResultSymbol]: 1
	}
}

export function errResult(error, ext = {}) {
	return {
		ok: 0,
		...ext,
		error,
		[asyncResultSymbol]: 1
	}
}
