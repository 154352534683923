﻿//define('m-realties-offers', ['m-realties-offers-offer', 'm-realties-offers-offers'], function (Offer, Offers) {});

import OfferModel from './offer';
import OffersCollection, { OffersCollectionV4 } from './offers';

export { 
	OfferModel,
	OffersCollection,
	OffersCollectionV4
}

var ns = {};
ns.OfferModel = OfferModel;
ns.OffersCollection = OffersCollection;

export default ns;
