﻿//define('svc-realties-offers-views-cardInModal-objectApartment', [], function () {});

import template from './objectApartment.html';
import { MneView } from 'core/mne';

var View = MneView.extend({
	className: 'block',
	//template: '#tmpl-service-realties-offers-views-cardInModal-objectApartment',
	template,
});

export default View;
