import { HamburgerView } from "coms/ui/HamburgerView";
import { MainInfoLine } from "./MainInfoLine";
import { ContactsInfoLine } from "./ContactsInfoLine";
import { TagsInfoLine } from "./TagsInfoLine";
import { NoteInfoLine } from "./NoteInfoLine";



const InfoBoxView = HamburgerView.extend({
	baseClassName: 'box flex-columns info-box',
	stateClassNames: ['collapsed'],
	childrenViews: [
		MainInfoLine,
		ContactsInfoLine,
		TagsInfoLine,
		NoteInfoLine,
	],
	modelEvents: {
		'info:toggled'() {
			this.state('collapsed', this.model._infoCollapsed);
		}
	},
});

export default InfoBoxView;