﻿//define('m-principalRights', [], function() {});

import { BbeModel, BbeCollection } from 'core/bbe';

var UpdateModel = BbeModel.extend({});

var Collection = BbeCollection.extend({
    initialize: function() {
        this.on('change',this.updateRights)
    },
    updateRights: function() {
        var model = new UpdateModel({rights:this.toJSON()});
        model.url = _.result(this,'url');
        model.loudSave();
    },
});

export default Collection;