﻿//define('modals-modal', ['modals-config'], function (cfg) {});

import cfg from './modals-config';
import { BbView } from 'vendors'
import { MneView } from 'core/mne';
import { elementInDom } from 'utils';

function css(init, add) {
    var arr = [init].concat(add instanceof Array ? add : [add]);
    return arr.onlyValues().join(' ');
}

import template from './modal.html';
import './modals.less';

var View = MneView.extend({
    className: 'modals-dialog',
    //template: '#tmpl-libs-modals-modal',
    template,
    regions: {
        'content': { el: '.modal-box > section', }
    },
    ui: {
        'bg': '.modal-bg',
        'close': '.modal-close',
        'wrapper': '.modal-wrapper',
        'box': '.modal-box',
        'content': '.modal-box > section',
    },
    setCssClasses: function() {
        var cls = this.cssClasses || {};
        this.$el.attr('class', css('modals-dialog', cls.dialog));
        this.ui.wrapper.attr('class', css('modal-wrapper', cls.wrapper));
        this.ui.box.attr('class', css('modal-box', cls.box));
        this.ui.content.attr('class', css('', cls.content));
    },
    fitHeight: function() {
        var fit = this.$el.hasClass('autoheight');
        if (!fit) return;

        var mh = this.ui.box.height();
        var wh = $(window).height();

        if (mh > wh) {
            this.ui.box.addClass('fullheight');
            this.ui.box.attr({
                style: ''
            });
        } else {
            this.ui.box.removeClass('fullheight');
            this.ui.box.css({ top: (wh - mh) / 2 });
        }

    },
    // animations: {
    //     render: true,
    //     destroy: true
    // },
    initialize: function() {
        var content = this.options.content;
        //if (typeof content === 'object') {
        if (content instanceof BbView) {
            content.renderedInModal = this;
            this.listenTo(content, 'destroy', this.destroy);
            this.listenTo(content, 'refresh', this.onContentRender)
            this.content = content;
        }
        this.cssClasses = _.extend({}, (this.options.cssClasses || {}));
        this.render();
    },
    events: {
        'click': 'onClick',
    },
    closeHandler: function(e) {
        this.destroy();
    },
    onClick: function(e) {
        var wrap = this.ui.wrapper.get(0);
        var bg = this.ui.bg.get(0);
        var close = this.ui.close.get(0);
        var targetclose = $(e.target).closest('.modal-close');
        if (e.target == wrap || e.target == bg || (targetclose && targetclose.get(0) == close))
            this.destroy();

    },
    onContentRender: function() {
        this.setCssClasses();
        this.fitHeight();
    },
    onRender: function() {

        this.$el.appendTo($('body'));

        //if (true) return;
        
        if (this.content) {
            try {
                var replaceEl = this.getOption('replaceContent') === true;
                this.showChildView('content', this.content, { replaceElement: replaceEl });
            } catch (e) {
                console.warn(e);
            }
        }

        this.setCssClasses();
        this.fitHeight();
        this.fixPosition();

    },
    fixPosition: function () {
        var type = this.getOption('type');
        if (type !== 'popup') return;
        var cntx = this.getOption('popupContext');
        var $tEl;
        if (elementInDom(cntx.$el)) {
            $tEl = cntx.$el;
        }
        var off = $tEl.offset();
        this.ui.box.css({
            'top': off.top + 'px',
            'left': off.left + 100 + 'px',
        });
    },
    onBeforeRender: function () {
        //this.broadcastAboutOpening();
        this.broadcastAbout('opened');
    },
    onBeforeDestroy: function () {
        if (this.content)
            this.content.destroy();

        this.broadcastAbout('close');
    },
    broadcastAbout: function (what) {
        cfg.radio.trigger(what, this);
    },
    //broadcastAboutOpening: function () {
    //	cfg.radio.trigger('opened', this);
    //},
    //broadcastAboutClose: function () {
    //	cfg.radio.trigger('closed', this);
    //},
});

export default View;
