import { CoreBbView } from 'core';
import { mediaApi } from 'apis';
import { _ } from 'vendors';

const wrapedTemplate = _.template('<img src="<%= url %>" />');

export const ImageView = CoreBbView.extend({
	tagName() { 
        return this.ifWraped('div', 'img');
    },
    isWraped() {
        if (this._isWraped == null) {
            this._isWraped = this.getOption('wrap', true);
        }
        return this._isWraped === true;
    },
    ifWraped(ifYes, ifNo) {
        return this.isWraped() ? ifYes : ifNo;
    },
	//template: false,
    getTemplate() {
        return this.ifWraped(wrapedTemplate, false);
    },
    getImageHash() {
        return this.getOption('imageHash', true);
    },
    getImageOptions() {
        return this.getOption('imageOptions', true);
    },
    getImageExtension() {
        return this.getOption('imageExtension', true) || 'jpg';
    },
    getUrl() {
        let options = this.getImageOptions();
        let ext = this.getImageExtension();
        let hash = this.getImageHash();
        return mediaApi.url(hash, options, ext);
    },
	attributes: function () {
        if (this.isWraped()) return;
		return {
			src: this.getUrl()
		}
	},
    templateContext() {
        if (!this.isWraped()) return;
        return {
            url: this.getUrl()
        }
    }
});