﻿//define('svc-deals-deal-entry', [], function () {});

import template from './entry.html';
import { MneView } from 'core/mne';

var View = MneView.extend({
	className:'block',
	//template: '#tmpl-service-deals-deal-entry',
	template,
	templateContextExtend: function () {
		var json = this.model.toJSON();
		var m = this.model.toJSON().subject || {};
		var res = [];
		res[0] = m.squareFrom;
		res[1] = m.squareTo;
		var meters = res.join('-');

		if (res[0] || res[1]) meters += "м";

		if (res[0] && !res[1])
			meters = 'от ';
		else if (!res[0] && res[1])
			meters = 'до ';


		var price = '';
		var pr = [];
		if (m.priceFrom) {
			pr.push('от');
			pr.push(m.priceFrom);
		}
		if (m.priceTo) {
			pr.push('до');
			pr.push(m.priceTo);
		}
		if (pr.length) {
			if (m.priceFor == 'meter')
				pr.push(' р. за м<sup>2</sup>');
			else
				pr.push(' р.');
			price = pr.join(' ');
		}

		
		//var adata;
		//var meters = '';
		return {
			_sourceName: json.leadSourceName,
			_meters: meters,
			_realtyName: m.realtyName || '',
			_geoName: m.geoName || '',
			_comment: m.comment || '',
			_price: price
		};
	}
});

export default View;