import { _, Collection } from 'vendors';
import refs from 'references';


let _modules = [
    { id: "svcAdminModule", name: 'администрирование' },
    { id: "svcTasksModule", name: 'задачи'},
    { id: "svcEmployeesModule", name: 'сотрудники и отделы'},
    { id: "svcProcessesModule", name: 'процессы'},
    { id: "svcClientsModule", name: 'клиенты'},
    { id: "svcLeadsModule", name: 'лиды'},
    { id: "svcRealtiesModule", name: 'недвижимость'},
    { id: "svcActualizationModule", name: 'актуализация'}
];

function createModule(id, name) {
	name = name || id;
	return { id, name };
}

export const modules = _.reduce(_modules, (hash, mod) => {
    hash[mod.id] = mod;
    return hash;
}, {});





export function getModuleEnum(moduleId) {
    let module = getModule(moduleId);
    if (!module) { return }
    let enumKey = module.enumName ? module.enumName : module.id + 'Rights';
    let enumHash = refs.enum(enumKey);
		if (enumHash == null && module.enumName == null) {
			enumHash = refs.enum('appModuleRights');
		}
    return enumHash;
}

export function getModule(moduleId) {
    const exist = modules[moduleId];
		if (exist) { return exist; }
		const newone = createModule(moduleId);
		modules[moduleId] = newone;
		return newone;
}

export function getModuleName(moduleId) {
    let module = getModule(moduleId);
    if (!module) { return }
    return module.name;
}

function splitString(text) {
    return text.split(/\s*,\s*/);
}

function textArrayToHash(arr) {
    return arr.reduce((hash, value) => {
        hash[value] = 1;
        return hash;
    }, {});
}

function getStringHash(text) {
    let arr = splitString(text);
    return textArrayToHash(arr);
}

function hashContainsArray(hash, arr, options) {
    let len = arr.length;
    if (len === 0) { return false; }
    let method = len > 1 && options.every ? Array.prototype.every : Array.prototype.some;
    return method.call(arr, (check) => check in hash);
}

export function containsValue(claimsValue, checkValue, options)
{
    
    if (!checkValue || !claimsValue) { return false; }
    if (!options) {
        options = { every: true };
    }
    let claimsHash = getStringHash(claimsValue);
    let checkArr = splitString(checkValue);
    return hashContainsArray(claimsHash, checkArr, options);
}

// export function hasRightsValue(moduleId, value) {
//     let enumHash = getModuleEnum(moduleId);
//     if (!enumHash) { return false; }



// }