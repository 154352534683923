﻿//define('ui-models-filters-collection', ['ui-models-filters-filter', 'ui-models-filters-normalizer'], function (Filter, filtersNormalizer) {});

import Filter from './filter';
import filtersNormalizer from './normalizer';
import { isEmpty } from 'utils';
import { isNotEmpty } from 'utils';

import { Collection as BbeCollection } from 'core/bbe';

import { BbView } from 'vendors';

function normalizeFilterData(data)
{
	if (!data) return undefined;
	var hash = {};

	_(data).each(function (piece) {
		var iKey = piece[0];
		var iValues = piece[1];
		hash[iKey] = iValues;
		//_(iValues).each(function (value, index) {
		//	_.extend(hash, normalizeValue(value, iKey, index));
		//});
	});

	return hash;
}


//ACTUAL
var Col2 = BbeCollection.extend({
	model: Filter,
	initialize: function(data, opts) {
		this.context = opts.filtersContext;
	},
	filtersPredicate: function(arg) {

		var model = arg instanceof BbView ? arg.model
			: arg;

		if (!model.context) return true;

		var col = model.context.filters;

		var dependency = model.get('dependency');
		if (!dependency) return true;
		if (!dependency.showIfFilter) return true;


		return _(dependency.showIfFilter).every(function(r) {
			//console.log('	', 'i:', r);
			var searchFor = r.value;
			var fltr = col.get(r.id);
			if (!fltr) return false;

			var fval = fltr.getValue() || [];
			if (!(fval instanceof Array)) fval = [fval];

			if ((fval.length == 0) && isEmpty(searchFor)) return true;
			else if (fval.length == 0 && isNotEmpty(searchFor)) return false;
			searchFor = searchFor instanceof Array ? searchFor : [searchFor];

			return _(searchFor).every(function(esf) {
				var who = _.who(esf);
				if (who.primitive) return fval.indexOf(esf) >= 0;
				else return !!_(fval).findWhere(esf);
			});


		});

	},
	getFiltersData: function () {
		var res = [];
		//var hash = 
		this.each(function (model) {
			var h = model.getFilterData();
			if (h) res.push(h);
		});
		var nres = normalizeFilterData(res);

		return nres;
	},
	getFiltersHash: function () {
		return filtersNormalizer.getHash(this.getFiltersData());
	}
});

export default Col2;
