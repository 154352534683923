﻿//define('v-contacts-entityContact-addContactValue', ['bus', 'references', 'm-contacts', 'behaviors-form2Hash', 'behaviors-dynamicClass', 'ui-controls-phone', 'ui-controls-input', 'modals', 'm-contacts-contactValue'], function (Bus, refs, contactsNs, BehaviorForm2Hash, BehaviorDynamicClass, Phone, Input, modals, ContactValueModel) {});


import Bus from 'bus';
import refs from 'references';
import BehaviorForm2Hash from 'behaviors/form2Hash';
import BehaviorDynamicClass from 'behaviors/dynamicClass';
import Phone from 'ui/controls/phone';
import Input from 'ui/controls/input';
import ContactValueModel from 'm/contacts/contactValue';

import template from './addContactValue.html';

import './contact-value-add-layout.less';
import { valueIn } from 'utils';

import { MneView } from 'core/mne';

var View = MneView.extend({
	className: 'contact-value-add-layout inline',
	//template: '#tmpl-v-contacts-entityContact-addContactValue',
	template,
	dynamicClass: function () {
		return 'type-' + this.model.get('type');
	},
	behaviors: [BehaviorForm2Hash.forView({ hash: {type:'phone'}}), BehaviorDynamicClass],
	regions: {
		'valueView':{el:'.value-region', replaceElement:false}
	},
	ui: {
		'addBtn':'button.edit-complete'
	},
	onHashChangeType: function () {
		var hash = this.getHash();

		this.triggerMethod('form2hash:reset', { type: hash.type });
		this.showValueView();
	},
	//modelEvents: {
	//	'change:type': function () {
	//		this.triggerMethod('form2hash:set:hash:value', 'add', null);
	//		this.triggerMethod('form2hash:set:hash:value', 'value', null);
	//		this.showValueView();
	//		//console.log('model change', arguments);
	//	}
	//},
	validateHash: function (hash) {

		if (!hash) return false;

		var type = hash.type;
		var value = hash.value;
		if (!value) return false;

		if (
			//type.in(/*deprecated*/'mobilePhone', 'phone')
			valueIn(type, 'mobilePhone', 'phone')
		) {
			var aval = (value || '').split('.');
			if (
				aval.length < 3
				|| (!aval[0].length)
				|| (aval[1].length < 2)
				|| (aval[2].length < 7)
			) return false;
		}
		else if (type == 'email') {

			if (!value || !/^[^@]+@[^@]+$/gmi.test(value))
				return false;

			var a = value.split('@');
			var left = a[0].trim();
			var right = a[1].trim();


			if (!/^[a-z0-9.\-+_]+$/gmi.test(left))
				return false;

			if (!/^[a-z0-9\-_]+\.[a-z0-9\-_]+(\.[a-z0-9\-_]+)*$/gmi.test(right))
				return false;


		} else {
			if (value.length < 2)
				return false;
		}

		return true;
	},
	toggleSubmit: function () {
		var hash = this.getHash();// || this.model.toJSON();
		var valid = this.validateHash(hash);
		this.ui.addBtn.prop('disabled', !valid);
	},
	onRender: function () {
		this.showValueView();
	},
	showValueView: function () {
		var hash = this.getHash();

		var type = hash.type; //'phone'; //this.model.get('type');
		var View;
		var options = {};

		if (
			//type.in(/*deprecated*/'phone', 'mobilePhone')
			valueIn(type, 'phone', 'mobilePhone')
		) {
			View = Phone;
			if (type == 'phone') {
				options.withAdd = true;
				options.data = { region: 495 };
			}
		}
		else {
			View = Input;
			if (type == 'email') {
				options.type = 'email';
			}
		}
		
		var view = new View(options);
		this.showChildView('valueView', view);
		this.toggleSubmit();
	},
	onEditComplete: function (hash) {
		//console.log(hash, this.model.toJSON(), this.getOption('contact'));
		var col = this.collection;
		//console.log('???', col);
		hash.contactId = _.isFunction(col.contactId) ? col.contactId.call(col) : col.contactId; //col.getOptionValue('contactId');

		

		var model = new ContactValueModel(hash);
		model.loudSave(null, { wait: true }).then(function () {
			col.add(model);
			//modals.notify('Контактная информация добавлена');
		});
		this.triggerMethod('form2hash:reset');
		this.showValueView();
	},
	onHashChange: function () {
		this.toggleSubmit();
	},
	onChildviewValueChanged: function (value, add) {
		var changeHash = {value:value};
		

		var hash = this.getHash();
		//if (!hash) {
		//	this.triggerMethod('form2hash:set:hash:value', changeHash);
		//	return;
		//}

		if (hash && (!!hash.add || (!hash.add && !!add))) {
			add = add === '' ? null : add;
			changeHash.addValue = add;
		}

		this.triggerMethod('form2hash:set:hash:value', changeHash);

		//if (hash.add == null && add != null && !add) return;

		//if(hash.add && !add)
		//	this.triggerMethod('form2hash:set:hash:value', 'addValue', null);
		//else if (add != null)
		//	this.triggerMethod('form2hash:set:hash:value', 'addValue', add);

	},
	templateContextExtend: function () {
		var hash = this.getHash();
		var typesHtml = '';
		var types = refs.enum('contactValueTypes');
		var thistype = this.model.get('type')
		_(types).each(function (label, value) {
			var checked = thistype == value ? ' checked="checked"' : '';
			typesHtml += '<li><label><input type="radio" value="' + value + '" name="type" ' + checked + '><span><span class="input-icon"></span><span class="input-label">' + label + '</span></span></label></li>'
		});
		return {
			type: hash.type,
			typesHtml: typesHtml,
		}
	}
});


View.create = function (opts) {
	opts.contact = opts.model;
	//opts.model = new contactsNs.ContactValueModel({
	//	type: 'mobilePhone',
	//	contactId: opts.contact.get('id')
	//});
	return new View(opts);
}

Bus.Views.reply('entityContact:addContactValue', function () { return View; });

export default View;