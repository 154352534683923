import { HamburgerView } from "coms/ui/HamburgerView";
import { View, CollectionView } from 'core';
import { actionsMixin } from "./common";
import { objectActions } from "./ObjectModel";
import { offerActions } from "./OfferModel";

const Header = View.extend({
	tagName: 'header',
	template: `<span>Выбрано <%= genType%>: <big><b><%= amount %></b></big><br>
	<strong class="hovered">Действия:</strong>
	<em class="unhovered">наведите курсор мыши для просмотра</em>
	</span>
	<button class="clear-selected" title="сбросить выделенное"><i class="fa fa-remove"></i></button>`,
	templateContext() {
		return {
			amount: this.collection.length,
			genType: this.getOption('genType')
		}
	},
	triggers: {
		'click .clear-selected':'clear:selected'
	}
});

const ActionView = View.extend({
	baseClassName: 'multy-action',
	template: '<%= label %>',
	triggers: { 'click':'click' }
});

const ActionsView = CollectionView.extend({
	...actionsMixin,
	_getRawActions() {
		const type = this.getOption('type');
		if (type === 'objects') {
			return [...objectActions];
		} else {
			return [...offerActions];
		}
	},
	_getActions({ models } = {}) {
		return models ? [...this.collection.models] : this.collection;
	},
	initialize() {
		this.collection = this._buildActionsCollection();
	},
	childView: ActionView,
	viewFilter(v) {
		return !!v.model.get('multyAction');
	},
	childViewTriggers: { 'click': 'multy:action:click' }
});


const SelectedInfo = HamburgerView.extend({
	baseClassName: 'selected-info-pane',
	classNames: [
		v => v.getOption('type')
	],
	initialize() {
		const key = this.getOption('collectionName');
		this.collection = this.getOption(key);
		this.listenTo(this.collection, 'all', c => console.log('c', c));
	},
	collectionEvents: {
		'select': 'render'
	},
	getChildren() {
		if (this.collection.length) {
			return this.getOption('childrenViews', true);
		}
	},
	childrenViews: [
		Header,
		ActionsView
	],
	childViewOptions() {
		return {
			type: this.getOption('type'),
			genType: this.getOption('genType'),
		}
	},
	childViewEvents: {
		'clear:selected'() {
			this.collection.unselectAll();
		},
		'multy:action:click'(child) {
			console.log('trying...');
			this.trigger('multy:action:click', this.getOption('type'), child.model, this.collection.models);
		}
	}
		
	
});

const SelectedObjects = SelectedInfo.extend({
	collectionName: 'objectsSelector',
	genType: 'площадей',
	type: 'objects',
});

const SelectedOffers = SelectedInfo.extend({
	collectionName: 'offersSelector',
	genType: 'предложений',
	type: 'offers',
});

export const SelectedContainer = HamburgerView.extend({
	baseClassName: 'selected-container',
	childrenViews: [
		SelectedObjects,
		SelectedOffers
	],
	childViewOptions() {
		return {
			objectsSelector: this.getOption('objectsSelector'),
			offersSelector: this.getOption('offersSelector'),
		}
	},
	childViewTriggers: {
		'multy:action:click':'multy:action:click'
	}
});