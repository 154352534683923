import refs from 'references';
import SchemaEdit from 'ui/controls/schemaEdit';

let addressSchema;

export function getAddressSchema() {
    if (!addressSchema) {
        addressSchema = {

            'lat': {
                caption: 'latitude (lat)',
                emptyText: 'не установлена',
                modelType: 'single',
                valueType: 'number',
            },
            'lng': {
                caption: 'longitude (lng)',
                emptyText: 'не установлена',
                modelType: 'single',
                valueType: 'number',
            },
    
            'fullAddress': {
                caption: 'полный адресс',
                emptyText: 'не указан',
                modelType: 'single',
                valueType: 'string',
            },
    
            'gd.house': {
                caption: 'номер дома',
                emptyText: '&mdash;',
                modelType: 'single',
                valueType: 'string',
            },
            'gd.route': {
                caption: 'улица/шоссе',
                emptyText: '&mdash;',
                modelType: 'single',
                valueType: 'string',
            },
            'gd.sublocality2': {
                caption: 'округ/район 2го ур.',
                emptyText: '&mdash;',
                modelType: 'single',
                valueType: 'string',
            },
            'gd.sublocality': {
                caption: 'округ/район 1го ур.',
                emptyText: '&mdash;',
                modelType: 'single',
                valueType: 'string',
            },
            'gd.locality': {
                caption: 'город / поселение',
                emptyText: '&mdash;',
                modelType: 'single',
                valueType: 'string',
            },
            'gd.administrativeArea2': {
                caption: 'административный район 2го ур.',
                emptyText: '&mdash;',
                modelType: 'single',
                valueType: 'string',
            },
            'gd.administrativeArea1': {
                caption: 'административный район 1го ур.',
                emptyText: '&mdash;',
                modelType: 'single',
                valueType: 'string',
            },
            'gd.country': {
                caption: 'страна',
                emptyText: '&mdash;',
                modelType: 'single',
                valueType: 'string',
            },
            'gd.postalCode': {
                caption: 'почтовый индекс',
                emptyText: '&mdash;',
                modelType: 'single',
                valueType: 'string',
            }


            // 'type': {
            //     caption: 'тип файла/картинки',
            //     emptyText: 'Неустановлен',
            //     sourceValues: refs.enum('realtyMediaTypes'),
            //     display: v => refs.enum('realtyMediaTypes', v),
            //     // sourceValues: refs.enum('yesNo'),
            //     modelType: 'single',
            //     valueType: 'enum',
            // }
    
            /*        
            'forCustomer.taxType': {
                caption: 'налогообложение',
                emptyText: 'не установлено',
                display: v => v ? refs.enum('taxTypes',v) : undefined,
                sourceValues: refs.enum('taxTypes'),
                _value: model && model.getValueByPath('offer.forCustomer.taxType') || void 0,
                modelType: 'single',
                valueType: 'string',
                flattenValues: true,
                multiple: false,
                validate: required,	
            },
            'forAgent.contractType': {
                caption: 'договор',
                emptyText: 'не установлен',
                display: v => v ? refs.enum('contractTypes',v) : undefined,
                sourceValues: refs.enum('contractTypes'),
                _value: model && model.getValueByPath('offer.forAgent.contractType') || void 0,
                modelType: 'single',
                valueType: 'string',
                flattenValues: true,
                multiple: false,
                validate: required,	
            },
            'forAgent.income': {
                caption: 'процент коммиссии',
                modelType: 'single',
                valueType: 'number',
                display: v => v ? v + '%' : '',
                validate: required,
                _value: model && model.getValueByPath('offer.forAgent.income') || void 0,
            },
            */
        }
    }
    return addressSchema;
}

export function editAddressModal(opts) {
    let properties = getAddressSchema();
    opts = opts || {};
    let options = {
        header: 'Изменение адреса',
        applyText: 'Применить изменения',
        beforeApply: (value) => {
            //return this.takeAction('commercial:update', 'изменение условий', models, value);
        },
        ...opts,
    }
		console.warn('OPTIONS', opts);
    const context = SchemaEdit.inModal(properties, options);
		//context.modal.on('all', c => console.log('modal:', c));
		const promise = new Promise((resolve) => {
			context.view.once('edit:stop', type => resolve({ ok: type === 'resolved' }));
		});
		// context.view.on('done', c => console.log('view:DONE', c));
		// context.view.on('destroy', c => console.log('view:destroy', c));
		return promise;
		// .then(
		// 	() => console.log('RESOLVED'),
		// 	() => console.log('REJECTED'),
		// );	    
}