﻿//define('svc-realties-searchOnMap-clusterInfo', ['bus-svc','m-realties-offers-offers', 'realties-offers-views-collection'], function (appBus, Offers, OffersCollectionView) {});

import appBus from 'bus/svc';
import Offers from 'm/realties/offers/offers';
import OffersCollectionView from 'svc/realties/offers/views/collection';

import template from './clusterInfo.html';

import { getValueByPath } from 'utils';
import {bbeViewComparator} from 'utils';

import { MneView } from 'core/mne';
// import { getBuildingName } from '../../../m/realties/buildings/buildings';
import { getRealtyName } from 'mods/realties/realties/realties';

var View = MneView.extend({
	className: 'actions-panel actions-panel-left cluster-info',
	//template: '#tmpl-service-realties-searchOnMap-clusterInfo',
	template,
	initialize: function (options) {
		this.mergeOptions(options, ['filterObject', 'selectionHolder', 'deal', 'realtyId']);
	},
	triggers: {
		'click header .close':'return:filters'
	},
	regions: {
		'data': { el: '.data-region', replaceElement:true},
	},
	onRender: function () {
		//var offers = 
		this.searchResult = new Offers();
		//var data = _.extend({}, this.getFiltersExtender(), this.filterObject.getUrlHash());
		//offers.loudFetch({ data: data }).then(function () {
		//	console.log('fetched');
		//});
		//console.log(this.getOption('filterObject'));
		this.showResult();
	},
	getFiltersExtender: function () {

		var model = this.model;

		var bounds = model.getBounds().toJSON();
		var realties = model.getRealtiesIds && model.getRealtiesIds();
		var offers = model.getOffersIds && model.getOffersIds();
		var extender = {};
		if (this.realtyId) {
			extender['bld'] = this.realtyId;
		}
		else if (realties && realties.length < 5) {
			_(realties).each(function (v, i) {
				extender['bld[' + i + ']'] = v;
			});
			//if (realties.length == 1) {
			//	this.name = appBus.request('buildings', realties[0]);
			//	//console.log('Realties::', realties);
			//}
			//extender.bld = realties.join('&bld=');
		} else if (offers && offers < 10) {
			_(realties).each(function (v, i) {
				extender['oid[' + i + ']'] = v;
			});
		} else {

			if (this.model.name)
				this.name = this.model.name;

			_.extend(extender, bounds);
			//console.log('EXTENDER', extender);
		}
		return extender;
	},
	showResult: function () {

		var denySelect;
		var deal = this.getOption('deal');
		if (deal) {
			var offers = deal.getOffers();
			denySelect = offers.map(function (m) { return getValueByPath(m, 'realtyOffer.id'); }).onlyValues();
			//console.log('deny', ids, offers);
		}
		var view = this;
		var result = new OffersCollectionView({
			collection: this.searchResult,
			filterObject: this.filterObject,
			selectionHolder: this.selectionHolder,
			denySelectionFor: denySelect,
			fetchOnShow: true,
			extendFiltersWith: function () {
				//console.log('filters extender', view.getFiltersExtender());
				return view.getFiltersExtender();
			},
			childViewOptions: function () {
				return {
					popoverPlacement: 'right',
				};
			},
			viewComparator: function (v1,v2) {
				return bbeViewComparator([
					[v1, v2, function (m) { return m.get('realtyId'); }],
					[v1, v2, function (m) { return m.get('obj').info.squareOffer; }],
					[v2, v1, function (m) { return (m.get('forAgent') || {}).income; }]
				]);
			},
		});
		this.showChildView('data', result);
	},
	getName() {
		var model = this.model;
		var rids = (model.getRealtiesIds && model.getRealtiesIds()) || [];
		if (rids.length == 1)
			return getRealtyName(rids[0]);
			//return appBus.request('building:name', rids[0]);
		else if (model.name)
			return model.name;
		else
			return this.name || 'точка на карте';
	},
	templateContext: function () {
		var name = this.getName();
		return {
			header: name,
		};
	}
});

export default View;
