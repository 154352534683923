import { displayDistance, getValueByPath } from "utils";
import { Schema } from 'apis/schema';
import appCfg from 'app-config';

function getAttrs(arg) {
    if (!arg || !arg.attributes) {
        return arg;
    }
    return arg.attributes;
}
function extractOldAddress(model) {
    model = getAttrs(model);
    if (!model) return;
    let address = getAttrs(model.address);
    return address;
}

function extractOldMetro(model) {
    let address = extractOldAddress(model);
    let metro = address && getAttrs(address.metro);
    return metro;
}

export const OldSchema = {
    address: {
        label: 'адрес',
        getValue(model) {
            let addr = extractOldAddress(model);
            if (!addr) { return }
            return addr.fullAddress;
        }
    },
    subway: {
        label: 'станция метро',
        getValue(model) {
            let metro = extractOldMetro(model);
            if (!metro) { return; }
            return metro.label;
            /*
distance: 107.85
label: "<span class=\"clr-metro-800 txt\">Деловой центр (100м)</span>"
line: "Калининско-Солнцевская линия"
lineNumber: 800
station: "Деловой центр"
stationId: "df539426-97c5-4dd2-924a-7544384194ef"
            */

        }
    }
}


export const modelSchema = {
    "buildingClassEnum": {
        valueType: 'enum',
        enumType: 'dtoRealtyClasses',
        label: 'класс'
    },
    "realtyType": {
        valueType: 'enum',
        enumType: 'realtyTypes',
        label: 'тип недвижимости'
    },
    "buildingType": {
        valueType: 'enum',
        enumType: 'buildingTypes',
        label: 'тип строения'
    },
    'address.fullAddress': {
        label: 'адрес',
        editable: false,
    },
    'subwayDetails': {
        label: 'метро',
        editable: false,
        getValue(model) {
            let name = getValueByPath(model, 'address.metro.name');
            if (!name) { return }
            let distance = getValueByPath(model, 'address.metro.distance');
            let distanceDisp = displayDistance(distance);
            return name + ` (~${distanceDisp}м)`;
        }
    },

		'building.buildYear': {
        label: 'год постройки',
        valueType: 'number',
        displayValue(v) {
            return v ? v : '&mdash;'
        }
    },

		'building.reBuildYear': {
        label: 'год реконструкции',
        valueType: 'number',
        displayValue(v) {
            return v ? v : '&mdash;'
        }
    },

		'building.squareTotal': {
			label: 'полная площадь, кв. м',
			valueType: 'number',
			ifEmpty: '&mdash;',
			// displayValue(v) {
			// 	return v ? v : '&mdash;'
			// }
		},

		'building.squareUsefull': {
			label: 'полезная площадь, кв. м',
			valueType: 'number',
			ifEmpty: '&mdash;',
			// displayValue(v) {
			// 	return v ? v : '&mdash;'
			// }
		},

		'taxSvcNumber': {
        label: 'номер налоговой',
        valueType: 'string',
    }
}

export const schema = new Schema({ 
    name: 'realty',
		modelSchema,
    schemaUrl: () => appCfg.urls.apiV('realties/realties/schema'),
});

window.realtySchema = schema;