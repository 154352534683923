module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<small>'+
((__t=(info.purposes))==null?'':__t)+
'</small>\n<div>\n	<div class="data-cnt">\n		<span class="floor">'+
((__t=(_floor))==null?'':__t)+
' эт</span><span class="square">'+
((__t=(info.squareOffer))==null?'':__t)+
' м<sup>2</sup></span>\n		<span class="offers"></span>\n	</div>\n	<small>'+
((__t=(owner.name))==null?'':__t)+
'</small>\n</div>';
}
return __p;
};
