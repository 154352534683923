﻿//define('m-tasks-entityTasks', ['m-tasks-tasks','app-paths'], function (Tasks, paths) {});

import Tasks from './tasks';
import paths from 'app-paths';

var EntityTaskMapping = {
	'deal': 'tasksDeal',
	'client': 'tasksClient',
	'employee': 'tasksEmployee'
};

var EntityTasks = Tasks.extend({
	url: function () {
		return paths.api[EntityTaskMapping[this.entity]] + '/' + this.entityId;
	}
});

EntityTasks.init = function (entity, entityid) {
	var col = new EntityTasks();
	col.entity = entity;
	col.entityId = entityid;
	return col;
}

export default EntityTasks;