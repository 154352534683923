export const popupProperties = [
    ['nameNoMarket'],
    ['obj.info.purposes'],
    [
        'forCustomer.taxType',
        'forCustomer.boma',
    ],
    ['forCustomer.priceIncludes'],
    ['forCustomer.priceExcludes'],
    [
        'obj.info.state',
        'obj.info.spaceLayout',
    ],
    [
        'obj.info.roomsCount',
        'obj.info.floor' 
    ],
    // ,[
    //     ,'obj.info.floorsCount' 
    // ] 
    ['bld.taxNum'],
    //,['bld.address.fullAddress'] 
    ['addressAndMetro'],
    // ,[
    //     'bld.address.metro.station'
    //     ,'bld.address.metro.line'
    //     ,'bld.address.metro.distance'
    // ]
    ['actualizedOrModified']
]