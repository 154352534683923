﻿import { $ } from 'vendors';
import { unFlatObject } from 'utils/object-manipulations';

$(function(){

	function assign(model, name, value, options) {
		var tostr = (options || {}).asString || [];
		var asarray = (options || {}).asArray || [];

		if (!(name in model)) {
			if (asarray.indexOf(name) >= 0)
				model[name] = [value];
			else
				model[name] = value;
			return;
		}

		if (tostr && tostr.indexOf(name) >= 0) {
			model[name] = model[name] + ', ' + value;
			return
		}

		if (!(model[name] instanceof Array))
			model[name] = [model[name]];

		model[name].push(value);

	}

	$.fn.serializeModel = function (opts) {
		var options = opts || {};
		var skip = options.skip || [];
		//var tostr = options.asString || [];
		var array = this.serializeArray();
		//console.log('arr',array);
		var model = {};
		_(array).each(function (obj) {		
			if (skip.indexOf(obj.name) >= 0) {
				assign(model, obj.name, obj.value, opts);
				//model[obj.name] = obj.value;
				return;
			}
			if (obj.value === '') {
				assign(model, obj.name, obj.value, opts);
				//model[obj.name] = obj.value;
				return;
			}
			var num = Number(obj.value);
			if (!isNaN(num)) {
				//model[obj.name] = num;
				assign(model, obj.name, num, opts);
				return;
			}
			
			var bool = Boolean.fromString(obj.value);
			if (!isNaN(bool)) {
				//model[obj.name] = bool;
				assign(model, obj.name, bool, opts);
				return;
			}

			assign(model, obj.name, obj.value, opts);
			//model[obj.name] = obj.value;
		});
		if (!options.unflat)
			return model;
		else
			return unFlatObject(model);
	};

	$.fn.htmlToModel = function (opts) {
		var options = opts || {};
		return this.find('[name]').serializeModel(options);
	}



});
