
function idChunks(block, popsCount = 0, forParent) {
    let chunks = [hasRealty(block), hasObject(block), hasOffer(block)].filter(f => !!f);
    while(popsCount) {
        chunks.pop();
        popsCount--;
    }
    let res = chunks.join('::');
    if (res === '' && forParent) {
        res = undefined;
    }
    return res;
}

function blockEntityId(block, forParent) {
    return idChunks(block, forParent ? 1 : 0, forParent);
}

// function blockLayerId(block, forParent) {    
//     return idChunks(block, forParent ? 2 : 1, forParent);
// }

function getLayerId(forType, block) {
    if (forType === 'root') {
        return 'root';
    } else if (!forType) {
        return;
    }
    return forType + "::" + block[forType + 'Id'];
}

// function hasNoEntity(block) {
//     return !hasRealty(block) && !hasObject(block) && !hasOffer(block);
// }

function blockType(block) {
    let off = hasOffer(block);
    let obj = hasObject(block);
    let rlt = hasRealty(block);

    if (rlt && obj && off) {
        return 'realtyOffer';
    } else if (rlt && obj) {
        return 'realtyObject';        
    } else if (rlt) {
        return 'realty'
    } else {
        return 'root';
    }
}

function blockLayerId(block, forParent) {
    let bt = blockType(block);

    if (!forParent) {
        return bt !== 'root' ? bt + ':' + block[bt] : bt;
    }

    switch(bt) {
        case 'realtyOffer':
            return 'realtyObject:' + hasObject(block);
        case 'realtyObject':
            return 'realty:' + hasRealty(block);
        case 'realty':
            return 'root';
        case 'root':
            return;
    }
}

function hasRealty(block) {
    return block.realtyId;
}

function hasObject(block) {
    return block.realtyObjectId;
}

function hasOffer(block) {
    return block.realtyOfferId;
}

// function isRealty(block) {
//     return !hasObject(block) && !hasOffer(block) && hasRealty(block);
// }

// function isRealtyObject(block) {
//     return !hasOffer(block) && hasRealty(block) && hasObject(block);
// }

// function isOffer(block) {
//     return hasRealty(block) && hasObject(block) && hasOffer(block);
// }

const typeParents = {
    realtyOffer: 'realtyObject',
    realtyObject: 'realty',
    realty: 'root'
}

function getBlockLayer() {
    const store = new Map();

    class BlockLayer {
        constructor(id, index) {
            let idchunks = id.split('::');
            this.id = id;
            this.type = idchunks[0];
            this.entityId = idchunks[1];
            this.index = index;
            this.isRoot = !id || id === 'root';
            this.children = [];
            this.tops = [];
            this.bottoms = [];
        }

        addLayer(layer) {
            this.children.push(layer);
        }

        add(block) {
            if (this.children.length) {
                this.bottoms.push(block);
            } else {
                this.tops.push(block);
            }
            // if (this.isRoot) {
            //     let arr = this.topsEnded ? this.bottoms : this.tops;
            //     arr.push(arr);
            // } else {
            //     this.blocks.push(block)
            // }
        }

        toJSON() {
            this.children.sort((l1, l2) => {
                return l1.index - l2.index;
            });
            let { id, type, index, entityId } = this;
            return {

                id, type, index,entityId,

                tops: [...this.tops],
                children: this.children.map(child => child.toJSON()),
                bottoms: [...this.bottoms]
            }                    
        }

        static add(block, index) {
            
            block = this.prepareBlock(block, index);
            //console.error(index, block._type, block);
            let layer = this.ensureLayer(block._type, block, index);
            try {
                layer.add(block);
            } catch(err) {
                console.log('-add failed-');
                // console.error(block._type, block._id);
                // console.error(layer, index, block)
            }
        }
        static ensureLayer(ensureType, block, index) {

            let id = getLayerId(ensureType, block);
            if (!id) { return; }

            let layer = store.get(id);
            if (layer) { return layer };

            layer = new BlockLayer(id, index);

            let parentEnsureType = typeParents[ensureType];
            if (parentEnsureType) {
                let parent = this.ensureLayer(parentEnsureType, block, index);
                parent.addLayer(layer);
            }
            store.set(id, layer);
            return layer;
        }
        static prepareBlock(block, _index) {
            if ('_id' in block) {
                return block;
            }
            let _type = blockType(block);
            let _layerId = getLayerId(_type, block);
            return { 
                ...block, 
                _index, _type, _layerId,
                _id: blockEntityId(block),
                _entityId: block[_type + 'Id'],
            }
        }
        static getStore() {
            return store;
        }
        static toJSON() {
            let root = store.get('root');
            return root.toJSON();
        }
    }
    return BlockLayer
}

export function buildTree(blocks, data) {
    const Layer = getBlockLayer();
    //console.warn('store', Layer.getStore());
    blocks.forEach((block, index) => Layer.add(block, index));
    const tree = Layer.toJSON();
    return tree;
}