﻿//define('svc-leads-stats-filters', ['bus-svc', 'references'], function (svcRadio, refs) {});

import svcRadio from 'bus/svc';
import refs from 'references';


function getFilters() {
    return [
//        {
//            id: 'period',
//            group: 'base',
//            order: -2,
//            caption: 'период',
//            emptyText: 'не указан',
//            modelType: 'range',
            //valueType: 'datetime',
            //controlType: 'datetime2',
//            multiple: false,
//            pinned: true,
//        },
        {
            id: 'createperiod',
            group: 'base',
            order: -2,
            caption: 'создано',
            emptyText: 'не указана',
            modelType: 'range',
            valueType: 'datetime',
            controlType: 'datetime2',
            multiple: false,
            pinned: true,
        },
        {
            id: 'completeperiod',
            group: 'base',
            order: -2,
            caption: 'завершено',
            emptyText: 'не указана',
            modelType: 'range',
            valueType: 'datetime',
            controlType: 'datetime2',
            multiple: false,
            pinned: true,
        },
        {
            id: 'cancelperiod',
            group: 'base',
            order: -2,
            caption: 'отменено',
            emptyText: 'не указана',
            modelType: 'range',
            valueType: 'datetime',
            controlType: 'datetime2',
            multiple: false,
            pinned: true,
        },
        {
            id: 'mt',
            group: 'base',
            order: -1,
            caption: 'рынок',
            emptyText: 'не указан',
            modelType: 'single',
            valueType: 'string',
            sourceValues: refs.enum('realtyMarkets'),
            flattenValues: true,
            multiple: false,
            pinned: true,
        },
        {
            id: 'ot',
            group: 'base',
            order: -1,
            caption: 'операция',
            emptyText: 'не указана',
            modelType: 'single',
            valueType: 'string',
            sourceValues: refs.enum('realtyOperations'),
            flattenValues: true,
            multiple: false,
            pinned: true,
        },
        {
            id: 'sqr',
            group: 'base',
            order: 0,
            caption: 'площадь',
            emptyText: 'не выбрана',
            modelType: 'range',
            valueType: 'number',
            multiple: false,
            pinned: true
        },
        {
            id: 'owner',
            group: 'base',
            order: 0,
            caption: 'ответственный',
            emptyText: 'не выбран',
            controlType: 'selectEmployees',
            sourceValues: svcRadio.request('employeesSelect'),
            multiple: false,
            pinned: true,
        },
        {
            id: 'creator',
            group: 'base',
            order: 0,
            caption: 'создатель',
            emptyText: 'не выбран',
            controlType: 'selectEmployees',
            sourceValues: svcRadio.request('employeesSelect'),
            multiple: false,
            pinned: true,
        },
        {
            id: 'status',
            group: 'base',
            order: 0,
            caption: 'состояние',
            //values: ['inProcess'],
            emptyText: 'не выбран',
            modelType: 'single',
            valueType: 'string',
            sourceValues: refs.enum('dealStatuses'),
            flattenValues: true,
            multiple: false,
            pinned: true
        },
        {
            id: 'stage',
            group: 'base',
            order: 0,
            caption: 'стадия',
            //values: [''],
            emptyText: 'не выбран',
            modelType: 'single',
            valueType: 'string',
            sourceValues: refs.enum('dealStages'),
            noComparator: true,
            flattenValues: true,
            multiple: false,
            pinned: true
        },
        {
            id: 'origin',
            group: 'base',
            order: 0,
            caption: 'тип лида',
            //values: [''],
            emptyText: 'не выбран',
            modelType: 'single',
            valueType: 'string',
            sourceValues: refs.enum('leadOriginTypes'),
            noComparator: true,
            flattenValues: true,
            multiple: false,
            pinned: true
        },
    ];
}

export default getFilters;
