﻿//define('root', ['root-layout', 'root-services', 'appModule', '_libs/identity', 'app-config', 'link', 'acc-createService'], function (Layout, ServicesLayout, Module, identity, cfg, link, moduleCreateService ) {});

import Layout from './layout'; //'root-layout'
import ServicesLayout from './services'; //'root-services'
import Module from 'appModule';
import identity from '_libs/identity';
import cfg from 'app-config';
import link from 'link';

//moduleCreateService 'acc-createService'


var module = Module.content({
	name: 'root',
	routeData: () => link.route('selectService'), 
	label: 'Выберите аккаунт',
	urlKey:'selectService',
	navLinks: function () {
		var res = [];
		if (identity.isLogged()) {
			var svcs = identity.get('services') || {};
			var data = _(svcs).map(function (src, id) {
				return link('_serviceRoot:' + src.idString, src.name, { clean: true, skipRoute: true });
			});
			res = res.concat(data);
		}
		var links = this.getSubLinks() || [];
		res = res.concat(links);
		
		return res;
	},
	onRun: function () {
		console.log('==!!==');
		this.showButtonsLayout();
		//this.createAndShow(ServicesLayout, 'Выберите аккаунт', { navLinks: this.navLinks() }, { navLinks: this.navLinks() });
	}
});

//UNCOMMENT FOR ENABLE CREATING
//module.addSubModule(moduleCreateService);

//	console.log(module);

export default module;
