//define("server-cfg",[],function(){});



const protocol = document.location.protocol;

const curEnv = `${process.env.API_TYPE}.${process.env.API_LOCATION}.${process.env.API_SECURE}`;

console.warn('------------------------')
console.warn(curEnv);
console.warn('------------------------')

const currentEnvironment = curEnv; //'old.local.unsecure';

// const API_SERVERS = {
// 	localNewApi: `https://api2.nrg.loc:44398`,
// 	localOldApi: `https://api.nrg.loc`,
// 	externalApi: 'https://reag-api.azurewebsites.net',
// };

const ENVIRONMENTS = {
	'old.local.unsecure': {
		cfg: {
			isNewApi: false,
		},
		sockets: {
			old: true
		},
		paths: {
			api: '/svc/',
			apiServer: `${protocol}//api.nrg.loc`,
			svcIdInApiUrls: true,
		}
	},
	'old.local.secure': {
		cfg: {
			isNewApi: false,
		},
		sockets: {
			old: true
		},
		paths: {
			api: '/svc/',
			apiServer: 'https://api.nrg.loc',
			svcIdInApiUrls: true,
		}
	},
	'old.remote.secure': {
		cfg: {
			isNewApi: false,
		},
		sockets: {
			old: true
		},
		paths: {
			api: '/svc/',
			apiServer: 'https://api.nrg.plus', //'https://reag-api.azurewebsites.net',
			svcIdInApiUrls: true,
		}
	},
	'new.local.secure': {
		cfg: {
			isNewApi: true,
		},
		sockets: {
			old: false
		},
		paths: {
			api: '/svc/',
			apiServer: 'https://api2.nrg.loc:44398',
			svcIdInApiUrls: true,
		}
	},
	'new.remote.secure': {
		cfg: {
			isNewApi: true,
		},
		sockets: {
			old: false
		},
		paths: {
			api: '/svc/',
			apiServer: 'https://api2.nrg.plus',
			svcIdInApiUrls: true,
		}
	}
}

// let protocol = document.location.protocol;
// let svcIdString;

let host = document.location.hostname;
let path = document.location.pathname.split('/');

let isNative = host.endsWith('.nrg.plus') || host.endsWith('.nrg.loc');

// if (isNative && path[1] === 's' && path[2]) {
// 	svcIdString = path[2];
// }

let isExternal = !host.endsWith('.loc');

let apiZone = isExternal ? 'plus' : 'loc';

let mediaApiZone = isExternal ? 'plus' : 'plus';

// let oldapi = !isExternal ? 'old' : '';
// oldapi = '';

// let apiPort = '';
// if (oldapi) {
// 	apiPort = ":52221";
// }

let svcWebId;
if (isNative && path[1] === 's' && path[2]) {
	svcWebId = path[2];
}
//console.log('STARTURL', host, path, svcWebId);
//apiPort = '';
/*test*/
// let debugApi = 'https://api.nrg.loc/svc/';
// oldapi = ''; apiZone = 'loc'; apiPort = ':44398';
// let apiVer = '2';
// let ownApi = `${protocol}//${oldapi}api${apiVer}.nrg.${apiZone}${apiPort}`;
// let localNewApi = `https://api2.nrg.loc:44398`;
// let localOldApi = `https://api.nrg.loc`;
// let externalApi = 'https://reag-api.azurewebsites.net';

// let apiServer = localNewApi; //externalApi; //localOldApi; //
// let api = apiServer + '/svc/';

const cfg = {
	isExternal,
	isNative,
	svcWebId,
	paths: {
		app: {
			native: isNative,
			bearerToken: '/bundles/djs/renewtoken',
			media: `//media2.nrg.${mediaApiZone}/`
		},
		// api,
		// apiServer
	},
	// serviceRole:"ServiceUser",
	// inServiceRole:!1
}


let envCfg = Object.assign({}, ENVIRONMENTS[currentEnvironment]);
envCfg.paths.api = envCfg.paths.apiServer + envCfg.paths.api;

Object.assign(cfg, envCfg.cfg);
Object.assign(cfg.paths, envCfg.paths);
cfg.sockets = envCfg.sockets


//console.warn('server-cfg', cfg);

export default cfg;