﻿//define('ui-controls-dataroleActionItem', ['behaviors-datarole'], function (Datarole){	});

import Datarole from 'behaviors/datarole';
import { MneView } from 'core/mne';

var BoardListItem = MneView.extend({
	tagName: 'li',
	className: 'list-btn',
	behaviors: [Datarole.Self],
	attributes: function () {
		return {
			'data-role': 'action',
			'data-type': this.model.get('type'),
			'data-id': this.model.get('id'),
		};
	},
	initialize: function () {
		//this.on('all', console.log);
	},
	onAction: function (context, modal) {
		
		var parent = this.getOption('parent');

		modal || (modal = this.getOption('renderedInModal'));

		if (!(parent && parent.methodExists(context.type)))
			return;

		if (modal)
			modal.destroy();

		parent.executeAction({ action: context.type, modal: modal, view: this });
	},

	template: _.template('<span><%=label%></span>'),
});

var ns = {}
ns.BoardListItem = BoardListItem;

export default ns;