﻿//define('svc-tasks', ['appModule', 'link', 'svc-tasks-submodules'], function (Module, link, submodules) {});

import { _ } from 'vendors';
import Module from 'appModule';
import link from 'link';
import { submodules } from './submmodules';

var module = Module.content({
	name: 'tasks',
	label:'Задачи',
	urlKey: 'tasks',
	routeData: () => link.route('tasks'),
	claims: {
		svcTasksModule: ['view']
	},			
	onRun: function () {
		this.showButtonsLayout();
	}
});


_.each(submodules, smodule => module.addSubModule(smodule));


export default module;