// define('svc/actualization/realties-list/realty-model', [
// 	'svc/actualization/realties-list/config'
// ], function(
// 	cfg
// ){});

import cfg from '../config';
import { BbeModel } from 'core/bbe';
const Model = BbeModel.extend({
	//model: Model,
	urlRoot: cfg.realtiesApiUrl
});

export default Model;

