module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="time-value hours">\n	<i></i>\n	<input value="'+
((__t=( moment(date).format('HH') ))==null?'':__t)+
'" type="number" min="0" max="23"  maxlength="2" />\n	<i></i>\n</div>\n<div>:</div>\n<div class="time-value minutes">\n	<i></i>\n	<input value="'+
((__t=( moment(date).format('mm') ))==null?'':__t)+
'" type="number" min="0" max="59" maxlength="2"/>\n	<i></i>\n</div>\n<span>,</span>';
}
return __p;
};
