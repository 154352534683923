import cfg from 'app-config';
import { mapsApi } from 'apis/mapsApi';

import SchemaEdit from 'ui/controls/schemaEdit';
import refs from 'references';

import { _, BbModel, BbCollection, MnView, CollectionView } from 'vendors';

import { View } from 'core';
import modals from '_libs/modals';
import { smartOpen } from 'utils';
import { urls } from 'coms/urls';

import './create-building-modal.less';
import { MapContainerView } from '../../../apis/mapsApi/MapContainerView';
import { buildSearchControl } from '../../../apis/mapsApi';
import { SearchRealtyControl, NewSearchPlaceControl } from 'ui/controls/map/control-input';


const SimilarView = View.extend({
	baseClassName: 'similar-item',
	template: '<label><%= name %></label>, <small><%= fullAddress %></small>',
	templateContext() {
		return {
			fullName: this.model.get('name'),
			fullAddress: this.model.get('fullAddress')
		}
	}
});

const SimilarsView = CollectionView.extend({
	initialize() {
		let apiUrl = this.getOption('apiUrl');
		this.collection = new BbCollection();
		this.collection.url = function() {
			let url = apiUrl + '/searchByText';
			let kvs = _.map(this.urlArgs, (value, key) => `${key}=${encodeURIComponent(value)}`);
			let query = kvs.join('&');
			query = query ? '?' + query : '';
			return url + query;
		}
	},
	childView: SimilarView,
	onChange(val) {
		this.collection.urlArgs = {
			text: val
		}
		this.collection.fetch().then(() => this.triggerMethod('fetched'));
	}
});

const BuildingName = View.extend({
	baseClassName: 'padding-all',
	template: `
		<h3>Добавление здания: Шаг 1</h3>
		<div class="padding-v">
			<p>Введите название или адрес чтобы убедиться, что такого здания еще нет в справочнике</p>
			<label>Название или адрес: </label>
			<input type="text" class="form-control"/>
		</div>
		<div class="actions padding-v">
			<p>Если среди похожих вы не видите своего здания, нажмите кнопку &laquo;далее&raquo;</p>
			<button class="next">Далее</button>
		</div>
		<label>похожие: </label>
		<div class="similars"></div>
	`,
	ui: {
		inp: 'input',
		next: 'button.next'
	},
	regions: {
		similars: '.similars'
	},
	onRender() {
		this.ui.next.prop('disabled', true);
		this.apiUrl = this.getOption('apiUrl');
		const similarsView = new SimilarsView({ apiUrl: this.apiUrl });
		this.showChildView('similars', similarsView);
		similarsView.listenTo(this, 'change', (...args) => similarsView.triggerMethod('change', ...args));
		this.listenTo(similarsView, 'fetched', () => this.ui.next.prop('disabled', false));
	},
	initialize() {
		this.delegate('input input', _.debounce(this.onInput.bind(this), 300));
	},	
	triggers: {
		//'input input': 'input',
		'click .next': 'before:next'
	},
	onInput() {
		let val = this.getInputValue();
		if (!val) return;
		this.triggerMethod('change', val);
	},
	getInputValue() {
		let val = this.ui.inp.val();
		if (val && val.length > 2) {
			return val;
		}
	},
	onBeforeNext() {
		let name = this.getInputValue();
		if (!name) return;
		this.triggerMethod('next', name);
		// let model = new BbModel();
		// model.url = this.apiUrl;
		// model.save({ name }, { wait: true }).then(() => {
		// 	this.triggerMethod('create', model);
		// });
	},
	// onCreate(model) {
	// 	this.trigger('edit:done', model);
	// }
});

const inps = {
	route: {
		caption: 'улица/шоссе'
	},
	house: {
		caption: 'номер дома / удалённость в км'
	},
	sublocality: {
		caption: 'район / округ'
	},
	locality: {
		caption: 'населённый пункт'
	},
	administrativeArea3: {
		caption: 'адм. округ уровень 3'
	},
	administrativeArea2: {
		caption: 'адм. округ уровень 2'
	},
	administrativeArea1: {
		caption: 'адм. округ уровень 1'
	},
	fullAddress: {
		caption: 'полный адрес'
	},

}

function inp(id) {
	let d = inps[id] || {};
	let name = d.name || id;
	let cls = d.className || id;
	let value = d.value || id;
	let caption = d.caption || id;
	return `<span class="form-element"><i>${caption}</i><input class="form-control ${cls}" name="${name}" data-id="${id}" value="<%= ${value} %>" ></span>`
}

function item(className, ...elements) {
	className = className ? ' ' + className : ' one';
	elements = elements.map(e => inp(e)).join('');
	return `<div class="form-item${className}">${elements}</div>`;
}

let fields = ['lat', 'lng', 'street_number', 'route', 'sublocality', 'locality', 'administrative_area_level_3', 'administrative_area_level_2', 'administrative_area_level_1','fullAddress'];
		fields = ['lat', 'lng', 'house', 'route', 'sublocality2', 'sublocality', 'locality', 'administrativeArea3', 'administrativeArea2', 'administrativeArea1', 'fullAddress'];
let addHash = (hash, key, val) => {
	hash[key] = val;
	return hash;
}

const EditAddress = View.extend({
	baseClassName: 'address-form',
	template: `
		<h3>Добавление здания: Шаг 2</h3>
		<p>Установите точку на карте и скорректируйте адрес, затем нажмите кнопку &laquo;Далее&raquo;</p>
		${item('two', 'lat', 'lng')}
		${item('two', 'route', 'house')}
		${item(null, 'sublocality')}
		${item(null, 'locality')}
		${item(null, 'administrativeArea3')}
		${item(null, 'administrativeArea2')}
		${item(null, 'administrativeArea1')}
		${item(null, 'fullAddress')}
		<div class="padding-v">
			<button class="next">Далее</button>
		</div>
	`,
	ui: {
		next: '.next'
	},
	initialize(options) {
		this.mergeOptions(options, ['value']);
		this.delegate('input', 'input[data-id]', _.debounce(this._onInput.bind(this), 300));
		this.delegate('click', 'button.next', _.debounce(this._onClickNext.bind(this), 300));
	},
	validate() {
		let valid = !isNaN(parseFloat(this.value.lat, 10))
		&& !isNaN(parseFloat(this.value.lng, 10))
		&& this.value.route
		&& this.value.house
		&& this.value.fullAddress;
		this.ui.next.prop('disabled', !valid);
	},
	onRender() {
		this.validate();
	},
	_onClickNext() {
		console.log('NEXT CLICKED', this.value);
		this.triggerMethod('next', this.value);
	},
	_onInput(e) {
		let $el = $(e.target);
		let id = $el.data('id');
		let raw = $el.val();
		this.setValue(id, raw);
	},
	transforms: {
		lat: val => parseFloat(val, 10),
		lng: val => parseFloat(val, 10),
	},
	setValue(id, raw) {
		let transform = this.transforms[id];
		let value = transform ? transform(raw) : raw;
		this.value[id] = value;
		console.log('#set-value', value, id);
		this.validate();
	},
	templateContext() {
		let def = fields.reduce((memo, field) => addHash(memo, field, void 0), {});
		return {
			...def,
			...(this.value || {})
		}
	}
});

// const MapView = View.extend({
// 	baseClassName: 'map-container',
// 	template: false,
// 	onAttach() {
// 		this.initializeMap();
// 	},
// 	initializeMap() {
// 		const initializeOptions = this.getMapInitializeOptions();
// 		this.map = mapsApi.attachMapToView(this, initializeOptions);
// 	},
// 	getMapElement() {
// 		return this.el;
// 	},
// 	getMapInitializeOptions() {
// 		const el = this.getMapElement();
// 		const zoom = this.getOption('zoom');
// 		const center = this.getOption('center');
// 		return Object.assign({ el, zoom, center}, this.getOption('mapInitializeOptions', true));
// 	}
// });

const SetupAddress = View.extend({
	baseClassName: 'map-container',
	template: `<div class="address"></div><div class="controls-container"></div><div class="map"></div>`,
	regions: {
		map: '.map',
		address: '.address',
		search: '.controls-container',
	},
	initialize(options) {
		this.mergeOptions(options, ['value']);
	},
	// createOrUpdateAddressMarker(latLng) {
	// 	if (this.marker) {
	// 		this.updateAddressMarker(latLng);
	// 	} else {
	// 		this.createAddressMarker(latLng);
	// 	}
	// 	gmaps.geocodeLatLng(latLng).then(([ok, places]) => {
	// 		if (ok) {
	// 			this.updateAddressForm(places);
	// 		}							
	// 	});	
	// },
	// createAddressMarker(position) {
	// 	this.marker = new google.maps.Marker({
	// 		map: this.map,
	// 		position,
	// 		animation: google.maps.Animation.BOUNCE,
	// 		draggable: true,
	// 		title: 'Перетаскивайте, чтобы изменить адрес'
	// 	});
	// 	this.marker.addListener('dragend', () => {
	// 		gmaps.geocodeLatLng(this.marker.position.toJSON()).then(([ok, places]) => {
	// 			if (ok) {
	// 				this.updateAddressForm(places);
	// 			}							
	// 		});				
	// 		//processPlaces(map, places, getOldMarker, onMarker);
	// 	});
	// },
	// updateAddressMarker(position) {
	// 	this.marker.setPosition(position);
	// },

	// _processSearchedPlaces(places, options) {
	// 	if (places.length == 0) {
	// 		return;
	// 	}
	// 	options = options || {};
	// 	const bounds = new google.maps.LatLngBounds();
	// 	//let placeMarker;
	// 	places.forEach(place => {
	// 		if (!place.geometry) {
	// 			console.log("Returned place contains no geometry");
	// 			return;
	// 		}
	// 		if (place.geometry.viewport) {
	// 			bounds.union(place.geometry.viewport);
	// 		} else {
	// 			bounds.extend(place.geometry.location);
	// 		}
	// 		this.createOrUpdateAddressMarker(place.geometry.location);
	// 	});
	// 	this.map.fitBounds(bounds);

	// 	if (!options.noform)
	// 		this.updateAddressForm(places);

	// },
	updateAddressForm(hash, coords = {}) {
		// const hash = mapsApi.geodecodeToHash(places, coords) || {};
		this.triggerMethod('update:address:form', hash);
		// let geocoded = gmaps.convertGeocodeResults(places) || {};
		// let hash = {
		// 	...(geocoded.components || {}),
		// 	...coords,
		// 	fullAddress: geocoded.fullAddress
		// }
	},
	onUpdateAddressForm(hash) {
		console.log('-- on update adress form --', hash);
		this.showAddress(hash);
	},

	onRender() {

		this.showAddress();
		this.showSearchPlace();
		this.showMap();

	},
	showSearchPlace() {

		this.searchView = new NewSearchPlaceControl({ value: this.value.name });
		this.showChildView('search', this.searchView)
		
	},
	async showMap() {
		
		const mapres = await mapsApi.whenReady();
		if (!mapres.ok) { return console.error('map was not initialized'); }

		const inputEl = this.searchView.inputEl;
		// const { controlEl, inputEl } = buildSearchControl(this.value.name);

		// let $controlEl = $('<span class="search-places-wrapper"><input class="form-control map-input-control" type="text" /><span class="icon"></span></span>');
		// let $inputEl = $controlEl.find('input');
		// $inputEl.val(this.value.name);
		// const inputEl = $inputEl.get(0);
		// const controlEl = $controlEl.get(0);

		// const newLatLng = async coords => {
		// 	const res = await mapsApi.geodecode(coords.lat, coords.lng);
		// 	if (res.ok) {
		// 		this.updateAddressForm(res.value, coords);
		// 	}
		// } 		

		const view = new MapContainerView({
			googleMapOptions: {
				disableDefaultUI: true,
				zoomControl: true,
				scaleControl: true,
			},
			onMapAttach: async map => {

				mapsApi.setupAddressApi(map, {
					searchControl: {
						inputEl: inputEl,
						// controlEl: controlEl,
						updateMarker: true,
					},
					marker: {
						markerClass: 'coral',
						isDraggable: true,
						updateAllowed: true,
						updatePositionOnMapClick: true,
						updatePositionOnDragend: true,
						onPositionChange: hash => this.updateAddressForm(hash)
					},
				});

				/*
				const callback = mapsApi.getFindAndShowAddressCallback(map, { 
					changePositionByClick: true,
					onDrag: newLatLng,
					onPositionChange: newLatLng
				});

				mapsApi.setupSearchInput(map, inputEl, { controlEl, callback });
				let value = inputEl.value;
				if (!value) return;
				const geocodeRes = await mapsApi.geocode(value);
				if (geocodeRes.ok) {
					const places = geocodeRes.value;
					callback(places);
					this.updateAddressForm(places);
				}
				*/
			}
		});

		this.showChildView('map', view, { replaceElement: true });
	},
	showAddress(value = {}) {
		let addressView = new EditAddress({ value: value });
		this.showChildView('address', addressView);
		this.listenTo(addressView, 'next', (...args) => this.triggerMethod('next', ...args));
	},
	// onBeforeNewAddress(marker) {
	// 	gmaps.geocodeLatLng(marker.position, {  }).then(([status, result]) => {
	// 		if (!status) return;
	// 		let hash = gmaps.convertGeocodeResults(result);				
	// 		this.triggerMethod('new:address', {
	// 			...(marker.position.toJSON()),
	// 			fullAddress: hash.fullAddress,
	// 			...(hash.components || {})
	// 		});
	// 		console.log(status, hash, result);
	// 	});
	// },

});


function renameField(hash, field, newfield)
{
	if (!(field in hash)) return;
	let value = hash[field];
	delete hash[field];
	hash[newfield] = value;
}

function renameFields(hash, fields)
{
	_.each(fields, (newField, oldField) => renameField(hash, oldField, newField));
	return hash;
}

const LastStepEdit = MnView.extend({
	className: 'step-last',
	template: _.template(`
		<h3>Добавление здания: последний шаг</h3>
		<div class="props"></div>
	`),
	initialize(options) {
		this.mergeOptions(options, ['value']);
	},
	regions: {
		props: '.props'
	},
	onRender() {
		let properties = {
			markets: {
				caption: 'относится к рынкам',
				//multiple: true,
				emptyText: 'не установлено',
				modelType: 'single',
				valueType: 'enum',
				multiple: true,
				sourceValues: refs.enum('realtyMarkets'),
				required: true,
			},				
			prefix: {
				caption: 'Префикс: Бизнес-центр, Складской комплекс и т.д.',
				emptyText: 'не указано',
				valueType: 'string',
				modelType: 'single',
				_value: this.value.prefix,
				required: true,
			},				
			name: {
				caption: 'Название',
				emptyText: 'не указано',
				valueType: 'string',
				modelType: 'single',
				_value: this.value.name,
				required: true,
			},
			cls: {
				caption: 'класс',
				//multiple: true,
				emptyText: 'не установлен',					
				modelType: 'single',
				valueType: 'enum',
				sourceValues: refs.enum('realtyClasses'),
				required: true,
			},
			taxSvcNumber: {
				caption: 'Номер налоговой',
				emptyText: 'не указан',
				valueType: 'number',
				modelType: 'single',
				validate: v => {
					console.log('[taxSvcNumber]', parseInt(v, 10), !isNaN(parseInt(v, 10)), parseInt(v, 10));
					return !isNaN(parseInt(v, 10)) && parseInt(v, 10) > 0
				},
			},
			realtyType: {
				caption: 'тип недвижимости',
				//multiple: true,
				emptyText: 'не установлен',					
				modelType: 'single',
				valueType: 'enum',
				sourceValues: refs.enum('realtyTypes'),
				required: true,
			},				
			buildingType: {
				caption: 'тип строения',
				//multiple: true,
				emptyText: 'не установлен',					
				modelType: 'single',
				valueType: 'enum',
				sourceValues: refs.enum('buildingTypes'),
				required: true,
			},
			floors: {
				caption: 'Количество этажей',
				emptyText: 'не указано',
				valueType: 'number',
				modelType: 'single',
				_value: this.value.floors,
			},
			buildingStatus: {
				caption: 'Статус строения',
				//multiple: true,
				emptyText: 'не установлен',					
				modelType: 'single',
				valueType: 'enum',
				sourceValues: refs.enum('realtyBuildStatus'),
				required: true,
			},							
		}
		let view = SchemaEdit.inLine(properties, {
			destroyOnDone: false,
			applyText: 'Добавить строение в справочник',
			// beforeApply(data) {
			// 	console.log('#DATA', data);
			// },
		});
		this.listenTo(view, 'done', this._onEditDone);
		this.showChildView('props', view);
	},

	_onEditDone(lastValue) {

		this.triggerMethod('next', lastValue);

	}
});

const CreateBuilding = MnView.extend({
	className: 'create-building-modal',
	template: _.template(`
		<div class="content"></div>
		<!-- <div class="map-container"></div> -->
	`),
	regions: {
		map: '.map-container',
		content: '.content'
	},

	onRender() {
		this.apiUrl = cfg.urls.apiV('realties/realties/');
		this.value = {};
		if (this.currentStep == null) {
			this.currentStep = 0;
		}
		this.showStep(this.currentStep);
	},
	toNextStep() {
		this.currentStep++;
		this.showStep(this.currentStep);
	},
	showStep(num) {
		let stepCb = this['step'+num];
		if (stepCb) {
			stepCb.call(this, this);
		}
	},
	step0() {
		let view = new BuildingName({ apiUrl: this.apiUrl });
		this.listenTo(view, 'next', name => {
			this.value.name = name;
			this.toNextStep();
		});
		this.showChildView('content', view);
	},		
	step1() {
		let view = new SetupAddress({ value: this.value });
		this.listenTo(view, 'next', address => {
			this.value.jsonAddress = address;
			this.toNextStep();
		});
		this.showChildView('content', view, { replaceElement: true });
	},
	step2() {

		let view = new LastStepEdit({ value: this.value });
		this.listenTo(view, 'next', value => {
			this.beforeSend(this.value, value);
		});			
		this.showChildView('content', view);
		
	},
	fixPostData(post) {
		let addr = post.jsonAddress;
		delete post.jsonAddress;
		addr = renameFields(addr, {
			street_number: 'house',
			postal_code: 'postalCode',
			administrative_area_level_1: 'administrativeArea1',
			administrative_area_level_2: 'administrativeArea2',
			administrative_area_level_3: 'administrativeArea3',
		});
		let stretch = ['lat', 'lng', 'house', 'route', 'fullAddress'];
		post.address = {
			...(_.pick(addr, ...stretch)),
			GD: _.omit(addr, ...stretch)
		}
		post.address.GD.house = post.address.house;
		post.address.GD.route = post.address.route;
		return post;
	},
	beforeSend(val1, val2)	 {
		let postData = this.fixPostData({
			...val1,
			...val2
		})
		console.log('### WILL BE POSTED', postData);
		this.send(postData)
	},
	send(data) {
		
		let model = new BbModel();
		model.url = this.apiUrl;
		model.save(null, { attrs: data, wait: true }).then(() => {
			this.triggerMethod('create', model);
			modals.confirm('Перейти на страницу только-что созданного здания?').then((res) => {
				const url = urls.page('actualization/realties-list/' + model.id +'/edit');
				console.log(url);
				smartOpen(url);
			}, () => {});
		});			
	}
});

export default CreateBuilding;	

