﻿//define('m-comments', ['m-comments-comment', 'm-comments-comments'],function(Comment, Comments){});

import Comment from './comment';
import Comments from './comments';


var ns = {};

ns.CommentsCollection = Comments;
ns.CommentModel = Comment;

export default ns;

