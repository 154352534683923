﻿//define('m-employees-modelInGroup', ['m-employees-model','action'], function (EmployeeModel, action) {});

import EmployeeModel from './model';
import action from '_helpers/action';

var Model = EmployeeModel.extend({
	actions: function () {
		return [
			action('removeEmployeeFromGroup', 'исключить сотрудника', { staff: 'manageEmployeesInDepartments' }, { order: 10 })
		];
		//
	},
	actionRemoveEmployeeFromGroup: function (options) {
		if (options.modal)
			options.modal.destroy();

		this.loudDestroy({ wait: true }).then(function () {

			//appRadio.reply('departmentEmployees:' + dep.get('id'), col.pluck('id'));
		});

	},
	urlRoot: null,
});

export default Model;
