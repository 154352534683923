//define('root-notnative', ['appModule', '_libs/identity', 'app-config', 'link'], function (Module, identity, cfg, link) {});

import Module from 'appModule';
import identity from '_libs/identity';
import link from 'link';

//console.log('root not native url', link.route('notNativeRoot'));

var module = Module.content({
	name: 'root-notnative',
	routeData: () => link.route('notNativeRoot'), 
	label: 'Необходимо авторизоваться',
	urlKey: 'notNativeRoot',
	onRun: function () {
		
		console.log('==! NOT NATIVE ROOT !==');
	}
});

if (identity.isLogged()) {
	module = null;
}

export default module;