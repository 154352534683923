﻿//define('ui-models-values-rest', [], function() {});
import { Model as BbeModel, Collection as BbeCollection } from 'core/bbe';
import { MneObject } from 'core/mne';
import { _ } from 'vendors';

var RestModel = MneObject.extend({
    initialize: function() { },
    get: function(txt, opts) {
        var prepare = this.getOption('prepareData');
        var data;
        if (typeof prepare === 'function')
            data = prepare.apply(this,arguments);
        opts = _.extend({},{ data: data },opts);
        //console.log('>>> rest', opts);
        return this.fetch(opts);
    },
    getModelById: function (id) {
        var Model = this.getModelClass();
        var model = new Model({ id: id });
        model.fetch();
        return model;
    },
    getModelClass: function () {

        //console.log('??', this.getOption('baseUrl'));
        var Coll = this.getOption('Collection') || BbeCollection;
        var Model = Coll.prototype.model || BbeModel;
        var parseMethod = this.getOption('modelParse');
        var url = this.getOption('cardUrl');
        //var tmpModel = new Model();

        var ext = {};
        if (typeof parseMethod === 'function') 
            ext.parse = parseMethod;

        if (url)
            ext.url = url;

        return Model.extend(ext);
    },
    getUrl() {
        const defaultUrl = _.result(this, 'defaultUrl');

        let url = this.getOption('url');

        if (typeof url === 'function') {
            url = url.call(this, this);
        }
        return url || defaultUrl;
    },
    fetch: function(opts) {
        let url = this.getUrl();
        var Coll = this.getOption('Collection') || BbeCollection;
        var Model = this.getModelClass();
        
        //var Model = Coll.prototype.model || BbeModel;

        //if (typeof this.getOption('modelParse') === 'function') {
        //    Model = Coll.prototype.model.extend({
        //        parse: this.getOption('modelParse')
        //    });
        //}
        var col = new Coll([], {model:Model});
        col.url = url;
        this.lastFetchCollection = col;
        var obj = this;
				col.once('sync', (...args) => this.triggerMethod('sync', ...args));
        var promise = col.loudFetch(opts).then(function(data) {
            obj.triggerMethod('data:arrived', col, opts, data);
            return data;
        });
        return promise;
    },
    filter(predicate) {
        if (!this.lastFetchCollection) return false;
        return this.lastFetchCollection.filter(predicate);
    },
    findWhere (predicate) {
        if (!this.lastFetchCollection) return;
        return this.lastFetchCollection.findWhere(predicate);
    }
});

var Buildings = RestModel.extend({
    prepareData: function(txt) {
        var data = { text: txt };
        return data;
    }
});

var ns = {};
ns.common = RestModel;
ns.buildings = Buildings;


RestModel.create = function(options) {
    //var type = (options && options.type) || 'common';
    var rest = new this(options); //new ns[type](options);
    return rest;
}

export default RestModel;