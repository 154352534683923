import { View } from 'core';
import { PreviewsView } from './previews-view';
import { ImageView } from './image-view';
import { mediaApi } from 'apis';

import './galery-view.less';

export const GaleryView = View.extend({
    className: 'image-galery',
    template: `
    <div class="big-container">        
        <button class="big-left">&lt;</button><div class="big"></div><button class="big-right">&gt;</button>
        <button class="open">открыть в отдельной вкладке</button>
    </div>
    <div class="previews"></div>`,
    regions: {
        big: '.big',
        previews: '.previews'
    },
    onRender() {
        this.showBig(this.model || this.collection.models[0]);
        this.showPreviews();
    },
    showBig(model) {
        if (!model) { return; }
        let view = new ImageView({
            imageOptions: 'hd',
            imageHash: model.id,
            model
        });
        this.showChildView('big', view);
    },
    showPreviews() {
        let view = new PreviewsView({
            collection: this.collection
        });
        this.previews = view;
        this.showChildView('previews', view, { replaceElement: true });
    },
    childViewEvents: {
        'preview'(model) {
            this.showBig(model);
        }
    },
    events: {
        'click .big-left'(e) {
            this._moveBig(-1);
        },
        'click .big-right'(e) {
            this._moveBig(1);
        },
        'click .open'(e) {
            let reg = this.getRegion('big');
            let bigOne = reg.currentView.model;
            let url = mediaApi.url(bigOne.id, 'hd', 'jpg');
            let win = window.open(url,'_blank');
            win.focus();
        }
    },
    _moveBig(dif) {
        let reg = this.getRegion('big');
        let bigOne = reg.currentView.model;
        let models = this.collection.models;
        let index = models.indexOf(bigOne);
        let newIndex = index + dif;

        if (newIndex < 0) {
            newIndex = models.length - 1;
        } else if (newIndex >= models.length) {
            newIndex = 0;
        }

        let newBig = models[newIndex];

        this.showBig(newBig);
    }
});