﻿//define('realties-object', ['appModule', 'realties-models', 'link', 'realties-object-layout'], function (appModule, realtiesNs, link, Layout) {});

import appModule from 'appModule';
import realtiesNs from 'svc/realties/models';
import link from 'link';
import Layout from './Layout';

import './realties-object.less';

var module = appModule.content({
	name: 'realties-object',
	routeData: function () {
		console.error('-realty-object-page-', link.route('realtiesObject'));
		return link.route('realtiesObject');
	},
	onBeforeRun: function (id) {
		var model = new realtiesNs.ObjectModel({ id: id });
		model.loudFetch();
		this.addRunPromise(model.apiPromise);
		this.addRunArgument(model);
	},
	onRun: function (model) {
		//console.log('hello man', model)
		var links = [
			link('на главную', 'root', { attrs: { class: 'go-up' } })
			, link('подбор по списку', 'realtiesSearch')
			, link('подбор по карте', 'realtiesSearchMap')
		];
		//var result = 
		this.createAndShow(Layout, 'Карточка площади ' + model.get('webId'), { navLinks: links, model: model }, { navLinks: links });
	}
});

export default module;