import { actionsApi, C } from "apis";
import EditValue from 'ui/controls/editValue';
import { backendApi } from 'apis';
import modals from '_libs/modals';
// const propertySchemaExample = {
//     property: '<path>',
//     valueType: 'string',
//     header: 'Укажите название группы',
// }

function patchProperty(schema, entity) {
    console.log(' -- entity --', entity);
    const value = entity 
    ? entity.get
        ? entity.get(schema.property)
        : entity[schema.property]
    : undefined;

    return EditValue.modal.single({ ...schema, value })
    .done((type, value) => {
        if (type != 'ok') return;
        let hash = { [schema.property]: value };
        return backendApi.patch(hash, entity, true);
    });
}

const actions = actionsApi.getCollection('admin-group');
const A = actions.createHelper();

const schema = {
    name: {
        property: 'name', valueType: 'string', header: 'Укажите новое название'
    },
    order: {
        property: 'order', valueType: 'number', header: 'Укажите новое значение сортировки'
    }
}

const noop = () => {};

A({
    'change:name': {
        placement: 'page',
        name: 'изменить название',
        claims: C({ svcAdminModule: 'manageGroups' }),
        action: group => patchProperty(schema.name, group),
    },
    'change:order': {
        placement: 'page',
        name: 'изменить сортировку',
        claims: C({ svcAdminModule: 'manageGroups' }),
        action: group => patchProperty(schema.order, group),
    },
    'delete':{
        placement: 'page',
        name: 'удалить',
        glyph: 'trash',
        claims: C({ svcAdminModule: 'manageGroups' }),
        action: group => modals.confirm('удаление группы','подтвердите действие').then(() => {
            backendApi.delete(group)
        }, noop),
    }
});


export default actions;