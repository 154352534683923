import { View } from 'core/views';


import { backendApi } from 'apis';
import { _ } from 'vendors';

import { ActionsView } from 'apis/actions';

import { EmployeeBoxView } from './EmployeesBoxView';
import { PrincipalRightsBoxView } from '../principal-rights/PrincipalRightsBoxView';



export const PrincipalGroupPageLayout = View.extend({
	baseClassName: 'ui2-rows fixed-height principal-group',
	template: _.template(`
		<div class="actions"></div>
		<div class="ui2-columns fixed-height">
			<div class="ui2-column employees fixed-height"></div>
			<div class="ui2-column rights fixed-height"></div>
		</div>
	`),
	regions: {
		actions: '.actions',
		employees: '.employees',
		rights: '.rights',
	},
	onRender() {
		this.showActions();
		this.showEmployees();
		this.showRights();
	},
	showActions() {

		const actions = this.getOption('actions', true);
		const actionsIds = this.getOption('actionsIds', true);

		if (actions && actionsIds.length) {
			let view = new ActionsView({ actions, ids: actionsIds, actionArgs: [this.model] });
			this.showChildView('actions', view, { replaceElement: true });
		}

	},
	showEmployees() {
		let model = this.model;
		let view = new EmployeeBoxView({ model, joinedAsEnabled: this.getOption('joinedAsEnabled') });
		this.showChildView('employees', view);
	},
	showRights() {
		let model = this.model;
		let view = new PrincipalRightsBoxView({
			model
		});
		this.showChildView('rights', view);
	},
	saveClaimsChangesAction() {
		return backendApi.post(this.model.newClaims, this.model, 'new-claims').then(resClaims => {
			let claims = _.extend({}, this.model.get('claims'), resClaims);
			this.model.newClaims = {};
			this.model.set({ claims });
			this.render();
		});
	}
});


