import { BaseObject } from 'core';
import { MiniStore } from './MiniStore';

export const Selector = BaseObject.extend({
	initialize(opts) {
		this.mergeOptions(opts, ['parent']);
		this.store = new MiniStore({ getId: view => view.cid });
		this.viewsByCid = {};
	},

	isSelected(view) {
		if (this._empty) return false;
		return this.store.has(view);
	},
	clear() {
		this._empty = true;
		this._takeAction(this.store.items, this._unselectView, 'has');
		this.store.clear();
		delete this._empty;
		this._broadcastChange();
	},


	_takeAction(arg, method, checkMethod, silent) {
		if (checkMethod == false || checkMethod == true) {
			silent = checkMethod;
			checkMethod = undefined;
		}
		if (Array.isArray(arg)) {
			if (_.reduce(arg, (memo, item) => method.call(this, item, checkMethod) || memo, false)) {
				!silent && this._broadcastChange();
			}
		} else {
			if (method.call(this, arg, checkMethod)) {
				!silent && this._broadcastChange();
			}
		}
	},

	select(viewOrViews) {
		this._takeAction(viewOrViews, this._selectView);
	},
	_selectView(view) {
		if (this.store.add(view)) {
			this._triggerModel(view, 'selected');
			return true;
		}
	},		
	unselect(viewOrViews) {
		this._takeAction(viewOrViews, this._unselectView);
	},		
	_unselectView(view, method) {
		method = method || 'remove';
		if (this.store[method].call(this.store, view)) {
			this._triggerModel(view, 'unselected');
			return true;
		}
	},

	toggle(viewOrViews) {
		this._takeAction(viewOrViews, this._toggleView);
	},
	_toggleView(view) {
		return this._selectView(view) || this._unselectView(view);
	},
	_triggerModel(view, event) {
		if (!view.model) return;
		view.model.trigger(event, view.model, view);
	},

	click(context) {
		let prevClick = this.prevClick;
		let skey = context.event && context.event.shiftKey;
		if (skey && prevClick) {
			this._toggleRangeClick(context, prevClick);
		} else {
			this._toggleOneClick(context, prevClick);
		}
		this.prevClick = context;
	},		
	_toggleOneClick(context, prevClick) {
		this.toggle(context.view);
		// this._toggleView(context.view);
		// this._broadcastChange();
	},
	_toggleRangeClick(context, prevClick) {
		let prevIndex = this.parent.getChildIndex(prevClick.view);
		let currIndex = this.parent.getChildIndex(context.view);

		// same item or nearest.
		if (currIndex == prevIndex || Math.abs(currIndex - prevIndex) == 1) {
			return this._toggleOneClick(context, prevClick);
		}

		let step = currIndex >= prevIndex ? 1 : -1;

		for (let x = prevIndex + step; step > 0 ? x <= currIndex : currIndex <= x; x = x + step) {
			let view = this.parent.getChildViewByIndex(x);
			this._toggleView(view);
		}
		this._broadcastChange();
	},
	
	_broadcastChange() {
		this.trigger('change');
		//innerBus.trigger('selector:change', this.store);
	}
});