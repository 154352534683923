﻿//something happens here - ;

window['UUrl'] = (function () {
	function UrlClass (value) {
		
		value = value || window.location.toString();

		var hashArr = value.split('#');
		if (hashArr.length > 1) {
			value = hashArr.shift();
			this.hash = '#' + hashArr.join('#');
		}

		var qsArr = value.split('?');
		if (qsArr.length > 1) {
			value = qsArr.shift();
			this.search = '?' + qsArr.join('?');
		}

		var protoArr = value.split('://');
		if (protoArr.length > 1) {
			this.protocol = protoArr.shift();
			value = protoArr.join('://');
		}

		var pathArr = value.split('/');
		if (pathArr.length > 1) {
			value = pathArr.shift();
			this.path = pathArr.onlyValues().join('/');
		}
		
		value = value || document.location.hostname;

		var portArr = value.split(':');
		if (portArr.length > 1) {
			this.port = portArr.pop();
			value = portArr.join(':');
		}

		var errors = /[^\w|\d|_|\-|.|а-я]/gmi.test(value);
		/*	/[^\w|\d|\_|\-|\.|а-я]/gmi.test(value); */
		if (errors)
			throw "url malformed: " + value;

		this.hostName = value;
		this.isLocal = this.hostName == document.location.hostname;

		if (this.hostName == document.location.hostname && !this.protocol)
			this.protocol = document.location.protocol.replace(':', '');
		else if (!this.protocol)
			this.protocol = "http";

		if (!this.search) this.search = '';
		if (!this.hash) this.hash = '';
		if (!this.path) this.path = '';
	}
	
	(function (p) {
		p.isLocal = undefined;
		p.protocol = undefined;
		p.port = undefined;
		p.hostName = undefined;
		p.path = undefined;
		p.search = undefined;
		p.hash = undefined;
		p.toString = function (abs) {

			var root = [].addValues(this.protocol + "://" + this.hostName, this.port).join(':');
			var res = '';
			if (typeof abs == 'undefined' && !this.isLocal)
				res = [].addValues(root, this.path).join('/') + this.search + this.hash;
			else if (typeof abs == 'undefined' && this.isLocal)
				res = '/' + this.path + this.search + this.hash;
			else
				res = '';


			if (abs == true || (typeof abs == 'undefined' && !this.isLocal))
				res = [].addValues(root, this.path).join('/') + this.search + this.hash;
			else if (abs == false || (typeof abs == 'undefined' && this.isLocal))
				res = '/' + this.path + this.search + this.hash;
			else
				res = [].addValues(root, this.path).join('/') + this.search + this.hash;

			return res;
		};
		p.absolute = function () {
			return this.toString(true);
		}
		p.relative = function () {
			return this.toString(false);
		}
	})(UrlClass.prototype);
	

	return UrlClass;
})();
