import { View } from 'core/view';
import { buildView, isViewClass } from 'utils';
import { _ } from 'vendors';

const emptyArr = [];

function attachChild(child) {
	if (child._isDestroyed) { return; }
	child._isAttached = true;
	child.triggerMethod('attach');
}
function dettachChild(child) {
	if (child._isDestroyed) { return; }
	child._isAttached = false;
	child.triggerMethod('dettach');
}


export const HamburgerView = View.extend({

	constructor: function() {
		this.children = [];
		this.children.byName = {};
		View.apply(this, arguments);
		this.on({
			'destroy': this.destroyChildren,
			'attach': () => this.children.forEach(attachChild),
			'dettach': () => this.children.forEach(dettachChild),
		});
	},

	render() {
		const template = this.getTemplate();

		//if (template === false || this._isDestroyed) { return this; }

		this.triggerMethod('before:render', this);

		// If this is not the first render call, then we need to
		// re-initialize the `el` for each region
		if (this._isRendered) {
				this._reInitRegions();
		}

		if (template) {
				this._renderTemplate(template);
				this.bindUIElements();
		}

		this.renderChildren();

		this._isRendered = true;
		this.triggerMethod('render', this);

		return this;
	},

	renderChildren() {
		this.triggerMethod('before:render:children');

		this.destroyChildren();

		let views = this.getChildren() || emptyArr;
		const isArray = Array.isArray(views);
		_.each(views, (rawView, key) => {
			let viewName = !isArray ? key : undefined;
			let view = this.buildChildView(rawView, viewName);
			if (view)
				this.addChildView(view);
		});
		// for (let x = 0; x < views.length; x++) {
		// 	let rawView = views[x];
		// 	let view = this.buildChildView(rawView, x);
		// 	if (!view) { continue; }

		// 	this.addChildView(view, x);

		// }

		this.triggerMethod('render:children');
	},

	addChildView(view) {
		this.children.push(view);
		const viewName = view.getOption('name', true);
		if (viewName) {
			this.children.byName[viewName] = view;
		}
		// if (this.getOption('DEBUG')) {
		// 	console.log('DEBUG');
		// }
		this._proxyChildViewEvents(view);
		view.triggerMethod('setup', view, this);

		if (!view._isRendered) {
			view.render();
		}
			
		let container = this.getChildrenContainer();
		container.appendChild(view.el);
		this.listenTo(view, 'destroy', () => this._removeChild(view, viewName));


		if (this.setupChildView) {
			this.setupChildView(view, viewName);
		}
	},

	getChildrenContainer() {
			let selector = this.getOption('childrenContainer', true);
			if (selector) {
					return this.$(selector).get(0);
			}
			return this.el;
	},

	getChildren() {
			return this.getOption('childrenViews', true);
	},

	buildChildView(raw, viewName) {
		if (!raw) return;

		if (typeof raw === 'function' && !isViewClass(raw)) {
			raw = raw.call(this, this);
		}

		let viewOptions = this._buildChildViewOptions();
			// this.getOption('childViewOptions', true);

		let defaultViewOptions = { model: this.model, collection: this.collection };
		if (viewName) {
			defaultViewOptions.name = viewName;
		}
		let view = buildView(raw, { invokeContext: this, invokeArgs: this, viewOptions, defaultViewOptions });

		
		return view;
	},
	
	_buildChildViewOptions() {
		const opts = this.getOption('childViewOptions', true);
		const options = Object.assign({}, opts);

		const keys = this.getOption('childViewOptionsKeys', true) || [];
		return keys.reduce((memo, key) => {
			const value = this.getOption(key, true);
			if (value !== undefined) {
				memo[key] = value;
			}
			return options;
		}, options);

	},

	_removeChild(view, viewName) {
			let index = this.children.indexOf(view);
			if (index >= 0) {
					this.children.splice(index, 1);
			}
			if (viewName) {
				delete this.children.byName[viewName];
			}
	},

	destroyChildren() {
			this.children.forEach(view => {
					this.stopListening(view);
					view.destroy();
			});
			this.children.length = 0;
			this.children.byName = {};
	},

	getNamedView(name) {
		return this.children.byName[name];
	},
	getNamedViews() {
		return { ...this.children.byName };
	}

});