import { SmartPropertiesLinesView } from 'coms/ui/PropertiesLineView';
import { modelSchema } from '../schema';
import { claimsApi } from 'apis/claims';
import { fastEditAction } from '../../fast-edit-action';

const commonProps = [
	// ['id', 'status'],
	// ['market', 'operation'],
	['forCustomer.priceMeter', 'forCustomer.priceSquare'],
	'forCustomer.priceIncludes',
	'forCustomer.priceExcludes',
]

const commonBottomProps = [
	['forCustomer.taxType', 'forCustomer.boma'],
	['forAgent.contractType', 'forAgent.income'],
	'forCustomer.offerContractType',
	'modified'
]

const rentProperties = [
    ...commonProps,
    'forCustomer.depositeInfo',
    'forCustomer.rentDurationInfo',
		...commonBottomProps
];

const sellProps = [
    ...commonProps,
		...commonBottomProps,
    // 'forCustomer.taxType',
    // 'forCustomer.priceIncludes',
    // 'forCustomer.priceExcludes',
    // ['modified', 'actualize.last']
]

const mainProperties = [
    ['offerFullIdentifier', 'actualize.last'],
		'status'
]

export const RealtyOfferPropertiesView = SmartPropertiesLinesView.extend({
    thisClassName: 'with-borders colored-values',
    modelSchema: modelSchema,
    editEnabled: () => claimsApi.canEditRealtiesInCard(),
    editAction: fastEditAction,
    properties() {
        let isRent = this.model.get('operation') == 'rent';
        return isRent ? rentProperties : sellProps;
    }
    //showValueless: false
});

export const RealtyOfferMainPropertiesView = SmartPropertiesLinesView.extend({
    thisClassName: 'with-borders colored-values main-properties',
    modelSchema: modelSchema,
    editEnabled: () => claimsApi.canEditRealtiesInCard(),
    editAction: fastEditAction,
    properties: mainProperties

});