// import { editInModal } from "../../../../coms/ui/EditView";

import { HamburgerView } from "coms/ui/HamburgerView";
//import { DropdownButton } from "../../../../coms/ui/DropdownButton";
// import { EditContactInfo } from "./EditContactInfo";
import { unFlatObject, flatObject } from 'utils';
import { EditContactValueInfo } from "./EditContactValueInfo";
// import './edit-contact.less';
// import { EditContactContacts } from "./EditContactContacts";
// import { EditContactBuildings } from "./EditContactBuildings";
// const defaultShowOptions = {
// 	modalCssCfg: "fullscreen",
// 	cssClasses: {
// 		dialog: "edit-contact-modal-dialogue",
// 		content: 'no-padding'
// 	}
// }


export const EditContactValueLayout = HamburgerView.extend({
	thisClassName: 'edit-contact-value-layout',
	initialize() {
		this.isNewContact = this.getOption('isNewContact', true);
		this.isNew = this.getOption('isNew', true);
		this.modelHash = this.model ? flatObject(this.model.attributes) : {};
	},
	getChildren() {
		return [
			{
				class: EditContactValueInfo,
				model: this.model,
				modelHash: this.modelHash,
				isNew: this.isNew,
				isNewContact: this.isNewContact,
				trackChanges: true,
			},
			// {
			// 	class: EditContactContacts,
			// 	modelHash: this.modelHash
			// },
			// {
			// 	class: EditContactBuildings,
			// 	modelHash: this.modelHash
			// }
		]
	},
	childViewEvents: {
		'before:edit:done'(hash) {
			this.triggerMethod('before:edit:done', hash, this.isNew);
		},
	}
});