import { HamburgerView } from "coms/ui/HamburgerView";
import InfoView from "./info";
import ObjectsView from "./objects";
import './owner-in-realty-page.less';
import Model from "../model";

const NewLayout = HamburgerView.extend({
	className: 'owner-in-realty-page',
	childrenViews: [
		InfoView,
		ObjectsView,
	],
	initialize() {
		// console.log('new layout', this);
		this.model = new Model({
			ownerId: this.getOption('ownerId'),
			realtyId: this.getOption('realtyId')
		});
		// this.model.on('all', c => console.log('[all]', c));
		this.listenToOnce(this.model, 'sync', this.render);
		this.model.firstFetch();
	},
	onBeforeRender() {
		this.model.syncObjects();
	}
});

export default NewLayout;