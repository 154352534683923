import { Backbone, history } from 'vendors';

import { Model } from './model';
import { Collection } from './collection';
import { DependedCollection } from './dependedCollection'; //'assets-backbone-dependedCollection';
import { LoadmoreableCollection } from './loadmoreableCollection'; //'assets-backbone-loadmoreableCollection';

export const Bbe = {
	Bb: Backbone,
	history,
    Model,
    Collection,
    DependedCollection,
    LoadmoreableCollection
};

window.Bb = Backbone;
window.Bbe = Bbe;

