﻿//define('svc-tasks-create-layout', ['bus', 'link', 'bus-svc', '_libs/identity', 'references', 'modals', 'ui-controls-editValue','m-tasks'], function (Bus, link, svcRadio,identity, refs, modals, EditValue, tasksNs) {});




import Bus from 'bus';
import link from 'link';
import svcRadio from 'bus/svc';
import identity from '_libs/identity';
import refs from 'references';
import modals from '_libs/modals';
import EditValue from 'ui/controls/editValue';
import tasksNs from 'm/tasks';

import template from './layout.html';

import { MneView } from 'core/mne';

var Layout = MneView.extend({
	className:'board-container',
	//template: '#tmpl-service-tasks-create-layout',
	template,
	initialize: function (options) {
		// this.on('all', (c) => console.log('layout:',c));
		this.initializeModel(options);
		// this.model.on('all', (c) => console.log('MOD:', c, arguments));
	},
	initializeModel: function(options) {
		if (this.model) return;

		this.model = new tasksNs.TaskModel();
		if (options.entity) {
			var field = options.entityType + 'Id';
			this.model.set(field, options.entity.id);
		}
	},
	ui: {
		'puChange': '.list-btn[data-change]',
		'create':'button.create',
	},
	triggers: {
		'click @ui.puChange': 'change:in:popup',
		'click @ui.create':'create:click'
	},
	modelEvents: {
		'change:type': function () {
			var type = this.model.get('type');
			var todo = '';
			var values = ['позвонить', 'отправить письмо', 'показ помещения', 'встреча у нас'];
			if (type == 'call') {
				todo = 'позвонить';
			}
			else if (type == 'email') {
				todo = 'отправить письмо';
			}
			else if (type == 'outHomeMeeting') {
				todo = 'показ помещения';
			}
			else if (type == 'inHomeMeeting') {
				todo = 'встреча у нас';
			}
			var current = this.model.get('toDo');
			if (!current || values.indexOf(current)>-1)
				this.model.set('toDo', todo, { silent: true });
		},
		'change': function () {
			//console.log('MOD:', arguments);
			this.render();
		},
	},
	getPopupFieldSetup: function (field) {
		var setup = {
			'dates.strictDeadline': {
				valueType: 'boolean',
				header: 'Жесткий дедлайн?',
				sourceValues: { true: "Жесткий дедлайн", false: "Условный дедлайн"},
				value: this.model.get('dates').strictDeadline.toString(),
			},
			'type': {
				valueType: 'string',
				header: 'Тип задачи',
				sourceValues: refs.enum('employeeTaskTypes'),
				value: this.model.get('type'),
			},
			'toDo': {
				valueType: 'string',
				header: 'Суть задачи',
				value: this.model.get('toDo'),
			},
			'dates.duration': {
				valueType: 'number',
				header: 'Предположительная продолжительность в минутах',
				value: this.model.get('dates').duration,
			},
			'responsibleId': {
				valueType: 'string',
				header: 'Выберите ответственного',
				value: this.model.get('responsibleId'),
				controlType: 'selectEmployees',
				sourceValues: svcRadio.request('employeesSelect'),
			},
			'checkerId': {
				valueType: 'string',
				header: 'Выберите проверяющего',
				value: this.model.get('checkerId'),
				controlType: 'selectEmployees',
				sourceValues: svcRadio.request('employeesSelect'),

				//sourceValues: svcRadio.request('employeesSelect'),
				//viewFilter: function (v) {
				//	return v.model.get('state') != 'fired' && !!v.model.get('userId');
				//},
			},
			'dates.deadline': {
				controlType:'datetime2',
				valueType: 'datetime',
				modelType: 'single',
				header: 'Установите крайний срок',
				value: this.model.get('dates').deadline,
				noTime: false,
			},
			'dates.remindAt': {
				valueType: 'datetime',
				header: 'Установите дату напоминания',
				value: this.model.get('dates').remindAt,
			}
		};
		return setup[field];
	},
	onChangeInPopup: function (_view, e) {

		var view = this;

		var $el = $(e.target).closest('[data-change]');
		var field = $el.data('change');
		var setup = _.resultArg(view, 'getPopupFieldSetup', field);
		if (!setup) return;
		var model = view.model;
		var tmp = EditValue.modal.single(setup)
			.done(function (type, newvalue) {
				if (type != 'ok') return;

				if (newvalue instanceof Array && !setup.multiple)
					newvalue = newvalue[0];

				if (field == 'dates.strictDeadline')
					newvalue = newvalue == 'true';

				model.setValueByPath(field, newvalue);

				if (field == 'responsibleId' && newvalue != identity.get('employeeId'))
					model.set('checkerId', identity.get('employeeId'));
				else if (field == 'type') {
					var minutes = 15;
					switch (newvalue) {
						case 'common':
							minutes = 15; break;
						case 'call':
						case 'email':
							minutes = 5; break;
						case 'outOfOffice':
							minutes = 150; break;
					}
					model.setValueByPath('dates.duration', minutes);
				}
			})
			.fail(function (type, newval, initval) {
				if (type != 'reset') return;
					model.setValueByPath(field, undefined);
			});

		console.log('TMPL', tmp);
		
	},
	onCreateClick: function () {
		var model = this.model;
		var dealId = model.get('dealId');
		var clientId = model.get('clientId');
		//var hasId = dealId || clientId;
		var _this = this;

		var entity = this.getOption('entity');
		var shouldFixAction = entity && _.isFunction(entity.fixTaskAction);

		model.loudSave().then(function () {
			console.log('task created', !!entity);
			//var entity = _this.getOption('entity');
			if (entity) {
				entity.trigger('task:add', model);
				if (shouldFixAction)
					entity.fixTaskAction(model);
				//entity.fetch();
			}

			if (_this.getOption('closeOnCreate')) {
				console.log(_this.getOption('entity'))
				_this.destroy();
				return;
			}

			var url;
			if (dealId)
				url = link.url('dealCard:' + model.get('deal').wId);
			else if (clientId)
				url = link.url('clientCard:' + model.get('client').wId);
			if (url) {

				Bus.navigate(url);
				return;
			}

			model.clear({ silent: true });
			var defs = _.result(model.constructor.prototype, 'defaults');
			model.set(defs)
			//var _await = 
			modals.notify('Задача поставлена');

		});
		//console.log(this.model.toJSON());
	},
	templateContextExtend: function () {
		return {
			_submitDisabled: this.model.validateCreate() ? '' : ' disabled="disabled"',
		}
	}
});


Bus.app.reply('entityTask:create', function(options) {

	options[options.entityType + 'Id'] = options.entity.id;
	options.closeOnCreate = true;
	var view = new Layout(options);

	Bus.modals.request('modal', view, { modalCssCfg: 'autoCenter' });

});


export default Layout;
