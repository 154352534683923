// define('svc/actualization/realties-list/realty/edit/properties/controls', [
//     'svc/actualization/realties-list/realty/edit/properties/bus'
// ], function(
//     bus
// ) {});


import bus from './bus';

function hasErrors(errors) {
    if (errors == null || errors === true || (Array.isArray(errors) && !errors.length)) {
        return false;
    } else {
        return true;
    }
}

const mod = {
    ControlValueMixin: {
        initialize() {
            this.initializeValue();
        },
				transformIncomingValue(value) { return value; },
        initializeValue() {
            if ('schema' in this) return;
            this.entity = this.getOption('entity');
            let schema = this.schema = this.getOption('schema');
            let value = this._value = this.transformIncomingValue(this.getOption('value'));
            this.value = value;

            if (schema.get('valueType') == "enum") {
                this.initializeEnumValue()
            }
        },
        initializeEnumValue() {
            if (this.schema.get('multiple') && !Array.isArray(this.value)) {
                let value = this.value;
                if (value === '' || value == null) {
                    value = [];
                } else {
                    if (typeof(value) == 'number') {
                        value = void 0;
                    }
                    value = (value || '').split(/\s*,\s*/g);
                    // try {
                    // } catch(e) {
                    //     console.log(value, this.schema);
                    //     throw e;
                    // }
                }
                this.value = value;
            }
        },
        getDefaultControlOptions() {
            return {
                schema: this.schema,
                value: this.value
            }
        },
        buildControlOptions(add) {
            return Object.assign({}, this.getDefaultControlOptions(), add);
        }
    },

    ControlChangeValueMixin: {

        changeValueAsync(value) {
            let errors = this.validateAsync(value);
            if (hasErrors(errors)) {
                this.broadcastErrors(errors);
            } else {
                this.broadcastValue(value);
            }
        },

        validateAsync(value) {

        },

        broadcastErrors(errors) {
            console.log('# error:', this.schema.id, errors)
        },
        broadcastValue(value) {
            bus.setEntityValue(this.entity, this.schema, value);
        }

    },

    InputValueMixin: {

        transformInputValue(value) {
            return this.schema.fromString(value);
        },

        onInputValue(value) {
            value = this.transformInputValue(value);
            this.changeValueAsync(value);
        }

    },

    InputValueEventsMixin(tagName, eventName = 'input') {
        let event = eventName + ' ' + tagName;
        return {
            [event](e) {
                this.onInputValue(e.target.value);
            }
        }
    }

}

export default mod;
