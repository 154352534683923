﻿//define('m-employees-createModel', ['bus-app', 'app-paths', ], function (appRadio, paths) {});

//import appRadio from 'bus/app';
import paths from 'app-paths';

import { BbeModel } from 'core/bbe';

var Model = BbeModel.extend({
	urlRoot: function () {
		return paths.api.employeeCreate;
	}
});


export default Model;