﻿//define('svc-staff-emps', ['appModule', 'link', '_libs/identity', 'svc-staff-emps-layout', 'svc-staff-emps-invite', 'svc-staff-emps-create'], function(Module, link, identity, Layout, InviteModule, CreateModule) {});

import Module from 'appModule';
import link from 'link';
import Layout from './layout';
import InviteModule from './invite';
import CreateModule from './create';

import './employee-card.less';

var module = Module.content({
    name: 'staff-employees',
    urlKey: 'staffEmployees',
    label:'Сотрудники',
    routeData: function() {
        return link.route('staffEmployees');
    },
    //runPromises: function() {
    //    var tasks = this.tasks = new models.Collection();
    //    return [tasks.fetch()];
    //},
    onRun: function() {
        //var module = this;
        //this.showButtonsLayout();
        this.createAndShow(Layout);
        //if (identity.isLogged()) {

        //    var links = [
        //        link('сотрудники и отделы', 'staff', { attrs: { class: 'go-up' } }),
        //        //link('список сотрудников', 'staffEmployees'),
        //        //link('список отделов', 'staffDepartments'),
        //        //link('завтра', 'tasksTomorrow'),
        //        //link('на этой неделе', 'tasksWeek'),
        //        //link('просроченные задачи', 'tasksPast'),
        //    ];
        //    this.createAndShow(Layout, 'Список сотрудников', { }, { navLinks: links });

        //} else {
        //    console.log('not logged');
        //};
    }
});


module.addSubModule(InviteModule);
module.addSubModule(CreateModule);

export default module;
