// define('ui-controls-editPriceMeter', [
// ], function(
// ){
// });
import { MneView } from 'core/mne';


const Price = function({ value, ndsInside, perSquare } = {}, square = 0, rent = false) {
	this.value = value || 0;
	this.ndsInside = ndsInside == true;
	this.perSquare = perSquare == true;
	this.square = isNaN(square) ? 0 : square;
	this.rent = rent;
	//this.square = square || 0;
}
Object.assign(Price.prototype, {
	getBaseMeter() {
		let value = this.value;
		if (this.perSquare && this.square > 0) {
			value = value / this.square;
			if (this.rent) {
				value = value * 12;
			}
		}
		if (this.ndsInside) {
			value = value / 1.2;
		}
		return value;
	},
	displayBaseMeter() {
		let value = this.getBaseMeter();
		let text = _.displayNum(value, 2) + ' руб. за кв. м';
		if (this.rent)  {
			text += " в год";
		}
		return text;
	},
	toJSON() {
		return _.pick(this, 'value', 'ndsInside', 'perSquare');
	}
});


const Layout = MneView.extend({
	template: _.template(`
		<div class="padding-v">
		<input name="price" class="form-control" placeholder="стоимость..." value="<%= value %>" type="number"/>
		<%if (hasNds) {%><label><input type="checkbox" name="ndsInside" <%=ndsChecked%>> ндс внутри</label><% } %>
		<%if (hasSquare) {%>
			<label><input type="radio" name="perSquare" value="false" <%=perMeterChecked%>> кв. метра<%= rentMeterPostfix %></label>
			<label><input type="radio" name="perSquare" value="true" <%=perSquareChecked%>> всей площади<%= rentPostfix %></label>
		<% } else {%>
			<label>кв. метра<%= rentMeterPostfix %></label>
		<% } %>
		</div>
		<div class="padding-v">
			<label>базовый метр: <span class="base-meter-info"><%= baseMeterInfo %></span></label>
		</div>
	`),
	initialize(options) {
		console.log('EDIT PRICE: options', options);
		this.mergeOptions(options, ['getAllValues']);
		this.price = new Price(this.getOption('initialValue'), this.getSquare(), this.isRent());
		
		
		//console.log('???', this.getAllValues);
	},
	ui: {
		baseMeterInfo: '.base-meter-info',
	},
	templateContext() {
		return {
			value: this.price.value,
			ndsChecked: this.price.ndsInside ? ' checked="checked"' : '',
			perMeterChecked: this.price.perSquare ? '' : ' checked="checked"',
			perSquareChecked: this.price.perSquare ? ' checked="checked"' : '',
			hasNds: this.hasNds(),
			hasSquare: this.hasSquare(),
			rentPostfix: this.isRent() ? ' в месяц' : '',
			rentMeterPostfix: this.isRent() ? ' в год' : '',
			baseMeterInfo: this.price.displayBaseMeter()
		}
	},
	calculatePrice() {
		//debugger;
		let isRent = this.isRent();
		let square = this.getSquare() || 0;
		let hasNds = this.hasNds();
		let value = this._value.price || 0;

		if (square && this._value.perSquare) {
			value = value / square;
			if (isRent) {
				value = value * 12;
			}
		}

		if (hasNds && this._value.ndsInside) {
			value = value * 100 / 120
		}

		return value;
	},
	setValue(key, val) {
		// if (this._value == null) {
		// 	this._value = {};
		// }
		// this._value[key] = val;
		// let price = this.calculatePrice();
		this.price[key] = val;
		this.ui.baseMeterInfo.html(this.price.displayBaseMeter());
		this.triggerMethod('change', this.price.toJSON());
	},
	getOptionValue(key) {
		let all = this.getAllValues ? this.getAllValues() : this.options;
		let add = this.getOption('valuesHash') || {};
		let hash = { ...add, ...all };
		console.log('all #', hash);
		return hash[key];
	},
	isRent() {
		return this.getOptionValue('operation') == 'rent';
	},
	getSquare() {
		let val = parseFloat(this.getOptionValue('square'), 10);
		return isNaN(val) ? void 0 : val;
	},
	hasSquare() {
		return !!this.getSquare();
	},
	hasNds() {
		return (this.getOptionValue('taxType') || '').indexOf('nds') > -1;
	},
	events: {
		'input [name=price]'(e) {
			let val = parseFloat($(e.target).val(), 10);
			if (isNaN(val)) return;				
			this.setValue('value', val);
		},
		'change [name=ndsInside]'(e) {
			this.setValue('ndsInside', e.target.checked == true);
		},
		'change [name=perSquare]'(e) {
			let perSquare = $(e.target).val().toLowerCase() == 'true';
			this.setValue('perSquare', perSquare);
		}
	}
});

export default Layout;
