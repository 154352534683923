﻿//define('svc-deals-create-clientLayout', ['app-config', 'app-paths', 'svc-deals-deal-dealInList', 'svc-clients-client-clientInList', 'ui-controls-phone', 'm-deals', 'm-clients', 'm-contacts'], function (cfg, paths, DealInList, ClientInList, phoneControl, dealsNs, clientsNs, contactsNs) {});


import paths from 'app-paths';
import DealInList from '../deal/dealInList';
import ClientInList from 'svc/clients/client/clientInList';
import phoneControl from 'ui/controls/phone';
import dealsNs from 'm/deals';
import clientsNs from 'm/clients';
import contactsNs from 'm/contacts';

import template from './client.html';

import { BbeModel } from 'core/bbe';

import { MneView, MneNextCollectionView } from 'core/mne';

var EmptyView = MneView.extend({
	tagName: 'li',
	className: 'list-lbl',
	template: _.template('<span>Поиск не дал результатов</span>'),
	onRender: function () {
		//var view = this;
		//setTimeout(function () {
		//	view.triggerMethod('time:off');
		//}, 5000);
	}
});

var Founded = MneView.extend({
	className: function () { return 'block'},
	template: _.template('<header class="small <%=_bg%>"><%=header%></header><ul></ul>'),
	regions: {
		'result': { el: 'ul', replaceElement: true }
	},
	onRender: function () {
		var view = new MneNextCollectionView({
			tagName:'ul',
			collection: this.getOption("collection"),
			childView: this.getOption("childView"),
			emptyView: EmptyView,
			childViewOptions: this.getOption("childViewOptions")
		});
		this.showChildView('result', view);
	},
	templateContext: function () {
		return {
			_bg: this.getOption('addClass'),
			header: this.getOption('header')
		}
	}
});


var Layout = MneView.extend({
	//template: '#tmpl-service-deals-create-client',
	template,
	ui: {
		'searchBtn': 'button.search',
	},
	regions: {
		'phone-search': '.phone-region',
		'founded-deals': { el: '.founded-deals', replaceElement:true },
		'founded-clients': { el: '.founded-clients', replaceElement: true },
	},
	events: {
		'click @ui.searchBtn': 'searchInputed',
	},
	onRender: function () {
		
		var searched = this.model.get('searched');
		var phoneOpts;
		var validPhone = false;
		var isPhone = false;
		if (searched && (searched.type || '').indexOf('hone') > 0) {
			isPhone = true;
			var arr = searched.value.split('.');
			phoneOpts = { data: { country: arr[0], region: arr[1], number: arr[2] } };
			validPhone = contactsNs.ContactValueModel.baseValidate(searched);
		}


		var view = new phoneControl(phoneOpts);
		this.listenTo(view, 'value:changed', this.searchPhoneEntered)
		this.showChildView('phone-search', view);
		this.toggleSearchBtn();

		if (isPhone)
			this.searchPhoneEntered(searched.value);

		if (isPhone && validPhone)
			this.searchInputed();
	},
	searchPhoneEntered: function (value) {


		var arr = value.split('.');
		var searchModel = {
			type: 'phone',
			value: value,
		};

		this.model.set('searched', searchModel, { silent: true });
		this.model.trigger('storeable:change', this.model);


		this.originalPhone = value;
		this.phone = arr.map(function (chunk) {
			if (chunk === '') return '%'
			else return chunk
		}).join('.');
		this.toggleSearchBtn();
	},
	toggleSearchBtn: function () {
		var arr = (this.phone || '').split('.');
		this.ui.searchBtn.prop('disabled', !arr[2] || arr[2].length <= 3);
	},
	searchInputed: function () {
		this.tryToSearch(this.phone);
	},
	tryToSearch: async function (txt) {
		
		if (this.searchInProcess) {
			console.warn('search already in process');
			return;
		}
		this.searchInProcess = true;
		var view = this;
		var src = new BbeModel({ id: 'searchByPhone' });
		src.urlRoot = paths.api.contactValues;

		await src.loudFetch({ data: { 's.v': txt } });

		view.searchInProcess = false;
		var res = src.toJSON();
		res.phone = txt;
		view.showSearchResult(res);

		// .always(function () {
		// 	view.searchInProcess = false;
		// 	var res = src.toJSON();
		// 	res.phone = txt;
		// 	view.showSearchResult(res);
		// });

	},
	/*
	showAwaitingUis: function () {
		var p = this.model.get('client').contacts[0];
		this.ui.awaiting.show();
		var arr = (this.originalPhone || '').split('.');
		p.values[0].value = this.originalPhone;
		var view = new phoneControl({ data: { country: arr[0], region: arr[1], number: arr[2] } });
		var view2 = new phoneControl();
		this.showChildView('phone1', view);
		this.showChildView('phone2', view2);
		var view = this;
		this.listenTo(view, 'value:changed', function (value) {
			p.values[0].value = value;
			view.changeHappens();
		});
		this.listenTo(view2, 'value:changed', function (value) {
			p.values[1].value = value;
			view.changeHappens();
		});
	},
	*/
	changeHappens: function () {
		this.model.trigger('recalc:name', this.model);
	},
	showSearchResult: function (hash) {
		hash || (hash = {});
		var deals = hash.deals || [];
		var clients = hash.clients || [];
		var cdeals = new dealsNs.DealsCollection(deals);
		var cclients = new clientsNs.ClientsCollection(clients);
		var view = this;

		var dealsView = new Founded({
			header: 'Незакрытые сделки',
			addClass: 'bg-danger',
			collection: cdeals,
			childView: DealInList,
		});
		var clientsView = new Founded({
			header: 'Клиенты',
			className: 'block fastaction fastaction-arrow-right',
			addClass: 'bg-warning',
			collection: cclients,
			childView: ClientInList,
			childViewOptions: function() {
				//var parent = parentView;
				return {
					fastaction: true,
					fastactionEvent: 'exist:client:select',
					fastactionContext: view,
				};
			}
		});
		
		this.showChildView('founded-deals', dealsView);
		this.showChildView('founded-clients', clientsView);	

		var result = {
			deals: cdeals,
			clients: cclients,
			phone: hash.phone,
		};
		this.triggerMethod('phonesearch:result', result);

	},
	//getFormData: function () {
	//	return this.model.get('client');
	//},
	//searchInputed: _.debounce(function () {
	//	console.log(this.ui.search.val());
	//},400),
});

export default Layout;