import { CustomsView, BbView } from "base/views";
import { HeaderView } from './header-view';
import { PromiseButtons } from './promise-buttons';
import { proxyUpPromiseEvents } from './utils';
import { CloseButtonView } from './close-button-view';
import { eventToMethod } from '_helpers/eventName';
import { HamburgerView } from "../../../coms/ui/HamburgerView";



function actionToPromise(arg, args = [], context) {

    if (typeof arg == 'function') {
        let value = arg.apply(context, args);
        arg = actionToPromise(value, args, context);
    }

    if (arg && typeof arg.then == 'function')
        return arg;

    return Promise.resolve(arg);
}

export const __ModalBoxView = CustomsView.extend({
	className: 'new-flex-modal-box',
	initialize(options) {
		this.mergeOptions(options, ['modalContents', 'shouldShowBg', 'shouldShowClose', 'shouldShowPromiseButtons']);
		this.triggerMethod('modal:initialize');
	},
	getCustomViews() {
			let views = [];

			if (this.modalContents.length == 1) {
					this.modalContents[0].$el.addClass('elastic');
					views.push(this.modalContents[0]);
			} else {
					this.modalContents.forEach(view => {
							if (view.getOption('fixedInModal')) {
									view.$el.addClass('fixed');
							}
							if (view.getOption('elasticInModal')) {
									view.$el.addClass('elastic');
							}
							views.push(view);
					});
			}

			let shouldShowClose;
			let header = this.getOption('header');
			if (header) {
					if (header instanceof BbView && !this.shouldShowClose) {
							views.unshift(header);
					} else {
							views.unshift(new HeaderView({ header, closeView: CloseButtonView }));
					}
					shouldShowClose = false;
			} else {
					shouldShowClose = this.shouldShowClose;
			}
			
			if (this.shouldShowPromiseButtons) {
					let options = Object.assign({}, this.options);
					let buttons = new PromiseButtons(options);
					this.listenTo(buttons, 'action:start', (action) => {
							//buttons.disable();
							let methodName = eventToMethod(action) + 'Action';
							
							let content = _.find(this.modalContents, v => {
									return methodName in v || methodName in v.options;
							});
							console.log('action start: ', action, methodName, content);
							let promise = actionToPromise(content && content.getOption(methodName), [content], content);

							promise.then((arg) => {
									buttons.triggerMethod('action:success', action, arg);
							}, exc => {
									buttons.triggerMethod('action:fail', action, exc);
							});

					});
					this.listenTo(buttons, 'action:success', (action) => this.trigger(action))
					views.push(buttons);
			}

			if (shouldShowClose)
					views.push(new CloseButtonView({ addClass: 'absolute' }));


			return views;
	},
	childViewTriggers: {
		...proxyUpPromiseEvents
	},
	render() {
		return CustomsView.prototype.render.apply(this, arguments);
	}
});

export const ModalBoxView = HamburgerView.extend({
	baseClassName: 'new-flex-modal-box',
	initialize(options) {
		this.mergeOptions(options, ['modalContents', 'shouldShowBg', 'shouldShowClose', 'shouldShowPromiseButtons']);
		this.triggerMethod('modal:initialize');
	},
	getChildren() {
		return this.getCustomViews();
	},
	getCustomViews() {

		let views = [];

		if (this.modalContents.length == 1) {
			this.modalContents[0].$el.addClass('elastic');
			views.push(this.modalContents[0]);
		} else {
			this.modalContents.forEach(view => {
				if (view.getOption('fixedInModal')) {
					view.$el.addClass('fixed');
				}
				if (view.getOption('elasticInModal')) {
					view.$el.addClass('elastic');
				}
				views.push(view);
			});
		}

		let shouldShowClose;
		let header = this.getOption('header');
		if (header) {
			if (header instanceof BbView && !this.shouldShowClose) {
				views.unshift(header);
			} else {
				views.unshift(new HeaderView({ header, closeView: CloseButtonView }));
			}
			shouldShowClose = false;
		} else {
			shouldShowClose = this.shouldShowClose;
		}
		
		if (this.shouldShowPromiseButtons) {
			let options = Object.assign({}, this.options);
			let buttons = new PromiseButtons(options);
			this.listenTo(buttons, 'action:start', (action) => {
					//buttons.disable();
				let methodName = eventToMethod(action) + 'Action';
				
				let content = _.find(this.modalContents, v => {
						return methodName in v || methodName in v.options;
				});
				console.log('action start: ', action, methodName, content);
				let promise = actionToPromise(content && content.getOption(methodName), [content], content);

				promise.then((arg) => {
						buttons.triggerMethod('action:success', action, arg);
				}, exc => {
						buttons.triggerMethod('action:fail', action, exc);
				});

			});
			this.listenTo(buttons, 'action:success', (action) => this.trigger(action))
			views.push(buttons);
		}

		if (shouldShowClose)
			views.push(new CloseButtonView({ addClass: 'absolute' }));


		return views;
	},
	childViewTriggers: {
		...proxyUpPromiseEvents
	},
})