import { BoxView } from './BoxView';
import { HamburgerView } from '../HamburgerView';
import { getSchema } from 'apis/schema';
import { isViewClass, isView } from 'utils/is';

export const AsyncModelBoxView = BoxView.extend({
	constructor: function() {
		BoxView.apply(this, arguments);
		this._initBoxListeners();
	},
	_initBoxListeners() {
		if (this.model) {
			this.listenTo(this.model, 'data:ready', () => this.render());
		}
	},
});

export const AsyncModelBoxWraperView = HamburgerView.extend({

	constructor: function(options) {
		HamburgerView.apply(this, arguments);

		this.initializeModel();
		this.syncModels();
		
	},
	baseClassName: 'box-holder',

	childrenViews() {
		// this.getOption('boxView')
		// const view = this.getOption('boxView') || AsyncModelBoxView;
		// return [view]
		return this.getBoxes();
	},

	getBoxes() {
		let boxes = this.getOption('boxView', true);
		if (!boxes) {
			return [AsyncModelBoxView];
		}
		if (!Array.isArray(boxes)) {
			boxes = [boxes];
		}
		return boxes.map(box => {
			if (!box) { return }
			if (isView(box) || isViewClass(box)) {
				return box;
			}
			if (typeof box === 'object') {
				if (!box.class) {
					box = {
						...box,
						class: AsyncModelBoxView
					}
				}
				return box;
			}
		});
	},

	initializeModel() {},

	async syncModels() {
		if (!this.model) { return; }
		let schema = getSchema(this.model);

		try {
			await this.model.whenFetched;
			if (schema) {
				await schema.whenBackendSchemaFetched;
			}

			this.triggerMethod('data:ready');
			this.model.trigger('data:ready');
			console.error('[READY]', this.model.attributes, this.model);
		} catch(exc) {
				console.error('-syncModels fails-', exc);
		}

	}	

});