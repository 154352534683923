//define('libs/html', [], function() {});
import { _ } from 'vendors';

const tag = (tagName, attrs) => {
	let attrsText = '';
	if (attrs) {
		_.each(attrs, (value, key) => {
			attrsText += ` ${key}="${value}"`;
		});
	}
	let res = `<${tagName}${attrsText}></${tagName}>`;
	return res;
}

const html = (...args) => {
	return args.join('');
}

const div = (cls) => {
	return tag('div', { class: cls });
}

const span = (cls) => {
	return tag('span', { class: cls });
}

export default {
	html,
	div,
	span
}
