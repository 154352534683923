
import { BbCollection } from 'vendors';
import { MnView, NextCollectionView } from 'vendors';

const Separator = MnView.extend({
    tagName: 'li',
    className: 'divider',
    attributes:{
        role:'separator'
    },
    template: false,
});

const ActionView = MnView.extend({
    tagName: 'li',
    template: _.template('<a href="<%= url %>"><% if(icon) { %><i class="<%= icon %>"></i><% } %><span><%= text %></span></a>'),
    templateContext() {
        let icon = this.getIcon();
        return {
            icon:  icon ? 'fa fa-' + icon : '',
            text: this.getText(),
            url: this.getUrl()
        }
    },
    _get(key) {
        if (this.model) {
            return this.model.get(key);
        } else {
            return this.getOption(key);
        }
    },
    getIcon() {
        return this._get('icon');
    },
    getText() {
        return this._get('text');
    },
    getUrl() {
        return this._get('url') || 'javascript:';
    },
    _getClickAction() {
        let url = this._get('url');
        if (url) return;

        let action = this._get('clickAction');

        if (typeof action === 'function')
            return action;

        let triggerEvent = this._get('triggerEvent');
        
        if (!triggerEvent) {
            triggerEvent = this._get('name');
        }

        return (event) => {
            this.triggerMethod('action:clicked', triggerEvent, this, event);
        }

    },
    events:{
        'click'(event) {

            let clickAction = this._getClickAction();
            if (!clickAction)
                return;

            clickAction(event, this);
        }
    }
});

const ActionsView = NextCollectionView.extend({
    tagName: 'ul',
    className: 'dropdown-menu',
    childView(child) {
        if (child.get('separator')) {
            return Separator;
        } else {
            return ActionView
        }
    },
    initialize() {
        this.initializeActions();
    },
    initializeActions() {
        if (this.collection) return;
        let actions = this.getOption('actions') || [];
        this.collection = new BbCollection(actions);
    },
    childViewTriggers: {
        'action:clicked':'action:clicked'
    }
});


export default MnView.extend({
    initialize() {
        this.$el.addClass('btn-group');
    },
    template: _.template(`<button type="button" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="glyphicon glyphicon-menu-hamburger"></i></button>
<ul class="dropdown-menu"></ul>`),
    regions: {
        items: '> ul',
    },
    onRender() {
        let options = { 
            collection: this.collection,
            actions: this.getOption('actions')
        };
        let view = new ActionsView(options);
        this.showChildView('items', view, { replaceElement: true });
    },
    childViewTriggers: {
        'action:clicked':'action:clicked'
    }
});