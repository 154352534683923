﻿//define('ui-controls-datetime2-monthDaysView', ['behaviors', 'ui-controls-datetime2-dayView'], function (beh, childView) {});

import childView from './day-view';
import { MneNextCollectionView } from 'core/mne';
var View = MneNextCollectionView.extend({
	className: 'datetime-month',
	childView: childView,
	//initialize: function (opts) {
	//	this.mergeOptions(opts, ['modelType', 'valueModel']);
	//},
	//childViewOptions: function () {
	//	return {
	//		modelType: this.modelType,
	//		valueModel: this.valueModel,
	//	};
	//},
	//childViewTriggers: {
	//	'click': 'day:click'
	//},
	//collectionEvents: {
	//	'day:selected': function (prop) {
	//		if (this.valueModel.get('from') && this.valueModel.get('to'))
	//			this.render();
	//	},
	//}
});

export default View;