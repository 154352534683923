module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="board-list-region">\n	<div>\n		<div class="task-actions"></div>\n		<div class="block">\n			<ul>\n				<li class="list-lbl"><small>дедлайн</small><span>'+
((__t=(_tfn(dates.deadline)))==null?'':__t)+
' ('+
((__t=(_enum(state, 'employeeTaskStates')))==null?'':__t)+
') <small>'+
((__t=(_fromNow(dates.deadline)))==null?'':__t)+
'</small></span></li>\n				<li class="list-lbl"><small>ответственный/проверяющий</small><span>'+
((__t=(_empn(responsibleId)))==null?'':__t)+
' '+
((__t=(_ift(_empn(checkerId),'', '<small>'+_empn(checkerId)+'</small>')))==null?'':__t)+
'</span></li>\n			</ul>\n		</div>\n		';
if(_m.deal){
__p+='\n		<div class="block">\n			<header class="small bg-success">процесс</header>\n			<ul class="deal-region"></ul>\n		</div>\n		';
}
__p+='\n		';
if(_m.client){
__p+='\n		<div class="block">\n			<header class="small bg-success">клиент</header>\n			<ul class="client-region"></ul>\n		</div>\n		';
}
__p+='\n		<div class="block">\n			<ul>\n				<li class="list-lbl"><small>последнее изменение</small><span>'+
((__t=(_tfn(modified)))==null?'':__t)+
' <small>'+
((__t=(_fromNow(modified)))==null?'':__t)+
'</small></span></li>\n				<li class="list-lbl"><small>дата создания</small><span>'+
((__t=(_tfn(created)))==null?'':__t)+
' <small>'+
((__t=(_fromNow(created)))==null?'':__t)+
'</small></span></li>\n				<li class="list-lbl"><small>задачу поставил</small><span>'+
((__t=(_empn(creatorId)))==null?'':__t)+
'</span></li>\n			</ul>\n		</div>\n	</div>\n</div>\n<div class="board-list-region comments"></div>\n<!--<div class="board-list-region logs"></div>-->\n\n';
}
return __p;
};
