import { EditLayout } from '../../../entity-list-common';

//import APPBUS from 'bus/app';
// import bus from './properties/bus';

//import Properties from './properties';
import tabs from './tabs';
import OwnerSchemaCollection from '../../owner-schema';
import OwnerModel from '../../owner-model';
//import NavTabsView from './nav-tabs';
//import './edit.less';

const Layout = EditLayout.extend({
    className: 'entity-edit-page realty-edit-page',

    tabs,
    //bus,

    buildModels() {
        this.model = new OwnerModel({ id: this.getOption('ownerId') });
        this.collection = new OwnerSchemaCollection();
    },

    getModelHeader() {
        return this.model.get('name');
    },

});

export default Layout;

