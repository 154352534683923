module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<span class="logs-date">'+
((__t=( _tfn(date) ))==null?'':__t)+
'</span><span class="logs-text">'+
((__t=(_enum(type,'extendedLogTypes')))==null?'':__t)+
'</span>\n<span class="logs-author">'+
((__t=( _empn(actorId) ))==null?'':__t)+
'</span>\n';
}
return __p;
};
