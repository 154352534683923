﻿//define('bus-media', ['app-config', 'bus-core'], function (cfg, core) {});

import cfg from 'app-config'; // 'app-config'
import core from './core'; // 'bus-core'

import { MnObject } from 'vendors';

core.media = core.channel('media');
var radio = core.media;
var Media = MnObject.extend({
	channelName: core.media.channelName,
	radioRequests: {
		'url': function (id, opts, ext) {
			var root = cfg.paths.app.media;
			var res = root + 'imgs/' + id + '/' + opts + '/' + ext;
			return res;
		},
		'realty:thumbnail': function (id, opts) {
			var def = 'w88-h88-c-rbc';
			var img = opts ? getOptions(opts) : def;
			return radio.request('url', id, img, 'jpg');
		}
	},
});

function getOptions(opts) {
	if (_.isString(opts))
		return opts;
	else if (_.isObject(opts)) {

		if (!('c' in opts))
			opts.c = undefined;
		if (!('rbc' in opts))
			opts.rbc = undefined;

		//var res = [];
		var res = _(opts).each(function (value, key) {
			return key + (value ? value : '');
		});
		return res.join('-');
	}
}

//var media = new Media();
core.mediaMediator = new Media();
export default core.media;