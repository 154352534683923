import { isModel } from 'utils/is';

const schemaSymb = Symbol('schema');

export const schemaStore = new Map();

export function find(arg) {

	if (arg == null) { return; }

	const type = typeof arg;

	const Ctor = type === 'function' ? arg : undefined;

	if (Ctor) {
		let schema = schemaStore.get(Ctor.schemaName);
		if (!schema) {			
			schema = schemaStore.get(Ctor);
		}
		return schema;
	}

	const schemaName = type === 'string' ? arg : undefined
	if (schemaName) {
		return schemaStore.get(schemaName);
	}
	
	const entity = type === 'object' ? arg : undefined;
	if (entity) {
		let schema = entity.schema || entity[schemaSymb];
		if (!schema) {
			schema = find(entity.constructor);
		}
		return schema;
	}

}

export function define(arg, schema) {

	const Ctor = typeof arg === 'function' ? arg : undefined;
	const schemaName = typeof arg === 'string' ? arg : undefined;

	if (Ctor || schemaName) {
		const key = Ctor?.schemaName || Ctor || schemaName;
		schemaStore.set(key, schema);
		return schema;
	}

	if (isModel(arg)) {
		arg.schema = schema;
		return schema;
	} 

	if (typeof arg === 'object') {
		arg[schemaSymb] = schema;
	}
	
	return schema;
}