import { _, MnObject } from 'vendors';

import Bus from 'bus'; // 'bus'

import identity from '_libs/identity'; // '_libs/identity'

import link from 'link'; // 'link'

import './module.less';

import { isValue } from 'utils/is';

function initDefaults() {
	this.runPromise = $.Deferred();
	this._runPromises = [];
	this.runArguments = [];
	this.submodules = [];
	this._awaitingSubmodules = [];
}

function initRunPromises() {
	if (this._runPromisesPrepared) return this._runPromises;

	var op = undefined;
	var arr = [];
	var initialPromises = this._runPromises || [];

	if (typeof this.runPromises == 'function') {
		op = this.runPromises();
	} else if (typeof this.runPromises == 'object' && !(this.runPromises instanceof Array)) {
		op = _.extend({}, this.runPromises);
	} else if (this.runPromises instanceof Array) {
		arr = [].slice.call(this.runPromises);
	}

	if (op) {
		arr = _(op).map(function (val) { return val; });
	}

	this._runPromises = initialPromises.concat(arr);
	this._runPromisesPrepared = true;
	return this._runPromises;
}

function extractRouteArguments(route, thispath) {
	let pattern = buildRoutePattern(route);
	let params = (pattern.exec(thispath) || []).slice(1);

	params.pop();

	let matches = route.match(/[:|*]\w+/g) || [];
	let args = matches.reduce((memo, paramName, index) => {
		paramName = paramName.substring(1);
		if (index > params.length) return memo;
	
		addValue(memo, paramName, params[index]);
	
		return memo;
	}, {});

	return args;
}

function addValue(entity, key, value) {
	if (Array.isArray(entity[key])) {
		entity[key].push(value);
	} else if (key in entity) {
		entity[key] = [entity[key], value];
	} else {
		entity[key] = value;
	}
}

var routeRegexPatterns = {
	optionalParam: /\((.*?)\)/g,
	namedParam: /(\(\?)?:\w+/g,
	splatParam: /\*\w+/g,
	escapeRegExp: /[-{}[\]+?.,\\^$|#\s]/g ///[\-{}\[\]+?.,\\\^$|#\s]/g,
}

function buildRoutePattern(path) {
	var o = routeRegexPatterns;
	var route = path
		.replace(o.escapeRegExp, '\\$&')
		.replace(o.optionalParam, '(?:$1)?')
		.replace(o.namedParam, () => {
			return '([^/#?]+)';
			/*
			the backbone version is:
			return optional ? match : '([^/#?]+)';
			not sure, but it seems that this is for optional static segment after trailing slash
			foo/bar/(baz)
			in my case there is no such thing, so, i simplified it.
			also, add `#` to the returned pattern.
			*/
		})
		.replace(o.splatParam, '([^?]*?)');

	let trailingSlash = '\\/*';
	let patternString = `^\\/?${route}(?:${trailingSlash}[?#]([\\s\\S]*))?$`;
	return new RegExp(patternString);
}

export const AppModule = MnObject.extend({
	radio: Bus.modules,
	initialize: function () {
		initDefaults.call(this);
		var moduleId = this.getOption('moduleId');
		if (moduleId) {
			this.addRunPromise(identity.getPromise());
		}
		this.name = this.getOption('name');
		this.moduleId = moduleId;
	},
	onRegistered: function () {
		this.registerSubModules();
	},
	getSubLinks: function () {
		//console.log('# sublinks: ^^ ', this.submodules);
		var modules = this.submodules || [];
		let res = modules.map(module => {
			if (!identity.rights.isModuleAvailable(module)) {
				return;
			}
			return module.getModuleLink();
		}).onlyValues();
		return res;
		// return _(modules).map(function (m) {
		// 	return m.getModuleLink();
		// }).onlyValues();
	},
	getModuleLink: function () {
		
		if (this.link) {
			let link = this.link;

			if (typeof link == 'function') {
				link = link.call(this, this);
			}
			return link;
			//return _.result(this, 'link');
		}
		else if (this.urlKey) {
			var key = _.result(this, 'urlKey');
			var lbl = _.result(this, 'label');

			return link(key, lbl);
		}
	},
	navLinks: function () {
		var links = this.getSubLinks() || [];
		return links;
	},
	getAllModules: function () {
		var modules = this.submodules || [];
		var result = _.uniq(_.flatten([this, _(modules).map(function (a) { return a.getAllModules(); })]));
		return result;
	},
	registerSubModules: function () {
		//console.log('\m/')
		var module = this;
		_(this._awaitingSubmodules).each(function (sm) {
			module._registerModule(sm);
		});
	},
	_registerModule: function (module) {
		if (!isValue(module)) {
			console.warn('Register submodule fail: submodule for ' + this.name + ' undefined');
			return;
		}
		var result = true; // identity.rights.isModuleAvailable(module);
		//console.log(this.name, 'REG MOD', result, module.name, module.routeData);
		if (result) {
			module.parentModule = this;
			this.submodules.push(module);
			module.triggerMethod('registered');
		}

	},
	registerModule(module) {
		this.addSubModule(module);
	},
	addSubModule: function (module) {
		this._awaitingSubmodules.push(module);
	},
	addRunArgument: function (arg, ind) {
		if (ind >= 0)
			this.runArguments[ind] = arg;
		else
			this.runArguments.push(arg);
	},
	setRunArguments: function (args) {
		args = [].slice.call(args);
		this.runArguments = args;
	},
	addRunPromise: function (promise) {
		this._runPromises.push(promise);
	},
	getRunPromises: function () {

		initRunPromises.call(this);

		var promises = _(this._runPromises).map(function (promise) {
			return typeof promise === 'function' ? promise() : promise;
		});

		var dependsPromises = _(this.dependsOn).map(function (module) { return module._run(); });

		promises = promises.concat(dependsPromises);

		if (this.parentModule)
			promises.push(this.parentModule._run());

		return $.when.apply(this, promises);
	},
	onBeforeRun: function () {
		this.setRunArguments(arguments);
	},
	_run: function () {
		
		var module = this;
		var runpromises = module.getRunPromises();
		runpromises.then(function () {
			module.runPromise.resolve();
		}, function () {
			module.runPromise.reject();
		});

		return module.runPromise;

	},
	____run: function () {

		//console.log('run: ', this.name, arguments);

		this.runArguments = [];
		var args = [].slice.call(arguments);
		var brunArgs = [].slice.call(args);

		var mRadio = this.radio;

		brunArgs.unshift('before:run');
		this.triggerMethod.apply(this, brunArgs);
		if (mRadio)
			mRadio.trigger('before:module:run', this);

		var module = this;
		var runArgs = this.runArguments;
		//var thismodule = this;

		var promise = this._run();

		//console.log('run: run args', JSON.stringify(runArgs));
		promise.then(function () {
			var args = ['run'].concat(runArgs);
			module.triggerMethod.apply(module, args);
			if (mRadio)
				mRadio.trigger('module:run', module);
		}, function () {
			console.warn(this.name, ' run promise rejected');
		});

		return promise;
	},
	_workoutQueryParams: function() {
		
		var route = _.result(this, 'routeData');

		if (route == null || Array.isArray(route)) return;

		this.queryParams = extractRouteArguments(route, document.location.pathname.substring(1)) || {};
		

	},
	_workoutQueryString: function () {
		var query = (document.location.search || '?').split('?')[1];
		var pairs = query.split('&');
		var hash = {};
		_(pairs).each(function (pair) {
			var kv = (pair || '').split('=');
			if (kv.length < 2 || !kv[0]) return;
			var key = kv[0];
			var val = kv[1];
			if (!(key in hash)) {
				hash[key] = val;
			} else {
				if (hash[key] instanceof Array)
					hash[key].push(val);
				else
					hash[key] = [hash[key], val];
			}
		});
		this.query = hash;
	},
	isRunPrevented() {

	},
	run: function () {
		const hasRights = identity.rights.isModuleAvailable(this);
		console.log('--- RUN ---');
		if (!hasRights) {
			console.error('-not-authorized-');
			this.triggerMethod('run:prevented', 'show-not-authorized');
			return;			
		}
		console.warn('module:run', this.name, this.cid);
		if (this.isRunPrevented())  {
			this.triggerMethod('run:prevented', 'show-login');
			return;
		}

		Bus.app.trigger('module:starting', this);

		this._workoutQueryString();
		this._workoutQueryParams();

		this.runArguments = [];
		var args = [].slice.call(arguments);
		var brunArgs = [].slice.call(args);

		var mRadio = this.radio;

		brunArgs.unshift('before:run');
		this.triggerMethod.apply(this, brunArgs);
		if (mRadio)
			mRadio.trigger('before:module:run', this);

		var module = this;
		var runArgs = this.runArguments;
		//var thismodule = this;


		this.runPromise.then(() => {
			var args = ['run'].concat(runArgs);
			module.triggerMethod.apply(module, args);
			if (mRadio)
				mRadio.trigger('module:run', module);

			if (this.pageName)
				Bus.app.reply('current:page', { name: this.pageName, id: this.pageId });

			Bus.modals.request('clear:modals');

		}, () => {
			console.warn(this.name, 'run promise rejected');
			var thisargs = [].slice.call(arguments);
			thisargs.unshift(module);
			thisargs.unshift('module:run:fail');
			if (mRadio)
				mRadio.trigger.apply(mRadio, thisargs);
		});

		return this._run();
	},
	stop: function () {
		Bus.app.reply('current:page', null);
		this.runPromise = $.Deferred();
		//console.log('STOP MACHINE', this.name);
		this._runPromises = [];
		this.runArguments = [];
		this.triggerMethod('stop', this);
	},
});

