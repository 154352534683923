// define('svc/actualization/realties-list/realty/edit/properties/boolean-control', [
//     'svc/actualization/realties-list/realty/edit/properties/controls'
// ], function(
//     controls
// ) {});

import controls from './controls'
import { MneView } from 'core/mne';
const Layout = MneView.extend({
    className: 'boolean-control',
    template: _.template(`
        <label><input type="radio" name="<%= _cid %>" value="true" <%= _trueChecked %> />Да</label>
        <label><input type="radio" name="<%= _cid %>" value="false" <%= _falseChecked %> />Нет</label>
    `),
    templateContextExtend() {
        return {
            _trueChecked: this._value === true ? " checked" : "",
            _falseChecked: this._value === false ? " checked" : "",
        }
    },
    ...controls.ControlValueMixin,
    ...controls.ControlChangeValueMixin,
    ...controls.InputValueMixin,
    events: {
        ...controls.InputValueEventsMixin('input', 'change'),
    }        
});

export default Layout;
