﻿//define('svc-staff-deps-departments', ['bus-svc','svc-staff-deps-departmentTreeItem'], function (appRadio, Department) {});

import appRadio from 'bus/svc';
import Department from './departmentTreeItem';

import { CollectionView } from 'vendors';

import { departments } from 'm/departments';

var View = CollectionView.extend({
	initialize() {
		console.error('-started deps-');
		this.collection = departments;
	},
	className: 'tree departments',
	childView: Department,
	viewFilter: function (v) {
		return !v.model.get('parentId');
	}
});

export default View;