import { formatNum } from 'utils/number';
import { moment } from 'vendors';

var templateFuncs = {		
	_formatNum: function (value, options) {
		return formatNum(value, options);
        //fnRadio.request('formatNum'/*DEPRECATED*/, value, options);
	},
	_format: function (value, options) {
		if (value == null) return options.empty || '';
		if (options.format == 'number')
			return this._formatNum(value, options);
		else
			return value;
	},
	_tfnf: function (date, opts) {
		opts || (opts = { empty: '&ndash;' });
		if (!date)
			return opts.empty;
		else
			return this._tfn(date, opts) + ' <small class="time-from-now">(' + this._fromNow(date) + ')</small>';
	},
	_fullDate: function (date) {
		var mdate = moment(date);
		return mdate.format('LT l');
	},
	_fromNow: function (date) {
		if (date == null) return '';
		var mdate = moment(date);
		if (mdate.isValid())
			return mdate.fromNow();
		else
			return '';
	},
	_date: function (date) {
		var mdate = moment(date);
		return mdate.format('l');
	},
	_time: function (date) {
		var mdate = moment(date);
		return mdate.format('LT');
	},
	_tfn: function (date, opts) {
		var info = Date.info(date);
		if (info.dateInvalid) return '';
		var mdate = moment(info.date);
		if (info.calendar.days == 0)
			return mdate.format('LT');
		else if (info.calendar.months == 0 && info.calendar.days < 7)
			return mdate.format('Do') + ', в ' + mdate.format('LT');
		else if (info.calendar.years == 0)
			return mdate.format('Do MMM');
		else
			return mdate.format('l');
	},
	_dfn: function (date, opts) {
		if (opts == null) {
			opts = {
				fromNow: true,
			};
		}
		else if (opts === true || opts === false) {
			var fn = opts;
			opts = {
				fromNow: fn
			}
		}

		if (date == null) return '';

		var mdate = moment(date);
		var dif = Math.abs(mdate.diff(moment(), 'day'));

		if ((dif > 7 && opts.fromNow) || (dif <= 7 && !opts.fromNow))
			return mdate.format('L LT');
		else if ((dif > 7 && !opts.fromNow) || (dif <= 7 && opts.fromNow))
			return mdate.fromNow();

		return mdate.format('L LT');
	},
	_ift: function (condition, iffalse, iftrue) {
		iftrue || (iftrue = condition);
		return condition ? iftrue : iffalse;
	},
	_ifnt: function (condition, iftrue, iffalse) {
		iffalse || (iffalse = condition);
		return condition ? iftrue : iffalse;
	},
	_ndash: function (val) {
		return this._ift(val, '&mdash;');
	},
	_f2h: function (name) {
		return ' name="' + this._cided(name) + '"';
	},
	_cided: function (name) {
		return this._cid + ":" + (name || '');
	}
}



// var radio = Bbe[.]Radio.channel('assets');
// var radio = assetsRadio.channel('assets');
// radio.reply('templateFuncs', templateFuncs);

export {
    templateFuncs
}