import { Model } from 'core';

const boundsKeys = {'north':1, 'east':1, 'south':1, 'west':1};

export const MapFilters = Model.extend({
	constructor: function(data, options = {}) {

		const { 
			getFiltersHash, 
			method,
			setup
		} = options;

		this.method = method;
		this.getNewFiltersHash = getFiltersHash;

		Model.apply(this, arguments);

		if (setup) {
			setup(this);
		}
		this.on('change', () => {
			const attrs = { ...this.attributes };
			console.log('-map-filter-', attrs);
		});
	},

	updateFilters() {
		const hash = this.getNewFiltersHash() || {};
		console.warn('new hash', hash);
		Object.keys(this.attributes).forEach(existKey => {
			const isBoundKey = existKey in boundsKeys;
			if (isBoundKey || existKey in hash) { return; }
			hash[existKey] = undefined;
		});
		let changes = this._update(hash, { event: 'new:filters' });
		return changes
	},

	updateBounds(bounds) {
		let changes = this._update(bounds, { event: 'new:bounds' });
		return changes;
	},


	_update(hash, { event, silentChange, silentPropertyChange }) {
		let hasChanges = false;
		const changes = {};
		Object.keys(hash).forEach(key => {
			const value = hash[key];
			const prev = this.get(key);
			if (value !== prev) {
				hasChanges = true;
				changes[key] = value;
				this.set(key, value, { silent: true });
				if (value === undefined) {
					delete this.attributes[key];
				}
				if (!silentPropertyChange) {
					this.trigger('change:' + key, this, value);
				}
			}
		});
		if (hasChanges) {
			if (event) {
				this.trigger(event, changes);
			}
			if (!silentChange) {
				this.trigger('change', this, changes);
			}
		}
		return hasChanges ? changes : undefined;
	},

});