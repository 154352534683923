import { DropdownActions } from "coms/ui/DropdownActions";
import { HamburgerView } from "coms/ui/HamburgerView";
import { getValueByPath } from "utils";
import { EntityLabelView } from "./EntityLabelView";
import { Separator } from "../objects/common";
import { NextIconButtonView } from 'coms/ui/Button';
import { View } from 'core';
import EditValue from 'ui/controls/editValue';
import { invokeValue } from "utils";

function editDaysToStale(model) {
	let options = {
		header: 'Изменение количества дней до устаревания данных',
		valueType: 'number',
		initialValue: model.getByPath('actualize.daysToStale')
	}				
	EditValue.modal.single(options).then((type, text) => {
		if (type != 'ok') return;
		//const url = invokeValue(model.url, model, model);
		//console.log('>>>>', url);
		model.send({ attrs: { 'actualize.daysToStale': text }, method: 'patch' }).then(data => {
			model.setByPath(data);
		});
	}, () => {});		
}

const DaysToStale = View.extend({
	baseClassName: 'entity-label-view days-to-stale flex-row',
	template: `<div class="icon"><i class="fa fa-clock-o"></i></div>
	<div class="ui2-atom"><i>кол. дней до устаревания</i><b></b><span><%= value %></span></div>`,
	templateContext() {
		return {
			value: this.model.getByPath('actualize.daysToStale') || '&mdash;'
		}
	},
	events: {
		click() {
			editDaysToStale(this.model);
		}
	}
});

export const MainInfoLine = HamburgerView.extend({
	baseClassName: 'flex-row main box-row',

	childrenViews: [
		{
			class: DropdownActions,
			thisClassName: 'actions',
			actions: v => v.model.getInfoActions(),
		},
		v => ({
			class: EntityLabelView,
			type: 'owner',
			entityId: v.model.get('ownerId')
		}),
		v => ({
			class: EntityLabelView,
			type: 'realty',
			entityId: v.model.get('realtyId')
		}),
		DaysToStale,
		Separator,
		{
			class: NextIconButtonView,
			thisClassName: 'toggle-button',
			action(){
				console.log('chpock', this.model.toggleInfo);
				this.model.toggleInfo();
			},
			icon:'icon'
		}
	],
	childViewTriggers: {
		'toggle:info':'toggle:info'
	},
})