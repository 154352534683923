﻿//define('svc-staff-emps-layout', ['bus-svc','link', 'behaviors-subdivision','svc-staff-employees-listView', '_libs/identity', 'ui-layouts-tabs'], function(radio, link, SubdivisionBehavior, ListView, identity, Tabs) {});



import radio from 'bus/svc';
import link from 'link';
import SubdivisionBehavior from 'behaviors/subdivision';
import ListView from './listView';
import Tabs from 'ui/layouts/tabs';

import { bbeViewComparator } from 'utils';

import { MneView } from 'core/mne';

import template from './layout.html';
import { employees } from 'm/employees/collection';
// var TabView = MneView.extend({
// 	className: 'tab-item',
// 	template: _.template('<%- id %>'),
// 	triggers: { 'click': 'tab:click' },
// 	onBeforeRender: function () {
// 		if (this.model.get('active'))
// 			this.$el.addClass('active');
// 		else
// 			this.$el.removeClass('active');
// 	},
// 	modelEvents: {
// 		'change':'render'
// 	},
// });

var Layout = MneView.extend({
	className: 'tabs-container flex-in-root-section',
	//template: '#tmpl-service-staff-emps-layout',
	template,
	behaviors: [SubdivisionBehavior],
	regions: {
		'employees': '.employees-list-region',
		'tabs': '.tab-switcher',
		'footer': '> footer'
	},
	getCurrentTabName: function () {
		var tab = this.tabs.getActive();
		return tab ? tab.get('name') : undefined;
	},
	getViewFilter: function (tab) {
		tab || (tab = this.getCurrentTabName())
		return this.tabsConfig[tab];
	},
	onRender: function () {
		this.showTabSwitcher();
		this.showEmployees();
	},
	tabsConfig: {
		'active': function (v) {
			return v.model.isActive();
			//v.model.get('state') != 'fired' && !!v.model.get('userId')
		},
		'virtual': function (v) {
			return v.model.isVirtalActive();
			//v.model.get('state') != 'fired' && !v.model.get('userId')
		},
		'fired': function (v) {
			return v.model.isFired();
			//v.model.get('state') == 'fired'
		},
		'all': undefined
	},
	showTabSwitcher: function () {
		var tabs = {
			active: 'действующие',
			virtual: 'виртуальные',
			fired: 'уволенные',
			all: 'все'
		}
		var view = this.tabs = new Tabs({
			tabs: tabs,
			active: 'active',
		});
		this.showChildView('tabs', view);


	},
	showEmployees: function () {

		var collection = employees; //radio.request('employees')
		var viewFilter = this.getViewFilter();

		var viewComparator = function (v1, v2) {
			return bbeViewComparator(v1, v2, function (m, v) { return m.getName(); })
		};

		var view = this.employees = new ListView({
			collection,
			viewFilter,
			viewComparator
		});

		this.showChildView('employees', view);
		//console.error('emps view', view, viewFilter);
	},
	onChildviewTabOn: function (tab) {
		this.employees.viewFilter = this.getViewFilter(tab);
		this.employees.sort();
	},
	templateContext: function () {
		return {
			empsUrl: link.url('staffEmployees'),
			depsUrl: link.url('staffDepartments'),
		}
	},
});

export default Layout;