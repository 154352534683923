// define('svc/actualization/ownerInRealty/filters', [
// 	'references'
// ], 
// function(
// 	refs
// ){});


import refs from 'references';
import { getValueByPath } from 'utils';

import { BbeModel } from 'core/bbe';

const Filters = BbeModel.extend({
	check(object, offer)
	{
		let cval = { object, offer };
		let config = this.config();
		return _.every(this.attributes, (av, key) => {
			
			if (av == null) return true;
			if (key.startsWith("offer.") && !offer) return false;
			if (key.startsWith("object.") && !object) return false;


			let propCfg = config[key];
			if (propCfg && propCfg.compare) {
				return propCfg.compare(av, cval);
			} else {
				return getValueByPath(cval, key) == av;
			}
		});
	},
	config() {
		if (!this._config) {
			let cfg = [
				{
					id: 'object.offers',
					label: 'помещения',
					enum: {
						with: 'С предложениями',
						without: 'Без предложений',
						none: 'Все'
					},
					compare: (value, hash) => {
						if (value == 'none') return true;
						else if (value == 'with') return hash.object.offers && !!hash.object.offers.length
						else return !hash.object.offers || (hash.object.offers && !hash.object.offers.length)
					}
				},
				{
					id: 'offer.status',
					enum: 'realtyOfferStatuses',
					label: 'статус',
					sourceValues: refs.enum('realtyOfferStatuses'),
					addNone: true,
					none: {
						id: 'none',
						label: 'все',
						value: null,
					}
				},
				{
					id: 'offer.operation',
					enum: 'realtyOperations',
					label: 'операция',
					sourceValues: refs.enum('realtyOperations'),
					none: {
						label: 'все',
						value: null,
					}
				},
				{
					id: 'offer.market',
					enum: 'realtyMarkets',
					label: 'рынок',
					sourceValues: refs.enum('realtyMarkets'),
					none: {
						label: 'все',
						value: null,
					}
				}
			]
			this._config = _.reduce(cfg, (memo, value, k) => {
				memo[value.id] = value;
				return memo;
			}, {});
		}
		return this._config;
	}
});

const filters = new Filters();

export default filters;
