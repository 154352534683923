﻿//define('ui-controls-datetime2-monthModel', [], function () {});
import { BbeModel } from 'core/bbe';

var Model = BbeModel.extend({
	constructor: function () {
		this.on('change', this.onChange);
		BbeModel.apply(this, arguments);
	},
	onChange: function () {
		var n = new Date();
		this.now = new Date(n.getFullYear(), n.getMonth(), n.getDate());
		this.date = new Date(this.get('year'), this.get('month'), 1);
		this.trigger('ready', this);
	},
	year: function () {
		return this.date.getFullYear();
	},
	month: function () {
		return this.date.getMonth();
	},
	daysCount: function () {
		var d = new Date(this.date.valueOf());
		d.setMonth(d.getMonth() + 1);
		d.setDate(0);
		return d.getDate();
	},
	createDayDate: function (day) {
		return new Date(this.get('year'), this.get('month'), day);
	},
	_update: function () {
		this.set({
			year: this.date.getFullYear(),
			month: this.date.getMonth(),
		});
	},
	addMonth: function (num) {
		this.date.setMonth(this.date.getMonth() + num);
		this._update();
	},
	setMonth: function (num) {
		this.date.setMonth(num);
		this._update();
	},
	setYear: function (num) {
		this.date.setYear(num);
		this._update();
	}
});

export default Model;