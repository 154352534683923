﻿//define('ui-models-filters-filter', ['ui-models-values-collection', 'ui-models-values-single', 'ui-models-values-editContext'], function(ValuesCollection, Single, EditContext) {});

import ValuesCollection from 'ui/models/values/collection';
import { isNotValue } from 'utils';
// import Single from 'ui/models/values/single';
// import EditContext from 'ui/models/values/editContext';

import { Model as BbeModel, Collection as BbeCollection } from 'core/bbe';

var Model2 = BbeModel.extend({
	defaults: {
		modelType: 'single',
		valueType: 'string',
		multiple: false,
		values: undefined,
	},
	ensureIsValid: function () { },
	initializeGroup: function (groups) {
		if (!groups) return;

		var groupId = this.get('group');
		var key = this.get('id');
		if (!groupId) return;

		var group = groups.get(groupId);
		if (!group) return;

		group.filters = group.filters || {};
		group.filters[key] = this;
		this.group = group;
	},
	initializeSourceValues: function () {
		var src = this.get('sourceValues');
		if (!src) return;
		var values = (typeof src === 'function') ? src() : src;
		var modelType = this.get('modelType');
		var collection;


		if (
			(modelType === 'range' && (values instanceof ValuesCollection.Range))
			||
			(modelType === 'single' && (values instanceof ValuesCollection.Single))
		)
			collection = values;
		else if (values instanceof ValuesCollection.Rest) {
			collection = values;
		}
		else if (values instanceof Array && modelType == 'range') {
			collection = new ValuesCollection.Range(values, { parse: true });
		}
		else if (values instanceof Array && modelType == 'single') {
			collection = new ValuesCollection.Single(values, { parse: true });
		}
		else if (values instanceof BbeCollection && modelType == 'single') {
			var data = [];
			values.each(function (m) {
				var nv = { value: _.val(m), label: _.lbl(m) };
				if (isNotValue(nv.value)) return;
				data.push(_.extend(m.toJSON(), nv));
			});
			collection = new ValuesCollection.Single(data, { parse: true });
			if (values.comparator)
				collection.comparator = values.comparator;
		}
		else if (!(values instanceof Array) && typeof values === 'object' && modelType == 'single') {
			collection = ValuesCollection.Single.fromHash(values);
		}
		else {
			throw Error('Filter: initialize source values - unhandled values case, id:' + this.get('id'));
		}

		this.set('sourceValues', collection);


	},
	initializeValues: function () {

		var modelType = this.get('modelType');
		var multiple = this.get('multiple');
		var valueType = this.get('valueType');

		var values = this.get('values');
		if (isNotValue(values)) values = [];
		else if (!(values instanceof Array)) values = [values];
		else if (!multiple && values instanceof Array) values = [values[0]];

		if (values.length > 0 && this.hasSourceValues()) {
			var find = [];
			var srcValues = this.srcValues();

			_(values).each(function (val) {

				if (modelType == 'single') {
					let f = srcValues.findWhere({ value: val });

					if (f) find.push(f.toJSON());
				} else if (modelType == 'range') {
					let f = srcValues.findWhere({ from: val.from, to: val.to });
					if (f) find.push(f.toJSON());
				}
			});

			values = find;
		}

		if (valueType === 'object') {
			values = _.map(values, function (m) {
				return { value: m };
			});
		}

		var collection = (modelType == 'single') ? ValuesCollection.Single.fromArray(values, { valueType: valueType }) : new ValuesCollection.Range(values, { valueType: valueType });
		this.set('values', collection);
	},
	initializeDependencies: function () {
		var dependency = this.get('dependency');
		if (!dependency) return;
		var filter = this;
		if (dependency.resetOnFilters instanceof Array) {
			var ids = dependency.resetOnFilters;
			this.listenTo(this.collection, 'values:change', function (id) {
				if (ids.indexOf(id) == -1) return;
				filter.clearValues();
			});
		}

		//console.log('col is', this.collection);
	},
	initialize: function(attrs, opts) {

		this.context = opts.filtersContext;

		this.ensureIsValid();
		this.initializeGroup(opts.groups);
		this.initializeSourceValues();
		this.initializeValues();
		this.initializeDependencies();

		//console.log('filter', this.get('id'), this);

	},
	_getFiltersCollection: function() {
		if (this.context) return this.context.filters;
		return this.collection;
	},

	//****

	srcValues: function () { return this.get('sourceValues'); },
	hasSourceValues() { 
		return this.has('sourceValues');
			//this.get('sourceValues') instanceof BbeCollection; 
	},

	values: function () { return this.get('values'); },
	hasValue: function () {

		let values = this.values();
		return values.hasValue();

	},

	getLabel: function () {
		var label = this.values().getLabel();
		return label || this.get('emptyText');
	},
	getValue: function (opts) {
		opts || (opts = {});
		var multiple = this.get('multiple');
		var flattenValues = this.get('flattenValues');
		//console.log('####', this.values());
		var arr = this.values().getValue();

		if (!multiple)
			return arr[0];
		else if (multiple && ((flattenValues && !opts.asArray) || (opts.asString)))
			return arr.join(', ');
		else
			return arr;
	},


	_triggerChanges: function (viewSilent, modelSilent) {
		if (!viewSilent) this.trigger('view:refresh');
		if (modelSilent) return;
		this.trigger('values:change', this.get('id'));
		this.trigger('values:change:' + this.get('id'));
	},
	clearValues: function (opts) {
		opts || (opts = {});
		this.values().reset([]);

		this._triggerChanges(opts.viewSilent, opts.modelSilent)
	},
	resetValuesTo: function (data, opts) {
		this.applyValues(data, opts);
	},


	applyValues: function(values, opts) {

		opts || (opts = {});
		var multiple = this.get('multiple');
		var notMultiple = !multiple;
		var modelType = this.get('modelType');

		if (this.hasSourceValues() && modelType == 'single') {
			if (!(values instanceof Array)) values = [values];
			const sources = this.srcValues();
			let found = sources.filter(sourceModel => {
				const sourceModelValue = sourceModel.get('value');
				// var simple = values.indexOf(sourceModelValue) >= 0;
				// if (simple) {
				// 	return true;
				// }

				return _.some(values, function (newValue) {
					let result;
					let type = typeof newValue;
					let isObject = newValue && type === 'object';
					if (newValue === sourceModelValue) {
						return true;
					}
					if (!isObject) { return }
					if ('value' in newValue) {
						result = newValue.value === sourceModelValue
					} else if (newValue.get) {
						result = newValue.get('value') === sourceModelValue;
					}
					return result;
					//return _.isObject(val) && val.value == sourceModelValue;
				});

				//return values.indexOf(m.get('value')) >= 0;
			});
			if (!multiple && found.length > 1) found = [found[0]];
			console.log('[found]', found);
			this.values().reset(found);

			return this._triggerChanges(opts.viewSilent, opts.modelSilent);
		}
		if (this.hasSourceValues() && modelType == 'range') {
			throw Error('Filter apply value for sourced range not implemented');
		}

		// if (multiple) {
		// 	throw Error('Filter apply value for multiple are not implemented');
		// }

		var who = _.who(values);
		console.log('# who:', who, values);
		if (modelType == 'single') {

			if (notMultiple && who.array && values.length == 0) {
				values = undefined;
			} else if (notMultiple && who.array && values.length == 1) {
				values = values[0];
			} else if (notMultiple && who.array && values.length > 1){
				console.log(values);
				throw Error('applyValue fail: model single:single, values is array > 2');
			}

			//console.log('blyam!');
			if (notMultiple) {
				if (typeof values !== 'undefined') {
					var firstModel = this.values().first();
					let hash;

					if (who.bbm) {
						let json = values.toJSON();
						//let jsonWho = _.who(json);
						hash = json;
					} else if (who.single) {
						hash = values;
					} else {
						hash = { value: values }
					}

					// var hash = valWho.primitive ? { value: values, label: values }
					//     : valWho.bbm ? values.toJSON()
					//         : valWho.hash ? values
					//             : values;

					if (firstModel) {
						firstModel.set(hash);
					}
					else {
						this.values().add(hash);
					}
				}
				else {
					this.values().reset();
				}
			} else if (multiple) {
				values = values.filter(f => f != null).map(v => {
					if (_.isObject(v)) {
						throw Error("multiple single array mapping not implemented")
					} else {
						let id = _.isDate(v) ? v.valueOf() : v;
						let value = v;
						let label = _.isDate(v) ? undefined : v;
						return {id, value, label};
					}
				});
				this.values().reset(values);
				console.log('## multiple action not taken', values);
			}

			return this._triggerChanges(opts.viewSilent, opts.modelSilent);

		}
		else if (modelType == 'range') {

			if (notMultiple && who.array && values.length == 0) {
				values = undefined;
			} else if (notMultiple && who.array && values.length == 1) {
				values = values[0];
			} else if (notMultiple && who.array && values.length > 1) {
				console.log(values);
				throw Error('applyValue fail: model single:range, values is array > 2');
			}
			if (notMultiple) {
				if (typeof values !== 'undefined') {
					let model = this.values().first();
					if (model) model.set(values);
					else this.values().add(values);
				} else {
					this.values().reset();
				}
			}
			else {
				throw Error('multiple range values are not implemented');
			}
			return this._triggerChanges(opts.viewSilent, opts.modelSilent);
		}

	},


	getFilterData: function () {
		//var thisId = this.getId();
		var vals = this.get('values');


		if (this.get('flattenValues') && this.get('modelType') == 'single') {
			var pl = vals.pluck('value');
			if (!pl || pl.length == 0) return undefined;
			return [this.get('id'), [{ v: pl }]];
		} else {
			var pairs = vals.getUrlHash();
			if (!pairs) return undefined;
			return [this.get('id'), pairs];
		}

	},


});





export default Model2;
