import { _ } from 'vendors';

import Bus from 'bus';
// // import svcRadio from 'bus/svc';
import refs from 'references';
import identity from '_libs/identity';
import modals from '_libs/modals';
import store from '_libs/store';
import paths from 'app-paths';
// // import abstractNs from 'm/abstract';
import action from '_helpers/action';

import EditValue from 'ui/controls/editValue';

import AddVirtualOffer from 'svc/deals/deal/addVirtualOffer';
import clientsNs from 'm/clients';
import DealOffersCollection from './dealOffers';

import appCfg from 'app-config';

import { unFlatObject } from 'utils/object-manipulations';
import { valueIn } from 'utils/array-manipulations';

import { BbeModel, BbeCollection } from 'core/bbe';
import { selectEmployeesInModernModal } from '../../ui/controls/selectEmployees/in-modal';
import { backendApi } from 'apis/backend';

import { proxyNestedChanges } from 'm/abstract';
import link from '_libs/link';

const mixin = {
	factoryName: 'JsonDeal',

	openInModalOptions: {
		fail: {
			500: 'Этот процесс вам недоступен'
		}
	},

	isNotCompleted: function () {
		return !this.isCompleted();
	},

	isCompleted: function () {
		//return (this.get('status') || '').in(/*deprecated*/'completed', 'canceled');
		return valueIn(this.get('status') || '', 'completed', 'canceled');
	},

	isCompleting: function () {
		//return (this.get('status') || '').in(/*deprecated*/'completing', 'canceling');
		return valueIn(this.get('status') || '', 'completing', 'canceling');
	},
	isInProcess: function () {
		return this.isNotCompleted() && !this.isCompleting();
	},
	isAwaitingOffer: function () {
		return this.get('status') == 'awaitingOffer';
	},
	canView: function () {
		return this.isMy() || this.isInTeam() || this.isMyEmployee();
	},
	canChange: function () {
		return this.isNotCompleted() && (this.isInTeam() || this.isMy() || this.isMyEmployee());
	},
	isInTeam: function () {
		var team = this.get('team') || [];
		var found = _(team).findWhere({ id: identity.getEmployeeId() });
		return found && ['teamMember', 'teamLead'].indexOf(found.joinedAs) > -1;
	},
	isMy: function () {
		return identity.isMe(this.get('ownerId'));
	},
	isMyEmployee: function () {
		return identity.isMyEmployee(this.get('ownerId'));
	},
	isMyOrMyEmployee: function () {
		return this.isMy() || this.isMyEmployee();
	},
	isMyNotCompleted: function () {
		return this.isNotCompleted() && this.isMy();
	},
	amIOnlyOne: function () {
		return this.isMy() && !identity.hasLeaders();
	},

	isTodayFuture: function () {
		var i = this.getWhenInfo();
		if (i.dateInvalid)
			return false;
		var res = i.when == 'future' && i.calendar.days == 0;
		//console.log('today ->', res, this.attributes.action.when)
		return res;
	},
	isTodayPast: function () {
		var i = this.getWhenInfo();
		if (i.dateInvalid)
			return false;
		var res = i.when == 'past' && i.calendar.days == 0;
		//console.log('today ->', res, this.attributes.action.when)
		return res;
	},
	getWhenInfo: function () {
		var raw = (this.get('action') || {}).when;
		var i = Date.info(raw);
		return i;
	},

	getWhen: function () {
		var i = this.getWhenInfo();
		return i.date;
	},

	getWhenValue: function () {
		var when = this.getWhen();
		if (!when)
			return 0;
		return when.valueOf();
	},

	getOffers: function () {
		var raw = this.get('offers');
		if (raw instanceof BbeCollection) {
			console.log('getOffers: raw collection');
			return raw;
		}
		var col = DealOffersCollection.create(this, raw);


		this.set('offers', col);
		col.loudFetch();

		//console.log('1====----');
		return col;
	},

	getDealOfferName: function (id) {
		var offers = this.getOffers();
		var doff = offers.get(id);
		if (!doff) return '';
		return doff.getLabel();
	},

	getClient: function () {
		var client = this.get('client');
		if (!client || client instanceof BbeModel) return client;
		client = new clientsNs.ClientModel(client);
		this.set('client', client);
		proxyNestedChanges(this, client, 'client');
		return client;
	},

	getSquare: function () {
		var info = this.get('info') || {};
		var subj = this.get('subject') || {};
		if (info.square)
			return info.square;
		else if (subj.squareFrom || subj.squareTo) {
			return { from: subj.squareFrom, to: subj.squareTo };
		}
	},

	getSquareStr: function () {
		var sq = this.getSquare();
		return _.rangeLabel(sq, { empty: '&mdash;', postfix: ' м<sup>2</sup>' });
	},

	getIncome: function () {
		var info = this.get('info') || {};
		//var subj = this.get('subject') || {};
		if (info.income)
			return info.income;
		else if (info.price && info.incomePercent)
			return (info.price * info.incomePercent);
	},

	getIncomeStr: function () {
		var inc = this.getIncome();
		return _.rangeLabel(inc, { empty: '&mdash;', postfix: ' р.' });
	},

	fixTaskAction: function (task) {
		var nact = ((task.get('deal') || {}).action || {});
		var dact = this.get('action');
		if (dact == null) {
			dact = {};
			this.set('action', dact, { silent: true });
		}
		_(dact).each(function (pv, pn) { delete dact[pn]; });
		_(nact).each(function (pv, pn) { dact[pn] = pv; });
		this.trigger('change:action', this);
		this.trigger('change', this);
	},

	actions: function () {
		return [
			action('openModal', 'показать', {}, { order: 0 })
			, action('openSearchPage', 'подобрать предложения', {}, { order: 0, rule: function () { return this.isInProcess() && this.canChange(); } })
			, action('addVirtualOffer', 'добавить виртуальное предложение', {}, { order: 0, rule: function () { return this.isInProcess() && this.canChange(); } })
			, action('addEntityTask', 'поставить задачу', {}, { order: 0, rule: function () { return this.canChange() || (this.isMyEmployee() && this.isNotCompleted()); } })
			, action('sendToAwaitOffer', 'отправить в ожидание', {}, { order: 0, rule: function () { return this.isInProcess() && this.canChange() && !this.isAwaitingOffer(); } })
			, action('changeStage', 'изменить стадию', {}, { order: 0, rule: function () { return this.isInProcess() && this.canChange(); } })
			, action('extendTeam', 'расширить команду', {}, { order: 0, rule: function () { return this.isMyOrMyEmployee() && this.isInProcess(); } })
			, action('complete', 'завершить', {}, { order: 0, rule: function () { return this.isMyOrMyEmployee() && this.isInProcess() && this.get('stageId') === 'income'; } })
			, action('cancel', 'отказаться', {}, { order: 0, rule: function () { return this.isMyOrMyEmployee() && this.isInProcess(); } })
			, action('changeOwner', 'изменить ответственного', {}, { order: 0, rule: function () { return this.isMyOrMyEmployee() && this.isNotCompleted(); } })
			, action('returnInWork', 'вернуть в работу', {}, {
				rule: function () {
					return (
						(this.isMyEmployee() || this.isMy()) && (this.isCompleting() || this.isAwaitingOffer())
						|| (identity.isAdmin() && (this.isCompleting() || this.isAwaitingOffer() || this.isCompleted()))
					);
					//|| (!this.isCompleting() && (this.canChange() && this.isAwaitingOffer()));
				}, order: 0
			})
			, action('finalize', 'подтвердить завершение/отказ', {}, {
				rule: function () {
					console.log('/**/**/', this.isMyEmployee(), this.amIOnlyOne());
					return (this.isMyEmployee() || this.amIOnlyOne()) && this.isCompleting();
				}, order: 0
			})
			, action('joinOffersToDeal', 'прикрепить предложения к процессу', {}, { order: 0, hidden: true, rule: function () { return this.canChange() && this.isInProcess(); } })
			, action('openPage', 'перейти на страницу процесса', {}, { rule: function () { return this.canView(); }, order: 0 })
		];
	},

	urlRoot: function () {
		//if (!cfg.service) return;
		return paths.api.deals;
	},
	//actionOpenDealPage: function (options) {
	//	if (options.modal)
	//		options.modal.destroy();

	//	var url = this.cardUrl();
	//	Bus.navigate(url);
	//},
	//actionOpenInModal: function (options) {

	//	return Bus.modals.request('show:fullscreen', {
	//		View: Bus.Views.request('deal:page'),
	//		model: this
	//	});

	//},
	actionOpenSearchPage: function (options) {
		if (options.modal)
			options.modal.destroy();
		try {
			let json = this.toJSON({ improvedJson: true });
			console.log('deal json', json);
			store.set(store.KEYS.currentDeal, json);
		} catch (exc) {
			console.error('UNABLE TO STORE deal in local storage');
			console.error(exc);
		}
		var url = link.url('realtiesSearchMap:' + this.get('wId'));
		Bus.navigate(url);
	},
	actionAddVirtualOffer: function (options) {
		if (options.modal)
			options.modal.destroy();
		var deal = this;
		var offers = deal.getOffers();
		console.log('OFFERS', offers.model.prototype.getLabel);
		AddVirtualOffer.showInModal({ deal: this }).then(function (offer) {
			console.log('offer is ->', offer);
			offers.add(offer.toJSON());
			console.log('OFFERS', offers);
		});

	},
	actionAddEntityTask: function (options) {

		if (options && options.modal)
			options.modal.destroy();

		//var modal = 
		Bus.app.request('entityTask:create', {
			entity: this,
			entityType: 'deal',
			modalCssCfg: 'fullscreen',
		});

		//console.log('VIEW>>', view);
		//Bus.modals.request('modal', view, { modalCssCfg: 'fullscreen' });

		//store.set(store.KEYS.currentDeal + ":" + this.get('wId'), this.toJSON());
		//var url = link.url('tasksCreate') + "?dealId=" + this.get('wId');
		//Bus.navigate(url);

	},
	actionSendToAwaitOffer: function (options) {
		if (options.modal)
			options.modal.destroy();

		var model = this;
		model.loudPatch({ status: 'awaitingOffer' });
		//var field = 'status';
		//var popupSetup = {
		//	valueType: 'string',
		//	header: 'Установите новый статус',
		//	value: this.get(field),
		//	sourceValues: refs.enum('dealStatuses'),
		//	noComparator: true,
		//};
		//EditValue.modal.single(popupSetup)
		//	.done(function (type, newvalue) {

		//		if (type != 'ok') return;

		//		if (newvalue instanceof Array && !popupSetup.multiple)
		//			newvalue = newvalue[0];

		//		var hash = {};
		//		hash[field] = newvalue;
		//		var unflatHash = hash.unFlat();
		//		model.loudPatch(unflatHash);

		//	});


	},
	actionChangeStage: function (options) {
		if (options.modal)
			options.modal.destroy();

		var model = this;
		var popupSetup = {
			valueType: 'string',
			header: 'Установите новую стадию',
			value: this.get('stageId'),
			sourceValues: refs.enum('dealStages'),
			noComparator: true,
		};
		var field = 'stageId';
		EditValue.modal.single(popupSetup)
			.done(function (type, newvalue) {

				if (type != 'ok') return;

				if (newvalue instanceof Array && !popupSetup.multiple)
					newvalue = newvalue[0];

				var hash = {};
				hash[field] = newvalue;
				var unflatHash = unFlatObject(hash);
				model.loudPatch(unflatHash);

			});


	},
	actionExtendTeam: function (options) {
		if (options.modal)
			options.modal.destroy();
		var owner = this.get('ownerId');
		//var exist = [this.get('ownerId')];
		var model = this;

		var members = _(this.get('team') || {}).map(function (t) { return t.id });
		if (members.indexOf(owner) == -1)
			members.push(owner);

		/*
		var setup = {
			valueType: 'string',
			header: 'управление командой',
			editValueClassName: 'flexible-edit-modal',
			value: members,
			multiple: true,
			controlType: 'selectEmployees',
			sourceValues: svcRadio.request('employeesSelect'),
			viewFilter: function (v) {
				return v.model.get('value') != owner;
			}
		};

		EditValue.modal.single(setup, { modalCssCfg: 'flexible' }).done(function (type, newvalue) {
			//console.log(type, newvalue, newvalue.indexOf(owner));

			var url = model.url();
			url += '/team';
			var team = new BbeModel({ members: newvalue });
			team.url = url;

			team.loudSave().done(function () {
				model.trigger('change', model);
				model.trigger('team:changed');
			});

		});
		*/


		const setupNew = {
			header: 'управление командой',
			value: members,
			multiple: true,
			viewFilter: function (v) {
				return v.model.get('value') != owner;
			}
		}
		const callback = function (res) {
			//console.log(type, newvalue, newvalue.indexOf(owner));
			if (!res.ok) { return }
			const newvalue = res.value.value;

			var url = model.url();
			url += '/team';
			var team = new BbeModel({ members: newvalue });
			team.url = url;

			team.loudSave().then(function () {
				model.trigger('change', model);
				model.trigger('team:changed');
			});

		}
		selectEmployeesInModernModal(setupNew, callback);


		//console.log('TEA',this.get('team'));
		//SelectEmployees.inModal({
		//	header: 'Пригласить в команду',
		//	multiple: true,
		//	values: members,
		//	//viewFilter: function (v) {
		//	//	return exist.indexOf(v.model.get('value'));
		//	//},
		//}).done(function (type, newvalue) {

		//	var url = model.url();
		//	url += '/team';
		//	var team = new BbeModel({ join: newvalue });
		//	team.url = url;

		//	team.loudSave().done(function () {
		//		model.trigger('change', model);
		//		model.trigger('team:changed');
		//	});

		//});
	},
	actionChangeOwner: function (options) {
		if (options.modal)
			options.modal.destroy();
		var owner = this.get('ownerId');

		var model = this;

		var members = _(this.get('team') || {}).map(function (t) { return t.id });
		if (members.indexOf(owner) === -1)
			members.push(owner);

		/*
		var setup = {
			valueType: 'string',
			header: 'изменение ответственного',
			value: [owner],
			//multiple: true,
			controlType: 'selectEmployees',
			sourceValues: svcRadio.request('employeesSelect'),
			viewFilter: function (v) {
				return v.model.get('value') != owner && v.model.isActive();
			}
		};

		EditValue.modal.single(setup).done(function (type, newvalue) {
			if (type != 'ok' || newvalue[0] == owner) return;
			
			var change = new BbeModel({
				ownerId: newvalue[0],
				removePreviousFromTeam: false
			});
			change.url = model.url() + '/owner';
			change.loudSave().then(function () {
				model.set(change.toJSON())
			});

			//model.loudPatch({ ownerId: newvalue[0] });

			//console.log(type, newvalue, newvalue.indexOf(owner));

			//var url = model.url();
			//url += '/team';
			//var team = new BbeModel({ members: newvalue });
			//team.url = url;

			//team.loudSave().done(function () {
			//	model.trigger('change', model);
			//	model.trigger('team:changed');
			//});

		});
		*/

		const setupNew = {
			header: 'изменение ответственного',
			value: [owner],
			viewFilter: function (v) {
				return v.model.get('value') != owner && v.model.isActive();
			}
		}
		const callback = function (res, type, newvalue) {
			if (!res.ok) { return; }
			const newOwnerId = res.value.value[0];
			if (newOwnerId == owner) { return; }
			//if (type != 'ok' || newvalue[0] == owner) return;

			var change = new BbeModel({
				ownerId: newOwnerId,
				removePreviousFromTeam: false
			});
			change.url = model.url() + '/owner';
			change.loudSave().then(function () {
				model.set(change.toJSON())
			});

			//model.loudPatch({ ownerId: newvalue[0] });

			//console.log(type, newvalue, newvalue.indexOf(owner));

			//var url = model.url();
			//url += '/team';
			//var team = new BbeModel({ members: newvalue });
			//team.url = url;

			//team.loudSave().done(function () {
			//	model.trigger('change', model);
			//	model.trigger('team:changed');
			//});

		}
		selectEmployeesInModernModal(setupNew, callback);

	},

	actionJoinOffersToDeal: function (options) {
		//var deal = this;
		var data = options.data;
		if (!data || !data.length) {
			modals.notify('Нечего добавлять, сначала выберите предложения');
			return;
		}

		var hash = { ids: _(data).pluck('id') };
		var model = new BbeModel(hash);
		var offers = this.getOffers();
		model.url = this.url() + '/realtyoffers';
		model.loudSave().then(function (data) {
			offers.add(data);
			modals.notify('предложения добавлены');
		});
	},

	actionComplete: function () {
		var model = this;
		Bus.modals.request('confirm').then(function () {
			model.loudPatch({ status: 'completing' });
		});

	},

	actionCancel: function () {
		var model = this;
		Bus.modals.request('confirm').then(function () {
			model.loudPatch({ status: 'canceling' });
		});
	},

	actionFinalize: function () {
		var model = this;
		//var status = model.get('status') == 'canceling' ? 'canceled' : 'completed';
		Bus.modals.request('confirm').then(function () {
			var finalize = new BbeModel();
			finalize.url = model.url() + '/finalize';
			finalize.loudSave().then(function () {
				model.set(finalize.toJSON());
			});
		});
	},

	actionReturnInWork: function () {
		var model = this;
		//var status = model.get('status') == 'canceling' ? 'canceled' : 'completed';
		Bus.modals.request('confirm').then(function () {
			var finalize = new BbeModel();
			finalize.url = model.url() + '/return';
			finalize.loudSave().then(function () {
				model.set(finalize.toJSON());
			});
		});
	},

	updateSearchAsync(hash) {
		//debugger;
		const url = appCfg.urls.apiV(`deals/${this.id}`);
		const data = { searchConfig: hash }
		const sync = (patched) => {
			this.mergeAttributes(patched);
		};

		return backendApi.patch(data, url, sync);
		//return this.loudPatch({ searchConfig: hash });
	}

}

export default mixin;