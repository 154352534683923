﻿//define('ui-controls-selectEmployees', ['bus-svc', 'bus-controls', 'ui-controls-select', 'ui-layouts-tabs', '_libs/identity'], function (radio, busControls, Select, Tabs, identity) {});


import radio from 'bus/svc';
import busControls from 'bus/controls';
import Select from 'ui/controls/select';
import Tabs from 'ui/layouts/tabs';
import identity from '_libs/identity';

import template from './control.html';

import { bbeViewComparator } from 'utils';

import './select-employee.less';

// export * from './in-modal';

var SelectEmployees = Select.extend({
    className: 'select-control-box select-employees',
    //template: '#tmpl-ui-controls-selectEmployees-control',
    template,
    employeesFilters: {
        'selected': function (v) { return v.isSelected(); },
        'my': function (v) { return identity.isMyEmployee(v.model.id) },
        'active': function(v) { return v.model.isActive() },
        'virtual': function(v) { return v.model.isVirtual() && !v.model.isFired() },
        'fired': function (v) { return v.model.isFired() },
        'all': undefined
    },
    onRender: function() {
        console.log('# BLYAM BLYAM BLUYAM #');
        this.$('.select-label-searcher').css('display', 'block');

        this.showTabs();
        this.showSelect();
        this.setFilterToValues('active');
    },
    _initTabRegion: function() {
        var region = this.getRegion('tabs');
        if (!region) {
            this.addRegion('tabs', { el: '.tabs-region', replaceElements: true });
        }
    },
    onChildviewTabOn: function(tab) {
        this.setFilterToValues(tab);
    },
    showTabs: function() {
        this._initTabRegion();
        var tabs = {
            selected: this.getOption('selectedTabLabel') || 'выбранные',
            my: 'мои подчиненные',
            active: 'действующие',
            virtual: 'виртуальные',
            fired: 'уволенные',
            all: 'все'
        }


        if (!this.getOption('multiple'))
            delete tabs.selected;

        if (!identity.hasEmployees())
            delete tabs.my;

        var view = this.tabs = new Tabs({
            tabs: tabs,
            active: 'active',
        });
        this.showChildView('tabs', view);
    },
    setFilterToValues: function(name) {
        this.valuesView.employeesFilter = this.employeesFilters[name];
        this.valuesView.sort();
    },
    getUlOptions: function() {
        var opts = _.extend({}, this.options);
        var userViewFilter = _.isFunction(opts.viewFilter) && opts.viewFilter;
        opts.showTextFilter = true;
        opts.viewFilter = function(view) {
            var emp = this.employeesFilter;
            var result = _.isFunction(emp) ? emp.call(this, view) : true;
            if (!result) return false;

            if (userViewFilter && !userViewFilter.call(this, view))
                return false;

            return this.testTextPattern(view);
        }
        return opts;
    },
});

var ns = {};

ns.create = function(_opts) {
    var opts = {
        modelType: 'single',
        valueType: 'string',
        viewComparator: function(a, b) {
            return bbeViewComparator(a, b, function() { return this.model.get('label'); })
        },
    }
    _.extend(opts, _opts);
    opts.collection = opts.sourceValues;

    return new SelectEmployees(opts);
}

ns.inModal = function (_opts) {

    var opts = {
        header: 'Выбор сотрудников',
        multiple: false,
        modelType: 'single',
        valueType: 'string',
        //initialValue: m.get('ownerId'),
        sourceValues: radio.request('employeesSelect'),
        controlType: 'select',
        viewComparator: function (a, b) {
            return bbeViewComparator(a, b, function () { return this.model.get('label'); })
        },
        viewFilter: function () {
            return false;
        },
    }

    var options = _.extend({}, opts, _opts);
    var EditValue = busControls.request('edit:value');
    return EditValue.modal(options, { modalCssCfg: 'shortAutoHeight' });

}

export default ns;
