module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="block-line-system client-type">'+
((__t=( _enum('clientTypes',_m.type) ))==null?'':__t)+
'</div>\n<div class="block-line client-name">'+
((__t=( _ift(_m.name, 'без названия*') ))==null?'':__t)+
'</div>\n<div class="block-line-small client-occupation">'+
((__t=( _ndash(_m.occupation) ))==null?'':__t)+
'</div>\n';
}
return __p;
};
