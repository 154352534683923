﻿//define('ui-layouts-logsBoardList',['bus-app','m-logs'],function(radio, logsNs){});

import logsNs from 'm/logs';
import logViewTemplate from './logItem.html';
import template from './layout.html';
import './logs.less';

import { MneView, MneMixedView } from 'core/mne';
import beh from 'behaviors';



var LogView = MneView.extend({
	className:'log-item',
	//template: '#tmpl-ui-layouts-logsBoardList-logItem',
	template: logViewTemplate,
});

var EmptyView = MneView.extend({
	className: 'log-item empty',
	template: _.template('Логи отсутствуют'),
});


var LogsView = MneMixedView.extend({
	className: 'logs-list scrollY-region',
	childView: LogView,
	emptyView: EmptyView,
	behaviors: [beh.OnScrollLoadmorable, beh.ScrollableY],
	onRender: function () {
		this.triggerMethod('load');
	},
});



var Layout = MneView.extend({
	//template: '#tmpl-ui-layouts-logsBoardList-layout',
	template,
	regions: {
		'logs':'.logs-region'
	},
	onRender: function () {
		var view = this;
		//return;
		var col = this.logs = logsNs.LogsCollection.create(this.getOption('entity'), this.getOption('entityId'));
		if (col) {
			//console.log(col.loudFetch, col.url())
			//col.loudFetch();
			var logs = new LogsView({collection:col});
			view.showChildView('logs', logs);
		}
	},
});


export default Layout;
