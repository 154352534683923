import { _ } from 'vendors';

export const MiniStore = function(options) {
	this._id = options.getId.bind(this);
	this.clear();
	Object.defineProperty(this, 'length', {
		get: () => this.items.length,
		enumerable: false,
		configurable: false
	});
}


_.extend(MiniStore.prototype, {
	get(arg) {
		if (arg == null) return;
		let type = typeof(arg);
		if (type == 'number') {
			return this.getByIndex(arg);
		} else {
			arg = type == 'object' ? this._id(arg) : arg;
			return this.getById(arg);
		}
	},
	getByIndex(index) {
		return this.items[index];
	},
	getById(id) {
		return this.byId[id];
	},
	add(item) {
		
		let id = this._id(item);
		if (this.byId[id]) return false;

		this.items.push(item);
		this.byId[id] = item;

		return item;
	},
	remove(item) {
		if (item == null) return;

		let id = typeof(item) == 'object' ? this._id(item) : item;
		item = this.byId[id];
		if (!item) return;

		let index = this.items.indexOf(item);
		if (index == -1) return;

		delete this.byId[id]
		if (this.items.length == index) {
			this.items.pop();
		} else if (index == 0) {
			this.items.shift();
		} else {
			this.items.splice(index, 1);
		}

		return item;
	},
	clear() {
		this.items = [];
		this.byId = {};
	},
	has(arg) {
		if (arg == null) return false;

		if (typeof(arg) == 'object') {
			arg = this._id(arg);
		}
		return this.getById(arg) != null;
	},
	toArray() {
		return [...this.items];
	}
});