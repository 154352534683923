import SchemaEdit from 'ui/controls/schemaEdit';
import { HamburgerView } from "coms/ui/HamburgerView";
import { main, entry, additional } from './schemas';

export default HamburgerView.extend({
	getChildren() {
		const options = {
			noApplyButton: true,
			noHeader: true,
			thisClassName: 'ui2-box',
		}
		return [
			SchemaEdit.inLine(main, options),
			SchemaEdit.inLine(entry, options),
			SchemaEdit.inLine(additional, options),
		]
	}
});
