//define('_libs/appleCookieStore', [], function () {});


const KEY = "requeststamp";

const store = {
    set(token) {
        sessionStorage.setItem(KEY, token);
    },
    get() {
        return sessionStorage.getItem(KEY);
    },
    getHeaders() {
        let token = this.get();
        if (!token) return;
        return {
            Authorization: "Bearer " + token
        }
    }
};



export default store;