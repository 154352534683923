﻿// define('ui-controls-datetime2-monthView', [
// 	'behaviors',
// 	'ui-controls-datetime2-monthModel',
// 	'ui-controls-datetime2-dayModel',
// 	'ui-controls-datetime2-daysCollection',
// 	'ui-controls-datetime2-monthDaysView',
// 	'ui-controls-datetime2-monthControlsView'
// ], function (
// 	beh,
// 	MonthModel,
// 	DayModel,
// 	DaysCollection,
// 	MonthDays,
// 	MonthControls
// ) {
// });


import template from './monthView.html';

import { moment } from 'vendors';

import MonthModel from './month-model';
import DayModel from './day-model';
import DaysCollection from './days-collection';
import MonthDays from './month-days-view';
import MonthControls from './month-controls-view';

import { MneView } from 'core/mne';

var View = MneView.extend({
	//template: '#tmpl-ui-controls-datetime2-monthView',
	template,

	regions: {
		'days': '.datetime-month-days',
		'controls': '.datetime-month-controls',
		'weekdays': '.datetime-month-weekdays',
	},
	initialize: function (opts) {
		//this.mergeOptions(opts, ['modelType', 'valueModel']);

		this.initializeModel();
		this.initializeCollection();
		this.initModelListeners();

	},
	initializeModel: function () {
		if (this.model) return;
		var now = new Date();
		var year = this.getOption('year') || now.getFullYear();
		var month = this.getOption('month') || now.getMonth();
		this.model = new MonthModel({ year: year, month: month, });
	},
	initModelListeners: function () {
		this.listenTo(this.model, 'ready', this.onModelChange);
	},
	createDayModel: function (year, month, day) {
		var date = _.isNumber(year) ? new Date(year, month, day) : year;
		return new DayModel({ id: date.valueOf(), value: date });
	},
	initializeCollection: function () {

		var days = this.model.daysCount();
		var hash = [];
		for (var x = 1; x < days + 1; x++) {
			var date = this.model.createDayDate(x);
			hash.push(this.createDayModel(date));
		}

		if (!this.collection)
			this.collection = new DaysCollection(null, { model: DayModel });


		var col = this.collection;
		col.valueModel = this.getOption('valueModel');

		var first = col.firstDay = _(hash).first();
		var last = col.lastDay = _(hash).last();
		col.reset(hash);



		var fd = first.getDay();
		if (fd > 1) {
			for (let x = 0; x < fd - 1; x++) {
				this.collection.unshift(this.createDayModel(this.model.createDayDate(-x)))
			}
		}

		var ld = last.getDay();
		if (ld < 7) {
			var lastDate = new Date(last.id);
			for (let x = ld + 1; x < 8; x++) {
				lastDate.setDate(lastDate.getDate() + 1);
				this.collection.push(this.createDayModel(lastDate.getFullYear(), lastDate.getMonth(), lastDate.getDate()))
			}
		}

	},
	showDays: function () {

		var view = new MonthDays({
			model: this.model,
			collection: this.collection,
			//modelType: this.modelType,
			//valueModel: this.valueModel,
		});
		this.showChildView('days', view);
	},
	showControls: function () {
		var view = new MonthControls({ model: this.model });
		console.log('-controls-', view._viewType)
		this.showChildView('controls', view);
	},
	showWeekdays: function () {
		var _sdays = '';
		for (var x = 0; x < 7; x++)
			_sdays += '<b>' + moment().weekday(x).format('dd') + '</b>';
		var days = _.template(_sdays);
		this.showChildView('weekdays', days);
	},
	onRender: function () {
		this.showDays();
		this.showControls();
		this.showWeekdays();
	},
	onModelChange: function () {
		this.initializeCollection();
	},
	collectionEvents: {
		'clicked': function (model) {
			this.trigger('day:click', model);
		},
	},
	childViewTriggers: {
		//'day:click': 'day:click',
		'select:year': 'select:year',
		'select:month': 'select:month',
	},
	triggers: {
		'click .year': 'select:year',
		'click .month': 'select:month',
	},
});


export default View;
