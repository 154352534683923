module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if(showHeader) { 
__p+='<h3>фильтры <span class="fastfilter-amount pull-right"><small>нашлось:</small><span data-role="itemsCount"></span></span></h3>';
 } 
__p+='\n<div class="pinned-filters-region"></div>\n<div class="actions">\n	';
 if(allFilters) {
__p+='<button class="allfilters">все фильтры</button>';
}
__p+='\n	<div>\n		<button class="apply">применить</button>\n		<button class="reset">сбросить</button>\n	</div>\n</div>';
}
return __p;
};
