import { View } from 'core';
import { HamburgerView } from 'coms/ui/HamburgerView';
import { UiAtomView } from '../../../../../../coms/ui/UiAtomView';
import { propertySchemaApi } from '../../../../../../apis/schema';

const IconView = View.extend({
	className: 'icon',
	template: '<i></i>'
});


const entityTypes = {
	realty: 'здание',
	owner: 'собственник'
}
const ownerInRealtySchema = {
	'realty.visibleFor': {
		valueType: 'enum',
		valueSubType: 'entityVisibilities'
	},
	'realty.buildStatus': {
		valueType: 'enum',
		valueSubType: 'realtyBuildStatus'
	}
}


const ThisUiAtomView = UiAtomView.extend({
	initialize(options) {
		this.mergeOptions(options, ['type', 'entityId']);
		this.modelSchema = ownerInRealtySchema;
	},
	is(type) {
		return this.type === type;
	},
	modelGet(key) {
		return this.model.getByPath(this.type + '.' + key);
	},
	modelDisplay(key) {
		return propertySchemaApi.displayValue(this.type + '.' + key, this.model, this.modelSchema);
	}
});
const NameView = ThisUiAtomView.extend({
	// initialize(options) {
	// 	this.mergeOptions(options, ['type', 'entityId']);
	// 	//console.log('nameview', this);
	// },
	getTypeName() {
		return entityTypes[this.type];
	},
	getName() {
		let add = '';
		if (this.is('realty')) {
			add = ' <small><b>(' + this.modelDisplay('buildStatus') + ')</b></small>';
		}
		return this.modelGet('name') + add; //.getByPath(this.type + '.name');
	},
	text: v => v.getName(),
	topText: v => v.getTypeName(),
	bottomText: v => v.is('realty') ? v.modelDisplay('visibleFor') : ''
});

const RealtyVisibility = ThisUiAtomView.extend({
	topText: 'видимость',
	text: v => v.modelDisplay('visibleFor')
});

export const EntityLabelView = HamburgerView.extend({
	baseClassName: 'entity-label-view flex-row',
	childViewOptions() {
		return {
			type: this.getOption('type'),
			entityId: this.getOption('entityId'),
		}
	},
	is(type) {
		return this.getOption('type', true) === type;
	},
	classNames: [
		v => v.getOption('type', true)
	],
	childrenViews: [
		IconView,
		NameView,
	]
});