﻿//define('ui-controls-datetime2-daysCollection', ['ui-controls-datetime2-dayModel'], function (model) {});

import model from './day-model';
import { BbeCollection } from 'core/bbe';

var Model = BbeCollection.extend({
	model: model,
	test: function (model) {
		if (this.valueModel == null) return false;

		var isSingle, single, left, right;
		isSingle = this.valueModel.modelType == 'single';

		if (this.valueModel.modelType == 'single')
			single = this.valueModel.get('value');
		else if (this.valueModel.get('from') && !this.valueModel.get('to'))
			single = this.valueModel.get('from');
		else if (this.valueModel.modelType == 'range') {
			left = this.valueModel.get('from');
			right = this.valueModel.get('to');
		}

		if (single) {
			//console.log('single is', signle);
			return model.sameDay(single);
		} else if (!isSingle && (left || right)) {
			return model.inRange(left, right)
		} else {
			return false;
		}


	},
	getDay: function () {
		return this.get('value').getDay() || 7;
	},
	inMonth: function () {
		return this.inRange(this.collection.firstDay.get('value'), this.collection.lastDay.get('value'));
		//return this.id >= this.collection.firstDay.id && this.id <= this.collection.lastDay.id;
	},
	notInMonth: function () {
		return !this.inMonth();
	},
	inRange: function (left, right) {
		var res = this.dayEqualOrGreater(left) && this.dayEqualOrLess(right);
		return res;
	},
	sameMonth: function (check) {
		var date = this.get('value');
		return date.getFullYear() == check.getFullYear()
			&& date.getMonth() == check.getMonth();
	},
	dayEqualOrGreater: function (check) {
		var date = this.get('value');
		return this.sameDay(check) || date > check;
	},
	dayEqualOrLess: function (check) {
		var date = this.get('value');
		return this.sameDay(check) || date < check;
	},
	sameDay: function (check) {
		var date = this.get('value');
		return this.sameMonth(check)
			&& date.getDate() == check.getDate()
	},
	isToday: function () {
		var _now = new Date();
		return this.sameDay(_now);
	},
	isSelected: function () {
		return false;
	},
	isHoliday: function () {
		return this.getDay() > 5;
	},
});

export default Model;