import { propertySchemaApi } from "apis/schema/propertySchemaApi";
import { HamburgerView } from 'coms/ui/HamburgerView';

function isEmpty(value) {
	return value == null || value === '';
}

const booleanValues = {
	true: true,
	1: true,
	True: true,
	false: false,
	False: false,
	'': false,
	0: false
}

export function getBooleanValue(arg) {
	if (arg != null) {
		return booleanValues[arg];
	}
}

export const controlValueMixin = {
	updateElClassBeforeRender: true,
	initializeValue() {
		if (this._valueInitialized) { return; }
		this._valueInitialized = true;
		this.value = this.getOption('value', true);
		this.initialValue = this.value;
		this.updateValidateStatus(this.value);
	},
	initializeControl() {
		this.controlOptions = this.getOptions(['valueSchema', 'modelSchema', 'modelHash']);
		const { valueSchema, modelSchema } = this.controlOptions;
		this.controlOptions.valueSchema = propertySchemaApi.getSchema(valueSchema, modelSchema);
	},
	getValue() {
		return this.value;
	},
	getInitialValue() {
		return this.initialValue;
	},
	setValue(value, done) {
		this.value = this.normalizeValue(value);
		value = this.getValue();		
		this.updateValidateStatus();
		this.triggerUserInput(value, done);
	},
	validateValue(value, done) {

	},
	updateValidateStatus(value, done) {
		const errors = this.validateValue(value, done);
		this.validationErrors = errors;
		if (this.state) {
			this.state('validation', errors ? 'invalid' : 'valid');
		}
		this.triggerMethod('validation', errors);
	},
	normalizeValue(value) {
		const { valueSchema, modelSchema } = this.controlOptions;
		const { valueType, valueSubType, emptyValue, required } = valueSchema;
		const empty = isEmpty(value);
		const type = typeof value;
		if (empty && required) {
			return emptyValue;
		}
		if (type === valueType) {
			return value;
		}
		if (valueType === 'number') {
			value = value.toString().replace(',', '.');
			if (value.endsWith('.')) {
				value = value.substring(0, value.length - 1);
			}
			value = parseFloat(value, 10);
			if (isNaN(value)) {
				value = emptyValue;
			}
			return value;
		} else if (valueType === 'enum') {
			if (Array.isArray(value)) {
				value = value.join(', ');
			}
			return value;
		} else if (valueType === 'boolean' && !Array.isArray(value) && value != null) {
			return booleanValues[value];
		}
		return value;
	},
	triggerUserInput(value, done) {
		this.triggerMethod('user:input', value, done);
	}
}


export const SchemaView = HamburgerView.extend({
	getValueSchema() {
		return this.getOption('valueSchema') || {};
	},
	getSchemaProperty(key) {
		const schema = this.getValueSchema();
		return schema[key];
	}
});