﻿//define('m-contacts-contactValuesCollection', ['m-contacts-contactValue', 'app-paths'], function (ContactValueModel, paths) {});


import paths from 'app-paths';
import ContactValueModel from './contactValue';

import { BbeCollection } from 'core/bbe';

var Collection = BbeCollection.extend({
	initialize: function (data, opts) {
		opts || (opts = {});
		this.contactId = opts.contactId;
	},
	//channel: 'EntityContact',
	//broadcastName: function () {
	//	return 'broadcast:' + this.contactId;
	//},
	model: ContactValueModel,
	url: function () {
		var cid = this.contactId;
		if (_.isFunction(cid)) cid = cid.call(this);
		if (!cid) return;
		return paths.api.contactContactValues(cid);
	},
	//onBroadcastContactValueChange: function (id, model) {
	//	this.add(model, {merge:true});
	//}
});

Collection.create = function (opts) {
	opts || (opts = {});
	var data = opts.data || [];
	var col = new Collection(data, opts);
	return col;
}

export default Collection;