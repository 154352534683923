//define('acc/forgotPassword/layout',['app-config', 'ui/controls/forms/simple-form-mixin', 'modals'],function(cfg, formMixin, modals){});


import cfg from 'app-config';
import formMixin from 'ui/controls/forms/simple-form-mixin';
//import modals from '_libs\modals';

import { MneView } from 'core/mne';

var template = `
<div class="form-group">
	<label>Укажите свой логин и нажмите кнопку &laquo;далее&raquo;</label>
</div>
<div class="form-group">
	<input class="form-control" name="userName" type="email"/>
</div>
<button class="btn btn-default submit" data-role="submit">далее</button>	
`;

var afterSendTemplate = `
<div class="form-group">
<label>На Ваш email отправлено письмо с инструкциями.</label>
</div>
`;

var Layout = formMixin.mix(MneView).extend({
	template: template,
	templateAfterSend: afterSendTemplate,

	formUrl: () => cfg.apiUrl('acc/forgot-password'),
	//defaultFormData: v => v.getOption('queryData'),

	initialize() {
		this._initializeClickHandler();
	},

	validateData(data) {
		if (!data.userName) {
			return 'необходимо указать свой логин';
		}
	},
	
	onSendSuccess() {
		//this._sended = true;
		this.render();
	},

});

export default Layout;