export function buildUrlHashByKeys(query, ...keys) {
	query = query || {};
	const res = {};
	console.log(">", query);
	keys.forEach(key => {
		let v = query[key];
		console.log("k:", key, v);
		if (v) {
			res[key] = decodeURIComponent(v);
		}
	});
	return res;
}