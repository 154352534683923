export const fetchPromiseMixin = Base => Base.extend({    

    constructor: function() {
        this.whenFetched = new Promise((res, rej) => {
            this.once('sync', () => {
                this.trigger('first:sync', this);
                return res(this);
            });
        });
        Base.apply(this, arguments);
    },

});