export function stringValue(arg, defaultValue = '') {
    if (arg == null || arg === '')
        return defaultValue;
    return arg;
}

export function addPostfix(arg, postfix) {
    if (postfix == null || arg == null || arg === '') { return arg; }
    return arg + postfix;
}

export function addPrefix(arg, prefix) {
    if (prefix == null || arg == null || arg === '') { return arg; }
    return prefix + arg;
}

export function wrapValue(arg, prefix, postfix) {
    
    if ((prefix == null && postfix == null) || arg == null || arg === '') { return arg; }

    if (prefix != null) {
        arg = prefix + arg;
    }

    if (postfix != null) {
        arg = arg + postfix;
    }

    return arg;
}


