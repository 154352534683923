import { Model } from 'core';
import { OffersCollection } from './OffersCollection';
import { actionsMixin, actualizeMixin, isNotActualized, plateActionsMixin } from './common';
import { urls } from 'coms/urls';
import { addOffer, changeOwner, editMainProperties } from './object-actions';
import refs from 'references';
import { invokeValue } from 'utils';

export const objectActions = [
	{ id:'nochanges', label: 'Без изменений', 
		multyAction: true, places: ['actualize', 'plate'],
		getAttrs: () => ({ 'actualize.last': new Date() }),
	},
	{ 
		id:'openpage', label: 'Открыть страницу площади в новой вкладке', newWindow: true, 
		places: ['plate'],
		url: m => urls.page('actualization/objects-list/' + m.id + '/edit')
	},
	{ id:'edit', label: 'Редактировать основные характеристики', 
		multyAction: true, places: ['plate'],
		getAttrs: (defValue = {}) => {
			console.log('> def value:', defValue);
			return editMainProperties(defValue);
		}
	},
	{ id:'addoffer', label: 'Добавить предложение', 
		places: ['plate'], action: addOffer
	},
	{ id:'changeowner', label: 'Изменить собственника', 
		multyAction: true, places: ['plate'], multyAction: true,
		action: changeOwner
	},
	// { id:'changerealty', label: 'Поменять здание*', claims: { svcActualizationModule: 'actualizationAdmin' }, 
	// 	multyAction: true, places: ['plate'],
	// },
	{ id:'archive', label: 'Отправить в архив', 
		filter: (m) => m.get('status') !== 'archived' && m.get('status') !== 'deleted',
		multyAction: true, places: ['plate'],
		getAttrs: () => ({ 'status': 'archived' }),
	},
	{ id:'unarchive', label: 'Достать из архива', 
		filter: (m) => m.get('status') === 'archived',
		multyAction: true, places: ['plate'],
		getAttrs: () => ({ 'status': 'notVerified' }),
	},
	// { id:'delete', label: 'Удалить*', claims: { svcActualizationModule: 'actualizationAdmin' }, 
	// 	filter: (m) => m.get('status') !== 'archived' && m.get('status') !== 'deleted',
	// 	multyAction: true, places: ['plate'],
	// 	getAttrs: () => ({ 'status': 'deleted' }),
	// },
	// { id:'undelete', label: 'Отменить удаление*', claims: { svcActualizationModule: 'actualizationAdmin' }, 
	// 	filter: (m) => m.get('status') === 'deleted',
	// 	multyAction: true, places: ['plate'],
	// 	getAttrs: () => ({ 'status': 'notVerified' }),
	// },	
]


export const ObjectModel = Model.extend({
	initialize() {
		this.on('ownerInRealty:sync', this.syncOffers);
	},
	...actionsMixin,
	...actualizeMixin,
	plateActionsHeader: 'Площадь',
	actions() {
		return [...objectActions];
	},
	getOffers({ models } = {}) {
		if (!this.offersCollection) {
			const models = this.get('offers') || [];
			const ownerInRealty = this.collection.parent;
			this.offersCollection = new OffersCollection(models, { parent: this, ownerInRealty });
		}
		return models ? this.offersCollection.models : this.offersCollection;
	},
	syncOffers() {
		const col = this.getOffers();
		const models = this.get('offers') || [];
		col.set(models, { remove: true, merge: true, add: true });
	},
	hasOffers() {
		const offers = this.getOffers();
		return offers.length > 0;
	},
	hasAvailableOffers() {
		const offers = this.getOffers();
		return offers.filter(f => f.get('status') === 'active').length > 0;
	},
	getAvailableMarketsOperations() {
		const markets = Object.keys(refs.enum('realtyMarkets'));
		const operations = Object.keys(refs.enum('realtyOperations'));
		const allowed = {};
		operations.forEach(operation => {
			if (operation === 'none') { return; }
			allowed[operation] = {};
			markets.forEach(market => {
				if (market === 'none') { return; }
				allowed[operation][market] = 1;
			});
		});

		const offers = this.getOffers();
		offers.each(model => {
			const { market, operation } = model.attributes;
			delete allowed[operation][market];
		});

		_.each(allowed, (obj, key) => {
			allowed[key] = Object.keys(obj);
		});

		return allowed;

	},
	async sendNewOffer(attrs) {
		const oirModel = this.collection.parent;
		const result = await oirModel.massObjectsActionAsync({ action: 'newoffer', ids:[this.id], attrs });
		const newoffer = result[this.id];
		if (!newoffer) { return }
		const offers = this.getOffers();
		offers.add(newoffer);
	}
});

ObjectModel.defineSchema({
	neededActualize: {
		label: 'Статус актуализации',
		valueType: 'enum',
		getValue(model) {
			const globalDaysToStale = model?.collection?.parent?.getByPath('actualize.daysToStale');
			const objectDaysToStale = model.getByPath('actualize.daysToStale');
			const offers = model.getOffers();
			if (offers.length) {
				const offersNeededActualize = offers.hasNotActualized(objectDaysToStale, globalDaysToStale);
				return offersNeededActualize ? 'notActualized' : '';
			} else {
				let last = model.getByPath('actualize.last');
				return isNotActualized(last, objectDaysToStale, globalDaysToStale) ? 'notActualized' : '';
			}
			
		}
	},
	status: {
		label: 'статус',
		valueType: 'enum',
		valueSubType: 'realtyObjectStatuses',
		wrapDisplayValue: true,
		inlineEdit: true,
		controlOptions: {
			thisClassName: 'inline-buttons'
		}
	},
	visibleFor: {
		label: 'Видимость',
		valueType: 'enum',
		valueSubType: 'entityVisibilities',
		inlineEdit: true,
		multiple: true,
		controlOptions: {
			thisClassName: 'inline-buttons'
		}
	},
	'info.squareOffer': {
		valueType: 'number',
		postfix: 'м<sup>2</sup>',
		wrapDisplayValue: true,
	},
	'info.floor': {
		label: 'Этаж',
		valueType: 'number',
		postfix: 'Эт.',
		wrapDisplayValue: true,
		wrapPostfixAlways: true,
	},
	'info.roomNumber': {
		label: 'Номер(название) кабинета',
		valueType: 'string',
		prefix: 'каб.',
		wrapDisplayValue: true,
		displayIfEmpty: '',
	},
	'info.state': {
		label: 'Состояние',
		valueType: 'enum',
		valueSubType: 'realtyObjectStates',
		wrapDisplayValue: true,
		inlineEdit: true,
		controlOptions: {
			thisClassName: 'inline-buttons'
		}
	},
	'info.spaceLayout': {
		label: 'Планировка',
		valueType: 'enum',
		valueSubType: 'spaceLayouts',
		wrapDisplayValue: true,
		// inlineEdit: true,
	},

	
	'info.purposes': {
		label: 'Возможные назначения',
		valueType: 'enum',
		valueSubType: 'realtyPurposes',
		multiple: true,
		// inlineEdit: true,
		wrapDisplayValue: true,
		controlOptions: {
			searchEnabled: true,
		}
	},

	'info.currentPurpose': {
		label: 'Текущее назначение',
		valueType: 'enum',
		valueSubType: 'realtyPurposes',
		// inlineEdit: true,
		wrapDisplayValue: true,
	},

}, 'realtyObject');
