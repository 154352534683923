import { CollectionView } from 'core/collection-view';
import { contactValuesComparator } from '../comparator';
import { getContactValuesCollection } from '../ContactValueModel';
import { BaseContactValueListItemView } from './BaseContactValueListItemView';

export const BaseContactValuesListView = CollectionView.extend({
	constructor: function() {
		CollectionView.apply(this, arguments);
		this.initializeCollection();
	},
	initializeCollection() {
		if (this.collection || !this.model) { return; }
		this.collection = getContactValuesCollection(this.model);
	},
	viewComparator: contactValuesComparator,
	childView: BaseContactValueListItemView
});