import { getValueByPath } from 'utils';

export const postfixFunc = text => `<span class="postfix">${text}</span>`;
export const prefixFunc = text => `<span class="prefix">${text}</span>`;
export const postfixMeters = postfixFunc('м<sup>2</sup>');
export const importantFunc = (text, add = '') => `<span class="important-value${add ? ' ' + add : ''}">${text}</span>`
export const postfixRuble = postfixFunc('р.');

function getOffer(model, { offerPath, objectPath } = {}) {
	if (model == null) { return; }
	if (offerPath) {
		return getValueByPath(model, offerPath);
	}
	return model.attributes || model;
}

function getObject(model, options = {}) {
	if (model == null) { return; }

	let { objectPath } = options;

	if (objectPath) {
		return getValueByPath(model, objectPath);
	}
	var off = getOffer(model, options);
	if (off) {
		return off.obj || off.object;
	}
}

function getOfferForCustomer(model, options) {

	let { offerForCustomerPath } = options;

	if (offerForCustomerPath) {
		return getValueByPath(model, offerForCustomerPath);
	}

	const offer = getOffer(model, options);

	return offer.forCustomer;

}

function getObjectSquare(model, options) {
	if (model == null) { return; }

	let { objectSquarePath } = options;

	if (objectSquarePath) {
		return getValueByPath(model, objectSquarePath);
	}
	const obj = getObject(model);

	if (obj) {
		return getValueByPath(obj, 'info.squareOffer');
	}

}


export function extractPriceOptions (model, options = {}) {
	if (!model) { return {}; }
	
	const forCustomer = getOfferForCustomer(model, options);
	if (!forCustomer) { return {}; }

	const { boma, priceMeter, taxType } = forCustomer;
	
	const square = getObjectSquare(model, options);
	
	const offer = getOffer(model, options);

	// const attrs = model.attributes || model;

	// if (!attrs.forCustomer) { return {}; }

	// const { boma, priceMeter, taxType } = attrs.forCustomer;

	const isRent = offer.operation === 'rent';
	
	const isNds = (taxType || '').indexOf('nds') > -1;
	// const square = getValueByPath(attrs.obj || attrs.object, 'info.squareOffer');
	const bomaKoef = isRent && boma > 1 ? boma : 1;
	
	const isBoma = bomaKoef > 1;

	const priceOtions = { isNds, isRent, isBoma, boma: bomaKoef, priceMeter, square };

	return priceOtions;
}


export function getEngClass(ru) {
	let res = ru.replace('+', 'p');
	return res;
}