﻿//define('ui-controls-allfilters', ['ui-controls-allfilters-groupView'],function(GroupView){});

import GroupView from 'ui/controls/allfilters/groupView';

import template from './control.html';

import { MneView, MneNextCollectionView } from 'core/mne';

var Groups = MneNextCollectionView.extend({
    childView: GroupView,
    viewComparator: function (view) { return -view.model.get('order'); },
    childViewOptions:function(){
        return {filters:this.getOption('filters')};
    },
    viewFilter: function (view) {
        var m = view.model;
        var dep = m.get('dependency');
        if (!dep || !dep.showIfFilter) return true;

        //var showFilter = dep.showIfFilter;

        var fltr = view.getOption('filters');
        if (!fltr || !fltr.filters) return true;

        var all = fltr.filters;
        return _(dep.showIfFilter).every(function (d) {
            var f = all.get(d.id);
            if (!f) return false;
            return (f.getValue({ asArray: true }) || []).indexOf(d.value) >= 0;
        });

    }
});

var Layout = MneView.extend({
    //template: '#tmpl-ui-controls-allfilters-control',
    template,
    regions: {
        'groups':'.groups-region'
    },
    ui: {
        'apply': 'button.apply',
        'reset': 'button.reset',
    },
    triggers: {
        'click @ui.apply': 'apply',
        'click @ui.reset': 'reset',
    },
    onApply: function() {
        var filters = this.getOption('filters');
        if (!filters) return;
        filters.apply();
        this.destroy();
    },
    onReset: function() {
        var filters = this.getOption('filters');
        if (!filters) return;
        filters.clearValues();
        this.render();
    },
    onRender: function() {
        var opts = _.extend({}, this.options);
        var groups = new Groups(opts);
        this.showChildView('groups', groups);
    }
});

Layout.create = function (opts) {
    var filters = opts.filters || {};
    var groups = filters.groups;
    var options = _.extend({}, opts);
    options.collection = groups;
    return new Layout(options);
}

export default Layout;
