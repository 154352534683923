import refs from 'references';
import SchemaEdit from 'ui/controls/schemaEdit';

export function displayFileSize(size) {
    if (size < 1024)
        return size + 'b';


    size = size / 1024; // now in kb

    if (size < 1024) {
        return _.displayNum(size, 1) + 'Kb'
    }

    size = size / 1024; // now in Mb
    if (size < 1024) {
        return _.displayNum(size, 1) + 'Mb'
    }

    size = size / 1024; // now in Gb
    return _.displayNum(size, 1) + 'Gb'
}

let imageSchema;

export function ensureProperties() {
    if (imageSchema) return imageSchema;

    let properties = {
        // 'isPrimary':{
        //     caption: 'заглавное фото / основной файл',
        //     emptyText: 'Нет',
        //     sourceValues: refs.enum('trueFalse'),
        //     display: v => refs.enum('trueFalse', v),
        //     // sourceValues: refs.enum('yesNo'),
        //     modelType: 'single',
        //     valueType: 'boolean',
        // },

        'order': {
            caption: 'порядок сортировки',
            emptyText: 'не установлен',
            modelType: 'single',
            valueType: 'number',
        },

        'groupName': {
            caption: 'группа',
            emptyText: 'не указана',
            modelType: 'single',
            valueType: 'string',
        },

        'type': {
            caption: 'тип файла/картинки',
            emptyText: 'Неустановлен',
            sourceValues: refs.enum('realtyMediaTypes'),
            display: v => refs.enum('realtyMediaTypes', v),
            // sourceValues: refs.enum('yesNo'),
            modelType: 'single',
            valueType: 'enum',
        }

        /*        
        'forCustomer.taxType': {
            caption: 'налогообложение',
            emptyText: 'не установлено',
            display: v => v ? refs.enum('taxTypes',v) : undefined,
            sourceValues: refs.enum('taxTypes'),
            _value: model && model.getValueByPath('offer.forCustomer.taxType') || void 0,
            modelType: 'single',
            valueType: 'string',
            flattenValues: true,
            multiple: false,
            validate: required,	
        },
        'forAgent.contractType': {
            caption: 'договор',
            emptyText: 'не установлен',
            display: v => v ? refs.enum('contractTypes',v) : undefined,
            sourceValues: refs.enum('contractTypes'),
            _value: model && model.getValueByPath('offer.forAgent.contractType') || void 0,
            modelType: 'single',
            valueType: 'string',
            flattenValues: true,
            multiple: false,
            validate: required,	
        },
        'forAgent.income': {
            caption: 'процент коммиссии',
            modelType: 'single',
            valueType: 'number',
            display: v => v ? v + '%' : '',
            validate: required,
            _value: model && model.getValueByPath('offer.forAgent.income') || void 0,
        },
        */
    }    

    imageSchema = properties;
    return imageSchema;
}


export function editImage(opts) {
    let properties = ensureProperties();
    opts = opts || {};
    let options = {
        header: 'Изменение файла',
        applyText: 'Изменить',
        beforeApply: (value) => {
            //return this.takeAction('commercial:update', 'изменение условий', models, value);
        },
        ...opts,
    }
    SchemaEdit.inModal(properties, options);	    
}

export function simpleEditImage(model) {
	return editImage({ 
		valuesModel: model,
		beforeApply: (value) => {
				model.set(value);
				model.collection.trigger('mass:changes', [model]);
		}
});
}

export const bytesInMegabyte = 1048576;
export const maximumImageSizeInBytes = 8 * bytesInMegabyte;