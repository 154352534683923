import { Collection as BbeCollection } from 'core/bbe';
import { _ } from 'vendors';

export const BaseCollection = BbeCollection.extend({
	getLabel () {
		return this.map(m => _.lbl(m)).filter(f => f != null).join(', ');
		// return this.map(function (m) { return _.lbl(m); }).onlyValues().join(', ');
	},
	hasValue () {
		var x = this.getValue();
		return x.length > 0;
		//console.log('values hasValue', x);
	},
	getValue () {
		return this.map(m => _.val(m)).filter(f => f != null);
		// return this.map(function (m) { return _.val(m); }).onlyValues();
	},
	getUrlHash (options) {
		var res = [];
		this.each(model => {
			var h = model.getUrlHash(options);
			if (h) { res.push(h); }
		});
		return res.length === 0 ? undefined : res;
	},
});