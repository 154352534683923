// define('app-initialize-2', [
// 	'server-cfg', 'app-config', 'assets-identity-token', 'bus-app', '_libs/identity',
// 	'references', 'templates-cache', 'app-paths', 'm-appData', 'app', 'request-cfg', 'v', 'm'
// ], function(serverCfg, appCfg, Token, bus, identity, refs, templates, paths, appData, app) {});


// import serverCfg from 'server-cfg'; // alias
// import appCfg from 'app-config'; // alias

// import Token from '../assets/identity'; //'assets-identity-token'


// import refs from 'references'; // 'references'

//DEPRECATED //import templates from './_libs/templates-cache'; //'templates-cache'

// import paths from 'app-paths';

// import appData from './m/appData'; //'m-appData'

// import bus from 'bus/app'; //'bus-app'
// import identity from '_libs/identity'; //'_libs/identity'
// import app from './app/app'; //'app'

import './request-cfg'; //'request-cfg';
// import './v';

// import registerModules from './register-modules';
// import registerSvcModules from './register-svcmodules';

import { ajaxSetup } from './ajaxSetup';
import { busSetup } from './busSetup';
import { buildStartPrommises } from './startPromisses';
import { afterStartPromise } from './afterStartPromise';
//DEPRECATED // import './v'; //'v'
//DEPRECATED // import './m'; //'m'







console.warn('app initialization');


ajaxSetup();
busSetup();
const startPrommises = buildStartPrommises();
startPrommises.then(
	afterStartPromise,
	exc => console.error('startPRomise fail', exc)
);



// startPrommises.then(() => {
// 	//let test = Object.assign({}, refs.Enums);
// 	//console.log('start promises resolved', test);

// 	app._rootModulesPromise = createPromiseContext();
// 	app._svcModulesPromise = createPromiseContext();


// 	registerModules();

// 	//registerModules('register-modules');

// 	if (identity.get('employeeId')) {
// 		registerSvcModules();
// 		//registerModules('register-svcmodules');
// 	} else {
// 		console.error('no employeeId', identity.attributes);
// 		app._svcModulesPromise.resolve();
// 	}

// 	Promise.all([app._rootModulesPromise.promise, app._svcModulesPromise.promise]).then(() => {
// 		bus.trigger('allmodules:registered');
// 	});

// }, (exc) => {
// 	console.error('startPromises fail', exc);
// 	//console.log(refs.runPromise);
// });

