﻿//define('m-contacts-entityContact', ['bus', 'm-abstract', 'm-contacts-contactValue', 'm-contacts-contactValuesCollection', 'action', 'app-paths', 'modals'], function (Bus, abstractNs, ContactValue, ContactValues, action, paths, modals) {});

import Bus from 'bus';
import abstractNs from 'm/abstract';
import ContactValues from './contactValues';
import action from '_helpers/action';
import paths from 'app-paths';
import modals from '_libs/modals';

import { bbeViewComparator } from 'utils';

import { BbeCollection } from 'core/bbe';
import { EntityContactEdit } from '../../v/contacts/entityContact/edit';
import { EntityContactEditContacts } from '../../v/contacts/entityContact/editContacts';

var Model = abstractNs.ActionableModel.extend({
	initialize: function (data, opts) {
		opts || (opts = {});
		this.realtyId = opts.realtyId;
		this.initContacts();
	},
	initContacts: function () {
		return this.getValues();
	},
	getName: function() {
		var p = this.get('person') || {};
		var name = p.name || {};
		return name.full || name.short || name.first || '&ndash;';
	},
	getEmail: function() {
		var values = this.getValues();
		if (!values || values.length == 0) return;

		var value = values.models.sort(function(a, b) {
			return bbeViewComparator([
				[b, a, function() { return this.get('type') == 'email' }],
				[b, a, function() { return this.get('isPrimary'); }],
				[b, a, function() { return this.get('order'); }],
			]);
		})[0];
		return value.get('value');
	},
	getValues: function () {
		var values = this.get('values') || [];
		if (values instanceof BbeCollection) return values;
		var col = ContactValues.create({
			data: values,
			contactId: this.getId(),
			parent: this,
		});
		this.set('values', col);
		var _this = this;
		this.listenTo(col, 'add remove change', function () {
			_this.trigger('change', _this);
			_this.trigger('change:values', _this);
		});
		return col;
	},
	getFirstContact: function () {
		//this.initContacts();
		var values = this.getValues();
				//this.get('values').models;
		var sorted = values.models.sort(function (a, b) {
			return bbeViewComparator([
				[b, a, function () { return this.get('isPrimary'); }],
				[b, a, function () { return this.get('order'); }],
				[b, a, function () { return (this.get('type') || '').indexOf('hone'); }],
			]);
		});
		return sorted[0];
	},
	actions: function () {
		return [
			action('edit', 'редактировать информацию', {}, { order: 0 })
			, action('editContacts', 'редактировать контакты', {}, { order: 0 })
			, action('remove', 'удалить', {}, { order: 0 })
		];
	},
	actionRemove: function (options) {
		if (options.modal)
			options.modal.destroy();

		var model = this;
		modals.confirm('подтвердите удаление').then(function () {
			model.loudDestroy({wait:true});
		});
	},
	actionEdit: function (options) {
		if (options.modal)
			options.modal.destroy();

		var View = EntityContactEdit;
		// Bus.Views.request('entityContact:edit');
		if (View)
			View.openInModal({ model: this });
		else
			console.warn('entityContact:edit View not returned');
	},
	actionEditContacts: function (options) {
		if (options.modal)
			options.modal.destroy();

		var View = EntityContactEditContacts;
		// Bus.Views.request('entityContact:editContacts');
		if (View)
			View.openInModal({ model: this, realtyId: this.realtyId });
		else
			console.warn('entityContact:editContacts View not returned');
	},


	urlRoot: function () {

		if (this.get('clientId'))
			return paths.api.clientContacts(this.get('clientId'));

		if (this.get('ownerId'))
			return paths.api.realtiesOwnerContacts(this.get('ownerId'));

	},
	//channel: function () {
	//	return Model.channel;
	//}
});

//Model.channel = 'EntityContact';

export default Model;