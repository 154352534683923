﻿//define('m-clients-clientsSearch', ['m-clients-client','app-paths'], function (Client, paths) {});


import Client from './client';
import paths from 'app-paths';
import { BbeCollection } from 'core/bbe';

var ClientsSearch = BbeCollection.extend({
	model: Client,
	url: function () {
		return paths.api.clientsSearch;
	},
});

export default ClientsSearch;