﻿//define('ui-models-values-collection', ['ui-models-values-single', 'ui-models-values-range', 'ui-models-values-rest'], function (Single, Range, Rest) {})


import Single from './single';
import Range from './range';
import Rest from './rest';

import { isNotValue, isValue } from 'utils/is';
import { BbCollection } from 'vendors';
//import { Collection as BbeCollection } from 'core/bbe';
import { BaseCollection } from './BaseCollection';

// var BaseCollection = BbeCollection.extend({
// 	getLabel: function () {
// 		return this.map(function (m) { return _.lbl(m); }).onlyValues().join(', ');
// 	},
// 	hasValue: function () {
// 		var x = this.getValue();
// 		return x.length > 0;
// 		//console.log('values hasValue', x);
// 	},
// 	getValue: function () {
// 		return this.map(function (m) { return _.val(m); }).onlyValues();
// 	},
// 	getUrlHash: function (options) {
// 		var res = [];
// 		this.each(function (model) {
// 			var h = model.getUrlHash(options);
// 			if (h) res.push(h);
// 		});
// 		return res.length == 0 ? undefined : res;
// 	},
// });

const SingleCollection = BaseCollection.extend({
	model: Single
});

const _contains = (arr, val) => {
	//let res = arr && arr.indexOf(val) > -1;
	return arr && arr.indexOf(val) > -1;
}
const falsyValues = ['no','false','0','нет'];

function convertValue(value, options = {}) {
	if (value == null) { return value; }

	const { valueType } = options;
	if (valueType === 'boolean') {
		value = falsyValues.indexOf(value.toString().toLowerCase()) > -1 ? false : !!value
	}
	return value;
}

SingleCollection.fromHash = function (hash, options = {}) {
	var data = [];
	
	let { excludeValues } = options;

	if (excludeValues && !Array.isArray(excludeValues)) {
		excludeValues = undefined;
	}

	_(hash).each(function (label, value) {
		value = convertValue(value , options);
		if ((!isValue(label) || label === '') || (!isValue(value) || value === '')) return;
		if (_contains(excludeValues, value)) return;
		var hash = { value: value, label: label };
		data.push(hash);
	});
	return new SingleCollection(data, { parse: true });
}

SingleCollection.fromArray = function (arr, opts) {
	opts || (opts = {});
	var filter = opts.filter;
	var data = [];
	var valueType = opts.valueType;
	var consistent = _(arr).every(function (aItem) {
		if (isNotValue(aItem)) return true;
		//if (aItem instanceof BbeModel)
		//	aItem = aItem.toJSON();

		var who = _.who(aItem);
		

		if (who.primitive) {
			if (filter && !filter.call(null, aItem)) return true;

			data.push({ id: aItem, value: aItem, label: aItem });
			return true;

		} else if (who.hash && (isValue(aItem.value) || isValue(aItem.id))) {

			if (filter && !filter.call(aItem, aItem)) return true;

			var value = {
				id: (isValue(aItem.id) && aItem.id) || aItem.value,
				value: aItem.value,
				label: (isValue(aItem.label) && aItem.label) || aItem.value,
			};
			var rawdata = _.extend({}, aItem, value);

			data.push(rawdata);
			return true;
		} else if (who.bbm && ('value' in aItem.attributes || 'id' in aItem.attributes)) {
			data.push(aItem);
			return true;
		}
		return false;
	});

	if (!consistent) {
		console.warn('inconsistent data', arr);
		throw Error("SingleCollection from Array: inconsistent data");
	}
	var col = new SingleCollection(data, { parse: true });
	col.each(function (model) {
		if (model.valueType == null)
			model.valueType = valueType;
	});
	return col;
}

SingleCollection.map = function (_data, map, opts) {

	var data = _data instanceof BbCollection ? _data : _(_data);

	var raw = data.map(function () { return map.apply(_data, arguments); });
	return SingleCollection.fromArray(raw, opts);
}

var RangeCollection = BaseCollection.extend({
	model: Range,
	initialize: function (data, options) {
		options || (options = {});
		var valueType = this.valueType = options.valueType;
		if (this.valueType != null) {
			this.on('add', function (model) {
				model.valueType = valueType;
			});
		}
	}
});

var ns = {};

ns.Single = SingleCollection;
ns.Range = RangeCollection;
ns.Rest = Rest;

export default ns;

export {
	SingleCollection,
	RangeCollection,
	Rest
}