import { UiMoleculeView } from 'coms/ui/UiMoleculeView';

export const ManagerView = UiMoleculeView.extend({
	removeButtonLeft: true,
	content:{
		text: v => {
			const name = v.model.get('name') || {};
			let { first, last, middle } = name;
			return `${last} ${first} ${middle}`
		}
	},
	childViewTriggers: {
		'remove:click':'remove:click'
	},
	// onRemoveClick() {
	// 	console.log('chpok');
	// }
});
