﻿//define('ui-controls-select', ['bus', 'ui-controls-option', 'ui-models-values-collection','behaviors'], function(Bus, Option, Collection, beh) {});


//let Bus, Option, Collection, beh;

import Option from 'ui/controls/option';
import beh from 'behaviors';

import template from './control.html';

import { isNotEmpty } from 'utils/is';
import { bbeViewComparator } from 'utils/comparators';

import { MneView, MneNextCollectionView } from 'core/mne';

import './select.less';

var UlView = MneNextCollectionView.extend({
	tagName: 'ul',
	className: 'select-control',		
	__viewComparator: function (a, b) {
		//console.log('::::',a, b);
		if (this.collection.comparator) {
			return this.collection.comparator(a.model, b.model);
		} else {

			return bbeViewComparator(a, b, function () { return this.model.get('label'); });

		}
		
	},
	testTextPattern: function (view) {
		if (isNotEmpty(this.textFilter)) {
			var pattern = new RegExp(this.textFilter);
			var sel = pattern.test((view.model.getLabel() || '').toLowerCase());
			if (!sel) return false;
		}
		return true;
	},
	viewFilter: function(view) {
		return this.testTextPattern(view);
	},		
	initialize: function () {

		//console.log(' -- ++ SELECT:', this);

		var sel = this.selected = {};
		var col = this.collection;

		//var multiple = this.getOption('multiple');
		var initial = this.getOption('initialValue');
		if (!(initial instanceof Array))
			initial = [initial];

		var func = this.getOption('viewFilter');

		if (typeof func === 'function') {
			this.viewFilter = function (v) {
				var res = func.call(this, v);
				if (!res) return false;

				return this.testTextPattern(v);

			}
		}

		//var comparator = 
		//console.log('OPA', this.getOption('noComparator'));

		if (this.getOption('noComparator') !== true) {
			this.setComparator(this.__viewComparator);
		}

		this.childViewOptions = {
			selected: this.selected
		};
		_.each(initial, function (value) {
			var m = col.findWhere({ value: value });
			if (!m) return;
			sel[m.cid] = m;
		});
	},
	behaviors:[beh.DynamicClass],
	dynamicClass: function() {
		return this.getOption('multiple') ? 'multiple' : '';
	},
	childView: Option,
	unselect: function (model, viewCid) {
		if (!model) return;
		//var selected = this.selected[model.cid];
		delete this.selected[model.cid];
		model.trigger('select-control:unselect', viewCid)
	},
	select: function (model, viewCid) {
		this.selected[model.cid] = model;
		model.trigger('select-control:select', viewCid)
	},
	broadcastChanges: function () {
		// const values =  _(this.selected).chain().toArray().map(function (m) { return _.val(m); }).value();
		const values = _.map(this.selected, (model, key) => {
			const resu = _.val(model);
			// console.warn('>>>', model, resu);
			return resu;
		})
		this.triggerMethod('value:changed', values);
	},
	onChildviewClicked: function (view) {
		var mult = this.getOption('multiple');
		if (mult) {
			var sel = this.selected[view.model.cid];
			if (sel)
				this.unselect(view.model);
			else
				this.select(view.model);
		} else {
			var selected = _(this.selected).chain().toArray().first().value();
			this.unselect(selected, view.cid);
			this.select(view.model, view.cid);
		}
		// console.error('SELecteD', this.selected);
		this.broadcastChanges();
	},
});


var View = MneView.extend({
	className:'select-control-box',
	//template: '#tmpl-ui-controls-select-control',
	template,
	ui: {
		'filtertext':'input[name="filter-label"]'
	},
	triggers: {
		'input @ui.filtertext':'items:filter:input'
	},
	onItemsFilterInput: function () {

		this.valuesView.textFilter = this.ui.filtertext.val();
		this.valuesView.sort();

	},
	regions: {
		'select': { el: 'ul', replaceElement: true }
	},
	onRender: function() {

		this.showSelect();
	},
	getUlOptions: function() {
		var opts = _.extend({}, this.options);
		return opts;
	},
	showSelect: function() {
		var opts = this.getUlOptions();
		var view = this.valuesView = new UlView(opts);
		this.showChildView('select', view);
	},
	onChildviewValueChanged: function(values) {
		this.triggerMethod('value:changed', values);
		//console.log('###', arguments);
	}
});

View.create = function(opts) {
	var options = _.extend({}, opts);
	//console.log('create select', opts)
	return new View(options);
}



export default View;