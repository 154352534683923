import { ButtonView } from './ButtonView';

export const GlyphButtonView = ButtonView.extend({
    template:`<% if(glyph) { %><i class="glyphicon glyphicon-<%=glyph%>"></i><% } %><% if (text) {%><span><%= text %></span><% } %>`,
    templateContext() {
        return {
            glyph: this.getOption('glyph', true),
            text: this.getOption('text', true),
        }
    }
});