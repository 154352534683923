﻿//define('behaviors-form2Hash', [], function () {});

import { Behavior } from 'vendors';

function normName(name)
{
	if (!name) return name;
	if (name.indexOf(':') < 0) return name;
	return name.split(':')[1];
}

var b = Behavior.extend({
	options: {
		applyToModel: false,
		broadcast: true,
	},
	initialize: function () {
		var beh = this;
		this.view.getHash = function () {
			return beh.hash;
		};
		this.initHash();
	},
	initHash: function (inhash) {
		var hash = this.hash || this.getOption('hash');
		this.hash = _.extend({}, inhash, hash);
		//console.log('init result', this.hash);
		this.firstTime = true;
	},
	populate: function () {
		if (this.firstTime)
			this.populateHashValues();
		else
			this.populateFormValues();

		this.firstTime = false;
	},		
	onRender: function () {
		this.populate();
	},
	populateFormValues: function () {			
		var hash = this.getOption('hash');
		if (!hash) return;
		var view = this.view;
		var names = this.getNameTypes();
		_(hash).each(function (value, name) {
			var vname = view.cid + ':' + name;
			var type = names[vname];
			if (type == 'radio') {
				view.$('[type=radio][name="' + vname + '"][value="' + value + '"]').prop('checked',true);
			} else if (type == 'checkbox') {
				if (value instanceof Array) {
					_(value).each(function (_val) {
						view.$('[type=checkbox][name="' + vname + '"][value="' + _val + '"]').prop('checked',true);
					});
				} else {
					view.$('[type=checkbox][name="' + vname + '"][value="' + value + '"]').prop('checked',true);
				}
			} else {
				var $el = view.$('[name="' + vname + '"]');
				//console.log('-=', vname, value);
				$el.val(value);
			}
		});
	},
	getNameTypes: function () {
		var names = {};
		this.$('[name]:not([data-form-skip])').each(function () {
			var name = this.name;
			//if (this.name.indexOf(':')) {
			//	name = name.split(':')[1];
			//}
			names[name] = this.type;
		});
		return names;
	},
	populateHashValues: function () {
		var view = this;
		var hash = this.getOption('hash');
		var names = this.getNameTypes();

		_(names).each(function (type,name) {
			if (type == 'radio') {
				var $chck = view.$('[name="' + name + '"][type="radio"]:checked');
				var value = $chck.val();
				if (hash[normName(name)] == null)
					hash[normName(name)] = value;
				console.log('r', name, value, $chck);
			} else if (type == 'checkbox') {
				if (hash[normName(name)] == null)
					hash[normName(name)] = view.getCheckboxValues(name);
				console.log('c', name, view.getCheckboxValues(name));
			} else {
				if (hash[normName(name)] == null)
					hash[normName(name)] = view.$('[name="' + name + '"]').val();
				//console.log(name, view.$('[name="' + name + '"]').val())
			}
		});
	},
	getCheckboxValues: function (name) {
		var $chcks = this.$('input[type=checkbox][name="' + name + '"]:checked');
		var res = [];
		$chcks.each(function () {
			res.push($(this).val());
		});
		return res;
	},
	onForm2hashReset: function (hash) {
		this.initHash(hash);
	},
	onForm2hashSetHashValue: function (property, value) {
		if (typeof property == 'object') {
			var _this = this;
			_(property).each(function (value, property) {
				_this.setHashValue(property, value);
			});
		}else
			this.setHashValue(property, value);
	},
	setHashValue: function (property, value) {
		property = normName(property);
		//var _this = this;
		var hash = this.hash;
		hash[property] = value;
		this.broadcastChanges(property, value);
		this.syncModel(property, value);
	},
	broadcastChanges: function (property, value) {
		var broadcast = this.getOption('broadcast');
		if (broadcast != true) return;

		var nprop = property.replace(/\./gmi, ':');
		var event = 'hash:change';
		var hash = this.hash; //.getOption('hash');

		this.view.triggerMethod(event, property, value, hash);
		//console.log('----', event, property, value, hash);

		this.view.triggerMethod(event+":"+nprop, property, value);
		//console.log('broadcast', event + ":" + nprop, value);
	},
	syncModel: function (property, value) {
		var model = this.view.model;
		var apply = this.getOption('applyToModel');
		if (!apply || !model) return;
		model.setValueByPath(property, value);
	},
	events: {
		'input textarea:not([data-form-skip])': function (e) {
			e.stopPropagation();
			//this.hash[e.target.name] = $(e.target).val();
			this.setHashValue(e.target.name, $(e.target).val());
		},
		'input input:not([data-form-skip])': function (e) {
			e.stopPropagation();
			//console.log('=====', arguments);
			//this.hash[e.target.name] = e.target.value;
			this.setHashValue(e.target.name, e.target.value);
		},
		'change input[type=radio]:not([data-form-skip])': function (e) {
			e.stopPropagation();
			//console.log('oppa!', e.target.value);
			var dropdown = $(e.target).closest('.dropdown-menu-inputed');
			if (dropdown.length) {
				$(e.target).dropdownLabel();
			}
			//this.hash[e.target.name] = e.target.value;
			this.setHashValue(e.target.name, e.target.value);
		},
		'change input[type=checkbox]:not([data-form-skip])': function (e) {
			e.stopPropagation();
			var dropdown = $(e.target).closest('.dropdown-menu-inputed');
			if (dropdown.length) {
				$(e.target).dropdownLabel();
			}
			var res = this.getCheckboxValues(e.target.name);
			//this.hash[e.target.name] = res;
			this.setHashValue(e.target.name, res);
		},
		'click .edit-complete': function (e) {
			e.stopPropagation();
			var val = _.extend({}, this.hash);
			//this.hash = {};
			this.view.triggerMethod('edit:complete', val);
		},
		'click .edit-cancel': function (e) {
			e.stopPropagation();
			this.hash = {};
			this.view.triggerMethod('edit:cancel');
		},
	}
});

export default b;