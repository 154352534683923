﻿//define('ui-controls-allfilters-groupView', ['ui-controls-filter'], function (Filter) {});

import Filter from 'ui/controls/filter';

import template from './group.html';

import { BbeCollection } from 'core/bbe';
import { MneView, MneNextCollectionView } from 'core/mne';

var GroupFilters = MneNextCollectionView.extend({
    childView: Filter,
    viewComparator: 'order',
    collectionEvents: {
        'view:refresh': _.debounce(function() {
            this.sort();
        }, 70)
    },
    viewFilter: function(view) {
        var model = view.model;
        if (this.collection.filtersPredicate) {
            var predicate = this.collection.filtersPredicate(model);
            if (!predicate) return false;
        }
        return true;
    }
});


var Layout = MneView.extend({
    //template: '#tmpl-ui-controls-allfilters-group',
    template,
    regions: {
        'filters':'.filters-region'
    },
    onRender: function() {
        //console.log('check me', this);
        var fobj = this.getOption('filters');

        var data = _(this.model.filters).toArray();
        var col = new BbeCollection(data);
        col.filtersPredicate = fobj.filters.filtersPredicate;
        var filters = new GroupFilters({ collection: col });
        this.showChildView('filters', filters);
    }
});

export default Layout;