﻿
import factory from 'm/factory';
//import store from '';
import Module from 'appModule';
// import cfg from '';
// import hlps from '';
import link from 'link';
//import gmaps from '';
// import geoModel from 'vXX/geo/reference/model';
import { geoReference } from '../../../../mods/geo';
import Layout from 'svc/realties/search/layout';

import './realties-search-content.less';

var module = Module.content({
	name: 'realties-search',
	label: 'Подбор по списку',
	urlKey:'realtiesSearch',
	routeData: function() {
		return [link.route('realtiesSearch'), link.route('realtiesSearchDeal')];
	},
	onBeforeRun: function (dealWebId) {
		this.addRunPromise(geoReference.refetch());
		// this.addRunPromise(geoModel.populate());
		if (dealWebId) {
			var deal = factory.model('JsonDeal', dealWebId);
			if (!deal.isFetched()) {
				deal.loudFetch();
				this.addRunPromise(deal.getApiPromise());
			}
			this.addRunArgument(deal);
		}
	},
	onRun: function (deal) {
		//console.log('deal::', deal.toJSON());
		var label = deal ? 'Подбор к процессу ' + deal.get('name') : 'Подбор по списку';
		var backUrl = deal ? deal.cardUrl() : undefined;
		var navLinks = deal ? [{ label: 'подбор по карте', href: link.url('realtiesSearchMap:' + deal.get('wId')) }, { label: 'вернуться в процесс', href: link.url('dealCard:' + deal.get('wId')) }] : undefined;
		//var result = 
		module.createAndShow(Layout, { header: label, backUrl: backUrl }, { deal: deal, navLinks: navLinks });
	}
});
//console.log('module', module);
export default module;
