﻿import { AppModule }  from './appModule';
import { ContentModule } from './contentModule';


var ns = {
	simple: function (extend, options) {
		
		const Module = AppModule.extend(extend || {});

		return new Module(options || {});

	},
	content: function (extend, options) {
		const Module = ContentModule.extend(extend || {});
		return new Module(options || {});

	},
}

export default ns;