﻿//define('svc-staff-deps-create', ['appModule', 'link', '_libs/identity', 'svc-staff-deps-create-layout'], function (Module, link, identity, Layout) {});

import Module from 'appModule';
import link from 'link';
import Layout from './layout';

var module = Module.content({
	name: 'staff-departments-create',
	urlKey: 'staffDepartmentCreate',
	label: 'Создание отдела',
	routeData: function () {
		return link.route('staffDepartmentCreate');
	},
	onRun: function () {
		
		//var module = this;
		this.createAndShow(Layout);
	},
	rightsMask: {
		staff: 'manageDepartments'
	}
});


export default module;
