import { Model } from 'core/model';
import { _ } from 'vendors';
import { Collection } from 'core/collection';

import { getNestedCollection } from 'utils/getNestedCollection';
import { contactValuesModelComparator } from './comparator';
import { phoneTypes } from './contactValueSchema';

export const ContactValueModel = Model.extend({

});

const valueKeys = { type: 1, value: 1 }

export const ContactValuesCollection = Collection.extend({
	model: ContactValueModel,
	comparator: contactValuesModelComparator,

	getMainValue() {
		const model = this.first();
		return model;
	},

	_toPredicate(predicate) {
		return model => {
			const attrs = model.attributes;
			return _.every(predicate, (predicateValue, predicateKey) => {
				const obj = predicateKey in valueKeys ? attrs.value : attrs;
				const objValue = obj[predicateKey];

				if (Array.isArray(predicateValue)) {
					return predicateValue.indexOf(objValue) > -1;
				} else {
					return predicateValue === objValue;
				}
			});
		}
		// const hash = {
		// 	"id": 0,
		// 		"type": 0,
		// 		"value": 0,
		// 	"addValue": 0,
		// 	"order": 0,
		// 	"purposes": 0
		// }
	},

	getMainPhoneModel() {
		const predicate = this._toPredicate({
			type: ['phone', 'mobilePhone']
		});
		return this.findWhere(predicate);
	},

	getMainEmailModel() {
		const predicate = this._toPredicate({
			type: 'email'
		});
		return this.findWhere(predicate);
	},

	getMainPhoneValue() {

		const model = this.getMainPhoneModel();
		if (!model) { return; }
		const add = model.attributes.addValue;
		const value = model.attributes.value.value;
		return [value, add];

	},

	getMainEmailValue() {
		const model = this.getMainEmailModel();
		if (!model) { return; }
		return model.attributes.value.value;
	},

});

export function getContactValuesCollection(model) {
	return getNestedCollection(model, {
		dataKey: 'values',		
		Collection: ContactValuesCollection,
	});
}