//define('request-cfg', ['server-cfg', 'bus-app'], function(serverCfg, busApp) {});

import serverCfg from 'server-cfg';
import busApp from 'bus/app';

const module = {
	headers: {},
	setHeader(name, value) {
		if (typeof name === 'string') {
			module.headers[name] = value;
		} else if (name && typeof name === 'object') {
			Object.assign(module.headers, name);
		}
		$.ajaxSetup({
			headers: Object.assign({}, module.headers)
		});			
	},
	removeHeader(...keys)
	{
		keys.forEach(key => {
			delete module.headers[key];
		});
		module.setHeader();
	}
}

if (serverCfg.svcWebId) {
	module.setHeader('svcwebid', serverCfg.svcWebId)
}

busApp.on('svc:config', svc => {

	if (svc == null || !svc.id) {
		module.removeHeader('svcwebid', 'svcid');
	} else {
		module.setHeader({
			svcwebid: svc.idString,
			svcid: svc.id
		});
	}
});