const defaultOptions = {
    invert: false,
    modelfFirst: true,
}

const defaultInvertedOptions = {
    invert: true,
    modelfFirst: true,
}


function normalizeOptions(invert) {
    if (invert === true) {
        return defaultInvertedOptions;
    } else if (!invert) {
        return defaultOptions;
    }
    return invert;
}

export function keycmp(key, v1, v2, invertArg) {
    let { invert, modelFirst } = normalizeOptions(invertArg);
    let m1 = v1.model;
    let m2 = v2.model;
    let m1IsNull = m1 == null;
    let m2IsNull = m2 == null;

    // model first behavior 
    if (m1IsNull || m2IsNull) {
        return m1IsNull 
            ? m2IsNull 
                ? 0
                : modelFirst ? 1 : -1
            : modelFirst ? -1 : 1;
    }

    let m1v = m1.get(key);
    let m2v = m2.get(key);

    let first = invert ? m2v : m1v;
    let second = invert ? m1v : m2v;

    return first < second ? -1
    : first == second ? 0
    : 1;
}

export const ordercompare = (v1,v2) => keycmp('order', v1, v2, true);

export const compare = (v1,v2, ...comparators) => {
    let result;

    for(let x = 0; x < comparators.length; x++) {
        let cmp = comparators[x];
        result = cmp(v1,v2);
        if (result)
            return result;
    }

    return result || 0;
}