﻿//define('ui-controls-datetime2-selectYearItemView', ['behaviors', 'ui-controls-datetime2-selectItemView'], function (beh, SelectItemView) {});

import SelectItemView from './select-item-view';

var View = SelectItemView.extend({
	isCurrent: function () {
		return (new Date()).getFullYear() == this.model.id;
	},
});

export default View;
