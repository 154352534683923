﻿//define('websockets-config', ['app-config'], function(cfg) {});

import cfg from 'app-config';

var hubName = '_aws';
var server = cfg.paths.api.server; //cfg.paths.api.server.replace('.loc', '.plus');
const oldConfig = {
    old: true,
    hubUrl: server + '/' + hubName,
    hubId: 'wsApiHub',
    channel: '_aws'
}

const newConfig = {
    hubUrl: server + '/' + hubName,
    channel: '_aws'
};

let socketsConfig;

if (cfg.sockets.old) {
    socketsConfig = oldConfig;
} else {
    socketsConfig = newConfig;
}

export default socketsConfig;