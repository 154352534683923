module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<small>'+
((__t=( _enum('clientTypes',_m.type, 'клиент с неустановленным типом')))==null?'':__t)+
'</small>\n<span>'+
((__t=( _ift(_m.name, 'без названия*') ))==null?'':__t)+
' <small>'+
((__t=( _ndash(_m.occupation) ))==null?'':__t)+
'</small></span>\n<div class="item-fastaction-trigger"><span></span></div>';
}
return __p;
};
