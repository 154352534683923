import cfg from 'app-config'; // 'app-config'
import { identity } from 'mods/acc';

export const mediaApi = {
    url(hash, options, ext) {
        let root = cfg.paths.app.media;
				identity.get()
        let svcId = identity.getSvcWebId();
        return `${root}svc/${svcId}/${hash}/${options}/${ext}`;
    }
};