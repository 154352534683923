// define('svc/actualization/realties-list/realty/edit/properties/properties-view', [
//     'svc/actualization/realties-list/realty/edit/properties/bus',
//     'svc/actualization/realties-list/realty/edit/properties/property-view'
// ], function(
//     bus, PropertyView
// ) {});

import bus from './bus';
import PropertyView from './property-view';
import { ordercompare } from './comparators';
import { NextCollectionView } from 'vendors';

const Layout = NextCollectionView.extend({
    className: 'properties-list',
    childView: PropertyView,
    initialize() {
        this.listenTo(bus, 'filter:change', () => {
            this.filter()
        });
    },
    childViewOptions() {
        return {
            entity: this.model,
        }
    },

    onRender() {
        //console.log('# props', this);
    },

    viewFilter(propertyView) {
        return bus.filterProperty(propertyView.model, this.model);
    },
    viewComparator: ordercompare
});

export default Layout;
