import refs from 'references';
// string Key 
// string Name 
// string GroupName 
// string Purpose 
// RealtyAgencyDataTypes ValueType 
// string StringValue 
// bool? BooleanValue 
// decimal? NumberValue 

const fullSchema = {
	key : {
		caption: 'идентификатор (латинские буквы и цифры)',
		valueType: 'string',
		required: true,
		emptyText: 'не установлено',
	},
	name : {
		caption: 'отображаемое название (для внутреннего использования)',
		valueType: 'string',
		emptyText: 'не установлено',
	},
	groupName: {
		caption: 'группа (для внутреннего использования)',
		valueType: 'string',
		emptyText: 'не установлено',
	},
	purpose: {
		caption: 'назначение (для определения где публиковать)',
		valueType: 'string',
		emptyText: 'не установлено',
	},
	valueType: {
		caption: 'тип значения',
		valueType: 'enum',
		sourceValues: () => refs.enum('realtyAgencyDataTypes'),
		// emptyText: 'Текст',
		// display: (v = 'text') => refs.enum('realtyAgencyDataTypes', v),
		multiple: false,
	},
	value: {
		caption: 'значение',
		valueType: 'bigtext',
		required: true,
		emptyText: 'не установлено',
	},

}

const addKeys = {
	key : 1,
	name : 1,
	groupName: 1,
	purpose: 1,
	// valueType: 1,
	value: 1
}

export const addNewSchema = Object.keys(addKeys).reduce((memo, key) => {
	memo[key] = fullSchema[key];
	return memo;
}, {});


const editKeys = {
	key : 1,
	name : 1,
	groupName: 1,
	purpose: 1,
	// valueType: 1,
	value: 1
}


export const editExistSchema = Object.keys(editKeys).reduce((memo, key) => {
	memo[key] = fullSchema[key];
	return memo;
}, {});


// const props = {

	// floor: {
	// 	caption: 'этаж',
	// 	emptyText: 'не установлен',
	// 	modelType: 'single',
	// 	valueType: 'number',
	// 	display: v => v != null ? v + ' эт.' : '',
	// 	validate: required,	
	// },
	// roomNumber: {
	// 	caption: 'номер кабинета',
	// 	emptyText: 'не установлен',
	// 	modelType: 'single',
	// 	valueType: 'string',
	// 	//display: v => v ? v + ' эт.' : '',
	// 	//validate: required,	
	// },
	// square: {
	// 	caption: 'площадь',
	// 	emptyText: 'не указана',
	// 	modelType: 'single',
	// 	valueType: 'number',
	// 	display: v => v != null ? v + ' кв. м' : '',
	// 	validate: required,	
	// },
	// purposes: {
	// 	caption: 'возможные назначения',
	// 	emptyText: 'не выбраны',
	// 	// display: v => {
	// 	// 	console.log('v::', v);
	// 	// 	let gg = (v || []).map(mv => refs.enum('realtyPurposes', mv));
	// 	// 	return gg.join(', ');
	// 	// },
	// 	sourceValues: refs.enum('realtyPurposes'),
	// 	modelType: 'single',
	// 	valueType: 'enum',
	// 	//flattenValues: true,
	// 	multiple: true,
	// 	validate: required,	
	// },		
	// currentPurposes: {
	// 	caption: 'текущее назначение',
	// 	emptyText: 'не выбрано',
	// 	//display: v => v ? refs.enum('realtyPurposes',v) : undefined,
	// 	sourceValues: refs.enum('realtyPurposes'),
	// 	modelType: 'single',
	// 	valueType: 'enum',
	// 	//flattenValues: true,
	// 	multiple: false,
	// },
// }