﻿//define('store',['store-model'],function(Model){});

import Model from './model';

const Store = Model.extend({
	channelName: 'store',
});
const store = new Store();

export default store;
