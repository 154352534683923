module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
if(funcs){
__p+='<small>'+
((__t=( funcs))==null?'':__t)+
'</small>';
}
__p+='\n<span>'+
((__t=(name))==null?'':__t)+
''+
((__t=(contact))==null?'':__t)+
'</span>\n'+
((__t=( bottomName))==null?'':__t)+
'\n';
}
return __p;
};
