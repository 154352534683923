import { UiSchemaMoleculeView, UiSchemaAtomView } from "coms/schema";
import { contactModelSchema } from "./schema";
import { TextView } from 'core';
import { MainContactValueView } from "./MainContactValueView";

import { editContactInModal, removeContact } from 'mods/contacts';


import { simplePopupMixin } from 'apis/popup';


function getRealtyNames(m) {
	const names = (m.get('inRealties') || [])
	.reduce((memo, ir) => {
		memo[ir.ownerInRealty.realtyName] = 1;
		return memo;
	}, {});
	return Object.keys(names);
}


const RealtyNamesPopupView = TextView.extend({
	//classNames: ['fixed'],
	text: v => getRealtyNames(v.model).map(n => `<div class="popup-line">${n}</div>`).join('')
})

export const realtiesPopupMixin = {
	
	...simplePopupMixin,

	hoverPopup: {
		autoUpdateEnabled: true,
		openEvent: 'mouseenter',
		removeDelay: 500,
		wrapContent: true,
		popupViewOptions: {
			thisClassName: 'yellow realty-names-popup'
		},
		view: {
			class: RealtyNamesPopupView,
		},
		useFlip: true,
		positionOptions: {
			placement: 'bottom-start',
		}
	},
}


const RealtiesAtomView = UiSchemaAtomView.extend({
	...realtiesPopupMixin,
	initialize() {
		this.initializePopupMixin()
	},
	thisClassName: 'fixed',
	topText: 'здания',
	text: v => v.getRealtyNamesCount(),
	getRealtyNamesCount() {
		const names = getRealtyNames(this.model);
		return names.length;
	},
	// getRealtyNames() {
	// 	const names = (this.model.get('inRealties') || [])
	// 	.reduce((memo, ir) => {
	// 		memo[ir.ownerInRealty.realtyName] = 1;
	// 		return memo;
	// 	}, {});
		
		
	// 	return Object.keys(names);
	// },
})

// const valuesPopupMixin = {
	
// 	...simplePopupMixin,

// 	hoverPopup: {
// 		autoUpdateEnabled: true,
// 		openEvent: 'mouseenter',
// 		removeDelay: 50000,
// 		wrapContent: true,
// 		popupViewOptions: {
// 			thisClassName: 'yellow contact-values-popup'
// 		},
// 		view: {
// 			class: TextView,
// 			text: '<h1>oplya</h1>'
// 		},
// 		useFlip: true,
// 		positionOptions: {
// 			placement: 'left-start',
// 		}
// 	},
// }


// const MainValueView = UiSchemaAtomView.extend({
// 	...valuesPopupMixin,
// 	initialize() {
// 		this.initializePopupMixin();
// 		console.error('- popuped -');
// 	},
// 	// initialize() {
// 	// 	this.contact = this.model;
// 	// 	this.model = this.model.getMainValue();
// 	// },
// 	getMainPhoneUrl() {
// 		const value = this.model.getMainPhoneValue();
// 		return displayPhone(value, { url: true });
// 	},
// 	getMainEmailUrl() {
// 		const value = this.model.getMainEmailValue();
// 		return displayEmail(value, { url: true });

// 	},
// 	getModelSchema() {
// 		return contactValueSchema;
// 	},
// 	showSmall: true,
// 	showBottom: false,
// 	text: v => v.getMainPhoneUrl(),
// 	smallText: v => v.getMainEmailUrl()
// 	// smallText: v => displayPhone(v.value('value.value'))
// });

export const ContactView = UiSchemaMoleculeView.extend({

	modelSchema: contactModelSchema,
	childViewOptionsKeys: ['modelSchema'],
	childView: UiSchemaAtomView,
	thisClassName: 'contact-list-item wrap-on',
	editButtonLeft: true,
	removeButton: true,
	classNames: [
		v => v.ifValueEqual('isPerson', true, 'person-contact', 'common-contact'),
		v => v.ifValueEqual('primary', true, 'primary'),
		v => v.ifValueEqual('isPublic', true, 'public'),
	],

	content: {
		showSmall: true,
		showBottom: false,
		thisClassName: 'fixed',
		text: v => v.display('name'),
		smallText: v => v.display('note'),
		// topText: v => 'порядок сортировки: ' + [v.model.get('primary') ? 'главный' : '', v.model.get('order')].filter(f => !!f).join(', ')
		// bottomText: v => v.display('ownerFuncs')
	},

	rightItems: [
		{ 
			class: MainContactValueView, 
			topText: v => 'порядок сортировки: ' + [v.model.get('primary') ? 'главный' : '', v.model.get('order')].filter(f => !!f).join(', ')
		},
		{
			class: TextView,
			thisClassName: 'ui-system-line full-width',
			text: v => v.display('ownerFuncs')
		},
		RealtiesAtomView
		// v => ({
		// 	topText: 'здания',
		// 	text: v.getRealtyNames().length
		// }),
		// {
		// 	topText: 'порядок сортировки',
		// 	text: v => [v.model.get('primary') ? 'главный' : '', v.model.get('order')].filter(f => !!f).join(', ')
		// },

	],
	childViewEvents: {
		'edit:click'() {
			console.log('	: contact : 	', this.model);
			editContactInModal(this.model, {
				realties: this.getOption('realties', true),
				owner:  this.getOption('owner', true),
			}).then((res) => {
				console.warn('	AFTER:EDIT	',res);
				//this.model.on('all', c => console.log('-mod-', c));
				return this.model.fetch();
			}).then(() => {
				this.model.valuesCollection.reset(this.model.get('values'));
				this.render();
			});
		},
		'remove:click'() {
			removeContact(this.model, {
				owner:  this.getOption('owner', true),
			});
		}
	},

	modelEvents: {
		'change': 'render'
	}
});